import { Component, OnInit, Input, Output, EventEmitter, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { CaravanFormAdminACO, ColumnDefs } from '@shared/models/forms/form-admin-model';
import { ClickableParentComponent } from './clickable.parent.component';

@Component({
  selector: 'coach-admin-form-report',
  templateUrl: './admin-report.component.html'
})
export class AdminFormReport implements OnInit {
  @Input() CaravanFormAdminData: []; // Import form from parent component
  @Input() columnNamesLevelOne: ColumnDefs[];
  @Input() tierID: string;
  @Input() reportName: string;
  @Input() formID: string;

  @Output() resultClickedEvent = new EventEmitter<rowSelected>();
  @Output() resultResponseReport = new EventEmitter();

  public allSubs: Subscription[];
  public specialStatusSummary: CaravanFormAdminACO[];

  public defaultColDef;
  public detailCellRendererParams;
  public detailCellRenderer;
  public context: ReportContext;

  public gridApi;
  public gridColumnApi;
  public rowData;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    this.context = { componentParent: this };
  }

  public frameworkComponents = {
    clickableParentComponent: ClickableParentComponent
  };

  ngOnInit(): void {}

  reportParentClickResult(cell: any, data: any, row: any): void {
    this.resultClickedEvent.emit({ cell, data });
  }

  onFirstDataRendered(params: any): void {
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(0).setExpanded(false);
    }, 0);
  }

  public formSubmissionsOverview: CaravanFormAdminACO[];

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onBtnExport(): void {
    let columnKeys = [];
    this.columnNamesLevelOne.forEach(c => {
      if (c?.cellRenderer !== 'clickableParentComponent') {
        columnKeys.push(c.field);
      }
    });

    const reportName: string = this.reportName + '_' + this.tierID;
    const timestamp = formatDate(new Date(), 'yyyy-MM-dd', this.locale);

    this.gridApi.exportDataAsCsv({
      suppressQuotes: false,
      columnKeys: columnKeys,
      fileName: `${reportName}_${timestamp}.csv`
    });
  }

  onBtnExportResponses(): void {
    this.resultResponseReport.emit();
  }
}
interface ReportContext {
  componentParent: AdminFormReport;
}
interface rowSelected {
  cell: string;
  data: any;
}
