<coach-header-title-portal>
    <div class="padding-left--larger u-truncate padding-right--larger u-display--none--larger">
        <h3 class="margin--none">{{reportMenuItem?.menuName}}</h3>
        <a [routerLink]="" (click)="openPane()">{{selectedQuarterYearItem?.attributionYear}}
            <span *ngIf="!reportConfig.hideQuarter"> |
                {{selectedQuarterYearItem?.attributionQuarter_DisplayText}}
            </span>
            <span *ngIf="initialReportFilterItem != null">
                <span> | {{initialReportFilterItem?.filterType}} </span>
                <span *ngIf="initialReportFilterItem?.filterType === cinLevel"> |
                    {{initialReportFilterItem?.filterTypeName}}</span>
                <span *ngIf="initialReportFilterItem?.filterType !== cinLevel"> |
                    {{initialReportFilterItem?.filterTypeID}}</span>
            </span>
        </a>
    </div>
</coach-header-title-portal>

<coach-toggle-pane [(isOpen)]="isFilterPaneOpen">
    <div class="titles" pane-header>
        <div class="title">Filters</div>
    </div>
    <div pane-body>
        <coach-track-time-filters [quarterYears]="quarterYears" [(selectedYear)]="reportParameters.attributionYear"
            [(selectedQuarter)]="reportParameters.attributionQuarter" [reportConfig]="reportConfig">
        </coach-track-time-filters>
        <coach-track-report-filter-types *ngIf="reportMenuItem?.showCinRegionFilter === 1 && this.reportFilterTypes.length > 1" [reportFilterTypes]="reportFilterTypes"
            (selectedItemChange)="filterItemSelected($event)" [(selectedItem)]="selectedReportFilterItem"
            [(selectedFilterType)]="selectedReportFilterType" [initialItem]="initialReportFilterItem">
        </coach-track-report-filter-types>
        <button (click)="runReportClick()" class="button is-primary margin-auto margin-top--larger">Apply</button>
    </div>
</coach-toggle-pane>

<div class="u-fill u-position--relative">
    <div *ngIf="isReportLoaded" class="print-button">
        <button class="button" (click)="print()">
            Print
        </button>
    </div>

    <div id="pbi-container" #powerBi>
        <div *ngIf="embedFail && !showNoDataModal">
            <h1>Unable to load the report at this time. Please try again later.</h1>
        </div>
    </div>
</div>

<ng-container *ngIf="showNoDataModal">
    <div class="overlay is-open">
        <div class="modal u-width--largest">
            <div class="header">
                <div class="titles">
                    <div class="title u-display--flex u-justify-content--spaceBetween">
                        <span>{{noDataTitle}}</span>
                    </div>
                </div>
            </div>
            <div class="padding--large" [innerHTML]="noDataMessage">
            </div>
            <div class="footer"></div>
        </div>
    </div>
</ng-container>