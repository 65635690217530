import * as wp from '@hcd-caravanhealth/pkg-wptypes';

export enum EnvironmentType {
  dev = 'dev',
  qa = 'qa',
  demo = 'demo',
  prod = 'prod',
}

export type AppId =
  | '93b14305-c16a-4f29-bcd1-abe5680ab49e'
  | '76cea668-b792-439a-9e8a-13af9d10c752'
  | '17e61bfa-2fdb-4d74-9f91-3f5f571d9e9b'
  | '638367a0-f2fe-4baf-ac7a-ef8ba4a5c633';

export enum AppIdEnum {
  dev = '93b14305-c16a-4f29-bcd1-abe5680ab49e',
  qa = '76cea668-b792-439a-9e8a-13af9d10c752',
  demo = '17e61bfa-2fdb-4d74-9f91-3f5f571d9e9b',
  prod = '638367a0-f2fe-4baf-ac7a-ef8ba4a5c633',
}

export const AppIds: { [x in EnvironmentType]: AppId } = {
  dev: '93b14305-c16a-4f29-bcd1-abe5680ab49e',
  qa: '76cea668-b792-439a-9e8a-13af9d10c752',
  demo: '17e61bfa-2fdb-4d74-9f91-3f5f571d9e9b',
  prod: '638367a0-f2fe-4baf-ac7a-ef8ba4a5c633',
};

export interface IEnvironment {
  production: boolean;
  /** The data environment for this deployment. This determines which set of patients and patient data that is used.
   * In the Wellpepper data environment, entities will be tagged with the environmentId so that they remain distinct.
   *
   * Permissions are dealt with separately as they are mapped to target entity permissions based on the
   * users' identity issuer e.g. users in ChPortalDev are identified as non-prod users and given permissions only
   * to the corresponding non-prod Wellpepper organizations.
   */
  environmentId: EnvironmentType;
  /** application ID in B2C instance */
  authClientId: AppId;
  wpApiUrl: string;
  trackApiUrl: string;
  userDetailApiUrl: string;
  apiBaseUrl: string;
  ocpApimSubscriptionKey: string;
  chEndPoint: string;
  authWellknownEndpoint: string;
  stsServer: string;
  authConfigScope: string;
  /** Related to environmentId, but is used to configure how patients are mapped to the multi-tenanted
   * wellpepper data environment from different source data environments.
   */
  patientIdentityProvider: wp.common.IdentifierProviderInfo;
  coachApiUrl: string;
  qualityInitiativeApiUrl?: string;
  prizmApiUrl: string;
  careOrchApiUrl: string;
  blobStorageUrl: string;
  maskSensitiveData: boolean;
  useHashStrategy: boolean;
  idleStart: number;
  idleTimeout: number;
  idlePing: number;
  e2e?: { Username: string; Password: string };
  displayRiskLevel: boolean;
  mixpanel: {
    projectId: string;
    debug: boolean;
    pageViews: boolean;
  };
  appInsights?: {
    connectionString: string;
    cloudRole?: string;
  };
  serverEventsEndpoint: string;
}
