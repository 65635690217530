import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sidebar-account-dropdown',
  templateUrl: './sidebar-account-dropdown.component.html',
  styleUrls: ['./sidebar-account-dropdown.scss'],
  host : {
    class: ''
  }
})
export class SidebarAccountDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
