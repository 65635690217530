import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Patient } from '@shared/models/patient';
import { forkJoin } from 'rxjs';
import { PatientService } from '@shared/services/patient/patient.service';
import { HccCodingOpportunity } from '@shared/models/hcc-care-gaps';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { ErrorHandlerService } from '@shared/services/logging/error-handler.service';
import { HccPatient } from '@api/track/models/hcc-patient';
import { FacesheetService } from '@api/track/services/facesheet.service';
import { Facesheets } from '@api/track/models';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';

@Component({
  selector: 'coach-patient-chronic-coding-opportunities',
  templateUrl: './patient-chronic-coding-opportunities.component.html',
  styleUrls: ['./patient-chronic-coding-opportunities.component.scss']
})
export class PatientChronicCodingOpportunitiesComponent implements OnInit {
  @Input() patient: Patient;
  @Input() tier: IHierarchyTier;
  @Input() mobileScreenSize: boolean;

  progress = 75;
  showClosedGaps = false;
  showPotentialOpportunities = false;
  showMoreOpportunities = false;
  opportunities = []; // TODO: create opportunity model
  chronicOpportunities: HccCodingOpportunity[] = [];
  diseaseInteractions: HccCodingOpportunity[] = [];
  hccPatient: HccPatient;
  FacesheetV28: string = CareFeatureConstants.FacesheetV28;

  get tierId(): string {
    return this?.tier?.selectedTierId;
  }
  get tierNum(): number {
    return this?.tier?.tier;
  }

  constructor(
    private _patientService: PatientService,
    private _facesheetService: FacesheetService,
    private toastrMessageService: ToastrMessageService,
    private errorHandlerService: ErrorHandlerService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.loadHealthData();
  }

  private async loadHealthData(): Promise<void> {
    if (this.patient) {
      const hasFacesheetV28Flag = await this.featureFlagService.hasFeatureFlag(this.FacesheetV28);
      if (!hasFacesheetV28Flag && this.patient?.trackPatient?.chPatId) {
        forkJoin({
          reqChronicOpportunities: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'opportunities',
            true
          ),
          reqAcuteOpportunities: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'opportunities',
            false
          ),
          hccPatient: this._facesheetService.facesheetGet({
            Id: this.patient.trackPatient.mbi,
            ChPatId: parseInt(this.patient.trackPatient.chPatId)
          })          
        }).subscribe(
          ({ reqChronicOpportunities, reqAcuteOpportunities, hccPatient }) => {
            this.chronicOpportunities = reqChronicOpportunities.filter(co => !co.isInteraction) || [];
            this.diseaseInteractions =
              reqChronicOpportunities
                .filter(co => co.isInteraction)
                .concat(reqAcuteOpportunities?.filter(co => co.isInteraction) || []) || [];
            this.hccPatient = hccPatient;
          },
          errors => {
            this.toastrMessageService.error(errors);
            this.errorHandlerService.handleError(errors);
          }
        );
      }
    }
  }
}
