<div class="
    queue
    border-right
    u-position--relative
    animation--content--fadeIn
    u-height--full
    u-justify-content--spaceBetween" [ngClass]="isQueueOpen ? 'is-open' : 'is-closed'">
    <button class="pullTab top--xs" (click)="toggleQueueOpen()" [ngClass]="{ 'is-open': isQueueOpen }"
        aria-label="Toggle File List Panel">
        <coach-icon iconName="chevrons-right"></coach-icon>
    </button>
    <div class="header">
        <div class="titles t5">
            <div class="title">{{title}}</div>
            <div class="subtitle">Filters</div>
        </div>
    </div>

    <div class="dynamicBody">
        <div class="accordion list-item" [class.is-open]="isAssignmentOpen">
            <div class="row u-center u-justify-content--spaceBetween">
                <div class="field header border--none cursor-default u-flex-direction--column row-title">
                    Assignment
                    <div class="selection-list">
                        <span class="selection-element u-font-size--smallest u-italic u-color--interaction">
                            Attributed{{ currentFilterModel.isMostAssignable ? ' + Most Assignable' : ''}}
                        </span>
                    </div>
                </div>

                <coach-icon class="u-cursor--pointer" iconName="{{isAssignmentOpen ? 'chevron-up' : 'chevron-down'}}"
                    (click)="isAssignmentOpen = !isAssignmentOpen" iconColor="currentColor">
                </coach-icon>
            </div>
            <div class="content content-planRow">
                <div>
                    <input [checked]="!currentFilterModel.isMostAssignable" id="attributed-radio"
                        (change)="mostAssignableSelected()" type="radio">
                    <label for="attributed-radio">Attributed</label>
                </div>
                <div>
                    <input [checked]="currentFilterModel.isMostAssignable" id="most-assignable-radio"
                        (change)="mostAssignableSelected()" type="radio">
                    <label for="most-assignable-radio">Attributed + Most Assignable</label>
                </div>
            </div>
        </div>

        <div *ngIf="hasCinAccess" class="accordion list-item" [class.is-open]="isCinOpen">
            <div class="row u-center u-justify-content--spaceBetween">
                <div class="field header border--none cursor-default u-flex-direction--column row-title">
                    CIN
                    <div class="selection-list">
                        <span class="selection-element u-font-size--smallest u-italic u-color--interaction">
                            {{currentFilterModel.isCinSelected ? currentFilterModel.selectedCin?.name : ''}}
                        </span>
                    </div>
                </div>
                <coach-icon class="u-cursor--pointer" iconName="{{isCinOpen ? 'chevron-up' : 'chevron-down'}}"
                    (click)="isCinOpen = !isCinOpen" iconColor="currentColor">
                </coach-icon>
            </div>
            <div class="content content-planRow shadowLess">
                <div class="u-italic u-font-size--smallest">Use this if you want to view the data grouped by a CIN instead of the traditional ACO structure</div>
                <div>
                    <input [checked]="currentFilterModel.isCinSelected" id="cin-checkbox" (change)="viewCinSelected()"
                        type="checkbox">
                    <label for="cin-checkbox">View by CIN</label>
                </div>
                <div *ngIf="currentFilterModel.isCinSelected">
                    <select name="items" [(ngModel)]="currentFilterModel.selectedCin" (ngModelChange)="cinSelected($event)"
                        *ngIf="!isCinLoading">
                        <option *ngIf="cinItems?.length === 0" disabled [ngValue]="null">None Available</option>
                        <option *ngFor="let item of cinItems" [ngValue]="item">
                            {{item.name}}
                        </option>
                    </select>
                    <div class="select-placeholder u-display--flex u-progress" *ngIf="isCinLoading">
                        <coach-loading-indicator class="u-flex--right"></coach-loading-indicator>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="border-top--4 ">
        <button [disabled]="!hasModelChanged || isCinLoading" class="button is-primary margin-auto margin-top margin-bottom" (click)="applyChanges()">
            Apply
        </button>
    </div>
</div>