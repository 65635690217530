<div class="u-height--full u-display--flex u-flex-direction--column">
    <div class="margin-topBottom">
        <strong>Note: ACO Champions</strong>, For any clinician adds or removes please send an email to&nbsp;
        <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>&nbsp;with any updates and the
        action(s) to be taken. For multiple changes, download all clinicians to an Excel sheet and submit as an
        attachment with any edits noted to&nbsp;<a
            href="mailto:{{supportEmail}}">{{supportEmail}}</a>.&nbsp; Note: <b>Practices Tax
            IDs&nbsp;</b>can only be added or removed annually.
    </div>

    <div class="margin-topBottom">
      <strong>Program Select:</strong>
      <select class="field u-color--interaction u-width--larger" name="submissionPeriod" id="subPeriod"  [(ngModel)]="progID" (change)="selectClinicianRoster($event)">
        <option *ngFor="let program of ClinicianRoster" [value]="program.progID">
          {{ program.progType }}
        </option>
      </select>
  </div>

    <ag-grid-angular class="ag-theme-material contacts-grid u-height--full" [pagination]="true"
        [paginationAutoPageSize]="true" multiSortKey="ctrl" [rowData]="rowData" [columnDefs]="columnDefs"
        (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)" 
        coachAgGridResize>
    </ag-grid-angular>
    <button [disabled]="rowData == null || rowData.length === 0" class="button is-small margin-right export-button"
        (click)="export()"> Export </button>
</div>

<ng-container *ngIf="showInstructionsModal">
    <div class="overlay is-open">
        <div class="modal u-width--largest">
            <div class="header">
                <div class="titles">
                    <div class="title u-display--flex u-justify-content--spaceBetween">
                        <span>Clinician Roster Export Instructions</span>
                    </div>
                </div>
            </div>
            <div class="dynamicBody padding">
                <div class="margin-topBottom--small u-italic u-bold">
                    This file is to attest to Caravan Health the clinicians currently participating in the community's
                    ACO.
                </div>
                <div class="margin-topBottom--small u-italic u-bold">
                    The data contained within this report is coming from Caravan Health's internal records and is used
                    to identify the clinicians who are participating in the community's ACO.
                </div>
                <p class="margin-top--large">
                    Please make any required changes in the PECOS system first. Then update this spreadsheet. Once
                    completed please submit the workbook as an attachment on a new ticket at {{supportEmail}}

                    Please update the column Q on each row to state if it is correct or not. Column R is provided for
                    any note you may want to leave us for that given row.
                </p>
                <p>
                    To update PECOS via the internet portal, please click <a target="_blank"
                        href="https://pecos.cms.hhs.gov">here</a>
                </p>
                <p>
                    For PECOS technical assistance, please click <a target="_blank"
                        href="https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/downloads/MedEnroll_PECOS_Contact_FactSheet_ICN903766.pdf">here</a>
                        for troubleshooting help.

                </p>
            </div>
            <div class="footer">
                <button class="button is-primary" (click)="closeModal()">OK</button>
            </div>
        </div>
    </div>
</ng-container>
