<div class="dynamicBody">
  <div class="widgets">
    <div class="widget" data-cy="practiceImprovement_widget" *hasFeature="practiceImprovementFeature">
      <div class="header header--slanted u-hoverEffect" [routerLink]="['../practice-improvement']">
        <div class="titles t5">
          <div class="title">Practice Improvement</div>
        </div>
      </div>
      <div class="dynamicBody padding--large">
        <img class="widget-illustration" src="assets/img/illustrations/SH_PEP_Illustrations_Improve_Sum_PI_1700x1100_20221114.svg" />
        <p class="u-italic u-opacity--light margin-top--smaller">
          Learn about {{app.CompanyNameFull}}'s proven best practices and how to implement them across your community.
        </p>
        <ul class="margin-top--large margins-bottom--small">
          <li *ngIf= "RoadmapsFolder && RoadmapsFolder.id"><a href="Roadmaps" (click)="goToItems($event, '/improve/practice-improvement', {folder: RoadmapsFolder.id})">Roadmaps</a>
          <li *ngIf= "RoadmapsFolder && !RoadmapsFolder.id"><a href="Roadmaps" (click)="goToItems($event, '/improve/practice-improvement', {folder: 207})">Roadmaps</a>
          <li><a href="Quality Performance" (click)="goToItems($event, '/improve/practice-improvement', {folder: 18})">Quality Perfomance</a></li>
          <li><a href="Compliance" (click)="goToItems($event, '/improve/practice-improvement', {folder: 20})">Compliance</a></li>
          <li><a href="Promoting Interoperablity" (click)="goToItems($event, '/improve/practice-improvement', {folder:4206})">Promoting Interoperability</a></li>
          <li><a href="Clinical Program Documents" (click)="goToItems($event, '/improve/practice-improvement', {folder:4068})">Clinical Program Documents</a></li>
          <li><a href="Client Success" (click)="goToItems($event, '/improve/practice-improvement', {folder:4060})">Client Success Stories</a></li>
        </ul>
      </div>
    </div>
    <div class="widget" data-cy="infoTechnology_widget" *hasFeature="informationTechnologyFeature">
      <div class="header header--slanted u-hoverEffect" [routerLink]="['../information-technology']">
        <div class="titles t5">
          <div class="title">Information Technology</div>
        </div>
      </div>
      <div class="dynamicBody padding--large">
        <img class="widget-illustration" src="assets/img/illustrations/SH_PEP_Illustrations_Improve_Sum_IT_1700x1100_20221114.svg" />
        <p class="u-italic u-opacity--light margin-top--smaller">
          We're here to help! We are dedicated to improving our solutions to make your job easier - check out the latest and greatest!
        </p>
        <ul class="margin-top--large margins-bottom--small">
          <li><a href="Coach User Guides" (click)="goToItems($event, '/improve/information-technology', {folder: 3582})">Coach User Guides</a>
            <li><a href="Navigation Tutorials" (click)="goToItems($event, '/improve/information-technology', {folder: 4152})">Navigation Tutorials</a>
            <li><a href="Coach Data Timeline" (click)="goToItems($event, '/improve/information-technology', {folder: 1004994})">Coach Data Timeline</a>
          </ul>
      </div>
    </div>
    <div class="widget" data-cy="contacts_widget" *hasFeature="ContactsFeature">
      <div class="header header--slanted u-hoverEffect" [routerLink]="['../contacts']">
        <div class="titles t5">
          <div class="title">Contacts</div>
        </div>
      </div>
      <div class="dynamicBody padding--large">
        <img class="widget-illustration" src="assets/img/illustrations/SH_PEP_Illustrations_Improve_Sum_ACO_1700x1100_20221114.svg" />
        <p class="u-italic u-opacity--light margin-top--smaller">
          Welcome to {{app.CompanyName}} Health, we appreciate you and look forward to helping you on your ACO journey.
        </p>
        <ul class="margin-top--large margins-bottom--small">
          <li><a href="My Signify Team" (click)="goToItems($event, '/improve/contacts')">My Signify Health Team</a>
          <li><a href="Client Contacts" (click)="goToItems($event, '/improve/contacts')">Client Contacts</a></li>
          <li><a href="Clinician Roster" (click)="goToItems($event, '/improve/contacts')">Clinician Roster</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
