<button class="pullTab top--xs disable-rotate" (click)="toggleListFilters()" [ngClass]="{
        'is-open': isListFiltersOpen,
        'is-closed': !isListFiltersOpen
    }" aria-label="Toggle filter panel">
    <coach-icon *ngIf="isListFiltersOpen" iconName="chevrons-left"></coach-icon>
    <coach-icon *ngIf="!isListFiltersOpen" iconName="chevrons-right"></coach-icon>

</button>
<div class="header">
    <div
        class="title u-display--flex u-flex-direction--row u-justify-content--spaceBetween padding--none u-width--full">
        <div class="title title-tooltip-wrapper">340B Worklists</div>
        <div class="tooltip-wrapper">
            <!-- <button class="button is-small is-icon border--none has-tooltip u-position--fixed"
                (click)="openNewListDrawer()">
                <span class="tooltip">Create a custom list</span>
                <coach-icon class="transition-fix" iconName="plus" iconColor="currentColor">
                </coach-icon>
            </button> -->
        </div>
    </div>
</div>
<div class="tabs--fullView tabs tabs--secondary u-display--flex u-flex-direction--row">
    <div class="tab" [ngClass]="{'is-active': activeTab == 'standard', 'u-disabled': isDrillInOpen}"
        (click)="switchListsActiveTab('standard')">Standard</div>
    <!-- <div class="tab" [ngClass]="{'is-active': activeTab == 'custom', 'u-disabled': isDrillInOpen}"
        (click)="switchListsActiveTab('custom')">Custom</div> -->
</div>
<div class="column u-fill u-position--relative">
    <div class="dynamicBody">
        <div class="list">
            <ng-container *ngFor="let list of allLists">
                <div class="list-item list-item--complex formatting--none"
                    [ngClass]="{'is-active': list === selectedList}" (click)="selectList(list)">
                    <div class="list-item-main">
                        <div class="row">
                            <div class="field margin-right--smallest">{{list.name}}</div>
                        </div>
                    </div>
                    <div class="list-item-action u-cursor--pointer" (click)="toggleDrillInOpen(list)">
                        <coach-icon class="transition-fix" iconName="chevron-right" iconColor="currentColor">
                        </coach-icon>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="drillIn--queue" [ngClass]="{'is-open': isDrillInOpen}">
        <div class="header u-align-items--center">
            <button class="button is-small is-icon border--none" (click)="toggleDrillInOpen(selectedList)">
                <coach-icon iconName="chevron-left" iconColor="default"></coach-icon>
            </button> 
            <div class="titles t5">
                <div class="field margin-right--smallest">{{selectedList?.name}}</div>
            </div>
        </div>
        <coach-care-prescriptions-filters 
            *ngIf="filterOptions && gridApi && !loadingStatus"
            [configData]="filterOptions" 
            [gridApi]="gridApi" 
            [UIUpdates340BFeatureFlag]="true">
        </coach-care-prescriptions-filters>
        <div *ngIf="loadingStatus" class="u-display--flex u-justify-content--center">
            <coach-loading-indicator size="40"></coach-loading-indicator>
        </div>

    </div>
</div>