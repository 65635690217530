import moment from 'moment';

export class WorkflowDateTimeUtil {

  public static dateTimeStringToDateOnlyString(dateTime: string): string {
    if (!dateTime){
      return '';
    }
    return moment(dateTime).format('yyyy-MM-DD');
  }

  public static dateTimeStringToUSDateOnlyString(dateTime: string): string {
    return moment(dateTime).format('MM/DD/yyyy');
  }

  public static todayDateOnlyString(): string {
    return moment().format('yyyy-MM-DD');
  }

  public static dateTimeStringToMonthDateFormat(dateTime: string): string {
    return moment(dateTime).format('MM/DD');
  }

  public static todayToMonthDateFormat(): string {
    return moment().format('MM/DD');
  }

  public static minNextAwvDate(scheduledOrCompletedDate?: string): string {
    const date = scheduledOrCompletedDate ? moment(scheduledOrCompletedDate) : moment();
    return date.add(1, 'year').startOf('month').format('yyyy-MM-DD');
  }

  public static maxNextAwvDate(scheduledOrCompletedDate?: string): string {
    const date = scheduledOrCompletedDate ? moment(scheduledOrCompletedDate) : moment();
    return date.add(2, 'year').startOf('month').format('yyyy-MM-DD');
  }
}
