import {Component, Input, SimpleChanges} from '@angular/core';
import {Patient} from '@shared/models/patient';

@Component({
  selector: 'coach-patient-last-awv-details',
  templateUrl: './patient-last-awv-details.component.html'
})
export class PatientLastAwvDetailsComponent {
  @Input() public patient: Patient;

  lastAwvDate: string;

  ngOnChanges(_: SimpleChanges): void {
    if (this.patient) {
      this.lastAwvDate = null;

      if (this.patient.trackAwvPatient) {
        this.lastAwvDate = this.patient.trackAwvPatient.lastAWVDate;
      } else if(this.patient.trackPatient) {
        this.lastAwvDate = this.patient.trackPatient.lastAWVDate;
      }
    }
  }
}
