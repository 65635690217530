<div class="u-position--relative queue u-preserve u-height--full border-right"
    [ngClass]="isOpen ? 'is-open' : 'is-closed'">
    <coach-toggle class="pullTab top--md" (onToggle)="paneToggled()" [toggle]="isOpen" [toggledOpenDefault]="toggledOpenDefault" [iconName]="iconName" [iconRotates]="iconRotates"></coach-toggle>
    <div class="header u-preserve">
        <ng-content select="[pane-header]"></ng-content>
    </div>
    <div class="column u-fill u-position--relative">
        <div class="dynamicBody pane-body margin--smaller padding--smaller">
            <ng-content select="[pane-body]"></ng-content>
        </div>
    </div>
</div>