<ng-container *ngIf="showModal" style="font-size: x-small">
  <coach-sub-fill-dialog [showDialog]="showSubFillDialog" [dialogMessage]="'Do you wish to approve all subsequent fills for this claim?

  '" (buttonClicked)="onSubFillButtonClicked($event)"></coach-sub-fill-dialog>

  <div class="overlay is-open">
    <div class="modal u-width--larger">
      <div class="header">
        <div class="titles">
          <div class="title" style="text-align: center">
            Approve Rx
          </div>
          <div class="subtitle" style="text-align: center">
            Paste text into EMR and then approve
          </div>
        </div>
      </div>
      <div class="dynamicBody padding" #noteContent>
        <strong><u>CARE COORDINATION NOTE</u></strong><br />
        Patient Name: {{ patientName }}<br />
        DOB: {{ patientDobText }}<br />
        MBI: {{ patientMbi | mask: 6 }}<br />
        By: {{ creatorName }}<br />
        Date: {{ formatDate(creatorDate) }}<br /><br />


        <u>340B External Prescription</u><br />
        <strong>{{ selectedPatientInfo.prescriptionName }}</strong><br />
        by {{ selectedPatientInfo.prescriberName }}<br />
        First filled {{ formatDate(selectedPatientInfo.firstFilled)
        }}<br /><br />

        <u>Visit Note from {{ selectedPatientInfo.prescriberName }}</u><br />

        <div>
          <p>
            Visit Date: {{ formatDate(prescriptionInfo.visit_Note_Visit_Date)
            }}<br />
            Rx Diagnosis: {{ prescriptionInfo.visit_Note_Diagnosis }}
          </p>
        </div>

        <div>
          <p style="text-decoration: underline; margin-bottom: 1px">
            Related Visit By Covered Entity:
          </p>
          <span>
            Visit Date: {{ formatDate(prescriptionInfo.cE_Visit_Date) }}<br />
            Provider: {{ prescriptionInfo.cE_Provider }}<br />
            CE Location: {{ prescriptionInfo.cE_Location }}<br />
          </span>
        </div>
        <br />

        <div>
          <p style="text-decoration: underline; margin-bottom: 1px">
            Related Referral By Covered Entity:
          </p>
          <span>
            Referral Date:
            {{ formatDate(prescriptionInfo.cE_Related_Referral_Date) }}<br />
          </span>
        </div>
        <br />

        <div>
          <p style="text-decoration: underline; margin-bottom: 1px">
            Follow-Up Plan:
          </p>
          <span [innerHTML]="formattedFollowUpPlan"><br /> </span>
        </div>
        <br />
      </div>
      <div class="footer" style="
          background-color: #eee;
          justify-content: space-around;
        ">
        <button class="border--none" (click)="toggleVisitNoteModal()">
          Cancel
        </button>
        <button class="button border--4" (click)="copyNote()">Copy Text</button>
        <button class="button is-primary" (click)="toggleSubFillDialog()">Approve</button>
      </div>
    </div>
  </div>
</ng-container>