<div class="submitFormBlock clump border--4 u-background-color--white"
  [ngClass]="{ 'ideal--low': formQuestion?.isLowPointsIdeal }">
  <div class="submitFormBlock-header">
    <div class="row">
      <h6 class="margin--none u-fill u-opacity--light">
        {{ formQuestion?.descText }}
      </h6>
      <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small" iconColor="primary"
        (click)="toggleSubmitFormInfoText()"></coach-icon>
    </div>
    <div class="u-bold margin-top--smallest">
      {{ formQuestion?.questionText }}
    </div>
  </div>
  <div class="submitFormBlock-main row">
    <div class="submitFormBlock-inputs" [formGroup]="qualityForm">
      <div class="compliance-form--answer" [formGroupName]="formGroupName">
        <ng-container formArrayName="FormQResponses">
          <ng-container [formGroupName]="1">
            <input type="number" pattern="\d*" formControlName="ResponsePointValue" placeholder="Num" min="0"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" max="{{
                qualityForm.controls[formGroupName].get('FormQResponses.2.ResponsePointValue').value
                  ? qualityForm.controls[formGroupName].get('FormQResponses.2.ResponsePointValue').value
                  : 999
              }}" (blur)="toggleErrorTexts(1)" />
          </ng-container>

          <div class="divider"></div>

          <ng-container [formGroupName]="2">
            <input type="number" pattern="\d*" formControlName="ResponsePointValue" placeholder="Den" min="0"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
              (blur)="toggleErrorTexts(2)" />
          </ng-container>
          <ng-container [formGroupName]="0">
            <input type="number" formControlName="ResponsePointValue" placeholder="Pts" min="0" hidden />
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="submitFormBlock-graphic">
      <div class="row--baseline u-justify-content--center">
        <h2 class="margin--none">
          {{qualityForm.controls[formGroupName].get("FormQResponses.0.ResponsePointValue").value
          || 0}}
        </h2>
        <div class="u-font-size--large u-font-weight--light">&nbsp;pts</div>
      </div>
      <div class="barGraph margin-top" attr.data-percentage="{{ percentRoundedGraph }}"
        style="--bar-graph--percentage: {{ percentRoundedGraph }}%">
        <div class="bar"></div>
      </div>
      <div class="submitFormBlock-graphic-labels">
        <div *ngIf="formQuestion?.isLowPointsIdeal" class="submitFormBlock-graphic-labels-better u-color--success">
          Better
        </div>
        <div *ngIf="percentRounded !== null" class="submitFormBlock-graphic-labels-percentage">
          {{ percentRounded || 0 }}%
        </div>
      </div>
    </div>
  </div>
  <div class="submitFormBlock-alerts u-italic u-color--danger padding-left--smaller paddings-top--small"
    *ngIf="errors && errors?.length">
    <div *ngFor="let error of errors">{{ error }}</div>
  </div>
</div>

<!-- // TODO: pull out into shared component. -->
<div class="overlay overlay--light is-open" *ngIf="isInfoTextOpen"
  (click)="toggleSubmitFormInfoText(); $event.stopPropagation()">
  <div class="modal margin u-max-width--larger" (click)="$event.stopPropagation()">
    <div class="header u-align-items--center">
      <div class="titles">
        <div class="title" title="{{ formQuestion?.questionText }}">
          {{ formQuestion?.questionText }}
        </div>
      </div>
      <button class="button is-icon border--none" (click)="toggleSubmitFormInfoText(); $event.stopPropagation()">
        <coach-icon iconName="x" iconColor="default"></coach-icon>
      </button>
    </div>
    <div class="dynamicBody padding" [innerHtml]="formQuestion?.infoText"></div>
    <div class="footer">
      <button class="button is-primary" (click)="toggleSubmitFormInfoText(); $event.stopPropagation()">
        Close
      </button>
    </div>
  </div>
</div>
