import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import {AwvWorkflowService} from '@shared/services/awv-workflow/awv-workflow.service';
import {BackendError} from '@shared/services/awv-workflow/backend-error';

@Component({
  selector: 'coach-patient-awv-inline-error',
  templateUrl: './patient-awv-inline-error.component.html',
  styleUrls: ['./patient-awv-inline-error.component.scss']
})
export class PatientAwvInlineErrorComponent implements OnInit{
  backendError: BackendError;
  constructor(private awvWorkflowService: AwvWorkflowService, private elementRef: ElementRef) {
  }
  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.awvWorkflowService.backendError$.subscribe((error: BackendError) => {
      // need this check so that the component is only displayed for the open form
      if (element.offsetParent !== null) {
        this.backendError = error;
      }
    } );
  }
}
