<ag-grid-angular
  
  coachAgGridResize
  row-animation
  style="width: 100%; height: 100%"
  class="ag-theme-material"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [overlayNoRowsTemplate]="loadingStatus?'Loading':'No data'"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="onFirstDataRendered($event)"
  (rowClicked)="onRowClicked($event)"
  [pagination]="true"
  [paginationAutoPageSize]="true"
  [rowSelection]="'multiple'"
  [rowMultiSelectWithClick]="true"
  (selectionChanged)="onSelectionChanged($event)"
  (columnMoved)="onColumnUpdated()"
  (sortChanged)="onColumnUpdated()"
  (filterChanged)="agFilterChanged($event)"
  [localeText]="localeText"
  [suppressRowClickSelection]="true"
>
</ag-grid-angular>
<!-- <div class="row--right" style="padding: 6px">
  <button class="button is-small" (click)="onExportClick($event)">
    Export
  </button>
</div> -->