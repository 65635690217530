import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { environment } from 'src/environments/environment';
import { HccDashboardCodingOpportunity } from './hcc-dashboard-coding-opportunity';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HccDasbhoardCodingOpportunitiesService extends AzureApiBase<HccDashboardCodingOpportunity> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/hccDashboard/opportunities');
  }

  getOpportunites(tierNum: number, tierId: string): Observable<HccDashboardCodingOpportunity[]> {
    const params: HttpParams = new HttpParams()
      .set('tierNum', tierNum.toString())
      .set('tierId', tierId);

    return this.getAll(null, params);
  }
}
