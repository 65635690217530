import { ChangeDetectorRef, Injectable } from '@angular/core';

import { PatientViewModel } from '@shared/models/patient';

import { IPatientFilterPreset } from '@shared/models/patient-filters';
import _ from 'lodash';
export class FilterSettings {
  rows: AgGridTableFilterSetting[];
}
import {
  AgGridTableFilterSetting,
  filterSettings
} from '@care/views/care-patients/care-patients-filters/filter-settings';
export const AG_GRID_LOCALE_CARE = {
  // overwrites agGrid wordings (CC-2939)
  selectAll: 'All',
  blanks: 'None',
  empty: 'Unset'
};
import { AgGridLocalSettingsService } from './ag-grid/ag-grid.service';
import { AwvWorklistService } from '@care/components/awv-worklist/awv-worklist.service';
import { CarePatientItem } from '@shared/models/module-constants';
import { GridApi, ColumnApi } from 'ag-grid-community';
@Injectable({
  providedIn: 'root'
})
export class FilterDataService {
  constructor(
    private agGridLocalSettingsService: AgGridLocalSettingsService,
    private awvWorklistService: AwvWorklistService
  ) {}

  // ====================== FILTER SETTINGS ========================

  updateFilterSettings(
    selectedPreset: IPatientFilterPreset,
    patients: PatientViewModel[],
    gridApi: GridApi,
    useDefaultFilterModel?: boolean
  ): void {
    if (selectedPreset?.filterSettings?.rows && patients?.length) {
      selectedPreset.filterSettings.rows = this.agGridLocalSettingsService.updateFilterSettings(
        selectedPreset,
        patients,
        useDefaultFilterModel,
        gridApi.getFilterModel()
      );
    }
  }

  async applyFilterSettings(
    selectedPreset: IPatientFilterPreset,
    filterSettings: FilterSettings,
    gridApi: GridApi,
    keepPreviousFilterModel: boolean,
    ignoreDefaultAndCurrentFilterModels?: boolean
  ) {
    // Get filter from local storage or from the defaultFilterModel, on clear() default is skipped
    let currentFilterModel = gridApi?.getFilterModel() || {};
    //Column Defs is not able to grab and this is causing the filter to not get applied not sure why
    if (
      (!keepPreviousFilterModel || !Object.keys(currentFilterModel)?.length) &&
      selectedPreset.defaultFilterModel &&
      !ignoreDefaultAndCurrentFilterModels
    ) {
      currentFilterModel = { ...currentFilterModel, ...selectedPreset.defaultFilterModel };
    }
    if (filterSettings && gridApi?.getColumnDefs()?.length > 0) {
      selectedPreset.filterSettings = filterSettings;
      this.agGridLocalSettingsService.applyFilterSettings(
        filterSettings?.rows,
        gridApi,
        keepPreviousFilterModel,
        ignoreDefaultAndCurrentFilterModels ? null : currentFilterModel
      );
    }
  }

  async applyDefaultFilterModel(selectedPreset: IPatientFilterPreset, gridApi: GridApi) {
    if (
      !selectedPreset.defaultFilterModel ||
      !gridApi?.getFilterModel() ||
      Object.keys(gridApi?.getFilterModel())?.length
    ) {
      return;
    }
    // check if there's no default stored filter model
    // if object exists (even empty object), then stop, otherwise apply default filter model
    let storedFilterModel = await this.agGridLocalSettingsService.getStoredFilterModelFromLocalStorage(
      selectedPreset.category,
      selectedPreset.name
    );
    if (!storedFilterModel) {
      this.setFilterModel(selectedPreset.defaultFilterModel, gridApi);
    }
  }

  async updatePresetFilterSettings(
    selectedPreset: IPatientFilterPreset,
    newFilterSettings: FilterSettings,
    gridApi: GridApi
  ) {
    // This is not used
    selectedPreset.filterSettings = newFilterSettings;
    await this.applyFilterSettings(selectedPreset, selectedPreset?.filterSettings, gridApi, true, true);
    await this.storeFilterModelAndMatchFilterSettings(selectedPreset, gridApi);
  }

  /**
   * Helper function check if all items are selected on a specified column
   * needed to compare it with ag-grid applied filter since it will not return any data
   * @param colId - the column id we need the data for
   * @param gridApi -
   * @param possibleValues - the possible values for that column
   * @returns: an object with 2 types isAllSelected: boolean, size:number
   */
  checkIfAllItemsSelected(
    colId: string,
    gridApi: GridApi,
    possibleValues: string[]
  ): { isAllSelected: boolean; size: number } {
    let filteredRowsData = [] as string[];

    gridApi.forEachNodeAfterFilter(node => {
      if (filteredRowsData.indexOf(node.data[colId]) === -1)
        // unique only
        filteredRowsData.push(node.data[colId]);
    });

    if (possibleValues.length === filteredRowsData.length) {
      // all unique items selected
      return {
        isAllSelected: true,
        size: possibleValues.length
      };
    } else {
      return {
        isAllSelected: false,
        size: possibleValues.length
      };
    }
  }

  /**
   * Helper function to get all unique values for a column
   * @param colId - the column id we need the data for
   * @param gridApi -
   * @param columnApi
   * @returns: a string array of unique column values
   */
  getValuesForColumn(colId: string, gridApi: GridApi, columnApi: ColumnApi): string[] {
    var values = [];
    gridApi.forEachNode(rowNode => {
      var value = rowNode.data[colId];
      let column = columnApi.getColumn(colId);
      let colDef = column.getColDef();
      let valueGetter = colDef.valueGetter;
      if (typeof valueGetter === 'function') {
        // If there is a valueGetter func definied in columnDefinitions ex: api input ['Y', 'N'] after ['YES', 'NO']
        const inputValue = { [colId]: value };
        const params = {
          data: inputValue,
          node: rowNode,
          colDef: colDef,
          api: gridApi,
          columnApi: columnApi,
          context: null,
          column: column,
          getValue: (colId: any) => rowNode.data[colId]
        };
        value = valueGetter(params);
      }

      if (values.indexOf(value) === -1) {
        // Unique only
        values.push(value);
      }
    });
    return values;
  }

  /**
   * On inputed defaultFilterModel removes not needed columns and/or sub-values on columns
   * Handles these scenarios:
   *   - If the column exists in the defaultFilterModel but it is removed in the columnDefinitions or behind a featureFlag
   *   - If the data returned to the grid is empty
   *   - If All the values are selected on a specific column and ag-grid appliedFilters will not detect a change
   *   - If a suppressSelectAll:true is set on the columnDefinitions and the column is missing the 'All' value
   *   - If all values are selected on specific column and ag-grid appliedFilters doesn't detect it as a change
   * @param dfm - the defaultFilterModel comming in from columnPresets
   * @param gridApi - ag-grid api
   * @param columnApi - ag-grid columnApi to get access to the columns
   * @returns: a parsed down defaultFilterModel that has specific items removed
   */
  cleanDefaultFilterModel(dfm: { [key: string]: any }, gridApi: GridApi, columnApi: ColumnApi) {
    const cleanedDFM: { [key: string]: any } = {};
    for (let key in dfm) {
      columnApi?.getColumns().forEach(col => {
        if (key === col.getColId()) {
          // if the column is visible in the grid
          const possibleValues = this.getValuesForColumn(key, gridApi, columnApi);
          const colDef = col.getColDef();
          if (colDef.filterParams?.suppressSelectAll && possibleValues.length === 0) return; // suppressSelectAll in columnDefinitions prevents showing 'All' as values

          cleanedDFM[key] = _.cloneDeep(dfm[key]); // copy the column from dfm to the cleanedDFM

          const cleanedDFMValues = cleanedDFM[key].values;
          if (cleanedDFMValues) {
            // filter the Default Filter Model values to have only the ones that are possible: are returned in the grid
            const filteredValues = cleanedDFMValues.filter((value: string) => possibleValues.includes(value));
            cleanedDFM[key].values = filteredValues;
            // check for scenario where all items are selected and gridApi?.getFilterModel() will return empty
            const objAllItemsSelected = this.checkIfAllItemsSelected(key, gridApi, possibleValues);
            if (objAllItemsSelected.isAllSelected) {
              if (cleanedDFM[key].values.length === objAllItemsSelected.size) {
                delete cleanedDFM[key];
              }
            }
          }
        }
      });
    }
    return cleanedDFM;
  }

  /**
   * Decides whether to show the "filter applied" and "clear" elements
   * in the UI by updating the view model property filterAppliedBool
   * @param selectedPreset - We get the defaultFilterModel from the selectedPreset
   * @param gridApi - We use it to get the applied filters on the grid
   * @param columnApi - To get allColumns in the grid, used in the parseDefaultFilterModel func
   * @param AWVPatientsStatus - Used in AWV Button selection, comes from the url on specific button pressed. ex: AWVPatientsStatus=ReadyToSchedule
   * @param fromDate - AWV side menu fromDate; used to detect change from default value 1901-01-01 to display clear button on ag-grid
   * @returns: True if the grid aplied filters are different from the defaultFilterModel, False if they are the same
   */
  checkIfFilterIsApplied(selectedPreset: IPatientFilterPreset, gridApi: GridApi, columnApi: ColumnApi): boolean {
    if (selectedPreset.name == CarePatientItem.AwvOpportunities && this.awvWorklistService.hasDateChanged())
      return true;

    const appliedFilters = gridApi?.getFilterModel();
    if (!appliedFilters) return false; // disable checks on loading and empty filterModel...

    if (selectedPreset?.defaultFilterModel) {
      const dfm = selectedPreset?.defaultFilterModel;
      const matchingDFM = this.cleanDefaultFilterModel(dfm, gridApi, columnApi);
      if ('allAWVPatientsListStatus' in matchingDFM && !('allAWVPatientsListStatus' in appliedFilters)) {
        // AWV Button check
        delete matchingDFM['allAWVPatientsListStatus'];
      }

      return !_.isEqual(matchingDFM, appliedFilters);
    }
    return Object.keys(appliedFilters)?.length > 0;
  }

  matchFilterSettingsWithCurrentFilterModel(
    selectedPreset: IPatientFilterPreset,
    gridApi: GridApi,
    shouldStoreFilterModel?: boolean
  ): void {
    let updatedFilterSettings = this.agGridLocalSettingsService.matchAndUpdateFilterSettings(
      selectedPreset.filterSettings,
      gridApi
    );
    if (selectedPreset.filterSettings && gridApi && updatedFilterSettings) {
      selectedPreset.filterSettings = updatedFilterSettings;
      if (shouldStoreFilterModel) {
        this.storeFilterSettings(selectedPreset);
      }
    }
  }
  storeFilterSettings(selectedPreset: IPatientFilterPreset) {
    this.agGridLocalSettingsService.storeFilterSettingsInLocalStorage(
      selectedPreset.category,
      selectedPreset?.presetKey,
      selectedPreset.filterSettings
    );
  }
  getNonActiveColumnsBetweenPresetAndFilterModel(
    columns: Array<{ [key: string]: boolean | string }>,
    filterModel: { [key: string]: any }
  ): string[] {
    let columnNames: string[] = [];
    for (let key in filterModel) {
      if (!columns.find(column => key in column)) {
        columnNames.push(key);
      }
    }
    return columnNames;
  }
  async storeFilterModelAndMatchFilterSettings(preset: IPatientFilterPreset, gridApi: GridApi) {
    // before it make sure to call this.changeDetectorRef.detectChanges()
    if (!preset.temporary && gridApi?.getColumnDefs().length > 0) {
      let filterModel = gridApi.getFilterModel();
      // CC-4491: need to check whether there are non-active columns that we need to keep in filter model.
      // Some of care management presets have different list of columns, but share the same filter model settings.
      // Here we need to find columns that are not available on the current preset,
      // but still want to keep them for the right care management preset because those columns are going to be removed by storing current filter model.
      // Confused?
      // For example: presetA has columns ['a', 'b'], while presetB has ['a', 'b', 'c'], both share the same filter settings.
      // Shared filter model between both presets has columns "a" to equal "123" and  "c" to be "321": [{ a: { equalsTo: 123 } },{ c: { equalsTo: 321 } }].
      // When user switches to presetA, the logic pulls stored filter model that has column "c",
      // and before it applies stored filter model, it removes column "c" (by finding that column name from getNonActiveColumnsBetweenPresetAndFilterModel() result).
      // Then, on any filter change on presetA, filter model would be like [{a: {equalsTo: 111}}], but on filter storing process we going to lose column "c" values,
      // and that's what we need to prevent. While filter model is [{a: {equalsTo: 111}}], get stored filter model,
      // find columns that are in stored filter model and not in current preset (by calling getNonActiveColumnsBetweenPresetAndFilterModel()).
      // That should get us column "c". After that, merge new filter model with stored filter that has only non-active column:
      // new filter model is [{a: {equalsTo: 111}}], stored filter model [{ a: { equalsTo: 123 } },{ c: { equalsTo: 321 } }], make it equal to [{ a: { equalsTo: 111 } },{ c: { equalsTo: 321 } }].
      let storedFilterModel = await this.agGridLocalSettingsService.getStoredFilterModelFromLocalStorage(
        preset.category,
        preset?.presetKey
      );
      let nonActiveColumns = this.getNonActiveColumnsBetweenPresetAndFilterModel(preset.columns, storedFilterModel);
      for (let column of nonActiveColumns) {
        filterModel[column] = storedFilterModel[column];
      }

      this.agGridLocalSettingsService.storeFilterModelInLocalStorage(preset.category, preset.presetKey, filterModel);
      if (preset.filterSettings?.rows) {
        for (let r in preset.filterSettings.rows) {
          let row: AgGridTableFilterSetting = preset.filterSettings.rows[r];
          let newFilterValues = filterModel[row.name];
          if (newFilterValues) {
            if (newFilterValues.values) {
              preset.filterSettings.rows[r].excluded = row.options.filter(
                option => !newFilterValues.values.includes(option == 'None' ? null : option)
              );
            } else if (newFilterValues.filterType == 'date' || newFilterValues.filterType == 'number') {
              preset.filterSettings.rows[r] = { ...row, ...newFilterValues };
            }
          } else {
            if (preset.filterSettings.rows[r].excluded) {
              preset.filterSettings.rows[r].excluded = [];
            } else {
              preset.filterSettings.rows[r].dateFrom = null;
              preset.filterSettings.rows[r].dateTo = null;
              preset.filterSettings.rows[r].filter = null;
              preset.filterSettings.rows[r].filterTo = null;
            }
          }
        }
        this.agGridLocalSettingsService.updateCurrentFilterSettingsObservable(preset.filterSettings?.rows);
      }
    }
  }
  async applyStoredFilterModel(preset: IPatientFilterPreset, gridApi: GridApi, filterModel?: { [key: string]: any }) {
    let storedFilterModel =
      filterModel ||
      (await this.agGridLocalSettingsService.getStoredFilterModelFromLocalStorage(preset.category, preset?.presetKey));
    // CC-4491: need to remove missed columns from new filter model before applying it
    let nonActiveColumns = this.getNonActiveColumnsBetweenPresetAndFilterModel(preset.columns, storedFilterModel);
    for (let column of nonActiveColumns) {
      delete storedFilterModel[column];
    }
    this.setFilterModel(storedFilterModel, gridApi);
  }
  setFilterModel(filterModel: { [key: string]: any }, gridApi: GridApi): void {
    // before it call this.changeDetectorRef.detectChanges()
    // this.changeDetectorRef.detectChanges();
    gridApi.setFilterModel(filterModel);
  }
  resetFilterSettings(preset: IPatientFilterPreset): void {
    preset.filterSettings?.rows?.forEach((row, idx) => {
      if (row.type == 'set') {
        row.excluded = [];
      } else {
        row.filter = null;
        row.filterTo = null;
        row.type = 'unset';
        row.dateFrom = null;
        row.dateTo = null;
      }
    });
  }
}
