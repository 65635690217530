import { formatDate } from '@angular/common';
import {removeLeadingZeros, mapOverallOptionsToStrings} from '../care-prescriptions/shared-lib';
import {formatDate as fmtDate, statusSortPriorityMap} from '../care-prescriptions/shared-lib';
import { compareString, compareStringPlain, compareNumber, compareRiskTrend, compareRiskCategory, compareDate} from "@shared/components/grid/grid-functions"

import moment from 'moment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { ColDef } from 'ag-grid-community';


export const COLUMN_DEFS: Array<ColDef> = [
  {
    headerName: 'STATUS',
    field: 'status',
    maxWidth: 128,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellRenderer: (data) => {
      return mapOverallOptionsToStrings(data.data?.status ?? -1);
    },
    comparator: (s1, s2) => {
      let sx1 = statusSortPriorityMap.get(s1);
      let sx2 = statusSortPriorityMap.get(s2);
      return sx1 - sx2;
    },
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'NAME',
    field: 'patientName',
    maxWidth: 200,
    comparator: compareString,
    resizable: true,
    filter: 'agSetColumnFilter',
    sortable: true,
    cellRenderer: (data: any): string => {
      return data.data?.patientName;
    },
  },
  {
    headerName: 'DOB',
    field: 'dob',
    maxWidth: 120,
    suppressAutoSize: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    sortable: true,
    cellRenderer: (data: any): string => {
      return moment(data.data?.dob).format('L');
    },
  },
  // This may come back -- DJ
  // {
  //   headerName: 'MBI',
  //   field: 'mbi',
  //   maxWidth: 170,
  //   suppressAutoSize: true,
  //   resizable: true,
  //   comparator: compareStringPlain,
  //   filter: 'agSetColumnFilter',
  //   cellRenderer: (data): string => {
  //     return mask.transform(data.data?.mbi, 6);
  //   },
  //   valueGetter: params => {
  //     return mask.transform(params.data?.mbi, 6);
  //   }
  // },
  {
    headerName: 'FIRST FILL',
    field: 'firstFillDate',
    maxWidth: 130,
    suppressAutoSize: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      return data.data?.firstFilled
        ? formatDate(new Date(data.data?.firstFilled), 'MM/dd/yy', 'en_US').trim()
        : '';
    },
    valueGetter: (data: any) => {
      let result = formatDate(new Date(data.data?.firstFilled), 'yyyy/MM/dd', 'en_US').trim();
      return result;
    },
    getQuickFilterText: params => {
      return params.value;
    },
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'FILL#',
    field: 'clPrescriptionFillNumber',
    maxWidth: 120,
    filter: 'agSetColumnFilter',
    resizable: true,
    hide: false,
    sortable: true,
    cellRenderer: (data: any): string => {
      return removeLeadingZeros(data.data?.clPrescriptionFillNumber);
    },
    valueGetter: params => {
      return params.data?.clPrescriptionFillNumber
        ? parseInt(params.data?.clPrescriptionFillNumber)
        : '';
    }
  },
  {
    headerName: 'FILL DATE',
    field: 'fillDate',
    maxWidth: 130,
    suppressAutoSize: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    hide: false,
    cellRenderer: (data): string => {
      return data.data?.firstFilled
        ? formatDate(new Date(data.data?.fillDate), 'MM/dd/yy', 'en_US').trim()
        : '';
    },
    valueGetter: (data: any) => {
      let result = formatDate(new Date(data.data?.fillDate), 'yyyy/MM/dd', 'en_US').trim();
      return result;
    },
    getQuickFilterText: params => {
      return params.value;
    },
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'PRESCRIPTION',
    field: 'prescriptionName',
    resizable: true,
    sortable: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      return data.data?.prescriptionName;
    },
  },
  {
    headerName: 'PRESCRIBER',
    field: 'prescriberName',
    resizable: true,
    sortable: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      return data.data?.prescriberName;
    },
  },
  {
    headerName: 'RX NUMBER',
    field: 'rxNumber',
    maxWidth: 120,
    suppressAutoSize: true,
    sortable: true,
    filter: 'agSetColumnFilter',
    // hide: true,
    valueGetter: params => {
      return params.data.rxNumber;
    }
  },
  {
    headerName: 'PHARMACY',
    field: 'pharmacyName',
    resizable: true,
    sortable: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      return data.data?.pharmacyName;
    },
    valueGetter: data => {
      return data.data?.pharmacyName;
    }
  },
  {
    headerName: 'PHARMACY (OTHER)',
    field: 'pharmacyNameOther',
    resizable: true,
    sortable: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      return data.data?.pharmacyNameOther;
    },
    valueGetter: data => {
      return data.data?.pharmacyNameOther;
    }
  },
  {
    headerName: 'PHARMACY ADDRESS',
    field: 'pharmacyAddress',
    resizable: true,
    sortable: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      return data.data?.pharmacyAddress;
    },
    valueGetter: data => {
      return data.data?.pharmacyAddress;
    }
  },
  {
    headerName: 'FIRST FILLED HIDDEN',
    field: 'firstFilledHidden',
    maxWidth: 120,
    suppressAutoSize: true,
    hide: true,
    valueGetter: params => {
      return fmtDate(params.data.firstFilled);
    }
  },
  {
    headerName: 'NPI HIDDEN',
    field: 'npi',
    maxWidth: 120,
    suppressAutoSize: true,
    hide: true,
    valueGetter: params => {
      return params.data.npi;
    }
  },
  {
    headerName: 'Price',
    field: 'tpaPrice',
    resizable: true,
    sortable: true,
    comparator: compareNumber,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      const price = data.data?.claimType === 'CMS'
         ? `$${data.data?.awpPrice.toFixed(2)}`
         : `$${data.data?.tpaPrice.toFixed(2)}`;
      return price;
    },
    valueGetter: data => {
      const price = data.data?.claimType === 'CMS'
         ? +data.data?.awpPrice.toFixed(2)
         : +data.data?.tpaPrice.toFixed(2);
      return price;
    }
  },
  {
    headerName: 'Claim Source',
    field: 'tpaName',
    minWidth: 120,
    suppressAutoSize: true,
    hide: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
  {
    headerName: 'Drug Manufacturer',
    field: 'drugManufacturer',
    minWidth: 120,
    suppressAutoSize: true,
    hide: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
];
