import { Component, OnInit } from '@angular/core';
import { Module } from '@shared/services/graphql/module.service';
import { AccessGQL } from '@shared/services/graphql/access.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import { ImproveFeatureConstants } from '@shared/models/module-constants';
import { Observable } from 'rxjs';

const {
  ContactsFeature,
  PracticeImprovementFeature,
  InformationTechnologyFeature,
} = ImproveFeatureConstants;

@Component({
  selector: 'coach-improve',
  templateUrl: './improve.component.html',
  host: {
    class: 'coach-improve',
  },
})
export class ImproveComponent implements OnInit {
  public module: Observable<Module>;

  public tabsData = [
    {
      name: 'Summary',
      routerLink: ['summary'],
      featureId: null,
    },
    {
      name: 'Practice Improvement',
      routerLink: ['practice-improvement'],
      featureId: PracticeImprovementFeature,
    },
    {
      name: 'Information Technology',
      routerLink: ['information-technology'],
      featureId: InformationTechnologyFeature,
    },
    {
      name: 'Contacts',
      routerLink: ['contacts'],
      featureId: ContactsFeature,
    },
  ];

  public currentTier: IHierarchyTier;

  constructor(
    private accessGQL: AccessGQL,
    private hierarchyTierService: HierarchyTierService,
    private fileDownloadService: FileDownloadService
  ) {}

  ngOnInit(): void {
    this.getTier();
  }

  getTier(): void {
    this.hierarchyTierService.currentTier$?.subscribe((x) => {
      this.currentTier = x;
      this.fileDownloadService.fetchDocRepoList(x.selectedTierId);
      this.getAccess();
    });
  }

  getAccess(): void {
    //This module subscribes directly to the Gql API
    //TODO - replace it with the UserAccessService

    if (this.currentTier !== null) {
      this.accessGQL
        .watch(
          { nodeId: this.currentTier.selectedTierId },
          { fetchPolicy: 'cache-first' }
        )
        .valueChanges.subscribe(({ data }) => {
          const ourModule =
            data?.access?.modules?.filter((m) => m.name === 'Transform') || [];
          const featureIds: number[] = (ourModule[0]?.features || []).map(
            (feature) => feature.featureId
          );
          this.tabsData = this.tabsData.filter(
            (tab) =>
              tab.featureId === null || featureIds.indexOf(tab.featureId) >= 0
          );
        });
    }
  }
}
