<div class="row--wrap row-header">
    <div class="span--4 u-font-size--small">
        <button class="button" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="previous()">Previous</button>
    </div>
    <div class="span--4 u-font-size--large " style="font-size: 2.5rem">
        {{viewDateHeader}}
        <!-- {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }} -->
    </div>
    <div class="span--4 u-font-size--small ">
        <button class="button float-right" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="next()">Next</button>
    </div>
</div>
<div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-month-view>
</div>


<ng-container *ngIf="showModalSurvey">
    <div class="overlay is-open" [style.bottom]="cssBottom">
        <div class="modal u-width--larger u-width--extralarge">

            <div class="survey-holder" *ngFor="let sr of surveys; let i=index">
                <div class="header">
                    <div class="titles">
                        <div class="title">{{sr.name}}
                        </div>
                        <div class="subtitle row">
                            <div class="double-column">
                                {{ordinal(i+1)}} survey
                            </div>
                            <div class="column">
                                {{selectedDay}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dynamicBody padding">
                    <div class="panel-body">
                        <div class="question row" *ngFor="let line of sr.lines">
                            <div class="question-text double-column">
                                <div class="line">{{line.question}}
                                </div>
                            </div>
                            <div class="response-text column">
                                <div class="line">{{line.answer}}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div *ngIf="showEmptyDayMessage">
                <div class="header">
                    <div class="titles">
                        <div class="title">No surveys completed on this date
                        </div>
                        <div class="subtitle row">
                            <div class="double-column">
                            </div>
                            <div class="column">
                                {{selectedDay}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="footer">
                <button class="button margin-right border--none" (click)="copyMessage('This goes to Clipboard')"
                    value="click to copy">Copy to clipboard</button>
                <button class="button is-primary" (click)="closeSurvey()">OK</button>
            </div>
        </div>
    </div>
</ng-container>