import { Component, OnInit, HostListener, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { HccPatientDetailService } from './hcc-patient-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HccDashboardPatientDetail } from './hcc-dashboard-patient-detail';
import { formatDate, Location, formatNumber } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { GridFilterService, PATIENT_DETAIL_REPORT_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { Subject } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';

@Component({
  selector: 'coach-patient-detail-report',
  templateUrl: './patient-detail-report.component.html',
  host: {
    class: 'coach-patient-detail-report'
  },
  providers: [MaskPipe]
})
export class PatientDetailReportComponent extends PersistentGridFilters implements OnInit, OnDestroy {
  columnDefs = [
    { headerName: 'Clinician Name', field: 'providerName', sortable: true, filter: true, tooltipField: 'providerName', resizable: true },
    {
      headerName: 'Clinician NPI', field: 'tierID', sortable: true, filter: !environment.maskSensitiveData, width: 175, resizable: true,
      valueFormatter: params => {
        const patient: HccDashboardPatientDetail = params.data;

        if (patient == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(patient.tierID.toString(), 6);
        }
        else {
          return patient.tierID;
        }
      }
    },
    { headerName: 'Clinician Specialty', field: 'provSplty', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Patient Name', field: 'patientName', sortable: true, filter: true, tooltipField: 'patientName', resizable: true,
      cellRendererFramework: RouterLinkRendererComponent,
      cellRendererParams: {
        linkClicked: (data: HccDashboardPatientDetail) => {
          const path = '../../../care/patient';

          this.router.navigate([path, data.chPatID], { relativeTo: this.route.parent });
        }
      }
    },
    {
      headerName: 'Date of Birth', field: 'dob', sortable: true, filter: true, width: 175, resizable: true,
      valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale)
    },
    { headerName: 'Chronic Conditions', field: 'hcc', sortable: true, filter: true, width: 125, resizable: true },
    { headerName: 'Chronic Conditions Weight', field: 'hccWeight', sortable: true, filter: true, width: 150, resizable: true, hide: true },
    { headerName: 'Chronic Conditions Description', field: 'hccDesc', sortable: true, filter: true, tooltipField: 'hccDesc', resizable: true },
    {
      headerName: 'Date of Service', field: 'dos', sortable: true, filter: true, width: 175, resizable: true,
      valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale)
    },
    {
      headerName: 'Last Coded Provider', field: 'claimProviderName', sortable: true, filter: true,
      tooltipField: 'claimProviderName', resizable: true
    },
    { headerName: 'RAF Score (YTD)', field: 'raF_YTD', sortable: true, filter: true, resizable: true },
    { headerName: 'Attribution Status', field: 'attributionStatus', sortable: true, filter: true, resizable: true },
    { headerName: 'Last AWV Date', field: 'lastAWVDate', sortable: true, filter: true, resizable: true, valueFormatter: params => params.value ? formatDate(params.value, 'MM/dd/yyyy', this.locale) : '' },
    { headerName: 'Last AWV Provider', field: 'lastAWVProvider', sortable: true, filter: true, resizable: true },
  ];

  rowData: any;

  private clinician: string;
  private hccFilter: string;
  public isPracticeFilter: boolean;

  
  public hccEnhanced2FeatureFlag = TrackFeatureConstants.HccDashboardEnhanced2FeatureFlag;

  constructor(private patientSummaryService: HccPatientDetailService, private route: ActivatedRoute,
              @Inject(LOCALE_ID) private locale: string, private router: Router, private featureFlagService: FeatureFlagService,
              protected filterService: GridFilterService, private mask: MaskPipe,
              private hierarchyTierService: HierarchyTierService) {

    super(filterService, PATIENT_DETAIL_REPORT_GRID_KEY);
  }

  ngOnInit(): void {
    const hasFeature = this.featureFlagService.hasFeatureFlag(this.hccEnhanced2FeatureFlag);

    if (!hasFeature) {
      this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger), share()).subscribe(tier => {
        if (tier != null) {
          const tierNum = tier.tier;
          const tierId = tier.selectedTierId;
          this.clinician = this.route.snapshot.params.clinician;
          this.hccFilter = this.route.snapshot.queryParams.hcc;
          this.isPracticeFilter = this.route.snapshot.queryParams.isPractice;

          this.rowData = this.patientSummaryService.getPatients(tierNum, tierId, tier.selectedItem.tier1_ID);
        }
      });
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const sort = [
      {
        colId: 'gapCount',
        sort: 'desc'
      },
      {
        colId: 'patientName',
        sort: 'asc'
      },
      {
        colId: 'dob',
        sort: 'asc'
      }
    ];
    this.gridApi.setSortModel(sort);
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    } else if (this.clinician != null) {
      const filterInstance = this.isPracticeFilter ? this.gridApi.getFilterInstance('providerName') : this.gridApi.getFilterInstance('tierID');

      filterInstance.setModel({
        type: 'set',
        values: [this.clinician]
      });

      this.gridApi.onFilterChanged();
    } else if (this.hccFilter != null) {
      const filterInstance = this.gridApi.getFilterInstance('hcc');

      filterInstance.setModel({
        type: 'set',
        values: [this.hccFilter]
      });

      this.gridApi.onFilterChanged();
    }

    params.api.sizeColumnsToFit();
  }
}
