import { Component, Input } from '@angular/core';

@Component({
  selector: 'coach-sidebar-logo',
  templateUrl: './sidebar-logo.component.html'
})
export class SidebarLogoComponent {

  @Input() isSidebarOpen: boolean;

  constructor() { }

}
