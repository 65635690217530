/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Sp340BClaimIdList } from '../models/sp-340-b-claim-id-list';
import { Sp340BContractInfo } from '../models/sp-340-b-contract-info';
import { Sp340BInventoryClaim } from '../models/sp-340-b-inventory-claim';
import { Sp340BInvoice } from '../models/sp-340-b-invoice';
import { Sp340BInvoiceId } from '../models/sp-340-b-invoice-id';
import { Sp340BUserInfo } from '../models/sp-340-b-user-info';

@Injectable({
  providedIn: 'root',
})
export class Sp340BInvoiceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation sp340BInvoiceGet
   */
  static readonly Sp340BInvoiceGetPath = '/Sp340BInvoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BInvoiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGet$Response(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BInvoice>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BInvoiceService.Sp340BInvoiceGetPath, 'get');
    if (params) {
      rb.query('acoId', params.acoId, {});
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('tier3Id', params.tier3Id, {});
      rb.query('tier4Id', params.tier4Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BInvoice>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BInvoiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGet(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BInvoice>> {

    return this.sp340BInvoiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BInvoice>>) => r.body as Array<Sp340BInvoice>)
    );
  }

  /**
   * Path part for operation sp340BInvoiceSaveInvoicePost
   */
  static readonly Sp340BInvoiceSaveInvoicePostPath = '/Sp340BInvoice/saveInvoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BInvoiceSaveInvoicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BInvoiceSaveInvoicePost$Response(params?: {
    body?: Sp340BInvoice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BInvoice>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BInvoiceService.Sp340BInvoiceSaveInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BInvoice>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BInvoiceSaveInvoicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BInvoiceSaveInvoicePost(params?: {
    body?: Sp340BInvoice
  },
  context?: HttpContext

): Observable<Sp340BInvoice> {

    return this.sp340BInvoiceSaveInvoicePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BInvoice>) => r.body as Sp340BInvoice)
    );
  }

  /**
   * Path part for operation sp340BInvoiceGetInvoiceHistoryGet
   */
  static readonly Sp340BInvoiceGetInvoiceHistoryGetPath = '/Sp340BInvoice/getInvoiceHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BInvoiceGetInvoiceHistoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetInvoiceHistoryGet$Response(params?: {
    tier2Id?: string;
    claimId?: number;
    status?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BInvoice>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BInvoiceService.Sp340BInvoiceGetInvoiceHistoryGetPath, 'get');
    if (params) {
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('claimId', params.claimId, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BInvoice>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BInvoiceGetInvoiceHistoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetInvoiceHistoryGet(params?: {
    tier2Id?: string;
    claimId?: number;
    status?: number;
  },
  context?: HttpContext

): Observable<Array<Sp340BInvoice>> {

    return this.sp340BInvoiceGetInvoiceHistoryGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BInvoice>>) => r.body as Array<Sp340BInvoice>)
    );
  }

  /**
   * Path part for operation sp340BInvoiceGetUserInfoGet
   */
  static readonly Sp340BInvoiceGetUserInfoGetPath = '/Sp340BInvoice/getUserInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BInvoiceGetUserInfoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetUserInfoGet$Response(params?: {
    userId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BUserInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BInvoiceService.Sp340BInvoiceGetUserInfoGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BUserInfo>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BInvoiceGetUserInfoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetUserInfoGet(params?: {
    userId?: number;
  },
  context?: HttpContext

): Observable<Array<Sp340BUserInfo>> {

    return this.sp340BInvoiceGetUserInfoGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BUserInfo>>) => r.body as Array<Sp340BUserInfo>)
    );
  }

  /**
   * Path part for operation sp340BInvoiceGetContractInfoGet
   */
  static readonly Sp340BInvoiceGetContractInfoGetPath = '/Sp340BInvoice/getContractInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BInvoiceGetContractInfoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetContractInfoGet$Response(params?: {
    tier2Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BContractInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BInvoiceService.Sp340BInvoiceGetContractInfoGetPath, 'get');
    if (params) {
      rb.query('tier2Id', params.tier2Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BContractInfo>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BInvoiceGetContractInfoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetContractInfoGet(params?: {
    tier2Id?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BContractInfo>> {

    return this.sp340BInvoiceGetContractInfoGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BContractInfo>>) => r.body as Array<Sp340BContractInfo>)
    );
  }

  /**
   * Path part for operation sp340BInvoiceGetInvoiceIdGet
   */
  static readonly Sp340BInvoiceGetInvoiceIdGetPath = '/Sp340BInvoice/getInvoiceId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BInvoiceGetInvoiceIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetInvoiceIdGet$Response(params?: {
    coach340BClaimsID?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BInvoiceId>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BInvoiceService.Sp340BInvoiceGetInvoiceIdGetPath, 'get');
    if (params) {
      rb.query('coach340BClaimsID', params.coach340BClaimsID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BInvoiceId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BInvoiceGetInvoiceIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BInvoiceGetInvoiceIdGet(params?: {
    coach340BClaimsID?: number;
  },
  context?: HttpContext

): Observable<Sp340BInvoiceId> {

    return this.sp340BInvoiceGetInvoiceIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BInvoiceId>) => r.body as Sp340BInvoiceId)
    );
  }

  /**
   * Path part for operation sp340BInvoiceGetClaimsForInvoicePost
   */
  static readonly Sp340BInvoiceGetClaimsForInvoicePostPath = '/Sp340BInvoice/getClaimsForInvoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BInvoiceGetClaimsForInvoicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BInvoiceGetClaimsForInvoicePost$Response(params?: {
    body?: Sp340BClaimIdList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BInventoryClaim>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BInvoiceService.Sp340BInvoiceGetClaimsForInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BInventoryClaim>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BInvoiceGetClaimsForInvoicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BInvoiceGetClaimsForInvoicePost(params?: {
    body?: Sp340BClaimIdList
  },
  context?: HttpContext

): Observable<Array<Sp340BInventoryClaim>> {

    return this.sp340BInvoiceGetClaimsForInvoicePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BInventoryClaim>>) => r.body as Array<Sp340BInventoryClaim>)
    );
  }

}
