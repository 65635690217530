import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    template: '<a *ngIf="value != null && !isDisabled" [routerLink]="" (click)="onClick()">{{value}}</a><span *ngIf="isDisabled">{{value}}</span>'
})
export class RouterLinkRendererComponent implements AgRendererComponent {
    params: any;
    isDisabled: boolean;
    value: string;

    constructor(
        private ngZone: NgZone,
        private router: Router) { }

    agInit(params: any): void {
        this.params = params;

        if (this.params != null && this.params.isDisabled != null) {
            this.isDisabled = this.params.isDisabled(this.params.data);
        }
        
        if (this.params?.valueOverride) {
            this.value = this.params.valueOverride(this.params.data);
        } else {
            this.value = this.params.value;
        }
    }

    refresh(params: any): boolean {
        return false;
    }

    // This was needed to make the link work correctly
    navigate(link): void {
        this.ngZone.run(() => {
            this.router.navigate([link, this.params.value]);
        });
    }

    onClick(): void {
      this.params.linkClicked(this.params.data);
    }
}