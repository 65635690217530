import {
  EventEmitter,
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IPrescriptionDetail } from '@shared/models/prescription';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';
import { Subscription } from 'rxjs';
import { ClaimStatus, formatDate, basicNameSplit, removeLeadingZeros } from '../../shared-lib';

@Component({
  selector: 'coach-request-care-coordination-note',
  templateUrl: './request-care-coordination-note.component.html',
  styleUrls: ['./request-care-coordination-note.component.scss'],
})
export class RequestCareCoordinationNoteComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() selectedPatientInfo: any;
  @Input() prescriptionInfo: any;
  @Input() showModal: boolean = false;
  @ViewChild('noteContent') noteContent: ElementRef;
  @Output() modalToggled = new EventEmitter<boolean>();
  @Output() claimApproved = new EventEmitter<boolean>();

  authSubscription: Subscription;

  patientName: string;
  patientDobText: string;
  patientMbi: string;

  provider: string = '';
  providerVisitDate: Date;
  rxDiagnosis: string = '';
  relatedDxPresent: number;
  referralDxPresent: number;
  followUpPlanWithCE: string = '';
  followUpPlanWithProvider: string = '';
  formattedFollowUpPlan: string = '';

  careCoordNote: any;

  referralDocSelection: any;

  currentUser: any;
  creatorName: string;
  creatorDate: Date;

  showSubFillDialog: boolean = false;

  constructor(
    private prescriptionService: PrescriptionService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser
        this.creatorName = this.currentUser.name || this.currentUser.email;
        this.creatorDate = new Date();
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue) {
      this.formattedFollowUpPlan = this.prescriptionInfo.follow_Up_Plan.replace(/\n/g, '<br />')
    }
    
    if (changes.selectedPatientInfo) {
      
      this.patientName = basicNameSplit(this.selectedPatientInfo.patientName).join(' ');
      this.patientDobText = this.formatDate(this.selectedPatientInfo.patientDob);
      this.patientMbi = this.selectedPatientInfo.mbi ? this.selectedPatientInfo.mbi : 'N/A';

      setTimeout(() => {
        if (this.showModal) {
          this.toggleVisitNoteModal();
        }
      }, 0);
    }
  }

  public formatDate(dateStr) {
    return dateStr ? new Date(dateStr).toISOString().split('T')[0] : null;
  }

  toggleSubFillDialog() {
    this.showSubFillDialog = !this.showSubFillDialog;
  }

  onSubFillButtonClicked(buttonValue: string) {
    switch (buttonValue.toLowerCase()) {
      case 'cancel':
        break;
      case 'yes':
        this.save(false);
        break;
      case 'no':
        this.save(true);
        break;
      default:
        throw new Error("onSubFillButtonClicked: invalid value");
    }
    this.toggleSubFillDialog();
  }
  
  save(applySubFillLogic: boolean = false) {
    this.prescriptionInfo.claim_Status = ClaimStatus.APPROVED;
    this.prescriptionInfo.claim_Status_Set_By = this.creatorName;
    this.prescriptionInfo.claim_Status_Set_On_Date = this.creatorDate.toISOString();

    this.prescriptionInfo.acoId = this.selectedPatientInfo.acoId;
    this.prescriptionInfo.claimPk = this.selectedPatientInfo.claimPk;
    this.prescriptionInfo.tier2_id = this.selectedPatientInfo.tier2_id;
    this.prescriptionInfo.tier3_id = this.selectedPatientInfo.tier3_id;
    this.prescriptionInfo.tier4_grpId = this.selectedPatientInfo.tier4_grpId;

    this.prescriptionInfo.selectedPatientInfo = this.selectedPatientInfo;
    this.prescriptionInfo.userId = this.selectedPatientInfo.userId;

    this.prescriptionInfo.claimType = this.selectedPatientInfo.claimType;

    this.prescriptionInfo.follow_Up_Plan = this.prescriptionInfo.follow_Up_Plan.replace(/\n/g, '<br />');

    this.prescriptionService.setPrescriptionDetail(this.prescriptionInfo, true, applySubFillLogic);
    
    this.claimApproved.emit(applySubFillLogic);
    this.toggleVisitNoteModal();
  }

  toggleVisitNoteModal() {
    this.modalToggled.emit(this.showModal);
  }

  copyNote() {
    this.copyMessage(this.noteContent.nativeElement.innerText);
  }

  getFormattedFollowUpPlan() {
    return this.prescriptionInfo.follow_Up_Plan.replace(/\n/g, '<br />')
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.addFieldData(val);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  addFieldData(text: string): string {
    const textTerminationIndex = text.indexOf('Visit Date:') - 3;
    let header = text.substring(0, textTerminationIndex);

    let visitDate = new Date(this.prescriptionInfo.visit_Note_Visit_Date);
    let rxDiagnosis = this.prescriptionInfo.visit_Note_Diagnosis;

    let displayDate = visitDate.toISOString();
    displayDate = displayDate.substring(0, displayDate.indexOf('T'));

    return `
${header}
Visit Date: ${displayDate}
Rx Diagnosis: ${rxDiagnosis}

Related Visit by Covered Entity:
Visit Date: ${this.formatDate(this.prescriptionInfo.cE_Visit_Date)}
Provider: ${this.prescriptionInfo.cE_Provider}
CE Location: ${this.prescriptionInfo.cE_Location}

Related Referral by Covered Entity:
Referral Date: ${this.formatDate(this.prescriptionInfo.cE_Related_Referral_Date)}

Follow-up plan:
${this.prescriptionInfo.follow_Up_Plan}
    `.trim();
  }
}
