import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { LogAuditElementID } from '../audit-constants';
import { IRecentMatcher } from './recent-matcher';

export class PatientMatcher implements IRecentMatcher {
  match(url: string): boolean {
    if (url.includes('care/patient/')) {
      return true;
    } else {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createLog(url: string): RecentLogData {
    return {
      url: url.split('?')[0],
      elements: [
        {
          id: LogAuditElementID.ChPatId,
          value: url.split('patient/')[1].split('?')[0],
        },
      ],
    };
  }

  // null to trigger lookup call
  title(url: string): null {
    return null;
  }
}
