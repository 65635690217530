import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { AzureApiBase } from '../azure-api/azure-api-base';
import { PatientSearchResult } from './patient-search-result';
import { PatientSearchRequest } from './patient-search-request';
import { Observable } from 'rxjs';
import { objectToParams } from '@shared/utilities';

export interface SearchFormState {
  advancedSearch?: boolean,
  savedSearchTerm?: string,
  name?: string,
  mbi?: string, 
  dob?: string
}

@Injectable({
  providedIn: 'root',
})
export class PatientSearchService extends AzureApiBase<PatientSearchResult> {
  public savedSearch: SearchFormState = { savedSearchTerm: '', name: '', mbi: '', dob: ''};
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/patients/search');
  }

  search(request: PatientSearchRequest): Observable<PatientSearchResult[]> {
    const params = objectToParams(request);
    console.log(JSON.stringify(params));
    return this.getAll(null, params);
  }

  get patientSearchResults(): PatientSearchResult[] {
    return this.values.value;
  }

  clearSavedData(): void {
    this.savedSearch.savedSearchTerm = '';
    this.savedSearch.name = '';
    this.savedSearch.mbi = '';
    this.savedSearch.dob = '';
  }
}
