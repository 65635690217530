import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { HierarchyTierService } from '../hierarchy/hierarchy-tier.service';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  ComponentId,
  ActionId,
  LogAuditElementID,
  UrlExceptionsToRemove
} from '@shared/models/audit-constants';
import {
  RecentsCacheService,
  RecentsElement,
} from '../recents-cache/recents-cache.service';
import { RecentsMatchers } from '@shared/models/recent-matchers';
import _ from 'lodash';

export interface RecentLogData {
  url: string;
  elements: RecentsElement[];
}
@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService {
  constructor(
    router: Router,
    private auditService: AuditService,
    private hierarchyTierService: HierarchyTierService,
    private recentsCache: RecentsCacheService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let routeState: {
          url: string,
          queryParams: Params
        } = {
          url: router.url,
          queryParams: {}
        }

        if (routeState.url.includes('aco/')) {
          return;
        }
        if (router?.routerState?.snapshot?.root?.children[0]?.queryParams) {
          routeState.queryParams = _.clone(router.routerState.snapshot.root.children[0].queryParams);
        }
        this.filterURLExceptions(routeState);
        for (let idx = 0; idx < RecentsMatchers.length; idx++) {
          if (RecentsMatchers[idx].match(routeState.url)) {
            this.log(RecentsMatchers[idx].createLog(routeState.url, routeState.queryParams));
            break;
          }
        }
      }
    });
  }

  public log(data: RecentLogData): void {
    const { url, elements } = data;
    let tier2_ID =
      this.hierarchyTierService.currentTier$.getValue()?.selectedItem.tier2_ID;
    const segments = url.split('/');
    // correct hierarchy service race condition so recents don't get associated with the wrong community
    if (segments[1] === 'com' && segments[2] !== tier2_ID) {
      tier2_ID = segments[2];
    }
    elements.push({
      value: tier2_ID,
      id: LogAuditElementID.Tier2_ID,
    });

    this.recentsCache.push({
      navURL: url,
      dateTime: Date.now().toString(),
      elements: elements,
    });

    this.auditService.auditLog(
      url,
      ComponentId.Recents,
      null,
      ActionId.Navigate,
      null,
      null,
      null,
      elements.map((e) => {
        return {
          LogAuditElementID: e.id,
          ElementValue: e.value,
        };
      })
    );
  }

  filterURLExceptions(routeState: {
    url: string,
    queryParams: Params
  }): void {
    let urlWithoutQueryParams = routeState.url.split("?")?.[0];
    let urlQueryParams = routeState.url.split("?")?.[1];
    urlQueryParams = urlQueryParams?.split("&")?.filter(el => !UrlExceptionsToRemove.Params.includes(el.split("=")?.[0])).join("&") || "";
    routeState.url = urlWithoutQueryParams + (urlQueryParams ?  "?" + urlQueryParams : '');
    for (let key in routeState.queryParams) {
      if (UrlExceptionsToRemove.Params.includes(key)) {
        delete routeState.queryParams[key];
      }
    }
  }
}
