import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { Patient } from '@shared/models/patient';
import { PatientService } from '@shared/services/patient/patient.service';
import _ from 'lodash';
import * as moment from 'moment'
import { BloodPressureTask } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/wpapi-model';
/** Get only the date part from a date string that contains time zone offset */
function normalizedDateFromDateWithOffset(dateString:string):Date {
  const year = parseInt(dateString.substr(0, 4));
  const month = parseInt(dateString.substr(5,2));
  const day = parseInt(dateString.substr(8,2));
  return new Date(year, month-1, day);
}

export interface TaskViewModel extends wpapi.model.Task {
  systolicDiastolicAlertOn? : boolean;
  weightAlertOn?: boolean;
  pulseAlertOn?: boolean;
  bloodSugarAlertOn?: boolean;
}

@Component({
  selector: 'coach-careplan-patient-task',
  templateUrl: './patient-task.component.html',
  styleUrls: ['./patient-task.component.scss']
})
export class PatientTaskComponent implements OnInit {
  public editModalIsOpen: boolean = false
  constructor(private PatientService:PatientService) { }
  @Input() templateTask: wpapi.model.ProtocolTask;
  @Input() assignedTask: wpapi.model.Task;
  @Input() patient: Patient;
  @Input() tier: IHierarchyTier;
  @Input() isDisabled?: boolean;
  @Output() updated = new EventEmitter<wpapi.model.Task>();
  isActive: boolean = false;
  get tierId() {
    return this?.tier?.selectedTierId;
  }
  clonedTask: TaskViewModel;

  get repeatingTask() {
    if (wpapi.model.isRepeatingTask(this.clonedTask)) {
      return this.clonedTask;
    }
    return null;
  }
  get weightTask() {
    if (wpapi.model.isWeightTask(this.clonedTask)) {
      return this.clonedTask;
    }
    return null;
  }
  get bloodPressureTask() {
    if (wpapi.model.isBloodPressureTask(this.clonedTask)) {
      return this.clonedTask;
    }
    return null;
  }
  get bloodSugarTask() {
    if (wpapi.model.isBloodSugarTask(this.clonedTask)) {
      return this.clonedTask;
    }
  }
  today:Date = new Date()
  ngOnInit(): void {
    this.isActive = this.assignedTask?.isActive;
  }
  // Event handlers
  async toggleIsActive(overWriteStatus?: boolean) {
    if (overWriteStatus) {
      this.isActive = overWriteStatus;
    }
    if (this.assignedTask) {
      try {
        await this.PatientService.activateOrDeactivateTask(this.assignedTask, this.isActive);
        this.assignedTask.isActive = this.isActive;
        this.updated.emit(this.assignedTask);
      } catch (error) {
        console.error(error)
      }
    }
  }

  public get id() {
    return this.assignedTask?._id
  }
  public get name() {
    if (this.assignedTask) {
      return this.assignedTask.name;
    }
    if (this.templateTask) {
      return this.templateTask.name;
    }
    return "(uninitialized)";
  }

  public get startDate():Date {
    const startDate = this?.assignedTask?.startDate;
    if (!startDate) {
      return null;
    }
    return normalizedDateFromDateWithOffset(startDate);
  }

  public get endDate():Date {
    const endDate = this?.assignedTask?.endDate;
    if (!endDate) {
      return null;
    }
    return normalizedDateFromDateWithOffset(endDate);
  }

  public get taskTypeText() {
    const task = this.assignedTask || this.templateTask;
    if (!task) {
      return null;
    }
    if (!task.type) {
      return "Exercise";
    }
    var s = task.type.toString().toLowerCase();
    switch (s) {
      case 'twiliostudioflow':
        s = 'SMS PROGRAM';
        break;
      case 'activitytracker':
        s = 'activity tracker';
        break;
      case 'aboutinfo':
        s = 'about info';
        break;
      case 'painmedication':
        s = 'pain medication';
        break;
      case 'startdoing':
        s = 'start doing';
        break;
      case 'stopdoing':
        s = 'stop doing';
        break;
      case 'patientsatisfaction':
        s = 'patient satisfaction';
        if (task.subType === "wellpepper") {
          s += " (Wellpepper)";
        }
        break;
      case 'didyoufall':
        s = 'did you fall';
        break;
      case 'quicktips':
        s = 'quick tips';
        break;
      case 'videodiary':
        s = 'video diary';
        break;
      case 'food diary':
      case 'fooddiary':
        s = 'food diary';
        break;
      case 'meddiary':
        s = 'med diary';
        break;
      case 'goaltracking':
        s = 'goal';
        break;
      case "bloodpressure":
        s = "blood pressure";
        break;
      case "bloodsugar":
        s = "blood sugar";
        break;
      case 'tasksetup':
        switch ((task.subType || "").toString().toLowerCase()) {
          case "activity":
            s = 'activity';
            break;
          case "weight":
            s = 'weight';
            break;
          case "bloodpressure":
            s = "blood pressure"
            break;
          default:
            s = "tracking";
            break;
        }
        break;
      case 'educationvideo':
      case 'educationwebvideo':
        s = 'education video';
        break;
      case "weighttracker":
        s = "weight tracker";
        break;
    }
    s = s.replace(/_/g, ' ');
    return s.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /** This gets the recurrence from the recurrence configurableField, not the actual rrule */
  public get recurrenceText() {
    if (!this.assignedTask) {
      return null;
    }
    // REVIEW - not sure what the right way is of doing a type downcast is
    if (wpapi.model.isRepeatingTask(this.assignedTask)) {
      const recurTask = this.assignedTask;
      const repeatTextRaw = recurTask?.configurableFields?.recurrence?.repeatInterval.value;
      return repeatTextRaw;
    }
    return null;
  }

  public get goalText():string {
    if (!this.assignedTask) {
      return null;
    }

    if (wpapi.model.isWeightTask(this.assignedTask)) {
      const goals = this.assignedTask.goals;
      if (!goals || !goals.isGoalTrackingEnabled || !goals.current) {
        return null;
      }
      // return `${goals.current.weight} ${goals.current.unit}`;
      return `${goals.current.weight.rangeBottom} - ${goals.current.weight.rangeTop} ${goals.current.unit}`;
    } else if (wpapi.model.isBloodPressureTask(this.assignedTask)) {
      const goals = this.assignedTask.goals;
      if (!goals || !goals.isGoalTrackingEnabled || !goals.current) {
        return null;
      }
      return `${goals.current.systolic.rangeBottom}/${goals.current.diastolic.rangeBottom} - ${goals.current.systolic.rangeTop}/${goals.current.diastolic.rangeTop} ${goals.current.unit}`;
    } else if (wpapi.model.isBloodSugarTask(this.assignedTask)) {
      const goals = this.assignedTask.goals;
      if (!goals || !goals.isGoalTrackingEnabled || !goals.current) {
        return null;
      }
      return `${goals.current.bloodSugar.rangeBottom} - ${goals.current.bloodSugar.rangeTop} ${goals.current.unit}`;
    } else {
      return null;
    }
  }


  /*
  public get stateHistoryText(h:wpapi.model.EpisodeOfCareModuleAssignmentHistory) {
    let stateText:string;
    switch(h.state) {
      case "active":
        stateText = "Activated";
        break;
      case "available":
        stateText = "Unlocked";
        break;
      case "inactive":
        stateText = "Deactivated";
        break;
      case "unavailable":
        stateText = "Locked";
        break;
      default:
        stateText = "Updated";
        break;
    }

    let stateChangeByText = h.updatedBy ? "by " + this.getProfessionalName(h.updatedBy) : "";
    let stateChangeOnText = moment(h.effectiveDate || h.createdAt).format("ddd MMM D, YYYY HH:mm:ss");

    return `${stateText} ${stateChangeOnText} ${stateChangeByText}`;
  }
  */

  public get taskId():string {
    return this.assignedTask?._id || null;
  }
  isTaskEditable(): boolean {
    if (!this.assignedTask || this.assignedTask['isLocked'] || !this.assignedTask.type) {
      return false;
    }

    switch (this.assignedTask.type) {
      case "exercise":
      case "activityTracker":
      case "activity":
      case "bloodPressure":
      case "bloodSugar":
      case "weight":
      case "pain":
        return true;
      case "taskSetup":
        switch(this.assignedTask.subType) {
          case "activity":
            return true;
        }
        break;
      default:
        return false;
    }
  }
  closeModalOnBackgroundClick(target: any): void {
    if(target.getAttribute('role') == 'modal-background'){
      this.editModalIsOpen = null
    }
  }
  openEditModal(): void {
    this.clonedTask = _.cloneDeep(this.assignedTask)
    if (wpapi.model.isRepeatingTask(this.clonedTask)) {
      if(this.clonedTask.configurableFields?.recurrence?.startDate?.value){
        this.clonedTask.configurableFields.recurrence.startDate.value = this.formatDateForDateInput(this.clonedTask.configurableFields.recurrence.startDate.value)
      }
      if(this.clonedTask.configurableFields?.recurrence?.endDate?.value){
        this.clonedTask.configurableFields.recurrence.endDate.value = this.formatDateForDateInput(this.clonedTask.configurableFields.recurrence.endDate.value)
      }
      this.clonedTask.configurableFields = Object.assign({recurrence: { startDate: {}, endDate: {}}}, this.clonedTask.configurableFields);
    }

    // assign goals
    if(!this.clonedTask.goals.history) this.clonedTask.goals.history = [];
    if(wpapi.model.isBloodPressureTask(this.clonedTask)){
      this.clonedTask.configurableFields = Object.assign({}, this.clonedTask.configurableFields);
      if (!this.clonedTask.goals.current) {
        this.clonedTask.goals.current = {
          createdAt: new Date().toISOString(),
          effectiveOn: new Date().toISOString(),
          unit:"mmHg",
          diastolic: {
            rangeBottom: null,
            rangeTop: null
          },
          systolic: {
            rangeBottom: null,
            rangeTop: null
          }
        };
      }
      if(this.clonedTask.configurableFields?.systolicAlerts?.systolicAlertIsActive && this.clonedTask.configurableFields?.diastolicAlerts?.diastolicAlertIsActive){
        this.clonedTask.systolicDiastolicAlertOn = this.clonedTask.configurableFields?.systolicAlerts?.systolicAlertIsActive?.value && this.clonedTask.configurableFields?.diastolicAlerts?.diastolicAlertIsActive?.value
      }
      if(this.clonedTask.configurableFields?.pulseAlerts?.pulseAlertIsActive){
        this.clonedTask.pulseAlertOn = this.clonedTask.configurableFields?.pulseAlerts?.pulseAlertIsActive?.value
      }
    } else if(wpapi.model.isWeightTask(this.clonedTask)){
      if (!this.clonedTask.goals.current) {
        this.clonedTask.goals.current = {
          createdAt: new Date().toISOString(),
          effectiveOn: new Date().toISOString(),
          unit: "lb",
          weight: {
          rangeBottom: null,
          rangeTop: null,
          }
        };
      }
      if(this.clonedTask.configurableFields?.weightAlerts?.weightAlertIsActive){
        this.clonedTask.weightAlertOn = this.clonedTask.configurableFields?.weightAlerts?.weightAlertIsActive?.value
      }
    }else if(wpapi.model.isBloodSugarTask(this.clonedTask)){
      if (!this.clonedTask.goals.current) {
        this.clonedTask.goals.current = {
          createdAt: new Date().toISOString(),
          effectiveOn: new Date().toISOString(),
          unit: "mg/dL",
          bloodSugar: {
            rangeBottom: null,
            rangeTop: null
          }
        };
      }
      if(this.clonedTask.configurableFields?.bloodSugarAlerts?.bloodSugarAlertIsActive){
        this.clonedTask.bloodSugarAlertOn = this.clonedTask.configurableFields?.bloodSugarAlerts?.bloodSugarAlertIsActive?.value
      }
    }

    this.editModalIsOpen = true
  }
  formatDateForDateInput(date: any): string {
    if(date && moment(date).isValid) return moment(date).format("YYYY-MM-DD")
    return null
  }
  updateGoalsHistory(): any[]{ // not used
    let oldGoalRecord = this.assignedTask.goals.current;
    this.clonedTask.goals.history.push(oldGoalRecord)
    return this.clonedTask.goals.history
  }

  isBPAlertActive(task:BloodPressureTask): boolean {
    const cf = task?.configurableFields;
    return cf &&
      cf?.diastolicAlerts?.diastolicAlertIsActive.value ||
      cf?.systolicAlerts?.systolicAlertIsActive.value;
  }
  toggleBPAlertIsActive(task:BloodPressureTask): void {
    const isActive = this.isBPAlertActive(task);
    const cf = task?.configurableFields;
    if (cf?.diastolicAlerts?.diastolicAlertIsActive) {
      cf.diastolicAlerts.diastolicAlertIsActive.value = !cf.diastolicAlerts.diastolicAlertIsActive.value;
    }
    if (cf?.systolicAlerts?.systolicAlertIsActive) {
      cf.systolicAlerts.systolicAlertIsActive.value = !cf.systolicAlerts.systolicAlertIsActive.value;
    }
  }

  isDateBefore(date1: any, date2: any): boolean {
    return moment(date1).isBefore(date2)
  }
  isTaskValid(): boolean {
    if(this.repeatingTask?.configurableFields?.recurrence?.startDate?.value && this.repeatingTask?.configurableFields?.recurrence?.endDate?.value){
      return this.isDateBefore(this.repeatingTask.configurableFields.recurrence.startDate.value, this.repeatingTask.configurableFields.recurrence.endDate.value)
    }
    return true
  }
  getOriginalDate(date):string{
    return this.assignedTask.configurableFields?.['recurrence']?.[date]?.value || null
  }
  async saveTask() {
    /**
     * Steps:
     * 1. compare goal inputs
     * 2. compare start and end dates
     * 3. compare goal alerts
     * Save any differences between assignedTask and clonedTask
     */
    let result: any = {}

    // compare goals
    if(JSON.stringify(this.assignedTask.goals?.current) != JSON.stringify(this.clonedTask.goals?.current)){
      if(this.assignedTask.goals.current){
        this.clonedTask.goals.history.push(this.assignedTask.goals.current)
      }
    }
    result.goals = _.cloneDeep(this.clonedTask.goals);
    result.configurableFields = _.cloneDeep(this.clonedTask.configurableFields)
    result.configurableFields.recurrence.startDate.value = result.configurableFields.recurrence.startDate.value ? moment(result.configurableFields.recurrence.startDate.value).utcOffset(this.patient.currentTimeZoneOffsetSec/3600).format() : null
    result.configurableFields.recurrence.endDate.value = result.configurableFields.recurrence.endDate.value ? moment(result.configurableFields.recurrence.endDate.value).utcOffset(this.patient.currentTimeZoneOffsetSec/3600).format() : null
    let updateTaskConfirmation = await this.PatientService.updateTask(this.tierId, this.patient, this.assignedTask._id, result)
    if(updateTaskConfirmation){
      this.clonedTask.startDate = this.repeatingTask?.configurableFields?.recurrence?.startDate?.value
      this.clonedTask.endDate = this.repeatingTask?.configurableFields?.recurrence?.endDate?.value
      this.assignedTask = _.cloneDeep(this.clonedTask)
      this.editModalIsOpen = false;
      this.updated.emit(this.assignedTask);
    }
  }
}
