import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CarePatientItem } from '@shared/models/module-constants';
import { IPatientFilterPreset, IPatientFilterPresetGroup } from '@shared/models/patient-filters';
import { FilterSettings } from '@shared/services/ag-grid/ag-grid.service';
import { PresetSettingsService } from '../preset-settings.service';

@Component({
  selector: 'coach-care-patients-filters-2',
  templateUrl: './care-patients-filters.component.html',
  styleUrls: ['./care-patients-filters.component.scss']
})
export class CarePatientsFiltersComponent implements OnInit {

  isPatientFiltersOpen = true;
  allPresetGroups: IPatientFilterPresetGroup[] = [];

  selectedPresetSettings: FilterSettings;
  selectedPreset: IPatientFilterPreset;
  loadingStatus: boolean;

  constructor(
    private presetSettingsService: PresetSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.allPresetGroups = this.presetSettingsService.getPresetGroups();

    this.presetSettingsService.selectedPreset$.subscribe(preset => {
      this.selectedPreset = preset;
    });

    const requestedPreset = this.activatedRoute.snapshot.queryParams['filterPreset'];
    this.selectPresetByName(requestedPreset);
  }

  public togglePatientFilters(): void {
    this.isPatientFiltersOpen = !this.isPatientFiltersOpen;
  }

  public isActivePreset(preset: IPatientFilterPreset): boolean {
    return this.selectedPreset && (preset.name == this.selectedPreset.name || (preset.label === 'Care Management' && this.selectedPreset.label === preset.label));
  }

  public async selectPreset(preset: IPatientFilterPreset): Promise<void> {
    this.presetSettingsService.selectPreset(preset);

    const queryParams: Params = { filterPreset: preset.name };

    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

  private selectPresetByName(presetName: string): void {
    let preset = this.findPresetByName(presetName);

    if (!preset) {
      preset = this.findPresetByName(CarePatientItem.AwvOpportunities);
    }

    if (preset) {
      this.selectPreset(preset);
    }
  }

  private findPresetByName(presetName: string): IPatientFilterPreset {
    return this.allPresetGroups
      .flatMap(presetGroup => presetGroup.presets)
      .find(p => p.name === presetName);
  }

}
