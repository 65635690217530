import { Component, OnInit } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';

@Component({
    selector: 'app-auto-component',
    templateUrl: './auto-login.component.html',
})
export class AutoLoginComponent implements OnInit {
    constructor(public authService: AuthService) {}

    ngOnInit() {
        this.authService.login();
    }
}
