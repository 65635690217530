import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = (value - minutes * 60);
    const displaySeconds = seconds > 9 ? seconds : '0' + seconds;
    return minutes + ':' + displaySeconds;
  }
}
