<div class="min-height margin-auto row container-color u-border-radius">
  <div class="span--4 u-border-radius--rounded">
    <div class="icon-container u-center u-display--flex u-position--relative u-align-items--center u-flex-direction--column">
      <div class="vertical-line"></div>
      <coach-icon class="padding-regular u-align-items--center u-justify-content--center" iconName="phone-callback" ></coach-icon>
      <div class="u-font-size--smallest">{{callbackDate}}</div>
    </div>
  </div>
  <div class="span--8 u-align-items--top u-display--flex u-flex-direction--column u-justify-content--center padding--large">
    <div>
        <div class="u-bold">{{callbackReason}}</div>
    </div>
    <div class="text-opacity u-font-weight--light u-italic">{{comment}}
    </div>
  </div>
</div>
