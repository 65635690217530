import { Injectable } from '@angular/core';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { TrackReportMenuItem } from '@track/track-reports/track-report-menu-item';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackReportMenuItemsService  extends AzureApiBase<TrackReportMenuItem> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/report/menuItems');
  }
}
