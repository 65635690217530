<div class="header row--verticalCenter padding--smaller">
  <button class="button is-icon border--none margin-right--smaller"
    (click)="drillOutClicked()">
  <coach-icon iconName="chevron-left" iconColor="currentColor"></coach-icon>
  </button>
  <div class="titles t5">
    <div class="title">
      {{folder?.Title}}
    </div>
  </div>
</div>
