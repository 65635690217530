import { Component } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
@Component({
  templateUrl: 'actions.html'
})
export class ActionsColumnRendererComponent {
  link: string;
  alertsCount: number;
  id: string;
  tier: IHierarchyTier;
  withoutFacesheet:boolean;
  agInit(params): void {
    this.id = params.value.id;
    this.tier = params.value.tier;
    this.link = `/${this.tier?.abbreviation}/${this.tier?.selectedTierId}/care/patient/${this.id}`;
    this.alertsCount = params.value.alertsCount || 0;
  }
}
