import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideBarToggleService {
  public sidebarToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  constructor() {}

  public toggleSidebarPane(toggledStatus: boolean) {
    this.sidebarToggle.next(toggledStatus);
  }
}
