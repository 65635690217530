import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICurrentRoute } from '@shared/models/activated-route';

export interface TabSwitchState {
  isInvoiceDetailsTabSelected: boolean;
  isClaimInfoTabSelected: boolean;
  isClaimHistorySelected: boolean;
}

@Component({
  selector: 'coach-invoicing-tab-switch',
  templateUrl: './invoicing-tab-switch.component.html',
  styleUrls: ['./invoicing-tab-switch.component.scss']
})
export class InvoicingTabSwitchComponent implements OnInit {

  public isTabDropdownOpen = true;
  public currentRoute$: BehaviorSubject<ICurrentRoute>;
  public activeView: ICurrentRoute;
  public isSidebarOpen: boolean = true;

  @Input() isInvoiceDetailsTabSelected: boolean;
  @Input() isClaimInfoTabSelected: boolean;
  @Input() isClaimHistorySelected: boolean;

  @Output() onTabClicked = new EventEmitter<TabSwitchState>();

  constructor() {}

  updateTabs(): void {
  }

  ngOnInit(): void {
  }

  onSummaryTabSelected() {
    this.isInvoiceDetailsTabSelected = true;
    this.isClaimInfoTabSelected = false;
    this.isClaimHistorySelected = false;
    this.emitEvent();
  }

  onQuickFillTabSelected() {
    this.isInvoiceDetailsTabSelected = false;
    this.isClaimInfoTabSelected = true;
    this.isClaimHistorySelected = false;
    this.emitEvent();
  }

  onCareNoteSelected() {
    this.isInvoiceDetailsTabSelected = false;
    this.isClaimInfoTabSelected = false;
    this.isClaimHistorySelected = true;
    this.emitEvent();
  }

  emitEvent() {
    this.onTabClicked.emit({
      isInvoiceDetailsTabSelected: this.isInvoiceDetailsTabSelected,
      isClaimInfoTabSelected: this.isClaimInfoTabSelected,
      isClaimHistorySelected: this.isClaimHistorySelected
    });
  }

}
