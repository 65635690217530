import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, ViewChild, HostListener, Injector, ElementRef, ViewContainerRef, OnChanges } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { IQueryParam } from '@shared/models/query-param';
import { Patient } from '@shared/models/patient';
import { PatientService } from '@shared/services/patient/patient.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { CdkPortalOutletAttachedRef, ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { PatientActionDropdownComponent, NAVIGATION_PORTAL_DATA } from './../patient-action-dropdown/patient-action-dropdown.component';
import { environment } from 'src/environments/environment'
import { CareComponentConstants } from '@shared/models/module-constants';
import { CareFeatureConstants } from "@shared/models/module-constants";
@Component({
  selector: 'coach-patient-demographics',
  templateUrl: './patient-demographics.component.html',
  styleUrls: ['./patient-demographics.component.scss']
})
export class PatientDemographicsComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() tier: IHierarchyTier;
  @Input() patientFacesheetSize: 'mobile' | 'tablet' | 'desktop';
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private overlayRef: OverlayRef;
  public modalStatus: { isOpen: boolean, target: string };
  public modalPatientInviteForm: boolean = false;
  public modalPatientEditForm: boolean = false;
  public readonly careFeatureConstants = CareFeatureConstants;
  scopeSelectDropdownPortal: ComponentPortal<PatientActionDropdownComponent>;

  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private _router: Router,
    private _route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private _injector: Injector,
    public overlay: Overlay,
    private vcRef: ViewContainerRef) {
    this.modalStatus = {
      isOpen: false,
      target: null
    }
  }
  @ViewChild('scopeSelectOrigin') scopeSelectOrigin: ElementRef
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.overlayRef?.dispose()
    this.overlayRef = null;
  }
  public get isCarePatient() {
    return this?.patient?.carePatient?.accountType === "patient";
  }
  public get displayPatientProviderAssignment() {
    return `Provider${this?.patient?.trackPatient?.assignment ? ` (${this?.patient?.trackPatient?.assignment})` : ''}`;
  }
  public get displayProviderName() {
    return (this.patient.tier5_Name ? this.patient.tier5_Name : this.patient.tier4_Name)?.toLowerCase();
  }
  public get displayRiskLevel() {
    return environment.displayRiskLevel;
  }
  public get displayEnrollmentType() {
    return this?.patient?.enrollmentType;
  }
  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(NAVIGATION_PORTAL_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }
  public openScopeSelectDropdown() {
    const scopeSelectDropdownPortal = new ComponentPortal(PatientActionDropdownComponent, this.vcRef, this.createInjector({
      emitter: this.dropDownEmitterFunc,
      patient: this.patient
    }));
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.scopeSelectOrigin)
      .withPositions([
        { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }])
    });
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(scopeSelectDropdownPortal);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    this.overlayRef.outsidePointerEvents().subscribe(() => this.overlayRef.detach());
  }
  get dropDownEmitterFunc() {
    return this.dropDownEmitter.bind(this);
  }

  dropDownEmitter(action: string): void {
    this.overlayRef.detach()
    this.modalStatus = {
      isOpen: true,
      target: action
    }
  }
  closeModalOnBackgroundClick(event: any): void{
    if(event.target.getAttribute('role') == 'modal-background'){
      this.closeModal()
    }
  }
  closeModal(): void {
    this.modalStatus = {
      isOpen: false,
      target: null
    }
  }
  public titleLine(fields: string[], separator = ' | '): string {
    return fields.filter(f => !!f).join(separator);
  }
}
