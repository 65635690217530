import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';

@Component({
  selector: 'coach-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.scss'],
})
export class FileImportComponent implements OnInit, OnChanges {
  importFeatureFlag: string = CareFeatureConstants.FileImport340B;
  @Input() claimId: string;
  @Input() prescriptionRefID: string;
  @Input() tier2Id: string;
  @ViewChild("fileUpload") fileUploadControl: ElementRef;

  message: string;

  files: Array<string>;

  constructor(private prescriptionService: PrescriptionService) {}
  
  ngOnInit(): void {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.claimId) {
      this.prescriptionService.getFileAttachments(this.tier2Id, this.prescriptionRefID).then(attachments => {
        this.files = [];
        attachments.forEach(attachment => this.files.push(attachment['fileName']));
        this.message = '';
      });
    }
  }

  onFileInputClicked(e) {
    for (let file of e.target.files) {
      if (file) {
        const formData = new FormData();
        formData.append('claimId', this.claimId);
        formData.append('tier2Id', this.tier2Id);
        formData.append('prescriptionRefID', this.prescriptionRefID);
        formData.append('file', file);
                
        this.prescriptionService.uploadFile(formData).then(upload$ => {
          let sub = upload$.subscribe(x => {
            
            switch (x['statusCode']) {
              case 'FT0000':
                this.message = 'Upload successful';
                let fileName = file.name;
                this.files.push(fileName);
                break;
              case 'FT0001':
                this.message = 'File already exists';
                break;
              case 'FT0002':
                this.message = 'Filetype not allowed';
                break;
              default:
                console.log(x);
            }
            
            sub.unsubscribe();
          });
        });
      }
    }
  }

  onImportButtonClick() {
    this.fileUploadControl.nativeElement.click();
  }

  setMessage(message: string): void {
    this.message = message;
  }

  removeFile(filename: string): void {
    this.files = this.files.filter(file => file !== filename);
  }
}
