import { CheckboxQuestion, NumDenPtsQuestion, SecurityRiskQuestion, YesNonPtsQuestion, DateQuestion} from './forms-frontend';

export class FormQuestionConstants {
  public static Q8SecurityRisk: SecurityRiskQuestion = {
    name: 'FormQ8SecurityRisk',
    description: 'Security Risk Analysis',
    promptQuarter: true,
    measureId: 'PI_PPHI_1',
    helpText:
      // eslint-disable-next-line quotes
      "Conduct or review a security risk analysis in accordance with the requirements in 45 CFR 16.308(a)(1), including addressing the security (to include encryption) of ePHI data created or maintained by certified electronic health record technology (CEHRT) in accordance with requirements in 45 CFR 164.312(a)(2)(iv) and 45 CFR 164.306(d)(3), implement security updates as necessary, and correct identified security deficiencies as part of the MIPS eligible clinician's risk management process.",
    riskOptions: [
      {
        optionId: 0,
        optionText: 'WillComplete',
      },
      {
        optionId: 1,
        optionText: 'Complete',
      },
      {
        optionId: 2,
        optionText: 'Unset',
      }
    ],
    modalLinks: [
      {
        entryID: 1166288,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
  };
  public static Q9ePrescibe: NumDenPtsQuestion = {
    name: 'FormQ9ePrescribe',
    description: 'e-Prescribing',
    measureId: 'PI_EP_1',
    exclusionText:
      'MIPS eligible clinician who writes fewer than 100 permissible prescriptions during the performance period.',
    maxPoints: 10,
    allowExclusion: true,
    helpText:
      'At least one permissible prescription written by the MIPS eligible clinician is queried for a drug formulary and transmitted electronically using CEHRT.',
    minDenominator: 1,
    modalLinks: [
      {
        entryID: 1166298,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
  };

  public static Q10SupportSend: NumDenPtsQuestion = {
    name: 'FormQ10SupportSend',

    description: 'Support Electronic Referral Loops By - Sending Health Information',
    measureId: 'PI_HIE_1',
    exclusionText:
      'MIPS eligible clinician who transfers a patient to another setting or refers a patient fewer than 100 times during the performance period.',
    maxPoints: 15,
    allowExclusion: true,
    helpText:
      'For at least one transition of care of referral, the MIPS eligible clinician that transitions or refers their patient to another setting of care or health care provider -- (1) creates a summary of care record using certified electronic health record technology (CEHRT); and (2) electronically exchanges the summary of care record.',
    minDenominator: 1,
    modalLinks: [
      {
        entryID: 1166289,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
  };

  public static Q11SupportReceive: NumDenPtsQuestion = {
    name: 'FormQ11SupportReceive',
    description: 'Support Electronic Referral Loops By - Receiving and Reconciling Health Information',
    measureId: 'PI_HIE_4',
    exclusionText:
      'MIPS eligible clinician who receives transitions of care or referrals or has patient encounters in which the MIPS eligible clinician has never before encountered the patient fewer than 100 times during the performance period.',
    maxPoints: 15,
    allowExclusion: true,
    helpText:
      'For at least one electronic summary of care record received for patient encounters during the performance period for which a MIPS eligible clinician was the receiving party of a transition of care or referral, or for patient encounters during the performance period in which the MIPS eligible clinician has never before encountered the patient, the MIPS eligible clinician conducts clinical information reconciliation for medication,medication allergy, and current problem list.',
    minDenominator: 1,
    modalLinks: [
      {
        entryID: 1166310,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
    };

  public static Q12ProvideAccess: NumDenPtsQuestion = {
    name: 'FormQ12ProvideAccess',
    description: 'Provide Patients Electronic Access to Their Health Information',
    measureId: 'PI_PEA_1',
    exclusionText:
      'MIPS eligible clinician who receives transitions of care or referrals or has patient encounters in which the MIPS eligible clinician has never before encountered the patient fewer than 100 times during the performance period.',
    maxPoints: 25,
    allowExclusion: false,
    helpText:
      // eslint-disable-next-line quotes
      "For at least one unique patient seen by the MIPS eligible clinician: (1) The patient (or the patient-authorized representative) is provided timely access to view online, download, and transmit his or her health information; and (2) The MIPS eligible clinician ensures the patient's health information is available for the patient (or the patient-authorized representative) to access using any application of their choice that is configured to meet the technical specifications of the Application Programming Interface (API) in the MIPS eligible clinician's certfied electronic health record technology (CEHRT).",
    minDenominator: 1,
    modalLinks: [
      {
        entryID: 1166290,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
    };

    public static Q14DrugMonitor: YesNonPtsQuestion = {
      name: 'FormQ14DrugMonitor',
      checkBoxOptions: [
        {
          checkId: 0,
          checkText: 'Yes',
        },
        {
          checkId: 1,
          checkText: 'No',
        }
      ],
      description: 'Query of Prescription Drug Monitoring Program',
      measureId: 'PI_EP_2',
      maxPoints: 10,
      allowExclusion: true,
      helpText:
      'For at least one Schedule II opioid electronically prescribed using CEHRT during the performance period, the MIPS eligible clinician uses data from CEHRT to conduct a query of a PDMP for prescription drug history, except where prohibited and in accordance with applicable law.',
      exclusionText:
      'MIPS eligible clinician who meets one or more of the following criteria: 1. Is unable to electronically prescribe Schedule II opioids and Schedule III and IV drugs in accordance with applicable law during the performance period; 2. Writes fewer than 100 permissible prescriptions during the performance period; 3. Querying a PDMP would impose an excessive workflow or cost burden prior to the start of the performance period they select in CY 2023.',
      modalLinks: [
        {
          entryID: 1166276,
          linkText: 'Link to Measure Specification'

        },
        {
          linkText: 'Link to Scoring Methodology'
        }
      ],
    };

  public static Q71HealthInformationExchange: CheckboxQuestion =  {
    name: 'FormQ71HealthInformationExchange',
    description: 'Health Information Exchange (HIE) Bi-Directional Exchange',
    measureId: 'PI_HIE_5',
    //checkBoxText: 'Participates in an HIE',
    checkBoxOptions: [
      {
        checkId: 0,
        checkText: 'CareQuality',
      },
      {
        checkId: 1,
        checkText: 'CommonWell',
      },
      {
        checkId: 2,
        checkText: 'eHealthExchange',
      },
      {
        checkId: 3,
        checkText: 'State/Regional HIE',
      },
      {
        checkId: 4,
        checkText: 'Other',
      },
    ],
    pointsText: 'Max',
    allowExclusion: false,
    helpText:
      'The MIPS eligible clinician or group must attest that they engage in bidirectional exchange with an HIE to support transitions of care.',
    maxPoints: 30,
    modalLinks: [
      {
        entryID: 1166292,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
  };

  public static Q77HighPriorityGuideSAFER: SecurityRiskQuestion = {
    name: 'FormQ77HighPriorityGuideSAFER',
    description: 'High Priority Practices Guide of the SAFER Guide Attestation',
    promptQuarter: false,
    helpText:
      // eslint-disable-next-line quotes
      "Conduct an annual assessment of the High Priority Practices Guide SAFER Guides.",
    measureId: 'PI_PPHI_2',
    riskOptions: [
      {
        optionId: 3,
        optionText: 'WillComplete',
      },
      {
        optionId: 4,
        optionText: 'Complete',
      },
      {
        optionId: 5,
        optionText: 'Unset',
      }
    ],
    modalLinks: [
      {
        entryID: 1166308,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
  };

  public static Q78BonusRegistryReporting: CheckboxQuestion = {
    name: 'FormQ78BonusRegistryReporting',
    description: 'Select additional registries that you are reporting on',
    //measureId: '',
    //checkBoxText: 'Has queried a prescription drug monitoring program',
    checkBoxOptions: [
      {
        checkId: 0,
        checkText: 'Clinical Data Registry',
      },
      {
        checkId: 1,
        checkText: 'Public Health Registry',
      },
      {
        checkId: 2,
        checkText: 'Syndromic Surveillance',
      }
    ],
    pointsText: 'Bonus',
    helpText:
      'Reporting on one of the three optional registry options will result in 5 total bonus points.',
    maxPoints: 5,
    allowExclusion: false,
    modalLinks: [
      {
        entryID: 1166295,
        linkText: 'Clinical Data Registry'

      },
      {
        entryID: 1166309,
        linkText: 'Public Health Registry'

      },
      {
        entryID: 1166278,
        linkText: 'Syndromic Surveillance'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
  };
  public static Q82TEFCA: YesNonPtsQuestion = {
    name: 'FormQ82TEFCA',
    checkBoxOptions: [
      {
        checkId: 0,
        checkText: 'Yes',
      },
      {
        checkId: 1,
        checkText: 'No',
      }
    ],
    description: 'Enabling Exchange Under the Trusted Exchange Framework and Common Agreement (TEFCA) ',
    measureId: 'PI_HIE_6',
    exclusionText:
      'MIPS eligible clinician who writes fewer than 100 permissible prescriptions during the performance period.',
    maxPoints: 30,
    allowExclusion: false,
    helpText:
      'The MIPS eligible clinician or group must attest to participation of an ONC recognized Trusted Exchange Framework and Common Agreement (TEFCA)',
    modalLinks: [
      {
        entryID: 1166277,
        linkText: 'Link to Measure Specification'

      },
      {
        linkText: 'Link to Scoring Methodology'
      }
    ],
  };
  public static Q83Date: DateQuestion = {
    name: 'FormQ83Date',
    description: 'Date Range',
    allowExclusion: true,
    requiredDayDif: 180,
    helpText:
    'Report PI data on the best 180+ continuous days within the calendar year',
  };
}
