import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'DXCodeFormat'
})

// Formats the DXCode in the standard formatting 
// ex: input E11649 -> E11.649
export class DXCodeFormatPipe implements PipeTransform {
    transform(dx:string): string {
      if (!dx) return '';
      dx = dx.replace(/\s+/g, ''); // remove all whitespace 
      if (dx.length > 3 ){
        return `${dx.substring(0, 3)}.${dx.substring(3)}`      
      } else {
        return dx;
      }
    }
}
