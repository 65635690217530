import { EventEmitter, Injector, OnDestroy } from '@angular/core';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';
import { Subscription } from 'rxjs';
import { CarePrescriptionsComponent } from '../care-prescriptions.component';

import { formatDate as fmtDate } from '@angular/common';

import {
  compareDates,
  ClaimStatus,
  getDefaultPrescriptionData,
} from '../shared-lib';

import { CareFeatureConstants } from '@shared/models/module-constants';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { UserInfo } from '@shared/services/auth-service/auth-interface';

@Component({
  selector: 'coach-care-prescriptions-sidebar',
  templateUrl: './care-prescriptions-sidebar.component.html',
  styleUrls: ['./care-prescriptions-sidebar.component.scss'],
})
export class CarePrescriptionsSidebarComponent implements OnInit, OnDestroy {
  selectedPatientInfo: any = [];
  @Input() show: boolean;
  @Input() ceLocations;
  @Output() removeRow = new EventEmitter<boolean>();
  @ViewChild('mainPanel') mainPanel: ElementRef;
  @ViewChild('ceLocationsSelect') ceLocationsSelect: ElementRef;

  importFeatureFlag: string = CareFeatureConstants.FileImport340B;

  acoId: string;
  tier2Id: string;
  tier3Id: string;
  
  claimId: number;
  claimPk: number;
  claimType: string;
  prescriptionRefID: string;

  isValidationPanelOpen = false;
  isClaimSelected = false;
  toggleVisitNoteModal = false;
  toggleCareCoordinationNoteModal = false;
  toggleRejectionModal = false;

  selectedCeLocation: string;

  showClaimNote = false;

  prescriptionDetails: any;

  providerVisitDate: Date;

  visitDateIsValid = true;
  ceVisitDateIsValid = true;
  ceReferralDateIsValid = true;

  approveButtonEnabled = false;
  rejectButtonEnabled = false;

  debounceTimer: any = null;

  prescriptionSubscription: Subscription;
  authSubscription: Subscription;

  currentUser: UserInfo;
  creatorName: string;
  creatorDate: Date;

  constructor(
    private prescriptionService: PrescriptionService,
    public route: ActivatedRoute,
    private injector: Injector,
    private authService: AuthService
  ) {}

  ngOnInit(): void {

    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser
        this.creatorName = this.currentUser.name || this.currentUser.email;
        this.creatorDate = new Date();
      }
    );

    this.prescriptionSubscription =
      this.prescriptionService.claimInfoUpdated.subscribe((claimInfo: any) => {
        this.selectedPatientInfo = claimInfo?.selectedPatientInfo;
        this.ceLocations = claimInfo.ceLocations;
        this.claimPk = claimInfo.selectedPatientInfo.claimPk;
        this.claimId = claimInfo.claimId;
        this.acoId = claimInfo.selectedPatientInfo.acoId;
        this.tier2Id = claimInfo.selectedPatientInfo.tier2_id;
        this.tier3Id = claimInfo.selectedPatientInfo.tier3_id;
        this.prescriptionRefID = this.selectedPatientInfo.prescriptionRefID;
        this.claimPk = claimInfo.selectedPatientInfo.claimPk;
        this.claimType = claimInfo.selectedPatientInfo.claimType;

        if (!this.isValidationPanelOpen) {
          this.isValidationPanelOpen = !this.isValidationPanelOpen;
        }

        if (
          !this.selectedPatientInfo.follow_Up_Plan ||
          this.selectedPatientInfo.follow_Up_Plan === ''
        ) {
          this.setDefaultFollowUpPlan(this.selectedPatientInfo);
        }

        this.updatePrescriptionDetailsData(this.claimId, this.claimType);
        
        this.onUpdateProvider();
      });

    this.prescriptionDetails = getDefaultPrescriptionData(
      this.selectedPatientInfo.claimId,
      this.selectedPatientInfo.acoId,
      this.selectedPatientInfo.tier2_id,
      this.selectedPatientInfo.tier3_id,
      this.selectedPatientInfo.tier4_grpId,
      this.selectedPatientInfo.coach340BClaimsID
    );
  }

  setDefaultFollowUpPlan(data: any): void {
    data.follow_Up_Plan = `1. Continue referral to ${data.prescriberName}
2. Follow up with ${
      data.primaryProviderName ?? 'provider'
    } at [CE Location] within 30 days.
    `;
  }

  ngOnDestroy() {
    this.prescriptionSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }

  onRejectClaim(subsequentFillLogicOptOut: boolean = false) {
    let grid = this.injector.get(CarePrescriptionsComponent);
    grid.onRejectClaim(subsequentFillLogicOptOut);
    this.toggleValidatePanel();
  }

  removeClaimFromGrid(subsequentFillLogicOptOut) {
    let parentComponent = this.injector.get(CarePrescriptionsComponent);
    parentComponent.removeRow(subsequentFillLogicOptOut);
    this.toggleValidatePanel();
  }

  updateReferralDate(date) {
    this.prescriptionDetails.cE_Related_Referral_Date = date;
    this.validateClaim();
  }

  updateCeVisitDate(date) {
    this.prescriptionDetails.cE_Visit_Date = date;
    this.validateClaim();
  }

  updateVisitDate(date) {
    this.prescriptionDetails.visit_Note_Visit_Date = date;
    this.validateClaim();
  }

  updateVisitNoteMissing(status) {
    this.prescriptionDetails.visit_Note_Missing = status;
    this.validateClaim();
  }

  updateCeVisitNoteMissing(status) {
    this.prescriptionDetails.cE_Visit_Note_Missing = status;
    this.validateClaim();
  }

  updateCeRelatedReferralMissing(status) {
    this.prescriptionDetails.cE_Related_Referral_Missing = status;
    this.validateClaim();
  }

  onUpdateProvider() {
    setTimeout(() => {
      const pattern = /with [^]* at/;
      const updatedPlan = this.prescriptionDetails.follow_Up_Plan.replace(
        pattern,
        () => `with ${this.prescriptionDetails.cE_Provider} at`
      );
      this.prescriptionDetails.follow_Up_Plan = updatedPlan;

      this.onUpdateTypedText();
    }, 0);
  }

  onUpdateTypedText() {
    this.debouncedExecute(() => this.validateClaim(), 150);
  }

  debouncedExecute(func, timeMs) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      func();
      this.debounceTimer = null;
    }, timeMs);
  }

  onNoteRequested(isRequested: boolean) {
    this.prescriptionDetails.visit_Note_Requested_Date =
      new Date().toISOString();
    
    this.prescriptionDetails.claimPk = this.claimPk;
    this.prescriptionDetails.acoId = this.acoId;
    this.prescriptionDetails.tier2_id = this.tier2Id;
    this.prescriptionDetails.tier3_id = this.tier3Id;
    this.prescriptionDetails.claimType = this.claimType;

    this.prescriptionService.setPrescriptionDetail(this.prescriptionDetails)
      .then(() => {
        let parentComponent = this.injector.get(CarePrescriptionsComponent);
        if (this.prescriptionDetails.claim_Status === ClaimStatus.PENDING) {
          parentComponent.refreshGridAndUpdateStatus(ClaimStatus.PENDING);
        }
      })
      .then(() => {
        this.toggleValidatePanel();        
      });
  }

  onClickClaimNote() {
    this.showClaimNote = !this.showClaimNote;
  }

  saveClaimNote(note: string) {
    const status = this.selectedPatientInfo.claim_Status
      ? this.selectedPatientInfo.claim_Status
      : ClaimStatus.NEW;
    this.prescriptionDetails.claim_Note = note;
    this.savePrescriptionDetailsData(status);

    let parentComponent = this.injector.get(CarePrescriptionsComponent);
    if (this.prescriptionDetails.claim_Status === ClaimStatus.PENDING) {
      parentComponent.refreshGridAndUpdateStatus(ClaimStatus.PENDING);
    }
  }

  requestVisitNote() {
    this.toggleVisitNoteModal = !this.toggleVisitNoteModal;
  }

  requestCareCoordinationNote() {
    this.toggleCareCoordinationNoteModal =
      !this.toggleCareCoordinationNoteModal;
  }

  toggleRejectModal() {
    this.toggleRejectionModal = !this.toggleRejectionModal;
  }

  validateClaim(): boolean {
    const visitDate = this.prescriptionDetails.visit_Note_Visit_Date
      ? new Date(this.prescriptionDetails.visit_Note_Visit_Date)
      : null;
    const ceVisitDate = this.prescriptionDetails.cE_Visit_Date
      ? new Date(this.prescriptionDetails.cE_Visit_Date)
      : null;
    const referralDate = this.prescriptionDetails.cE_Related_Referral_Date
      ? new Date(this.prescriptionDetails.cE_Related_Referral_Date)
      : null;
    const filledDate = new Date(this.selectedPatientInfo.firstFilled);

    let claimIsValid = true;

    if (!visitDate || !compareDates(filledDate, visitDate)) {
      this.visitDateIsValid = false;
      claimIsValid = false;
    } else {
      this.visitDateIsValid = true;
    }

    if (!ceVisitDate || !compareDates(filledDate, ceVisitDate)) {
      this.ceVisitDateIsValid = false;
      claimIsValid = false;
    } else {
      this.ceVisitDateIsValid = true;
    }

    if (!referralDate || !compareDates(filledDate, referralDate)) {
      this.ceReferralDateIsValid = false;
      claimIsValid = false;
    } else {
      this.ceReferralDateIsValid = true;
    }

    if (
      this.prescriptionDetails.visit_Note_Missing ||
      this.prescriptionDetails.cE_Visit_Note_Missing ||
      this.prescriptionDetails.cE_Related_Referral_Missing ||
      this.prescriptionDetails.cE_Location === 'None' ||
      this.prescriptionDetails.cE_Provider.length < 3 ||
      !/\S/.test(this.prescriptionDetails.visit_Note_Diagnosis) ||
      !/\S/.test(this.prescriptionDetails.cE_Provider) ||
      !/\S/.test(this.prescriptionDetails.follow_Up_Plan)
    ) {
      claimIsValid = false;
    }

    this.approveButtonEnabled = claimIsValid;
    this.rejectButtonEnabled =
      this.prescriptionDetails.visit_Note_Missing ||
      this.prescriptionDetails.cE_Visit_Note_Missing ||
      this.prescriptionDetails.cE_Related_Referral_Missing
        ? true
        : false;

      this.prescriptionDetails.follow_Up_Plan = this.prescriptionDetails.follow_Up_Plan.replace(/<br \/>/g, '\n');

    return claimIsValid;
  }

  onRejectClicked() {
    this.savePrescriptionDetailsData(ClaimStatus.REJECTED);
    this.toggleValidatePanel();
  }

  updatePrescriptionDetailsData(claimId, claimType) {
    if (!this.isValidationPanelOpen) {
      this.toggleValidatePanel();
    }

    this.prescriptionService
      .getPrescriptionDetail(
        this.selectedPatientInfo.claimPk,
        this.selectedPatientInfo.acoId,
        this.selectedPatientInfo.tier2_id,
        this.selectedPatientInfo.tier3_id,
        this.selectedPatientInfo.tier4_grpId,
        claimId,
        this.selectedPatientInfo.userId,
        this.selectedPatientInfo
      )
      .then((data) => {
        if (!data) {
          data = getDefaultPrescriptionData(
            claimId,
            this.selectedPatientInfo.acoId,
            this.selectedPatientInfo.tier2_id,
            this.selectedPatientInfo.tier3_id,
            this.selectedPatientInfo.tier4_grpId,
            this.selectedPatientInfo.coach340BClaimsID
          );
        }

        if (!data.follow_Up_Plan || data.follow_Up_Plan === '') {
          data.follow_Up_Plan = this.selectedPatientInfo.follow_Up_Plan;
        }

        this.prescriptionDetails = data;
        this.prescriptionDetails.visitProvider = this.selectedPatientInfo.prescriberName;

        this.selectedPatientInfo.ceVisitDate = this.prescriptionDetails.cE_Visit_Date;
        this.selectedPatientInfo.ceRelatedReferralDate = this.prescriptionDetails.cE_Related_Referral_Date

        this.selectedCeLocation =
          data.cE_Location && data.cE_Location !== ''
            ? data.cE_Location
            : 'None';
        this.ceLocationsSelect.nativeElement.value = this.selectedCeLocation;
        this.updateFollowUpPlan();

        this.validateClaim();

        data.visit_Note_Visit_Date = fmtDate(
          new Date(data.visit_Note_Visit_Date),
          'yyyy-MM-dd',
          'en-US'
        );
        data.cE_Visit_Date = fmtDate(
          new Date(data.cE_Visit_Date),
          'yyyy-MM-dd',
          'en-US'
        );
        data.cE_Related_Referral_Date = fmtDate(
          new Date(data.cE_Related_Referral_Date),
          'yyyy-MM-dd',
          'en-US'
        );

        if (this.isDefaultDate(data.visit_Note_Visit_Date)) {
          data.visit_Note_Visit_Date = 'mm/dd/yyyy';
        }
        if (this.isDefaultDate(data.cE_Visit_Date)) {
          data.cE_Visit_Date = 'mm/dd/yyyy';
        }
        if (this.isDefaultDate(data.cE_Related_Referral_Date)) {
          data.cE_Related_Referral_Date = 'mm/dd/yyyy';
        }

        const oldRejectState = this.rejectButtonEnabled;
        const oldApproveState = this.approveButtonEnabled;
        this.rejectButtonEnabled = false;
        this.approveButtonEnabled = false;
        setTimeout(() => {
          this.rejectButtonEnabled = oldRejectState;
          this.approveButtonEnabled = oldApproveState;
        }, 1000);
      });
  }

  isDefaultDate(date): boolean {
    return date == '1969-12-31' || date == '1970-01-01';
  }

  updateFollowUpPlan() {
    const match = /at (.+) within/;
    const oldValue = this.prescriptionDetails.follow_Up_Plan.match(match);

    if (oldValue) {
      const startIndex = oldValue.input.indexOf(oldValue[1]);
      const endIndex = startIndex + oldValue[1].length;
      const newValue = `${oldValue.input.substring(0, startIndex)}${
        this.ceLocationsSelect.nativeElement.value
      } ${oldValue.input.substring(endIndex + 1)}`;
      this.prescriptionDetails.follow_Up_Plan = newValue;
    }

    this.selectedCeLocation = this.ceLocationsSelect.nativeElement.value;
    this.prescriptionDetails.cE_Location = this.selectedCeLocation;
  }

  validateHasNote(): boolean {
    try {
      const match = this.prescriptionDetails.visit_Note_Visit_Date.match(
        /(\d){4}-(\d){2}-(\d){2}/
      );

      if (!match) {
        return false;
      }

      const visitDate = new Date(match[0]);
      const firstFillDate = new Date(
        this.formatDate(this.selectedPatientInfo.firstFilled)
      );

      if (visitDate > firstFillDate) {
        return false;
      } else {
        return true && this.prescriptionDetails.visit_Note_Diagnosis;
      }
    } catch (e) {
      if (this.prescriptionDetails.visit_Note_Visit_Date == null) {
        return false;
      }
      const year = this.prescriptionDetails.visit_Note_Visit_Date.getFullYear();

      return (
        this.prescriptionDetails.visit_Note_Visit_Date &&
        year !== 1969 &&
        year != 1970 &&
        this.prescriptionDetails.visit_Note_Diagnosis
      );
    }
  }

  async onSaveAsPending(labelText) {
    let status = this.validateHasNote()
      ? ClaimStatus.HAS_NOTE
      : ClaimStatus.PENDING;

    this.prescriptionDetails.userId = this.selectedPatientInfo.userId;

    await this.savePrescriptionDetailsData(status);

    let parentComponent = this.injector.get(CarePrescriptionsComponent);
    parentComponent.changeClaimStatus(status);
    this.toggleValidatePanel();
  }

  async savePrescriptionDetailsData(status: ClaimStatus): Promise<any> {
    if (!this.prescriptionDetails) {
      this.prescriptionDetails = getDefaultPrescriptionData(
        this.selectedPatientInfo.claimId,
        this.selectedPatientInfo.acoId,
        this.selectedPatientInfo.tier2_id,
        this.selectedPatientInfo.tier3_id,
        this.selectedPatientInfo.tier4_grpId,
        this.selectedPatientInfo.coach340BClaimsID
      );
    }

    this.prescriptionDetails.claim_Status = status;
    this.prescriptionDetails.claim_Status_Set_By = this.creatorName;
    this.prescriptionDetails.claim_Status_Set_On_Date = this.creatorDate.toISOString();

    this.prescriptionDetails.cE_Location =
      this.ceLocationsSelect.nativeElement.value;

    const isRejected = status === ClaimStatus.REJECTED;

    this.prescriptionDetails.acoId = this.selectedPatientInfo.acoId;
    this.prescriptionDetails.claimPk = this.selectedPatientInfo.claimPk;
    this.prescriptionDetails.tier2_id = this.selectedPatientInfo.tier2_id;
    this.prescriptionDetails.tier3_id = this.selectedPatientInfo.tier3_id;
    this.prescriptionDetails.tier4_grpId = this.selectedPatientInfo.tier4_grpId;

    this.prescriptionDetails.claimType = this.selectedPatientInfo.claimType;

    this.prescriptionDetails.selectedPatientInfo = this.selectedPatientInfo;

    if (this.prescriptionDetails.visit_Note_Visit_Date == 'mm/dd/yyyy') {
      this.prescriptionDetails.visit_Note_Visit_Date = '1970-01-01';
    }
    if (this.prescriptionDetails.cE_Visit_Date == 'mm/dd/yyyy') {
      this.prescriptionDetails.cE_Visit_Date = '1970-01-01';
    }
    if (this.prescriptionDetails.cE_Related_Referral_Date == 'mm/dd/yyyy') {
      this.prescriptionDetails.cE_Related_Referral_Date = '1970-01-01';
    }

    if (this.prescriptionDetails.visit_Note_Visit_Date == '') {
      this.prescriptionDetails.visit_Note_Visit_Date = null;
    }
    if (this.prescriptionDetails.cE_Visit_Date == '') {
      this.prescriptionDetails.cE_Visit_Date = null;
    }
    if (this.prescriptionDetails.cE_Related_Referral_Date == '') {
      this.prescriptionDetails.cE_Related_Referral_Date = null;
    }
    let result = await this.prescriptionService.setPrescriptionDetail(
      this.prescriptionDetails,
      isRejected
    );
    if (this.prescriptionDetails.visit_Note_Visit_Date == null) {
      this.prescriptionDetails.visit_Note_Visit_Date = '';
    }
    if (this.prescriptionDetails.cE_Visit_Date == null) {
      this.prescriptionDetails.cE_Visit_Date = '';
    }
    if (this.prescriptionDetails.cE_Related_Referral_Date == null) {
      this.prescriptionDetails.cE_Related_Referral_Date = '';
    }

    return result;
  }

  setCeLocation(ceLocation: string): void {}

  updateCeLocationInFollowUpPlan() {
    this.updateFollowUpPlan();
    this.validateClaim();
  }

  public formatDate(dateStr) {
    return new Date(dateStr).toLocaleDateString();
  }

  public formatPhone(value) {
    if (!value) {
      return '';
    }

    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  }

  public getPatientSex() {
    return this.selectedPatientInfo?.patientSex?.startsWith('M') ||
      this.selectedPatientInfo.patientSex.startsWith('m')
      ? 'Male'
      : 'Female';
  }

  public toggleValidatePanel() {
    this.isValidationPanelOpen = !this.isValidationPanelOpen;
  }
}
