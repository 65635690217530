<ng-container *ngIf="isSidebarOpen">
    <div class="sidebar-account row border-top--white u-align-items--center u-color--contrast">
        <div class="account-profile u-truncate padding">
            <div class="row u-align-items--center" date-cy="userInfoName_sidebar" *ngIf= "(isAuthenticated$|async)">
                <coach-icon class="u-preserve margin-right--smaller" iconName="user" iconColor="currentColor"></coach-icon>
                <div class="field u-bold u-truncate" id="userInfoName" *ngIf="userInfo$|async as userInfo">
                    {{userInfo.name ? userInfo.name : userInfo.email}}
                </div>
                <!-- <coach-icon class="u-preserve margin-left--smaller" iconName="chevron-down" iconSize="small" iconColor="currentColor"></coach-icon> -->
            </div>
        </div>
        <div class="account-signout padding-leftRight--small u-flex--right u-preserve column u-justify-content--center">
            <button class="button is-small is-contrast margin-topBottom--small" aria-label="Sign out"
                (click)="signInOrOutClick($event)" ng-dblclick="return false">
                <div *ngIf= "(isAuthenticated$|async)">Sign out</div>
                <div *ngIf= "!(isAuthenticated$|async)">Sign in</div>
            </button>
        </div>
        <!-- Will bring back the dropdown in a future release -->
        <!-- <ng-template
        cdkConnectedOverlay>
        <ng-template [cdkPortalOutlet]="sidebarAccountDropdownPortal"></ng-template>
        </ng-template> -->
    </div>
</ng-container>
<ng-container *ngIf="!isSidebarOpen">
    <div class="sidebar-item" (click)="signInOrOutClick($event)">
        <div class="sidebar-item-icon">
            <coach-icon
            iconName="user"
            iconColor="currentColor"></coach-icon>
        </div>
        <div class="sidebar-item-label" *ngIf= "(isAuthenticated$|async)">Sign Out</div>
        <div class="sidebar-item-label" *ngIf= "!(isAuthenticated$|async)">Sign In</div>
      </div>
</ng-container>
