import { Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit, HostBinding } from '@angular/core';
import moment from 'moment';
import { formatDate } from '../shared-lib';

export type FilterOpenState = 'is-open' | 'is-closed';
@Component({
  selector: 'coach-care-prescriptions-filters',
  templateUrl: './care-prescriptions-filters.component.html',
  styleUrls: ['./_care-prescriptions-filters.scss']
})
export class CarePrescriptionsFiltersComponent implements OnInit {
  public isPatientFiltersOpen: boolean = false;

  // The api object of the relevant ag-grid
  @Input() gridApi;
  @Input() UIUpdates340BFeatureFlag: boolean = false;
  @Input() customDateFilterLabel: string = 'Fill Date (Month/Year)';

  // Configure the filter by passing the following data:
  // [...] = { headerLabel, optionLabels, filterInstanceLabel }
  @Input() configData;
  
  @Output() onFilterChanged = new EventEmitter<boolean>();
  @Output() onRadioClicked = new EventEmitter<Object>();
  
  dateFilterMonthCount: number = 12;
  isInitialDateSelected: boolean = false;

  selectedOptionValues = null;

  filterOptionMap = new Map([
    ['Pending', 'pendingDate'],
    ['Invoicing', 'invoicingDate'],
    ['Invoiced', 'invoicedDate'],
    ['Rejected', 'rejectedDate'],
    ['Rejected Needs Credit', 'rejectedNeedsCreditDate'],
    ['Credited', 'creditedDate'],
  ]);
  
  filterByDateAndStatus: boolean = false;
  currentRadioFilter: string = 'Invoicing';
  dateOptions: string[];
  selectedDateModel: string;
  selectedDateLabel: string = 'All';
  selectedDateStr: string = null;
  activeDropdownPointer: number | 'month' | null = null;
  @HostBinding('class')
  get pclass() {
    const sharedClasses = 'queue u-position--relative';
    return this.isPatientFiltersOpen ? `is-open ${sharedClasses}` : `is-closed ${sharedClasses}`;
  }

  lastSelectedRadioFilter = null;

  constructor() { }

  async ngOnInit() {
    this.dateOptions = this.createDateOptions(this.configData);
    this.selectedDateModel = this.dateOptions[0]
    this.isPatientFiltersOpen = this.UIUpdates340BFeatureFlag ? true : false;
  }

  private createDateOptions(config) {
    const monthHeaderConfig = this.configData.find(obj => obj.customMonthLabelPresent);
    this.customDateFilterLabel = monthHeaderConfig ? monthHeaderConfig.customMonthLabel : this.customDateFilterLabel;
    this.filterByDateAndStatus = monthHeaderConfig ? monthHeaderConfig.filterByDateAndStatus : false;

    const dates = this.generateDates();
    dates.unshift('All');
    return dates;
  }

  generateDates(): Array<string> {
    const dates = [];

    var monthName = new Array(
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    );
    var d = new Date();
    d.setDate(1);
    // The use of hardcoded values for the maximum month and year is a temporary fix until we implement more thorough logic
    // for detecting the first month that claims were invoiced for a particular community.
    for (let i = 0; d.getMonth() !== 6 || d.getFullYear() !== 2021; i++) {
      dates.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
      d.setMonth(d.getMonth() - 1);
    }

    return dates;
  }

  onDateSelected(e) {
    this.isInitialDateSelected = true;

    this.selectedDateStr = e.target.value.includes('All') ? 'All' : e.target.value;
    const dateStr = `1 ${e.target.value}`;
    if (dateStr.includes('All')) {
      if (this.filterByDateAndStatus) {
        this.filterByDateAndByStatus(
          `1 January 2020`,
          `31 December ${new Date().getFullYear()}`,
          true
        );
      } else {
        this.filterByDate(
          `1 January 2020`,
          `31 December ${new Date().getFullYear()}`
        );
      }
      this.selectedDateLabel = 'All'
    } else {
      if (this.filterByDateAndStatus) {
        this.filterByDateAndByStatus(dateStr);
      } else {
        this.filterByDate(dateStr);
      }
      this.selectedDateLabel = moment(dateStr).format('MMMM yyyy') // THIS LINE MAY NEED TO MOVE UP INTO THE ELSE ABOVE IT
    }
    this.onFilterChanged.emit(true);
  }

  clearRadioDateFilters(statusOption) {
    const labels = statusOption.optionLabels;
    for (let label of labels) {
      const statusFilter = this.gridApi?.getFilterInstance(
        this.filterOptionMap.get(label)
      );
      statusFilter?.setModel(null);
    }
    this.gridApi?.onFilterChanged();
  }

  filterByDateAndByStatus(dateStr, endDateStr = null, doClear = false) {
    if (doClear) {
      this.isInitialDateSelected = false;
      this.clearRadioDateFilters(this.configData[0]);
      return;
    }
    
    const statusConfig = this.configData.find(c => c.headerLabel === 'Status');
    const msPerDay = 86400000;

    const date = new Date(dateStr);
    date.setHours(0, 0, 0, 0);
    let endDate = !endDateStr ? new Date(dateStr) : new Date(endDateStr);
    endDate.setHours(0, 0, 0, 0);

    if (!endDateStr) {
      endDate.setMonth(date.getMonth() + 1);
    }

    const ustart = date.getTime();
    const uend = endDate.getTime();

    const dates = [];

    for (let unix = ustart; unix <= uend; unix += msPerDay) {
      dates.push(new Date(unix));
    }

    if (
      dates[dates.length - 1].getMonth() !== dates[dates.length - 2].getMonth()
    ) {
      dates.pop();
    }

    const dateStrings = dates.map((date) => {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let result = `${year}/${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}`;
      return result;
    });

    if (this.lastSelectedRadioFilter) {
      let statusFilter = this.gridApi?.getFilterInstance(
        this.filterOptionMap.get(this.lastSelectedRadioFilter)
      );

      statusFilter?.setModel(null);
      this.lastSelectedRadioFilter = null;
    }
    
    const statusFilter = this.gridApi?.getFilterInstance(
      this.filterOptionMap.get(this.currentRadioFilter)
    );

    statusFilter?.setModel({
      type: 'set',
      values: dateStrings,
    });
    
    this.lastSelectedRadioFilter = this.currentRadioFilter;
    this.gridApi?.onFilterChanged();
  }

  filterByDate(dateStr, endDateStr = null) {
    const dateHeaderLabel = 'fillDate';
    const msPerDay = 86400000;

    const date = new Date(dateStr);
    date.setHours(0, 0, 0, 0);
    let endDate = !endDateStr ? new Date(dateStr) : new Date(endDateStr);
    endDate.setHours(0, 0, 0, 0);

    if (!endDateStr) {
      endDate.setMonth(date.getMonth() + 1);
    }

    const ustart = date.getTime();
    const uend = endDate.getTime();

    const dates = [];

    for (let unix = ustart; unix <= uend; unix += msPerDay) {
      dates.push(new Date(unix));
    }

    if (
      dates[dates.length - 1].getMonth() !== dates[dates.length - 2].getMonth()
    ) {
      dates.pop();
    }

    const dateStrings = dates.map((date) => {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let result = `${year}/${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}`;
      return result;
    });

    const statusFilterInstance = this.gridApi?.getFilterInstance(
      dateHeaderLabel
    );

    statusFilterInstance?.setModel({
      type: 'set',
      values: dateStrings,
    });

    this.gridApi?.onFilterChanged();
  }

  filterDoneTimeout = null;

  onRadioChecked(event) {
    this.currentRadioFilter = event.option.name;

    let e = {target: {value: this.selectedDateStr ?? 'Any'}}
    this.onDateSelected(e);
  }
  
  onOptionChecked(event) {
    let optionValues = event.options.isArray;

    let config = this.configData.find(
      (config) => config.headerLabel === event.headerLabel
    );
    if (!config) {
      return;
    }

    if (config.dataMapper) {
      optionValues = optionValues.map((option) => config.dataMapper(option));
    }

    if (this.filterByDateAndStatus && config.headerLabel === 'Status') {
      this.selectedOptionValues = optionValues;
    } else {
      this.selectedOptionValues = null;
    }
    
    const statusFilterInstance = this.gridApi?.getFilterInstance(
      config.filterInstanceLabel
    );
    statusFilterInstance?.setModel({
      type: 'set',
      values: optionValues,
    });

    if (this.filterDoneTimeout) {
      clearTimeout(this.filterDoneTimeout);
      this.filterDoneTimeout = null;
    }

    this.filterDoneTimeout = setTimeout(() => {
      this.gridApi?.onFilterChanged();
      this.onFilterChanged.emit(true);
      this.filterDoneTimeout = null;
    }, 25);

  }

  public togglePatientFilters(): void {
    this.isPatientFiltersOpen = !this.isPatientFiltersOpen;
  }

  getChevronStatus(dropdownPointer?: number | 'month'): string {
    if (!dropdownPointer) {
      dropdownPointer = 'month'
    }
    return this.activeDropdownPointer == dropdownPointer ? 'chevron-up' : 'chevron-down';
  }
  openAccordion(dropdownPointer: number | 'month'): void {
    this.activeDropdownPointer = this.activeDropdownPointer == dropdownPointer ? null : dropdownPointer;
  }
  checkAllOptions(): void {
    this.onDateSelected({ target: { value: 'All' } })
    this.selectedDateModel = this.dateOptions[0]
  }
}
