<div [ngClass]="{'widget margin--large u-height--fit-content': !sidebarMode }">
  <div class="header" *ngIf="!sidebarMode" (click)="isTabOpen = !isTabOpen">
    <div class="titles">
      <div class="title">
        <coach-patient-awv-banner [minimalView]="true" [patient]="patient" [awvWorkflowStatus]=awvWorkflowStatus [isLoading]="isLoading"></coach-patient-awv-banner>
        <coach-patient-awv-error [inError]="inError" [errorTitle]="errorTitle" [errorMessage]="errorMessage"></coach-patient-awv-error>
      </div>
    </div>
    <coach-icon class="u-cursor--pointer" iconSize="large" [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" *ngIf="!isInactiveStatus()"></coach-icon>
  </div>
  <div  *ngIf="patient && isTabOpen && !isInactiveStatus()"  class="pacnav-content-wrapper u-width--full" [ngClass]="{'u-flex-wrap--wrap': mobileScreenSize}">
    <div class="u-display--flex u-flex-direction--column padding-leftRightTopBottom--small" [ngClass]="{'u-width--webkit-fill-available': mobileScreenSize}">
      <div class="u-height--fit-content u-display--flex u-flex-direction--row u-justify-content--spaceBetween">
        <div class="left-cont" [ngClass]="{'margin--large': !mobileScreenSize}">
          <coach-patient-last-awv-details [patient]="patient" *ngIf="!inError"></coach-patient-last-awv-details>
          <coach-patient-awv-enrol [patient]="patient" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Other && isLowerEnvironment() && !inError"></coach-patient-awv-enrol>
          <coach-patient-awv-scheduling *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.ReadyForOutreach || awvWorkflowStatus == allAwvWorkflowStatuses.OutreachAttempted" [patient]="patient" [awvWorkflow]="awvWorkflow" [tier]="tier" [mobileScreenSize]="true" [sidebarMode]="true" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" [isTabOpen]="true"></coach-patient-awv-scheduling>
          <coach-patient-awv-reminder *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Scheduled || (awvWorkflowStatus == allAwvWorkflowStatuses.Completed && attendedActivityWasSubmitted === true)" [patient]="patient" [awvWorkflow]="awvWorkflow" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" (activityDeleted)="activityDeleted($event)"></coach-patient-awv-reminder>
          <coach-patient-awv-removed *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Declined || awvWorkflowStatus == allAwvWorkflowStatuses.Ineligible" [patient]="patient" [awvWorkflow]="awvWorkflow" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" (activityDeleted)="activityDeleted($event)"></coach-patient-awv-removed>
          <coach-patient-awv-completed *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Completed && attendedActivityWasSubmitted === false" [patient]="patient" [awvWorkflow]="awvWorkflow" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" (activityDeleted)="activityDeleted($event)"></coach-patient-awv-completed>
          <coach-patient-awv-deferred *ngIf="awvWorkflowStatus === allAwvWorkflowStatuses.DeferredToSignify"></coach-patient-awv-deferred>
        </div>
        <div *ngIf="!mobileScreenSize" class="u-position--relative">
          <div class="vertical-line u-position--absolute"></div>
        </div>
        <div class="right-cont" [ngClass]="{'margin--large': !mobileScreenSize}">
          <coach-patient-awv-history *ngIf="awvWorkflowStatus != allAwvWorkflowStatuses.Other" [awvWorkflow]="awvWorkflow" [patientId]="patient.chPatId"></coach-patient-awv-history>
        </div>
      </div>
      <coach-patient-awv-loading [isLoading]="isLoading"></coach-patient-awv-loading>
    </div>
  </div>
</div>
