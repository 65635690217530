<label class="label margin-top">Year</label>
<select name="year" [(ngModel)]="selectedYearItem" (ngModelChange)="yearSelected($event)">
    <option *ngFor="let year of years" [ngValue]="year">
        {{year.display}}
    </option>
</select>

<ng-container *ngIf="!reportConfig?.hideQuarter">
    <label class="label margin-top">{{reportConfig?.quarterLabel ? reportConfig.quarterLabel : 'Quarter'}}
    </label>
    <select name="quarter" [(ngModel)]="selectedQuarterItem" (ngModelChange)="quarterSelected($event)">
        <option *ngFor="let quarter of quarters" [ngValue]="quarter">
            {{quarter.display}}
        </option>
    </select>
</ng-container>