import { Pipe, PipeTransform } from '@angular/core';
import differenceInYears from 'date-fns/differenceInYears';

@Pipe({
    name: 'age'
})

export class GetAgeFromDOB implements PipeTransform {
    transform(value:number): number {
      if (value) {
        let age = differenceInYears(new Date(), new Date(value));
        return age
      } else {
        return null;
      }
    }
}
