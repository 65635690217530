<div class="dynamicBody padding--responsive">
  <div class="div">
    <section class="bump padding--larger margin-bottom--larger">
      <h6 class="margin-top--none">Default Typeface</h6>
      <div class="row--baseline">
        <h1 class="margin--none u-fill" style="font-size: 6.5rem">Lato</h1>
      </div>
      <div class="row--baseline margins-right margin-top">
        <div>Regular</div>
        <div class="u-bold">Bold</div>
        <div class="u-bolder">Bolder</div>
        <div class="u-thin">Light</div>
        <div class="u-italic">Italic</div><a class="u-right"
          href="https://fonts.google.com/specimen/Lato?selection.family=Lato" target="_blank">View Specimen</a>
      </div>
    </section>
    <coach-care-test></coach-care-test>
    <div class="row--wrap">
      <div class="column span--8 margins-left--none">
        <h1 class="margin-topBottom--small">Header One h1. <span
            class="u-opacity--lighter margin-left--large u-font-size--small u-font-weight--normal u-text-transform--lowercase">Rendered
            at 40px @ 1.6rem base font config</span></h1>
        <h2 class="margin-topBottom--small">Header Two h2. <span
            class="u-opacity--lighter margin-left--large u-font-size--small u-font-weight--normal u-text-transform--lowercase">32px
            @ 1.6rem</span></h2>
        <h3 class="margin-topBottom--small">Header Three h3. <span
            class="u-opacity--lighter margin-left--large u-font-size--small u-font-weight--normal u-text-transform--lowercase">26px
            @ 1.6rem</span></h3>
        <h4 class="margin-topBottom--small">Header Four h4. <span
            class="u-opacity--lighter margin-left--large u-font-size--small u-font-weight--normal u-text-transform--lowercase">20px
            @ 1.6rem</span></h4>
        <h5 class="margin-topBottom--small">Header Five h5. <span
            class="u-opacity--lighter margin-left--large u-font-size--small u-font-weight--normal u-text-transform--lowercase">14px
            @ 1.6rem</span></h5>
        <h6 class="margin-topBottom--small">Header Six h6. <span
            class="u-opacity--lighter margin-left--large u-font-size--small u-font-weight--normal u-text-transform--lowercase">14px
            @ 1.6rem</span></h6>
        <div class="margins-bottom--large margin-top--larger">
          <div class="titles t1">
            <div class="title">Titles t1 &gt; Title</div>
            <div class="subtitle">subtitle down below</div>
          </div>
          <div class="titles t2">
            <div class="title">Titles t2 &gt; Title</div>
            <div class="subtitle">subtitle down below</div>
          </div>
          <div class="titles t3">
            <div class="title">Titles t3 &gt; Title</div>
            <div class="subtitle">subtitle down below</div>
          </div>
          <div class="titles t4">
            <div class="title">Titles t4 &gt; Title</div>
            <div class="subtitle">subtitle down below</div>
          </div>
          <div class="titles t5">
            <div class="title">Titles t5 &gt; Title</div>
            <div class="subtitle">subtitle down below</div>
          </div>
          <div class="titles t6">
            <div class="title">Titles t6 &gt; Title</div>
            <div class="subtitle">subtitle down below</div>
          </div>
          <div class="titles">
            <div class="title">Titles &gt; Title Above</div>
            <div class="subtitle">subtitle down below</div>
          </div>
        </div>
        <div class="subheader span--5 margin-top--larger margin-bottom--larger">Subheader</div>
      </div>
      <div class="column span--4 margin-top--large">
        <h5 class=" margin-top--none">Native Typography Elements</h5>
        <div>
          <p>Lorem ipsum <b>b tag</b> along with <strong>strong tag </strong> and <small>small tag</small> dolor amet <a
              href="#">Hyperlink Text</a> seitan pork belly unicorn put a bird on it ramps. Cred deep v <sup>level</sup>
            sustainable offal.
            <u>Microdosing</u> wolf VHS distillery. Messenger bag meh ethical fixie, 3 wolf <sub>portland</sub> moon
            next level lomo humblebrag XOXO street art salvia.</p>
        </div>
        <div>
          <div class="row--baseline margins--topBottom--large">
            <h6 class="u-fill">Light</h6>
            <p>A bit lighter, WCAG</p>
          </div>
          <p class="u-opacity--light">Lorem ipsum <b>b tag</b> along with <strong>strong tag </strong> and <small>small
              tag</small> dolor amet <a href="#">Hyperlink Text</a> seitan pork belly unicorn put a bird on it ramps.
            Cred deep v
            <sup>level</sup> sustainable offal. <u>Microdosing</u> wolf VHS distillery. Messenger bag meh ethical fixie,
            3 wolf <sub>portland</sub> moon next level lomo humblebrag XOXO street art salvia.</p>
        </div>
        <div>
          <div class="row--baseline margins--topBottom--large">
            <h6 class="u-fill">lighter</h6>
            <p>Slightly lighter, WCAG</p>
          </div>
          <p class="u-opacity--lighter">Lorem ipsum <b>b tag</b> along with <strong>strong tag </strong> and
            <small>small tag</small> dolor amet <a href="#">Hyperlink Text</a> seitan pork belly unicorn put a bird on
            it ramps. Cred deep v
            <sup>level</sup> sustainable offal. <u>Microdosing</u> wolf VHS distillery. Messenger bag meh ethical fixie,
            3 wolf <sub>portland</sub> moon next level lomo humblebrag XOXO street art salvia.</p>
        </div>
        <div>
          <div class="row--baseline margins--topBottom--large">
            <h6 class="u-fill">lightest</h6>
            <p>Much lighter, <b>Fails</b> WCAG</p>
          </div>
          <p class="u-opacity--lightest">Lorem ipsum <b>b tag</b> along with <strong>strong tag </strong> and
            <small>small tag</small> dolor amet <a href="#">Hyperlink Text</a> seitan pork belly unicorn put a bird on
            it ramps. Cred deep v
            <sup>level</sup> sustainable offal. <u>Microdosing</u> wolf VHS distillery. Messenger bag meh ethical fixie,
            3 wolf <sub>portland</sub> moon next level lomo humblebrag XOXO street art salvia.</p>
        </div>
      </div>
    </div>
    <div class="margins-bottom">
      <div class="d1">Display One</div>
      <div class="d2">Display Two</div>
      <div class="d3">Display Three</div>
      <div class="d4">Display Four</div>
      <div class="d5">Display Five</div>
      <div class="d6">Display Six</div>
    </div>
    <div class="margin-top--largest margins-top--large">
      <h5>Font sizes</h5>
      <div class="row--wrap paddings-right--large">
        <div class="span--4 u-font-size--small"><b>u-font-size--small</b> Lorem ipsum dolor amet tofu keffiyeh glossier
          craft beer sartorial. Banh mi vice mixtape, keytar celiac next level raw denim. Crucifix food truck hexagon,
          tousled raclette
          thundercats</div>
        <div class="span--4"><b>Default</b> Lorem ipsum dolor amet tofu keffiyeh glossier craft beer sartorial. Banh mi
          vice mixtape, keytar celiac next level raw denim. Crucifix food truck hexagon, tousled raclette thundercats
        </div>
        <div class="span--4 u-font-size--large"><b>u-font-size--large</b> Lorem ipsum dolor amet tofu keffiyeh glossier
          craft beer sartorial. Banh mi vice mixtape, keytar celiac next level raw denim. Crucifix food truck hexagon,
          tousled raclette
          thundercats</div>
      </div>
    </div>
    <div class="u-break--larger"></div>
    <h5>Interaction Color for buttons,etc</h5>
    <div class="row u-fills u-border-radius u-overflow--hidden margin-topBottom--large u-width--full">
      <div class="padding--large" style="background-color: var(--interaction--darkest)"></div>
      <div class="padding--large" style="background-color: var(--interaction--darker)"></div>
      <div class="padding--large" style="background-color: var(--interaction--dark)"></div>
      <div class="padding--large d4 u-text-align--center"
        style="background-color: var(--interaction); color: var(--interaction--contrast)">Interaction</div>
      <div class="padding--large" style="background-color: var(--interaction--light)"></div>
      <div class="padding--large" style="background-color: var(--interaction--lighter)"></div>
      <div class="padding--large" style="background-color: var(--interaction--lightest)"></div>
    </div>
    <h5>UX States</h5>
    <div class="row margins-inline--large">
      <div class="padding--large d4 u-border-radius list-item is-active">Active State</div>
      <div class="padding--large d4 u-border-radius"
        style="outline: none; box-shadow:0 0 0.2rem 0.2rem var(--focus, var(--interaction--light))">Focused State</div>
    </div>
    <h5>Alert Colors</h5>
    <div class="row--wrap row--top margins-inline--large paddings--large margin-top">
      <div class="u-border-radius u-colors--info">Info</div>
      <div class="u-border-radius u-colors--success">Success</div>
      <div class="u-border-radius u-colors--warning">Warning</div>
      <div class="u-border-radius u-colors--danger">Danger</div>
    </div>
    <h5>Brand/Theme Colors</h5>
    <div class="row--wrap row--top margins-inline--large paddings--large margin-top">
      <!--bindings={
  "ng-reflect-ng-for-of": "1,2,3,4,5,6"
}-->
      <div
        class="u-border-radius row--baseline u-justify-content--center margins--none u-background-color--brand--1  paddings-leftRight--larger">
        <div class="u-color--brand--1--contrast d4">1</div>
      </div>
      <div
        class="u-border-radius row--baseline u-justify-content--center margins--none u-background-color--brand--2  paddings-leftRight--larger">
        <div class="u-color--brand--2--contrast d4">2</div>
      </div>
      <div
        class="u-border-radius row--baseline u-justify-content--center margins--none u-background-color--brand--3  paddings-leftRight--larger">
        <div class="u-color--brand--3--contrast d4">3</div>
      </div>
      <div
        class="u-border-radius row--baseline u-justify-content--center margins--none u-background-color--brand--4  paddings-leftRight--larger">
        <div class="u-color--brand--4--contrast d4">4</div>
      </div>
      <div
        class="u-border-radius row--baseline u-justify-content--center margins--none u-background-color--brand--5  paddings-leftRight--larger">
        <div class="u-color--brand--5--contrast d4">5</div>
      </div>
      <div
        class="u-border-radius row--baseline u-justify-content--center margins--none u-background-color--brand--6  paddings-leftRight--larger">
        <div class="u-color--brand--6--contrast d4">6</div>
      </div>
    </div>
    <h5>Gradients</h5>
    <div class="row--wrap row--top margins-inline--large paddings--large margin-top">
      <div class="bump u-fill u-gradient u-height--smallest row--center u-align-items--center">
        <div class="d3 u-color--white">Default</div>
      </div>
    </div>
    <div class="row--wrap row--top margins-inline--large paddings--large margin-top">
      <div class="bump u-fill u-gradient--cool u-height--smallest row--center u-align-items--center">
        <div class="d5 u-color--white">Cool</div>
      </div>
      <div class="bump u-fill u-gradient--warm u-height--smallest row--center u-align-items--center">
        <div class="d5 u-color--white">Warm</div>
      </div>
      <div class="bump u-fill u-gradient--mix u-height--smallest row--center u-align-items--center">
        <div class="d5 u-color--white">Mix</div>
      </div>
    </div>
    <h5>Background Blacks/Whites</h5>
    <div class="row--wrap margins-inline">
      <div class="row--wrap row--top margins--larger paddings--large">
        <!--bindings={
  "ng-reflect-ng-for-of": "0,1,2,3,4,5"
}-->
        <div class="u-border-radius u-background-color--black--0">0</div>
        <div class="u-border-radius u-background-color--black--1">1</div>
        <div class="u-border-radius u-background-color--black--2">2</div>
        <div class="u-border-radius u-background-color--black--3">3</div>
        <div class="u-border-radius u-background-color--black--4">4</div>
        <div class="u-border-radius u-background-color--black--5">5</div>
      </div>
      <div class="row row--wrap row--top margins--larger paddings--large u-background-color--black--0">
        <!--bindings={
  "ng-reflect-ng-for-of": "0,1,2,3,4,5"
}-->
        <div class="u-border-radius u-background-color--white--0">0</div>
        <div class="u-border-radius u-background-color--white--1">1</div>
        <div class="u-border-radius u-background-color--white--2">2</div>
        <div class="u-border-radius u-background-color--white--3">3</div>
        <div class="u-border-radius u-background-color--white--4">4</div>
        <div class="u-border-radius u-background-color--white--5">5</div>
      </div>
    </div>
    <h5>Shadows</h5>
    <div class="row--wrap row--top margins-inline--large paddings--large margin-top--larger">
      <div class="u-box-shadow--0 u-border-radius--large">Shadow 0</div>
      <div class="u-box-shadow--1 u-border-radius--large">Shadow 1</div>
      <div class="u-box-shadow--2 u-border-radius--large">Shadow 2</div>
      <div class="u-box-shadow--3 u-border-radius--large">Shadow 3</div>
    </div>
    <h5>Default spacing sizes</h5>
    <div class="row margin-bottom--larger">
      <div class="padding-left--smallest u-height--smallest u-background-color--black--3" style="margin-right: 1rem;">
      </div>
      <div class="padding-left--smaller u-height--smallest u-background-color--black--3" style="margin-right: 1rem;">
      </div>
      <div class="padding-left--small u-height--smallest u-background-color--black--3" style="margin-right: 1rem;">
      </div>
      <div class="padding-left u-height--smallest u-background-color--black--3" style="margin-right: 1rem;"></div>
      <div class="padding-left--large u-height--smallest u-background-color--black--3" style="margin-right: 1rem;">
      </div>
      <div class="padding-left--larger u-height--smallest u-background-color--black--3" style="margin-right: 1rem;">
      </div>
      <div class="padding-left--largest u-height--smallest u-background-color--black--3"></div>
    </div>
    <div class="u-break--larger"></div>
    <h1>Atomic UI Elements</h1>
    <div class="row--wrap row--baseline margins--large margin-top--larger">
      <h1>h1</h1>
      <h2>h2</h2>
      <h3>h3</h3>
      <h4>h4</h4>
      <h5>h5</h5>
      <h6>h6</h6>
      <div class="d1">D1</div>
      <div class="d2">D2</div>
      <div class="d3">D3</div>
      <div class="d4">D4</div>
      <div class="d5">D5</div>
      <div class="d6">D6</div>
      <p>paragraph</p>
      <a>Anchor Tag</a>
      <label class="label">label</label>
      <svg class="icon">
        <use href="assets/icons/Magicons.svg#icon-settings"></use>
      </svg>
      <button class="button">Button</button>
      <button class="button is-primary">Button is-primary</button>
      <button class="button is-borderless">Button borderless</button>
      <button
        class="button is-primary is-small">Small Button</button><button class="button is-icon"><svg class="icon">
          <use href="assets/icons/Magicons.svg#icon-x"></use>
        </svg>
      </button>
      <button class="button is-icon border--none">
        <svg class="icon icon--default">
          <use href="assets/icons/Magicons.svg#icon-edit"></use>
        </svg>
      </button>
      <button class="button is-primary is-icon">
        <svg class="icon icon--white">
          <use href="assets/icons/Magicons.svg#icon-plus-circle"></use>
        </svg>
      </button>
      <button class="button is-icon is-primary is-small">
        <svg class="icon icon--white">
          <use href="assets/icons/Magicons.svg#icon-plus-circle"></use>
        </svg>
      </button>
      <button class="button">
        <svg class="icon">
          <use href="assets/icons/Magicons.svg#icon-edit"></use>
        </svg>
        <div>Edit</div>
      </button>
      <div><input checked="true" id="checkboxa" type="checkbox"><label for="checkbox1"></label></div>
      <div><input checked="true" id="radioa" type="radio"><label for="radioa"></label></div>
      <div><input checked="true" class="toggle" id="togglea" type="checkbox"><label for="togglea"></label></div>
      <div class="bean">Bean</div>
      <div class="bean bean--interactive"> Bean--interactive <svg class="icon margin-left--smaller">
          <use href="assets/icons/Magicons.svg#icon-x-circle"></use>
        </svg></div>
      <div class="bean is-small">Bean is-small</div><input class="u-width--medium" placeholder="Input type text..."
        type="text"><input class="u-width--medium" disabled="true" placeholder="Disabled Input type text..."
        type="text"><input class="u-width--medium" placeholder="Input type email..." type="email"><select
        class="u-width--medium" id="" name="selectdemo">
        <option value="Option 1" ng-reflect-value="Option 1">Select Option 1</option>
        <option value="Option 2" ng-reflect-value="Option 2">Select Option Two</option>
        <option value="Option 3" ng-reflect-value="Option 3">Select Option 3</option>
        <option value="Option 4" ng-reflect-value="Option 4">Select Option Four</option>
      </select><select class="u-width--medium" disabled="true" id="" name="selectdemo2">
        <option value="Option 1" ng-reflect-value="Option 1">Disabled Select</option>
        <option value="Option 2" ng-reflect-value="Option 2">Select Option Two</option>
        <option value="Option 3" ng-reflect-value="Option 3">Select Option 3</option>
        <option value="Option 4" ng-reflect-value="Option 4">Select Option Four</option>
      </select><select class="is-small u-width--medium" id="" name="selectdemo3">
        <option value="Option 1" ng-reflect-value="Option 1">Select small</option>
        <option value="Option 2" ng-reflect-value="Option 2">Select Option Two</option>
        <option value="Option 3" ng-reflect-value="Option 3">Select Option 3</option>
        <option value="Option 4" ng-reflect-value="Option 4">Select Option Four</option>
      </select><input class="u-width--medium" placeholder="datetime-local..." type="datetime-local"><input
        class="u-width--medium" placeholder="datetime..." type="datetime"><input autosave="some_unique_value"
        class="u-width--medium" name="s" placeholder="Input type search (avoid me)..." results="5" type="search"><input
        class="u-width--medium" placeholder="date..." type="date"><textarea class="u-width--large" name="ta1"
        placeholder="textarea... rows='6' " rows="6"></textarea><textarea class="u-width--large resize--both" name="ta2"
        placeholder="textarea with resize--both modifier..."></textarea>
    </div>
    <div class="row margins-inline">
      <ul class="u-fill">
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ul>
      <ul class="is-circle u-fill">
        <li>Item 1</li>
        <li>Item 2 <ul class="is-square">
            <li>Item 2.1</li>
            <li>Item 2.2</li>
            <li>Item 2.3</li>
          </ul>
        </li>
        <li>Item 3</li>
      </ul>
      <ol class="u-fill">
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ol>
      <ol class="u-fill">
        <li>Item 1</li>
        <li>Item 2 <ol>
            <li>Item 2.1</li>
            <li>Item 2.2</li>
            <li>Item 2.3</li>
          </ol>
        </li>
        <li>Item 3</li>
      </ol>
    </div>
    <div class="row--wrap borders margins--small">
      <div class="circle--smallest"></div>
      <div class="circle--smaller"></div>
      <div class="circle--small"></div>
      <div class="square--smallest"></div>
      <div class="square--smaller"></div>
      <div class="square--small"></div>
    </div>
  </div>

  <div class="row--wrap margins-inline">
    <div class="avi is-smallest">
      <img src="assets/img/mock-avatars/avatar-m-04.jpg">
    </div>
    <div class="avi is-smallest">
      JG
    </div>
    <div class="avi is-small">
      <img src="assets/img/mock-avatars/avatar-m-04.jpg">
    </div>
    <div class="avi is-small">
      JG
    </div>
    <div class="avi">
      <img src="assets/img/mock-avatars/avatar-m-04.jpg">
    </div>
    <div class="avi">
      JG
    </div>
    <div class="avi is-large">
      <img src="assets/img/mock-avatars/avatar-m-04.jpg">
    </div>
    <div class="avi is-large">
      JG
    </div>
    <div class="avi is-largest">
      <img src="assets/img/mock-avatars/avatar-m-04.jpg">
    </div>
    <div class="avi is-largest">
      JG
    </div>
  </div>



  <h1>Molecules</h1>

  <div class="list border u-width--larger margin--large">

    <div class="subheader">Ideal Patterns</div>
    <div class="list-item">
      <div class="row">
        <div class="field">list-item (plain)</div>
        <div class="field">Field 2</div>
      </div>
    </div>

    <div class="list-item">
      <div class="row">
        <div class="field">list-item (plain)</div>
        <div class="field">Field 2</div>
      </div>
      <div class="row">
        <div class="field">Third field here</div>
        <div class="field">#4</div>
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item--complex</div>
          <div class="field">Field 2</div>
        </div>
      </div>
      <div class="list-item-action">
        action
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item--complex</div>
          <div class="field">Field 2</div>
        </div>
        <div class="row">
          <div class="field">Third field here</div>
          <div class="field">#4</div>
        </div>
      </div>
      <div class="list-item-action">
        action
      </div>
    </div>

    <div class="list-item row--verticalCenter">
      <div class="list-item-avi">
        <div class="avi"><img src="assets/img/mock-avatars/avatar-m-02.jpg" class="img--circle"></div>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field">Robert "Robby" Smith</div>
          <div class="row--verticalCenter">
            <div class="badge margin-right--smallest">1</div>
            <div class="badge u-colors--info">2</div>
          </div>
        </div>
        <div class="row u-italic">
          <div class="field">I'm built with row--verticalCenter</div>
        </div>
      </div>
    </div>

    <div class="list-item">
      <div class="row row--verticalCenter">
        <coach-icon iconName="edit" iconColor="currentColor" class="margin-right--small"></coach-icon>
        <div class="field">list-item (plain)</div>
        <div class="field">Field 2</div>
      </div>
    </div>


    <div class="list-item row--verticalCenter">
      <div>
        <coach-icon iconName="clock" iconColor="currentColor" iconSize="large"></coach-icon>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item (plain)</div>
          <div class="field">Field 2</div>
        </div>
        <div class="row">
          <div class="field">Third field here</div>
          <div class="field">#4</div>
        </div>
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main row--verticalCenter">
        <div class="list-item-avi">
          <div class="avi"><img src="assets/img/mock-avatars/avatar-m-02.jpg" class="img--circle"></div>
        </div>
        <div class="list-item-main padding-left">
          <div class="row">
            <div class="field">Robert "Robby" Smith</div>
            <div class="row--verticalCenter">
              <div class="badge margin-right--smallest">1</div>
              <div class="badge u-colors--info">2</div>
            </div>
          </div>
          <div class="row u-italic">
            <div class="field">I'm built with list-item-complex</div>
          </div>
        </div>
      </div>
      <div class="list-item-action">
        action
      </div>
      <div class="list-item-action">
        2
      </div>
    </div>


    <div class="subheader">Stress Testing</div>

    <div class="list-item">
      <div class="row">
        <div class="field">Jeff Garlin</div>
        <div class="field">#9271</div>
      </div>
      <div class="row">
        <div class="field">Just a plain old list-item</div>
        <div class="field">11/12/1960</div>
      </div>
    </div>

    <div class="list-item">
      <div class="row">
        <div class="field">Jeff Garlin</div>
        <div class="field">#9271</div>
      </div>
      <div class="row">
        <div class="field">Jeffy@jeff.com</div>
        <div class="field">11/12/1960</div>
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item--complex</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="list-item-action">
        action
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item--complex</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="list-item-action">
        action
      </div>
    </div>

    <div class="list-item row--verticalCenter">
      <div class="list-item-avi">
        <div class="avi"><img src="assets/img/mock-avatars/avatar-m-02.jpg" class="img--circle"></div>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field">Robert "Robby" Smith</div>
          <div class="row--verticalCenter">
            <div class="badge margin-right--smallest">1</div>
            <div class="badge u-colors--info">2</div>
          </div>
        </div>
        <div class="row u-italic">
          <div class="field">I'm built with row--verticalCenter</div>
        </div>
      </div>
    </div>

    <div class="list-item list-item--complex list-item--singleHitArea">
      <div class="list-item-avi">
        <div class="avi"><img src="assets/img/mock-avatars/avatar-m-02.jpg" class="img--circle"></div>
      </div>
      <div class="list-item-main u-justify-content--center">
        <div class="row">
          <div class="field">Robert "Robby" Smith</div>
          <div class="row--verticalCenter">
            <div class="badge margin-right--smallest">1</div>
            <div class="badge u-colors--info">2</div>
          </div>
        </div>
        <div class="row u-italic">
          <div class="field">I'm built with list-item--complex and --singleHitArea</div>
        </div>
      </div>
    </div>

    <div class="list-item row row--verticalCenter">
      <div class="list-item-avi">
        <div class="avi">
          <img class="img--circle" src="assets/img/mock-avatars/avatar-m-01.jpg">
        </div>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item row row--verticalCenter</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">list-item row row--verticalCenter</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
    </div>



    <div class="list-item list-item--complex">
      <div class="list-item-main row--verticalCenter">
        <div class="list-item-avi margin-right--small">
          <div class="avi">
            <img class="img--circle" src="assets/img/mock-avatars/avatar-m-01.jpg">
          </div>
        </div>
        <div class="list-item-main">
          <div class="row">
            <div class="field">primary field</div>
            <div class="field">#9271</div>
          </div>
          <div class="row">
            <div class="field">list-item--complex > list-item-main row--verticalCenter</div>
            <div class="field">11/12/1960</div>
          </div>
        </div>
      </div>
      <div class="list-item-action">
        <coach-icon iconName="edit" iconColor="currentColor" class="u-flex--top"></coach-icon>
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item--complex</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="list-item-action">
        <coach-icon iconName="edit" iconColor="currentColor" class="u-flex--top"></coach-icon>
      </div>
    </div>

    <div class="list-item list-item--complex list-item--multipleClickAreas">
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item--multipleClickAreas</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="list-item-action">
        <coach-icon iconName="edit" iconColor="currentColor" class="u-flex--top"></coach-icon>
      </div>
      <div class="list-item-block u-hoverEffect--color">
        click area
      </div>
    </div>

    <div class="list-item list-item--complex list-item--multipleClickAreas">
      <div class="list-item-main">
        <div class="row">
          <div class="field">list-item--multipleClickAreas</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="list-item-action">
        <coach-icon iconName="edit" iconColor="currentColor"></coach-icon>
      </div>
    </div>

    <div class="list-item list-item--complex formatting--none">
      <div class="list-item-main">
        <div class="row">
          <div class="field">formatting--none</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="list-item-block">
        1
      </div>
      <div class="list-item-block">
        2
      </div>
    </div>

    <div class="list-item list-item--complex formatting--none">
      <div class="list-item-main">
        <div class="row">
          <div class="field">formatting--none</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="column">
        <button class="button">1</button>
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main">
        <div class="row">
          <div class="field">Jeff Garlin</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="column">
        <button class="button">1</button>
      </div>
    </div>

    <div class="list-item list-item--complex">
      <div class="list-item-main padding-left--smaller padding-topBottom--large padding-right--largest">
        <div class="row">
          <div class="field">padding-- modifier</div>
          <div class="field">#9271</div>
        </div>
        <div class="row">
          <div class="field">Jeffy@jeff.com</div>
          <div class="field">11/12/1960</div>
        </div>
      </div>
      <div class="column">
        <button class="button">1</button>
      </div>
    </div>

    <div class="list-item">
      <div class="row">
        <div class="field">Chronic Conditions Dashboard</div>
        <div class="field">06/12/20</div>
      </div>
    </div>
    <div class="list-item">
      <div class="row u-color--interaction">
        <div class="field">Custom View</div>
        <coach-icon iconName="chevron-right" iconColor="currentColor"></coach-icon>
      </div>
    </div>
    <div class="list-item list-item--complex">
      <div class="list-item-main">
        <div class="row">
          <div class="field u-fill field--primary">Practice Name</div>
          <div class="field field--secondary">Label here</div>
        </div>
      </div>
      <div class="list-item-action border-left">
        <coach-icon iconName="edit" iconColor="currentColor"></coach-icon>
      </div>
    </div>
    <div class="list-item">
      <div class="row">
        <div class="field">High BP Alerts</div>
        <div class="field">14</div>
      </div>
    </div>
    <div class="list-item list-item--complex">
      <div class="list-item-action">
        <coach-icon iconName="edit" iconColor="currentColor"></coach-icon>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field">Schedules AWVs</div>
          <div class="field">132</div>
        </div>
      </div>
      <div class="list-item-action">
        <coach-icon iconName="edit" iconColor="currentColor"></coach-icon>
      </div>
    </div>
    <div class="list-item list-item--complex">
      <div class="list-item-avi">
        <div class="avi">
          <img class="img--circle" src="assets/img/mock-avatars/avatar-m-02.jpg">
        </div>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field">Robert "Robby" Smith</div>
          <div class="row--verticalCenter">
            <div class="badge margin-right--smallest">1</div>
            <div class="badge u-colors--info">2</div>
          </div>
        </div>
        <div class="row u-italic">
          <div class="field">My last two readings were in the normal range!</div>
        </div>
      </div>
      <div class="list-item-action">
        click
      </div>
    </div>
    <div class="list-item list-item--complex list-item--singleHitArea">
      <div class="list-item-avi">
        <div class="avi">
          <img class="img--circle" src="assets/img/mock-avatars/avatar-m-04.jpg">
        </div>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field u-fill">Yuna “June” Takahashi</div>
          <div class="row--verticalCenter">
            <div class="badge margin-right--smallest">1</div>
            <div class="badge u-colors--info">1</div>
          </div>
        </div>
        <div class="row u-italic">
          <div class="field">Can you calll me? My ear is really aching this morning.</div>
        </div>
      </div>
    </div>
    <div class="list-item list-item--complex list-item--singleHitArea">
      <div class="list-item-avi">
        <div class="avi">
          <img class="img--circle" src="assets/img/mock-avatars/avatar-m-01.jpg">
        </div>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field">Field here yeah</div>
        </div>
        <div class="row u-italic">
          <div class="field">second row below</div>
        </div>
      </div>
    </div>
    <div class="list-item list-item--complex">
      <div class="list-item-icon column u-center span--2">
        <coach-icon iconName="home" iconSize="large" iconColor="currentColor"></coach-icon>
      </div>
      <div class="list-item-main">
        <div class="row">
          <div class="field u-truncate u-fill">Primary field here</div>
          <div class="field u-thin u-preserve margin-left--small">#000asd0123</div>
        </div>
      </div>
    </div>
  </div>

  <h1>Organisms</h1>

  <button class="button" (click)="toggleModalDemo()">Show Modal Example</button>

  <ng-container *ngIf="showModalDemo">
    <div class="overlay is-open">
      <div class="modal u-width--larger">
        <div class="header">
          <div class="titles">
            <div class="title">Modal</div>
            <div class="subtitle">some modal thing</div>
          </div>
        </div>
        <div class="dynamicBody padding"> I'm some cool modal stuff.</div>
        <div class="footer">
          <button class="button margin-right border--none" (click)="toggleModalDemo()">Nevermind</button>
          <button class="button is-primary" (click)="toggleModalDemo()">Send it</button>
        </div>
      </div>
    </div>
  </ng-container>

  <h1>Custom Components</h1>
  <h2>Loading Indicator</h2>
  <coach-loading-indicator size="40"></coach-loading-indicator>
  <h2>Button Toggle</h2>
  <coach-button-toggle [options]="['First Item', 'Second Item', 'Third Item']"></coach-button-toggle>
  <h1>Fontawesome Icons</h1>
  <span>
    <fa-icon class='fa-sm' [icon]="faMinus"></fa-icon>
  </span>
  <span>
    <fa-icon class='fa-sm' [icon]="faPhone"></fa-icon>
  </span>
  <span>
    <fa-icon class='fa-sm' [icon]="faSms"></fa-icon>
  </span>
  <span>
    <fa-icon class='fa-sm' [icon]="faUserMd"></fa-icon>
  </span>
  <span>
    <fa-icon class='fa-sm' [icon]="faCheckSquare"></fa-icon>
  </span>
  <span>
    <fa-icon class='fa-sm' [icon]="faTimesCircle"></fa-icon>
  </span>

  <h1>Border radius</h1>
  <div class="u-display--flex">
    <button class="button is-primary margin-right--large u-border-top-radius--none">Top none</button>
    <button class="button is-primary margin-right--large u-border-bottom-radius--none">Bottom none</button>
    <button class="button is-primary margin-right--large u-border-left-radius--none">Left none</button>
    <button class="button is-primary margin-right--large u-border-right-radius--none">Right none</button>
    <button class="button is-primary margin-right--large u-border-radius--large">large</button>
    <button class="button is-primary margin-right--large u-border-radius--none">none</button>
  </div>

</div>
