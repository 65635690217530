<div class="u-height--fit-content margin-auto row min-height">
  <div class="span--2 u-border-radius--rounded">
    <coach-stepper class="stepper" [icon]="'calendar'"></coach-stepper>
  </div>
  <div class="span--10">
    <div class="row" (click)="isTabOpen = !isTabOpen">
      <div class="span--10 padding-left--small pointer">
        <div [ngClass]="{'tabOpen': isTabOpen === true }" class="u-bold">AWV Scheduled</div>
        <div class="u-color--brand--6 u-font-weight--light" id='testScheduledDate'>{{scheduledOutcomeProperties.date | date:'MM/dd/yyyy'}}&nbsp;{{scheduledOutcomeProperties.practiceId? ('at ' + practiceName) : ''}}</div>
      </div>
      <div class="span--2 padding-left--small" id='testAWVScheduledArrow'>
        <coach-icon [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" iconColor="currentColor"></coach-icon>
      </div>
    </div>
    <div class="padding-top--large">
      <coach-patient-workflow-scheduled [patient]="patient" [editModeActive]="true" *ngIf="isTabOpen" [properties]=scheduledOutcomeProperties
                                        (outcomeSaved)="this.outcomeSaved($event)"
                                        [ngClass]="{'u-flex-wrap--wrap padding--small': mobileScreenSize, 'padding-top--large padding-bottom--large padding-right--small': !mobileScreenSize}"
                                        (deleteActivity)="this.deleteSchedulingActivity()"
      ></coach-patient-workflow-scheduled>
    </div>
  </div>
</div>


