import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterToggleService } from '@shared/services/filter-toggle/filter-toggle.service';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { CoachTabs } from '@shared/components/coach-tabs/coach-tabs';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { SpacesToDashesPipe } from '@shared/pipes/spaces-to-dashes.pipe';
import { FilterOption } from '../care-all-prescriptions.component';
import _ from 'lodash';
import { GridApi } from 'ag-grid-community';
export type FilterOpenState = 'is-open' | 'is-closed';

const {
  PatientsFeature,
  CarePrescriptionFeature,
  CarePrescriptionProcessingFeature,
  CarePrescriptionInvoicingFeature,
  FeatureFlag340B,
  HiddenFeatureFlag340B,
  UIUpdates340B,
  ExceptionQueue340B,
  CareExceptionsComponent
} = CareFeatureConstants;
@Component({
  selector: 'care-all-prescriptions-filters',
  templateUrl: './care-all-prescriptions-filters.component.html',
  providers: [SpacesToDashesPipe],
  styleUrls: ['./care-all-prescriptions-filters.component.scss']
})
export class CareAllPrescriptionsFiltersComponent implements OnInit, OnChanges {
  @Input() public gridApi: GridApi;
  @Input() public filterOptions: FilterOption[];
  @Output() public listSelected: EventEmitter<CoachTabs> = new EventEmitter();

  isListFiltersOpen: boolean = true;
  activeTab: 'standard' | 'custom' = 'standard';
  isDrillInOpen: boolean = false;
  isNewListDrawerOpen: boolean = false;
  allLists: CoachTabs[] = [
    {
      name: 'Pre-validation Exceptions',
      featureId: CareExceptionsComponent,
      featureFlag: ExceptionQueue340B
    },
    {
      name: 'Validate 340B',
      featureId: CarePrescriptionFeature,
      featureFlag: FeatureFlag340B
    },
    {
      name: 'Process 340B',
      featureId: CarePrescriptionProcessingFeature,
      featureFlag: FeatureFlag340B
    },
    {
      name: 'Invoice 340B',
      featureId: CarePrescriptionInvoicingFeature,
      featureFlag: HiddenFeatureFlag340B
    }
  ];
  selectedList: CoachTabs;
  @HostBinding('class')
  get pclass() {
    const sharedClasses = 'queue border-right u-position--relative coach-care-patients-filters';
    return this.isListFiltersOpen ? `${sharedClasses} is-open` : `${sharedClasses} is-closed`;
  }
  loadingStatus: boolean = false;
  constructor(
    private filterToggleService: FilterToggleService,
    protected featureFlagService: FeatureFlagService,
    private spacesToDashes: SpacesToDashesPipe
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes?.filterOptions?.currentValue) {
      this.loadingStatus = false;
    }
  }
  ngOnInit(): void {
    this.allLists = this.allLists.filter(tab => {
      return this.featureFlagService.hasFeatureFlag(tab.featureFlag);
    });
    this.selectList(this.allLists[0]);
  }

  toggleListFilters(): void {
    this.isListFiltersOpen = !this.isListFiltersOpen;
    this.filterToggleService.toggleFilterPane(this.isListFiltersOpen);
  }

  openNewListDrawer(): void {}
  switchListsActiveTab(tab: 'standard' | 'custom'): void {
    if (!this.isNewListDrawerOpen && !this.isDrillInOpen) {
      this.activeTab = tab;
    }
  }
  selectList(list: CoachTabs): void {
    if (_.isEqual(this.selectedList, list)) {
      return;
    }
    this.selectedList = list;
    this.loadingStatus = true;
    this.listSelected.emit(this.selectedList);
  }
  toggleDrillInOpen(list: CoachTabs): void {
    this.selectList(list);
    this.isDrillInOpen = !this.isDrillInOpen;
  }
}
