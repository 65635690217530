import { Component } from '@angular/core';
@Component({
  templateUrl: 'riskTrend.html'
})
export class RiskTrendColumnRendererComponent {
  riskTrend: string;
  agInit(params): void {
    this.riskTrend = params.data?.riskTrend;
  }
}
