import { Params } from '@angular/router';
import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { LogAuditElementID } from '../audit-constants';
import { IRecentMatcher } from './recent-matcher';
export class DocumentMatcher implements IRecentMatcher {
  match(url: string): boolean {
    if (url.includes('improve') && url.includes('file=')) {
      return true;
    } else {
      return false;
    }
  }

  createLog(url: string, queryParams: Params): RecentLogData {
    return {
      url,
      elements: [
        {
          id: LogAuditElementID.DocumentEntryId,
          value: queryParams.file,
        },
      ],
    };
  }

  // null to trigger lookup call
  title(url: string): null {
    return null;
  }
}
