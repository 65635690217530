import {
  Component,
  OnInit,
  HostBinding,
  ViewChild,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { HelpPanelService } from '@shared/services/help-panel/help-panel.service';
import { BehaviorSubject } from 'rxjs';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { MarkdownComponent } from 'ngx-markdown';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-help-panel',
  templateUrl: './help-panel.component.html'  
})
export class HelpPanelComponent implements OnInit, OnDestroy {
  public isHelpPanelOpen$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isHelpPanelPinned$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public helpfile: string;
  public pinnedClass: string;
  supportEmail: string = AppConstants.SupportEmail;
  @HostBinding('class')
  get pclass(): string {
    return `${this.pinnedClass} coach-help-panel column`;
  }

  private readonly isAbsolute = new RegExp('(?:^[a-z][a-z0-9+.-]*:|//)', 'i');

  @ViewChild('postDiv', { static: false })
  private postDiv: MarkdownComponent;

  constructor(
    private _helpPanelService: HelpPanelService,
    private httpClient: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private hierarchyTierService: HierarchyTierService
  ) {
    this.isHelpPanelOpen$ = this._helpPanelService.isHelpPanelOpen$;
    this.isHelpPanelPinned$ = this._helpPanelService.isHelpPanelPinned$;
    this.setPinnedClass();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          let isFound = true;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (
              child.snapshot &&
              child.snapshot.queryParams &&
              isFound
            ) {
              if (
                child.snapshot.queryParams.filterPreset
              ) {
                let filterHelpFile = this.getHelpfileFromfilterPreset(child.snapshot.queryParams.filterPreset);
                if(filterHelpFile){
                  child.snapshot.data['helpfile'] = filterHelpFile;
                  return child.snapshot.data['helpfile'];
                }
                else{isFound = false;
                  //child.snapshot.data['helpfile'] = null; //resets the help file from last one loaded.
                continue}
              }
              else if (child.snapshot && child.snapshot.url[1] && ! child.snapshot.data['helpfile'])
              {
                let filterHelpFile = this.getHelpfileFromReport(child.snapshot.url[1].path);
                if(filterHelpFile){
                  child.snapshot.data['helpfile'] = filterHelpFile;
                  return filterHelpFile;
                }
                else{isFound = false;
                  //child.snapshot.data['helpfile'] = null; //resets the help file from last one loaded.
                continue}
              }
              else if (child.snapshot && child.snapshot.queryParams.folder) {
                let docrepoHelpFile = this.getHelpfileFromDocRepoLocation(
                  child.snapshot.queryParams.folder,
                  child.snapshot.queryParams.file);
                child.snapshot.data['helpfile'] = this.getHelpfileFromDocRepoLocation(
                  child.snapshot.queryParams.folder,
                  child.snapshot.queryParams.file);
                //return child.snapshot.data['helpfile'];
                return docrepoHelpFile;
              } else {
                isFound = false;
                continue;
              }
            } else if (child.snapshot.data && child.snapshot.data['helpfile']) {
              return child.snapshot.data['helpfile'];
            } else if (
              child.parent.snapshot.data &&
              child.parent.snapshot.data['helpfile']
            ) {
              return child.parent.snapshot.data['helpfile'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((helpfile: any) => {
        this.getHelpfileContents(helpfile);
      });
  }

  public getHelpfileFromDocRepoLocation(folder, file): string {
    switch (folder + '-' + file) {
      case '196-3922': {
        return 'LEARN_CARE_AWVBasic_2020';
      }
      default: {
        switch (folder) {
          case '196': {
            return 'home';
          }
          default: {
            return 'LEARN_IMPROVE_General_2020';
          }
        }
      }
    }
  }
  public getHelpfileFromfilterPreset(filterPreset): string {
    switch (filterPreset) {
      case 'awv-opportunities': {
        return 'LEARN_CARE_AWVBasic_2020';}
      case 'blood-pressure': {
        return 'LEARN_CARE_BloodPressure_2020'}
      case 'high-blood-sugar' :{
        return 'LEARN_CARE_BloodGlucose_2020'}
      case 'low-blood-sugar' :{
        return 'LEARN_CARE_BloodGlucose_2020'}
      case 'facesheet-schedule' :{
        return 'LEARN_CARE_General_2020'}
      case 'rapid-weight-gain-reading' :{
        return 'LEARN_CARE_WeightTracking_2020'}
      case '' :{
        return ''}
      default: {return 'LEARN_CARE_General_2020';}
      }
    }

    public getHelpfileFromReport(reportGuid): string {
      switch (reportGuid) {
        case '1c951665-2e75-4979-949c-c7b22255a11f': {
          return 'LEARN_TRACK_FU_KPI_2020';}
        case '319b62e2-c336-4627-8b01-35443ed2b372': { 
          return 'LEARN_TRACK_FU_KPI_2020';}
        case '63042d25-5a41-4484-bae5-3d1dc06c59c3' :{
          return 'LEARN_TRACK_FU_NU_2020';}
        case 'bb27f02a-3a04-406c-9f16-6ef54410d31e' :{
          return 'LEARN_TRACK_FU_NU_2020';}
        case '3d011cf6-27b2-433f-821f-22c5b6cd27e4' :{
          return 'LEARN_TRACK_FU_PAC_2020';}
        case '852b7a7f-b979-48c2-b027-7984e1540023' :{
          return 'LEARN_TRACK_FU_PAC_2020';}
        case 'dec1b521-05fb-4cd0-9a46-43cb871044d5' :{
          return 'LEARN_TRACK_FU_EOL_2020';}
        case 'edf29df5-2059-4d12-a640-f0fbe8f38b6a' :{
          return 'LEARN_TRACK_FU_EOL_2020';}
        case '9909c861-582c-4ccc-a9d2-41b600b3d321' :{
          return 'LEARN_TRACK_FU_CE_2020';}
        case '132c9441-d01f-4b42-8e28-6c9b3191a91f':{
          return 'LEARN_TRACK_FU_CE_2020';}
        case '6ce3b3a4-780d-4644-88fa-c4aad2831133' :{
          return 'LEARN_TRACK_QualityMeasures_2020';}
        case 'ec25f9ab-700b-46c3-b508-81f31e9e1d17' :{
          return 'LEARN_TRACK_QualityMeasures_2020';}
        case '8977abc7-489a-44be-8fc7-12b2b392f108' :{
          return 'LEARN_TRACK_QualityMeasures_2020';}
        case '1f8bc59d-1d3b-44c9-bb9b-2648414c32d0' :{
          return 'LEARN_TRACK_QualityMeasures_2020';}
        case '0c47a9c9-3c9a-49d7-b3b9-2789adc2b58b' :{
          return 'LEARN_TRACK_QualityMeasures_2020';}
        case '6dcedecd-7418-4280-9ea5-3c2ca3f74294' :{
          return 'LEARN_TRACK_QualityMeasures_SPI';}
        case '4659a7f0-55f5-4a07-88c8-461abf329df5' :{
          return 'LEARN_TRACK_QualityMeasures_SRQ';}
        default: {return 'LEARN_CARE_General_2020';}
        }
      }

  public getHelpfileContents(helpfile) {
    if (!helpfile) {
      helpfile = 'general';
    }
    this.httpClient
      .get('assets/helpfiles/' + helpfile + '.md', { responseType: 'text' })
      .subscribe((markdown) => {
        this.helpfile = markdown;
      });
  }

  public setPinnedClass() {
    this.pinnedClass = this.isHelpPanelPinned$.value
      ? 'is-pinned'
      : 'is-unpinned';
  }

  public togglePinned() {
    this._helpPanelService.toggleHelpPanelPinned();
    this.setPinnedClass();
  }

  public toggleOpen() {
    this._helpPanelService.toggleHelpPanelOpen();
  }

  public getIsDisabled(): boolean {
    if (
      (this.isHelpPanelOpen$.value && this.isHelpPanelPinned$.value) ||
      !this.isHelpPanelOpen$.value
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
  }
}
