import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TrackReportMenuItem } from '../track-report-menu-item';
import { TrackReportMenuItemsService } from '@track/track-reports/services/track-report-menu-items.service';
import { TrackReportService } from '../services/track-report.service';
import { TrackComponentConstants, TrackFeatureConstants } from '@shared/models/module-constants';
import { QUALITY_REPORTS_FEATURE } from '@track/track-constants';

@Component({
  selector: 'coach-track-report-widgets',
  templateUrl: './track-report-widgets.component.html',
  styleUrls: ['./track-report-widgets.component.scss']
})
export class TrackReportWidgetsComponent implements OnInit {

  @Input()
  reportComponentId: number;
  qualityDashboardComponent: number = TrackComponentConstants.QualityDashboardComponent;
  qualityDashboardFeatureFlag: string = TrackFeatureConstants.QualityDashboardFeatureFlag;
  qualityReportsFeatureId: number = QUALITY_REPORTS_FEATURE;
  constructor(private menuItemService: TrackReportMenuItemsService, private reportService: TrackReportService) { }

  menuItems$: Observable<TrackReportMenuItem[]>;

  ngOnInit(): void {
    this.menuItems$ = this.menuItemService.getAllFromCache();
  }

  openReport(item: TrackReportMenuItem): void {
    this.reportService.runReport(item);
  }

}
