import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from './auth-service/auth.service';
import { PresetItemBase } from '@care/preset-group/utils';

@Injectable({
  providedIn: 'root'
})
export class WellpepperResolverService implements Resolve<any> {
  constructor(private authService: AuthService, private presetItemBase: PresetItemBase) {}
  async resolve(route: ActivatedRouteSnapshot) {
    const user = await this.authService.getWellpepperUserInfo();
    this.authService.setWellpepperUser(user);
    return user;
  }
}
