<div *ngIf="!UIUpdates340BFeatureFlag">
  <div style="font-size: small; color: gray; margin-left: 16px">
    {{ groupOptions.headerLabel }}
  </div>
  <div
    style="
      border: 1px solid gray;
      border-radius: 5px;
      padding: 16px;
      margin: 0px 16px 16px 16px;
    "
  >
    <form [formGroup]="form">
      <div>
        <input
          [id]="getUniqueAllCheckboxId()"
          type="checkbox"
          value="All"
          (change)="onAllChecked($event)"
          [checked]="allIsChecked"
        />
        <label [for]="getUniqueAllCheckboxId()">All</label>
      </div>
      <div *ngFor="let option of options">
        <input
          id="{{ option.value }}"
          type="checkbox"
          [value]="option.value"
          (change)="onCbChange($event, option)"
          [checked]="option.isSelected"
        />
        <label for="{{ option.value }}">
          {{ option.name }}
        </label>
      </div>
    </form>
  </div>
</div>

<div *ngIf="UIUpdates340BFeatureFlag" class="accordion list-item padding--none" [class.is-open]="activeDropdownPointer == idx">
  <div class="row u-justify-content--spaceBetween">
    <div class="field header border--none cursor-default u-flex-direction--column row-title">
      {{ groupOptions.headerLabel }}
      <div class="selection-list">
        <ng-container *ngIf="getSelectedOptions().length == options.length && getSelectedOptions().length > 0">
          <small class="selection-element u-color--highlight">
            All
          </small>
        </ng-container>
        <ng-container *ngIf="getSelectedOptions().length < options.length && getSelectedOptions().length > 0">
          <small class="selection-element u-color--highlight" *ngFor="let selected of getSelectedOptions(); let s_idx = index">
            {{selected}}
            <span *ngIf="s_idx + 1 < getSelectedOptions().length">,</span>
          </small>
        </ng-container>
        <ng-container *ngIf="getSelectedOptions().length < options.length && getSelectedOptions().length == 0">
          <small *ngIf="!useRadioButtons" class="selection-element u-color--highlight">
            None
          </small>
        </ng-container>
        
      </div>
    </div>
    <coach-icon iconName="x-circle" *ngIf="!useRadioButtons && getSelectedOptions().length != options.length" class="padding-top--large" (click)="checkAllOptions()"
      iconColor="currentColor">
    </coach-icon>
    <coach-icon iconName={{getChevronStatus()}} class="padding-top--large" (click)="openAccordion()" iconColor="currentColor">
    </coach-icon>
  </div>
  <div class="content content-planRow shadowLess">
    <form [formGroup]="form" *ngIf="!useRadioButtons">
      <div>
        <input [id]="getUniqueAllCheckboxId()" type="checkbox" value="All" (change)="onAllChecked($event)"
          [checked]="allIsChecked" />
        <label [for]="getUniqueAllCheckboxId()">All</label>
      </div>
      <div *ngFor="let option of options">
        <input id="{{ option.value }}" type="checkbox" [value]="option.value" (change)="onCbChange($event, option)"
          [checked]="option.isSelected" />
        <label for="{{ option.value }}">
          {{ option.name }}
        </label>
      </div>
    </form>

    <form [formGroup]="form" *ngIf="useRadioButtons">
      <div *ngFor="let option of options">
        <input id="{{ option.value }}" name="itemSelect" type="radio" [value]="option.value" (change)="onRadioChange($event, option)"
          [checked]="option.isSelected" (click)="confirmClick($event)" />
        <label for="{{ option.value }}">
          {{ option.name }}
        </label>
      </div>
    </form>

  </div>
</div>
