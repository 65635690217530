import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CareRoutingModule } from '@care/care-routing.module';
import { CareComponent } from '@care/care.component';
import { CarePatientsComponent } from '@care/views/care-patients/care-patients.component';
import { RiskTrendColumnRendererComponent } from '@care/views/care-patients/customColumns/riskTrend';
import { ActionsColumnRendererComponent } from '@care/views/care-patients/customColumns/actions';
import { IheFilesColumnRendererComponent } from '@care/views/care-patients/customColumns/iheFiles';
import { CareSettingsComponent } from '@care/views/care-settings/care-settings.component';
import { CarePatientComponent } from '@care/views/care-patient/care-patient.component';
import { CareTestComponent } from '@care/views/care-test/care-test.component';
import { RouterModule } from '@angular/router';
import { PatientFacesheetModule } from '@shared/modules/patient-facesheet/patient-facesheet.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { CarePatientsFiltersComponent } from './views/care-patients/care-patients-filters/care-patients-filters.component';
import { DiseaseCohortGridComponent } from './views/care-patients/disease-cohort-grid/disease-cohort-grid.component';
import { EdUtilizationGridComponent } from './views/care-patients/ed-utilization-grid/ed-utilization-grid.component';
import { FacesheetScheduleGridComponent } from './components/facesheet-schedule/facesheet-schedule-grid/facesheet-schedule-grid.component';
import { FacesheetScheduleComponent } from './components/facesheet-schedule/facesheet-schedule/facesheet-schedule.component';
import { FacesheetScheduleFiltersComponent } from './components/facesheet-schedule/facesheet-schedule-filters/facesheet-schedule-filters.component';
import { AwvWorklistFiltersComponent } from './components/awv-worklist/awv-worklist-filters/awv-worklist-filters.component';
import { CarePrescriptionsComponent } from './views/care-prescriptions/care-prescriptions.component';
import { CarePrescriptionsFiltersComponent } from './views/care-prescriptions/care-prescriptions-filters/care-prescriptions-filters.component';
import { CarePrescriptionsSidebarComponent } from './views/care-prescriptions/care-prescriptions-sidebar/care-prescriptions-sidebar.component';
import { EmptyRouteComponent } from './views/care-prescriptions/care-prescriptions-sidebar/empty-route/empty-route.component';
import { RequestNoteComponent } from './views/care-prescriptions/care-prescriptions-sidebar/request-note/request-note.component';
import { RequestCareCoordinationNoteComponent } from './views/care-prescriptions/care-prescriptions-sidebar/request-care-coordination-note/request-care-coordination-note.component';
import { CarePrescriptionsProcessingComponent } from './views/care-patients/care-prescriptions-processing/care-prescriptions-processing.component';
import { CarePrescriptionsProcessingSidebarComponent } from './views/care-patients/care-prescriptions-processing/care-prescriptions-processing-sidebar/care-prescriptions-processing-sidebar.component';
import { PatientHeaderComponent } from './views/care-prescriptions/care-prescriptions-sidebar/patient-header/patient-header.component';
import { OptionToggleComponent } from './views/care-prescriptions/care-prescriptions-sidebar/request-care-coordination-note/option-toggle/option-toggle.component';
import { PatientHeaderHelpComponent } from './views/care-prescriptions/care-prescriptions-sidebar/patient-header/patient-header-help/patient-header-help.component';
import { ClaimNoteComponent } from './views/care-prescriptions/care-prescriptions-sidebar/claim-note/claim-note.component';
import { RejectedClaimComponent } from './views/care-prescriptions/care-prescriptions-sidebar/rejected-claim/rejected-claim.component';
import { FilterGroupComponent } from './views/care-prescriptions/care-prescriptions-filters/filter-group/filter-group.component';
import { FacesheetExportComponent } from './components/facesheet-schedule/facesheet-export/facesheet-export.component';
import { TabSwitchComponent } from './views/care-patients/care-prescriptions-processing/care-prescriptions-processing-sidebar/tab-switch/tab-switch.component';
import { CarePrescriptionsInvoicingComponent } from './views/care-prescriptions-invoicing/care-prescriptions-invoicing.component';
import { CarePrescriptionsInvoicingSidebarComponent } from './views/care-prescriptions-invoicing/care-prescriptions-invoicing-sidebar/care-prescriptions-invoicing-sidebar.component';
import { InvoicingTabSwitchComponent } from './views/care-prescriptions-invoicing/care-prescriptions-invoicing-sidebar/invoicing-tab-switch/invoicing-tab-switch.component';
import { CarePatientsFiltersSettingsComponent } from './views/care-patients/care-patients-filters/care-patients-filters-settings/care-patients-filters-settings.component';
import { FileImportComponent } from './views/care-prescriptions/care-prescriptions-sidebar/file-import/file-import.component';
import { FileControlComponent } from './views/care-prescriptions/care-prescriptions-sidebar/file-import/file-control/file-control.component';
import { CareAllPrescriptionsComponent } from './views/care-all-prescriptions/care-all-prescriptions.component';
import { CareAllPrescriptionsFiltersComponent } from './views/care-all-prescriptions/care-all-prescriptions-filters/care-all-prescriptions-filters.component';
import { SubFillDialogComponent } from './views/care-prescriptions/sub-fill-dialog/sub-fill-dialog.component';
import { CareExceptionsComponent } from './views/care-prescriptions/care-exceptions/care-exceptions.component';
import { ExceptionsCellRendererComponent } from './views/care-prescriptions/exceptions-cell-renderer/exceptions-cell-renderer.component';
import { CarePatientSidebarComponent } from './views/care-patients/care-patient-sidebar/care-patient-sidebar.component';
import { CarePatientsContainerComponent } from './views/care-patients/refactor-poc/care-patients-container/care-patients-container.component';
import { CarePatientsFiltersComponent as CarePatientsFiltersComponent2 } from './views/care-patients/refactor-poc/care-patients-filters/care-patients-filters.component';
import { CarePatientsGridComponent } from './views/care-patients/refactor-poc/care-patients-grid/care-patients-grid.component';
import { CareSummaryTabComponent,CareSummaryTileComponent } from './views/care-summary-tab';
import { NgxMasonryModule } from 'ngx-masonry';
import { PresetGroupModule } from './preset-group/preset-group.module';

@NgModule({
    declarations: [
        CareComponent,
        CarePatientComponent,
        CarePatientsComponent,
        CarePatientsFiltersComponent,
        CareSettingsComponent,
        CareTestComponent,
        RiskTrendColumnRendererComponent,
        ActionsColumnRendererComponent,
        IheFilesColumnRendererComponent,
        DiseaseCohortGridComponent,
        EdUtilizationGridComponent,
        FacesheetScheduleGridComponent,
        FacesheetScheduleComponent,
        FacesheetScheduleFiltersComponent,
        AwvWorklistFiltersComponent,
        CarePrescriptionsComponent,
        CarePrescriptionsFiltersComponent,
        CarePrescriptionsSidebarComponent,
        EmptyRouteComponent,
        RequestNoteComponent,
        RequestCareCoordinationNoteComponent,
        CarePrescriptionsProcessingComponent,
        CarePrescriptionsProcessingSidebarComponent,
        PatientHeaderComponent,
        OptionToggleComponent,
        PatientHeaderHelpComponent,
        ClaimNoteComponent,
        RejectedClaimComponent,
        FilterGroupComponent,
        FacesheetExportComponent,
        TabSwitchComponent,
        CarePrescriptionsInvoicingComponent,
        CarePrescriptionsInvoicingSidebarComponent,
        InvoicingTabSwitchComponent,
        CarePatientsFiltersSettingsComponent,
        FileImportComponent,
        FileControlComponent,
        CareAllPrescriptionsComponent,
        CareAllPrescriptionsFiltersComponent,
        SubFillDialogComponent,
        CareExceptionsComponent,
        ExceptionsCellRendererComponent,
        CarePatientSidebarComponent,
        CarePatientsContainerComponent,
        CarePatientsGridComponent,
        CarePatientsFiltersComponent2,
        CareSummaryTabComponent,
        CareSummaryTileComponent
    ],
    imports: [
        AgGridModule,
        CareRoutingModule,
        PatientFacesheetModule,
        SharedModule,
        RouterModule,
        FontAwesomeModule,
        NgxMasonryModule,
        PresetGroupModule
    ]
})
export class CareModule {}
