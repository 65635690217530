<ng-container *ngIf="showModal">
  <div class="overlay is-open">
    <div class="modal u-width--larger">
      <div class="header">
        <div class="titles">
          <div class="title" style="text-align: center">Request Visit Note</div>
          <coach-icon iconName="x" style="margin-left: auto; cursor: pointer" (click)="toggleVisitNoteModal()">
          </coach-icon>
          <div class="subtitle" style="text-align: center">
            Paste this into an email or fax
          </div>
        </div>
      </div>
      <div class="dynamicBody padding row" *hasFeatureFlag="Fax340bOutboundFeature">
        <p style="font-size: smaller">
          Fax:
        </p>
        <div style=" margin-left: 1.3rem">
          <input [ngModel]="faxNum" id="faxInput" name="faxInput" type="tel"
            [ngClass]="{'ng-invalid': !faxNumberValid()}" style="margin-top: -1.25rem" (ngModelChange)="formatPhoneNumber($event)">
          <div *ngIf="visitNoteRequestedDate" class="row">
            <coach-icon iconName='check' iconColor='primary'></coach-icon>
            <span style="font-style: italic; color: grey ">
              {{formatDate(visitNoteRequestedDate)}}
            </span>
          </div>
        </div>
      </div>
      <div class="dynamicBody padding" #noteContent>
        Dear {{ selectedPatientInfo.prescriberName }},<br /><br />
        {{selectedPatientInfo.primaryProviderName}} is the primary care provider for:<br /><br />
        <strong>{{ selectedPatientInfo.patientName }}</strong><br />
        DOB: {{ formatDate(selectedPatientInfo.patientDob) }} | Sex:
        {{ getPatientSex() }} <br />
        MBI: {{ selectedPatientInfo.mbi | mask: 6 }}<br /><br />
        You prescribed:
        <strong>{{ selectedPatientInfo.prescriptionName }}</strong><br />
        First filled on: {{ formatDate(selectedPatientInfo.firstFilled)
        }}<br /><br />
        Please provide a summary of the visit <strong><em>prior</em></strong> to the fill date that resulted in this prescription and any updates you may have on the patient’s condition.
      </div>
      <div class="footer" style="background-color: #eee">
        <button class="button margin-right border--none" (click)="toggleVisitNoteModal()">
          Cancel
        </button>
        <div *hasFeatureFlag="Fax340bOutboundFeature" class="margin-left margin-right">
          <coach-loading-indicator [size]="40" *ngIf="sendingFaxLoading">
          </coach-loading-indicator>
          <button *ngIf="!sendingFaxLoading" type="submit" class="button" (click)="sendFax(faxNum)"
            [disabled]="!faxNumberValid()">
            Send Fax
          </button>
        </div>
        <button class=" button is-primary" (click)="copyTextAndClose()">
          Copy Text and Close
        </button>
      </div>
    </div>
  </div>
</ng-container>
