import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import * as _ from 'lodash';

import { Patient } from '@shared/models/patient';
import { PatientService, ModuleActivateDeactivateDialogViewModel, EpisodeOfCareProtocolAssignmentViewModel, EpisodeOfCareViewModel, EpisodeOfCareModuleAssignmentViewModel, TaskViewModel, CarePlanViewModel, IProcedure, ProtocolTaskViewModel } from '@shared/services/patient/patient.service';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { Procedure, Task } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/wp-model';
import { OrgService, IProtocol, IModule } from '@shared/services/org-service/org.service';
import { Protocol } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/protocol';
import { IconDefinition, faSave } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { PatientIdentifiersIssuers } from '@shared/models/module-constants';

/** We are caching protocols - they change very rarely */
const protocols: { [key: string]: wpapi.model.Protocol } = {};

@Component({
  selector: 'coach-care-patient-careplan-editable',
  templateUrl: './patient-careplan-editable.component.html',
  styleUrls: ['./patient-careplan-editable.component.scss']
})
export class CarePatientCareplanEditableComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  orgData: any = null;
  orgId: string = null;
  get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get tierNum() {
    return this?.tier?.tier
  };

  vm: CarePlanViewModel;
  selectHolder: any = {
    primaryProfessional: {},
    newProtocol: {},
    newModules: [],
    newProcedure: {
      allowedProtocols: [],
    },
    extendedFields: {},
  };
  orgProtocols: any = [];
  protocols: IProtocol[] = [];
  protocolsById: { [key: string]: any } = {};
  activeProtocolsById: { [key: string]: string[] } = {}; // list of protocols by id, each one is list of modules ids
  activeModulesById: { [key: string]: boolean } = {};
  activeEpisodesOfCareByName: { [key: string]: boolean } = {};
  activeEpisodesOfCare: EpisodeOfCareViewModel[] = [];
  modulesById: any = {};
  smsPhoneNumberByProtocolId: { [key: string]: string } = {};
  professionals: wpapi.model.Professional[] = null;
  professionalsById: any = {};
  availableProcedures: IProcedure[] = [];
  availableProceduresByName: { [key: string]: any } = {};
  selectedModuleActivateDeactivateInfo: any = null;
  tasks: any = [];
  activeTasksById: any = {};
  activeTasksByProtocolId: any = {};
  faSave: IconDefinition = faSave;
  phoneNumber: string = null;
  constructor(
    private PatientService: PatientService,
    private auditService: AuditService,
    private OrgService: OrgService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.patient && this.tier) {
      this.refresh();
      //(detail, compId, subComp, actionId, chPatId, tierId, orgId);
      this.auditService.auditLog(
        'Patient CarePlan',
        ComponentId.Dashboard,
        SubComponentId.CarePlan,
        ActionId.PHIAccess,
        this.patient.chPatId,
        this.tier,
        null
      );
    }
  }
  async getAndStoreOrgProtocols() {
    this.protocolsById = {};
    let orgProtocols = await this.OrgService.getProtocolsForOrg(this.orgId);
    let result = [];
    // pull extra info for a protocol. Looks like some details are missing when pulling protocols by org
    orgProtocols?.forEach(async protocol => {
      let protocolDetails = await this.OrgService.getProtocol(protocol._id);
      this.protocolsById[protocolDetails._id] = protocolDetails;
      result.push(protocolDetails)
    })
    return result;
  }
  async getAndStorePatientEpisodesOfCare() {
    this.activeProtocolsById = {};
    let activeEpisodesOfCare = await this.PatientService.getPatientEpisodesOfCare(this.tierId, this.patient);
    activeEpisodesOfCare?.forEach(eoc => {
      this.availableProceduresByName[eoc.name] = eoc;
      eoc.protocols?.forEach(protocol => {
        this.activeProtocolsById[protocol.protocolId] = protocol.modules?.filter(m => m.state == 'active').map(m => m.protocolId)
      })
      eoc.protocols = eoc.protocols?.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
    })
    return (activeEpisodesOfCare || []).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
  }
  test(target){
  }
  async getAndStorePatientTasks() {
    this.activeTasksById = {};
    this.activeTasksByProtocolId = {};
    let tasks = await this.PatientService.getPatientTasks(this.tierId, this.patient);
    for(let task of tasks){
      this.activeTasksById[task._id] = task;
      let taskProtocolId = task['moduleId'] || task.protocolId
      if(!this.activeTasksByProtocolId[taskProtocolId]) {
        this.activeTasksByProtocolId[taskProtocolId] = []
      }
      this.activeTasksByProtocolId[taskProtocolId].push(task)
    }
    return tasks;
  }
  async refresh() {
    this.phoneNumber = _.cloneDeep(this.patient.phoneNumber);
    if (!this.orgId) {
      this.orgId = await this.PatientService.getWpOrgIdFromTierId(
        this.tier?.selectedTierId
      );
    }
    try {
      this.activeModulesById = {};
      this.orgData = await this.OrgService.getOrg(this.orgId);
      let availableProcedures = this.orgData.availableProcedures;
      this.orgProtocols = await this.getAndStoreOrgProtocols();
      this.activeEpisodesOfCare = await this.getAndStorePatientEpisodesOfCare();
      this.selectedModuleActivateDeactivateInfo = null;
      this.tasks = await this.getAndStorePatientTasks();

      await this.getAndAssignOrgAndPatientData()
      // this.patient.episodesOfCare = this.vm.episodesOfCare;
      for(let procedure of availableProcedures){
        this.availableProceduresByName[procedure.name] = { ...procedure, ...(this.availableProceduresByName[procedure.name] || {}) };
        procedure._id = this.availableProceduresByName[procedure.name]?._id
        procedure.procedureName = procedure.name;
        procedure.protocols = []
        procedure.allowedProtocols?.forEach(async protocolId => {
          let protocol = this.protocolsById[protocolId]

          if (protocol) {
            protocol.protocolId = protocol._id;
            this.clearDefaultTasksState(protocol.tasks)
            if (protocol.modules) {
              protocol.modules.forEach(module => {
                module.state = this.activeModulesById[module.protocolId] ? 'active' : 'inactive'
              })
            }
            procedure.protocols.push(await this.protocolHandler(protocol, procedure))
          }
        })
        procedure.isActive = procedure.protocols?.filter(protocol => protocol.isActive)?.length > 0;
      }

      this.availableProcedures = availableProcedures
    } catch (error) {
      console.error("error =>", error)
    }
  }
  clearDefaultTasksState(tasks: Task[]): void { // for some reason .isActive has two meanings: 1. is assigned to patient, 2. is available to assign
    tasks?.forEach(task => {
      task.isActive = false;
    })
  }
  async protocolHandler(protocol, procedure) {
    try {
      protocol.isOpen = true;
      protocol.parentEpisodeOfCare = procedure;
      let protocolTasks = this.activeTasksByProtocolId[protocol._id] || protocol.tasks
      protocol.tasks = protocolTasks?.map((protocolTask) => {
        let tvm = protocolTask as TaskViewModel;
        tvm.swimlane = this.getSwimlaneForTask(protocolTask);
        return tvm;
      })?.filter((tvm) => !!tvm);

      protocol.activeTaskCount = protocol.tasks.reduce((count, t) => (t.isActive ? count + 1 : count), 0);
      let listOfActiveModuleIds = this.activeProtocolsById[protocol._id]
      if (protocol.modules) {
        for (let m of protocol.modules) {
          if (listOfActiveModuleIds?.includes[m.protocolId]) {
            m.state = 'active'
          }
          m.isOpen = true;
          // store ids only, otherwise it turns into circular object
          m.parentProtocol = {
            protocolId: protocol._id,
            parentEpisodeOfCare: {
              _id: protocol.parentEpisodeOfCare._id,
              name: protocol.parentEpisodeOfCare.name
            }
          };
          if (!this.protocolsById[m.protocolId]) {
            this.protocolsById[m.protocolId] = await this.OrgService.getProtocol(
              m.protocolId
            );
            this.clearDefaultTasksState(this.protocolsById[m.protocolId]?.tasks)
          }
          // this.protocolsById[m.protocolId].isActive = false;
          m.protocol = this.protocolsById[m.protocolId];
          let moduleTasks = this.activeTasksByProtocolId[m.protocolId];
          if (moduleTasks && m.isActive) {
            m.templateTasks = this.templateTasksForModule(
              moduleTasks,
              m.protocolId
            ) || [];
            m.assignedTasks = moduleTasks?.map((moduleTask) => {
              let tvm = moduleTask as TaskViewModel;
              tvm.swimlane = this.getSwimlaneForTask(moduleTask);
              return tvm;
            })?.filter((t) => !!t) || [];

          } else {
            m.templateTasks = m.protocol?.tasks.map((moduleTask) => {
              let tvm = moduleTask as TaskViewModel;
              tvm.swimlane = this.getSwimlaneForTask(moduleTask);
              return tvm;
            })?.filter((t) => !!t) || [];
          }
          m.activeTaskCount = m.assignedTasks?.reduce(
            (count, t) => (t.isActive ? count + 1 : count),
            0
          );
        }
      }
      protocol.isActive = protocol.tasks?.filter(task => task.isActive)?.length || protocol.modules?.filter(module => module.state == 'active')?.length;

      return protocol;
    }catch(error){
      console.error(error)
    }

  }
  async getAndAssignOrgAndPatientData() {
    this.activeEpisodesOfCare?.forEach(episode => {
      this.activeEpisodesOfCareByName[episode.name] = true;
      episode.protocols?.forEach(protocol => {
        this.activeProtocolsById[protocol.protocolId] = [];
        protocol.modules?.forEach(module => {
          if (module.state == 'active') {
            this.activeProtocolsById[protocol.protocolId].push(module.protocolId);
            this.activeModulesById[module.protocolId] = true;
          }
        })
      })
    })
    await this.loadPatientInviteDetailsForOrg();
  }
  async loadPatientInviteDetailsForOrg() {
    let org = this.orgData
    let protocols = this.orgProtocols;
    protocols.forEach((p) => {
      if (this.activeProtocolsById[p._id]) {
        p.isSelected = true;
      }
      this.protocolsById[p._id] = p;
      this.storeModuleNames(p.modules);
      this.checkModules(p.modules, this.activeProtocolsById[p._id] || [])
    });
    org.hasProcedures =
      org.availableProcedures &&
      Array.isArray(org.availableProcedures) &&
      org.availableProcedures.length > 0;
    org.availableProcedures.forEach( procedure => {
      procedure.isSelected = this.activeEpisodesOfCareByName[procedure.name];
    })
    this.orgData = org;
  }

  storeModuleNames(modules: IModule[]) {
    if (modules) {
      modules.forEach(async (m) => {
        this.modulesById[m.protocolId] = await this.OrgService.getModuleName(m.protocolId);
      });
    }
  }
  checkModules(modules: IModule[], activeModules: string[]) {
    if (modules) {
      modules.forEach(async (m) => {
        m.isActive = activeModules.includes(m.protocolId);
      });
    }
  }


  updateCalculatedValues() {
    for (let eoc of this.availableProcedures) {
      for (let p of eoc.protocols) {
        p.activeTaskCount = p.tasks.reduce(
          (count, t) => (t.isActive ? count + 1 : count),
          0
        );
        if (p.modules) {
          for (let m of p.modules) {
            m.activeTaskCount = m.assignedTasks.reduce(
              (count, t) => (t.isActive ? count + 1 : count),
              0
            );
          }
        }
      }
    }
  }

  // Event handlers
  async onTaskUpdated(t: ProtocolTaskViewModel) {
    // this.refresh()
    for(let eoc of this.availableProcedures){
      for(let protocol of eoc.protocols) {
        if(protocol._id == t.protocolId || protocol.protocolId == t.protocolId){
          // activate/deactivate protocol
          for(let task of protocol.tasks){
            if(task._id == t._id) {
              task = t;
              protocol.activeTaskCount = protocol.tasks.reduce(
                (count, t) => (t.isActive ? count + 1 : count),
                0
              );
              if(!protocol.activeTaskCount && !protocol.modules.filter(module => module.isActive).length) {
                protocol.isActive = false;
              }
              break;
            }
          }
          // activate/deactivate module
          for(let module of protocol.modules){
            for(let task of module.assignedTasks){
              if (task._id == t._id) {
                task = t;
                this.updateModuleState(module)
                break;
              }
            }
            for(let task of module.templateTasks){
              if (task._id == t._id) {
                task = t;
                this.updateModuleState(module)
                break;
              }
            }
          }
        }
      }
    };
  }
  async updateModuleState(module) {
    let activeTasks = module.assignedTasks?.filter(t => t.isActive)
    if (activeTasks?.length && module.state != 'active') { // if there are active tasks and module is deactivated, then activate module
      this.activateOrDeactivateModule({ module: module, operation: 'Activate', tasksToAssign: null, tasksNotToBeDeactivated: null, tasksToActivate: null, tasksToLeaveAlone: null, tasksToBeDeactivated: null }, module.assignedTasks.filter(t => !t.isActive))
    } else
    if (!(activeTasks?.length) && module.state == 'active') { // if there are no active tasks and module is active, then deactivate module
      this.activateOrDeactivateModule({ module: module, operation: 'Deactivate', tasksToAssign: null, tasksNotToBeDeactivated: null, tasksToActivate: null, tasksToLeaveAlone: null, tasksToBeDeactivated: null })
    }
    module.activeTaskCount = activeTasks?.length || 0;
  }
  async moduleCheckClicked(module: any) {
    if(!module) return
    this.selectedModuleActivateDeactivateInfo = this.getModuleActivateDeactivateInfo(
      module,
      module.state != 'active'
      );

  }
  async protocolCheckClicked(protocol: EpisodeOfCareProtocolAssignmentViewModel) {
    if(!module) return
    this.selectedModuleActivateDeactivateInfo = this.getProtocolActivateDeactivateInfo(protocol, !protocol.isActive);
  }
  async activateOrDeactivateModule(info: ModuleActivateDeactivateDialogViewModel, deactivateTasks?: any[]) {
    const activate: boolean = info.operation != 'Deactivate';
    // need to check whether info.module.parentProtocol.parentEpisodeOfCare._id is not undefined
    try {
      if(info.protocol){
        await this.activateOrDeactivateProtocol(info.protocol, activate, [info.module?.protocolId]) // by this time only 1 active module should be active, and it's going to be activated/deactivated bellow
      }
      if(info.module) {
        await this.PatientService.activateOrDeactivateModule({
          userId: this.patient.carePatient._id,
          episodeOfCareId: info.module.parentProtocol.parentEpisodeOfCare._id,
          protocolId: info.module.parentProtocol.protocolId,
          moduleId: info.module.protocolId,
          activate: activate,
        });
        this.toastr.success(`Module ${info.module.protocol?.name} is successfully ${activate ? 'activated' : 'deactivated'}.`)
      }
      if(deactivateTasks?.length) {
        deactivateTasks.forEach( async task => {
          await this.PatientService.activateOrDeactivateTask(task, false);
        })
      }
      this.selectedModuleActivateDeactivateInfo = null;
      this.toastr.info(`Reloading list...`)
      await this.refresh();
    } catch (error) {
      this.toastr.error(`Can't ${activate ? 'activate' : 'deactivate'} module.`)
      console.error(error)
    }
  }
  getProtocolActivateDeactivateInfo(protocol, activate: boolean) {
    let result = {
      operation: activate ? 'Activate' : 'Deactivate',
      protocolTasksToAssign: [],
      protocolTasksToActivate: [],
      protocolTasksToBeDeactivated: [],
      protocolTasksNotToBeDeactivated: [],
      protocolTasksToLeaveAlone: [],
      protocol: protocol,
      procedure: null,
      modulesToActivate: [],
      modulesToBeDeactivated: [],
      modulesToLeaveAlone: []
    };
    protocol.tasks.forEach( task => {
      if(activate) {
        task.isActive ? result.protocolTasksToLeaveAlone.push(task) : result.protocolTasksToAssign.push(task)
      } else {
        task.isActive ? result.protocolTasksToBeDeactivated.push(task) : result.protocolTasksToLeaveAlone.push(task)
      }
    })
    return result;
  }
  async assignProtocol(protocol, parentEpisodeOfCare, excludeModuleIds?) {
    let activeModules = {};
    protocol.modules?.forEach(module => {
      activeModules[module.protocolId] = !excludeModuleIds.includes[module.protocolId];
    })
    await this.PatientService.assignProtocol({
      protocol: protocol,
      patient: this.patient.carePatient,
      modules: activeModules,
      episodeOfCare: parentEpisodeOfCare,
    });
  }
  async assignProcedure(parentEpisodeOfCare) {
    let episodeOfCare: any = { isActive: true };
    episodeOfCare.procedureName = parentEpisodeOfCare
      ? parentEpisodeOfCare.name
      : 'Procedure';
    if (parentEpisodeOfCare && parentEpisodeOfCare.shortName) {
      episodeOfCare.procedureShortName = parentEpisodeOfCare.shortName;
    }
    if (parentEpisodeOfCare && parentEpisodeOfCare.extendedFields) {
      episodeOfCare.extendedFields = parentEpisodeOfCare.extendedFields;
    }
    episodeOfCare.procedureTimeZoneName = this.patient.professional?.currentTimeZoneName || this.patient.currentTimeZoneName || Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await this.PatientService.assignEpisodeOfCare({ episodeOfCare: episodeOfCare, patient: this.patient.carePatient })
  }
  async activateOrDeactivateProtocol(protocol, activate: boolean, excludeModuleIds?: string[]) { // excludeModuleIds => list of modules that should not be activated
    if (this.doesProtocolHaveTwilioFlowTask(protocol) && !this.isPhoneValid(this.phoneNumber)) return;

    if (activate) {
      if (protocol.parentEpisodeOfCare?._id && protocol._id) { // already assigned, just need to reactive
        let tasksToReactivate = protocol?.tasks.filter( task => task._id)
        let tasksToAssign = protocol?.tasks.filter( task => !task._id)
        try {
          tasksToReactivate.length ? await this.activateDeactivateTasks(tasksToReactivate, true) : await this.assignProtocol(protocol, protocol.parentEpisodeOfCare, excludeModuleIds)
        } catch(error) {
          console.error(error)
        }
      } else {
        let parentEpisodeOfCare = this.availableProceduresByName[protocol.parentEpisodeOfCare?.name];
        if (!protocol.parentEpisodeOfCare?._id) {
          parentEpisodeOfCare = await this.assignProcedure(protocol.parentEpisodeOfCare);
        }
        await this.assignProtocol(protocol, parentEpisodeOfCare, excludeModuleIds)
      }
    } else {
      await this.activateDeactivateTasks(protocol?.tasks || [], false)
      for (let module of protocol.modules) {
        if (module.isActive && !(excludeModuleIds?.includes[module.protocolId]))
          await this.PatientService.activateOrDeactivateModule({
            userId: this.patient.carePatient._id,
            episodeOfCareId: module.parentProtocol.parentEpisodeOfCare._id,
            protocolId: module.parentProtocol.protocolId,
            moduleId: module.protocolId,
            activate: false,
          });
        await this.activateDeactivateTasks(module.assignedTasks || [], false);
      }
    }
    this.toastr.success(`Protocol ${protocol?.name} is successfully ${activate ? 'activated' : 'deactivated'}.`)
  };
  async activateDeactivateTasks(tasks, activate: boolean) {
    for(let task of tasks){
      try{
        if(task.isActive != activate) {
          await this.PatientService.activateOrDeactivateTask(task, activate);
          task.isActive = activate
        }
      } catch(error) {
        console.error(error)
        throw error
      }
    }
  }
  expandCollapseAll(expand: boolean, index: number) {
    this.availableProcedures?.[index]?.protocols?.forEach((p) => {
      p.isOpen = expand;
      p.modules?.forEach((m) => {
        m.isOpen = expand;
      });
    });
  }

  // Helpers
  getSwimlaneForTask(t: Task): 'once' | 'daily' | 'weekly' | 'monthly' {
    // @ts-ignore
    let ruleText = t?.relativeSchedule?.recurRule?.toLowerCase();
    if (!ruleText) {
      return 'once';
    }
    if (ruleText.indexOf('freq=daily') >= 0) {
      return 'daily';
    } else if (ruleText.indexOf('freq=weekly') >= 0) {
      return 'weekly';
    } else if (ruleText.indexOf('freq=monthly') >= 0) {
      return 'monthly';
    } else {
      // Not a valid value, really.
      return 'once';
    }
  }
  tasksForSwimlane(
    tasks: Array<TaskViewModel>,
    swimlane: 'once' | 'daily' | 'weekly' | 'monthly'
  ) {
    if (!tasks) {
      return [];
    }
    return tasks.filter((t) => swimlane == t.swimlane);
  }

  /** Calculate the effect of assigning/activating/deactivating a module */
  public getModuleActivateDeactivateInfo(
    mod,
    activate: boolean
  ) {
    let result = {
      module: mod,
      operation: null,
      tasksToAssign: [],
      tasksToActivate: [],
      tasksToBeDeactivated: [],
      tasksNotToBeDeactivated: [],
      tasksToLeaveAlone: [],
      protocolInfo: null,
      procedure: null
    };
    let protocol = this.protocolsById[mod.parentProtocol?.protocolId]
    try {
     if (activate) {
      const moduleIsAssigned = mod.stateHistory?.find(
        (sh) => sh.state === 'active'
      );
      if (moduleIsAssigned) {
        result.operation = 'Activate';
        _.each(mod.assignedTasks, (t) => {
          if (!t.isActive) {
            result.tasksToActivate.push(t);
          } else {
            result.tasksToLeaveAlone.push(t);
          }
        });
      } else {
        result.operation = 'Assign';
        _.each(mod.templateTasks, (tt) => {
          let t = this.findAssignedTaskByProtocolTaskId(tt.protocolTaskId);
          if (t) {
            // Is assigned
            if (t.isActive) {
              result.tasksToLeaveAlone.push(t);
            } else {
              result.tasksToActivate.push(t);
            }
          } else {
            result.tasksToAssign.push(tt);
          }
        });
      }
      if(!protocol?.isActive) {
        result = {...result, ...this.getProtocolActivateDeactivateInfo(protocol, activate)}
      }
    } else {
      result.operation = 'Deactivate';
      // Tasks to be deactivated will be unless they are in another module and already active.
      _.each(mod.assignedTasks, (t) => {
        if (this.isTaskActiveInAnotherModule(t._id, mod)) {
          result.tasksNotToBeDeactivated.push(t);
        } else {
          result.tasksToBeDeactivated.push(t);
        }
      });

      if(protocol?.isActive && !(protocol?.tasks.filter(t => t.isActive).length)) { // check if protocol is active and has no active tasks
        result = {...result, ...this.getProtocolActivateDeactivateInfo(protocol, activate)}
      }
    }

    return result;

    } catch (error) {
      console.error(error)
    }
  }

  public findAssignedTaskByProtocolTaskId(
    protocolTaskId: string
  ): ProtocolTaskViewModel {
    try{
      for (let i = 0; i < this.availableProcedures.length; i++) {
        let e = this.availableProcedures[i];
        for (let j = 0; j < e.protocols.length; j++) {
          let p = e.protocols[j];
          for (let k = 0; k < p.tasks.length; k++) {
            let t = p.tasks[k];
            if (t.protocolTaskId === protocolTaskId) {
              return t;
            }
          }
          if (p.modules) {
            for (let l = 0; l < p.modules.length; l++) {
              const assignedTasks = p.modules[l]?.assignedTasks;
              if (assignedTasks) {
                for (let m = 0; m < assignedTasks.length; m++) {
                  let at = assignedTasks[m];
                  if (protocolTaskId === at.protocolTaskId) {
                    return at;
                  }
                }
              }
            }
          }
        }
      }
    } catch (error){
      console.error(error)
    }

    return null;
  }
  public isTaskActiveInAnotherModule(
    taskId: string,
    mod: EpisodeOfCareModuleAssignmentViewModel
  ) {
    return this.availableProcedures?.find((e) => {
      return e.protocols?.find((p) => {
        return p.modules?.find((mm) => {
          if (mm === mod) {
            return false;
          }
          if (mm.state !== 'active') {
            return false;
          }
          // REVIEW - not sure why assignedTasks is sometimes undefined
          if (!mm.assignedTasks) {
            return false;
          }
          return mm.assignedTasks.find((at) => {
            return taskId === at._id;
          });
        });
      });
    });
  }

  /** Get tasks from the module, which is a protocol */
  templateTasksForModule(
    mod: wpapi.model.Protocol,
    protocolId: string
  ): Array<wpapi.model.ProtocolTask & { swimlane?: string }> {
    if (mod) {
      return (
        mod.tasks?.filter(
            (t) =>
              t.type !== 'patientsatisfaction' || t.subType !== 'wellpepper'
          ).map((t) => {
            const copy: wpapi.model.ProtocolTask & { swimlane?: string } =
              _.cloneDeep(t);
            copy.swimlane = this.getSwimlaneForTask(t as Task);
            return copy;
          })
      ); // Make sure we don't change template tasks.
    }
    return [];
  }

  getTaskTypeText(task: ProtocolTaskViewModel) {
    if (!task.type) {
      return 'Exercise';
    }
    var s = task.type.toLowerCase();
    s = s.toLowerCase();
    switch (s) {
      case 'activitytracker':
        s = 'activity tracker';
        break;
      case 'aboutinfo':
        s = 'about info';
        break;
      case 'painmedication':
        s = 'pain medication';
        break;
      case 'startdoing':
        s = 'start doing';
        break;
      case 'stopdoing':
        s = 'stop doing';
        break;
      case 'patientsatisfaction':
        s = 'patient satisfaction';
        if (task.subType === 'wellpepper') {
          s += ' (Wellpepper)';
        }
        break;
      case 'didyoufall':
        s = 'did you fall';
        break;
      case 'quicktips':
        s = 'quick tips';
        break;
      case 'videodiary':
        s = 'video diary';
        break;
      case 'food diary':
      case 'fooddiary':
        s = 'food diary';
        break;
      case 'meddiary':
        s = 'med diary';
        break;
      case 'goaltracking':
        s = 'goal';
        break;
      case 'bloodpressure':
        s = 'blood pressure';
        break;
      case 'bloodsugar':
        s = 'blood sugar';
        break;
      case 'tasksetup':
        switch ((task.subType || '').toString().toLowerCase()) {
          case 'activity':
            s = 'activity';
            break;
          case 'weight':
            s = 'weight';
            break;
          case 'bloodpressure':
            s = 'blood pressure';
            break;
          default:
            s = 'tracking';
            break;
        }
        break;
      case 'educationvideo':
      case 'educationwebvideo':
        s = 'education video';
        break;
      case 'weighttracker':
        s = 'weight tracker';
        break;
    }
    s = s.replace(/_/g, ' ');
    return s.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  doesProtocolHaveTwilioFlowTask(protocol: Protocol): boolean {
    return !!protocol.tasks?.find(t => t.type == "twilioStudioFlow");
  }
  isPhoneValid(phoneNumber: string): boolean {
    return this.PatientService.isPhoneNumberFormatValid(phoneNumber)
  }

  async updatePhoneNumber() {
    if (this.patient.phoneNumber == this.phoneNumber || !this.isPhoneValid(this.phoneNumber)) return
    try {
      let newPhoneNumber: string = this.phoneNumber?.replace(/\D+/g, '');
      await this.PatientService.updateCarePatient(this.patient.carePatient._id, {
        phoneNumber: newPhoneNumber,
        identifiers: [{
          issuer: PatientIdentifiersIssuers.SmsCarePlanPhoneNumber,
          type: 'custom',
          subject: this.PatientService.formatPhoneNumberForTwilio(newPhoneNumber),
          createdAt: new Date().toDateString(),
          provider: 'custom',
          canBeUpdated: true
        }]
      })
      await this.PatientService.updateTrackPatient(this.tierId, this.tierNum, {
        email: this.patient.email,
        preferredName: this.patient.nickName?.trim() || this.patient.preferredName || "",
        phone: newPhoneNumber,
        tierID: this.tierId,
        tierNum: this.tierNum,
        carePatientId: this.patient.carePatient._id,
        mbi: this.patient.mbi,
        firstName: this.patient.firstName,
        lastName: this.patient.lastName,
        middleName: this.patient.middleName,
        dateOfBirth: this.patient.dob,
        gender: this.patient.gender[0].toUpperCase(),
        chPatId: Number(this.patient.chPatId),
        ChPatID: Number(this.patient.chPatId),
        supplementalId: this.patient.trackPatient.supplementalID
      })
      this.patient.phoneNumber = _.cloneDeep(this.phoneNumber)
    } catch (error) {
      console.error(error)
      return
    }
  }
}
