import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { CareFeatureConstants,PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class AwvBillingPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.AnnualWellnessVisits: PresetCategory.Worklists;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.AwvBilling,
      presetKey: CarePatientItem.AwvBilling,
      label: 'In-Home AWV Billing',
      filters: [],
      componentId: CareComponentConstants.IHEBilling,
      hidePreset: true,
      filterSettings: null,
      columns: this.getPresetColumns(),
      patientUrlQueryParams: { tab: 'Worklist Details' }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    return [
      { select: true },
      { mbi: true },
      { fullName: true },
      { dob: true },
      { gender: true },
      { phoneNumber: true },
      { address: true },
      { IHEAWVVisitDate: true },
      { cptCode: true },
      { claimFrequencyCode: true },
      { IHEAWVBatchDate: true },
      { iheDxCount: true },
      { renderingProvider: true },
      { attributedPractice: true },
      { attributedCP: true }
    ];
  }

}

