import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Patient } from '@shared/models/patient';
import { PatientAwvEnrolService } from "@shared/modules/patient-facesheet/tabs/patient-awv-enrol/patient-awv-enrol.service";
import { AwvWorkflow } from '@api/care-orch/models/awv-workflow';
import { CareOrchestrationConstants } from '@shared/models/module-constants';
import { AwvService } from '@api/care-orch/services/awv.service';
import { Observable } from 'rxjs';

/*
Temporary Component for Care Orchestration - simulating patient enrolment
 */

@Component({
  selector: 'coach-patient-awv-enrol',
  templateUrl: './patient-awv-enrol.component.html'
})
export class PatientAwvEnrolComponent {
  @Input() public patient: Patient;
  @Output() awvWorkflowUpdated: EventEmitter<Observable<AwvWorkflow>> = new EventEmitter();

  constructor(private awvService: AwvService, private enrolService: PatientAwvEnrolService) {}

  async enrol() {
    await this.enrolService.enrol(this.patient.chPatId);
    console.info(`Enrolled patient ${this.patient.chPatId}`);
    let obsWorkflow = this.awvService.getWorkflowActivities(
      {programId: CareOrchestrationConstants.AwvProgramId, chPatId: parseInt(this.patient.chPatId)});
    this.awvWorkflowUpdated.emit(obsWorkflow);
  }
}
