import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';

@Component({
  selector: 'coach-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private hierarchyTierService: HierarchyTierService) { }

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.router.navigate([this.hierarchyTierService.currentTier$.value?.abbreviation, 
          this.hierarchyTierService.currentTier$.value?.selectedTierIdForDisplay, 'home']);
      }
    });
  }

  signIn(): void {
    this.authService.login();
  }
}
