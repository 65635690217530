import { Component, OnInit } from '@angular/core';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { Process } from '@shared/services/processing/process';
import { ProcessingService } from '@shared/services/processing/processing.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'coach-header-processes',
  templateUrl: './header-processes.component.html',
  styleUrls: ['./header-processes.component.scss']
})
export class HeaderProcessesComponent extends KillSubscriptions implements OnInit {

  constructor(private processingService: ProcessingService) {
    super();
  }

  public processes: Process<unknown>[] = [];

  ngOnInit(): void {
    this.processes = this.processingService.processes;
  }

}
