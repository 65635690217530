import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { AzureApiBase } from '../azure-api/azure-api-base';
import { UserEula } from './user-eula';

@Injectable({
  providedIn: 'root'
})
export class EulaService extends AzureApiBase<UserEula> {
  public showEULA$: BehaviorSubject<boolean>;

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/eula');
    this.showEULA$ = new BehaviorSubject(false);
  }

  public setShowEULA(isShown: boolean) {
    this.showEULA$.next(isShown);
  }

}
