import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { PatientFacesheetComponent } from './patient-facesheet.component';
import { PatientCondensedComponent } from './patient-condensed/patient-condensed.component';
import { PatientExpandedComponent } from './patient-expanded/patient-expanded.component';
import { PatientActionDropdownComponent } from './patient-action-dropdown/patient-action-dropdown.component';
import { PatientTimeTrackingComponent } from './shared/patient-time-tracking/patient-time-tracking.component';
import { PatientCareGapsComponent } from '@care/components/patient-care-gaps/patient-care-gaps.component';
import { ChartTimelineComponent } from './tabs/chart-timeline/chart-timeline.component';
import { ChartSurveysComponent } from './tabs/chart-surveys/chart-surveys.component';
import { ChartMedicationsComponent } from './tabs/chart-medications/chart-medications.component';
import { ChartVitalsComponent } from './tabs/chart-vitals/chart-vitals.component';
import { ChartEducationComponent } from './tabs/chart-education/chart-education.component';
import { PatientActivityComponent } from './tabs/patient-activity/patient-activity.component';
import { PatientAlertsComponent } from './tabs/patient-alerts/patient-alerts.component';
import { PatientAlertComponent } from './shared/patient-alert/patient-alert.component';
import { PatientSummaryComponent } from './tabs/patient-summary/patient-summary.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ChartInfoComponent } from './tabs/chart-info/chart-info.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PatientInviteFormComponent } from './patient-invite-form/patient-invite-form.component';
import { SinglePatientInviteFormComponent } from './single-patient-invite-form/single-patient-invite-form.component';
import { PatientEditFormComponent } from './patient-edit-form/patient-edit-form.component';
import { CarePatientCareplanComponent } from './tabs/patient-careplan/patient-careplan.component';
import { PatientTaskComponent } from './tabs/patient-careplan/patient-task/patient-task.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PatientRemindersComponent } from './tabs/patient-reminders/patient-reminders.component';
import { AgGridModule } from 'ag-grid-angular';
import { PatientDemographicsComponent } from './patient-demographics/patient-demographics.component';
import { PatientChronicCodingOpportunitiesComponent } from '@care/components/patient-facesheet/patient-chronic-coding-opportunities/patient-chronic-coding-opportunities.component';
import { PatientFacesheetDragDropComponent } from '@care/components/patient-facesheet/patient-facesheet-drag-drop/patient-facesheet-drag-drop.component';
import { PatientHealthMaintenanceComponent } from '@care/components/patient-facesheet/patient-health-maintenance/patient-health-maintenance.component';
import { PatientUtilizationComponent } from '@care/components/patient-facesheet/patient-utilization/patient-utilization.component';
import { PatientAcuteCodingOpportunitiesComponent } from '@care/components/patient-facesheet/patient-acute-coding-opportunities/patient-acute-coding-opportunities.component';
import { PatientQualityOpportunitiesComponent } from '@care/components/patient-facesheet/patient-quality-opportunities/patient-quality-opportunities.component';
import { PatientPacNavigatorComponent } from './tabs/patient-pac-navigator/patient-pac-navigator.component';
import { CarePatientCareplanEditableComponent } from './tabs/patient-careplan-editable/patient-careplan-editable.component';
import { TimeTrackingDropdownComponent } from './shared/patient-time-tracking/patient-time-tracking-dropdown/patient-time-tracking-dropdown.component';
import { PatientIHEWorklistComponent } from './tabs/patient-ihe-worklist/patient-ihe-worklist.component';
import { PatientWorklistsComponent } from './tabs/patient-worklists/patient-worklists.component';
import { PatientAwvWorkflowComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-workflow/patient-awv-workflow.component';
import { PatientAwvReportComponent } from './tabs/patient-awv-report/patient-awv-report.component';
import { PatientAwvSchedulingComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-awv-scheduling.component';
import { PatientWorkflowScheduledComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-scheduled/patient-workflow-scheduled.component';
import { PatientWorkflowCompletedComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-completed/patient-workflow-completed.component';
import { PatientWorkflowCallBackComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-call-back/patient-workflow-call-back.component';
import { PatientWorkflowRemoveComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-remove/patient-workflow-remove.component';
import { PatientAwvReminderComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-reminder/patient-awv-reminder.component';
import { PatientStepScheduledComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-reminder/patient-step-scheduled/patient-step-scheduled.component';
import { PatientStepCompletedComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-reminder/patient-step-completed/patient-step-completed.component';
import { StepperComponent } from '@shared/components/stepper/stepper.component';
import { PatientStepReminderComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-reminder/patient-step-reminder/patient-step-reminder.component';
import { PatientAwvBannerComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-banner/patient-awv-banner.component';
import { PatientAwvHistoryComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-history/patient-awv-history.component';
import { PatientAwvRemovedComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-removed/patient-awv-removed.component';
import { PatientAwvCompletedComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-completed/patient-awv-completed.component';
import { PatientAwvEnrolComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-enrol/patient-awv-enrol.component';
import { PatientAwvLoadingComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-loading/patient-awv-loading.component';
import { PatientStepReminderFormComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-reminder/patient-step-reminder-form/patient-step-reminder-form.component';
import { PatientAwvCallbackComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-callback/patient-awv-callback.component';
import { PatientLastAwvDetailsComponent } from '@shared/modules/patient-facesheet/tabs/patient-last-awv-details/patient-last-awv-details.component';
import { PatientStepCompletedFormComponent } from '@shared/modules/patient-facesheet/tabs/patient-awv-reminder/patient-step-completed-form/patient-step-completed-form.component';
import { PatientAwvRemoveModalDialogComponent } from './tabs/patient-awv-remove-modal-dialog/patient-awv-remove-modal-dialog.component';
import { PatientAwvDeferredComponent } from './tabs/patient-awv-deferred/patient-awv-deferred.component';
import { PatientAwvErrorComponent } from './tabs/patient-awv-error/patient-awv-error.component';
import { PatientAwvInlineErrorComponent } from './tabs/patient-awv-inline-error/patient-awv-inline-error.component';
import { SmsStandardScheduleComponent } from './tabs/sms-standard-schedule/sms-standard-schedule.component';
import { CustomScheduleComponent } from './tabs/custom-schedule/custom-schedule.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    PatientFacesheetComponent,
    PatientCondensedComponent,
    PatientExpandedComponent,
    PatientActionDropdownComponent,
    PatientTimeTrackingComponent,
    PatientCareGapsComponent,
    ChartTimelineComponent,
    ChartSurveysComponent,
    ChartMedicationsComponent,
    ChartVitalsComponent,
    ChartEducationComponent,
    PatientActivityComponent,
    PatientAlertsComponent,
    PatientAlertComponent,
    CarePatientCareplanComponent,
    PatientSummaryComponent,
    ChartInfoComponent,
    PatientInviteFormComponent,
    SinglePatientInviteFormComponent,
    PatientTaskComponent,
    PatientEditFormComponent,
    PatientRemindersComponent,
    PatientDemographicsComponent,
    PatientChronicCodingOpportunitiesComponent,
    PatientFacesheetDragDropComponent,
    PatientHealthMaintenanceComponent,
    PatientUtilizationComponent,
    PatientAcuteCodingOpportunitiesComponent,
    PatientQualityOpportunitiesComponent,
    PatientPacNavigatorComponent,
    CarePatientCareplanEditableComponent,
    TimeTrackingDropdownComponent,
    PatientIHEWorklistComponent,
    PatientWorklistsComponent,
    PatientAwvWorkflowComponent,
    PatientAwvReportComponent,
    PatientAwvSchedulingComponent,
    PatientWorkflowScheduledComponent,
    PatientWorkflowCompletedComponent,
    PatientWorkflowCallBackComponent,
    PatientWorkflowRemoveComponent,
    PatientAwvReminderComponent,
    PatientStepScheduledComponent,
    PatientStepReminderComponent,
    PatientStepCompletedComponent,
    StepperComponent,
    PatientStepReminderFormComponent,
    PatientAwvBannerComponent,
    PatientAwvHistoryComponent,
    PatientAwvRemovedComponent,
    PatientAwvCompletedComponent,
    PatientAwvEnrolComponent,
    PatientAwvLoadingComponent,
    PatientAwvCallbackComponent,
    PatientLastAwvDetailsComponent,
    PatientStepCompletedFormComponent,
    PatientAwvRemoveModalDialogComponent,
    PatientAwvDeferredComponent,
    PatientAwvErrorComponent,
    PatientAwvInlineErrorComponent,
    SmsStandardScheduleComponent,
    CustomScheduleComponent
  ],
  imports: [
    FontAwesomeModule,
    OverlayModule,
    SharedModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AgGridModule,
    MatInputModule,
    MatAutocompleteModule
  ],
  exports: [
    PatientFacesheetComponent,
    SinglePatientInviteFormComponent,
    PatientInviteFormComponent,
    PatientPacNavigatorComponent,
    TimeTrackingDropdownComponent,
    PatientIHEWorklistComponent,
    PatientAwvReportComponent,
    PatientAwvSchedulingComponent,
    PatientAwvReminderComponent,
    PatientAwvRemovedComponent,
    PatientAwvBannerComponent,
    PatientAwvHistoryComponent,
    PatientAwvCompletedComponent,
    PatientAwvEnrolComponent,
    PatientAwvLoadingComponent,
    PatientAwvCallbackComponent,
    PatientLastAwvDetailsComponent,
    PatientStepCompletedFormComponent,
    PatientAwvDeferredComponent,
    PatientAwvErrorComponent,
    PatientAwvInlineErrorComponent
  ]
})
export class PatientFacesheetModule {}
