<div class="header-titles row--verticalCenter u-truncate u-fill">
  <div *ngIf="activeView$ | async as activeView" class="header-title-wrapper row--verticalCenter u-truncate"
    (click)="clickTitle()">
    <coach-icon [iconName]="activeView.icon" iconColor="currentColor" [ngClass]="
        (isHeaderConsdensed$ | async)
          ? 'margin-right--smallest'
          : 'margin-right--smaller'
      "></coach-icon>
    <h3 class="margin--none u-truncate" title="{{ activeView.label }}">
      {{ activeView.label }}
    </h3>
  </div>
  <coach-header-breadcrumbs [showBreadcrumbs]="isHeaderConsdensed$ | async"
    (activeViewCrumb)="setActiveViewCrumb($event)" class="header-breadcrumbs row--verticalCenter u-preserve">
  </coach-header-breadcrumbs>
  <div class="u-display--flex dynamicBody" id="header-title-container"></div>
</div>

<div *ngIf="currentTier != null" class="header-scope group u-cursor--pointer" (click)="openScopeSelectDropdown()" #scopeSelectOrigin>
  <div class="label">{{ currentTier?.name }}</div>
  <div class="field row--verticalCenter u-color--interaction">
    <div class="u-fill u-truncate">
      {{ currentTier?.selectedItem?.tierName }}
    </div>
    <coach-icon class="u-preserve" iconName="chevron-down" iconSize="small" iconColor="currentColor"></coach-icon>
  </div>
</div>

<div class="row--right u-fill" *ngIf="currentTier != null">
  <button class="button border--none margin-left"
  [hidden]="processes.length === 0"
    (click)="openProcessesDropdown()" #processesOrigin>
    <coach-icon iconName="download"></coach-icon>
    <div *ngIf="completedProcessCount > 0" [ngClass]="hasProcessError ? 'u-colors--danger' : 'u-colors--success'" class="badge">{{completedProcessCount}}</div>
  </button>
  <button [ngClass]="
      searchActivated ? 'button margin-left' : 'button border--none margin-left'
    " (click)="openSearchDropdown()" #searchOrigin>
    <coach-icon iconName="search"></coach-icon>
  </button>
</div>

<ng-template cdkConnectedOverlay>
  <ng-template [cdkPortalOutlet]="scopeSelectDropdownPortal"></ng-template>
  <ng-template [cdkPortalOutlet]="searchDropdownPortal"></ng-template>
</ng-template>