import { Component, Input, OnInit } from '@angular/core';
import { DocRepo } from '@shared/models/docRepo/list';

@Component({
  selector: 'coach-doc-repo-item',
  templateUrl: './doc-repo-item.component.html',
  styleUrls: ['./doc-repo-item.component.scss']
})
export class DocRepoItemComponent implements OnInit{

  @Input() public item: DocRepo;

  public iconType;

  constructor() {}

  ngOnInit(): void {
    if (this.item) {
      if (this.item.EntryType == 'Folder'){
        if (this.item?.Children) {
          this.iconType = 'folder';
        } else {
          this.iconType = 'folder-empty';
        }
      } else if (this.item.EntryType == 'Page') {
        this.iconType = 'book-open';
      } else {
        this.iconType = 'file';
      }
    }
  }

}
