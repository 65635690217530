<ng-container *hasFeature="performanceDashboardFeature">
    <div *hasFeatureFlag="performanceDashboardFeatureFlag" class="widget" data-cy="perfromance_widget" ngxMasonryItem>
        <div class="header header--slanted u-accentColor--track u-hoverEffect"
            (click)="navigateToItem($event, '/track/performance')">
            <div class="titles t5">
                <div class="title">{{performanceDashboardName}}</div>
            </div>
            <div *ngIf="!hideIllustration"
                class="label row--verticalCenter u-color--accentColor widget--label padding--none">
                <coach-icon iconName="trending-up" iconColor="currentColor" class="margin-right--smaller"
                    iconSize="small">
                </coach-icon>
                <span>Track</span>
            </div>
        </div>
        <div class="dynamicBody padding--large">
            <img *ngIf="!hideIllustration" class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_PD_Track_1700x1100_20221114.svg" />
            <p class="u-italic u-opacity--light margin-top--smaller">
                Check out how you are doing against the core measures and manage your attributed patient population to
                maximize your shared savings.
            </p>
            <ul class="margin-top--large margins-bottom--small">
                <li *ngFor="let data of widgetData">
                    <div class="u-display--flex">
                        <a [routerLink]=""
                            (click)="navigateToItem($event, '/track/performance', { trackId: data.id })">{{data.name}}</a>
                        <coach-icon *ngIf="data.sharedSavings" title="Includes Shared Savings metrics"
                            class="shared-savings-icon is-thin" iconName="shared-savings" iconColor="primary"
                            iconSize="small"></coach-icon>

                        <span class="u-border-radius--rounded section-status u-display--flex" [ngClass]="{'u-colors--warning': data.targetStatus === needsImprovement, 
                                    'u-colors--danger': data.targetStatus === needsAttention, 
                                    'u-colors--negative': data.targetStatus === notConnected,
                                    'u-colors--affirmative': data.targetStatus === connected,
                                    'u-color--success': data.targetStatus === hittingTarget}">

                            <coach-icon *ngIf="data.targetStatus === needsImprovement" class="status-icon"
                                iconName="alert" iconColor="currentColor" iconSize="small" title="Needs Improvement">
                            </coach-icon>

                            <coach-icon *ngIf="data.targetStatus === hittingTarget" class="status-icon"
                                title="Hitting Target" iconName="check" iconColor="currentColor" iconSize="small">
                            </coach-icon>

                            <coach-icon *ngIf="data.targetStatus === connected" class="status-icon connected-status"
                                title="Connected" iconName="link" iconColor="currentColor" iconSize="small">
                            </coach-icon>

                            <coach-icon *ngIf="data.targetStatus === notConnected" class="status-icon not-connected-status"
                                title="Not Connected" iconName="broken-link" iconColor="currentColor" iconSize="small">
                            </coach-icon>

                            <span *ngIf="data.targetStatus === needsAttention" class="needs-attention"
                                title="Needs Attention">!</span>

                            <span *ngIf="data.targetStatus == null" class="no-status"
                                title="No specific target set, just need to monitor">—</span>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</ng-container>