import { Component, OnInit, Input } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Patient } from '@shared/models/patient';
import { PatientTimelineService } from '@shared/services/patient/patient-timeline.service';

import { Subscription } from 'rxjs';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { PatientService } from '@shared/services/patient/patient.service';

@Component({
  selector: 'coach-chart-timeline',
  templateUrl: './chart-timeline.component.html',
  providers: [DatePipe],
})
export class ChartTimelineComponent implements OnInit {
  @Input() public tier: IHierarchyTier;

  public timelineItems = [];
  public timlineItemsSub: Subscription;
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  @Input() set patient(value: Patient) {
    if (value != null) {
      this.timlineItemsSub = this.patientTimelineService
        .getTimelineItems(
          parseInt(value.trackPatient?.chPatId),
          value.trackPatient?.supplementalID
        )
        .subscribe((item) => {
          this.timelineItems = item;
          let currentMonth: string;
          let previousMonth: string;
          this.timelineItems.forEach((timelineItem) => {
            currentMonth = this.datePipe.transform(
              timelineItem.claimFromDate,
              'MMMM y'
            );
            if (currentMonth !== previousMonth) {
              timelineItem.newMonthSeparator = true;
            } else {
              timelineItem.newMonthSeparator = false;
            }
            previousMonth = this.datePipe.transform(
              timelineItem.claimFromDate,
              'MMMM y'
            );
          });
        });
      //should we also get the TierId?
      //(detail, compId, subComp, actionId, chPatId, tierId, orgId);
      this.auditService.auditLog(
        'Patient Timeline',
        ComponentId.Dashboard,
        SubComponentId.TimeLine,
        ActionId.PHIAccess,
        value.trackPatient?.chPatId,
        this.tier,
        null
      );
    }
  }

  constructor(
    private patientTimelineService: PatientTimelineService,
    private datePipe: DatePipe,
    private auditService: AuditService,
    private PatientService: PatientService
  ) {}

  ngOnInit(): void {}
}
