import { Component, Input, OnInit } from '@angular/core';
import { Params, UrlSerializer } from '@angular/router';
import { RecentsLookupService } from '@shared/services/recents-lookup/recents-lookup.service';
import { RecentsItem } from '@shared/services/recents-cache/recents-cache.service';
import { TierNameLookupService } from '@shared/services/tier-name-lookup/tier-name-lookup.service';
import { RecentsMatchers } from '@shared/models/recent-matchers';

@Component({
  selector: 'coach-sidebar-recent-element',
  templateUrl: './sidebar-recent-element.component.html',
  styleUrls: ['./sidebar-recent-element.component.scss'],
})
export class SidebarRecentElementComponent implements OnInit {
  @Input() item: RecentsItem;
  public module: string;
  public tierName: string;
  public title: string;
  public routerURL: string;
  public queryParams: Params;

  constructor(
    private recentsLookupService: RecentsLookupService,
    private tierNameLookupService: TierNameLookupService,
    private urlSerializer: UrlSerializer
  ) {}

  parseFeature(input: string): string {
    const words = input?.split('?')[0].split('-');
    return words
      ?.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      ?.join(' ');
  }

  async ngOnInit(): Promise<void> {
    this.routerURL = this.item.navURL.split('?')[0];
    this.queryParams = this.urlSerializer.parse(this.item.navURL).queryParams;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, _tier, tierId, module, feature] = this.item.navURL.split('/');

    this.tierName = await this.tierNameLookupService.getTierName(tierId);

    this.module = module?.toUpperCase();

    for (let idx = 0; idx < RecentsMatchers.length; idx++) {
      if (RecentsMatchers[idx].match(this.item.navURL)) {
        let title = RecentsMatchers[idx].title(this.item.navURL);
        if (title) {
          this.title = title;
        } else {
          const lookupResult = await this.recentsLookupService.getTitle(
            this.item
          );
          if (lookupResult === null) {
            this.title = this.parseFeature(feature);
          } else {
            this.title = lookupResult;
          }
        }
        break;
      }
    }
  }
}
