<div class="filter-panel border-bottom" style="position: absolute; z-index: 100">
    <coach-toggle-pane class="u-height--full" [(isOpen)]="isFilterPaneOpen">
        <div class="titles" pane-header>
            <div class="title">Time Tracking Settings</div>
        </div>
        <div pane-body>
            <label class="label margin-top">Year</label>
            <select name="year" [(ngModel)]="selectedYear" (ngModelChange)="yearSelected()">
                <option *ngFor="let year of years" [ngValue]="year">
                    {{year.display}}
                </option>
            </select>
            <label class="label margin-top">Month</label>
            <select name="month" [(ngModel)]="selectedMonth" (ngModelChange)="monthSelected()">
                <option *ngFor="let month of months" [ngValue]="month">
                    {{month.display}}
                </option>
            </select>
            <div class="margin-top--small padding-leftRight--small" *ngIf="selectedList">
                <input [(ngModel)]="selectedList.detailed" class="toggle" id="togglea" type="checkbox"><label for="togglea">Detailed report</label>
            </div>
            <div class="u-display--flex margin-top--small u-flex-direction--row u-justify-content--spaceBetween">
                <button (click)="exportClick('csv')" class="button is-small is-primary">Export CSV</button>
                <button (click)="exportClick('copyToClipboard')" class="button is-small">Copy to clipboard</button>
            </div>
        </div>
    </coach-toggle-pane>
</div>