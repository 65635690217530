<div class="section" *ngIf="patient">

  <!-- // HEADER -->
  <div class="header border-top--none border-bottom padding-bottom--smaller">
    <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--large u-align-items--bottom">
        <div class="titles t4">
            <b class="title">
                <!-- // TITLE -->
                UTILIZATION
              </b>
        </div>
    </div>
  </div>

  <!-- // BODY -->
  <div class="margin-left--larger margin-right--smaller margin-topBottom--smaller">
       <table class="care-gaps-table">
          <tbody>

              <!-- Emergency Department Visits-->
              <tr>
                  <td class="icon-placeholder u-text-align--right u-bold">
                      {{( (!costUtilization.edVisits) || costUtilization.edVisits == 0)? 0 : costUtilization.edVisits}}
                  </td>
                  <td>
                      <b>Emergency Department Visits (15 mo)</b>
                  </td>
              </tr>
              <tr>
                  <td class="icon-placeholder">
                      <!-- // ICON OR EMPTY SPACE -->
                  </td>
                  <td>
                      <!-- // COLUMN 2  -->
                      <!-- // TIP: USE "u-text-align--right" CLASS -->
                      <div *ngIf="costUtilization.edVisits" class="u-thin u-display--flex u-flex-direction--row u-display--flex u-align-items--start u-justify-content--spaceBetween">
                          <div class="u-color--highlight" style="margin-left:1em">
                              <i>
                                  {{costUtilization.edLastVisitDxCode}} : {{costUtilization.edLastVisitDxDesc}}
                              </i>
                          </div>
                          <div class="u-text--nowrap"> {{toTitleCase(costUtilization.edLastVisitFacility)}}, {{costUtilization.edLastVisitDOS | date:'MM/dd/yy'}}
                          </div>
                      </div>
                      <span *ngIf="!costUtilization.edVisits" class="u-color--highlight u-italic" style="margin-left:1em">No Record Found</span>
                  </td>
              </tr>

              <!-- Impatient stay -->
              <tr>
                  <td class="icon-placeholder u-text-align--right u-bold">
                      {{(!costUtilization.inpatientVisits || (costUtilization.inpatientVisits == 0))? 0 :
                          costUtilization.inpatientVisits }}
                  </td>
                  <td>
                       <b>Inpatient Stay (15 mo)</b>
                  </td>
              </tr>
              <tr>
                  <td class="icon-placeholder">
                      <!-- // ICON OR EMPTY SPACE -->
                  </td>
                  <td>
                      <!-- // COLUMN 2  -->
                      <!-- // TIP: USE "u-text-align--right" CLASS -->
                      <div *ngIf="costUtilization.inpatientVisits" class="u-thin u-display--flex u-flex-direction--row u-display--flex u-align-items--start u-justify-content--spaceBetween">
                          <div class="u-color--highlight" style="margin-left:1em">
                              <i>{{costUtilization.ipLastVisitDxCode}} : {{costUtilization.ipLastVisitDxDesc}}</i>
                          </div>
                          <div class="u-text--nowrap"> {{toTitleCase(costUtilization.ipLastVisitFacility)}}, {{costUtilization.ipLastVisitDOS | date:'MM/dd/yy'}}
                          </div>
                      </div>
                      <span *ngIf="!costUtilization.inpatientVisits" class="u-color--highlight u-italic" style="margin-left:1em">No Record Found</span>
                  </td>
              </tr>

              <!-- Post Acute -->
            <tr>
                <ng-container *ngIf="costUtilization.postAcute && !isNumber(costUtilization.postAcute)">
                    <td colspan="2">
                        <!-- // COLUMN 1 -->
                        <b class="margin-left">{{ costUtilization.postAcute }}, Post Acute Care (6 mo)</b>
                    </td>
                </ng-container>
                <ng-container *ngIf="!(costUtilization.postAcute && !isNumber(costUtilization.postAcute))">
                    <td class="icon-placeholder u-text-align--right u-bold">
                        {{(!costUtilization.postAcute || costUtilization.postAcute == 0)? 0 : costUtilization.postAcute}}
                    </td>
            
                    <td>
                        <!-- // COLUMN 1 -->
                        <b>Post Acute Care (6 mo)</b>
                    </td>
                </ng-container>
            </tr>
              <tr>
                  <td class="icon-placeholder">
                      <!-- // ICON OR EMPTY SPACE -->
                  </td>
                  <td>
                      <!-- // COLUMN 2  -->
                      <!-- // TIP: USE "u-text-align--right" CLASS -->
                      <div *ngIf="costUtilization.postAcute" class="u-thin u-display--flex u-flex-direction--row u-display--flex u-align-items--start u-justify-content--spaceBetween">
                          <div class="u-color--highlight" style="margin-left:1em">
                              <i>{{costUtilization.pacLastVisitDxCode}} : {{costUtilization.pacLastVisitDxDesc}}</i>
                          </div>
                          <div class="u-text--nowrap"> {{toTitleCase(costUtilization.pacLastVisitFacility)}}, {{costUtilization.pacLastVisitDOS |
                              date:'MM/dd/yy'}} </div>
                      </div>
                      <span *ngIf="!costUtilization.postAcute" class="u-color--highlight u-italic" style="margin-left:1em">No Record Found</span>
                  </td>
              </tr>
            </tbody>
        </table>
  </div>
</div>
