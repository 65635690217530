/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Sp340BceLocation } from '../models/sp-340-bce-location';
import { Sp340BClaim } from '../models/sp-340-b-claim';
import { Sp340BClaimDetail } from '../models/sp-340-b-claim-detail';
import { Sp340BClaimException } from '../models/sp-340-b-claim-exception';
import { Sp340BClaimIds } from '../models/sp-340-b-claim-ids';
import { Sp340BClaimNote } from '../models/sp-340-b-claim-note';
import { Sp340BFileAttachment } from '../models/sp-340-b-file-attachment';
import { Sp340BLogMessage } from '../models/sp-340-b-log-message';
import { Sp340BPharmacy } from '../models/sp-340-b-pharmacy';
import { Sp340BPrescriber } from '../models/sp-340-b-prescriber';
import { Sp340BSimpleResponse } from '../models/sp-340-b-simple-response';

@Injectable({
  providedIn: 'root',
})
export class Sp340BClaimsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation sp340BClaimsGet
   */
  static readonly Sp340BClaimsGetPath = '/Sp340BClaims';

  /**
   * Get claims data
   * No parameters returns all.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGet$Response(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BClaim>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetPath, 'get');
    if (params) {
      rb.query('acoId', params.acoId, {});
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('tier3Id', params.tier3Id, {});
      rb.query('tier4Id', params.tier4Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BClaim>>;
      })
    );
  }

  /**
   * Get claims data
   * No parameters returns all.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGet(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BClaim>> {

    return this.sp340BClaimsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BClaim>>) => r.body as Array<Sp340BClaim>)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetDetailGet
   */
  static readonly Sp340BClaimsGetDetailGetPath = '/Sp340BClaims/getDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetDetailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetDetailGet$Response(params?: {
    claimPk?: number;
    claimId?: number;
    acoId?: string;
    tier2_id?: string;
    tier3_id?: string;
    tier4_grpId?: string;
    claimType?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BClaimDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetDetailGetPath, 'get');
    if (params) {
      rb.query('claimPk', params.claimPk, {});
      rb.query('claimId', params.claimId, {});
      rb.query('acoId', params.acoId, {});
      rb.query('tier2_id', params.tier2_id, {});
      rb.query('tier3_id', params.tier3_id, {});
      rb.query('tier4_grpId', params.tier4_grpId, {});
      rb.query('claimType', params.claimType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BClaimDetail>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetDetailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetDetailGet(params?: {
    claimPk?: number;
    claimId?: number;
    acoId?: string;
    tier2_id?: string;
    tier3_id?: string;
    tier4_grpId?: string;
    claimType?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BClaimDetail>> {

    return this.sp340BClaimsGetDetailGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BClaimDetail>>) => r.body as Array<Sp340BClaimDetail>)
    );
  }

  /**
   * Path part for operation sp340BClaimsSetDetailPost
   */
  static readonly Sp340BClaimsSetDetailPostPath = '/Sp340BClaims/setDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsSetDetailPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsSetDetailPost$Response(params?: {
    body?: Sp340BClaimDetail
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BClaimDetail>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsSetDetailPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BClaimDetail>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsSetDetailPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsSetDetailPost(params?: {
    body?: Sp340BClaimDetail
  },
  context?: HttpContext

): Observable<Sp340BClaimDetail> {

    return this.sp340BClaimsSetDetailPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BClaimDetail>) => r.body as Sp340BClaimDetail)
    );
  }

  /**
   * Path part for operation sp340BClaimsSaveClaimNotePost
   */
  static readonly Sp340BClaimsSaveClaimNotePostPath = '/Sp340BClaims/saveClaimNote';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsSaveClaimNotePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsSaveClaimNotePost$Response(params?: {
    body?: Sp340BClaimNote
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BClaimNote>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsSaveClaimNotePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BClaimNote>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsSaveClaimNotePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsSaveClaimNotePost(params?: {
    body?: Sp340BClaimNote
  },
  context?: HttpContext

): Observable<Sp340BClaimNote> {

    return this.sp340BClaimsSaveClaimNotePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BClaimNote>) => r.body as Sp340BClaimNote)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetApprovedClaimsGet
   */
  static readonly Sp340BClaimsGetApprovedClaimsGetPath = '/Sp340BClaims/getApprovedClaims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetApprovedClaimsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetApprovedClaimsGet$Response(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BClaim>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetApprovedClaimsGetPath, 'get');
    if (params) {
      rb.query('acoId', params.acoId, {});
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('tier3Id', params.tier3Id, {});
      rb.query('tier4Id', params.tier4Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BClaim>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetApprovedClaimsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetApprovedClaimsGet(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BClaim>> {

    return this.sp340BClaimsGetApprovedClaimsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BClaim>>) => r.body as Array<Sp340BClaim>)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetPrescribersGet
   */
  static readonly Sp340BClaimsGetPrescribersGetPath = '/Sp340BClaims/getPrescribers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetPrescribersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetPrescribersGet$Response(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BPrescriber>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetPrescribersGetPath, 'get');
    if (params) {
      rb.query('acoId', params.acoId, {});
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('tier3Id', params.tier3Id, {});
      rb.query('tier4Id', params.tier4Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BPrescriber>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetPrescribersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetPrescribersGet(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BPrescriber>> {

    return this.sp340BClaimsGetPrescribersGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BPrescriber>>) => r.body as Array<Sp340BPrescriber>)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetPharmacyNamesGet
   */
  static readonly Sp340BClaimsGetPharmacyNamesGetPath = '/Sp340BClaims/getPharmacyNames';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetPharmacyNamesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetPharmacyNamesGet$Response(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BPharmacy>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetPharmacyNamesGetPath, 'get');
    if (params) {
      rb.query('acoId', params.acoId, {});
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('tier3Id', params.tier3Id, {});
      rb.query('tier4Id', params.tier4Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BPharmacy>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetPharmacyNamesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetPharmacyNamesGet(params?: {
    acoId?: string;
    tier2Id?: string;
    tier3Id?: string;
    tier4Id?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BPharmacy>> {

    return this.sp340BClaimsGetPharmacyNamesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BPharmacy>>) => r.body as Array<Sp340BPharmacy>)
    );
  }

  /**
   * Path part for operation sp340BClaimsSetClaimsToProcessedPost
   */
  static readonly Sp340BClaimsSetClaimsToProcessedPostPath = '/Sp340BClaims/setClaimsToProcessed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsSetClaimsToProcessedPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsSetClaimsToProcessedPost$Response(params?: {
    body?: Sp340BClaimIds
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsSetClaimsToProcessedPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsSetClaimsToProcessedPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsSetClaimsToProcessedPost(params?: {
    body?: Sp340BClaimIds
  },
  context?: HttpContext

): Observable<boolean> {

    return this.sp340BClaimsSetClaimsToProcessedPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetCeLocationsGet
   */
  static readonly Sp340BClaimsGetCeLocationsGetPath = '/Sp340BClaims/getCELocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetCeLocationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetCeLocationsGet$Response(params?: {
    ccn?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BceLocation>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetCeLocationsGetPath, 'get');
    if (params) {
      rb.query('ccn', params.ccn, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BceLocation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetCeLocationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetCeLocationsGet(params?: {
    ccn?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BceLocation>> {

    return this.sp340BClaimsGetCeLocationsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BceLocation>>) => r.body as Array<Sp340BceLocation>)
    );
  }

  /**
   * Path part for operation sp340BClaimsWriteLogMessagePost
   */
  static readonly Sp340BClaimsWriteLogMessagePostPath = '/Sp340BClaims/writeLogMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsWriteLogMessagePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsWriteLogMessagePost$Response(params?: {
    body?: Sp340BLogMessage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsWriteLogMessagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsWriteLogMessagePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsWriteLogMessagePost(params?: {
    body?: Sp340BLogMessage
  },
  context?: HttpContext

): Observable<boolean> {

    return this.sp340BClaimsWriteLogMessagePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetDetailWithIdGet
   */
  static readonly Sp340BClaimsGetDetailWithIdGetPath = '/Sp340BClaims/getDetailWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetDetailWithIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetDetailWithIdGet$Response(params?: {
    claimId?: number;
    claimType?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BClaimDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetDetailWithIdGetPath, 'get');
    if (params) {
      rb.query('claimId', params.claimId, {});
      rb.query('claimType', params.claimType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BClaimDetail>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetDetailWithIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetDetailWithIdGet(params?: {
    claimId?: number;
    claimType?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BClaimDetail>> {

    return this.sp340BClaimsGetDetailWithIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BClaimDetail>>) => r.body as Array<Sp340BClaimDetail>)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetClaimWithIdGet
   */
  static readonly Sp340BClaimsGetClaimWithIdGetPath = '/Sp340BClaims/getClaimWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetClaimWithIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetClaimWithIdGet$Response(params?: {
    tier2Id?: string;
    claimId?: number;
    claimType?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BClaim>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetClaimWithIdGetPath, 'get');
    if (params) {
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('claimId', params.claimId, {});
      rb.query('claimType', params.claimType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BClaim>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetClaimWithIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetClaimWithIdGet(params?: {
    tier2Id?: string;
    claimId?: number;
    claimType?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BClaim>> {

    return this.sp340BClaimsGetClaimWithIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BClaim>>) => r.body as Array<Sp340BClaim>)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetClaimDetailHistoryGet
   */
  static readonly Sp340BClaimsGetClaimDetailHistoryGetPath = '/Sp340BClaims/getClaimDetailHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetClaimDetailHistoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetClaimDetailHistoryGet$Response(params?: {
    claimId?: number;
    status?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BClaimDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetClaimDetailHistoryGetPath, 'get');
    if (params) {
      rb.query('claimId', params.claimId, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BClaimDetail>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetClaimDetailHistoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetClaimDetailHistoryGet(params?: {
    claimId?: number;
    status?: number;
  },
  context?: HttpContext

): Observable<Array<Sp340BClaimDetail>> {

    return this.sp340BClaimsGetClaimDetailHistoryGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BClaimDetail>>) => r.body as Array<Sp340BClaimDetail>)
    );
  }

  /**
   * Path part for operation sp340BClaimsUploadFilesPost
   */
  static readonly Sp340BClaimsUploadFilesPostPath = '/Sp340BClaims/uploadFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsUploadFilesPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sp340BClaimsUploadFilesPost$Response(params?: {
    body?: {
'ContentType'?: string;
'ContentDisposition'?: string;
'Headers'?: {
[key: string]: Array<string>;
};
'Length'?: number;
'Name'?: string;
'FileName'?: string;
'claimId'?: string;
'tier2Id'?: string;
'prescriptionRefID'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BSimpleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsUploadFilesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BSimpleResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsUploadFilesPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sp340BClaimsUploadFilesPost(params?: {
    body?: {
'ContentType'?: string;
'ContentDisposition'?: string;
'Headers'?: {
[key: string]: Array<string>;
};
'Length'?: number;
'Name'?: string;
'FileName'?: string;
'claimId'?: string;
'tier2Id'?: string;
'prescriptionRefID'?: string;
}
  },
  context?: HttpContext

): Observable<Sp340BSimpleResponse> {

    return this.sp340BClaimsUploadFilesPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BSimpleResponse>) => r.body as Sp340BSimpleResponse)
    );
  }

  /**
   * Path part for operation sp340BClaimsDeleteUploadedFilePost
   */
  static readonly Sp340BClaimsDeleteUploadedFilePostPath = '/Sp340BClaims/deleteUploadedFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsDeleteUploadedFilePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsDeleteUploadedFilePost$Response(params?: {
    body?: Sp340BFileAttachment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BSimpleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsDeleteUploadedFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BSimpleResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsDeleteUploadedFilePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sp340BClaimsDeleteUploadedFilePost(params?: {
    body?: Sp340BFileAttachment
  },
  context?: HttpContext

): Observable<Sp340BSimpleResponse> {

    return this.sp340BClaimsDeleteUploadedFilePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BSimpleResponse>) => r.body as Sp340BSimpleResponse)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetUploadedFileListGet
   */
  static readonly Sp340BClaimsGetUploadedFileListGetPath = '/Sp340BClaims/getUploadedFileList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetUploadedFileListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetUploadedFileListGet$Response(params?: {
    tier2Id?: string;
    prescriptionRefId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BFileAttachment>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetUploadedFileListGetPath, 'get');
    if (params) {
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('prescriptionRefId', params.prescriptionRefId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BFileAttachment>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetUploadedFileListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetUploadedFileListGet(params?: {
    tier2Id?: string;
    prescriptionRefId?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BFileAttachment>> {

    return this.sp340BClaimsGetUploadedFileListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BFileAttachment>>) => r.body as Array<Sp340BFileAttachment>)
    );
  }

  /**
   * Path part for operation sp340BClaimsDownloadFileGet
   */
  static readonly Sp340BClaimsDownloadFileGetPath = '/Sp340BClaims/downloadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsDownloadFileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsDownloadFileGet$Response(params?: {
    tier2Id?: string;
    prescriptionRefId?: string;
    filename?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsDownloadFileGetPath, 'get');
    if (params) {
      rb.query('tier2Id', params.tier2Id, {});
      rb.query('prescriptionRefId', params.prescriptionRefId, {});
      rb.query('filename', params.filename, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsDownloadFileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsDownloadFileGet(params?: {
    tier2Id?: string;
    prescriptionRefId?: string;
    filename?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.sp340BClaimsDownloadFileGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sp340BClaimsUndoProcessClaimPost
   */
  static readonly Sp340BClaimsUndoProcessClaimPostPath = '/Sp340BClaims/undoProcessClaim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsUndoProcessClaimPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsUndoProcessClaimPost$Response(params?: {
    coach340BClaimsID?: number;
    claimType?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BSimpleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsUndoProcessClaimPostPath, 'post');
    if (params) {
      rb.query('coach340BClaimsID', params.coach340BClaimsID, {});
      rb.query('claimType', params.claimType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BSimpleResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsUndoProcessClaimPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsUndoProcessClaimPost(params?: {
    coach340BClaimsID?: number;
    claimType?: string;
  },
  context?: HttpContext

): Observable<Sp340BSimpleResponse> {

    return this.sp340BClaimsUndoProcessClaimPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BSimpleResponse>) => r.body as Sp340BSimpleResponse)
    );
  }

  /**
   * Path part for operation sp340BClaimsGetClaimExceptionsGet
   */
  static readonly Sp340BClaimsGetClaimExceptionsGetPath = '/Sp340BClaims/getClaimExceptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsGetClaimExceptionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetClaimExceptionsGet$Response(params?: {
    tier2Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Sp340BClaimException>>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsGetClaimExceptionsGetPath, 'get');
    if (params) {
      rb.query('tier2Id', params.tier2Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sp340BClaimException>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsGetClaimExceptionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsGetClaimExceptionsGet(params?: {
    tier2Id?: string;
  },
  context?: HttpContext

): Observable<Array<Sp340BClaimException>> {

    return this.sp340BClaimsGetClaimExceptionsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Sp340BClaimException>>) => r.body as Array<Sp340BClaimException>)
    );
  }

  /**
   * Path part for operation sp340BClaimsMoveExceptionToClaimGet
   */
  static readonly Sp340BClaimsMoveExceptionToClaimGetPath = '/Sp340BClaims/moveExceptionToClaim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsMoveExceptionToClaimGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsMoveExceptionToClaimGet$Response(params?: {
    exceptionId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BSimpleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsMoveExceptionToClaimGetPath, 'get');
    if (params) {
      rb.query('exceptionId', params.exceptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BSimpleResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsMoveExceptionToClaimGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsMoveExceptionToClaimGet(params?: {
    exceptionId?: string;
  },
  context?: HttpContext

): Observable<Sp340BSimpleResponse> {

    return this.sp340BClaimsMoveExceptionToClaimGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BSimpleResponse>) => r.body as Sp340BSimpleResponse)
    );
  }

  /**
   * Path part for operation sp340BClaimsRejectExceptionGet
   */
  static readonly Sp340BClaimsRejectExceptionGetPath = '/Sp340BClaims/rejectException';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sp340BClaimsRejectExceptionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsRejectExceptionGet$Response(params?: {
    exceptionId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Sp340BSimpleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Sp340BClaimsService.Sp340BClaimsRejectExceptionGetPath, 'get');
    if (params) {
      rb.query('exceptionId', params.exceptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Sp340BSimpleResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sp340BClaimsRejectExceptionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sp340BClaimsRejectExceptionGet(params?: {
    exceptionId?: string;
  },
  context?: HttpContext

): Observable<Sp340BSimpleResponse> {

    return this.sp340BClaimsRejectExceptionGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sp340BSimpleResponse>) => r.body as Sp340BSimpleResponse)
    );
  }

}
