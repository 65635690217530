import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { AppConstants } from '@shared/models/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class ToastrMessageService {
  settings: any;
  constructor(private ToastrService: ToastrService) {
    this.settings = {
      disableTimeOut: true,
      tapToDismiss: false,
      closeButton: true,
      enableHtml: true,
    };
  }
  error(error: Error & { error?: { message: string } }): void {
    let errorText = error.error?.message || error.message;

    if (errorText.includes('Error 0')) { // This console.log is related to diagnosing CC-2713
      console.error(error);
      errorText = 'Request timed out';
    }

    if (errorText) {
      const body = `
      <p>Please refresh the page and try again. If you continue seeing this error contact <a href="mailto:${AppConstants.SupportEmail}">${AppConstants.SupportEmail}</a> and provide the below information:</p>
      <p>${errorText}</p>
      <p>${moment().format()}</p>`;

      this.ToastrService.error(body, '', this.settings);
    }
  }
  success(message: {title: string, body: string}): void {
    this.ToastrService.success(message.body, message.title, this.settings);
  }
}
