import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttributionFilterModel } from './attribution-filter-model';
import { CompareService } from '@api/track/services';
import { CompareCin, ReportFilterType } from '@api/track/models';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '../kill-subscriptions';

@Component({
  selector: 'coach-attribution-filter-pane',
  templateUrl: './attribution-filter-pane.component.html',
  styleUrls: ['./attribution-filter-pane.component.scss']
})
export class AttributionFilterPaneComponent extends KillSubscriptions implements OnInit {

  @Input() title: string;

  @Input() isQueueOpen = true;
  @Output() isQueueOpenChange = new EventEmitter<boolean>();

  @Input() currentFilterModel = new AttributionFilterModel();
  @Output() currentFilterModelChange = new EventEmitter<AttributionFilterModel>();

  public isAssignmentOpen = true;
  public hasModelChanged = false;
  public previousFilterModel: AttributionFilterModel = new AttributionFilterModel();

  public hasCinAccess = false;
  public isCinOpen = true;
  public isCinLoading = false;
  public cinItems: CompareCin[] = [];

  constructor(private compareService: CompareService, private hierarchyTierService: HierarchyTierService) { 
    super();
  }

  ngOnInit(): void {
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
      this.hasCinAccess = tier.selectedItem.cinFlag === 1;

      if (this.hasCinAccess) {
        this.getCinList();
      } else {
        this.previousFilterModel.isCinSelected = this.currentFilterModel.isCinSelected = false;
        this.previousFilterModel.selectedCin = this.currentFilterModel.selectedCin = undefined;
      }
    });
  }

  public toggleQueueOpen(): void {
    this.isQueueOpen = !this.isQueueOpen;
    this.isQueueOpenChange.emit(this.isQueueOpen);
  }

  public mostAssignableSelected(): void {
    this.currentFilterModel.isMostAssignable = !this.currentFilterModel.isMostAssignable;
    this.hasModelChanged = this.hasFilterModelChanged();

    if (this.hasCinAccess) {
      this.getCinList();
    }
  }

  public getCinList(): void {
    this.isCinLoading = true;
    this.compareService.compareCinsGet({ attribution: this.currentFilterModel.isMostAssignable ? MOST_ASSIGNABLE : ATTRIBUTED }).subscribe({
      next: items => {
        this.cinItems = items;

        if (this.currentFilterModel.selectedCin != null) {
          this.currentFilterModel.selectedCin = this.cinItems.find(x => x.id === this.currentFilterModel.selectedCin.id);
        }

        if (this.currentFilterModel.isCinSelected && items != null && items.length > 0) {
          if (this.currentFilterModel.selectedCin == null) {
            this.currentFilterModel.selectedCin = items[0];
          }

          this.previousFilterModel.selectedCin = this.currentFilterModel.selectedCin;
        }
      }, complete: () => this.isCinLoading = false
    });
  }

  private hasFilterModelChanged(): boolean {
    return this.currentFilterModel.isMostAssignable !== this.previousFilterModel.isMostAssignable ||
      this.currentFilterModel.isCinSelected !== this.previousFilterModel.isCinSelected ||
      (this.currentFilterModel.isCinSelected && this.currentFilterModel.selectedCin?.id !== this.previousFilterModel.selectedCin?.id);
  }

  public viewCinSelected(): void {
    this.currentFilterModel.isCinSelected = !this.currentFilterModel.isCinSelected;

    if (!this.currentFilterModel.isCinSelected) {
      this.currentFilterModel.selectedCin = undefined;
    } else if (this.currentFilterModel.selectedCin == null && this.cinItems.length > 0) {
      this.currentFilterModel.selectedCin = this.cinItems[0];
    }
    this.hasModelChanged = this.hasFilterModelChanged();
  }

  public cinSelected(item: ReportFilterType): void {
    this.hasModelChanged = this.hasFilterModelChanged();
  }

  public applyChanges(): void {
    this.isQueueOpen = false;
    this.currentFilterModelChange.emit(this.currentFilterModel);

    this.previousFilterModel.isMostAssignable = this.currentFilterModel.isMostAssignable;
    this.previousFilterModel.selectedCin = this.currentFilterModel.selectedCin;
    this.previousFilterModel.isCinSelected = this.currentFilterModel.isCinSelected;

    this.hasModelChanged = false;
  }

}
