import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import * as _ from 'lodash';

import { Patient } from '@shared/models/patient';
import { PatientService, ModuleActivateDeactivateDialogViewModel, EpisodeOfCareProtocolAssignmentViewModel, EpisodeOfCareViewModel, EpisodeOfCareModuleAssignmentViewModel, TaskViewModel, CarePlanViewModel, ProtocolTaskViewModel } from '@shared/services/patient/patient.service';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { Task } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/wp-model';
import { OrgService } from '@shared/services/org-service/org.service';


/** We are caching protocols - they change very rarely */
const protocols: { [key: string]: wpapi.model.Protocol } = {};

@Component({
  selector: 'coach-care-patient-careplan',
  templateUrl: './patient-careplan.component.html',
  styleUrls: ['./patient-careplan.component.scss'],
})
export class CarePatientCareplanComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;

  get tierId() {
    return this?.tier?.selectedTierId;
  }

  vm: CarePlanViewModel;

  constructor(
    private PatientService: PatientService,
    private auditService: AuditService,
    private OrgService: OrgService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.patient && this.tier) {
      this.refresh();
      //(detail, compId, subComp, actionId, chPatId, tierId, orgId);
      this.auditService.auditLog(
        'Patient CarePlan',
        ComponentId.Dashboard,
        SubComponentId.CarePlan,
        ActionId.PHIAccess,
        this.patient.chPatId,
        this.tier,
        null
      );
    }
  }

  async refresh() {
    this.vm = {
      episodesOfCare: await this.PatientService.getPatientEpisodesOfCare(
        this.tierId,
        this.patient
      ),
      moduleActivateDeactivateInfo: null,
    };

    let tasks = await this.PatientService.getPatientTasks(
      this.tierId,
      this.patient
    );
    for (let eoc of this.vm.episodesOfCare) {
      for (let p of eoc.protocols) {
        p.isOpen = true;
        p.parentEpisodeOfCare = eoc;

        if (!protocols[p.protocolId]) {
          protocols[p.protocolId] = await this.OrgService.getProtocol(
            p.protocolId
          );
        }
        const protocol = protocols[p.protocolId];
        p.tasks = protocol.tasks
          .map((pt) => {
            const tt = tasks.find(
              (t) => t.protocolTaskId === pt.protocolTaskId
            );
            if (!tt) {
              return null;
            }
            let tvm = tt as TaskViewModel;
            tvm.swimlane = this.getSwimlaneForTask(tt);
            return tvm;
          })
          .filter((tvm) => !!tvm);
        p.activeTaskCount = p.tasks.reduce(
          (count, t) => (t.isActive ? count + 1 : count),
          0
        );

        if (p.modules) {
          for (let m of p.modules) {
            m.isOpen = true;
            m.parentProtocol = p;
            if (!protocols[m.protocolId]) {
              protocols[m.protocolId] = await this.OrgService.getProtocol(
                m.protocolId
              );
            }
            m.protocol = protocols[m.protocolId];
            m.templateTasks = this.templateTasksForModule(
              m.protocol,
              m.protocolId
            );
            m.assignedTasks = m.templateTasks
              .map((tt) => {
                let t = tasks.find(
                  (t) => t.protocolTaskId === tt.protocolTaskId
                );
                if (!t) {
                  return null;
                }

                let tvm = t as TaskViewModel;
                tvm.swimlane = this.getSwimlaneForTask(t);
                return tvm;
              })
              .filter((t) => !!t);
            m.activeTaskCount = m.assignedTasks.reduce(
              (count, t) => (t.isActive ? count + 1 : count),
              0
            );
          }
        }

        p.protocol = protocols[p.protocolId];
      }
    }
  }

  updateCalculatedValues() {
    for (let eoc of this.vm.episodesOfCare) {
      for (let p of eoc.protocols) {
        p.activeTaskCount = p.tasks.reduce(
          (count, t) => (t.isActive ? count + 1 : count),
          0
        );
        if (p.modules) {
          for (let m of p.modules) {
            m.activeTaskCount = m.assignedTasks.reduce(
              (count, t) => (t.isActive ? count + 1 : count),
              0
            );
          }
        }
      }
    }
  }

  // Event handlers
  async onTaskUpdated(t: ProtocolTaskViewModel) {
    // this.refresh()
    this.vm.episodesOfCare?.forEach((eoc) => {
      eoc.protocols?.[0]?.tasks?.forEach((task) => {
        if (task._id == t._id) {
          task = t;
          return;
        }
      });
    });
    this.updateCalculatedValues();
  }

  async moduleCheckClicked(module: EpisodeOfCareModuleAssignmentViewModel) {
    this.vm.moduleActivateDeactivateInfo = this.getModuleActivateDeactivateInfo(
      module,
      module.state !== 'active'
    );
  }

  async activateOrDeactivateModule(
    info: ModuleActivateDeactivateDialogViewModel
  ) {
    const activate: boolean = info.operation != 'Deactivate';
    await this.PatientService.activateOrDeactivateModule({
      userId: this.patient.carePatient._id,
      episodeOfCareId: info.module.parentProtocol.parentEpisodeOfCare._id,
      protocolId: info.module.parentProtocol.protocolId,
      moduleId: info.module.protocolId,
      activate: activate,
    });

    await this.refresh();
  }

  expandCollapseAll(expand: boolean, index: number) {
    this.vm?.episodesOfCare?.[index]?.protocols?.forEach((p) => {
      p.isOpen = expand;
      p.modules?.forEach((m) => {
        m.isOpen = expand;
      });
    });
  }

  // Helpers
  getSwimlaneForTask(t: Task): 'once' | 'daily' | 'weekly' | 'monthly' {
    // @ts-ignore
    let ruleText = t?.relativeSchedule?.recurRule?.toLowerCase();
    if (!ruleText) {
      return 'once';
    }
    if (ruleText.indexOf('freq=daily') >= 0) {
      return 'daily';
    } else if (ruleText.indexOf('freq=weekly') >= 0) {
      return 'weekly';
    } else if (ruleText.indexOf('freq=monthly') >= 0) {
      return 'monthly';
    } else {
      // Not a valid value, really.
      return 'once';
    }
  }

  tasksForSwimlane(
    tasks: Array<TaskViewModel>,
    swimlane: 'once' | 'daily' | 'weekly' | 'monthly'
  ) {
    if (!tasks) {
      return [];
    }
    return tasks.filter((t) => swimlane == t.swimlane);
  }

  /** Calculate the effect of assigning/activating/deactivating a module */
  public getModuleActivateDeactivateInfo(
    mod: EpisodeOfCareModuleAssignmentViewModel,
    activate: boolean
  ): ModuleActivateDeactivateDialogViewModel {
    let result: ModuleActivateDeactivateDialogViewModel = {
      module: mod,
      operation: null,
      tasksToAssign: [],
      tasksToActivate: [],
      tasksToBeDeactivated: [],
      tasksNotToBeDeactivated: [],
      tasksToLeaveAlone: [],
    };

    if (activate) {
      const moduleIsAssigned = mod.stateHistory.find(
        (sh) => sh.state === 'active'
      );
      if (moduleIsAssigned) {
        result.operation = 'Activate';
        _.each(mod.assignedTasks, (t) => {
          if (!t.isActive) {
            result.tasksToActivate.push(t);
          } else {
            result.tasksToLeaveAlone.push(t);
          }
        });
      } else {
        result.operation = 'Assign';
        _.each(mod.templateTasks, (tt) => {
          let t = this.findAssignedTaskByProtocolTaskId(tt.protocolTaskId);
          if (t) {
            // Is assigned
            if (t.isActive) {
              result.tasksToLeaveAlone.push(t);
            } else {
              result.tasksToActivate.push(t);
            }
          } else {
            result.tasksToAssign.push(tt);
          }
        });
      }
    } else {
      result.operation = 'Deactivate';
      // Tasks to be deactivated will be unless they are in another module and already active.
      _.each(mod.assignedTasks, (t) => {
        if (this.isTaskActiveInAnotherModule(t._id, mod)) {
          result.tasksNotToBeDeactivated.push(t);
        } else {
          result.tasksToBeDeactivated.push(t);
        }
      });
    }
    return result;
  }

  public findAssignedTaskByProtocolTaskId(
    protocolTaskId: string
  ): ProtocolTaskViewModel {
    // REVIEW - could probably be shortened by using Array.find()
    for (let i = 0; i < this.vm.episodesOfCare.length; i++) {
      let e = this.vm.episodesOfCare[i];
      for (let j = 0; j < e.protocols.length; j++) {
        let p = e.protocols[j];
        for (let k = 0; k < p.tasks.length; k++) {
          let t = p.tasks[k];
          if (t.protocolTaskId === protocolTaskId) {
            return t;
          }
        }
        if (p.modules) {
          for (let l = 0; l < p.modules.length; l++) {
            const assignedTasks = p.modules[l]?.assignedTasks;
            if (assignedTasks) {
              for (let m = 0; m < assignedTasks.length; m++) {
                let at = assignedTasks[m];
                if (protocolTaskId === at.protocolTaskId) {
                  return at;
                }
              }
            }
          }
        }
      }
    }
    return null;
  }
  public isTaskActiveInAnotherModule(
    taskId: string,
    mod: EpisodeOfCareModuleAssignmentViewModel
  ) {
    return this.vm.episodesOfCare?.find((e) => {
      return e.protocols?.find((p) => {
        return p.modules?.find((mm) => {
          if (mm === mod) {
            return false;
          }
          if (mm.state !== 'active') {
            return false;
          }
          // REVIEW - not sure why assignedTasks is sometimes undefined
          if (!mm.assignedTasks) {
            return false;
          }
          return mm.assignedTasks.find((at) => {
            return taskId === at._id;
          });
        });
      });
    });
  }

  /** Get tasks from the module, which is a protocol */
  templateTasksForModule(
    mod: wpapi.model.Protocol,
    protocolId: string
  ): Array<ProtocolTaskViewModel> {
    if (mod) {
      return (
        mod.tasks
          // NUD-3736 - filter out certain tasks - TODO - should have a more general way of dealing with this.
          .filter(
            (t) =>
              t.type !== 'patientsatisfaction' || t.subType !== 'wellpepper'
          )
          .map((t) => {
            const copy: ProtocolTaskViewModel =
              _.cloneDeep(t);
            copy.swimlane = this.getSwimlaneForTask(t as Task);
            return copy;
          })
      ); // Make sure we don't change template tasks.
    }
    return [];
  }

  getTaskTypeText(task: ProtocolTaskViewModel) {
    if (!task.type) {
      return 'Exercise';
    }
    var s = task.type.toLowerCase();
    s = s.toLowerCase();
    switch (s) {
      case 'activitytracker':
        s = 'activity tracker';
        break;
      case 'aboutinfo':
        s = 'about info';
        break;
      case 'painmedication':
        s = 'pain medication';
        break;
      case 'startdoing':
        s = 'start doing';
        break;
      case 'stopdoing':
        s = 'stop doing';
        break;
      case 'patientsatisfaction':
        s = 'patient satisfaction';
        if (task.subType === 'wellpepper') {
          s += ' (Wellpepper)';
        }
        break;
      case 'didyoufall':
        s = 'did you fall';
        break;
      case 'quicktips':
        s = 'quick tips';
        break;
      case 'videodiary':
        s = 'video diary';
        break;
      case 'food diary':
      case 'fooddiary':
        s = 'food diary';
        break;
      case 'meddiary':
        s = 'med diary';
        break;
      case 'goaltracking':
        s = 'goal';
        break;
      case 'bloodpressure':
        s = 'blood pressure';
        break;
      case 'bloodsugar':
        s = 'blood sugar';
        break;
      case 'tasksetup':
        switch ((task.subType || '').toString().toLowerCase()) {
          case 'activity':
            s = 'activity';
            break;
          case 'weight':
            s = 'weight';
            break;
          case 'bloodpressure':
            s = 'blood pressure';
            break;
          default:
            s = 'tracking';
            break;
        }
        break;
      case 'educationvideo':
      case 'educationwebvideo':
        s = 'education video';
        break;
      case 'weighttracker':
        s = 'weight tracker';
        break;
    }
    s = s.replace(/_/g, ' ');
    return s.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}