import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Patient } from '@shared/models/patient';
import { forkJoin } from 'rxjs';
import { PatientService } from '@shared/services/patient/patient.service';
import { HccCodingOpportunity } from '@shared/models/hcc-care-gaps';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { ErrorHandlerService } from '@shared/services/logging/error-handler.service'

@Component({
  selector: 'coach-patient-acute-coding-opportunities',
  templateUrl: './patient-acute-coding-opportunities.component.html',
  styleUrls: ['./patient-acute-coding-opportunities.component.scss']
})
export class PatientAcuteCodingOpportunitiesComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  diseaseInteractions: HccCodingOpportunity[] = [];
  acuteOpportunities: HccCodingOpportunity[] = [];
  constructor(private _patientService: PatientService, private toastrMessageService: ToastrMessageService, private errorHandlerService: ErrorHandlerService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadHealthData()
  }
  private loadHealthData() {
    if (this.patient) {
      if (this.patient?.trackPatient?.chPatId) {
        forkJoin({
          reqAcuteOpportunities: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'opportunities',
            false
          ),
        }).subscribe(
          ({
            reqAcuteOpportunities
          }) => {
            this.acuteOpportunities = reqAcuteOpportunities.filter(co => !co.isInteraction) || [];
          },
          (errors) => {
            this.toastrMessageService.error(errors)
            this.errorHandlerService.handleError(errors)
          },
        );
      }
    }
  }
}
