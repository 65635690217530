import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Patient } from '@shared/models/patient';
import { common as wp, wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { PatientService } from '@shared/services/patient/patient.service';


type SMSScheduleSettings = {
  startDate: string;
  endDate?: string;
  frequency: string;
  duration: wpapi.model.Duration;
  currentStep: number;
  totalSteps: number;
  phases: Array<wpapi.model.TwilioScheduleReportInterval>;
};

type CustomScheduleSettings = {
  startDate: string;
  frequency: string;
  stratedBy: string;
}

type frequency = {
  Monthly: string;
  Weekdays: string;
  MWF: string;
  Weekly: string;
  Biweekly: string;
}

@Component({
  selector: 'coach-custom-schedule',
  templateUrl: './custom-schedule.component.html',
  styleUrls: ['./custom-schedule.component.scss']
})

export class CustomScheduleComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public phases: Array<wpapi.model.TwilioScheduleReportInterval>;
  @Input() public adaptivephases: Array<wpapi.model.TwilioScheduleReportInterval>;
  @Input() public customSchedulesettings: CustomScheduleSettings;
  @Input() public stoppedbyUser: string;
  @Input() public isScheduleStopped: boolean;
  @Input() public program: string;
  @Output() cancelClicked : EventEmitter<void> = new EventEmitter<void>();

  currentUser: wpapi.model.Professional;
  selectedFrequency: string;
  customInviteStatus: boolean = false;
  scheduleSettings: SMSScheduleSettings;
  customPhases: Array<string>;
  showCustomScheduleSelection: boolean = false;
  isStopRequested: boolean = false;
  today = new Date();
  frequency: frequency = {
    Monthly: 'monthly',
    Weekdays: 'weekday',
    MWF: 'mwf',
    Weekly: 'weekly',
    Biweekly: 'biweekly'
  }
  daysOfWeek : string []; 
  greyDaysOfWeek : any; 
  onTextContent : string; 

  constructor(
    private AuthService: AuthService,
    private PatientService: PatientService,
  ) {

  }

  ngOnChanges() {
    this.getcustomPhases();
  }

  private async getCurrentUserInfo() {
    this.currentUser = await this.AuthService.getWellpepperUserInfo();
  }

  private get patientId() {
    return this?.patient?.carePatient?._id;
  }

  get twilioTask() {
    return this.patient?.tasks?.find(task => task.type == "twilioStudioFlow" && task.isActive);
  }

  formatDate(date: wpapi.model.LocalDate): string {
    return new Date(date.year, date.month - 1, date.date).toLocaleDateString('en-US')
  }


  onClick() {
  }

  ngOnInit(): void {
    this.getCurrentUserInfo();
    this.getcustomPhases();
  }

  get isScheduleCustom() {
    return this.twilioTask?.schedule?.isCustom === true;
  }

  get isScheduleActive() {
    return this.twilioTask?.schedule;
  }


  getcustomPhases() {
    let frequencies = this.twilioTask?.twilioInfo?.scheduleDefinition?.frequencyKeyMap;
    if (frequencies) {
      this.customPhases = Object.values<any>(frequencies).map(item => item.label);
      this.selectedFrequency = this.customPhases[0];
      this.onFrequencyChange();
    }
  }

  customScheduleSelection(show: boolean) {
    this.selectedFrequency = this.customPhases[0];
    this.showCustomScheduleSelection = show;
  }

  stopRequest(request: boolean) {
    this.isStopRequested = request;
  }

  onFrequencyChange() {
    const greyDays = {
      '5 times/week': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      '3 times/week': ['Monday', 'Wednesday', 'Friday'],
      '1 time/week': ['Monday'],
      'Every other week': ['Monday'],
      'Monthly': ['Monday'],
    };
    this.daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const selectedFrequencyLabel = this.selectedFrequency;
    const daysToGrey = greyDays[selectedFrequencyLabel];
    this.greyDaysOfWeek = {};
    this.daysOfWeek.forEach(day => {
      this.greyDaysOfWeek[day] =  daysToGrey.includes(day);
    });
    this.onTextContent = (this.selectedFrequency === 'Monthly')? 'On First' : 'On';
  }


  async stopSchedule(userId: string) {
    let stopTwilioFlowSchedule = await this.PatientService.stopTwilioFlowSchedule(userId, this.twilioTask._id);
    return stopTwilioFlowSchedule;
  }


  async onSave() {
    let userId = this.patient.carePatient._id;
    if (this.isScheduleCustom && this.isStopRequested) {
      let stopTwilioFlowSchedule = await this.stopSchedule(userId);
      if (stopTwilioFlowSchedule) {
        window.location.reload();
      }
    }
    else if (!this.isScheduleCustom || this.showCustomScheduleSelection) {
      if (this.twilioTask?.twilioInfo?.scheduleDefinition) {
        let frequency: string = '';
        switch (this.selectedFrequency) {
          case '5 times/week':
            frequency = this.frequency.Weekdays;
            break;
          case '3 times/week':
            frequency = this.frequency.MWF;
            break;
          case '1 time/week':
            frequency = this.frequency.Weekly;
            break;
          case 'Every other week':
            frequency = this.frequency.Biweekly;
            break;
          case 'Monthly':
            frequency = this.frequency.Monthly;
            break;
        }
        let isAdaptive = false;
        let stopTwilioFlowSchedule
        if (!this.isScheduleStopped) {
          stopTwilioFlowSchedule = await this.stopSchedule(userId);
          if (stopTwilioFlowSchedule) {
            let twilioFlowSchedule = await this.PatientService.createTwilioFlowSchedule(userId, this.twilioTask._id, frequency, isAdaptive);
            if (twilioFlowSchedule) {
              this.showCustomScheduleSelection = true;
              window.location.reload();
            }
          }
        } else {
          let twilioFlowSchedule = await this.PatientService.createTwilioFlowSchedule(userId, this.twilioTask._id, frequency, isAdaptive);
            if (twilioFlowSchedule) {
              this.showCustomScheduleSelection = true;
              window.location.reload();
            }
        }
      }
    }

  }


  cancel() {
    this.cancelClicked.emit();
  }
}
