import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { environment } from 'src/environments/environment';
import { HccDashboardTierMetric } from './hcc-dashboard-tier-metric';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HccDashboardTierMetricsService extends AzureApiBase<HccDashboardTierMetric> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/hccDashboard/tierMetrics');
  }

  lastUpdateDate: Date;

  getMetrics(tierNum: number, tierId: string): Observable<HccDashboardTierMetric[]> {
    const params: HttpParams = new HttpParams()
      .set('tierNum', tierNum.toString())
      .set('tierId', tierId);

    let observable: Observable<HccDashboardTierMetric[]>;
    observable = this.getAll(null, params).pipe(share());
    observable.subscribe(data => {
      if (data != null && data.length > 0) {
        this.lastUpdateDate = data[0].lastUpdateDate;
      }
    });

    return observable;
  }
}
