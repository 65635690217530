/* tslint:disable */
/* eslint-disable */

/**
 * The outcome from an activity
 */
export enum OutcomeType {
  ScheduledOutcome = 'ScheduledOutcome',
  CompletedOutcome = 'CompletedOutcome',
  CallBackOutcome = 'CallBackOutcome',
  RemovedOutcome = 'RemovedOutcome',
  DeferredToSignifyOutcome = 'DeferredToSignifyOutcome',
  PatientWasRemindedOutcome = 'PatientWasRemindedOutcome',
  PatientDidNotAnswerOutcome = 'PatientDidNotAnswerOutcome',
  PatientAttendedOutcome = 'PatientAttendedOutcome',
  PatientDidNotAttendOutcome = 'PatientDidNotAttendOutcome'
}
