<div class="u-height--fit-content form-background">
  <div class="u-font-size--small">
    <div class="row padding-bottom--small u-flex-direction--column">
      <input [(ngModel)]="selectedOutcome" type="radio" id="patAttendedAwv" name="patient_attended_awv" [value]="AttendOutcome.PATIENT_ATTENDED_AWV" (input)="fieldChanged(true)" [disabled]="isReadOnly">
      <label for="patAttendedAwv">Patient Attended AWV</label>
      <div class="row padding-left">
        <input id="nextawvdatechkbx" type="checkbox" name="nextawvdatechkbx" [checked]="nextAwvDateChecked" (change)="toggleNextAwvDateInput()" #nextawvdatechkbx  [disabled]="isReadOnly">
        <label id='testNextAwvAppointmentChkbx' for="nextawvdatechkbx">Add Next Scheduled AWV Appointment:</label>
      </div>

      <div class="row span--8 padding-left" >
        <input class="margin-left--large" [ngClass]="{'invalid-date': '' !== nextAwvDate && !isNextAwvDateChangedAndHasValidValue()}"
               #nextAwvDateInput
               [disabled]="!nextAwvDateChecked"
               [required]="nextAwvDateChecked"
               placeholder="Next AWV Date"
               id="nextAwvDateInput"
               (input)="fieldChanged(false);"
               [(ngModel)]="nextAwvDate"
               type="date"
               [min]="minNextAwvDate"
               [max]="maxNextAwvDate">
      </div>
      <div class="row padding-bottom--small">
        <coach-invalid-input [input]='nextAwvDateInput'></coach-invalid-input>
      </div>
      <input [(ngModel)]="selectedOutcome" type="radio" id="patDidNotAttended" name="patient_did_not_attend" [value]="AttendOutcome.PATIENT_DID_NOT_ATTEND_AWV" (input)="fieldChanged(true)" [disabled]="isReadOnly||attendedAwv">
      <label for="patDidNotAttended">Patient Did Not Attend AWV</label>
      <div>
        <div class="row padding-bottom--smallest row padding-top--large">
          <textarea class="resize--none" [(ngModel)]="comment" placeholder="Add a comment" id="completedComment" rows="6"
                    [maxlength]="commentCharacterlimit" (input)="fieldChanged(false)" #commentbox [disabled]="isReadOnly"></textarea>
        </div>
        <div class="row padding-bottom--small">
          <span class="span--6 u-font-weight--light" [ngClass]="{'character-limit-reached': commentbox.value.length == commentCharacterlimit }">{{commentbox.value.length}}/{{commentCharacterlimit}}</span>
          <div class="span--6 u-text-align--right u-cursor--pointer u-font-weight--light padding-right--small" id='testAwvCompletedCopyText' (click)="copyComment(commentbox)">Copy text</div>
        </div>
      </div>
      <coach-patient-awv-inline-error></coach-patient-awv-inline-error>
      <div class="row padding-top--small u-align-items--center margin-bottom--small">
        <coach-icon *ngIf="outcome" class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" iconName="trash" iconColor="primary" id='testAwvCompletedDeleteBtn' (click)="showDialog()"></coach-icon>
        <coach-icon *ngIf="!isReadOnly" class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" iconName="copy" iconColor="primary" id='testAwvCompletedCopyBtn' [copy]="stringifyForm()"></coach-icon>
        <coach-patient-awv-remove-modal-dialog [showDialog]="showRemoveDialog" (buttonClicked)="onDialogButtonClick($event)"></coach-patient-awv-remove-modal-dialog>
        <button class="span--3 button is-unstyled u-flex--right is-small margin-right--small cancel-btn u-color--brand--1" id="cancelBtn" (click)="clearFields()" [disabled]="allFieldsEmpty">Cancel</button>
        <button class="span--3 button is-small is-primary" id="saveBtn" (click)="save()" [disabled]="!requiredFieldsSatisfied">Save</button>
      </div>
    </div>
  </div>
</div>
