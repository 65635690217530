import { SimpleChanges } from '@angular/core';
import {
  EventEmitter,
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { IPrescriptionDetail } from '@shared/models/prescription';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';
import { Subscription } from 'rxjs';
import { ClaimStatus, compareDates, formatDate, basicNameSplit, removeLeadingZeros } from '../../shared-lib';

@Component({
  selector: 'coach-rejected-claim',
  templateUrl: './rejected-claim.component.html',
  styleUrls: ['./rejected-claim.component.scss'],
})
export class RejectedClaimComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedPatientInfo: any;
  @Input() prescriptionInfo: any;
  @Input() showModal: boolean = false;
  @ViewChild('noteContent') noteContent: ElementRef;
  @Output() modalToggled = new EventEmitter<boolean>();
  @Output() claimRejected = new EventEmitter<boolean>();

  authSubscription: Subscription;

  patientName: string;
  patientDobText: string;
  patientMbi: string;

  provider: string = '';
  providerVisitDate: Date;
  rxDiagnosis: string = '';
  relatedDxPresent: number;
  referralDxPresent: number;
  followUpPlanWithCE: string = '';
  followUpPlanWithProvider: string = '';
  formattedFollowUpPlan: string = '';

  careCoordNote: any;

  referralDocSelection: any;

  currentUser: any;
  creatorName: string;
  creatorDate: Date;

  showSubFillDialog: boolean = false;

  constructor(
    private prescriptionService: PrescriptionService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser;
        this.creatorName = this.currentUser.name || this.currentUser.email;
        this.creatorDate = new Date();
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue) {
      this.formattedFollowUpPlan = this.prescriptionInfo.follow_Up_Plan.replace(/\n/g, '<br />')
    }

    if (changes.prescriptionInfo) {
      const pi = changes.prescriptionInfo.currentValue;
      if (
        !pi.cE_Related_Referral_Date ||
        pi.cE_Related_Referral_Date === 'mm/dd/yyyy'
      ) {
        this.prescriptionInfo.cE_Related_Referral_Date = new Date('1970-01-01');
      }
      if (!pi.cE_Visit_Date || pi.cE_Visit_Date === 'mm/dd/yyyy') {
        this.prescriptionInfo.cE_Visit_Date = new Date('1970-01-01');
      }
      if (
        !pi.visit_Note_Visit_Date ||
        pi.visit_Note_Visit_Date === 'mm/dd/yyyy'
      ) {
        this.prescriptionInfo.visit_Note_Visit_Date = new Date('1970-01-01');
      }
    }
    if (changes.selectedPatientInfo) {
      this.patientName = basicNameSplit(this.selectedPatientInfo.patientName).join(' ');
      this.patientDobText = this.formatDate(this.selectedPatientInfo.patientDob);
      this.patientMbi = this.selectedPatientInfo.mbi ? this.selectedPatientInfo.mbi : 'N/A';

      setTimeout(() => {
        if (this.showModal) {
          this.toggleVisitNoteModal();
        }
      }, 0);
    }
  }

  public formatDate(dateStr) {
    if (dateStr == new Date(null).toString()) {
      return "";
    }
    if (dateStr) {
      return new Date(dateStr).toISOString().split('T')[0];
    }
    return "";
  }

  getTodaysDate(): Date {
    return new Date();
  }

  getLoggedInUserName(): string {
    return this.creatorName;
  }

  toggleSubFillDialog() {
    this.showSubFillDialog = !this.showSubFillDialog;
  }

  onSubFillButtonClicked(buttonValue: string) {
    switch (buttonValue.toLowerCase()) {
      case 'cancel':
        break;
      case 'yes':
        this.reject(false);
        break;
      case 'no':
        this.reject(true);
        break;
      default:
        throw new Error("onSubFillButtonClicked: invalid value");
    }
    this.toggleSubFillDialog();
  }

  reject(applySubFillLogic: boolean = true) {
    this.prescriptionInfo.claim_Status = ClaimStatus.REJECTED;

    this.prescriptionInfo.acoId = this.selectedPatientInfo.acoId;
    this.prescriptionInfo.claimPk = this.selectedPatientInfo.claimPk;
    this.prescriptionInfo.tier2_id = this.selectedPatientInfo.tier2_id;
    this.prescriptionInfo.tier3_id = this.selectedPatientInfo.tier3_id;
    this.prescriptionInfo.tier4_grpId = this.selectedPatientInfo.tier4_grpId;

    this.prescriptionInfo.selectedPatientInfo = this.selectedPatientInfo;
    this.prescriptionInfo.userId = this.selectedPatientInfo.userId;

    this.prescriptionInfo.claim_Status_Set_By = this.creatorName;
    this.prescriptionInfo.claim_Status_Set_On_Date = this.creatorDate.toISOString();

    this.prescriptionInfo.cE_Related_Referral_Date = this.prescriptionInfo.cE_Related_Referral_Date ?
      this.prescriptionInfo.cE_Related_Referral_Date : null;
    this.prescriptionInfo.visit_Note_Visit_Date = this.prescriptionInfo.visit_Note_Visit_Date ?
      this.prescriptionInfo.visit_Note_Visit_Date : null;
    this.prescriptionInfo.cE_Visit_Date = this.prescriptionInfo.cE_Visit_Date ?
      this.prescriptionInfo.cE_Visit_Date : null;

    this.prescriptionInfo.claimType = this.prescriptionInfo.selectedPatientInfo.claimType;

    this.prescriptionService.setPrescriptionDetail(this.prescriptionInfo, true, applySubFillLogic);
    
    this.claimRejected.emit(applySubFillLogic);
    this.toggleVisitNoteModal();
  }

  toggleVisitNoteModal() {
    this.modalToggled.emit(this.showModal);
  }

  copyNote() {
    this.copyMessage(this.noteContent.nativeElement.innerText);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.addFieldData(val);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  addFieldData(text: string): string {
    const textTerminationIndex = text.indexOf('First filled');
    let header = text.substring(0, textTerminationIndex);

    let visitDate = new Date(this.prescriptionInfo.visit_Note_Visit_Date);
    let rxDiagnosis = this.prescriptionInfo.visit_Note_Diagnosis;

    let displayDate = visitDate.toISOString();
    displayDate = displayDate.substring(0, displayDate.indexOf('T'));

    let result =
      header +
      `First filled: ${this.formatDate(this.selectedPatientInfo.firstFilled)}` +
      '\n';
    if (!this.prescriptionInfo.visit_Note_Missing) {
      result += `
Visit note from ${this.selectedPatientInfo.prescriberName}:
Visit Date: ${this.formatDate(this.prescriptionInfo.visit_Note_Visit_Date)}
Rx Diagnosis: ${rxDiagnosis}
      `;
    }

    if (!this.prescriptionInfo.cE_Visit_Note_Missing) {
      result += `
Related Visit by Covered Entity:
Visit Date: ${this.formatDate(this.prescriptionInfo.cE_Visit_Date)}
Provider: ${this.prescriptionInfo.cE_Provider}
CE Location: ${this.prescriptionInfo.cE_Location}
      `;
    }

    if (!this.prescriptionInfo.cE_Related_Referral_Missing) {
      result += `
Related Referral by Covered Entity:
Referral Date: ${this.formatDate(
        this.prescriptionInfo.cE_Related_Referral_Date
      )}
      `;
    }

    if (this.prescriptionInfo.follow_Up_Plan) {
      result += `
Follow-up plan:
${this.prescriptionInfo.follow_Up_Plan}
      `;
    }

    return result.trim();
  }
}
