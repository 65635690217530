import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Toggle button.
 * 
 * @example
 * <coach-toggle
 *                (onToggle)="paneToggled()"
 *                [toggle]="isOpen"
 *                [toggledOpenDefault]="toggledOpenDefault"
 *                [iconRotates]="iconRotates"
 *                [iconName]="iconName">
 * </coach-toggle>
 */
@Component({
  selector: 'coach-toggle',
  templateUrl: './coach-toggle.component.html',
  host: {
    class: 'coach-toggle'
  }
})
export class CoachToggleComponent implements OnInit {

  /** Indicates whether or not component starts toggled or not */
  @Input() public toggledOpenDefault: boolean;
  /** Used to manually toggle the component */
  @Input() public set toggle(value: boolean) {
    this.isOpen = value;
  }
  /** Icon name to use for button. Corresponds to icons found in Magicons.svg */
  @Input() public iconName: string = 'chevrons-right';
  /** Indicates whether or not the icon should flip on toggle */
  @Input() public iconRotates: boolean = true;
  /** Method to call when toggle event occurs */
  @Output() public onToggle: EventEmitter<boolean> = new EventEmitter();

  public isOpen: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isOpen = this.toggledOpenDefault;
  }

  public doToggle() {
    this.isOpen = !this.isOpen;
    this.emitIsOpen();
  }

  public emitIsOpen() {
    this.onToggle.emit(this.isOpen);
  }

}
