import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { Patient } from '@shared/models/patient';
import { AwvWorkflow } from '@api/care-orch/models/awv-workflow';
import { PatientAwvUtilities } from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/patient-awv-utilities.component';
import { SchedulingActivity } from '@api/care-orch/models/scheduling-activity';
import { RemovedOutcome } from '@api/care-orch/models/removed-outcome';
import {ActivityType, Outcome, OutcomeType} from '@api/care-orch/models';
import {OnChanges} from '@angular/core';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {AwvService} from '@api/care-orch/services/awv.service';
import {Observable} from 'rxjs';
import { AwvWorkflowService } from '@shared/services/awv-workflow/awv-workflow.service';
import { Program } from '@shared/services/mixpanel/events/workflow-events';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';

@Component({
  selector: 'coach-patient-awv-removed',
  templateUrl: './patient-awv-removed.component.html',
  styleUrls: ['./patient-awv-removed.component.scss']
})
export class PatientAwvRemovedComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public awvWorkflow: AwvWorkflow;
  @Input() public mobileScreenSize: boolean;
  @Input() sidebarMode?: boolean;
  @Output() awvWorkflowUpdated: EventEmitter<Observable<AwvWorkflow>> = new EventEmitter();
  @Output() public activityDeleted: EventEmitter<ActivityType> = new EventEmitter();

  isTabOpen = false;
  removedOutcome = { } as RemovedOutcome;

  constructor(private awvService: AwvService,
              private awvWorkflowService: AwvWorkflowService,
              private workflowEvents: WorkflowEventsService) {}

  ngOnInit(): void {
    this.awvWorkflowService.updateSuccessful$.subscribe(() => this.isTabOpen = false);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.awvWorkflow){
      const schedulingActivities = this.awvWorkflow.completedActivities
        .filter( x  => x.type === 'SchedulingActivity').map(x => x as SchedulingActivity);
      this.removedOutcome = schedulingActivities.find( x => x.outcome.type === OutcomeType.RemovedOutcome).outcome as RemovedOutcome;
    }
  }
  public removedReasonToString( enumValue): string {
    return PatientAwvUtilities.removedReasonToString(enumValue);
  }

  outcomeSaved(outcome: Outcome): void {
    const activityToSave = { type: ActivityType.SchedulingActivity, outcome } as SchedulingActivity;
    if (this.removedOutcome) {
      this.workflowEvents.trackEdit(activityToSave);
    } else {
      this.workflowEvents.trackSave(activityToSave);
    }

    const obsUpdatedWorkflow = this.awvService.updateWorkflowActivity({
      programId: CareOrchestrationConstants.AwvProgramId,
      chPatId: this.patient.trackAwvPatient?.chPatID || Number(this.patient.trackPatient.chPatId),
      body: activityToSave
    });

    this.awvWorkflowUpdated.emit(obsUpdatedWorkflow);
  }

  deleteSchedulingActivity(): void {
    this.activityDeleted.emit(ActivityType.SchedulingActivity);
  }
}
