import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { environment } from 'src/environments/environment';
import { CareFeatureConstants,PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class CareManagementHypertensionPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.ChronicConditions: PresetCategory.Cohorts;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.CareManagementHypertension,
      label: 'Care Management',
      urlLabel: 'Care Management Hypertension',
      filters: [],
      componentId: CareComponentConstants.CareManagement,
      hidePreset: true,
      hidePresetAdditionCondition: true,
      filterComponent: null,
      filterSettings: null,
      columns: this.getPresetColumns(),
      hiddenColumnsByDefault: [],
      listDefinition: 'Target your patients for care management and clinical programs',
      defaultFilterModel: {       
        ssPercent: { 
          filterType: 'number',
          type: 'lessThanOrEqual',
          filter: 15
        }
      },
      presetKey: 'Care Management'
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}_2`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    return [
      { select: true },
      { mbi: true },
      { fullName: true },
      { dob: true },
      { gender: true },
      { attributedCP: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      { ccmEligible: this.agGridCustomFilteringFlag },
      { totalSpend: true },
      { hccGapCount: true },
      { diseaseCohorts: this.agGridCustomFilteringFlag },
      { raF_YTD: true },
      { totalGapWt: true },
      { lastVisit: true },
      { attributedPractice: true },
      { awvTypeDue: true },
      { lastAWVDate: true },
      { edVisits: true },
      { pyRaf: true },
      { ssDenom: true },
      { ssPercent: true }
    ];
  }
}
