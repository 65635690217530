import { Component, OnInit } from '@angular/core';
import { FINANCIAL_REPORTS_FEATURE } from '@track/track-constants'

@Component({
  selector: 'coach-track-financial-utilization',
  templateUrl: './track-financial-utilization.component.html'
})
export class TrackFinancialUtilizationComponent implements OnInit {

  constructor() { }

  reportsFeature: number = FINANCIAL_REPORTS_FEATURE;

  ngOnInit(): void {
  }
}
