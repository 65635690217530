/* tslint:disable */
/* eslint-disable */
export enum RemovedReasons {
  PatientHasDeclined = 'PATIENT_HAS_DECLINED',
  PatientIsUnreachable = 'PATIENT_IS_UNREACHABLE',
  PatientIsUnderHospiceCare = 'PATIENT_IS_UNDER_HOSPICE_CARE',
  PatientIsInASkilledNursingFacility = 'PATIENT_IS_IN_A_SKILLED_NURSING_FACILITY',
  PatientIsInAnInpatientFacility = 'PATIENT_IS_IN_AN_INPATIENT_FACILITY',
  PatientIsDeceased = 'PATIENT_IS_DECEASED',
  PatientMovedOutOfTheServiceArea = 'PATIENT_MOVED_OUT_OF_THE_SERVICE_AREA',
  InsurancePlanDoesNotCoverAwv = 'INSURANCE_PLAN_DOES_NOT_COVER_AWV',
  AwvWasCompletedByExternalProvider = 'AWV_WAS_COMPLETED_BY_EXTERNAL_PROVIDER',
  Other = 'OTHER'
}
