import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  ComponentRef,
  Injector,
  OnInit,
} from '@angular/core';
import { IView } from '@shared/models/views';
import { ActiveViewService } from '@shared/services/active-view/active-view.service';
import { HeaderCondensedService } from '@shared/services/header-condensed/header-condensed.service';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRouteService } from '@shared/services/activated-route/activated-route.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IBreadcrumb } from '@shared/models/breadcrumbs';
import {
  CdkPortalOutletAttachedRef,
  ComponentPortal,
  PortalInjector,
} from '@angular/cdk/portal';
import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  HeaderScopeOverlayComponent,
  NAVIGATION_PORTAL_DATA,
} from './header-scope-overlay/header-scope-overlay.component';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { HeaderHierarchyOverlayComponent } from './header-hierarchy-overlay/header-hierarchy-overlay.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { HeaderProcessesComponent } from './header-processes/header-processes.component';
import { ProcessingService } from '@shared/services/processing/processing.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { takeUntil } from 'rxjs/operators';
import { Process } from '@shared/services/processing/process';

@Component({
  selector: 'coach-header',
  templateUrl: './header.component.html',
  host: {
    class: 'coach-header',
  },
  providers: [Overlay],
})
export class HeaderComponent extends KillSubscriptions implements OnInit {
  constructor(
    private _activeView: ActiveViewService,
    private _isHeaderCondensed: HeaderCondensedService,
    private _activatedRoute: ActivatedRouteService,
    private _router: Router,
    private _injector: Injector,
    public overlay: Overlay,
    private vcRef: ViewContainerRef,
    private hierarchyTierService: HierarchyTierService,
    private processingService: ProcessingService,
  ) {
    super();

    this.activeView$ = this._activeView.activeView$;
    this.isHeaderConsdensed$ = this._isHeaderCondensed.isHeaderCondensed$;
    this.activatedRoute$ = this._activatedRoute.activatedRoute$;

    this.hierarchyTierService.currentTier$?.subscribe((x) => {
      this.currentTier = x;
    });
  }
  public searchActivated: boolean = false;
  public activeView$: BehaviorSubject<IView>;
  public isHeaderConsdensed$: BehaviorSubject<boolean>;
  public activatedRoute$: BehaviorSubject<ActivatedRoute>;
  public activeViewCrumb: IBreadcrumb;
  public highestTier: number;
  public currentTier: IHierarchyTier;
  private overlayRef: OverlayRef;
  public processes: Process<unknown>[] = [];

  @ViewChild('scopeSelectOrigin') scopeSelectOrigin: ElementRef;
  @ViewChild('searchOrigin') searchOrigin: ElementRef;
  @ViewChild('processesOrigin') processesOrigin: ElementRef;

  scopeSelectDropdownPortal: ComponentPortal<HeaderScopeOverlayComponent>;
  searchDropdownPortal: ComponentPortal<HeaderSearchComponent>;

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(NAVIGATION_PORTAL_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  ngOnInit(): void {
    this.processes = this.processingService.processes;
    this.processingService.processes$.pipe(takeUntil(this.killTrigger)).subscribe(process => {
      if (process != null) {
        this.openProcessesDropdown();
      } else if (this.processingService.processes.length === 0 && this.overlayRef != null) {
        this.overlayRef.detach();
      }
    });
  }

  public get completedProcessCount(): number {
    return this.processes.filter(x => x.status?.isComplete || x.status?.isFailed).length;
  }

  public get hasProcessError(): boolean {
    return this.processes.some(x => x.status?.isFailed);
  }

  public openScopeSelectDropdown() {
    const scopeSelectDropdownPortal = new ComponentPortal(
      HeaderHierarchyOverlayComponent,
      this.vcRef,
      this.createInjector({
        highestTier: this.highestTier,
      })
    );
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.scopeSelectOrigin)
        .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]),
    });
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(scopeSelectDropdownPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
      this.hierarchyTierService.applyCurrentTier();
    });
  }

  public openProcessesDropdown(): void {
    const dropdownPortal = new ComponentPortal(
      HeaderProcessesComponent,
      this.vcRef
    );

    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.processesOrigin)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          } as ConnectedPosition,
        ]),
    });
    this.overlayRef = this.overlay.create(config);
    const compRef = this.overlayRef.attach(dropdownPortal).instance;

    this.overlayRef.backdropClick().subscribe(() => {
      this.searchActivated = false;
      this.overlayRef.detach();
    });
  }

  public openSearchDropdown() {
    this.searchActivated = true;
    const searchDropdownPortal = new ComponentPortal(
      HeaderSearchComponent,
      this.vcRef
    );

    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.searchOrigin)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          } as ConnectedPosition,
        ]),
    });
    this.overlayRef = this.overlay.create(config);
    const compRef = this.overlayRef.attach(searchDropdownPortal).instance;

    compRef.patientSelected.subscribe(() => {
      this.searchActivated = false;
      this.overlayRef.detach();
      return;
    });

    this.overlayRef.backdropClick().subscribe(() => {
      this.searchActivated = false;
      this.overlayRef.detach();
    });
  }

  public clickTitle() {
    if (this.isHeaderConsdensed$.value && this.activeViewCrumb) {
      this._router.navigate([this.activeViewCrumb.urlSegment]);
    }
  }

  public setActiveViewCrumb(event: IBreadcrumb) {
    this.activeViewCrumb = event;
    // modify paths to go to 'default' children
    const path = this.activeViewCrumb.urlSegment;
    switch (path) {
      case '/care':
        this.activeViewCrumb.urlSegment = path + '/summary';
        break;
      case '/track':
        this.activeViewCrumb.urlSegment = path + '/summary';
        break;
      case '/improve':
        this.activeViewCrumb.urlSegment = path + '/home';
        break;
      case '/submit':
        this.activeViewCrumb.urlSegment = path + '/summary';
        break;
      case '/home':
        break;
      default:
        break;
    }
  }

  public passTier(ref: CdkPortalOutletAttachedRef): void {
    console.log(this.highestTier);
    ref = ref as ComponentRef<HeaderScopeOverlayComponent>;
    ref.instance.highestTier = this.highestTier;
  }
}
