<div class="widget margin--large margin-bottom--none">
    <div class="header">
        <div class="titles t5 u-display--flex u-flex-direction--row u-align-items--center">
            <div class="title u-width--fit-content margin-right--large">{{program}} SMS OUTREACH PROGRAM - CUSTOM SCHEDULE
            </div>
        </div>
    </div>
    <div class="dynamicBody margin-bottom--small">
        <div class=" margin--large">
            <div class="margin-bottom">
                <label class="label">QUESTION</label>
                <div class="u-italic u-opacity--light u-font-size--large u-font-weight--lighter">Can you do your usual
                    activities without feeling more short of breath, having trouble breathing or coughing more?</div>
            </div>

            <div>
                <label class="label margin-bottom--small">SMS SCHEDULE</label> 
                <!--IS NOT CUSTOM-->
                <div *ngIf="!isScheduleCustom">
                    <div *ngFor="let phase of phases; index as i; last as isLast">
                        <div class="u-display--flex u-flex-direction--row" *ngIf="phase.status != 'Starts'">
                            <div [ngClass]="{'stoppedStep': phase.status == 'Current', 'pastStep': phase.status == 'Finished', 'futureStep': phase.status == 'Starts'}"
                                class="step margin-right--small u-display--flex u-justify-content--center">{{i + 1}}
                            </div>
                            <div class="u-display--flex phaseText">
                                <p class="margin-right--smaller">{{phase.frequencyDescription}} </p>
                                <p *ngIf="phase.duration"> for {{phase.duration.period}} {{phase.duration.units}}</p>
                            </div>
                            <div class="u-display--flex u-flex-direction--row phaseText phaseDates">
                                <p  class="margin--none stopped-text" *ngIf="phase.status == 'Current'">
                                    Stopped by  {{this.currentUser.firstName? this.currentUser.firstName + ' ' + this.currentUser.lastName : this.currentUser.email}} :  </p>
                                <p class="margin-left--smaller">{{formatDate(phase.startDate)}} </p>
                                <p class="margin-leftRight--small">-</p>
                                <p *ngIf="phase.finishDate">{{today | date:'MM/dd/yyyy'}}</p>
                                <p *ngIf="!phase.finishDate" class="u-italic u-opacity--light">no end date</p>
                            </div>
                        </div>
                        <div *ngIf="phase.status != 'Current' && phase.status != 'Starts'" class="verticalLine"></div>
                    </div>
                </div>
                <!--IS CUSTOM-->
                <div *ngIf="isScheduleCustom">
                    <div *ngFor="let phase of adaptivephases; index as i; last as isLast">
                        <div class="u-display--flex u-flex-direction--row">
                            <div [ngClass]="{'pastStep': phase.status == 'Stopped && !isLast', 'stoppedStep' : isLast}"
                                class="step margin-right--small u-display--flex u-justify-content--center"> {{i+1}}
                            </div>
                            <div class="u-display--flex phaseText">
                                <p class="margin-right--smaller">{{phase.frequencyDescription}} </p>
                                <p *ngIf="phase.duration"> for {{phase.duration.period}} {{phase.duration.units}}</p>
                            </div>
                            <div class="u-display--flex u-flex-direction--row phaseText phaseDates">
                                <p class="margin--none" *ngIf="!isLast">Finished: </p>
                                <p class="margin--none stopped-text" *ngIf="isLast">Stopped by 
                                    {{stoppedbyUser}} : </p>
                                <p class="margin-left--smaller">{{formatDate(phase.startDate)}} </p>
                                <p class="margin-leftRight--small">-</p>
                                <b> <p *ngIf="phase.finishDate">{{formatDate(phase.stopDate)}}</p> </b>
                                <p *ngIf="!phase.finishDate" class="u-italic u-opacity--light">no end date</p>
                            </div>
                        </div>
                        <div *ngIf="!isLast" class="verticalLine"></div>
                    </div>
                </div>

            </div>
        </div>
        <!--CUSTOM PART OF THE SCHEDULE-->
        <div *ngIf="isScheduleCustom">
            <label class="label margin--large margin-bottom--none"> CUSTOM SCHEDULE </label>
            <div class="u-display--flex u-flex-direction--row margin--large margin-bottom--none">
                <div [ngClass]="{'currentStep': !isStopRequested , 'stoppedStep' : isStopRequested}" class="step margin-right--small u-display--flex u-justify-content--center"> ... </div>
                <div class="u-display--flex phaseText">
                    <p class="margin-right--smaller">{{customSchedulesettings.frequency}} </p>
                </div>

                <div *ngIf= "!isStopRequested" class="u-display--flex u-flex-direction--row phaseText phaseDates">
                    <p class= "margin--none">Started by :
                        {{customSchedulesettings.stratedBy}} </p>
                    <p class="margin-left--smaller">{{customSchedulesettings.startDate}} </p>
                    <p class="margin-leftRight--small">-</p>
                    <p> <a (click)="stopRequest(true)"> STOP </a> </p>
                </div>

                <div *ngIf= "isStopRequested" class="u-display--flex u-flex-direction--row phaseText phaseDates">
                    <p  class="margin--none stopped-text">
                        Stopped by  {{this.currentUser.firstName? this.currentUser.firstName + ' ' + this.currentUser.lastName : this.currentUser.email}} : </p>
                    <p class="margin-left--smaller">{{customSchedulesettings.startDate}} </p>
                    <p class="margin-leftRight--small">-</p>
                    <p>  {{today | date:'MM/dd/yyyy'}}  </p>
                </div>

            </div>
        </div>
        <br>
        <br>
        <!---END OF CUSTOM PART OF THE SCHEDULE-->
        <!--CUSTOM SELECTION -->
        <div *ngIf="(!isScheduleCustom || (showCustomScheduleSelection && !isStopRequested))">
            <div class="u-display--flex u-flex-direction--row container margin-left--large">
                <div class="futureStep step margin-right u-display--flex u-justify-content--center"> + </div>
                <select [(ngModel)]="selectedFrequency" name="frequency" 
                    (change)="onFrequencyChange()" class="u-display--flex u-width--fit-content margin-right">
                    <option *ngFor="let frequency of customPhases" [value]="frequency">{{frequency}}</option>
                </select>
                <p id="on-text" class="margin-right u-display--flex margin--left margin-top--small"> {{onTextContent}} </p>
                <div class="calendar" class="margin-right u-display--flex margin--left">
                   <div *ngFor = "let day of daysOfWeek" [class.grey]="greyDaysOfWeek[day]" class="calendar-day">
                    {{day.substring(0,1)}}
                   </div>
                </div>
            </div>
        </div>
        <!--CUSTOM SELECTION -->
        <div *ngIf="isScheduleCustom && (!showCustomScheduleSelection && !isStopRequested)" class="margin-left--large">
            <p> <a (click)="customScheduleSelection(true)"> Edit Custom Schedule </a> </p>
        </div>
    </div>

    <p id="save-reminder" class="margin--none" *ngIf="!isScheduleCustom || (showCustomScheduleSelection && !isStopRequested)"> Click Save to Start </p>
    <p id="save-reminder" class="margin--none" *ngIf="isStopRequested"> Click Save to Stop </p>

    <div class="u-display--flex u-justify-content--right footer">
        <button class="button is-borderless margin-right--small" (click)="cancel()">Cancel</button>
        <button class="button is-primary" (click)="onSave()">
            <span>Save</span>
        </button>
    </div>
</div>