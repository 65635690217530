import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACO_REPORT_LEVEL, ReportFilterType } from '@track/track-reports/track-report-filter-type';
import { TrackReportFilterTypesService } from '../services/track-report-filter-types.service';

@Component({
  selector: 'coach-track-report-filter-types',
  templateUrl: './track-report-filter-types.component.html',
  styleUrls: ['./track-report-filter-types.component.scss']
})
export class TrackReportFilterTypesComponent implements OnInit {

  @Input() reportFilterTypes: string[];
  @Input() selectedFilterType: string;
  @Output()
  selectedFilterTypeChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() selectedItem: ReportFilterType;
  @Output()
  selectedItemChange: EventEmitter<ReportFilterType> = new EventEmitter<ReportFilterType>();

  _initialItem: ReportFilterType;
  @Input()
  set initialItem(value: ReportFilterType) {
    this._initialItem = value;

    if (value != null) {
      this.selectedFilterType = this.initialItem.filterType;
      this.filterTypeSelected(this.initialItem.filterType);
    }
  }
  get initialItem(): ReportFilterType {
    return this._initialItem;
  }

  filterItems: ReportFilterType[];
  acoLevel = ACO_REPORT_LEVEL;
  isLoading = false;

  constructor(private reportFilterTypeService: TrackReportFilterTypesService) { }

  ngOnInit(): void {
  }

  filterTypeSelected(value: string): void {
    this.selectedFilterTypeChange.emit(value);

    if (value !== ACO_REPORT_LEVEL) {
      this.isLoading = true;
      this.reportFilterTypeService.getFilterTypes(value).subscribe({
        next: items => {
          this.filterItems = items;
          this.selectedItem = null;

          if (items != null && items.length > 0) {
            if (this.initialItem != null) {
              this.selectedItem = items.find(x => x.filterTypeID === this.initialItem.filterTypeID &&
                x.filterType === this.initialItem.filterType);

              this.initialItem = null;
            }

            if (this.selectedItem == null) {
              this.selectedItem = items[0];
            }

            this.itemSelected(this.selectedItem);
          }
        }, complete: () => this.isLoading = false
      });
    }
  }

  itemSelected(value: ReportFilterType): void {
    this.selectedItemChange.emit(value);
  }

}
