import { DocumentMatcher } from './document.matcher';
import { PatientMatcher } from './patient.matcher';
import { ReportMatcher } from './report.matcher';
import { HCCDashboardMatcher } from './hcc-dashboard.matcher';
import { TimeTrackingMatcher } from './time-tracking.matcher';
import { MSSPValueReportMatcher } from './mssp-value-report.matcher';
import { FormsMatcher } from './forms.matcher';
import { PrescriptionsMatcher } from './prescriptions.matcher';
import { PatientsCohortMatcher } from './patients-cohort.matcher';
import { PerformanceDashboardMatcher } from './performance-dashboard.matcher';

export const RecentsMatchers = [
  new DocumentMatcher(),
  new PatientMatcher(),
  new ReportMatcher(),
  new HCCDashboardMatcher(),
  new TimeTrackingMatcher(),
  new MSSPValueReportMatcher(),
  new FormsMatcher(),
  new PrescriptionsMatcher(),
  new PatientsCohortMatcher(),
  new PerformanceDashboardMatcher(),
];
