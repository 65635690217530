import { Component, OnInit, Input, ViewChild, Injector, ElementRef, ViewContainerRef, OnChanges, OnDestroy } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { IPatientSubtab } from '@shared/models/patient-subtab';
import { PatientSubtabsPrimaryWithoutPatientEngagement, PatientSubtabsPrimaryWithPatientEngagement, PatientSubtabsSecondary, PatientSubtabsPrimaryCarePatientOnly } from '@shared/modules/patient-facesheet/patient-facesheet.constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { PatientActionDropdownComponent, NAVIGATION_PORTAL_DATA } from './../patient-action-dropdown/patient-action-dropdown.component';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import _ from 'lodash';
import { LocationStrategy } from '@angular/common';
import { CareComponentConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { CareFeatureConstants } from '@shared/models/module-constants';

import { Subscription } from 'rxjs';
import { PatientEngagement, PatientEngagementComponentIds } from '@shared/enums';

@Component({
  selector: 'coach-patient-expanded',
  templateUrl: './patient-expanded.component.html',
  providers: [Overlay]
})
export class PatientExpandedComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input() public isSMSTaskAdaptive : boolean; 
  @Input() public isScreenSmall: boolean;

  public get tierId() {
    return this?.tier?.selectedTierId;
  }

  PatientEngagement = PatientEngagement;
  PatientEngagementComponentIds = PatientEngagementComponentIds;
  public subtabsSecondary: IPatientSubtab[] = PatientSubtabsSecondary;


  public activeSubtabPrimary: string = 'Facesheet';
  public activeSubtabSecondary: string = 'Alerts';

  public isAlertsAndMessagesClosed: boolean = false;
  public get isCarePatient() {
    return this?.patient?.carePatient?.accountType === "patient";
  }
  isPatientNavDropdownOpen = false;
  private overlayRef: OverlayRef;
  public currentUrl: string = null;
  public modalStatus: { isOpen: boolean, target: string };
  public modalPatientInviteForm: boolean = false;
  public modalPatientEditForm: boolean = false;
  scopeSelectDropdownPortal: ComponentPortal<PatientActionDropdownComponent>;
  public readonly careComponentConstants = CareComponentConstants;
  CoachPatientReminderTab: string = CareFeatureConstants.CoachPatientReminderTab;
  isCoachPatientReminderTabEnabled: boolean = false;
  currentUrlSubscriber: Subscription = new Subscription();
  isSMSInviteFlagEnabled: boolean = false;
  SMSInviteFlag: string = CareFeatureConstants.SMSInviteFlag;
  subtabsPrimary: IPatientSubtab[];


  constructor(private route: ActivatedRoute,
    private _injector: Injector,
    public overlay: Overlay,
    private vcRef: ViewContainerRef,
    private Router: Router,
    private featureFlagService: FeatureFlagService,
    private location: LocationStrategy) {
    this.isSMSInviteFlagEnabled = this.featureFlagService.hasFeatureFlag(
      this.SMSInviteFlag
    );
    this.currentUrlSubscriber = this.Router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = unescape(this.Router.url).split('?')[0];
      }
    });

    this.currentUrl = unescape(this.Router.url).split('?')[0]

    this.modalStatus = {
      isOpen: false,
      target: null
    };
    this.location.onPopState(() => this.overlayRef.detach());
  }
  @ViewChild('scopeSelectOrigin') scopeSelectOrigin: ElementRef
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.currentUrlSubscriber.unsubscribe()
  }
  ngOnChanges(): void {
    if (this.patient) {
      this.route.queryParams.subscribe(params => {
        if (params.sidebar) {
          if (params.sidebar != this.activeSubtabSecondary || (params.sidebar == this.activeSubtabSecondary && this.isAlertsAndMessagesClosed)) {
            this.activeSubtabSecondary = params.sidebar;
            this.isAlertsAndMessagesClosed = false;
          }
        } else {
          this.activeSubtabSecondary = "Alerts";
        }
      });
      this.handleAsyncOperation();
    }
  }

  async handleAsyncOperation() {
    this.getsubtabsPrimary();
  }

  public getsubtabsPrimary() {
    let subTabs;
    if (this.patient && !this.patient.trackPatient) {
      subTabs = PatientSubtabsPrimaryCarePatientOnly;
    } else if (this.isPatientInvited) {
      subTabs = PatientSubtabsPrimaryWithPatientEngagement;
      if (this.isSMSTaskAdaptive) { 
        subTabs = subTabs.filter(st => st.label != 'Plan');
      }
    } else {
      subTabs = PatientSubtabsPrimaryWithoutPatientEngagement;
    }
    if (!this.isCoachPatientReminderTabEnabled) {
      subTabs = subTabs.filter(st => st.label != 'Reminders')
    }
    this.subtabsPrimary = subTabs;
    if (this.patient) {
      this.route.queryParams.subscribe(params => {
        if (this.isSMSTaskAdaptive) {
          this.activeSubtabPrimary = params.tab ? params.tab : (this.patient?.trackPatient ? 'Facesheet' : 'SMS Programs')
        } else {
          this.activeSubtabPrimary = params.tab ? params.tab : (this.patient?.trackPatient ? 'Facesheet' : 'Summary')
        }
      })
    }
  }

  public titleLine(fields: string[], separator = ' | '): string {
    return fields.filter(f => !!f).join(separator);
  }

  public switchTab(tab: string, isSecondary: boolean = false): void {
    if (!isSecondary) {
      this.activeSubtabPrimary = tab;
    } else {
      if (this.activeSubtabSecondary === tab) {
        this.toggleAlertsAndMessages();
      } else if (!this.activeSubtabSecondary) {
        this.activeSubtabSecondary = tab;
        this.toggleAlertsAndMessages();
      } else {
        this.activeSubtabSecondary = tab;
      }
    }
  }

  public toggleAlertsAndMessages(): void {
    this.isAlertsAndMessagesClosed = !this.isAlertsAndMessagesClosed;
    if (this.isAlertsAndMessagesClosed) {
      this.activeSubtabSecondary = null;
    }
  }
  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(NAVIGATION_PORTAL_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }
  public openScopeSelectDropdown() {
    const scopeSelectDropdownPortal = new ComponentPortal(PatientActionDropdownComponent, this.vcRef, this.createInjector({
      emitter: this.dropDownEmitterFunc,
      patient: this.patient
    }));
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.scopeSelectOrigin)
        .withPositions([
          { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }])
    });
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(scopeSelectDropdownPortal);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    this.overlayRef.outsidePointerEvents().subscribe(() => this.overlayRef.detach());
  }
  get dropDownEmitterFunc() {
    return this.dropDownEmitter.bind(this);
  }

  dropDownEmitter(action: string): void {
    this.overlayRef.detach()
    this.modalStatus = {
      isOpen: true,
      target: action
    }
  }
  closeModalOnBackgroundClick(event: any): void {
    if (event.target.getAttribute('role') == 'modal-background') {
      this.closeModal()
    }
  }
  closeModal(): void {
    this.modalStatus = {
      isOpen: false,
      target: null
    }
  }
  get isPatientInvited(): boolean {
    return this?.patient?.carePatient?.accountType === 'patient';
  }

  public isSelectOptionSelected(tab: string): boolean {
    return this.subtabsPrimary.some((tab) => tab.label === this.activeSubtabPrimary);
  }

  getSubtabComponentId(subtabLabel: string): number | number[] {
    switch (subtabLabel) {
      case 'Facesheet':
      case 'Timeline':
        return this.careComponentConstants.Default;
      case 'Plan':
        return PatientEngagementComponentIds;
      case 'Summary':
        return PatientEngagementComponentIds;
      case 'SMS Programs':
        return PatientEngagementComponentIds;
      case 'Worklist Details':
        return this.careComponentConstants.PACNavigatorProgram;
      default:
        return PatientEngagement.PatientApp;
    }
  }

}
