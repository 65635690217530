import { AwvWorkflowStatuses } from '@api/care-orch/models/awv-workflow-statuses';
import { AwvWorkflow } from '@api/care-orch/models/awv-workflow';

export class AwvActivityChangeEvent {
  chPatId: number = null;
  before?: AwvActivityChangeEventDetails;
  after: AwvActivityChangeEventDetails = null;
  constructor(chPatId: number, beforeAwvWorkflow: AwvWorkflow, afterAwvWorkflow: AwvWorkflow){
    this.before = beforeAwvWorkflow === null ? null :
      new AwvActivityChangeEventDetails(beforeAwvWorkflow.status, new Date(beforeAwvWorkflow.dueDateTime));
    this.after = new AwvActivityChangeEventDetails(afterAwvWorkflow.status, new Date(afterAwvWorkflow.dueDateTime));
    this.chPatId = chPatId;
  }
}

export class AwvActivityChangeEventDetails {
  status: AwvWorkflowStatuses = null;
  dueDate: Date = null;
  constructor(status: AwvWorkflowStatuses, dueDate: Date) {
    this.status = status;
    this.dueDate = dueDate;
  }
}


