import { NgModule } from '@angular/core';

import { ClickAwayDirective } from './click-away.directive';
import { HasModuleDirective } from './has-access/has-module.directive';
import { HasFeatureDirective } from './has-access/has-feature.directive';
import { HasComponentDirective } from './has-access/has-component.directive';
import { AgGridResizeDirective } from './ag-grid-resize/ag-grid-resize.directive';
import { HasFeatureFlagDirective } from './has-access/has-feature-flag.directive';
import { DoesNotHaveFeatureFlagDirective } from './has-access/does-not-have-feature-flag.directive';
import { HasFeatureFlagAndAllComponents } from './has-access/has-feature-flag-and-all-components.directive';
import { DoesNotHaveFeatureFlagOrAnyComponents } from './has-access/does-not-have-feature-flag-or-any-components.directive';
import { IsNotTierNumDirective } from './is-not-tier-num.directive';
import { HasFeatureFlagDefinedDirective } from './has-access/has-feature-flag-defined.directive';
import { CopyToClipboardDirective } from '@shared/directives/copy-to-clipboard.directive';

@NgModule({
  declarations: [
    ClickAwayDirective,
    CopyToClipboardDirective,
    HasModuleDirective,
    HasFeatureDirective,
    HasComponentDirective,
    AgGridResizeDirective,
    HasFeatureFlagDirective,
    HasFeatureFlagDefinedDirective,
    DoesNotHaveFeatureFlagDirective,
    HasFeatureFlagAndAllComponents,
    DoesNotHaveFeatureFlagOrAnyComponents,
    IsNotTierNumDirective
  ],
  exports: [
    ClickAwayDirective,
    CopyToClipboardDirective,
    HasModuleDirective,
    HasFeatureDirective,
    HasComponentDirective,
    HasFeatureFlagDirective,
    HasFeatureFlagDefinedDirective,
    DoesNotHaveFeatureFlagDirective,
    HasFeatureFlagAndAllComponents,
    DoesNotHaveFeatureFlagOrAnyComponents,
    IsNotTierNumDirective,
    AgGridResizeDirective
  ],
  providers: [
    ClickAwayDirective,
    CopyToClipboardDirective,
    HasModuleDirective,
    HasFeatureDirective,
    HasComponentDirective,
    IsNotTierNumDirective,
    HasFeatureFlagDirective,
    DoesNotHaveFeatureFlagDirective,
    HasFeatureFlagAndAllComponents,
    DoesNotHaveFeatureFlagOrAnyComponents,
    AgGridResizeDirective
  ],
})
export class DirectivesModule {}
