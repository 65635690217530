import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { environment } from 'src/environments/environment';
import { InterfaceSourceTier } from './interface-source-tier';

@Injectable({
  providedIn: 'root'
})
export class InterfaceSourceTiersService extends AzureApiBase<InterfaceSourceTier> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/facesheetSchedule/interfaceTiers');
  }

}
