<div class="dynamicBody">
  <div class="widgets">
    <div class="widget-column column">
      <div class="widget">
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">Summary</div>
          </div>
        </div>

        <div class="dynamicBody padding--large">
          <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Submit_Sum_1700x1100_20221114.svg" />
          <p>This area makes it easy for you to access, submit, and review your required forms. The Quality, Compliance,
            Promoting Interoperability, and Meeting forms reflect your progress toward CMS requirements and contribute
            to the success of the ACO. <em> Don’t see the form you are looking for? Check back later as form links are
              made available once the submission period has opened.</em></p>
              <coach-submit-upcoming-submission-summary (formSubmissionOverviewEmitter)="recieveFormSubmissionOverviewData($event)"></coach-submit-upcoming-submission-summary>
        </div>
      </div>
    </div>
    <div class="widget-column column" *hasFeature="qualityForm">
      <div class="widget">
        <div class="header header--slanted u-cursor--pointer" *ngIf="qualityFormOpen" (click)="routeToTab('quality')">
          <div class="titles t5">
            <div class="title">{{quailityName}}</div>
          </div>
        </div>
        <div class="header header--slanted" *ngIf="!qualityFormOpen">
          <div class="titles t5">
            <div class="title">{{quailityName}}</div>
          </div>
        </div>
        <div class="dynamicBody">
          <section class="padding--large">
            <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Submit_Sum_Qual_1700x1100_20221114.svg" />
            <p>
              Quality is essential to valued-based programs. {{app.CompanyName}} uses these self-reported Quality data submissions,
              from your EHR, to help assess performance and track progress improvement throughout the year, so you can
              successfully report Quality for MIPS and the Medicare Shared Savings program. Depending on your ACO, these
              data may be used as part of the Shared Savings distribution model.
            </p>
            <div class="row--verticalCenter margin-top--large">
              <button class="button u-flex--right" *ngIf="qualityFormOpen" (click)="routeToTab('quality')">Open</button>
            </div>
          </section>
          <!-- Leaving this section here commented so we have the UX design for dynamicly building this section
          <section class="border-top padding--large padding-bottom--larger">
            <h5 class="margin-top">Next Submission</h5>
            <div class="row--verticalCenter">
              <div class="titles t4 u-fill">
                <div class="title">
                  <span>Jan-Dec 2020</span>
                  <span class="u-opacity--light u-italic margin-left--small">(Due Jan 31)</span>
                </div>
                <div class="subtitle">In progress</div>
              </div>
              <button class="button" (click)="routeToTab('quality')">Open</button>
            </div>
            <div class="clump padding margin-top margins-bottom--smaller u-background-color--black--5">
              <div>
                <b>4</b> Practices have special exemption status
              </div>
              <div>
                <b>3</b> Practices have pending submission forms
              </div>
              <div class="u-color--danger">
                <b>13</b> Practices have special exemption status
              </div>
            </div>
          </section>
          <section class="border-top padding--large padding-bottom--larger">
            <h5 class="margin-top">Last Submission</h5>
            <div class="row--verticalCenter">
              <div class="titles t4 u-fill">
                <div class="title">
                  <span>Jan-Sep 2020</span>
                </div>
                <div class="subtitle u-color--success">Complete</div>
              </div>
              <button class="button" (click)="routeToTab('quality')">View</button>
            </div>
            <div class="clump padding margin-top margins-bottom--smaller u-background-color--black--5">
              <div>
                <b>4</b> Practices have special exemption status
              </div>
              <div>
                <b>16</b> Practices have been submitted
              </div>
            </div>
          </section>-->
        </div>
      </div>
    </div>
    <!-- Leaving this section here commented so we have the UX design for dynamicly building this section<div class="widget-column column" *hasFeature="promotingInteroperabilityForm">
      <div class="widget">
        <div class="header header--slanted u-cursor--pointer" (click)="routeToTab('promoting-interoperability')">
          <div class="titles t5">
            <div class="title">Promoting Interoperability</div>
          </div>
        </div>
        <div class="dynamicBody">
          <section class="padding--large">
            <p>Ensuring accurate patient records leads to better outcomes and patient safety.  That’s why interoperability is so crucial to the long term success of your ACO.</p>
            <p>We’re here to help you track your progress and help you leverage best practices toward your established interoperability goals.</p>
          </section>

          <section class="border-top padding--large padding-bottom--larger">
            <h5 class="margin-top">Next Snapshot</h5>
            <div class="row--verticalCenter">
              <div class="titles t4 u-fill">
                <div class="title">
                  <span>Jan-Dec 2020</span>
                  <span class="u-opacity--light u-italic margin-left--small">(Due Jan 31)</span>
                </div>
                <div class="subtitle">In progress</div>
              </div>
              <button class="button" (click)="routeToTab('promoting-interoperability')">Open</button>
            </div>
            <div class="clump padding margin-top margins-bottom--smaller u-background-color--black--5">
              <div>
                <b>6</b> Practices have special exemption status
              </div>
              <div>
                <b>3</b> Practices have pending submission forms
              </div>
              <div class="u-color--danger">
                <b>11</b> Practices have special exemption status
              </div>
            </div>
          </section>
          <section class="border-top padding--large padding-bottom--larger">
            <h5 class="margin-top">Last Snapshot</h5>
            <div class="row--verticalCenter">
              <div class="titles t4 u-fill">
                <div class="title">
                  <span>Jan-Sep 2020</span>
                </div>
                <div class="subtitle u-color--success">Complete</div>
              </div>
              <button class="button" (click)="routeToTab('promoting-interoperability')">View</button>
            </div>
            <div class="clump padding margin-top margins-bottom--smaller u-background-color--black--5">
              <div>
                <b>6</b> Practices have special exemption status
              </div>
              <div>
                <b>12</b> Practices have been submitted
              </div>
            </div>
          </section>
        </div>
      </div>
    </div> -->
    <div class="widget-column column" *hasFeature="complianceForm">
      <div class="widget">
        <div class="header header--slanted u-cursor--pointer" *ngIf="complianceFormOpen" (click)="routeToTab('compliance')">
          <div class="titles t5">
            <div class="title">Compliance</div>
          </div>
        </div>
        <div class="header header--slanted" *ngIf="!complianceFormOpen">
          <div class="titles t5">
            <div class="title">Compliance</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Submit_Sum_Compl_1700x1100_20221114.svg" />
          <p>The self-assessment form is an annual exercise that ensures each organization is aware of and implementing
            the required ACO compliance policies and procedures.</p>
          <div class="row--verticalCenter margin-top--large">
            <button class="button u-flex--right" *ngIf="complianceFormOpen" (click)="routeToTab('compliance')">Open</button>
          </div>
        </div>
      </div>
    </div>

    <div class="widget-column column" *hasFeature="promotingInteroperabilityForm">
      <div class="widget">
        <div class="header header--slanted u-cursor--pointer" *ngIf="piFormOpen" (click)="routeToTab('promoting-interoperability')">
          <div class="titles t5">
            <div class="title">Promoting Interoperability</div>
          </div>
        </div>
        <div class="header header--slanted" *ngIf="!piFormOpen">
          <div class="titles t5">
            <div class="title">Promoting Interoperability</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Submit_Sum_PI_1700x1100_20221114.svg" />
          <p>Ensuring accurate patient records leads to better outcomes and patient safety. That’s why interoperability
            is so crucial to the long term success of your ACO.</p>
          <p>We’re here to help you track your progress and help you leverage best practices toward your established
            interoperability goals.</p>
          <div class="row--verticalCenter margin-top--large">
            <button class="button u-flex--right" *ngIf="piFormOpen" (click)="routeToTab('promoting-interoperability')">Open</button>
          </div>
        </div>
      </div>
    </div>
    <div class="widget-column column" *hasFeature="meetingForm">
      <div class="widget" *hasFeature="meetingForm">
        <div class="header header--slanted u-cursor--pointer" (click)="routeToTab('meeting')">
          <div class="titles t5">
            <div class="title">
              {{submitMeetingName}}
            </div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <p>Upload your agenda and meeting notes from your monthly Roadmap and quarterly Cohort Steering meetings,
            along with some of the highlights of each of those meetings. </p>
          <div class="row--verticalCenter margin-top--large">
            <button class="button u-flex--right" (click)="routeToTab('meeting')">Open</button>
          </div>
        </div>
      </div>
    </div>
  </div>
