<div *ngIf="item" class="row--verticalCenter">
  <coach-icon [iconName]="iconType"
    class="margin-right--small  {{ !item.Children ? 'u-opacity--lightest' : 'u-opacity--lighter' }}">
  </coach-icon>
  <div class="field u-text-wrap u-wrap {{ !item.Children ? 'u-font-weight--normal' : '' }} ">
    {{item.Title}}
  </div>
  <div *ngIf="item.Children" class="u-display--flex">
    <div class="field u-opacity--lighter u-preserve u-italic padding-bottom--smallest">
      {{item.Children.length}}
    </div>
    <coach-icon class="u-preserve" iconName="chevron-right"
      iconColor="var(--interaction)">
    </coach-icon>
  </div>
</div>
