import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { AzureApiBase } from '../azure-api/azure-api-base';
import { FeatureFlag } from './feature-flag';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService extends AzureApiBase<FeatureFlag> {

  /** Subscribe to listen for changes in the current feature flags collection */
  public featureFlags$ = new BehaviorSubject<FeatureFlag[]>(null);

  /** Service for retrieving feature flags for current tier
   * 
   * To restrict template by feature flag, see {@link HasFeatureFlagDirective}
   * 
   * To restrict access to a route, add `featureFlag` to route data
   * 
   * To restrict access to a tab of {@link CoachTabsComponent}, use the `featureFlag` property of {@link CoachTabs}
   * 
   * If checking for feature flag directly, use `featureFlags$` behavior subject to subscribe to current feature flags and use `hasFeatureFlag()` to check for a specific flag
  */
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.apiBaseUrl, '/access/featureFlag');
  }

  /** Updates the feature flag collection based on the current tier. Should only be called from app component. */
  updateFeatureFlags(tier: HierarchyTier): Observable<FeatureFlag[]> {
    const observable = this.getFeatureFlags(tier.tier, tier.selectedTierId).pipe(share());
    observable.subscribe(flags => this.featureFlags$.next(flags));
    return observable;
  }

  /** Call Feature Flag API endpoint to retrieve flags for current tier*/
  private getFeatureFlags(tierNum: number, tierId: string): Observable<FeatureFlag[]> {
    const params = new HttpParams().set('tierNum', tierNum?.toString())
      .append('tierId', tierId);

    return this.get(null, params);
  }

  /** Checks to see if a given feature flag is present in the current flag collection */
  hasFeatureFlag(featureFlag: string): boolean {
    return this.featureFlags$.value?.some(x => x.flagName === featureFlag);
  }
}
