/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CareManagementPatientMapPagedResponse } from '../models/care-management-patient-map-paged-response';
import { ChPatId } from '../models/ch-pat-id';
import { DiseaseCohortDefinition } from '../models/disease-cohort-definition';
import { GlobalPatientSearchResult } from '../models/global-patient-search-result';
import { InsertCoachPatientRequest } from '../models/insert-coach-patient-request';
import { PatientCohortMapPagedResponse } from '../models/patient-cohort-map-paged-response';
import { PatientTimeline } from '../models/patient-timeline';
import { TrackPatientMap } from '../models/track-patient-map';
import { TrackPatientMapPagedResponse } from '../models/track-patient-map-paged-response';
import { UpdateCoachPatientRequest } from '../models/update-coach-patient-request';

@Injectable({
  providedIn: 'root',
})
export class PatientsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation patientsSearchGet
   */
  static readonly PatientsSearchGetPath = '/Patients/search';

  /**
   * Search for a patient based on full name, date of birth, or MBI.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsSearchGet$Response(params: {
    NameSearchTerm?: string;
    Mbi?: string;
    DateOfBirth?: string;
    TierId: string;
    TierNum: number;
    RowCount?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<GlobalPatientSearchResult>>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsSearchGetPath, 'get');
    if (params) {
      rb.query('NameSearchTerm', params.NameSearchTerm, {});
      rb.query('Mbi', params.Mbi, {});
      rb.query('DateOfBirth', params.DateOfBirth, {});
      rb.query('TierId', params.TierId, {});
      rb.query('TierNum', params.TierNum, {});
      rb.query('RowCount', params.RowCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GlobalPatientSearchResult>>;
      })
    );
  }

  /**
   * Search for a patient based on full name, date of birth, or MBI.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsSearchGet(params: {
    NameSearchTerm?: string;
    Mbi?: string;
    DateOfBirth?: string;
    TierId: string;
    TierNum: number;
    RowCount?: number;
  },
  context?: HttpContext

): Observable<Array<GlobalPatientSearchResult>> {

    return this.patientsSearchGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<GlobalPatientSearchResult>>) => r.body as Array<GlobalPatientSearchResult>)
    );
  }

  /**
   * Path part for operation patientsCohortsGet
   */
  static readonly PatientsCohortsGetPath = '/Patients/cohorts';

  /**
   * Return a list of patient cohorts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsCohortsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsCohortsGet$Response(params: {
    CohortId?: number;
    TierNum: number;
    TierId: string;
    OnlyAttributed?: boolean;
    AddedAfter?: string;
    SortColumn?: string;
    SortDirection?: string;
    Skip?: number;
    Take?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PatientCohortMapPagedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsCohortsGetPath, 'get');
    if (params) {
      rb.query('CohortId', params.CohortId, {});
      rb.query('TierNum', params.TierNum, {});
      rb.query('TierId', params.TierId, {});
      rb.query('OnlyAttributed', params.OnlyAttributed, {});
      rb.query('AddedAfter', params.AddedAfter, {});
      rb.query('SortColumn', params.SortColumn, {});
      rb.query('SortDirection', params.SortDirection, {});
      rb.query('Skip', params.Skip, {});
      rb.query('Take', params.Take, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatientCohortMapPagedResponse>;
      })
    );
  }

  /**
   * Return a list of patient cohorts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsCohortsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsCohortsGet(params: {
    CohortId?: number;
    TierNum: number;
    TierId: string;
    OnlyAttributed?: boolean;
    AddedAfter?: string;
    SortColumn?: string;
    SortDirection?: string;
    Skip?: number;
    Take?: number;
  },
  context?: HttpContext

): Observable<PatientCohortMapPagedResponse> {

    return this.patientsCohortsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<PatientCohortMapPagedResponse>) => r.body as PatientCohortMapPagedResponse)
    );
  }

  /**
   * Path part for operation patientsListGet
   */
  static readonly PatientsListGetPath = '/Patients/list';

  /**
   * Return a list of patients.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsListGet$Response(params: {
    TierNum: number;
    TierId: string;
    Skip?: number;
    Take?: number;
    ChPatIds?: Array<number>;

    /**
     * Flag for returning deceased patients. Defaults to true if not specified.
     */
    IncludeDeceased?: boolean;

    /**
     * Number indicating the top percentage of patients to return.
     */
    TopPercent?: number;

    /**
     * Indicates the column on which to order the results by. Currently only applies when TopPercent is set. Defaults to "openhccgapwt".
     */
    OrderBy?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TrackPatientMapPagedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsListGetPath, 'get');
    if (params) {
      rb.query('TierNum', params.TierNum, {});
      rb.query('TierId', params.TierId, {});
      rb.query('Skip', params.Skip, {});
      rb.query('Take', params.Take, {});
      rb.query('ChPatIds', params.ChPatIds, {});
      rb.query('IncludeDeceased', params.IncludeDeceased, {});
      rb.query('TopPercent', params.TopPercent, {});
      rb.query('OrderBy', params.OrderBy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrackPatientMapPagedResponse>;
      })
    );
  }

  /**
   * Return a list of patients.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsListGet(params: {
    TierNum: number;
    TierId: string;
    Skip?: number;
    Take?: number;
    ChPatIds?: Array<number>;

    /**
     * Flag for returning deceased patients. Defaults to true if not specified.
     */
    IncludeDeceased?: boolean;

    /**
     * Number indicating the top percentage of patients to return.
     */
    TopPercent?: number;

    /**
     * Indicates the column on which to order the results by. Currently only applies when TopPercent is set. Defaults to "openhccgapwt".
     */
    OrderBy?: string;
  },
  context?: HttpContext

): Observable<TrackPatientMapPagedResponse> {

    return this.patientsListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TrackPatientMapPagedResponse>) => r.body as TrackPatientMapPagedResponse)
    );
  }

  /**
   * Path part for operation patientsGet
   */
  static readonly PatientsGetPath = '/Patients';

  /**
   * Retrieve a single patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsGet$Response(params: {

    /**
     * Caravan Health Patient ID
     */
    ChPatId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TrackPatientMap>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsGetPath, 'get');
    if (params) {
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrackPatientMap>;
      })
    );
  }

  /**
   * Retrieve a single patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsGet(params: {

    /**
     * Caravan Health Patient ID
     */
    ChPatId: number;
  },
  context?: HttpContext

): Observable<TrackPatientMap> {

    return this.patientsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TrackPatientMap>) => r.body as TrackPatientMap)
    );
  }

  /**
   * Path part for operation patientsInsertPost
   */
  static readonly PatientsInsertPostPath = '/Patients/insert';

  /**
   * Insert patient into the CDW.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsInsertPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientsInsertPost$Response(params?: {
    body?: InsertCoachPatientRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ChPatId>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsInsertPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChPatId>;
      })
    );
  }

  /**
   * Insert patient into the CDW.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsInsertPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientsInsertPost(params?: {
    body?: InsertCoachPatientRequest
  },
  context?: HttpContext

): Observable<ChPatId> {

    return this.patientsInsertPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ChPatId>) => r.body as ChPatId)
    );
  }

  /**
   * Path part for operation patientsUpdatePost
   */
  static readonly PatientsUpdatePostPath = '/Patients/update';

  /**
   * Update patient in the CDW.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsUpdatePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientsUpdatePost$Response(params?: {
    body?: UpdateCoachPatientRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ChPatId>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChPatId>;
      })
    );
  }

  /**
   * Update patient in the CDW.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsUpdatePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientsUpdatePost(params?: {
    body?: UpdateCoachPatientRequest
  },
  context?: HttpContext

): Observable<ChPatId> {

    return this.patientsUpdatePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ChPatId>) => r.body as ChPatId)
    );
  }

  /**
   * Path part for operation patientsTimelineGet
   */
  static readonly PatientsTimelineGetPath = '/Patients/timeline';

  /**
   * Retrieve timeline for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsTimelineGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsTimelineGet$Response(params?: {
    chPatId?: number;
    supplementalId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PatientTimeline>>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsTimelineGetPath, 'get');
    if (params) {
      rb.query('chPatId', params.chPatId, {});
      rb.query('supplementalId', params.supplementalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PatientTimeline>>;
      })
    );
  }

  /**
   * Retrieve timeline for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsTimelineGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsTimelineGet(params?: {
    chPatId?: number;
    supplementalId?: number;
  },
  context?: HttpContext

): Observable<Array<PatientTimeline>> {

    return this.patientsTimelineGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PatientTimeline>>) => r.body as Array<PatientTimeline>)
    );
  }

  /**
   * Path part for operation patientsDiseaseCohortDefinitionsGet
   */
  static readonly PatientsDiseaseCohortDefinitionsGetPath = '/Patients/diseaseCohortDefinitions';

  /**
   * Retrieve disease cohort definitions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsDiseaseCohortDefinitionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsDiseaseCohortDefinitionsGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DiseaseCohortDefinition>>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsDiseaseCohortDefinitionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DiseaseCohortDefinition>>;
      })
    );
  }

  /**
   * Retrieve disease cohort definitions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsDiseaseCohortDefinitionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsDiseaseCohortDefinitionsGet(params?: {
  },
  context?: HttpContext

): Observable<Array<DiseaseCohortDefinition>> {

    return this.patientsDiseaseCohortDefinitionsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DiseaseCohortDefinition>>) => r.body as Array<DiseaseCohortDefinition>)
    );
  }

  /**
   * Path part for operation patientsHccVipCohortGet
   */
  static readonly PatientsHccVipCohortGetPath = '/Patients/hccVipCohort';

  /**
   * Return a list of HCC VIP cohorts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsHccVipCohortGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsHccVipCohortGet$Response(params: {
    TierNum: number;
    TierId: string;
    TopCount?: number;
    OnlyAttributed?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TrackPatientMap>>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsHccVipCohortGetPath, 'get');
    if (params) {
      rb.query('TierNum', params.TierNum, {});
      rb.query('TierId', params.TierId, {});
      rb.query('TopCount', params.TopCount, {});
      rb.query('OnlyAttributed', params.OnlyAttributed, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrackPatientMap>>;
      })
    );
  }

  /**
   * Return a list of HCC VIP cohorts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsHccVipCohortGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsHccVipCohortGet(params: {
    TierNum: number;
    TierId: string;
    TopCount?: number;
    OnlyAttributed?: boolean;
  },
  context?: HttpContext

): Observable<Array<TrackPatientMap>> {

    return this.patientsHccVipCohortGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TrackPatientMap>>) => r.body as Array<TrackPatientMap>)
    );
  }

  /**
   * Path part for operation patientsCareManagementGet
   */
  static readonly PatientsCareManagementGetPath = '/Patients/careManagement';

  /**
   * Return a list of Care Management patients.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientsCareManagementGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsCareManagementGet$Response(params?: {
    TierId?: string;
    TierNum?: number;
    CohortId?: number;
    Skip?: number;
    Take?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CareManagementPatientMapPagedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PatientsService.PatientsCareManagementGetPath, 'get');
    if (params) {
      rb.query('TierId', params.TierId, {});
      rb.query('TierNum', params.TierNum, {});
      rb.query('CohortId', params.CohortId, {});
      rb.query('Skip', params.Skip, {});
      rb.query('Take', params.Take, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CareManagementPatientMapPagedResponse>;
      })
    );
  }

  /**
   * Return a list of Care Management patients.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientsCareManagementGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientsCareManagementGet(params?: {
    TierId?: string;
    TierNum?: number;
    CohortId?: number;
    Skip?: number;
    Take?: number;
  },
  context?: HttpContext

): Observable<CareManagementPatientMapPagedResponse> {

    return this.patientsCareManagementGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<CareManagementPatientMapPagedResponse>) => r.body as CareManagementPatientMapPagedResponse)
    );
  }

}
