<div *ngIf="(patientClone && !isPatientInvited) || patients" >
    <!-- HEADER -->
    <div class="header padding--smallest u-display--flex u-align-items--center">
        <div class="edgeItem"></div>
        <div class="modalHeader">Invite Patient</div>
        <div class="closeButton"><button class="button is-borderless margin-right--small u-justify-content--right" (click)="close('')"><coach-icon iconName="x"></coach-icon></button></div>
    </div>
    <div class="header">
        <div class="titles">
            <div class="text-break white-space--normal patientName" id="patientDisplayNameExpanded">
                {{patient.displayName()}}
              </div>
              <div class="subtitle text-break white-space--normal">
                {{patient.displayAge()}} {{patient.displayGender()}} | {{patient.displayDob()}} |
                <span *ngIf="patient.displayIdentifier()">| {{
                  patient.displayIdentifier() }}
                </span>
                <span class="subtitle text-break white-space--normal" *ngIf="patient.mbi">
                    MBI: {{patient.mbi | mask: 6 }}
                </span>
            </div>
        </div>
    </div>
    <!-- STEP 1 -->
    <span *ngIf="formStep === 1">
        <div class="dynamicBody padding" *ngIf="patientClone">
            <!-- PHONE NUMBER -->
            <div class="field padding--smallest">
                <label class="label" for="patientMobile"
                [ngClass]="{
                    'u-color--brand--3': !isInputValid(patientClone, 'phoneNumber')
                }">Mobile Phone *</label>
                <input class="mobileInput" type="text" id="patientMobile" placeholder="(999) 999-9999"
                    [(ngModel)]="patientClone.phoneNumber" />
            </div>
            <div class="field padding--smallest">
                <small id="error-message-phone" class="margin-top" *ngIf="!isInputValid(patientClone, 'phoneNumber')"> Invalid phone format </small>
            </div>
            <!-- EMAIL -->
            <label class="margin-top label padding-smallest" for="patientEmail" [ngClass]="{
                'u-color--brand--3': !isInputValid(patientClone, 'email')
            }">Email</label>
            <input type="text" id="patientEmail" [(ngModel)]="patientClone.email"  placeholder="user@example.com"/>
            <div class="field padding--smallest">
                <small id="error-message-email" class="margin-top" *ngIf="!isInputValid(patientClone, 'email')"> Invalid email </small>
            </div>
            <!-- CARE COORDINATOR -->
            <div class="field padding--smallest" *ngIf="isSMSInviteFlagEnabled">
                <label class="margin-top label" for="patientProfessional" [ngClass]="{
                    'u-color--brand--3': !(patientClone.professionalId && patientClone.professionalId.length > 0)
                }">
                    Care Coordinator *
                </label>
                <select id="patientProfessional" [(ngModel)]="patientClone.professionalId"
                    name="selectProfessional">
                    <option disabled>Please select
                        professional
                    </option>
                    <option [ngValue]="professional._id"
                        [selected]="patientClone.professionalId == professional._id"
                        *ngFor="let professional of professionals">{{ professional.lastName }}
                        {{ professional.firstName }}
                    </option>
                </select>
            </div>
            <div *ngIf='orgData'>
                <label class="margin-top label" for="patientProfessional">
                    Program
                </label>
                <div class="column u-fill u-position--relative">
    
                    <!-- PROCEDURE -->
                    <p *ngIf="!orgData.availableProcedures?.length">No protocols found.</p>
                    <ng-container *ngFor="let procedure of orgData.availableProcedures">
                        <div class="list">
    
                            <!-- PROTOCOL -->
                            <span *ngFor="let protocolId of procedure.allowedProtocols">
                                <div *ngIf="protocolsById[protocolId]">
                                    <div id={{protocolId}} >
                                        <div class="row">
                                            <input [checked]="protocolsById[protocolId].isSelected"
                                                type="radio">
                                            <label (click)="selectProtocol(procedure, protocolId)">{{protocolsById[protocolId].name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- Consent  -->
            <div class="field margin-top" *ngIf="patientClone">
                <label class="margin-top label" for="patientProfessional">
                    Consent *
                </label>
                <input id="checkbox_smsEnrollmentAgreed" type="checkbox" [(ngModel)]="smsEnrollmentAgreed">
                <label for="checkbox_smsEnrollmentAgreed"> Patient has verbally consented to be enrolled in the SMS program. </label>
            </div>
        </div>
        <!-- footer -->
        <div class="footer">
            <div *ngIf="!patientClone.inviteStatus || patientClone.inviteStatus?.loading === 0" class="u-display--flex u-justify-content--right">
                <button class="button is-borderless margin-right--small" (click)="close('')">Cancel</button>
                <button class="button" (click)="submit(patientClone, patientClone.professionalId)" [ngClass]="{
                            'is-disabled is-secondary': !isPatientValid() || !smsEnrollmentAgreed || !isProtocolSelected() || !orgData,
                            'is-primary': isPatientValid() && smsEnrollmentAgreed && isProtocolSelected() && orgData
                        }"
                    [disabled]="!isPatientValid() || !smsEnrollmentAgreed || !isProtocolSelected() || !orgData">
                        <coach-loading-indicator *ngIf="!orgData"></coach-loading-indicator>
                        <span *ngIf="orgData">Send Invite</span>
                    </button>
            </div>
            <div class="u-display--flex u-justify-content--right u-align-items--center"
            *ngIf="patientClone.inviteStatus && patientClone.inviteStatus.loading !== 0">
            <coach-loading-indicator size="30" *ngIf="patientClone.inviteStatus?.loading === 1">
            </coach-loading-indicator>
            <span class="margin-leftRight--large">{{patientClone.inviteStatus?.message}}</span>

            <button class="button is-primary" [disabled]="invitationEligible && !smsEnrollmentAgreed" [ngClass]="{'u-disabled': patientClone.inviteStatus?.loading === 1}"
                [disabled]="patientClone.inviteStatus?.loading === 1" (click)="close('')">
                Close
            </button>
        </div>
        </div>
    </span>
</div>
<div *ngIf="patientClone && isPatientInvited">
    <!-- HEADER -->
    <div class="header">
        <div class="titles">
            <div class="title text-center">
                Patient Re-Invite Form
            </div>
        </div>
    </div>
    <div class="dynamicBody padding">
        <!-- single patient -->
        <div class="u-display--flex margin-topBottom--small" *ngIf="patientClone">
            <coach-icon iconName="user" iconSize="large"></coach-icon>
            <div class='u-display--flex u-flex-direction--column margin-left--small'>
                <h5 class="margin-topBottom--none">{{patientClone.lastName}} {{patientClone.nickName}},
                    {{patientClone.firstName}}</h5>
                <p class="margin-topBottom--none u-thin">{{patientClone.email}}</p>
            </div>
        </div>
    </div>
    <div class="footer" [ngClass]="{
        'u-colors--success': patientClone.inviteStatus?.loading == 2,
        'u-colors--danger': patientClone.inviteStatus?.loading == -1
    }">
        <div class="u-display--flex u-justify-content--right" *ngIf="!patientClone.inviteStatus || patientClone.inviteStatus?.loading === 0">
            <button class="button is-borderless margin-right--small" (click)="close('')">Cancel</button>
            <button class="button is-primary" [ngClass]="{
                'is-danger': !isSMSPatient,
                'is-primary': isSMSPatient
            }" (click)="sendInvite(patientClone)" [disabled]="!isSMSPatient">
                <span *ngIf="isSMSPatient">Send Invite</span>
                <span *ngIf="!isSMSPatient">Can't re-send invitation sms</span>
            </button>
        </div>
        <div class="u-display--flex u-justify-content--right u-align-items--center"
            *ngIf="patientClone.inviteStatus && patientClone.inviteStatus.loading !== 0">
            <coach-loading-indicator size="30" *ngIf="patientClone.inviteStatus?.loading === 1">
            </coach-loading-indicator>
            <span class="margin-leftRight--large">{{patientClone.inviteStatus?.message}}</span>

            <button class="button is-primary" [ngClass]="{'u-disabled': patientClone.inviteStatus?.loading === 1}"
                [disabled]="patientClone.inviteStatus?.loading === 1" (click)="close('')">
                Close
            </button>
        </div>
    </div>
</div>
