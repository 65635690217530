import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { Patient } from '@shared/models/patient';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { PatientService } from '@shared/services/patient/patient.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { OutcomeType } from '@api/care-orch/models/outcome-type';
import { PatientAwvUtilities } from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/patient-awv-utilities.component';
import { RemovedOutcome } from '@api/care-orch/models/removed-outcome';
import {PropertiesType, RemovedProperties, RemovedReasons} from '@api/care-orch/models';
import {CareOrchestrationConstants} from '@shared/models/module-constants';

export class Comment {
  createdDateTime: string;
  createdUserDisplayName: string;
  commentText: string;
  constructor(createdDateTime, createdUserDisplayName, commentText) {
    this.createdDateTime = createdDateTime;
    this.createdUserDisplayName = createdUserDisplayName;
    this.commentText = commentText;
  }
}

@Component({
  selector: 'coach-patient-workflow-remove',
  templateUrl: './patient-workflow-remove.component.html',
  styleUrls: ['./patient-workflow-remove.component.scss']
})
export class PatientWorkflowRemoveComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public removedOutcome: RemovedOutcome;
  @Input() public editModeActive = false;
  @Output() outcomeSaved: EventEmitter<RemovedOutcome> = new EventEmitter();
  @Output() deleteActivity: EventEmitter<string> = new EventEmitter();

  user: wpapi.model.Professional;
  professionalsById: { [key: string]: wpapi.model.Professional } = {};

  removeReason = '';
  comment = '';
  reasons: string[];
  allFieldsEmpty = true;
  requiredFieldsSatisfied = false;
  commentCharacterLimit = CareOrchestrationConstants.commentBoxCharacterLimit;
  showRemoveDialog = false;

  constructor(private patientService: PatientService, private authService: AuthService) { }

  ngOnInit(): void {
    this.reasons = Object.values(RemovedReasons).map(r => PatientAwvUtilities.removedReasonToString(r));
    this.getCurrentUserInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.patient) {
      this.clearFields();
    }
    if (this.removedOutcome){
      this.removeReason = PatientAwvUtilities.removedReasonToString(this.removedOutcome.properties.reason);
    }
  }

  copyComment(commentBox){
    commentBox.select();
    document.execCommand('copy');
    commentBox.setSelectionRange(0, 0);
  }

  fieldChanged() {
    this.checkAnyFieldHasValue();
    this.checkRequiredFieldsSatisfied();
  }

  private checkAnyFieldHasValue() {
    if (this.removeReason === '' && this.comment === '') {
      this.allFieldsEmpty = true;
      return;
    }

    this.allFieldsEmpty = false;
  }

  private checkRequiredFieldsSatisfied() {
    if (this.removeReason !== '' || (this.editModeActive && ('' !== this.comment))) {
      this.requiredFieldsSatisfied = true;
      return;
    }
    this.requiredFieldsSatisfied = false;
  }

  clearFields() {
    if (!this.editModeActive) {
      this.removeReason = '';
      this.comment = '';
      this.fieldChanged();
    }
    else{
      this.resetFields();
    }
  }
  private resetFields(){
    this.removeReason = PatientAwvUtilities.removedReasonToString(this.removedOutcome.properties.reason);
    this.comment = '';
    this.requiredFieldsSatisfied = false;
    this.allFieldsEmpty = true;
  }

  save() {
    const removedOutcome = {
      type: OutcomeType.RemovedOutcome,
      properties: {
        reason: PatientAwvUtilities.removeReasonToEnum(this.removeReason),
        comment: this.comment ? this.comment : null,
        type: PropertiesType.RemovedProperties
      } as unknown as RemovedProperties
    } as RemovedOutcome;
    if (removedOutcome != null) {
      this.outcomeSaved.emit(removedOutcome);
    }
    this.clearFields();
  }

  getCurrentUserInfo(): void {
    this.authService.getWellpepperUserInfo().then(user => this.user = user);
  }

  getProfessionalsInfo(proIds: string[]): void {
    proIds?.forEach(id => {
      if (!this.professionalsById[id]) {
        this.patientService.getProfessional(id).then(professional => {
          this.professionalsById[id] = professional;
        });
      }
    });
  }

  onDialogButtonClick(buttonValue: string): void {
    switch (buttonValue){
      case 'cancel':
        this.showRemoveDialog = !this.showRemoveDialog;
        break;
      case 'delete':
        this.deleteActivity.emit();
    }
  }

  showDialog(): void {
    this.showRemoveDialog = true;
  }

  stringifyForm(): string {
    if (!this.removeReason) {
      return '';
    }

    let value = 'Removed from AWV default list because: ' + this.removeReason;

    if (this.comment) {
      value += '. ' + this.comment;
    }

    return value;
  }
}


