import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { IRecentMatcher } from './recent-matcher';
import { TrackFeatureConstants } from '../module-constants';

export class PerformanceDashboardMatcher implements IRecentMatcher {
    match(url: string): boolean {
        return url.includes('track/performance');
    }

    createLog(url: string): RecentLogData {
        return {
            url,
            elements: [],
        };
    }

    title(url: string): string {
        return TrackFeatureConstants.PerformanceDashboardName;
    }
}
