<ng-container *ngIf="patient">
  <div *ngFor="let activity of patient.activities; index as i" class="list-item row--verticalCenter">
    <div>
      <coach-icon iconName="clock" iconColor="currentColor" iconSize="large"></coach-icon>
    </div>
    <div class="list-item-main">
      <div class="row">
        <div class="field">{{activity.date|date :"MMM dd, yyyy hh:mm"}}</div>
        <div class="field">{{activity.title}}</div>
      </div>
      <div *ngFor="let detail of activity.detail; index as j" class="row">
        <div class="field">{{detail.label}}</div>
        <div class="field">{{detail.content}}</div>
      </div>
    </div>
  </div>
</ng-container>