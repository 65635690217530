import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ToolTipMeasureDetails } from '@shared/models/forms/tool-tip-measure-details';
import { FormQuestionConstants } from '@shared/models/forms/form-question-constants';
import { NumDenPtsQuestion, ExclusionRequestDetails } from '@shared/models/forms/forms-frontend';
@Component({
  selector: 'coach-submit-interoperability-question',
  templateUrl: './submit-interoperability-question.component.html',
  styleUrls: ['./submit-interoperability-question.component.scss'],
})
export class SubmitInteroperabilityQuestionComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() questionInfo: NumDenPtsQuestion;

  _maxPoints: number;
  private hasInitialized = false;
  @Input() set maxPoints(value: number) {
    this._maxPoints = value;

    if (this.hasInitialized) {
      this.updatePoints();
    }
  }
  get maxPoints(): number {
    return this._maxPoints;
  }

  @Output()
  showHelpText = new EventEmitter<ToolTipMeasureDetails>();

  @Output() checkBoxChanged = new EventEmitter<ExclusionRequestDetails>();

  public get isDisabled(): boolean {
    return (
      this.parentForm.get(`${this.questionInfo.name}.isDisabled`)?.value ===
      true
    );
  }

  get checkedControl(): AbstractControl {
    return this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.UserResponse`
    );
  }

  public errors = [];


  public errTexts = {
    NUM :'Numerator cannot be negative',
    DEN : 'Denominator cannot be less than ',
    NUMDEN : 'Denominator cannot be less than numerator',
    EMPTY : 'Field(s) cannot be empty'}

  public percentage: number;
  public percentRounded: number;
  public showExclusionModal = false;

  private readonly exclusionTypeId = 3;
  private readonly responseTypeId = 4;

  ngOnInit(): void {
    this.hasInitialized = true;
    this.updatePoints();
  }

  toggleInfoText(): void {
    let helpText = '';

    switch (this.questionInfo.name) {
      case FormQuestionConstants.Q9ePrescibe.name:
        helpText = FormQuestionConstants.Q9ePrescibe.helpText;
        break;
      case FormQuestionConstants.Q10SupportSend.name:
        helpText = FormQuestionConstants.Q10SupportSend.helpText;
        break;
      case FormQuestionConstants.Q11SupportReceive.name:
        helpText = FormQuestionConstants.Q11SupportReceive.helpText;
        break;
      case FormQuestionConstants.Q12ProvideAccess.name:
        helpText = FormQuestionConstants.Q12ProvideAccess.helpText;
        break;
      case FormQuestionConstants.Q14DrugMonitor.name:
        helpText = FormQuestionConstants.Q14DrugMonitor.helpText;
        break;
    }

    this.showHelpText.emit({
      detailsText: helpText,
      modalLinks: this.questionInfo.modalLinks,
    });
  }

  updatePoints(): void {
    if (this.parentForm == null) {
      return;
    }

    const pointsControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.ResponsePointValue`
    );
    const numerator = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.1.ResponsePointValue`
    ).value as number;
    const denominator = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.2.ResponsePointValue`
    ).value as number;
    const exclusion = this.parentForm.get(
      `${this.questionInfo.name}.exclusion`
    ).value;

      this.toggleErrorTexts(2);


    if (exclusion) {
      pointsControl.setValue(0);
    } else {
      if (this.isNumDenValid()) {
        this.percentage = numerator / denominator;
        const pointValue = Math.round(this.percentage * this.maxPoints);
        const pointValueTo1 = Math.ceil(this.percentage * this.maxPoints);

        if (pointValue === 0 && pointValueTo1 === 1) {
          pointsControl.setValue(pointValueTo1);
        } else {
          pointsControl.setValue(pointValue);
        }
        this.percentRounded = Math.round(this.percentage * 100);
      }
   }
  }


  public isNumDenValid(): boolean {
    const num = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.1.ResponsePointValue`
    ) as UntypedFormControl;
    const den = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.2.ResponsePointValue`
    ) as UntypedFormControl;
    if (num.valid && den.valid) {
      if (num.value >= 0 && den.value >= 0) {
        if (num.value <= den.value) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if ((num.dirty || num.touched) && (den.dirty || den.touched)) {
      if (num.value < 0 && den.value < 1) {
        return false;
      }
    } else if ((num.dirty || num.touched) && (den.pristine || den.untouched)) {
      if (num.value < 0) {
        return false;
      }
    } else if ((den.dirty || den.touched) && (num.pristine || num.untouched)) {
      if (den.value < 1) {
        return false;
      }
    } else {
      return false;
    }
  }

  public toggleErrorTexts(numDenIndex?: number): void {
    if (numDenIndex) {
      const num = this.parentForm.get(
        `${this.questionInfo.name}.FormQResponses.1.ResponsePointValue`
      ) as UntypedFormControl;
      const den = this.parentForm.get(
        `${this.questionInfo.name}.FormQResponses.2.ResponsePointValue`
      ) as UntypedFormControl;

      // Check if numerator OR denominator is empty
      const emptyExists = this.errors.some((e) => e === this.errTexts.EMPTY);
      if (
        (num.touched && num.value === null) ||
        (den.touched && den.value === null)
      ) {
        if (!emptyExists) {
          this.errors.push(this.errTexts.EMPTY);
        }
      } else {
        if (emptyExists) {
          const i = this.errors.indexOf(this.errTexts.EMPTY);
          this.errors.splice(i, 1);
        }
      }
      // Check if denominator is LESS THAN numerator
      const numDenExists = this.errors.some((e) => e === this.errTexts.NUMDEN);
      if (num.value >= 0 && den.value >= 0 && num.value > den.value) {
        if (!numDenExists) {
          this.errors.push(this.errTexts.NUMDEN);
        }
      } else {
        if (numDenExists) {
          const i = this.errors.indexOf(this.errTexts.NUMDEN);
          this.errors.splice(i, 1);
        }
      }
      // Check if numerator is LESS THAN 0
      const numExists = this.errors.some((e) => e === this.errTexts.NUM);
      if (num.value < 0 && num.value !== null) {
        if (!numExists) {
          this.errors.push(this.errTexts.NUM);
        }
      } else {
        if (numExists) {
          const i = this.errors.indexOf(this.errTexts.NUM);
          this.errors.splice(i, 1);
        }
      }
      // Check if denominator is LESS THAN minimum denominator
      const denExists = this.errors.some((e) => e === this.errTexts.DEN);
      if (den.value < this.questionInfo.minDenominator && den.value !== null) {
        //Check if both den and num are 0, if so allow it as an exception
        if (den.value == 0 && num.value == 0) {
          if (denExists) {
            const i = this.errors.indexOf(this.errTexts.DEN);
            this.errors.splice(i, 1);
          }
        }
        else {
          if (!denExists) {
            this.errors.push(this.errTexts.DEN);
          }
        }
      } else {
        if (denExists) {
          const i = this.errors.indexOf(this.errTexts.DEN);
          this.errors.splice(i, 1);
        }
      }
    }
  }

  numeratorChanged(): void {
    this.updatePoints();
    this.timeStamp(1);
    this.parentForm.get(`${this.questionInfo.name}.FormQResponses.1.ResponsePointValue`
    ).updateValueAndValidity();
  }

  denominatorChanged(): void {
    this.updatePoints();
    this.timeStamp(2);
    this.parentForm.get(`${this.questionInfo.name}.FormQResponses.1.ResponsePointValue`
      ).updateValueAndValidity();
  }

  exclusionChanged(): void {
    const question = this.parentForm.controls[this.questionInfo.name];
    const exclusionControl = question.get('exclusion');

    if (exclusionControl.value) {
      this.showExclusionModal = true;
    } else {
      this.setFormType(this.responseTypeId);
      this.updatePoints();
      this.enableDisableQuestions(question, 'enable');
      this.checkBoxChanged.emit({
        questionname: this.questionInfo.name,
        status: false,
      });
    }
  }

  confirmExclusion(): void {
    const question = this.parentForm.controls[this.questionInfo.name];

    const pointsControl = question.get('FormQResponses.0.ResponsePointValue');
    pointsControl.setValue(-1);
    this.setFormType(this.exclusionTypeId);
    this.showExclusionModal = false;
    this.enableDisableQuestions(question, 'disable');


    this.checkBoxChanged.emit({
      questionname: this.questionInfo.name,
      status: true,
    });
  }

  setFormType(type: number): void {
    const formTypeControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.FormResponseTypeID`
    );
    formTypeControl.setValue(type);
  }

  cancelExclusion(): void {
    const question = this.parentForm.controls[this.questionInfo.name];
    const exclusionControl = question.get('exclusion');
    exclusionControl.setValue(false);
    this.showExclusionModal = false;
  }

  enableDisableQuestions(
    question: AbstractControl,
    enableOrDisable: 'enable' | 'disable'
  ): void {
    if (enableOrDisable === 'enable') {
      question.get('FormQResponses').enable({ emitEvent: false });
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(null, { emitEvent: false });
      question
        .get('FormQResponses.1.ResponsePointValue')
        .reset(null, { emitEvent: false });
      question
        .get('FormQResponses.2.ResponsePointValue')
        .reset(null, { emitEvent: false });
    } else if (enableOrDisable === 'disable') {
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(0, { emitEvent: false });
      question
        .get('FormQResponses.1.ResponsePointValue')
        .reset(-1, { emitEvent: false });
      question
        .get('FormQResponses.2.ResponsePointValue')
        .reset(-1, { emitEvent: false });
    }
  }

  timeStamp(numDenIndex?: number): void {
    const newTimeStamp = new Date().toJSON();
    this.parentForm.patchValue(
      { LastUpdateDateTime: newTimeStamp },
      { emitEvent: false }
    );
    this.parentForm.controls[this.questionInfo.name].patchValue(
      { LastUpdateDateTime: newTimeStamp },
      { emitEvent: false }
    );
    if (numDenIndex) {
      this.parentForm.controls[this.questionInfo.name]
        .get('FormQResponses.0')
        .patchValue({ LastUpdateDateTime: newTimeStamp }, { emitEvent: false });
      this.parentForm.controls[this.questionInfo.name]
        .get('FormQResponses.' + numDenIndex.toString())
        .patchValue({ LastUpdateDateTime: newTimeStamp }, { emitEvent: false });
    }
  }
}
