import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { takeUntil } from 'rxjs/operators';

/**
 * Only renders element if the feature flag is enabled globally or for current tier. 
 * 
 * @example
 * <div *hasFeatureFlag="valueCalculatorFeatureFlag"></div>
 */
@Directive({
  selector: '[doesNotHaveFeatureFlag]'
})
export class DoesNotHaveFeatureFlagDirective extends KillSubscriptions implements OnInit {

  private doesNotHaveView = false;
  private featureFlag: string;

  constructor(protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected featureFlagService: FeatureFlagService) {
    super();
  }

  @Input()
  set doesNotHaveFeatureFlag(value: string) {
    this.featureFlag = value;
  }

  ngOnInit(): void {
    this.featureFlagService.featureFlags$.pipe(takeUntil(this.killTrigger)).subscribe(() => this.updateAccess());
  }

  updateAccess(): void {
    const hasFlag = this.featureFlagService.hasFeatureFlag(this.featureFlag);
    if (hasFlag && this.doesNotHaveView) {
      this.viewContainer.clear();
      this.doesNotHaveView = true;
    } else if (!hasFlag && !this.doesNotHaveView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.doesNotHaveView = true;
    }
  }

}
