<div [ngClass]="{'widget margin--large u-height--fit-content': !sidebarMode }">
    <div class="header" *ngIf="!sidebarMode">
        <div class="titles">
            <div class="title">
                <span class="margin-right--small">In-Home Annual Wellness Visit</span>
                <span *ngIf="patient && !isTabOpen && inHomeVisitDate" class="u-font-size--small u-font-weight--smaller">{{inHomeVisitDate.toLocaleDateString()}}</span>
            </div>
        </div>
        <coach-icon class="u-cursor--pointer" iconSize="large" [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" (click)="isTabOpen = !isTabOpen"></coach-icon>
    </div>
    <div>
        <div  *ngIf="patient && isTabOpen"  class="u-display--flex u-flex-direction--row ihe-content-wrapper" [ngClass]="{'u-flex-wrap--wrap padding--small': mobileScreenSize, 'padding--large': !mobileScreenSize}">
            <div class="u-display--flex u-flex-direction--column margin-bottom--large padding-leftRightTopBottom--small" [ngClass]="{'u-width--webkit-fill-available': mobileScreenSize, 'span--6': !mobileScreenSize}">
                <span class="visitDateText">In-Home Annual Wellness Visit on</span>
                <span class="visitDate" *ngIf="inHomeVisitDate"> {{inHomeVisitDate.toLocaleDateString()}}</span>

                <div class="margin-topBottom--large" *ngIf="files.length > 0">
                    <span class="subtitle padding--small">FILES</span>
                    <ng-container *ngFor="let file of filesByTypeName | keyvalue; index as i">
                        <div class="u-display--flex u-flex-direction--row">
                            <coach-icon class="margin--small u-cursor--pointer" iconSize="large" *ngIf="file.value.fileDownloadStatus === 'New'" iconColor="{{file.key !== 'Casemgmt' ? 'tile' : 'yellow'}}"  iconName="arrow-down-circle-filled" (click)="downloadFile(file.value)"></coach-icon>
                            <div class="loader margin--small" style="width: 32px; height: 32px" *ngIf="file.value.fileDownloadStatus === 'Downloading'"></div>

                            <coach-icon class="margin--small u-cursor--pointer" iconSize="large" iconColor="{{file.key !== 'Casemgmt' ? 'tile' : 'yellow'}}" *ngIf="file.value.fileDownloadStatus === 'Downloaded'" iconName="check-circle-2" (click)="downloadFile(file.value)"></coach-icon>
                            <div class="margin--small margin-left--none">
                                <div class="fileName u-color--brand--1">{{patientIHEFileTypeLabels[file.key]}}</div>
                                <div class="u-display--flex u-flex-direction--row" *ngIf="file.downloadDetails">
                                    <coach-icon class="margin-right--smallest" iconSize="small" iconName="download"></coach-icon>
                                    <span class="subtitle">{{getFormattedDownloadDetails(file)}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="areAllFilesAvailable" class="padding-bottom--large padding-leftRight--small u-display--flex u-flex-direction--row u-justify-content--spaceBetween">
                    <button class="padding--small button" [disabled]="downloading" (click)="downloadAllFiles()">
                        <coach-icon class="margin-right--small" iconColor="currentColor" iconName="download"></coach-icon>
                        {{ downloading ? 'Downloading...' : 'Download All' }}
                    </button>
                    <div class="u-text-align--right">
                        <p class="subtitle margin--none">Download Status</p>
                        <p class="u-bold">
                            {{getDownloadStatus()}}
                        </p>
                    </div>
                </div>
                <div>
                    <textarea class="resize--none" [(ngModel)]="note" placeholder="Add a comment here..." id="ihe_comment" rows="6"
                        maxlength="5000"></textarea>
                </div>
                <div *ngIf="patient && isTabOpen " class="padding-topBottom u-display--flex u-justify-content--spaceBetween">
                    <button class="button is-primary is-small" (click)="postNote()" [disabled]="!note.length">Save</button>
                    <button class="button is-primary is-small is-secondary" [disabled]="!note.length" (click)="note = ''">Cancel</button>
                </div>
            </div>
            <div *ngIf="getLiveUpdatedHistoryList().length > 0" class="u-display--flex u-flex-direction--column history-wrapper span--6" [ngClass]="{'u-width--webkit-fill-available': mobileScreenSize }">
                <div class="list u-width--full u-display--flex u-flex-direction--column">
                    <span class="subtitle padding--small padding-bottom--small">HISTORY</span>
                    <ng-container *ngFor="let log of getLiveUpdatedHistoryList(); index as idx">
                        <div class="list-item--complex list-item--singleHitArea">
                            <div class="list-item-avi">
                                <div class="avi">
                                    <img *ngIf="proAvatar(log.createdBy)" [src]="proAvatar(log.createdBy)" class="img--circle">
                                    <div class="nameAvatar" *ngIf="log.createdBy && !(proAvatar(log.createdBy))">
                                        {{ log.createdBy | initialsFromName }}
                                    </div>
                                </div>
                            </div>
                            <div class="list-item-main u-justify-content--center">
                                <div class="row u-display--flex u-justify-content--spaceBetween">
                                    <div class="field u-bold">
                                        <span *ngIf="log.createdBy">{{log.createdBy}}</span>
                                        <span *ngIf="!log.createdBy">(name not found)</span>
                                    </div>
                                    <div class="row--verticalCenter margin-right--smallest u-font-size--smallest text-right">
                                        {{log.createdAt | date: "medium"}}
                                    </div>
                                </div>
                                <div class="u-display--flex u-flex-direction--row u-color--brand--1 u-font-size--smallest">
                                    <div class="u-display--flex u-flex-direction--row u-align-items--top">
                                        <div class="faIcon-wrapper padding--smallest">
                                            <fa-icon *ngIf="log.faIcon" class="fa-lg " [icon]="log.faIcon"></fa-icon>
                                        </div>
                                        <span class="subtitle u-text-white-space--break-spaces" *ngIf="log.noteText">{{log.noteText}}</span>
                                        <span class="subtitle u-text-white-space--break-spaces" *ngIf="log.fileType">Downloaded {{log.fileType}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
