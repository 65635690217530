<ng-container *ngIf="isAddEditFormActive; else qualityDashboard">
  <form [formGroup]="qualityForm" (ngSubmit)="onSubmit(true)" class="page-content">
    <div class="page-content-main">
      <div class="dynamicBody row">
        <!-- PANEL START -->
        <div class="queue u-position--relative animation--content--fadeIn"
          [ngClass]="isFormQueueOpen ? 'is-open border-right' : 'is-closed'">
          <button class="pullTab top--xs" (click)="toggleFormQueueOpen()" [ngClass]="{ 'is-open': isFormQueueOpen }">
            <coach-icon iconName="chevrons-right"></coach-icon>
          </button>
          <div class="header">
            <div class="titles t3">
              <div class="title">{{copy.title}}</div>
              <div class="subtitle row--baseline">
                <span class="u-fill u-bold">{{copy.submissionPeriod}}</span>
                <span class="label margin-right--smaller">Due</span>
                <span>{{dueDate | date:'MMM dd'}}</span>
              </div>
            </div>
          </div>
          <div class="dynamicBody padding margins-bottom--large">
            <div class="alert is-info padding--small u-font-size--small">
              Fill in the following required fields.
            </div>
            <div class="label margin-bottom--smallest">
              Practice(s)
              <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                iconColor="primary" (click)="toggleInfoText('tooltipPracticeText')"></coach-icon>
            </div>

            <form [formGroup]="practicesForm" class="clump padding--small" [ngClass]="{
                'is-invalid':
                  qualityForm.get('Tier4_Name').invalid &&
                  (qualityForm.get('Tier4_Name').dirty ||
                    qualityForm.get('Tier4_Name').touched)
              }">
              <ng-container formArrayName="practices">
                <div *ngFor="
                    let practice of practicesFormArray.controls;
                    let i = index
                  ">
                  <input type="checkbox" id="practice_{{ practiceList[i].Fac_ID }}" [formControlName]="i" />
                  <label for="practice_{{ practiceList[i].Fac_ID }}">
                    <span class="u-bold u-fill">{{
                      practiceList[i].Name
                      }}</span>
                  </label>
                </div>

                <div *ngIf="practicesFormArray.length === 0">
                  <div class="u-italic">No practices available</div>
                </div>
                <div *ngIf="
                    qualityForm.get('Tier4_Name').invalid &&
                    (qualityForm.get('Tier4_Name').dirty ||
                      qualityForm.get('Tier4_Name').touched)
                  ">
                  <div class="u-italic u-color--danger padding-top--small">
                    At least one practice is required
                  </div>
                </div>
              </ng-container>
            </form>

            <form [formGroup]="exemptForm" class="row--right row--verticalCenter" *ngIf="shwSpecialStatus">
              <coach-icon class="icon--selectable margin-right--smaller" iconName="info" iconSize="small"
                iconColor="primary" (click)="toggleInfoText('tooltipExemptText')"></coach-icon>
              <input class="toggle" (click)="updateOnExempt()" formControlName="isExempt" id="togglea"
                type="checkbox" />
              <label for="togglea">Special Status Exemption</label>
            </form>

            <div class="group" *ngIf="showSpecialStatus">
              <label class="label margin-bottom--smaller" for="specialStatus">
                Exemption Special Status
                <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                  iconColor="primary" (click)="toggleInfoText('tooltipSpecialStatusText')"></coach-icon>
              </label>
              <select class="field" formControlName="SpecialStatus" name="specialStatus" id="specialStatus">
                <option [value]="null" disabled>Select Special Status</option>
                <option *ngFor="let specialStatus of specialStatusList" [value]="specialStatus.specialStatusID">
                  {{ specialStatus.specialStatus }}
                </option>
              </select>
              <div *ngIf="
                  qualityForm.get('SpecialStatus').invalid &&
                  (qualityForm.get('SpecialStatus').dirty ||
                    qualityForm.get('SpecialStatus').touched)
                ">
                <div class="u-italic u-color--danger padding-top--small">
                  Exemption Special Status required
                </div>
              </div>
            </div>
            <div class="group">
              <label class="label margin-bottom--smaller" for="ehrvendor">
                EHR Vendor
                <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                  iconColor="primary" (click)="toggleInfoText('tooltipEHRText')"></coach-icon>
              </label>
              <select class="field" formControlName="EHRVendor" name="ehrvendor" id="ehrvendor">
                <option [value]="null" disabled>Select EHR Vendor</option>
                <option *ngFor="let vendor of ehrVendorList" [value]="vendor">
                  {{ vendor }}
                </option>
              </select>
              <div *ngIf="
                  qualityForm.get('EHRVendor').invalid &&
                  (qualityForm.get('EHRVendor').dirty ||
                    qualityForm.get('EHRVendor').touched)
                ">
                <div class="u-italic u-color--danger padding-top--small">
                  EHR Vendor required
                </div>
              </div>
            </div>
            <ng-container *ngIf="!areQuestionsHidden">
              <div class="group" formGroupName="FormQ67DataSource">
                <ng-container formArrayName="FormQResponses">
                  <ng-container [formGroupName]="0">
                    <label class="label margin-bottom--smaller" for="dataSource">
                      Data Source
                      <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                        iconColor="primary" (click)="toggleInfoText('tooltipDataSourceText')"></coach-icon>
                    </label>
                    <select class="field" formControlName="UserResponse" name="dataSource" id="dataSource" [ngClass]="{
                        'u-cursor--not-allowed':
                          exemptForm.get('isExempt').value &&
                          qualityForm.controls['SpecialStatus'].value == 26
                      }">
                      <option [value]="null" disabled>
                        Select Data Source
                      </option>
                      <option *ngFor="let source of dataSourceList" [value]="source">
                        {{ source }}
                      </option>
                    </select>
                    <div *ngIf="
                        qualityForm.get(
                          'FormQ67DataSource.FormQResponses.0.UserResponse'
                        ).invalid &&
                        (qualityForm.get(
                          'FormQ67DataSource.FormQResponses.0.UserResponse'
                        ).dirty ||
                          qualityForm.get(
                            'FormQ67DataSource.FormQResponses.0.UserResponse'
                          ).touched)
                      ">
                      <div class="u-italic u-color--danger padding-top--small">
                        Data Source required
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="group" formGroupName="FormQ68DataType">
                <ng-container formArrayName="FormQResponses">
                  <ng-container [formGroupName]="0">
                    <label class="label margin-bottom--smaller" for="dataType">
                      Patient Population
                      <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                        iconColor="primary" (click)="toggleInfoText('tooltipPatientPopText')"></coach-icon>
                    </label>
                    <select class="field" formControlName="UserResponse" name="dataType" id="dataType" [ngClass]="{
                        'u-cursor--not-allowed':
                          exemptForm.get('isExempt').value &&
                          qualityForm.controls['SpecialStatus'].value == 26
                      }">
                      <option [value]="null" disabled>Select Data Type</option>
                      <option *ngFor="let type of dataTypeList" [value]="type">
                        {{ type }}
                      </option>
                    </select>
                    <div *ngIf="
                        qualityForm.get(
                          'FormQ68DataType.FormQResponses.0.UserResponse'
                        ).invalid &&
                        (qualityForm.get(
                          'FormQ68DataType.FormQResponses.0.UserResponse'
                        ).dirty ||
                          qualityForm.get(
                            'FormQ68DataType.FormQResponses.0.UserResponse'
                          ).touched)
                      ">
                      <div class="u-italic u-color--danger padding-top--small">
                        Patient Population required
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <form [formGroup]="uploadForm" class="group">
                <div class="label margin-bottom--smaller">
                  Upload Quality Measures Report
                  <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                    iconColor="primary" (click)="toggleInfoText('tooltipUploadQualityMeasureReportText')"></coach-icon>
                </div>
                <div class="field">
                  <div class="fileUploadWrapper clump padding--small u-width--full row--verticalCenter" [ngClass]="{
                      'is-invalid': uploadForm.invalid && uploadForm.touched
                    }">
                    <div class="inputButtonCombo u-preserve margin-right--small">
                      <input type="file" (change)="onFileChange($event)" />
                      <button class="button is-small" [ngClass]="{
                          'is-primary': uploadedFileName === 'No file chosen'
                        }">
                        {{
                        uploadedFileName === "No file chosen"
                        ? "Upload"
                        : "Change"
                        }}
                        File
                      </button>
                    </div>
                    <div>{{ uploadedFileName }}</div>
                  </div>
                  <div *ngIf="uploadForm.invalid && uploadForm.touched">
                    <div class="u-italic u-color--danger padding-top--small">
                      Must upload file
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
          </div>
        </div>
        <!-- PANEL END -->
        <!-- MAIN START -->
        <div class="dynamicBody" [ngClass]="{ column: areQuestionsHidden }">
          <div class="submitForms u-background-color--white" *ngIf="!areQuestionsHidden">
            <ng-container *ngFor="let question of qualityFormQuestions">
              <coach-submission-form-block [qualityForm]="qualityForm" [formQuestion]="question"
                [questionsDisabled]="areQuestionsHidden"></coach-submission-form-block>
            </ng-container>
          </div>
          <div *ngIf="areQuestionsHidden" class="u-fill row u-center u-italic u-opacity--lighter">
            You are exempt from submitting quality data at this time due to the
            special status selected.
          </div>
        </div>
        <!-- MAIN END -->
      </div>
      <!-- FOOTER START -->
      <div class="footer margins-left--small">
        <div class="u-fill row u-center">
          <div class="column u-align-items--center u-flex--right">
            <div class="margin-bottom--smaller u-opacity--lighter u-italic">
              <b>{{ totalPointsRounded || 0 }}</b>{{totalPointsRoundedText}}
            </div>
            <div class="pointsBar" style="--points-bar--percentage: {{
                (totalPointsRounded / maxPoints) * 100
              }}%">
              <div class="bar"></div>
            </div>
          </div>
        </div>
        <button class="button is-unstyled" [disabled]="formSaving" (click)="cancel()">
          Cancel
        </button>
        <button class="button" type="button" (click)="onSubmit(false)">
          Save as Draft
        </button>
        <button type="submit" [disabled]="formSaving" class="button is-primary"
          [ngClass]="{ 'faux-disabled': qf.invalid || uploadForm.invalid }">
          Submit
        </button>
      </div>
      <!-- FOOTER END -->
      <div class="overlay overlay--light is-open" *ngIf="isInfoTextOpen">
        <div class="modal margin u-max-width--larger" (click)="$event.stopPropagation()">
          <div class="dynamicBody padding--large" [innerHtml]="infoText"></div>
          <div class="footer">
            <button class="button is-primary" (click)="toggleInfoText(); $event.stopPropagation()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #qualityDashboard>
  <div class="page-content">
    <div class="queue u-position--relative animation--content--fadeIn"
      [ngClass]="isQueueOpen ? 'is-open border-right' : 'is-closed'">
      <button class="pullTab top--xs" (click)="toggleQueueOpen()" [ngClass]="{ 'is-open': isQueueOpen }"
        aria-label="Toggle Practice Improvement List Panel">
        <coach-icon iconName="chevrons-right"></coach-icon>
      </button>
      <div class="header">
        <div class="titles t3">
          <div class="title">{{copy.overviewTitle}}</div>
          <div class="subtitle row--baseline">
            <span class="u-fill u-bold">{{copy.submissionPeriod}}</span>
            <span class="label margin-right--smaller">Due</span>
            <span>{{dueDate | date:'MMM dd'}}</span>
          </div>
        </div>
      </div>
      <div class="dynamicBody padding">
        <h4 class="margin-top">Welcome!</h4>
        <p><span class="u-bolder">{{copy.requirements}}</span></p>
        <p>
          {{copy.instructionsPart1}}<span class="u-bolder">{{copy.submissionPeriod}}</span>{{copy.instructionsPart2}}
        </p>
        <p class="u-bolder">{{ copy.dueDate3rdParagraph }}</p>
        <div class="alert is-info margin-topBottom--larger padding--small">
          <span class="u-bolder">{{copy.noteTextPart1}}</span>{{copy.noteTextPart2}}
          <span class="u-bolder">{{copy.noteTextPart3}}</span>{{copy.noteTextPart4}}
        </div>
      </div>
      <div class="border-top--5 padding u-font-size--smallest u-opacity--light">
        {{copy.disclaimer}}
      </div>
    </div>
    <div class="page-content-main">
      <div class="dynamicBody padding--larger padding-top--largest u-background-color--white">
        <div class="row--wrap row--baseline"
        *ngIf="caravanFormSubmissionPeriod.length >= 2">
          <h3
            class="margin-top--none margin-right--large margin-bottom--large bean u-background-color--view u-color--white u-preserve">
            Step {{stepDataSource}}
          </h3>
          <div class="submit-stepHeader column">
            <h3 class="margin-top--none margin-bottom--small">
              Select Submission Type
            </h3>
            <p class="u-opacity--light">
              {{copy.instructionDataSrc}}
            </p>
            <select class="field u-color--interaction u-width--larger" name="submissionPeriod" id="subPeriod"  [(ngModel)]="selectedFormSubPeriod" (change)="selectSubmissionPeriod($event)">
              <option *ngFor="let period of caravanFormSubmissionPeriod" [value]="period.caravanFormSubmitPeriodID">
                {{ period.openingInfo }}
              </option>
            </select>
          </div>
        </div>

        <div class="row--wrap row--baseline"  *ngIf="shwSpecialStatus">
          <h3
            class="margin-top--none margin-right--large margin-bottom--large bean u-background-color--view u-color--white u-preserve">
            Step {{stepSpecialStatus}}
          </h3>
          <div class="submit-stepHeader column">
            <h3 class="margin-top--none margin-bottom--small">
              Review Special Status Exemptions
            </h3>
            <p class="u-opacity--light">
              {{copy.stepSpecialStatus1Text}}
            </p>
            <p class="u-opacity--light">
              {{copy.stepSpecialStatus2Text}}
            </p>
          </div>
        </div>

        <table class="table--tight margin-topBottom--larger u-width--full" *ngIf="shwSpecialStatus">
          <thead>
            <tr>
              <th class="border-right--none">
                <h5 class="margin--none">Practice Name</h5>
              </th>
              <th class="border-right--none">
                <h5 class="margin--none">Exemption Status</h5>
              </th>
              <th class="border-right--none">
                <h5 class="margin--none">Remove</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="
                specialStatusSummary == null ||
                specialStatusSummary.length === 0
              ">
              <td colspan="3">
                <span class="u-opacity--lighter u-italic u-center u-display--flex">No Exemptions</span>
              </td>
            </tr>
            <tr *ngFor="let statusSummary of specialStatusSummary">
              <td>{{ statusSummary.Practice }}</td>
              <td class="u-display--flex">
                <select class="border--none u-color--interaction" name="exemptstatus"
                  [(ngModel)]="statusSummary.SpecialStatusID" (ngModelChange)="updateSpecialStatus(statusSummary)">
                  <option *ngFor="let specialStatus of specialStatusList" [ngValue]="specialStatus.specialStatusID">
                    {{ specialStatus.specialStatus }}
                  </option>
                </select>
                <input placeholder="Other Reason" class="margin-left"
                  *ngIf="statusSummary.SpecialStatusID === OtherSpecialStatusId" [(ngModel)]="statusSummary.OtherReason"
                  (ngModelChange)="updateSpecialStatus(statusSummary)" />
              </td>
              <td>
                <a [routerLink]="" (click)="removeConfirmation(statusSummary)">Remove</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row--wrap row--baseline padding-top--largest">
          <h3
            class="margin-top--none margin-right--large margin-bottom--large bean u-background-color--view u-color--white u-preserve">
            Step {{stepAddEdit}}
          </h3>
          <div class="submit-stepHeader column">
            <h3 class="margin-top--none margin-bottom--small">
              Add/Edit Forms
            </h3>
            <p class="u-opacity--light">
              {{copy.stepAddEditText}}
              <b>{{dueDate | date:'MMMM dd, yyyy'}}</b>.
            </p>
          </div>
        </div>

        <div class="row margin-topBottom--larger padding-topBottom padding-right u-overflow-x--auto">
          <table class="table--tight u-width--full">
            <thead>
              <tr>
                <th class="border-right--none">
                  <h5 class="margin--none">Name</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Total Points</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Submitter</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Date/Time</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Status</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Edit</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="formSubmissions == null || formSubmissions.length === 0">
                <td colspan="6">
                  <span class="u-opacity--lighter u-italic u-center u-display--flex">No Submissions</span>
                </td>
              </tr>
              <tr *ngFor="let submission of formSubmissions">
                <td>
                  {{ submission.Practice }}
                  <div *ngIf="submission.SpecialStatus != null" class="u-color--danger">
                    <b>Special Status</b>: {{ submission.SpecialStatus }}
                  </div>
                </td>
                <td>{{ submission.TotalPoints | number: "1.2-2" }}</td>
                <td>{{ submission.SubmitterEmail }}</td>
                <td>{{ submission.LastUpdateDateTime | date: "short" }}</td>
                <td>{{ submission.ISComplete ? "Complete" : "Pending" }}</td>
                <td>
                  <a [routerLink]="" (click)="editForm(submission)">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="footer">
        <div class="form-reportwrapper" *hasFeatureFlag="formsAdminFormFeatureFlag" >
          <button class="button is-primary" *hasFeature="formsAdminFormFeature"  (click)="formsReport()">Submission Status Report</button>
        </div>

        <button class="button is-primary" (click)="newSubmission()">+ Quality Form</button>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="showRemoveModal" class="overlay overlay--light is-open">
  <div class="modal">
    <div class="header">
      <div class="titles">
        <div class="title">Remove Special Status Exemption</div>
      </div>
    </div>
    <div class="dynamicBody padding">
      Are you sure you would like to remove this Special Status Exemption
      submission?
    </div>
    <div class="footer">
      <button class="button margin-right border--none" (click)="showRemoveModal = false">
        No
      </button>
      <button class="button is-primary" (click)="removeSubmission()">
        Yes, Remove
      </button>
    </div>
  </div>
</div>
