export interface IPrescription {
  claimPk: string,
  claimId: number;
  patientName?: string;
  acoId: string;
  tier2_id: string;
  tier2_Name: string;
  tier3_id: string;
  tier4_grpId: string;
  ccn: string;
  dob?: string;
  mbi?: string;
  firstFilled?: string;
  fillDate?: string;
  prescriptionName?: string;
  prescriberName?: string;
  prescriberCredentials?: string;
  prescriberSpecialty?: string;
  prescriberPhone?: string;
  location?: string;
  status?: number;
  ndc?: string;
  rxNumber?: number;
  enrollmentStatus: string;
  patientSex: string;
  primaryProviderName: string;
  primaryProviderCredentials: string;
  pharmacyName: string;
  pharmacyNameOther: string;
  pharmacyAddress: string;
  pharmacyAddressOther: string;
  chPatId: number;
  clPrescriptionFillNumber: string;
  npi: string;
  servicePharmacyNpi: string;
  createdatetime: string;
  gender: string;
  prescriberFax: string;
  prescriptionRefID: string;
  daysSupplyQty: number,
  serviceUnitQty: number,
  priceRetail: number,
  awpPrice: number,
  claimType: string,
  drugManufacturer: string,
  tpaName: string,
  tpaPrice?: number,
}

export interface IApprovedPrescription {
  claimPk: string,
  claimId: number;
  claimType: string;
  tier2_Name?: string;
  patientName?: string;
  acoId: string;
  tier2_id: string;
  tier3_id: string;
  tier4_grpId: string;
  dob?: string;
  mbi?: string;
  firstFilled?: string;
  fillDate?: string;
  prescriptionName?: string;
  prescriberName?: string;
  prescriberCredentials?: string;
  prescriberSpecialty?: string;
  prescriberPhone?: string;
  location?: string;
  status?: number;
  ndc?: string;
  rxNumber?: number;
  npi: string;
  orgName: string;
  pharmacy_Address: string;
  pharmacyName: string;
  pharmacyNameOther: string;
  sex: string;
  patientZip: string;
  prescriptionQuantity: number
  clPrescriptionFillNumber: string;
  daysSupply: number;
  prescriberDEA: string;
  prescriberNPI: string;
  medicationDescription: string;
  gender: string;
  pharmacyNpi: string;
  pharmacyOtherAddress: string;
  pharmacyOtherCity: string;
  pharmacyOtherPhone: string;
  pharmacyOtherState: string;
  pharmacyOtherZip: string;
  prescriptionRefID: string;
  servicePharmacyNpi?: string;
  pharmacyAddress?: string;
  pharmacyAddressOther?: string;
  priceRetail: number;
  awpPrice: number;
  drugManufacturer: string;
  tpaName: string;
  tpaPrice: number;
}

export interface IPrescriptionInvoice {
  userId?: number;
  contractPharmacy?: string;
  tpaName?: string;
  claimType?: string;
}

export interface IContractInfo {
  priceTypeId: number;
  caravanPct: number;
}

export interface IPrescriber {
  npi: string;
  name: string;
}

export interface IPharmacyNamesOrig {
  pharmacy_Org_Name: string;
  pharmacy_Other_Org_Name: string;
}

export interface IPharmacyNames {
  firstName: string;
  secondName: string;
}

export interface IPrescriberOrig {
  prescribing_Provider_NPI: string;
  prescribing_Provider_Name: string;
}

export interface ICCNLocation {
  display: string;
}

export interface IClaimException {
  claimDate: Date;
  claimIdSource: string;
  clientIdSource: string;
  clientName: string;
  clientType: string;
  createDate: Date;
  dispenseDate: Date;
  fillDate: Date,
  exceptionId: number;
  groupCat: number;
  hashValue: string;
  insureId: string;
  insuredDob: Date;
  insuredFirstName: string;
  insuredLastName: string;
  isDeleted: boolean;
  isMovedToClaim: boolean;
  medDaysSupply: number;
  medDesc: string;
  medNdc: string;
  medPkgQty: number;
  medQty: number;
  medUnitsPerPkg: number;
  patientDob: Date;
  patientFirstName: string;
  patientGender: number;
  patientLastName: string;
  patientMrn: string;
  pharmacyIdSource: string;
  pharmacyNCPDP: number;
  pharmacyName: string;
  pharmacyNpi: string;
  prescriberDea: string;
  prescriberFirstName: string;
  prescriberLastName: string;
  prescriberNpi: string;
  processedDate: Date;
  profitLossAmount: number;
  recordNumber: number;
  rxFillNum: number;
  rxNum: string;
  rxWrittenDate: Date;
  rxWrittenLocation: string;
  serviceDate: Date;
  totalCollected: number;
  tpaClaimFileId: number;
  unitPrice: number;
  visitDate: Date;
  wacPkgPrice: number;
  wacUnitPrice: number;
  prescriptionRefId: string;
}

export interface IPrescriptionDetail {
  acoId: string;
  tier2_id: string;
  tier3_id: string;
  tier4_grpId: string;
  claimPk: string;

  cuR_CLM_UNIQ_ID: number;

  acO_ID: string;
  coach340BClaimsID: string;
  tier2_ID: string;
  tier3_ID: string;
  tier4_GrpID: string;

  visit_Note_Visit_Date: string;
  visit_Note_Missing: number;
  visit_Note_Diagnosis: string;
  visit_Note_Requested_Date: string;
  cE_Visit_Date: string;
  cE_Visit_Note_Missing: number;
  cE_Provider: string;
  cE_Location: string;
  cE_Related_Referral_Date: string;
  cE_Related_Referral_Missing: number;
  follow_Up_Plan: string;
  claim_Note: string;
  claim_Status: number;
  claim_Status_Set_By: string;
  claim_Status_Set_On_Date: string;

  // sysStartTime: string;
  // sysEndTime: string;
}

export interface ICareCoordinationNote {
  cuR_CLM_UNIQ_ID: number;
  tier_Num: number;
  tier_Id: string;
  creatorName: string;
  creationDate: string;
  prescriptionName: string;
  prescriptionFilledBy: string;
  prescriptionFilledDate: string;
  provider: string;
  providerSpecialty: string;
  providerVisitDate: string;
  rxDiagnosis: string;
  relatedDocumentationPresent: number;
  referralDocumentationPresent: number;
  followUpPlanWithCE: string;
  followUpPlanWithProvider: string;
  // sysStartTime: string;
  // sysEndTime: string;
}

export interface IPrescriptionOrig {
  acO_ID: string,
  acO_Name: string,
  tier4_ID_CCN: string,
  available340B: number,
  benE_FullName: string,
  bene_dob: string,
  chPatID: number,
  clPrescriptionFillNumber: string,
  clPrescriptionServiceReference: number,
  comment: string,
  cuR_CLM_UNIQ_ID: number,
  current_MBI: string,
  date_Initial_Fill: string,
  enrollment_Status: string,
  group_Cat: number,
  lastMBIUpdate: string,
  location_Restrictions: string,
  mbi: string,
  medication_Broad: string,
  medication_Description: string,
  ndc: string,
  orphan_Drug: number,
  pharmacy_Address: string,
  pharmacy_Org_Name: string,
  pharmacy_Other_Org_name: string,
  ppBusinessPracticeLocation: string,
  prescribing_Provider_NPI: string,
  prescribing_Provider_Name: string,
  prescribing_Provider_Credentials: string,
  prescribing_Provider_Specialty: string,
  prescribing_Provider_Phone: string,
  prescriptionFilledDate: string,
  price_Retail: number,
  priorEMVisit: number,
  providerInCommunity: number,
  providerTINasCE: number,
  service_Pharmacy_NPI: string,
  tier2_ID: string,
  tier2_Name: string;
  tier3_ID: string,
  tier4_ID: string,
  claim_Status: number,
  benE_SEX_CD: string,
  primary_Provider_Name: string,
  primary_Provider_Credentials: string,
  benE_ZIP_CD: string,
  clM_LINE_SRVC_UNIT_QTY: number,
  clM_LINE_DAYS_SUPLY_QTY: number,
  prescribing_Provider_DEA_Number: string,
  coach340BClaimsID: string,
  createdatetime: string;
  gender: string;
  prescriberFax: string;
  pharmacyOtherAddress: string;
  pharmacyOtherCity: string;
  pharmacyOtherPhone: string;
  pharmacyOtherState: string;
  pharmacyOtherZip: string;
  prescriptionRefID: string;
  claimType: string;
  drugManufacturer: string;
  tpaName: string;
  tpaPrice: number;
}

export enum PrescriptionStatus {
  Approve = 0,
  Pending = 1,
  Reject = 2
}

export function convertOrig2Columns (prescription: IPrescriptionOrig):IPrescription {
  if (!prescription) {
    return null;
  }
  const result = {
    claimPk: prescription.coach340BClaimsID,
    claimId: prescription.cuR_CLM_UNIQ_ID,
    patientName: prescription.benE_FullName,
    acoId: prescription.acO_ID,
    tier2_id: prescription.tier2_ID,
    tier2_Name: prescription.tier2_Name,
    tier3_id: prescription.tier3_ID,
    tier4_grpId: prescription.tier4_ID,
    dob: prescription.bene_dob,
    mbi: prescription.mbi,
    firstFilled: prescription.date_Initial_Fill,
    fillDate: prescription.prescriptionFilledDate,
    prescriptionName: prescription.medication_Description,
    prescriberName: prescription.prescribing_Provider_Name,
    prescriberCredentials: prescription.prescribing_Provider_Credentials,
    prescriberSpecialty: prescription.prescribing_Provider_Specialty,
    prescriberPhone: prescription.prescribing_Provider_Phone,
    location: prescription.ppBusinessPracticeLocation,
    enrollmentStatus: prescription.enrollment_Status,
    ndc: prescription.ndc,
    rxNumber: prescription.clPrescriptionServiceReference,
    status: prescription.claim_Status,
    patientSex: prescription.benE_SEX_CD,
    primaryProviderName: prescription.primary_Provider_Name,
    primaryProviderCredentials: prescription.primary_Provider_Credentials,
    pharmacyName: prescription.pharmacy_Org_Name,
    pharmacyNameOther: prescription.pharmacy_Other_Org_name,
    chPatId: prescription.chPatID,
    ccn: prescription.tier4_ID_CCN,
    clPrescriptionFillNumber: prescription.clPrescriptionFillNumber,
    npi: prescription.prescribing_Provider_NPI,
    servicePharmacyNpi: prescription.service_Pharmacy_NPI,
    createdatetime: prescription.createdatetime,
    gender: prescription.gender,
    prescriberFax: prescription.prescriberFax,
    pharmacyAddress: prescription.pharmacy_Address,
    prescriptionRefID: prescription.prescriptionRefID,
    pharmacyAddressOther: prescription.pharmacyOtherAddress,
    daysSupplyQty: prescription.clM_LINE_DAYS_SUPLY_QTY,
    serviceUnitQty: prescription.clM_LINE_SRVC_UNIT_QTY,
    priceRetail: prescription.price_Retail,
    awpPrice: prescription.price_Retail * prescription.clM_LINE_SRVC_UNIT_QTY,
    claimType: prescription.claimType,
    drugManufacturer: prescription.drugManufacturer,
    tpaName: prescription.tpaName ? prescription.tpaName : 'CMS',
    tpaPrice: prescription.tpaPrice ?? 0,
  }
  return result;
}

export function convertOrig2ColumnsProcessing (prescription: IPrescriptionOrig):IApprovedPrescription {
  return {
    claimPk: prescription.coach340BClaimsID,
    claimId: prescription.cuR_CLM_UNIQ_ID,
    claimType: prescription.claimType,
    patientName: prescription.benE_FullName,
    acoId: prescription.acO_ID,
    tier2_id: prescription.tier2_ID,
    tier2_Name: prescription.tier2_Name,
    tier3_id: prescription.tier3_ID,
    tier4_grpId: prescription.tier4_ID,
    dob: prescription.bene_dob,
    mbi: prescription.mbi,
    firstFilled: prescription.date_Initial_Fill,
    prescriptionName: prescription.medication_Description,
    prescriberName: prescription.prescribing_Provider_Name,
    prescriberCredentials: prescription.prescribing_Provider_Credentials,
    prescriberSpecialty: prescription.prescribing_Provider_Specialty,
    prescriberPhone: prescription.prescribing_Provider_Phone,
    location: prescription.ppBusinessPracticeLocation,
    status: prescription.claim_Status,
    ndc: prescription.ndc,
    rxNumber: prescription.clPrescriptionServiceReference,
    npi: prescription.prescribing_Provider_NPI,
    orgName: prescription.pharmacy_Org_Name,
    pharmacy_Address: prescription.pharmacy_Address,
    pharmacyName: prescription.pharmacy_Org_Name,
    pharmacyNameOther: prescription.pharmacy_Other_Org_name,
    sex: prescription.benE_SEX_CD,
    patientZip: prescription.benE_ZIP_CD,
    prescriptionQuantity: prescription.clM_LINE_SRVC_UNIT_QTY,
    clPrescriptionFillNumber: prescription.clPrescriptionFillNumber,
    daysSupply: prescription.clM_LINE_DAYS_SUPLY_QTY,
    prescriberDEA: prescription.prescribing_Provider_DEA_Number,
    prescriberNPI: prescription.prescribing_Provider_NPI,
    medicationDescription: prescription.medication_Description,
    fillDate: prescription.prescriptionFilledDate,
    gender: prescription.gender,
    pharmacyNpi: prescription.service_Pharmacy_NPI,
    servicePharmacyNpi: prescription.service_Pharmacy_NPI,
    pharmacyOtherAddress: prescription.pharmacyOtherAddress,
    pharmacyOtherCity: prescription.pharmacyOtherCity,
    pharmacyOtherPhone: prescription.pharmacyOtherPhone,
    pharmacyOtherState: prescription.pharmacyOtherState,
    pharmacyOtherZip: prescription.pharmacyOtherZip,
    prescriptionRefID: prescription.prescriptionRefID,
    priceRetail: prescription.price_Retail,
    awpPrice: prescription.price_Retail * prescription.clM_LINE_SRVC_UNIT_QTY,
    drugManufacturer: prescription.drugManufacturer,
    tpaName: prescription.tpaName ? prescription.tpaName : 'CMS',
    tpaPrice: prescription.tpaPrice ?? 0,
  }
}

export function convertPrescriberToCols(prescribers: any): IPrescriber {
  return {
    npi: prescribers.prescribing_Provider_NPI,
    name: prescribers.prescribing_Provider_Name
  };
}

export function convertPharmacyNameToCols(pharmacyNames: IPharmacyNamesOrig): IPharmacyNames {
  return {
    firstName: pharmacyNames.pharmacy_Org_Name,
    secondName: pharmacyNames.pharmacy_Other_Org_Name
  }
}
