import { Component, Input, OnInit } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { ActivatedRoute } from '@angular/router';
import { CareFeatureConstants, CareOrchestrationConstants, CareComponentConstants } from '@shared/models/module-constants';

@Component({
    selector: 'coach-patient-worklists',
    templateUrl: './patient-worklists.component.html',
    styleUrls: ['./patient-worklists.component.scss']
})
export class PatientWorklistsComponent implements OnInit {
    @Input() public patient: Patient;
    @Input() public tier: IHierarchyTier;
    @Input() public mobileScreenSize: boolean;
    @Input() sidebarMode?: boolean;
    openTabs: {[key: string]: boolean} = {}
    public readonly IHECohort = CareFeatureConstants.IHECohort;
    public readonly IHECohortComponentId = CareComponentConstants.IHECohort;
    public readonly CareOrchAccessReqs = CareOrchestrationConstants.FacesheetAccessRequirements;

    constructor(private route: ActivatedRoute){
    }

    ngOnInit(): void {
        let subtab = this.route.snapshot.queryParamMap.get('subtab');
        this.openTabs[subtab] = true;
    }

    openAllTabs(): void {
        this.openTabs['PAC Navigator Program'] = true;
        this.openTabs['In Home AWV Files'] = true;
        this.openTabs['AWV Opportunities'] = true;
    }

    closeAllTabs(): void {
      this.openTabs['PAC Navigator Program'] = false;
      this.openTabs['In Home AWV Files'] = false;
      this.openTabs['AWV Opportunities'] = false;
    }

}
