import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { Observable, of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { EulaService } from '@shared/services/eula-agreement/eula.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    private checkfns = {
        'care': this.careTierAuth,
        'submit': this.submitTierAuth

    }
    constructor(private authService: AuthService,
        private router: Router,
        private eulaService: EulaService,
        private userAccessService: UserAccessService,
        private hierarchyTierService: HierarchyTierService,
        private featureFlagService: FeatureFlagService) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.canActivate(route, state);
    }

    /** Check if user can navigate to route given current access. If navigation is not possible, redirect to the home page */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const isAuthenticated$ = this.authService.authCheckComplete$.pipe(
            switchMap(() => this.isAuthenticated(route, state))).pipe(
                switchMap(result => this.hasEula(result))).pipe(
                    switchMap(result => this.hasAccess(result, route))).pipe(
                        switchMap(result => this.hasFeatureFlag(result, route)))
                            .pipe(map(result => result === false ? this.router.parseUrl(this.hierarchyTierService.currentTierUrl) : result)); 

        return isAuthenticated$;
    }

    isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authService.isAuthenticated$.pipe(map((isAuthorized: boolean) => {
            console.log('AuthorizationGuard, canActivate isAuthorized: ' + isAuthorized);
            // never check tier if not authorized
            if (!(isAuthorized && this.checkTierAuth(route))) {
                const timedOut: boolean = this.authService.read(AuthService.TIMED_OUT);
                if (timedOut) {
                    this.authService.write(AuthService.TIMED_OUT, null);
                    return this.router.parseUrl('timeout');
                } else {
                    this.storeUrl(route, state.url);
                    return this.router.parseUrl('/autologin');
                }
            }

            return true;
        }));
    }

    hasAccess(result: boolean | UrlTree, route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        if (result === true && (route.data.moduleId != null || route.data.featureId != null || route.data.componentId != null || route.data.requirePhi)) {
            return this.userAccessService.accessHistory$.pipe(take(1)).pipe(map(access => {
                let hasAccess = this.userAccessService.accessRestricted(route.data, access);

                if (route.data.requirePhi) {
                    hasAccess = hasAccess && access.phi;
                }

                if (!hasAccess && this.router.routerState.snapshot.url === '') {
                    this.router.navigate(['/']);
                }

                return hasAccess;
            }));
        }

        return of(result);
    }

    /** Check if a feature flag is required for navigation and if so, ensure it exists for current tier */
    hasFeatureFlag(result: boolean | UrlTree, route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        if (result === true && (route.data.featureFlag != null)) {
            return this.featureFlagService.featureFlags$.pipe(skipWhile(value => value == null), take(1), map(() => {
                const hasFlag = this.featureFlagService.hasFeatureFlag(route.data.featureFlag);
                return hasFlag;
            }));
        }

        return of(result);
    }

    hasEula(result: boolean | UrlTree): Observable<boolean | UrlTree> {
        if (!result === true || !this.authService.IdToken) {
            return of(result);
        }

        return this.eulaService.getAllFromCache().pipe(map(x => {
            const hasEula = x != null && x.length > 0 && x[0]?.acceptDateTime != null;

            if (!hasEula) {
                this.router.navigate(['/eula']);
            }

            return hasEula;
        }));
    }

    storeUrl(route: ActivatedRouteSnapshot, url: string): void {
        if (!this.isHomeRoute(url)) {
            url = url.split('?')[0];
            this.authService.write('coachRedirect', url);
            this.authService.write('queryParams', route.queryParams);

            return;
        }

        this.authService.write('coachRedirect', null);
        this.authService.write('queryParams', null);
    }

    private getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    getConfiguredUrl(route: ActivatedRouteSnapshot): string {
        return '/' + route.pathFromRoot
            .filter(v => v.routeConfig)
            .map(v => v.routeConfig!.path)
            .join('/');
    }

    isHomeRoute(url): boolean {
        let isHomeRoute = false;
        if (url && url.search('home') >= 0)
            isHomeRoute = true;
        return isHomeRoute;

    }
    checkTierAuth(route: ActivatedRouteSnapshot): boolean {
        let isTierAuthorized: boolean = true;
        var fn = this.checkfns[route.fragment];
        if (typeof fn === 'function') {
            isTierAuthorized = fn();
        }
        else //default is true
            isTierAuthorized = true;
        return isTierAuthorized;
    }

    // these function can be implemnented to provide custom view specific authorization control.
    //to add go to custom specifc view instead of Home/Login
    private careTierAuth(): boolean {
        //this.authService.tiers==""
        return true;
    }
    private submitTierAuth(): boolean {
        return true;
    }

}

