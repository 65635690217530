import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HccDashboardPatient } from './hcc-dashboard-patient';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HccPatientSummaryService extends AzureApiBase<HccDashboardPatient> {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/hccDashboard/patientSummary');
  }

  getPatients(tierNum: number, tierId: string, tier1Id: string): Observable<HccDashboardPatient[]> {
    const params: HttpParams = new HttpParams()
      .set('tierNum', tierNum == null ? '' : tierNum.toString())
      .set('tierId', tierId)
      .set('tier1Id', tier1Id);

    return this.getAll(null, params);
  }
}