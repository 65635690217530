import { CarePatientItem } from '@shared/models/module-constants';

export interface AgGridTableFilterSetting {
  // AgGrid custom filter documentation https://www.ag-grid.com/javascript-data-grid/filter-provided-simple/
  excluded?: string[]; // list of excluded options from options list. If option in options list is unchecked it goes into this list
  name: string; // display name
  options?: string[]; // list of available options to select
  title: string; // aggrid filter/column name
  keys?: Array<{ label: string; name: string }>;
  isOpen?: boolean; // is used to expand/collapse dropdown
  type?: string; // type of filter https://www.ag-grid.com/javascript-data-grid/filter-provided-simple/#simple-filter-options
  list?: boolean; // checks whether filter is list or not. Used in AgGridLocalSettingsService.updateFilterSettings() to populate filter drawer settings.
  filter?: number; // The number value(s) associated with the filter. Custom filters can have no values (hence both are optional). Range filter has two values (from and to). https://www.ag-grid.com/javascript-data-grid/filter-provided-simple/#reference-NumberFilterModel
  filterTo?: number; // Range filter to value.
  filterType?: string;
  filterOptions?: string[] | Array<{ value: string; label: string }>; // list of filter options for a dropdown https://www.ag-grid.com/angular-data-grid/filter-provided-simple/#simple-filter-options
  includeAllOrNoneSelection?: boolean; // toggle for "All or None" filter type
  dateFrom?: string; // The date value(s) associated with the filter. The type is string and format is always YYYY-MM-DD e.g. 2019-05-24. Custom filters can have no values (hence both are optional). Range filter has two values (from and to). https://www.ag-grid.com/angular-data-grid/filter-provided-simple/#reference-DateFilterModel
  dateTo?: string; // Range filter to date value.
  percentage?: boolean;
}


const careManagementFilterSetttings ={
    rows: [
      {
        title: 'Attributed Practice',
        name: 'attributedPractice',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Attributed Provider',
        name: 'attributedCP',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'CCM Eligible',
        name: 'ccmEligible',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Highest Risk Percentile',
        name: 'ssPercent',
        type: 'lessThanOrEqual',
        percentage: true,
        filterType: 'number',
      },
    ],
  }

export const filterSettings = {
  [CarePatientItem.AllPatients]: {
    rows: [
      {
        title: 'Chronic Conditions',
        name: 'diseaseCohorts',
        options: [],
        excluded: [],
        type: 'set',
        list: true,
      },
      {
        title: 'Attributed Provider',
        name: 'attributedCP',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Care Coordinator',
        name: 'professionalName',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Assignment',
        name: 'assignment',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'CCM Eligible',
        name: 'ccmEligible',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Predicted Risk Category',
        name: 'predictedRiskLevel',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        /**
         * @documentation for Simple Filter Options
         * https://www.ag-grid.com/angular-data-grid/filter-provided-simple/#simple-filter-options
         */
        title: 'Total Gap Weight',
        name: 'openRAFWt',
        type: null,
        filter: null,
        filterTo: null,
        filterType: 'number',
        filterOptions: [
          { value: 'inRange', label: 'In range' },
          { value: 'lessThan', label: 'Less than' },
          { value: 'lessThanOrEqual', label: 'Less than or equal' },
          { value: 'greaterThan', label: 'Greater than' },
          { value: 'greaterThanOrEqual', label: 'Greater than or equal' },
        ],
      },
      {
        title: 'Include Deceased',
        name: 'dodBoolean',
        type: 'set',
        options: ['Yes', 'No'],
        excluded: [],
        includeAllOrNoneSelection: true, // is needed to pull all options at once, in other words it checks whether value exists OR null
      },
    ],
  },
  [CarePatientItem.AwvSchedule]: {
    rows: [
      {
        title: 'Schedule Status',
        name: 'scheduleStatus',
        options: [],
        excluded: [],
        type: 'set',
      },
      {
        title: 'Assignment',
        name: 'assignment',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Appointment Date',
        name: 'dateOfService',
        dateFrom: null,
        dateTo: null,
        filterType: 'date',
        filterOptions: [{ value: 'inRange', label: 'In range' }],
      },
    ],
  },
  [CarePatientItem.AwvFiles]: {
    rows: [
      {
        title: 'Status',
        name: 'IHEDownloadStatus',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Attributed Provider',
        name: 'attributedCP',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Assignment',
        name: 'assignment',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Visit Date',
        name: 'IHEAWVVisitDate',
        dateFrom: null,
        dateTo: null,
        filterType: 'date',
        filterOptions: [{ value: 'inRange', label: 'In range' }],
      },
    ],
  },
  [CarePatientItem.AwvBilling]: {
    rows: [
      {
        title: 'Batch Date',
        name: 'IHEAWVBatchDate',
        dateFrom: null,
        dateTo: null,
        filterType: 'date',
        filterOptions: [{ value: 'inRange', label: 'In range' }],
      },
      {
        title: 'Claim Frequency Code',
        name: 'claimFrequencyCode',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Dx Count',
        name: 'dxCount',
        type: 'set',
        options: [],
        excluded: [],
      },
      {
        title: 'Visit Date',
        name: 'IHEAWVVisitDate',
        dateFrom: null,
        dateTo: null,
        filterType: 'date',
        filterOptions: [{ value: 'inRange', label: 'In range' }],
      },
    ],
  },
  [CarePatientItem.CareManagementAllProgram]: careManagementFilterSetttings,
  [CarePatientItem.CareManagementCopd]:careManagementFilterSetttings,
  [CarePatientItem.CareManagementHeartFailure]:careManagementFilterSetttings,
  [CarePatientItem.CareManagementHypertension]:careManagementFilterSetttings,

};

