import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-steering-committee',
  templateUrl: './steering-committee.component.html',
  styleUrls: ['./steering-committee.component.scss']
})
export class SteeringCommitteeComponent implements OnInit {
  app = AppConstants;
  
  constructor() { }

  ngOnInit(): void {
  }

}
