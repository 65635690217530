import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { ReportFilterType } from '@track/track-reports/track-report-filter-type';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrackReportFilterTypesService extends AzureApiBase<ReportFilterType> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/report/filterTypes');
  }

  getFilterTypes(filterType: string): Observable<ReportFilterType[]> {
    const params = new HttpParams().set('type', filterType);

    return this.get(null, params);
  }
}
