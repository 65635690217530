<coach-care-prescriptions-filters
  *ngIf="!UIUpdates340BFeatureFlag"
  [configData]="filterOptions"
  [gridApi]="gridApi"
>
</coach-care-prescriptions-filters>

<coach-care-prescriptions-sidebar></coach-care-prescriptions-sidebar>

<div class="page-content-main">
  <div class="header row--verticalCenter padding-topBottom--none">
    <div class="titles">
      <div class="title row--verticalCenter u-truncate">
        <div class="header-content">
          <div class="head">340B External Prescriptions</div>
        </div>

        <div style="margin-left: auto; margin-right: 20px;">
          <select
            id="exportSelect"
            style="
              color: rgba(32, 129, 174);
              display: inline;
              background-color: white;
              border: 1px solid rgb(32, 129, 174);
              padding-bottom: 10px;
              margin-right: 20px;
              width: min-content;
            "
            (change)="exportSelectionChanged($event)"
            #exportDropdown
            [(ngModel)]="selectedExportOption"
          >
            <option selected disabled hidden>{{b340StringConstants.EXPORT_MENU_TITLE}}</option>
            <option>{{b340StringConstants.EXPORTS_PATIENT_LIST}}</option>
            <option>{{b340StringConstants.EXPORTS_EXPORT}}</option>
          </select>
        </div>

        <button
          *ngIf="loadingStatus"
          (click)="cancelPrescriptionsLoadingProcess()"
          class="button margin-left--small is-smaller margin-topBottom--none padding-topBottom--smaller"
        >
          <span>
            <div class="loader margin-right"></div>
          </span>
          Cancel
        </button>
      </div>
    </div>
  </div>
  <div class="dynamicBody row" style="height: 100%">
    <div class="dynamicBody">
      <ag-grid-angular
        #theGrid
        class="ag-theme-material"
        [hidden]="loadingStatus"
        
        row-animation
        style="width: 100%; height: 100%"
        [rowData]="prescriptions"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [overlayNoRowsTemplate]="'&lt;p&gt;No data&lt;/p&gt;'"
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (rowClicked)="onRowClicked($event)"
        [pagination]="true"
        [paginationAutoPageSize]="true"
        [rowSelection]="'multiple'"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>
