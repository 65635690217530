import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';


import { COLUMN_DEFS } from './care-prescription.columns';
import {
  IPrescription,
  IApprovedPrescription,
  IContractInfo,
  IPharmacyNames,
  IPrescriptionDetail,
  IPrescriptionInvoice,
} from '@shared/models/prescription';
import { IPrescriptionFilterPreset } from '@shared/models/prescription-filters';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { ToastrService } from 'ngx-toastr';
import { ClaimStatus, formatDate, formatDateFourDigitYear, toCsv, download, generateTable, generateTableHead, B340StringConstants } from '../care-prescriptions/shared-lib';
import { Subscription } from 'rxjs';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { FilterOption } from '../care-all-prescriptions/care-all-prescriptions.component';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
@Component({
  selector: 'coach-care-prescriptions-invoicing',
  templateUrl: './care-prescriptions-invoicing.component.html',
  styleUrls: ['./care-prescriptions-invoicing.component.scss'],
  host: {
    class: 'page-content coach-care-prescriptions',
  },
})
export class CarePrescriptionsInvoicingComponent extends KillSubscriptions
implements OnInit, OnDestroy {

  public static AUDIT_ACCESS_MESSAGE = 'Accessed 340B Processing Queue';
  public static AUDIT_PRINT_MESSAGE = 'Printed 340B TPA Approved List';

  public isClaimSelected: boolean = false;

  public static ACCESS_AUDIT_MESSAGE = 'Accessed 340B Work Queue';
  
  public prescriptions: Array<IPrescription> = [];
  public dataLoaded: boolean = false;
  public loadingStatus = true;
  public cancelLoadingBool = false;
  public resetFilter = false;
  public selectedPreset: IPrescriptionFilterPreset;
  public prescriptionDetail: IPrescriptionDetail;
  
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  public selectedPatientInfo: any;
  readonly defaultColDef: ColDef = {
    resizable: true,
    headerCheckboxSelectionFilteredOnly: true,
  };
  readonly columnDefs = COLUMN_DEFS;

  b340StringConstants: B340StringConstants = new B340StringConstants();
  selectedExportOption = this.b340StringConstants.EXPORT_MENU_TITLE;

  public orgId: string = null;
  public tier: IHierarchyTier = null;
  public tier2_id: string = null;
  public rowCount: number = 10;
  public pageNo: number = 1;

  public redrawRowInterval: any;

  dateRangeLower: Date;
  dateRangeHigher: Date;

  public gridObject: any = null;

  authSubscription: Subscription;
  currentUser: any;

  currentRow;

  filterOptions: FilterOption[];
  isPatientFiltersOpen: boolean = false;
  filterByDateAndStatus: boolean = true;

  invoices: Array<IPrescriptionInvoice>;
  selectedInvoice: IPrescriptionInvoice;

  contractInfo: IContractInfo;

  pharmacyNames: Array<IPharmacyNames>;

  public claimPanelStatus: number = 1;
  UIUpdates340BFeatureFlag: boolean;
  
  @Output() public gridApiEmitter: EventEmitter<GridApi> = new EventEmitter();
  @Output() public filterOptionsEmitter: EventEmitter<FilterOption[]> = new EventEmitter();

  constructor(
    private _route: ActivatedRoute,
    private HierarchyTierService: HierarchyTierService,
    private PrescriptionService: PrescriptionService,
    private toastr: ToastrService,
    private router: Router,
    protected featureFlagService: FeatureFlagService,
    private authService: AuthService
  ) {
    super();
    this.gridApi = null;
    HierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (tier) => {
        if (this.tier?.selectedTierId !== tier?.selectedTierId) {
          this.tier = tier;
          this.tier2_id = this.tier.selectedItem.tier2_ID;
          let getInvoices = this.getInvoices();
          let contractInfo = this.getContractInfo();
          let pharmacyNames = this.PrescriptionService.getPharmacyNames(this.tier.selectedItem.tier1_ID, this.tier.selectedItem.tier2_ID, null, null)

          await getInvoices;
          await contractInfo;
          this.pharmacyNames = await pharmacyNames;

          this.initializeFilter();
        }
      });
    this.UIUpdates340BFeatureFlag = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.UIUpdates340B)
  }

  async getContractInfo() {
    const tierId = this.tier.selectedItem.tier2_ID;
    this.PrescriptionService.getContractInfo(tierId).then(contractData => {
      this.contractInfo = contractData;
    });
  }

  ngOnInit(): void {
    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  allowSorting: boolean = false;
  onGridReady(param: any): void {
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
    this.rowCount = this.gridApi.getDisplayedRowCount();
    this.pageNo = 1;

    this.setDefaultSortOrder();
    this.gridApiEmitter.emit(this.gridApi)
    this.allowSorting = true;
  }

  onFirstDataRendered(params: any): void {
    this.gridObject = params;
    this.dataLoaded = true;
  }

  public async filterChange(event: any) {}

  onFilterChanged(): void {}

  public togglePatientFilters(): void {
    this.isPatientFiltersOpen = !this.isPatientFiltersOpen;
  }

  removeRow() {
    const selectedData = this.gridApi.getSelectedRows();
    this.gridApi.applyTransaction({ remove: selectedData });
  }

  public async onRowClicked(event: any): Promise<void> {
    this.selectedInvoice = event.data;

    this.PrescriptionService.setSelectedInvoicingClaimInfo({
      selectedPatientInfo: this.selectedInvoice,
      rowId: event.node.id,
    });

    this.currentRow = event;
  }

  exportSelectionChanged(e) {
    switch (e.target.value) {
      case this.b340StringConstants.EXPORT_INVOICE_DATA:
        this.exportExtendedClaimsData();
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.selectedExportOption = this.b340StringConstants.EXPORT_MENU_TITLE;
    }, 0);
  }

  async exportExtendedClaimsData() {
    let tbl = document.createElement('table');
    let visibleNodes = this.buildVisibleClaimsNodeSet();
    
    let claimIds = this.buildClaimIdStrings(visibleNodes);    

    this.prescriptions = await this.PrescriptionService.getClaimsForInvoice(claimIds.cmsClaimIds, claimIds.tpaClaimIds);
    let data = this.buildTableData();

    let filteredData = data.filter((item) => {
      for (let node of visibleNodes) {
        if (node.claimId === item.claimPk && node.claimType === item.claimType) {
          return true;
        }
      }
      return false;
    })
    .map(claimObj => {
      delete claimObj.claimPk;
      delete claimObj.claimType;
      claimObj.drugManufacturer = claimObj.drugManufacturer ?? '';
      return claimObj;
    });

    generateTable(tbl, filteredData);
    generateTableHead(tbl, filteredData[0]);

    const csv = toCsv(tbl);
    download(csv, 'download.csv');
  }

  buildClaimIdStrings(visibleNodes) {
    let cmsClaimIds = [];
    let tpaClaimIds = [];
    
    for (let node of visibleNodes) {
      if (node.claimType === 'CMS') {
        cmsClaimIds.push(node.claimId);
      } else if(node.claimType === 'TPA') {
        tpaClaimIds.push(node.claimId);
      }
    }

    return { cmsClaimIds: cmsClaimIds.join(','), tpaClaimIds: tpaClaimIds.join(',') };
  }

  async fetchPrescriptions(nodes: Set<any>): Promise<IPrescription[]> {
    let prescriptions = [];
    let prescriptionPromises = [];
    let approvedByPromises = [];
    let approvers = new Map();

    try {
      for (let node of nodes) {
        prescriptionPromises.push(this.PrescriptionService.getPrescriptionWithId(this.tier.selectedItem.tier2_ID, node.claimId, node.claimType));
        approvedByPromises.push(this.PrescriptionService.getPrescriptionHistoryDetailRecord(node.claimId, ClaimStatus.APPROVED));
      }
      for (let promise of approvedByPromises) {
        let resolved = await promise;

        if (!resolved) {
          continue;
        }

        approvers.set(resolved.coach340BClaimsID, resolved);
      }
      for (let promise of prescriptionPromises) {
        let prescription = await promise;
        
        // Non-participating prescription
        if (!prescription) {
          continue;
        }

        let approver = approvers.get(prescription.claimPk);

        if (!approver) {
          continue;
        }

        prescription.approvedBy = approver.claim_Status_Set_By;
        prescription.approvedOn = approver.claim_Status_Set_On_Date;
        prescriptions.push(prescription);
      }
    } catch (e) {
      this.toastr.error(`${e.status}: ${e.statusText}`, 'Error');
    }
    
    this.writeAccessAuditMessage(
      CarePrescriptionsInvoicingComponent.ACCESS_AUDIT_MESSAGE
      );
      
    return prescriptions;
  }

  buildVisibleClaimsNodeSet() {
    let pageCount = this.gridApi.paginationGetTotalPages();
    let originalPage = this.gridApi.paginationGetCurrentPage();
    this.gridApi.paginationGoToFirstPage();
    let nodes = [];
    for (let i = 0; i < pageCount; i++) {
      let currentPageNodes = this.gridApi.getRenderedNodes();
      nodes.push(currentPageNodes);
      this.gridApi.paginationGoToNextPage();
    }
    this.gridApi.paginationGoToPage(originalPage);
    let result = new Set(nodes.flat(1).map(node => {
      return {'claimId': node.data.claimId, 'claimType': node.data.claimType};
    }));
    return result;
  }

  buildTableData(): any {
    let prescriptions: any = this.prescriptions;
    let result = prescriptions.map(p => {
      const invoices: any = this.invoices;
      const relevantInvoice: any = invoices.find(invoice => invoice.claimId == p.coach340BClaimsID);
      const costAmount = relevantInvoice.costAmount;
      const caravanFee = relevantInvoice.caravanFee;
      const priceSpread = relevantInvoice.priceSpread;

      return {
        "claimPk": p.coach340BClaimsID,
        "Tier2_ID": p.tier2_ID,
        "Tier2_Name": p.tier2_Name,
        "Bene_FullName": `"${p.benE_FullName}"`,
        "Bene DOB": formatDateFourDigitYear(p.bene_dob),
        "Prescription_Filled_Date": formatDateFourDigitYear(p.prescriptionFilledDate),
        "NDC": `"${p.ndc}"`,
        "Medication_Description": `"${p.medication_Description}"`,
        "Rx Number": p.clPrescriptionServiceReference,
        "Fill Number": p.clPrescriptionFillNumber,
        "Prescribing_Provider_NPI": p.prescribing_Provider_NPI,
        "Prescribing_Provider_Name": `"${p.prescribing_Provider_Name}"`,
        "Provider_Address": `"${p.ppBusinessPracticeLocation}"`,
        "Prescribing_Provider_Specialty": `"${p.prescribing_Provider_Specialty}"`,
        "Service_Pharmacy_NPI": p.service_Pharmacy_NPI,
        "Pharmacy_Org_Name": `"${p.pharmacy_Org_Name}"`,
        "Pharmacy_Address": `"${p.pharmacy_Address}"`,
        "Pharmacy_Other_Org_Name": `"${p.pharmacy_Other_Org_name}"`,
        "Pharmacy_Address_Other": `"${p.pharmacyOtherAddress || ''}"`,
        "Date_Initial_Fill": formatDateFourDigitYear(p.date_Initial_Fill),
        
        "Cost_Amount": `$${costAmount.toFixed(2)}`,
        "Caravan_Fee": `$${caravanFee.toFixed(2)}`,
        "Price_Spread": `$${priceSpread.toFixed(2)}`,
        
        "Approved_By": p.approved_By ?? '',
        "Approved_On": p.approved_On ? formatDateFourDigitYear(p.approved_On) : '',
        "claimType": p.claimType,
        "Claim Source": relevantInvoice.claimType === "CMS" ? "CMS" : relevantInvoice.tpaName,
        "drugManufacturer": p.drugManufacturer,
        "PendingDate":             p.pendingDate ? formatDate(p.pendingDate) : '',
        "InvoicingDate":           p.invoicingDate ? formatDate(p.invoicingDate) : '',
        "InvoicedDate":            p.invoicedDate ? formatDate(p.invoicedDate) : '',
        "RejectedDate":            p.rejectedDate ? formatDate(p.rejectedDate) : '',
        "RejectedNeedsCreditDate": p.rejectedNeedsCreditDate ? formatDate(p.rejectedNeedsCreditDate) : '',
        "CreditedDate":            p.creditedDate ? formatDate(p.creditedDate) : '',
      }
    });

    return result;
  }
  
  setDefaultSortOrder() {
    this.columnApi.applyColumnState({
      state: [
        {
          colId: 'status',
          sort: 'asc',
          sortIndex: 0,
        },
      ],
      defaultState: { sort: null },
    });
  }

  public cancelPrescriptionsLoadingProcess(): void {
    this.cancelLoadingBool = true;
    this.loadingStatus = false;
    this.resetFilter = true;
    let that = this;
    setTimeout(function () {
      that.resetFilter = false;
    }, 1000);
  }

  initializeFilter() {
    const { selectedItem } = this.tier;

    const filterOptions = [
      {
        headerLabel: 'Status',
        filterInstanceLabel: 'status',
        optionLabels: ['Pending', 'Invoicing', 'Invoiced', 'Rejected', 'Rejected Needs Credit', 'Credited'],
        // checkedByDefault: ['Pending', 'Invoicing'],
        checkedByDefault: [],
        dataMapper: null,
        customMonthLabelPresent: true,
        customMonthLabel: 'Month',
        filterByDateAndStatus: true,
        useRadioButtons: true,
      },
      {
        headerLabel: 'Contract Pharmacy',
        filterInstanceLabel: 'contractPharmacy',
        optionLabels: [...new Set(this.invoices.map(invoice => invoice.contractPharmacy))],
        checkedByDefault: ['All'],
        dataMapper: null,
      },
      {
        headerLabel: 'Claim Source',
        filterInstanceLabel: 'tpaName',
        checkedByDefault: ['All'],
        optionLabels: this.b340StringConstants.FILTER_SHARED_OPTION_LABELS_TPANAME,
        dataMapper: null,
      },
    ];

    this.filterOptions = filterOptions;
    this.filterOptionsEmitter.emit(this.filterOptions)

    this.loadingStatus = false;
  }

  markSelectedRowsAsProcessed() {
    if (this.onCanProcessClaims()) {
      const selectedData = this.gridApi.getSelectedRows();
      this.PrescriptionService.setClaimToProcessed(
        selectedData.map((claim) => claim.claimId)
      )
      .then(() => this.removeRow())
    }
  }

  onRowSelected() {
    const claims = this.gridApi.getSelectedNodes();
    if (
      claims.length > 0 &&
      claims.every((claim) => claim.data.status == ClaimStatus.APPROVED)
    ) {
      this.isClaimSelected = true;
    } else {
      this.isClaimSelected = false;
    }
  }

  onCanProcessClaims(): boolean {
    const selectedData = this.gridApi.getSelectedRows();
    return selectedData.every((claim) => claim.status == ClaimStatus.APPROVED);
  }

  markCurrentRowAsProcessed(id) {
    this.gridApi.forEachNode((rowNode, index) => {
      if (rowNode.data.claimPk === id) {
        rowNode.setSelected(true);
      } else {
        rowNode.setSelected(false);
      }
    });
    this.markSelectedRowsAsProcessed();
  }

  async getInvoices(): Promise<void> {
    const { selectedItem } = this.tier;
    try {
      this.invoices = await this.PrescriptionService.getInvoices(
        selectedItem.tier1_ID,
        selectedItem.tier2_ID,
        selectedItem.tier3_ID,
        selectedItem.tier4_ID
      );
    } catch (e) {
      this.toastr.error(`${e.status}: ${e.statusText}`, 'Error');
    }
  }

  writeAccessAuditMessage(action: string) {
    const dateRange = this.getFilterDateRange();

    setTimeout(async () => {
      this.PrescriptionService.writeAuditMessage({
        entityid: parseInt(this.currentUser.dnn_id),
        moduleid: 2, // care
        componentid: 36,
        actionid: 6, // phi access
        tablename: 'Coach340BClaims',
        auditdesc: `User ID ${this.currentUser.dnn_id} ${action} for date range ${dateRange}`,
        applicationID: 2,
      });
    }, 0);
  }

  getFilterDateRange() {
    return `${this.dateRangeLower?.toLocaleDateString() ?? 'All'} - ${
      this.dateRangeHigher?.toLocaleDateString() ?? 'All'
    }`;
  }

  async getPrescriptionDetail(claimId: number): Promise<void> {
    try {
      this.prescriptionDetail =
        await this.PrescriptionService.getPrescriptionDetail(
          this.selectedPatientInfo.claimPk,
          this.selectedPatientInfo.acoId,
          this.selectedPatientInfo.tier2_id,
          this.selectedPatientInfo.tier3_id,
          this.selectedPatientInfo.tier4_grpId,
          claimId,
          this.currentUser.dnn_id,
          this.selectedPatientInfo
        );
    } catch (e) {
      this.toastr.error(`${e.status}: ${e.statusText}`, `Error`);
    }
  }

  onSaveInvoice(e: boolean) {
    this.gridApi.refreshCells();
  }
}
