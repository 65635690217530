import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { CareFeatureConstants,CareComponentConstants, CarePatientItem, PresetCategory } from '@shared/models/module-constants';
import { FacesheetScheduleFiltersComponent } from '@care/components/facesheet-schedule/facesheet-schedule-filters/facesheet-schedule-filters.component';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class FacesheetPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.AnnualWellnessVisits: PresetCategory.Facesheet;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.FacesheetSchedule,
      presetKey: CarePatientItem.FacesheetSchedule,
      label: 'Facesheet Schedule',
      filters: [],
      componentId: CareComponentConstants.HccFacesheetBySchedule,
      hidePreset: true,
      columns: this.getPresetColumns(),
      filterComponent: FacesheetScheduleFiltersComponent
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean }[] {
    return [
      { status: true },
      { appointmentDate: true },
      { appointmentTime: true },
      { clinicianName: true },
      { patientName: true },
      { patientId: true },
      { dob: true },
      { mbi: true },
      { assignment: true },
      { attributedCP: true },
      { appointmentInfo: true },
      { locationId: true },
      { currentRiskLevel: true },
      { predictedRiskLevel: environment.displayRiskLevel }
    ];
  }
}
