import { Injectable } from '@angular/core';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { environment } from 'src/environments/environment';
import { AllPatientSnfStatusItems, Patient } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/user';
import { CareFeatureConstants, PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';
import { PresetItemBase, IPresetItemContract } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class PacNavigatorProgramPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.PostAcuteCare: PresetCategory.Worklists;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.PacNavigatorProgram,
      presetKey: CarePatientItem.PacNavigatorProgram,
      label: 'PAC Navigator Program',
      filters: [],
      componentId: CareComponentConstants.PACNavigatorProgram,
      hidePreset: true,
      dontStoreName: true,
      columns: this.getPresetColumns(),
      patientSidebar: true,
      patientUrlQueryParams: {
        tab: 'Worklist Details',
        subtab: 'PAC Navigator Program'
      },
      defaultFilterModel: {
        snfStatus: {
          filterType: 'set',
          values: ['Admitted', 'Discharged']
        }
      },
      requiredPatientFields: ['snfStatus'],
      csvAddition: {
        snfFlagsList: {
          additionalGridColumns: AllPatientSnfStatusItems.map<any>(column => {
            return {
              headerName: this.splitStringIntoWords(column),
              field: column,
              hide: true,
              displayInCSV: true
            };
          }),
          additionalCsvColumns: AllPatientSnfStatusItems,
          addPatientColumns(originalValue: string[], patient: Patient): void {
            AllPatientSnfStatusItems.forEach(item => {
              patient[item] = originalValue?.includes(item) ? 'Yes' : 'No';
            });
          }
        }
      }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    return [
      { select: true },
      { fullName: true },
      { dob: true },
      { mbi: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      { attributedCP: true },
      { snfName: true },
      { snfAdmittedAt: true },
      { snfDischargedAt: true },
      { snfStatus: true },
      { snfFlagsList: true },
      { assignment: true },
      { gender: true },
      { attributedPractice: true },
      { lastAWVDate: true },
      { lastAWVProvider: true },
      { ihAwvPatient: true },
      { hccGapCount: true },
      { openRAFWt: true },
      { pyRaf: true },
      { lastCCMDate: true },
      { edVisits: true },
      { inpatientVisits: true },
      { officeVisits: true },
      { lastVisit: true },
      { enrollmentType: true },
      { isUsingSnf3DayWaiver: this.hasPAC3DayWaiver }
    ];
  }

  splitStringIntoWords(string: string): string {
    return (
      string[0] +
      string
        .slice(1, string.length)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }
}
