<div [ngClass]="{'widget margin--large u-height--fit-content': !sidebarMode }">
  <div class="header" *ngIf="!sidebarMode">
      <div class="titles">
          <div class="title">
              <span class="margin-right--small">AWV Self-reported date</span>
              <span *ngIf="patient && !isTabOpen" class="u-font-size--small u-font-weight--smaller"></span>
          </div>
      </div>
      <coach-icon class="u-cursor--pointer" iconSize="large" [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" (click)="isTabOpen = !isTabOpen"></coach-icon>
  </div>
  <div>
      <div  *ngIf="patient && isTabOpen"  class="u-display--flex u-flex-direction--row ihe-content-wrapper" [ngClass]="{'u-flex-wrap--wrap padding--small': mobileScreenSize, 'padding--large': !mobileScreenSize}">
          <div class="u-display--flex u-flex-direction--column margin-bottom--large padding-leftRightTopBottom--small" [ngClass]="{'u-width--webkit-fill-available': mobileScreenSize, 'span--6': !mobileScreenSize}">
            <div class="padding-bottom--large">
              <label class="label" for="statusDateInput">AWV Completed on:</label>
              <input placeholder="AWV date" id="statusDateInput" (change)="setChangeDateFlag()"
                [(ngModel)]="statusDate" [max]="today" [min]= "oneYearAgo" type="date"
                [ngClass]="{
                    'u-color--brand--3': isDateAfterToday() || isDateBeforeOneYearAgo()
                }">
                <div>
                <span *ngIf="isDateBeforeOneYearAgo()" class="u-font-size--small u-font-weight--smaller u-color--error u-color--brand--3">Invalid date, this field requires a date no earlier than one year from today's date</span>
                </div>
                <div>
                  <span *ngIf="isDateAfterToday()" class="u-font-size--small u-font-weight--smaller u-color--error u-color--brand--3">Invalid date, future dates are not allowed</span>
                </div>
            </div>
            <div>
                <textarea class="resize--none" [(ngModel)]="note" placeholder="Add a comment here..." id="awv_comment" rows="6"
                    maxlength="5000"></textarea>
            </div>
            <div *ngIf="patient && isTabOpen " class="padding-topBottom u-display--flex u-justify-content--spaceBetween">
              <button class="button is-primary is-small" (click)="postNote();updateStatusDate()" [disabled]="isDateAfterToday()||isDateBeforeOneYearAgo()||(!note.length&&!dateChange)">Save</button>
              <button class="button is-primary is-small is-secondary" [disabled]="!note.length&&!dateChange" (click)="note = ''; resetInfo()">Cancel</button>
            </div>
            </div>
          <div *ngIf="getLiveUpdatedHistoryList().length > 0" class="u-display--flex u-flex-direction--column history-wrapper span--6" [ngClass]="{'u-width--webkit-fill-available': mobileScreenSize }">
              <div class="list u-width--full u-display--flex u-flex-direction--column">
                  <span class="subtitle padding--small padding-bottom--small">HISTORY</span>
                  <ng-container *ngFor="let log of getLiveUpdatedHistoryList(); index as idx">
                      <div class="list-item--complex list-item--singleHitArea">
                          <div class="list-item-avi">
                              <div class="avi">
                                  <img *ngIf="proAvatar(log.createdBy)" [src]="proAvatar(log.createdBy)" class="img--circle">
                                  <div class="nameAvatar" *ngIf="log.createdBy && !(proAvatar(log.createdBy))">
                                      {{ log.createdBy | initialsFromName }}
                                  </div>
                              </div>
                          </div>
                          <div class="list-item-main u-justify-content--center">
                              <div class="row u-display--flex u-justify-content--spaceBetween">
                                  <div class="field u-bold">
                                      <span *ngIf="log.createdBy">{{log.createdBy}}</span>
                                      <span *ngIf="!log.createdBy">(name not found)</span>
                                  </div>
                                  <div class="row--verticalCenter margin-right--smallest u-font-size--smallest text-right">
                                      {{log.createdAt | date: "medium"}}
                                  </div>
                              </div>
                              <div class="u-display--flex u-flex-direction--row u-color--brand--1 u-font-size--smallest">
                                  <div class="u-display--flex u-flex-direction--row u-align-items--top">
                                      <div class="faIcon-wrapper padding--smallest">
                                          <fa-icon *ngIf="log.faIcon" class="fa-lg " [icon]="log.faIcon"></fa-icon>
                                      </div>
                                      <span class="subtitle u-text-white-space--break-spaces" *ngIf="log.noteText">{{log.noteText}}</span>
                                      <span class="subtitle u-text-white-space--break-spaces" *ngIf="log.fileType">Downloaded {{log.fileType}}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </ng-container>
              </div>
          </div>
      </div>
  </div>
</div>
