import { common as wp } from '@hcd-caravanhealth/pkg-wptypes';
import { EnvironmentType, IEnvironment } from './environmentTypeDef';
import { TrackFeatureConstants } from '../app/shared/models/module-constants';

export const environment: IEnvironment = {
  production: true,
  environmentId: EnvironmentType.dev,
  wpApiUrl: 'https://api.int.caravan.health/wpapi/2',
  // wpApiUrl: 'https://wellpepper-dev-wpapi-as.azurewebsites.net/2',
  trackApiUrl: 'https://api.int.caravan.health/track',
  userDetailApiUrl: 'https://api.int.caravan.health/access',
  apiBaseUrl: 'https://api.int.caravan.health',
  ocpApimSubscriptionKey: '56ed966789434fba9a3750c2715bcbcc',
  chEndPoint:
    'https://portaldevidservice-as.azurewebsites.net/connect/userinfo',
  authWellknownEndpoint:
    'https://chprodb2c.b2clogin.com/chprodb2c.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signinCaravanHealth',
  stsServer: 'https://login.microsoftonline.com/chprodb2c.onmicrosoft.com/v2.0',
  authClientId: '93b14305-c16a-4f29-bcd1-abe5680ab49e',
  authConfigScope:
    'openid https://chprodb2c.onmicrosoft.com/coachApi/demo.write https://chprodb2c.onmicrosoft.com/coachApi/demo.read',
  coachApiUrl: 'https://api.int.caravan.health/coach',
  qualityInitiativeApiUrl: 'https://api.int.caravan.health/coach',
  prizmApiUrl: 'https://prizmapi.dev.caravan.health',
  careOrchApiUrl: 'https://api.int.caravan.health/careorchservice',
  patientIdentityProvider:
    wp.IdentifierProviders.CaravanHealthPatientInHierarchyNodeDevEnvironment,
  blobStorageUrl: 'https://caravancoachdocrepo.blob.core.windows.net/documents',
  maskSensitiveData: true,
  useHashStrategy: false,
  idleStart: 1500,
  idleTimeout: 300,
  idlePing: 120,
  mixpanel: {
    projectId: '70bb633cfc1b8aa514d859c151271aea',
    debug: false,
    pageViews: true
  },
  appInsights: {
    connectionString: 'InstrumentationKey=05f44bd5-f4dd-4776-b77e-06a066a716b6',
  },
  e2e: {
    Username: 'CompassHCCPHI#caravan.com',
    Password: 'Welcome3#',
  },
  displayRiskLevel: true,
  serverEventsEndpoint: 'https://coachsignalr-int-as.azurewebsites.net',
};
