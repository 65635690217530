import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[clickAway]'
})
export class ClickAwayDirective {

  @Output() public clickAway: EventEmitter<any> = new EventEmitter();
  @Input() public clickAwayDisabled: boolean = false;

  constructor(
    private _elementRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event.target'])
  public onClickAway(targetElement) {
    if ( !this.clickAwayDisabled ) { 
      const clickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.clickAway.emit(null);
      }
    }
  }
}