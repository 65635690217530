<div class="dynamicBody padding--large">
  <div class="bpChart" style="min-height:500px"></div>
  <div class="margins-bottom" id="BPChartSettingsPanel">
    <div >
      <div>
        <label class="margin-top label" for="systolic_low">systolic low</label><input class="u-width--medium"
          id="systolic_low" placeholder="systolic low" [ngModel]="systolic_range[0]" type="number"
          (ngModelChange)="rangeChange($event, 'systolic_range',0)">
        <label class="margin-top label" for="systolic_high">Systolic high</label><input class="u-width--medium"
          id="systolic_high" placeholder="Systolic high" [ngModel]="systolic_range[1]" type="number"
          (ngModelChange)="rangeChange($event, 'systolic_range', 1)">

        <label class="margin-top label" for="diastolic_low">Diastolic low</label><input class="u-width--medium"
          id="diastolic_low" placeholder="Diastolic low" [ngModel]="diastolic_range[0]" type="number"
          (ngModelChange)="rangeChange($event, 'diastolic_range', 0)">
        <label class="margin-top label" for="diastolic_high">Diastolic high</label><input class="u-width--medium"
          id="diastolic_high" placeholder="Diastolic high" [ngModel]="diastolic_range[1]" type="number"
          (ngModelChange)="rangeChange($event, 'diastolic_range', 1)">
      </div>
    </div>
    <div>
      <div><input [ngModel]='days' (ngModelChange)="daysChange($event)" id="days100" [value]=100 type="radio"><label
          for="days100">100 Days</label></div>
      <div><input [ngModel]='days' (ngModelChange)="daysChange($event)" id="days30" [value]=30 type="radio"><label
          for="days30">Month</label></div>
      <div><input [ngModel]='days' (ngModelChange)="daysChange($event)" id="days14" [value]=14 type="radio"><label
          for="days14">2 weeks</label></div>
      <div><input [ngModel]='days' (ngModelChange)="daysChange($event)" id="days7" [value]=7 type="radio"><label
          for="days7">1 week</label></div>
      <button class="button" (click)="randomizeData()">Randomize</button>
    </div>
  </div>


</div>