import gql from 'graphql-tag';

// A GraphQL Fragment on Access
export const modules = gql`
  fragment modules on Access {
    modules {
      name
      moduleId
      features {
        name
        featureId
        components {
          componentId
        }
      }
    }
  }
`;
