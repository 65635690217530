import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';

export interface TierNameLookupResult {
  tierID: string;
  tierName: string;
}

@Injectable({
  providedIn: 'root',
})
export class TierNameLookupService {
  public authToken: string;
  private tierEndpoint = environment.apiBaseUrl + '/track/Hierarchy/tiers';
  private get httpHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authService.IdToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    });
  }
  private cache = {};

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  async preLoadTierNames(tierIds: string[]): Promise<void> {
    const queryParams = [
      ...new Set(
        tierIds.flatMap((tierId) => {
          // don't lookup names we already have cached
          if (!Object.keys(this.cache).includes(tierId)) {
            return [`tierIds=${tierId}`];
          } else {
            return [];
          }
        })
      ),
    ].join('&');
    if (queryParams.length) {
      const result = await this.httpClient
        .get<TierNameLookupResult[]>(`${this.tierEndpoint}?${queryParams}`, {
          responseType: 'json',
          headers: this.httpHeaders,
        })
        .toPromise();
      result.map((tn) => {
        this.cache[tn.tierID] = tn.tierName;
      });
    }
  }

  async getTierName(tierId: string): Promise<string> {
    if (this.cache[tierId]) {
      return this.cache[tierId];
    } else {
      const result = await this.httpClient
        .get<TierNameLookupResult[]>(`${this.tierEndpoint}?tierIds=${tierId}`, {
          responseType: 'json',
          headers: this.httpHeaders,
        })
        .toPromise();
      result.map((tn) => {
        this.cache[tn.tierID] = tn.tierName;
      });
      return this.cache[tierId];
    }
  }
}
