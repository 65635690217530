import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowSelectedEvent } from 'ag-grid-community';

/**
 * Custom AG Grid cell renderer for checkbox selection
 * Uses Coach themed checkboxes and incorporates custom behavior
 * 
 * @param {number?} maxSelected: optional number indicating the maximum amount of selected rows
 * 
 * @example
 * public columnDefs: ColDef[] = [
 *     { cellRendererFramework: CheckboxRendererComponent, cellRendererParams: { maxSelected: 20 } }
 * ];
 */
@Component({
  selector: 'coach-checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss']
})
export class CheckboxRendererComponent implements ICellRendererAngularComp, OnDestroy {
  
  params: any;
  isSelected: boolean = false;
  isDisabled: boolean = false;

  private rowListener = (params: RowSelectedEvent) => {
    if (params.node.rowIndex == this.params.node.rowIndex) {
      this.isSelected = params.node.isSelected();
    }

    this.isDisabled = this.checkDisabled();
  };

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.isSelected = params.node.isSelected();
    this.isDisabled = this.checkDisabled();

    params.api.addEventListener('rowSelected', this.rowListener);
  }

  ngOnDestroy(): void {
    this.params?.api?.removeEventListener('rowSelected', this.rowListener);
  }

  checkDisabled(): boolean {
    return this.params.maxSelected && this.params.api.getSelectedNodes().length >= this.params.maxSelected && !this.isSelected;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onSelected(): void {
    this.params.node.setSelected(this.isSelected);
  }
}
