import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-practice-improvement',
  templateUrl: './practice-improvement.component.html',
  styleUrls: ['./practice-improvement.component.scss']
})
export class PracticeImprovementComponent implements OnInit {
  app = AppConstants;
  
  constructor() { }

  ngOnInit(): void {
  }

}
