import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CaravanPracticeDropdown,
  CaravanSpecialStatus,
  FormResponses,
  FormSubmission,
  SpecialStatusSummary,
  UpdateQualityStatusParams,
} from '@shared/models/forms/forms-backend';
import { QualityFormQuestion } from '@shared/models/forms/forms-frontend';
import {
  QUALITY_FORM_QUESTIONS,
  QUALITY_FORM_TOOLTIPS,
} from './submit-quality';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { UserInfo } from '@shared/services/auth-service/auth-interface';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormConstants, QualityFormSettings } from '@shared/models/forms/form-constants';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { SubmitFeatureConstants  } from '@shared/models/module-constants';
import { CaravanFormSubmissionPeriod  } from '@shared/models/forms/form-submissionID-model';
import { isThursday } from 'date-fns';
import {submitFormSharedFunctions} from '../shared/functions/submit-form-functions';
import { CustomValidators } from '@shared/validators';
@Component({
  selector: 'coach-submit-quality',
  templateUrl: './submit-quality.component.html',
  host: {
    class: 'row u-fill',
  },
})
export class SubmitQualityComponent implements OnInit, OnDestroy {
  // Get Data
  public qualityFormQuestions: QualityFormQuestion[] = QUALITY_FORM_QUESTIONS;
  public practiceList: CaravanPracticeDropdown[] = [];
  public specialStatusList: CaravanSpecialStatus[] = [];
  public ehrVendorList: string[];
  public dataSourceList: string[];
  public dataTypeList: string[];
  public tooltips: Object = QUALITY_FORM_TOOLTIPS;
  public FormQNames: string[] = [
    'FormQ1Fall',
    'FormQ2Diabetes',
    'FormQ3Breast',
    'FormQ4Colorectal',
    'FormQ5Controlling',
    'FormQ6Screening',
    'FormQ7PreventFlu',
    'FormQ49PreventTobacco',
  ];
  public formSubmissions: FormSubmission[];
  public specialStatusSummary: SpecialStatusSummary[];
  public caravanFormSubmissionPeriod: CaravanFormSubmissionPeriod[];
  public selectedFormSubPeriod: number;
  public submitPeriodParam: number;
  public dueDate: Date;

  // Main Form
  public qualityForm: UntypedFormGroup;
  public totalPoints: number = 0;
  public totalPointsRounded: number = this.totalPoints;
  public maxPoints = 80;
  public totalPointsRoundedText = ` out of ${this.maxPoints} points`;

  // Practice Select "Sub" Form
  public practicesForm: UntypedFormGroup;

  // isExempt "Sub" Form
  public exemptForm: UntypedFormGroup;
  public showSpecialStatus: boolean;
  public areQuestionsHidden: boolean = false;

  // fileUpload "Sub" Form
  public uploadForm: UntypedFormGroup;
  public uploadedFileName: string = 'No file chosen';

  // User Info
  public isAuthenticated$: Observable<boolean>;
  public userInfo$: Observable<UserInfo>;
  private _isLoggedIn: boolean = false;
  private _currentUser: UserInfo;

  // Subscriptions
  public qualityFormSub: Subscription;
  public practicesFormSub: Subscription;
  public exemptFormSub: Subscription;
  public uploadFormSub: Subscription;
  public authSub: Subscription;
  public userSub: Subscription;
  public allSubs: Subscription[];

  // Other UI
  public isQueueOpen: boolean = true;
  public isFormQueueOpen: boolean = true;
  public isAddEditFormActive: boolean = false;
  public isInfoTextOpen: boolean = false;
  public infoText: string;
  public readonly OtherSpecialStatusId = 23;
  public readonly QualityFormId = 2;
  public showRemoveModal = false;
  public formSaving = false;
  private exemptionToRemove: SpecialStatusSummary;
  public shwSpecialStatus: boolean = true;
  public useCurrentHierarchy: boolean = true;
  public stepDataSource = '1';
  public stepSpecialStatus = '2';
  public stepAddEdit= '3';

  public submitFormSharedFunction: submitFormSharedFunctions = new submitFormSharedFunctions();
  readonly formsAdminFormFeatureFlag: string = SubmitFeatureConstants.FormsAdminFormFeatureFlag;
  readonly formsAdminFormFeature: number = SubmitFeatureConstants.FormsAdminFormFeature;

  // Copy
  public copy: QualityFormSettings = FormConstants.Quality;

  // Convenience Getters
  public get qf(): UntypedFormGroup {
    return this.qualityForm;
  }
  public get isExempt(): boolean {
    return this.exemptForm.get('isExempt').value;
  }
  public get specialStatus(): number | string {
    return this.qf.get('SpecialStatus').value;
  }

  // Create Practice "Sub" Form
  get practicesFormArray() {
    return this.practicesForm.get('practices') as UntypedFormArray;
  }
  private addPracticeCheckboxes() {
    this.practiceList.forEach(() =>
      this.practicesFormArray.push(new UntypedFormControl(false))
    );
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _authService: AuthService,
    private _cd: ChangeDetectorRef,
    private qualityService: SubmitFormsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private userAccessService: UserAccessService,
  ) {
    this.practicesForm = this._fb.group({
      practices: this._fb.array([]),
    });
    this.addPracticeCheckboxes();

    this.exemptForm = this._fb.group({ isExempt: [false] });
    this.uploadForm = this._fb.group({ file: [null, Validators.required] });
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this._authService.isAuthenticated$;
    this.userInfo$ = this._authService.userInfo$;
    this.authSub = this.isAuthenticated$.subscribe(
      (isLoggedIn) => {
        this._isLoggedIn = isLoggedIn;
      },
      (error) => {}
    );
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      this._currentUser = userInfo;
    });
    this.route.queryParams
      .subscribe(params => {
        if (params.submitperiod != null){
          this.submitPeriodParam = params.submitperiod;
        }

      }
    );

    this.qualityService.getSubmitPeriodDetails(this.QualityFormId).subscribe((data) => {
      this.caravanFormSubmissionPeriod = data;
      if (this.submitPeriodParam == null) {
        this.selectedFormSubPeriod = this.caravanFormSubmissionPeriod[0].caravanFormSubmitPeriodID;
        this.dueDate = this.caravanFormSubmissionPeriod[0].dueDate;
        this.updateSubmissionPeriodConfigs(0);
      } else
      {
        this.selectedFormSubPeriod = this.submitPeriodParam;
        this.dueDate = this.caravanFormSubmissionPeriod.find(i => i.caravanFormSubmitPeriodID == this.submitPeriodParam).dueDate;
        this.updateSubmissionPeriodConfigs(this.caravanFormSubmissionPeriod.findIndex(i => i.caravanFormSubmitPeriodID == this.submitPeriodParam));
      }

      this.initializeQualityForm();

      this.updateOnExempt();

      this.refreshFormData();
    });
  }

  ngOnDestroy(): void {
    this.allSubs.forEach((sub) => sub.unsubscribe());
  }

  public refreshFormData(): void {
    const formID = this.QualityFormId.toString();
    const submissionID = this.selectedFormSubPeriod.toString();

    this.qualityService.getStatuses(formID).subscribe((data) => {
      this.specialStatusList = this.getFilteredStatusList(JSON.parse(data));
    });

    this.qualityService.getSubmissions(formID, submissionID).subscribe((data) => {
      this.formSubmissions = data;
    });

    this.qualityService.getStatusSummary(formID).subscribe((data) => {
      this.specialStatusSummary = data;
    });
  }

  private setPractices(data: string, facIds: number[] = []): void {
    if(data != ''){
      this.practiceList = JSON.parse(data);
      this.practicesFormArray.clear();
      this.practiceList?.forEach((practice) => {
        const isSet = facIds.some((x) => x === practice.Fac_ID);
        this.practicesFormArray.push(new UntypedFormControl(isSet));
      });
    }
  }

  public updateForm() {
    this.updateOnExempt();
    this.calcTotalPoints();
  }

  public updateOnExempt() {
    this.areQuestionsHidden = !this.areQuestionsHidden;
    this.toggleShowSpecialStatus(this.isExempt);
    this.toggleSourceTypeUpload(this.isExempt, this.specialStatus);
  }

  public calcTotalPoints() {
    if (!this.areQuestionsHidden) {
      const pointsArray = [];
      this.FormQNames.forEach((q) => {
        const value = this.qf.get(
          q + '.FormQResponses.0.ResponsePointValue'
        ).value;
        pointsArray.push(value);
      });
      // Filter out Nan, null, false
      const pointsArrayFiltered = pointsArray.filter(function (item) {
        return (parseFloat(item) == item);
      });

      if (pointsArrayFiltered.length > 0) {
        const totalPoints = pointsArrayFiltered.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        this.qf.patchValue({ TotalPoints: totalPoints }, { emitEvent: false });
        this.qf.get('TotalPoints').enable({ emitEvent: false });
      }
    } else {
      this.qf.patchValue({ TotalPoints: null }, { emitEvent: false });
      this.qf.get('TotalPoints').disable({ emitEvent: false });
    }
    this.totalPointsRounded =
      Math.round((this.qf.get('TotalPoints').value + Number.EPSILON) * 100) /
      100;
  }

  public getFilteredStatusList(
    statuses: CaravanSpecialStatus[]
  ): CaravanSpecialStatus[] {
    const statusList = statuses.filter(
      (s) => s.formID === 2 && s.isDeleted === 0 && s.isVisible === 1
    );
    return statusList;
  }

  // Non-Form UI Toggles
  public toggleQueueOpen() {
    this.isQueueOpen = !this.isQueueOpen;
  }
  public toggleFormQueueOpen() {
    this.isFormQueueOpen = !this.isFormQueueOpen;
  }
  public toggleAddEditFormActive() {
    this.isAddEditFormActive = !this.isAddEditFormActive;
  }
  public toggleInfoText(tooltip?) {
    this.isInfoTextOpen = !this.isInfoTextOpen;
    if (this.tooltips[tooltip]) {
      this.infoText = this.tooltips[tooltip].html;
    } else {
      this.infoText = `Additional information may be found at 12 <a href="https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/sharedsavingsprogram/Downloads/2018-and-2019-quality-benchmarks-guidance.pdf" target="_blank"> www.cms.gov</a>`;
    }
  }

  public selectSubmissionPeriod(submissionPeriodID) {
    this.selectedFormSubPeriod = submissionPeriodID.target.value;
    this.dueDate = this.caravanFormSubmissionPeriod[submissionPeriodID.target.selectedIndex].dueDate;
    this.updateSubmissionPeriodConfigs(submissionPeriodID.target.selectedIndex);

    this.initializeQualityForm();

    this.updateOnExempt();

    this.refreshFormData();
  }

  public updateSubmissionPeriodConfigs(submissionPeriodID) {
    const submitPeriod = this.caravanFormSubmissionPeriod[submissionPeriodID];
    for (let config in submitPeriod.configs) {
      if (submitPeriod.configs[config].configValue !== null) {
        if(submitPeriod.configs[config].configName == 'shwSpecialStatus') {
          this.shwSpecialStatus = this.submitFormSharedFunction.getBoolean(submitPeriod.configs[config].configValue);
          this.copy[submitPeriod.configs[config].configName] = this.shwSpecialStatus;
        }
        else if(submitPeriod.configs[config].configName == 'drpDataSource' || submitPeriod.configs[config].configName == 'drpEHR'
        || submitPeriod.configs[config].configName == 'drpPatientPop') {
          this.copy[submitPeriod.configs[config].configName] =  submitPeriod.configs[config].configValue.split('|');
        }
        else if(submitPeriod.configs[config].configName == 'useCurrentHierarchy') {
          this.useCurrentHierarchy =  this.submitFormSharedFunction.getBoolean(submitPeriod.configs[config].configValue);
          this.copy[submitPeriod.configs[config].configName] = this.useCurrentHierarchy;
        }
        else if(submitPeriod.configs[config].configName == 'tooltipEHRText' || submitPeriod.configs[config].configName == 'tooltipDataSourceText'
        || submitPeriod.configs[config].configName == 'tooltipPatientPopText' || submitPeriod.configs[config].configName == 'tooltipPracticeText'
        || submitPeriod.configs[config].configName == 'tooltipExemptText' || submitPeriod.configs[config].configName == 'tooltipSpecialStatusText'
        || submitPeriod.configs[config].configName == 'tooltipUploadQualityMeasureReportText') {
          this.tooltips[submitPeriod.configs[config].configName].html = submitPeriod.configs[config].configValue.toString();
        }
        else {
          this.copy[submitPeriod.configs[config].configName] = submitPeriod.configs[config].configValue.toString();
        }
      }
    }

    this.ehrVendorList = this.copy.drpEHR;
    this.dataSourceList = this.copy.drpDataSource;
    this.dataTypeList = this.copy.drpPatientPop;

    this.stepNumbers();
  }

  public toggleShowSpecialStatus(isExempt: boolean) {
    if (isExempt) {
      this.showSpecialStatus = true;
      this.qf.get('SpecialStatus').enable({ onlySelf: true, emitEvent: false });
    } else {
      this.showSpecialStatus = false;
      this.qf
        .get('SpecialStatus')
        .disable({ onlySelf: true, emitEvent: false });
      this.qf.get('SpecialStatus').reset(null, { emitEvent: false });
    }
  }

  public toggleSourceTypeUpload(isExempt: boolean, status: number | string) {
    const controlSource = this.qf.get('FormQ67DataSource');
    const controlType = this.qf.get('FormQ68DataType');
    if (isExempt) {
      controlSource.disable({ emitEvent: false });
      controlType.disable({ emitEvent: false });
      if (status == 26) {
        this.areQuestionsHidden = false;
        controlSource
          .get('FormQResponses.0.UserResponse')
          .reset(this.dataSourceList[1], { emitEvent: false });
        controlType
          .get('FormQResponses.0.UserResponse')
          .reset(this.dataTypeList[1], { emitEvent: false });
        this.uploadForm.enable({ emitEvent: false });
        this.enableDisableQuestions('enable');
      } else {
        this.areQuestionsHidden = true;
        controlSource
          .get('FormQResponses.0.UserResponse')
          .reset(null, { emitEvent: false });
        controlType
          .get('FormQResponses.0.UserResponse')
          .reset(null, { emitEvent: false });
        this.uploadForm.disable({ emitEvent: false });
        this.uploadForm.get('file').reset(null, { emitEvent: false });
        this.enableDisableQuestions('disable');
      }
    }
    if (!isExempt) {
      this.areQuestionsHidden = false;
      controlSource.enable({ emitEvent: false });
      controlType.enable({ emitEvent: false });
      this.uploadForm.enable({ emitEvent: false });
      this.enableDisableQuestions('enable');
    }
  }

  public enableDisableQuestions(enableOrDisable: 'enable' | 'disable') {
    if (enableOrDisable === 'enable') {
      this.FormQNames.forEach((q) => {
        const formQ = this.qf.get(q);
        formQ.enable({ emitEvent: false });
      });
    } else if (enableOrDisable === 'disable') {
      this.FormQNames.forEach((q) => {
        const formQ = this.qf.get(q);
        formQ.disable({ emitEvent: false });
        formQ
          .get('FormQResponses.0.ResponsePointValue')
          .reset(null, { emitEvent: false });
        formQ
          .get('FormQResponses.1.ResponsePointValue')
          .reset(null, { emitEvent: false });
        formQ
          .get('FormQResponses.2.ResponsePointValue')
          .reset(null, { emitEvent: false });
      });
    }
  }

  public onFileChange(event): void {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.uploadForm.patchValue({ file: file });
      this.uploadedFileName = file.name || 'File name not found';
      this._cd.markForCheck();
    } else {
      this.resetUploadForm();
    }
    this.uploadForm.get('file').markAsTouched();
  }

  private resetUploadForm(): void {
    this.uploadForm.reset({ file: null });
    this.uploadedFileName = 'No file chosen';
    this._cd.markForCheck();
  }

  public updateSelectedPractices() {
    const selectedPractices: CaravanPracticeDropdown[] = this.practicesForm
      .get('practices')
      .value.map((selected, i) => (selected ? this.practiceList[i] : null))
      .filter((p) => p !== null);
    // update fac_id
    const selectedPracticeFacIds = selectedPractices
      .map((p) => p.Fac_ID)
      .toString();
    this.qf.patchValue(
      { Tier4_FacID: selectedPracticeFacIds },
      { emitEvent: false }
    );
    this.qf.get('Tier4_FacID').markAsTouched({ onlySelf: true });
    // update names
    const selectedPracticeNames = selectedPractices
      .map((p) => p.Name)
      .toString();
    this.qf.patchValue({ Tier4_Name: selectedPracticeNames });
    this.qf.get('Tier4_Name').markAsTouched({ onlySelf: true });
  }

  public removeQuestions(formCopy) {
    const copy = formCopy;
    delete copy.FormQ1Fall;
    delete copy.FormQ2Diabetes;
    delete copy.FormQ3Breast;
    delete copy.FormQ4Colorectal;
    delete copy.FormQ5Controlling;
    delete copy.FormQ6Screening;
    delete copy.FormQ7PreventFlu;
    delete copy.FormQ49PreventTobacco;
    delete copy.FormQ67DataSource;
    delete copy.FormQ68DataType;
    return copy;
  }

  public onSubmit(isComplete: boolean): void {
    this.calcTotalPoints();

    let validSubmit: boolean;

    if (this.isExempt) {
      validSubmit = isComplete && this.qf.valid;
    } else {
      validSubmit = isComplete && this.qf.valid && this.uploadForm.valid;
    }

    const validDraft = !isComplete;

    if (validSubmit) this.toastr.info('Form Submitting', 'Quality Form');
    if (validDraft) this.toastr.info('Draft Saving', 'Quality Form');

    if (validSubmit || validDraft) {
      this.qf.controls['isComplete'].setValue(isComplete ? 1 : 0);
      let formCopy = Object.assign({}, this.qf.getRawValue());
      if (this.areQuestionsHidden) {
        formCopy = this.removeQuestions(formCopy);
      }

      if (formCopy.SpecialStatus == null) {
        formCopy.SpecialStatus = '';
      }

      this.formSaving = true;
      const uploadFile = this.uploadForm.get('file').value;
      this.qualityService
        .save([uploadFile], formCopy)
        .pipe(finalize(() => (this.formSaving = false)))
        .subscribe(
          () => {
            if (validSubmit)
              this.toastr.success('Form Submitted', 'Quality Form');
            if (validDraft) this.toastr.success('Draft Saved', 'Quality Form');
            this.refreshFormData();
            this.resetForms();
            this.toggleAddEditFormActive();
          },
          (err) => {
            console.error(err);
            this.toastr.error('Failed To Save', 'Quality Form');
          }
        );
    } else if (isComplete) {
      this.qf.markAllAsTouched();
      this.uploadForm.markAllAsTouched();
    } else if (!isComplete) {
      this.uploadForm.markAllAsTouched();
    }
  }

  public cancel(): void {
    this.resetForms();
    this.toggleAddEditFormActive();
  }

  public initializeQualityForm(): void {
    function quarter_of_the_year(date) {
      const month = date.getMonth() + 1;
      return Math.ceil(month / 3);
    }

    const newDate = new Date();
    const userID = this._currentUser?.dnn_id || null;
    const userEmail = this._currentUser?.email || null;
    const nowJson = newDate.toJSON();
    const nowQtr = quarter_of_the_year(newDate);

    this.qualityForm = this._fb.group({
      FormID: [2],
      FormVersionID: [3],
      FormName: ['Quality'],
      FormCategoryID: [1],
      Email: [userEmail],
      Tier4_FacID: [null, Validators.required],
      Tier4_Name: [null, Validators.required],
      SpecialStatus: [null, Validators.required],
      EHRVendor: [null, Validators.required],
      RegReportOne: [null],
      RegReportTwo: [null],
      FormQuarter: [nowQtr],
      CEHRT: [2020],
      FormCreateDateTime: [nowJson],
      CreatedByUserID: [userID],
      ClosedDateTime: [null],
      LastUpdateUserID: [userID],
      LastUpdateDateTime: [nowJson],
      isComplete: [0],
      TotalPoints: [0],
      UserFormID: [null],
      UserID: [userID],
      SubmitPeriodID: [this.selectedFormSubPeriod.toString()],
      FormQ1Fall: this._fb.group({
        QuestionID: [1],
        QuestionText: ['Falls: Screening for Future Falls Risk'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[0].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ2Diabetes: this._fb.group({
        QuestionID: [2],
        QuestionText: ['Diabetes: Hemoglobin A1c Poor Control (>9.0%)'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[1].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ3Breast: this._fb.group({
        QuestionID: [3],
        QuestionText: ['Breast Cancer Screening'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[3].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ4Colorectal: this._fb.group({
        QuestionID: [4],
        QuestionText: ['Colorectal Cancer Screening'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[4].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ5Controlling: this._fb.group({
        QuestionID: [5],
        QuestionText: ['Controlling High Blood Pressure'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[2].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ6Screening: this._fb.group({
        QuestionID: [6],
        QuestionText: [
          'Preventive Care and Screening: Screening for Depression and Follow-Up Plan',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[7].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ7PreventFlu: this._fb.group({
        QuestionID: [7],
        QuestionText: ['Preventive Care and Screening: Influenza Immunization'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[5].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ49PreventTobacco: this._fb.group({
        QuestionID: [49],
        QuestionText: [
          'Preventive Care and Screening: Tobacco Use: Screening and Cessation Intervention',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, Validators.min(0)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
          this._fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [Validators.required, CustomValidators.greaterThanMinOrZero(this.qualityFormQuestions[6].minDenominator)],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ67DataSource: this._fb.group({
        QuestionID: [67],
        QuestionText: ['Patient Population (Data Source)'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null, Validators.required],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ68DataType: this._fb.group({
        QuestionID: [68],
        QuestionText: ['Patient Population (Data Type)'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this._fb.array([
          this._fb.group({
            UserResponse: [null, Validators.required],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
    });

    this.qualityFormSub = this.qf.valueChanges.subscribe(() => {
      this.updateForm();
    });
    this.practicesFormSub = this.practicesForm.valueChanges.subscribe(() => {
      this.updateSelectedPractices();
    });
    this.exemptFormSub = this.exemptForm.valueChanges.subscribe(() => {
      this.updateForm();
    });
    this.uploadFormSub = this.uploadForm.valueChanges.subscribe(() => {});
    this.allSubs = [
      this.qualityFormSub,
      this.practicesFormSub,
      this.exemptFormSub,
      this.uploadFormSub,
      this.authSub,
      this.userSub,
    ];
  }

  resetForms(): void {
    this.initializeQualityForm();

    this.exemptForm.reset();
    this.resetUploadForm();

    this.updateForm();
  }

  newSubmission(): void {
    const submissionID = this.selectedFormSubPeriod.toString();
    const specialStatusIncluded = this.shwSpecialStatus? '1':'0';
    const useCurrentHierarchy = this.useCurrentHierarchy? '1':'0';
    this.qualityService
      .getListOfSpecificTier(null, SubmitFeatureConstants.QualityFormFeature, submissionID, specialStatusIncluded, useCurrentHierarchy)
      .subscribe((data) => {
        this.setPractices(data);
        this.toggleAddEditFormActive();
      });
    this.initializeQualityForm();
  }

  editForm(submission: FormSubmission): void {
    const submissionID = this.selectedFormSubPeriod.toString();
    const specialStatusIncluded = this.shwSpecialStatus? '1':'0';
    const useCurrentHierarchy = this.useCurrentHierarchy? '1':'0';
    forkJoin([
      this.qualityService.getListOfSpecificTier(
        submission.UserFormID,
        SubmitFeatureConstants.QualityFormFeature,
        submissionID,
        specialStatusIncluded,
        useCurrentHierarchy
      ),
      this.qualityService.getFormResponses(submission.UserFormID),
    ]).subscribe((data) => {
      const submission = data[1][0];
      const facultyIds = submission.SelectedFacs.split('; ').map((id) =>
        parseInt(id)
      );
      const tier4 = JSON.parse(data[0]);
      if (tier4[0].Name) {
        !submission.SelectedFacName
          ? (submission.SelectedFacName = tier4[0].Name)
          : null;
      }
      this.setPractices(data[0], facultyIds);
      this.loadFromSubmission(submission);
      this.toggleAddEditFormActive();
    });
  }

  loadFromSubmission(submission: FormResponses): void {
    this.qualityForm.get('EHRVendor').setValue(submission.EHRVendor);
    this.qualityForm.get('UserFormID').setValue(submission.UserFormID);
    this.qualityForm.get('TotalPoints').setValue(submission.TotalPoints);
    this.qualityForm.get('RegReportOne').setValue(submission.regreportone);
    this.qualityForm.get('RegReportTwo').setValue(submission.regreporttwo);
    this.qualityForm.get('Tier4_Name').setValue(submission.SelectedFacName);
    this.qualityForm.get('Tier4_FacID').setValue(submission.SelectedFacs);

    if (submission.SpecialStatusID && submission.SpecialStatusID !== '0') {
      this.exemptForm.get('isExempt').setValue(true);
      this.qualityForm
        .get('SpecialStatus')
        .setValue(parseInt(submission.SpecialStatusID));
    }

    const dataSource = submission.Q.find((x) => x.ID === 67);
    const patientPopulation = submission.Q.find((x) => x.ID === 68);

    this.qualityForm
      .get('FormQ67DataSource.FormQResponses.0.UserResponse')
      .setValue(dataSource?.Response);
    this.qualityForm
      .get('FormQ68DataType.FormQResponses.0.UserResponse')
      .setValue(patientPopulation?.Response);

    submission.Q.forEach((question) => {
      for (const property in this.qualityForm.controls) {
        const questionControl = this.qualityForm.get(`${property}.QuestionID`);
        const questionId = questionControl?.value;
        if (questionControl != null && questionId === question.ID) {
          const numerator = this.qualityForm.get(
            `${property}.FormQResponses.1.ResponsePointValue`
          );
          const denominator = this.qualityForm.get(
            `${property}.FormQResponses.2.ResponsePointValue`
          );
          const points = this.qualityForm.get(
            `${property}.FormQResponses.0.ResponsePointValue`
          );

          if (numerator != null && denominator != null && points != null) {
            numerator.setValue(Number(question.Numerator));
            denominator.setValue(Number(question.Denominator));
            points.setValue(Number(question.Points));
          }
        }
      }
    });

    this.updateForm();
  }

  removeSubmission(): void {
    this.exemptionToRemove.IsDeleted = true;
    this.updateSpecialStatus(this.exemptionToRemove);
    this.showRemoveModal = false;
    this.specialStatusSummary.splice(
      this.specialStatusSummary.findIndex(
        (x) =>
          x.specialstatusEntryID === this.exemptionToRemove.specialstatusEntryID
      ),
      1
    );
    this.exemptionToRemove = null;
  }

  removeConfirmation(statusSummary: SpecialStatusSummary): void {
    this.exemptionToRemove = statusSummary;
    this.showRemoveModal = true;
  }

  updateSpecialStatus(statusSummary: SpecialStatusSummary): void {
    const params: UpdateQualityStatusParams = {
      formID: this.QualityFormId,
      isDeleted: statusSummary.IsDeleted,
      otherReason: statusSummary.OtherReason,
      specialStatus: statusSummary.SpecialStatusID,
      specialstatusEntryID: statusSummary.specialstatusEntryID,
      userFormID: statusSummary.UserFormID,
      tier: null,
    };

    this.qualityService
      .updateQualityStatus(params)
      .subscribe(() => this.refreshFormData());
  }

  formsReport(): void {
    this.router.navigate(['quality/formreport'], { relativeTo: this.route.parent });
  }


 stepNumbers(): void {
   //Starting off with if there is a submission period drop down
   //If there is, it's numbered 1, as it's step 1
  if (this.caravanFormSubmissionPeriod.length >= 2){
    this.stepDataSource = '1';
  }
  else
  {
    //If it isn't going to be shown, we can name it step 0
    this.stepDataSource = '0';
  }
  //Determining if special status selection is going to be displayed
  if(this.shwSpecialStatus == true) {
    //If there is no submission period drop down but special statuses are visible, special statuses are step 1 and add/edit is step 2
    if(this.stepDataSource == '0'){
      this.stepSpecialStatus = '1';
      this.stepAddEdit = '2';
    }
    else{
      //If there is then special statuses is step 2 and add/edit is step 3
      this.stepSpecialStatus = '2';
      this.stepAddEdit = '3';
    }
  }
  //If there is no special status and no submission period drop down, then add/edit is step 1, otherwise add/edit is step 2
  else {
    if(this.stepDataSource == '0'){
      this.stepAddEdit = '1';
    }
    else{
      this.stepAddEdit = '2';
    }
  }
}

}
