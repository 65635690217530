<div class="section u-background-color--white" *ngIf="patient">
    <!-- HEADER -->
    <div class="header padding-bottom--smaller">
        <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--large">
            <div class="titles t4">
                <b class="title"> Chronic Conditions</b>
            </div>
        </div>
    </div>

    <!-- Closure Rate -->

    <div class="row--wrap row--responsive border-bottom padding--small">
        <!--  CC2-580 hide closure rate
        <div class="span--2 u-display--flex u-flex-direction--column u-align-items--center border-right">
            <div class="titles t6 padding--none">
                <p class="margin-bottom--none open-gaps-title">
                  Closure Rate
                </p>
            </div>
            <div class="d4 open-gaps-number font-family--unset">
                <div>{{(hccPatient?.overallHCCClosureRate == null ? 0 : hccPatient.overallHCCClosureRate) | number : '1.0-0'}}&nbsp;%</div>
            </div>
        </div>
        -->
        <div class="span--10 u-display--flex u-flex-direction--column u-justify-content--spaceEvenly padding-left">
            <!-- GRID EXAMPLE -->
            <!-- LAST YEAR
            Display the total number of gaps closed (Previous Year Chronic Condition Weight Closed) -->
            <div class="row--wrap row--responsive u-font-size--small">
                <div class="span--2 u-color--highlight u-display--flex row--right u-align-items--center row--wrap row--responsive responsive-width-30">
                    <div class="span--6 text-left">
                        Last year
                    </div>
                    <div class="span--6 text-right">
                        <span *ngIf="patient?.trackPatient?.pyRaf">{{patient.trackPatient.pyRaf | number : '1.3-3'}}</span>
                    </div>
                </div>
                <div class="span--10 u-font-size--small row--wrap row--responsive responsive-width-65">
                    <div class="row--wrap row--responsive" [ngClass]="{'span--12': mobileScreenSize, 'span--7': !mobileScreenSize}">
                        <div class="span--11">
                            <div
                                class="u-width--full u-display--flex u-height--full u-display--flex u-align-items--center padding-leftRight--small">
                                <div style="background: silver; height: 5px;" [ngStyle]="{'width': patient?.getLastYearPercentageBar() + '%'}">
                                </div>
                            </div>
                        </div>
                        <div class="span--1">
                            <!-- DUMMY TEXT AFTER BAR -->
                        </div>
                    </div>
                    <div *ngIf="!mobileScreenSize" class="span--5">
                        <div class="u-width--full u-display--flex u-align-items--center row--right u-font-size--small text-right">
                            <!-- Attributed, Non-dual aged: 0.324
                            <div class="cube cube-darkGreen"></div> -->
                            <div class="toggleDummyBox">
                                <!-- DUMMY BOX -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- THIS YEAR
            Display total number of gaps closed -->
            <div class="row--wrap row--responsive u-font-size--small">
                <div class="span--2 u-display--flex row--right u-align-items--center row--wrap row--responsive responsive-width-30">
                    <div class="span--6 text-left">
                        This year
                    </div>
                    <div class="span--6 text-right">
                        <span *ngIf="patient?.trackPatient?.raF_YTD">{{patient.trackPatient.raF_YTD | number : '1.3-3'}}</span>
                    </div>
                </div>
                <div class="span--10 u-font-size--small row--wrap row--responsive responsive-width-65">
                    <div class="row--wrap row--responsive" [ngClass]="{'span--12': mobileScreenSize, 'span--7': !mobileScreenSize}">
                        <div class="span--11">
                            <div
                                class="u-width--full u-display--flex u-height--full u-display--flex u-align-items--center padding-leftRight--small">
                                <!-- <div style="background: #005c62; height: 12px;" [ngStyle]="{'width': 18 + '%'}">
                                </div> -->
                                <div class="u-colors--success" style="height: 12px;" [ngStyle]="{'width': patient.getThisYearPercentageBar() + '%'}">
                                </div>
                                <div style="background: #eaeff5; height: 12px;" [ngStyle]="{'width': (100-patient.getThisYearPercentageBar()) + '%'}">
                                </div>
                            </div>
                        </div>
                        <div class="span--1 u-color--highlight u-display--flex u-align-items--center">
                            {{(patient.trackPatient.raF_YTD + patient.trackPatient.openRAFWt).toFixed(3) | number : '1.3-3'}}
                        </div>
                    </div>
                    <div *ngIf="!mobileScreenSize" class="span--5 u-display--flex">
                        <div class="u-width--full u-display--flex u-align-items--center row--right text-right">
                            <!-- Closed Gaps: 1.53
                            <div class="cube cube-green"></div>
                            <input checked="true" class="toggle" id="togglea" [(ngModel)]="showClosedGaps"
                                type="checkbox">
                            <label for="togglea" style="margin-top: -23px"></label> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- REGULAR EVENT ROW -->

    <ng-container *hasFeatureFlag="FacesheetV28" >
        <coach-patient-facesheet-drag-drop [patient]="patient" ></coach-patient-facesheet-drag-drop>
    </ng-container>

    <ng-container *doesNotHaveFeatureFlag="FacesheetV28">
        <ng-container *ngFor="let opportunity of chronicOpportunities; let idx = index">
            <div class="padding-left--largest row--wrap row--responsive padding--small padding-bottom--small">
                <div class="span--12 u-display--flex u-flex-direction--column">
                    <div class="u-bold">{{opportunity.hccDescription}}</div>
                    <!-- hccCodes -->
                    <div class="u-display--flex u-justify-content--spaceBetween" *ngFor="let hccCode of opportunity.hccCodes">
                        <div class="u-italic u-italic u-color--highlight">{{hccCode.code}}: {{hccCode.name}}</div>
                        <div class="u-thin u-text--nowrap">{{opportunity.providerName | titlecase}}, {{opportunity.dateOfService | date:'MM/dd/yyyy'}}</div>
                    </div>
                </div>
            </div>
            <!-- childOpportunities -->
            <ng-container *ngFor="let childOpportunity of opportunity.childOpportunities; let c_idx = index">
            <div class="row--wrap row--responsive padding--small padding-topBottom--none">
                <div class="span--1 u-display--flex u-flex-direction--column u-align-items--center u-bold">
                    <div class="row" style="width: -webkit-fill-available; height: -webkit-fill-available">
                        <div class="span--4"></div>
                        <!-- arrow -->
                        <div class="span--8 row--wrap row--responsive">
                            <div style="width: calc(50% - 0.5px);"></div>
                            <!-- arrow up/fown -->
                            <div style="width: 1px" class="u-display--flex u-flex-direction--column u-align-items--center">
                                <div style="width: 1px;height: 50%;background: black;"></div>
                                <div *ngIf="opportunity.childOpportunities.length - 1 != c_idx" style="width: 1px;height: 50%;background: black;"></div>
                            </div>
                            <!-- arrow right -->
                            <div style="width: calc(50% - 0.5px);" class="u-display--flex u-flex-direction--column u-justify-content--center">
                                <div style="width: 100%;background: black; height: 1px"></div>
                            </div>
                        </div>
                        <!-- <div class="span--4">{{childOpportunity.weight | number : '1.3-3'}}</div> -->
                    </div>
                        <div class="row" *ngIf="opportunity.childOpportunities.length - 1 != c_idx" style="width: -webkit-fill-available; height: 100%">
                            <div class="span--4"></div>
                            <div class="span--4"></div>
                            <div class="span--4" style="border-left: 1px solid black;"></div>
                        </div>
                    <!-- <div  style="width: 1px;height: ;background: black;"></div> -->
                </div>
                <div class="span--11 u-display--flex u-flex-direction--column padding-left--small responsive-width-90">
                    <div class="u-bold">{{childOpportunity.hccDescription}}</div>
                    <!-- hccCodes -->
                    <div class="u-display--flex u-justify-content--spaceBetween" *ngFor="let hccCode of childOpportunity.hccCodes">
                        <div class="u-italic u-italic u-color--highlight">{{hccCode.code}}: {{hccCode.name}}</div>
                        <div class="u-thin u-text--nowrap">{{childOpportunity.providerName | titlecase}}, {{childOpportunity.dateOfService | date:'MM/dd/yyyy'}}</div>
                    </div>
                </div>
            </div>
        </ng-container>

        </ng-container>

        <!-- DISEASE INTERACTIONS -->
        <div class="u-width--full padding-left--largest border-top" *ngIf="diseaseInteractions.length">
            DISEASE INTERACTIONS
        </div>
        <ng-container *ngFor="let opportunity of diseaseInteractions; let idx = index">
            <!--  *ngIf="idx <= 4 || idx > 4 &&  showMoreOpportunities" -->
            <div class="row--wrap row--responsive padding--small padding-left--largest">
                <div class="span--10 u-display--flex u-flex-direction--column">
                    <div class="u-bold">{{opportunity.hccDescription}}</div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showPotentialOpportunities">
            <!-- DIVIDER  (POTENTIAL NEW OPPORTUNITIES) -->
            <div class="u-width--full padding-left--largest border-top">
                POTENTIAL NEW OPPORTUNITIES
            </div>

            <!-- SYMBOL + COLORIZED NUMBER EVENT ROW -->
            <div class="row--wrap row--responsive padding--small">
                <div class="span--2 u-display--flex u-flex-direction--column u-align-items--center u-bold u-color--brand--6">+
                    1.051</div>
                <div class="span--10 u-display--flex u-flex-direction--column">
                    <div class="u-bold">Title</div>
                    <div class="u-display--flex u-justify-content--spaceBetween">
                        <div class="u-italic u-color--highlight">Some comment</div>
                        <div class="u-thin u-text--nowrap">Name, Date</div>
                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="showClosedGaps">
            <!-- DIVIDER  (CLOSED GAPS) -->
            <div class="u-width--full padding-left--largest border-top">
                CLOSED GAPS
            </div>

            <!-- SYMBOL + EVENT ROW -->
            <div class="row--wrap row--responsive padding--small">
                <div class="span--2 u-display--flex u-flex-direction--column u-align-items--center u-bold">&#10003; 1.051</div>
                <div class="span--10 u-display--flex u-flex-direction--column">
                    <div class="u-bold">Title</div>
                    <div class="u-display--flex u-justify-content--spaceBetween">
                        <div class="u-italic u-italic u-color--highlight">Some comment</div>
                        <div class="u-thin u-text--nowrap">Name, Date</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

</div>
