export const ACO_REPORT_LEVEL = 'ACO';
export const CIN_REPORT_LEVEL = 'CIN';
export const REGION_REPORT_LEVEL = 'Region';

export interface ReportFilterType
    {
        userID: number | null;
        phiFlag: number | null;
        filterType: string;
        filterTypeID: number;
        filterTypeName: string;
    }