import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IContractInfo, IPrescriptionDetail } from '@shared/models/prescription';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';
import { Subscription } from 'rxjs';
import { copyTextToClipboard } from '@shared/utilities';
import { TabSwitchState } from './invoicing-tab-switch/invoicing-tab-switch.component';
import {
  InvoiceStatus,
  mapInvoiceStatusToString,
  formatDate,
  basicNameSplit,
  removeLeadingZeros
} from '@care/views/care-prescriptions/shared-lib';

@Component({
  selector: 'coach-care-prescriptions-invoicing-sidebar',
  templateUrl: './care-prescriptions-invoicing-sidebar.component.html',
  styleUrls: ['./care-prescriptions-invoicing-sidebar.component.scss'],
})
export class CarePrescriptionsInvoicingSidebarComponent
  implements OnInit, OnDestroy
{
  @Input() selectedPatientInfo: any = [];
  @Input() show: boolean;
  @Input() selectedInvoice: any;
  @Input() contractInfo: IContractInfo;
  @Input() tier2_id: string;

  @Output() onProcessClaim = new EventEmitter();
  @Output() onSaveInvoice = new EventEmitter<boolean>();

  areChangesMade: boolean = false;

  patientDemographics: any;
  
  thePriceSpread: number = 0;
  theRejectNote: string = null;
  theRejectReason: number = null;
  theInvoiceStatus: number = null;

  patientName: string;
  patientDobText: string;
  patientMbi: string;

  claimId: number;

  isRecordInEndStateStatus: boolean = false;

  currentUser: any;
  creatorName: string;
  creatorDate: Date;

  authSubscription: Subscription;

  isValidationPanelOpen: boolean = false;
  isClaimSelected: boolean = false;
  notes: string = '';
  toggleVisitNoteModal: boolean = false;
  toggleCareCoordinationNoteModal: boolean = false;
  currentRowId: number;

  prescriptionDetails: IPrescriptionDetail;
  prescriptionSubscription: Subscription;

  isInvoiceDetailsTabSelected: boolean = true;
  isClaimInfoTabSelected: boolean = false;
  isClaimHistorySelected: boolean = false;

  historyDetail: any;

  constructor(
    private prescriptionService: PrescriptionService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.prescriptionSubscription =
      this.prescriptionService.invoicingClaimInfoUpdated.subscribe(
        (invoice:any) => {
          setTimeout(() => {
            this.selectedInvoice = invoice.selectedPatientInfo;
            this.thePriceSpread = invoice.selectedPatientInfo.priceSpread;
            this.theRejectNote = invoice.selectedPatientInfo.rejectNote;
            this.theRejectReason = invoice.selectedPatientInfo.rejectReasonId;
            this.theInvoiceStatus = this.selectedInvoice.invoiceQStateID;
            this.currentRowId = invoice.rowId;
            this.updateInvoiceDetailsData(this.selectedPatientInfo.claimId);

            this.setRecordEndStateStatus();

            this.prescriptionService.getPrescriptionWithId(this.tier2_id, this.selectedInvoice.claimId, this.selectedInvoice.claimType).then(demo => {
              this.patientDemographics = demo;
              this.patientDemographics.patientDob = this.patientDemographics.dob;
            });
            
            this.resetControls();
            this.setChangesMade(false);

            this.prescriptionService
              .getPrescriptionDetailWithId(this.selectedInvoice.claimId, this.selectedInvoice.claimType)
              .then((prescriptionInfo) => {
                this.prescriptionDetails = prescriptionInfo;
              });

            this.prescriptionService
              .getPrescriptionWithId(this.tier2_id, this.selectedInvoice.claimId, this.selectedInvoice.claimType)
              .then((result) => {
                this.selectedPatientInfo = result;
                this.patientName = basicNameSplit(this.selectedPatientInfo.patientName).join(' ');
                this.patientDobText = this.formatDate(this.selectedPatientInfo.dob);
                this.patientMbi = this.selectedPatientInfo.mbi ? this.selectedPatientInfo.mbi : 'N/A';

                this.historyDetail = this.prescriptionService
                  .getPrescriptionHistoryDetail(
                    this.tier2_id,
                    invoice.selectedPatientInfo.claimId,
                    result.createdatetime
                  )
                  .then((result) => {
                    this.historyDetail = result;
                  });
              });
          }, 0);
        }
      );

    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser;
        this.creatorName = this.currentUser.name || this.currentUser.email;
        this.creatorDate = new Date();
      }
    );
  }

  setRecordEndStateStatus() {
    const invoiceStatus = Number(this.theInvoiceStatus);
    switch (invoiceStatus) {
      case InvoiceStatus.INVOICED:
      case InvoiceStatus.REJECTED:
      case InvoiceStatus.REJECTED_NEEDS_CREDIT:
      case InvoiceStatus.CREDITED:
        this.isRecordInEndStateStatus = true;
        return;        
    }
    this.isRecordInEndStateStatus = false;
  }
  
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    this.prescriptionSubscription.unsubscribe();
  }

  updateInvoiceStatus(status) {
    if (status != InvoiceStatus.REJECTED) {
      this.theRejectReason = undefined;
      this.theRejectNote = '';
    }

    this.theInvoiceStatus = status;
    this.setRecordEndStateStatus();
    this.setChangesMade(true);
  }

  resetControls() {
    if (this.isInvoiceDetailsTabSelected) {
      if (this.contractInfo?.priceTypeId == 1) {
        this.thePriceSpread = this.selectedInvoice.priceSpread;
      }
    }
  }

  setChangesMade(isChangeMade: boolean = true) {
    this.areChangesMade = isChangeMade;
  }

  okayToSave(): boolean {
    return this.areChangesMade && (!this.isRejectedSelected() || this.theRejectReason != null);
  }

  isRejectedSelected() {
    const isSelected = this.theInvoiceStatus == InvoiceStatus.REJECTED;
    if (!isSelected && this.selectedInvoice) {
      this.resetControls();
    }
    return isSelected;
  }

  setTabStatus(e: TabSwitchState) {
    this.isInvoiceDetailsTabSelected = e.isInvoiceDetailsTabSelected;
    this.isClaimInfoTabSelected = e.isClaimInfoTabSelected;
    this.isClaimHistorySelected = e.isClaimHistorySelected;

    setTimeout(() => {
      this.resetControls();
    }, 0);
  }

  updateInvoiceDetailsData(claimId) {
    if (!this.isValidationPanelOpen) {
      this.toggleValidatePanel();
    }
  }

  processThisClaim() {
    this.onProcessClaim.emit(this.selectedPatientInfo.claimPk);
  }

  copyData(text) {
    copyTextToClipboard(text);
  }

  public formatDate(dateStr) {
    if (dateStr) {
      return new Date(dateStr).toLocaleDateString();
    } else {
      return '';
    }
  }

  // Right Panel Operation
  public toggleValidatePanel() {
    this.isValidationPanelOpen = !this.isValidationPanelOpen;
  }

  public saveChanges() {
    const oldStatus = this.selectedInvoice.invoiceQStateID;
    this.selectedInvoice.invoiceQStateID = parseFloat(this.theInvoiceStatus.toString());
    this.selectedInvoice.rejectReasonId = this.theRejectReason ? parseInt(this.theRejectReason.toString()) : null;
    this.selectedInvoice.rejectNote = this.theRejectNote;
    this.selectedInvoice.priceSpread = this.thePriceSpread ? Number.parseFloat(this.thePriceSpread.toString()) : 0;

    if (this.thePriceSpread != 0 && this.theInvoiceStatus == InvoiceStatus.PENDING) {
      this.selectedInvoice.invoiceQStateID = this.theInvoiceStatus = InvoiceStatus.INVOICING;
    }

    if (
      this.theInvoiceStatus == InvoiceStatus.REJECTED &&
      oldStatus == InvoiceStatus.INVOICED
    ) {
      this.selectedInvoice.invoiceQStateID = this.theInvoiceStatus =
        InvoiceStatus.REJECTED_NEEDS_CREDIT;
    }

    this.selectedInvoice.status = mapInvoiceStatusToString(
      this.selectedInvoice.invoiceQStateID
    );

    if (this.selectedInvoice.priceSpread) {
      this.selectedInvoice.costAmount = this.selectedInvoice.priceSpread;
      this.selectedInvoice.caravanFee = this.selectedInvoice.costAmount * this.contractInfo.caravanPct;
    }
    
    this.prescriptionService.submitInvoices([this.selectedInvoice]).then(() => {
      this.toggleValidatePanel();
      this.onSaveInvoice.emit(true);
    });
  }
}
