<div class="list border">
  <div class="subheader list-ite padding--smaller">
    <div class="row">
      <!-- REVIEW - click is bound to container. Might we want to handle this a different way e.g. not using a native checkbox -->
      <div class="field u-display--flex u-align-items--top u-width--webkit-fill-available"  >
        <!-- <input type="checkbox" (click)="toggleIsActive()" [checked]="isActive" id="patient-task-{{id}}" /> -->
        <div *ngIf="!isDisabled && id">
          <input (change)="toggleIsActive()" id="patient-task-{{id}}" class="" type="checkbox" [(ngModel)]="isActive">
          <label for="patient-task-{{id}}" class="u-display--none task-checkbox-label"></label>
        </div>
        <div *ngIf="isTaskEditable()" (click)="openEditModal()" class="u-font-size--small task-title-hover u-cursor--pointer has-tooltip u-width--webkit-fill-available">
          <span class="tooltip">Click to edit</span>
          {{name}}
        </div>
        <div *ngIf="!isTaskEditable()" class="u-font-size--small u-width--webkit-fill-available">
          {{name}}
        </div>
      </div>
      <!-- <button class="button is-small padding--small" (click)="openEditModal()">E</button> -->
    </div>
  </div>
  <div class="list-item padding--smaller" *ngIf="startDate">
    <div class="row">
      <span>
        Starts: {{ startDate | date : "mediumDate" }}
      </span>
    </div>
  </div>
  <div class="list-item padding--smaller" *ngIf="endDate">
    <div class="row">
      <span>
        Ends: {{ endDate | date : "mediumDate" }}
      </span>
    </div>
  </div>
  <div class="list-item padding--smaller" *ngIf="recurrenceText">
    <div class="row">
      <span>
        Repeats: {{ recurrenceText | uppercase }}
      </span>
    </div>
  </div>
  <div class="list-item padding--smaller" *ngIf="goalText">
    <div class="row">
      <span>
        Goal: {{ goalText | titlecase }}
      </span>
    </div>
  </div>
  <div class="list-item padding--smaller">
    <div class="u-text-transform--uppercase">
      <div class="field">
        {{ taskTypeText }}
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="editModalIsOpen && clonedTask">
  <div role="modal-background" (click)="closeModalOnBackgroundClick($event.target)" class="overlay is-open">
    <div class="modal u-width--larger">
      <div class="header">
        <div class="titles">
          <div class="title">{{clonedTask.name}}</div>
          <!-- <div class="subtitle">{{clonedTask.includedObjects}}</div> -->
        </div>
      </div>
      <div class="dynamicBody padding" *ngIf="repeatingTask">
        <!-- START AND END DATES -->
        <div class="row u-justify-content--spaceBetween" >
          <div class="field padding--smallest" *ngIf="repeatingTask.configurableFields && repeatingTask.configurableFields.recurrence.startDate">
            <label class="margin-top label" 
            [ngClass]="{
              'u-color--brand--3': formatDateForDateInput(repeatingTask.configurableFields.recurrence.endDate.value) && !isDateBefore(repeatingTask.configurableFields.recurrence.startDate.value, repeatingTask.configurableFields.recurrence.endDate.value)
            }" for="startDate">Starts </label>
            <input type="date" id="startDate" 
            [ngClass]="{
              'u-color--brand--3': formatDateForDateInput(repeatingTask.configurableFields.recurrence.endDate.value) && !isDateBefore(repeatingTask.configurableFields.recurrence.startDate.value, repeatingTask.configurableFields.recurrence.endDate.value)
            }" 
            min="{{formatDateForDateInput(getOriginalDate('startDate'))&&isDateBefore(getOriginalDate('startDate'),today)?formatDateForDateInput(getOriginalDate('startDate')):formatDateForDateInput(today)}}" 
            max="{{repeatingTask.configurableFields && repeatingTask.configurableFields.recurrence && repeatingTask.configurableFields.recurrence.endDate.value ? formatDateForDateInput(repeatingTask.configurableFields.recurrence.endDate.value) : null}}" [(ngModel)]="repeatingTask.configurableFields.recurrence.startDate.value" />
          </div>
          <div class="field padding--smallest"  *ngIf="repeatingTask.configurableFields && repeatingTask.configurableFields.recurrence.endDate">
            <label class="margin-top label" for="endDate"
            [ngClass]="{
              'u-color--brand--3': formatDateForDateInput(repeatingTask.configurableFields.recurrence.endDate.value) && !isDateBefore(repeatingTask.configurableFields.recurrence.startDate.value, repeatingTask.configurableFields.recurrence.endDate.value)
            }" >Ends</label>
            <input type="date" id="endDate" 
            [ngClass]="{
              'u-color--brand--3': formatDateForDateInput(repeatingTask.configurableFields.recurrence.endDate.value) && !isDateBefore(repeatingTask.configurableFields.recurrence.startDate.value, repeatingTask.configurableFields.recurrence.endDate.value)
            }" 
            min="{{formatDateForDateInput(repeatingTask.configurableFields.recurrence.startDate.value)&&!isDateBefore(repeatingTask.configurableFields.recurrence.startDate.value,today)?formatDateForDateInput(repeatingTask.configurableFields.recurrence.startDate.value):formatDateForDateInput(today)}}" 
            [(ngModel)]="repeatingTask.configurableFields.recurrence.endDate.value" />
          </div>
        </div>
        <!-- repeatInterval -->
        <div class="row" *ngIf="repeatingTask.configurableFields.recurrence && repeatingTask.configurableFields.recurrence.repeatInterval">
          <div class="field padding--smallest u-width--full" >
            <label class="margin-top label" for="repeatIntervalSelector">Repeats</label>
            <select [(ngModel)]="repeatingTask.configurableFields.recurrence.repeatInterval.value" id="repeatIntervalSelector" name="repeatIntervalSelector">
              <option *ngFor="let response of repeatingTask.configurableFields && repeatingTask.configurableFields.recurrence.repeatInterval.responses" value="{{response.value}}">{{response.label}}</option>
            </select>
          </div>
        </div>

        <!-- Blood pressure  -->
        <ng-container *ngIf="bloodPressureTask">
           <!-- Blood pressure goal -->
          <div *ngIf="bloodPressureTask.goals">
            <div class="margin-top--large">
              <input id="bpGoalCheckbox" [(ngModel)]="bloodPressureTask.goals.isGoalTrackingEnabled" class="" type="checkbox">
              <label for="bpGoalCheckbox" class="u-display--none ">Blood Pressure Goal</label>
            </div>
            <div class="row">
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">Between</div>
                <div class="task-input-grid-element"></div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                max='{{bloodPressureTask.goals.current.systolic.rangeTop}}'
                [disabled]="!bloodPressureTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="bloodPressureTask.goals.current.systolic.rangeBottom" >
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                max='{{bloodPressureTask.goals.current.diastolic.rangeBottom}}'
                [disabled]="!bloodPressureTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="bloodPressureTask.goals.current.diastolic.rangeBottom" >
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">and</div>
                <div class="task-input-grid-element"></div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                min='{{bloodPressureTask.goals.current.systolic.rangeBottom}}'
                [disabled]="!bloodPressureTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="bloodPressureTask.goals.current.systolic.rangeTop" >
                
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                min='{{bloodPressureTask.goals.current.diastolic.rangeTop}}'
                [disabled]="!bloodPressureTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="bloodPressureTask.goals.current.diastolic.rangeTop" >
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">{{bloodPressureTask.goals.current.unit}}</div>
                <div class="task-input-grid-element"></div>
              </div>
            </div>
          </div>
           <!-- Blood pressure alert -->
          <div *ngIf="bloodPressureTask.systolicDiastolicAlertOn !== null">
            <div class="margin-top--large">
              <input id="bpAlertCheckbox" (change)="toggleBPAlertIsActive(bloodPressureTask)" [checked]="isBPAlertActive(bloodPressureTask)" class="" type="checkbox">
              <label for="bpAlertCheckbox" class="u-display--none ">Blood Pressure Alert </label>
            </div>
            <div class="row">
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">Below</div>
                <div class="task-input-grid-element"></div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                [(ngModel)]="bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[0]" 
                [disabled]="!isBPAlertActive(bloodPressureTask)"
                min="{{bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.lowerValue.min}}" 
                max="{{bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.lowerValue.max<bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[1]?bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.lowerValue.max:bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[1]}}">
                  <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                  [disabled]="!isBPAlertActive(bloodPressureTask)"
                  [(ngModel)]="bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.value[0]" 
                  min="{{bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.lowerValue.min}}" 
                  max="{{bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.lowerValue.max<bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.value[1]?bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.lowerValue.max:bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.value[1]}}">
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">or above</div>
                <div class="task-input-grid-element"></div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                [(ngModel)]="bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[1]"
                [disabled]="!isBPAlertActive(bloodPressureTask)" 
                min="{{bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.upperValue.min > bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[0] ? bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[0] : bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[0]}}" 
                max="{{bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.upperValue.max">
                
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                [disabled]="!isBPAlertActive(bloodPressureTask)"
                [(ngModel)]="bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.value[1]" 
                min="{{bloodPressureTask.configurableFields.diastolicAlerts.diastolicAlertRange.upperValue.min > bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[0] ? bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[0] : bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.value[0]}}" 
                max="{{bloodPressureTask.configurableFields.systolicAlerts.systolicAlertRange.upperValue.max">
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">{{bloodPressureTask.goals.current.unit}}</div>
                <div class="task-input-grid-element"></div>
              </div>
            </div>
          </div>
           <!-- Pulse alert -->
          <div *ngIf="bloodPressureTask.pulseAlertOn !== null">
            <div class="margin-top--large">
              <input id="pulseAlertCheckbox" [(ngModel)]="bloodPressureTask.configurableFields.pulseAlerts.pulseAlertIsActive.value" class="" type="checkbox">
              <label for="pulseAlertCheckbox" class="u-display--none ">Pulse Alert</label>
            </div>
            <div class="row">
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">Below</div> 
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                [disabled]="!bloodPressureTask.configurableFields.pulseAlerts.pulseAlertIsActive.value"
                [(ngModel)]="bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.value[0]" 
                min="{{bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.lowerValue.min}}" 
                max="{{bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.lowerValue.max > bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.value[1] ? bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.value[1] : bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.lowerValue.max}}">
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">or above</div> 
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                [disabled]="!bloodPressureTask.configurableFields.pulseAlerts.pulseAlertIsActive.value"
                [(ngModel)]="bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.value[1]"
                min="{{bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.upperValue.min > bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.value[0] ? bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.upperValue.min : bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.value[0]}}" 
                max="{{bloodPressureTask.configurableFields.pulseAlerts.pulseAlertRange.upperValue.max}}"
                >
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">bpm</div> 
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Weight tracking -->
        <ng-container *ngIf="weightTask">
           <!-- Weight goal -->
           <div *ngIf="weightTask.goals">
            <div class="margin-top--large">
              <input id="bpGoalCheckbox" [(ngModel)]="weightTask.goals.isGoalTrackingEnabled" class="" type="checkbox">
              <label for="bpGoalCheckbox" class="u-display--none ">Weight Tracking Goal</label>
            </div>
            <div class="row">
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">Between</div> 
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                max='{{weightTask.goals.current.weight.rangeTop}}'
                [disabled]="!weightTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="weightTask.goals.current.weight.rangeBottom" >
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">and</div> 
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                min='{{weightTask.goals.current.weight.rangeBottom}}'
                [disabled]="!weightTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="weightTask.goals.current.weight.rangeTop" >
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">{{weightTask.goals.current.unit}}</div>
              </div>
            </div>
          </div>
           <!-- Weight alert -->
          <div *ngIf="weightTask.weightAlertOn !== null">
            <div class="margin-top--large">
              <input id="bpAlertCheckbox" [(ngModel)]="weightTask.configurableFields.weightAlerts.weightAlertIsActive.value" class="" type="checkbox">
              <label for="bpAlertCheckbox" class="u-display--none ">Alert</label>
            </div>
            <div class="margin-top--smaller">Gained 3 lbs in 1 day OR</div>
            <div class="margin-top-small">Gained 5 lbs since the last measurement</div>
            <div class="margin-top-small">Alerting thresholds per 2017 ACC/AHA/HFSA guidelines</div>
          </div>
        </ng-container>

        <!-- Blood sugar -->
        <ng-container *ngIf="bloodSugarTask">
          <!-- Blood sugar goal -->
          <div *ngIf="bloodSugarTask.goals">
            <div class="margin-top--large">
              <input id="bpGoalCheckbox" [(ngModel)]="bloodSugarTask.goals.isGoalTrackingEnabled" class="" type="checkbox">
              <label for="bpGoalCheckbox" class="u-display--none ">Blood Sugar Goal</label>
            </div>
            <div class="row">
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">Between</div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                max='{{bloodSugarTask.goals.current.bloodSugar.rangeTop}}'
                [disabled]="!bloodSugarTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="bloodSugarTask.goals.current.bloodSugar.rangeBottom" >
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">and</div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" 
                min='{{bloodSugarTask.goals.current.bloodSugar.rangeBottom}}'
                [disabled]="!bloodSugarTask.goals.isGoalTrackingEnabled"
                [(ngModel)]="bloodSugarTask.goals.current.bloodSugar.rangeTop" >
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">{{bloodSugarTask.goals.current.unit}}</div>
              </div>
            </div>
          </div>
           <!-- Blood sugar alert -->
          <div *ngIf="bloodSugarTask.bloodSugarAlertOn !== null">
            <div class="margin-top--large">
              <input id="bpAlertCheckbox" [(ngModel)]="bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertIsActive.value" class="" type="checkbox">
              <label for="bpAlertCheckbox" class="u-display--none ">Blood Sugar Alert</label>
            </div>
            <div class="row">
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">Below</div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                [(ngModel)]="bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.value[0]" 
                [disabled]="!bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertIsActive.value"
                min="{{bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.lowerValue.min}}" 
                max="{{bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.lowerValue.max<bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.value[1]?bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.lowerValue.max:bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.value[1]}}">
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">or above</div>
              </div>
              <div class="task-input-grid-column">
                <input class="task-input-grid-element-textInput margin--smaller is-small" type="number" pattern="[0-9]*" 
                [disabled]="!bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertIsActive.value"
                [(ngModel)]="bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.value[1]" 
                min="{{bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.lowerValue.min > bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.value[0] ? bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.lowerValue.min : bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.value[0]}}" 
                max="{{bloodSugarTask.configurableFields.bloodSugarAlerts.bloodSugarAlertRange.lowerValue.max}}">
              </div>
              <div class="task-input-grid-column">
                <div class="task-input-grid-element">{{bloodSugarTask.goals.current.unit}}</div>
              </div>
            </div>
          </div>
        </ng-container>
        
      </div>
      <div class="u-display--flex u-flex-direction--row u-justify-content--spaceBetween u-width--full padding--small">
        <button class="button" (click)="toggleIsActive(!isActive)" [ngClass]="{'is-primary': !isActive}">
          <span *ngIf="isActive">Deactivate</span>
          <span *ngIf="!isActive">Activate</span>
        </button>
        <div class="u-display--flex u-flex-direction--row u-justify-content--right">
          <button class="button margin-right border--none" (click)="editModalIsOpen = false">Close</button>
          <button class="button is-primary" [disabled]="!isTaskValid()" (click)="saveTask()">Save</button>
        </div>
      </div>
      
    </div>
  </div>
</ng-container>