/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Access } from '../models/access';
import { UserAccessFull2Result } from '../models/user-access-full-2-result';

@Injectable({
  providedIn: 'root',
})
export class Access2Service extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation access2
   */
  static readonly Access2Path = '/access2';

  /**
   * Replacement for GQL access endpoint in coach-api (used by coach).
   *
   * GET /access2?tierid=AA01
   * Original API at https://github.com/caravanhealth/coach-api/blob/int/src/resolvers/Access.resolver.ts#L17
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `access2()` instead.
   *
   * This method doesn't expect any request body.
   */
  access2$Response(params: {

    /**
     * Internal code for tier node where access is being requested (case insensitive)
     */
    tierid: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Access>> {

    const rb = new RequestBuilder(this.rootUrl, Access2Service.Access2Path, 'get');
    if (params) {
      rb.query('tierid', params.tierid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Access>;
      })
    );
  }

  /**
   * Replacement for GQL access endpoint in coach-api (used by coach).
   *
   * GET /access2?tierid=AA01
   * Original API at https://github.com/caravanhealth/coach-api/blob/int/src/resolvers/Access.resolver.ts#L17
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `access2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  access2(params: {

    /**
     * Internal code for tier node where access is being requested (case insensitive)
     */
    tierid: string;
  },
  context?: HttpContext

): Observable<Access> {

    return this.access2$Response(params,context).pipe(
      map((r: StrictHttpResponse<Access>) => r.body as Access)
    );
  }

  /**
   * Path part for operation userAccessFull2
   */
  static readonly UserAccessFull2Path = '/userAccessFull2';

  /**
   * Replacement for userAccessFull (used by APIs).
   *
   * See also type definitions in dependents:
   * https://github.com/caravanhealth/wpapi/blob/trunk/src/coach-access2.ts#L42
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccessFull2()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccessFull2$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserAccessFull2Result>> {

    const rb = new RequestBuilder(this.rootUrl, Access2Service.UserAccessFull2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAccessFull2Result>;
      })
    );
  }

  /**
   * Replacement for userAccessFull (used by APIs).
   *
   * See also type definitions in dependents:
   * https://github.com/caravanhealth/wpapi/blob/trunk/src/coach-access2.ts#L42
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccessFull2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccessFull2(params?: {
  },
  context?: HttpContext

): Observable<UserAccessFull2Result> {

    return this.userAccessFull2$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserAccessFull2Result>) => r.body as UserAccessFull2Result)
    );
  }

}
