import { first } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { IPrescriptionDetail } from '@shared/models/prescription';

export enum ClaimStatusSteps {
  PENDING = 1000,
  NO,
  YES,
}

export enum ClaimStatus {
  PENDING = 1000,
  REJECTED,
  APPROVED,
  NEW,
  HAS_NOTE,
  PROCESSED,
  EXPIRED,
}

export enum InvoiceStatus {
  PENDING = 1,
  INVOICING,
  INVOICED,
  REJECTED,
  REJECTED_NEEDS_CREDIT,
  CREDITED
}

export enum InvoiceRejectionReason {
  NONE = 1000
}

export function mapInvoiceStatusToString(status: number): string {
  switch (status) {
    case InvoiceStatus.PENDING:
      return 'Pending';
    case InvoiceStatus.INVOICING:
        return 'Invoicing';
    case InvoiceStatus.INVOICED:
        return 'Invoiced';
    case InvoiceStatus.REJECTED:
      return 'Rejected';
    case InvoiceStatus.REJECTED_NEEDS_CREDIT:
      return 'Rejected Needs Credit';
    case InvoiceStatus.CREDITED:
      return 'Credited';
    default:
      return 'Unknown';
  }
}

export function mapOptionsToStrings(optionValue) {
  switch (optionValue) {
    case ClaimStatusSteps.NO:
      return 'No';
    case ClaimStatusSteps.YES:
      return 'Yes';
    case ClaimStatusSteps.PENDING:
    default:
      return 'Pending';
  }
}

export function mapStringsToOptions(str) {
  switch (str) {
    case 'no':
      return ClaimStatusSteps.NO;
    case 'yes':
      return ClaimStatusSteps.YES;
    case 'pending':
      return ClaimStatusSteps.PENDING;
  }
}

export function mapOverallOptionsToStrings(optionValue) {
  switch (optionValue) {
    case ClaimStatus.PENDING:
      return 'Pending';
    case ClaimStatus.REJECTED:
      return 'Rejected';
    case ClaimStatus.APPROVED:
      return 'Approved';
    case ClaimStatus.NEW:
      return 'New';
    case ClaimStatus.HAS_NOTE:
      return 'Has Note';
    case ClaimStatus.PROCESSED:
      return 'Processed';
    case ClaimStatus.EXPIRED:
      return 'Expired';
    default:
      return 'Unknown';
  }
}

export const statusSortPriorityMap = new Map();
statusSortPriorityMap.set(ClaimStatus.NEW, 1);
statusSortPriorityMap.set(ClaimStatus.HAS_NOTE, 2);
statusSortPriorityMap.set(ClaimStatus.PENDING, 3);
statusSortPriorityMap.set(ClaimStatus.REJECTED, 4);
statusSortPriorityMap.set(ClaimStatus.PROCESSED, 5);
statusSortPriorityMap.set(ClaimStatus.EXPIRED, 6);

export const invoiceStatusPriorityMap = new Map();
invoiceStatusPriorityMap.set(mapInvoiceStatusToString(InvoiceStatus.PENDING), 1);
invoiceStatusPriorityMap.set(mapInvoiceStatusToString(InvoiceStatus.INVOICING), 2);
invoiceStatusPriorityMap.set(mapInvoiceStatusToString(InvoiceStatus.INVOICED), 3);
invoiceStatusPriorityMap.set(mapInvoiceStatusToString(InvoiceStatus.REJECTED), 4);
invoiceStatusPriorityMap.set(mapInvoiceStatusToString(InvoiceStatus.REJECTED_NEEDS_CREDIT), 5);
invoiceStatusPriorityMap.set(mapInvoiceStatusToString(InvoiceStatus.CREDITED), 6);

export function getDefaultPrescriptionData(
  claimId,
  acO_ID,
  tier2_ID,
  tier3_ID,
  tier4_GrpID,
  coach340BClaimsID
): any {
  const now = new Date().toISOString();

  return {
    cuR_CLM_UNIQ_ID: claimId,

    acoId: acO_ID,
    tier2_id: tier2_ID,
    tier3_id: tier3_ID,
    tier4_grpId: tier4_GrpID,
    coach340BClaimsID: coach340BClaimsID,

    visit_Note_Visit_Date: null,
    visit_Note_Missing: 0,
    visit_Note_Diagnosis: '',
    visit_Note_Requested_Date: null,
    cE_Visit_Date: null,
    cE_Visit_Note_Missing: 0,
    cE_Provider: '',
    cE_Location: '',
    cE_Related_Referral_Date: null,
    cE_Related_Referral_Missing: 0,
    follow_Up_Plan: '',
    claim_Note: '',
    claim_Status: ClaimStatus.NEW,
    claim_Status_Set_By: '',
    claim_Status_Set_On_Date: null,

    // sysStartTime: new Date().toISOString(),
    // sysEndTime: new Date().toISOString()
  };
}

export function compareDates(fillDate, otherDate) {
  if (!otherDate || otherDate.toLocaleDateString() == '12/31/1969' || otherDate.toLocaleDateString() == 'mm/dd/yyyy' || otherDate.getFullYear() === 1970) {
    return false;
  }
  const format = 'Www, dd Mmm yyyy';
  fillDate = new Date(fillDate.toUTCString().slice(0, format.length));
  otherDate = new Date(otherDate.toUTCString().slice(0, format.length));
  return fillDate >= otherDate;
}

export function formatDate(dateStr) {
  return new Date(dateStr).toLocaleDateString("en-US", {month: '2-digit', year: '2-digit', day: '2-digit'});
}

export function formatDateFourDigitYear(dateStr) {
  return new Date(dateStr).toLocaleDateString("en-US", {month: '2-digit', year: 'numeric', day: '2-digit'});
}

export function removeLeadingZeros(str) {
  var result = '0';
  if (str) {
    result = str.replace(/0{2,}/, '');
    if (result.length === 0) {
      result = '0';
    }
  }
  return result;
}

// 340B CSV Export Functions

export function toCsv(table) {
  // Query all rows
  const rows = table.querySelectorAll('tr');

  return [].slice
      .call(rows)
      .map(function (row) {
          // Query all cells
          const cells = row.querySelectorAll('th,td');
          return [].slice
              .call(cells)
              .map(function (cell) {
                  return cell.textContent;
              })
              .join(',');
      })
      .join('\n');
}

export function download(text, fileName) {
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
  link.setAttribute('download', fileName);

  link.style.display = 'none';
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export function generateTableHead(table, data) {
  let thead = table.createTHead();
  let row = thead.insertRow();
  for (let key in data) {
    let th = document.createElement("th");
    let text = document.createTextNode(key);
    th.appendChild(text);
    row.appendChild(th);
  }
}

export function generateTable(table, data) {
  for (let element of data) {
    let row = table.insertRow();
    for (let key in element) {
      let cell = row.insertCell();
      let text = document.createTextNode(element[key]);
      cell.appendChild(text);
    }
  }
}

export function basicNameSplit(fullName: string) {
  const parts = fullName?.split(/\s+/);
  return parts ? [capitalize(parts[0]), capitalize(parts[parts.length - 1])] : [];
}

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
}
export class B340StringConstants {
  public EXPORT_MENU_TITLE: string = 'Exports';
  public EXPORT_MACRO_HELIX: string = 'Macro Helix';
  public EXPORT_CVS: string = 'CVS';
  public EXPORT_INVOICE_DATA = 'Invoice Data';

  public EXPORTS_PATIENT_LIST = 'Patient List';
  public EXPORTS_EXPORT = 'Export';

  public FILTER_SHARED_OPTION_LABELS_TPANAME = ['CMS', 'MacroHelix', 'WellPartner'];
}
