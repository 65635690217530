<div *ngIf="!question.disabled">
  <form [formGroup]="answerForm">
    <div class="compliance-form--row row--verticalCenter margin-bottom">
      <div class="compliance-form--mainColumn">
        <div class="row--verticalCenter">
          <div *ngIf="question.code"
            class="compliance-form--code u-font-size--small u-text-transform--uppercase u-font-weight--light u-opacity--lighter">
            {{ question.code }}
          </div>
          <div *ngIf="question.infoText">
            <coach-icon class="u-flex--right" (click)="toggleInfoText()" iconName="info" iconSize="small"
              iconColor="primary">
            </coach-icon>
          </div>
        </div>
        <div class="row">
          <div class="compliance-form--number">{{ question.number }}</div>
          <div class="compliance-form--questionText">
            {{ question.questionText }}
          </div>
        </div>
      </div>
      <div *ngIf="!question.checkListResponseOnly && !question.textResponseOnly && !question.fileUpload">

        <div class="compliance-form--answer">
          <div class="button-group is-primary">
            <div class="group">
              <input type="radio" id="radio1-{{ question.questionId }}" [value]="answer.no" formControlName="formAnswer" />
              <label for="radio1-{{ question.questionId }}" class="u-text-transform--capitalize">{{ answer.no }}</label>
            </div>
            <div class="group">
              <input type="radio" id="radio2-{{ question.questionId }}" [value]="answer.partial"
                formControlName="formAnswer" />
              <label for="radio2-{{ question.questionId }}" class="u-text-transform--capitalize">{{ answer.partial }}</label>
            </div>
            <div class="group">
              <input type="radio" id="radio3-{{ question.questionId }}" [value]="answer.yes" formControlName="formAnswer" />
              <label for="radio3-{{ question.questionId }}" class="u-text-transform--capitalize">{{ answer.yes }}</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="question.fileUpload">
        <div class="compliance-form--answer">

            <div class="button-group is-primary">
              <div class="group">
                <div class="fileUploadWrapper clump padding--small u-width--full row--verticalCenter" [ngClass]="{
                    'is-invalid': answerForm.invalid && answerForm.touched
                  }">
                  <div class="inputButtonCombo u-preserve margin-right--small">
                    <input type="file" (change)="onFileChange($event)" formControlName="fileUp" />
                    <button class="button-group is-primary" [ngClass]="{
                        'is-primary': uploadedFileName === 'No file chosen'
                      }">
                      {{
                      uploadedFileName === "No file chosen"
                      ? "Upload"
                      : "Change"
                      }}
                      File
                    </button>
                  </div>
                  <div>{{ uploadedFileName }}</div>
                </div>
                <div *ngIf="answerForm.invalid && answerForm.touched">
                  <div class="u-italic u-color--danger padding-top--small">
                    Must upload file
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
      <div *ngIf="question.checkListResponseOnly">
        <form [formGroup]="formCheck" class="clump padding--small">
        <div class="compliance-form--answer">
          <ng-container formArrayName="chkbxlst">
            <div *ngFor="
                let option of question.checkListOptions;
                let i = index
              ">
              <input type="checkbox" id="practice_{{ question.checkListOptions[i].checkId }}" [formControlName]="i" />
              <label for="practice_{{ question.checkListOptions[i].checkId }}">
                <span class="u-bold u-fill">{{
                  question.checkListOptions[i].checkText
                  }}</span>
              </label>
            </div>
          </ng-container>
        </div>
      </form>
      </div>
    </div>
    <div *ngIf="question.textResponseOnly">
      <div class="compliance-form--textArea">
        <textarea placeholder="Provide response here..." formControlName="formTextRespOnly">
        </textarea>
      </div>
    </div>

    <div class="compliance-form--textArea" [ngClass]="{ 'is-hidden': answerForm.value?.formAnswer !== answer.partial }">
      <textarea placeholder="Provide explanation here..." formControlName="formPartialText">
      </textarea>
    </div>
  </form>
</div>
<div class="overlay overlay--light is-open" style="position: fixed" *ngIf="isInfoTextOpen" (click)="toggleInfoText()">
  <div class="modal margin u-max-width--larger" (click)="$event.stopPropagation()">
    <div class="header u-align-items--center">
      <div class="titles">
        <div class="title">Question {{ question.number }}</div>
      </div>
      <button class="button is-icon border--none" (click)="toggleInfoText(); $event.stopPropagation()">
        <coach-icon iconName="x" iconColor="default"></coach-icon>
      </button>
    </div>
    <div class="dynamicBody padding">
      <div>{{ question.infoText }}</div>
    </div>
    <div class="footer">
      <button class="button is-primary" (click)="toggleInfoText(); $event.stopPropagation()">
        Close
      </button>
    </div>
  </div>
</div>
