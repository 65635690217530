import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import moment from 'moment';
import { PatientMeasure } from './patientMeasure';
import { Measure } from './measure';
@Injectable({
  providedIn: 'root'
})
export class QualityService {
  patientCache: { [key: string]: PatientMeasure[] } = {};

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private ToastrMessageService: ToastrMessageService
  ) {}

  async getMeasurePatients(
    tierId: string,
    tierNum: number,
    measureID: string,
    activeFilters: IQualityDashboardActiveFilters,
    patientTableFilteringId: string
  ): Promise<Array<PatientMeasure>> {
    const authToken = this.authService.getToken();
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };

    const url = `${environment.qualityInitiativeApiUrl}/api/qi/getMeasurePatients`;
    let params = new HttpParams()
      .set('measureID', measureID)
      .set('tierId', tierId)
      .set('tierNum', tierNum)
      .set('patientTableFilteringId', patientTableFilteringId);
    // .set('year', activeFilters.year)
    // .set('assignment', activeFilters.assignment)
    // .set('dataFeed', activeFilters.dataFeed)
    // .set('measureSet', activeFilters.measureSet);
    for (const filter in activeFilters) {
      params = params.append(filter, activeFilters[filter]);
    }
    const options = {
      headers: headers,
      params: params
    };

    const patientData = await this.httpClient.get<Array<PatientMeasure>>(url, options).toPromise();

    return patientData;
  }
  async getMeasures(
    tierId: string,
    measureID: string[] = [],
    activeFilters: IQualityDashboardActiveFilters
  ): Promise<Array<Measure>> {
    const authToken = this.authService.getToken();
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };

    const url = `${environment.qualityInitiativeApiUrl}/api/qi/getMeasures`;
    let params = new HttpParams().set('tierId', tierId);
    for (const measure in measureID) {
      params = params.append('measureID', measureID[measure]);
    }
    for (const filter in activeFilters) {
      params = params.append(filter, activeFilters[filter]);
    }
    const options = {
      headers: headers,
      params: params
    };

    //TODO: Really call API here
    //const measureData = await this.httpClient
    //     .get<Array<Measure>>(url, options)
    //     .toPromise();

    // TODO: Remove dummy measures data

    const measureData = [
      {
        measureId: 'id0',
        numerator: 9,
        denominator: 59,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 79,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 0',
        ehrFlag: true
      },
      {
        measureId: 'id1',
        numerator: 120,
        denominator: 124,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 100,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 1'
      },
      {
        measureId: 'id2',
        numerator: 75,
        denominator: 68,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 53,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 2'
      },
      {
        measureId: 'id3',
        numerator: 58,
        denominator: 114,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 24,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 3',
        ehrFlag: true
      },
      {
        measureId: 'id4',
        numerator: 16,
        denominator: 75,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 49,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 4',
        ehrFlag: true
      },
      {
        measureId: 'id5',
        numerator: 18,
        denominator: 84,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 58,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 5'
      },
      {
        measureId: 'id6',
        numerator: 59,
        denominator: 63,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 87,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 6'
      },
      {
        measureId: 'id7',
        numerator: 29,
        denominator: 92,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 9,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 7'
      },
      {
        measureId: 'id8',
        numerator: 18,
        denominator: 70,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 15,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 8'
      },
      {
        measureId: 'id9',
        numerator: 97,
        denominator: 66,
        numeratorExclusions: 0,
        denominatorExclusions: 0,
        target: 76,
        measureDescription:
          'Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period.',
        measureName: 'Measure 9'
      }
    ];
    // Simulate picking one measureid
    if (measureID.length == 1) {
      return measureData.filter(m => m.measureId === measureID[0]);
    }
    //simulate loading
    await new Promise(resolve => setTimeout(resolve, 2000));

    return measureData;
  }

  async getPatientsSummary(
    tierId: string,
    activeFilters: IQualityDashboardActiveFilters
  ): Promise<{
    totalPatients: number;
    patientsWithGapsClosed: number;
  }> {
    const authToken = this.authService.getToken();
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };

    const url = `${environment.qualityInitiativeApiUrl}/api/qi/getPatientSummary`;
    let params = new HttpParams().set('tierId', tierId);

    for (const filter in activeFilters) {
      params = params.append(filter, activeFilters[filter]);
    }
    const options = {
      headers: headers,
      params: params
    };

    //TODO: Really call API here
    // const patientSummary = await this.httpClient
    //     .get<Array<Measure>>(url, options)
    //     .toPromise();

    const patientSummary = {
      totalPatients: Math.floor(500 + Math.random() * 500),
      patientsWithGapsClosed: Math.floor(Math.random() * 500)
    };

    //simulate loading
    await new Promise(resolve => setTimeout(resolve, 2000));

    return patientSummary;
  }

  getMeasureMeetingTarget(measure: Measure): QualityDashboardMeetingTargets {
    const targetValue = Math.floor((measure.target / 100) * measure.denominator);
    const difference = measure.numerator - targetValue;

    if (difference >= 0) {
      return QualityDashboardMeetingTargets.Meeting;
    } else {
      // Here we are defining "approaching" as within 10% of the target number
      const approachingTarget = Math.floor(targetValue * 0.9);
      const approachingDifference = measure.numerator - approachingTarget;
      if (approachingDifference > 0) {
        return QualityDashboardMeetingTargets.Approaching;
      } else {
        return QualityDashboardMeetingTargets.Underperforming;
      }
    }
  }

  generateRandomDate(from, to): Date {
    return new Date(from.valueOf() + Math.random() * (to.valueOf() - from.valueOf()));
  }
  getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }
}
export interface IQualityDashboardActiveFilters {
  year?: string;
  assignment?: string;
  dataFeed?: string;
  measureSet?: string;
}
export interface IQualityDashboardActiveFilters {
  year?: string;
  assignment?: string;
  dataFeed?: string;
  measureSet?: string;
}
export enum QualityDashboardMeetingTargets {
  Underperforming = 0,
  Meeting = 1,
  Approaching = 2
}
