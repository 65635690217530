<div class="queue claim-validation border-left u-box-shadow--2" [ngClass]="{ 'is-validation-closed ': !isValidationPanelOpen }">
    <button class="pushTab top--xs disable-rotate" (click)="toggleValidatePanel()" [disabled]="!selectedPatientInfo" aria-label="Toggle Validation Panel"
    [ngClass]="{
      'is-validation-open': isValidationPanelOpen,
      'is-validation-disabled': !selectedPatientInfo
    }"
    >
    <coach-icon iconName="chevron-left"></coach-icon>
  </button>
    <div class="patientContent">

      <coach-patient-header [selectedPatientInfo]="patientDemographics" [showInstructions]="false"></coach-patient-header>

        <coach-invoicing-tab-switch [isInvoiceDetailsTabSelected]="isInvoiceDetailsTabSelected" [isClaimInfoTabSelected]="isClaimInfoTabSelected" [isClaimHistorySelected]="isClaimHistorySelected" (onTabClicked)="setTabStatus($event)"></coach-invoicing-tab-switch>

        <div *ngIf="isInvoiceDetailsTabSelected">
            <div style="margin: 1.25em">
                <p>
                    <span style="font-size: small; color: gray">Invoicing Status</span>
                    <select id="statusSelect" name="statusSelect" [ngModel]="theInvoiceStatus" (ngModelChange)="updateInvoiceStatus($event)">
            <option value="1" *ngIf="selectedInvoice && selectedInvoice.invoiceQStateID == 1">Pending</option>
            <option value="2" *ngIf="selectedInvoice && selectedInvoice.invoiceQStateID == 2">Invoicing</option>
            <option value="3" *ngIf="selectedInvoice && selectedInvoice.invoiceQStateID == 3">Invoiced</option>
            <option value="4" *ngIf="selectedInvoice && selectedInvoice.invoiceQStateID != 5 && selectedInvoice.invoiceQStateID != 6">Rejected</option>
            <option value="5" *ngIf="selectedInvoice && selectedInvoice.invoiceQStateID == 5">Rejected Needs Credit</option>
            <option value="6" *ngIf="selectedInvoice && selectedInvoice.invoiceQStateID == 6">Credited</option>
          </select>
                </p>

                <div style="display: inline-block;" *ngIf="contractInfo?.priceTypeId != 1">
                    <span style="font-size: small; color: gray; display: inline; margin-bottom: 4px;">Price Spread</span>
                    <span style="font-weight: bold; display: inline; margin-left: 160px;">
                <br /><input type="text" [ngClass]="{'greyOut': isRecordInEndStateStatus, priceSpread: true }" [(ngModel)]="thePriceSpread"
                    [disabled]="isRecordInEndStateStatus" (focus)="setChangesMade()"><br />
                </span>
                </div>

                <div class="display: inline-block;">
                    <br /><span style="font-size: small; color: gray">Reason for Rejection:</span
          ><br />
          <select aria-placeholder="Select rejection reason..." [disabled]="!isRejectedSelected()" [(ngModel)]="theRejectReason"
            (focus)="setChangesMade()">
            <option [ngValue]="1">Not financially feasible</option>
            <option [ngValue]="2">Claim too old</option>
            <option [ngValue]="3">Duplicate claim</option>
            <option [ngValue]="4">Patient has dual coverage with Medicaid</option>
            <option [ngValue]="5">Other</option>
          </select>
        </div>

        <span style="font-size: smaller">
          <span style="color: gray; margin-bottom: 4px;">Rejection Notes:</span>
                    <textarea [disabled]="!isRejectedSelected()" [(ngModel)]="theRejectNote" maxlength="50"
                      (focus)="setChangesMade()" ></textarea>
                    </span>
                </div>
            </div>

            <div class="dynamicBody padding" #noteContent *ngIf="isClaimInfoTabSelected">
                <strong><u>CARE COORDINATION NOTE</u></strong
      ><br />

      Patient Name: {{ patientName }}<br />
      DOB: {{ patientDobText }}<br />
      MBI: {{ patientMbi | mask: 6 }}<br />
      By: {{ prescriptionDetails.claim_Status_Set_By }}<br />
      Date: {{ formatDate(prescriptionDetails.claim_Status_Set_On_Date) }}<br /><br />


      <u>340B External Prescription</u><br />
      <strong>{{ selectedPatientInfo.prescriptionName }}</strong
      ><br />
      by {{ selectedPatientInfo.prescriberName }}, {{selectedPatientInfo.prescriberSpecialty}}<br />
      First filled {{ formatDate(selectedPatientInfo.firstFilled) }}<br /><br />

      
      <u>Visit Note from {{ selectedPatientInfo.prescriberName }}</u><br />

      <div>
        <p>
          Visit Date: {{ formatDate(prescriptionDetails.visit_Note_Visit_Date)
          }}<br />
          Rx Diagnosis: {{ prescriptionDetails.visit_Note_Diagnosis }}
        </p>
      </div>

      <div>
        <p style="text-decoration: underline; margin-bottom: 1px">
          Related Visit By Covered Entity:
        </p>
        <span>
          Visit Date: {{ formatDate(prescriptionDetails.cE_Visit_Date) }}<br />
          Provider: {{ prescriptionDetails.cE_Provider }}<br />
          CE Location: {{ prescriptionDetails.cE_Location }}<br />
        </span>
      </div>
      <br />

      <div>
        <p style="text-decoration: underline; margin-bottom: 1px">
          Related Referral By Covered Entity:
        </p>
        <span>
          Referral Date:
          {{ formatDate(prescriptionDetails.cE_Related_Referral_Date) }}<br />
        </span>
      </div>
      <br />

      <div>
        <p style="text-decoration: underline; margin-bottom: 1px">
          Follow-Up Plan:
        </p>
        <span [innerHTML]="prescriptionDetails.follow_Up_Plan"><br /> </span>
      </div>
      <br />
    </div>

    <div class="dynamicBody padding" *ngIf="isClaimHistorySelected">
      <div style="text-align: center;">
        <h4>Claim History (Validation + Invoice)</h4>
        <table style="margin-left: auto; margin-right: auto;">
          <thead style="border-bottom: 2px solid black;">
            <td>Status</td>
            <td>Date</td>
            <td>User</td>
          </thead>
          <tbody>
            <tr *ngIf="historyDetail.newStatus">
              <td>New</td>
              <td class="sideStriped">{{formatDate(historyDetail.newStatus.newDate)}}</td>
              <td>{{historyDetail.newStatus.newBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.approvedStatus">
              <td>Approved</td>
              <td class="sideStriped">{{formatDate(historyDetail.approvedStatus.approvedDate)}}</td>
              <td>{{historyDetail.approvedStatus.approvedBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.processedStatus">
              <td>Processed</td>
              <td class="sideStriped">{{formatDate(historyDetail.processedStatus.processedDate)}}</td>
              <td>{{historyDetail.processedStatus.processedBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.pendingStatus">
              <td>Pending Invoicing</td>
              <td class="sideStriped">{{formatDate(historyDetail.pendingStatus.pendingDate)}}</td>
              <td>{{historyDetail.pendingStatus.pendingBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.invoicingStatus">
              <td>Invoicing</td>
              <td class="sideStriped">{{formatDate(historyDetail.invoicingStatus.invoicingDate)}}</td>
              <td>{{historyDetail.invoicingStatus.invoicingBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.invoicedStatus">
              <td>Invoiced</td>
              <td class="sideStriped">{{formatDate(historyDetail.invoicedStatus.invoicedDate)}}</td>
              <td>{{historyDetail.invoicedStatus.invoicedBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.rejectedStatus">
              <td>Rejected</td>
              <td class="sideStriped">{{formatDate(historyDetail.rejectedStatus.rejectedDate)}}</td>
              <td>{{historyDetail.rejectedStatus.rejectedBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.needsCreditStatus">
              <td>Rejected Needs Credit</td>
              <td class="sideStriped">{{formatDate(historyDetail.needsCreditStatus.needsCreditDate)}}</td>
              <td>{{historyDetail.needsCreditStatus.needsCreditBy}}</td>
            </tr>
            <tr *ngIf="historyDetail.creditedStatus">
              <td>Credited</td>
              <td class="sideStriped">{{formatDate(historyDetail.creditedStatus.creditedDate)}}</td>
              <td>{{historyDetail.creditedStatus.creditedBy}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      [ngClass]="{footer: isInvoiceDetailsTabSelected}"
      style="
        /* background-color: #eee; */
        justify-content: space-around;
        position: absolute;
        bottom: 0;
        height: 65px;
        left: 80px;
      "
    >
      <button *ngIf="isInvoiceDetailsTabSelected"
        class="button is-primary"
        (click)="toggleValidatePanel()"
        style="margin-right: 40px;"
      >
        Cancel
      </button>

      <button *ngIf="isInvoiceDetailsTabSelected"
        class="button is-primary"
        (click)="saveChanges()"
        [disabled]="!okayToSave()"
      >
        Save & Close
      </button>

    </div>
  </div>
</div>