import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, SimpleChanges } from '@angular/core';
import {Patient} from '@shared/models/patient';
import {PatientService} from '@shared/services/patient/patient.service';
import {AuthService} from '@shared/services/auth-service/auth.service';
import {Outcome} from '@api/care-orch/models/outcome';
import {OutcomeType} from '@api/care-orch/models/outcome-type';
import {AttendedActivity} from '@api/care-orch/models/attended-activity';
import {AwvWorkflow} from '@api/care-orch/models/awv-workflow';
import {SchedulingActivity} from '@api/care-orch/models/scheduling-activity';
import {ScheduledOutcome} from '@api/care-orch/models/scheduled-outcome';
import {ActivityType} from '@api/care-orch/models/activity-type';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import { AwvWorkflowService } from '@shared/services/awv-workflow/awv-workflow.service';
import { OnChanges } from '@angular/core';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';

@Component({
  selector: 'coach-patient-step-completed',
  templateUrl: './patient-step-completed.component.html',
  styleUrls: ['./patient-step-completed.component.scss']
})
export class PatientStepCompletedComponent implements OnInit, OnChanges{
  @Input() public mobileScreenSize: boolean;
  @Input() public awvWorkflow: AwvWorkflow;
  @Input() public patient: Patient;
  @Input() sidebarMode?: boolean;
  @Output() public activitySaved: EventEmitter<AttendedActivity> = new EventEmitter();
  @Output() public activityDeleted: EventEmitter<ActivityType> = new EventEmitter();

  public isActivityComplete = false;
  isTabOpen = false;
  scheduledOutcome: ScheduledOutcome;
  scheduledDate = '';
  public outcomeTypes = OutcomeType;
  public completedOutcome: Outcome;
  today: string = WorkflowDateTimeUtil.todayDateOnlyString();

  constructor(private patientService: PatientService,
              private authService: AuthService,
              @Inject(LOCALE_ID) private userLocale: string,
              private awvWorkflowService: AwvWorkflowService,
              private workflowEvents: WorkflowEventsService) {
}

  ngOnInit(): void {
  this.awvWorkflowService.updateSuccessful$.subscribe(() => this.isTabOpen = false);
  }
  ngOnChanges(_: SimpleChanges): void {
    if (this.awvWorkflow) {
      this.completedOutcome = null;
      this.isActivityComplete = false;
      this.scheduledOutcome = null;
      this.scheduledDate = '';
      const completeActivities = this.awvWorkflow.completedActivities
        .filter( x  => x.type === 'AttendedActivity').map(x => x as AttendedActivity);
      if(completeActivities.length > 0) {
        this.completedOutcome = completeActivities.pop().outcome;
        if(this.completedOutcome) {
          this.isActivityComplete = true;
        }
      }
      const schedulingActivities = this.awvWorkflow.completedActivities
        .filter( x  => x.type === 'SchedulingActivity').map(x => x as SchedulingActivity);
      if (schedulingActivities.length > 0){
        this.scheduledOutcome = schedulingActivities.find(x => x.outcome.type === OutcomeType.ScheduledOutcome).outcome as ScheduledOutcome;
        if (this.scheduledOutcome){
          const scheduledOutcomeProperties = this.scheduledOutcome.properties;
          this.scheduledDate =  WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(scheduledOutcomeProperties.date);
        }
      }
    }
  }

  outcomeSaved(outcome: Outcome): void {
    const activity = {
      type: 'AttendedActivity',
      outcome
    } as AttendedActivity;

    if (this.completedOutcome) {
      this.workflowEvents.trackEdit(activity);
    } else {
      this.workflowEvents.trackSave(activity);
    }

    this.activitySaved.emit(activity);
  }
  deleteActivity(activityType: ActivityType): void {
    this.activityDeleted.emit(activityType);
  }
}


