import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { AwvWorkflow } from '@api/care-orch/models/awv-workflow';
import { SchedulingActivity } from '@api/care-orch/models/scheduling-activity';
import {ActivityType, CompletedOutcome, CompletedProperties, Outcome, OutcomeType} from '@api/care-orch/models';
import {OnChanges} from '@angular/core';
import {PracticeService} from '@shared/services/hierarchy/practice.service';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {AwvService} from '@api/care-orch/services/awv.service';
import {Observable} from 'rxjs';
import { AwvWorkflowService } from '@shared/services/awv-workflow/awv-workflow.service';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';

@Component({
  selector: 'coach-patient-awv-completed',
  templateUrl: './patient-awv-completed.component.html',
  styleUrls: ['./patient-awv-completed.component.scss']
})
export class PatientAwvCompletedComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public awvWorkflow: AwvWorkflow;
  @Input() public mobileScreenSize: boolean;
  @Input() sidebarMode?: boolean;
  @Input() public isTabOpen: boolean;
  @Output() awvWorkflowUpdated: EventEmitter<Observable<AwvWorkflow>> = new EventEmitter();
  @Output() public activityDeleted: EventEmitter<ActivityType> = new EventEmitter();

  completedOutcomeProperties = {} as CompletedProperties;
  practiceName = '';

  constructor(private awvService: AwvService,
              private practiceService: PracticeService,
              private awvWorkflowService: AwvWorkflowService,
              private workflowEvents: WorkflowEventsService) {}

  ngOnInit(): void {
    this.awvWorkflowService.updateSuccessful$.subscribe(() => this.isTabOpen = false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.awvWorkflow){
      const schedulingActivities = this.awvWorkflow.completedActivities
        .filter( x  => x.type === 'SchedulingActivity').map(x => x as SchedulingActivity);
      const completedOutcome = schedulingActivities.find(x => x.outcome.type === OutcomeType.CompletedOutcome).outcome as CompletedOutcome;
      this.completedOutcomeProperties = completedOutcome.properties;
      if (completedOutcome.properties.practiceId) {
        this.practiceService.getPracticeById(this.patient, completedOutcome.properties.practiceId).then(p => this.practiceName = p);
      }
    }
  }

  outcomeSaved(outcome: Outcome): void {
    const activityToSave = { type: ActivityType.SchedulingActivity, outcome } as SchedulingActivity;

    if (this.completedOutcomeProperties) {
      this.workflowEvents.trackEdit(activityToSave);
    } else {
      this.workflowEvents.trackSave(activityToSave);
    }

    const obsUpdatedWorkflow = this.awvService.updateWorkflowActivity({
      programId: CareOrchestrationConstants.AwvProgramId,
      chPatId: this.patient.trackAwvPatient?.chPatID || Number(this.patient.trackPatient.chPatId),
      body: activityToSave
    });

    this.awvWorkflowUpdated.emit(obsUpdatedWorkflow);
  }

  deleteSchedulingActivity(): void {
    this.activityDeleted.emit(ActivityType.SchedulingActivity);
  }

}
