/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PopHealthMarginInsertUpdateRequest } from '../models/pop-health-margin-insert-update-request';
import { RefreshDates } from '../models/refresh-dates';
import { ValueCalculatorDetailsResponse } from '../models/value-calculator-details-response';
import { ValueCalculatorNetValueItem } from '../models/value-calculator-net-value-item';
import { ValueCalculatorPopHealthMargin } from '../models/value-calculator-pop-health-margin';
import { ValueNetworkUtilizationItem } from '../models/value-network-utilization-item';
import { ValuePopHealthItem } from '../models/value-pop-health-item';
import { ValueSharedSavingsItem } from '../models/value-shared-savings-item';

@Injectable({
  providedIn: 'root',
})
export class ValueCalculatorService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation valueCalculatorGet
   */
  static readonly ValueCalculatorGetPath = '/ValueCalculator';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ValueCalculatorDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValueCalculatorDetailsResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<ValueCalculatorDetailsResponse> {

    return this.valueCalculatorGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ValueCalculatorDetailsResponse>) => r.body as ValueCalculatorDetailsResponse)
    );
  }

  /**
   * Path part for operation valueCalculatorSharedSavingsGet
   */
  static readonly ValueCalculatorSharedSavingsGetPath = '/ValueCalculator/sharedSavings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorSharedSavingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorSharedSavingsGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValueSharedSavingsItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorSharedSavingsGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValueSharedSavingsItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorSharedSavingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorSharedSavingsGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<ValueSharedSavingsItem>> {

    return this.valueCalculatorSharedSavingsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValueSharedSavingsItem>>) => r.body as Array<ValueSharedSavingsItem>)
    );
  }

  /**
   * Path part for operation valueCalculatorNetworkUtilizationGet
   */
  static readonly ValueCalculatorNetworkUtilizationGetPath = '/ValueCalculator/networkUtilization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorNetworkUtilizationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorNetworkUtilizationGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValueNetworkUtilizationItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorNetworkUtilizationGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValueNetworkUtilizationItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorNetworkUtilizationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorNetworkUtilizationGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<ValueNetworkUtilizationItem>> {

    return this.valueCalculatorNetworkUtilizationGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValueNetworkUtilizationItem>>) => r.body as Array<ValueNetworkUtilizationItem>)
    );
  }

  /**
   * Path part for operation valueCalculatorPopHealthGet
   */
  static readonly ValueCalculatorPopHealthGetPath = '/ValueCalculator/popHealth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorPopHealthGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorPopHealthGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValuePopHealthItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorPopHealthGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValuePopHealthItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorPopHealthGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorPopHealthGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<ValuePopHealthItem>> {

    return this.valueCalculatorPopHealthGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValuePopHealthItem>>) => r.body as Array<ValuePopHealthItem>)
    );
  }

  /**
   * Path part for operation valueCalculatorGetRefreshDatesGet
   */
  static readonly ValueCalculatorGetRefreshDatesGetPath = '/ValueCalculator/getRefreshDates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorGetRefreshDatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorGetRefreshDatesGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RefreshDates>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorGetRefreshDatesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RefreshDates>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorGetRefreshDatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorGetRefreshDatesGet(params?: {
  },
  context?: HttpContext

): Observable<RefreshDates> {

    return this.valueCalculatorGetRefreshDatesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<RefreshDates>) => r.body as RefreshDates)
    );
  }

  /**
   * Path part for operation valueCalculatorPopHealthMarginGet
   */
  static readonly ValueCalculatorPopHealthMarginGetPath = '/ValueCalculator/popHealthMargin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorPopHealthMarginGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorPopHealthMarginGet$Response(params?: {
    TierNum?: number;
    TierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ValueCalculatorPopHealthMargin>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorPopHealthMarginGetPath, 'get');
    if (params) {
      rb.query('TierNum', params.TierNum, {});
      rb.query('TierId', params.TierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValueCalculatorPopHealthMargin>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorPopHealthMarginGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorPopHealthMarginGet(params?: {
    TierNum?: number;
    TierId?: string;
  },
  context?: HttpContext

): Observable<ValueCalculatorPopHealthMargin> {

    return this.valueCalculatorPopHealthMarginGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ValueCalculatorPopHealthMargin>) => r.body as ValueCalculatorPopHealthMargin)
    );
  }

  /**
   * Path part for operation valueCalculatorPopHealthMarginPost
   */
  static readonly ValueCalculatorPopHealthMarginPostPath = '/ValueCalculator/popHealthMargin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorPopHealthMarginPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  valueCalculatorPopHealthMarginPost$Response(params?: {
    body?: PopHealthMarginInsertUpdateRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorPopHealthMarginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorPopHealthMarginPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  valueCalculatorPopHealthMarginPost(params?: {
    body?: PopHealthMarginInsertUpdateRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.valueCalculatorPopHealthMarginPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation valueCalculatorNetValueGet
   */
  static readonly ValueCalculatorNetValueGetPath = '/ValueCalculator/netValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueCalculatorNetValueGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorNetValueGet$Response(params?: {
    TierNum?: number;
    TierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValueCalculatorNetValueItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ValueCalculatorService.ValueCalculatorNetValueGetPath, 'get');
    if (params) {
      rb.query('TierNum', params.TierNum, {});
      rb.query('TierId', params.TierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValueCalculatorNetValueItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `valueCalculatorNetValueGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueCalculatorNetValueGet(params?: {
    TierNum?: number;
    TierId?: string;
  },
  context?: HttpContext

): Observable<Array<ValueCalculatorNetValueItem>> {

    return this.valueCalculatorNetValueGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValueCalculatorNetValueItem>>) => r.body as Array<ValueCalculatorNetValueItem>)
    );
  }

}
