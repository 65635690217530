import { Injectable } from '@angular/core';
import { CareFeatureConstants, CareOrchestrationConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { Access } from '@shared/services/graphql/access.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { IPatientFilterPreset, IPatientFilterPresetGroup } from '@shared/models/patient-filters';
import { map, switchMap } from 'rxjs/operators';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';

@Injectable({
  providedIn: 'root'
})
export class CarePatientsPresetGroupService {
  userAccess: Access = null;
  EHRflag: string = CareFeatureConstants.EHR;
  AgGridCustomFilteringFlag: string = CareFeatureConstants.AgGridCustomFilteringFlag;
  PatientListsUpdateFlag: string = CareFeatureConstants.PatientListsUpdateFlag;
  ClinicalProgramsListFlag: string = CareFeatureConstants.ClinicalProgramsListFlag;
  HCCVIPCohortList: string = CareFeatureConstants.HCCVIPCohortList;
  PACNavigatorProgram: string = CareFeatureConstants.PACNavigatorProgram;
  IHECohort: string = CareFeatureConstants.IHECohort;
  SMSOutreachProgram: string = CareFeatureConstants.SMSOutreachProgram;
  InHomeAWVScheduleList: string = CareFeatureConstants.InHomeAWVScheduleList;
  CareOrchAccessReqs = CareOrchestrationConstants.GridViewAccessRequirements;
  HCCListRevamp: string = CareFeatureConstants.HCCListRevamp;
  CombineSMSCohorts: string = CareFeatureConstants.CombineSMSCohorts;
  isHCCVIPCohortListEnabled = false;
  isPACNavigatorProgramEnabled = false;
  isPatientListsUpdateFlagEnabled = false;
  isIHECohortEnabled = false;
  isSMSOutreachProgramEnabled = false;
  isCombineSMSCohortsEnabled = false;
  isInHomeAWVScheduleList = false;
  isHCCListRevampEnabled = false;
  isCombinedSMSCohortsEnabled = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CombineSMSCohorts);
  smsAlerts: number = 0;

  activeOrgAlerts: Array<wpapi.model.Alert & { filterDesc?: string; userId?: string }> = [];
  presetsColumns: { [key: string]: Array<{ [key: string]: boolean | string }> };
  
  constructor(private featureFlagService: FeatureFlagService, private userAccessService: UserAccessService) {
    this.userAccessService.currentAccess$
      .pipe(
        map(access => {
          if (access == null) {
            return;
          }
          this.userAccess = access;
        }),
        switchMap(() => this.featureFlagService.featureFlags$),
        map(() => {
          this.intializeFeatureFlags();
        })
      )
      .subscribe(() => {
        // this.initializeColumns();
        // this.initializePresetGroups();
      });
  }

  intializeFeatureFlags(): void {
    this.isPatientListsUpdateFlagEnabled =
      this.featureFlagService.hasFeatureFlag(this.PatientListsUpdateFlag);
    this.isHCCVIPCohortListEnabled =
      this.featureFlagService.hasFeatureFlag(this.HCCVIPCohortList);
    this.isPACNavigatorProgramEnabled =
      this.featureFlagService.hasFeatureFlag(this.PACNavigatorProgram);
    this.isHCCListRevampEnabled = this.featureFlagService.hasFeatureFlag(
      this.HCCListRevamp
    );
    this.isIHECohortEnabled = this.featureFlagService.hasFeatureFlag(
      this.IHECohort
    );
    this.isSMSOutreachProgramEnabled =
      this.featureFlagService.hasFeatureFlag(this.SMSOutreachProgram);
    this.isInHomeAWVScheduleList = this.featureFlagService.hasFeatureFlag(
      this.InHomeAWVScheduleList);
    this.isCombineSMSCohortsEnabled = this.featureFlagService.hasFeatureFlag(this.CombineSMSCohorts);

  }

  private hasCareOrchFeatureFlagAndComponents(): boolean {
    return (
      this.featureFlagService.hasFeatureFlag(this.CareOrchAccessReqs.featureFlagId) &&
      this.CareOrchAccessReqs.componentIds.every(id =>
        this.userAccessService.hasComponent(this.userAccessService.currentAccess$.value, id)
      )
    );
  }

  /**
   * filters presets that meet featureFlagRequired and hideIfFeatureFlagIsActive conditions
   * @param presetGroups
   * @returns
   */
  filterPresetGroupsByFeatureFlag(presetGroups: IPatientFilterPresetGroup[]): IPatientFilterPresetGroup[] {
    return presetGroups.filter(pg => {
      if (pg.featureFlagRequired) {
        return this.featureFlagService.hasFeatureFlag(pg.featureFlagRequired);
      }
      if (pg.hideIfFeatureFlagIsActive) {
        return !this.featureFlagService.hasFeatureFlag(pg.hideIfFeatureFlagIsActive);
      }
      return true;
    });
  }

  splitStringIntoWords(string: string): string {
    return (
      string[0] +
      string
        .slice(1, string.length)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }
}
