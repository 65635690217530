import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { wpapi } from "@hcd-caravanhealth/pkg-wptypes";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth-service/auth.service';
import moment from 'moment';
import _ from 'lodash';
import { Protocol, Module } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/protocol';
import { Task } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/task.base';
import { Completion } from '../patient/patient.service';
export class IProtocol implements Protocol{
  name: string;
  isActive: boolean;
  isModuleSelectorIsOpen?: boolean;
  isSelected?: boolean;
  modules?: any[] // for some reason modules is Array<{protocolId: string;}>
  tasks?: Task[]
}
export class IModule implements Module {
  isActivatedBySystemOnly: boolean;
  isAvailable?: boolean;
  protocolId?: string;
  protocolLineage?: string[];
  state?: string;
  stateHistory?: any[];
  isNotAssignable: boolean;
  assignByDefault: boolean;
  name: string;
  isActive: boolean;
}
@Injectable({
  providedIn: 'root'
})

export class OrgService {
  wpBaseURL: string = environment.wpApiUrl;
  trackBaseURL: string = environment.trackApiUrl;
  // REVIEW - seems like this pattern could be genericized
  orgsLocalStorage: {[id:string]:Promise<wpapi.model.Organization>} = {};
  protocolsByOrgLocalStorage: {[orgId:string]:Promise<Array<wpapi.model.Protocol>>} = {};
  professionalsByOrgLocalStorage: {[orgId:string]:Promise<Array<wpapi.model.Professional>>} = {};
  modulesLocalStorage: any = {};

  constructor(private http: HttpClient,
    private _authService: AuthService) {
  }

  /**
   * Helper function to do HTTP get and
   * translate 404s to null (rather than throwing an exception)
   */
  async getHelper<T>(url:string):Promise<T> {
    const headers = {
      Authorization: 'Bearer ' + this._authService.IdToken,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };

    return new Promise((resolve, reject) => {
      this.http.get<T>(url, { headers: headers }).subscribe({
        next: (result) => { resolve(result); },
        error: (err:HttpErrorResponse) =>  {
          if (err.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        }});
      });
  }

  async getOrg(orgId: string):Promise<wpapi.model.Organization> {
    if(this.orgsLocalStorage[orgId]){
      const org = await this.orgsLocalStorage[orgId];
      return _.cloneDeep(org);
    }

    const url = `${this.wpBaseURL}/orgs/${orgId}`;
    const promise = this.getHelper<wpapi.model.Organization>(url);
    this.orgsLocalStorage[orgId] = promise;
    return promise.then(x=>_.cloneDeep(x));
  }

  async getProtocolsForOrg(orgId: string):Promise<Array<wpapi.model.Protocol>> {
    if (this.protocolsByOrgLocalStorage[orgId]) {
      const protocols = await this.protocolsByOrgLocalStorage[orgId];
      return _.cloneDeep(protocols);
    }
    const url = `${this.wpBaseURL}/orgs/${orgId}/protocols`;
    const promise = this.getHelper<Array<wpapi.model.Protocol>>(url);
    this.protocolsByOrgLocalStorage[orgId] = promise;
    return promise.then(x=>_.cloneDeep(x));
  }
  async getProfessionalsForOrg(orgId: string): Promise<wpapi.model.Professional[]> {
    if(this.professionalsByOrgLocalStorage[orgId]){
      const professionals = await this.professionalsByOrgLocalStorage[orgId];
      return _.cloneDeep(professionals);
    }
    const url = `${this.wpBaseURL}/orgs/${orgId}/pros`;
    const promise = this.getHelper<wpapi.model.Professional[]>(url);
    this.professionalsByOrgLocalStorage[orgId] = promise;
    return promise.then(x=>_.cloneDeep(x));
  }


  async getModuleName(moduleId: string) {
    if(this.modulesLocalStorage[moduleId]){
      return _.cloneDeep(this.modulesLocalStorage[moduleId])
    }
    let headers = {
      Authorization: 'Bearer ' + this._authService.IdToken,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };
    let url = `${this.wpBaseURL}/protocols/${moduleId}`;
    let protocol = await this.http.get(url, { headers: headers }).toPromise();
    this.modulesLocalStorage[moduleId] = protocol
    return _.cloneDeep(protocol)
  }
  
  async getTimeTrackingEventsForOrg(orgId: string, startDate: string, endDate: string, patientDetails: boolean, professionalDetails: boolean): Promise<{count: number, events: (wpapi.model.TimeTrackingEvent & any)[], patients: wpapi.model.Patient[], professionals: wpapi.model.Professional[]}> {
    let options =  {
      headers:{
        Authorization: 'Bearer ' + this._authService.IdToken,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      },
      params: {
        patientDetails: patientDetails ? "1" : "0",
        professionalDetails: professionalDetails ? "1" : "0"
      }
    }

    let url = `${this.wpBaseURL}/orgs/${orgId}/timeTrackingEvents?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`; // date format yyyy-MM-dd
    let timeTrackingEvents = await this.http.get(url, options).toPromise() as Promise<{count: number, events: (wpapi.model.TimeTrackingEvent)[], patients: wpapi.model.Patient[], professionals: wpapi.model.Professional[]}>;
    return timeTrackingEvents
  }
  async getTasksByOrg(orgId: string, taskType?: string): Promise<wpapi.model.Task[]> {
    let url = `${this.wpBaseURL}/orgs/${orgId}/tasks/${taskType}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };

    try {
      return (await this.http.get(url, options).toPromise()) as Promise<wpapi.model.Task[]>;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        alert('Failed to get tasks by org - API call failed: ' + e.message || e.status);
      }
      // this.ToastrMessageService.error(e);
      console.error(e);
    }
  };
  async getCompletionsByOrg(orgId: string, taskType?: string): Promise<Completion[]> {
    let url = `${this.wpBaseURL}/orgs/${orgId}/completions/${taskType}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };

    try {
      return await this.http.get<Completion[]>(url, options).toPromise();
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        alert('Failed to get completions by org - API call failed: ' + e.message || e.status);
      }
      // this.ToastrMessageService.error(e);
      console.error(e);
    }
  };

  /** Get protocol object by id. Get all the tasks flattened (from subprotocols) */
  async getProtocol(protocolId: string): Promise<wpapi.model.Protocol> {
    const url = `${this.wpBaseURL}/protocols/${protocolId}?expanded=true`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    return (await this.http
      .get(url, options)
      .toPromise()) as Promise<wpapi.model.Protocol>;
  }
}
