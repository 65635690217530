import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { CareFeatureConstants, PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class AwvSchedulePreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.AnnualWellnessVisits: PresetCategory.Worklists;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.AwvSchedule,
      presetKey: CarePatientItem.AwvSchedule,
      label: 'In Home AWV Schedule',
      filters: [],
      componentId: this.isInHomeAWVScheduleList ? CareComponentConstants.IHESchedule : null,
      hidePreset: true,
      filterSettings: null,
      listDefinition: 'View patient status in the In-Home Annual Wellness Visit program',
      columns: this.getPresetColumns()
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    return [
      { select: true },
      { mbi: true },
      { fullName: true },
      { dob: true },
      { gender: true },
      { phoneNumber: true },
      { address: true },
      { scheduleStatus: true },
      { schedulingDescription: true },
      { callDate: true },
      { dateOfService: true },
      { assignment: true },
      { renderingProvider: true },
      { attributedPractice: true },
      { attributedCP: true },
      { hccGapCount: true },
      { predictedRiskLevel: true },
      { lastAWVDate: true },
      { lastCCMDate: true },
      { ccmEligible: true },
      { lastVisit: true },
      { diseaseCohorts: true },
      { totalCost: true },
      { edVisits: true },
      { openRAFWt: true },
      { raF_YTD: true }
    ];
  }
}
