<meta name="viewport" content="width=device-width, initial-scale=1">
<div class = "content-body">
<div class="scroll">    
    <div class="header">
        <div class="header-rectangle" style="background-color: var(--brand--1);">
            <h2 class="header-title">Contacts</h2>
        </div>
        <img class="img-fluid header-image" src="assets\img\illustrations\SH_PEP_Illustrations_Improve_Sum_ACO_1700x1100_20221114.svg"/>
    </div>

  <br>
  <br>
  <br>
    <table class="description">
        <tr>
            <td class = "table-question"> 
            <h4 class="aco-question">Client Contacts</h4>
            </td> 
            <td class = "table-answer">
            <p class="aco-answer">
                An up to date contacts list ensures vital information reaches the correct members of your organization and drives Coach access levels.
            </p>
        </td>
        </tr>

        <tr>
            <td class = "table-question"> 
            <h4 class="aco-question">Clinician Roster</h4>
            </td> 
            <td class = "table-answer">
            <p class="aco-answer">
                Updating your clinician roster with Signify Health is a requirement of your participation agreement and keeps your Coach hierarchy data accurate and up to date.
            </p>
        </td>
        </tr>

        <tr>
            <td class = "table-question"> 
            <h4 class="aco-question">My Signify Health Team</h4>
            </td> 
            <td class = "table-answer">
            <p class="aco-answer">
                This is a directory of Signify Health contacts who can help your team be successful.
            </p>
            </td>
        </tr>
    </table>

    
</div> 
</div>
