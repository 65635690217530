import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[isNotTierNum]',
})
export class IsNotTierNumDirective extends KillSubscriptions {
  private hasView = false;
  tierNum: number;

  @Input()
  set isNotTierNum(value: number) {
    this.tierNum = value;
    this.updateTier(this.hierarchyTierService.currentTier$.value);
  }

  constructor(
    protected hierarchyTierService: HierarchyTierService,
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef
  ) {
    super();
    hierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe((tier) => {
        this.updateTier(tier);
      });
  }

  updateTier(tier: IHierarchyTier): void {
    if (this.tierNum && this.tierNum !== tier.tier && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (this.tierNum === tier.tier && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
