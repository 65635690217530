<div class="action-dropdown dropdown u-width--small">
  <div class="list u-background-color--white--0">
    <ng-container *hasComponent="PatientEngagementComponentIds">
      <div class="list-item padding--smallest" *ngIf="orgId">
        <button class="button padding-top--none padding-bottom--none is-borderless u-width--full"
          [ngClass]="{'u-disabled': patient.hideTabsAndDisableTimeTracking}"
          [disabled]="patient.hideTabsAndDisableTimeTracking"
          (click)="emitAction('modalPatientInviteForm')">
          <span *ngIf="!isPatientInvited">Invite patient</span>
          <span *ngIf="isPatientInvited">Resend invitation</span>
        </button>
      </div>
    </ng-container>

    <ng-container *hasComponent="PatientEngagementComponentIds">
      <div class="list-item padding--smallest" *ngIf="orgId">
        <button class="button padding-top--none padding-bottom--none is-borderless u-width--full"
          [ngClass]="{'u-disabled': !isPatientInvited || patient.hideTabsAndDisableTimeTracking}"
          [disabled]="!isPatientInvited || patient.hideTabsAndDisableTimeTracking"
          (click)="emitAction('modalPatientEditForm')">Edit patient</button>
      </div>
    </ng-container>

    <div class="list-item padding--smallest u-display--flex u-flex-direction--row" *ngIf="patient.trackPatient">
      <button class="button padding-top--none padding-bottom--none is-borderless" (click)="printPdfClick()">
        Print Facesheet
        <coach-loading-indicator class="margin-left--small" [ngClass]="{'is-hidden':!loadingPdf}" size="30"></coach-loading-indicator>
      </button>
      <div class="u-display--flex u-flex-direction--row padding--none margin-right--small u-color--highlight">
        <input [(ngModel)]="detailedPdf" class="toggle" id="toggleDetailedPdf" type="checkbox"><label for="toggleDetailedPdf">Details</label>
      </div>
    </div>
  </div>
</div>
