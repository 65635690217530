import { Component, OnInit } from '@angular/core';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import { TeamMember, Account } from './team-member';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'coach-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.scss']
})
export class MyTeamComponent extends KillSubscriptions implements OnInit {

  selectedAccount: Account;
  teamMembers: TeamMember[];

  constructor(private fileDownloadService: FileDownloadService, private hierarchyTierService: HierarchyTierService) {
    super();
  }

  ngOnInit(): void {
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
      if (tier != null) {
        this.fileDownloadService.getMyTeamPage(tier.selectedItem.tier2_ID).subscribe(accountsJson => {
          if(accountsJson) {
            const serializedAccounts: Account[] = JSON.parse(accountsJson) || [];
            this.selectedAccount = serializedAccounts.length == 0 ? null : serializedAccounts.find(account => account.cat.some(member => member.ICC === tier.selectedItem.tier2_ID));
            this.teamMembers = this.selectedAccount == null ? [] : this.selectedAccount.cat.filter(member => member.ICC === tier.selectedItem.tier2_ID);
          }
        });
      }
    });
  }

}
