/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Condition } from '../models/condition';
import { FacesheetBatchRequest } from '../models/facesheet-batch-request';
import { FacesheetInteractiveConditionRequest } from '../models/facesheet-interactive-condition-request';
import { HccCodingOpportunityMap } from '../models/hcc-coding-opportunity-map';
import { HccCostUtilization } from '../models/hcc-cost-utilization';
import { HccHealthMaintenance } from '../models/hcc-health-maintenance';
import { HccHealthMaintenanceDetail } from '../models/hcc-health-maintenance-detail';
import { HccPatient } from '../models/hcc-patient';
import { HccPreventive } from '../models/hcc-preventive';

@Injectable({
  providedIn: 'root',
})
export class FacesheetService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation facesheetGet
   */
  static readonly FacesheetGetPath = '/Facesheet';

  /**
   * Get Facesheet demographics for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetGet$Response(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<HccPatient>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HccPatient>;
      })
    );
  }

  /**
   * Get Facesheet demographics for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetGet(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<HccPatient> {

    return this.facesheetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<HccPatient>) => r.body as HccPatient)
    );
  }

  /**
   * Path part for operation facesheetHealthMaintenanceGet
   */
  static readonly FacesheetHealthMaintenanceGetPath = '/Facesheet/healthMaintenance';

  /**
   * Get Facesheet health maintenenance for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetHealthMaintenanceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetHealthMaintenanceGet$Response(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<HccHealthMaintenance>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetHealthMaintenanceGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HccHealthMaintenance>;
      })
    );
  }

  /**
   * Get Facesheet health maintenenance for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetHealthMaintenanceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetHealthMaintenanceGet(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<HccHealthMaintenance> {

    return this.facesheetHealthMaintenanceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<HccHealthMaintenance>) => r.body as HccHealthMaintenance)
    );
  }

  /**
   * Path part for operation facesheetCostUtilizationGet
   */
  static readonly FacesheetCostUtilizationGetPath = '/Facesheet/costUtilization';

  /**
   * Get Facesheet cost utilization for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetCostUtilizationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetCostUtilizationGet$Response(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<HccCostUtilization>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetCostUtilizationGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HccCostUtilization>;
      })
    );
  }

  /**
   * Get Facesheet cost utilization for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetCostUtilizationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetCostUtilizationGet(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<HccCostUtilization> {

    return this.facesheetCostUtilizationGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<HccCostUtilization>) => r.body as HccCostUtilization)
    );
  }

  /**
   * Path part for operation facesheetPreventiveGet
   */
  static readonly FacesheetPreventiveGetPath = '/Facesheet/preventive';

  /**
   * Get Facesheet preventive care details for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetPreventiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetPreventiveGet$Response(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<HccPreventive>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetPreventiveGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HccPreventive>;
      })
    );
  }

  /**
   * Get Facesheet preventive care details for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetPreventiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetPreventiveGet(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<HccPreventive> {

    return this.facesheetPreventiveGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<HccPreventive>) => r.body as HccPreventive)
    );
  }

  /**
   * Path part for operation facesheetOpportunitiesGet
   */
  static readonly FacesheetOpportunitiesGetPath = '/Facesheet/opportunities';

  /**
   * Get Facesheet coding opportunities for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetOpportunitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetOpportunitiesGet$Response(params?: {

    /**
     * Flag specifying whether or not to return chronic coding opportunities
     */
    IsChronic?: boolean;

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccCodingOpportunityMap>>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetOpportunitiesGetPath, 'get');
    if (params) {
      rb.query('IsChronic', params.IsChronic, {});
      rb.query('Id', params.Id, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccCodingOpportunityMap>>;
      })
    );
  }

  /**
   * Get Facesheet coding opportunities for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetOpportunitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetOpportunitiesGet(params?: {

    /**
     * Flag specifying whether or not to return chronic coding opportunities
     */
    IsChronic?: boolean;

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<Array<HccCodingOpportunityMap>> {

    return this.facesheetOpportunitiesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccCodingOpportunityMap>>) => r.body as Array<HccCodingOpportunityMap>)
    );
  }

  /**
   * Path part for operation facesheetConditionsGet
   */
  static readonly FacesheetConditionsGetPath = '/Facesheet/conditions';

  /**
   * Get Facesheet conditions for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetConditionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetConditionsGet$Response(params?: {

    /**
     * Flag specifying whether or not to return chronic coding opportunities
     */
    IsChronic?: boolean;

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Condition>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetConditionsGetPath, 'get');
    if (params) {
      rb.query('IsChronic', params.IsChronic, {});
      rb.query('Id', params.Id, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Condition>;
      })
    );
  }

  /**
   * Get Facesheet conditions for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetConditionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetConditionsGet(params?: {

    /**
     * Flag specifying whether or not to return chronic coding opportunities
     */
    IsChronic?: boolean;

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<Condition> {

    return this.facesheetConditionsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Condition>) => r.body as Condition)
    );
  }

  /**
   * Path part for operation facesheetHealthMaintenanceDetailsGet
   */
  static readonly FacesheetHealthMaintenanceDetailsGetPath = '/Facesheet/healthMaintenanceDetails';

  /**
   * Get Facesheet health maintenance details for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetHealthMaintenanceDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetHealthMaintenanceDetailsGet$Response(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccHealthMaintenanceDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetHealthMaintenanceDetailsGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccHealthMaintenanceDetail>>;
      })
    );
  }

  /**
   * Get Facesheet health maintenance details for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetHealthMaintenanceDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetHealthMaintenanceDetailsGet(params?: {

    /**
     * The requested patient's MBI
     */
    Id?: string;
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<Array<HccHealthMaintenanceDetail>> {

    return this.facesheetHealthMaintenanceDetailsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccHealthMaintenanceDetail>>) => r.body as Array<HccHealthMaintenanceDetail>)
    );
  }

  /**
   * Path part for operation facesheetPdfPost
   */
  static readonly FacesheetPdfPostPath = '/Facesheet/pdf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetPdfPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetPdfPost$Response(params?: {
    chPatId?: number;
    tierId?: string;
    tierNum?: number;
    expanded?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetPdfPostPath, 'post');
    if (params) {
      rb.query('chPatId', params.chPatId, {});
      rb.query('tierId', params.tierId, {});
      rb.query('tierNum', params.tierNum, {});
      rb.query('expanded', params.expanded, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetPdfPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetPdfPost(params?: {
    chPatId?: number;
    tierId?: string;
    tierNum?: number;
    expanded?: boolean;
  },
  context?: HttpContext

): Observable<void> {

    return this.facesheetPdfPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation facesheetBatchPost
   */
  static readonly FacesheetBatchPostPath = '/Facesheet/batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetBatchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetBatchPost$Response(params?: {
    body?: FacesheetBatchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetBatchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetBatchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetBatchPost(params?: {
    body?: FacesheetBatchRequest
  },
  context?: HttpContext

): Observable<number> {

    return this.facesheetBatchPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation facesheetInteractiveConditionPost
   */
  static readonly FacesheetInteractiveConditionPostPath = '/Facesheet/interactiveCondition';

  /**
   * Please check Schema for annotation for DxStatusID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetInteractiveConditionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetInteractiveConditionPost$Response(params?: {
    body?: FacesheetInteractiveConditionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetService.FacesheetInteractiveConditionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Please check Schema for annotation for DxStatusID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetInteractiveConditionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetInteractiveConditionPost(params?: {
    body?: FacesheetInteractiveConditionRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.facesheetInteractiveConditionPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
