<div class="overlay overlay--light is-open eulaModal">
  <div class="modal u-width--largest u-max-height--largest">
    <div class="header">
      <div class="titles">
        <div class="title">End User License Agreement</div>
      </div>
    </div>
    <div class="dynamicBody padding" [innerHtml]="(eula$ | async)[0]?.eulaText">

    </div>
    <div class="footer">
      <button [disabled]="isLoading" class="button margin-right border--none" (click)="declineEULA()">Decline</button>
      <button [disabled]="isLoading" class="button is-primary" (click)="acceptEULA()">Accept</button>
    </div>
  </div>
</div>
