<div class="page-content-main">
    <div class="header row--verticalCenter padding-topBottom--none">
        <div class="titles">
            <div class="title row--verticalCenter u-truncate">
                <div class="header-content">
                    <div class="head">340B Claim Exceptions</div>
                </div>

                <button *ngIf="loadingStatus" (click)="cancelPrescriptionsLoadingProcess()"
                    class="button margin-left--small is-smaller margin-topBottom--none padding-topBottom--smaller">
                    <span>
                        <div class="loader margin-right"></div>
                    </span>
                    Cancel
                </button>
            </div>
        </div>
    </div>
    <div class="dynamicBody row">
        <div class="dynamicBody">
            <ag-grid-angular #theGrid class="ag-theme-material" [hidden]="loadingStatus" 
                coachAgGridResize row-animation style="width: 100%; height: 100%" [rowData]="claimExceptions"
                [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
                [overlayNoRowsTemplate]="'&lt;p&gt;No data&lt;/p&gt;'" (gridReady)="onGridReady($event)"
                (firstDataRendered)="onFirstDataRendered($event)"
                [pagination]="true" [paginationAutoPageSize]="true" [rowSelection]="'multiple'"
                [sortingOrder]="['asc', 'desc', null]"
                (cellClicked)="onCellClicked($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>