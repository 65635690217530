import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QualityFormQuestion } from '@shared/models/forms/forms-frontend';
import { FormQuestionConstants } from '@shared/models/forms/form-question-constants';

@Component({
  selector: 'coach-submission-form-block',
  templateUrl: './form-block.component.html',
  styleUrls: ['./form-block.component.scss'],
})
export class FormBlockComponent implements OnInit, OnDestroy, OnChanges {
  @Input() qualityForm: UntypedFormGroup; // Import form from parent component
  @Input() formQuestion: QualityFormQuestion; // Import front-end question data from iterated question list
  @Input() questionsDisabled: boolean;


  public errors = [];

  // Forms
  public formGroupName: string;
  public percent: number;
  public newPoints: number;
  public percentRounded: number;
  public percentRoundedGraph: number;

  // Subscriptions
  public responseSub$: Subscription;
  public numSub$: Subscription;
  public denSub$: Subscription;
  public allSubs: Subscription[];

  // Other UI
  public isInfoTextOpen = false;


  public errTexts = {
    NUM :'Numerator cannot be negative',
    DEN : 'Denominator cannot be less than ',
    NUMDEN : 'Denominator cannot be less than numerator',
    EMPTY : 'Field(s) cannot be empty'}

  ngOnInit(): void {
    this.formGroupName = this.getQualityFormQGroup() || null;
    this.errTexts.DEN = 'Denominator cannot be less than ' + this.formQuestion.minDenominator.toString();
    if (this.formGroupName) {
      this.responseSub$ = this.qualityForm
        .get(this.formGroupName)
        .valueChanges.subscribe(() => {
          this.updateValues();
        });
      this.numSub$ = this.qualityForm
        .get(this.formGroupName + '.FormQResponses.1')
        .valueChanges.subscribe(() => {
          this.updateValues(1);
        });
      this.denSub$ = this.qualityForm
        .get(this.formGroupName + '.FormQResponses.2')
        .valueChanges.subscribe(() => {
          this.updateValues(2);
        });
      this.allSubs = [this.responseSub$, this.numSub$, this.denSub$];
    }

    this.getPercent();
    this.getPoints();

    this.qualityForm
      .get(this.formGroupName + '.FormQResponses')
      .setValidators(() =>
        this.errors && this.errors.length > 0
          ? { numeratorDenominator: true }
          : null
      );

    this.updateValues(2);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changed =
      changes.questionsDisabled.previousValue !==
      changes.questionsDisabled.currentValue;
    if (changed) {
      if (changes.questionsDisabled.currentValue === true) {
        this.qualityForm.get(this.formGroupName).disable({ emitEvent: false });
        this.qualityForm
          .get(this.formGroupName)
          .updateValueAndValidity({ onlySelf: true, emitEvent: false });
      } else if (changes.questionsDisabled.currentValue === false) {
        if (!changes.questionsDisabled.isFirstChange()) {
          this.qualityForm.get(this.formGroupName).enable({ emitEvent: false });
          this.qualityForm
            .get(this.formGroupName)
            .updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.allSubs.forEach((sub) => sub.unsubscribe());
  }

  public getQualityFormQGroup(): string {
    switch (this.formQuestion?.questionID) {
      case 1:
        return 'FormQ1Fall';
      case 2:
        return 'FormQ2Diabetes';
      case 3:
        return 'FormQ3Breast';
      case 4:
        return 'FormQ4Colorectal';
      case 5:
        return 'FormQ5Controlling';
      case 6:
        return 'FormQ6Screening';
      case 7:
        return 'FormQ7PreventFlu';
      case 49:
        return 'FormQ49PreventTobacco';
      default:
        break;
    }
  }

  public toggleSubmitFormInfoText(): void {
    this.isInfoTextOpen = !this.isInfoTextOpen;
  }

  public updateValues(numDenIndex?: number): void {
    this.timeStamp(numDenIndex || null);
    if (numDenIndex) {
      this.toggleErrorTexts(numDenIndex || null);
    }
    this.getPercent();
    this.getPoints();
  }

  public timeStamp(numDenIndex?: number): void {
    const newTimeStamp = new Date().toJSON();
    this.qualityForm.patchValue(
      { LastUpdateDateTime: newTimeStamp },
      { emitEvent: false }
    );
    this.qualityForm
      .get(this.formGroupName)
      .patchValue({ LastUpdateDateTime: newTimeStamp }, { emitEvent: false });
    if (numDenIndex) {
      this.qualityForm
        .get(this.formGroupName + '.FormQResponses.0')
        .patchValue({ LastUpdateDateTime: newTimeStamp }, { emitEvent: false });
      this.qualityForm
        .get(this.formGroupName + '.FormQResponses.' + numDenIndex.toString())
        .patchValue({ LastUpdateDateTime: newTimeStamp }, { emitEvent: false });
    }
  }

  public toggleErrorTexts(numDenIndex?: number): void {
    if (numDenIndex) {
      const num = this.qualityForm.get(
        this.formGroupName + '.FormQResponses.1.ResponsePointValue'
      ) as UntypedFormControl;
      const den = this.qualityForm.get(
        this.formGroupName + '.FormQResponses.2.ResponsePointValue'
      ) as UntypedFormControl;

      // Check if numerator OR denominator is empty
      const emptyExists = this.errors.some((e) => e === this.errTexts.EMPTY);
      if (
        (num.touched && num.value === null) ||
        (den.touched && den.value === null)
      ) {
        if (!emptyExists) {
          this.errors.push(this.errTexts.EMPTY);
        }
      } else {
        if (emptyExists) {
          const i = this.errors.indexOf(this.errTexts.EMPTY);
          this.errors.splice(i, 1);
        }
      }
      // Check if denominator is LESS THAN numerator
      const numDenExists = this.errors.some((e) => e === this.errTexts.NUMDEN);
      if (num.value >= 0 && den.value >= 0 && num.value > den.value) {
        if (!numDenExists) {
          this.errors.push(this.errTexts.NUMDEN);
        }
      } else {
        if (numDenExists) {
          const i = this.errors.indexOf(this.errTexts.NUMDEN);
          this.errors.splice(i, 1);
        }
      }
      // Check if numerator is LESS THAN 0
      const numExists = this.errors.some((e) => e === this.errTexts.NUM);
      if (num.value < 0 && num.value !== null) {
        if (!numExists) {
          this.errors.push(this.errTexts.NUM);
        }
      } else {
        if (numExists) {
          const i = this.errors.indexOf(this.errTexts.NUM);
          this.errors.splice(i, 1);
        }
      }
      // Check if denominator is LESS THAN minimum denominator
      const denExists = this.errors.some((e) => e === this.errTexts.DEN);
      if (den.value < this.formQuestion.minDenominator && den.value !== null) {
        //Check if both den and num are 0, if so allow it as an exception
        if (den.value == 0 && num.value == 0) {
          if (denExists) {
            const i = this.errors.indexOf(this.errTexts.DEN);
            this.errors.splice(i, 1);
          }
        }
        else {
          if (!denExists) {
            this.errors.push(this.errTexts.DEN);
          }
        }
      } else {
        if (denExists) {
          const i = this.errors.indexOf(this.errTexts.DEN);
          this.errors.splice(i, 1);
        }
      }
    }
  }

  public isNumDenValid(): boolean {
    const num = this.qualityForm.get(
      this.formGroupName + '.FormQResponses.1.ResponsePointValue'
    ) as UntypedFormControl;
    const den = this.qualityForm.get(
      this.formGroupName + '.FormQResponses.2.ResponsePointValue'
    ) as UntypedFormControl;
    if (num.valid && den.valid) {
      if (num.value >= 0 && den.value >= 0) {
        if (num.value <= den.value) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if ((num.dirty || num.touched) && (den.dirty || den.touched)) {
      if (num.value < 0 && den.value < 1) {
        return false;
      }
    } else if ((num.dirty || num.touched) && (den.pristine || den.untouched)) {
      if (num.value < 0) {
        return false;
      }
    } else if ((den.dirty || den.touched) && (num.pristine || num.untouched)) {
      if (den.value < 1) {
        return false;
      }
    } else {
      return false;
    }
  }

  public getPercent(): void {
    const num = this.qualityForm.get(
      this.formGroupName + '.FormQResponses.1.ResponsePointValue'
    ) as UntypedFormControl;
    const den = this.qualityForm.get(
      this.formGroupName + '.FormQResponses.2.ResponsePointValue'
    ) as UntypedFormControl;
    if (this.isNumDenValid()) {
      const percent = ((num.value / den.value) * 100).toFixed(3);
      this.percent = Number(percent);
      if (isNaN(this.percent)) {
        this.percent = 0;
      }
      this.percentRounded = Math.round((this.percent + Number.EPSILON) * 100) / 100;
      this.percentRoundedGraph = Math.round((this.percent + Number.EPSILON))
    }
  }

  public getPoints(): void {
    const num = this.qualityForm.get(
      this.formGroupName + '.FormQResponses.1.ResponsePointValue'
    ) as UntypedFormControl;
    const den = this.qualityForm.get(
      this.formGroupName + '.FormQResponses.2.ResponsePointValue'
    ) as UntypedFormControl;
    if (this.formQuestion?.isLowPointsIdeal) {
      this.newPoints =  this.getPointsLow(this.percent);
      if (this.newPoints < 3) {
        this.patchPoints(3);
      } else {
        if (num.value == 0 && den.value == 0) {
          this.patchPoints(3);
        }
        else {
        this.patchPoints(this.newPoints);
        }
      }
    } else {
      this.newPoints =  this.getPointsHigh(this.percent);
      if (this.newPoints < 3) {
        if (num.value == 0 && den.value == 0) {
          this.patchPoints(3);
        }
        else {
          this.patchPoints(3);
        }
      } else {
        this.patchPoints(this.newPoints);
      }
    }
  }

  public patchPoints(points: number): void {
    this.qualityForm
      .get(this.formGroupName + '.FormQResponses.0')
      .patchValue({ ResponsePointValue: points }, { emitEvent: false });
  }

  private getPointsHigh(percent: number): number {
    const x = percent;
    switch (true) {
      case (x >= 0 && x<= 1.5):
        return 3;
        break;
      case (x >= 1.501 && x<= 4.5):
        return 3.1;
        break;
      case (x >= 4.501 && x<= 7.5):
        return 3.2;
        break;
      case (x >= 7.501 && x<= 10.5):
        return 3.3;
        break;
      case (x >= 10.501 && x<= 13.5):
        return 3.4;
        break;
      case (x >= 13.501 && x<= 16.499):
        return 3.5;
        break;
      case (x >= 16.5 && x<= 19.499):
        return 3.6;
        break;
      case (x >= 19.5 && x<= 22.499):
        return 3.7;
        break;
      case (x >= 22.5 && x<= 25.5):
        return 3.8;
        break;
      case (x >= 25.501 && x<= 29.999):
        return 3.9;
        break;
      case (x >= 30 && x<= 30.5):
        return 4;
        break;
      case (x >= 30.501 && x<= 31.5):
        return 4.1;
        break;
      case (x >= 31.501 && x<= 32.5):
        return 4.2;
        break;
      case (x >= 32.501 && x<= 33.5):
        return 4.3;
        break;
      case (x >= 33.501 && x<= 34.5):
        return 4.4;
        break;
      case (x >= 34.501 && x<= 35.499):
        return 4.5;
        break;
      case (x >= 35.5 && x<= 36.499):
        return 4.6;
        break;
      case (x >= 36.5 && x<= 37.499):
        return 4.7;
        break;
      case (x >= 37.5 && x<= 38.5):
        return 4.8;
        break;
      case (x >= 38.501 && x<= 39.999):
        return 4.9;
        break;
      case (x >= 40 && x<= 40.5):
        return 5;
        break;
      case (x >= 40.501 && x<= 41.5):
        return 5.1;
        break;
      case (x >= 41.501 && x<= 42.5):
        return 5.2;
        break;
      case (x >= 42.501 && x<= 43.5):
        return 5.3;
        break;
      case (x >= 43.501 && x<= 44.5):
        return 5.4;
        break;
      case (x >= 44.501 && x<= 45.499):
        return 5.5;
        break;
      case (x >= 45.5 && x<= 46.499):
        return 5.6;
        break;
      case (x >= 46.5 && x<= 47.499):
        return 5.7;
        break;
      case (x >= 47.5 && x<= 48.5):
        return 5.8;
        break;
      case (x >= 48.501 && x<= 49.999):
        return 5.9;
        break;
      case (x >= 50 && x<= 50.5):
        return 6;
        break;
      case (x >= 50.501 && x<= 51.5):
        return 6.1;
        break;
      case (x >= 51.501 && x<= 52.5):
        return 6.2;
        break;
      case (x >= 52.501 && x<= 53.5):
        return 6.3;
        break;
      case (x >= 53.501 && x<= 54.5):
        return 6.4;
        break;
      case (x >= 54.501 && x<= 55.499):
        return 6.5;
        break;
      case (x >= 55.5 && x<= 56.499):
        return 6.6;
        break;
      case (x >= 56.5 && x<= 57.499):
        return 6.7;
        break;
      case (x >= 57.5 && x<= 58.5):
        return 6.8;
        break;
      case (x >= 58.501 && x<= 59.999):
        return 6.9;
        break;
      case (x >= 60 && x<= 60.5):
        return 7;
        break;
      case (x >= 60.501 && x<= 61.5):
        return 7.1;
        break;
      case (x >= 61.501 && x<= 62.5):
        return 7.2;
        break;
      case (x >= 62.501 && x<= 63.5):
        return 7.3;
        break;
      case (x >= 63.501 && x<= 64.5):
        return 7.4;
        break;
      case (x >= 64.501 && x<= 65.499):
        return 7.5;
        break;
      case (x >= 65.5 && x<= 66.499):
        return 7.6;
        break;
      case (x >= 66.5 && x<= 67.499):
        return 7.7;
        break;
      case (x >= 67.5 && x<= 68.5):
        return 7.8;
        break;
      case (x >= 68.501 && x<= 69.999):
        return 7.9;
        break;
      case (x >= 70 && x<= 70.5):
        return 8;
        break;
      case (x >= 70.501 && x<= 71.5):
        return 8.1;
        break;
      case (x >= 71.501 && x<= 72.5):
        return 8.2;
        break;
      case (x >= 72.501 && x<= 73.5):
        return 8.3;
        break;
      case (x >= 73.501 && x<= 74.5):
        return 8.4;
        break;
      case (x >= 74.501 && x<= 75.499):
        return 8.5;
        break;
      case (x >= 75.5 && x<= 76.499):
        return 8.6;
        break;
      case (x >= 76.5 && x<= 77.499):
        return 8.7;
        break;
      case (x >= 77.5 && x<= 78.5):
        return 8.8;
        break;
      case (x >= 78.501 && x<= 79.999):
        return 8.9;
        break;
      case (x >= 80 && x<= 80.5):
        return 9;
        break;
      case (x >= 80.501 && x<= 81.5):
        return 9.1;
        break;
      case (x >= 81.501 && x<= 82.5):
        return 9.2;
        break;
      case (x >= 82.501 && x<= 83.5):
        return 9.3;
        break;
      case (x >= 83.501 && x<= 84.5):
        return 9.4;
        break;
      case (x >= 84.501 && x<= 85.499):
        return 9.5;
        break;
      case (x >= 85.5 && x<= 86.499):
        return 9.6;
        break;
      case (x >= 86.5 && x<= 87.499):
        return 9.7;
        break;
      case (x >= 87.5 && x<= 88.5):
        return 9.8;
        break;
      case (x >= 88.501 && x<= 89.999):
        return 9.9;
        break;
      case (x >= 90 && x<= 100):
        return 10;
        break;
      default:
        return;
    }
  }


  private getPointsLow(percent: number): number {
    const x = percent;
    switch (true) {
      case (x <= 100 && x>= 98.5):
        return 3;
        break;
      case (x <= 98.499 && x>= 95.5):
        return 3.1;
        break;
      case (x <= 95.499 && x>= 92.5):
        return 3.2;
        break;
      case (x <= 92.499 && x>= 89.5):
        return 3.3;
        break;
      case (x <= 89.499 && x>= 86.5):
        return 3.4;
        break;
      case (x <= 86.499 && x>= 83.501):
        return 3.5;
        break;
      case (x <= 83.5 && x>= 80.501):
        return 3.6;
        break;
      case (x <= 80.5 && x>= 77.501):
        return 3.7;
        break;
      case (x <= 77.5 && x>= 74.5):
        return 3.8;
        break;
      case (x <= 74.499 && x>= 70.001):
        return 3.9;
        break;
      case (x <= 70 && x>= 69.5):
        return 4;
        break;
      case (x <= 69.499 && x>= 68.5):
        return 4.1;
        break;
      case (x <= 68.499 && x>= 67.5):
        return 4.2;
        break;
      case (x <= 67.499 && x>= 66.5):
        return 4.3;
        break;
      case (x <= 66.499 && x>= 65.5):
        return 4.4;
        break;
      case (x <= 65.499 && x>= 64.501):
        return 4.5;
        break;
      case (x <= 64.5 && x>= 63.501):
        return 4.6;
        break;
      case (x <= 63.5 && x>= 62.501):
        return 4.7;
        break;
      case (x <= 62.5 && x>= 61.5):
        return 4.8;
        break;
      case (x <= 61.499 && x>= 60.001):
        return 4.9;
        break;
      case (x <= 60 && x>= 59.5):
        return 5;
        break;
      case (x <= 59.499 && x>= 58.5):
        return 5.1;
        break;
      case (x <= 58.499 && x>= 57.5):
        return 5.2;
        break;
      case (x <= 57.499 && x>= 56.5):
        return 5.3;
        break;
      case (x <= 56.499 && x>= 55.5):
        return 5.4;
        break;
      case (x <= 55.499 && x>= 54.501):
        return 5.5;
        break;
      case (x <= 54.5 && x>= 53.501):
        return 5.6;
        break;
      case (x <= 53.5 && x>= 52.501):
        return 5.7;
        break;
      case (x <= 52.5 && x>= 51.5):
        return 5.8;
        break;
      case (x <= 51.499 && x>= 50.001):
        return 5.9;
        break;
      case (x <= 50 && x>= 49.5):
        return 6;
        break;
      case (x <= 49.499 && x>= 48.5):
        return 6.1;
        break;
      case (x <= 48.499 && x>= 47.5):
        return 6.2;
        break;
      case (x <= 47.499 && x>= 46.5):
        return 6.3;
        break;
      case (x <= 46.499 && x>= 45.5):
        return 6.4;
        break;
      case (x <= 45.499 && x>= 44.501):
        return 6.5;
        break;
      case (x <= 44.5 && x>= 43.501):
        return 6.6;
        break;
      case (x <= 43.5 && x>= 42.501):
        return 6.7;
        break;
      case (x <= 42.5 && x>= 41.5):
        return 6.8;
        break;
      case (x <= 41.499 && x>= 40.001):
        return 6.9;
        break;
      case (x <= 40 && x>= 39.5):
        return 7;
        break;
      case (x <= 39.499 && x>= 38.5):
        return 7.1;
        break;
      case (x <= 38.499 && x>= 37.5):
        return 7.2;
        break;
      case (x <= 37.499 && x>= 36.5):
        return 7.3;
        break;
      case (x <= 36.499 && x>= 35.5):
        return 7.4;
        break;
      case (x <= 35.499 && x>= 34.501):
        return 7.5;
        break;
      case (x <= 34.5 && x>= 33.501):
        return 7.6;
        break;
      case (x <= 33.5 && x>= 32.501):
        return 7.7;
        break;
      case (x <= 32.5 && x>= 31.5):
        return 7.8;
        break;
      case (x <= 31.499 && x>= 30.001):
        return 7.9;
        break;
      case (x <= 30 && x>= 29.5):
        return 8;
        break;
      case (x <= 29.499 && x>= 28.5):
        return 8.1;
        break;
      case (x <= 28.499 && x>= 27.5):
        return 8.2;
        break;
      case (x <= 27.499 && x>= 26.5):
        return 8.3;
        break;
      case (x <= 26.499 && x>= 25.5):
        return 8.4;
        break;
      case (x <= 25.499 && x>= 24.501):
        return 8.5;
        break;
      case (x <= 24.5 && x>= 23.501):
        return 8.6;
        break;
      case (x <= 23.5 && x>= 22.501):
        return 8.7;
        break;
      case (x <= 22.5 && x>= 21.5):
        return 8.8;
        break;
      case (x <= 21.499 && x>= 20.001):
        return 8.9;
        break;
      case (x <= 20 && x>= 19.5):
        return 9;
        break;
      case (x <= 19.499 && x>= 18.5):
        return 9.1;
        break;
      case (x <= 18.499 && x>= 17.5):
        return 9.2;
        break;
      case (x <= 17.499 && x>= 16.5):
        return 9.3;
        break;
      case (x <= 16.499 && x>= 15.5):
        return 9.4;
        break;
      case (x <= 15.499 && x>= 14.501):
        return 9.5;
        break;
      case (x <= 14.5 && x>= 13.501):
        return 9.6;
        break;
      case (x <= 13.5 && x>= 12.501):
        return 9.7;
        break;
      case (x <= 12.5 && x>= 11.5):
        return 9.8;
        break;
      case (x <= 11.499 && x>= 10.001):
        return 9.9;
        break;
      case (x <= 10 && x>= 0):
        return 10;
        break;
      default:
        return;
      }
  }
}
