import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleConstants } from '@shared/models/module-constants';
import { EulaAgreementComponent } from '@views/eula-agreement/eula-agreement.component';
import { TimeoutComponent } from '@views/timeout/timeout.component';
import { SignoutComponent } from '@views/signout/signout.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth.guard';
import { AutoLoginComponent } from './views/auto-lopgin/auto-login.component';
import { NoAuthComponent } from './views/no-auth/no-auth.component';
import { SharedRedirectComponent } from '@shared/components/shared-redirect/shared-redirect.component';
import { RootComponent } from './views/root/root.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RootComponent,
    canActivate: [AuthGuard],
  },
  {
    // NO CANACTIVATE ROUTE
    path: 'autologin',
    component: AutoLoginComponent,
  },
  {
    // NO CANACTIVATE ROUTE
    path: 'noauth',
    component: NoAuthComponent,
  },
  {
    path: 'timeout',
    component: TimeoutComponent,
  },
  {
    path: 'signout',
    component: SignoutComponent,
  },
  {
    path: 'eula',
    component: EulaAgreementComponent,
    data: {
      label: 'EULA',
    },
  },
  {
    path: 'shared',
    children: [
      {
        path: '**',
        canActivate: [AuthGuard],
        component: SharedRedirectComponent,
      },
    ],
  },
  {
    path: ':tierName/:tierId',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/home/home.module').then((m) => m.HomeModule),
        data: {
          label: 'home',
          helpfile: 'Learn_HOME_General_2021',
        },
      },
      {
        // to do: enable canActivate for  all routes like done for 'care"
        path: 'care',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@care/care.module').then((m) => m.CareModule),
        data: {
          moduleId: ModuleConstants.CareModule.valueOf(),
        },
      },
      {
        path: 'submit',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/submit/submit.module').then((m) => m.SubmitModule),
        data: {
          moduleId: ModuleConstants.SubmitModule.valueOf(),
        },
      },
      {
        path: 'track',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@track/track.module').then((m) => m.TrackModule),
        data: {
          moduleId: ModuleConstants.TrackModule.valueOf(),
        },
      },
      {
        path: 'improve',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/improve/improve.module').then((m) => m.ImproveModule),
        data: {
          moduleId: ModuleConstants.ImproveModule.valueOf(),
        },
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: environment.useHashStrategy }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
