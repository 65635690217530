<div class="u-height--fit-content margin-auto row min-height">
  <div class="span--2 u-border-radius--rounded">
    <coach-stepper class="stepper" [icon]="'calendar-complete'" [isLastStep]="true" [activityCompleted]="true"></coach-stepper>
  </div>
  <div class="span--10">
    <div class="row" (click)="isTabOpen = !isTabOpen">
      <div class="span--10 padding-left--small pointer">
        <div [ngClass]="{'tabOpen': isTabOpen === true }" class="u-bold">AWV Completed</div>
        <div class="u-color--brand--6 u-font-weight--light">{{ completedOutcomeProperties.date | date: 'shortDate' }}&nbsp;{{completedOutcomeProperties.practiceId? ('at ' + practiceName) : ''}}</div>
      </div>
      <div class="span--2 padding-left--small">
        <coach-icon [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" iconColor="currentColor"></coach-icon>
      </div>
    </div>
    <div class="padding-top--large" *ngIf="isTabOpen">
        <coach-patient-workflow-completed [patient]="patient" [properties]="completedOutcomeProperties"
                                          (outcomeSaved)="this.outcomeSaved($event);" [editModeActive]="true"
                                          [ngClass]="{'u-flex-wrap--wrap padding--small': mobileScreenSize, 'padding-top--large padding-bottom--large padding-right--small': !mobileScreenSize}"
                                          (deleteActivity)="this.deleteSchedulingActivity()"
        ></coach-patient-workflow-completed>
    </div>
  </div>
</div>

