import { EventEmitter, Injectable, Output } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { HierarchyTierService } from '../hierarchy/hierarchy-tier.service';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import {
  IPrescription,
  IApprovedPrescription,
  IPrescriptionOrig,
  IPrescriptionDetail,
  convertOrig2Columns,
  convertOrig2ColumnsProcessing,
  convertPrescriberToCols,
  convertPharmacyNameToCols,
  IPrescriber,
  IPrescriberOrig,
  IPharmacyNames,
  IPharmacyNamesOrig,
  ICCNLocation,
  IPrescriptionInvoice,
  IContractInfo,
  IClaimException,
} from '@shared/models/prescription';
import {
  ClaimStatus,
  InvoiceStatus,
  mapOverallOptionsToStrings,
} from '../../../care/views/care-prescriptions/shared-lib';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Observable, Subscription } from 'rxjs';

export interface IInvoiceStatusId {
  id: number
}
@Injectable({
  providedIn: 'root',
})
export class PrescriptionService extends KillSubscriptions {
  private static tempOcp_Apim_Sub_Key: string =
    // '83906f50df4a486a89a6f116e276e56a';
    environment.ocpApimSubscriptionKey;

  trackBaseURL: string = environment.trackApiUrl;
  apiBaseURL: string = environment.apiBaseUrl;
  // trackBaseURL: string = `https://localhost:44395`;

  prescriptions: any[];

  public tier: IHierarchyTier = null;
  claimInfo = null;
  processingClaim = null;
  invoicingClaim = null;

  @Output() claimInfoUpdated = new EventEmitter<Object>();
  @Output() processingClaimInfoUpdated = new EventEmitter<Object>();
  @Output() invoicingClaimInfoUpdated = new EventEmitter<Object>();

  constructor(
    private http: HttpClient,
    private _authService: AuthService,
    private HierarchyTierService: HierarchyTierService,
    private ToastrMessageService: ToastrMessageService
  ) {
    super();

    HierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (tier) => {
        if (this.tier?.selectedTierId !== tier?.selectedTierId) {
          this.tier = tier;
        }
      });
  }

  getStandardHeaders() {
    return {
      Authorization: 'Bearer ' + this._authService.IdToken,
      'Ocp-Apim-Subscription-Key': PrescriptionService.tempOcp_Apim_Sub_Key,
    };
  }

  async getPrescriptions(
    acoId?: string,
    tier2Id?: string,
    tier3Id?: string,
    tier4Id?: string,
    userId: any = null
  ): Promise<Array<IPrescription>> {
    try {
      const headers = this.getStandardHeaders();

      const url = `${this.trackBaseURL}/Sp340BClaims`;
      const params = {
        acoId: acoId || null,
        ...(tier2Id && { tier2Id: tier2Id }),
        ...(tier3Id && { tier3Id: tier3Id }),
        ...(tier4Id && { tier4Id: tier4Id }),
      };
      const options = {
        headers,
        params: params,
      };

      if (userId) {
        await this.writeAuditMessage({
          entityid: parseInt(userId),
          moduleid: 2, // care
          componentid: 36,
          actionid: 6, // phi access
          tablename: 'Coach340BClaims',
          auditdesc: `User ID ${userId} Accessed 340B Work Queue`,
          applicationID: 2,
        });
      }

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionOrigs: Array<IPrescriptionOrig>) => {
            return PrescriptionOrigs.map((PrescriptionOrig) =>
              convertOrig2Columns(PrescriptionOrig)
            );
          })
        )
        .toPromise()) as Array<IPrescription>;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getPrescriptionWithId(tier2Id: string, claimId: number, claimType: string): Promise<IPrescription> {
    try {
      const headers = this.getStandardHeaders();

      const url = `${this.trackBaseURL}/Sp340BClaims/getClaimWithId`;
      const params: any = {
        tier2Id,
        userId: this.tier.selectedItem.userID,
        claimId,
        claimType
      };
      const options = {
        headers,
        params,
      };

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionOrigs: Array<IPrescriptionOrig>) => {
            const result = convertOrig2Columns(PrescriptionOrigs[0]);
            return result;
          })
        )
        .toPromise()) as IPrescription;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getClaimsForInvoice(cmsClaims: string, tpaClaims: string): Promise<IPrescription[]> {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    const url = `${this.trackBaseURL}/Sp340BInvoice/getClaimsForInvoice`;
    const bodyObj: any = {
      cmsClaims,
      tpaClaims
    };
      const body = JSON.stringify(bodyObj);
      
      let result = await fetch(url, { method: 'post', body, headers });
      let jsonResponse = await result.json();
      return jsonResponse;
  }

  async getApprovedClaims(
    acoId?: string,
    tier2Id?: string,
    tier3Id?: string,
    tier4Id?: string
  ): Promise<Array<IApprovedPrescription>> {
    try {
      const headers = this.getStandardHeaders();

      const url = `${this.trackBaseURL}/Sp340BClaims/getApprovedClaims`;
      const options = {
        headers,
        params: {
          acoId: acoId || null,
          ...(tier2Id && { tier2Id: tier2Id }),
          ...(tier3Id && { tier3Id: tier3Id }),
          ...(tier4Id && { tier4Id: tier4Id }),
        },
      };
      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionOrigs: Array<IPrescriptionOrig>) => {
            return PrescriptionOrigs.map((PrescriptionOrig) =>
              convertOrig2ColumnsProcessing(PrescriptionOrig)
            );
          })
        )
        .toPromise()) as Array<IApprovedPrescription>;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getPrescribers(
    acoId?: string,
    tier2Id?: string,
    tier3Id?: string,
    tier4Id?: string
  ): Promise<Array<IPrescriber>> {
    try {
      const headers = this.getStandardHeaders();

      const url = `${this.trackBaseURL}/Sp340BClaims/getPrescribers`;
      const params = {
        acoId: acoId || null,
        ...(tier2Id && { tier2Id: tier2Id }),
        ...(tier3Id && { tier3Id: tier3Id }),
        ...(tier4Id && { tier4Id: tier4Id }),
      };
      const options = {
        headers,
        params,
      };
      const result = (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionOrigs: Array<IPrescriberOrig>) => {
            let result = PrescriptionOrigs.map((PrescriptionOrig) =>
              convertPrescriberToCols(PrescriptionOrig)
            );
            result.unshift({ npi: 'allPrescribers', name: 'All' });
            return result;
          })
        )
        .toPromise()) as Array<IPrescriber>;
      return result;
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getPharmacyNames(
    acoId,
    tier2Id,
    tier3Id,
    tier4Id
  ): Promise<Array<IPharmacyNames>> {
    try {
      const headers = this.getStandardHeaders();
      
      const params = {
        acoId: acoId || null,
        ...(tier2Id && { tier2Id: tier2Id }),
        ...(tier3Id && { tier3Id: tier3Id }),
        ...(tier4Id && { tier4Id: tier4Id }),
      };
      const url = `${this.trackBaseURL}/Sp340BClaims/getPharmacyNames`;
      const options = {
        headers,
        params,
      };
      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionOrigs: Array<IPharmacyNamesOrig>) => {
            return PrescriptionOrigs.map((PrescriptionOrig) =>
              convertPharmacyNameToCols(PrescriptionOrig)
            );
          })
        )
        .toPromise()) as Array<IPharmacyNames>;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getPrescriptionDetail(
    claimPk?: string,
    acoId?: string,
    tier2_id?: string,
    tier3_id?: string,
    tier4_grpId?: string,
    claimId?: number,
    userId: any = null,
    selectedPatientInfo: any = null
  ): Promise<IPrescriptionDetail> {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BClaims/getDetail`;

      const options = {
        headers,
        params: {
          claimPk,
          claimId: claimId?.toString() ?? '-1',
          acoId,
          tier2_id,
          tier3_id,
          tier4_grpId,
          claimType: selectedPatientInfo.claimType,
        },
      };

      if (userId) {
        this.writeAuditMessage({
          entityid: parseInt(userId),
          moduleid: 2, // care
          componentid: 36,
          actionid: 6,
          tablename: 'Coach340BDetail',
          auditdesc: `User ID ${userId} Accessed Patient: ${selectedPatientInfo.mbi}, ${selectedPatientInfo.patientName}, ${selectedPatientInfo.patientDob}`,
          applicationID: 2,
        });
      }

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionDetails: Array<IPrescriptionDetail>) => {
            return PrescriptionDetails[0];
          })
        )
        .toPromise()) as IPrescriptionDetail;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getPrescriptionHistoryDetail(
    tier2_id: string,
    claimId: number,
    newDate: string
  ): Promise<any> {

    const approved = this.getPrescriptionHistoryDetailRecord(
      claimId,
      ClaimStatus.APPROVED
    );
    const processed = this.getPrescriptionHistoryDetailRecord(
      claimId,
      ClaimStatus.PROCESSED
    );

    const pending = this.getInvoiceHistoryRecord(
      tier2_id,
      claimId,
      InvoiceStatus.PENDING
    );
    const invoicing = this.getInvoiceHistoryRecord(
      tier2_id,
      claimId,
      InvoiceStatus.INVOICING
    );
    const invoiced = this.getInvoiceHistoryRecord(
      tier2_id,
      claimId,
      InvoiceStatus.INVOICED
    );
    const rejected = this.getInvoiceHistoryRecord(
      tier2_id,
      claimId,
      InvoiceStatus.REJECTED
    );
    const rejectedNeedsCredit = this.getInvoiceHistoryRecord(
      tier2_id,
      claimId,
      InvoiceStatus.REJECTED_NEEDS_CREDIT
    );
    const credited = this.getInvoiceHistoryRecord(
      tier2_id,
      claimId,
      InvoiceStatus.CREDITED
    );

    const approvedObj = await approved;
    const processedObj = await processed;

    const pendingObj = await pending;
    const invoicingObj = await invoicing;
    const invoicedObj = await invoiced;
    const rejectedObj = await rejected;
    const needsCreditObj = await rejectedNeedsCredit;
    const creditedObj = await credited;

    const newStatus = { newDate, newBy: 'System' };
    const approvedStatus = {
      approvedDate: approvedObj?.claim_Status_Set_On_Date ?? null,
      approvedBy: approvedObj?.claim_Status_Set_By ?? 'System',
    };

    const processedStatus = {
      processedDate: processedObj?.claim_Status_Set_On_Date ?? null,
      processedBy: processedObj?.claim_Status_Set_By ?? 'System',
    };

    const pendingStatus = pendingObj
      ? {
          pendingDate: pendingObj?.lastUpdatedOn ?? null,
          pendingBy: await this.getUserFullName(
            pendingObj?.lastUpdateUserId ?? 1
          ),
        }
      : null;
    const invoicingStatus = invoicingObj
      ? {
          invoicingDate: invoicingObj?.lastUpdatedOn ?? null,
          invoicingBy: await this.getUserFullName(
            invoicingObj?.lastUpdateUserId ?? 1
          ),
        }
      : null;
    const invoicedStatus = invoicedObj
      ? {
          invoicedDate: invoicedObj?.lastUpdatedOn ?? null,
          invoicedBy: await this.getUserFullName(
            invoicedObj?.lastUpdateUserId ?? 1
          ),
        }
      : null;
    const rejectedStatus = rejectedObj
      ? {
          rejectedDate: rejectedObj?.lastUpdatedOn ?? null,
          rejectedBy: await this.getUserFullName(
            rejectedObj?.lastUpdateUserId ?? 1
          ),
        }
      : null;
    const needsCreditStatus = needsCreditObj
      ? {
          needsCreditDate: needsCreditObj?.lastUpdatedOn ?? null,
          needsCreditBy: await this.getUserFullName(
            needsCreditObj?.lastUpdateUserId ?? 1
          ),
        }
      : null;
    const creditedStatus = creditedObj
      ? {
          creditedDate: creditedObj?.lastUpdatedOn ?? null,
          creditedBy: await this.getUserFullName(
            creditedObj?.lastUpdateUserId ?? 1
          ),
        }
      : null;

    return {
      newStatus,
      approvedStatus,
      processedStatus,
      pendingStatus,
      invoicingStatus,
      invoicedStatus,
      rejectedStatus,
      needsCreditStatus,
      creditedStatus,
    };
  }

  private static userNames = new Map();
  async getUserFullName(userId: any) {
    const fullName = await this.getUserInfo(userId);
    return fullName?.displayName || 'System';
  }

  async getPrescriptionHistoryDetailRecord(
    claimId: number,
    status: number
  ): Promise<IPrescriptionDetail> {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BClaims/getClaimDetailHistory`;

      const options = {
        headers,
        params: {
          userId: this.tier.selectedItem.userID.toString(),
          claimId: claimId.toString(),
          status: status.toString(),
        },
      };

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionDetails: Array<IPrescriptionDetail>) => {
            return PrescriptionDetails[0];
          })
        )
        .toPromise()) as IPrescriptionDetail;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getUserInfo(userId: number): Promise<any> {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BInvoice/getUserInfo`;

      const options = {
        headers,
        params: {
          userId: userId.toString(),
        },
      };

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionDetails: any) => {
            return PrescriptionDetails[0];
          })
        )
        .toPromise()) as any;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getInvoiceHistoryRecord(tier2_id: string, claimId: number, status: number): Promise<any> {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BInvoice/getInvoiceHistory`;

      const options = {
        headers,
        params: {
          tier2Id: tier2_id,
          claimId: claimId.toString(),
          status: status.toString(),
        },
      };

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionDetails: Array<IPrescriptionInvoice>) => {
            return PrescriptionDetails[0];
          })
        )
        .toPromise()) as IPrescriptionInvoice;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getCELocations(ccn: string): Promise<ICCNLocation[]> {
    try {
      const headers = this.getStandardHeaders();
      
      const url = `${this.trackBaseURL}/Sp340BClaims/getCELocations`;
      const options = {
        headers,
        params: {
          ccn,
        },
      };
      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionDetails: Array<ICCNLocation>, index: number) => {
            PrescriptionDetails.unshift({ display: 'None' });
            return PrescriptionDetails;
          })
        )
        .toPromise()) as ICCNLocation[];
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getContractInfo(tierId: string): Promise<any> {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BInvoice/getContractInfo`;

      const options = {
        headers,
        params: {
          tier2Id: tierId,
        },
      };

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionDetails: Array<IContractInfo>) => {
            return PrescriptionDetails[0];
          })
        )
        .toPromise()) as IContractInfo;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  /**
   * Constructs a request which interprets the body as a JSON object and returns the full
   * `HTTPResponse` as a JSON object.
   *
   * @param method  The HTTP method.
   * @param url     The endpoint URL.
   * @param options The HTTP options to send with the request.
   *
   * @return An `Observable` of the `HTTPResponse`, with the response body of type `Object`.
   */
  async setPrescriptionDetail(
    details: any,
    isRejected: boolean = false,
    subsequentFillLogicOptOut: boolean = null
  ): Promise<Object> {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    details.visit_Note_Missing = details.visit_Note_Missing ? 1 : 0;
    details.cE_Visit_Note_Missing = details.cE_Visit_Note_Missing ? 1 : 0;
    details.cE_Related_Referral_Missing = details.cE_Related_Referral_Missing
      ? 1
      : 0;

    details.acO_ID = details.acoId;
    details.coach340BClaimsID = details.claimPk;
    details.tier2_ID = details.tier2_id;
    details.tier3_ID = details.tier3_id;
    details.tier4_GrpID = details.tier4_grpId;

    if (!details.cuR_CLM_UNIQ_ID) {
      details.cuR_CLM_UNIQ_ID = -1;
    }
    
    details.claim_Status = details.claim_Status === ClaimStatus.NEW
    ? ClaimStatus.PENDING : details.claim_Status; 
    
    if (details.userId) {
      await this.writeAuditMessage({
        entityid: parseInt(details.userId),
        moduleid: 2, // care
        componentid: 36,
        actionid: 3,
        tablename: 'Coach340BDetail',
        auditdesc: `User ID ${details.userId} Updated 340B claim record (${
          details.claimPk
        }) (${mapOverallOptionsToStrings(
          details.claim_Status
          )}) for Drug name: ${
            details.selectedPatientInfo.prescriptionName
          } (NDC#: ${details.selectedPatientInfo.ndc}) for patient: ${
            details.selectedPatientInfo.mbi
          }, ${details.selectedPatientInfo.patientName}, ${
            details.selectedPatientInfo.patientDob
          }`,
          applicationID: 2,
        });
      }
      
      details.subsequentFillLogicOptOut = subsequentFillLogicOptOut;
      
      const url = `${this.trackBaseURL}/Sp340BClaims/setDetail`;
      const body = JSON.stringify(details);
      
      let result = fetch(url, { method: 'post', body, headers });
    return result;
  }

  async setClaimToProcessed(claimIds): Promise<Object> {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    const url = `${this.trackBaseURL}/Sp340BClaims/setClaimsToProcessed`;
    let result;

    claimIds.forEach(async (idObj) => {
      const body = JSON.stringify({
        claimIds: idObj.claimId,
        prescriptionId: idObj.prescriptionId,
      });
      result = await fetch(url, { method: 'post', body, headers });
    });

    return result;
  }

  async saveClaimNote(claimNote: any): Promise<Object> {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    const url = `${this.trackBaseURL}/Sp340BClaims/saveClaimNote`;
    const body = JSON.stringify(claimNote);

    let result = fetch(url, { method: 'post', body, headers });
    return result;
  }

  async writeAuditMessage(message: any): Promise<Object> {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    const url = `${this.trackBaseURL}/Sp340BClaims/writeLogMessage`;
    const body = JSON.stringify(message);

    let result = fetch(url, { method: 'post', body, headers });
    return result;
  }

  setSelectedClaimInfo(selectedClaimInfo) {
    this.claimInfo = Object.assign({}, selectedClaimInfo);
    this.claimInfoUpdated.emit(this.claimInfo);
  }

  getSelectedClaimInfo() {
    return this.claimInfo;
  }

  setSelectedProcessingClaimInfo(selectedProcessingClaim) {
    this.processingClaim = Object.assign({}, selectedProcessingClaim);
    this.processingClaimInfoUpdated.emit(this.processingClaim);
  }

  setSelectedInvoicingClaimInfo(selectedInvoicingClaim) {
    this.invoicingClaim = Object.assign({}, selectedInvoicingClaim);
    this.invoicingClaimInfoUpdated.emit(this.invoicingClaim);
  }

  getSelectedProcessingClaimInfo() {
    return this.processingClaim;
  }

  async getInvoices(
    acoId?: string,
    tier2Id?: string,
    tier3Id?: string,
    tier4Id?: string,
    userId: any = null
  ): Promise<Array<IPrescriptionInvoice>> {
    try {
      const headers = this.getStandardHeaders();

      const url = `${this.trackBaseURL}/Sp340BInvoice`;
      const params = {
        userId: this.tier.selectedItem.userID.toString(),
        acoId: acoId || null,
        ...(tier2Id && { tier2Id: tier2Id }),
        ...(tier3Id && { tier3Id: tier3Id }),
        ...(tier4Id && { tier4Id: tier4Id }),
      };
      const options = {
        headers,
        params: params,
      };

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionOrigs: Array<IPrescriptionOrig>) => {
              return PrescriptionOrigs.map((PrescriptionOrig) => {
                PrescriptionOrig.tpaName = PrescriptionOrig.tpaName ? PrescriptionOrig.tpaName : 'CMS';
                return PrescriptionOrig;
              }
            );
          })
        )
        .toPromise()) as Array<IPrescriptionInvoice>;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }
  
  async getInvoiceId(
    coach340BClaimsID: number
  ): Promise<IInvoiceStatusId> {
    try {
      const headers = this.getStandardHeaders();

      const url = `${this.trackBaseURL}/Sp340BInvoice/getInvoiceId`;
      const params = {
        coach340BClaimsID: coach340BClaimsID.toString()
      };
      const options = {
        headers,
        params: params,
      };

      return (await this.http
        .get(url, options)
        .toPromise()) as IInvoiceStatusId;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async submitInvoices(invoices: Array<any>): Promise<Object> {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    const url = `${this.trackBaseURL}/Sp340BInvoice/saveInvoice`;
    let result;

    invoices.forEach(async (invoice) => {
      invoice.userId = this.tier.selectedItem.userID;
      const body = JSON.stringify(invoice);
      result = await fetch(url, { method: 'post', body, headers });
    });

    return result;
  }

  async getPrescriptionDetailWithId(claimId, claimType): Promise<IPrescriptionDetail> {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BClaims/getDetailWithId`;

      const options = {
        headers,
        params: {
          userId: this.tier.selectedItem.userID.toString(),
          claimId,
          claimType,
        },
      };

      return (await this.http
        .get(url, options)
        .pipe(
          map((PrescriptionDetails: Array<IPrescriptionDetail>) => {
            return PrescriptionDetails[0];
          })
        )
        .toPromise()) as IPrescriptionDetail;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async sendFax(claimsId, toFax, claimType): Promise<Response> {
    const headers = {
      Authorization: 'Bearer ' + this._authService.IdToken,
      'Ocp-Apim-Subscription-Key': PrescriptionService.tempOcp_Apim_Sub_Key,
      'content-type': 'application/json',
    };
    const url = `${this.apiBaseURL}/fax/sendFax`;

    const body = JSON.stringify({ claimsId, toFax, claimType });
    const result = await fetch(url, {
      method: 'post',
      body,
      headers,
    });

    return result;
  }

  async uploadFile(formData: FormData): Promise<Observable<Object>> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this._authService.IdToken,
        'Ocp-Apim-Subscription-Key': PrescriptionService.tempOcp_Apim_Sub_Key,
      }),
    };

    const url = `${this.trackBaseURL}/Sp340BClaims/uploadFiles`;
    const upload$ = this.http.post(url, formData, httpOptions);

    return upload$;
  }

  async deleteUploadedFile(tier2Id: string, prescriptionRefId: string, filename: string) {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    let data = { filename, prescriptionRefId, tier2Id };
    let body = JSON.stringify(data);

    const url = `${this.trackBaseURL}/Sp340BClaims/deleteUploadedFile`;
    let result = await fetch(url, { method: 'post', body, headers });
    return result;
  }

  async getFileAttachments(tier2Id: string, prescriptionRefId: string): Promise<string[]> {

    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BClaims/getUploadedFileList`;

      const options = {
        headers,
        params: {
          tier2Id,
          prescriptionRefId,
        },
      };

      return (await this.http.get(url, options).toPromise()) as any;
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async downloadAttachedFiles(tier2Id: string, prescriptionRefId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this._authService.IdToken,
        'Ocp-Apim-Subscription-Key': PrescriptionService.tempOcp_Apim_Sub_Key,
      }),
    };

    const url = `${this.trackBaseURL}/Sp340BClaims/downloadFile`;

    let filenames = await this.getFileAttachments(tier2Id, prescriptionRefId);
    filenames.forEach((filename) => {
      let name = filename['fileName'];
      this.downloadFile(`${url}?tier2Id=${tier2Id}&prescriptionRefId=${prescriptionRefId}&filename=${name}`, name);
    });
  }

  downloadFile(route: string, filename: string = null): void {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this._authService.IdToken,
      'Ocp-Apim-Subscription-Key': PrescriptionService.tempOcp_Apim_Sub_Key,
    });

    this.http
      .get(route, { headers, responseType: 'blob' as 'json' })
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (filename) downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  async getClaimExceptions(
    tier2Id: string,
  ): Promise<Array<IClaimException>> {
    const headers = this.getStandardHeaders();

    const url = `${this.trackBaseURL}/Sp340BClaims/getClaimExceptions`;
    const params = {
      tier2Id
    };
    const options = {
      headers,
      params: params,
    };

    const result = await this.http.get(url, options).toPromise() as Array<IClaimException>;
    return result;
  }

  async moveExceptionToClaim(exceptionId: number) {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BClaims/moveExceptionToClaim`;

      const options = {
        headers,
        params: {
          exceptionId: exceptionId.toString()
        },
      };

      return (await this.http
        .get(url, options)
        .toPromise());
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async rejectException(exceptionId: number) {
    try {
      const headers = this.getStandardHeaders();
      const url = `${this.trackBaseURL}/Sp340BClaims/rejectException`;

      const options = {
        headers,
        params: {
          exceptionId: exceptionId.toString()
        },
      };

      return (await this.http
        .get(url, options)
        .toPromise());
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async undoProcessClaim(claimId: number, claimType: string): Promise<Object> {
    const headers = this.getStandardHeaders();
    headers['content-type'] = 'application/json';

    const url = `${this.trackBaseURL}/Sp340BClaims/undoProcessClaim?coach340BClaimsID=${claimId}&claimType=${claimType}`;

    let result = fetch(url, { method: 'post', headers });
    return result;
  }
}
