import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const ToasterTimeoutInMs = 3000;

@Directive({
  selector: '[copy]'
})
export class CopyToClipboardDirective {
  @Input() copy: string;

  constructor(private elementRef: ElementRef, private toaster: ToastrService) { }

  @HostListener('document:click', ['$event.target'])
  public onCopy(targetElement: ElementRef): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      if (this.copy) {
        navigator.clipboard.writeText(this.copy)
          .then(v => this.toaster.success(null, 'Copied to clipboard!', { timeOut: ToasterTimeoutInMs }));
      } else {
        this.toaster.error(null, 'All mandatory fields required to copy form!', { timeOut: ToasterTimeoutInMs });
      }
    }
  }
}
