import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FilterSettings, AgGridLocalSettingsService } from "@shared/services/ag-grid/ag-grid.service"
import _ from 'lodash';
import { faTrash, IconDefinition, faSave } from '@fortawesome/free-solid-svg-icons';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { CareFeatureConstants, CarePatientItem, PresetCategory } from '@shared/models/module-constants';
import { AgGridTableFilterSetting } from '../filter-settings';
import moment from 'moment';

@Component({
  selector: 'coach-care-patients-filters-settings',
  templateUrl: './care-patients-filters-settings.component.html',
  styleUrls: ['./care-patients-filters-settings.component.scss']
})
export class CarePatientsFiltersSettingsComponent implements OnInit, OnDestroy {
  @Input() selectedPresetSettings?: FilterSettings;
  filterSettingsObservableSubscriber;
  @Input() filterName: CarePatientItem;
  @Input() category: PresetCategory;
  @Input() versionKey: string;
  @Input() origin: string;
  @Input() filterNames: string[];
  @Input() loadingStatus: boolean;
  @Input() isNewFilter: boolean;
  @Input() filterNameIsAlreadyUsed: boolean;
  @Input() defaultFilterModel: { [key: string]: any };
  @Input() filterNewName: CarePatientItem;
  @Output() newFilterSettings: EventEmitter<{ name: string, filters: FilterSettings }> = new EventEmitter();
  @Output() updateFilterName: EventEmitter<{ oldName: string, newName: string }> = new EventEmitter();
  newFilter: string = '';
  openSaveFilterModal: boolean = false;
  PatientListsUpdateFlag: string = CareFeatureConstants.PatientListsUpdateFlag;
  isPatientListsUpdateFlagEnabled: boolean = false;
  openDeleteFilterModal: boolean = false;
  settings: FilterSettings = null;
  @Output() updatedFilterSettings: EventEmitter<FilterSettings> = new EventEmitter();
  @Output() deleteCustomFilterPreset: EventEmitter<null> = new EventEmitter();
  checkedOptions: Array<string[]> = [];
  faTrash: IconDefinition = faTrash;
  faSave: IconDefinition = faSave;
  constructor(private agGridLocalSettingsService: AgGridLocalSettingsService, private featureFlagService: FeatureFlagService) {
    this.isPatientListsUpdateFlagEnabled = this.featureFlagService.hasFeatureFlag(this.PatientListsUpdateFlag)
    this.filterSettingsObservableSubscriber = this.agGridLocalSettingsService.currentFilterSettings.subscribe(filterSettings => {
      this.assignSettings(this.selectedPresetSettings)
    })
  }


  ngOnInit(): void {
  }
  ngOnChanges(changes): void {
    if (changes?.selectedPresetSettings?.currentValue) {
      this.assignSettings(this.selectedPresetSettings)
    }
  }
  assignSettings(filterSettings: FilterSettings): void {
    this.settings = _.cloneDeep(filterSettings)
    this.settings?.rows?.forEach((r, i) => {
      r.isOpen = false
      this.updateCheckedOptions(i)
      if (r.filterType == 'date') {
        if (r.dateTo) r.dateTo = moment(r.dateTo).format("YYYY-MM-DD")
        if (r.dateFrom) r.dateFrom = moment(r.dateFrom).format("YYYY-MM-DD")
      }
      if(r.percentage && r.filter === null) {
        r.filter = 100;
      }
    })
  }
  openAccordion(list_idx: number): void {
    this.settings.rows.forEach((r, i) => {
      r.isOpen = i == list_idx && !r.isOpen
    })
  }
  ngOnDestroy(): void {
    this.filterSettingsObservableSubscriber.unsubscribe()
  }
  getChevronStatus(list_idx: number): string {
    return this.settings.rows?.[list_idx]?.isOpen ? 'chevron-up' : 'chevron-down'
  }
  isOptionChecked(optionName: string, list_idx: number): boolean {
    return !this.settings.rows[list_idx]?.excluded?.includes(optionName)
  }
  checkOption(optionName: string, list_idx: number): void {
    this.isOptionChecked(optionName, list_idx) ? this.settings.rows[list_idx].excluded.push(optionName) : this.settings.rows[list_idx].excluded = this.settings.rows[list_idx]?.excluded.filter(option => option != optionName)
    this.updateCheckedOptions(list_idx)
  }
  checkAllOptions(list_idx: number): void {
    let row = this.settings.rows[list_idx];
    if (row.type == 'set') {
      row.excluded = row.excluded.length == 0 ? row.excluded.concat(row.options) : []
      this.updateCheckedOptions(list_idx)
    } else if(row.percentage) {
      row.filter = 100;
    } else {
      row.filter = null
      row.filterTo = null
      row.dateFrom = null
      row.dateTo = null
      row.filterTo = null
      row.type = null
    }
  }
  updateCheckedOptions(list_idx: number): void {
    if (this.settings.rows[list_idx].type == 'set') {
      this.checkedOptions[list_idx] = this.settings.rows[list_idx].excluded.length == 0 ? ["All"] : this.settings.rows[list_idx].options.filter(o => !this.settings.rows[list_idx].excluded.includes(o))
    }
  }
  isRowValid(row: AgGridTableFilterSetting): boolean {
    if (row.includeAllOrNoneSelection || row.percentage) {
      return true
    } else if (row.type == 'set' && row.excluded.length == row.options.length && row.excluded.length > 0) { // check if nothing checked
      return false
    } else if (row.type == 'inRange' && row.filterType !== 'date' && row.filter > row.filterTo) {
      return false
    } else if ((row.type && row.type != 'unset') && row.type !== 'set' && row.filterType !== 'date' && (!row.filter && row.filter !== 0)) {
      return false
    } else if (row.filterType == 'date') {
      //list of types: inRange, lessThan, lessThanOrEqual, greaterThan, greaterThanOrEqual
      if ((row.dateFrom && !row.dateTo) || (!row.dateFrom && row.dateTo)) return false;
      if (moment(row.dateFrom).isAfter(moment(row.dateTo))) return false;
    } else if(row.type == 'unset' && (row.filter || row.dateFrom) ) { // if filter is unset, but values are selected
      return false;
    }
    return true
  }
  updateFilterValue(row: AgGridTableFilterSetting): void {
    if(!row.type || row.type == 'unset') {
      row.filter = null;
      row.filterTo = null;
    }
  }
  isFormChanged(): boolean {
    if(this.filterName != this.filterNewName) return true; // if name was changed

    let currentFilterSettings = _.cloneDeep(this.settings);
    let oldFilterSettings = _.cloneDeep(this.selectedPresetSettings);
    for (let row in currentFilterSettings.rows) {
      delete currentFilterSettings.rows[row].isOpen;
      delete oldFilterSettings.rows[row].isOpen;
      if (currentFilterSettings.rows[row].filterType == 'date') {
        if (currentFilterSettings.rows[row].dateTo) currentFilterSettings.rows[row].dateTo = moment(currentFilterSettings.rows[row].dateTo).format("YYYY-MM-DD")
        if (currentFilterSettings.rows[row].dateFrom) currentFilterSettings.rows[row].dateFrom = moment(currentFilterSettings.rows[row].dateFrom).format("YYYY-MM-DD")
      }
      if (currentFilterSettings.rows[row].percentage && currentFilterSettings.rows[row].filter === null) {
        currentFilterSettings.rows[row].filter = 100;
      }
      if (currentFilterSettings.rows[row].filterType == 'number') {
        currentFilterSettings.rows[row].filter = Number(currentFilterSettings.rows[row].filter || 0);
        oldFilterSettings.rows[row].filter = Number(oldFilterSettings.rows[row].filter || 0);
      }
      if (!_.isEqual(JSON.stringify(currentFilterSettings.rows[row]), JSON.stringify(oldFilterSettings.rows[row]))) {
        return true;
      }
    };
    return false;
  }
  isFormValid(): boolean {
    if(this.filterNewName != this.filterName && this.filterNames?.includes(this.filterNewName)) return false; // checks if name is already taken
    for (let r in this.settings.rows) {
      if (!this.isRowValid(this.settings.rows[r])) {
        return false
      }
    }
    return true
  }
  saveChanges(): void {
    let clonedSettings = _.cloneDeep(this.settings);
    // change date format
    clonedSettings.rows?.forEach( row => {
      if (row.filterType == 'date') {
        if (row.dateTo) {
          row.dateTo = moment(row.dateTo).format("YYYY-MM-DD");
          row.type = 'inRange';
        } else {
          row.type = null;
        };
        if (row.dateFrom) {
          row.dateFrom = moment(row.dateFrom).format("YYYY-MM-DD");
        };
      }
      if(row.type == null || row.type == 'unset') {
        row.filter = null;
        row.filterTo = null;
        row.dateFrom = null;
        row.dateTo = null;
      }
    })
    if(this.filterNewName != this.filterName){ // in case if a name was edited      
      this.agGridLocalSettingsService.renameCustomPreset(this.category, this.filterName, this.filterNewName, this.versionKey)
      this.agGridLocalSettingsService.storeFilterSettingsInLocalStorage(this.category, this.filterNewName, clonedSettings)
      this.updateFilterName.emit({oldName: this.filterName, newName: this.filterNewName})
    }else{
      this.agGridLocalSettingsService.storeFilterSettingsInLocalStorage(this.category, this.filterName, clonedSettings)
    }
    this.updatedFilterSettings.emit(clonedSettings);
  }
  createNewFilter(): void {
    this.newFilterSettings.emit({
      name: this.isPatientListsUpdateFlagEnabled ? this.filterNewName : this.newFilter,
      filters: this.settings
    })
  }

  clearFilters(): void {    
    this.settings.rows.forEach((row, idx) => {
      if (row.type == 'set') {
        row.excluded = [];
        this.updateCheckedOptions(idx);
      } else if (row.percentage) {
        row.filter = this.defaultFilterModel?.ssPercent?.filter;
      } else {
        row.filter = null
        row.filterTo = null
        row.type = null
        row.dateFrom = null
        row.dateTo = null
      }
    })
    this.agGridLocalSettingsService.storeFilterSettingsInLocalStorage(this.category, this.filterName, this.settings)
    this.updatedFilterSettings.emit(this.settings)
  }

  deleteCustomFilter(): void {
    this.agGridLocalSettingsService.deleteCustomPreset(this.category, this.filterName, this.versionKey)
    this.deleteCustomFilterPreset.emit()
    this.openDeleteFilterModal = false;
  }

  getRowValueLabelText(row): string {
    let setLabel = row.filterOptions?.filter(fo => fo.value == row.type)[0]?.label // matching filter type
    if (setLabel && row.filterType !== 'date') {
      setLabel += ` ${row.filter ? row.filter : (row.dateFrom ? moment(row.dateFrom).format("MM/DD/YYYY") : "")}`;
      if (row.type == 'inRange' && (row.filterTo || row.dateTo)) {
        setLabel += ` to ${row.filterTo ? row.filterTo : moment(row.dateTo).format('MM/DD/YYYY')}`;
      }
    } else if (row.filterType == 'date' && row.dateFrom) {
      setLabel = "From " + moment(row.dateFrom).format("MM/DD/YYYY");
      if (row.dateTo) {
        setLabel += ` to ${moment(row.dateTo).format('MM/DD/YYYY')}`;
      }
    } else if(row.percentage) {
      setLabel = row.filter < 100 ? `Top ${row.filter}%` : 'All';
    }
    return setLabel;
  }

  /**
     * @list_idx list index is used as a pointer to find a selected filter setting
     * @value is a string represents an option, whether it's checked or not
     */
  doesExcludedContainValue(list_idx: number, value: string): boolean {

    return this.settings.rows[list_idx].excluded.includes(value)
  }
  /**
     * @list_idx list index is used as a pointer to find a selected filter setting
     * @checkedAsYes is a boolean represents "Yes" (as true) and "No" (as false)
     */
  checkIncludeAllOrNoneSelection(list_idx: number, checkedAsYes: boolean): void {
    if (!checkedAsYes && !this.doesExcludedContainValue(list_idx, 'Yes')) {
      this.settings.rows[list_idx].excluded.push('Yes')
    } else if (checkedAsYes) {
      this.settings.rows[list_idx].excluded = []
    }
  }
  preventValueToBeBelowMin(value: number, min: number): void {
    if (value < min) {
      value = min;
    }
  }
  displayCheckAllOptionsButton(row: AgGridTableFilterSetting): boolean {
    return !row.includeAllOrNoneSelection 
    && (
      (row.type && row.type != 'unset' && row.type != 'set' && !row.percentage) 
      || 
      (row.type == 'set' && row.excluded.length > 0)
      ||
      (row.filter < 100 && row.percentage)
      )
  }
}
