/* tslint:disable */
/* eslint-disable */

/**
 * TODO
 */
export enum PropertiesType {
  ScheduledProperties = 'ScheduledProperties',
  CompletedProperties = 'CompletedProperties',
  CallBackProperties = 'CallBackProperties',
  RemovedProperties = 'RemovedProperties',
  PatientWasRemindedProperties = 'PatientWasRemindedProperties',
  PatientDidNotAnswerProperties = 'PatientDidNotAnswerProperties',
  PatientAttendedProperties = 'PatientAttendedProperties',
  PatientDidNotAttendProperties = 'PatientDidNotAttendProperties'
}
