import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-aco-onboard',
  templateUrl: './aco-onboard.component.html',
  styleUrls: ['./aco-onboard.component.scss']
})
export class AcoOnboardComponent implements OnInit {
  app = AppConstants;

  constructor() { }

  ngOnInit(): void {
  }

}
