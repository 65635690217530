import { Component, OnInit, Input } from '@angular/core';
import { PatientService } from '@shared/services/patient/patient.service';
import { Patient } from '@shared/models/patient';
import {
  Diagnosis,
  Diseasegroup,
  FacesheetInteractiveConditionRequest,
  Facesheets,
  Interaction
} from '@api/track/models';
import { FacesheetService } from '@api/track/services';
import { ExtendedDiagnosis } from '../facesheet-extended-models/diagnosis';
import { ExtendedDiseasegroup } from '../facesheet-extended-models/diseasegroup';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { ErrorHandlerService } from '@shared/services/logging/error-handler.service';
import { forkJoin } from 'rxjs';
import { debounce } from 'lodash';

export enum DxStatusID {
  MarkedNotApplicableViaAssessment = 1,
  MarkedClosedViaAssessment = 2,
  ClosedByClaim = 3,
  ClosedByHierarchy = 4
}

@Component({
  selector: 'coach-patient-facesheet-drag-drop',
  templateUrl: './patient-facesheet-drag-drop.component.html',
  styleUrls: ['./patient-facesheet-drag-drop.scss']
})
export class PatientFacesheetDragDropComponent implements OnInit {
  private _patient: Patient;

  @Input()
  set patient(value: Patient) {
    this._patient = value;
    if (value && value.trackPatient && value.trackPatient.chPatId) {
      this.chPatId = parseInt(value.trackPatient.chPatId);
      this.loadFacesheet();
    }
  }

  get patient(): Patient {
    return this._patient;
  }

  public loading = true;
  openGaps: Array<ExtendedDiseasegroup> = [];
  closedByClaim: Array<Diseasegroup> = [];
  closedByHierarchy: Array<Diseasegroup> = [];
  closedNotApplicable: Array<ExtendedDiagnosis> = [];
  closedViaAssessment: Array<ExtendedDiagnosis> = [];
  chPatId: number;
  showNoneStatus: boolean;

  constructor(
    private _facesheetService: FacesheetService,
    private toastrMessageService: ToastrMessageService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  markAsClosed(item: ExtendedDiagnosis): void {
    if (!item.disabled) {
      this.loading = true;
      item.disabled = true; // prevent double click
      const bodyPayload: FacesheetInteractiveConditionRequest = {
        chPatID: this.chPatId,
        dxCode: item.dx,
        dxStatusID: DxStatusID.MarkedClosedViaAssessment, // 0,1,2,3
        undo: 0
      };

      this._facesheetService.facesheetInteractiveConditionPost({ body: bodyPayload }).subscribe(
        () => {
          this.loadFacesheetDebounced();
        },
        errors => {
          this.toastrMessageService.error(errors);
          this.errorHandlerService.handleError(errors);
        }
      );
    }
  }

  markAsNotApplicable(item: ExtendedDiagnosis): void {
    if (!item.disabled) {
      this.loading = true;
      item.disabled = true;
      const bodyPayload: FacesheetInteractiveConditionRequest = {
        chPatID: this.chPatId,
        dxCode: item.dx,
        dxStatusID: DxStatusID.MarkedNotApplicableViaAssessment,
        undo: 0
      };

      this._facesheetService.facesheetInteractiveConditionPost({ body: bodyPayload }).subscribe(
        () => {
          this.loadFacesheetDebounced();
        },
        errors => {
          this.toastrMessageService.error(errors);
          this.errorHandlerService.handleError(errors);
        }
      );
    }
  }

  undoClosed(item: ExtendedDiagnosis): void {
    if (!item.disabled) {
      this.loading = true;
      item.disabled = true; // prevent double click
      const bodyPayload: FacesheetInteractiveConditionRequest = {
        chPatID: this.chPatId,
        dxCode: item.dx,
        dxStatusID: DxStatusID.MarkedClosedViaAssessment,
        undo: 1
      };

      this._facesheetService.facesheetInteractiveConditionPost({ body: bodyPayload }).subscribe(
        () => {
          this.loadFacesheetDebounced();
        },
        errors => {
          this.toastrMessageService.error(errors);
          this.errorHandlerService.handleError(errors);
        }
      );
    }
  }

  undoNotApplicable(item: ExtendedDiagnosis): void {
    if (!item.disabled) {
      this.loading = true;
      item.disabled = true; // prevent double click
      const bodyPayload: FacesheetInteractiveConditionRequest = {
        chPatID: this.chPatId,
        dxCode: item.dx,
        dxStatusID: DxStatusID.MarkedNotApplicableViaAssessment,
        undo: 1
      };

      this._facesheetService.facesheetInteractiveConditionPost({ body: bodyPayload }).subscribe(
        () => {
          this.loadFacesheetDebounced();
        },
        errors => {
          this.toastrMessageService.error(errors);
          this.errorHandlerService.handleError(errors);
        }
      );
    }
  }

  showTooltip(groupIdx: number, diagIdx: number) {
    this.openGaps[groupIdx].diagnoses[diagIdx].tooltipInteractions = true;
  }

  hideTooltip(groupIdx: number, diagIdx: number) {
    this.openGaps[groupIdx].diagnoses[diagIdx].tooltipInteractions = false;
  }

  showTooltipClosed(groupIdx: number, diagIdx: number) {
    this.openGaps[groupIdx].diagnoses[diagIdx].tooltipMarkAsClosed = true;
  }

  hideTooltipClosed(groupIdx: number, diagIdx: number) {
    this.openGaps[groupIdx].diagnoses[diagIdx].tooltipMarkAsClosed = false;
  }

  showTooltipNotApplicable(groupIdx: number, diagIdx: number) {
    this.openGaps[groupIdx].diagnoses[diagIdx].tooltipMarkAsNotApplicable = true;
  }

  hideTooltipNotApplicable(groupIdx: number, diagIdx: number) {
    this.openGaps[groupIdx].diagnoses[diagIdx].tooltipMarkAsNotApplicable = false;
  }

  ngOnInit() {}

  private loadFacesheetDebounced = debounce(() => {
    this.loadFacesheet();
  }, 200);

  private loadFacesheet(): void {
    this.loading = true;

    forkJoin({
      chronicCondition: this._facesheetService.facesheetConditionsGet({
        ChPatId: this.chPatId,
        IsChronic: true
      }),
      acuteCondition: this._facesheetService.facesheetConditionsGet({
        ChPatId: this.chPatId,
        IsChronic: false
      })
    }).subscribe(
      ({ chronicCondition, acuteCondition }) => {
        this.openGaps = [];
        this.closedByClaim = [];
        this.closedByHierarchy = [];
        this.closedViaAssessment = [];
        this.closedNotApplicable = [];

        this.updateFacesheet(chronicCondition?.facesheet, acuteCondition?.facesheet);

        this.setNoneStatus();
        this.loading = false;
      },
      errors => {
        this.toastrMessageService.error(errors);
        this.errorHandlerService.handleError(errors);
        this.loading = false;
      }
    );
  }


  private setNoneStatus() {
    if (
      this.closedViaAssessment?.length === 0 &&
      this.closedNotApplicable?.length === 0 &&
      this.closedByClaim?.length === 0 &&
      this.closedByHierarchy?.length === 0
    ) {
      this.showNoneStatus = true;
    } else {
      this.showNoneStatus = false;
    }
  }

  // Groups together by groupname chronic and acute diagnosis
  mergeArrays(arr1: Diseasegroup[], arr2: Diseasegroup[]): Diseasegroup[] {
    arr2.forEach(item => {
      const existingIndex = arr1.findIndex(res => res.groupname === item.groupname);
      if (existingIndex !== -1) {
        // set it if it doesn't exist
        arr1[existingIndex].diagnoses = arr1[existingIndex].diagnoses.concat(item.diagnoses);
      } else {
        // merge if it does...
        arr1.push({ ...item });
      }
    });
    arr1.sort((a, b) => a.groupid - b.groupid);
    return arr1;
  }

  // Removes null values from tooltips
  cleanupTooltips(input: ExtendedDiseasegroup[]): ExtendedDiseasegroup[] {
    input.forEach((item: ExtendedDiseasegroup) => {
      item.diagnoses.forEach((item2: ExtendedDiagnosis) => {
        item2.disabled = false;
        item2.interactions = item2.interactions.filter(
          (item3: Interaction) => item3 != null && item3.interactionname != null
        );
      });
    });
    return input;
  }

  private getExtendedDiagnosis(input: Diagnosis[]): ExtendedDiagnosis[] {
    const extendedDiagnosis = input as ExtendedDiagnosis[];
    extendedDiagnosis.forEach(extendedDiag => (extendedDiag.disabled = false)); // set to false to avoid accidental double clicks on undo button
    return extendedDiagnosis;
  }

  updateFacesheet(facesheetChronic: Facesheets[] = [], facesheetAcute: Facesheets[] = []) {
    try {
      // Open
      const chronicOpen = facesheetChronic.find(item => item.status === 'Open');
      if (chronicOpen && chronicOpen.diseasegroup) {
        this.openGaps = chronicOpen.diseasegroup as ExtendedDiseasegroup[];
      }
      const acuteOpen = facesheetAcute.find(item => item.status === 'Open');
      if (acuteOpen && acuteOpen.diseasegroup) {
        this.openGaps = this.mergeArrays(this.openGaps, acuteOpen.diseasegroup) as ExtendedDiseasegroup[];
      }
      this.openGaps = this.cleanupTooltips(this.openGaps);

      // Claim
      const chronicClosedByClaim = facesheetChronic.find(item => item.status === 'Closed By Claim');
      if (chronicClosedByClaim && chronicClosedByClaim.diseasegroup) {
        this.closedByClaim = chronicClosedByClaim.diseasegroup;
      }
      const acuteClosedByClaim = facesheetAcute.find(item => item.status === 'Closed By Claim');
      if (acuteClosedByClaim && acuteClosedByClaim.diseasegroup) {
        this.closedByClaim = this.mergeArrays(this.closedByClaim, acuteClosedByClaim.diseasegroup);
      }

      // Hierarchy
      const chronicClosedByHierarchy = facesheetChronic.find(item => item.status === 'Closed By Hierarchy');
      if (chronicClosedByHierarchy && chronicClosedByHierarchy.diseasegroup) {
        this.closedByHierarchy = chronicClosedByHierarchy.diseasegroup;
      }

      const acuteClosedByHierarchy = facesheetAcute.find(item => item.status === 'Closed By Hierarchy');
      if (acuteClosedByHierarchy && acuteClosedByHierarchy.diseasegroup) {
        this.closedByHierarchy = this.mergeArrays(this.closedByHierarchy, acuteClosedByHierarchy.diseasegroup);
      }

      // Marked as Closed via Assessment
      const chronicClosedGroup = facesheetChronic.find(obj => obj.status === 'Marked as Closed via Assessment');
      if (chronicClosedGroup && chronicClosedGroup.diseasegroup) {
        chronicClosedGroup.diseasegroup.forEach(dg => {
          this.closedViaAssessment.push(...this.getExtendedDiagnosis(dg.diagnoses));
        });
      }

      const acuteClosedGroup = facesheetAcute.find(obj => obj.status === 'Marked as Closed via Assessment');
      if (acuteClosedGroup && acuteClosedGroup.diseasegroup) {
        acuteClosedGroup.diseasegroup.forEach(dg => {
          this.closedViaAssessment.push(...this.getExtendedDiagnosis(dg.diagnoses));
        });
      }

      // Marked as Not Applicable via Assessment
      const chronicNotApplicableGroup = facesheetChronic.find(obj => obj.status === 'Marked as Not Applicable via Assessment');
      if (chronicNotApplicableGroup && chronicNotApplicableGroup.diseasegroup) {
        chronicNotApplicableGroup.diseasegroup.forEach(item => {
          this.closedNotApplicable.push(...this.getExtendedDiagnosis(item.diagnoses));
        });
      }
      const acuteNotApplicableGroup = facesheetAcute.find(obj => obj.status === 'Marked as Not Applicable via Assessment');
      if (acuteNotApplicableGroup && acuteNotApplicableGroup.diseasegroup) {
        acuteNotApplicableGroup.diseasegroup.forEach(item => {
          this.closedNotApplicable.push(...this.getExtendedDiagnosis(item.diagnoses));
        });
      }
    } catch (errors) {
      this.toastrMessageService.error(errors);
      this.errorHandlerService.handleError(errors);
    }
  }
}
