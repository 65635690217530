import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IPrescriptionDetail } from '@shared/models/prescription';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';
import { Subscription } from 'rxjs';
import { copyTextToClipboard } from '@shared/utilities';
import { TabSwitchState } from './tab-switch/tab-switch.component';
import { formatDate, basicNameSplit, removeLeadingZeros } from '@care/views/care-prescriptions/shared-lib';
import { MaskPipe } from '@shared/pipes/mask.pipe';

import pdfMake from "pdfmake/build/pdfmake";  
import pdfFonts from "pdfmake/build/vfs_fonts";  
import { CareFeatureConstants } from '@shared/models/module-constants';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'coach-care-prescriptions-processing-sidebar',
  templateUrl: './care-prescriptions-processing-sidebar.component.html',
  styleUrls: ['./care-prescriptions-processing-sidebar.component.scss'],
})
export class CarePrescriptionsProcessingSidebarComponent implements OnInit, OnDestroy {
  importFeatureFlag: string = CareFeatureConstants.FileImport340B;

  @Input() selectedPatientInfo: any = [];
  @Input() show: boolean;
  
  @Output() onProcessClaim = new EventEmitter();
  @Output() onUndoProcessClaim = new EventEmitter();

  claimId: number;

  currentUser: any;
  creatorName: string;
  creatorDate: Date;

  authSubscription: Subscription;

  isValidationPanelOpen: boolean = false;
  isClaimSelected: boolean = false;
  notes: string = '';
  toggleVisitNoteModal: boolean = false;
  toggleCareCoordinationNoteModal: boolean = false;
  currentRowId: number;

  prescriptionDetails: IPrescriptionDetail;
  prescriptionSubscription: Subscription;

  isSummaryTabSelected: boolean = true;
  isQuickFillTabSelected: boolean = false;
  isCareNoteSelected: boolean = false;

  patientName: string;
  patientDobText: string;
  patientMbi: string;

  constructor(
    private prescriptionService: PrescriptionService,
    private authService: AuthService,
    private mask: MaskPipe
  ) {}

  ngOnInit(): void {
    this.prescriptionSubscription = this.prescriptionService.processingClaimInfoUpdated.subscribe((claim:any) => {
      this.selectedPatientInfo = claim.selectedPatientInfo;
      this.currentRowId = claim.rowId;
      this.updatePrescriptionDetailsData(this.selectedPatientInfo.claimId);
    })
    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser
        this.creatorName = this.currentUser.name || this.currentUser.email;
        this.creatorDate = new Date();
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  setTabStatus(e: TabSwitchState) {
    this.isSummaryTabSelected = e.isSummaryTabSelected;
    this.isQuickFillTabSelected = e.isQuickFillTabSelected;
    this.isCareNoteSelected = e.isCareNoteSelected;
  }

  updatePrescriptionDetailsData(claimId) {
    if (!this.isValidationPanelOpen) {
      this.toggleValidatePanel();
    }
    this.prescriptionService
      .getPrescriptionDetail(
        this.selectedPatientInfo.claimPk,
        this.selectedPatientInfo.acoId,
        this.selectedPatientInfo.tier2_id,
        this.selectedPatientInfo.tier3_id,
        this.selectedPatientInfo.tier4_grpId,
        claimId,
        this.selectedPatientInfo.userId,
        this.selectedPatientInfo
        )
        .then((data) => {
          this.prescriptionDetails = data;
          this.patientName = basicNameSplit(this.selectedPatientInfo.patientName).join(' ');
          this.patientDobText = this.formatDate(this.selectedPatientInfo.patientDob);
          this.patientMbi = this.selectedPatientInfo.mbi ? this.selectedPatientInfo.mbi : 'N/A';
      })
      .catch((err) => console.log(err));
  }

  getFillInfo(firstFilled: string, fillNumber: string): string {
    let requiredSpaces = firstFilled.split('/')[0].length > 1 ? 22 : 24;
    let filledStr = `Filled ${firstFilled}`.padEnd(requiredSpaces, '\xa0');
    return `${filledStr} FILL # ${fillNumber}`;
  }
  
  processThisClaim() {
    this.onProcessClaim.emit(this.selectedPatientInfo.claimPk);
    this.toggleValidatePanel();
  }

  undoProcessThisClaim() {
    this.onUndoProcessClaim.emit(this.selectedPatientInfo.claimPk);
    this.toggleValidatePanel();
  }

  getPatientFirstName() {
    let nameMatches = this.getPatientName();
    return nameMatches[1];
  }

  getPatientLastName() {
    let names = this.selectedPatientInfo.patientName.split(' ');
    return names[names.length - 1];
  }

  getPatientName() {
    let regex = /(\w+)\s+(\w+)/;
    return this.selectedPatientInfo.patientName.match(regex);
  }

  stripLeadingZeros(str) {
    if (str) {
      return str.replace(/0{2,}/, '');
    }
    return '';
  }

  copyData(text) {
    copyTextToClipboard(text);
  }

  public formatDate(dateStr) {
    return new Date(dateStr).toLocaleDateString();
  }

  public formatPhone(value) {
    if (!value) {
      return '';
    }

    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  }

  public getPatientSex() {
    return this.selectedPatientInfo.gender;
  }

  getMbi() {
    if (this.selectedPatientInfo && this.selectedPatientInfo.claimType !== 'TPA') {
      return this.mask.transform(this.selectedPatientInfo.mbi, 6)
    } else {
      return "N/A";
    }
  }

  // Right Panel Operation
  public toggleValidatePanel() {
    this.isValidationPanelOpen = !this.isValidationPanelOpen;
  }

  exportClaimFiles(): void {
    this.prescriptionService.downloadAttachedFiles(this.selectedPatientInfo.tier2_id, this.selectedPatientInfo.prescriptionRefId);
    this.generatePDF();
  }

  async generatePDF() {
    let username = await this.prescriptionService.getUserFullName(parseInt(this.selectedPatientInfo.userId));

    let docDefinition = {  
      content: [
        {
          text: 'Care Coordination Note',
          fontSize: 14,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        {
          text: `Patient Name: ${this.patientName}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `DOB: ${this.patientDobText}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `MBI: ${this.mask.transform(this.patientMbi, 6)}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `By: ${this.prescriptionDetails.claim_Status_Set_By}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `Date: ${formatDate(this.prescriptionDetails.claim_Status_Set_On_Date)}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: ' ',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: '340B External Prescription',
          fontSize: 14,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        {
          text: this.selectedPatientInfo.prescriptionName,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: true
        },
        {
          text: `by ${this.selectedPatientInfo.prescriberName}${this.selectedPatientInfo.prescriberCredentials 
            ? ", " + this.selectedPatientInfo.prescriberCredentials : ''}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `First filled ${formatDate(this.selectedPatientInfo.firstFilled)}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: ' ',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: 'Follow-Up Plan:',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        {
          text: `${this.prescriptionDetails.follow_Up_Plan.replace(/<br \/>/g, '\n')}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: ' ',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `Visit Note from ${this.selectedPatientInfo.prescriberName}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        {
          text: `Visit Date: ${formatDate(this.prescriptionDetails.visit_Note_Visit_Date)}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false,
        },
        {
          text: `Rx Diagnosis: ${this.prescriptionDetails.visit_Note_Diagnosis}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false,
        },
        {
          text: ' ',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
      ]
    };

    const ceRelatedReferralDate = formatDate(this.prescriptionDetails.cE_Related_Referral_Date);
    const ceVisitDate = formatDate(this.prescriptionDetails.cE_Visit_Date);

    if (ceVisitDate !== '12/31/69'
        && ceVisitDate !== '01/01/70') {
      docDefinition.content.push({
        text: 'Related Visit By Covered Entity:',
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: true,
        decoration: 'underline'
      });
      docDefinition.content.push({
        text: `Visit Date: ${ceVisitDate}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
      docDefinition.content.push({
        text: `Provider: ${this.prescriptionDetails.cE_Provider}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
      docDefinition.content.push({
        text: `CE Location: ${this.prescriptionDetails.cE_Location}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
      docDefinition.content.push({
        text: ' ',
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false
      })
    }
    if (ceRelatedReferralDate !== '12/31/69'
        && ceRelatedReferralDate !== '01/01/70') {
      docDefinition.content.push({
        text: 'Related Referral By Covered Entity:',
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: true,
        decoration: 'underline'
      });
      docDefinition.content.push({
        text: `Referral Date: ${ceRelatedReferralDate}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
    }
   
    pdfMake.createPdf(docDefinition).download();
  }
}
