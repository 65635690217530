import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'coach-eula-agreement',
  templateUrl: './eula-agreement.component.html',
  styleUrls: ['./eula-agreement.component.scss']
})
export class EulaAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
