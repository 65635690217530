<div class="page-content">
  <div class="queue u-position--relative animation--content--fadeIn"
    [ngClass]="isQueueOpen ? 'is-open border-right' : 'is-closed'">
    <button class="pullTab top--xs" (click)="toggleQueueOpen()" [ngClass]="{'is-open': isQueueOpen}"
      aria-label="Toggle Practice Improvement List Panel">
      <coach-icon iconName="chevrons-right"></coach-icon>
    </button>
    <div class="header">
      <div class="titles t3">
        <div class="title">{{submitMeetingName}}</div>
      </div>
    </div>
    <div class="dynamicBody padding">
      <p>As your ACO Partner, we'd like to stay in tune with your progress so we can provide our insights as needed.</p>
      <p>We're asking that you upload your agenda and meeting notes from your monthly Roadmap and quarterly Cohort
        Steering meetings, along with some of the highlights of each of those meetings.</p>

      <div class="row-bottom" *ngIf="!ppSet">
        <div class="u-italic u-color--danger padding-top--small">
          At least one participant is required
        </div>
      </div>
      <label class="label" for="meetingPrincipleParticipant">
        Principle Participant(s)
        <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small" iconColor="primary"
          (click)="toggleInfoText(principleParticipantHelpText)">
        </coach-icon>
      </label>
      <select class="field" name="meetingPrincipleParticipant" id="meetingPrincipleParticipant"
        (change)="ppSelected($event.target.value)" [(ngModel)]="selectedPP" required autofocus>
        <option *ngIf="principleParticipants != null && principleParticipants.length === 0" [value]="null" disabled>
          No Available Principle Participants
        </option>
        <option [ngValue]="null" selected disabled>Select User</option>
        <option *ngFor="let pp of principleParticipants" [value]="pp.TierID">
          {{pp.TierName}}
        </option>
      </select>
    </div>
  </div>

  <form class="page-content-main meetingForm" [formGroup]="meetingForm" (ngSubmit)="onSubmit()">
    <div class="dynamicBody padding--larger padding-top--larger margins-bottom--larger u-background-color--white">


      <ng-template *ngIf="(currentTier)?.tier === 2; else ppSelectedWarning" #customComponent></ng-template>
      <ng-template #ppSelectedWarning>
        <div
          class="dynamicBody row--verticalCenter u-center u-italic u-opacity--lighter u-font-size--large u-thin u-color--danger">
          {{selectCommunity}}
        </div>
      </ng-template>

      <div class="tabs tabs--secondary row--wrap">
        <div class="margin-right--large margin-bottom--large">
          <input class="tab" name="type" type="radio" id="monthlyTab" [value]="'monthly'" formControlName="type">
          <label for="monthlyTab" class="u-font-size--large">
            Monthly Roadmap Meeting
          </label>
        </div>
        <div>
          <input class="tab" name="type" type="radio" id="quarterlyTab" [value]="'quarterly'" formControlName="type">
          <label for="quarterlyTab" class="u-font-size--large">
            Quarterly Cohort Meeting
          </label>
        </div>
      </div>
      <div class="row--wrap">
        <div class="meetingUploads-formColumn meetingUploads-formColumn--left u-fill margins-bottom--larger">
          <ng-container [ngSwitch]="meetingForm.value.type">
            <ng-container *ngSwitchCase="'quarterly'">

              <div class="group">
                <div class="label">Quarter</div>
                <div class="field">
                  <div class="button-group is-primary is-stacked u-width--full">
                    <ng-container *ngFor="let quarter of quartersData; let i = index">
                      <div class="group u-text-align--center">
                        <input name="quarter" type="radio" id="quarter-{{i}}" [value]="quarter.value"
                          formControlName="quarter">
                        <label for="quarter-{{i}}">{{quarter.label}}</label>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'monthly'">
              <div class="group">
                <div class="label">Month</div>
                <div class="field row--wrap button-group is-primary u-width--full button-group--month">
                  <div *ngFor="let month of monthsData" class="group u-text-align--center">
                    <input name="month" type="radio" id="month-{{month.value}}" [value]="month.value"
                      formControlName="month">
                    <label for="month-{{month.value}}">
                      {{month.label}}
                    </label>
                  </div>
                </div>
              </div>
              <div class="group group--4 animation--fadeIn" *ngIf="meetingForm.value.month">
                <label for="meeting-quarter" class="label">
                  Number of local roadmaps held that month
                </label>
                <div class="field u-width--smaller u-preserve">
                  <input type="number" formControlName="meetingCount" min="0" placeholder="0">
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="group">
            <label for="meeting-agenda" class="label">Agenda</label>
            <div class="field">
              <div class="fileUploadWrapper clump padding--small u-width--full row--verticalCenter">
                <div class="inputButtonCombo u-preserve margin-right--small">
                  <input type="file" id="meeting-agenda" (change)="onAgendaFileChange($event)">
                  <button class="button is-small"
                    [ngClass]="{'is-primary': uploadedAgendaFileName === 'No file chosen'}">
                    {{uploadedAgendaFileName === 'No file chosen' ? 'Upload' : 'Choose'}} File
                  </button>
                </div>
                <div>{{uploadedAgendaFileName}}</div>
              </div>
            </div>
          </div>
          <div class="group">
            <label for="meeting-minutes" class="label">Minutes</label>
            <div class="field">
              <div class="fileUploadWrapper clump padding--small u-width--full row--verticalCenter"
                [ngClass]="{'is-invalid': meetingForm.get('minutes').invalid && meetingForm.get('minutes').touched}">
                <div class="inputButtonCombo u-preserve margin-right--small">
                  <input type="file" id="meeting-minutes" (change)="onMinutesFileChange($event)">
                  <button class="button is-small"
                    [ngClass]="{'is-primary': uploadedMinutesFileName === 'No file chosen'}">
                    {{uploadedMinutesFileName === 'No file chosen' ? 'Upload' : 'Change'}} File
                  </button>
                </div>
                <div>{{uploadedMinutesFileName}}</div>
              </div>
              <div *ngIf="meetingForm.get('minutes').value.length === 0 ">
                <div class="u-italic u-color--danger padding-top--small">Must upload file.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="meetingUploads-formColumn meetingUploads-formColumn--right u-fill margins-bottom--larger">
          <ng-container [ngSwitch]="meetingForm.value.type">
            <ng-container *ngSwitchCase="'quarterly'">
              <div class="group">
                <label for="meeting-success-story" class="label">Patient Success Story / Practice Bright Spot</label>
                <div class="field">
                  <!-- TODO: this data is not being saved! -->
                  <textarea rows="9" formControlName="brightSpot" placeholder="Enter success story or bright spot...">
                  </textarea>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'monthly'">
              <div class="group">
                <div class="label">Successes</div>
                <div class="field padding-top--small">
                  <form [formGroup]="successesForm">
                    <div formArrayName="successes" *ngFor="let success of successesFormArray.controls; let i = index">
                      <input type="checkbox" id="success-{{i}}" [formControlName]="i">
                      <label for="success-{{i}}">
                        {{successesList[i].label}}
                      </label>
                    </div>
                  </form>
                  <div>
                    <input type="checkbox" id="success-other" [(ngModel)]="hasOtherSuccesses"
                      [ngModelOptions]="{standalone: true}" (click)="toggleOnFocusOtherSuccesses()">
                    <label for="success-other">Other</label>
                  </div>
                  <ng-container *ngIf="hasOtherSuccesses">
                    <textarea name="meeting-otherSuccesses" id="meeting-otherSuccesses" rows="5"
                      [(ngModel)]="otherSuccesses" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="updateSelectedSuccesses()" placeholder="Enter other successes..."
                      #meetingForm_otherSuccesses>
                    </textarea>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="group">
            <div class="label">Barriers</div>
            <div class="field padding-top--small">
              <form [formGroup]="barriersForm">
                <div formArrayName="barriers" *ngFor="let barriers of barriersFormArray.controls; let i = index">
                  <input type="checkbox" id="barriers-{{i}}" [formControlName]="i">
                  <label for="barriers-{{i}}">{{barriersList[i].label}}</label>
                </div>
              </form>
              <div>
                <input type="checkbox" id="barriers-other" [(ngModel)]="hasOtherBarriers"
                  [ngModelOptions]="{standalone: true}" (click)="toggleOnFocusOtherBarriers()">
                <label for="barriers-other">Other</label>
              </div>
              <ng-container *ngIf="hasOtherBarriers">
                <textarea name="meeting-otherBarriers" id="meeting-otherBarriers" rows="5" [(ngModel)]="otherBarriers"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="updateSelectedBarriers()"
                  placeholder="Enter other barriers..." #meetingForm_otherBarriers>
                </textarea>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <coach-hierarchy-required pageName="Meeting Form" [requiredTier]="2"></coach-hierarchy-required>
    <div class="footer">
      <button class="button u-flex--right" type="submit" [disabled]="formSaving"
        [ngClass]="{ 'faux-disabled' : meetingForm.invalid}">
        Submit
      </button>
    </div>
    <div class="overlay overlay--light is-open" *ngIf="isInfoTextOpen">
      <div class="modal margin u-max-width--larger" (click)="$event.stopPropagation()">
        <div class="dynamicBody padding--large">
          <p [innerHtml]="infoText"></p>
          <!-- <div class="margin-top margin-bottom" *ngIf="this.infoMeasureLinkFile != null">
            <div class="text-center">
              <a [routerLink]="['../../improve/practice-improvement']"
                [queryParams]="{folder: infoMeasureLinkFolder, file: infoMeasureLinkFile}" target="_blank">Link to
                Measure Specification</a>
            </div>
            <div class="text-center">
              <a [routerLink]="['../../improve/practice-improvement']"
                [queryParams]="{folder: infoMeasureLinkFolder, file: '2120'}" target="_blank">Link to Scoring
                Methodology</a>
            </div>
          </div> -->
          <p>Additional information may be found at <a
              href="https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/sharedsavingsprogram/Downloads/2018-and-2019-quality-benchmarks-guidance.pdf"
              target="_blank"> www.cms.gov</a></p>
        </div>
        <div class="footer">
          <button class="button is-primary" (click)="toggleInfoText(null); $event.stopPropagation();">Close</button>
        </div>
      </div>
    </div>
  </form>
</div>
