import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';

interface DocumentLookupResult {
  title: string;
  isShared: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentLookupService {
  private docEndpoint = environment.coachApiUrl + '/api/docRepo/fileInfo/';
  private get httpHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authService.IdToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    });
  }
  private cache = {};

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {  }

  public async getTitle(fileId: string): Promise<string> {
    if (this.cache[fileId]) {
      return this.cache[fileId];
    } else {
      const result = await this.httpClient
        .get<DocumentLookupResult>(this.docEndpoint + fileId, {
          responseType: 'json',
          headers: this.httpHeaders,
        })
        .toPromise();
      if (result.title) {
        this.cache[fileId] = result.title;
        return result.title;
      } else {
        return undefined;
      }
    }
  }
}
