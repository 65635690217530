import { Injectable } from '@angular/core';
import { TrackEmbeddedReport } from '@track/track-reports/track-embedded-report';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReportAuditRequest } from '@track/track-reports/track-report/report-audit-request';
import { Observable } from 'rxjs';
import { TrackReportParameters } from '../track-report/track-report-parameters';
import { Router } from '@angular/router';
import { TrackReportMenuItem } from '../track-report-menu-item';
import { environment } from 'src/environments/environment';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { CIN_REPORT_LEVEL, REGION_REPORT_LEVEL } from '@track/track-reports/track-report-filter-type';

@Injectable({
  providedIn: 'root'
})
export class TrackReportService extends AzureApiBase<TrackEmbeddedReport> {
  constructor(http: HttpClient, authService: AuthService, private router: Router, private hierarchyTierService: HierarchyTierService) {
    super(http, authService, environment.trackApiUrl, '/report');
  }

  public lastRunYear: number;
  public lastRunQuarter: number;

  getAccessToken(reportId: string): Observable<TrackEmbeddedReport> {
    const params: HttpParams = new HttpParams().set('reportId', reportId);
    return this.getSingle('/accessToken', params);
  }

  logReportAudit(request: ReportAuditRequest): Observable<any> {
    return this.post('/audit', request);
  }

  setLastRun(year: number, quarter: number): void {
    this.lastRunYear = year;
    this.lastRunQuarter = quarter;
  }

  navigateToReport(parameters: TrackReportParameters): Promise<boolean> {
    const tier = this.hierarchyTierService.currentTier$.value?.abbreviation;
    const tierId = this.hierarchyTierService.currentTier$.value.selectedTierIdForDisplay;

    const params = [tier, tierId, 'track', 'report', parameters.reportId, parameters.menuItemId];

    const year = parameters.attributionYear == null ? this.lastRunYear : parameters.attributionYear;
    const quarter = parameters.attributionQuarter  == null ? this.lastRunQuarter : parameters.attributionQuarter;

    if (year != null && quarter != null) {
      params.push(...[year, quarter]);

      if (parameters.cinId != null) {
        params.push(...[CIN_REPORT_LEVEL, parameters.cinId]);
      } else if (parameters.regionId != null) {
        params.push(...[REGION_REPORT_LEVEL, parameters.regionId]);
      }

      this.setLastRun(year, quarter);
    }

    return this.router.navigate(params);
  }

  runReport(menuItem: TrackReportMenuItem): void {
    this.navigateToReport({
      attributionQuarter: null,
      attributionYear: null,
      cinId: null,
      cinName: null,
      menuItemId: menuItem.compassReportMenuID,
      phiFlag: null,
      regionId: null,
      reportId: menuItem.menuGUID,
      reportTierID: null,
      reportTierNum: null
    });
  }
}
