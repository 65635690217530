import { ComponentId } from '@shared/models/audit-constants';

export class ModuleConstants {
  public static readonly HomeModule = 1;
  public static readonly CareModule = 2;
  public static readonly TrackModule = 3;
  public static readonly ImproveModule = 4;
  public static readonly SubmitModule = 5;
  public static readonly LearnModule = 6;
}

export class CareFeatureConstants {
  public static readonly PatientsFeature = 1;
  public static readonly CareApiPhiFeature = 2;
  public static readonly CarePrescriptionFeature = 22;
  public static readonly CarePrescriptionExceptionsFeature = 22;
  public static readonly CarePrescriptionProcessingFeature = 22;
  public static readonly CarePrescriptionInvoicingFeature = 22;
  public static readonly CareExceptionsComponent = 28;
  public static readonly FeatureFlag340B = '340BFeatureFlag';
  public static readonly HiddenFeatureFlag340B = '340BHiddenFeatureFlag';
  public static readonly FileImport340B = 'FileImport340B';
  public static readonly AgGridCustomFilteringFlag = 'AgGridCustomFiltering';
  public static readonly PatientListsUpdateFlag = 'CC2909-PatientListsUpdate';
  public static readonly ClinicalProgramsListFlag = 'ClinicalProgramsListFlag';
  public static readonly CoachPatientReminderTab = 'CoachPatientReminderTab';
  public static readonly HCCVIPCohortList = 'HCCVIPCohortList';
  public static readonly Fax340bOutboundFeature = '340bFaxOutbound';
  public static readonly UIUpdates340B = '340BUIUpdates';
  public static readonly FacesheetUIUpdatesFlag = 'FacesheetUIUpdates';
  public static readonly ExceptionQueue340B = 'ExceptionQueue340B';
  public static readonly PACNavigatorProgram = 'PACNavigatorProgram';
  public static readonly PatientSideBar = 'PatientSideBar';
  public static readonly HCCListRevamp = 'HCCListRevamp';
  public static readonly IHECohort = 'IHECohort';
  public static readonly InHomeAWVScheduleList = 'InHomeAWVScheduleList';
  public static readonly SMSOutreachProgram = 'SMSOutreachProgram';
  public static readonly EHR = 'AWVDateAutoPopulated';
  public static readonly CombineSMSCohorts = 'CombineSMSCohorts';
  public static readonly CareLandingPageFlag ='CareLandingPage';
  public static readonly HideEHRColumnsInAwvOpportunities = 'hideEHRColumnsInAwvOpportunities';
  public static readonly SMSInviteFlag = 'SMSInvite';
  public static readonly FacesheetV28 = 'FacesheetV28';
}

export class CareComponentConstants {
  public static readonly Default = 0;
  public static readonly PatientList = 1;
  public static readonly AwvWorklist = 2;
  public static readonly EdUtilizationWorklist = 3;
  public static readonly PatientDiseaseCohorts = 4;
  public static readonly PatientCareGaps = 5;
  public static readonly PatientTimeline = 6;
  public static readonly Timeracking = 7;
  public static readonly HccFacesheetBySchedule = 8;
  public static readonly PACNavigatorProgram = 50;
  public static readonly IHECohort = 53;
  public static readonly IHESchedule = 54;
  public static readonly IHEBilling = 56;
  public static readonly CareManagement = 58;
  public static readonly AwvPatientsWorklist = 60;
  public static readonly PAC3DayWaiver = 64;
}

export class TrackComponentConstants {
  public static readonly TimeTrackingReport = 11;
  public static readonly ChronicConditions = 10;
  public static readonly AcoNetworkUtilization = 12;
  public static readonly ClinicalEpisodes = 13;
  public static readonly EndOfLife = 14;
  public static readonly KeyPerformanceIndicators = 15;
  public static readonly PostAcuteCare = 16;
  public static readonly PromotingInteroperabilitySubmission = 17;
  public static readonly QualityPortalSubmission = 18;
  public static readonly UnofficialCmsQuality = 19;
  public static readonly ValueCalculator = 38;
  public static readonly PerformanceDashboard = 52;
  public static readonly CompareComponent = 59;
  public static readonly QualityDashboardComponent = 63;

  public static readonly ValueCalculatorReportName = 'MSSP Value Report';
}

export class TrackFeatureConstants {
  public static readonly ClinicalFeature = 3;
  public static readonly FinancialUtilizationFeature = 4;
  public static readonly QualityFeature = 5;
  public static readonly SummaryFeature = 6;
  public static readonly MicroServiceApiFeature = 7;
  public static readonly ValueCalculatorFeature = 23;
  public static readonly PerformanceDashboardFeature = 26;
  public static readonly CompareFeature = 30;

  public static readonly CompareFeatureName = 'Compare';
  public static readonly QualityFeatureName = 'Quality + Interop';
  public static readonly ValueCalculatorName = 'MSSP Value';
  public static readonly ValueCalculatorFeatureFlag = 'MSSPValueReport';
  public static readonly ValueReportOverviewFeatureFlag = 'MSSPValueOverview';
  public static readonly FacesheetExportAsyncFeatureFlag =
    'FacesheetExportAsync';
  public static readonly PerformanceDashboardFeatureFlag =
    'TrackPerformanceDashboard';
  public static readonly PerformanceDashboardName = 'Performance Dashboard';
  public static readonly HccDashboardEnhancedFeatureFlag =
    'HccDashboardEnhanced';
  public static readonly CompareFeatureFlag = 'TrackCompare';
  public static readonly ComparePhase2FeatureFlag = 'TrackComparePhase2';
  public static readonly QualityDashboardFeatureFlag = 'QIDashboard';
  public static readonly HccDashboardEnhanced2FeatureFlag =
    'HccDashboardEnhanced2';
}

export class CareOrchestrationConstants {
  // Access Requirements
  private static readonly GridViewComponentId = 60;
  private static readonly SideBarSubComponentId = 12;
  private static readonly FacesheetViewComponentId = 61;
  private static readonly FacesheetTabSubComponentId = 13;
  public static readonly FeatureFlagId = 'CareOrchestration';
  public static readonly GridViewAccessRequirements = {
    featureFlagId: CareOrchestrationConstants.FeatureFlagId,
    componentIds: [CareOrchestrationConstants.GridViewComponentId],
  };
  public static readonly SideBarAccessRequirements = {
    featureFlagId: CareOrchestrationConstants.FeatureFlagId,
    componentIds: [
      CareOrchestrationConstants.GridViewComponentId
    ],
  };
  public static readonly FacesheetAccessRequirements = {
    featureFlagId: CareOrchestrationConstants.FeatureFlagId,
    componentIds: [
      CareOrchestrationConstants.FacesheetViewComponentId
    ],
  };

  public static readonly AwvProgramId = 1;
  public static readonly FetchAwvWorkflowHistoryPageSize = 100;
  public static readonly commentBoxCharacterLimit = 200;
  public static readonly providerFieldCharacterLimit = 100;
  public static readonly forbiddenErrorMessageBackend =
    'due a change in the rollback sequence being made by a non-user!';
  public static readonly forbiddenErrorMessageFrontend = `Cannot update patient where the latest update source was System(claims, in home AWV, or clinical (EHR))`;
  public static readonly conflictErrorMessageFrontend = `Cannot update patient because concurrent update attempted, please try again.`;
  public static readonly userDetailsErrorMessageFrontend = `Cannot retrieve user details from Access backend system`;
  public static readonly serverErrorMessageFrontend = `Please try to refresh the page. If the problem persists you can contact aco_support@signifyhealth.com`;
  public static readonly defaultErrorMessage = `Cannot update patient details`;
  public static readonly coachUser = 'Coach User';
  public static readonly workflowHistoryErrorMessageFrontend = `Cannot retrieve patient's history from the backend system`;
}

export class ImproveFeatureConstants {
  public static readonly SummaryFeature = 8;
  public static readonly ContactsFeature = 9;
  public static readonly ACOBoardFeature = 10;
  public static readonly SteeringCommiteeFeature = 11;
  public static readonly PracticeImprovementFeature = 12;
  public static readonly InformationTechnologyFeature = 13;
  public static readonly EventsFeature = 14;
}

// Modules Features Components Authorization Ids
export class SubmitFeatureConstants {
  public static readonly SummaryFormFeature = 21;
  public static readonly QualityFormFeature = 18;
  public static readonly MeetingFormFeature = 15;
  public static readonly ComplianceFormFeature = 19;
  public static readonly PromotingInteroperabilityFormFeature = 20;
  public static readonly FormsAdminFormFeature = 24;
  public static readonly FormsAdminFormFeatureFlag = 'FormAdminReport';
}

// Form Ids in DB.
export class SubmitFormConstants {
  public static PromotingInteroperabilityFormComponent = 1;
  public static QualityFormComponent = 2;
  public static ComplianceFormComponent = 3;
  public static CommunicationFormComponent = 4;
  public static MeetingFormComponent = 5;
}

// Constants for Audit Logging.
export class SubmitComponentConstants {
  public static QualityFormComponent = 27;
  public static ComplianceFormComponent = 28;
  public static PromotingInteroperabilityFormComponent = 29;
  public static MeetingFormComponent = 30;
  public static SubmitSummaryFormComponent = 35;
}

export class SubmitFeatureToFormId {
  public static map = new Map<number, number>([
    [
      SubmitFeatureConstants.PromotingInteroperabilityFormFeature,
      SubmitFormConstants.PromotingInteroperabilityFormComponent,
    ],
    [
      SubmitFeatureConstants.QualityFormFeature,
      SubmitFormConstants.QualityFormComponent,
    ],
    [
      SubmitFeatureConstants.ComplianceFormFeature,
      SubmitFormConstants.ComplianceFormComponent,
    ],
    [
      SubmitFeatureConstants.SummaryFormFeature,
      SubmitFormConstants.CommunicationFormComponent,
    ],
    [
      SubmitFeatureConstants.MeetingFormFeature,
      SubmitFormConstants.MeetingFormComponent,
    ],
  ]);
}

export class LearnFeatureConstants {
  public static readonly LearnFeature = 16;
}

export class HomeFeatureConstants {
  public static readonly ApplicationFeature = 17;
}

export class HomeComponentConstants {
  public static readonly MarketingWidget = 45;
  public static readonly MarketingWidget2 = 46;
}

export class DocRepoConstants {
  public static readonly YoutubeEntryType = 'youtube';
  public static readonly TextPlainEntryType = 'text/plain';
  public static readonly TextHtmlEntryType = 'text/html';
  public static readonly PageEntryType = 'Page';
  public static readonly URLEntryType = 'URL';
}

export class RecentsConstants {
  public static readonly RecentsFeatureFlag = 'Recents';
}

export class ImproveFeatureNames {
  public static readonly Contacts = 'Contacts';
  public static readonly ACOBoard = 'ACO Board';
  public static readonly SteeringCommittee = 'Steering Committee';
  public static readonly PracticeImprovement = 'Practice Improvement';
  public static readonly InformationTechnology = 'Information Technology';
  public static readonly Events = 'Events';
}

export class ImproveFeatureNamesToAuditComponentId {
  public static map = new Map<string, number>([
    [ImproveFeatureNames.Contacts, ComponentId.Contacts],
    [ImproveFeatureNames.ACOBoard, ComponentId.ACOBoard],
    [ImproveFeatureNames.SteeringCommittee, ComponentId.SteeringCommitee],
    [ImproveFeatureNames.PracticeImprovement, ComponentId.PracticeImprovement],
    [
      ImproveFeatureNames.InformationTechnology,
      ComponentId.InformationTechnology,
    ],
    [ImproveFeatureNames.Events, ComponentId.Events],
  ]);
}
export class ImproveFeatureNamesAuditComponentIdMap {
  public static map = {
    ImproveFeatureNames,
  };
}

export class SubmitFeatureNames {
  public static readonly Summary = 'Summary';
  public static readonly Quality = 'Quality';
  public static readonly PI = 'Promoting Interoperability';
  public static readonly Compliance = 'Compliance';
  public static readonly Meeting = 'Meeting Uploads';
  public static readonly FormAdministration = 'Forms Administration';
}

export class PatientSnfStatusItemAllowedTypes {
  public static readonly Names = [
    'ServiceDelay',
    'ClinicianDelay',
    'PatientDecompensation',
    'PatientDelay',
    'DischargeIssue',
    'InpatientTransfer',
    'Other',
    'None',
  ];
  public static readonly Definitions = {
    ServiceDelay:
      'Services or DME did not show up as planned leading to missed services',
    ClinicianDelay:
      'Delay in getting discharge order and/or services and supply orders',
    PatientDecompensation:
      'Patient develops worsening symptoms and is unable to participate',
    PatientDelay: 'Patient refusing treatment and/or patient family issues',
    DischargeIssue:
      'Transportation and/or services not arranged in timely manner',
    InpatientTransfer: 'Admitted to IP during SNF stay',
    Other: 'Issues not reflected in other options',
    None: 'No issues',
  };
}

export class PatientIdentifiersIssuers {
  public static readonly SmsCarePlanPhoneNumber =
    'https://identity.caravanhealth.com/smscareplanphonenumber';
}

export enum ClinicalProgram {
  Hypertension = 'Hypertension',
  COPD = 'COPD',
  HeartFailure = 'Heart Failure',
  AllProgram = 'AllProgram',
}

export enum CarePatientItem {
  AllPatients = 'AllPatients',
  FacesheetSchedule = 'FacesheetSchedule',
  AwvOpportunities = 'AwvOpportunities',
  AwvOpportunitiesReadyToSchedule = 'AwvOpportunities_ReadyToSchedule',
  AwvOpportunitiesReadyToRemind = 'AwvOpportunities_ReadyToRemind',
  AwvOpportunitiesReadyToSelfReport = 'AwvOpportunities_ReadyToSelfReport',
  EdUtilization = 'EdUtilization',
  CareManagementAllProgram = 'CareManagementAllProgram',
  CareManagementCopd = 'CareManagementCopd',
  CareManagementHeartFailure = 'CareManagementHeartFailure',
  CareManagementHypertension = 'CareManagementHypertension',
  HccTop50 = 'Top50',
  HccVipCohort = 'HCCVipCohort',
  ClinicalProgramHeartFailure = 'ClinicalProgramHeartFailure',
  ClinicalProgramCOPD = 'ClinicalProgramCOPD',
  ClinicalProgramHypertension = 'ClinicalProgramHypertension',
  AwvSchedule = 'AwvSchedule',
  AwvFiles = 'AwvFiles',
  AwvBilling = 'AwvBilling',
  PacNavigatorProgram = 'PacNavigatorProgram',
  SmsOutreachProgram = 'SmsOutreachProgram',
  CopdSmsAlert = 'CopdSmsAlert',
  Chf = 'Chf',
  ChronicConditionCOPD = 'ChronicConditionCOPD',
  Diabetes = 'Diabetes',
  CustomPatientList = 'CustomPatientList',
  UnreadMessages = 'Unread messages',
}

export enum PresetCategory {
  Alerts = 'Alerts',
  AnnualWellnessVisits = 'Annual Wellness Visits',
  CareManagement = 'CareManagement',
  ChronicConditions = 'Chronic Conditions',
  ClinicalProgram = 'ClinicalProgram',
  Cohorts = 'Cohorts',
  CustomList = 'Custom list',
  Facesheet = 'Facesheet',
  General = 'General',
  Messages = 'Messages',
  AlertsAndMessages = 'Alerts + Messages',
  PostAcuteCare = 'Post Acute Care',
  Worklists = 'Worklists',
}

export const ClinicalProgramPresetMap = new Map<string, CarePatientItem>([
  ['Heart Failure', CarePatientItem.CareManagementHeartFailure],
  ['COPD', CarePatientItem.CareManagementCopd],
  ['Hypertension', CarePatientItem.CareManagementHypertension],
]);

// If true use Graphql replacement
export const FeatureFlagAccess2 = 'Access2';
