import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackQuarter, TrackQuarterYear, TrackYear } from '@track/track-reports/track-quarter-year';
import { TrackReportConfig } from '@track/track-reports/track-report-config';

@Component({
  selector: 'coach-track-time-filters',
  templateUrl: './track-time-filters.component.html',
  styleUrls: ['./track-time-filters.component.scss']
})
export class TrackTimeFiltersComponent implements OnInit {

  private _quarterYears: TrackQuarterYear[];
  @Input()
  set quarterYears(value: TrackQuarterYear[]) {
    this._quarterYears = value;
    if (this._quarterYears == null) {
      this.years = [];
      this.quarters = [];
      this._quarterYears = [];
    } else {
      this.mapQuarterYears(this._quarterYears);
      this.setYear(this._selectedYear);
      this.setQuarter(this._selectedQuarter);
    }
  }

  _selectedYear: number;
  @Input()
  set selectedYear(value: number) {
    this._selectedYear = value;
    this.setYear(value);
  }

  @Output()
  selectedYearChange: EventEmitter<number> = new EventEmitter<number>();

  _selectedQuarter: number;
  @Input()
  set selectedQuarter(value: number) {
    this._selectedQuarter = value;
    this.setQuarter(value);
  }

  @Input() reportConfig: TrackReportConfig;

  @Output()
  selectedQuarterChange: EventEmitter<number> = new EventEmitter<number>();

  public selectedQuarterItem: TrackQuarter;
  @Output()
  selectedQuarterItemChange: EventEmitter<TrackQuarter> = new EventEmitter<TrackQuarter>();

  public selectedYearItem: TrackYear;
  @Output()
  selectedYearItemChange: EventEmitter<TrackYear> = new EventEmitter<TrackYear>();

  public years: TrackYear[] = [];
  public quarters: TrackQuarter[] = [];

  public hideQuarter = false;
  public quarterLabel = 'Quarter';

  constructor() { }

  ngOnInit(): void {
  }

  setYear(value: number): void {
    this.selectedYearItem = this.years.find(x => x.year === value);

    if (this.selectedYearItem == null && this.years != null && this.years.length > 0) {
      this.selectedYearItem = this.years[this.years.length - 1];
    }

    this.updateQuarters(value);

    if (value != null) {
      this.selectedYearItemChange.emit(this.selectedYearItem);
      this.selectedQuarterItemChange.emit(this.selectedQuarterItem);
    }
  }

  setQuarter(value: number): void {
    this.selectedQuarterItem = this.quarters.find(x => x.quarter === value);
    this.selectedQuarterItemChange.emit(this.selectedQuarterItem);
  }

  yearSelected(value: TrackYear): void {
    this.selectedYearChange.emit(value.year);
    this.selectedYearItemChange.emit(value);

    this.updateQuarters(value.year);

    this.selectedQuarterChange.emit(this.selectedQuarterItem?.quarter);
    this.selectedQuarterItemChange.emit(this.selectedQuarterItem);
  }

  updateQuarters(year: number): void {
    this.quarters = this._quarterYears?.filter(x => x.attributionYear === year)
      .map(x => ({ display: x.attributionQuarter_DisplayText, quarter: x.attributionQuarter }));

    if (this.quarters != null && this.quarters.length > 0) {
      this.selectedQuarterItem = this.quarters[this.quarters.length - 1];
    } else {
      this.selectedQuarterItem = null;
    }
  }

  quarterSelected(value: TrackQuarter): void {
    this.selectedQuarterChange.emit(value.quarter);
    this.selectedQuarterItemChange.emit(value);
  }

  mapQuarterYears(quarterYears: TrackQuarterYear[]): void {
    const years: TrackYear[] = [];
    const map = new Map();

    for (const item of quarterYears) {
      if (!map.has(item.attributionYear)) {
        map.set(item.attributionYear, true);
        years.push({
          display: item.attributionYear.toString(),
          year: item.attributionYear
        });
      }
    }

    this.years = years;
  }

}
