import {Component, Input} from '@angular/core';
import {Patient} from '@shared/models/patient';
import {AwvWorkflowStatuses} from '../../../../../api/care-orch/models/awv-workflow-statuses';

@Component({
  selector: 'coach-patient-awv-banner',
  templateUrl: './patient-awv-banner.component.html',
  styleUrls: ['./patient-awv-banner.component.scss']
})
export class PatientAwvBannerComponent {
  @Input() public mobileScreenSize: boolean;
  @Input() public patient: Patient;
  @Input() sidebarMode?: boolean;
  @Input() public isTabOpen: boolean;
  @Input() public awvWorkflowStatus: AwvWorkflowStatuses;
  @Input() public awvWorkflowStatusToString: string;
  @Input() public minimalView: boolean = false;
  @Input() public isLoading: boolean;

  constructor() {}

  public convertAwvStatusToCamelCase(awvWorkflowStatus: AwvWorkflowStatuses): string {
    switch (awvWorkflowStatus) {
      case AwvWorkflowStatuses.ReadyForOutreach: {
        return 'Ready for outreach';
      }
      case AwvWorkflowStatuses.OutreachAttempted: {
        return 'Outreach attempted';
      }
      case AwvWorkflowStatuses.Scheduled: {
        return 'Scheduled';
      }
      case AwvWorkflowStatuses.Completed: {
        return 'Completed';
      }
      case AwvWorkflowStatuses.Declined: {
        return 'Patient declined';
      }
      case AwvWorkflowStatuses.Ineligible: {
        return 'Ineligible';
      }
      case AwvWorkflowStatuses.DeferredToSignify: {
        return 'Deferred';
      }
      case AwvWorkflowStatuses.Other:
      default: {
        return 'Inactive';
      }
    }
  }
}
