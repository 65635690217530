<button
  class="pullTab top--xs disable-rotate"
  (click)="togglePatientFilters()"
  [ngClass]="{ 'is-open': !isPatientFiltersOpen }"
  aria-label="Toggle filter panel"
>
  <coach-icon iconName="chevrons-right"></coach-icon>
</button>
<div *ngIf="UIUpdates340BFeatureFlag"
  class="u-display--flex u-flex-direction--column u-justify-content--spaceBetween padding">
  <div class="list scroll-list-y">
    <div class="accordion list-item" [class.is-open]="activeDropdownPointer == 'month'">
      <div class="row u-justify-content--spaceBetween">
        <div class="field header border--none cursor-default u-flex-direction--column row-title">
          {{customDateFilterLabel}}
          <div class="selection-list">
            <small class="selection-element u-color--highlight">{{ selectedDateLabel }}</small>
          </div>
        </div>
        <coach-icon iconName="x-circle" *ngIf="selectedDateLabel != 'All'" class="padding-top--large" (click)="checkAllOptions()"
          iconColor="currentColor">
        </coach-icon>
        <coach-icon iconName={{getChevronStatus()}} class="padding-top--large" (click)="openAccordion('month')" iconColor="currentColor">
        </coach-icon>
      </div>
      <div class="content content-planRow shadowLess">
        <div>
          <select id="monthSelector" name="monthSelector" (change)="onDateSelected($event)" [(ngModel)]="selectedDateModel" #datePicker>
            <option *ngFor="let date of dateOptions" [selected]="date == selectedDateModel">{{ date }}</option>
          </select>
        </div>
      </div>
    </div> 
    <coach-filter-group *ngFor="let options of configData; let idx = index"
      [groupOptions]="options"
      (optionChecked)="onOptionChecked($event)"
      (radioChecked)="onRadioChecked($event)"
      [isDateSelected]="isInitialDateSelected"
      [UIUpdates340BFeatureFlag]="true"
      (activeDropdownPointerSelected)="openAccordion($event)"
      [idx]="idx"
      [activeDropdownPointer]="activeDropdownPointer"
    ></coach-filter-group>
  </div>
</div>
<div class="header" *ngIf="!UIUpdates340BFeatureFlag">
  <div class="titles">
    <div class="title">Filters</div>
  </div>
</div>

<div style="overflow: auto" *ngIf="!UIUpdates340BFeatureFlag">
  <label
    for="monthSelect"
    style="font-size: smaller; color: gray; margin-left: 16px"
    >Month</label
  >
  <select
    id="monthSelect"
    style="border: 1px solid gray; margin: 4px 16px; width: 268px"
    (change)="onDateSelected($event)"
    #datePicker
  >
  </select>

  <div *ngFor="let options of configData">
    <coach-filter-group
      [groupOptions]="options"
      (optionChecked)="onOptionChecked($event)"
    ></coach-filter-group>
  </div>
</div>

