import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Process } from '@shared/services/processing/process';
import { ProcessingService } from '@shared/services/processing/processing.service';

@Component({
  selector: 'coach-header-process-progress',
  templateUrl: './header-process-progress.component.html',
  styleUrls: ['./header-process-progress.component.scss']
})
export class HeaderProcessProgressComponent implements OnInit {

  private customComponent: ViewContainerRef;

  @Input() process: Process<unknown>;

  @ViewChild('completed', { read: ViewContainerRef })
  set content(content: ViewContainerRef) {
    if (content != null) {
      this.customComponent = content;

      // Creating a microtask to delay creation of new component until after next execution hook to avoid ExpressionChangedAfterItHasBeenCheckedError
      // https://indepth.dev/posts/1001/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error
      Promise.resolve(null).then(() => this.setCustomComponent());
    }
  }

  constructor(private processingService: ProcessingService) { }

  ngOnInit(): void {
  }

  setCustomComponent(): void {
    if (this.customComponent != null && this.process != null) {
      this.customComponent.clear();
      const component = this.customComponent.createComponent(this.process.componentFactory);
      component.instance['process'] = this.process;
    }
  }

  remove(): void {
    this.processingService.removeProcess(this.process);
  }

}
