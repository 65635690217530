import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'coach-patient-awv-remove-modal-dialog',
  templateUrl: './patient-awv-remove-modal-dialog.component.html',
  styleUrls: ['./patient-awv-remove-modal-dialog.component.scss']
})
export class PatientAwvRemoveModalDialogComponent {
  @Input()
  dialogMessage = 'This will remove the saved activity and revert the patient to the previous status';

  @Input()
  dialogHeader = 'Remove Saved Activity';

  @Input()
  showDialog = false;

  @Output() buttonClicked = new EventEmitter<string>();

  onButtonClicked(buttonValue: string): void {
    this.buttonClicked.emit(buttonValue);
  }
}
