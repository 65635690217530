import { Component, OnInit, LOCALE_ID, Inject, HostListener, OnDestroy } from '@angular/core';
import { HccPatientSummaryService } from './hcc-patient-summary.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { formatDate, formatNumber } from '@angular/common';

import { HccDashboardPatient } from './hcc-dashboard-patient';

import { GridFilterService } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { PATIENT_SUMMARY_GRID_KEY, PATIENT_DETAIL_REPORT_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { environment } from 'src/environments/environment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { Subject } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';

@Component({
  selector: 'coach-patient-summary-report',
  templateUrl: './patient-summary-report.component.html',
  host: {
    class: 'coach-patient-summary-report'
  },
  providers: [MaskPipe]
})
export class PatientSummaryReportComponent extends PersistentGridFilters implements OnInit, OnDestroy {
  columnDefs = [
    {
      headerName: 'MBI', field: 'current_MBI', sortable: true, filter: true, resizable: true, valueFormatter: params => {
        const patient: HccDashboardPatient = params.data;

        if (patient == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(patient.current_MBI, 6);
        }
        else {
          return patient.current_MBI;
        }
      }, keyCreator: params => {
        if (environment.maskSensitiveData) {
          return this.mask.transform(params.value, 6);
        }
        else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Name', field: 'fullName', sortable: true, filter: true, resizable: true,
      cellRendererFramework: RouterLinkRendererComponent,
      cellRendererParams: {
        linkClicked: (data: HccDashboardPatient) => {
          const path = '../../../care/patient';

          this.router.navigate([path, data.chPatID], { relativeTo: this.route.parent });
        }
      }
    },
    {
      headerName: 'Date of Birth', field: 'dob', sortable: true, filter: true, resizable: true,
      valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale)
    },
    { headerName: 'Clinician', field: 'providerName', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Clinician NPI', field: 'tierID', sortable: true, filter: !environment.maskSensitiveData, resizable: true,
      valueFormatter: params => {
        const patient: HccDashboardPatient = params.data;
        let value = '';

        if (patient == null) {
          value = params.value;
        } else if (patient.hasProvider) {
          value = patient.tierID;
        }

        if (environment.maskSensitiveData) {
          value = this.mask.transform(value, 6);
        }
        return value;
      }
    },
    { headerName: 'Clinician Specialty', field: 'provSplty', sortable: true, filter: true, resizable: true },
    { headerName: 'RAF Score (YTD)', field: 'raF_YTD', sortable: true, filter: true, resizable: true },
    { headerName: 'Total Gap Weight', field: 'openRAFWt', sortable: true, filter: true, resizable: true, headerTooltip: 'Total Gap Weight' },
    { headerName: 'Chronic Conditions Closure Gaps', field: 'gapCount', sortable: true, filter: true, resizable: true, headerTooltip: 'Chronic Conditions Closure Gaps' },
    {
      headerName: 'Overall Chronic Conditions Closure Rate', field: 'overallHCCClosureRate', sortable: true, filter: true, resizable: true, headerTooltip: 'Overall HCC Closure Rate',
      valueFormatter: (params: any) => {
        const patient: HccDashboardPatient = params.data;
        return patient == null ? null : Math.round(patient.overallHCCClosureRate) + '%';
      }
    },
    { headerName: 'Attribution Status', field: 'attributionStatus', sortable: true, filter: true, resizable: true },
    { headerName: 'Last AWV Date', field: 'lastAWVDate', sortable: true, filter: true, resizable: true, valueFormatter: params => params.value ? formatDate(params.value, 'MM/dd/yyyy', this.locale) : '' },
    { headerName: 'Last AWV Provider', field: 'lastAWVProvider', sortable: true, filter: true, resizable: true },
  ];

  rowData: any;

  public clinician: string;
  public isPracticeFilter: boolean;

  private tierNum: number;
  private tierId: string;

  public hccEnhanced2FeatureFlag = TrackFeatureConstants.HccDashboardEnhanced2FeatureFlag;

  constructor(private patientSummaryService: HccPatientSummaryService, private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string, private router: Router, private mask: MaskPipe, private featureFlagService: FeatureFlagService,
    protected filterService: GridFilterService, private hierarchyTierService: HierarchyTierService) {
    super(filterService, PATIENT_SUMMARY_GRID_KEY);
  }

  ngOnInit(): void {
    const hasFeature = this.featureFlagService.hasFeatureFlag(this.hccEnhanced2FeatureFlag);

    if (!hasFeature) {
      this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger), share()).subscribe(tier => {
        if (tier != null) {
          this.tierNum = tier.tier;
          this.tierId = tier.selectedTierId;
          this.clinician = this.route.snapshot.params.clinician;
          this.isPracticeFilter = this.route.snapshot.queryParams.isPractice;

          this.rowData = this.patientSummaryService.getPatients(this.tierNum, this.tierId, tier.selectedItem.tier1_ID);
        }
      });
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const sort = [
      {
        colId: 'hccGapCount',
        sort: 'desc'
      },
      {
        colId: 'fullName',
        sort: 'asc'
      },
      {
        colId: 'dob',
        sort: 'asc'
      }
    ];
    this.gridApi.setSortModel(sort);
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    } else if (this.clinician != null) {
      const filterInstance = this.isPracticeFilter ? this.gridApi.getFilterInstance('providerName') : this.gridApi.getFilterInstance('tierID');

      filterInstance.setModel({
        type: 'set',
        values: [this.clinician]
      });

      this.gridApi.onFilterChanged();
    }

    params.api.sizeColumnsToFit();
  }

  details(): void {
    const params = ['patientDetails'];
    if (this.clinician != null) {
      params.push(this.clinician);
    }

    let queryParams: Params;
    if (this.isPracticeFilter != null) {
      queryParams = { isPractice: true };
    }

    this.filterService.resetFilters(PATIENT_DETAIL_REPORT_GRID_KEY);
    this.router.navigate(params, { relativeTo: this.route.parent, queryParams: queryParams });
  }

  ngOnDestroy(): void {
    this.filterService.resetFilters(PATIENT_SUMMARY_GRID_KEY);
  }
}
