/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { QuarterYear } from '../models/quarter-year';
import { ReportAuditRequest } from '../models/report-audit-request';
import { ReportDefinition } from '../models/report-definition';
import { ReportFilterType } from '../models/report-filter-type';
import { ReportMenuItem } from '../models/report-menu-item';
import { TrackReportInfo } from '../models/track-report-info';

@Injectable({
  providedIn: 'root',
})
export class ReportItemsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reportAuditPost
   */
  static readonly ReportAuditPostPath = '/report/audit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportAuditPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportAuditPost$Response(params?: {
    body?: ReportAuditRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportItemsService.ReportAuditPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportAuditPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportAuditPost(params?: {
    body?: ReportAuditRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.reportAuditPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reportMenuItemsGet
   */
  static readonly ReportMenuItemsGetPath = '/report/menuItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportMenuItemsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportMenuItemsGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReportMenuItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportItemsService.ReportMenuItemsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReportMenuItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportMenuItemsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportMenuItemsGet(params?: {
  },
  context?: HttpContext

): Observable<Array<ReportMenuItem>> {

    return this.reportMenuItemsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReportMenuItem>>) => r.body as Array<ReportMenuItem>)
    );
  }

  /**
   * Path part for operation reportDefinitionGet
   */
  static readonly ReportDefinitionGetPath = '/report/definition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportDefinitionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportDefinitionGet$Response(params?: {
    id?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ReportDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ReportItemsService.ReportDefinitionGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportDefinition>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportDefinitionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportDefinitionGet(params?: {
    id?: number;
  },
  context?: HttpContext

): Observable<ReportDefinition> {

    return this.reportDefinitionGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ReportDefinition>) => r.body as ReportDefinition)
    );
  }

  /**
   * Path part for operation reportQuarterYearsGet
   */
  static readonly ReportQuarterYearsGetPath = '/report/quarterYears';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportQuarterYearsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportQuarterYearsGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
    menuId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<QuarterYear>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportItemsService.ReportQuarterYearsGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('menuId', params.menuId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuarterYear>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportQuarterYearsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportQuarterYearsGet(params?: {
    tierNum?: number;
    tierId?: string;
    menuId?: number;
  },
  context?: HttpContext

): Observable<Array<QuarterYear>> {

    return this.reportQuarterYearsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<QuarterYear>>) => r.body as Array<QuarterYear>)
    );
  }

  /**
   * Path part for operation reportFilterTypesGet
   */
  static readonly ReportFilterTypesGetPath = '/report/filterTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportFilterTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFilterTypesGet$Response(params?: {
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReportFilterType>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportItemsService.ReportFilterTypesGetPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReportFilterType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportFilterTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFilterTypesGet(params?: {
    type?: string;
  },
  context?: HttpContext

): Observable<Array<ReportFilterType>> {

    return this.reportFilterTypesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReportFilterType>>) => r.body as Array<ReportFilterType>)
    );
  }

  /**
   * Path part for operation reportInfoGet
   */
  static readonly ReportInfoGetPath = '/report/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportInfoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportInfoGet$Response(params?: {
    reportId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TrackReportInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ReportItemsService.ReportInfoGetPath, 'get');
    if (params) {
      rb.query('reportId', params.reportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrackReportInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportInfoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportInfoGet(params?: {
    reportId?: string;
  },
  context?: HttpContext

): Observable<TrackReportInfo> {

    return this.reportInfoGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TrackReportInfo>) => r.body as TrackReportInfo)
    );
  }

}
