export class TrackPerformanceDashboardTargetStatus {
    public static readonly NeedsAttention = 'needsAttention';
    public static readonly NeedsImprovement = 'needsImprovement';
    public static readonly HittingTarget = 'hittingTarget';
    public static readonly Connected = 'connected';
    public static readonly NotConnected = 'notConnected';
}

export class TrackPerformanceDashboardMetrics {
    public static readonly RafScoreMetricId = 3;
}