import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { Access, AccessGQL } from '../graphql/access.service';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { Data } from '@angular/router';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Subject } from 'rxjs';
import { Access2Service } from '@api/access2/services';
import { Access as Access2Type } from '@api/access2/models';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { FeatureFlagAccess2 } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class UserAccessService {
  public currentAccess$ = new BehaviorSubject<Access>(null);
  public accessHistory$ = new ReplaySubject<Access>(1);

  constructor(private accessGQL: AccessGQL,
              private access2Service: Access2Service,
              private featureFlagService: FeatureFlagService,
              private toastrService: ToastrMessageService) {
  }

  public updateAccess(currentTier: IHierarchyTier): Observable<Access> {
    const observable: Subject<Access> = new Subject<Access>();
    if (this.featureFlagService.hasFeatureFlag(FeatureFlagAccess2)) {
      this.access2Service.access2({ tierid: currentTier.selectedTierId }).subscribe(
        (result:Access2Type) => {
          const access = result as Access;
          this.currentAccess$.next(access);
          this.accessHistory$.next(access);
          observable.next(access);
          observable.complete();
         },
        error => {
          this.currentAccess$.next(null);
          this.accessHistory$.next(null);
          this.toastrService.error(error);
          observable.next(error);
          observable.complete();
        }
      );
    }
    else {
      this.accessGQL.watch({ nodeId: currentTier.selectedTierId }, { fetchPolicy: 'cache-first' })
      .valueChanges.subscribe(result => {
        this.currentAccess$.next(result.data?.access);
        this.accessHistory$.next(result.data?.access);
        observable.next(result.data?.access);
        observable.complete();
      }, error => {
        this.currentAccess$.next(null);
        this.accessHistory$.next(null);
        this.toastrService.error(error);
        observable.next(error);
        observable.complete();
      });

    }

    return observable.asObservable();
  }

  public hasFeature(access: Access, featureId: number): boolean {
    return access?.modules?.some(m => m.features?.some(f => f.featureId === featureId));
  }

  public hasModule(access: Access, moduleId: number): boolean {
    return access?.modules?.some(m => m.moduleId === moduleId);
  }

  /** Returns whether access structure grants access  to any of the component ids provided
   * @param access - instance of the Access interface returned from the access endpoint.
   * @param componentIds - either a single component id or an array of them
   * @returns - true if any of the passed in componentIds matches one in the access structure
   */
  public hasComponent(access: Access, componentIds: number | Array<number>): boolean {
    // REVIEW Is this a real edge case?
    if (componentIds === 0) {
      return true;
    }

    const arr = Array.isArray(componentIds) ? componentIds : [componentIds];
    return access?.modules?.some(m =>
      m.features?.some(f =>
        f.components?.some(c =>
          arr.includes(c.componentId)
        )
      )
    );
  }

  public accessRestricted(routeData: Data, access: Access): boolean {
    let hasAccess = true;
    if (routeData.moduleId != null) {
        hasAccess = hasAccess && this.hasModule(access, routeData.moduleId);
    }
    if (routeData.featureId != null) {
        hasAccess = hasAccess && this.hasFeature(access, routeData.featureId);
    }
    if (routeData.componentId != null) {
        hasAccess = hasAccess && this.hasComponent(access, routeData.componentId);
    }

    return hasAccess;
}
}

