<div class="section u-background-color--white" *ngIf="patient && (acuteOpportunities.length || diseaseInteractions.length)">
    <!-- HEADER -->
    <div class="header padding-bottom--smaller">
        <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--large">
            <div class="titles t4">
                <b class="title"> Acute Episodes</b>
            </div>
        </div>
    </div>

    <!-- REGULAR EVENT ROW -->
    <ng-container *ngFor="let opportunity of acuteOpportunities; let idx = index">
        <div class="row--wrap padding--small padding-left--largest">
            <div class="span--12 u-display--flex u-flex-direction--column">
                <div class="u-bold">{{opportunity.hccDescription}}</div>
                <!-- hccCodes -->
                <div class="u-display--flex u-justify-content--spaceBetween" *ngFor="let hccCode of opportunity.hccCodes">
                    <div class="u-italic u-italic u-color--highlight">{{hccCode.code}}: {{hccCode.name}}</div>
                    <div class="u-thin u-text--nowrap">{{opportunity.providerName | titlecase}}, {{opportunity.dateOfService | date:'MM/dd/yyyy'}}</div>
                </div>
            </div>
        </div>
    </ng-container>

</div>
