<div class="u-background-color--white" [formGroup]="interopForm">
  <div class="submitFormBlock-header">
    <div class="row">
      <h6 class="margin--none u-fill u-opacity--light">
        Max: 25 Points
      </h6>
      <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small" iconColor="primary"
        (click)="isInfoTextOpen = true"></coach-icon>
    </div>
    <div class="u-bold margin-top--smallest">Registry Reporting</div>
  </div>
  <div class="u-italic u-opacity--light"> Report the two required registries.</div>
  <div class="submitFormBlock-main row margin-top">
    <table class="span--12">
      <thead>
        <tr class="borders-right--none">
          <th>Registries</th>
          <th>1st</th>
          <th>2nd</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Request Exclusion</td>
          <td>
            <div>
              <input type="radio" id="rr1-1" formControlName="RegReportOne" value="exclusion" class="is-standalone">
              <label for="rr1-1"></label>
            </div>
          </td>
          <td>
            <div>
              <input type="radio" id="rr1-2" formControlName="RegReportTwo" value="exclusion" class="is-standalone">
              <label for="rr1-2"></label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Immunization Registry</td>
          <td>
            <div>
              <input type="radio" id="rr2-1" formControlName="RegReportOne" value="ImmunizationRegistryReporting"
                class="is-standalone">
              <label for="rr2-1"></label>
            </div>
          </td>
          <td>
            <div>
              <input type="radio" id="rr2-2" formControlName="RegReportTwo" value="ImmunizationRegistryReporting"
                class="is-standalone">
              <label for="rr2-2"></label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Electronic Case</td>
          <td>
            <div>
              <input type="radio" id="rr6-1" formControlName="RegReportOne" value="ElectronicCaseReporting"
                class="is-standalone">
              <label for="rr6-1"></label>
            </div>
          </td>
          <td>
            <div>
              <input type="radio" id="rr6-2" formControlName="RegReportTwo" value="ElectronicCaseReporting"
                class="is-standalone">
              <label for="rr6-2"></label>
            </div>
          </td>
        </tr>
        <tr>
          <td>None</td>
          <td>
            <div>
              <input type="radio" id="rr7-1" formControlName="RegReportOne" value="none" class="is-standalone">
              <label for="rr7-1"></label>
            </div>
          </td>
          <td>
            <div>
              <input type="radio" id="rr7-2" formControlName="RegReportTwo" value="none" class="is-standalone">
              <label for="rr7-2"></label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>

<div class="overlay overlay--light is-open" *ngIf="isInfoTextOpen">
  <div class="modal margin u-max-width--larger">
    <div class="dynamicBody padding--large">
      <ul>
        <li type="disc">If you claim an exclusion and report on one registry, you will get a full 10
          points.</li>
        <li type="disc">If you select two exclusions, the 10 points will be re-weighted to Provide
          Patients to their Health Information measure making it worth 50 points.</li>
        <li type="disc">If you cannot report on two registries or claim exclusions the entire
          Promoting Interoperability category will be scored as a zero per CMS.</li>
      </ul>

      <p>Registry Reporting Links to Each Registry Specification</p>

      <div class="margin-top margin-bottom">
        <div class="text-center">
          <a [routerLink]="['../../improve/practice-improvement']" [queryParams]="{folder: '1166275', file: '1166311'}"
            target="_blank">Immunization Registry Reporting</a>
        </div>
        <div class="text-center">
          <a [routerLink]="['../../improve/practice-improvement']" [queryParams]="{folder: '1166275', file: '1166296'}"
            target="_blank">Electronic Case Reporting</a>
        </div>
        <div class="text-center">
          <a [routerLink]="['../../improve/practice-improvement']" [queryParams]="{folder: '1061850', file: '1061874'}"
            target="_blank">Link to Scoring Methodology</a>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="button is-primary" (click)="isInfoTextOpen = false">Close</button>
    </div>
  </div>
</div>
