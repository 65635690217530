import { Component, Input, SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';


const invalidClasses = ['ng-invalid', 'ng-touched'];

@Component({
  selector: 'coach-invalid-input',
  templateUrl: './invalid-input.component.html',
  styleUrls: []
})
export class InvalidInputComponent implements OnChanges {
  @Input() public input: HTMLInputElement;
  @Input() public select: HTMLSelectElement;

  public observer: MutationObserver;
  public requiredError = false;
  public minError = false;
  public maxError = false;
  public hasBeenTouched = false;

  ngOnChanges(_: SimpleChanges): void {
    if (this.input) {
      const validationFn = () => this.validateInput();
      this.observer = new MutationObserver(validationFn);
      this.observer.observe(this.input, { attributeOldValue: true, attributeFilter: ['required', 'min', 'max'] });
      this.input.addEventListener('focusin', () => this.hasBeenTouched = true);
      this.input.addEventListener('focusout', validationFn);
      this.input.addEventListener('input', validationFn);
    }

    if (this.select) {
      const validationFn = () => this.validateSelect();
      this.observer = new MutationObserver(validationFn);
      this.observer.observe(this.select, { attributeOldValue: true, attributeFilter: ['required'] });
      this.select.addEventListener('focusin', () => this.hasBeenTouched = true);
      this.select.addEventListener('focusout', validationFn);
      this.select.addEventListener('change', validationFn);
    }
  }

  private validateInput(): void {
    if (!this.hasBeenTouched) {
      return;
    }

    const value = this.input.value;
    const min = this.input.min;
    const max = this.input.max;
    const required = this.input.required;

    this.resetErrors(this.input);

    if (value) {
      if (min && value < min) {
        this.minError = true;
        this.input.classList.add(...invalidClasses);
      }

      if (max && value > max) {
        this.maxError = true;
        this.input.classList.add(...invalidClasses);
      }
    } else {
      if (required) {
        this.requiredError = true;
        this.input.classList.add(...invalidClasses);
      }
    }
  }

  private validateSelect(): void {
    if (!this.hasBeenTouched) {
      return;
    }

    const value = this.select.value;
    const required = this.select.required;

    this.resetErrors(this.select);

    if (required && !value) {
      this.requiredError = true;
      this.select.classList.add(...invalidClasses);
    }
  }

  private resetErrors(el: HTMLElement): void {
    this.requiredError = false;
    this.minError = false;
    this.maxError = false;
    el.classList.remove(...invalidClasses);
  }
}
