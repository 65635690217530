import { common as wp } from '@hcd-caravanhealth/pkg-wptypes';
import { AppIds, EnvironmentType, IEnvironment } from './environmentTypeDef';
import { TrackFeatureConstants } from '../app/shared/models/module-constants';

export const environment: IEnvironment = {
  production: true,
  environmentId: EnvironmentType.qa,
  authClientId: AppIds.qa,
  patientIdentityProvider:
    wp.IdentifierProviders.CaravanHealthPatientInHierarchyNodeQAEnvironment,

  wpApiUrl: 'https://api.staging.caravan.health/wpapi/2',
  trackApiUrl: 'https://api.staging.caravan.health/track',
  userDetailApiUrl: 'https://api.staging.caravan.health/access',
  apiBaseUrl: 'https://api.staging.caravan.health',
  ocpApimSubscriptionKey: '7c4fa04a34d34e02b2ec87fd64e28437',
  chEndPoint:
    'https://compassdevqaidservice.azurewebsites.net/connect/userinfo',
  authWellknownEndpoint:
    'https://chprodb2c.b2clogin.com/chprodb2c.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signinCaravanHealthStaging',
  stsServer: 'https://login.microsoftonline.com/chprodb2c.onmicrosoft.com/v2.0',
  authConfigScope:
    'openid https://chprodb2c.onmicrosoft.com/coachApi/demo.write https://chprodb2c.onmicrosoft.com/coachApi/demo.read',
  coachApiUrl: 'https://api.staging.caravan.health/coach', // 'https://api.qa.caravan.health',
  prizmApiUrl: 'https://prizmapi.dev.caravan.health',
  careOrchApiUrl: 'https://api.staging.caravan.health/careorchservice',
  blobStorageUrl: 'https://caravancoachdocrepo.blob.core.windows.net/documents',
  // docRepo: 'https://api.qa.caravan.health/api/docRepo',
  // coachGraphQL: 'https://api.qa.caravan.health/graphql',
  maskSensitiveData: true,
  useHashStrategy: false,
  idleStart: 1500,
  idleTimeout: 300,
  idlePing: 120,
  mixpanel: {
    projectId: '0ae84527ed177cd8bfb8d2700f8ad9a6',
    debug: false,
    pageViews: true
  },
  appInsights: {
    connectionString: 'InstrumentationKey=a4d4980d-17bd-4111-8b7b-fd9e0f0f7272',
  },
  displayRiskLevel: true,
  serverEventsEndpoint: 'https://coachsignalr-staging-as.azurewebsites.net',
};
