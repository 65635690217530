import { Component } from '@angular/core';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { CareExceptionsComponent } from './views/care-prescriptions/care-exceptions/care-exceptions.component';

const {
  PatientsFeature,
  CarePrescriptionFeature,
  CarePrescriptionProcessingFeature,
  CarePrescriptionInvoicingFeature,
  FeatureFlag340B,
  HiddenFeatureFlag340B,
  UIUpdates340B,
  ExceptionQueue340B,
  CareLandingPageFlag
} = CareFeatureConstants;

@Component({
  selector: 'coach-care',
  templateUrl: './care.component.html',
  host: {
    class: 'coach-care',
  },
})
export class CareComponent {
  public tabsData = [
    {
      name: 'Summary',
      routerLink: ['summary-tab'],
      featureFlag: CareLandingPageFlag,
    },
    {
      name: 'Patients',
      routerLink: ['patients'],
      featureId: PatientsFeature,
    },
    {
      name: '340B Prescriptions',
      routerLink: ['allPrescriptions'],
      featureId: CarePrescriptionFeature,
      featureFlag: UIUpdates340B,
    },
    {
      name: 'Pre-validation Exceptions',
      routerLink: ['prescriptions-exceptions'],
      featureId: CareExceptionsComponent,
      featureFlag: ExceptionQueue340B,
      hideIfFeatureFlagIsActive: UIUpdates340B
    },
    {
      name: 'Validate 340B',
      routerLink: ['prescriptions'],
      featureId: CarePrescriptionFeature,
      featureFlag: FeatureFlag340B,
      hideIfFeatureFlagIsActive: UIUpdates340B
    },
    {
      name: 'Process 340B',
      routerLink: ['prescriptions-processing'],
      featureId: CarePrescriptionProcessingFeature,
      featureFlag: FeatureFlag340B,
      hideIfFeatureFlagIsActive: UIUpdates340B
    },
    {
      name: 'Invoice 340B',
      routerLink: ['prescriptions-invoicing'],
      featureId: CarePrescriptionInvoicingFeature,
      featureFlag: HiddenFeatureFlag340B,
      hideIfFeatureFlagIsActive: UIUpdates340B
    },
  ];

  constructor() {}
}
