import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { HierarchyTierService } from '../hierarchy/hierarchy-tier.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

export interface RecentsElement {
  id: number;
  value: string;
}

export interface RecentsItem {
  navURL: string;
  dateTime: string;
  elements: RecentsElement[];
}
@Injectable({
  providedIn: 'root',
})
export class RecentsCacheService extends KillSubscriptions {
  public recents$: BehaviorSubject<RecentsItem[]> = new BehaviorSubject([]);
  recentsTier$: BehaviorSubject<string> = new BehaviorSubject('');

  private endpoint = environment.apiBaseUrl + '/access/userRecents';
  private get httpHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authService.IdToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    });
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private hierarchyTierService: HierarchyTierService
  ) {
    super();
    hierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe((currentTier) => {
        if (currentTier) {
          const {
            selectedItem: { tier2_ID },
          } = currentTier;
          if (tier2_ID !== this.recentsTier$.value) {
            this.recentsTier$.next(tier2_ID);
          }
          this.httpClient
            .get(this.endpoint + `?tier2Id=${tier2_ID}`, {
              responseType: 'json',
              headers: this.httpHeaders,
            })
            .subscribe((result: RecentsItem[]) => {
              this.recents$.next(result);
            });
        }
      });
  }

  public push(recent: RecentsItem): void {
    let temp = this.recents$.value;
    temp = temp.filter((r) => r.navURL !== recent.navURL);
    temp.unshift(recent);
    if (temp.length > 10) {
      temp.length = 10;
    }
    this.recents$.next(temp);
  }
}
