import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterToggleService {
  public filterToggle: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  public toggleFilterPane(toggledStatus: boolean) {
    this.filterToggle.next(toggledStatus);
  }
}
