import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { filter, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HasFeatureGuard implements CanActivate {
  constructor(private featureFlagService: FeatureFlagService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const feature = route.data?.featureFlag;
    return this.featureFlagService.featureFlags$.pipe(
      filter((value) => !!value),
      take(1),
      map(() => this.featureFlagService.hasFeatureFlag(feature))
    );
  }
}
