import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

import { SidebarComponent } from './sidebar.component';
import { SidebarLogoComponent } from './sidebar-logo/sidebar-logo.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { SidebarRecentComponent } from './sidebar-recent/sidebar-recent.component';
import { SidebarRecentElementComponent } from './sidebar-recent/sidebar-recent-element/sidebar-recent-element.component';
import { SidebarAccountComponent } from './sidebar-account/sidebar-account.component';
import { SidebarAccountDropdownComponent } from './sidebar-account/sidebar-account-dropdown/sidebar-account-dropdown.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        SidebarComponent,
        SidebarLogoComponent,
        SidebarMenuComponent,
        SidebarRecentComponent,
        SidebarRecentElementComponent,
        SidebarAccountComponent,
        SidebarAccountDropdownComponent,
    ],
    imports: [SharedModule, PortalModule, OverlayModule, RouterModule],
    exports: [SidebarComponent]
})
export class SidebarModule {}
