/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompareAwvMissing } from '../models/compare-awv-missing';
import { CompareAwvScheduled } from '../models/compare-awv-scheduled';
import { CompareCin } from '../models/compare-cin';
import { CompareMonthlyAwvResults } from '../models/compare-monthly-awv-results';
import { CompareSummaryResults } from '../models/compare-summary-results';

@Injectable({
  providedIn: 'root',
})
export class CompareService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation compareSummaryGet
   */
  static readonly CompareSummaryGetPath = '/Compare/summary';

  /**
   * Get data for the Compare summary page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `compareSummaryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareSummaryGet$Response(params?: {
    tierId?: string;
    tierNum?: number;
    attribution?: string;
    communityToggle?: string;
    cinId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CompareSummaryResults>> {

    const rb = new RequestBuilder(this.rootUrl, CompareService.CompareSummaryGetPath, 'get');
    if (params) {
      rb.query('tierId', params.tierId, {});
      rb.query('tierNum', params.tierNum, {});
      rb.query('attribution', params.attribution, {});
      rb.query('communityToggle', params.communityToggle, {});
      rb.query('cinId', params.cinId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompareSummaryResults>;
      })
    );
  }

  /**
   * Get data for the Compare summary page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `compareSummaryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareSummaryGet(params?: {
    tierId?: string;
    tierNum?: number;
    attribution?: string;
    communityToggle?: string;
    cinId?: number;
  },
  context?: HttpContext

): Observable<CompareSummaryResults> {

    return this.compareSummaryGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<CompareSummaryResults>) => r.body as CompareSummaryResults)
    );
  }

  /**
   * Path part for operation compareCinsGet
   */
  static readonly CompareCinsGetPath = '/Compare/cins';

  /**
   * Get CIN list for the Compare page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `compareCinsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareCinsGet$Response(params?: {
    attribution?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CompareCin>>> {

    const rb = new RequestBuilder(this.rootUrl, CompareService.CompareCinsGetPath, 'get');
    if (params) {
      rb.query('attribution', params.attribution, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompareCin>>;
      })
    );
  }

  /**
   * Get CIN list for the Compare page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `compareCinsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareCinsGet(params?: {
    attribution?: string;
  },
  context?: HttpContext

): Observable<Array<CompareCin>> {

    return this.compareCinsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CompareCin>>) => r.body as Array<CompareCin>)
    );
  }

  /**
   * Path part for operation compareMonthlyAwvGet
   */
  static readonly CompareMonthlyAwvGetPath = '/Compare/monthlyAwv';

  /**
   * Get Monthly AWV rates for the Compare page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `compareMonthlyAwvGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareMonthlyAwvGet$Response(params?: {
    tierIdList?: string;
    tierNum?: number;
    attribution?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CompareMonthlyAwvResults>> {

    const rb = new RequestBuilder(this.rootUrl, CompareService.CompareMonthlyAwvGetPath, 'get');
    if (params) {
      rb.query('tierIdList', params.tierIdList, {});
      rb.query('tierNum', params.tierNum, {});
      rb.query('attribution', params.attribution, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompareMonthlyAwvResults>;
      })
    );
  }

  /**
   * Get Monthly AWV rates for the Compare page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `compareMonthlyAwvGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareMonthlyAwvGet(params?: {
    tierIdList?: string;
    tierNum?: number;
    attribution?: string;
  },
  context?: HttpContext

): Observable<CompareMonthlyAwvResults> {

    return this.compareMonthlyAwvGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<CompareMonthlyAwvResults>) => r.body as CompareMonthlyAwvResults)
    );
  }

  /**
   * Path part for operation compareScheduledPatientsGet
   */
  static readonly CompareScheduledPatientsGetPath = '/Compare/scheduledPatients';

  /**
   * Get Scheduled Patients drillthrough data for Compare page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `compareScheduledPatientsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareScheduledPatientsGet$Response(params?: {
    tierId?: string;
    tierNum?: number;
    monthYear?: string;
    attribution?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CompareAwvScheduled>>> {

    const rb = new RequestBuilder(this.rootUrl, CompareService.CompareScheduledPatientsGetPath, 'get');
    if (params) {
      rb.query('tierId', params.tierId, {});
      rb.query('tierNum', params.tierNum, {});
      rb.query('monthYear', params.monthYear, {});
      rb.query('attribution', params.attribution, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompareAwvScheduled>>;
      })
    );
  }

  /**
   * Get Scheduled Patients drillthrough data for Compare page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `compareScheduledPatientsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareScheduledPatientsGet(params?: {
    tierId?: string;
    tierNum?: number;
    monthYear?: string;
    attribution?: string;
  },
  context?: HttpContext

): Observable<Array<CompareAwvScheduled>> {

    return this.compareScheduledPatientsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CompareAwvScheduled>>) => r.body as Array<CompareAwvScheduled>)
    );
  }

  /**
   * Path part for operation compareMissingPatientsGet
   */
  static readonly CompareMissingPatientsGetPath = '/Compare/missingPatients';

  /**
   * Get Missing Patients drillthrough data for Compare page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `compareMissingPatientsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareMissingPatientsGet$Response(params?: {
    tierId?: string;
    tierNum?: number;
    monthYear?: string;
    attribution?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CompareAwvMissing>>> {

    const rb = new RequestBuilder(this.rootUrl, CompareService.CompareMissingPatientsGetPath, 'get');
    if (params) {
      rb.query('tierId', params.tierId, {});
      rb.query('tierNum', params.tierNum, {});
      rb.query('monthYear', params.monthYear, {});
      rb.query('attribution', params.attribution, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompareAwvMissing>>;
      })
    );
  }

  /**
   * Get Missing Patients drillthrough data for Compare page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `compareMissingPatientsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  compareMissingPatientsGet(params?: {
    tierId?: string;
    tierNum?: number;
    monthYear?: string;
    attribution?: string;
  },
  context?: HttpContext

): Observable<Array<CompareAwvMissing>> {

    return this.compareMissingPatientsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CompareAwvMissing>>) => r.body as Array<CompareAwvMissing>)
    );
  }

}
