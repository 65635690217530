import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { AzureApiBase } from '../azure-api/azure-api-base';
import { LogError } from './log-error';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService extends AzureApiBase<LogError> {

  /** Service for logging errors to the CoachSQL database */
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.apiBaseUrl, '/logging/error');
  }

  /** Use the post http method to post a {@link LogError} request object for logging an exception */
  public logError(error: LogError): Observable<unknown> {
    return this.post(null, error);
  }
}
