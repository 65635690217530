import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mbi',
})
export class MbiFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length) {
      return (
        value.substr(0, 4) + '-' + value.substr(4, 3) + '-' + value.substr(7, 4)
      );
    } else {
      return null;
    }
  }
}
