<div class="body">
  <coach-sidebar class="sidebar main-sidebar u-overflow--visible no-print"  [ngClass]="{'is-open': isSidebarOpen}" (sidebarToggled)="toggleSidebar($event)" [state]="isSidebarOpen"></coach-sidebar>
  <div class="page">
    <coach-header class="navbar no-print"></coach-header>
    <div class="row u-fill u-position--relative">
      <coach-help-panel></coach-help-panel>
      <div class="column u-fill">
        <router-outlet class="outlet--column"></router-outlet>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isTimeoutDialogOpen" class="overlay overlay--light is-open">
  <div class="modal">
    <div class="header">
      <div class="titles">
        <div class="title">Session Expires Soon</div>
      </div>
    </div>
    <div class="dynamicBody padding">
      <p>Your session is about to expire due to inactivity.</p>
      <p>You will be logged out in <b>{{timeLeft | minuteSeconds}}</b></p>
    </div>
    <div class="footer">
      <button class="button margin-right" (click)="logout()">Logout</button>
      <button class="button is-primary" (click)="continueSession()">Stay Logged In</button>
    </div>
  </div>
</div>