<ng-container [formGroup]="parentForm">
  <div [formGroupName]="questionInfo.name" class="u-background-color--white">


    <ng-container formArrayName="FormQResponses">
      <div class="submitFormBlock-main" [formGroupName]="0" >

          <div class="group">

            
            <label class="label" for="pi-{{questionInfo.name}}-periodStartDate">
              PI Start Date
              <coach-icon class="icon--selectable margin-right--smaller" iconName="info" iconSize="small"
              iconColor="primary" (click)="showInfoWithText()"></coach-icon>
            </label>
            
            <input class="u-width--medium" formControlName="periodStartDate"  (change)="changeResponse($event.target.value, startDateText)"
            placeholder="date..." type="date" >

          
            <label class="label" for="pi-{{questionInfo.name}}-periodEndDate">
              PI End Date
              <coach-icon class="icon--selectable margin-right--smaller" iconName="info" iconSize="small"
              iconColor="primary" (click)="showInfoWithText()"></coach-icon>
            </label>
            
            <input class="u-width--medium"  formControlName="periodEndDate" (change)="changeResponse($event.target.value, endDateText)"
                placeholder="date..." type="date" >
          </div>

          <div class="submitFormBlock-alerts u-italic u-color--danger padding-left--smaller paddings-top--small"
          *ngIf="errors && errors?.length">
            <div *ngFor="let error of errors">{{ error }}</div>
          </div>


      </div>
    </ng-container>
  </div>
</ng-container>




