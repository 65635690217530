import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { Patient } from '@shared/models/patient';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { PatientService } from '@shared/services/patient/patient.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import moment from 'moment/moment';
import { OutcomeType } from '@api/care-orch/models/outcome-type';
import { PropertiesType } from '@api/care-orch/models/properties-type';
import { CallbackProperties } from '@api/care-orch/models/callback-properties';
import { CallBackOutcome } from '@api/care-orch/models/call-back-outcome';
import { CompletedOutcome } from '@api/care-orch/models/completed-outcome';
import { PatientAwvUtilities } from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/patient-awv-utilities.component';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';

const oneWeeksTime = moment().add(1, 'week').format('MM/DD/yyyy');

export class Comment {
  createdDateTime: string;
  createdUserDisplayName: string;
  commentText: string;
  constructor(createdDateTime, createdUserDisplayName, commentText) {
    this.createdDateTime = createdDateTime;
    this.createdUserDisplayName = createdUserDisplayName;
    this.commentText = commentText;
  }
}

@Component({
  selector: 'coach-patient-workflow-call-back',
  templateUrl: './patient-workflow-call-back.component.html',
  styleUrls: ['./patient-workflow-call-back.component.scss']
})
export class PatientWorkflowCallBackComponent implements OnInit {
  @Input() public patient: Patient;
  @Output() outcomeSaved: EventEmitter<CompletedOutcome> = new EventEmitter();
  @Input() public properties: CallbackProperties;

  user: wpapi.model.Professional;
  professionalsById: { [key: string]: wpapi.model.Professional } = {};
  selectedReminder = 0;
  reminderDate: string = oneWeeksTime;
  checked = false;
  reminderDateMin: string = moment().add(1, 'days').format('YYYY-MM-DD');
  reminderDateMax: string = moment().add(1, 'year').format('YYYY-MM-DD');
  callBackReason = '';
  comment = '';
  reasons: string[];
  allFieldsEmpty = true;
  requiredFieldsSatisfied = false;
  commentCharacterLimit = CareOrchestrationConstants.commentBoxCharacterLimit;

  constructor(private patientService: PatientService, private authService: AuthService) {
    this.setReminderDateMin();
  }

  ngOnInit(): void {
    this.reminderDate = oneWeeksTime;
    this.reasons = [
      'Person unreachable',
      'Patient traveling',
      'Patient requested callback',
      'Patient requires translator',
      'Provider requested callback',
      'Patient is in a skilled nursing facility',
      'Patient is in an inpatient facility',
      'Other'
    ];
    this.getCurrentUserInfo();
  }

  setReminderDateMin() {
    this.reminderDateMin = moment().add(1, 'days').format('YYYY-MM-DD');
  }

  setSelectedReminderDate(reminderNumber) {
    this.selectedReminder = reminderNumber;
    switch (reminderNumber){
      case 1:
        this.reminderDate = moment().add(1, 'days').format('MM/DD/yyyy');
        break;
      case 2:
        this.reminderDate = moment().add(1, 'week').format('MM/DD/yyyy');
        break;
      case 3:
        this.reminderDate = moment().add(1, 'month').format('MM/DD/yyyy');
        break;
      case 4:
        this.reminderDate = moment().add(3, 'months').format('MM/DD/yyyy');
        break;
    }
    this.fieldChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.patient) {
      this.clearFields();
    }
    this.selectedReminder = 2;
  }

  copyComment(commentBox){
    commentBox.select();
    document.execCommand('copy');
    commentBox.setSelectionRange(0, 0);
  }

  fieldChanged() {
    this.checkAnyFieldHasValue();
    this.checkRequiredFieldsSatisfied();
  }

  private checkAnyFieldHasValue() {
    if (this.selectedReminder === 2 && this.reminderDate === '' && this.callBackReason === '' && this.comment === '') {
      this.allFieldsEmpty = true;
      return;
    }

    this.allFieldsEmpty = false;
  }

  public isReminderDateValid(): boolean {
    return this.reminderDate !== '' && this.reminderDate >= this.reminderDateMin && this.reminderDate <= this.reminderDateMax;
  }

  private checkRequiredFieldsSatisfied() {
    if ((this.selectedReminder !== 0 || this.isReminderDateValid()) && this.callBackReason !== '') {
      this.requiredFieldsSatisfied = true;
      return;
    }

    this.requiredFieldsSatisfied = false;
  }

  clearFields() {
    this.selectedReminder = 2;
    this.reminderDate = oneWeeksTime;
    this.callBackReason = '';
    this.comment = '';
    this.checked = false;
    this.fieldChanged();
  }

  save() {
    const reminderOutcome = {
      type: OutcomeType.CallBackOutcome,
      properties: {
        type: PropertiesType.CallBackProperties,
        date: WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.reminderDate),
        reason: PatientAwvUtilities.callBackReasonToEnum(this.callBackReason),
        comment: this.comment ? this.comment : null,
      } as unknown as CallbackProperties
    } as CallBackOutcome;
    this.outcomeSaved.emit(reminderOutcome);
    this.clearFields();
  }

  getCurrentUserInfo(): void {
    this.authService.getWellpepperUserInfo().then(user => this.user = user);
  }

  getProfessionalsInfo(proIds: string[]): void {
    proIds?.forEach(id => {
      if (!this.professionalsById[id]) {
        this.patientService.getProfessional(id).then(professional => {
          this.professionalsById[id] = professional;
        });
      }
    });
  }

  customDateChange(){
    this.checked = !this.checked;
    if (this.checked === true) {
      this.selectedReminder = 0;
      this.reminderDate = '';
    } else{
    if (this.checked === false) {
        this.selectedReminder = 2;
        this.reminderDate = oneWeeksTime;
      }
    }
    this.fieldChanged();
  }

  stringifyForm(): string {
    if (!this.callBackReason || !this.reminderDate) {
      return '';
    }

    let value = 'AWV outreach attempted. ' + this.callBackReason + '. Next outreach on ' +
      WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat(this.reminderDate);

    if (this.comment) {
      value += '. ' + this.comment;
    }

    return value;
  }
}


