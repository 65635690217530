import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsLoggingService {

  appInsights: ApplicationInsights;

  /** Array of URL segments to be replaced when reporting telemetry to Application Insights */
  urlReplacementPatterns: UrlSegmentPattern[] = [
    { pattern: '(?<tierInfo>/(com|aco|ppr|pra)/[a-zA-Z0-9.]+/)', group: 'tierInfo', replacement: '/' },
    { pattern: '(/care/patient(?<carePatient>/[0-9]+))', group: 'carePatient', replacement: '' },
  ];

  /** Service for logging app events to Application Insights
   *
   * https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
  */
  constructor(private router: Router) {
    if (environment.appInsights?.connectionString) {
      const angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsights.connectionString,
          distributedTracingMode: 2, // DistributedTracingModes.W3C
          disableFetchTracking: false,
          enableCorsCorrelation: true,
          correlationHeaderExcludedDomains: ['*.mixpanel.com'],
          enableRequestHeaderTracking: !environment.production, // request headers include authorization info
          enableResponseHeaderTracking: true,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router },
          },
        },
      });
      this.appInsights.loadAppInsights().addTelemetryInitializer((item) => this.modifyAppInsightsTelemetry(item));
    }
  }

  modifyAppInsightsTelemetry(envelope: ITelemetryItem): void {
    this.replaceUrlSegments(envelope);

    envelope.tags['ai.cloud.role'] = environment.appInsights?.cloudRole || 'COACH WEB';
    envelope.tags[
      'ai.cloud.roleInstance'
    ] = `${environment.environmentId} instance`;
  }

  /**
   * Replace segments in the URL that is reported to Application Insights in order to provide more useful routes for tracing
   * @param {ITelemetryItem} envelope Contains data that will be sent to Application Insights
   */
  replaceUrlSegments(envelope: ITelemetryItem): void {
    if (envelope.baseData?.uri != null && envelope.baseType === 'PageviewData') {
      const uri: string = envelope.baseData?.uri;
      const regex = new RegExp(this.urlReplacementPatterns.map(x => x.pattern).join('|'), 'i');
      const result = regex.exec(uri);

      if (result?.length > 0 && envelope.ext?.trace?.name && envelope.baseData?.uri) {
        envelope.data.originalUri = uri;

        this.urlReplacementPatterns.forEach(pattern => {
          const re = new RegExp(pattern.pattern);
          const matches = re.exec(envelope.ext.trace.name);

          if (matches != null) {
            envelope.ext.trace.name = envelope.ext.trace.name.replace(matches.groups[pattern.group], pattern.replacement);
            envelope.baseData.uri = envelope.baseData.uri.replace(matches.groups[pattern.group], pattern.replacement);
          }
        });
      }
    }
  }

  logPageView(name?: string, url?: string): void { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: unknown }): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: unknown }): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: unknown }): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }

}

interface UrlSegmentPattern {
  pattern: string;
  group: string;
  replacement: string;
}
