import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Inject,
  LOCALE_ID,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { GridFilterService, ED_UTILIZATION_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { PhonePipe } from '@shared/pipes/phone.pipe';
import { AgGridLocalSettingsService } from '@shared/services/ag-grid/ag-grid.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { PatientViewModel } from '@shared/models/patient';
import { currencyCellRenderer } from '@shared/components/grid/grid-functions';
import { ColDef, ColumnApi, ColumnState, ColumnStateParams, FilterChangedEvent, GridApi } from 'ag-grid-community';

@Component({
  selector: 'coach-ed-utilization-grid',
  templateUrl: './ed-utilization-grid.component.html',
  styleUrls: ['./ed-utilization-grid.component.scss'],
  providers: [MaskPipe, PhonePipe]
})
export class EdUtilizationGridComponent extends PersistentGridFilters implements OnInit, OnChanges {
  @Input() rowData: any[];
  @Input() tierNum: number;
  @Input() tierId: string;
  @Input() reportName: string;
  @Input() columnDefs: Array<ColDef>;
  @Input() localeText: { [key: string]: string };
  @Input() loadingStatus: boolean;
  @Output() public selectedPatients: EventEmitter<any[]> = new EventEmitter();
  @Output() public secondaryColumnApiEmitter: EventEmitter<ColumnApi> = new EventEmitter(); // sends columnApi to care-patients where it's used in columnsSettingsModal to hide/display columns;
  @Output() public agFilterChangedEmitter: EventEmitter<GridApi> = new EventEmitter(); // sends gridApi instance to care-patients when filter is applied;
  @Input() hiddenColumnsByDefault: string[];
  @Output() public setSelectedPatientId: EventEmitter<string> = new EventEmitter();
  
  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  private currentUrl: string;
  AgGridCustomFilteringFlag: string = CareFeatureConstants.AgGridCustomFilteringFlag;

  defSortModel: any = [
    {
      colId: 'predictedRiskLevel',
      sort: 'asc'
    },
    {
      colId: 'patientName',
      sort: 'asc'
    }
  ];
  user: wpapi.model.Professional;
  constructor(
    protected filterService: GridFilterService,
    private router: Router,
    private route: ActivatedRoute,
    private mask: MaskPipe,
    private authService: AuthService,
    @Inject(LOCALE_ID) private locale: string,
    private agGridLocalSettingsService: AgGridLocalSettingsService,
    private featureFlagService: FeatureFlagService,
    private phone: PhonePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(filterService, ED_UTILIZATION_GRID_KEY);
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.currentUrl = this.router.url;
    if (this.hasFilterModel()) {
      if (this.currentUrl === this.getFilterModel().url) {
        console.log('Restoring');
        this.restoreColumnState(this.gridColumnApi, this.gridApi);
      } else {
        console.log('Resetting');
        this.gridColumnApi?.resetColumnState();

        this.gridColumnApi.applyColumnState({
          state: this.defSortModel
        });
        this.setLocalColumnSettings();
      }
    } else {
      console.log('No filter model');
      if (this.gridColumnApi) {
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.applyColumnState({
          state: this.defSortModel
        });
        this.setLocalColumnSettings();
      }
    }
  }

  public clear() {
    this.gridApi.setFilterModel(null);
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      params.api.setFilterModel(this.getFilterModel().filterModel);
    }

    params.api.sizeColumnsToFit();
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setLocalColumnSettings();
    this.gridApi.deselectAll();
    this.selectedPatients.emit([]);
    this.secondaryColumnApiEmitter.emit(this.gridColumnApi);
    this.getAndApplyFilterModel();
  }

  public onRowClicked(event: any): void {
    console.log('saving column state');
    this.saveColumnState(this.gridColumnApi, this.gridApi, this.currentUrl);

    const sourceCell = event.event.srcElement;
    if (sourceCell.className.includes('cell-patient-navigation')) {
      // CC-2955 incorrect case for 'chPatID'
      // this.router.navigate([`../patient/${event.data.chPatID}`], {
      this.setSelectedPatientId.emit(event.data.chPatId);
    }
  }

  public onExportClick(): void {
    const reportCohort = this.route.snapshot.queryParamMap.get('filterPreset');
    const timestamp = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    this.gridApi.exportDataAsCsv({
      suppressQuotes: false,
      columnSeparator: ',',
      fileName: `EDUtilizationReport_${reportCohort}_${timestamp}.csv`
    });
  }

  private currencyCellRenderer(params: any): string {
    const inrFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    return inrFormat.format(params.value);
  }
  public onSelectionChanged(event: any): void {
    let patients: PatientViewModel[] = [];
    this.gridApi?.forEachNodeAfterFilter(patient => {
      if (patient.isSelected()) {
        patients.push(patient.data);
      }
    });
    this.selectedPatients.emit(patients);
  }
  public exportCSV(): void {
    let selectedItems = this.gridApi.getSelectedRows();
    if (selectedItems.length < 1) {
      return;
    }
    const reportCohort = this.route.snapshot.queryParamMap.get('filterPreset');
    const timestamp = formatDate(new Date(), 'yyyy-MM-dd', this.locale);

    this.gridApi.exportDataAsCsv({
      processCellCallback: (params: any): string => {
        switch (params.column.getColId()) {
          case 'lastActivityAt':
          case 'lastAWVDate':
          case 'lastVisit':
            return params.value ? formatDate(new Date(params.value), 'MM/dd/yyyy', this.locale) : '';
          case 'phone1':
          case 'phone2':
            return params.value ? this.phone.transform(params.value) : '';
          case 'totalSpend':
          case 'totalCost':
            return currencyCellRenderer(params);
          case 'patientId':
          case 'current_MBI':
            return this.mask.transform(params.value, 6);
          default:
            return params.value;
        }
      },
      suppressQuotes: false,
      columnSeparator: ',',
      onlySelected: true,
      columnKeys: this.agGridLocalSettingsService.getActiveColumnNames(this.gridColumnApi),
      fileName: `EDUtilizationReport_${reportCohort}_${timestamp}.csv`
    });
  }

  public dataExists(): boolean {
    return this.rowData?.length > 0;
  }
  onColumnUpdated(): void {
    this.agGridLocalSettingsService.storeColumnSettingsInLocalStorage(
      'ED Utilization',
      this.gridColumnApi.getColumnState(),
      null
    );
  }
  async setLocalColumnSettings() {
    if (!this.user) {
      this.user = await this.authService.getWellpepperUserInfo();
    }
    this.agGridLocalSettingsService.setColumnSettings(
      this.gridColumnApi,
      'ED Utilization',
      this.user._id,
      null,
      this.hiddenColumnsByDefault
    );
  }
  agFilterChanged(event: FilterChangedEvent): void {
    this.agFilterChangedEmitter.emit(this.gridApi);
    if (event?.afterDataChange != null) {
      this.storeFilterModel();
    }
  }
  storeFilterModel(): void {
    this.changeDetectorRef.detectChanges();
    this.agGridLocalSettingsService.storeFilterModelInLocalStorage(
      'COHORTS',
      'ED Utilization',
      this.gridApi.getFilterModel()
    );
  }
  async getAndApplyFilterModel() {
    let storedFilterModel = await this.agGridLocalSettingsService.getStoredFilterModelFromLocalStorage(
      'COHORTS',
      'ED Utilization'
    );
    this.gridApi.setFilterModel(storedFilterModel);
  }
}
