import { Component, Input, OnInit } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '../kill-subscriptions';

/**
 * Component for displaying an overlay modal message forcing a user to select
 * a required hierarchy tier item from the global hierarchy selectors.
 * 
 * @example
 * <coach-hierarchy-required
 *                pageName="HCC Dashboard"
 *                [requiredTier]="2">
 * </coach-hierarchy-required>
 */
@Component({
  selector: 'coach-hierarchy-required',
  templateUrl: './hierarchy-required.component.html',
  styleUrls: ['./hierarchy-required.component.scss']
})
export class HierarchyRequiredComponent extends KillSubscriptions implements OnInit {

  /**
   * (Required) Name of the page to display in the hierarchy required message
   */
  @Input() public pageName: string;
  /**
   * (Required) Tier number required in order to view page (e.g. 1, 2, 3, or 4)
   */
  @Input() public requiredTier: number;

  public tierName: string;
  public currentTier: IHierarchyTier;

  showModal = false;

  constructor(private hierarchyTierService: HierarchyTierService) {
    super();
   }

  ngOnInit(): void {
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
      if (tier == null) {
        return;
      }

      this.currentTier = tier;
      this.tierName = this.hierarchyTierService.hierarchyTiers.find(x => x.tier === this.requiredTier).name.toLowerCase();

      if (tier.tier >= this.requiredTier) {
        this.showModal = false;
      } else {
        this.showModal = true;
      }
    });
  }

}
