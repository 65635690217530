import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';
import { environment } from 'src/environments/environment.coachDev';

@Injectable({
  providedIn: 'root'
})
export class UnreadMessagesPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const item: IPatientFilterPreset = {
      category: PresetCategory.AlertsAndMessages,
      name: CarePatientItem.UnreadMessages,
      label: 'Unread messages',
      hidePreset: false,
      filters: [],
      type: 'messages',
      componentId: CareComponentConstants.PatientList,
      columns: this.getPresetColumns(),
      presetKey: CarePatientItem.UnreadMessages
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean }[] {
    return [
      { select: true },
      { fullName: true },
      { dob_age: true },
      { gender: true },
      { mbi: true },
      { phoneNumber: true },
      { professionalName: this.hasPatientApp },
      { assignment: true },
      { attributedCP: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      { ccmEligible: this.agGridCustomFilteringFlag },
      { actions: this.hasPatientApp },
      { hccGapCount: this.agGridCustomFilteringFlag },
      { diseaseCohorts: this.agGridCustomFilteringFlag },
      { raF_YTD: this.agGridCustomFilteringFlag },
      { openRAFWt: true },
      { lastVisit: true },
      { attributedPractice: true },
      { pyRaf: true }
    ];
  }
}
