import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  app = AppConstants;
  
  constructor() { }

  ngOnInit(): void {
  }

}
