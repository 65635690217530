<div class="modalOverlay" *ngIf="showDialog">
    <div class="bg-align" *ngIf="showDialog">
        <dialog open>
            <p>{{dialogMessage}}</p>
            <div class="buttonStyling">
                <button class="button" (click)="onButtonClicked('yes')">Yes</button>
                <button class="button" (click)="onButtonClicked('no')">No (just this one)</button>
                <button class="button is-primary" (click)="onButtonClicked('cancel')">Cancel</button>
            </div>
        </dialog>
    </div>
</div>