<div class="u-height--fit-content">
  <div class="padding-top--small">
    <coach-patient-step-scheduled [awvWorkflow]="awvWorkflow"
                                  [patient]="patient"
                                  (activitySaved)="this.activitySaved($event);"
                                  (activityDeleted)="this.activityDeletedEvent($event);"
    ></coach-patient-step-scheduled>
    <coach-patient-step-reminder [awvWorkflow]="awvWorkflow"
                                 (activitySaved)="this.activitySaved($event);"
    ></coach-patient-step-reminder>
    <coach-patient-step-completed [awvWorkflow]="awvWorkflow"
                                  [patient]="patient"
                                  (activitySaved)="this.activitySaved($event);"
                                  (activityDeleted)="this.activityDeletedEvent($event);"
    ></coach-patient-step-completed>
  </div>
</div>
