export const BARRIERS_DATA = [
  {
    value: 'Lack of physician engagement',
    label: 'Lack of physician engagement'
  },
  {
    value: 'Change in Staff',
    label: 'Change in Staff'
  },
  {
    value: 'Lack of patient interest in Chronic Care Management',
    label: 'Lack of patient interest in Chronic Care Management'
  },
  {
    value: 'Lack of patient interest in Annual Wellness Visits',
    label: 'Lack of patient interest in Annual Wellness Visits'
  },
  {
    value: 'Lack of Chronic Conditions improvement',
    label: 'Lack of Chronic Conditions improvement'
  },
  {
    value: 'Unable to perform Advance Care Planning',
    label: 'Unable to perform Advance Care Planning'
  }
];

export const SUCCESSES_DATA = [
  {
    value: 'Met our set 30 day goal',
    label: 'Met our set 30 day goal'
  },
  {
    value: 'Exceeded our set 30 day goal',
    label: 'Exceeded our set 30 day goal'
  },
  {
    value: 'Performed internal campaign to improve patient care',
    label: 'Performed internal campaign to improve patient care'
  },
  {
    value: 'Trained new stuff',
    label: 'Trained new stuff'
  }
];

export const QUARTERS_DATA = [
  {
    value: 1,
    label: 'Q1 (Jan-Mar)'
  },
  {
    value: 2,
    label: 'Q2 (Apr - Jun)'
  },
  {
    value: 3,
    label: 'Q3 (Jul - Sep)'
  },
  {
    value: 4,
    label: 'Q4 (Oct - Dec)'
  }
];

export const MONTHS_DATA = [
  {
    value: 'January',
    label: 'Jan'
  },
  {
    value: 'February',
    label: 'Feb'
  },
  {
    value: 'March',
    label: 'Mar'
  },
  {
    value: 'April',
    label: 'Apr'
  },
  {
    value: 'May',
    label: 'May'
  },
  {
    value: 'June',
    label: 'Jun'
  },
  {
    value: 'July',
    label: 'Jul'
  },
  {
    value: 'August',
    label: 'Aug'
  },
  {
    value: 'September',
    label: 'Sep'
  },
  {
    value: 'October',
    label: 'Oct'
  },
  {
    value: 'November',
    label: 'Nov'
  },
  {
    value: 'December',
    label: 'Dec'
  }
];
