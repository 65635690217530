import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
  Injector,
  ElementRef,
  ViewContainerRef,
} from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { IQueryParam } from '@shared/models/query-param';
import { Patient } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { PatientActionDropdownComponent, NAVIGATION_PORTAL_DATA } from './patient-action-dropdown/patient-action-dropdown.component';
import { environment } from 'src/environments/environment'
import { CareComponentConstants } from '@shared/models/module-constants';
import { CareFeatureConstants } from "@shared/models/module-constants";
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { PatientService } from '@shared/services/patient/patient.service';
@Component({
  selector: 'coach-patient-facesheet',
  templateUrl: './patient-facesheet.component.html',
  host: {
    '[class]': 'classNames'
  }

})

export class PatientFacesheetComponent implements OnInit {

  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  isSMSInviteFlagEnabled: boolean = false;
  SMSInviteFlag: string = CareFeatureConstants.SMSInviteFlag;
  isSMSTaskAdaptive : boolean = true; 

  private get tierId() {
    return this?.tier?.selectedTierId;
  }

  @ViewChild('chartContainer') chartContainer: ElementRef;
  public patientFacesheetSize: string = 'desktop';
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateChartViewType();
  }
  public get isCarePatient() {
    return this?.patient?.carePatient?.accountType === "patient";
  }
  public get displayRiskLevel() {
    return environment.displayRiskLevel;
  }

  private overlayRef: OverlayRef;
  public modalStatus: { isOpen: boolean, target: string };
  public modalPatientInviteForm: boolean = false;
  public modalPatientEditForm: boolean = false;
  scopeSelectDropdownPortal: ComponentPortal<PatientActionDropdownComponent>;
  public readonly careComponentConstants = CareComponentConstants;
  public readonly careFeatureConstants = CareFeatureConstants;
  FacesheetUIUpdatesFeatureFlag: string = CareFeatureConstants.FacesheetUIUpdatesFlag;
  classNames: string; // used to set the component's class
  componentScreenSize: number = null;
  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private _router: Router,
    private _route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private _injector: Injector,
    public overlay: Overlay,
    private vcRef: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
    private PatientService: PatientService,
  ) {
    this.isSMSInviteFlagEnabled = this.featureFlagService.hasFeatureFlag(
      this.SMSInviteFlag
    );
    this.modalStatus = {
      isOpen: false,
      target: null
    }
    this.classNames = 'column u-height--full';
  }

  @ViewChild('scopeSelectOrigin') scopeSelectOrigin: ElementRef
  ngOnInit(): void {
    this.locationStrategy.onPopState(() => this.overlayRef?.detach());
  }

  ngOnChanges(): void {
      this.handleAsyncOperations();
  }
  
  ngAfterViewChecked(): void {
    if (this.componentScreenSize != this.chartContainer.nativeElement.offsetWidth) {
      setTimeout(() => { // prevents error => NG0100: Expression has changed after it was checked
        this.updateChartViewType();
      }, 0)
    }


    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.overlayRef?.dispose();
    this.overlayRef = null;
  }
  
  async handleAsyncOperations() {
    await this.isSMSAdaptive();
  }

  public updateChartViewType() {

    this.componentScreenSize = this.chartContainer.nativeElement.offsetWidth;
    if (this.componentScreenSize > 1221 && this.patientFacesheetSize != 'desktop') {
      this.patientFacesheetSize = 'desktop';
      this.classNames = 'column u-height--full';
    } else if (this.componentScreenSize >= 800 && this.componentScreenSize <= 1221 && this.patientFacesheetSize != 'tablet') {
      this.patientFacesheetSize = 'tablet';
      this.classNames = 'column';
    } else if (this.componentScreenSize < 800 && this.patientFacesheetSize != 'mobile') {
      this.patientFacesheetSize = 'mobile';
      this.classNames = 'column';
    }
  }
  public goBack(): void {
    this.location.back();
  }

  public updateUrl(id: string): void {
    const param: IQueryParam = { patientId: id };
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: param,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  public titleLine(fields: string[], separator = ' | '): string {
    return fields.filter(f => !!f).join(separator);
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(NAVIGATION_PORTAL_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }
  public openScopeSelectDropdown() {
    const scopeSelectDropdownPortal = new ComponentPortal(PatientActionDropdownComponent, this.vcRef, this.createInjector({
      emitter: this.dropDownEmitterFunc,
      patient: this.patient
    }));
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay.position()
      .flexibleConnectedTo(this.scopeSelectOrigin)
      .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }])
    });
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(scopeSelectDropdownPortal);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    this.overlayRef.outsidePointerEvents().subscribe(() => this.overlayRef.detach());
  }
  get dropDownEmitterFunc() {
    return this.dropDownEmitter.bind(this);
  }

  dropDownEmitter(action: string): void {
    this.overlayRef.detach()
    this.modalStatus = {
      isOpen: true,
      target: action
    }
  }
  closeModalOnBackgroundClick(event: any): void {
    if (event.target.getAttribute('role') == 'modal-background') {
      this.closeModal()
    }
  }
  closeModal(): void { 
    this.modalStatus = { 
      isOpen: false,
      target: null
    } 
  }

  async isSMSAdaptive() { 
    let taskList = await this.PatientService.getTasks(this.tierId, this.patient);
    let twiliotask;
    for (const task of taskList) {
      if (task.userId == this.patient?.carePatient?._id && task.type == 'twilioStudioFlow') {
        twiliotask = task;
      }
    }
    if (twiliotask){
      this.isSMSTaskAdaptive = twiliotask?.twilioInfo?.scheduleDefinition?  true : false; 
    }
  }
}
