import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { HccDashboardService } from '@api/track/services';
import { GridFilterService, PRACTICES_REPORT_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { HccDashboardFilterService } from '../../hcc-dashboard-filter.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { combineLatest } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'coach-practices-report',
  templateUrl: './practices-report.component.html',
  styleUrls: ['./practices-report.component.scss']
})
export class PracticesReportComponent extends PersistentGridFilters implements OnInit {

  tierNum: number;
  tierId: string;

  columnDefs = [
    {
      headerName: 'Practice Name', field: 'practiceName', tooltipField: 'practiceName', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the name of the practice included in the hierarchy'
    },
    {
      headerName: 'Principal Participant', field: 'principalName', tooltipField: 'principalName', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the principal participant name included in the hierarchy'
    },
    {
      headerName: 'Patient Count', field: 'patientCount', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Count of patient attributed or attributed/assignable to the clinician/RHC/FQHC based on filter selection.'
    },
    {
      hide: true, // Hidden with ZBR-12923
      headerName: 'Chronic Condition Open Gaps', field: 'hccGaps', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Count of open chronic condition gaps'
    },
    {
      headerName: 'Annual Closure Rate', field: 'overallHCCClosureRate', sortable: true, filter: true, resizable: true,
      valueFormatter: params => formatNumber(params.value, this.locale, '1.0-3'),
      headerTooltip: 'Percentage of chronic conditions gaps closed by claims in the current calendar year.'
    }
  ];

  rowData: any;


  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private hccDashboardService: HccDashboardService, 
    protected filterService: GridFilterService,
    private hccDashboardFilterService: HccDashboardFilterService,
    private hierarchyTierService: HierarchyTierService) {
    super(filterService, PRACTICES_REPORT_GRID_KEY);
  }

  ngOnInit(): void {
    this.rowData =
      combineLatest([this.hierarchyTierService.currentTier$, this.hccDashboardFilterService.filterModel$]).pipe(
        takeUntil(this.killTrigger),
        filter(x => x[0] != null),
        switchMap(x => {
          const [tierInfo, filterValue] = x;
          const tierNum = filterValue?.isCinSelected ? -1 : tierInfo.tier;
          const tierId = filterValue?.isCinSelected ? filterValue.selectedCin?.id.toString() : tierInfo.selectedTierId;

          return this.hccDashboardService.hccDashboardPracticesGet(
            {
              tierNum: tierNum,
              tierId: tierId,
              attributionStatus: filterValue == null || !filterValue.isMostAssignable ? ATTRIBUTED : MOST_ASSIGNABLE
            }
          );
        }));
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridColumnApi.getColumn('hccGaps').setSort('desc');
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    }
  }
}