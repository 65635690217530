<div class="dynamicBody u-position--relative list">

  <coach-doc-repo-item *ngFor="let item of docRepoData"
    (click)="toggleQueueDrillIn(item)" [item]='item'
      class="list-item u-cursor--pointer"></coach-doc-repo-item>

  <div *ngIf="docRepoData && docRepoData.length == 0" class="dynamicBody">
    <div class="padding u-opacity--light u-italic">
      No Results
    </div>
  </div>
</div>
