import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';

@Component({
  selector: 'coach-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {
  signoutInProgress = true;
  constructor(private authService: AuthService, private router: Router, private hierarchyTierService: HierarchyTierService) { }

    /*  if the IDS signIn was through Docebo -isAUthenticated will will be false and the user will show as not authenticated.
    *   but to signin will login the user without IDS Popup since IDS login has allready happened
    *   sigin will cause ust redirect to home page and then naviagate to the timeout page because coachRedirect
    *   on this call for ngOnInit()  the user will show as autheticated. 
    *  
    */
    ngOnInit(): void {
      this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.signoutInProgress = false;
        this.authService.logout();
      }
      else  if (this.signoutInProgress){ //this check is required since the callback will enter again whne we logout.
         const url = this.router.url;
        this.authService.write('coachRedirect', url);
        this.signIn();
      }
    });
   }

  
  signIn(): void {
    this.authService.login();
  }
}
