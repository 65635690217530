<coach-care-prescriptions-filters
  *ngIf="!UIUpdates340BFeatureFlag"
  [configData]="filterOptions"
  [gridApi]="gridApi"
  (onFilterChanged)="onFilterChanged()"
>
</coach-care-prescriptions-filters>

<coach-care-prescriptions-invoicing-sidebar
[tier2_id]="tier2_id" [selectedInvoice]="selectedInvoice" (onSaveInvoice)="onSaveInvoice($event)" [contractInfo]="contractInfo">
</coach-care-prescriptions-invoicing-sidebar>

<div class="page-content-main">
  <div class="header row--verticalCenter padding-topBottom--none">
    <div class="titles">
      <div class="title row--verticalCenter">
        <div class="header-content">
          <div class="head">340B Prescription Invoicing</div>
        </div>

        <div class="exportDropdown">
          <select
            id="exportSelect"
            (change)="exportSelectionChanged($event)"
            #exportDropdown
            [(ngModel)]="selectedExportOption"
          >
            <option selected disabled hidden>{{b340StringConstants.EXPORT_MENU_TITLE}}</option>
            <option>{{b340StringConstants.EXPORT_INVOICE_DATA}}</option>
          </select>
        </div>

        <button
          *ngIf="loadingStatus"
          (click)="cancelPrescriptionsLoadingProcess()"
          class="
            button
            margin-left--small
            is-smaller
            margin-topBottom--none
            padding-topBottom--smaller
          "
        >
          <span>
            <div class="loader margin-right"></div>
          </span>
          Cancel
        </button>
      </div>
    </div>
  </div>
  <div class="dynamicBody row" style="height: 100%">
    <div class="dynamicBody">
      <ag-grid-angular
        class="ag-theme-material"
        [hidden]="loadingStatus"
        
        row-animation
        style="width: 100%; height: 100%"
        [rowData]="invoices"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [overlayNoRowsTemplate]="'&lt;p&gt;No data&lt;/p&gt;'"
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (rowClicked)="onRowClicked($event)"
        [pagination]="true"
        [paginationAutoPageSize]="true"
        [rowSelection]="'multiple'"
        [rowMultiSelectWithClick]="true">
      </ag-grid-angular>
    </div>
  </div>
</div>

