import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service'
import { PatientViewModel } from '@shared/models/patient'
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
export class FilterSettings {
  rows: AgGridTableFilterSetting[]
}
import { AgGridTableFilterSetting, filterSettings } from '@care/views/care-patients/care-patients-filters/filter-settings'
export const AG_GRID_LOCALE_CARE = {
  // overwrites agGrid wordings (CC-2939)
  selectAll: 'All',
  blanks: 'None',
  empty: 'Unset'
}
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { CarePatientItem, PresetCategory } from '@shared/models/module-constants';
import { ColDef, ColumnApi, ColumnState, GridApi } from 'ag-grid-community';
export const dateFormat = 'MM/dd/yyyy';
@Injectable({
  providedIn: 'root'
})
export class AgGridLocalSettingsService {
  columnSettings: { [key: string]: any } = {}
  user: wpapi.model.Professional;
  filterSettings = filterSettings
  private currentFilterSettingsObservable = new BehaviorSubject(null)
  currentFilterSettings = this.currentFilterSettingsObservable.asObservable()
  constructor(private authService: AuthService) {
    this.storeUser()
  }
  async storeUser() {
    if (!this.user) {
      this.user = await this.authService.getWellpepperUserInfo()
    }
  }
  // checks whether there are new options available and removes old ones
  updateFilterSettings(preset: IPatientFilterPreset, patients: Array<PatientViewModel>, useDefaultFilterModel?: boolean, currentFilterModel?: {[key: string]: any}): AgGridTableFilterSetting[] {
    let settingsObj = _.clone(preset.filterSettings.rows)
    let defaultFilterModel = preset.defaultFilterModel
    if (settingsObj.length > 0) {

      // CC-2893 need to clear old values from options lists
      settingsObj.forEach( s => {
        if(s.type == 'set'){
          s.options = []
        }
      })

      let set;
      let attributedCPtierByName = {}
      patients.forEach(patient => {
        if(patient.attributedCP && !attributedCPtierByName[patient.attributedCP]) {
          attributedCPtierByName[patient.attributedCP] = patient.attributedCPTier
        }
        for (let s in settingsObj) {
          set = settingsObj[s]
          if(set.type == 'set'){
            let value = patient[set.name] || 'None'
            if (set.list) {
              patient[set.name]?.forEach(s => {
                value = s || 'None'
                if (!set.options.includes(value) && set.type == 'set') { //  && !set.excluded.includes(value)
                  set.options.push(value)
                }
              })
              if (patient[set.name]?.length == 0 && !set.options.includes('None')) {
                set.options.push('None')
              }
            } else {
              if (!set.options.includes(value) && set.type == 'set') { //  && !set.excluded.includes(value)
                set.options.push(value)
              }
            }
          }
        }
      })
      settingsObj.forEach(set => {
        if(useDefaultFilterModel && defaultFilterModel[set.name]?.values) {
          set.excluded = (set.excluded || []).concat(set.options?.filter(option => !defaultFilterModel[set.name].values.includes(option) && !set.excluded.includes(option) ));
          set.options = set.options?.filter(option => defaultFilterModel[set.name].values.includes(option));
        }
        if (set.type == 'set') {
          set.excluded = set.excluded.filter(exl => set.options.includes(exl)); // remove non-existing options from excluded list
          set.options.sort((a: string, b: string) => {
            if (a == 'None' || a == 'N/A') return -1
            if (b == 'None' || b == 'N/A') return 1
            if (set.name == 'attributedCP') {
              return a.localeCompare(b) || attributedCPtierByName[a] - attributedCPtierByName[b]
            }
            return a > b ? 1 : -1
          });
          set.excluded.sort((a: string, b: string) => {
            if (a == 'None' || a == 'N/A') return -1
            if (b == 'None' || b == 'N/A') return 1
            if (set.name == 'attributedCP') {
              return a.localeCompare(b) || attributedCPtierByName[a] - attributedCPtierByName[b]
            }
            return a > b ? 1 : -1
          });
        };
        if(currentFilterModel?.[set.name]) {
          let newFilterValues = currentFilterModel?.[set.name];
          if (newFilterValues) {
            if (newFilterValues.values) {
              set.excluded = set.options.filter(option => !newFilterValues.values.includes(option == 'None' ? null : option));
              // set.options = newFilterValues.values;
            } else if (newFilterValues.filterType == 'date') {
              set = { ...set, ...newFilterValues };
            }
          } else {
            if (set.excluded) {
              set.excluded = [];
            } else {
              set.dateFrom = null;
              set.dateTo = null;
              set.filter = null;
              set.filterTo = null;
            }
          }
        }
      });
      this.updateCurrentFilterSettingsObservable(settingsObj);
    }
    return settingsObj
  }
  updateCurrentFilterSettingsObservable(settingsObj: AgGridTableFilterSetting[]): void {
    this.currentFilterSettingsObservable.next({ rows: settingsObj });
  }
  applyFilterSettings(settingsObj: AgGridTableFilterSetting[], gridApi: GridApi, keepPreviousFilterModel: boolean, storedFilterModel: {[key: string]: any;}) {
    let filterObj = {}
    let helperShortcuts = {
      'None': null
    }
    for (let f in settingsObj) {
      let filter = settingsObj[f]
      if (!filter.name || (!filter.filterType && !filter.type)) {
        continue
      }
      try{
        if (filter.type == 'set') {
          if(!filter.options){
            continue
          }
          let values = filter.options.filter(o => !filter.excluded.includes(o)) || []
          for (let v in values) {
            values[v] = values[v] in helperShortcuts ? helperShortcuts[values[v]] : values[v]
          }
          filterObj[filter.name] = {
            type: filter.type,
            values: values
          }
        } else {
          if(!filter.type) {
            filter.type = 'unset';
            // need to reset filter model, otherwise it will be applied to the grid
            const filterInstance = gridApi.getFilterInstance(filter.name); 
            filterInstance.setModel(null);
          } else {
            filterObj[filter.name] = filter;
          }

        }

      } catch(error) {
        console.error(error)
      }
    }
    // make sure to remove elements that have empty list of options, so they won't cause any problems with filter model
    try{
      // leave it for now, might be useful in the future
      // let filterModel = storedFilterModel.filter(row => row.list && !row.options.length ? false : true ) || gridApi?.getFilterModel(); // either keeps current filter model or pulls a stored one from local storage
      let newFilterModel = { ...gridApi?.getFilterModel(), ...filterObj };
      if (storedFilterModel && Object.keys(storedFilterModel)?.length) {
        newFilterModel = { ...newFilterModel, ...storedFilterModel }
      }
      gridApi?.setFilterModel(newFilterModel);
    } catch(error) {
      console.error(error)
    }
  }

  /**
     * localStorage keys:
     * `agGrid_${categoryName}_storedPresetNames_${userId}` = stores all custom preset/filter names (string[])
     * `agGrid_${categoryName}_${filterName}_${userId}` = stores custom preset/filter
     * `agGrid_${categoryName}_${filterName}_filter_settings_${userId}` = stores all filters' settings
     */

  async retrieveAllCustomPresets(categoryName: string, originColumns: Array<{[key: string]: boolean | string}>): Promise<IPatientFilterPreset[]> {
    if (!this.user) {
      await this.storeUser()
    }
    let storedCategoryList: string[] = this.getStoredPresetsFromLocalStorage(categoryName, this.user?._id)
    let presets = []
    if (storedCategoryList?.length > 0) {
      storedCategoryList.forEach(filterName => {
        try {
          let preset: IPatientFilterPreset = this.getStoredPresetFromLocalStorage(categoryName, filterName, this.user?._id)
          if (preset) {
            preset.columns = originColumns;
            presets.push(preset)
          }
        } catch (error) {
          console.warn('retrieveAllCustomPresets: something went wrong', error)
        }
      });
    }
    return presets
  }

  async addNewCustomPreset(categoryName: PresetCategory, filterName: CarePatientItem, originPreset: IPatientFilterPreset, filterSettings: FilterSettings) {
    if (!this.user) {
      await this.storeUser();
    }
    let newPreset = _.cloneDeep(originPreset);
    newPreset.category = categoryName;
    newPreset.name = filterName;
    newPreset.label = filterName;
    newPreset.presetKey = filterName;
    newPreset.origin = originPreset.name;
    newPreset.filterSettings = filterSettings
    newPreset.temporary = false;
    let storedCategoryList: string[] = this.getStoredPresetsFromLocalStorage(categoryName, this.user?._id)
    if (!storedCategoryList?.includes(filterName)) {
      storedCategoryList ? storedCategoryList.push(filterName) : storedCategoryList = [filterName]
      this.storeStoredPresetsInLocalStorage(categoryName, this.user?._id, storedCategoryList)
    }
    this.storeStoredPresetInLocalStorage(newPreset.category, newPreset.name, this.user?._id, newPreset)
    this.storeFilterSettingsInLocalStorage(newPreset.category, newPreset.name, newPreset.filterSettings)
    return newPreset;
  }



  async deleteCustomPreset(categoryName: string, filterName: string, versionKey: string) {
    if (!this.user) {
      await this.storeUser();
    }
    this.removeFilterFromStoredPresetsInLocalStorage(categoryName, filterName, this.user?._id)
    this.removeStoredPresetFromLocalStorage(categoryName, filterName, this.user?._id)
    this.removeColumnSettingsFromLocalStorage(filterName, versionKey, this.user?._id)
    this.removeFilterSettingsFromLocalStorage(categoryName, filterName, this.user?._id)
    this.removeFilterModelFromLocalStorage(categoryName, filterName, this.user?._id)
  }

  async renameCustomPreset(categoryName: PresetCategory, oldFilterName: CarePatientItem, newFilterName: CarePatientItem, versionKey: string) {
    if (!this.user) {
      await this.storeUser();
    }

    // replace preset in storedPresets
    let presets = this.getStoredPresetsFromLocalStorage(categoryName, this.user?._id)
    if(presets){
      for(let p in presets) {
        if(presets[p] == oldFilterName){
          presets[p] = newFilterName
        }
      }
      this.storeStoredPresetsInLocalStorage(categoryName, this.user?._id, presets)
    }

    // replace stored preset
    let preset = this.getStoredPresetFromLocalStorage(categoryName, oldFilterName, this.user?._id)
    if(preset){
      preset.name = newFilterName
      preset.label = newFilterName
      this.storeStoredPresetInLocalStorage(categoryName, newFilterName, this.user?._id, preset) // store new filter preset
      this.removeStoredPresetFromLocalStorage(categoryName, oldFilterName, this.user?._id) // delete old one
    }
    // replace column settings
    let columnSettings = this.getColumnSettingsFromLocalStorage(oldFilterName,versionKey,  this.user?._id)
    if(columnSettings){
      this.storeColumnSettingsInLocalStorage(newFilterName, columnSettings,versionKey)
      this.removeColumnSettingsFromLocalStorage(oldFilterName, versionKey, this.user?._id)
    }

    // replace filter settings
    let filterSettings = await this.getStoredFilterSettingsFromLocalStorage(categoryName, oldFilterName, this.user?._id, CarePatientItem.AllPatients)
    if(filterSettings) {
      this.storeFilterSettingsInLocalStorage(categoryName, newFilterName, filterSettings)
      this.removeFilterSettingsFromLocalStorage(categoryName, oldFilterName, this.user?._id)
    }

    let filterModel = await this.getStoredFilterModelFromLocalStorage(categoryName, oldFilterName)
    if(filterModel) {
      this.storeFilterModelInLocalStorage(categoryName, newFilterName, filterModel)
      this.removeFilterModelFromLocalStorage(categoryName, oldFilterName, this.user?._id)
    }
  }

  // ======================= localStorage Column Settings getters and setters =======================

  async storeColumnSettingsInLocalStorage(presetName: string,  settings: ColumnState[],versionKey:string) {
    if (!this.user) {
      await this.storeUser();
    }
    this.columnSettings[`${this.user._id}_${presetName}`] = settings
    const localStorageKey = versionKey || this.getPresetLocalStorageKey(presetName,this.user._id);
    localStorage.setItem(localStorageKey, JSON.stringify(settings || {}))
  }
  setColumnSettings(columnApi: ColumnApi, presetName: string, userId: string, versionKey: string,  hiddenColumnsByDefault?: string[]) {
    if (!columnApi || !presetName) {
      console.warn('AgGridLocalSettingsService setColumnSettings:', `columnApi ${columnApi ? 'is here' : 'not found'}`, `presetName ${presetName ? 'is here' : 'not found'}`)
      return;
    }
    let storedColumnSettings = this.getColumnSettingsFromLocalStorage(presetName,versionKey, userId)
    const columnState = columnApi.getColumnState(); // columnApi must be defined, it's checked earlier
    if (storedColumnSettings) {
      const defaultSortModelByColId: { [key: string]: string } = {};
      if (columnState?.length > 0) {
        // in order to prevent aggrid adding new columns to the begging of the table
        const columnDifferences = columnState.filter(x => storedColumnSettings.filter(y => y.colId == x.colId).length < 1) || [];
        storedColumnSettings = storedColumnSettings.filter(x => columnState.filter(y => y.colId == x.colId).length > 0).concat(columnDifferences); // remove old columns that don't exist anymore
        const storedSortModel = storedColumnSettings.map(cs => { return cs.sort ? { colId: cs.colId, sort: cs.sort } : null }).filter(cs => cs);

        // check whether sort model is not stored in localStorage, then apply default sort model
        if (storedSortModel.length < 1) {
          columnState.forEach(cs => {
            if (cs.sort) {
              defaultSortModelByColId[cs.colId] = cs.sort
            }
          });
          storedColumnSettings.forEach(cs => {
            cs.sort = defaultSortModelByColId[cs.colId] as any;
          });
        }
        columnApi.applyColumnState({state: storedColumnSettings });
      }

    } else if(hiddenColumnsByDefault){
      // if stored settings are not found in localStorage, then hide columns that are mentioned in hiddenColumnsByDefault
      if (columnState) {
        columnState.forEach( cs => {
          cs.hide = hiddenColumnsByDefault.includes(cs.colId);
        })
        columnApi.applyColumnState({state: storedColumnSettings })
      }
    }
  }

  getPresetLocalStorageKey(presetName: string, userId?: string) {
    if (presetName === CarePatientItem.AwvOpportunities) {
      return `columnState_v2_${userId || this.user._id}_${presetName}`;
    }
    return `columnState_${userId || this.user._id}_${presetName}`;
  }

  getColumnSettingsFromLocalStorage(presetName: string, versionKey: string, userId?: string): ColumnState[] {
    if (!userId && !this.user) {
      console.warn('AgGridLocalSettingsService getColumnSettingsFromLocalStorage: user isn\'t found', presetName)
      return null
    }
    const localStorageKey =  versionKey || this.getPresetLocalStorageKey(presetName,userId);
    return this.columnSettings[`${userId || this.user._id}_${presetName}`] || JSON.parse(localStorage.getItem(localStorageKey) || null)
  }
  getColumnSettingsFromLocalStorageWithCategory(category: string, presetName: string,  userId?: string): ColumnState[] {
    if (!userId && !this.user) {
      console.warn('AgGridLocalSettingsService getColumnSettingsFromLocalStorage: user isn\'t found', presetName)
      return null
    }

    category = category.replace(' ','_');
    presetName = presetName.replace(' ','_');
    return this.columnSettings[`${userId || this.user._id}_${presetName}`] || JSON.parse(localStorage.getItem(`columnState_${userId || this.user._id}_${category}-${presetName}`) || null)
  }
  removeColumnSettingsFromLocalStorage(filterName: string, versionKey: string, userId: string): void {
    delete this.columnSettings[`${userId}_${filterName}`];
    const localStorageKey = versionKey ||  this.getPresetLocalStorageKey(filterName,userId);
    localStorage.removeItem(localStorageKey);
  }
  // ======================= localStorage Filter Settings getters and setters =======================

  async getStoredFilterSettingsFromLocalStorage(categoryName: PresetCategory, filterName: string, userId: string, originPresetName: string): Promise<FilterSettings> {
    if (!userId || !this.user) {
      await this.storeUser()
    }


    // a possible bug happens if filterSettings file was updated, but local storage still has old values.
    // need to make sure that stored settings aren't outdated.
    // to do so we need to pull const filtersettings and stored filtersettings,
    // and then use const filtersettings as a primary list and pull excluded list from stored filtersettings
    let storedFilterSettings = JSON.parse(localStorage.getItem(`agGrid_${categoryName}_${filterName}_filter_settings_${userId || this.user._id}`) || null)

    let originalFilterSettings: any  = _.cloneDeep(this.filterSettings[originPresetName]) // for some reason _.cloneDeep returns type of rows: ({...}), instead of rows: {...}
    if (originalFilterSettings) {
      let storedFilterSettingsRowsByName = {}
      storedFilterSettings?.rows.forEach( row => {
        storedFilterSettingsRowsByName[row.name] = row
      })
      for(let r in originalFilterSettings.rows){
        if (storedFilterSettingsRowsByName[originalFilterSettings.rows[r].name]) {
          if (originalFilterSettings.rows[r].filterOptions) { // overriding stored filterOptions
            storedFilterSettingsRowsByName[originalFilterSettings.rows[r].name].filterOptions = originalFilterSettings.rows[r].filterOptions;
          };
          originalFilterSettings.rows[r] = { ...originalFilterSettings.rows[r], ...storedFilterSettingsRowsByName[originalFilterSettings.rows[r].name]} // add new params
        }
      }
      return originalFilterSettings
    }
    return storedFilterSettings
  }

  storeFilterSettingsInLocalStorage(categoryName: string, filterName: string, filterSettings: FilterSettings): void {
    localStorage.setItem(`agGrid_${categoryName}_${filterName}_filter_settings_${this.user?._id}`, JSON.stringify(filterSettings))
  }
  removeFilterSettingsFromLocalStorage(categoryName: string, filterName: string, userId: string): void {
    localStorage.removeItem(`agGrid_${categoryName}_${filterName}_filter_settings_${userId}`);
  }

  matchAndUpdateFilterSettings(filterSettings: FilterSettings, gridApi: GridApi): FilterSettings  {
    let foundDifferences: boolean = false;
    if (filterSettings && gridApi) {
      let filterModel = gridApi.getFilterModel()
      for(let f in filterSettings.rows){
        let filter = filterSettings.rows[f]
        if (filterModel && filterModel[filter.name]) {
          if(filter.type == 'set'){
            let filterClearedOptions = filter.options.filter(option => !filter.excluded.includes(option == 'None' ? null : option));
            for(let option in filterClearedOptions) {
              if(filterClearedOptions[option] === 'None') {
                filterClearedOptions[option] = null;
              }
            }
            if (!_.isEqual(filterModel[filter.name].values, filterClearedOptions)) {
              foundDifferences = true
              filter.excluded = filter.options.filter(option => !filterModel[filter.name].values?.includes(option == 'None' ? null : option))
            }
          }else{
            if(!_.isEqual(filterModel[filter.name], filter)){
              foundDifferences = true
              filterSettings.rows[f] = { ...filterSettings.rows[f], ...filterModel[filter.name]}
            }
          }
        } else if(filterModel && !filterModel[filter.name]) {
          if(filter.type == 'set'){
            filter.excluded = []
          } else {
            filter.filter = null
            filter.filterTo = null
            filter.type = null
          }
          foundDifferences = true
        }
      }
    }
    return foundDifferences ? filterSettings : null
  }

  // ======================= localStorage Filter Model getters and setters =======================
  async getStoredFilterModelFromLocalStorage(categoryName: string, filterName: string, userId?: string): Promise<{ [key: string]: any }> {
    if (!userId && !this.user) {
      await this.storeUser();
    }
    return JSON.parse(localStorage.getItem(`agGrid_${categoryName}_${filterName}_filter_model_${userId || this.user?._id}`) || null)
  }
  storeFilterModelInLocalStorage(categoryName: string, filterName: string, filterModel: { [key: string]: any }): void {
    localStorage.setItem(`agGrid_${categoryName}_${filterName}_filter_model_${this.user?._id}`, JSON.stringify(filterModel))
  }
  removeFilterModelFromLocalStorage(categoryName: string, filterName: string, userId: string): void {
    localStorage.removeItem(`agGrid_${categoryName}_${filterName}_filter_model_${userId}`);
  }
  // ======================= localStorage StoredPresets getters and setters =======================

  getStoredPresetsFromLocalStorage(categoryName: string, userId: string): string[] {
    return JSON.parse(localStorage.getItem(`agGrid_${categoryName}_storedPresetNames_${userId}`) || null)
  }
  getStoredPresetFromLocalStorage(categoryName: string, filterName: string, userId: string): IPatientFilterPreset {
    return JSON.parse(localStorage.getItem(`agGrid_${categoryName}_${filterName}_${userId}`) || null)
  }
  storeStoredPresetsInLocalStorage(categoryName: string, userId: string, presets: string[]): void {
    localStorage.setItem(`agGrid_${categoryName}_storedPresetNames_${userId}`, JSON.stringify(presets))
  }

  removeStoredPresetsFromLocalStorage(categoryName: string, userId: string): void {
    localStorage.removeItem(`agGrid_${categoryName}_storedPresetNames_${userId}`)
  }
  removeStoredPresetFromLocalStorage(categoryName: string, filterName: string, userId: string): void {
    localStorage.removeItem(`agGrid_${categoryName}_${filterName}_${userId}`);
  }
  async removeFilterFromStoredPresetsInLocalStorage(categoryName: string, filterName: string, userId: string) {
    let storedPresetNames: string[] = this.getStoredPresetsFromLocalStorage(categoryName, userId);
    if (storedPresetNames) {
      try {
        storedPresetNames = storedPresetNames.filter(p => p != filterName)
        storedPresetNames.length == 0 ? this.removeStoredPresetsFromLocalStorage(categoryName, userId) : this.storeStoredPresetsInLocalStorage(categoryName, userId, storedPresetNames)
      } catch (error) {
        console.warn('Can\'t remove preset', { categoryName, filterName, userId })
      }
    }
  }

  storeStoredPresetInLocalStorage(categoryName: string, filterName: string, userId: string, preset: IPatientFilterPreset): void {
    localStorage.setItem(`agGrid_${categoryName}_${filterName}_${userId}`, JSON.stringify(preset))
  }

  // =================================== misc functions ========================================
  getActiveColumnNames(columnApi: ColumnApi, exceptions: string[] = ['actions', 'select', 'IHEfileActions' , 'ssPercent']): string[] {
    return columnApi?.getColumnState().filter( column => !column.hide && !exceptions.includes(column.colId)).map( column => column.colId)

  }
  getCsvAdditionalColumns(csvAddition: { [key: string]: { additionalGridColumns?: ColDef[], additionalCsvColumns?: string[] } }): string[] {
    let result = [];
    for (let c in csvAddition) {
      result = [...result, ...csvAddition[c].additionalCsvColumns];
    }
    return result;
  }
}
