<div
  class="queue claim-validation border-left u-box-shadow--2"
  [ngClass]="{ 'is-validation-closed ': !isValidationPanelOpen }"
>
  <button
    class="pushTab top--xs disable-rotate"
    (click)="toggleValidatePanel()"
    [disabled]="!selectedPatientInfo"
    [ngClass]="{
      'is-validation-open': isValidationPanelOpen,
      'is-validation-disabled': !selectedPatientInfo
    }"
    aria-label="Toggle Validation Panel"
  >
    <coach-icon iconName="chevron-left"></coach-icon>
  </button>
  <div class="patientContent u-position--relative">
    <div class="patientGeneralInfoCard">
      
      <div class="patientInfo">
        <div class="patientName u-font-weight--normal u-font-size--small">
          {{ selectedPatientInfo.patientName }}
        </div>
        <div class="patientData">
          DOB: {{ formatDate(selectedPatientInfo.patientDob) }} | Sex:
          {{ getPatientSex() }}<br />
          MBI: {{ getMbi() }}<br />
          ZIP: {{ selectedPatientInfo.patientZip }}<br />
        </div>
      </div>
    </div>

    <div class="patientHeaderComponent">
      <button *hasFeatureFlag="importFeatureFlag" (click)="exportClaimFiles()" class="u-background-color--accentColor">
        <coach-icon iconName="download" class="icon--white"></coach-icon>
      </button>
    </div>

    <div class="claimStatus">
      Approved
      <span class="u-font-size--small"
        >by
        {{
          prescriptionDetails ? prescriptionDetails.claim_Status_Set_By : ""
        }},
        {{
          prescriptionDetails
            ? formatDate(prescriptionDetails.claim_Status_Set_On_Date)
            : ""
        }}</span
      >
    </div>

    <coach-tab-switch
      [isSummaryTabSelected]="isSummaryTabSelected"
      [isQuickFillTabSelected]="isQuickFillTabSelected"
      [isCareNoteSelected]="isCareNoteSelected"
      (onTabClicked)="setTabStatus($event)"
    ></coach-tab-switch>

    <div *ngIf="isSummaryTabSelected">
      <div class="prescriptionInfoCard u-background-color--accentColor">
        <div class="rxImage">
          <img src="assets/img/care/rx.svg" alt="Rx Image" />
        </div>
        <div class="prescriptionData">
          <div class="prescriptionName">
            {{ selectedPatientInfo.prescriptionName }}
          </div>
          RX # {{ selectedPatientInfo.rxNumber }}<br />
          NDC {{ selectedPatientInfo.ndc }}<br />

          <div class="inlineLabels">
            <span class="lineElements"
              >QTY
              {{
                selectedPatientInfo.prescriptionQuantity
                  ? (selectedPatientInfo.prescriptionQuantity + " ").padStart(
                      4,
                      "0"
                    )
                  : ""
              }}</span
            >
            <span class="lineElements"
              >DAYS SUPPLY {{ selectedPatientInfo.daysSupply }} <br
            /></span>
          </div>

          <br />
          {{
            getFillInfo(formatDate(selectedPatientInfo.firstFilled), stripLeadingZeros(selectedPatientInfo.clPrescriptionFillNumber))
          }}
        </div>
      </div>

      <div style="margin: 1.25em">
        <p>
          <span style="font-size: x-small; color: gray">PHARMACY</span><br />
          <span style="font-weight: bold">
            {{ selectedPatientInfo.orgName }}<br />
          </span>
          <span style="font-size: smaller;">
            <span style="color: gray;">NPI</span>: {{selectedPatientInfo.pharmacyNpi}}<br />
          </span>
          <span style="font-size: smaller">
            {{ selectedPatientInfo.pharmacyOtherAddress  }}<br />
            {{ formatPhone(selectedPatientInfo.pharmacyOtherPhone) }}<br />
          </span>
        </p>

        <span style="font-size: x-small; color: gray">PRESCRIBER</span><br />
        <span style="font-weight: bold">
          {{ selectedPatientInfo.prescriberName }} -
          {{ selectedPatientInfo.prescriberSpecialty }}<br />
        </span>
        <span style="font-size: smaller">
          <span style="color: gray">NPI:</span> {{ selectedPatientInfo.npi
          }}<br />
          {{ selectedPatientInfo.location }}<br />
          {{ formatPhone(selectedPatientInfo.prescriberPhone) }}<br /><br />
        </span>

        <span style="font-size: x-small; color: gray"
          >RELATED VISIT BY COVERED ENTITY</span
        ><br />
        <span style="font-weight: bold">
          {{ prescriptionDetails ? prescriptionDetails.cE_Provider : "" }}<br />
        </span>
        <span style="font-size: smaller">
          {{ prescriptionDetails ? prescriptionDetails.cE_Location : "" }}<br />
          <span style="color: gray">Visit Date:</span>
          {{
            prescriptionDetails
              ? formatDate(prescriptionDetails.cE_Related_Referral_Date)
              : ""
          }}<br />
        </span>
      </div>
    </div>

    <div class="dynamicBody padding" *ngIf="isQuickFillTabSelected">
      <p
        style="
          color: gray;
          font-style: italic;
          font-size: x-small;
          text-align: center;
        "
      >
        Use these buttons to copy the data to paste into your TPA
      </p>

      <div>
        <span style="color: gray; font-size: x-small; margin-left: 30px"
          >PATIENT</span
        ><br />
        <table style="margin: auto">
          <tr>
            <td>
              <button
                class="button tableButton nameAlignment"
                (click)="copyData(getPatientFirstName())"
              >
                <div>
                  First Name<br /><span class="grayItalic">{{
                    getPatientFirstName()
                  }}</span>
                </div>
              </button>
            </td>
            <td>
              <button
                class="button tableButton nameAlignment"
                (click)="copyData(getPatientLastName())"
              >
                <div>
                  Last Name<br /><span class="grayItalic">{{
                    getPatientLastName()
                  }}</span>
                </div>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button
                class="button tableButton"
                (click)="copyData(formatDate(selectedPatientInfo.patientDob))"
                style="padding-left: 35px"
              >
                <div>
                  DOB<br /><span class="grayItalic">{{
                    formatDate(selectedPatientInfo.patientDob)
                  }}</span>
                </div>
              </button>
            </td>
            <td>
              <button
                class="button tableButton"
                (click)="copyData(selectedPatientInfo.patientZip)"
                style="padding-left: 50px"
              >
                <div>
                  Zip<br /><span class="grayItalic">{{
                    selectedPatientInfo.patientZip
                  }}</span>
                </div>
              </button>
            </td>
          </tr>
        </table>
      </div>

      <div>
        <span style="color: gray; font-size: x-small; margin-left: 30px"
          >PRESCRIPTION</span
        ><br />
        <table style="margin: auto">
          <tr>
            <td>
              <button
                class="button tableButton"
                (click)="copyData(selectedPatientInfo.rxNumber)"
                style="padding-left: 40px"
              >
                <div>
                  Rx #<br /><span class="grayItalic">{{
                    selectedPatientInfo.rxNumber
                  }}</span>
                </div>
              </button>
            </td>
            <td>
              <button
                class="button tableButton"
                (click)="copyData(selectedPatientInfo.ndc)"
                style="padding-left: 20px"
              >
                <div>
                  NDC<br /><span class="grayItalic">{{
                    selectedPatientInfo.ndc
                  }}</span>
                </div>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button
                class="button tableButton"
                (click)="copyData(selectedPatientInfo.pharmacyNpi)"
                style="padding-left: 25px"
              >
                <div>
                  Pharmacy NPI<br /><span class="grayItalic">{{
                    selectedPatientInfo.pharmacyNpi
                  }}</span>
                </div>
              </button>
            </td>
            <td>
              <button
                class="button tableButton npiAlignment"
                (click)="
                  copyData(
                    selectedPatientInfo.npi
                      ? selectedPatientInfo.npi
                      : 'None'
                  )
                "
              >
                <div>
                  Provider NPI<br /><span class="grayItalic">{{
                    selectedPatientInfo.npi
                      ? selectedPatientInfo.npi
                      : "None"
                  }}</span>
                </div>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button
                class="button tableButton"
                (click)="copyData(selectedPatientInfo.prescriptionQuantity)"
                style="padding-left: 57px"
              >
                <div>
                  Qty<br /><span class="grayItalic">{{
                    selectedPatientInfo.prescriptionQuantity
                  }}</span>
                </div>
              </button>
            </td>
            <td>
              <button
                class="button tableButton nameAlignment"
                (click)="copyData(selectedPatientInfo.daysSupply)"
              >
                <div>
                  Days Supply<br /><span class="grayItalic">{{
                    selectedPatientInfo.daysSupply
                  }}</span>
                </div>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button
                class="button tableButton"
                (click)="copyData(stripLeadingZeros(selectedPatientInfo.clPrescriptionFillNumber))"
                style="padding-left: 55px;"
              >
                <div>
                  Fill #<br /><span class="grayItalic">{{
                    stripLeadingZeros(selectedPatientInfo.clPrescriptionFillNumber)
                  }}</span>
                </div>
              </button>
            </td>
          </tr>
        </table>
      </div>

      <div class="nameAlignment">
        <span style="color: gray; font-size: x-small"
          >RELATED VISIT BY COVERED ENTITY</span
        ><br />
        <table style="margin-right: auto">
          <tr>
            <td>
              <button
                class="button tableButton ceAlignment"
                (click)="
                  copyData(formatDate(prescriptionDetails.cE_Visit_Date))
                "
              >
                <div>
                  CE Visit<br /><span class="grayItalic">{{
                    formatDate(prescriptionDetails.cE_Visit_Date)
                  }}</span>
                </div>
              </button>
            </td>
          </tr>
        </table>
        <br />
      </div>
    </div>

    <div class="dynamicBody padding" #noteContent *ngIf="isCareNoteSelected">
      <strong><u>CARE COORDINATION NOTE</u></strong
      ><br />
      Patient Name: {{ patientName }}<br />
      DOB: {{ patientDobText }}<br />
      MBI: {{ patientMbi | mask: 6 }}<br />
      By: {{ prescriptionDetails.claim_Status_Set_By }}<br />
      Date: {{ formatDate(prescriptionDetails.claim_Status_Set_On_Date) }}<br /><br />
            
      <u>340B External Prescription</u><br />
      <strong>{{ selectedPatientInfo.prescriptionName }}</strong
      ><br />
      by {{ selectedPatientInfo.prescriberName }}, {{selectedPatientInfo.prescriberSpecialty}}<br />
      First filled {{ formatDate(selectedPatientInfo.firstFilled) }}<br /><br />

      <u>Visit Note from {{ selectedPatientInfo.prescriberName }}</u><br />

      <div>
        <p>
          Visit Date: {{ formatDate(prescriptionDetails.visit_Note_Visit_Date)
          }}<br />
          Rx Diagnosis: {{ prescriptionDetails.visit_Note_Diagnosis }}
        </p>
      </div>

      <div>
        <p style="text-decoration: underline; margin-bottom: 1px">
          Related Visit By Covered Entity:
        </p>
        <span>
          Visit Date: {{ formatDate(prescriptionDetails.cE_Visit_Date) }}<br />
          Provider: {{ prescriptionDetails.cE_Provider }}<br />
          CE Location: {{ prescriptionDetails.cE_Location }}<br />
        </span>
      </div>
      <br />

      <div>
        <p style="text-decoration: underline; margin-bottom: 1px">
          Related Referral By Covered Entity:
        </p>
        <span>
          Referral Date:
          {{ formatDate(prescriptionDetails.cE_Related_Referral_Date) }}<br />
        </span>
      </div>
      <br />

      <div>
        <p style="text-decoration: underline; margin-bottom: 1px">
          Follow-Up Plan:
        </p>
        <span [innerHTML]="prescriptionDetails.follow_Up_Plan"><br /> </span>
      </div>
      <br />
    </div>

    <div class="footer buttonContainer">
      <button
        class="button is-primary"
        (click)="selectedPatientInfo.status === 1005 ? undoProcessThisClaim() : processThisClaim()"
      >
        {{selectedPatientInfo.status === 1005 ? "Mark As Pending" : "Mark As Processed"}}
      </button>
      <button
        *ngIf="selectedPatientInfo.status === 1002"
        class="button is-primary"
        (click)="undoProcessThisClaim()"
      >
        Mark as Pending
      </button>
    </div>
  </div>
</div>
