/* tslint:disable */
/* eslint-disable */

/**
 * TODO
 */
export enum ActivityType {
  SchedulingActivity = 'SchedulingActivity',
  ReminderActivity = 'ReminderActivity',
  AttendedActivity = 'AttendedActivity'
}
