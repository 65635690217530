import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { PatientAlertService } from '@shared/services/patient-alert/patient-alert.service';
import {
  faPhone,
  faSms,
  faMinus,
  faUserMd,
  faCheckSquare,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { PatientService } from '@shared/services/patient/patient.service';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { defaultInputLength } from '@shared/modules/patient-facesheet/patient-facesheet.constants';
import moment from 'moment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
@Component({
  selector: 'patient-alerts',
  templateUrl: './patient-alerts.component.html',
  styleUrls: ['./patient-alerts.component.scss'],
})
export class PatientAlertsComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  selectedAlert: wpapi.model.Alert = null;
  faMinus = faMinus;
  faPhone = faPhone;
  faSms = faSms;
  faUserMd = faUserMd;
  faCheckSquare = faCheckSquare;
  faTimesCircle = faTimesCircle;
  copyToClipBoardAlert: any = null;
  actionsList = {
    phone: 'Phoned patient',
    message: 'Sent message to patient',
    handoff: 'Handed off to PA/MD',
    reviewed: 'Reviewed',
    ignore: 'Ignored',
  };
  mask = new MaskPipe();
  private currentUser: wpapi.model.Professional;
  commentMaxLength = defaultInputLength.comment;
  constructor(
    private PatientAlertService: PatientAlertService,
    private AuthService: AuthService,
    private auditService: AuditService,
    private patientService: PatientService
  ) {
    this.getCurrentUserInfo();
  }

  ngOnInit(): void {}
  ngOnChanges(): void {
    //(detail, compId, subComp, actionId, chPatId, tierId, orgId);
    if (this.patient)
      this.auditService.auditLog(
        'Patient Alerts',
        ComponentId.Events,
        SubComponentId.AlertManagement,
        ActionId.PHIAccess,
        this.patient.chPatId,
        this.tier,
        null
      );
  }
  private async getCurrentUserInfo() {
    this.currentUser = await this.AuthService.getWellpepperUserInfo();
  }
  selectAlert(alert: wpapi.model.Alert): void {
    if (this.selectedAlert !== alert) {
      this.selectedAlert = alert;
    }
  }
  deselectAlert(): void {
    this.selectedAlert = null;
  }
  async dismissAlert(alert: wpapi.model.Alert) {
    let updatedAlertConfirmation =
      await this.PatientAlertService.updatePatientAlert(
        this.tierId,
        this.patient.carePatient._id,
        alert
      );

    if (updatedAlertConfirmation) {
      this.deselectAlert();
      this.patient.alerts.unhandled = this.patient.alerts.unhandled.filter(
        (a) => a !== alert
      );
      alert.inactiveByFullName = `${
        this.currentUser.lastName && this.currentUser.firstName
          ? `${this.currentUser.lastName} ${this.currentUser.firstName}`
          : `${this.currentUser.email}`
      }`;
      alert.inactiveAt = new Date().toString();
      alert.isActive = false;
      console.log('alert =>', alert);
      this.patient.alerts.handled.push(alert);
      this.patient.alerts.handled.sort((a, b) => {
        if (moment(a.createdAt).isAfter(moment(b.createdAt))) return -1;
        else return 1;
      });
      //(detail, compId, subComp, actionId, chPatId, tierId, orgId);
      this.auditService.auditLog(
        'Patient Alert Dissimal',
        ComponentId.Events,
        SubComponentId.AlertManagement,
        ActionId.PHIAccess,
        this.patient.chPatId,
        this.tier,
        null
      );
    }
  }
  openModalCopyToClipBoard(alert: wpapi.model.Alert): void {
    this.copyToClipBoardAlert = alert;
  }
  copyToClipBoardText(): void {
    let result = `
    Name: ${this.patient.lastName} ${this.patient.firstName}
    DOB: ${this.patient.dob} \n
    ${
      this.patient.mrn ? `MRN: ${this.mask.transform(this.patient.mrn, 6)}` : ''
    }
    Alert: ${this.copyToClipBoardAlert.filterDesc}
    Date Reported: ${new Date(
      this.copyToClipBoardAlert.createdAt
    ).toLocaleString()}
    Description: ${this.copyToClipBoardAlert.desc}
    `;
    this.copyToClipBoardAlert.completion &&
    this.copyToClipBoardAlert.completion.systolic != null &&
    this.copyToClipBoardAlert.completion.diastolic != null
      ? (result += `Pressure: ${this.copyToClipBoardAlert.completion.systolic}/${this.copyToClipBoardAlert.completion.diastolic}\n`)
      : null;
    this.copyToClipBoardAlert.completion &&
    this.copyToClipBoardAlert.completion.bloodSugar != null
      ? (result += `Sugar: ${this.copyToClipBoardAlert.completion.bloodSugar} ${this.copyToClipBoardAlert.completion.bloodSugarUnits}\n`)
      : null;
    this.copyToClipBoardAlert.completion &&
    this.copyToClipBoardAlert.completion.weight != null
      ? (result += `Weight: ${this.copyToClipBoardAlert.completion.weight} ${this.copyToClipBoardAlert.completion.weightUnits}\n`)
      : null;
    !this.copyToClipBoardAlert.isActive &&
    this.copyToClipBoardAlert.inactiveByFullName
      ? (result += `Dismissed by: ${this.dismissedByNameForAlert(
          this.copyToClipBoardAlert
        )} \n`)
      : null;
    !this.copyToClipBoardAlert.isActive && this.copyToClipBoardAlert.inactiveAt
      ? (result += `Dismissed at: ${new Date(
          this.copyToClipBoardAlert.inactiveAt
        ).toLocaleString()} \n`)
      : null;
    !this.copyToClipBoardAlert.isActive &&
    this.actionsList[this.copyToClipBoardAlert.inactiveReason]
      ? (result += `Action done: ${
          this.actionsList[this.copyToClipBoardAlert.inactiveReason]
        } \n`)
      : null;
    this.copyToClipBoardAlert.inactiveReasonText &&
    this.copyToClipBoardAlert.inactiveReasonText.length > 0
      ? (result += `Comment: ${this.copyToClipBoardAlert.inactiveReasonText}\n`)
      : null;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = result;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  closeModalOnBackgroundClick(target: any): void {
    if (target.getAttribute('role') == 'modal-background') {
      this.copyToClipBoardAlert = null;
    }
  }

  dismissedByNameForAlert(alert: wpapi.model.Alert): string {
    const name = alert?.inactiveByFullName?.trim();
    if (name) {
      return name;
    }
    return '[no name found]';
  }
}
