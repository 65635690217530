/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HccDashboardCin } from '../models/hcc-dashboard-cin';
import { HccDashboardClinician } from '../models/hcc-dashboard-clinician';
import { HccDashboardClosureRate } from '../models/hcc-dashboard-closure-rate';
import { HccDashboardCodingOpportunity } from '../models/hcc-dashboard-coding-opportunity';
import { HccDashboardDiseaseGroupSummary } from '../models/hcc-dashboard-disease-group-summary';
import { HccDashboardMetric } from '../models/hcc-dashboard-metric';
import { HccDashboardPatient } from '../models/hcc-dashboard-patient';
import { HccDashboardPatientDetail } from '../models/hcc-dashboard-patient-detail';
import { HccDashboardPatientDetailV2 } from '../models/hcc-dashboard-patient-detail-v-2';
import { HccDashboardPractice } from '../models/hcc-dashboard-practice';
import { HccDashboardTierMetric } from '../models/hcc-dashboard-tier-metric';

@Injectable({
  providedIn: 'root',
})
export class HccDashboardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation hccDashboardOpportunitiesGet
   */
  static readonly HccDashboardOpportunitiesGetPath = '/HccDashboard/opportunities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardOpportunitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardOpportunitiesGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardCodingOpportunity>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardOpportunitiesGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardCodingOpportunity>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardOpportunitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardOpportunitiesGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardCodingOpportunity>> {

    return this.hccDashboardOpportunitiesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardCodingOpportunity>>) => r.body as Array<HccDashboardCodingOpportunity>)
    );
  }

  /**
   * Path part for operation hccDashboardMetricsGet
   */
  static readonly HccDashboardMetricsGetPath = '/HccDashboard/metrics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardMetricsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardMetricsGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardMetric>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardMetricsGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardMetric>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardMetricsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardMetricsGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardMetric>> {

    return this.hccDashboardMetricsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardMetric>>) => r.body as Array<HccDashboardMetric>)
    );
  }

  /**
   * Path part for operation hccDashboardTierMetricsGet
   */
  static readonly HccDashboardTierMetricsGetPath = '/HccDashboard/tierMetrics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardTierMetricsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardTierMetricsGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardTierMetric>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardTierMetricsGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardTierMetric>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardTierMetricsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardTierMetricsGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardTierMetric>> {

    return this.hccDashboardTierMetricsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardTierMetric>>) => r.body as Array<HccDashboardTierMetric>)
    );
  }

  /**
   * Path part for operation hccDashboardPatientSummaryGet
   */
  static readonly HccDashboardPatientSummaryGetPath = '/HccDashboard/patientSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardPatientSummaryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPatientSummaryGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
    tier1Id?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardPatient>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardPatientSummaryGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('tier1Id', params.tier1Id, {});
      rb.query('attributionStatus', params.attributionStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardPatient>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardPatientSummaryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPatientSummaryGet(params?: {
    tierNum?: number;
    tierId?: string;
    tier1Id?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardPatient>> {

    return this.hccDashboardPatientSummaryGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardPatient>>) => r.body as Array<HccDashboardPatient>)
    );
  }

  /**
   * Path part for operation hccDashboardPatientDetailsGet
   */
  static readonly HccDashboardPatientDetailsGetPath = '/HccDashboard/patientDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardPatientDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPatientDetailsGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
    tier1Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardPatientDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardPatientDetailsGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('tier1Id', params.tier1Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardPatientDetail>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardPatientDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPatientDetailsGet(params?: {
    tierNum?: number;
    tierId?: string;
    tier1Id?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardPatientDetail>> {

    return this.hccDashboardPatientDetailsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardPatientDetail>>) => r.body as Array<HccDashboardPatientDetail>)
    );
  }

  /**
   * Path part for operation hccDashboardPatientDetailsV2Get
   */
  static readonly HccDashboardPatientDetailsV2GetPath = '/HccDashboard/patientDetailsV2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardPatientDetailsV2Get()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPatientDetailsV2Get$Response(params?: {
    tierNum?: number;
    tierId?: string;
    tier1Id?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardPatientDetailV2>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardPatientDetailsV2GetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('tier1Id', params.tier1Id, {});
      rb.query('attributionStatus', params.attributionStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardPatientDetailV2>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardPatientDetailsV2Get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPatientDetailsV2Get(params?: {
    tierNum?: number;
    tierId?: string;
    tier1Id?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardPatientDetailV2>> {

    return this.hccDashboardPatientDetailsV2Get$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardPatientDetailV2>>) => r.body as Array<HccDashboardPatientDetailV2>)
    );
  }

  /**
   * Path part for operation hccDashboardCliniciansGet
   */
  static readonly HccDashboardCliniciansGetPath = '/HccDashboard/clinicians';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardCliniciansGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardCliniciansGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardClinician>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardCliniciansGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('attributionStatus', params.attributionStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardClinician>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardCliniciansGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardCliniciansGet(params?: {
    tierNum?: number;
    tierId?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardClinician>> {

    return this.hccDashboardCliniciansGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardClinician>>) => r.body as Array<HccDashboardClinician>)
    );
  }

  /**
   * Path part for operation hccDashboardPracticesGet
   */
  static readonly HccDashboardPracticesGetPath = '/HccDashboard/practices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardPracticesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPracticesGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardPractice>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardPracticesGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('attributionStatus', params.attributionStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardPractice>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardPracticesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardPracticesGet(params?: {
    tierNum?: number;
    tierId?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardPractice>> {

    return this.hccDashboardPracticesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardPractice>>) => r.body as Array<HccDashboardPractice>)
    );
  }

  /**
   * Path part for operation hccDashboardCinsGet
   */
  static readonly HccDashboardCinsGetPath = '/HccDashboard/cins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardCinsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardCinsGet$Response(params?: {
    cinId?: number;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardCin>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardCinsGetPath, 'get');
    if (params) {
      rb.query('cinId', params.cinId, {});
      rb.query('attributionStatus', params.attributionStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardCin>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardCinsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardCinsGet(params?: {
    cinId?: number;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardCin>> {

    return this.hccDashboardCinsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardCin>>) => r.body as Array<HccDashboardCin>)
    );
  }

  /**
   * Path part for operation hccDashboardDiseaseGroupsGet
   */
  static readonly HccDashboardDiseaseGroupsGetPath = '/HccDashboard/diseaseGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardDiseaseGroupsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardDiseaseGroupsGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccDashboardDiseaseGroupSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardDiseaseGroupsGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('attributionStatus', params.attributionStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccDashboardDiseaseGroupSummary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardDiseaseGroupsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardDiseaseGroupsGet(params?: {
    tierNum?: number;
    tierId?: string;
    attributionStatus?: string;
  },
  context?: HttpContext

): Observable<Array<HccDashboardDiseaseGroupSummary>> {

    return this.hccDashboardDiseaseGroupsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccDashboardDiseaseGroupSummary>>) => r.body as Array<HccDashboardDiseaseGroupSummary>)
    );
  }

  /**
   * Path part for operation hccDashboardClosureRatesGet
   */
  static readonly HccDashboardClosureRatesGetPath = '/HccDashboard/closureRates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hccDashboardClosureRatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardClosureRatesGet$Response(params?: {
    TierId?: string;
    TierNum?: number;
    AttributionStatus?: string;
    Tier1Id?: string;
    Tier2Id?: string;
    Tier3Id?: string;
    DatePart?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Array<HccDashboardClosureRate>>>> {

    const rb = new RequestBuilder(this.rootUrl, HccDashboardService.HccDashboardClosureRatesGetPath, 'get');
    if (params) {
      rb.query('TierId', params.TierId, {});
      rb.query('TierNum', params.TierNum, {});
      rb.query('AttributionStatus', params.AttributionStatus, {});
      rb.query('Tier1Id', params.Tier1Id, {});
      rb.query('Tier2Id', params.Tier2Id, {});
      rb.query('Tier3Id', params.Tier3Id, {});
      rb.query('DatePart', params.DatePart, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Array<HccDashboardClosureRate>>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hccDashboardClosureRatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hccDashboardClosureRatesGet(params?: {
    TierId?: string;
    TierNum?: number;
    AttributionStatus?: string;
    Tier1Id?: string;
    Tier2Id?: string;
    Tier3Id?: string;
    DatePart?: string;
  },
  context?: HttpContext

): Observable<Array<Array<HccDashboardClosureRate>>> {

    return this.hccDashboardClosureRatesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Array<HccDashboardClosureRate>>>) => r.body as Array<Array<HccDashboardClosureRate>>)
    );
  }

}
