import { Component, OnInit} from '@angular/core';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { CaravanFormAdminACO, CaravanFormAdminPracticeRespView} from '@shared/models/forms/form-admin-model';
import { FormSubmission } from '@shared/models/forms/forms-backend';
import { CaravanFormAdminSubmitPeriod  } from '@shared/models/forms/form-submissionID-model';
@Component({
  selector: 'coach-submit-promoting-interoperability-admin-report',
  templateUrl: './submit-promoting-interoperability-admin-report.component.html',
  host: {
    class: 'row u-fill',
  },
  styleUrls: ['../shared/submit-generic-admin-report.component.scss']
})
export class SubmitPromotingInteroperabilityAdminReportComponent implements OnInit {

public formsSubmissionsOverviewACO: CaravanFormAdminACO[];
public formsSubmissionsOverviewComm: CaravanFormAdminPracticeRespView[];
public formSubmissions: FormSubmission[];

public formSubmissionsPracticeResps: CaravanFormAdminPracticeRespView[];
public formID = '1';
public reportName = 'PI_Admin'
public TierID = 'ACO'

public isPracticeHidden: boolean = true;
public isICCHidden: boolean = true;
public isACOHidden: boolean = false;
public gridApi: any;
private icc: string;
private aco: string;

public selectedFormSubPeriod: number;

public caravanFormAdminSubmitPeriod: CaravanFormAdminSubmitPeriod[];
public submitPeriodParam: number;
public readonly PIFormId = 1;

constructor(
  private promotoingInteropService: SubmitFormsService
) {}

  public columnNamesACO = [{ headerName: 'ACO ID', field: 'acoID', cellRenderer: 'agGroupCellRenderer', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,} },
    { headerName: 'ACO Name', field: 'acoName', filter: true, minWidth: 300 },
    { headerName: 'Percentage Complete', field: 'acoPercentageComplete', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}  },
    { headerName: 'Last Update', field: 'acoLastUpdateTime', filter: true, minWidth: 200  },
    { headerName: 'View Communities', field: 'acoID',cellRenderer: 'clickableParentComponent',editable: false,minWidth: 150}
  ];

  public columnNamesComm = [{ headerName: 'ICC',  field: 'ICC', cellRenderer: 'agGroupCellRenderer', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,} },
    { headerName: 'Region',  field: 'regionID', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}   },
    { headerName: 'Community Name',  field: 'communityName', filter: true, filterParams: {applyMiniFilterWhileTyping: true,} , minWidth: 400   },
    { headerName: 'Percentage Complete',field: 'commPercentageComplete', filter: true, suppressSizeToFit: true},
    { headerName: 'Last Update', field: 'commLastUpdateTime', filter: true, minWidth: 200 },
    { headerName: 'View Practices', field: 'ICC', cellRenderer: 'clickableParentComponent', colId: 'params', editable: false,  minWidth: 150 }
    ];

  public practiceCellRendererParams = [ { headerName: 'ICC',  field: 'ICC',   filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}, maxWidth: 75  },
   {  headerName: 'Region',  field: 'regionID', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,} , maxWidth: 75   },
   {  headerName: 'Practice Name', field: 'tier4Name', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,},maxWidth: 380, minWidth: 320 },
   {  headerName: 'TIN (last 4)', field: 'tier4ID', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}, maxWidth: 135  },
   {  headerName: 'Has Entry', field: 'hasEntry', filter: true , suppressSizeToFit: true, maxWidth: 100 },
   {  headerName: 'Special Status', field: 'specialStatus', filter: true, suppressSizeToFit: true, minWidth: 125  },
   {  headerName: 'Total Points', field: 'totalPoints', filter: true , suppressSizeToFit: true  },
   {  headerName: 'Last Update', field: 'lastUpdateTime', filter: true , suppressSizeToFit: true  },
   {  headerName: 'Updated By', field: 'updatedByUsername', filter: true, suppressSizeToFit: true,  minWidth: 200   },
   {  headerName: 'User Form ID', field: 'UserFormID', filter: true, suppressSizeToFit: true },
  //  {  headerName: 'View Measures', field: 'formResponses', filter: true,  cellRenderer: 'clickableParentComponent', maxWidth: 150 }
  ];

  public formQuestionResponses = [{ headerName: 'Question ID', field: 'QuestionID', filter: true, maxWidth: 150  },
    {  headerName: 'Question Text', field: 'QuestionText', filter: true, minWidth: 350  },
    {  headerName: 'Point Value', field: 'PointValue', filter: true, maxWidth: 150   },
    {  headerName: 'Numerator', field: 'NumValue', filter: true, maxWidth: 150   },
    {  headerName: 'Denominator', field: 'DenValue', filter: true, maxWidth: 150   },
    {  headerName: 'Text Response', field: 'TextResponse', filter: true  }
  ];

  ngOnInit(): void {
    this.promotoingInteropService.getAdminReportSubmitPeriods(this.PIFormId).subscribe((data) => {
      this.caravanFormAdminSubmitPeriod = data;
      if (this.submitPeriodParam == null) {
        this.selectedFormSubPeriod = this.caravanFormAdminSubmitPeriod[0].caravanFormSubmitPeriodID;
      } else
      {
        this.selectedFormSubPeriod = this.submitPeriodParam;
      }

      this.refreshOverview();
    });
  }

  resultClickedICC(data): void{
    this.icc = data.cell;
    this.TierID = data.cell;
    this.isPracticeHidden = false;
    this.isICCHidden = true;
    this.refreshPractice();
  }

  refreshPractice(): void{
    const tierlevel = '4';
    const submissionID = this.selectedFormSubPeriod.toString();
    this.promotoingInteropService.getAdminReportPracticeResponseView(this.formID, this.icc, tierlevel, submissionID).subscribe((data) => {
      this.formSubmissionsPracticeResps = data;
    });
  }

  resultClickedACO(data): void{
    this.aco = data.cell;
    this.TierID = data.cell;
    this.isICCHidden = false;
    this.isACOHidden = true;
    this.refreshCommunity();
  }

  refreshCommunity(): void{
    const tierlevel = '2';
    const submissionID = this.selectedFormSubPeriod.toString();
    this.promotoingInteropService.getAdminReportPracticeResponseView(this.formID, this.aco, tierlevel, submissionID).subscribe((data) => {
      this.formsSubmissionsOverviewComm = data;
    });
  }

  returnToOverview(): void{
    this.TierID = 'ACO';
    this.isPracticeHidden = true;
    this.isICCHidden = true;
    this.isACOHidden = false;
    this.formSubmissionsPracticeResps= [];
    this.formsSubmissionsOverviewComm= [];
  }

  returnToCommunity(): void{
    this.TierID = this.aco;
    this.isPracticeHidden = true;
    this.isICCHidden = false;
    this.formSubmissionsPracticeResps= [];
  }

  refreshOverview(): void{
    const submissionID = this.selectedFormSubPeriod.toString();
    this.promotoingInteropService.getAdminReportResponseOverview(this.formID, submissionID).subscribe((data) => {
      this.formsSubmissionsOverviewACO = data;
    });
  }

  selectSubmissionPeriod(submissionPeriodID): void {
    this.selectedFormSubPeriod = submissionPeriodID.target.value;
    this.refreshOverview();

  }


}
