import _ from 'lodash';
import { isAfter, parseISO, subDays, toDate } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import {
  Component,
  OnInit,
  LOCALE_ID,
  Inject,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { AwvWorkflowStatusForPatientListDisplay, Patient, PatientViewModel } from '@shared/models/patient';
import {
  PatientService,
  ITrackApiPatient,
  IWPAPIPatient,
  ITrackApiIHEFilesListVisitInfo as ITrackApiIHEFilesListVisitInfo,
  ITrackApiIHEBillingListVisitInfo,
} from '@shared/services/patient/patient.service';
import {
  IPatientFilterPreset,
  ITrackAPIParams,
  IWPAPIParams,
} from '@shared/models/patient-filters';

import {
  DISEASE_COHORT_GRID_KEY,
  GridFilterService,
} from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { AwvWorklistService } from '@care/components/awv-worklist/awv-worklist.service';
import { formatDate } from '@angular/common';
import { DiseaseCohortGridComponent } from './disease-cohort-grid/disease-cohort-grid.component';
import { EdUtilizationGridComponent } from './ed-utilization-grid/ed-utilization-grid.component';
import { FacesheetScheduleComponent } from './../../components/facesheet-schedule/facesheet-schedule/facesheet-schedule.component';
import {
  CareFeatureConstants,
  CareOrchestrationConstants,
  CarePatientItem,
  ClinicalProgramPresetMap,
  PresetCategory,
} from '@shared/models/module-constants';
import { skip, takeUntil } from 'rxjs/operators';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { defaultInputLength } from '@shared/modules/patient-facesheet/patient-facesheet.constants';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import {
  AgGridLocalSettingsService,
  FilterSettings,
  AG_GRID_LOCALE_CARE,
  dateFormat,
} from '@shared/services/ag-grid/ag-grid.service';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { compareString, currencyCellRenderer, dodCellRenderer } from '@shared/components/grid/grid-functions';
import { Access } from '@shared/services/graphql/access.service';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { ErrorHandlerService } from '@shared/services/logging/error-handler.service';
import { HccFacesheetScheduleService } from '@care/components/facesheet-schedule/hcc-facesheet-schedule.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { CareComponentConstants } from '@shared/models/module-constants';
import { OrgService } from '@shared/services/org-service/org.service';
import {
  PatientEngagement,
  PatientEngagementComponentIds,
} from '@shared/enums';
import { UntypedFormControl } from '@angular/forms';
import { ClinicalProgram } from '@shared/models/module-constants';
import { SpacesToDashesPipe } from '@shared/pipes/spaces-to-dashes.pipe';
import { DiseaseCohortDefinition } from 'src/app/api/track/models/disease-cohort-definition';
import { getColumnDefinitions } from './columnDefinitions';
import { FilterDataService } from '@shared/services/filter-data.service';
import { ActivatedRouteService } from '@shared/services/activated-route/activated-route.service';
import { CarePatientsFiltersComponent } from './care-patients-filters/care-patients-filters.component';
import { PresetGroupService } from '@care/preset-group/preset-group.service';
import { Assignment } from '@shared/enums/assignment.enum';
import { IColumnOptions } from '@shared/models/forms/form-admin-model';
import { LinkClickedEventsService } from '@shared/services/mixpanel/events/link-clicked-events.service';
import { LoadingMsgEnum } from '@shared/enums/loadingMsg.enum';
import { ColDef, ColumnApi, ColumnState, FilterChangedEvent, GridApi, ProcessCellForExportParams } from 'ag-grid-community';

import 'ag-grid-enterprise';
import 'ag-grid-community';


/** Helper function
 * sortValue - comma delimited list of strings
 * @returns -1 if the first value sorts before the second  one
 *          0  if they are equal
 *          1  if the first value sorts after the second one
 * TODO - could use a bit more refactoring
 * TODO - support inverted compare
 */
export function compare(a: any, b: any, sortValue: string): -1 | 0 | 1 {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  let properties = sortValue.split(',');

  for (var i = 0; i < properties.length; i++) {
    let key = properties[i].trim();
    if (null == a && null == b) {
      continue;
    } else if (null == a) {
      return 1; // Sort nulls to the end
    } else if (null == b) {
      return -1;
    }

    let aString = a[key]?.toString()?.toLowerCase();
    let bString = b[key]?.toString()?.toLowerCase();
    const c = compareString(aString, bString);
    if (0 == c) {
      continue;
    }
    return c;
  }
  return 0;
}

@Component({
  selector: 'coach-care-patients',
  templateUrl: './care-patients.component.html',
  host: {
    class: 'page-content coach-care-patients',
  },
  providers: [MaskPipe],
})
export class CarePatientsComponent
  extends PersistentGridFilters
  implements OnInit
{
  public selectedPatient: PatientViewModel;
  public allPatients: PatientViewModel[];
  public readonly carePatientsFeature = CareFeatureConstants.PatientsFeature;
  public readonly facesheetUIUpdates =
    CareFeatureConstants.FacesheetUIUpdatesFlag;
  PatientEngagement = PatientEngagement;
  PatientEngagementComponentIds = PatientEngagementComponentIds;
  public readonly CareOrchAccessReqs = CareOrchestrationConstants.GridViewAccessRequirements;
  /** What's actually bound to the view */
  public patients: Array<PatientViewModel> = [];
  /** Indexed by page number */
  public allPatientsStore: Array<Array<PatientViewModel>>;
  pageNum: number = 0;
  isProcessingBool: boolean = false;
  public selectedPreset: IPatientFilterPreset;
  public resetFilter: boolean;
  selectedPatientId: string;
  forcePatientSideBar: { open: boolean } = { open: false };
  wpFirstBookmark: number = null;
  wpLastBookmark: number = null;
  trackFirstBookmark: number = null;
  trackLastBookmark: number = null;
  sortValue: string = 'lastName,firstName';
  alerts: any = [];
  filter: string = '';
  loadingStatus: boolean = true;
  newMessage = {
    senderType: 'user',
    senderId: null,
    messageText: '',
    pictureUrl: null,
    videoUrl: null,
    fileUrl: null,
  };
  public facesheetVersionModal: boolean = false;
  public facesheetVersionType: 'print' | 'export' | null = null;
  public facesheetVersionExpanded: boolean = false;
  public bulkInviteModal: boolean = false;
  public selectedPatients: any[];
  public activePatients: number = 0;
  messageBroadcastStatus: {
    messageBroadcastResult: string;
    messageBroadcastProcessStatusBool: boolean;
    progressPercentage: number;
  };
  public inviteList: any[];
  public buttonsStatus: {
    BulkInvite: boolean;
    BroadcastMessage: boolean;
    Export: boolean;
    Print: boolean;
  };
  public disableBroadcastButton = new BehaviorSubject<boolean>(true);
  public disableBulkInviteButton = new BehaviorSubject<boolean>(true);
  
  public filterModel: any;
  patientDataByChPatId: any;
  patientDataByCareId: { [key: string]: any };
  rowSelection = 'multiple';

  columnState: ColumnState[];
  gridApi: GridApi = null;
  columnApi: ColumnApi;
  secondaryColumnApi: ColumnApi;
  patientIds: any = {}; // to prevent duplicates to be displayed on the list
  public tier: IHierarchyTier;
  public orgId: string;
  fcButtonsStatus = {
    fcPdfLoading: false,
    fcPrintLoading: false,
  };

  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get tierNum() {
    return this?.tier?.tier;
  }

  readonly defaultColDef: ColDef = {
    resizable: true,
  };
  columnDefs: Array<ColDef> = [];
  ClinicalProgram = ClinicalProgram;

  clinicalProgramFormControl = new UntypedFormControl('');
  externalSelectedList: any[] = [];
  cancelLoadingBool: boolean = false;
  fileUploadingStatusOversizeBool: boolean = false;
  fileUploadingStatusBool: boolean = false;
  faPaperclip = faPaperclip;
  faPaperPlane = faPaperPlane;
  fileUploadObj: { size: number; name: string };
  messageMaxLength = defaultInputLength.message;
  userAccess: Access;
  columnsSettingsModal: boolean = false;
  externalColumnDefs: Array<ColDef>;
  diseaseCohortDefinitions: DiseaseCohortDefinition[] = null;
  filteredDiseaseCohortDefinitions: DiseaseCohortDefinition[] = null;
  filterAppliedBool: boolean = false;
  @ViewChild(DiseaseCohortGridComponent)
  DiseaseCohortGridComponent: DiseaseCohortGridComponent;
  @ViewChild(EdUtilizationGridComponent)
  EdUtilizationGridComponent: EdUtilizationGridComponent;
  @ViewChild(FacesheetScheduleComponent)
  FacesheetScheduleComponent: FacesheetScheduleComponent;
  @ViewChild(CarePatientsFiltersComponent, { static: true })
  carePatientsFiltersComponent: CarePatientsFiltersComponent;
  AG_GRID_LOCALE_CARE: { [key: string]: string } = AG_GRID_LOCALE_CARE;
  user: wpapi.model.Professional = null;
  private _refreshing: boolean = false;
  /** All care patients that have been loaded */
  private _loadedWpPatients: Array<IWPAPIPatient> = [];
  /** The index of the next patient to return */
  private _nextWpPatientIndex: number = 0;
  private _loadedAllWpPatients: boolean = false;
  private currentColumnDefs: ColDef[] = [];
  private datesCleared = false;
  CarePatientItem = CarePatientItem;
  PresetCategory = PresetCategory;
  AWVPatientsStatus: AwvWorkflowStatusForPatientListDisplay = null;
  AwvWorkflowStatusForPatientListDisplay = AwvWorkflowStatusForPatientListDisplay;
  public AWVOpportunitiesId =  CareComponentConstants.AwvPatientsWorklist;
  public loadingMsg = LoadingMsgEnum.Loading;
  columnOptions: IColumnOptions = {
      locale: this.locale,
      mask: this.mask,
      agGridCellRendererParams:  {
        getTierId: () => { return this.tierId; },
        getTierNum: () => { return this.tierNum; },
        getGridApi: () => { return this.gridApi; },
        updatePatientByIdInList: patient => { return this.updatePatientByIdInList(patient); }
      }
  };

  constructor(
    private _patientService: PatientService,
    private _orgService: OrgService,
    private _route: ActivatedRoute,
    private _router: Router,
    protected filterService: GridFilterService,
    private HierarchyTierService: HierarchyTierService,
    private awvWorklistService: AwvWorklistService,
    private route: ActivatedRoute,
    private auditService: AuditService,
    @Inject(LOCALE_ID) private locale: string,
    private userAccessService: UserAccessService,
    private mask: MaskPipe,
    private agGridLocalSettingsService: AgGridLocalSettingsService,
    private toastrMessageService: ToastrMessageService,
    public hccFacesheetScheduleService: HccFacesheetScheduleService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private errorHandlerService: ErrorHandlerService,
    private changeDetectorRef: ChangeDetectorRef,
    private spacesToDashes: SpacesToDashesPipe,
    private filterDataService: FilterDataService,
    private _routeService: ActivatedRouteService,
    private presetGroupService: PresetGroupService,
    private linkClickedEventsService: LinkClickedEventsService
  ) {
    super(filterService, DISEASE_COHORT_GRID_KEY);
    this.trackLastBookmark = 0;
    this.userAccessService.currentAccess$
      .pipe(takeUntil(this.killTrigger))
      .subscribe((access) => {
        this.userAccess = access;
      });
    HierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (tier) => {
        if (this.tier?.selectedTierId !== tier?.selectedTierId) {
          this.tier = tier;
          this.selectedPatientId = null;
          if (this.tier?.selectedTierId) {
            this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tier.selectedTierId);
            this.columnDefs = getColumnDefinitions(this.columnOptions);
          } else {
            this.orgId = null;
          }

          if(this.selectedPreset && !this._refreshing) {
            await this.refreshPatientsListAndApplyPreset();
            this.changeDetectorRef.detectChanges();
            await this.filterDataService.applyStoredFilterModel(this.selectedPreset, this.gridApi);
            await this.filterDataService.applyDefaultFilterModel(this.selectedPreset, this.gridApi);
            await this.filterDataService.applyFilterSettings(this.selectedPreset, this.selectedPreset?.filterSettings, this.gridApi, false);
            await this.matchAndUpdateFilterSettings();
            this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, this.gridApi, this.columnApi);
          }
        }
      });
    this.getUserInfo();
    this.columnDefs = getColumnDefinitions(this.columnOptions);

    this._routeService.currentRoute$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(() => {
        const requestedPreset = this._route.snapshot.queryParams[
          'filterPreset'
        ] as CarePatientItem;
        const requestedPresetFilterTitle = this._route.snapshot.queryParams[
          'filterTitle'
        ] as string;
        const newPreset: string = this._route.snapshot.queryParams[
          'newPreset'
        ];

        if(!requestedPreset && this.carePatientsFiltersComponent){
          const selectedPresetItem = this.presetGroupService.getPresetByName(CarePatientItem.AwvOpportunities);
          this.carePatientsFiltersComponent.onSelectPreset(selectedPresetItem);
          return;
        }
        let selectedPresetItem =
          this.presetGroupService.getPresetByFilterQueryParams(
            requestedPreset, requestedPresetFilterTitle
          );
        if(newPreset == 'true' && !selectedPresetItem){
          selectedPresetItem = this.presetGroupService.getTempPreset();
        }
        this.filterChange(selectedPresetItem);
      });
  }

  ngOnInit(): void {
    this._patientService.getDiseaseCohortData();
    this.awvWorklistService.loadItems$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async () => {
        this.loadingStatus = true;
        this.loadingMsg = LoadingMsgEnum.Loading;
        this.gridApi?.showLoadingOverlay();
        await this.resetPatientData();
        await this.getAwvPatients(this.selectedPreset?.name);
        this.changeDetectorRef.detectChanges();
        let storedFilterModel = await this.agGridLocalSettingsService.getStoredFilterModelFromLocalStorage(PresetCategory.AnnualWellnessVisits, this.selectedPreset?.presetKey);
        if(!storedFilterModel) {
          storedFilterModel = { ...this.selectedPreset.defaultFilterModel};
        }
        this.gridApi.setFilterModel(storedFilterModel);
        await this.filterDataService.applyFilterSettings(this.selectedPreset, this.selectedPreset?.filterSettings, this.gridApi, true);
        await this.matchAndUpdateFilterSettings();
        this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, this.gridApi, this.columnApi);

        this.loadingStatus = false;
        this.gridApi?.hideOverlay();
        if(!this.patients?.length) {
          this.loadingMsg = LoadingMsgEnum.NoData;
          this.changeDetectorRef.detectChanges();
          this.gridApi?.showNoRowsOverlay();
        }
      });
  }
  ngOnChanges(changes): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

  // ================================= GRID LISTENERS =================================
  onGridReady(param: any): void {
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
    this.columnDefs = getColumnDefinitions(this.columnOptions);
    this.setLocalColumnSettings();
  }

  public onFirstDataRendered(params: any): void {
    params.api.sizeColumnsToFit();
    this.gridApi.onFilterChanged();
    if (this.patients?.length === 0) {
      this.loadingMsg = LoadingMsgEnum.NoData;
      params.api.showNoRowsOverlay();
    }
  }

  async agFilterChanged(event: FilterChangedEvent) {
    this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, this.gridApi, this.columnApi);

    if (event?.afterDataChange != null && !this.selectedPreset.temporary && this.patients?.length) {
      await this.filterDataService.storeFilterModelAndMatchFilterSettings(this.selectedPreset, this.gridApi);
      // check here if new filter model conflicts with filterSettings
      await this.matchAndUpdateFilterSettings(true);
    }

    if (this.loadingStatus == false){
      const displayedRowCount = this.gridApi?.getDisplayedRowCount();
      if (displayedRowCount === 0) {
        this.loadingMsg = LoadingMsgEnum.NoData;
        this.changeDetectorRef.detectChanges();
        this.gridApi?.showNoRowsOverlay();
      } else {
        this.gridApi?.hideOverlay();
      }
    }
  }

  async onRowDataChanged(event) {
    return; // temporary disable it. Probably no need in this function, need to see how it works
    // if (this.selectedPreset?.filterSettings && this.patients?.length) {
    //   this.filterAppliedBool = false;
    //   await this.filterDataService.applyFilterSettings(this.selectedPreset, this.selectedPreset.filterSettings, this.gridApi, true);
    //   await this.matchAndUpdateFilterSettings();
    //   this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, this.gridApi);
    // }
  }

  // ================================= PATIENTS LISTS =================================

  public resetPatientData() {
    //empty all lists before doing a new pull
    this._nextWpPatientIndex = 0;

    this.pageNum = 0;
    this.wpFirstBookmark = 0;
    this.wpLastBookmark = 0;
    this.trackFirstBookmark = 0;
    this.trackLastBookmark = 0;

    this.allPatients = [];
    this.patients = [];
    this.allPatientsStore = [];
  }
  async refreshPatientsListAndApplyPreset() {
    if (this._refreshing) {
      return;
    }
    this._refreshing = true;
    this.resetPatientData();
    if (this.selectedPreset) {
      await this.getCorrespondingPatients();
    }
    this._refreshing = false;
  }

  /**
   * @returns index - the index of the patient returned
   *          patient - a patient from the care api
   */
  async getNextWpPatient(): Promise<{ index: number; patient: IWPAPIPatient }> {
    if (!this._loadedAllWpPatients) {
      let wpPatients = await this._patientService.getWellpepperPatients(
        this.tierId,
        this.sortValue,
        this.filter
      );
      this._loadedWpPatients = wpPatients;
      this._loadedAllWpPatients = true;
    }
    if (this._nextWpPatientIndex >= this._loadedWpPatients.length) {
      return null;
    }
    let res = {
      index: this._nextWpPatientIndex,
      patient: this._loadedWpPatients[this._nextWpPatientIndex],
    };
    this._nextWpPatientIndex++;
    return res;
  }
  private isAlertsOrMessagesFilter(): boolean {
    return (
      this.filter?.search('alerts') == 0 || this.filter?.search('messages') == 0
    );
  }


  async getCorrespondingPatients() {
    if (!this.diseaseCohortDefinitions) {
      this.diseaseCohortDefinitions = await this._patientService.getDiseaseCohortData();
      this.filteredDiseaseCohortDefinitions = (this.diseaseCohortDefinitions || []).filter(r => r.isVisible === 1);
    }
    if(this.selectedPreset?.label === 'Care Management'){
      const name = this.spacesToDashes.transform(this.selectedPreset?.name || '');
      let cohortId =null;
      if(name.toLowerCase().includes('copd')){
       cohortId = this.filteredDiseaseCohortDefinitions.find(r=> r.cohortName === ClinicalProgram.COPD);

      } else if(name.toLowerCase().includes('hypertension')){
        cohortId = this.filteredDiseaseCohortDefinitions.find(r=> r.cohortName === ClinicalProgram.Hypertension);

      } else if(name.toLowerCase().includes('heartfailure')){
        cohortId = this.filteredDiseaseCohortDefinitions.find(r=> r.cohortName === ClinicalProgram.HeartFailure);
      }
      this.clinicalProgramFormControl.setValue(cohortId?.cohortID || '', { emitEvent : false});
    }
    switch (this.selectedPreset?.name) {
      case CarePatientItem.AwvOpportunities:
        await this.getAwvPatients(this.selectedPreset?.name);
        break;

      case CarePatientItem.EdUtilization:
        await this.getEdUtilizationPatients(this.selectedPreset?.name);
        break;
      case CarePatientItem.Chf:
      case CarePatientItem.ClinicalProgramHeartFailure:
      case CarePatientItem.ClinicalProgramCOPD:
      case CarePatientItem.Diabetes:
      case CarePatientItem.ClinicalProgramHypertension:
        await this.getCohortPatients(
          this.selectedPreset?.name,
          this.selectedPreset?.diseaseCohortNameFromTrackApi
        );
        break;
      case CarePatientItem.HccTop50:
        await this.getHccVipCohortPatients(this.selectedPreset?.name);

        break;
      case CarePatientItem.CareManagementAllProgram:
      case CarePatientItem.CareManagementCopd:
      case CarePatientItem.CareManagementHeartFailure:
      case CarePatientItem.CareManagementHypertension:
        await this.getCareManagementCohortPatients(this.selectedPreset?.name);
        break;
      case CarePatientItem.AwvSchedule:
        await this.getIheAwvSchedulePatients(this.selectedPreset?.name);
        break;
      case CarePatientItem.FacesheetSchedule:
        if (
          !this.patientDataByChPatId ||
          Object.keys(this.patientDataByChPatId).length < 1
        ) {
          await this.getCarePatientData();
        }
        break;
      case CarePatientItem.AwvFiles:
        await this.getIHEPatients(this.selectedPreset?.name);
        break;
      case CarePatientItem.AwvBilling:
        await this.getIHEBillingPatients(this.selectedPreset?.name);
        break;
      default:
        // All patients
        await this.getPatients(this.selectedPreset.apiParams, this.selectedPreset?.name);
        break;
    }
    this.changeDetectorRef.detectChanges();
    let page = this._route.snapshot.queryParams['page'];
    if (!isNaN(page)) {
      this.gridApi.paginationGoToPage(Number(page));
    }
  }

  async getIHEPatients(presetName: string) {
    this.cancelLoadingBool = false;
    let ihePatients: ITrackApiIHEFilesListVisitInfo[] =
      await this._patientService.getTrackIHEListPatients(
        this.tierNum,
        this.tierId
      );
    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }
    this.patients = ihePatients?.map(v => PatientViewModel.fromIheVisit(v)) || [];
    this.filterDataService.updateFilterSettings(this.selectedPreset, this.patients, this.gridApi);
  }

  async getIHEBillingPatients(presetName: string) {
    this.cancelLoadingBool = false;
    let ihePatients: ITrackApiIHEBillingListVisitInfo[] =
      await this._patientService.getTrackIHEBillingListPatients(
        this.tierNum,
        this.tierId
      );
    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }
    let patients =
      ihePatients?.map((v) => PatientViewModel.fromIheBilling(v)) || [];
    patients.forEach((patient: any, index: number) => {
      patients[index].diseaseCohorts =
        this._patientService.convertDiseaseCohortIdToName(
          patient.diseaseCohortIds
        );
    });
    this.patients = patients;
    this.filterDataService.updateFilterSettings(this.selectedPreset, this.patients, this.gridApi);
  }

  async getPatients(apiParams: {
    track?: ITrackAPIParams;
    wpapi?: IWPAPIParams;
  }, presetName: string) {
    this.cancelLoadingBool = false;
    if (!this.orgId) {
      this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tierId);
    }

    if (
      !this.patientDataByCareId ||
      Object.keys(this.patientDataByCareId).length < 1
    ) {
      await this.getCarePatientData();
    }

    const [carePatients, trackPatients] = await Promise.all([
      this._patientService.getWellpepperPatients(
        this.tierId,
        this.sortValue,
        this.filter,
        apiParams?.wpapi
      ),
      this._patientService.getTrackPatients(
        this.tierNum,
        this.tierId,
        apiParams?.track
      ),
    ]);

    const carePatientsById: { [chpatid: string]: IWPAPIPatient } = {};
    carePatients.forEach((p) => {
      const chPatId = Patient.getChPatIdAndTierIdFromCarePatient(p)?.chPatId;
      chPatId
        ? (carePatientsById[chPatId] = p)
        : console.warn(`getPatients - ${p._id} could not get chPatId`);
    });
    const trackPatientsById: { [chPatId: string]: ITrackApiPatient } = {};
    trackPatients.results.forEach((p) => {
      const chPatId = p.chPatId;
      trackPatientsById[chPatId] = p;
    });

    let patients: Array<PatientViewModel>;
    if (this.isAlertsOrMessagesFilter()) {
      patients = [];
      for (let i = 0; i < carePatients.length; i++) {
        const cp = carePatients[i];
        const chPatId = Patient.getChPatIdAndTierIdFromCarePatient(cp)?.chPatId;
        const tPat = trackPatientsById[chPatId];
        const riskProbability = Number(tPat?.probability?.toFixed(2));
        if (tPat) {
          tPat.diseaseCohorts =
            this._patientService.convertDiseaseCohortIdToName(
              tPat.diseaseCohortIDs
            );
        }
        const patient = PatientViewModel.fromTrackAndCare(
          tPat,
          cp,
          this.tier,
          // TODO - these seem redundant
          tPat?.assignment,
          tPat?.currentRiskLevel,
          tPat?.predictedRiskLevel,
          riskProbability,
          tPat?.riskTrend
        );
        if (cp?.weight) {
          const comps = await this._patientService.getCompletionActivity(
            this.tierId,
            patient
          );
          const alertComp = patient?.carePatient?.alerts?.[0];
          if (comps && alertComp) {
            const alertDate = toDate(parseISO(alertComp.reportingDate));
            const alert3lbBound = subDays(alertDate, 2);
            const lastDaysComps = comps.filter((c) => {
              return isAfter(alert3lbBound, toDate(parseISO(c.reportingDate)));
            });
            const lowestWeight = _.min(lastDaysComps.map((c) => c.weight));
            patient.gain =
              lowestWeight <= patient.weight - 3
                ? '5+ since last'
                : '3+ in a day';
          }
        }
        patients.push(patient);
      }
    } else {
      // CC-1842 - for now, only display patients that are BOTH
      // track and care patients.
      patients = trackPatients.results.map((tp) => {
        let cp = carePatientsById[tp.chPatId];
        let cpExtra = cp && this.patientDataByCareId[cp._id];
        tp.diseaseCohorts = this._patientService.convertDiseaseCohortIdToName(
          tp.diseaseCohortIDs
        );
        return PatientViewModel.fromTrackAndCare(
          tp,
          cp,
          this.tier
        );
      });
    }

    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }

    this.allPatientsStore.push(patients);
    this.patients = [].concat(...this.allPatientsStore);
    if (this.selectedPreset.patientsDataExtender) {
      this.patients = await this.selectedPreset.patientsDataExtender(
        this.patients,
        this.orgId
      );
    }
    if (this.selectedPreset.requiredPatientFields) {
      await this.filterPatients(this.selectedPreset.requiredPatientFields);
    }
    this.filterDataService.updateFilterSettings(this.selectedPreset, this.patients, this.gridApi);
  }
  async getAwvPatients(presetName: string) {
    const isCareOrchFeatureFlagAndComponents = this.hasCareOrchFeatureFlagAndComponents();

    if (!this.orgId) {
      this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tierId);
    }
    this.cancelLoadingBool = false;
    if (
      !this.patientDataByChPatId ||
      Object.keys(this.patientDataByChPatId).length < 1
    ) {
      await this.getCarePatientData();
    }
    let patients: Array<PatientViewModel> = [];

    const fromDate =
      this.awvWorklistService.fromDate?.value === '' ||
      this.awvWorklistService.fromDate?.value === null
        ? new Date('1901-01-01')
        : new Date(this.awvWorklistService.fromDate.value);
    const toDate =
      this.awvWorklistService.toDate?.value == null
        ? null
        : new Date(this.awvWorklistService.toDate.value);

    let AwvPatientsWorklist: boolean = this.userAccessService.hasComponent(
      this.userAccess,
      CareComponentConstants.AwvPatientsWorklist
    )
    let trackAwvPatients = await this._patientService.getTrackAwvPatients(
      this.tierNum,
      this.tierId,
      fromDate,
      toDate,
      null,
      AwvPatientsWorklist ? CareComponentConstants.AwvPatientsWorklist.toString() : null
    );
    if (!trackAwvPatients) {
      trackAwvPatients = [];
    }
    // console.warn(`getAwvPatients: ${trackAwvPatients.length} track patients`);
    // console.warn(`getAwvPatients: this.AWVPatientsStatus is "${this.AWVPatientsStatus}"`);
    trackAwvPatients.forEach((p) => {
      p.diseaseCohorts = this._patientService.convertDiseaseCohortIdToName(
        p.diseaseCohortIDs
      );
      let patient = PatientViewModel.fromAwvAndCare(
        p,
        null,
        this.tier,
        this.patientDataByChPatId[p.chPatID]
      );

      if (isCareOrchFeatureFlagAndComponents && this.AWVPatientsStatus) { // filtering on awv buttons
          const status = this.getAwvPatientsStatusFromQueryParams(patient.allAWVPatientsListStatus);
          if(!this.AWVPatientsStatus.includes(status)){
            return;
          }
      }

      patients.push(patient);
    });
    // Getting ALL patients
    console.warn('total AWV PAtients:', patients.length);
    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }
    this.patients = patients.sort(this.fieldSorter(this.sortValue.split(',')));
  }

  private hasCareOrchFeatureFlagAndComponents(): boolean {
    return this.featureFlagService.hasFeatureFlag(this.CareOrchAccessReqs.featureFlagId) &&
      this.CareOrchAccessReqs.componentIds.every(id =>
        this.userAccessService.hasComponent(this.userAccessService.currentAccess$.value, id));
  }

  async getCohortPatients(cohortName: string, cohortSecondName?: string) {
    if (!this.orgId) {
      this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tierId);
    }
    this.cancelLoadingBool = false;
    if (
      !this.patientDataByChPatId ||
      Object.keys(this.patientDataByChPatId).length < 1
    ) {
      await this.getCarePatientData();
    }
    let patients: Array<PatientViewModel> = [];
    let cohortId = this.diseaseCohortDefinitions.filter(
      (dc) =>
        dc.cohortName == cohortName ||
        (cohortSecondName ? dc.cohortName == cohortSecondName : false)
    )[0]?.cohortID;
    let cohortPatients = await this._patientService.getTrackCohortPatients(
      this.tierNum,
      this.tierId,
      cohortId
    );

    try {
      cohortPatients.results.forEach((p) => {
        p.diseaseCohorts = this._patientService.convertDiseaseCohortIdToName(
          p.diseaseCohortIDs
        );
        patients.push(
          PatientViewModel.fromCohortPatient(
            p,
            this.tier,
            this.patientDataByChPatId[p.chPatID]
          )
        );
      });
    } catch (error) {
      console.warn(`ERROR -> ${error}`);
    }
    if (this.cancelLoadingBool || cohortName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }
    this.patients = patients;
  }

  onClinicalProgramChange(event: any) {
    let selectedPresetItem: IPatientFilterPreset = null;
    let selectedCohort = null;
    if (!this.clinicalProgramFormControl.value) {
      selectedPresetItem =
        this.presetGroupService.getPresetByName(
          CarePatientItem.CareManagementAllProgram
        );
    } else {
      selectedCohort = this.filteredDiseaseCohortDefinitions.find(
        (r) => r.cohortID === this.clinicalProgramFormControl.value
      );
      const selectedItem = ClinicalProgramPresetMap.get(
        selectedCohort.cohortName
      );
      selectedPresetItem =
        this.presetGroupService.getPresetByName(selectedItem);
    }

    // Send preset click event to MixPanel
    this.linkClickedEventsService.sendLinkClickedEvent({
      linkId: (event.target as HTMLElement).id,
      sourceSection: 'Care Management',
      sourceSubSection: 'Clinical Program',
      linkText: selectedCohort?.cohortName ?? 'All Programs',
      targetPage: '/care/patients',
      filterPreset: selectedPresetItem.name
    });

    this.carePatientsFiltersComponent.selectPreset(selectedPresetItem);
  }

  async getCareManagementCohortPatients(presetName: string) {
    if (!this.orgId) {
      this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tierId);
    }
    this.cancelLoadingBool = false;
    this.loadingStatus = true;
    this.loadingMsg = LoadingMsgEnum.Loading;
    this.gridApi?.showLoadingOverlay();

    if (
      !this.patientDataByChPatId ||
      Object.keys(this.patientDataByChPatId).length < 1
    ) {
      await this.getCarePatientData();
    }
    let patients: Array<PatientViewModel> = [];
    const cohortId = this.clinicalProgramFormControl.value || '';
    let cohortPatients = await this._patientService.getCareManagementCohort({
      ...{
        TierNum: this.tierNum.toString(),
        TierId: this.tierId,
        CohortId: cohortId,
      },
      ...this.selectedPreset.apiParams?.track,
    });
    try {
      cohortPatients?.results.forEach((patient) => {
        let carePatientInfo = this.patientDataByChPatId[patient.chPatId];
        patient.diseaseCohorts =
          this._patientService.convertDiseaseCohortIdToName(
            patient.chronicConditionList?.split(',')
          );
        patients.push(
          PatientViewModel.fromCareManagementPatient(patient, carePatientInfo)
        );
      });
    } catch (error) {
      this.toastrMessageService.error(error);
      this.errorHandlerService.handleError(error);
    }
    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }

    // 1. check whether there are any percentage filterSettings
    // 1. find columns that are needed to be recalculated
    // 3. for each patient calculate min and max values for ssDenum
    // 4. for each patient update target field to percentage of max/min by formula => 100 - (oldValue/(max - min)) * 100
    if (
      this.selectedPreset.filterSettings?.rows?.find((row) => row.percentage)
    ) {
      // step 1
      let columnsMinMax: { [key: string]: { min: number; max: number } } = {};
      this.selectedPreset.filterSettings.rows.forEach((row) => {
        // step 2
        if (row.percentage) {
          columnsMinMax[row.name] = {
            min: Number.MAX_VALUE,
            max: Number.MIN_VALUE,
          };
        }
      });
      patients.forEach((patient) => {
        // step 3
        for (let key in columnsMinMax) {
          if (patient[key]) {
            if (columnsMinMax[key].max < patient[key]) {
              columnsMinMax[key].max = patient[key];
            } else if (columnsMinMax[key].min > patient[key]) {
              columnsMinMax[key].min = patient[key];
            }
          }
        }
      });
      patients.forEach((patient) => {
        // step 3
        for (let key in columnsMinMax) {
          if (patient[key]) {
            // patient[key] = 100 - (patient[key] / (columnsMinMax[key].max - columnsMinMax[key].min)) * 100;
          }
        }
      });
    }
    //
    this.patients = patients;
    this.loadingStatus = false;

    if (this.patients?.length > 0) {
      this.gridApi?.hideOverlay();
    } else {
      this.loadingMsg = LoadingMsgEnum.NoData;
      this.changeDetectorRef.detectChanges();
      this.gridApi?.showNoRowsOverlay();
    }
    this.filterDataService.updateFilterSettings(this.selectedPreset, this.patients, this.gridApi);
  }
  async getHccVipCohortPatients(presetName: string) {
    if (!this.orgId) {
      this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tierId);
    }
    this.cancelLoadingBool = false;
    if (
      !this.patientDataByChPatId ||
      Object.keys(this.patientDataByChPatId).length < 1
    ) {
      await this.getCarePatientData();
    }
    let patients: Array<PatientViewModel> = [];
    let cohortPatients = await this._patientService.getTrackHccVipCohort({
      ...{ TierNum: this.tierNum.toString(), TierId: this.tierId },
      ...this.selectedPreset.apiParams?.track,
    });
    try {
      cohortPatients?.forEach((patient) => {
        patient.diseaseCohorts =
          this._patientService.convertDiseaseCohortIdToName(
            patient.diseaseCohortIDs
          );
        let carePatientInfo = this.patientDataByChPatId[patient.chPatId];
        patients.push(
          PatientViewModel.fromTrackAndCare(
            patient,
            carePatientInfo,
            this.tier
          )
        );
      });
    } catch (error) {
      this.toastrMessageService.error(error);
      this.errorHandlerService.handleError(error);
    }
    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }
    this.patients = patients;
  }
  async getIheAwvSchedulePatients(presetName: string) {
    this.cancelLoadingBool = false;
    this.patients = [];
    let ihePatients =
      await this._patientService.getTrackIHEAWVScheduleListPatients(
        this.tierNum,
        this.tierId
      );
    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }
    this.patients = ihePatients.map((patient) => {
      patient.diseaseCohorts =
        this._patientService.convertDiseaseCohortIdToName(
          patient.diseaseCohortIds?.split(',').map((x) => parseInt(x))
        );
      return PatientViewModel.fromIHEAWVSchedule(patient);
    });
    this.filterDataService.updateFilterSettings(this.selectedPreset, this.patients, this.gridApi);
  }
  async getEdUtilizationPatients(presetName: string) {
    if (!this.orgId) {
      this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tierId);
    }
    this.cancelLoadingBool = false;
    if (
      !this.patientDataByChPatId ||
      Object.keys(this.patientDataByChPatId).length
    ) {
      await this.getCarePatientData();
    }
    let patients: Array<PatientViewModel> = [];
    let edPatients = await this._patientService.getTrackEDUtilizationPatients(
      this.tierNum,
      this.tierId
    );
    try {
      edPatients.forEach((p) => {
        p.diseaseCohorts = this._patientService.convertDiseaseCohortIdToName(
          p.diseaseCohortIDs
        );
        patients.push(
          PatientViewModel.fromEdUtilizationPatient(
            p,
            this.tier,
            this.patientDataByChPatId[p.chPatID]
          )
        );
      });
    } catch (error) {
      console.warn(`Error -> ${error}`);
    }
    if (this.cancelLoadingBool || presetName != this.selectedPreset?.name) {
      console.warn("Cancel button was clicked. Stopping the function.")
      return;
    }
    this.patients = patients;
  }

  async getCarePatientData() {
    if (!this.tierId) return;
    let orgId = await this._patientService.getWpOrgIdFromTierId(this.tierId);
    let patients =
      (orgId && (await this._patientService.getCarePatientData(orgId))) || {};
    this.patientDataByCareId = patients;
    this.patientDataByChPatId = {};

    for (let p in patients) {
      let patientData = patients[p] as any;
      let id = Patient.getChPatIdAndTierIdFromCarePatient(patientData)?.chPatId;
      if (!id) continue;
      this.patientDataByChPatId[id] = {
        lastLogin: patientData.lastLogin,
        lastActivityAt: patientData.lastActivityAt,
        alertsCount: patientData.alertsCount,
        careId: p,
      };
    }
  }
  public getSelectedPatients(): any[] {
    return !this.selectedPresetIsChronicConditions &&
      this.selectedPreset?.name !== CarePatientItem.EdUtilization &&
      this.selectedPreset?.name !== CarePatientItem.FacesheetSchedule
      ? this.getSelectedRows() || []
      : this.externalSelectedList.length > 0
      ? this.externalSelectedList
      : [];
  }
  getSelectedRows(): PatientViewModel[] {
    let patients: PatientViewModel[] = [];
    this.gridApi?.forEachNodeAfterFilter((patient) => {
      if (patient.isSelected()) {
        patients.push(patient.data);
      }
    });
    return patients;
  }

  get selectedClinicalProgramName() {
    if (this.clinicalProgramFormControl.value === '') {
      return ClinicalProgram.AllProgram;
    }
    if (
      this.filteredDiseaseCohortDefinitions &&
      this.clinicalProgramFormControl.value
    ) {
      const clinicalProgram = this.filteredDiseaseCohortDefinitions.find(
        (r) => r.cohortID === this.clinicalProgramFormControl.value
      );
      return clinicalProgram && clinicalProgram.cohortName;
    }
  }

  get showCareManagementPreset() {
    const name = this.selectedPreset?.name;
    return (
      name === CarePatientItem.CareManagementAllProgram ||
      name === CarePatientItem.CareManagementCopd ||
      name === CarePatientItem.CareManagementHeartFailure ||
      name === CarePatientItem.CareManagementHypertension
    );
  }

  get showAWVButtons() {
    return this.selectedPreset?.name === CarePatientItem.AwvOpportunities;
  }


  getAwvPatientsStatusFromQueryParams(aWVPatientsStatus: AwvWorkflowStatusForPatientListDisplay[] | AwvWorkflowStatusForPatientListDisplay) {
    if (!Array.isArray(aWVPatientsStatus)) {
      aWVPatientsStatus = [aWVPatientsStatus];
    }

    if (aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule) || aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.CallBackLater)) {
      return AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule;
    } else if (aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.ReadyToRemind) || aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.Scheduled)) {
      return AwvWorkflowStatusForPatientListDisplay.ReadyToRemind;
    } else if (aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport)) {
      return AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport;
    }
    return null;
  }

  getAwvPatientsStatusOnFilterChange(aWVPatientsStatus: AwvWorkflowStatusForPatientListDisplay[] | AwvWorkflowStatusForPatientListDisplay) {
    // CC-4854 - REVIEW - might not be the best way to fix
    if (!this.hasCareOrchFeatureFlagAndComponents()) {
      return null;
    }

    if (!Array.isArray(aWVPatientsStatus)) {
      aWVPatientsStatus = [aWVPatientsStatus];
    }
    if (aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule) || aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.CallBackLater)) {
      return AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule;
    } else if (aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.ReadyToRemind) || aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.Scheduled)) {
      return AwvWorkflowStatusForPatientListDisplay.ReadyToRemind;
    } else if (aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport)) {
      return AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport;
    } else if (aWVPatientsStatus.includes(AwvWorkflowStatusForPatientListDisplay.AllPatients)) {
      // if clicked AWV button for All patients
      return null;
    }

    return AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule; // default to ReadyToSchedule button
  }

  private getPresetKeyForAwvButton(awvPatientsStatus: AwvWorkflowStatusForPatientListDisplay){
    switch (awvPatientsStatus) {
      case AwvWorkflowStatusForPatientListDisplay.ReadyToRemind:
        return CarePatientItem.AwvOpportunitiesReadyToRemind;
      case AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule:
        return CarePatientItem.AwvOpportunitiesReadyToSchedule
      case AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport:
        return CarePatientItem.AwvOpportunitiesReadyToSelfReport
      default:
        return CarePatientItem.AwvOpportunities;
    }
  }

// Due to grouping changes, Care Management is now part of chronic conditions instead of cohorts because of
// which Care Management was shown in as a part of Chronic conditions grid where all filters are different.
  get selectedPresetIsChronicConditions(){
    return this.selectedPreset?.category === PresetCategory.ChronicConditions && (this.selectedPreset?.label !== 'Care Management' && this.selectedPreset?.name !== CarePatientItem.SmsOutreachProgram);
  }

  public async filterChange(preset: IPatientFilterPreset) {
    if (!preset || !this.userAccessService.hasComponent(this.userAccess, preset.componentId)){
      this.selectedPreset = null;
      this.filter = null;
      return;
    }
    if ((!preset.patientSidebar || preset.name != this.selectedPreset?.name) && this.selectedPatientId){
      this.selectedPatientId = null;
    }
    this.filterAppliedBool = false;
    const newAWVPatientsStatus = this._route.snapshot.queryParams['AWVPatientsStatus'] || null as AwvWorkflowStatusForPatientListDisplay[] | AwvWorkflowStatusForPatientListDisplay;

    // CC-4974: The check of presets bellow prevents a reload of data with the return; statement. Mostly used on custom new lists filtering to prevent reloading data.
    // But when we press the buttons on awv we like the data to be reloaded
    // So if newAWVPatientsStatus != this.AWVPatientsStatus that means the newAWVPatientsStatus from uri is different from the old this.AWVPatientsStatus
    // example: this.AWVPatientsStatus = Ready-to-schedule  newAWVPatientsStatus=All_Patients
    // when this happens we don’t wan’t the preset check so isAWVStatusOld gets a true value.
    let isAWVStatusChanged = false;
    if (newAWVPatientsStatus && newAWVPatientsStatus != this.AWVPatientsStatus){
      isAWVStatusChanged = true; // is true when awv buttons pressed
    }
    if (!this.datesCleared && !isAWVStatusChanged && (this.selectedPreset?.name == preset.name && this.selectedPreset?.category == preset.category)) {
      // this scenario happens if filter drawer was changed OR new filter is added. Skipping this when buttons pressed in AWV
      this.selectedPreset = preset;
      if(preset.filterSettings) {
        await this.filterDataService.applyFilterSettings(this.selectedPreset, preset.filterSettings, this.gridApi, true);
        await this.matchAndUpdateFilterSettings();
        await this.filterDataService.storeFilterModelAndMatchFilterSettings(this.selectedPreset, this.gridApi);
      }
      this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, this.gridApi, this.columnApi);
      return;
    }
    this.datesCleared = false;

    this.AWVPatientsStatus = this.getAwvPatientsStatusOnFilterChange(newAWVPatientsStatus);
    if (preset.name == CarePatientItem.AwvOpportunities && this.hasCareOrchFeatureFlagAndComponents())
        preset.presetKey = this.getPresetKeyForAwvButton(this.AWVPatientsStatus); // individual filter model stored for each button

    this.resetColumns();

    this.loadingStatus = true;
    this.loadingMsg = LoadingMsgEnum.Loading;
    this.gridApi?.showLoadingOverlay();

    try {
      this.selectedPreset = preset;

      //need a better check once we get a new view
      this.sortValue = 'lastName,firstName';
      this.filter =
        !preset.dontStoreName && preset.origin != CarePatientItem.AllPatients
          ? `${preset.type},${preset.name}`
          : '';

      await this.refreshPatientsListAndApplyPreset();
      this.changeDetectorRef.markForCheck();
      // Set Avw columns in the filter and make the visible
      // first disable all and then make the new one visible.
      // also need to make the code renterant
      if (this.selectedPreset) {
        this.externalColumnDefs = [];
        let sortModel: Array<{ colId: string; sort: string }> = [];
        let newColumnDefs: Array<ColDef> = [];
        for(let c in this.selectedPreset.columns) {
          let column = this.selectedPreset.columns[c];
          const field = Object.keys(column)[0];
          const show = column[field];
          if (show) {
            let columnDefinition = _.cloneDeep(this.columnDefs.find(
              (x) => x.field === field
            ));
            if (columnDefinition) {
              if (column.sort) {
                sortModel.push({ colId: field, sort: column.sort.toString() });
                columnDefinition.sort = column.sort as any;
              }
              newColumnDefs.push(columnDefinition);
            }
          }
        }
        if (this.selectedPreset.csvAddition) {
          let additionalColumns = [];
          for (let col in this.selectedPreset.csvAddition) {
            let column = this.selectedPreset.csvAddition[col];
            additionalColumns = [
              ...additionalColumns,
              ...column.additionalGridColumns,
            ];
          }
          newColumnDefs = [...newColumnDefs, ...additionalColumns];
        }
        if (
          this.selectedPresetIsChronicConditions ||
          this.selectedPreset?.name == CarePatientItem.EdUtilization ||
          this.selectedPreset?.name == CarePatientItem.FacesheetSchedule
        ) {
          this.externalColumnDefs = newColumnDefs;
        } else {
          if (!this.gridApi) {
            this.currentColumnDefs = newColumnDefs;
          }
          this.columnApi?.resetColumnState();
          this.gridApi?.setColumnDefs(newColumnDefs);
          this.gridApi?.sizeColumnsToFit();
                    
          this.columnApi?.applyColumnState({state: sortModel.length > 0 ? sortModel as any : []});
          if(this.gridApi){
            await this.filterDataService.applyStoredFilterModel(this.selectedPreset, this.gridApi);
            await this.filterDataService.applyDefaultFilterModel(this.selectedPreset, this.gridApi);
            await this.filterDataService.applyFilterSettings(this.selectedPreset, preset.filterSettings, this.gridApi, true);
            await this.matchAndUpdateFilterSettings();
            this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, this.gridApi, this.columnApi);
            this.setLocalColumnSettings();
          }
         }
      }
    } catch (error) {
      console.error(error);
      this.toastrMessageService.error(error);
      throw error;
    } finally {
      this.loadingStatus = false;
      if (this.patients?.length > 0) {
        this.gridApi?.hideOverlay();
      } else {
        this.loadingMsg = LoadingMsgEnum.NoData;
        this.changeDetectorRef.detectChanges();
        this.gridApi?.showNoRowsOverlay();
      }
    }

    if (preset.name != CarePatientItem.AwvOpportunities)
      this.auditService.auditLog(
        `Patient List Filter:${preset.name}`,
        ComponentId.PatientList,
        SubComponentId.TimeLine,
        ActionId.PHIAccess,
        null,
        this.tier,
        null
      );
    else
      this.auditService.auditLog(
        `Patient List Filter:${preset.name}`,
        ComponentId.AWVWorklist,
        SubComponentId.PatientList,
        ActionId.PHIAccess,
        null,
        this.tier,
        null
      );
  }
  /**
   * Clears the filters on different ag-grid components based on the selected category and preset
   * Also removes the stored filter model from storage to prevent re-aplying filter on page refresh
   */

  async clear() {
    if (this.selectedPreset.name == CarePatientItem.AwvOpportunities && this.awvWorklistService.hasDateChanged()){
      this.awvWorklistService.clearDates();
      this.datesCleared = true;
    }

    if (this.selectedPresetIsChronicConditions) {
      this.agGridLocalSettingsService.removeFilterModelFromLocalStorage(
        this.selectedPreset.category,
        this.selectedPreset.name,
        this.user?._id
      );
      this.DiseaseCohortGridComponent.clear();
    } else if (this.selectedPreset?.name == CarePatientItem.EdUtilization) {
      this.agGridLocalSettingsService.removeFilterModelFromLocalStorage(
        this.selectedPreset.category,
        this.selectedPreset.name,
        this.user?._id
      );
      this.EdUtilizationGridComponent.clear();
      return;
    } else if (this.selectedPreset?.name == CarePatientItem.FacesheetSchedule) {
      this.agGridLocalSettingsService.removeFilterModelFromLocalStorage(
        this.selectedPreset.category,
        this.selectedPreset.name,
        this.user?._id
      );
      this.FacesheetScheduleComponent.clear();
    } else {
      this.agGridLocalSettingsService.removeFilterModelFromLocalStorage(this.selectedPreset.category, this.selectedPreset.presetKey, this.user?._id);
      this.changeDetectorRef.detectChanges();
      this.filterDataService.setFilterModel(this.selectedPreset?.defaultFilterModel, this.gridApi);
      await this.filterDataService.storeFilterModelAndMatchFilterSettings(this.selectedPreset, this.gridApi);
      if (this.selectedPreset.filterSettings) {
        this.filterDataService.resetFilterSettings(this.selectedPreset);
        await this.matchAndUpdateFilterSettings();
        this.agGridLocalSettingsService.storeFilterSettingsInLocalStorage(
          this.selectedPreset.category,
          this.selectedPreset.presetKey,
          this.selectedPreset.filterSettings
        );
      }
      this.filterChange(this.selectedPreset);
      this.filterDataService.updateFilterSettings(this.selectedPreset, this.patients, this.gridApi);
    }
  }
  /*
  This function is to hide Filter applied message if the patient list is pre-filtered.
  But CC-3905 require the user to be notified if the patient list is pre-filtered so this method is no longer is in service.

  isFilterModelActive(model: { [key: string]: any }): boolean {
    if (!model) return false;
    let currentFilterModel = this.gridApi.getFilterModel();
    let modelKeys = Object.keys(model);
    let currentFilterModelKeys = Object.keys(currentFilterModel);
    let intersection = currentFilterModelKeys.filter(key => modelKeys.includes(key));
    if (intersection.length < currentFilterModelKeys.length) return false;
    for (let key in model) {
      if (!currentFilterModel[key]) return false;
      if (model[key].values) {
        intersection = model[key].values.filter(x => currentFilterModel[key]?.values?.includes(x));
        if (!_.isEqual(intersection, currentFilterModel[key]?.values)) return false;
      }
    }
    return true;
  }
  */
  filterPatients(keys: string[]): void {
    let clonedPatients = _.cloneDeep(
      this.patients.filter((patient) => {
        for (let key of keys) {
          if (!patient[key]) {
            return false;
          }
        }
        return true;
      })
    );
    this.patients = clonedPatients;
  }

  // =================== CLICK EVENTS ====================
  public onRowClicked(event: any): void {
    var sourceCell = event.event.srcElement;
    if (sourceCell.className.includes('cell-patient-navigation') && !event.data.withoutFacesheet) {
      this.setSelectedPatientId(event.data.chPatId);
    }
  }
  setSelectedPatientId(id: string): void {
    // PatientSideBar
    const patient = this.patients.find((p) => p.chPatId === id);
    if (
      this.featureFlagService.hasFeatureFlag(
        CareFeatureConstants.PatientSideBar
      ) &&
      this.selectedPreset.patientSidebar
    ) {
      this.selectedPatient = patient;
      this.selectedPatientId == id
        ? (this.forcePatientSideBar = { open: true })
        : (this.selectedPatientId = id);
    } else {
      this._router.navigate([`../patient/${id}`], {
        relativeTo: this._route,
      });
    }
  }

  public onSelectionChanged(event: any): void {
    let nodes: any[] = this.gridApi.getSelectedNodes();
    this.disableBroadcastButton.next(nodes.length == 0);
    this.disableBulkInviteButton.next(nodes.length == 0);
  }
  closeModalOnBackgroundClick(clickedElement: any, target: any): void {
    if (clickedElement.getAttribute('role') == 'modal-background') {
      this[target] = false;
    }
  }
  public exportCSV(): void {
    let selectedPatients = this.getSelectedPatients();
    if (selectedPatients.length < 1) {
      return;
    }
    const reportCohort = this.route.snapshot.queryParamMap.get('filterPreset');
    const timestamp = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    let columnKeys: string[] =
      this.agGridLocalSettingsService.getActiveColumnNames(this.columnApi);
    let csvAddition = this.selectedPreset.csvAddition;
    let csvAdditionKeys: string[] = [];
    if (csvAddition) {
      csvAdditionKeys =
        this.agGridLocalSettingsService.getCsvAdditionalColumns(csvAddition);
      selectedPatients.forEach((patient) => {
        for (let c in csvAddition) {
          let targetFieldValue = patient[c];
          let fieldSettings = csvAddition[c];

          if (fieldSettings.addPatientColumns) {
            fieldSettings.addPatientColumns(targetFieldValue, patient);
          }
        }
      });
      columnKeys = [...columnKeys, ...csvAdditionKeys];
    }

    this.gridApi.exportDataAsCsv({
      processCellCallback: (params: ProcessCellForExportParams): string => {
        switch (params.column.getColId()) {
          case 'lastActivityAt':
          case 'lastAWVDate':
          case 'lastVisit':
            return params.value
              ? formatDate(params.value, dateFormat, this.locale)
              : '';
          case 'totalSpend':
          case 'totalCost':
            return currencyCellRenderer(params);
          case 'patientId':
          case 'current_MBI':
            return this.mask.transform(params.value, 6);
          case 'openRAFWt':
            return params.value?.toFixed(3) || 'N/A';
          case 'dodBoolean':
            const dodValue = dodCellRenderer(params);
            return dodValue ? formatDate(dodValue, dateFormat, this.locale) : null;
          default:
            return params.value;
        }
      },

      suppressQuotes: false,
      columnSeparator: ',',
      onlySelected: true,
      columnKeys: columnKeys,
      fileName: `GeneralList_${reportCohort}_${timestamp}.csv`,
    });
  }

  public onFacesheetExportClick(): void {
    this.FacesheetScheduleComponent.export(
      this.facesheetVersionExpanded,
      this.tierNum,
      this.tierId
    );
  }
  public onFacesheetPrintClick(): void {
    this.FacesheetScheduleComponent.print(
      this.facesheetVersionExpanded,
      this.tierNum,
      this.tierId
    );
  }

  public facesheetExport(action: 'export' | 'print'): void {
    if (this.selectedPreset?.name == CarePatientItem.FacesheetSchedule) {
      action == 'export'
        ? this.onFacesheetExportClick()
        : this.onFacesheetPrintClick();
    } else {
      let selectedPatients: any[] = [];


      selectedPatients = this.getSelectedPatients();

      if(this.selectedPreset.name === CarePatientItem.AllPatients || this.selectedPreset.name === CarePatientItem.AwvOpportunities){
        selectedPatients = selectedPatients.filter(p=> p.assignment !== Assignment.PreStep);
      }

      selectedPatients = selectedPatients.map(p=>({
        patientMbi: p.mbi || p.current_MBI,
        chPatId: p.chPatId}));

      this.hccFacesheetScheduleService.bulkExportPrint(
        action == 'export' ? true : false,
        selectedPatients,
        this.selectedPreset.componentId ??
          CareComponentConstants.HccFacesheetBySchedule,
        null,
        null,
        this.facesheetVersionExpanded,
        this.tierNum,
        this.tierId
      );
    }
    this.facesheetVersionModal = false;
  }

  public onExportCSVClick(): void {
    if (this.selectedPresetIsChronicConditions) {
      this.DiseaseCohortGridComponent.exportCSV();
    } else if (this.selectedPreset?.name == CarePatientItem.EdUtilization) {
      this.EdUtilizationGridComponent.exportCSV();
    } else if (this.selectedPreset?.name == CarePatientItem.FacesheetSchedule) {
      return;
    } else {
      this.exportCSV();
    }
  }
  public externalPatientSelected(patients: Patient[]) {
    this.externalSelectedList = patients;
    this.disableBroadcastButton.next(this.externalSelectedList.length == 0);
    this.disableBulkInviteButton.next(this.externalSelectedList.length == 0);
  }
  public cancelPatientsLoadingProcess(): void {
    this.cancelLoadingBool = true;
    this.loadingStatus = false;
    this.resetFilter = true;
    this._refreshing = false;
    this.changeDetectorRef.detectChanges();
  }
  updatePatientByIdInList(patient: PatientViewModel): void {
    let trackPatient = patient.trackPatient;
    let carePatient = patient.carePatient;
    let updatedPatient: PatientViewModel;
    switch (this.selectedPreset?.name) {
      case CarePatientItem.AwvFiles:
        updatedPatient = PatientViewModel.fromIheVisit(
          patient.trackPatient?.visitInfo
        );
        break;
      case CarePatientItem.AwvOpportunities:
        updatedPatient = PatientViewModel.fromAwvAndCare(
          patient.trackAwvPatient,
          null,
          this.tier,
          this.patientDataByChPatId[patient.chPatId]
        );
        break;
      default:
        const riskProbability = Number(trackPatient?.probability?.toFixed(2));
        updatedPatient = PatientViewModel.fromTrackAndCare(
          trackPatient,
          carePatient as IWPAPIPatient,
          this.tier,
          trackPatient?.assignment,
          trackPatient?.currentRiskLevel,
          trackPatient?.predictedRiskLevel,
          riskProbability,
          trackPatient?.riskTrend
        );
        break;
    }
    if (this.selectedPreset.name == CarePatientItem.PacNavigatorProgram) {
      if (!this.patients.find((p) => p.chPatId == patient.chPatId)) {
        // clear patient ID and close sidebar if patient
        this.selectedPatientId = null;
      }
    }
    // CC-4317 don't replace the entire list, just replace the updated patient
    // This way the sort order isn't messed up.
    // REVIEW we'd perhaps want to just update the fields that were updated and not replace the
    // entire object
    const index = this.patients.findIndex((p) => p.chPatId == patient.chPatId);
    if (index < 0) {
      throw new Error(`Unexpected couldn't update patient ${updatedPatient.chPatId}`);
    }
    this.patients[index] = updatedPatient;
    // REVIEW - would be preferable to set gridOptions.getRowNodeId to be a function that returns chpatid
    // but beware that can break in certain grids e.g. the ih-awv schedule which is really by
    // appointment/visit, not patient
    this.gridApi.getModel().forEachNode((rowNode) => {
      if (rowNode.data.chPatId == patient.chPatId) {
        // Need to do setData - updating the data in the bound list isn't enough.
        rowNode.setData(updatedPatient);
        // Explicit refresh isn't needed.
        // this.gridApi.refreshCells({rowNodes: [rowNode]});
      }
    });
    this.gridApi.refreshCells({force: true});
  }

  //  =================== BULK INVITE ====================
  public openBulkInvite(): void {
    this.newMessage.messageText = null;
    this.inviteList =
      !this.selectedPresetIsChronicConditions &&
      this.selectedPreset?.name !== CarePatientItem.EdUtilization &&
      this.selectedPreset?.name !== CarePatientItem.FacesheetSchedule
        ? this.gridApi?.getSelectedRows() || []
        : this.externalSelectedList;
    this.disableBulkInviteButton.next(this.inviteList.length == 0);
    if (this.inviteList.length == 0) return;
    this.bulkInviteModal = true;
  }
  isPatientValidForInvitation(patient: Patient): boolean {
    return (
      patient.trackPatient.email?.length > 0 &&
      patient.trackPatient.dob?.length > 0 &&
      patient.trackPatient.firstName?.length > 0 &&
      patient.trackPatient.lastName?.length > 0
    );
  }
  getValidPatientsLength(): number {
    return this.inviteList.filter((p) =>
      this.isPatientValidForInvitation(p.data)
    ).length;
  }
  closeBulkInviteModal(event: any): void {
    this.bulkInviteModal = false;
    // this.gridApi?.deselectAll();
  }

  // ====================== COLUMN SETTINGS ========================

  public fieldSorter = (fields) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }
        if (a[o] && !b[o]) return -1;
        if (!a[o] && b[o]) return 1;
        let valA = typeof a[o] == 'string' ? a[o].toLowerCase() : a[o];
        let valB = typeof b[o] == 'string' ? b[o].toLowerCase() : b[o];
        return valA > valB ? dir : valA < valB ? -dir : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);

  formatPhoneNumber = (str: string) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]} ${str.slice(-1)}`;
    }
    return '';
  };
  columnEverythingChanged(event) {
    // event.source: 'contextMenu' => when user clicks on column -> Reset Columns
    // event.source: 'api' => when user updates columns from the gear button menu
    // if(event?.source == 'contextMenu') {
    //   temporary removed
    // }
    if(event.source == 'contextMenu') {
      this.agGridLocalSettingsService.removeColumnSettingsFromLocalStorage(
        this.selectedPreset.name,
        this.selectedPreset.versionKey,
        this.user?._id,
      )
      this.setLocalColumnSettings()
    }
  }
  onColumnUpdated(): void {
    this.agGridLocalSettingsService.storeColumnSettingsInLocalStorage(
      this.selectedPreset.presetKey,
      this.columnApi.getColumnState(),
      this.selectedPreset.versionKey
    );
  }
  setLocalColumnSettings(): void {
    if (this.selectedPreset) {
      this.changeDetectorRef.detectChanges();
      this.agGridLocalSettingsService.setColumnSettings(
        this.columnApi,
        this.selectedPreset.name,
        this.user?._id,
        this.selectedPreset.versionKey,
        this.selectedPreset.hiddenColumnsByDefault
      );
    }
  }

  updateSecondaryColumnApi(columnApi: ColumnApi): void {
    this.secondaryColumnApi = columnApi;
  }

  autoSizeColumns() {
    var allColumnIds = [];
    this.columnApi?.getColumns().forEach(function (column) {
      allColumnIds.push(column['colId']);
    });
    this.columnApi?.autoSizeColumns(allColumnIds, false);
  }

  resetColumns(): void {
    this.gridApi?.setColumnDefs([]);
    this.autoSizeColumns();
    this.gridApi?.sizeColumnsToFit();
  }
  // ====================== FILTER SETTINGS ========================



  // need to find a way to  call:
  // 1. this.matchAndUpdateFilterSettings();
  // 2. this.checkIfFilterIsApplied(this.gridApi);

  async updatePresetFilterSettings(filterSettings: FilterSettings) {
    this.selectedPreset.filterSettings = filterSettings;
    await this.filterDataService.applyFilterSettings(this.selectedPreset, this.selectedPreset?.filterSettings, this.gridApi, true, true);
    this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, this.gridApi, this.columnApi);
    this.storeFilterModel(this.selectedPreset);
  }
  /** Decides whether to show the "filter applied" and "clear" elements
   * in the UI by updating the view model property filterAppliedBool
   */
  checkIfFilterIsApplied(gridApi: GridApi): void {
    this.filterAppliedBool = this.filterDataService.checkIfFilterIsApplied(this.selectedPreset, gridApi, this.columnApi);
  }

  matchAndUpdateFilterSettings(shouldStoreFilterModel?: boolean): void {
    this.changeDetectorRef.detectChanges();
    this.filterDataService.matchFilterSettingsWithCurrentFilterModel(this.selectedPreset, this.gridApi, shouldStoreFilterModel);
  }
  storeFilterModel(preset: IPatientFilterPreset) {
    this.filterDataService.storeFilterModelAndMatchFilterSettings(preset, this.gridApi);
  }
  // ====================== MISC SETTINGS ========================

  async getUserInfo() {
    this.user = await this.authService.getWellpepperUserInfo();
  }

  setAWVPatientsStatusURLParams(linkText: string, value: AwvWorkflowStatusForPatientListDisplay[]) {
    const queryParams = { AWVPatientsStatus: value};

    this.sendAWVPatientStatusLinkClickedEvent(linkText, value);

    this._router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  private sendAWVPatientStatusLinkClickedEvent(linkText, value: AwvWorkflowStatusForPatientListDisplay[]):void {
    try {
      const AWVPatientsStatus = value.length > 0 ? value[0] : AwvWorkflowStatusForPatientListDisplay.AllPatients;

      this.linkClickedEventsService.sendLinkClickedEvent({
        filterPreset: this._route.snapshot.queryParams['filterPreset'],
        linkText,
        targetPage: '/care/patients',
        AWVPatientsStatus
      });
    } catch (error) {
      // do nothing
      console.log('Error sending data to mixpanel', error);
    }
  }

}

export type CellAction = (params) => void;
