import { Component, OnInit } from '@angular/core';
import {
  SubmitFeatureConstants,
  SubmitFeatureNames,
  SubmitFormConstants,
} from '@shared/models/module-constants';

import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { CaravanFormSubmissionPeriodOverview } from '@shared/models/forms/form-submissionID-model';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { Subscription } from 'rxjs';

const {
  QualityFormFeature,
  ComplianceFormFeature,
  PromotingInteroperabilityFormFeature,
} = SubmitFeatureConstants;
@Component({
  selector: 'coach-submit',
  templateUrl: './submit.component.html',
  host: {
    class: 'coach-submit',
  },
})
export class SubmitComponent implements OnInit {
  public tabsData = [
    {
      name: SubmitFeatureNames.Summary,
      routerLink: ['summary'],
    },
  ];

  formSubmissionOverviewSubscription: Subscription = new Subscription();
  randomSampleManualAbstractionFolderSubscription: Subscription =
    new Subscription();
  formSubmissionOverview: CaravanFormSubmissionPeriodOverview[] = [];
  currentDate: string | undefined;

  piFormOpen = false;
  piFormId = SubmitFormConstants.PromotingInteroperabilityFormComponent;
  complianceFormOpen = false;
  complianceFormId = SubmitFormConstants.ComplianceFormComponent;
  qualityFormOpen: boolean = false;
  qualityFormId = SubmitFormConstants.QualityFormComponent;

  constructor(private formsService: SubmitFormsService) {}

  ngOnInit(): void {
    this.formSubmissionOverviewSubscription = this.formsService
      .getSubmitPeriodsOverview(0)
      .subscribe((data) => {
        this.formSubmissionOverview = data;
        this.checkFormOpenStatus();
      });
  }
  checkFormOpenStatus(): void {
    const todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    if (this.formSubmissionOverview != null) {
      for (let i = 0; i < this.formSubmissionOverview.length; i++) {
        let openDate = this.formSubmissionOverview[i].openDate;
        if (moment(openDate).isSameOrBefore(todayDate)) {
          if (this.formSubmissionOverview[i].formID == this.piFormId) {
            this.piFormOpen = true;
            //  name: SubmitFeatureNames.PI
          }
          if (this.formSubmissionOverview[i].formID == this.complianceFormId) {
            this.complianceFormOpen = true;
            //  name: SubmitFeatureNames.Compliance
          }
          if (this.formSubmissionOverview[i].formID == this.qualityFormId) {
            this.qualityFormOpen = true;
            //  name: SubmitFeatureNames.Quality
          }
        }
      }
    }
    if (this.piFormOpen == true) {
      this.addOpenFormToTab(
        SubmitFeatureNames.PI,
        ['promoting-interoperability'],
        PromotingInteroperabilityFormFeature
      );
    }
    if (this.complianceFormOpen == true) {
      this.addOpenFormToTab(
        SubmitFeatureNames.Compliance,
        ['compliance'],
        ComplianceFormFeature
      );
    }
    if (this.qualityFormOpen == true) {
      this.addOpenFormToTab(
        SubmitFeatureNames.Quality,
        ['quality'],
        QualityFormFeature
      );
    }
    //Need to leave this slice (even though it doesn't do anything out right) do to a quirk of pushing to the array not updating the tabs themselves.
    //Likely this is an issue with the push not doing a deep copy, where as slice does a deep copy which causes the tab list to actually update what's being displayed.
    this.tabsData = this.tabsData.slice();
  }

  addOpenFormToTab(
    tabName: string,
    routerLink: string[],
    featureId: number
  ): void {
    const newTab = {} as newTab;
    newTab.name = tabName;
    newTab.routerLink = routerLink;
    newTab.featureId = featureId;
    this.tabsData.push(newTab);
  }
}
export interface newTab {
  name: string;
  routerLink: string[];
  featureId: number;
}
