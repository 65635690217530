import { DomPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, Injector, OnDestroy, ViewChild } from '@angular/core';

/** 
 * Portal component used to display content in the global header.
 * 
 * @example
 * <coach-header-title-portal>
 *                ...
 * </coach-header-title-portal>
 */
@Component({
  selector: 'coach-header-title-portal',
  templateUrl: './header-title-portal.component.html',
  styleUrls: ['./header-title-portal.component.scss']
})
export class HeaderTitlePortalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('portalTemplate') portal: TemplatePortal<any>;
  private portalHost!: PortalOutlet;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {
  }

  ngAfterViewInit(): void {
    this.portalHost = new DomPortalOutlet(
      // tslint:disable-next-line:no-non-null-assertion
      document.querySelector('#header-title-container')!,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
    this.portalHost.attach(this.portal);
  }

  ngOnDestroy(): void {
    this.portalHost.detach();
  }
}
