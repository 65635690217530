import { Component, Input } from '@angular/core';

@Component({
  selector: 'coach-patient-awv-loading',
  templateUrl: './patient-awv-loading.component.html',
  styleUrls: ['./patient-awv-loading.component.scss']
})
export class PatientAwvLoadingComponent {
  @Input() public isLoading: boolean;
  @Input() public isHistory = false;
}
