import { Injectable } from '@angular/core';
import { PatientService } from '../patient/patient.service';
import { DocumentLookupService } from './document-lookup/document-lookup.service';
import { ReportLookupService } from './report-lookup/report-lookup.service';
import { RecentsItem } from '../recents-cache/recents-cache.service';
import { LogAuditElementID } from '@shared/models/audit-constants';
@Injectable({
  providedIn: 'root',
})
export class RecentsLookupService {
  constructor(
    private patientService: PatientService,
    private documentLookupService: DocumentLookupService,
    private reportLookupService: ReportLookupService
  ) {}

  async getTitle(item: RecentsItem): Promise<string | null> {
    const patientElement = item.elements.find(
      (element) => element.id === LogAuditElementID.ChPatId
    );
    const documentElement = item.elements.find(
      (element) => element.id === LogAuditElementID.DocumentEntryId
    );
    const reportElement = item.elements.find(
      (element) => element.id === LogAuditElementID.ReportGUID
    );
    if (documentElement) {
      return await this.documentLookupService.getTitle(documentElement.value);
    } else if (reportElement) {
      return await this.reportLookupService.getTitle(reportElement.value);
    } else if (patientElement) {
      const tierId = item.navURL.split('/')[2];
      const orgId = await this.patientService.getWpOrgIdFromTierId(tierId);
      const patient = await this.patientService.getPatient(
        orgId,
        patientElement.value
      );
      if (patient.id) {
        return patient.displayName();
      } else {
        return undefined;
      }
    } else {
      return null;
    }
  }
}
