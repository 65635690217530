<coach-track-time-tracking-filter
  (selectedYearChange)="selectedYearChange($event)"
  (selectedMonthChange)="selectedMonthChange($event)"
  (export)="exportClicked($event)"
  [selectedList]="selectedList"
>
</coach-track-time-tracking-filter>
<div class="u-height--full">
  <div class="dynamicBody padding--large" style="height: 100%">
    <ag-grid-angular
      *ngIf="selectedList"
      row-animation
      style="width: 100%; height: 100%"
      class="ag-theme-material"
      [rowData]="timeTrackingEvents[selectedList.detailed ? 'detailed' : 'summary']?.events"
      [defaultColDef]="defaultColDef"
      [columnDefs]="selectedList.detailed ? detailedDefs : summaryDefs"
      [overlayNoRowsTemplate]="'&lt;p&gt;No data&lt;/p&gt;'"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      (rowClicked)="onRowClicked($event)"
      [pagination]="true"
      [paginationAutoPageSize]="true"
      [sortingOrder]="['asc', 'desc', null]"
    >
    </ag-grid-angular>
  </div>
</div>
