import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { TrackRoutingModule } from '@track/track-routing.module';
import { TrackComponent } from '@track/track.component';
import { TrackSummaryComponent } from '@track/track-summary/track-summary.component';
import { TrackFinancialUtilizationComponent } from '@track/track-financial-utilization/track-financial-utilization.component';
import { TrackReturnOnInvestmentComponent } from '@track/track-return-on-investment/track-return-on-investment.component';
import { TrackQualityComponent } from '@track/track-quality/track-quality.component';
import { TrackReportComponent } from './track-reports/track-report/track-report.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { TrackReportWidgetsComponent } from './track-reports/track-report-widgets/track-report-widgets.component';
import { TrackTimeFiltersComponent } from './track-reports/track-time-filters/track-time-filters.component';
import { TrackClinicalModule } from './track-clinical/track-clinical.module';
import { TrackReportFilterTypesComponent } from './track-reports/track-report-filter-types/track-report-filter-types.component';
import { AgGridModule } from 'ag-grid-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({
  declarations: [
    TrackComponent,
    TrackSummaryComponent,
    TrackFinancialUtilizationComponent,
    TrackReturnOnInvestmentComponent,
    TrackQualityComponent,
    TrackReportComponent,
    TrackReportWidgetsComponent,
    TrackTimeFiltersComponent,
    TrackReportFilterTypesComponent
  ],
  imports: [
    SharedModule,
    TrackRoutingModule,
    NgxPowerBiModule,
    TrackClinicalModule,
    AgGridModule,
    FontAwesomeModule
  ]
})
export class TrackModule { }
