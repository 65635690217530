import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Access } from '@shared/services/graphql/access.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { HasAccessBase } from './has-access';

/**
 * Only renders element if the user's current access includes supplied Feature ID
 * 
 * @example
 * <div *hasFeature="trackClinicalFeatureId"></div>
 */
@Directive({
  selector: '[hasFeature]'
})
export class HasFeatureDirective extends HasAccessBase {

  constructor(protected userAccessService: UserAccessService,
              protected templateRef: TemplateRef<any>,
              protected viewContainer: ViewContainerRef) {
    super(userAccessService, templateRef, viewContainer);
  }

  featureId: number;

  @Input()
  set hasFeature(value: number) {
      this.featureId = value;
      this.updateAccess(this.userAccessService.currentAccess$.value);
  }

  getAccess(access: Access): boolean {
    return this.userAccessService.hasFeature(access, this.featureId);
  }

}
