import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';

@Component({
  selector: 'coach-file-control',
  templateUrl: './file-control.component.html',
  styleUrls: ['./file-control.component.scss']
})
export class FileControlComponent implements OnInit {

  @Input() filename: string;
  @Input() tier2Id: string;
  @Input() prescriptionRefId: string;
  @Output() setMessage: EventEmitter<string> = new EventEmitter();
  @Output() removeFile: EventEmitter<string> = new EventEmitter();

  maxFilenameLength: number = 40;
    
  constructor(private prescriptionService: PrescriptionService) { }

  ngOnInit(): void {
  }

  deleteFile(): void {
    let result = this.prescriptionService.deleteUploadedFile(this.tier2Id, this.prescriptionRefId, this.filename);
    this.setMessage.emit(result['description']);
    this.removeFile.emit(this.filename);
  }

}
