import { Component, Output, EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import {formatDate} from '@angular/common';
import {
  SubmitFeatureConstants,
  SubmitFeatureNames,
  SubmitFormConstants,
} from '@shared/models/module-constants';
import { FormConstants } from '@shared/models/forms/form-constants';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { FolderLookupService } from '@shared/services/folder-lookup/folder-lookup.service';
import { CaravanFormSubmissionPeriodOverview  } from '@shared/models/forms/form-submissionID-model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'coach-submit-upcoming-submission-summary',
  templateUrl: './submit-upcoming-submission-summary.component.html',
  host: {
    class: 'row u-fill',
  }
})
export class SubmitUpcomingSubmissionSummary  {
  @Output() formSubmissionOverviewEmitter = new EventEmitter<CaravanFormSubmissionPeriodOverview[]>();

  formSubmissionOverviewSubscription: Subscription;
  randomSampleManualAbstractionFolderSubscription: Subscription;
  randomSampleManualAbstractionFolder;
  formSubmissionOverview: CaravanFormSubmissionPeriodOverview[];
  currentDate: string;

  submitQualityFeature = SubmitFeatureConstants.QualityFormFeature;
  submitQuailityName = SubmitFeatureNames.Quality;
  submitMeetingFeature = SubmitFeatureConstants.MeetingFormFeature;
  submitMeetingName = SubmitFeatureNames.Meeting;
  submitComplianceFeature = SubmitFeatureConstants.ComplianceFormFeature;
  submitComplianceName = SubmitFeatureNames.Compliance;
  submitPIFeature = SubmitFeatureConstants.PromotingInteroperabilityFormFeature;
  submitPIName = SubmitFeatureNames.PI;

  qualityOpenInfo = FormConstants.QualityOpeningInfo;
  qualityOpenDueDate = FormConstants.QualityOpenDueDate;
  qualityrequirements = FormConstants.Quality.requirements;
  qualityFormId = SubmitFormConstants.QualityFormComponent;
  complianceOpenInfo = FormConstants.ComplianceOpeningInfo;
  complianceOpenDueDate = FormConstants.ComplianceOpenDueDate;
  complianceFormId = SubmitFormConstants.ComplianceFormComponent;
  piOpenInfo = FormConstants.PIOpeningInfo;
  piOpenDueDate = FormConstants.PIOpenDueDate;
  piFormId = SubmitFormConstants.PromotingInteroperabilityFormComponent;
  meetingWidgetName = FormConstants.MeetingWidgetName;
  meetingOpenInfo = FormConstants.MeetingOpeningInfo;
  meetingOpenDueDate = FormConstants.MeetingOpenDueDate;

  constructor(
    private folderService: FolderLookupService,
    private formsService: SubmitFormsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.formSubmissionOverviewSubscription = this.formsService.getSubmitPeriodsOverview(0).subscribe((data) => {
        this.formSubmissionOverview = data;
        this.formSubmissionOverviewEmitter.emit(this.formSubmissionOverview);
        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      });
    this.randomSampleManualAbstractionFolderSubscription = this.formsService.getFolderGeneric('Random_Sample_Manual_Abstraction', 'From Caravan WITH PHI','Practice Improvement').subscribe(
      (data) => (this.randomSampleManualAbstractionFolder = data)
    );
  }


  public goToItems(e, path: string, query?: object | null): void {
    e.preventDefault();
    this.router.navigate(['..' + path], {
      queryParams: query,
      relativeTo: this.activatedRoute.parent,
    });
  }
}
