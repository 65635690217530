import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import {
  EHR_VENDORS,
  FORMS_SPECIAL_STATUSES,
} from '@shared/mocks/forms/forms-select-dropdowns';
import {
  CaravanPracticeDropdown,
  CaravanSpecialStatus,
  FormResponses,
  FormSubmission,
  SpecialStatusSummary,
  UpdateQualityStatusParams,
} from '@shared/models/forms/forms-backend';
import { UserInfo } from '@shared/services/auth-service/auth-interface';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomValidators } from '@shared/validators';
import { ToastrService } from 'ngx-toastr';
import { ToolTipMeasureDetails } from '@shared/models/forms/tool-tip-measure-details';
import { CaravanFormHelpModalLinks, ExclusionRequestDetails } from '@shared/models/forms/forms-frontend';
import {
  SubmitFeatureConstants,
  SubmitFeatureNames,
} from '@shared/models/module-constants';
import { FormConstants, PromotingInteropFormSettings } from '@shared/models/forms/form-constants';
import { FormQuestionConstants } from '@shared/models/forms/form-question-constants';
import { CaravanFormSubmissionPeriod  } from '@shared/models/forms/form-submissionID-model';
import {submitFormSharedFunctions} from '../shared/functions/submit-form-functions';

@Component({
  selector: 'coach-submit-promoting-interoperability',
  templateUrl: './submit-promoting-interoperability.component.html',
})
export class SubmitPromotingInteroperabilityComponent
  extends KillSubscriptions
  implements OnInit
{
  public practices: CaravanPracticeDropdown[] = [];

  public specialStatuses: CaravanSpecialStatus[] = [];
  public specialStatusID: number | string = null;

  public ehrVendors: string[];
  public ehrVendor: string = null;

  public submitPI: UntypedFormGroup;

  public readonly QualityFormId = 1;
  public readonly OtherSpecialStatusId = 23;
  public readonly FormVersion = 3;
  public readonly CEHRT = 2021;
  public specialStatusSummary: SpecialStatusSummary[];
  public specialStatusList: CaravanSpecialStatus[] = [];
  public formSubmissions: FormSubmission[];
  public showRemoveModal = false;
  public formSaving = false;
  private exemptionToRemove: SpecialStatusSummary;
  public totalPoints: number;
  public hieSelected = false;
  public yesOnTEFCA = false;
  public isExempt = false;
  public caravanFormSubmissionPeriod: CaravanFormSubmissionPeriod[];
  public selectedFormSubPeriod: number;
  public dueDate: Date;


  public submitFormSharedFunction: submitFormSharedFunctions = new submitFormSharedFunctions();
  public formQuestionConstants = FormQuestionConstants;
  public providePatientsMax = FormQuestionConstants.Q12ProvideAccess.maxPoints;
  public drugMonitorsMax = FormQuestionConstants.Q14DrugMonitor.maxPoints;
  public ePrescribeMax = FormQuestionConstants.Q9ePrescibe.maxPoints;
  public hieMax = FormQuestionConstants.Q71HealthInformationExchange.maxPoints;
  public supportSendMax = FormQuestionConstants.Q10SupportSend.maxPoints;
  public supportReceiveMax = FormQuestionConstants.Q11SupportReceive.maxPoints;
  public tefcaMax = FormQuestionConstants.Q82TEFCA.maxPoints;
  public totalMaxPoints = 100;
  public questionNames = [
    FormQuestionConstants.Q8SecurityRisk.name,
    FormQuestionConstants.Q9ePrescibe.name,
    FormQuestionConstants.Q10SupportSend.name,
    FormQuestionConstants.Q11SupportReceive.name,
    FormQuestionConstants.Q12ProvideAccess.name,
    FormQuestionConstants.Q14DrugMonitor.name,
    FormQuestionConstants.Q71HealthInformationExchange.name,
    FormQuestionConstants.Q77HighPriorityGuideSAFER.name,
    FormQuestionConstants.Q78BonusRegistryReporting.name,
    FormQuestionConstants.Q82TEFCA.name
  ];

  public uploadedFileName: string;
  public uploadFile: File;
  private currentUser: UserInfo;

  public readonly practiceHelpText =
    'Lists names of TINs in the ACO. To complete data for a given TIN, select the TIN in the drop down.';
  public readonly specialStatusHelpText =
    'Select a new Special Status for a particular TIN, if applicable. Otherwise leaving it as N/A will allow you to complete the form.';
  public readonly ehrVendorHelpText =
    'The twelve most popular EHRs are listed. If your EHR is not indicated, please select Other.';
  public readonly exemptHelpText =
    'Indicate whether selected practice(s) are exempt. If exempt, please indicate the reason in "Special Status" below.';
  public readonly uploadHelpText =
    'Before submitting, please upload an electronic copy of the EHR report or excel template used to complete the form for verification and accountability.';


  public isQueueOpen = true;
  public isFormQueueOpen = true;
  public isAddEditFormActive = false;
  public isInfoTextOpen = false;
  public infoText: string;
  public infoMeasureLinkFile: CaravanFormHelpModalLinks[];

  public shwSpecialStatus: boolean = true;
  public copy: PromotingInteropFormSettings = FormConstants.PromotingInterop;

  readonly formsAdminFormFeatureFlag: string = SubmitFeatureConstants.FormsAdminFormFeatureFlag;
  readonly formsAdminFormFeature: number = SubmitFeatureConstants.FormsAdminFormFeature;
  private readonly exclusionTypeId = 3;

  constructor(
    private fb: UntypedFormBuilder,
    private _authService: AuthService,
    private submitFormsService: SubmitFormsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.specialStatuses = FORMS_SPECIAL_STATUSES.filter(
      (s) => s.formID === 1 && s.isDeleted === 0 && s.isVisible === 1
    );

    this.submitFormsService.getSubmitPeriodDetails(this.QualityFormId).subscribe((data) => {
      this.caravanFormSubmissionPeriod = data;
      this.selectedFormSubPeriod = this.caravanFormSubmissionPeriod[0].caravanFormSubmitPeriodID;
      this.dueDate = this.caravanFormSubmissionPeriod[0].dueDate;
      this.updateSubmissionPeriodConfigs(0);

      this.refreshFormData();

      this._authService.userInfo$
      .pipe(takeUntil(this.killTrigger))
      .subscribe((user) => {
        if (user != null) {
          this.currentUser = user;
          this.initializePiForm();
        }
      });
    });
  }

  valueChanges(): void {
    let points = 0;
    let provideExclusionPoints = 0;
    let zeroTotal = false;

    this.questionNames.forEach((question) => {
      const control = this.submitPI.controls[question];
      const exclusionValue = control.get('exclusion')?.value;
      const maxPoints: number = control.get('maxPoints')?.value;
      const isDisabled = control.get('isDisabled')?.value;
      const scoreZeroWholeForm = control.get('scoreZeroWholeForm')?.value;

      const pointValue = control.get('FormQResponses.0.ResponsePointValue').value as number;

      if (exclusionValue || isDisabled) {
        this.setMaxPoints(question, 0);
      } else {
        if (scoreZeroWholeForm == true && pointValue == 0) {
          zeroTotal = true;
        }
        points +=
          (control.get('FormQResponses.0.ResponsePointValue')
            .value as number) || 0;
        this.setMaxPoints(question, maxPoints);
      }
    });

    this.setExclustionPoints();

    const regReportOne: string = this.submitPI.controls['RegReportOne'].value;
    const regReportTwo: string = this.submitPI.controls['RegReportTwo'].value;
    const exclusion = 'exclusion';
    const none = 'none';

    if (regReportOne != null && regReportTwo != null) {
      if (regReportOne === exclusion && regReportTwo === exclusion) {
        provideExclusionPoints += 25;
      } else if (regReportOne === none || regReportTwo === none) {
        zeroTotal = true;
      } else {
        points += 25;
      }
    }

    this.providePatientsMax += provideExclusionPoints;

    if (zeroTotal == true) {
      points = 0;
    }

    if (points > this.totalMaxPoints) {
      points = this.totalMaxPoints;
    }

    this.submitPI.patchValue({ TotalPoints: points }, { emitEvent: false });
    setTimeout(() => (this.totalPoints = points));
  }

  public updateSubmissionPeriodConfigs(submissionPeriodID) {
    const submitPeriod = this.caravanFormSubmissionPeriod[submissionPeriodID];
    for (let config in submitPeriod.configs) {
      if (submitPeriod.configs[config].configValue !== null) {
        if(submitPeriod.configs[config].configName == 'shwSpecialStatus') {
          this.shwSpecialStatus = this.submitFormSharedFunction.getBoolean(submitPeriod.configs[config].configValue);
          this.copy[submitPeriod.configs[config].configName] = this.shwSpecialStatus;
        }
        else if(submitPeriod.configs[config].configName == 'drpEHR') {
          this.copy[submitPeriod.configs[config].configName] =  submitPeriod.configs[config].configValue.split('|');
        }
        else {
          this.copy[submitPeriod.configs[config].configName] = submitPeriod.configs[config].configValue.toString();
        }
      }
    }

    this.ehrVendors = this.copy.drpEHR;
  }

  setExclustionPoints(): void {
    const ePrescribeExclusion =
      this.submitPI.controls[FormQuestionConstants.Q9ePrescibe.name].get(
        'exclusion'
      )?.value;
    const supportSendExclusion =
      this.submitPI.controls[FormQuestionConstants.Q10SupportSend.name].get(
        'exclusion'
      )?.value;
    const supportReceieveExclusion =
      this.submitPI.controls[FormQuestionConstants.Q11SupportReceive.name].get(
        'exclusion'
      )?.value;
    const drugMonitorExclusion =
      this.submitPI.controls[FormQuestionConstants.Q14DrugMonitor.name].get(
        'exclusion'
      )?.value;

    const hieSelectedValue = this.submitPI.controls[
      FormQuestionConstants.Q71HealthInformationExchange.name
    ].get('FormQResponses.0.UserResponse')?.value;

  let hieSelectedBool = false;
    if (hieSelectedValue) {
      hieSelectedBool = true;
    }

    let ePrescribeMax =
      this.submitPI.controls[FormQuestionConstants.Q9ePrescibe.name].get(
        'maxPoints'
      )?.value;
    const supportSendMax =
      this.submitPI.controls[FormQuestionConstants.Q10SupportSend.name].get(
        'maxPoints'
      )?.value;
    const supportReceiveMax =
      this.submitPI.controls[FormQuestionConstants.Q11SupportReceive.name].get(
        'maxPoints'
      )?.value;
    const drugMonitorMax =
      this.submitPI.controls[FormQuestionConstants.Q14DrugMonitor.name].get(
        'maxPoints'
      )?.value;


      ///
      if (drugMonitorExclusion) {
        ePrescribeMax += drugMonitorMax;
        if (ePrescribeExclusion) {
          if (hieSelectedBool) {
            this.hieMax += ePrescribeMax;
          } else if (supportSendExclusion && supportReceieveExclusion) {
            this.providePatientsMax += ePrescribeMax;
          } else if (supportSendExclusion) {
            this.providePatientsMax += ePrescribeMax / 2;
            this.supportReceiveMax += ePrescribeMax / 2;
          } else if (supportReceieveExclusion) {
            this.supportSendMax += ePrescribeMax;
          } else {
            this.hieMax += ePrescribeMax;
            this.supportReceiveMax += ePrescribeMax / 2;
            this.supportSendMax += ePrescribeMax / 2;
            this.tefcaMax += ePrescribeMax;
          }
        }
        else {
          this.ePrescribeMax += drugMonitorMax;
        }
      }
      else {
        if (ePrescribeExclusion) {
          if (hieSelectedBool) {
            this.hieMax += ePrescribeMax;
          } else if (supportSendExclusion && supportReceieveExclusion) {
            this.providePatientsMax += ePrescribeMax;
          } else if (supportSendExclusion) {
            this.providePatientsMax += ePrescribeMax / 2;
            this.supportReceiveMax += ePrescribeMax / 2;
          } else if (supportReceieveExclusion) {
            this.supportSendMax += ePrescribeMax;
          } else {
            this.hieMax += ePrescribeMax;
            this.supportReceiveMax += ePrescribeMax / 2;
            this.supportSendMax += ePrescribeMax / 2;
            this.tefcaMax += ePrescribeMax;
          }
        }
      }
      ///



    if (supportReceieveExclusion && !hieSelectedBool) {
      if (supportSendExclusion) {
        this.providePatientsMax += supportReceiveMax;
      } else {
        this.supportSendMax += supportReceiveMax;
      }
    }

    if (supportSendExclusion && !hieSelectedBool) {
      this.providePatientsMax += supportSendMax;
    }
  }

  setMaxPoints(question: string, value: number): void {
    switch (question) {
      case FormQuestionConstants.Q9ePrescibe.name:
        this.ePrescribeMax = value;
        break;
      case FormQuestionConstants.Q10SupportSend.name:
        this.supportSendMax = value;
        break;
      case FormQuestionConstants.Q11SupportReceive.name:
        this.supportReceiveMax = value;
        break;
      case FormQuestionConstants.Q12ProvideAccess.name:
        this.providePatientsMax = value;
        break;
      case FormQuestionConstants.Q14DrugMonitor.name:
        this.drugMonitorsMax = value;
        break;
      case FormQuestionConstants.Q71HealthInformationExchange.name:
        this.hieMax = value;
        break;
      case FormQuestionConstants.Q82TEFCA.name:
        this.tefcaMax = value;
        break;
    }
  }

  initializePiForm(): void {
    const newDate = new Date();
    const nowJson = newDate.toJSON();
    const month = newDate.getMonth() + 1;
    const nowQtr = Math.ceil(month / 3);

    const userId = this.currentUser.dnn_id;

    this.submitPI = this.fb.group({
      practices: [null, Validators.required],
      isSpecialExemption: [false, Validators.required],
      uploadFile: [null, this.uploadRequiredValidator],

      FormID: [this.QualityFormId],
      FormVersionID: [this.FormVersion],
      FormName: ['Interoperability'],
      FormCategoryID: [1],
      Email: [this.currentUser.email],
      Tier4_FacID: [null, Validators.required],
      Tier4_Name: [null, Validators.required],
      SpecialStatus: [null, this.specialStatusRequiredValidator],
      EHRVendor: [null, Validators.required],
      RegReportOne: [null, Validators.required],
      RegReportTwo: [null, Validators.required],
      FormQuarter: [nowQtr],
      CEHRT: [this.CEHRT],
      FormCreateDateTime: [nowJson],
      CreatedByUserID: [userId],
      ClosedDateTime: [null],
      LastUpdateUserID: [userId],
      LastUpdateDateTime: [nowJson],
      isComplete: [0],
      TotalPoints: [0],
      UserFormID: [null],
      UserID: [userId],
      SubmitPeriodID: [this.selectedFormSubPeriod.toString()],
      FormQ8SecurityRisk: this.fb.group({
        QuestionID: [8],
        QuestionText: ['Security Risk Analysis'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null, 
              [
                Validators.required,
              ]
            ],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
            complete: [null],
          }),
        ]),
      }),
      FormQ9ePrescribe: this.fb.group({
        QuestionID: [9],
        QuestionText: ['e-Prescribing'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        exclusion: [false],
        maxPoints: [10],
        isDisabled: [false],
        scoreZeroWholeForm: [true],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(0),
                CustomValidators.lessThanOrEqual(
                  'FormQ9ePrescribe.FormQResponses.2.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(1),
                CustomValidators.greaterThanOrEqual(
                  'FormQ9ePrescribe.FormQResponses.1.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
        ]),
      }),
      FormQ10SupportSend: this.fb.group({
        QuestionID: [10],
        QuestionText: [
          'Support Electronic Referral Loops By - Sending Health Information',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        exclusion: [false],
        maxPoints: [15],
        isDisabled: [false],
        scoreZeroWholeForm: [true],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(0),
                CustomValidators.lessThanOrEqual(
                  'FormQ10SupportSend.FormQResponses.2.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(1),
                CustomValidators.greaterThanOrEqual(
                  'FormQ10SupportSend.FormQResponses.1.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
        ]),
      }),
      FormQ11SupportReceive: this.fb.group({
        QuestionID: [11],
        QuestionText: [
          'Support Electronic Referral Loops By - Receiving and Reconciling Health Information',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        exclusion: [false],
        maxPoints: [15],
        isDisabled: [false],
        scoreZeroWholeForm: [true],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(0),
                CustomValidators.lessThanOrEqual(
                  'FormQ11SupportReceive.FormQResponses.2.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(1),
                CustomValidators.greaterThanOrEqual(
                  'FormQ11SupportReceive.FormQResponses.1.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
        ]),
      }),
      FormQ12ProvideAccess: this.fb.group({
        QuestionID: [12],
        QuestionText: [
          'Provide Patients Electronic Access to Their Health Information',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        exclusion: [false],
        maxPoints: [25],
        isDisabled: [false],
        scoreZeroWholeForm: [true],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [2],
            FormResponseTypeID: [4],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(0),
                CustomValidators.lessThanOrEqual(
                  'FormQ12ProvideAccess.FormQResponses.2.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [1],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [
              null,
              [
                Validators.required,
                Validators.min(1),
                CustomValidators.greaterThanOrEqual(
                  'FormQ12ProvideAccess.FormQResponses.1.ResponsePointValue'
                ),
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [2],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
          }),
        ]),
      }),
      FormQ14DrugMonitor: this.fb.group({
        QuestionID: [14],
        QuestionText: [
          'Query of Prescription Drug Monitoring Program',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        exclusion: [false],
        maxPoints: [10],
        isDisabled: [false],
        scoreZeroWholeForm: [true],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null,
              [
                Validators.required,
              ],
            ],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
            checked: [false],
          }),
        ]),
      }),
      FormQ71HealthInformationExchange: this.fb.group({
        QuestionID: [71],
        QuestionText: ['Health Information Exchange'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        maxPoints: [30],
        isDisabled: [false],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
            checked: [false],
          }),
        ]),
      }),
      FormQ77HighPriorityGuideSAFER: this.fb.group({
        QuestionID: [77],
        QuestionText: [
          'High Priority Practices Guide of the SAFER Guide Attestation',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
            complete: [null],
          }),
        ]),
      }),
      FormQ78BonusRegistryReporting: this.fb.group({
        QuestionID: [78],
        QuestionText: [
          'Select additional registries that you are reporting on',
        ],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
            checked: [false],
          }),
        ]),
      }),
      FormQ82TEFCA: this.fb.group({
        QuestionID: [82],
        QuestionText: ['Trusted Exchange Framework and Common Agreement'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        maxPoints: [30],
        isDisabled: [false],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [1],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
            checked: [false],
            complete: [null],
          }),
        ]),
      }),
      FormQ83Date: this.fb.group({
        QuestionID: [83],
        QuestionText: ['PI Reporting date range'],
        FormQCategoryID: [1],
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userId],
        exclusion: [false],
        isDisabled: [false],
        FormQResponses: this.fb.array([
          this.fb.group({
            UserResponse: [
                          null, 
                          [
                          Validators.required,
                          CustomValidators.startDateIsInPast(),
                          CustomValidators.sufficentDateRange(FormQuestionConstants.Q83Date.requiredDayDif)
                        ],
            ],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson],
            ResponseUserID: [userId],
            LastUpdateDateTime: [nowJson],
            LastUpdateUserID: [userId],
            periodEndDate: [null],
            periodStartDate: [null],
          }),
        ]),
      }),
    });

    this.resetUploadForm();

    this.submitPI.valueChanges
      .pipe(takeUntil(this.killTrigger))
      .subscribe(() => this.valueChanges());
  }

  public specialStatusRequiredValidator(
    formControl: UntypedFormControl
  ): ValidationErrors {
    if (formControl?.parent?.get('isSpecialExemption').value) {
      return Validators.required(formControl)
        ? {
            spcialStatusRequired: true,
          }
        : null;
    }
    return null;
  }

  public uploadRequiredValidator(formControl: UntypedFormControl): ValidationErrors {
    if (!formControl?.parent?.get('isSpecialExemption').value) {
      return Validators.required(formControl)
        ? {
            uploadRequired: true,
          }
        : null;
    }
    return null;
  }

  public specialExemptionChange(): void {
    this.submitPI.get('uploadFile').updateValueAndValidity();
  }

  public isSpecialStatusChanged(): void {
    if (!this.submitPI.get('isSpecialExemption').value) {
      this.submitPI.patchValue({ SpecialStatus: null }, { emitEvent: false });
    }
    this.isExempt = this.submitPI.get('isSpecialExemption').value;

    if (this.isExempt) {
      this.disableFormValidators([
        FormQuestionConstants.Q9ePrescibe.name,
        FormQuestionConstants.Q10SupportSend.name,
        FormQuestionConstants.Q11SupportReceive.name,
        FormQuestionConstants.Q12ProvideAccess.name,
      ]);

      this.submitPI
      .get(`RegReportOne`)
      .clearValidators();

      this.submitPI
        .get(`RegReportOne`)
        .updateValueAndValidity();

      this.submitPI
      .get(`RegReportTwo`)
      .clearValidators();

      this.submitPI
        .get(`RegReportTwo`)
        .updateValueAndValidity();

      this.submitPI
        .get(`FormQ83Date.FormQResponses.0.UserResponse`)
        .clearValidators();

      this.submitPI
        .get(`FormQ83Date.FormQResponses.0.UserResponse`)
        .updateValueAndValidity();

      this.submitPI
        .get(`FormQ14DrugMonitor.FormQResponses.0.ResponsePointValue`)
        .clearValidators();

      this.submitPI
        .get(`FormQ14DrugMonitor.FormQResponses.0.ResponsePointValue`)
        .updateValueAndValidity();  
        
        
      this.submitPI
        .get(`FormQ8SecurityRisk.FormQResponses.0.UserResponse`)
        .clearValidators();

      this.submitPI
        .get(`FormQ8SecurityRisk.FormQResponses.0.UserResponse`)
        .updateValueAndValidity();
    
    } else {
      this.enableFormValidators([
        FormQuestionConstants.Q9ePrescibe.name,
        FormQuestionConstants.Q10SupportSend.name,
        FormQuestionConstants.Q11SupportReceive.name,
        FormQuestionConstants.Q12ProvideAccess.name,
      ]);

      this.submitPI
      .get(`RegReportOne`)
      .setValidators([
        Validators.required
      ]);

      this.submitPI
      .get(`RegReportOne`)
        .updateValueAndValidity();


      this.submitPI
      .get(`RegReportTwo`)
      .setValidators([
        Validators.required
      ]);

      this.submitPI
        .get(`RegReportTwo`)
        .updateValueAndValidity();

      this.submitPI
        .get(`FormQ83Date.FormQResponses.0.UserResponse`)
        .setValidators([
          Validators.required,
          CustomValidators.startDateIsInPast(),
          CustomValidators.sufficentDateRange(FormQuestionConstants.Q83Date.requiredDayDif)
        ]);

      this.submitPI
        .get(`FormQ83Date.FormQResponses.0.UserResponse`)
        .updateValueAndValidity();

      this.submitPI
        .get(`FormQ8SecurityRisk.FormQResponses.0.UserResponse`)
        .setValidators([
          Validators.required
        ]);

      this.submitPI
        .get(`FormQ8SecurityRisk.FormQResponses.0.UserResponse`)
        .updateValueAndValidity();
        
      this.submitPI
        .get(`FormQ14DrugMonitor.FormQResponses.0.ResponsePointValue`)
        .setValidators([
          Validators.required
        ]);

      this.submitPI
        .get(`FormQ14DrugMonitor.FormQResponses.0.ResponsePointValue`)
        .updateValueAndValidity();

    }

    this.specialExemptionChange();
  }

  public toggleQueueOpen(): void {
    this.isQueueOpen = !this.isQueueOpen;
  }

  public cancel(): void {
    this.isAddEditFormActive = false;
  }

  public onFileChange(event: Event): void {
    const target = <HTMLInputElement>event.target;
    if (target.files && target.files.length) {
      const file = target.files[0];
      this.uploadFile = file;
      this.uploadedFileName = file.name || 'File name not found';
      this.submitPI.get('uploadFile').setValue(file.name);
    } else {
      this.resetUploadForm();
    }
  }

  private resetUploadForm(): void {
    this.uploadFile = null;
    this.uploadedFileName = 'No file chosen';
    this.submitPI.get('uploadFile').setValue(null);
  }

  public toggleFormQueueOpen(): void {
    this.isFormQueueOpen = !this.isFormQueueOpen;
  }

  public toggleAddEditFormActive(): void {
    this.isAddEditFormActive = !this.isAddEditFormActive;
  }

  public onSubmit(isComplete: boolean): void {
    if (isComplete) {
      this.toastr.info('Form Submitting', 'Promoting Interoperability Form');
    }
    else {
      this.toastr.info('Form Saving', 'Promoting Interoperability Form');
    }
    if (isComplete && this.submitPI.invalid) {
      this.submitPI.markAllAsTouched();
    } else {
      this.submitPI.controls['isComplete'].setValue(isComplete ? 1 : 0);

      if (this.hieSelected) {
        this.submitPI
          .get(
            `${FormQuestionConstants.Q10SupportSend.name}.FormQResponses.1.ResponsePointValue`
          )
          .setValue(null);
        this.submitPI
          .get(
            `${FormQuestionConstants.Q10SupportSend.name}.FormQResponses.2.ResponsePointValue`
          )
          .setValue(null);
        this.submitPI
          .get(
            `${FormQuestionConstants.Q11SupportReceive.name}.FormQResponses.1.ResponsePointValue`
          )
          .setValue(null);
        this.submitPI
          .get(
            `${FormQuestionConstants.Q11SupportReceive.name}.FormQResponses.2.ResponsePointValue`
          )
          .setValue(null);
      }
      if (this.submitPI.get('practices').value == null){
        this.submitPI.get('practices').markAsTouched();
        this.toastr.error('Form Failed To Save', 'Please Select Practice First');
      } else {
        let formCopy = Object.assign({}, this.submitPI.getRawValue());
        if (this.submitPI.controls['isSpecialExemption'].value) {
          formCopy = this.removeQuestions(formCopy);
        }

        if (formCopy.SpecialStatus == null) {
          formCopy.SpecialStatus = '';
        }

        this.formSaving = true;
        this.submitFormsService
          .save([this.uploadFile], formCopy)
          .pipe(finalize(() => (this.formSaving = false)))
          .subscribe(
            () => {

              if (isComplete) {
                this.toastr.success('Form Submitted', 'Promoting Interoperability Form');
              }
              else {
                this.toastr.success('Draft Saved', 'Promoting Interoperability Form');
              }
              // this.toastr.success(
              //   'Form Submitted',
              //   'Promoting Interoperability Form'
              // );
              this.refreshFormData();
              this.resetForms();
              this.toggleAddEditFormActive();
            },
            (err) => {
              console.error(err);
              this.toastr.error(
                'Form Failed To Save',
                'Promoting Interoperability Form'
              );
            }
          );
      }
    }
  }

  resetForms(): void {
    this.initializePiForm();
  }

  public removeQuestions(formCopy: any): void {
    const copy = formCopy;
    this.questionNames.forEach((question) => delete copy[question]);
    return copy;
  }

  public toggleInfoText(details: ToolTipMeasureDetails): void {
    this.isInfoTextOpen = !this.isInfoTextOpen;
    if (details != null) {
      this.infoText = details.detailsText;
      this.infoMeasureLinkFile = details.modalLinks;
      this.infoText = details.detailsText;
    }
  }

  public showInfoWithText(text: string): void {
    this.toggleInfoText({ detailsText: text, modalLinks: null });
  }

  newSubmission(): void {
    const submissionID = this.selectedFormSubPeriod.toString();
    const specialStatusIncluded = this.shwSpecialStatus? '1':'0';
    this.submitFormsService
      .getListOfSpecificTier(
        null,
        SubmitFeatureConstants.PromotingInteroperabilityFormFeature,
        submissionID,
        specialStatusIncluded

      )
      .subscribe((data) => {
        this.setPractices(data);
        this.toggleAddEditFormActive();
      });
      this.initializePiForm();
  }

  private setPractices(data: string): void {
    if(data != ''){
      this.practices = JSON.parse(data);
    }
  }

  public refreshFormData(): void {
    const formID = this.QualityFormId.toString();
    const submissionID = this.selectedFormSubPeriod.toString();
    this.submitFormsService.getStatuses(formID).subscribe((data) => {
      this.specialStatusList = this.getFilteredStatusList(JSON.parse(data));
    });

    this.submitFormsService.getSubmissions(formID, submissionID).subscribe((data) => {
      this.formSubmissions = data;
    });

    this.submitFormsService.getStatusSummary(formID).subscribe((data) => {
      this.specialStatusSummary = data;
    });
  }

  public getFilteredStatusList(
    statuses: CaravanSpecialStatus[]
  ): CaravanSpecialStatus[] {
    const statusList = statuses.filter(
      (s) =>
        s.formID === this.QualityFormId &&
        s.isDeleted === 0 &&
        s.isVisible === 1
    );
    return statusList;
  }

  updateSpecialStatus(statusSummary: SpecialStatusSummary): void {
    const params: UpdateQualityStatusParams = {
      formID: this.QualityFormId,
      isDeleted: statusSummary.IsDeleted,
      otherReason: statusSummary.OtherReason,
      specialStatus: statusSummary.SpecialStatusID,
      specialstatusEntryID: statusSummary.specialstatusEntryID,
      userFormID: statusSummary.UserFormID,
      tier: null,
    };

    this.submitFormsService
      .updateQualityStatus(params)
      .subscribe(() => this.refreshFormData());
  }

  removeConfirmation(statusSummary: SpecialStatusSummary): void {
    this.exemptionToRemove = statusSummary;
    this.showRemoveModal = true;
  }

  removeSubmission(): void {
    this.exemptionToRemove.IsDeleted = true;
    this.updateSpecialStatus(this.exemptionToRemove);
    this.showRemoveModal = false;
    this.specialStatusSummary.splice(
      this.specialStatusSummary.findIndex(
        (x) =>
          x.specialstatusEntryID === this.exemptionToRemove.specialstatusEntryID
      ),
      1
    );
    this.exemptionToRemove = null;
  }

  public practiceSelected(facId: string): void {
    // update fac_id
    const facicilityId = parseInt(facId);
    const practice = this.practices.find((x) => x.Fac_ID === facicilityId);
    this.submitPI.patchValue(
      { Tier4_FacID: practice.Fac_ID },
      { emitEvent: false }
    );
    this.submitPI.get('Tier4_FacID').markAsTouched({ onlySelf: true });
    // update names
    this.submitPI.patchValue({ Tier4_Name: practice.Name });
    this.submitPI.get('Tier4_Name').markAsTouched({ onlySelf: true });
  }

  editForm(submission: FormSubmission): void {
    const submissionID = this.selectedFormSubPeriod.toString();
    const specialStatusIncluded = this.shwSpecialStatus? '1':'0';
    forkJoin([
      this.submitFormsService.getListOfSpecificTier(
        submission.UserFormID,
        SubmitFeatureConstants.PromotingInteroperabilityFormFeature,
        submissionID,
        specialStatusIncluded
      ),
      this.submitFormsService.getFormResponses(submission.UserFormID),
    ]).subscribe((data) => {
      const submission = data[1][0];
      const facilities = JSON.parse(data[0]) as CaravanPracticeDropdown[];

      this.setPractices(data[0]);
      this.loadFromSubmission(submission, facilities);
      this.isAddEditFormActive = true;
    });
  }

  loadFromSubmission(
    submission: FormResponses,
    facilities: CaravanPracticeDropdown[]
  ): void {
    const facilitySelected = facilities.find((x) => x.Fac_ID.toString() === submission.SelectedFacs);
    this.submitPI.get('EHRVendor').setValue(submission.EHRVendor);
    this.submitPI.get('UserFormID').setValue(submission.UserFormID);
    this.submitPI.get('TotalPoints').setValue(submission.TotalPoints);
    this.submitPI.get('RegReportOne').setValue(submission.regreportone);
    this.submitPI.get('RegReportTwo').setValue(submission.regreporttwo);

    if (facilitySelected){
    this.submitPI.get('Tier4_Name').setValue(facilitySelected.Name);
    this.submitPI.get('Tier4_FacID').setValue(facilitySelected.Fac_ID);
    this.submitPI.get('practices').setValue(facilitySelected.Fac_ID);
   }
   else {
    this.submitPI.get('Tier4_Name').setValue(facilities[0].Name);
    this.submitPI.get('Tier4_FacID').setValue(facilities[0].Fac_ID);
    this.submitPI.get('practices').setValue(facilities[0].Fac_ID);
   }

    if (submission.SpecialStatusID && submission.SpecialStatusID !== '0') {
      this.submitPI.get('isSpecialExemption').setValue(true);
      this.isExempt = true;
      this.submitPI
        .get('SpecialStatus')
        .setValue(parseInt(submission.SpecialStatusID));
    } else {
      this.submitPI.get('isSpecialExemption').setValue(false);
      this.isExempt = false;
    }

    this.submitPI.get('RegReportOne').setValue(submission.regreportone);
    this.submitPI.get('RegReportTwo').setValue(submission.regreporttwo);

    submission.Q.forEach((question) => {
      for (const property in this.submitPI.controls) {
        const questionControl = this.submitPI.get(`${property}.QuestionID`);
        const questionId = questionControl?.value;
        if (questionControl != null && questionId === question.ID) {
          const numerator = this.submitPI.get(
            `${property}.FormQResponses.1.ResponsePointValue`
          );
          const denominator = this.submitPI.get(
            `${property}.FormQResponses.2.ResponsePointValue`
          );
          const points = this.submitPI.get(
            `${property}.FormQResponses.0.ResponsePointValue`
          );

          if (question.Exclusion !== 'NA') {
            const exclusion = this.submitPI.get(`${property}.exclusion`);
            exclusion.setValue(true);
            const exclusionDataType = this.submitPI.get(`${property}.FormQResponses.0.FormResponseTypeID`);
            exclusionDataType.setValue(this.exclusionTypeId);

            if (question.ID == 14) {
              this.exclusionRequestCheckBox({
                questionname: property,
                status: true,
              });
            }
            else {
              this.exclusionRequest({
                questionname: property,
                status: true,
              });
            }
          }

          if (question.ID == 82) {
            console.log(question.ID);
            this.exclusionRequestTEFCA(question.Response);
          }


          const userResponse = this.submitPI.get(
            `${property}.FormQResponses.0.UserResponse`
          );
          userResponse.setValue(question.Response || null);

          if (question.Exclusion !== 'NA') {
            if (numerator != null ) {
              numerator.setValue(Number(-1));
            }
            if (denominator != null) {
              denominator.setValue(Number(-1));
            }
            if (points != null) {
              points.setValue(Number(0));
            }
          }

          if(question.Exclusion === 'NA') {
            if (numerator != null) {
              numerator.setValue(Number(question.Numerator));
            }
            if (denominator != null) {
              denominator.setValue(Number(question.Denominator));
            }
            if (points != null) {
              points.setValue(Number(question.Points));
            }
          }

        }
      }
    });

    this.resetUploadForm();
  }

  hieChanged(isChecked: string): void {
    if (isChecked){
      this.hieSelected = true;
    }
    else {
      this.hieSelected = false;
    }

    this.submitPI.get('FormQ10SupportSend.isDisabled').setValue(this.hieSelected);
    this.submitPI.get('FormQ11SupportReceive.isDisabled').setValue(this.hieSelected);

    // set validator to false;
    if (this.hieSelected) {
      this.disableFormValidators([
        'FormQ10SupportSend',
        'FormQ11SupportReceive',
      ]);
    } else {
      this.enableFormValidators([
        'FormQ10SupportSend',
        'FormQ11SupportReceive',
      ]);
    }
  }


  exclusionRequest(isChecked: ExclusionRequestDetails): void {
    // set validator to false;
    if (isChecked.status) {
      this.disableFormValidators([
        isChecked.questionname
      ]);
    } else {
      this.enableFormValidators([
        isChecked.questionname
      ]);
    }
  }

  exclusionRequestCheckBox(isChecked: ExclusionRequestDetails): void {
    // set validator to false;
    if (isChecked.status) {
      this.disableFormValidatorsCheckBox([
        isChecked.questionname
      ]);
      //this.submitPI.get('FormQ14DrugMonitor.isDisabled').setValue(true);
    } else {
      this.enableFormValidatorsCheckBox([
        isChecked.questionname
      ]);
       //this.submitPI.get('FormQ14DrugMonitor.isDisabled').setValue(false);
    }
  }

  exclusionRequestTEFCA(radioBoxResp): void {
    // set validator to false;

    if (radioBoxResp == 'yes') {
      this.yesOnTEFCA = true;

      this.submitPI.get('FormQ10SupportSend.FormQResponses.0.ResponsePointValue').setValue(0);

      this.submitPI.get('FormQ11SupportReceive.FormQResponses.0.ResponsePointValue').setValue(0);

    }
    else
    {
      this.yesOnTEFCA = false;
    }

    this.submitPI.get('FormQ10SupportSend.isDisabled').setValue(this.yesOnTEFCA);

    this.submitPI.get('FormQ11SupportReceive.isDisabled').setValue(this.yesOnTEFCA);

    this.submitPI.get('FormQ71HealthInformationExchange.isDisabled').setValue(this.yesOnTEFCA);

    if (this.yesOnTEFCA) {
      this.disableFormValidators([
        'FormQ10SupportSend',
        'FormQ11SupportReceive',
      ]);
    } else {
        const checkBoxCheck = this.submitPI.get('FormQ71HealthInformationExchange.FormQResponses.0.UserResponse');
        if ( checkBoxCheck.value == null) {
          this.enableFormValidators([
            'FormQ10SupportSend',
            'FormQ11SupportReceive',
          ]);
        }
        else {
          this.submitPI.get('FormQ10SupportSend.isDisabled').setValue(!this.yesOnTEFCA);

          this.submitPI.get('FormQ11SupportReceive.isDisabled').setValue(!this.yesOnTEFCA);
        }
    }
  }

  private enableFormValidators(quesitonNames: string[]): void {
    quesitonNames.forEach((name) => {
        this.submitPI
        .get(`${name}.FormQResponses.1.ResponsePointValue`)
        .setValidators([
          Validators.required,
          Validators.min(0),
          CustomValidators.lessThanOrEqual(
            `${name}.FormQResponses.2.ResponsePointValue`
          ),
        ]);
      this.submitPI
        .get(`${name}.FormQResponses.1.ResponsePointValue`)
        .updateValueAndValidity();

      this.submitPI
        .get(`${name}.FormQResponses.2.ResponsePointValue`)
        .setValidators([
          Validators.required,
          Validators.min(1),
          CustomValidators.greaterThanOrEqual(
            `${name}.FormQResponses.1.ResponsePointValue`
          ),
        ]);
      this.submitPI
        .get(`${name}.FormQResponses.2.ResponsePointValue`)
        .updateValueAndValidity();

    });
  }

  private disableFormValidators(quesitonNames: string[]): void {
    quesitonNames.forEach((name) => {
      this.submitPI
        .get(`${name}.FormQResponses.1.ResponsePointValue`)
        .clearValidators();

      this.submitPI
        .get(`${name}.FormQResponses.1.ResponsePointValue`)
        .updateValueAndValidity();

      this.submitPI
        .get(`${name}.FormQResponses.2.ResponsePointValue`)
        .clearValidators();
      this.submitPI
        .get(`${name}.FormQResponses.2.ResponsePointValue`)
        .updateValueAndValidity();

    });
  }

  private enableFormValidatorsCheckBox(quesitonNames: string[]): void {
    quesitonNames.forEach((name) => {
        this.submitPI
        .get(`${name}.FormQResponses.0.ResponsePointValue`)
        .setValidators([
          Validators.required
        ]);
      this.submitPI
        .get(`${name}.FormQResponses.0.ResponsePointValue`)
        .updateValueAndValidity();

    });
  }

  private disableFormValidatorsCheckBox(quesitonNames: string[]): void {
    quesitonNames.forEach((name) => {
      this.submitPI
        .get(`${name}.FormQResponses.0.ResponsePointValue`)
        .clearValidators();

      this.submitPI
        .get(`${name}.FormQResponses.0.ResponsePointValue`)
        .updateValueAndValidity();
    });
  }
  formsReport(): void {
    this.router.navigate(['promoting-interoperability/formreport'], { relativeTo: this.route.parent });
  }

}
