import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {Patient} from '@shared/models/patient';
import {IHierarchyTier} from '@shared/models/hierarchy/hierarchy-tier';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {AwvWorkflowStatuses} from '@api/care-orch/models/awv-workflow-statuses';
import {AwvWorkflow} from '@api/care-orch/models/awv-workflow';
import {Observable} from 'rxjs';
import {AwvService} from '@api/care-orch/services/awv.service';
import {AttendedActivity} from '@api/care-orch/models/attended-activity';
import {ActivityType} from '@api/care-orch/models/activity-type';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../../environments/environment';
import {EnvironmentType} from '../../../../../../environments/environmentTypeDef';
import {PracticeService} from '@shared/services/hierarchy/practice.service';
import {AwvWorkflowService} from '@shared/services/awv-workflow/awv-workflow.service';
import { BackendError } from '@shared/services/awv-workflow/backend-error';
import { AwvActivityChangeEvent } from '@shared/services/awv-workflow/awv-activity-change-event';
import { Program, TierIds } from '@shared/services/mixpanel/events/workflow-events';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';

@Component({
  selector: 'coach-patient-awv-workflow',
  styleUrls: ['./patient-awv-workflow.component.scss'],
  templateUrl: './patient-awv-workflow.component.html',
})
export class PatientAwvWorkflowComponent {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input() public mobileScreenSize: boolean;
  @Input() sidebarMode?: boolean;
  @Input() public isTabOpen: boolean;
  @Output() patientUpdated: EventEmitter<null> = new EventEmitter();

  awvWorkflowStatus: AwvWorkflowStatuses = AwvWorkflowStatuses.Other;
  awvWorkflow?: AwvWorkflow = null;
  allAwvWorkflowStatuses = AwvWorkflowStatuses;
  isLoading = false;
  attendedActivityWasSubmitted = false;
  inError = false;
  errorTitle = '';
  errorMessage = '';

  constructor(private careOrchService: AwvService, private practiceService: PracticeService, private toastr: ToastrService,
              private awvWorkflowService: AwvWorkflowService,
              private workflowEvents: WorkflowEventsService, private authService: AuthService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.patient) {
      const tier2ID = this.patient.tier2_ID;
      const tier3ID = this.patient.tier3_ID;
      let tier4ID = null;
      if (this.patient.trackPatient){
        tier4ID = this.patient.trackPatient.tier4_ID;
      }
      const tierIds = new TierIds(tier2ID, tier3ID, tier4ID);
      this.getUserInfo().then((user) => this.workflowEvents.trackProgramWithPatient(Program.AWV, Number(this.patient.chPatId),
        tierIds, user.email));
      this.practiceService.getPractices(this.patient).then();
      this.awvWorkflowStatus = AwvWorkflowStatuses.Other;
      this.awvWorkflow = null;
      this.attendedActivityWasSubmitted = false;
      let obsAwvWorkflow = this.careOrchService.getWorkflowActivities(
        {programId: CareOrchestrationConstants.AwvProgramId, chPatId: parseInt(this.patient.chPatId)});
      this.awvWorkflowUpdated(obsAwvWorkflow);
    }

  }
  awvWorkflowUpdated(obsAwvWorkflow: Observable<AwvWorkflow>): void {
    this.isLoading = true;
    this.inError = false;

    obsAwvWorkflow.subscribe(awvWorkflow => {
      const awvActivityChangeEvent = new AwvActivityChangeEvent(Number(this.patient.chPatId), this.awvWorkflow, awvWorkflow);
      this.awvWorkflowService.publishAwvActivityChangeEvent(awvActivityChangeEvent);
      this.workflowEvents.emit(this.awvWorkflow, awvWorkflow);
      this.awvWorkflow = awvWorkflow;
      this.awvWorkflowStatus = this.awvWorkflow.status;
      this.isLoading = false;
      const attendedActivities = this.awvWorkflow.completedActivities.filter(x => x.type === 'AttendedActivity')
        .map(x => x as AttendedActivity);
      this.attendedActivityWasSubmitted = attendedActivities.length > 0;
      this.awvWorkflowService.publishUpdateSuccessful();
    }, error => {
      this.workflowEvents.emit(this.awvWorkflow, this.awvWorkflow, error);
      this.isLoading = false;
      let backendError = null;
      if (error.status >= 500 && this.awvWorkflow === null){
        this.inError = true;
        this.errorTitle = error.status + ' Server Error';
        this.errorMessage = CareOrchestrationConstants.serverErrorMessageFrontend;
      } else {
        if (error.status === 404 ){
          // no workflow found for this patient, so show default screen
          this.awvWorkflowStatus = AwvWorkflowStatuses.Other;
        } else {
          let userFriendlyErrorMessage = null;
          if (error.status === 403 && error.error.detail.includes(CareOrchestrationConstants.forbiddenErrorMessageBackend)){
            userFriendlyErrorMessage = CareOrchestrationConstants.forbiddenErrorMessageFrontend;
          } else if (error.status === 409){
            userFriendlyErrorMessage = CareOrchestrationConstants.conflictErrorMessageFrontend;
          } else {
            userFriendlyErrorMessage = CareOrchestrationConstants.defaultErrorMessage;
          }
          backendError = new BackendError(error.status, error.statusText, userFriendlyErrorMessage);
          this.awvWorkflowService.publishBackendError(backendError);
        }
      } });
  }

  activityDeleted(activityType: ActivityType): void{
    this.workflowEvents.trackDelete(activityType);

    const awvWorflowObs = this.careOrchService.rollbackWorkflowActivity({
      programId: CareOrchestrationConstants.AwvProgramId,
      chPatId: Number(this.patient.chPatId),
      typeOfActivityToRollback: activityType,
    });

    this.awvWorkflowUpdated(awvWorflowObs);
  }

  isLowerEnvironment(): boolean {
    return (environment.environmentId === EnvironmentType.dev || environment.environmentId === EnvironmentType.qa);
  }

  isInactiveStatus(): boolean {
    return (this.awvWorkflowStatus === AwvWorkflowStatuses.Other ||
      this.awvWorkflowStatus === AwvWorkflowStatuses.DeferredToSignify ||
      this.awvWorkflowStatus === null);
  }

  async getUserInfo(): Promise<wpapi.model.Professional> {
    return await this.authService.getWellpepperUserInfo();
  }
}
