<meta name="viewport" content="width=device-width, initial-scale=1">
<div class = "content-body"> 
<div class="scroll">   
    <div class="header">
        <div class="header-rectangle" style="background-color: var(--brand--5);">
            <h2 class="header-title">Information Technology</h2>
            <p class="header-sub-title">
              The Information Technology section contains guidance documents and technical resources for {{app.ApplicationName}}, including trainings, guides, parameters, and specification documents. If you have questions regarding the logic, reasoning, or process, check for answers here! 
            </p>            
        </div>
        <img class="img-fluid header-image" src="assets\img\illustrations\SH_PEP_Illustrations_Improve_Sum_IT_1700x1100_20221114.svg"/>
    </div>

  <br>
  <br>   
  <div class = "description">
    <p>Please find supporting documentation by browsing the folders in the left panel. You may also search document titles and contents using the site search located in the top-right of your screen. </p>

 <br>  
  In this section you will find:  
<br> <br>

<ul>
<li>
  <p>
<b> {{app.ApplicationName}} Training Support: </b> Looking for an introduction to {{app.ApplicationName}}? Check the Navigation Tutorials folder to find instructional videos to share with new staff members. Stay up to date with the latest {{app.ApplicationName}} enhancements with the User Guide and Release Notes.  </p>

</li>
<li>
<p>
<b> {{app.ApplicationName}} Data Timelines and Parameters: </b> Check the Track Parameters folder for report configuration and check the Care Parameters folder to see what qualifies a patient for a cohort and information on {{app.ApplicationName}} Predicted Risk Category.  </p>
 
</li>

<li>
<p>
<b>Integration Resources: </b> Documents to support the implementation of a uni-directional interface of your EHR clinical data to the {{app.CompanyName}} Data Warehouse. </p>
</li>
</ul>    
</div>


  <!--

  <table> 
    <tr> 
      <td class = 'item1'> 
        <h4> Most Recent Meeting <br> Date</h4>   
        <p> Here are the documents from the most recent meeting:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>
      
      <td class = 'item2'> 
        <h4> Important Content <br> </h4>   
        <p> Here are some useful links:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>

    </tr> 
  
  </table>
-->

</div>  
</div> 


