import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PatientService } from '@shared/services/patient/patient.service';
import { Patient, PatientViewModel } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { ActivatedRoute } from '@angular/router';
import { CareOrchestrationConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { AwvWorkflowStatuses } from '../../../../api/care-orch/models/awv-workflow-statuses';
import { AwvService } from '../../../../api/care-orch/services/awv.service';
import { AwvWorkflow } from '../../../../api/care-orch/models/awv-workflow';
import { Observable } from 'rxjs';
import { AttendedActivity } from '@api/care-orch/models/attended-activity';
import { ActivityType } from '@api/care-orch/models/activity-type';
import { environment } from '../../../../../environments/environment';
import { EnvironmentType } from '../../../../../environments/environmentTypeDef';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { PracticeService } from '@shared/services/hierarchy/practice.service';
import { AwvWorkflowService } from '@shared/services/awv-workflow/awv-workflow.service';
import { BackendError } from '@shared/services/awv-workflow/backend-error';
import { AwvActivityChangeEvent } from '@shared/services/awv-workflow/awv-activity-change-event';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';
import { Program, TierIds } from '@shared/services/mixpanel/events/workflow-events';
import { OnChanges } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';

@Component({
  selector: 'coach-care-patient-sidebar',
  templateUrl: './care-patient-sidebar.component.html',
  styleUrls: ['./care-patient-sidebar.component.scss']
})
export class CarePatientSidebarComponent implements OnInit, OnChanges {
  isSidebarOpen = false;
  @Input() tier: IHierarchyTier;
  private get tierId(): string {
    return this?.tier?.selectedTierId;
  }
  @Input() orgId: string;
  @Input() forcePatientSideBar: { open: boolean } = { open: false }; // force sidebar close from parent component
  @Input() patientId: string;
  @Input() selectedPatient: PatientViewModel;
  @Input() patientUrlQueryParams: string[];
  @Output() updatePatientByIdInList: EventEmitter<Patient> = new EventEmitter();

  filterTitle: string = null;
  patient: Patient = null;
  patientUrl: string = null;
  public readonly CareOrchAccessReqs = CareOrchestrationConstants.SideBarAccessRequirements;
  awvWorkflowStatus: AwvWorkflowStatuses = AwvWorkflowStatuses.Other;
  awvWorkflow?: AwvWorkflow = null;
  allAwvWorkflowStatuses = AwvWorkflowStatuses;
  isLoading = false;
  attendedActivityWasSubmitted = false;
  inError = false;
  errorTitle = '';
  errorMessage = '';
  isLinkDisabled = false;

  constructor(private patientService: PatientService,
              private route: ActivatedRoute,
              private careOrchService: AwvService,
              private featureFlagService: FeatureFlagService,
              private userAccessService: UserAccessService,
              private practiceService: PracticeService,
              private awvWorkflowService: AwvWorkflowService,
              private authService: AuthService,
              private workflowEvents: WorkflowEventsService)
  {}

  ngOnInit(): void {
    this.filterTitle = this.route.snapshot.queryParamMap.get('filterTitle');
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.isLinkDisabled = false;
    if (!this.filterTitle || this.filterTitle !== this.route.snapshot.queryParamMap.get('filterTitle')) {
      this.filterTitle = this.route.snapshot.queryParamMap.get('filterTitle');
    }
    if (this.patientId) {
      this.getPatientData().then(r => {
        const tier2ID = this.patient.tier2_ID;
        const tier3ID = this.patient.tier3_ID;
        let tier4ID = null;
        if (this.patient.trackPatient){
          tier4ID = this.patient.trackPatient.tier4_ID;
        }
        const tierIds = new TierIds(tier2ID, tier3ID, tier4ID);
        this.getUserInfo().then((user) => {
          this.workflowEvents.trackProgramWithPatient(Program.AWV, Number(this.patientId),
            tierIds, user.email);
        });
      });
      this.isSidebarOpen = true;

      if (this.hasCareOrchFeatureFlagAndComponents() && this.filterTitle === 'AWV Opportunities') {
        this.practiceService.getPractices(this.patient).then();
        this.awvWorkflowStatus = AwvWorkflowStatuses.Other;
        this.awvWorkflow = null;
        this.attendedActivityWasSubmitted = false;
        const obsAwvWorkflow = this.careOrchService.getWorkflowActivities(
          {programId: CareOrchestrationConstants.AwvProgramId, chPatId: parseInt(this.patientId)});
        this.awvWorkflowUpdated(obsAwvWorkflow);
      }
    }

    if (this.forcePatientSideBar?.open) {
      this.isSidebarOpen = true;
    }
    if (this.selectedPatient.trackAwvPatient !== undefined && this.selectedPatient.trackAwvPatient.hasFacesheet === 0) {
      this.isLinkDisabled = true;
    }
  }

  async getUserInfo(): Promise<wpapi.model.Professional> {
    return await this.authService.getWellpepperUserInfo();
  }

  private hasCareOrchFeatureFlagAndComponents(): boolean {
    return this.featureFlagService.hasFeatureFlag(this.CareOrchAccessReqs.featureFlagId) &&
      this.CareOrchAccessReqs.componentIds.every(id =>
        this.userAccessService.hasComponent(this.userAccessService.currentAccess$.value, id));
  }

  public toggleValidatePanel(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
  async getPatientData(): Promise<void> {
    if (this.selectedPatient.trackPatient) {
      this.patient = Patient.fromCareAndTrackPatient(this.selectedPatient.carePatient, this.selectedPatient.trackPatient);
    } else if (this.selectedPatient.trackAwvPatient) {
      this.patient = Patient.fromCareAndTrackAwvPatient(this.selectedPatient.carePatient, this.selectedPatient.trackAwvPatient);
    }
    this.patientUrl = `../patient/${this.patientId}`;
  }
  patientUpdated(): void {
    this.updatePatientByIdInList.emit(this.patient);
  }

  awvWorkflowUpdated(obsAwvWorkflow: Observable<AwvWorkflow>): void {
    this.isLoading = true;
    this.inError = false;

    obsAwvWorkflow.subscribe(awvWorkflow => {
      const awvActivityChangeEvent = new AwvActivityChangeEvent(Number(this.patient.chPatId), this.awvWorkflow, awvWorkflow);
      this.awvWorkflowService.publishAwvActivityChangeEvent(awvActivityChangeEvent);
      this.workflowEvents.emit(this.awvWorkflow, awvWorkflow);
      this.awvWorkflow = awvWorkflow;
      this.awvWorkflowStatus = this.awvWorkflow.status;
      this.isLoading = false;
      const attendedActivities = this.awvWorkflow.completedActivities.filter(x => x.type === 'AttendedActivity')
        .map(x => x as AttendedActivity);
      this.attendedActivityWasSubmitted = attendedActivities.length > 0;
      this.awvWorkflowService.publishUpdateSuccessful();
    }, error => {
      this.workflowEvents.emit(this.awvWorkflow, this.awvWorkflow, error);
      this.isLoading = false;
      let backendError = null;
      if (error.status === 404){
        // no workflow found for this patient, so show default screen
        this.awvWorkflowStatus = AwvWorkflowStatuses.Other;
      } else if (this.awvWorkflow === null){
        this.inError = true;
        this.errorTitle = error.status + ' Error';
        this.errorMessage = CareOrchestrationConstants.serverErrorMessageFrontend;
      } else {
          let userFriendlyErrorMessage = null;
          if (error.status === 403 && error.error.detail.includes(CareOrchestrationConstants.forbiddenErrorMessageBackend)){
            userFriendlyErrorMessage = CareOrchestrationConstants.forbiddenErrorMessageFrontend;
          } else if (error.status === 409){
            userFriendlyErrorMessage = CareOrchestrationConstants.conflictErrorMessageFrontend;
          } else {
            userFriendlyErrorMessage = CareOrchestrationConstants.defaultErrorMessage;
          }
          backendError = new BackendError(error.status, error.statusText, userFriendlyErrorMessage);
          this.awvWorkflowService.publishBackendError(backendError);
        }
       });
  }

  activityDeleted(activityType: ActivityType): void{
    this.workflowEvents.trackDelete(activityType);

    const awvWorflowObs = this.careOrchService.rollbackWorkflowActivity({
      programId: CareOrchestrationConstants.AwvProgramId,
      chPatId: Number(this.patientId),
      typeOfActivityToRollback: activityType,
    });

    this.awvWorkflowUpdated(awvWorflowObs);
  }

  isLowerEnvironment(): boolean {
    return (environment.environmentId === EnvironmentType.dev || environment.environmentId === EnvironmentType.qa);
  }
}