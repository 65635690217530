import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackReportService } from '@track/track-reports/services/track-report.service';
import { TrackEmbeddedReport } from '@track/track-reports/track-embedded-report';
import { TrackReportFilter } from '@track/track-reports/track-report-filter';
import { IEmbedConfiguration, models, Report } from 'powerbi-client';
import { IError } from 'powerbi-models';
import { NgxPowerBiService } from 'ngx-powerbi';
import { interval } from 'rxjs';
import { Observable } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { ReportAuditRequest } from './report-audit-request';
import { TrackReportParameters } from './track-report-parameters';
import { TrackQuarterYearsService } from '@track/track-reports/services/track-quarter-years.service';
import { TrackQuarterYear } from '@track/track-reports/track-quarter-year';
import { TrackReportMenuItemsService } from '@track/track-reports/services/track-report-menu-items.service';
import { TrackReportConfig } from '@track/track-reports/track-report-config';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { stringToNumber } from '@shared/utilities';
import { ACO_REPORT_LEVEL, CIN_REPORT_LEVEL, REGION_REPORT_LEVEL } from '@track/track-reports/track-report-filter-type';
import { TrackReportMenuItem } from '../track-report-menu-item';
import { ReportFilterType } from '../track-report-filter-type';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { Access } from '@shared/services/graphql/access.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { UserIdleService } from 'angular-user-idle';
import { FINANCIAL_REPORTS_FEATURE, QUALITY_REPORTS_FEATURE } from '@track/track-constants';
import { ErrorLoggingService } from '@shared/services/logging/error-logging.service';
import { LogError } from '@shared/services/logging/log-error';
import { CoachException } from '@shared/models/exceptions/coach-exception';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-track-report',
  templateUrl: './track-report.component.html',
  host: {
    class: 'coach-track-report'
  }
})
export class TrackReportComponent extends KillSubscriptions implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private reportService: TrackReportService,
    private powerBiService: NgxPowerBiService,
    private menuItemService: TrackReportMenuItemsService,
    private quarterYearsService: TrackQuarterYearsService,
    private hierarchyTierService: HierarchyTierService,
    private userAccessService: UserAccessService,
    private userIdle: UserIdleService) {

    super();

    this.userAccessService.currentAccess$.pipe(takeUntil(this.killTrigger)).subscribe(access => {
      this.userAccess = access;
      if (access != null && this.hierarchyTierService.currentTier$.value != null) {

        this.reportFilterTypes = [ACO_REPORT_LEVEL]; 
        
        if (this.hierarchyTierService.currentTier$.value.selectedItem.cinFlag === 1) {
          this.reportFilterTypes.push(CIN_REPORT_LEVEL);
        }

        if (access.isInternal) {
          this.reportFilterTypes.push(REGION_REPORT_LEVEL);
        }

        this.runReport(this.hierarchyTierService.currentTier$.value);
      }
    });
  }

  @ViewChild('powerBi', { static: true }) powerBiContainer: ElementRef;

  reportParameters: TrackReportParameters = {
    attributionQuarter: null,
    attributionYear: null,
    cinId: null,
    cinName: null,
    menuItemId: null,
    phiFlag: null,
    regionId: null,
    reportId: null,
    reportTierID: null,
    reportTierNum: null
  };

  reportId: string;
  embedUrl: string;
  accessToken: string;
  reportFilters: TrackReportFilter[];
  embedFail: boolean;
  embeddedReport: Report;
  currentPage: string;
  isReportLoaded: boolean;
  quarterYears: TrackQuarterYear[];
  reportConfig: TrackReportConfig = {
    hidePrincipalParticipant: false,
    hideQuarter: false,
    quarterLabel: 'Quarter'
  };
  reportMenuItem: TrackReportMenuItem;
  selectedQuarterYearItem: TrackQuarterYear = null;
  isFilterPaneOpen = false;
  cinLevel = CIN_REPORT_LEVEL;
  userAccess: Access = null;

  reportFilterTypes:string[] = [];
  selectedReportFilterType = ACO_REPORT_LEVEL;
  selectedReportFilterItem: ReportFilterType = null;
  initialReportFilterItem: ReportFilterType = null;

  pbiContainerElement: HTMLElement;
  embedContainerId = 'pbi-container';
  showNoDataModal = false;
  noDataTitle = '';
  noDataMessage = '';

  ngOnInit(): void {

  }

  openPane(): void {
    this.isFilterPaneOpen = true;
  }

  filterItemSelected(item: ReportFilterType): void {
    if (item != null && this.initialReportFilterItem != null &&
      item.filterType === this.initialReportFilterItem.filterType && item.filterTypeID === item.filterTypeID) {
      this.initialReportFilterItem.filterTypeName = item.filterTypeName;
    }
  }

  runReport(tier: IHierarchyTier): void {
    const params = this.route?.snapshot?.paramMap;
    const menuItemId = stringToNumber(params.get('menuItem'));
    const year = this.reportParameters.attributionYear == null ? stringToNumber(params.get('year')) :
      this.reportParameters.attributionYear;
    const quarter = this.reportParameters.attributionQuarter == null ? stringToNumber(params.get('quarter')) :
      this.reportParameters.attributionQuarter;

    this.menuItemService.getAllFromCache().subscribe(menuItems => {

      this.reportMenuItem = menuItems.find(x => x.compassReportMenuID === menuItemId);

      if (this.reportMenuItem.configBlob != null) {
        this.reportConfig = JSON.parse(this.reportMenuItem.configBlob);
      }

      let reportFilterType: string = null;
      let reportFilterTypeId: number = null;

      if (this.reportMenuItem?.showCinRegionFilter === 1 && this.reportFilterTypes.length > 1) {
        reportFilterType = params?.get('filterType');
        reportFilterTypeId = stringToNumber(params?.get('filterTypeId'));
      }

      if (reportFilterType != null && reportFilterTypeId != null) {
        this.initialReportFilterItem = {
          filterTypeID: reportFilterTypeId,
          filterType: reportFilterType,
          phiFlag: null,
          userID: null,
          filterTypeName: null
        };
      }

      let reportId = this.reportMenuItem.menuGUID;
      if (this.hierarchyTierService.currentTier$.value.tier === 4 && this.reportMenuItem.secondaryGUID != null) {
        reportId = this.reportMenuItem.secondaryGUID;
      }

      this.reportParameters = {
        reportId: reportId,
        menuItemId,
        reportTierNum: tier.tier,
        reportTierID: tier.selectedTierId,
        attributionYear: year,
        attributionQuarter: quarter,
        cinId: reportFilterType === CIN_REPORT_LEVEL ? reportFilterTypeId : null,
        cinName: '',
        regionId: reportFilterType === REGION_REPORT_LEVEL ? reportFilterTypeId : null,
        phiFlag: this.userAccess.phi
      };

      const getQuarterYears$ = this.quarterYearsService.getQuarterYears(this.reportParameters.reportTierNum,
        this.reportParameters.reportTierID, this.reportParameters.menuItemId);

      getQuarterYears$.subscribe(quarterYears => {
        this.quarterYears = quarterYears;
        const noData = quarterYears?.length === 0;
        this.showNoDataModal = noData;
        
        if (noData) {
          this.setNoDataMessage();
        }

        this.selectedQuarterYearItem = this.findQuarterYear(this.reportParameters.attributionYear,
          this.reportParameters.attributionQuarter);

        if (this.selectedQuarterYearItem == null && !noData) {
          this.setMaxQuarterYear();
        }

        const versionedReportId = this.hierarchyTierService.currentTier$.value.tier === 4 && this.selectedQuarterYearItem?.secondaryGUID != null ? this.selectedQuarterYearItem.secondaryGUID : this.selectedQuarterYearItem?.menuGUID;
        this.reportParameters.reportId = versionedReportId ?? this.reportParameters.reportId;

        this.reportService.setLastRun(this.reportParameters.attributionYear, this.reportParameters.attributionQuarter);

        this.loadReport();
      });
    });
  }

  setNoDataMessage(): void {
    switch (this.reportMenuItem.logComponentID) {
      case QUALITY_REPORTS_FEATURE: 
        this.noDataTitle = 'Unable to Run Report';
        this.noDataMessage = `Please re-select filters using the current ACO to populate the report. If data was submitted and does not appear, please submit a <a href="mailto:${AppConstants.SupportEmail}" target="_blank">ticket</a> to Caravan Health.`;
        break;
      case FINANCIAL_REPORTS_FEATURE: 
        this.noDataTitle = 'Report Not Available';
        this.noDataMessage = `A report is not available for the selected hierarchy and time period combination. If you feel this is in error, please submit a <a href="mailto:${AppConstants.SupportEmail}">ticket</a> to Caravan Health.<p>The first set of reports for new starters will be available by mid-April.</p>`;
        break;
    }
  }

  setMaxQuarterYear(): void {
    const max = this.quarterYears[this.quarterYears.length - 1];

    this.reportParameters.attributionYear = max.attributionYear;
    this.reportParameters.attributionQuarter = max.attributionQuarter;

    this.selectedQuarterYearItem = this.findQuarterYear(this.reportParameters.attributionYear,
      this.reportParameters.attributionQuarter);
  }

  findQuarterYear(year: number, quarter: number): TrackQuarterYear {
    return this.quarterYears.find(quarterYear => quarterYear.attributionYear === year &&
      quarterYear.attributionQuarter === quarter);
  }

  loadReport(): void {
    const params = this.getParams();

    this.reportService.getSingle('', params)
      .subscribe(report => {
        this.embedUrl = report.embedUrl;
        this.accessToken = report.accessToken;
        this.reportFilters = report.reportFilters;
        this.reportId = report.reportId;

        const filters = this.getFilters();
        const config: IEmbedConfiguration = {
          type: 'report',
          id: this.reportId,
          embedUrl: this.embedUrl,
          accessToken: this.accessToken,
          tokenType: 1,
          filters,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true
          }
        };

        this.userIdle.stopWatching();
        this.pbiContainerElement = ((document.getElementById(this.embedContainerId)) as HTMLElement);
        this.embeddedReport = this.powerBiService.embed(this.pbiContainerElement, config) as Report;

        this.embeddedReport.on('dataSelected', () => this.resetIdle());
        this.embeddedReport.on('pageChanged', event => this.pageChanged(event));
        this.embeddedReport.on('buttonClicked', () => this.resetIdle());
        this.embeddedReport.on('error', (error) => this.handleError(error));

        this.embeddedReport.on('loaded', () => {
          this.isReportLoaded = true;
          this.embeddedReport.off('loaded');
          this.setTokenRefresh();
          this.userIdle.startWatching();

          this.embeddedReport.getPages().then(pages => {
            if (pages.length > 1) {
              pages[1].setActive();
            }
          });

        });
      }, () => {
        this.embedFail = true;
      });
  }

  handleError(event: CustomEvent): void {
    const error = event.detail as IError;
    const logError: LogError = {
      errorDescription: error.detailedMessage,
      process: 'TrackReport',
      applicationDetails: JSON.stringify(this.reportParameters),
      errorDetails: JSON.stringify(error),
      errorCode: error.errorCode,
      note: `Error rendering Track PowerBI report ${this.reportParameters.reportId}`
    };

    throw new CoachException(logError); 
  }

  getParams(): HttpParams {
    const isAcoLevel = this.reportParameters.regionId == null && this.reportParameters.cinId == null;
    return new HttpParams().set('reportTierNum', isAcoLevel ? this.reportParameters.reportTierNum?.toString() : '')
      .set('reportTierID', isAcoLevel ? this.reportParameters.reportTierID : '')
      .set('attributionQuarter', this.reportParameters.attributionQuarter?.toString())
      .set('attributionYear', this.reportParameters.attributionYear?.toString())
      .set('phiFlag', this.reportParameters.phiFlag.toString())
      .set('menuItemId', this.reportParameters.menuItemId?.toString())
      .set('reportId', this.reportParameters.reportId)
      .set('regionId', this.reportParameters.regionId == null ? '' : this.reportParameters.regionId.toString())
      .set('cinId', this.reportParameters.cinId == null ? '' : this.reportParameters.cinId.toString())
      .set('cinName', this.reportParameters.cinName == null ? '' : this.reportParameters.cinName);
  }

  getFilters(): models.IBasicFilter[] {

    let reportTierNum: string;
    let reportTierID: string;

    if (this.reportParameters.regionId != null) {
      reportTierNum = 'R';
      reportTierID = this.reportParameters.regionId.toString();
    }
    else if (this.reportParameters.cinId != null) {
      reportTierNum = 'C';
      reportTierID = this.reportParameters.cinId.toString();
    }
    else {
      reportTierNum = this.reportParameters.reportTierNum.toString();
      reportTierID = this.reportParameters.reportTierID;
    }

    return [{
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Hierarchy",
        column: "ReportTierNum"
      },
      operator: "In",
      values: [reportTierNum],
      filterType: 1,
      displaySettings: {
        isHiddenInViewMode: true
      }
    }, {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Hierarchy",
        column: "ReportTierID"
      },
      operator: "In",
      values: [reportTierID],
      filterType: 1,
      displaySettings: {
        isHiddenInViewMode: true
      }
    }, {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Hierarchy",
        column: "AttributionQuarter"
      },
      operator: "In",
      values: [this.reportParameters.attributionQuarter],
      filterType: 1,
      displaySettings: {
        isHiddenInViewMode: true
      }
    }, {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Hierarchy",
        column: "AttributionYear"
      },
      operator: "In",
      values: [this.reportParameters.attributionYear],
      filterType: 1,
      displaySettings: {
        isHiddenInViewMode: true
      }
    }, {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Hierarchy",
        column: "ReportQuarter"
      },
      operator: "In",
      values: [this.reportParameters.attributionQuarter],
      filterType: 1,
      displaySettings: {
        isHiddenInViewMode: true
      }
    }, {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Hierarchy",
        column: "ReportYear"
      },
      operator: "In",
      values: [this.reportParameters.attributionYear],
      filterType: 1,
      displaySettings: {
        isHiddenInViewMode: true
      }
    }, {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "PHIFlag",
        column: "PHIFlag"
      },
      operator: "In",
      values: [this.reportParameters.phiFlag ? 1 : 0],
      filterType: 1,
      displaySettings: {
        isHiddenInViewMode: true
      }
    }];
  }

  pageChanged(event): void {
    this.resetIdle();
    this.currentPage = event.detail.newPage.displayName;

    const newSettings = {
      filterPaneEnabled: false
    };

    if (event.detail.newPage.displayName.toLowerCase().indexOf('details') > -1) {
      const request: ReportAuditRequest = {
        reportParameters: this.reportParameters,
        drillThroughName: event.detail.newPage.displayName
      };

      this.reportService.logReportAudit(request).subscribe(() => { });
    }

    const reportFilter = this.reportFilters.find(x => x.pageName === event.detail.newPage.displayName);
    if (reportFilter != null) {
      newSettings.filterPaneEnabled = true;
    }

    this.embeddedReport.updateSettings(newSettings);

  }

  setTokenRefresh(): void {
    interval(1800000).pipe(takeUntil(this.killTrigger), share()).subscribe(() => this.getToken());
  }

  getToken(): Observable<TrackEmbeddedReport> {
    const observable = this.reportService.getAccessToken(this.reportId).pipe(share());

    observable.subscribe(embed => {
      this.embeddedReport.setAccessToken(embed.accessToken)
        .catch(error => { console.log('Error getting access token: ' + error) });
    });

    return observable;
  }

  print(): void {
    this.embeddedReport.print();
  }

  runReportClick(): void {
    this.isFilterPaneOpen = false;
    this.reportParameters.regionId = this.reportParameters.cinId = null;

    if (this.selectedReportFilterItem != null && this.selectedReportFilterType !== ACO_REPORT_LEVEL) {
      if (this.selectedReportFilterItem.filterType === REGION_REPORT_LEVEL) {
        this.reportParameters.regionId = this.selectedReportFilterItem.filterTypeID;
      } else if (this.selectedReportFilterItem.filterType === CIN_REPORT_LEVEL) {
        this.reportParameters.cinId = this.selectedReportFilterItem.filterTypeID;
        this.reportParameters.cinName = this.selectedReportFilterItem.filterTypeName;
      }
    }

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.reportService.navigateToReport(this.reportParameters);
    });
  }

  resetIdle(): void {
    this.userIdle.stopWatching();
    this.userIdle.startWatching();
  }

}
