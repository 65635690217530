import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
@Component({
  selector: 'coach-care-test',
  templateUrl: './care-test.component.html',
  styleUrls: ['./care-test.component.scss']
})
export class CareTestComponent implements OnInit {
  days: number;
  chart: am4charts.XYChart;
  systolic_range: Array<number>;
  diastolic_range: Array<number>;
  systolic_goal_range: Array<number>;
  diastolic_goal_range: Array<number>;
  chartDateReserve: Array<Object>;
  constructor() { };

  ngOnInit(): void {
    this.days = 100;
    this.systolic_range = [85, 125];
    this.diastolic_range = [55, 85];
    this.systolic_goal_range = [90, 120];
    this.diastolic_goal_range = [60, 80];
    this.chartDateReserve = [];
    this.renderBPChart()
  }
  renderBPChart() {
    /**
     clinicportal 
      look at src/app/patients/PatientsDetailsCtrl.ts $scope.updateTimelineArea = function()
      ReportsSvc.getUserReportById(sessionToken, $scope.patientId, reportToShowInsteadOfTimeline) 
     */
    let tempData = {
      "type": "line",
      "label": "Blood Pressure Over Time",
      "data": [
        {
          "label": "Systolic Measurement",
          "data": [
            [
              "2020-06-17T05:01:05",
              100
            ],
            [
              "2020-06-17T09:36:34",
              180
            ],
            [
              "2020-06-17T09:42:55",
              130
            ],
            [
              "2020-06-17T10:57:49",
              100
            ],
            [
              "2020-06-18T02:50:39",
              120
            ],
            [
              "2020-06-26T08:08:58",
              120
            ],
            [
              "2020-06-26T17:27:06",
              100
            ]
          ]
        },
        {
          "label": "Diastolic Measurement",
          "data": [
            [
              "2020-06-17T10:01:05",
              99
            ],
            [
              "2020-06-17T12:01:05",
              130
            ],
            [
              "2020-06-17T19:36:34",
              120
            ],
            [
              "2020-06-18T19:36:34",
              130
            ],
            [
              "2020-06-19T19:36:34",
              110
            ],
            [
              "2020-06-20T19:36:34",
              80
            ]
          ]
        }
      ],
      "xaxis": {
        "axisLabel": " ",
        "mode": "time",
        "tickSize": [
          3, "day"
        ],
        "ticks": [
          [
            1, "06/17/2020"
          ],
          [
            3, "06/17/2020"
          ],
          [
            5, "06/26/2020"
          ]
        ]
      },
      "yaxis": {
        "axisLabel": "Pressure (mmHg)",
        "min": 30,
        "max": 200
      },
      "series": {
        "points": {
          "show": true
        }
      },
      "name": "bpTracking",
      "tooltips": [
        [
          {
            "text": "Jun 17, 2020",
            "value": 100
          },
          {
            "text": "Jun 17, 2020",
            "value": 180
          },
          {
            "text": "Jun 17, 2020",
            "value": 180
          },
          {
            "text": "Jun 17, 2020",
            "value": 0
          }, {
            "text": "Jun 18, 2020",
            "value": 120
          }, {
            "text": "Jun 26, 2020",
            "value": 120
          }, {
            "text": "Jun 26, 2020",
            "value": 120
          }, {
            "text": "Aug 7, 2020",
            "value": null
          }, {
            "text": "Aug 8, 2020",
            "value": null
          }, {
            "text": "Aug 9, 2020",
            "value": null
          }, {
            "text": "Aug 10, 2020",
            "value": null
          }, {
            "text": "Aug 11, 2020",
            "value": null
          }, {
            "text": "Aug 12, 2020",
            "value": null
          }, {
            "text": "Aug 13, 2020",
            "value": null
          }
        ],
        [
          {
            "text": "Jun 17, 2020",
            "value": 88
          },
          {
            "text": "Jun 17, 2020",
            "value": 120
          },
          {
            "text": "Jun 17, 2020",
            "value": 120
          },
          {
            "text": "Jun 17, 2020",
            "value": 0
          }, {
            "text": "Jun 18, 2020",
            "value": 80
          }, {
            "text": "Jun 26, 2020",
            "value": 80
          }, {
            "text": "Jun 26, 2020",
            "value": 80
          }, {
            "text": "Aug 7, 2020",
            "value": null
          }, {
            "text": "Aug 8, 2020",
            "value": null
          }, {
            "text": "Aug 9, 2020",
            "value": null
          }, {
            "text": "Aug 10, 2020",
            "value": null
          }, {
            "text": "Aug 11, 2020",
            "value": null
          }, {
            "text": "Aug 12, 2020",
            "value": null
          }, {
            "text": "Aug 13, 2020",
            "value": null
          }
        ]
      ],
      "grid": {
        "markings": [
          { // SYSTOLIC
            "xaxis": {
              "from": 0,
              "to": 9
            },
            "yaxis": {
              "from": 90, // GOAL RANGE VALUES
              "to": 130
            },
            "color": "rgba(50, 157, 189, 0.2)"
          }, { // DIASTOLIC
            "xaxis": {
              "from": 0,
              "to": 9
            },
            "yaxis": {
              "from": 60,
              "to": 80
            },
            "color": "rgb(243, 140, 32, 0.2)"
          }
        ]
      },
      "chartId": "line-chart-1597211028990-859"
    }
    /**
     Skeleton:
     {
      "date": tooltip,
      "value": value,
      "value2": value
     }
     */
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("bpChart", am4charts.XYChart);
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";
    this.chart = chart;

    am4core.useTheme(am4themes_animated);
    let data = [];
    // let temp_map = {}
    // for (let el in tempData.data[0].data) {
    //   if (!tempData.data[0].data[el][1]) {
    //     continue
    //   }
    //   let key = new Date(tempData.data[0].data[el][0])

    //   if (!temp_map[key.getTime()]) {
    //     temp_map[key.getTime()] = {
    //       'systolic': null,
    //       'diastolic': null,
    //       'goal_diastolic_top': tempData.grid.markings[1].yaxis.to,
    //       'goal_diastolic_bottom': tempData.grid.markings[1].yaxis.from,
    //       'goal_systolic_top': tempData.grid.markings[0].yaxis.to,
    //       'goal_systolic_bottom': tempData.grid.markings[0].yaxis.from
    //     }
    //   }
    //   temp_map[key.getTime()].systolic = tempData.data[0].data[el][1]
    // }
    // for (let el in tempData.data[1].data) {
    //   if (!tempData.data[1].data[el][1]) {
    //     continue
    //   }
    //   let key = new Date(tempData.data[1].data[el][0])

    //   if (!temp_map[key.getTime()]) {
    //     temp_map[key.getTime()] = {
    //       'systolic': null,
    //       'diastolic': null,
    //       'goal_diastolic_top': tempData.grid.markings[1].yaxis.to,
    //       'goal_diastolic_bottom': tempData.grid.markings[1].yaxis.from,
    //       'goal_systolic_top': tempData.grid.markings[0].yaxis.to,
    //       'goal_systolic_bottom': tempData.grid.markings[0].yaxis.from
    //     }
    //   }
    //   temp_map[key.getTime()].diastolic = tempData.data[1].data[el][1]
    // }
    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.baseInterval = {
      timeUnit: "minute",
      count: 1
    };
    dateAxis.tooltipDateFormat = "HH:mm, d MMMM";
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // populating data array OLD
    // for (let el in temp_map) {
    //   console.log(el, new Date(Number(el)))
    //   chart.data.push({
    //     'date': new Date(Number(el)),
    //     'systolic': temp_map[el].systolic,
    //     'diastolic': temp_map[el].diastolic,
    //     'goal_diastolic_top': temp_map[el].goal_diastolic_top,
    //     'goal_diastolic_bottom': temp_map[el].goal_diastolic_bottom,
    //     'goal_systolic_top': temp_map[el].goal_systolic_top,
    //     'goal_systolic_bottom': temp_map[el].goal_systolic_bottom
    //   })
    // }

    
    // chart.data.sort((a, b) => (a.date > b.date) ? 1 : -1)
    // Create values
    this.chartRenderValues(this.generateRandomValues()) 
    // GOAL DIASTOLIC RANGE
    var goal_diastolic_top = this.chart.series.push(new am4charts.LineSeries());
    goal_diastolic_top.dataFields.dateX = "date";
    goal_diastolic_top.dataFields.openValueY = "goal_diastolic_bottom";
    goal_diastolic_top.dataFields.valueY = "goal_diastolic_top";
    goal_diastolic_top.sequencedInterpolation = true;
    goal_diastolic_top.fillOpacity = 0.3;
    goal_diastolic_top.tensionX = 0.8;
    goal_diastolic_top.hiddenInLegend = true;
    goal_diastolic_top.hideTooltip()
    goal_diastolic_top.stroke = am4core.color("#f7d5b8")
    goal_diastolic_top.fill = am4core.color("#f7d5b8")

    var goal_diastolic_bottom = this.chart.series.push(new am4charts.LineSeries());
    goal_diastolic_bottom.dataFields.dateX = "date";
    goal_diastolic_bottom.dataFields.valueY = "goal_diastolic_bottom";
    goal_diastolic_bottom.sequencedInterpolation = true;
    goal_diastolic_bottom.stroke = this.chart.colors.getIndex(6);
    goal_diastolic_bottom.tensionX = 0.8;
    goal_diastolic_bottom.hiddenInLegend = true;
    goal_diastolic_bottom.hideTooltip()
    goal_diastolic_bottom.stroke = am4core.color("#f7d5b8")

    // GOAL SYSTOLIC RANGE
    var goal_systolic_top = this.chart.series.push(new am4charts.LineSeries());
    goal_systolic_top.dataFields.dateX = "date";
    goal_systolic_top.dataFields.openValueY = "goal_systolic_bottom";
    goal_systolic_top.dataFields.valueY = "goal_systolic_top";
    goal_systolic_top.sequencedInterpolation = true;
    goal_systolic_top.fillOpacity = 0.3;
    goal_systolic_top.tensionX = 0.8;
    goal_systolic_top.hiddenInLegend = true;
    goal_systolic_top.hideTooltip()
    goal_systolic_top.stroke = am4core.color("#9fd4e4")
    goal_systolic_top.fill = am4core.color("#9fd4e4")

    var goal_systolic_bottom = this.chart.series.push(new am4charts.LineSeries());
    goal_systolic_bottom.dataFields.dateX = "date";
    goal_systolic_bottom.dataFields.valueY = "goal_systolic_bottom";
    goal_systolic_bottom.sequencedInterpolation = true;
    goal_systolic_bottom.stroke = this.chart.colors.getIndex(6);
    goal_systolic_bottom.tensionX = 0.8;
    goal_systolic_bottom.hiddenInLegend = true;
    goal_systolic_bottom.hideTooltip()
    goal_systolic_bottom.stroke = am4core.color("#9fd4e4")

    this.chart.legend = new am4charts.Legend();
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.scrollbarX = new am4core.Scrollbar();
    this.createSeries("systolic", "Systolic", "rgba(50, 157, 189, 0.5)");
    this.createSeries("diastolic", "Diastolic", "rgba(243, 140, 32, 0.6)");
  }
  createSeries(field, name, color) {
    var series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "date";
    series.name = name;
    series.tooltipText = `[b]{valueY}[/]`;
    series.strokeWidth = 8;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color(color);
    if (color) {
      series.stroke = am4core.color(color);
    }

    var circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.radius = 10;
    circleBullet.circle.fill = am4core.color(color);
    circleBullet.circle.fillOpacity = 0.5;
    circleBullet.circle.stroke = am4core.color(color);
    circleBullet.circle.strokeOpacity = 0.5;
    return series;
  }
  chartRenderValues(data) {
    this.chart.data = data;
    if(this.days == 100){
      this.chartDateReserve = data
    } 
  }
  daysChange(val, force?) { 
    if (val === this.days && !force) return;
    this.days = val;
    let data = []
    var d = new Date();
    d.setDate(d.getDate() - this.days);
    for (let i = 0; i < this.chartDateReserve.length; i++) { 
      if (d <= this.chartDateReserve[i]['date']) {
        data.push(this.chartDateReserve[i])
      }
    }
    this.chartRenderValues(data)
  }
  generateRandomValues(){
    let data = []
    for (let i = 0; i < 100; i++) {
      if ((Math.floor(Math.random() * 3) + 1) % 2 == 0) { // randomly skips every thrid day to make inputs realistic
        continue
      }
      var d = new Date();
      d.setDate(d.getDate() - (100 - i));  
      d.setHours(Math.floor(Math.random() * 24), Math.floor(Math.random() * 60),Math.floor(Math.random() * 60) )
      data.push({
        'date': d,
        'systolic': Math.floor(Math.random() * (this.systolic_range[1] - this.systolic_range[0] + 1) + this.systolic_range[0]),
        'diastolic': Math.floor(Math.random() * (this.diastolic_range[1] - this.diastolic_range[0] + 1) + this.diastolic_range[0]),
        'goal_diastolic_top': this.diastolic_goal_range[1],
        'goal_diastolic_bottom': this.diastolic_goal_range[0],
        'goal_systolic_top': this.systolic_goal_range[1],
        'goal_systolic_bottom': this.systolic_goal_range[0]
      })

    }
    return data
  }
  randomizeData() { 
    this.chartDateReserve = this.generateRandomValues();
    this.daysChange(this.days, true)
  }
  rangeChange(val, target, idx) {
    this[target][idx] = val
    for (let el in this.chart.data) {
      let target_type = target.split("_")[0]
      if (idx == 0 && this.chart.data[el][target_type] < this[target][idx]) {
        // console.log("ues")
        this.chart.data[el][target_type] = this[target][idx]
      } else if (idx == 1 && this.chart.data[el][target_type] > this[target][idx]) {
        this.chart.data[el][target_type] = this[target][idx]
      }
    }
  }
}
