import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

/**
 * workSheet is the name of the workSheet. It has to be less than 31 characters in length.
 * In the cases where the type of a worksheet is table, the workSheet name is also the table name without any spaces
 *
 * theme is used for the workSheetTypes `table`. If one is not provided, it will default to TableStyleMedium2
 *
 * Rows is set as Any because the different workSheet types will use the rows differently.
 */
export interface workBook {
  workSheet: string;
  workSheetType: WorkSheetTypes;
  theme?: string;
  rows: any[];
}

/**
 * Sheet will produce a basic sheet where
 */
export enum WorkSheetTypes {
  sheet = 'sheet',
  freeForm = 'freeForm',
  table = 'table',
}

@Injectable()
export class ExcelService {
  constructor() {}

  /** Service for creating Excel files for download
   *
   * To create an excel file, create an array of worksheets using {@link workBook}
   * In the `workBook` interface there is a {@link workSheetType} which allows for determining how the rows of the workBook will be handled
   * In the case of `workShetTypes` sheet and table, they take a very similar layout with the difference being that table allows for the use of a theme and will produce an Excel table
   *
   * Here is an example of what one might look like with each possible version of a worksheet included (table, sheet and freeForm)
   *
   *
   *                  workbookData = [
   *                    {
   *                      workSheet: "WS_TableSample",
   *                      theme: 'TableStyleMedium1'
   *                      rows: [
   *                        { columnA: "1", Bcolumn: "Smith", columnCValidation: ["Yes", "No", "Maybe"] },
   *                        { columnA: "", Bcolumn: "", columnCValidation: "" },
   *                        { columnA: "4", Bcolumn: "Parker", columnCValidation: ["Yes", "No", "Maybe"] }
   *                      ]
   *                    },
   *                    {
   *                      workSheet: "WS_regularSheet",
   *                      rows: [
   *                        { columnA: "1", Bcolumn: "Smith", columnCValidation: ["Yes", "No", "Maybe"] },
   *                        { columnA: "", Bcolumn: "", columnCValidation: "" },
   *                        { columnA: "4", Bcolumn: "Parker", columnCValidation: ["Yes", "No", "Maybe"] },
   *                      ]
   *                    },
   *                    {
   *                      workSheet: "WS_freeForm",
   *                      rows: [
   *                              { row: 1,
   *                                rowHeight: 10,
   *                                cols: [{column: 'A',
   *                                        value: 'columnA',
   *                                        styling: {font: {bold: true}, alignment: { vertical: 'bottom', horizontal: 'left'} },
   *                                        columnWidth: 17.43,
   *                                      },
   *                                      {column: 'B',
   *                                        value: 'Bcolumn',
   *                                        styling: {font: {bold: true}, border: {bottom: {style:'thin'}}, alignment: { vertical: 'bottom', horizontal: 'left'} }
   *                                      },
   *                                      {column: 'C',
   *                                        value: 'columnCValidation',
   *                                        styling: {border: {bottom: {style:'thin'}}, alignment: { vertical: 'bottom', horizontal: 'left'} }
   *                                      },
   *                                    ]
   *                              },
   *                              { row: 2,
   *                                cols: [{column: 'A',
   *                                         value: '1',
   *                                         styling: {font: {bold: true}, alignment: { vertical: 'bottom', horizontal: 'left'} }
   *                                       },
   *                                       {column: 'B',
   *                                         value: 'Smith',
   *                                         styling: {font: {bold: true}, alignment: { vertical: 'bottom', horizontal: 'left'} },
   *                                        },
   *                                        {column: 'C',
   *                                         value: ["Yes", "No", "Maybe"]
   *                                        },
   *                                     ]
   *                               },
   *                               { row: 4,
   *                                cols: [{column: 'A',
   *                                         value: '4',
   *                                         styling: {font: {bold: true}, alignment: { vertical: 'bottom', horizontal: 'left'} }
   *                                       },
   *                                       {column: 'B',
   *                                         value: 'Parker',
   *                                         styling: {alignment: { vertical: 'bottom', horizontal: 'left'} },
   *                                        },
   *                                        {column: 'C',
   *                                         value: ["Yes", "No", "Maybe"],
   *                                         styling: {alignment: { vertical: 'top', horizontal: 'right'} },
   *                                        },
   *                                     ]
   *                               }
   *                      ]
   *                    }
   *                  ];
   *
   * In this example we have the same end result repeated in 3 different ways (with the exception that the freeform one has styling included. Currently sheet doesn't have any styling options and table is limited to the table's theme).
   * In each version, column C is being used to demonstrate the ability to include a data validation drop down.
   *
   * For the sheet and table, you will see that the column names are given as key value. The keys will become row 1 as the column names. Note that if additional columns are included in subsequent rows, those columns will be appended to the end of the already existing columns.
   *
   * For the freeForm workSheet option, we see that the row number is called out per row with row 3 being skipped. The row number will dictate which row the column data will be entered on. Likewise the column letter will dictate with column the data will be entered into.
   * The styling options are just some random examples to show that this can be changed per individual cell. For additional styling information, see the excelJS styles section of their github page https://github.com/exceljs/exceljs#styles
   * Note that rowHeight is used to set an individual row's height as seen in row 1.
   * We can also merge cells together using `mergeCells: 'B18:F25'` as an additional property for a column.
   * For an example of this put into practice see {@link ClinicianRosterComponent}
   *
   * The @param workbookData is the workBook made up of the different worksheets.
   * The @param excelFileName is the name of the created Excel file. (Don't include the extension in the file name)
   */

  public async exportAsExcel(workbookData: workBook[], excelFileName: string) {
    const workbook = new ExcelJS.Workbook();

    workbookData.forEach(({ workSheet, rows, workSheetType, theme }) => {
      if (workSheetType == WorkSheetTypes.freeForm) {
        const sheet = this.createFreeFormWorkSheet(workbook, workSheet, rows);
      }
      if (workSheetType == WorkSheetTypes.table) {
        const sheet = this.createTableWorkSheet(
          workbook,
          workSheet,
          rows,
          theme
        );
      }
      if (workSheetType == WorkSheetTypes.sheet) {
        const sheet = this.createPlainWorkSheet(workbook, workSheet, rows);
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, excelFileName);
  }

  /**
   * Creates the freeform style of worksheet.
   */
  private createFreeFormWorkSheet(
    workbook: ExcelJS.Workbook,
    workSheetName: any,
    rows: any[]
  ): any {
    const sheet = workbook.addWorksheet(workSheetName);

    rows.forEach((row, cols) => {
      const rownum = row;
      if (rownum.rowHeight) {
        const row = sheet.getRow(rownum.row);
        row.height = rownum.rowHeight;
      }
      row.cols.forEach((entry) => {
        if (entry.mergeCells) {
          sheet.mergeCells(entry.mergeCells);
        }
        const cellLocation = entry.column + row.row;
        const cell = sheet.getCell(cellLocation);

        if (Array.isArray(entry.value)) {
          const jsonDropdown = entry.value;
          cell.dataValidation = {
            type: 'list',
            formulae: [`"${jsonDropdown.join(',')}"`],
          };
        } else {
          cell.value = entry.value;
        }

        if (entry.styling) {
          if (entry.styling.border) {
            cell.border = entry.styling.border;
          }
          if (entry.styling.font) {
            cell.font = entry.styling.font;
          }
          if (entry.styling.alignment) {
            cell.alignment = entry.styling.alignment;
          }
          if (entry.styling.fill) {
            cell.fill = entry.styling.fill;
          }
          if (entry.styling.numFmt) {
            cell.numFmt = entry.styling.numFmt;
          }
        }
        if (entry.columnWidth) {
          const col = sheet.getColumn(entry.column);
          col.width = entry.columnWidth;
        }

        if (entry.protection) {
          cell.protection = entry.protection;
        }
      });
    });

    return sheet;
  }

  /**
   * Creates the table style of worksheet. Filters are currently hard coded in so that you can filter the table's columns by default (As you would if you created a table in Excel).
   */
  private createTableWorkSheet(
    workbook: ExcelJS.Workbook,
    workSheetName: any,
    rows: any[],
    theme: any
  ): any {
    const sheet = workbook.addWorksheet(workSheetName);
    const uniqueHeaders = [
      ...new Set(
        rows.reduce((prev, next) => [...prev, ...Object.keys(next)], [])
      ),
    ];

    let output = [];

    rows.forEach((entry) => {
      const result = Object.keys(entry).map((key) =>
        key == 'Specify Edit/Remove/Add' ? '' : entry[key]
      );
      output.push(result);
    });

    const cols = uniqueHeaders.map((x) => ({
      header: x,
      key: x,
      name: x,
      filterButton: true,
    })) as ExcelJS.TableColumnProperties[];

    sheet.addTable({
      name: workSheetName.replace(/\s/g, ''),
      ref: 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: theme || 'TableStyleMedium2',
        showRowStripes: true,
      },
      columns: cols,
      rows: output,
    });

    rows.forEach((jsonRow, i) => {
      let cellValues = { ...jsonRow };

      uniqueHeaders.forEach((header: string, j) => {
        if (Array.isArray(jsonRow[header])) {
          cellValues[header] = '';
        }
      });
      uniqueHeaders.forEach((header: string, j) => {
        if (Array.isArray(jsonRow[header])) {
          const jsonDropdown = jsonRow[header];
          sheet.getCell(
            this.getSpreadSheetCellNumber(i + 1, j)
          ).dataValidation = {
            type: 'list',
            formulae: [`"${jsonDropdown.join(',')}"`],
          };
        }
      });
    });

    sheet.columns.forEach((column, i) => {
      let maxLength =
        column.values[1].toString().length > 0
          ? column.values[1].toString().length + 5
          : 0; //+5 to give space for the filter button
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });
  }

  /**
   * Creates a plain worksheet without any styling. Basically like the table, but without the table formatting or any filtering.
   */
  private createPlainWorkSheet(
    workbook: ExcelJS.Workbook,
    workSheetName: any,
    rows: any[]
  ): any {
    const sheet = workbook.addWorksheet(workSheetName);
    const uniqueHeaders = [
      ...new Set(
        rows.reduce((prev, next) => [...prev, ...Object.keys(next)], [])
      ),
    ];

    sheet.columns = uniqueHeaders.map((x) => ({
      header: x,
      key: x,
    })) as Partial<ExcelJS.Column>[];

    rows.forEach((jsonRow, i) => {
      let cellValues = { ...jsonRow };

      uniqueHeaders.forEach((header: string, j) => {
        if (Array.isArray(jsonRow[header])) {
          cellValues[header] = '';
        }
      });
      sheet.addRow(cellValues);
      uniqueHeaders.forEach((header: string, j) => {
        if (Array.isArray(jsonRow[header])) {
          const jsonDropdown = jsonRow[header];
          sheet.getCell(
            this.getSpreadSheetCellNumber(i + 1, j)
          ).dataValidation = {
            type: 'list',
            formulae: [`"${jsonDropdown.join(',')}"`],
          };
        }
      });
    });
  }

  /**
   * Used to find the spreadsheet's cell number in the sheet and table worksheet options.
   * Currently used when applying data validation lists.
   */
  private getSpreadSheetCellNumber(row, column) {
    let result = '';

    // Get spreadsheet column letter
    let n = column;
    while (n >= 0) {
      result = String.fromCharCode((n % 26) + 65) + result;
      n = Math.floor(n / 26) - 1;
    }

    // Get spreadsheet row number
    result += `${row + 1}`;

    return result;
  }

  /**
   * Saves the Excel file to be downloaded
   */
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
