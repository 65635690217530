import {Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  LOCALE_ID
} from '@angular/core';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';

import { CaravanFormAdminPracticeRespView, ColumnDefs } from '@shared/models/forms/form-admin-model';
import {ClickableParentComponent} from './clickable.parent.component';
import { DetailCellRenderer } from './detail-cell-renderer.component';

@Component({
  selector: 'coach-admin-form-report-practice',
  templateUrl: './admin-report-practice.component.html'
})

export class AdminFormReportPractice implements OnInit {

  @Input() CaravanFormAdminResponseData:[]; // Import form from parent component
  @Input() columnNamesLevelOne: ColumnDefs[];
  @Input() columnNamesLevelTwo: ColumnDefs[];
  @Input() tierID: string;
  @Input() reportName: string;
  @Input() formID: string;
  @Output() resultResponseReport = new EventEmitter();

  public allSubs: Subscription[];
  public defaultColDef;
  public detailCellRendererParams;
  public detailCellRenderer;
  public context: ReportContext;

  public gridApi;
  public gridColumnApi;
  public rowData;
  public isRowMaster;
  public icons;
  
  constructor(@Inject(LOCALE_ID) private locale: string) {
    this.context = {componentParent: this};
    this.isRowMaster = function (dataItem) {
      if (dataItem.formResponses != null){
        return dataItem ? dataItem.formResponses.length > 0 : false;
      }
      else{
        return false;
      }
    };
  }

   public frameworkComponents = {
    clickableParentComponent: ClickableParentComponent,
    myDetailCellRenderer:DetailCellRenderer
  };

  ngOnInit(): void {
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: this.columnNamesLevelTwo,
        defaultColDef: { flex: 1, },
        groupDefaultExpanded: 0,
        masterDetail: true,
        detailRowHeight: 240,
        detailRowAutoHeight: true,

    },
    //Keeping this in for future implementation
    // getDetailRowData: function (params) {
    //   params.successCallback(params.data.formResponses);
    // },
    // get the rows for each Detail Grid
    getDetailRowData: params => {
      setTimeout(function () {
        params.successCallback(params.data.formResponses);
      });

    },
    };
  }

  onFirstDataRendered(params: any): void {
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(0).setExpanded(false);
    }, 0);
  }

  public formSubmissionsOverview: CaravanFormAdminPracticeRespView[];

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onBtnExport(): void {
    let columnKeys = [];
    this.columnNamesLevelOne.forEach((c) => {
      if (c?.cellRenderer !== 'clickableParentComponent'){
        columnKeys.push(c.field);
      }
    });

    const reportName: string = this.reportName+ '_' + this.tierID;
    const timestamp = formatDate(new Date(), 'yyyy-MM-dd', this.locale);

    this.gridApi.exportDataAsCsv({ suppressQuotes: false, columnKeys: columnKeys, fileName: `${reportName}_${timestamp}.csv` });

  }

  reportParentClickResult(cell: any, data: any, row: any):void {
    if (this.gridApi.getRowNode(row).expanded == true){
      this.gridApi.getRowNode(row).expanded = false;
    }
    else {
      this.gridApi.getRowNode(row).expanded  = true;
    }
     this.gridApi.onGroupExpandedOrCollapsed();
  }

  onBtnExportResponses(): void {
    this.resultResponseReport.emit();

  }

}
interface ReportContext {
  componentParent: AdminFormReportPractice;
}
