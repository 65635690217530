import { Injectable } from '@angular/core';

import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { TrackQuarterYear } from '@track/track-reports/track-quarter-year';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackQuarterYearsService extends AzureApiBase<TrackQuarterYear> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/report/quarterYears');
  }

  getQuarterYears(tierNum: number, tierId: string, menuId: number): Observable<TrackQuarterYear[]> {
    const params = new HttpParams().set('menuId', menuId?.toString())
      .append('tierNum', tierNum?.toString())
      .append('tierId', tierId);

    return this.get(null, params);
  }
}
