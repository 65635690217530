<div #chartContainer></div>
<ng-container *doesNotHaveFeatureFlag="FacesheetUIUpdatesFeatureFlag">
  <div class="row" [ngClass]="{
    'chart-headers u-preserve': patientFacesheetSize == 'desktop',
    'patientChart-header padding-topBottom--small padding-leftRight--large u-background-color--white': patientFacesheetSize == 'tablet',
    'u-display--flex u-flex-direction--row u-flex-wrap--wrap u-justify-content--spaceAround u-align-items--center padding': patientFacesheetSize == 'mobile'
  }" *ngIf="patient">
    <!--
      EXPANDED size > 1175px
      CONDENSED size >= 921px AND <= 1175px
      MOBILE size < 921px
     -->

    <!-- EXPANDED DEMOGRAPHICS -->
    <div class="patientChart-header row--wrap u-fill padding-topBottom padding-leftRight--large"
      *ngIf="patientFacesheetSize == 'desktop'">
      <div class="chart-header-patient row u-fill">
        <div class="profile-photo square--smaller avi">
          <img alt="patient photo" src="{{patient.avatarUrl()}}" *ngIf="patient.avatarUrl()">
        <ng-container *ngIf="!patient.avatarUrl()">{{ patient.displayInitials() | initialsFromName }}</ng-container>
        </div>
        <div class="titles t2 margin-left u-fill">
          <div class="title" id="patientDisplayNameExpanded">
            {{patient.displayName()}}
          </div>
          <div class="subtitle" style="word-break: break-word">
             Gender: {{patient.displayGender()}} | {{ titleLine([patient.displayAge(), patient.displayDob(),
            patient.displayIdentifier()]) }}<span *ngIf="patient.mbi"> |
              MBI: {{patient.mbi | mask: 6 }}
            </span>
          </div>

          <div class="subtitle">
            {{ titleLine([patient.displayPhone(), patient.email]) }}
          </div>
          <div *ngIf="patient.dod" class="subtitle">
            {{ patient.displayDod() }}
          </div>
          <div *ngIf="!patient.trackPatient" class="field u-color--brand--3">
            Not attributed
          </div>
        </div>
      </div>
      <div class="chart-header-patientAdditional">
        <div class="row--wrap margins-inline">
          <div class="group" *ngIf="patient.attributedCP">
            <div class="label">Attributed Provider</div>
            <div class="field">{{ patient.attributedCP }}</div>
          </div>
          <div class="group" *ngIf="patient.raF_YTD">
            <div class="label">Chronic Conditions Risk Adjustment (YTD)</div>
            <div class="field">
              <span class="u-bold">{{ patient.raF_YTD }} </span>
              <span>({{ titleLine([patient.assignment, patient.enrollmentType], ', ') }})</span>
            </div>
          </div>
          <div class="group" *ngIf="displayRiskLevel">
            <div  class="label"> Predicted Risk Category </div>
            <div  class="field u-display--flex">
              <span  class="u-bold">{{ patient?.trackPatient?.predictedRiskLevel}}
              </span>
              <!-- temporarily dusabled CC-2347 -->
              <span *ngIf="false" class= "iconImg">
                   <!--src="assets/icons/noun_uptrend_1959567.svg"-->
                  <img *ngIf="patient?.trackPatient?.riskTrend == 'Increasing'" src="assets/icons/whiteRedUptrend.svg"  >
                  <img *ngIf="patient?.trackPatient?.riskTrend == 'Decreasing'" src="assets/icons/whiteGreenDwntrend.svg" >
                  <img *ngIf="patient?.trackPatient?.riskTrend == 'Same'" src="assets/icons/whiteBlueNotrend.svg" >
                </span>
            </div>
          </div>

        </div>
        <div class="actionButtonWrapper" *ngIf="patientFacesheetSize == 'desktop'">
          <button class="button is-small" (click)="openScopeSelectDropdown()" #scopeSelectOrigin>Actions</button>
        </div>
      </div>
    </div>

    <!-- CONDENSED DEMOGRAPHICS -->
    <div *ngIf="patientFacesheetSize == 'tablet'"
      class="chart-header-patient row--verticalCenter u-fill">
      <div class="profile-photo circle--smallest avi u-border-radius--rounded">
        <img alt="patient photo" src="{{patient.avatarUrl()}}" *ngIf="patient.avatarUrl()">
        <ng-container *ngIf="!patient.avatarUrl()">{{ patient.displayInitials() | initialsFromName }}</ng-container>
      </div>
      <div class="titles t3 padding-leftRight--large padding-bottom--small u-fill">
        <div class="title" id="patientDisplayNameCondensed">
          {{patient.displayName()}}
        </div>
        <div class="u-display--flex u-flex-direction--row u-flex-wrap--wrap">
          <div class="margin-right--small"> Gender: {{patient.displayGender()}}</div>
          <div class="margin-right--small">{{patient.displayAge()}}</div>
          <div class="margin-right--small">{{patient.displayDob()}}</div>
          <div class="margin-right--small">{{patient.displayIdentifier()}}</div>
          <div class="margin-right--small" *ngIf="patient.mbi">MBI: {{patient.mbi}}</div>
        </div>
        <div class="u-display--flex u-flex-direction--row u-flex-wrap--wrap">
          <div class="margin-right--small">Phone: {{patient.displayPhone()}}</div>
          <div>Email: {{patient.email}}</div>
          <div *ngIf="patient.dod">{{ patient.displayDod() }}</div>
        </div>
        <div *ngIf="!patient.trackPatient" class="field u-color--brand--3">
          Not attributed
        </div>
        <div class="padding-top--small">
          <button class="button is-small" (click)="openScopeSelectDropdown()" #scopeSelectOrigin>Actions</button>
        </div>
      </div>
    </div>
    <div *ngIf="patientFacesheetSize == 'tablet'" class="chart-header-patientAdditional">
      <div class="row--wrap margins-inline">
        <div class="group" *ngIf="patient.attributedCP">
          <div class="label">Attributed Provider</div>
          <div class="field">{{patient.attributedCP}}</div>
        </div>
        <div class="group" *ngIf="patient.raF_YTD">
          <div class="label">Chronic Conditions Risk Adjustment (YTD)</div>
          <div class="field">
            <span class="u-bold">{{ patient.raF_YTD }} </span>
            <span>({{ titleLine([patient.assignment, patient.enrollmentType], ', ') }})</span>
          </div>
        </div>
        <div class="group" *ngIf="displayRiskLevel">
          <div  class="label"> Predicted Risk Category </div>
            <div *ngIf="displayRiskLevel" class="field u-display--flex">
              <span  class="u-bold">{{ patient?.trackPatient?.predictedRiskLevel}}
              </span>
                <!-- temporarily dusabled CC-2347 -->
              <span  *ngIf="false" class= "iconImg">
                  <img *ngIf="patient?.trackPatient?.riskTrend == 'Increasing'" src="assets/icons/whiteRedUptrend.svg"  >
                  <img *ngIf="patient?.trackPatient?.riskTrend == 'Decreasing'" src="assets/icons/whiteGreenDwntrend.svg" >
                  <img *ngIf="patient?.trackPatient?.riskTrend == 'Same'" src="assets/icons/whiteBlueNotrend.svg" >
              </span>
          </div>
        </div>
      </div>
    </div>

    <!-- MOBILE DEMOGRAPHICS -->
    <div *ngIf="patientFacesheetSize == 'mobile'"
      class="chart-header-patient u-display--flex u-align-items--center facesheet-demographics-top--mobile" >
      <div class="profile-photo circle--smallest avi u-border-radius--rounded u-width--full u-height--full">
        <img alt="patient photo" src="{{patient.avatarUrl()}}" *ngIf="patient.avatarUrl()">
        <ng-container *ngIf="!patient.avatarUrl()">{{ patient.displayInitials() | initialsFromName }}</ng-container>
      </div>
    </div>

    <!-- MOBILE ACTION BUTTON -->
    <button *ngIf="patientFacesheetSize == 'mobile'" class="button" (click)="openScopeSelectDropdown()" #scopeSelectOrigin>Actions</button>

    <!-- TIMETRACKING -->
    <ng-container *hasFeature="careFeatureConstants.PatientsFeature">
      <div class="column" [ngClass]="{
          'u-width--large border-left': patientFacesheetSize == 'desktop',
          'u-width--smaller u-display--flex u-justify-content--center': patientFacesheetSize != 'desktop'
        }" *ngIf='!patient.hideTabsAndDisableTimeTracking'>
        <coach-patient-time-tracking [desktopSize]="patientFacesheetSize == 'desktop'" [patient]="patient" [tier]="tier">
        </coach-patient-time-tracking>
      </div>
    </ng-container>


  </div>
  <!-- MOBILE DEMOGRAPHICS 2 -->
  <div  *ngIf="patient && patientFacesheetSize == 'mobile'" class="u-text-align--center t3 padding-leftRight--smallest padding-bottom--small">
    <div class="title" id="patientDisplayNameCondensed">
      {{patient.displayName()}}
    </div>
    <div class="u-display--flex u-flex-direction--row u-flex-wrap--wrap u-justify-content--spaceEvenly">
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller"> Gender: {{patient.displayGender()}}  </div>
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller"> {{patient.displayAge()}}  </div>
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller">{{patient.displayDob()}}  </div>
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller" *ngIf="patient.mbi">MBI: {{patient.mbi}}  </div>
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller" *ngIf="patient.displayIdentifier() && patient.displayIdentifier().length > 0">{{patient.displayIdentifier()}}  </div>
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller">Phone {{patient.displayPhone()}}  </div>
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller">Email {{patient.email}}  </div>
      <div class="u-breakText--all u-font-size--small margin-leftRight--smaller" *ngIf="patient.dod">{{ patient.displayDod() }}</div>
      <div *ngIf="!patient.trackPatient" class="field u-color--brand--3">
        Not attributed
      </div>
    </div>
    <p class="u-breakText--all margin-bottom--none u-font-size--small" *ngIf="patient.attributedCP">
      Attributed Provider: {{ patient.attributedCP }}
    </p>
    <p class="u-breakText--all margin-bottom--none u-font-size--small" *ngIf="patient.raF_YTD">
      Chronic Conditions Risk Adjustment (YTD): <span class="u-bold">{{ patient.raF_YTD }} </span>
      <span>({{ titleLine([patient.assignment, patient.enrollmentType], ', ') }})</span>
    </p>
  </div>
</ng-container>


<coach-patient-demographics [patient]="patient" [tier]="tier" [patientFacesheetSize]="patientFacesheetSize" *hasFeatureFlag="FacesheetUIUpdatesFeatureFlag"></coach-patient-demographics>


<coach-patient-expanded #chartExpanded class="column u-fill chart chart--expanded"
  [patient]="patient" [tier]="tier" [isSMSTaskAdaptive]="isSMSTaskAdaptive" [isScreenSmall]="patientFacesheetSize != 'desktop'">
</coach-patient-expanded>
<!-- <coach-patient-condensed #chartCondensed *ngIf="patientFacesheetSize != 'desktop' && patient"
  class="column u-fill chart chart--condensed" [patient]="patient" [tier]="tier" [isSMSTaskAdaptive]="isSMSTaskAdaptive">
</coach-patient-condensed> -->

<!-- MODAL -->
<ng-container *ngIf="modalStatus.isOpen">
  <div class="overlay is-open">
    <div class="modal patientInviteModal u-width--larger">
      <coach-patient-invite-form *ngIf="modalStatus.target == 'modalPatientInviteForm' && !isSMSInviteFlagEnabled" (closeForm)="closeModal()" [patient]="patient" [tier]="tier"></coach-patient-invite-form>
      <coach-single-patient-invite-form *ngIf="modalStatus.target == 'modalPatientInviteForm' && isSMSInviteFlagEnabled" (closeForm)="closeModal()" [patient]="patient" [tier]="tier"></coach-single-patient-invite-form>
      <coach-patient-edit-form *ngIf="modalStatus.target == 'modalPatientEditForm'" (closeForm)="closeModal()" [patient]="patient" [tier]="tier"></coach-patient-edit-form>
    </div>
  </div>
</ng-container>
