<div *ngIf="(patientClone && !isPatientInvited) || patients" >
    <!-- HEADER -->
    <div class="header">
        <div class="titles">
            <div class="title text-center">
                Patient<span *ngIf="patients">s</span> Invite Form
            </div>
            <div class="subtitle text-center" *ngIf="patients">
                <span *ngIf="getValidPatientsLength() != patients.length">This invitation will be sent to
                    {{ getValidPatientsLength() }} of
                    {{ patients.length }} patients</span>
                <span *ngIf="getValidPatientsLength() == patients.length">This invitation will be sent to all selected
                    patients</span>
            </div>
            <p class="text-center">Step {{formStep}} of 3</p>
        </div>
    </div>
    <!-- STEP 1 -->
    <span *ngIf="formStep === 1">
        <!-- single patient -->
        <div class="dynamicBody padding" *ngIf="patientClone">

            <!-- LAST, FIRST, and PREFERRED NAME -->
            <div class="list-item padding-leftRight--none">
                <label class="margin-top label" for="patientLastName" [ngClass]="{
                    'u-color--brand--3': !(patientClone.lastName.length > 0) || !(patientClone.firstName.length > 0)
                }">Name *</label>
                <div class="row">
                    <div class="field padding--smallest">
                        <input type="text" id="patientLastName" class="is-disabled" placeholder="Last"
                            [(ngModel)]="patientClone.lastName" [disabled]="true"/>
                    </div>
                    <div class="field padding--smallest">
                        <input type="text" id="patientFirstName" class="is-disabled" placeholder="First"
                            [(ngModel)]="patientClone.firstName" [disabled]="true"/>
                    </div>
                    <div class="field padding--smallest">
                        <input type="text" id="patientPreferredName" placeholder="Preferred"
                            [(ngModel)]="patientClone.nickName" />
                    </div>
                </div>
            </div>

            <!-- GENDER and DOB -->
            <div class="list-item padding-leftRight--none">
                <div class="row">
                    <div class="field padding--smallest">
                        <label class="margin-top label" for="patientDob" [ngClass]="{
                            'u-color--brand--3': !(patientClone.dob.length > 0)
                        }">Date Of Birth *</label>
                        <input type="date" id="patientDob" [(ngModel)]="patientClone.dob" />
                    </div>
                    <div class="field padding--smallest">
                        <label class="margin-top label" for="patientGender">Gender *</label>
                        <div class="u-display--flex">
                            <input value="female" [(ngModel)]="patientClone.gender" id="genderFemale" type="radio">
                            <label for="genderFemale">Female</label>
                            <input value="male" [(ngModel)]="patientClone.gender" id="genderMale" type="radio">
                            <label for="genderMale">Male</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- EMAIL -->
            <label class="margin-top label" for="patientEmail" [ngClass]="{
                'u-color--brand--3': !isInputValid(patientClone, 'email')
            }">Email Address (user@somewhere.com) *</label>
            <input type="text" id="patientEmail" [(ngModel)]="patientClone.email" />
            <div class="field padding--smallest">
                <small id="error-message-email" class="margin-top" *ngIf="!isInputValid(patientClone, 'email')"> Invalid email </small>
            </div>

            <!-- PHONE NUMBER and PROFESSIONAL -->
            <div class="list-item padding-leftRight--none">
                <div class="row">
                    <div class="field padding--smallest">
                        <label class="margin-top label" for="patientMobile"
                        [ngClass]="{
                            'u-color--brand--3': !isInputValid(patientClone, 'phoneNumber')
                        }">Mobile Phone Number *</label>
                        <input type="text" id="patientMobile" placeholder="xxx-xxx-xxxx"
                            [(ngModel)]="patientClone.phoneNumber" />
                    </div>
                    <div class="field padding--smallest">
                        <label class="margin-top label" for="patientProfessional" [ngClass]="{
                            'u-color--brand--3': !(patientClone.professionalId && patientClone.professionalId.length > 0)
                        }">
                            Care Coordinator *
                        </label>
                        <select id="patientProfessional" [(ngModel)]="patientClone.professionalId"
                            name="selectProcedure">
                            <option disabled>Please select
                                professional
                            </option>
                            <option [ngValue]="professional._id"
                                [selected]="patientClone.professionalId == professional._id"
                                *ngFor="let professional of professionals">{{ professional.lastName }}
                                {{ professional.firstName }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="field padding--smallest">
                    <small id="error-message-phone" class="margin-top" *ngIf="!isInputValid(patientClone, 'phoneNumber')"> Invalid phone format </small>
                </div>
            </div>

            <!-- MBI -->
            <div class="list-item padding-leftRight--none">
                <div class="row">
                    <!-- REMOVED MRN CC-2348 -->
                    <div class="field padding--smallest">
                        <label class="margin-top label" for="patientMBI">MBI</label>
                        <input type="text" id="patientMBI" [ngModel]="patientClone.mbi" readonly />
                    </div>
                </div>
            </div>
        </div>
        <!-- bulk invite -->
        <div class="dynamicBody padding" *ngIf="patients">
            <div class="list">
                <div class="list-item" *ngFor="let patient of patients; let idx = index">
                    <div>
                        <div class="field"
                            [ngClass]="{'u-color--brand--3': !isPatientValidForInvitation(patient) }">
                            {{ patient.fullName || patient.patientName }}
                        </div>
                        <div class="row--verticalCenter" *ngIf="!(patient.careId || patient.carePatient)">
                            <div class="badge margin-right--smallest" [ngClass]="{
                          'u-colors--info': patient.firstName?.length > 0
                        }">First name</div>
                            <div class="badge margin-right--smallest" [ngClass]="{
                          'u-colors--info': patient.lastName?.length > 0
                        }">Last name</div>
                            <div class="badge margin-right--smallest" [ngClass]="{
                          'u-colors--info': isInputValid(patient, 'email')
                        }">Email</div>
                            <div class="badge margin-right--smallest" [ngClass]="{
                          'u-colors--info': isInputValid(patient, 'phoneNumber')
                        }">Phone number</div>
                            <div class="badge margin-right--smallest" [ngClass]="{
                          'u-colors--info': patient.dob?.length > 0
                        }">DOB</div>
                        </div>
                        <div class="row--verticalCenter" *ngIf="patient.careId || patient.carePatient">
                            <div class="badge margin-right--smallest">Patient already invited</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div class="footer">
            <div class="u-display--flex u-justify-content--right">
                <button class="button is-borderless margin-right--small" (click)="close('')">Cancel</button>
                <button class="button" (click)="switchPage(1)" [ngClass]="{
                            'is-disabled is-secondary': (patientClone ? !isPatientValid() : getValidPatientsLength()<1) || !orgData,
                            'is-primary': (patientClone ? isPatientValid() : getValidPatientsLength()>0) && orgData
                        }"
                    [disabled]="(patientClone?!isPatientValid():getValidPatientsLength()<1) || !orgData">
                        <coach-loading-indicator *ngIf="!orgData"></coach-loading-indicator>
                        <span *ngIf="orgData">Next</span>
                    </button>
            </div>
        </div>
    </span>




    <!-- STEP 2 -->
    <div class="padding" *ngIf="formStep === 2">
        <div class="field padding--smallest" *ngIf="patients">
            <label class="margin-top label" for="patientProfessional" [ngClass]="{
                'u-color--brand--3': !(bulkPatientsProfessionalId && bulkPatientsProfessionalId.length > 0)
            }">
                Care Coordinator *
            </label>
            <select id="patientProfessional" [(ngModel)]="bulkPatientsProfessionalId" name="selectProcedure">
                <option disabled>Please select
                    professional
                </option>
                <option [ngValue]="professional._id" [selected]="bulkPatientsProfessionalId == professional._id"
                    *ngFor="let professional of professionals">{{ professional.lastName }}
                    {{ professional.firstName }}
                </option>
            </select>
        </div>
        <div *ngIf='orgData'>
            <h5 class="margin-top--none">
                <span *ngIf="orgData.hasProcedures">
                    {{ orgData.availableProceduresProcedureUILabel || "Procedure"}} Configuration</span>
                <span *ngIf="!orgData.hasProcedures">Protocol Configuration</span>
            </h5>
            <div class="column u-fill u-position--relative">

                <!-- PROCEDURE -->
                <p *ngIf="!orgData.availableProcedures?.length">No protocols found.</p>
                <ng-container *ngFor="let procedure of orgData.availableProcedures">
                    <div class="subheader">{{procedure.title}}</div>
                    <div class="list">

                        <!-- PROTOCOL -->
                        <span *ngFor="let protocolId of procedure.allowedProtocols">
                            <div class="list-item list-item--complex formatting--none" *ngIf="protocolsById[protocolId]">
                                <div class="list-item-main" id={{protocolId}} >
                                    <div class="row">
                                        <input [checked]="protocolsById[protocolId].isSelected"
                                            type="checkbox">
                                        <label (click)="selectProtocol(procedure, protocolId)">{{protocolsById[protocolId].name}}</label>
                                    </div>
                                    <div class="row">
                                        <div class="field margin-right--smallest" *ngIf="protocolsById[protocolId] && protocolsById[protocolId].modules">
                                            <small>
                                                <span *ngIf="protocolsById[protocolId].isSelected">{{getSelectedModules(protocolId).length}}
                                                    of</span>
                                                {{protocolsById[protocolId].modules.length}} modules</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-action"
                                    *ngIf="protocolsById[protocolId] && protocolsById[protocolId].modules && protocolsById[protocolId].modules.length > 0"
                                    (click)="toggleModuleSelectorOpen(protocolId)">
                                    <coach-icon class="u-opacity--lightest transition-fix" iconName="chevron-down"
                                        iconColor="currentColor"></coach-icon>
                                </div>
                            </div>
                            <div class="dropdown padding--smaller" *ngIf="protocolsById[protocolId]" [ngClass]="{'dropdown--hidden': !protocolsById[protocolId].isModuleSelectorIsOpen}">
                                <div class="header u-align-items--center padding--none">
                                    <div class="titles t5">
                                        <div class="title">Select modules</div>
                                    </div>
                                    <button class="button is-small is-icon border--none" (click)="protocolsById[protocolId].isModuleSelectorIsOpen = false">
                                        <coach-icon iconName="x" iconColor="default"></coach-icon>
                                    </button>
                                </div>

                                <!-- MODULE -->
                                <div *ngFor="let module of protocolsById[protocolId].modules; let moduleIdx = index">
                                    <input id="checkbox{{moduleIdx}}" (change)="checkProcedureAndProtocol(procedure, protocolId)" [(ngModel)]="module.isActive" type="checkbox">
                                    <label for="checkbox{{moduleIdx}}">{{modulesById[module.protocolId] ? modulesById[module.protocolId].name :
                                        module.protocolId}}</label>
                                </div>
                            </div>
                            <p *ngIf="!protocolsById[protocolId]" class="u-color--brand--3 margin-leftRight--large">Can't find protocol</p>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="footer" *ngIf="formStep === 2">
        <div class="u-display--flex u-justify-content--right">
            <button class="button margin-right--small" (click)="switchPage(-1)">Back</button>
            <button class="button is-borderless margin-right--small" (click)="close('')">Cancel</button>
            <button class="button" (click)="switchPage(1)" [ngClass]="{
                'is-disabled is-secondary': getSelectedProceduresLength()<1 || !(patients?(bulkPatientsProfessionalId && bulkPatientsProfessionalId.length > 0):true),
                'is-primary': getSelectedProceduresLength()>0 && (patients?(bulkPatientsProfessionalId && bulkPatientsProfessionalId.length > 0):true)
            }"
                [disabled]="getSelectedProceduresLength()<1 || !(patients?(bulkPatientsProfessionalId && bulkPatientsProfessionalId.length > 0):true)">Next</button>
        </div>
    </div>

    <!-- STEP 3 -->
    <div class="dynamicBody padding" *ngIf="formStep === 3">
        <h3 class="margin-topBottom--small">Summary</h3>
        <!-- single patient -->
        <div class="u-display--flex margin-topBottom--small" *ngIf="patientClone">
            <coach-icon iconName="user" iconSize="medium"></coach-icon>
            <div class='u-display--flex u-flex-direction--column margin-left--small'>
                <h5 class="margin-topBottom--none">{{patientClone.lastName}} {{patientClone.nickName}},
                    {{patientClone.firstName}}</h5>
                <p class="margin-topBottom--none u-thin">{{patientClone.email}}</p>
                <p> {{patientClone.phoneNumber}} </p>
            </div>
        </div>
        <!-- multiple patients -->
        <div class="dynamicBody" *ngIf="patients">
            <div class="list">
                <span *ngFor="let patient of patients; let idx = index">
                    <div class="list-item" *ngIf="isPatientValidForInvitation(patient) || patient.inviteStatus">
                        <div class="row u-display--flex u-justify-content--spce">
                            <div class="field">
                                {{ patient.fullName }}
                            </div>
                            <div class="row--verticalCenter">
                                <div *ngIf="patient.inviteStatus" class="badge margin-right--smallest text-right" [ngClass]="{
                                        'u-colors--info': patient.inviteStatus.loading === 0,
                                        'u-colors--warning': patient.inviteStatus.loading == 1,
                                        'u-colors--success': patient.inviteStatus.loading == 2,
                                        'u-colors--danger': patient.inviteStatus.loading == -1
                                        }">
                                    {{patient.inviteStatus.message}}
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <span *ngFor="let procedure of orgData.availableProcedures" [ngClass]="{'u-display--none': !procedure.isSelected}">
            <div *ngFor="let protocolId of procedure.allowedProtocols"
            [ngClass]="{
                'u-display--none': (!protocolsById[protocolId] || !protocolsById[protocolId].isSelected),
                'u-display--flex': (protocolsById[protocolId] && protocolsById[protocolId].isSelected)
            }">
                <coach-icon iconName="info" iconSize="medium"></coach-icon>
                <div class='u-display--flex u-flex-direction--column margin-left--small u-justify-content--center'>
                    <h5 class="margin-topBottom--none">{{procedure.title}}: {{protocolsById[protocolId].name}}</h5>
                    <ul class="u-fill" *ngIf="protocolsById[protocolId].modules">
                        <li *ngFor="let module of protocolsById[protocolId].modules" [ngClass]="{'u-display--none': !module.isActive}">
                            {{modulesById[module.protocolId] ? modulesById[module.protocolId].name :
                                module.protocolId}}</li>
                    </ul>
                </div>
            </div>
        </span>

        <div *ngIf="patientClone && emailAndSmsInvitationEligible.sms">
            <input id="checkbox_smsEnrollmentAgreed" type="checkbox" [(ngModel)]="smsEnrollmentAgreed">
            <label for="checkbox_smsEnrollmentAgreed"> Patient has verbally consented to be enrolled in the SMS program. </label>
        </div>

        <h4 class="margin-topBottom--large u-italic">Ready to send?</h4>
    </div>
    <div class="footer" *ngIf="formStep === 3 && patientClone" [ngClass]="{
        'u-colors--success': patientClone.inviteStatus?.loading == 2,
        'u-colors--danger': patientClone.inviteStatus?.loading == -1
    }">
        <div class="u-display--flex u-justify-content--right" *ngIf="!patientClone.inviteStatus || patientClone.inviteStatus?.loading === 0">
            <button class="button margin-right--small" (click)="switchPage(-1)">Back</button>
            <button class="button is-borderless margin-right--small" (click)="close('')">Cancel</button>
            <button class="button is-primary" [disabled]="emailAndSmsInvitationEligible.sms && !smsEnrollmentAgreed" (click)="submit(patientClone, patientClone.professionalId)">Send
                Invitation</button>
        </div>
        <div class="u-display--flex u-justify-content--right u-align-items--center"
            *ngIf="patientClone.inviteStatus && patientClone.inviteStatus.loading !== 0">
            <coach-loading-indicator size="30" *ngIf="patientClone.inviteStatus?.loading === 1">
            </coach-loading-indicator>
            <span class="margin-leftRight--large">{{patientClone.inviteStatus?.message}}</span>

            <button class="button is-primary" [disabled]="emailAndSmsInvitationEligible.sms && !smsEnrollmentAgreed" [ngClass]="{'u-disabled': patientClone.inviteStatus?.loading === 1}"
                [disabled]="patientClone.inviteStatus?.loading === 1" (click)="close('')">
                Close
            </button>
        </div>
    </div>
    <div class="footer" *ngIf="formStep === 3 && patients">
        <div class="u-display--flex u-justify-content--right">
            <button class="button margin-right--small"  *ngIf="bulkInviteStatus.message != 'done'" (click)="switchPage(-1)">Back</button>
            <button class="button is-borderless margin-right--small" (click)="close('')">
                <span *ngIf="bulkInviteStatus.message != 'done'">Cancel</span>
                <span *ngIf="bulkInviteStatus.message == 'done'">Close</span>
            </button>
            <button class="button is-primary" [ngClass]="{'is-disabled': bulkInviteStatus.message == 'done'}" [disabled]="bulkInviteStatus.message == 'done'" (click)="submitMultiplePatients()">Send Invitations</button>
        </div>
    </div>
</div>
<div *ngIf="patientClone && isPatientInvited">
    <!-- HEADER -->
    <div class="header">
        <div class="titles">
            <div class="title text-center">
                Patient Re-Invite Form
            </div>
        </div>
    </div>
    <div class="dynamicBody padding">
        <!-- single patient -->
        <div class="u-display--flex margin-topBottom--small" *ngIf="patientClone">
            <coach-icon iconName="user" iconSize="large"></coach-icon>
            <div class='u-display--flex u-flex-direction--column margin-left--small'>
                <h5 class="margin-topBottom--none">{{patientClone.lastName}} {{patientClone.nickName}},
                    {{patientClone.firstName}}</h5>
                <p class="margin-topBottom--none u-thin">{{patientClone.email}}</p>
            </div>
        </div>
    </div>
    <div class="footer" [ngClass]="{
        'u-colors--success': patientClone.inviteStatus?.loading == 2,
        'u-colors--danger': patientClone.inviteStatus?.loading == -1
    }">
        <div class="u-display--flex u-justify-content--right" *ngIf="!patientClone.inviteStatus || patientClone.inviteStatus?.loading === 0">
            <button class="button is-borderless margin-right--small" (click)="close('')">Cancel</button>
            <button class="button is-primary" [ngClass]="{
                'is-danger': !isSMSPatient,
                'is-primary': isSMSPatient
            }" (click)="reinvite(patientClone)" [disabled]="!isSMSPatient">
                <span *ngIf="isSMSPatient">Send Invitation</span>
                <span *ngIf="!isSMSPatient">Can't re-send invitation sms</span>
            </button>
        </div>
        <div class="u-display--flex u-justify-content--right u-align-items--center"
            *ngIf="patientClone.inviteStatus && patientClone.inviteStatus.loading !== 0">
            <coach-loading-indicator size="30" *ngIf="patientClone.inviteStatus?.loading === 1">
            </coach-loading-indicator>
            <span class="margin-leftRight--large">{{patientClone.inviteStatus?.message}}</span>

            <button class="button is-primary" [ngClass]="{'u-disabled': patientClone.inviteStatus?.loading === 1}"
                [disabled]="patientClone.inviteStatus?.loading === 1" (click)="close('')">
                Close
            </button>
        </div>
    </div>
</div>
