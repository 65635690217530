import {Component, Input, OnInit, } from '@angular/core';
import {Patient} from '../../models/patient';
import {PatientService} from '../../services/patient/patient.service';
import {AuthService} from '../../services/auth-service/auth.service';

@Component({
  selector: 'coach-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit{
  @Input() public mobileScreenSize: boolean;
  @Input() public patient: Patient;
  @Input() sidebarMode?: boolean;
  @Input() public icon: string;
  @Input() public activityCompleted = true;
  @Input() public isLastStep = false;
  constructor(private patientService: PatientService, private authService: AuthService ) {}

  ngOnInit(): void {
  }
}


