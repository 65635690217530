import { Component, OnInit } from '@angular/core';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { AG_GRID_LOCALE_CARE } from '@shared/services/ag-grid/ag-grid.service';
import { ColDef } from 'ag-grid-community';
import { PresetSettingsService } from '../preset-settings.service';

@Component({
  selector: 'coach-care-patients-grid',
  templateUrl: './care-patients-grid.component.html',
  styleUrls: ['./care-patients-grid.component.scss']
})
export class CarePatientsGridComponent implements OnInit {

  selectedPreset: IPatientFilterPreset;
  AG_GRID_LOCALE_CARE: { [key: string]: string } = AG_GRID_LOCALE_CARE;

  constructor(private presetSettingsService: PresetSettingsService) { }

  ngOnInit(): void {
    this.presetSettingsService.selectedPreset$.subscribe(preset => {
      this.selectedPreset = preset;
    });
  }

}
