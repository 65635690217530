import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'clickable-cell',
  template: `
    <ag-clickable (onClicked)="clicked($event)" [cell]="cell"></ag-clickable>
  `
})
export class ClickableParentComponent implements AgRendererComponent {
  public params: ICellRendererParams;
  public cell: any;
  agInit(params): void {
    this.params = params;
    this.cell = { row: params.value, col: params.colDef.headerName };
  }

  public clicked(cell: any): void {
    this.params.context.componentParent.reportParentClickResult(
      this.params.value,
      this.params.node.data,
      this.params.rowIndex
    );
  }

  refresh(): boolean {
    return false;
  }
}
