import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'coach-submit-interoperability-registry-question',
  templateUrl: './submit-interoperability-registry-question.component.html',
  styleUrls: ['./submit-interoperability-registry-question.component.scss']
})
export class SubmitInteroperabilityRegistryQuestionComponent {

  @Input() interopForm: UntypedFormGroup;

  public isInfoTextOpen = false;

  constructor() { }

}
