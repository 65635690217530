import { B2CTokenPayload, UserInfo } from './auth-interface';
import { environment } from 'src/environments/environment';
import {
  EventTypes,
  OidcClientNotification,
  LogLevel,
  PublicEventsService,
  LoginResponse
} from 'angular-auth-oidc-client';
import { TokenHelperService } from 'angular-auth-oidc-client';
import { filter, map } from 'rxjs/operators';
import { HashLocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { Patient } from '@shared/models/patient';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
let ORIGIN_URL: string;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public static readonly TIMED_OUT = 'timed_out';

  private static i = 0;
  public isAuthenticated$: Observable<boolean>;
  public userData$: Observable<UserInfo>;
  public token: string;
  private _userInfoInternal$ = new BehaviorSubject<UserInfo>(null);
  public chEndPoint: string = environment.chEndPoint;
  private _inlogout = false;
  private _wpUserPromise: Promise<wpapi.model.Professional>;
  private _authCheckComplete$ = new ReplaySubject<boolean>();
  wellpepperUser: wpapi.model.Professional;
  //private oidcSecurityService:OidcSecurityService;
  public get authCheckComplete$(): Observable<boolean> {
    return this._authCheckComplete$.asObservable();
  }

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private readonly publicEventsService: PublicEventsService,
    private tokenHelperService: TokenHelperService,
    private restApiService: RestApiService,
    private hashLocationStrategy: HashLocationStrategy,
    private http: HttpClient,
    private ToastrMessageService: ToastrMessageService
  ) {
    this.debuglog('inC Constructor', ++AuthService.i);
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
      map(({ isAuthenticated }) => isAuthenticated)
    );
    this.publicEventsService
      .registerForEvents()
      .pipe(
        filter(
          notification =>
            notification.type === EventTypes.NewAuthenticationResult || notification.type === EventTypes.IdTokenExpired
        )
      )
      .subscribe(value => this.onNewAuthorizationResult(value));
  }
  public init(base: any): void {
    this.oidcSecurityService.checkAuth().subscribe(
      (loginResponse: LoginResponse) => {
        this._authCheckComplete$.next(true);
      },
      error => {
        this.ToastrMessageService.error(error);
      }
    );

    this.userData$ = this.oidcSecurityService.userData$.pipe(map(userDataResult => userDataResult.userData));
    this.token = this.oidcSecurityService.getIdToken();
    this.publishUserInfoIfExists();
    const baseHref = this.hashLocationStrategy.getBaseHref();
    ORIGIN_URL = baseHref !== '/' ? `${window.location.origin}/${baseHref}` : window.location.origin;
  }

  onNewAuthorizationResult(oidNotification: OidcClientNotification<any>) {
    if (oidNotification && oidNotification.type === EventTypes.NewAuthenticationResult) {
      this.debuglog('NewAuthorizationResult with value ', oidNotification);
      this.callGetUserInfo();
    } else if (oidNotification.type === EventTypes.IdTokenExpired) {
      // to do: show as signed out
      this.debuglog('idTokenExpired', oidNotification);
    }
  }

  get userInfo$(): Observable<UserInfo> {
    return this._userInfoInternal$.asObservable();
  }

  public getToken(): string {
    return this.oidcSecurityService.getIdToken();
  }

  public checkAuthentication(base: any): boolean {
    let checkAuthentication = false;
    this.oidcSecurityService.checkAuth().subscribe(
      isAuthenticated => {
        this._authCheckComplete$.next(true);
        if (isAuthenticated) {
          checkAuthentication = true;
          // to do call it every time u get the tokens
          // this.callGetUserInfo();
        }
      },
      error => {
        this.ToastrMessageService.error(error);
      }
    );
    //to do plug into the login page-for all other pages call Authorize.
    //,()=>this.OnCompleteCheckAuth(checkAuthentication));
    return checkAuthentication;
  }

  // private OnCompleteCheckAuth(checkAuthentication) {
  //   let checkAuthServer = false;
  //   // todo in the scenario where it is already true
  //   // if (!checkAuthentication)
  //   this.debuglog('checkAuthIncludingServer Enter on Completion:', checkAuthentication);
  //   this.oidcSecurityService.checkAuthIncludingServer().subscribe(
  //     isAuthenticated => {
  //       checkAuthServer = isAuthenticated;
  //       this.debuglog('checkAuthIncludingServer Done:', isAuthenticated);
  //     },
  //     error => {
  //       this.ToastrMessageService.error(error);
  //       this.debuglog('checkAuthIncludingServer Error:', error);
  //     }
  //   );
  // }

  private callGetUserInfo() {
    const userObj = this.oidcSecurityService.getPayloadFromIdToken(false);
    // quick way to to make sure we are logged in
    if (!userObj) {
      return;
    }
    const userInfoEndPoint: string = userObj.idp + '/connect/userinfo';
    this.debuglog('userInfoEndPoint:', userInfoEndPoint);
    const idp: string = userObj.idp;
    if (this.chEndPoint.search(idp) > -1) {
      this.debuglog('token', userInfoEndPoint);
      this.restApiService.getHttp(userInfoEndPoint, userObj.idp_access_token).subscribe(
        body => {
          const userInfo: UserInfo = body as UserInfo;
          this.setUserInfoToStore(userInfo);

          this.debuglog('userInfo:', userInfo);
        },
        error => {
          console.log('error =>', error);
          this.ToastrMessageService.error(error);
          this.restApiService.handleHttpErrorResponse(error, 'GetUserInfo');
          this.resetUserInfoInStore();
        }
      );
    }
  }
  private get endSessionUrl(): string {
    const userObj = this.oidcSecurityService.getPayloadFromIdToken(false);
    if (this.chEndPoint.search(userObj.idp) > -1) {
      this.debuglog('token in getENdSessionUtl', this.oidcSecurityService.getIdToken());
      let token = this.oidcSecurityService.getIdToken();
      token = userObj.idp_access_token;
      token = encodeURI(token);
      const userInfoEndPoint: string =
        userObj.idp +
        '/connect/endSession?' +
        'id_token_hint=' +
        token +
        '&post_logout_redirect_uri=' +
        encodeURI(ORIGIN_URL);
      this.debuglog('endSessionEndPoint:', userInfoEndPoint);
      return userInfoEndPoint;
    } else {
      return null;
    }
  }

  private endSession(url: string) {
    this._wpUserPromise = null;
    // Work around: directly callin IDS server
    // to do: make it work with a no ancestor ifrtame...
    if (url) {
      this.restApiService.redirectTo(url);
    }
    /*
    No longer using get instead using redirectto endsession..
    let userObj = this.oidcSecurityService.getPayloadFromIdToken(false);
    let token = this.oidcSecurityService.getIdToken();//userObj.idp_access_token;

    this.restApiService.getHttp(this.getEndSessionUrl(), null).subscribe((params)=>
    {

      this.debuglog("params:", params);

    },
    error=>{this.restApiService.handleHttpErrorResponse(error,'endSession');
    this.userInfo=null; this.inlogout=false});

  }
  */
  }

  public logoffLocal() {
    this.oidcSecurityService.logoffLocal();
    this.oidcSecurityService.revokeAccessToken();
    this.oidcSecurityService.revokeRefreshToken();
  }

  public logout() {
    this._wpUserPromise = null;
    this._inlogout = true;
    // Alert: getEndSessionUrl will not work after logoff local so cache the value
    const url = this.endSessionUrl;
    this.resetUserInfoInStore();
    // revking and logoff works correctly with IDS now
    // since we now return the the HOME Page after logging out
    // this should help in making sure x-ms-cpim-rc expire
    // this should eliminate the header too long login bug
    this.callLogoffAndRevokeTokens(url);
  }

  private callLogoffAndRevokeTokens(url) {
    // COM-5641: This call is returning an error when trying to revoke the access token
    // because Azure B2C does not publish a token revocation endpoint
    // This does not adversely affect the logout process

    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(
      result => {
        this._inlogout = false;
        this.debuglog('loggin out:', result);
        //will getcalled for Id servers which do no redirect correctly
        //IDS server redirects correctly so we we never reach this code.
        if (url) this.endSession(url);
        // this.logofflocal();
      },
      error => {
        this.endSession(url);
        this._inlogout = false;
        this.debuglog('loggin out ERROR:', error);
      }
    );
  }

  public login() {
    if (this._inlogout) {
      return;
    }
    this.oidcSecurityService.authorize();
  }

  private debuglog(...data: any[]): void {
    if (!environment.production) {
      console.error(...data);
    }
  }
  public get IdToken(): string {
    return this.oidcSecurityService.getIdToken();
  }

  public getPayloadFromIdToken(): B2CTokenPayload {
    return this.oidcSecurityService.getPayloadFromIdToken(false);
  }

  public get idp_access_token(): string {
    let token: string;
    const userObj = this.oidcSecurityService.getPayloadFromIdToken(false);
    if (userObj && userObj.idp_access_token) {
      token = userObj.idp_access_token;
    }
    return token;
  }

  public get idp_sub(): string {
    let issuerUserId: string;
    const userObj: B2CTokenPayload = this.oidcSecurityService.getPayloadFromIdToken(false);
    if (userObj && userObj.idp_sub) {
      issuerUserId = userObj.idp_sub;
    }
    return issuerUserId;
  }

  private getUserInfoFromStore(): any {
    return this.read('userInfo') || null;
  }
  public getUserId(): string {
    return this.read('userInfo')?.id || null;
  }
  public getUserDnnId(): string {
    return this.read('userInfo')?.dnn_id || null;
  }

  private publishUserInfoIfExists() {
    const userInfo: UserInfo = this.getUserInfoFromStore();
    if (userInfo) {
      this._userInfoInternal$.next(userInfo);
    }
  }

  private setUserInfoToStore(value: any): void {
    this.write('userInfo', value);
    this._userInfoInternal$.next(value);
  }

  private resetUserInfoInStore(): void {
    this.write('userInfo', null);
    this._userInfoInternal$.next(null);
  }
  public write(key: string, value: any): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }
  public read(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
  }

  setWellpepperUser(user) {
    this.wellpepperUser = user;
  }

  /** Get Wellpepper professional info for the currently logged in user.
   * This is cached and won't be refreshed for the session */
  public async getWellpepperUserInfo(): Promise<wpapi.model.Professional> {
    if (this._wpUserPromise) {
      return this._wpUserPromise;
    }
    const url = `${environment.wpApiUrl}/users/me`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this.IdToken
      }
    };
    this._wpUserPromise = this.http.get(url, options).toPromise() as Promise<wpapi.model.Professional>;
    this._wpUserPromise.catch((err: HttpErrorResponse) => {
      // NOTE: many places that call this function do not await the result so the error can be lost
      // Because a failure in the request is possible and can lead to a lot of downstream problems,
      // display a red box error.
      console.log('getCurrentUserInfo failed', err);
      this.ToastrMessageService.error(err);
    });
    return this._wpUserPromise;
  }

  // this will return false if not signed in.
  public isIdpExpiring(offsetMins: number = 0): boolean {
    let decodedIdToken = this.getToken();
    if (!decodedIdToken) return true;
    const tokenExpirationDate = this.tokenHelperService.getTokenExpirationDate(decodedIdToken);
    let offsetMilliSeconds = offsetMins * 60 * 1000;
    if (!tokenExpirationDate) {
      return true;
    }
    const tokenExpirationValue = tokenExpirationDate.valueOf();
    let nowDate = new Date();
    const nowWithOffset = nowDate.valueOf() + offsetMilliSeconds;
    const tokenExpired = tokenExpirationValue < nowWithOffset;
    console.log(
      `Has id_token expired=> ${!tokenExpired}, ${tokenExpirationDate.toISOString()} > ${nowDate.toISOString()}`
    );
    return tokenExpired;
  }

  // retunrs false if not signed in
  public isIdpExpiringIfSignedIn(offsetMins: number = 0): boolean {
    let decodedIdToken = this.getToken();
    if (!decodedIdToken) return false;
    const tokenExpirationDate = this.tokenHelperService.getTokenExpirationDate(decodedIdToken);
    let offsetMilliSeconds = offsetMins * 60 * 1000;
    if (!tokenExpirationDate) {
      return false;
    }
    const tokenExpirationValue = tokenExpirationDate.valueOf();
    let nowDate = new Date();
    const nowWithOffset = nowDate.valueOf() + offsetMilliSeconds;
    const tokenExpired = tokenExpirationValue < nowWithOffset;
    // console.log(`Has id_token expired=> ${!tokenExpired}, ${tokenExpirationDate.toISOString()} > ${nowDate.toISOString()}`);
    return tokenExpired;
  }
}
export function loadConfig() {
  const originUrl = ORIGIN_URL || window.location.origin + document.querySelector('base').getAttribute('href');
  return {
    authority: environment.stsServer,
    authWellknownEndpointUrl: environment.authWellknownEndpoint,
    redirectUrl: originUrl,
    postLogoutRedirectUri: originUrl,
    clientId: environment.authClientId,
    scope: environment.authConfigScope,
    responseType: 'code',
    silentRenew: true,
    autoUserInfo: false,
    logLevel: LogLevel.Error,
    silentRenewUrl: originUrl + '/silent-renew.html',
    renewTimeBeforeTokenExpiresInSeconds: 60,
    useRefreshToken: true, // for refresh renew, but revocation and one time usage is missing from server impl.
    ignoreNonceAfterRefresh: true,
    disableRefreshIdTokenAuthTimeValidation: true
  };
}
