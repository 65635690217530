import {
  CareFeatureConstants,
  ModuleConstants,
} from '@shared/models/module-constants';

export interface IView {
  label: string;
  icon: string;
  urlSegment: string;
  path: string;
  moduleId: number;
  hasPHI?: boolean;
  featureFlag?: string;
  hideIfFeatureFlag?: string;
}

export const Views: IView[] = [
  {
    label: 'Home',
    icon: 'home',
    urlSegment: '_/_/home',
    path: 'home',
    moduleId: ModuleConstants.HomeModule
  },
  {
    label: 'Care',
    icon: 'users',
    urlSegment: '_/_/care',
    path: 'care',
    moduleId: ModuleConstants.CareModule,
    hideIfFeatureFlag: CareFeatureConstants.CareLandingPageFlag,
    hasPHI: true
  },
  {
    label: 'Care',
    icon: 'users',
    urlSegment: '_/_/care/summary-tab',
    path: 'care/summary-tab',
    moduleId: ModuleConstants.CareModule,
    featureFlag: CareFeatureConstants.CareLandingPageFlag,
    hasPHI: true
  },
  {
    label: 'Track',
    icon: 'trending-up',
    urlSegment: '_/_/track',
    path: 'track',
    moduleId: ModuleConstants.TrackModule
  },
  {
    label: 'Improve',
    icon: 'award',
    urlSegment: '_/_/improve',
    path: 'improve',
    moduleId: ModuleConstants.ImproveModule
  },
  {
    label: 'Submit',
    icon: 'upload',
    urlSegment: '_/_/submit',
    path: 'submit',
    moduleId: ModuleConstants.SubmitModule
  }
];
