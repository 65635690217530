import { forwardRef, Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { environment } from '../../../../environments/environment';
import { HierarchyTierService } from '../hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';

@Injectable()
export class CareOrchApiInterceptor implements HttpInterceptor {

  private currentTier: IHierarchyTier;

  constructor(private _authService: AuthService, private hierarchyTierService: HierarchyTierService) {
    this.hierarchyTierService.currentTier$.subscribe(tier => {
      this.currentTier = tier;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isACareOrchRequest(req)) {
      req = this.extendCareOrchRequest(req);
    }

    return next.handle(req);
  }

  private isACareOrchRequest(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.careOrchApiUrl);
  }

  private extendCareOrchRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + this._authService.IdToken,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Tier-Id': this.currentTier?.selectedTierId,
        'Tier-Num': this.currentTier?.tier.toString(),
      }
    });
  }
}

export const CARE_ORCH_API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => CareOrchApiInterceptor),
  multi: true
};
