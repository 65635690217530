<meta name="viewport" content="width=device-width, initial-scale=1">
<div class = "content-body"> 
<div class="scroll">   
    <div class="header containter-fluid">
        <div class="header-rectangle" style="background-color: rgb(240, 196, 64);">
           <h2 class="header-title">Practice Improvements</h2>
            <p class="header-sub-title">
              The Practice Improvement section is dedicated to resources and education relating to your day-to-day work in practice transformation. 
            </p>            
        </div>
        <img class="img-fluid header-image" src="assets\img\illustrations\SH_PEP_Illustrations_Improve_Sum_PI_1700x1100_20221114.svg"/>
    </div>

  <br>
  <br>   
  <div class = "description">
    <p>
      This section is dedicated to resources and education relating to your day-to-day work in practice transformation. Roadmap calls with your {{app.CompanyName}} Health team will help you form strategies to meet the goals set in your Steering Committee meetings. Here you can find the tools and tactics that will help you along the way. 
      <br> <br>
      In this section, you can find supplemental materials, review information from your past Roadmaps, as well as prepare for your next meeting. 
    </p>
</div> 

