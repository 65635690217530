<div class="header u-text-align--center">
    <div class="titles">
        <div class="title2">Edit Grid Columns</div>
        <div class="title4">{{presetName}}</div>
        <div class="subtitle">
            Select the columns you want
        </div>
    </div>
</div>
<div class="dynamicBody padding--small">
    <div class="list border">
        <div class="list-item" *ngFor="let column of columnSettingsList; let idx = index">
            <div class="row" >
                <input checked="true" id="checkbox_{{idx}}" [(ngModel)]="column.display" type="checkbox">
                <label for="checkbox_{{idx}}">{{column.headerName}}</label>
            </div> 
        </div>
    </div>
</div>
<div class="footer">
    <button class="button margin-right border--none" (click)="closeModal()">Cancel</button>
    <button class="button is-primary" (click)="submitChanges()">Submit</button>
  </div>