import { Component, Input, OnInit } from '@angular/core';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { TrackPerformanceWidgetApiService } from '@shared/services/performance-dashboard/track-performance-widget-api.service';
import { TrackPerformanceWidgetData } from '@shared/services/performance-dashboard/track-performance-widget-data';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '../kill-subscriptions';
import { TrackPerformanceDashboardTargetStatus } from '@shared/services/performance-dashboard/track-performance-dashboard-constants';

const {
    NeedsAttention,
    NeedsImprovement,
    HittingTarget,
    Connected,
    NotConnected
  } = TrackPerformanceDashboardTargetStatus;

@Component({
  selector: 'coach-track-performance-widget',
  templateUrl: './track-performance-widget.component.html',
  styleUrls: ['./track-performance-widget.component.scss']
})
export class TrackPerformanceWidgetComponent extends KillSubscriptions implements OnInit {

  /** Callback that fires when an item is clicked */
  @Input()
  navigateToItem: (e: Event, path: string, query?: unknown | null) => void;

  /** Hide widget illustration */
  @Input()
  hideIllustration = false;

  performanceDashboardFeature = TrackFeatureConstants.PerformanceDashboardFeature;
  performanceDashboardFeatureFlag = TrackFeatureConstants.PerformanceDashboardFeatureFlag;
  performanceDashboardName = TrackFeatureConstants.PerformanceDashboardName;

  needsAttention = NeedsAttention;
  needsImprovement = NeedsImprovement;
  hittingTarget = HittingTarget;
  connected = Connected;
  notConnected = NotConnected;

  widgetData: TrackPerformanceWidgetData[] = [];

  constructor(private hierarchyTierService: HierarchyTierService, private trackPerformanceService: TrackPerformanceWidgetApiService) {
    super();
  }

  ngOnInit(): void {
    this.hierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger), filter(currentTier => currentTier != null), switchMap(tier => this.trackPerformanceService.getWidgetData(tier?.tier, tier?.selectedTierId)))
      .subscribe(results => {
        this.widgetData = results;
      });
  }

}
