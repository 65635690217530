import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HeaderCondensedService } from '@shared/services/header-condensed/header-condensed.service';
import { SideBarToggleService } from '@shared/services/sidebar-toggle/sidebar-toggle.service';
import { RecentsConstants } from '@shared/models/module-constants';

@Component({
  selector: 'coach-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  @Input() public state;
  public collapseSidebar$: BehaviorSubject<boolean>;
  public isSidebarOpen: boolean;
  @Output() sidebarToggled: EventEmitter<boolean> = new EventEmitter();

  public readonly Recents = RecentsConstants.RecentsFeatureFlag;

  constructor(
    private HeaderCondensedService: HeaderCondensedService,
    private sidebarToggleService: SideBarToggleService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.collapseSidebar$ = this.HeaderCondensedService.collapseSidebar$;
  }

  ngOnInit(): void {
    this.isSidebarOpen = this.state;

    this.collapseSidebar$.subscribe((whenChanged) => {
      if (whenChanged === true) {
        this.isSidebarOpen = false;
        this.sidebarToggled.emit(false);
      }
    });
  }

  public navigateHome(): void {
    this.router.navigate(['home'], { relativeTo: this.route.children[0] });
  }

  public toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.sidebarToggled.emit(this.isSidebarOpen);
    this.sidebarToggleService.toggleSidebarPane(this.isSidebarOpen);
  }

  public menuItemClicked(event) {
    this.isSidebarOpen = event;
    this.sidebarToggled.emit(this.isSidebarOpen);
  }
}
