<div class="u-height--fit-content margin-auto row min-height">
  <div class="span--2 u-border-radius--rounded">
    <coach-stepper class="stepper" [icon]="'right-arrow'" [isLastStep]="true" [activityCompleted]="true"></coach-stepper>
  </div>
  <div class="span--10">
    <div class="row" >
      <div class="span--12 padding-left--small pointer">
        <div class="u-bold">Deferred to Signify</div>
        <div class="u-color--brand--6 u-font-weight--light">On in home AWV list</div>
      </div>
    </div>
  </div>
</div>

