import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { GridApi, Column, CsvExportParams } from 'ag-grid-community';

@Component({
  selector: 'coach-export-report-button',
  templateUrl: './export-report-button.component.html',
  styleUrls: ['./export-report-button.component.scss']
})
export class ExportReportButtonComponent implements OnInit {

  private seperator = '|';

  @Input()
  reportName: string;

  @Input()
  gridApi: GridApi;

  @Input()
  buttonText: string = "Export";

  @Input()
  allColumns: boolean = false;

  @Input()
  columnKeys: (string | Column)[] = null;

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
  }

  exportReport(): void {
    const timestamp = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    const exportOptions: CsvExportParams = { suppressQuotes: false, allColumns: this.allColumns, columnKeys:this.columnKeys, columnSeparator: this.seperator, customHeader: `SEP=${this.seperator}`, fileName: `${this.reportName}_${timestamp}.csv` };

    this.gridApi.exportDataAsCsv(exportOptions);
  }

}
