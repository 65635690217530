import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'removeWhitespace'
})
export class RemoveStringWhitespacePipe implements PipeTransform {
  transform(text: string): string {
    if (text && text.length) {
      return text.replace(/\s/g, '');
    } else {
      return null;
    }
  }
}