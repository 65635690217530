import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';

interface ReportLookupResult {
  reportName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReportLookupService {
  private reportEndpoint = environment.trackApiUrl + '/report/info?reportId=';
  private get httpHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authService.IdToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    });
  }
  private cache = {};

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {  }

  public async getTitle(guid: string): Promise<string> {
    if (this.cache[guid]) {
      return this.cache[guid];
    } else {
      const result = await this.httpClient
        .get<ReportLookupResult>(this.reportEndpoint + guid, {
          responseType: 'json',
          headers: this.httpHeaders,
        })
        .toPromise();
      if (result.reportName) {
        this.cache[guid] = result.reportName;
        return result.reportName;
      } else {
        return undefined;
      }
    }
  }
}
