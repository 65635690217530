import { IPatientSubtab } from '@shared/models/patient-subtab';

export const PatientSubtabsPrimaryWithPatientEngagement: IPatientSubtab[] = [
  {
    label: 'Plan',
    queryParam: {
      primarySubtab: 'plan',
    },
    forAllPatients: false
  },
  {
    label: 'Facesheet',
    queryParam: {
      primarySubtab: 'care-gaps',
    },
    forAllPatients: true
  },
  {
    label: 'SMS Programs',
    queryParam: {
      primarySubtab: 'sms-programs',
    },
    forAllPatients: false
  },
  {
    label: 'Timeline',
    queryParam: {
      primarySubtab: 'timeline',
    },
    forAllPatients: true
  },
  {
    label: 'Surveys',
    queryParam: {
      primarySubtab: 'surveys',
    },
    forAllPatients: false
  },
  {
    label: 'Reminders',
    queryParam: {
      primarySubtab: 'reminders',
    },
    forAllPatients: false
  },
  {
    label: 'Worklist Details',
    queryParam: {
      primarySubtab: 'worklist-details',
    },
    forAllPatients: true
  }
]
export const PatientSubtabsPrimaryCarePatientOnly: IPatientSubtab[] = [
  {
    label: 'Plan',
    queryParam: {
      primarySubtab: 'plan',
    },
    forAllPatients: false
  },
  {
    label: 'SMS Programs',
    queryParam: {
      primarySubtab: 'sms-programs',
    },
    forAllPatients: false
  },
  {
    label: 'Surveys',
    queryParam: {
      primarySubtab: 'surveys',
    },
    forAllPatients: false
  },
  {
    label: 'Reminders',
    queryParam: {
      primarySubtab: 'reminders',
    },
    forAllPatients: false
  },
  {
    label: 'Worklist Details',
    queryParam: {
      primarySubtab: 'worklist-details',
    },
    forAllPatients: true
  }
]
export const PatientSubtabsPrimaryWithoutPatientEngagement: IPatientSubtab[] = [
  {
    label: 'Facesheet',
    queryParam: {
      primarySubtab: 'care-gaps',
    },
    forAllPatients: true
  },
  {
    label: 'Timeline',
    queryParam: {
      primarySubtab: 'timeline',
    },
    forAllPatients: true
  },
  {
    label: 'Worklist Details',
    queryParam: {
      primarySubtab: 'worklist-details',
    },
    forAllPatients: true
  }
]

export const PatientSubtabsSecondary: IPatientSubtab[] = [
  {
    label: 'Alerts',
    queryParam: {
      secondarySubtab: 'alerts',
    },
    icon: 'alert',
    forAllPatients: false
  },
  {
    label: 'Activity',
    queryParam: {
      secondarySubtab: 'activity',
    },
    icon: 'history',
    forAllPatients: false
  },
]
export const defaultInputLength = {
  comment: 200,
  message: 2000,
} 
export const timeTrackingInputLength = {
  comment: 5000,
  message: 2000,
} 
export const alertInputLength = {
  comment: 5000,
  message: 2000,
} 




