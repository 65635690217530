import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { AzureApiBase } from '../azure-api/azure-api-base';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserTierService extends AzureApiBase<void> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/user');
  }

  updateUserTierInfo(): Observable<void> {
    return this.get('/updateTierInfo');
  }
}
