import { Directive, HostListener, OnInit } from '@angular/core';
import { FilterToggleService } from '@shared/services/filter-toggle/filter-toggle.service';
import { SideBarToggleService } from '@shared/services/sidebar-toggle/sidebar-toggle.service'
import { Subscription } from 'rxjs';

@Directive({
  selector: '[coachAgGridResize]'
})
export class AgGridResizeDirective implements OnInit {
  private gridApi: any;
  private filterToggleSubscription: Subscription;
  private sideBarToggleSubscription: Subscription;

  constructor(public filterToggleService: FilterToggleService, public sidebarToggleService: SideBarToggleService) { }

  ngOnInit() {
    this.filterToggleSubscription = this.filterToggleService.filterToggle.subscribe(() => {
      window.setTimeout(() => this.gridApi ? this.gridApi.sizeColumnsToFit() : '', 300);
    });

    this.sideBarToggleSubscription = this.sidebarToggleService.sidebarToggle.subscribe(() => {
      window.setTimeout(() => this.gridApi ? this.gridApi.sizeColumnsToFit() : '', 300);
    })
  }

  ngOnDestroy() {
    this.filterToggleSubscription.unsubscribe();
    this.sideBarToggleSubscription.unsubscribe();
  }

  @HostListener('window:resize')
  onResize() {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    });
  }

  @HostListener('gridReady', ['$event'])
  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }
}
