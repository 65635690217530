import { Component, Input, OnInit } from '@angular/core';

@Component({
  template: '<youtube-player [videoId]="videoId" height="100%" width="100%"></youtube-player>',
  selector: 'app-video'
})
export class VideoComponent implements OnInit {

  @Input() videoId: string;

  ngOnInit() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }
}
