import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from '@shared/models/constants/app-constants';
import { ImproveFeatureConstants } from '@shared/models/module-constants';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service'; 
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'coach-improve-summary',
  templateUrl: './improve-summary.component.html',
  host: {
    class: 'coach-improve',
  },
})
export class ImproveSummaryComponent {
  RoadmapsFolderSubscription: Subscription;
  RoadmapsFolder;
  ContactsFeature = ImproveFeatureConstants.ContactsFeature;
  acoBoardFeature = ImproveFeatureConstants.ACOBoardFeature;
  steeringCommiteeFeature = ImproveFeatureConstants.SteeringCommiteeFeature;
  practiceImprovementFeature =
    ImproveFeatureConstants.PracticeImprovementFeature;
  informationTechnologyFeature =
    ImproveFeatureConstants.InformationTechnologyFeature;
  eventsFeature = ImproveFeatureConstants.EventsFeature;
  app = AppConstants;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private formsService: SubmitFormsService) {
    this.RoadmapsFolderSubscription = this.formsService.getFolderGeneric('ROADMAPS', 'Practice Improvement', 'Improve').subscribe(
      (data) => (this.RoadmapsFolder = data));  
  }

  public goToItems(e, path: string, query?: object | null) {
    e.preventDefault();
    this.router.navigate(['..' + path], {
      queryParams: query,
      relativeTo: this.activatedRoute.parent,
    });
  }
}
