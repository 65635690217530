import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { CareFeatureConstants, PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HccListPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.AnnualWellnessVisits: PresetCategory.Cohorts;
    if (this.isHCCListRevampEnabled) {
      const item: IPatientFilterPreset = {
        category: categoryName,
        name: CarePatientItem.HccTop50,
        presetKey: CarePatientItem.HccTop50,
        label: 'TOP 50',
        filters: [],
        componentId: this.isHCCVIPCohortListEnabled ? CareComponentConstants.PatientList : null,
        hidePreset: true,
        dontStoreName: true,
        columns: this.getPresetColumns(),
        hiddenColumnsByDefault: ['lastCsthDate']
      };

      item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
      return item;
    }

    const item: IPatientFilterPreset = {
      category: PresetCategory.Cohorts,
      name: CarePatientItem.HccVipCohort,
      presetKey: CarePatientItem.HccVipCohort,
      label: 'Chronic Conditions VIP Cohort',
      filters: [],
      componentId: this.isHCCVIPCohortListEnabled ? CareComponentConstants.PatientList : null,
      hidePreset: true,
      dontStoreName: true,
      columns: this.getPresetColumns(),
      apiParams: {
        track: {
          TopPercent: '20',
          OrderBy: 'openRAFWt',
          IncludeDeceased: 'false'
        }
      }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean  | string}[] {
    if (this.isHCCListRevampEnabled) {
      return [
        { select: true },
        { fullName: true },
        { mbi: true },
        { gender: true },
        { dob_age: true },
        { phoneNumber: true },
        { assignment: true },
        { attributedPractice: true },
        { attributedCP: true },
        { predictedRiskLevel: environment.displayRiskLevel },
        {
          hccGapCount: this.agGridCustomFilteringFlag
        },
        { openRAFWt: true, sort: 'desc' },
        { raF_YTD: true },
        { lastAWVDate: true },
        { lastAWVProvider: true },
        { ihAwvPatient: true },
        { lastVisit: true },
        { lastCcmDate: true },
        { ccmLastProcCode: true},
        {
          diseaseCohorts: this.agGridCustomFilteringFlag
        },
        { totalCost: true },
        { edVisits: true },
        { officeVisits: true },
        { inpatientVisits: true },
        { enrollmentType: true },
        { lastCsthDate: true }
      ];
    }

    return [
      { select: true },
      { fullName: true },
      { dob_age: true },
      { gender: true },
      { mbi: true },
      {
        lastActivityAt: this.hasPatientApp
      },
      { phoneNumber: true },
      {
        professionalName: this.hasPatientApp
      },
      { assignment: true },
      { attributedCP: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      {
        actions: this.hasPatientApp
      },
      {
        ccmEligible: this.agGridCustomFilteringFlag
      },
      {
        diseaseCohorts: this.agGridCustomFilteringFlag
      },
      { totalCost: true },
      { raF_YTD: true },
      { awvTypeDue: true },
      { lastAWVDate: true },
      {
        hccGapCount: this.agGridCustomFilteringFlag
      },
      { edVisits: true },
      { attributedPractice: true },
      { openRAFWt: true, sort: 'desc' },
      { lastVisit: true }
    ];
  }
}
