/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PerformanceDashboardCards } from '../models/performance-dashboard-cards';
import { PerformanceDashboardDetail } from '../models/performance-dashboard-detail';
import { PerformanceDashboardSummaryItem } from '../models/performance-dashboard-summary-item';

@Injectable({
  providedIn: 'root',
})
export class PerformanceDashboardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation performanceDashboardGet
   */
  static readonly PerformanceDashboardGetPath = '/PerformanceDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performanceDashboardGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  performanceDashboardGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PerformanceDashboardCards>> {

    const rb = new RequestBuilder(this.rootUrl, PerformanceDashboardService.PerformanceDashboardGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PerformanceDashboardCards>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `performanceDashboardGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  performanceDashboardGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<PerformanceDashboardCards> {

    return this.performanceDashboardGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<PerformanceDashboardCards>) => r.body as PerformanceDashboardCards)
    );
  }

  /**
   * Path part for operation performanceDashboardSummaryGet
   */
  static readonly PerformanceDashboardSummaryGetPath = '/PerformanceDashboard/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performanceDashboardSummaryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  performanceDashboardSummaryGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PerformanceDashboardSummaryItem>>> {

    const rb = new RequestBuilder(this.rootUrl, PerformanceDashboardService.PerformanceDashboardSummaryGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PerformanceDashboardSummaryItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `performanceDashboardSummaryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  performanceDashboardSummaryGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<PerformanceDashboardSummaryItem>> {

    return this.performanceDashboardSummaryGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PerformanceDashboardSummaryItem>>) => r.body as Array<PerformanceDashboardSummaryItem>)
    );
  }

  /**
   * Path part for operation performanceDashboardDetailsGet
   */
  static readonly PerformanceDashboardDetailsGetPath = '/PerformanceDashboard/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performanceDashboardDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  performanceDashboardDetailsGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
    metricId?: number;
    timeFrameId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PerformanceDashboardDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, PerformanceDashboardService.PerformanceDashboardDetailsGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
      rb.query('metricId', params.metricId, {});
      rb.query('timeFrameId', params.timeFrameId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PerformanceDashboardDetail>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `performanceDashboardDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  performanceDashboardDetailsGet(params?: {
    tierNum?: number;
    tierId?: string;
    metricId?: number;
    timeFrameId?: number;
  },
  context?: HttpContext

): Observable<Array<PerformanceDashboardDetail>> {

    return this.performanceDashboardDetailsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PerformanceDashboardDetail>>) => r.body as Array<PerformanceDashboardDetail>)
    );
  }

}
