<ng-container *ngIf="isScreenSmall">
  <coach-patient-alert [patient]="patient" *hasComponent="PatientEngagementComponentIds"></coach-patient-alert>
</ng-container>
<div class="row">
  <div *ngIf="!isScreenSmall" class="patientChart-main column u-fill">
    <coach-patient-alert class="u-height--full" [patient]="patient" *hasComponent="PatientEngagementComponentIds"></coach-patient-alert>
  </div>
  <ng-container *ngIf="!isScreenSmall && (isPatientInvited || isCarePatient)">
    <div class="alertsAndMessagesControlButtons u-width--large u-preserve border-left" *hasComponent="PatientEngagementComponentIds">
      <ng-container *ngFor="let subtab of subtabsSecondary">
        <div *hasComponent="subtab.label === 'Messages' ? PatientEngagement.PatientApp : PatientEngagementComponentIds">
          <div class="button is-borderless" *ngIf="subtab.label != 'Info' && isCarePatient"
          [routerLink]="[currentUrl]"
          [queryParams]="{tab: activeSubtabPrimary, sidebar: subtab.label}"
          [ngClass]="{'is-primary': subtab.label == activeSubtabSecondary}" >
              <div class="icon-with-numericBadge">
                <coach-icon iconName="{{subtab.icon}}" iconColor="currentColor" class="transition-fix margin-right--smaller"></coach-icon>
                <div class="badge badge-for-icon" *ngIf="subtab.label == 'Alerts' && patient && patient.alerts && patient.alerts.unhandled.length > 0">{{patient.alerts.unhandled.length}}</div>
              </div>
              <div>{{subtab.label}}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
    <ng-container *ngIf="isScreenSmall">
      <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isPatientNavDropdownOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      (backdropClick)="isPatientNavDropdownOpen = false"
    >
      <div class="dropdown u-width--medium">
        <div class="list">
          <span *ngFor="let subtab of subtabsPrimary">
            <div class="list-item" [routerLink]="[currentUrl]" [queryParams]="{tab: subtab.label, sidebar: null}"
              (click)="isPatientNavDropdownOpen = false" [ngClass]="{'is-active': activeSubtabPrimary === subtab.label}"
              *hasComponent="getSubtabComponentId(subtab.label)">
              <div class="row">
                <div class="field">{{subtab.label}}</div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </ng-template>
    <div class="banner--secondary tabs tabs--secondary u-fill">
      <div class="tab u-fill padding-left--none patient--condensed-primaryTab"
      [routerLink]="[currentUrl]"
      [queryParams]="{tab: activeSubtabPrimary, sidebar: null}"
      [ngClass]="{'is-active': isSelectOptionSelected(activeSubtabPrimary)}"
      type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <button class="button is-small is-unstyled margin--smallest row--verticalCenter" (click)="isPatientNavDropdownOpen = !isPatientNavDropdownOpen">
          <span class="margin-right u-bold">{{activeSubtabPrimary}}</span>
          <coach-icon iconName="chevron-down" iconColor="currentColor"></coach-icon>
        </button>
      </div>
      <ng-container *hasComponent="PatientEngagementComponentIds">
        <ng-container *ngIf="isPatientInvited">
          <div class="tab icon" *ngFor="let subtab of (patient.carePatient ? subtabsSecondary : [])"
            [routerLink]="[currentUrl]"
            [queryParams]="{tab: null, sidebar: subtab.label}"
            [ngClass]="{'is-active': activeSubtabPrimary === subtab.label, 'u-display--none': subtab.label == 'Info' || isCarePatient}">
            <ng-container *hasComponent="subtab.label === 'Messages' ? PatientEngagement.PatientApp : PatientEngagementComponentIds">
              <coach-icon iconName="{{subtab.icon}}" iconColor="currentColor"></coach-icon>
              <div class="badge badge-for-icon-mobile"
                *ngIf="subtab.label == 'Alerts' && patient && patient.alerts && patient.alerts.unhandled.length > 0">
                {{patient.alerts.unhandled.length}}</div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

</div>
<div class="chart-bodies row dynamicBody">
  <div class="patientChart-main column u-fill">
    <div class="banner--secondary padding-left--large u-overflow-x--auto padding-right--none" *ngIf="patient && !isScreenSmall">
      <div class="tabs" *ngIf='patient'>
        <span *ngFor="let subtab of subtabsPrimary">
          <div class="tab"
          [routerLink]="[currentUrl]"
          [queryParams]="{tab: subtab.label, sidebar: activeSubtabSecondary}"
          [ngClass]="{'is-active': activeSubtabSecondary === subtab.label || activeSubtabPrimary === subtab.label}"
          *hasComponent="getSubtabComponentId(subtab.label)"
          >{{subtab.label}}</div>
        </span>
      </div>
    </div>
    <div class="chart-main dynamicBody u-background-color--white" *ngIf="patient">
      <ng-container *ngIf="activeSubtabPrimary === 'SMS Programs'">
        <coach-patient-summary *hasComponent="PatientEngagementComponentIds"  [patient]="patient" [patientCompletions]="patient.completions" [patientTasks]="patient.tasks" [tier]="tier"></coach-patient-summary>
      </ng-container>
      <ng-container *ngIf="activeSubtabPrimary === 'Plan'">
        <ng-container *hasComponent="PatientEngagementComponentIds">
          <coach-care-patient-careplan *doesNotHaveFeatureFlag="'EditableProgramsFeatureFlag'" [patient]="patient" [tier]="tier"></coach-care-patient-careplan>
          <coach-care-patient-careplan-editable *hasFeatureFlag="'EditableProgramsFeatureFlag'" [patient]="patient" [tier]="tier"></coach-care-patient-careplan-editable>
        </ng-container>
      </ng-container>

      <patient-care-gaps [mobileScreenSize]="false" *ngIf="activeSubtabPrimary === 'Facesheet'" [patient]="patient" [tier]="tier"></patient-care-gaps>
      <coach-chart-timeline *ngIf="activeSubtabPrimary === 'Timeline'" [patient]="patient" [tier]="tier"></coach-chart-timeline>
      <ng-container *ngIf="activeSubtabPrimary === 'Surveys'">
        <coach-chart-surveys *hasComponent="PatientEngagement.PatientApp" [patient]="patient"></coach-chart-surveys>
      </ng-container>
      <coach-patient-reminders *ngIf="activeSubtabPrimary === 'Reminders'" [patient]="patient" [tier]="tier"></coach-patient-reminders>
      <coach-patient-worklists *ngIf="activeSubtabPrimary === 'Worklist Details'" [patient]="patient" [tier]="tier"></coach-patient-worklists>
      <coach-patient-activity *ngIf="activeSubtabSecondary === 'Activity' && isScreenSmall" [patient]="patient" [tier]="tier"></coach-patient-activity>
      <patient-alerts *ngIf="activeSubtabSecondary === 'Alerts' && isScreenSmall" [patient]="patient" [tier]="tier"></patient-alerts>
  
    </div>
  </div>
  <ng-container *ngIf="!isScreenSmall">
    <ng-container *hasComponent="PatientEngagementComponentIds" >
      <div class="alertsAndMessages-main column u-width--large u-preserve border-left"  [ngClass]="{'is-closed': isAlertsAndMessagesClosed}" *ngIf="isPatientInvited">
  
        <div class="header u-align-items--center padding-topBottom--small"  >
          <div class="titles">
            <div class="row u-justify-content--spaceBetween u-align-items--center">
              <div class="title field">{{activeSubtabSecondary}}</div>
            </div>
        </div>
          <button class="button is-small is-icon border--none" (click)="toggleAlertsAndMessages()">
            <coach-icon iconName="x"></coach-icon>
          </button>
        </div>
        <div class="dynamicBody column" *ngIf="isPatientInvited">
          <coach-patient-activity *ngIf="activeSubtabSecondary === 'Activity'"[patient]="patient" [tier]="tier"></coach-patient-activity>
          <patient-alerts *ngIf="activeSubtabSecondary === 'Alerts'" [patient]="patient" [tier]="tier"></patient-alerts>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- MODAL -->
<ng-container *ngIf="modalStatus.isOpen">
  <div class="overlay is-open">
    <div class="modal patientInviteModal u-width--larger">
      <coach-patient-invite-form *ngIf="modalStatus.target == 'modalPatientInviteForm' && !isSMSInviteFlagEnabled" (closeForm)="closeModal()" [patient]="patient" [tier]="tier"></coach-patient-invite-form>
      <coach-single-patient-invite-form *ngIf="modalStatus.target == 'modalPatientInviteForm' && isSMSInviteFlagEnabled" (closeForm)="closeModal()" [patient]="patient" [tier]="tier"></coach-single-patient-invite-form>
      <coach-patient-edit-form *ngIf="modalStatus.target == 'modalPatientEditForm'" (closeForm)="closeModal()" [patient]="patient" [tier]="tier"></coach-patient-edit-form>
    </div>
  </div>
</ng-container>
