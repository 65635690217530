<div class="overlay is-open" *ngIf="showModal">
    <div class="modal u-width--largest">
        <div class="header">
            <div class="titles">
                <div class="title u-display--flex u-justify-content--spaceBetween">
                    <span>{{currentTier?.name}} Selected</span>
                </div>
            </div>
        </div>
        <div class="dynamicBody padding">
            {{pageName}} is not available at the {{currentTier?.name}} level. To continue, select a {{tierName}} in the hierarchy
            selector.
        </div>
        <div class="footer"></div>
    </div>
</div>