import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OrgService } from '@shared/services/org-service/org.service';
import { wpapi, wpdb } from "@hcd-caravanhealth/pkg-wptypes";
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';

import { PatientService, ITrackApiPatient } from '@shared/services/patient/patient.service';
import moment from 'moment';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';

import { Patient } from '@shared/models/patient';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';

@Component({
  selector: 'coach-track-time-tracking',
  templateUrl: './track-time-tracking.component.html',
  host: {
    class: 'track-time-tracking'
  }
})
export class TrackTimeTrackingComponent implements OnInit {
  public tier: IHierarchyTier;
  public orgId: string;
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get tierNum() {
    return this?.tier?.tier;
  }
  timeTrackingEvents: {
    summary: { events: (wpapi.model.TimeTrackingEvent & any)[] };
    detailed: {
      count: number;
      events: (wpapi.model.TimeTrackingEvent & any)[];
      patients: wpapi.model.Patient[];
      professionals: wpapi.model.Professional[];
    };
  } = {
    summary: null,
    detailed: null
  };
  selectedList: {
    detailed: boolean;
  } = { detailed: false };
  selectedMonth: { display: string; value: number } = { display: null, value: null };
  selectedYear: { display: string; value: number } = { display: null, value: null };
  endDate: string;
  gridApi: GridApi;
  columnApi: ColumnApi;
  trackPatientsById: {
    [key: string]: ITrackApiPatient;
  } = {};
  readonly defaultColDef: ColDef = {
    resizable: true
  };
  readonly detailedDefs = [
    {
      headerName: 'Full name',
      field: 'patientName',
      minWidth: 75,
      sortable: true
    },
    {
      headerName: 'DOB',
      field: 'patientDob',
      sortable: true
    },
    {
      headerName: 'MBI',
      field: 'patientMbi',
      sortable: true
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      minWidth: 75,
      sortable: true
    },
    {
      headerName: 'Duration (mins)',
      field: 'durationMinutes',
      sortable: true
    },
    {
      headerName: 'Activity',
      field: 'activity',
      minWidth: 75,
      sortable: true
    },
    {
      headerName: 'Notes',
      field: 'notes',
      minWidth: 75,
      sortable: true
    },
    {
      headerName: 'Entered By',
      field: 'providerName',
      minWidth: 75,
      sortable: true
    }
  ];
  readonly summaryDefs = [
    {
      headerName: 'Full name',
      field: 'patientName',
      minWidth: 75,
      sortable: true
    },
    {
      headerName: 'DOB',
      field: 'patientDob',
      minWidth: 75,
      sortable: true
    },
    {
      headerName: 'MBI',
      field: 'patientMbi',
      minWidth: 75,
      sortable: true
    },
    {
      headerName: 'Total minutes',
      field: 'totalMinutes',
      minWidth: 75,
      sortable: true
    }
  ];
  mask = new MaskPipe();
  constructor(
    private _patientService: PatientService,
    private HierarchyTierService: HierarchyTierService,
    private OrgService: OrgService
  ) {
    HierarchyTierService.currentTier$.pipe().subscribe(async tier => {
      if (this.tier?.selectedTierId !== tier?.selectedTierId) {
        this.tier = tier;
        if (this.tier?.selectedTierId) {
          this.orgId = await this._patientService.getWpOrgIdFromTierId(this.tier.selectedTierId);
          if (this.selectedMonth.display && this.selectedYear.display) {
            let selectedDate = moment().set('month', this.selectedMonth.value).set('year', this.selectedYear.value);
            this.getTimeTrackingEventsForOrg(
              this.orgId,
              selectedDate.startOf('month').toString(),
              selectedDate.endOf('month').toString(),
              true,
              true
            );
          }
        } else {
          this.orgId = null;
        }
      }
    });
  }
  ngOnInit(): void {}

  // ================ LISTENERS ================
  onGridReady(param: any): void {
    // aggrid on init
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
  }
  public onFirstDataRendered(params: any): void {
    params.api.sizeColumnsToFit();
  }
  public onRowClicked(event: any): void {}
  public selectedYearChange(date): void {
    this.selectedYear = date;
    if (this.selectedMonth.display) {
      let selectedDate = moment().set('month', this.selectedMonth.value).set('year', this.selectedYear.value);
      if (this.orgId) {
        this.getTimeTrackingEventsForOrg(
          this.orgId,
          selectedDate.startOf('month').toString(),
          selectedDate.endOf('month').toString(),
          true,
          true
        );
      }
    }
  }
  public selectedMonthChange(date): void {
    this.selectedMonth = date;
    if (this.selectedYear.display) {
      let selectedDate = moment().set('month', this.selectedMonth.value).set('year', this.selectedYear.value);
      if (this.orgId) {
        this.getTimeTrackingEventsForOrg(
          this.orgId,
          selectedDate.startOf('month').toString(),
          selectedDate.endOf('month').toString(),
          true,
          true
        );
      }
    }
  }

  public exportClicked(action): void {
    action == 'csv' ? this.exportCSV() : this.copyToClipboard();
  }
  async exportCSV() {
    this.gridApi.exportDataAsCsv({
      suppressQuotes: false,
      columnSeparator: ',',
      allColumns: true,
      fileName: `timeTrackingEvents_${this.selectedList?.detailed ? 'detailed' : 'summary'}_${
        this.selectedMonth.display
      }/${this.selectedYear.display}.csv`
    });
  }
  public copyToClipboard(): void {
    let copy = this.gridApi.getDataAsCsv({
      suppressQuotes: false,
      columnSeparator: ',',
      allColumns: true,
      fileName: `timeTrackingEvents_${this.selectedList?.detailed ? 'detailed' : 'summary'}_${
        this.selectedMonth.display
      }/${this.selectedYear.display}.csv`
    });
    const element = document.createElement('textarea');
    element.value = copy;
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
  }
  // ================ API CALLS ================
  async getTimeTrackingEventsForOrg(
    orgId: string,
    startDate: string,
    endDate: string,
    patientDetails: boolean,
    professionalDetails: boolean
  ) {
    if (!orgId) {
      this.timeTrackingEvents = {
        summary: { events: [] },
        detailed: { count: null, events: [], patients: null, professionals: null }
      };
      return;
    }

    this.timeTrackingEvents.detailed = await this.OrgService.getTimeTrackingEventsForOrg(
      orgId,
      startDate,
      endDate,
      patientDetails,
      professionalDetails
    );

    // Patients
    let patientsObj = await this.patientsArrayToObjById(this.timeTrackingEvents.detailed.patients);
    // Profesionals
    let professionalsObj = {};

    this.timeTrackingEvents.detailed.professionals.forEach(professional => {
      professionalsObj[professional._id] = professional;
    });

    // Events
    let summaryObj = {};
    this.timeTrackingEvents.detailed.events.forEach(event => {
      const patient = patientsObj[event.patientId];
      const professional = professionalsObj[event.providerId];
      event.patientName = `${patient?.lastName} ${patient?.firstName}`.trim();
      event.patientMrn = this.mask.transform(patient?.mrn, 6);
      event.patientDob = patient?.dob;
      event.patientMbi = this.mask.transform(patient?.mbi, 6);
      if (professional.firstName || professional.lastName) {
        event.providerName = `${professional.firstName} ${professional.lastName}`.trim();
      } else if (professional.email) {
        event.providerName = professional.email;
      } else {
        event.providerName = `[${professional._id} is missing name]`;
      }
      event.durationMinutes = Math.floor(event.durationSeconds / 60);
      event.createdAt = moment(event.createdAt).format('MM/DD/YYYY, hh:mm:ss');
      if (!summaryObj[event.patientId]) {
        summaryObj[event.patientId] = {
          patientName: `${patient?.lastName} ${patient?.firstName}`,
          patientMrn: this.mask.transform(patient?.mrn, 6),
          patientMbi: this.mask.transform(patient?.mbi, 6),
          patientDob: patient?.dob,
          totalMinutes: Math.floor(event.durationSeconds / 60)
        };
      } else {
        summaryObj[event.patientId].totalMinutes += Math.floor(event.durationSeconds / 60);
      }
    });

    this.timeTrackingEvents.summary = { events: [] };

    for (let event in summaryObj) {
      this.timeTrackingEvents.summary.events.push(summaryObj[event]);
    }
  }
  async patientsArrayToObjById(patients): Promise<{ [aboutUserId: string]: Patient }> {
    // Promise<any>
    let result = {};
    if (Object.keys(this.trackPatientsById).length < 1) {
      let patients = await this._patientService.getTrackPatients(this.tierNum, this.tierId);
      patients?.results?.forEach(p => {
        this.trackPatientsById[p.chPatId] = p;
      });
    }
    patients.forEach(patient => {
      // CC-3570 don't filter by account type, some may be accountType == "patient" and there may be others in the future
      const chPatId = Patient.getChPatIdAndTierIdFromCarePatient(patient)?.chPatId;
      if (chPatId) {
        let trackPatientData = this.trackPatientsById[chPatId];
        if (trackPatientData) {
          patient = Object.assign(patient, trackPatientData || {});
        } else {
          console.warn(`Patient ${chPatId} has no track data`);
        }
      } else {
        console.warn(`Patient ${patient._id} has no chPatId`);
      }
      result[patient._id] = patient;
    });
    return result;
  }
}
