import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl, UntypedFormGroup, UntypedFormArray, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { CheckboxQuestion, CheckboxQuestionCheckboxOptions, ExclusionRequestDetails} from '@shared/models/forms/forms-frontend';
import { ToolTipMeasureDetails } from '@shared/models/forms/tool-tip-measure-details';

@Component({
  selector: 'coach-checkbox-question',
  templateUrl: './checkbox-question.component.html',
  styleUrls: ['./checkbox-question.component.scss'],
})
export class CheckboxQuestionComponent implements OnInit {
  @Input() public parentForm: UntypedFormGroup;
  @Input() public questionInfo: CheckboxQuestion;

  _maxPoints: number;
  @Input() set maxPoints(value: number) {
    this._maxPoints = value;


  }
  get maxPoints(): number {
    return this._maxPoints;
  }

  @Output() checkBoxChanged = new EventEmitter<string>();
  @Output()
  showHelpText = new EventEmitter<ToolTipMeasureDetails>();


  @Output() checkBoxExclusionChanged = new EventEmitter<ExclusionRequestDetails>();

  public get isDisabled(): boolean {
    return (
      this.parentForm.get(`${this.questionInfo.name}.isDisabled`)?.value ===
      true
    );
  }

  public get isExcluded(): boolean {
    return (
      this.parentForm.get(`${this.questionInfo.name}.exclusion`)?.value ===
      true
    );
  }

  public formCheck: UntypedFormGroup;
  public checkedList: CheckboxQuestionCheckboxOptions[] = [];
  private selectedselectedCheckListValues: string;
  public formCheckSub: Subscription;
  public answerForm: UntypedFormGroup;

  public showExclusionModal = false;
  private readonly exclusionTypeId = 3;
  private readonly responseTypeId = 4;


  private addCheckboxes() {
    if (this.questionInfo != undefined) {
        this.questionInfo.checkBoxOptions.forEach(() =>
          this.chklstFormArray.push(new UntypedFormControl(false))
        );
    }
}

  get chklstFormArray() {
    return this.formCheck.get('chkbxlst') as UntypedFormArray;
  }

  private hasInitialized = false;

  get checkedControl(): AbstractControl {
    return this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.UserResponse`
    );
  }

  get pointsControl(): AbstractControl {
    return this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.ResponsePointValue`
    );
  }

  constructor(
    fb: UntypedFormBuilder) {
      this.answerForm = fb.group({
        formAnswer: fb.control(''),
        formPoints: fb.control('')
      });
    this.formCheck = fb.group({
      chkbxlst: fb.array([]),
    });
    this.addCheckboxes();
  }

  ngOnInit(): void {
    const response: string = this.pointsControl?.value?.toString();
    const checkedControl = this.checkedControl;

    this.addCheckboxes();
    this.formCheckSub = this.formCheck.valueChanges.subscribe(() => {
      this.updateSelectedCheckList();
    });
    this.checkedList = this.questionInfo.checkBoxOptions;

    const value = parseInt(response);

    this.hasInitialized = true;

    this.checkBoxChanged.emit(this.checkedControl?.value);
//one or the other?
    this.checkBoxChanged.emit(this.selectedselectedCheckListValues);

///Pickup here
    const questionControl = this.parentForm.get(`${this.questionInfo.name}.FormQResponses.0.UserResponse`);

    if (questionControl.value != '' && questionControl.value != null) {
      const chkList = questionControl.value.split(',');

      this.chklstFormArray.clear();
      this.questionInfo.checkBoxOptions?.forEach((chk) => {
        const isSet = chkList.some((x) => x === chk.checkText);
        this.chklstFormArray.push(new UntypedFormControl(isSet));
      });

    }
  }

  toggleInfoText(): void {
    this.showHelpText.emit({
      detailsText: this.questionInfo.helpText,
      modalLinks: this.questionInfo.modalLinks,
    });
  }

  // updateValue(): void {
  //   if (this.checkedControl.value) {
  //     this.pointsControl.setValue(this.maxPoints);
  //   } else {
  //     this.pointsControl.setValue(0);
  //   }

  //   this.checkBoxChanged.emit(this.checkedControl.value);

  //   this.parentForm.updateValueAndValidity({
  //     onlySelf: false,
  //     emitEvent: true,
  //   });
  // }

  public updateSelectedCheckList() {
    if (this.checkedControl.value) {
      this.pointsControl.setValue(this.maxPoints);
    } else {
      this.pointsControl.setValue(0);
    }

    const selectedCheckList: CheckboxQuestionCheckboxOptions[] = this.formCheck
    .get('chkbxlst')
    .value.map((selected, i) => (selected ? this.checkedList[i] : null))
    .filter((p) => p !== null);

    this.selectedselectedCheckListValues = selectedCheckList
      .map((p) => p.checkText)
      .toString();

      if (this.selectedselectedCheckListValues) {
        this.pointsControl.setValue(this.maxPoints);
      } else {
        this.pointsControl.setValue(0);
      }

      this.checkedControl.setValue(this.selectedselectedCheckListValues);

    this.checkBoxChanged.emit(this.selectedselectedCheckListValues);

    this.parentForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });

    // // update names
    // const selectedselectedCheckListValues = selectedCheckList
    //   .map((p) => p.checkText)
    //   .toString();

      // this.answerUpdated.emit([
      //   this.sectionIndex,
      //   this.questionIndex,
      //   selectedselectedCheckListValues,
      //   this.question.questionId
      // ]);

  }

  exclusionChanged(): void {
    const question = this.parentForm.controls[this.questionInfo.name];
    const exclusionControl = question.get('exclusion');

    if (exclusionControl.value) {
      this.showExclusionModal = true;
    } else {
      this.setFormType(this.responseTypeId);
      this.updatePoints();
      this.enableDisableQuestions(question, 'enable');
      this.checkBoxExclusionChanged.emit({
        questionname: this.questionInfo.name,
        status: false,
      });
    }
  }

  confirmExclusion(): void {
    const question = this.parentForm.controls[this.questionInfo.name];

    const pointsControl = question.get('FormQResponses.0.ResponsePointValue');
    pointsControl.setValue(-1);
    this.setFormType(this.exclusionTypeId);
    this.showExclusionModal = false;
    this.enableDisableQuestions(question, 'disable');


    this.checkBoxExclusionChanged.emit({
      questionname: this.questionInfo.name,
      status: true,
    });
  }

  setFormType(type: number): void {
    const formTypeControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.FormResponseTypeID`
    );
    formTypeControl.setValue(type);
  }

  cancelExclusion(): void {
    const question = this.parentForm.controls[this.questionInfo.name];
    const exclusionControl = question.get('exclusion');
    exclusionControl.setValue(false);
    this.showExclusionModal = false;
  }

  enableDisableQuestions(
    question: AbstractControl,
    enableOrDisable: 'enable' | 'disable'
  ): void {
    if (enableOrDisable === 'enable') {
      question.get('FormQResponses').enable({ emitEvent: false });
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(null, { emitEvent: false });
      question
        .get('FormQResponses.0.UserResponse')
        .reset(null, { emitEvent: false });
    } else if (enableOrDisable === 'disable') {
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(0, { emitEvent: false });
      question
        .get('FormQResponses.0.UserResponse')
        .reset('', { emitEvent: false });
    }
  }

  updatePoints(): void {
    if (this.parentForm == null) {
      return;
    }

    const pointsControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.ResponsePointValue`
    );
    // const numerator = this.parentForm.get(
    //   `${this.questionInfo.name}.FormQResponses.1.ResponsePointValue`
    // ).value as number;
    // const denominator = this.parentForm.get(
    //   `${this.questionInfo.name}.FormQResponses.2.ResponsePointValue`
    // ).value as number;
    const exclusion = this.parentForm.get(
      `${this.questionInfo.name}.exclusion`
    ).value;

    if (exclusion) {
      pointsControl.setValue(0);
    } else {

      const pointValue = Math.round(this.maxPoints);
      const pointValueTo1 = Math.ceil(this.maxPoints);

      if (pointValue === 0 && pointValueTo1 === 1) {
        pointsControl.setValue(pointValueTo1);
      } else {
        pointsControl.setValue(pointValue);
      }

    }
  }
}
