import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Patient } from '@shared/models/patient';
import { PatientService } from '@shared/services/patient/patient.service';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { startOfDay, endOfDay } from 'date-fns';

import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'coach-chart-surveys',
  templateUrl: './chart-surveys.component.html',
  styleUrls: ['./chart-surveys.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSurveysComponent implements OnInit {
  @Input() public patient: Patient;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  selectedDay: string;
  showEmptyDayMessage: boolean = false;
  surveys: any[];
  showModalSurvey: boolean = false;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  clipboardText: string = '';
  viewDateHeader: string;
  public cssBottom = '0';

  constructor(
    private ref: ChangeDetectorRef,
    private patientService: PatientService,
    private toastr: ToastrService
  ) {}

  private get patientId() {
    if (!this.patient) return false;
    return this?.patient.carePatient._id;
  }

  private get limit() {
    return 3000;
  }

  private get skip() {
    return 0;
  }

  private get color() {
    return {
      primary: '#df442f',
      secondary: '#FAE3E3',
    };
  }

  private get where() {
    const momentViewDate = moment(this.viewDate);
    const startDate = momentViewDate.startOf('month').format('YYYY-MM-DD');
    const endDate = momentViewDate.endOf('month').format('YYYY-MM-DD');
    return escape(
      `{"$and":[{"reportingDate":{"$gte":"${startDate}"}},{"reportingDate":{"$lte":"${endDate}"}}]}`
    );
  }

  public closeSurvey() {
    this.showModalSurvey = false;
  }

  async dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }) {
    this.viewDate = date;
    this.selectedDay = moment(date).format('MMM DD, YYYY');
    this.surveys = [];
    this.showEmptyDayMessage = false;
    this.showModalSurvey = true;
    this.clipboardText = '';
    await Promise.all(
      events.map(async (event) => {
        let responseSurvey = await this.patientService.getSurvey(
          event.id.toString()
        );
        const responses = event.meta;
        const newSurvey = {
          name: responseSurvey.name,
          lines: [],
        };
        this.clipboardText += `${responseSurvey.name}\n`;
        let allItems = responseSurvey.pages
          .map((page) => {
            return page.items;
          })
          .reduce((pre, cur) => {
            return pre.concat(cur);
          });

        allItems.forEach((item) => {
          const answer = responses[item.qid];
          const line = {
            question: item.text,
            answer: answer,
          };
          if (answer) {
            newSurvey.lines.push(line);
            this.clipboardText += `${item.text} ${answer} \n`;
          }
        });

        this.surveys.push(newSurvey);        
      })
    );
    // CC-2030 fix. if popup has more than 20 lines extend overlay 
    const numberOfLines =
      this.surveys
        .map((x) => x.lines)
        .reduce((acc, element) => acc + element.length, 0) +
      this.surveys.length * 2;
    this.cssBottom = numberOfLines > 20 ? 'auto' : '0';


    if (this.surveys.length === 0) this.showEmptyDayMessage = true;
    this.ref.detectChanges();
  }

  async handleEvent(status, event) {
    this.viewDate = event.start;
    this.selectedDay = moment(event.start).format('MMM DD, YYYY');
    this.surveys = [];
    this.showModalSurvey = true;
    this.showEmptyDayMessage = false;

    // console.log('event', event);

    let responseSurvey = await this.patientService.getSurvey(
      event.id.toString()
    );
    const responses = event.meta;
    const newSurvey = {
      name: responseSurvey.name,
      lines: [],
    };
    let allItems = responseSurvey.pages
      .map((page) => {
        return page.items;
      })
      .reduce((pre, cur) => {
        return pre.concat(cur);
      });

    allItems.forEach((item) => {
      const answer = responses[item.qid];
      const line = {
        question: item.text,
        answer: answer,
      };
      if (answer) newSurvey.lines.push(line);
    });

    this.surveys.push(newSurvey);
    this.ref.detectChanges();
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.clipboardText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('The text has been copied to your clipboard');
  }

  ordinal(number) {
    switch (number) {
      case 1:
      case 21:
        return number + 'st';
        break;
      case 2:
      case 22:
        return number + 'nd';
        break;
      case 3:
      case 23:
        return number + 'rd';
        break;
      default:
        return number + 'th';
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  previous() {
    if (this.patientId) {
      this.getData();
    }
  }

  next() {
    if (this.patientId) {
      this.getData();
    }
  }

  ngOnChanges() {
    if (this.patientId) {
      this.getData();
    }
  }

  ngOnInit(): void {}

  // Main method for filling the events for each day in the calendar
  async getData() {
    this.viewDateHeader = moment(this.viewDate).format('MMMM YYYY');
    this.events = [];
    let reportsData = await this.patientService.getSurveyResponses(
      this.patientId,
      this.limit,
      this.skip,
      this.where
    );
    // console.log(reportsData);

    for (let item in reportsData) {
      this.events.push({
        id: reportsData[item].surveyTypeId,
        title: reportsData[item].surveyType,
        start: startOfDay(new Date(reportsData[item].createdAt)),
        end: endOfDay(new Date(reportsData[item].createdAt)),
        color: this.color,
        meta: reportsData[item].responses,
      });
    }
    this.refresh.next();
  }
}
