import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BooleanValueNode } from 'graphql';

@Component({
  selector: 'coach-patient-header-help',
  templateUrl: './patient-header-help.component.html',
  styleUrls: ['./patient-header-help.component.scss'],
})
export class PatientHeaderHelpComponent implements OnInit, AfterViewInit {
  private static IS_HIDDEN: string = 'HELP_PANEL_IS_HIDDEN';

  isHidden: boolean;
  @ViewChild('mainDiv') mainDiv: ElementRef;
  @ViewChild('expanderArrow') expanderArrow: ElementRef;
  @Input() fillDate: Date;
  @Input() showInstructions: boolean = true;

  activeColor: string = '#E8D2F2';
  inactiveColor: string = '#e0e0e0';

  activeArrow: string = 'chevron-up';
  inactiveArrow: string = 'chevron-down';
  currentArrow: string;

  activeBottomPadding: string = '24px';
  inactiveBottomPadding: string = '0';

  storage: any;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.storage = window.localStorage;
    this.isHidden =
      this.storage.getItem(PatientHeaderHelpComponent.IS_HIDDEN) === 'true' || !this.showInstructions;
    this.flipArrow();
  }

  ngAfterViewInit(): void {
    this.setColor();
  }

  toggleDisplay() {
    this.isHidden = !this.isHidden;
    this.setColor();
    this.flipArrow();
    this.storage.setItem(
      PatientHeaderHelpComponent.IS_HIDDEN,
      this.isHidden ? 'true' : 'false'
    );
  }

  setColor() {
    const newColor = this.isHidden ? this.inactiveColor : this.activeColor;
    this.renderer.setStyle(
      this.mainDiv.nativeElement,
      'background-color',
      newColor
    );
    const paddingAmount = this.isHidden ? this.inactiveBottomPadding : this.activeBottomPadding;
    this.renderer.setStyle(this.mainDiv.nativeElement, 'padding-bottom', paddingAmount);
  }

  flipArrow() {
    this.currentArrow = this.isHidden ? this.inactiveArrow : this.activeArrow;
  }
}
