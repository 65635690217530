<ng-container *ngIf="showModal">
  <div class="overlay is-open">
    <div class="modal u-width--larger">
      <div class="header">
        <div class="titles">
          <div class="title" style="margin-bottom: 1.25rem; text-align: center">
            Edit Note
          </div>
          <coach-icon
            iconName="x"
            style="margin-top: -3.5rem; margin-left: auto; cursor: pointer"
            (click)="cancel()"
          ></coach-icon>
          <div class="subtitle" style="text-align: center">
            <textarea
              [(ngModel)]="claimNote"
              placeholder="Enter a note..."
              style="height: 200px; border-top: 2px solid lightgray; margin-top: 16px;"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="footer" style="background-color: #eee; border-top: 2px solid lightgray; padding-right: 32px;">
        <!-- <coach-icon iconName="x-circle" style="margin-right: auto; margin-top: 1.35rem; cursor: pointer;" (click)="deleteNote()"></coach-icon> -->
        <img src="assets/icons/Trash.svg" width="24px" height="24px" alt="trash-icon" style="margin-right: auto; cursor: pointer;" (click)="deleteNote()" />
        <button
          class="button margin-right border--none"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button class="button is-primary" (click)="saveNote()">Save</button>
      </div>
    </div>
  </div>
</ng-container>
