import { Component, OnInit, LOCALE_ID, Inject, OnDestroy } from '@angular/core';
import { HccPatientSummaryService } from '../hcc-patient-summary.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { formatDate, formatNumber } from '@angular/common';
import { HccDashboardPatient } from '../hcc-dashboard-patient';


import { GridFilterService } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { PATIENT_SUMMARY_GRID_KEY, PATIENT_DETAIL_REPORT_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { environment } from 'src/environments/environment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { Subject, combineLatest } from 'rxjs';
import { filter, share, switchMap, takeUntil } from 'rxjs/operators';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { HccDashboardFilterService } from '@track/track-clinical/hcc-dashboard/hcc-dashboard-filter.service';
import { HccDashboardService } from '@api/track/services';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';

@Component({
  selector: 'coach-patient-summary-report2',
  templateUrl: './patient-summary-report2.component.html',
  host: {
    class: 'coach-patient-summary-report2'
  }
})
export class PatientSummaryReport2Component extends PersistentGridFilters implements OnInit, OnDestroy {
  columnDefs = [
    {
      headerName: 'MBI', field: 'current_MBI', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Patient\'s Medicare Beneficiary Identifier',
      valueFormatter: params => {
        const patient: HccDashboardPatient = params.data;

        if (patient == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(patient.current_MBI, 6);
        }
        else {
          return patient.current_MBI;
        }
      }, keyCreator: params => {
        if (environment.maskSensitiveData) {
          return this.mask.transform(params.value, 6);
        }
        else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Name', field: 'fullName', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Patient Name',
      cellRendererFramework: RouterLinkRendererComponent,
      cellRendererParams: {
        linkClicked: (data: HccDashboardPatient) => {
          const path = '../../../care/patient';

          this.router.navigate([path, data.chPatID], { relativeTo: this.route.parent });
        }
      }
    },
    {
      headerName: 'Date of Birth', field: 'dob', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Patient Date of Birth',
      valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale)
    },
    {
      headerName: 'Attributed Provider', field: 'providerName', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the name of the clinician that the beneficiary is attributed/assignable to. For RHC/FQHCs, the name of the practice will be displayed in this column.',
    },
    {
      headerName: 'Clinician NPI', field: 'tierID', sortable: true, filter: !environment.maskSensitiveData, resizable: true,
      headerTooltip: 'Clinician\'s National Provider Identifier',
      valueFormatter: params => {
        const patient: HccDashboardPatient = params.data;
        let value = '';

        if (patient == null) {
          value = params.value;
        } else if (patient.hasProvider) {
          value = patient.tierID;
        }

        if (environment.maskSensitiveData) {
          value = this.mask.transform(value, 6);
        }
        return value;
      }
    },
    {
      headerName: 'Clinician Specialty', field: 'provSplty', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Clinician\'s specialty designation from claims',
    },
    {
      headerName: 'Chronic Conditions Weight Closed YTD', field: 'raF_YTD', sortable: true, filter: true, resizable: true, width: 300,
      headerTooltip: 'Displays beneficiary\'s closed chronic conditions for the current calendar year.',
    },
    {
      headerName: 'Total Gap Weight', field: 'openRAFWt', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the highest possible weight that could be captured if all open chronic conditions are documented for appropriately.'
    },
    {
      hide: true, // Hidden with ZBR-12923
      headerName: 'Chronic Conditions Closure Gaps', field: 'gapCount', sortable: true, filter: true, resizable: true, headerTooltip: 'Count of open chronic condition gaps.'
    },
    {
      headerName: 'Annual Closure Rate', field: 'overallHCCClosureRate', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Percentage of chronic condition gaps closed by claims in the current calendar year.',
      valueFormatter: (params: any) => {
        const patient: HccDashboardPatient = params.data;
        return patient == null ? null : Math.round(patient.overallHCCClosureRate) + '%';
      }
    },
    {
      headerName: 'Assignment', field: 'attributionStatus', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the patient assignment status.',
    },
    {
      headerName: 'Last Claims AWV/IPPE Date', field: 'lastAWVDate', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the date of service for the last AWV or IPPE based on CMS Claims Data.',
      valueFormatter: params => params.value ? formatDate(params.value, 'MM/dd/yyyy', this.locale) : ''
    },
    {
      headerName: 'Last Claims AWV/IPPE Provider', field: 'lastAWVProvider', sortable: true, filter: true, resizable: true, width: 300,
      headerTooltip: 'Displays the name of the provider who performed the last AWV or IPPE based on CMS Claims Data.',
    },
  ];

  rowData: any;

  public clinician: string;
  public isPracticeFilter: boolean;

  public hccEnhanced2FeatureFlag = TrackFeatureConstants.HccDashboardEnhanced2FeatureFlag;

  constructor(private patientSummaryService: HccPatientSummaryService, private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string, private router: Router, private mask: MaskPipe,
    private hccDashboardFilterService: HccDashboardFilterService,
    private hccDashboardService: HccDashboardService,
    protected filterService: GridFilterService, private hierarchyTierService: HierarchyTierService) {
    super(filterService, PATIENT_SUMMARY_GRID_KEY);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(x => {
      this.isPracticeFilter = x.isPractice;
    });

    this.rowData =
      combineLatest([this.hierarchyTierService.currentTier$, this.hccDashboardFilterService.filterModel$]).pipe(
        takeUntil(this.killTrigger),
        filter(x => x[0] != null),
        switchMap(x => {
          const [tierInfo, filterValue] = x;
          const tierNum = filterValue?.isCinSelected ? -1 : tierInfo.tier;
          const tierId = filterValue?.isCinSelected ? filterValue.selectedCin?.id.toString() : tierInfo.selectedTierId;

          this.clinician = this.route.snapshot.params.clinician;
          this.isPracticeFilter = this.route.snapshot.queryParams.isPractice;

          return this.hccDashboardService.hccDashboardPatientSummaryGet(
            {
              tierNum: tierNum,
              tierId: tierId,
              tier1Id: tierInfo.selectedItem.tier1_ID,
              attributionStatus: filterValue == null || !filterValue.isMostAssignable ? ATTRIBUTED : MOST_ASSIGNABLE
            }
          );
        }));
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const sort = [
      {
        colId: 'openRAFWt',
        sort: 'desc'
      },
      {
        colId: 'fullName',
        sort: 'asc'
      },
      {
        colId: 'dob',
        sort: 'asc'
      }
    ];
    this.gridApi.setSortModel(sort);
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    } else if (this.clinician != null) {
      const filterInstance = this.isPracticeFilter ? this.gridApi.getFilterInstance('providerName') : this.gridApi.getFilterInstance('tierID');

      filterInstance.setModel({
        type: 'set',
        values: [this.clinician]
      });

      this.gridApi.onFilterChanged();
    }
  }

  details(): void {
    const params = ['patientDetails'];
    if (this.clinician != null) {
      params.push(this.clinician);
    }

    let queryParams: Params;
    if (this.route.snapshot.queryParams.isPractice != null) {
      queryParams = { isPractice: true };
    }

    this.filterService.resetFilters(PATIENT_DETAIL_REPORT_GRID_KEY);
    this.router.navigate(params, { relativeTo: this.route.parent, queryParams: queryParams });
  }

  ngOnDestroy(): void {
    this.filterService.resetFilters(PATIENT_SUMMARY_GRID_KEY);
  }
}
