
import {
    compareString,
    compareStringPlain,
    compareNumber,
    compareRiskTrend,
    compareRiskCategory,
    currencyCellRenderer,
    convertDateAndCompare,
    compareActions
} from '@shared/components/grid/grid-functions';
import { getDisplayStringForAWVWorklistStatusEnum, PatientViewModel } from '@shared/models/patient';
import moment from 'moment';
import { formatDate } from '@angular/common';
import {
    dateFormat
} from '@shared/services/ag-grid/ag-grid.service';

import { RiskTrendColumnRendererComponent } from './customColumns/riskTrend';
import { ActionsColumnRendererComponent } from './customColumns/actions';
import { IheFilesColumnRendererComponent } from './customColumns/iheFiles';


import { IColumnOptions } from '@shared/models/forms/form-admin-model';
import { ColDef } from 'ag-grid-community';

export function getColumnDefinitions(exportParams: IColumnOptions): ColDef[] {
    return [{
        headerName: '',
        field: 'select',
        minWidth: 25,
        maxWidth: 25,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        cellClass: 'cell-patient-navigation'
    },
    {
        headerName: 'Name',
        headerTooltip: 'Patient Name',
        field: 'fullName',
        minWidth: 250,
        cellClass: 'cell-patient-navigation',
        comparator: compareString,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
        cellStyle: params => {
          if (params.data?.withoutFacesheet){
            return {color : 'black', cursor: 'not-allowed'}
          }
        }
    },
    {
        headerName: 'Name',
        headerTooltip: 'Patient Name',
        field: 'patientName',
        minWidth: 250,
        cellClass: 'cell-patient-navigation',
        comparator: compareString,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
        cellStyle: params => {
          if (params.data?.withoutFacesheet){
            return {color : 'black', cursor: 'not-allowed'}
          }
        }
    },
    {
        headerName: 'BP',
        field: 'bp',
        minWidth: 80,
        sortable: true,
        cellClass: 'u-color--brand--3',
        comparator: (_a: PatientViewModel, _b: PatientViewModel) => {
            const a = _a.systolic;
            const b = _b.systolic;
            if (a == b) {
                return 0;
            }
            if (!a) {
                return -1;
            }
            if (!b) {
                return 1;
            }
            return b - a;
        },
    },
    {
        headerName: 'Pulse',
        field: 'pulse',
        minWidth: 75,
        sortable: true,
    },
    {
        headerName: 'Sugar',
        field: 'bloodSugar',
        minWidth: 75,
        sortable: true,
        cellClass: 'u-color--brand--3',
    },
    {
        headerName: 'Weight',
        field: 'weight',
        minWidth: 75,
        sortable: true,
        cellClass: 'u-color--brand--3',
    },
    {
        headerName: 'Gain',
        field: 'gain',
        minWidth: 120,
        sortable: true,
        cellClass: 'u-color--brand--3',
    },
    {
        headerName: 'DOB (Age)',
        headerTooltip: 'Date of birth',
        field: 'dob_age',
        minWidth: 140,
        sortable: true,
        // REVIEW - replace use of "any" - see all cell renderers - can also factor out the function so it can be tested
        cellRenderer: (data: any): string => {
            const patient: PatientViewModel = data.data;
            const dobMoment = moment(patient?.dob);
            const dobString = dobMoment.isValid() ? dobMoment.format('L') : '-';
            const age = patient?.age;
            return `${dobString} <span class="u-color--highlight u-font-weight--light u-font-size--small margin-left--smallest">${age ? age + 'y' : ''
                }</span>`;
        },
        comparator: (a, b, nodeA, nodeB, isInverted) => {
            return compareNumber(a?.age, b?.age, nodeA, nodeB, isInverted);
        },
    },
    {
        headerName: 'DOB (Age)',
        headerTooltip: 'Date of birth',
        field: 'dob',
        sortable: true,
        suppressSizeToFit: true,
        filter: false,
        valueGetter: (data: any): string => {
            return data?.data?.dob && moment(data.data.dob).isValid()
                ? `${formatDate(data.data.dob, dateFormat, exportParams.locale)}`
                : null;
        },
        cellRenderer: (data: any): string => {
            if (data?.data?.dob && moment(data.data.dob).isValid()) {
                const date = formatDate(
                    new Date(data.data.dob),
                    dateFormat,
                    exportParams.locale
                );
                return `${date} <span class="u-color--highlight u-font-weight--light u-font-size--small margin-left--small">${data.data.age}y</span>`;
            } else {
                return null;
            }
        },
    },
    {
        headerName: 'Date of death',
        headerTooltip: 'Displays the patient’s date of death.',
        field: 'dodBoolean',
        sortable: true,
        filter: 'agSetColumnFilter',
        suppressSizeToFit: true,
        valueGetter: (data: any): string => {
            return data?.data?.dodBoolean;
        },
        cellRenderer: (data: any): string => {
            if (data?.data?.dod && moment(data.data.dod).isValid()) {
                return formatDate(new Date(data.data.dod), dateFormat, exportParams.locale);
            } else {
                return null;
            }
        },
    },
    {
        headerName: 'Gender',
        headerTooltip: 'Gender',
        field: 'gender',
        minWidth: 80,
        sortable: true,
        cellRenderer: (data): string => {
            if (data.value === 'M') {
                return 'Male';
            } else if (data.value === 'F') {
                return 'Female';
            } else {
                return data.value;
            }
        },
        filter: 'agSetColumnFilter',
    },
    {
        headerName: 'Patient Id',
        field: 'patientId', //shown by AWV Only
        minWidth: 130,
        maxWidth: 140,
    },
    {
        headerName: 'MBI',
        headerTooltip: 'Medicare Beneficiary Identifier',
        field: 'current_MBI', // used by cohorts
        minWidth: 130,
        maxWidth: 140,
        suppressSizeToFit: true,
        cellRenderer: params => {
            return `<span ${(params.rowIndex === 0 || params.rowIndex === 1) ? `[attr.data-cy]="patient-row_${params.rowIndex}"` : void 0}>${exportParams.mask.transform(params.value, 6)}</span>`;
        }
    },
    {
        headerName: 'MBI',
        headerTooltip: 'Medicare Beneficiary Identifier',
        field: 'mbi',
        minWidth: 130,
        maxWidth: 140,
        suppressSizeToFit: true,
        cellRenderer: params => {
            return `<span ${(params.rowIndex === 0 || params.rowIndex === 1) ? `[attr.data-cy]="patient-row_${params.rowIndex}"` : void 0}>${exportParams.mask.transform(params.value, 6)}</span>`;
        }
    },
    {
        headerName: 'Phone',
        field: 'phoneNumber',
        minWidth: 150,
        sortable: true,
        comparator: compareStringPlain,
    },
    {
        headerName: 'Phone 1',
        field: 'phone1',
        minWidth: 150,
        sortable: true,
        comparator: compareStringPlain,
    },
    {
        headerName: 'Phone 2',
        field: 'phone2',
        minWidth: 150,
        sortable: true,
        comparator: compareStringPlain,
    },
    {
        headerName: 'Care Coordinator',
        headerTooltip: 'Displays the name of the care coordinator assigned to monitor the patient in Wellpepper.',
        field: 'professionalName',
        minWidth: 200,
        sortable: true,
        comparator: compareString,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
    },
    {
        headerName: 'Assignment',
        headerTooltip: 'Displays the patient assignment status',
        field: 'assignment',
        minWidth: 140,
        sortable: true,
        comparator: compareString,
        filter: true,
    },
    {
        headerName: 'Attributed Provider',
        headerTooltip: 'Displays the name of the provider that the beneficiary is attributed/assignable to. For RHC/FQHCs, the name of the practice is displayed as the beneficiaries cannot be attributed or assigned below the practice level.',
        field: 'attributedCP',
        minWidth: 200,
        sortable: true,
        comparator: compareString,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
    },
    {
        headerName: 'Attributed Practice',
        headerTooltip: 'Displays the name of the practice that the beneficiary is attributed/assignable based on plurality of claims',
        field: 'attributedPractice',
        minWidth: 200,
        sortable: true,
        comparator: compareString,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
    },
    {
        headerName: 'Last Provider Visit',
        headerTooltip: 'Displays the date of the most recent provider visit within that practice.',
        field: 'lastVisit',
        sortable: true,
        suppressSizeToFit: true,
        filter: 'agDateColumnFilter',
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
        filterParams: {
            comparator: convertDateAndCompare
        }
    },
    {
        headerName: 'CPT Code',
        headerTooltip: 'Displays the CPT code to be billed',
        field: 'cptCode',
        suppressSizeToFit: true,
        resizable: true,
        filterParams: {},
    },
    {
        headerName: 'Claim Frequency Code',
        headerTooltip: 'Displays the Claim Frequency Code for the In-Home Annual Wellness Visit claim.',
        field: 'claimFrequencyCode',
        sortable: true,
        comparator: compareString,
        suppressSizeToFit: true,
        resizable: true,
        filterParams: {},
    },
    {
        headerName: 'In-Home AWV Status',
        headerTooltip: 'Displays the patient\'s IH-AWV scheduling status.',
        field: 'ihAwvPatient',
        sortable: true,
        comparator: compareString,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
    },
    {
        headerName: 'Visit Date',
        headerTooltip: 'Displays the date the patient\'s In-Home Annual Wellness was completed',
        field: 'IHEAWVVisitDate',
        sortable: true,
        suppressSizeToFit: true,
        filter: 'agDateColumnFilter',
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
        filterParams: {
            filterOptions: ['inRange'],
            inRangeInclusive: true,
            comparator: convertDateAndCompare,
        }
    },
    {
        headerName: 'Batch Date',
        headerTooltip: 'Displays the date the billing output was available for download',
        field: 'IHEAWVBatchDate',
        sortable: true,
        suppressSizeToFit: true,
        filter: false,
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
    },
    {
        headerName: 'Files',
        headerTooltip: 'Displays all files available from the patient’s In-Home Annual Wellness Visit - Assessment, Personal Prevention Plan, Primary Care Provider Summary, and Case Management Referral.',
        field: 'IHEfileActions',
        sortable: true,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: IheFilesColumnRendererComponent,
        cellRendererParams: { ...exportParams.agGridCellRendererParams }
    },
    {
        headerName: 'Download Status',
        headerTooltip: 'Displays the download state of available files - New, Partial or Done',
        field: 'IHEDownloadStatus',
        sortable: true,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
    },
    {
        headerName: 'Last Download Date',
        headerTooltip: 'Displays the date the last file was downloaded',
        field: 'IHEDownloadDate',
        sortable: true,
        suppressSizeToFit: true,
        filter: false,
        cellRenderer: (data): string => {
            return data.value
                ? formatDate(data.value, 'MMM d, y, h:mm:ss a', exportParams.locale)
                : '';
        },
    },
    {
        headerName: 'Last Activity',
        headerTooltip: 'Displays the date the patient last logged into Wellpepper.',
        field: 'lastActivityAt',
        minWidth: 100,
        cellRenderer: (data): string => {
            return data.value
                ? formatDate(data.value, dateFormat, 'en_US')
                : '';
        },
        sortable: true,
        comparator: compareString,
        suppressSizeToFit: true,
    },
    {
        headerName: 'Alerts',
        headerTooltip: 'Displays whether or not a patient has an outstanding alert or message that needs attention from the Care Team.',
        field: 'actions',
        suppressSizeToFit: true,
        comparator: compareActions,
        sortable: true,
        cellRendererFramework: ActionsColumnRendererComponent,
    },
    {
        headerName: 'Previous Year Chronic Condition Weight Closed',
        headerTooltip: 'Displays beneficiary\'s Closed Chronic Conditions for the previous calendar year.',
        field: 'pyRaf',
        suppressSizeToFit: true,
        sortable: true,
        comparator: compareNumber,
        filter: 'agSetColumnFilter',
        valueGetter(data: any): number | string {
            return data.data.pyRaf?.length > 0 ? data.data.pyRaf : 'N/A';
        },
    },
    {
        headerName: 'Chronic Condition Weight Closed YTD',
        headerTooltip: 'Displays beneficiary\'s Closed Chronic Conditions for the current calendar year.',
        field: 'raF_YTD',
        minWidth: 30,
        maxWidth: 400,
        suppressSizeToFit: true,
        sortable: true,
        comparator: compareNumber,
        filter: 'agSetColumnFilter',
        valueGetter(data: any): number | string {
            return data.data.raF_YTD?.length > 0 ? data.data.raF_YTD : 'N/A';
        },
    },
    {
        headerName: 'Total Gap Weight',
        headerTooltip: 'Displays the highest possible weight that can be coded if all open chronic Chronic Conditions gaps are closed',
        field: 'openRAFWt',
        minWidth: 150,
        maxWidth: 200,
        sortable: true,
        comparator: compareNumber,
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: ['empty', 'inRange', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
            suppressAndOrCondition: true,
            inRangeInclusive: true,
            includeBlanksInLessThan: true
        },
        valueGetter(data: any): number | string {
            return data.data.openRAFWt;
        },
        valueFormatter(data: any): string {
            return data.value?.toFixed(3) || 'N/A';
        }
    },
    {
        headerName: 'AWV/IPPE',
        headerTooltip: 'Type of service the patient is next eligible for',
        field: 'awvTypeDue',
        suppressSizeToFit: true,
        minWidth: 30,
        maxWidth: 120,
        filter: 'agSetColumnFilter',
    },
    {
        headerName: 'Last Claims AWV/IPPE Date',
        headerTooltip: 'Displays the date of service for the last AWV or IPPE based on CMS claims data.',
        field: 'lastAWVDate',
        sortable: true,
        suppressSizeToFit: true,
        filter: 'agDateColumnFilter',
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
        filterParams: {
            comparator: convertDateAndCompare
        }
    },
    {
      headerName: 'Last Claims AWV/IPPE Date',
      headerTooltip: 'Displays the date of service for the last AWV or IPPE based on CMS claims data.',
      field: 'lastClaimAWVDate',
      sortable: true,
      suppressSizeToFit: true,
      filter: 'agDateColumnFilter',
      cellRenderer: (params): string => {
          if (params.value) {
              const date = new Date(params.value);
              return `${formatDate(date, dateFormat, exportParams.locale)}`;
          }
          return '';
      },
      filterParams: {
          comparator: convertDateAndCompare
      }
  },
    {
        headerName: '',
        field: 'hasNotes',
        minWidth: 10,
    },
    {
        headerName: 'Current Risk Category',
        field: 'currentRiskLevel',
        filter: true,
        minWidth: 185,
        sortable: true,
        comparator: compareRiskCategory,
    },
    {
        headerName: 'Predicted Risk Category',
        headerTooltip: 'Displays beneficiary\'s Predicted Risk Category (PRC). PRCs is a predictive model designed to identify patients who are likely to incur high levels of expenditures in the next 12 months.',
        field: 'predictedRiskLevel',
        filter: true,
        minWidth: 190,
        sortable: true,
        comparator: compareRiskCategory,
    },
    {
        headerName: 'Last Claims AWV/IPPE Provider',
        field: 'lastAWVProvider',
        headerTooltip: 'Displays the name of the provider who performed the last AWV or IPPE based on CMS claims data.',
        minWidth: 250,
        sortable: true,
        comparator: compareString,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
    },
    {
      headerName: 'Last Claims AWV/IPPE Provider',
      field: 'lastClaimAWVProvider',
      headerTooltip: 'Displays the name of the provider who performed the last AWV or IPPE based on CMS claims data',
      minWidth: 250,
      sortable: true,
      comparator: compareString,
      filter: 'agSetColumnFilter',
      filterParams: {
          showTooltips: true,
      },
  },
    {
        headerName: 'Probability Risk Category',
        field: 'probability',
        filter: true,
        minWidth: 205,
        sortable: true,
    },
    {
        headerName: 'Risk Trend',
        field: 'riskTrend',
        filter: true,
        minWidth: 120,
        sortable: true,
        cellRendererFramework: RiskTrendColumnRendererComponent,
        comparator: compareRiskTrend,
    },
    {
        headerName: 'CCM Eligible',
        headerTooltip: 'Displays if the patient meets CCM eligibility due to 2+ diagnosis codes in an Chronic Conditions Category.',
        field: 'ccmEligible',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter'
    },
    {
        headerName: 'Last CCM Date',
        headerTooltip: 'The date for the last billed chronic care management visit',
        field: 'lastCcmDate',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
        if (params.value) {
          return moment.utc(params.value).format("MM/DD/YYYY")
        }
        return '';
      },
      filterParams: {
        comparator: convertDateAndCompare
      }
    },
    {
        headerName: 'Chronic Conditions',
        headerTooltip: 'Displays the name of each Caravan Chronic Condition the patient qualifies for.',
        field: 'diseaseCohorts',
        minWidth: 200,
        suppressSizeToFit: true,
        cellRenderer: (data): string => {
            return data?.value?.join(', ');
        },
        sortable: true,
        filter: 'agSetColumnFilter',
    },
    {
        headerName: 'Health Care Cost',
        headerTooltip: 'Displays a sum of the total paid amounts for claims within the previous 15 (12 + 3) months. The 3 additional months account for CMS claims lag.',
        field: 'totalCost',
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: (
            valueA: number,
            valueB: number,
            nodeA,
            nodeB,
            isInverted
        ) => {
            return valueA - valueB;
        },
        cellRenderer: currencyCellRenderer,
    },
    {
        headerName: 'ED Utilization',
        headerTooltip: 'Number of visits to the Emergency Department in the last 15 (12+3) months.',
        field: 'edVisits',
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: (valueA: string, valueB: string) => {
            return Number(valueA) - Number(valueB);
        },
    },
    {
        headerName: 'Health Care Costs',
        headerTooltip: 'Displays a sum of the total paid amounts for claims within the previous 15 (12 + 3) months. The 3 additional months account for CMS claims lag.  ',
        field: 'totalSpend',
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: (
            valueA: number,
            valueB: number,
            nodeA,
            nodeB,
            isInverted
        ) => {
            return valueA - valueB;
        },
        cellRenderer: currencyCellRenderer,
    },
    {
        headerName: 'Appointment Time',
        field: 'appointmentTime',
        sortable: true,
        filter: true,
        minWidth: 150,
        width: 150,
        valueFormatter: (params) =>
            params?.value ? formatDate(params.value, 'h:mm a', exportParams.locale) : null,
        resizable: true,
        suppressSizeToFit: true,
    },
    {
        headerName: 'Status',
        field: 'status',
        sortable: true,
        filter: true,
        minWidth: 125,
        width: 125,
        resizable: true,
        suppressSizeToFit: true,
        headerTooltip: 'Displays if the patient is enrolled or unenrolled in the program',
    },
    {
        headerName: 'Last Download Date',
        field: 'IHEModifiedDate',
        headerTooltip: 'Displays the date the last file was downloaded',
        sortable: true,
        filter: true,
        minWidth: 125,
        width: 125,
        resizable: true,
        suppressSizeToFit: true,
    },
    {
        headerName: 'Appointment Date',
        field: 'appointmentDate',
        sortable: true,
        filter: true,
        minWidth: 150,
        width: 150,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
        resizable: true,
        suppressSizeToFit: true,
    },
    {
        headerName: 'Clinician',
        field: 'clinicianName',
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: true,
        filterParams: {
            showTooltips: true,
        },
    },
    {
        headerName: 'Appointment Info',
        field: 'appointmentInfo',
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: true,
        filterParams: {
            showTooltips: true,
        },
    },
    {
        headerName: 'Location ID',
        field: 'locationId',
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: true,
    },
    //Hidden with CC2-579 for all cohorts.
    // {
    //     headerName: 'HCC Closure Gaps',
    //     headerTooltip: 'Displays the number of open Chronic Hierarchical Condition Category (HCC) gaps.',
    //     field: 'hccGapCount',
    //     minWidth: 100,
    //     suppressSizeToFit: true,
    //     sortable: true,
    //     filter: 'agSetColumnFilter',
    // },
    {
        headerName: 'SNF Name', // string
        headerTooltip: 'The name of the Skilled Nursing Facility housing a patient.',
        field: 'snfName',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
    },
    {
        headerName: 'SNF Flags List', // string
        field: 'snfFlagsList',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        hide: true
    },
    // CC-3535 new cohort columns
    {
        headerName: 'Last Spirometry Date',
        headerTooltip: 'Date of the last Spirometry',
        field: 'spirometryProcedure_LastDate',
        sortable: true,
        filter: true,
        minWidth: 150,
        width: 150,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
        resizable: true,
        suppressSizeToFit: true,
    },
    {
        headerName: 'Pulmonologist',
        headerTooltip: 'Last Pulmonologist that billed for a service ',
        field: 'pulmonologistProcedure_LastProviderName',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
    },
    {
        headerName: 'SNF Admission', // date
        headerTooltip: 'The date the patient is admitted to the Skilled Nursing Facility.',
        field: 'snfAdmittedAt',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        cellRenderer: (data): string => {
            return data.value
                ? formatDate(data.value, dateFormat, exportParams.locale)
                : '';
        }
    },
    {
        headerName: 'Last Pulmonologist Visit Date',
        headerTooltip: 'Last Pulmonologist Visit Date',
        field: 'pulmonologistProcedure_LastDate',
        sortable: true,
        filter: true,
        minWidth: 150,
        width: 150,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
        resizable: true,
        suppressSizeToFit: true,
    },
    {
        headerName: 'Last Echo Date',
        headerTooltip: 'Date of the last echo code billed',
        field: 'echoProcedure_LastDate',
        sortable: true,
        filter: true,
        minWidth: 150,
        width: 150,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
        resizable: true,
        suppressSizeToFit: true,
    },
    {
        headerName: 'SNF Discharge',  // date
        headerTooltip: 'The date the patient is discharged from the Skilled Nursing Facility.',
        field: 'snfDischargedAt',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        cellRenderer: (data): string => {
            return data.value
                ? formatDate(data.value, dateFormat, exportParams.locale)
                : '';
        },
    },
    {
        headerName: 'COPD DX Count',
        headerTooltip: 'Number of times COPD appeared on a claim within the last 27 (24+3) months. The 3 additional months account for CMS claims lag.',
        field: 'copdDxCount',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    },

    {
        headerName: 'Cardiologist ',
        headerTooltip: 'Last Cardiologist that billed for a service ',
        field: 'cardiologistProcedure_LastProviderName',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    },
    {
        headerName: 'Patient Status', // string list
        headerTooltip: 'The patient\'s status - Admitted, Discharged, Discharged after 30 days or Removed.',
        field: 'snfStatus',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            suppressSelectAll: true,
        },
        /* cp.snfInfo?.current?.snfStatus?.map( flag => flag[0] + flag.slice(1, flag.length).replace(/([A-Z])/g, ' $1').trim() ).join(", ")
        cellRenderer: (data): string => {
          return data.value?.length ? data.value.map( flag => flag[0] + flag.slice(1, flag.length).replace(/([A-Z])/g, ' $1').trim() ).join(", ") : '';
        },*/
    },
    {
        headerName: 'Last Cardiologist Visit Date',
        headerTooltip: 'Last Cardiologist Visit Date',
        field: 'cardiologistProcedure_LastDate',
        sortable: true,
        filter: true,
        minWidth: 150,
        width: 150,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
        resizable: true,
        suppressSizeToFit: true,
    },
    {
        headerName: 'HF DX Count',
        headerTooltip: 'Number of times Heart Failure appeared on a claim within the last 27 (24+3) months. The 3 additional months account for CMS claims lag',
        field: 'hfDxCount',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        resizable: true,
        comparator: compareNumber,
        filter: 'agNumberColumnFilter',
    },
    {
        headerName: 'DX Count',
        headerTooltip: 'Displays the count of diagnoses included on the CSV billing output',
        field: 'iheDxCount',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    },
    {
        headerName: 'Office Visits',
        headerTooltip: 'Number of office visits in the last  15 (12 + 3) months with any care provider. The 3 additional months account for CMS claims lag.',
        field: 'officeVisits',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    },
    {
        headerName: 'IP Admissions',
        headerTooltip: 'Number of inpatient admissions in the last 15 (12 + 3) months. The 3 additional months account for CMS claims lag.',
        field: 'inpatientVisits',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    },
    {
      headerName: 'Last CCM Code',
      headerTooltip: 'The code for the last billed chronic care management visit',
      field: 'ccmLastProcCode',
      minWidth: 100,
      suppressSizeToFit: true,
      sortable: true,
      filter: 'agSetColumnFilter',
      resizable: true,
    },
    {
        headerName: 'Most Impactable',
        headerTooltip: 'Most impactable patients that were identified each quarter for shared savings in the performance dashboard denominator.',
        field: 'isMostImpactable',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    }, {
        headerName: 'Address',
        headerTooltip: 'Displays the patient\'s address',
        field: 'address',
        minWidth: 300,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    }, {
        headerName: 'Schedule Status',
        headerTooltip: 'Displays the scheduling status of patients on the In-Home Annual Wellness Visit List',
        field: 'scheduleStatus',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    }, {
        headerName: 'Scheduling Description',
        headerTooltip: 'Displays the scheduling status details for patients on the In-Home Annual Wellness Visit List',
        field: 'schedulingDescription',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    }, {
        headerName: 'Last Outreach Date',
        headerTooltip: 'Displays the last date the patient was contacted',
        field: 'callDate',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
    }, {
        headerName: 'Appointment Date',
        headerTooltip: 'Displays the date of the scheduled In-Home Annual Wellness Visit',
        field: 'dateOfService',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
        filterParams: {
            comparator: convertDateAndCompare,
            filterOptions: ['inRange'],
            inRangeInclusive: true
        }
    }, {
        headerName: 'Rendering Provider',
        headerTooltip: 'Displays the Signify clinician that completed the In-Home Annual Wellness Visit',
        field: 'renderingProvider',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    },
    {
        headerName: 'Rendering Provider',
        field: 'renderingProviderName',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
    },
    {
        headerName: 'Alert',
        field: 'alert',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        headerTooltip: 'Displays if the patient triggered an alert or not',
    },
    {
        headerName: 'Self-Reported Date',
        field: 'statusDate',
        headerTooltip: 'Self-reported AWV date, patient will be removed from this list when the AWV claim is received.',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${moment(date).utc().format('MM/DD/yyyy')}`;
            }
            return '';
        },
    },
    {
        headerName: 'Last Response',
        field: 'lastResponse',
        minWidth: 200,
        suppressSizeToFit: true,
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        cellRenderer: (params): string => {
            if (params.value?.includes("Response: ")) {
                return params.value.replace("Response: ", "");
            }
            return params.value;
        },
    },
    {
        headerName: 'Last Response Date',
        field: 'lastResponseDate',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        headerTooltip: 'Displays the date of the patient’s last response.',
        filterParams: {
            filterOptions: ['equals', 'inRange'], // https://www.ag-grid.com/javascript-data-grid/filter-provided-simple/
            suppressAndOrCondition: true,
            inRangeInclusive: true,
            comparator: convertDateAndCompare
        },
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
    },
    {
        headerName: 'Enrollment Date',
        field: 'enrollmentDate',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        valueFormatter: (params) =>
            params?.value
                ? formatDate(params.value, dateFormat, exportParams.locale)
                : null,
    },
    {
        headerName: 'SMS Program',
        headerTooltip: 'Displays the name of the SMS program the patient is enrolled in',
        field: 'smsProgram',
        minWidth: 200,
        sortable: true,
        comparator: compareString,
        filter: 'agSetColumnFilter',
        filterParams: {
            showTooltips: true,
        },
    },
    {
        headerName: 'Enrollment Status',
        field: 'enrollmentType',
        headerTooltip: 'Enrollment Status - Displays reason for enrollment in Medicare',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true
    }, {
        headerName: 'Shared Savings Denominator',
        field: 'ssDenom',
        headerTooltip: 'Patients meet shared savings criteria',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        valueGetter: (params) => {
            return params.data.ssDenom == 1 ? "Yes" : "No"
        },
    }, {
        headerName: '', // need to hide this column
        field: 'ssPercent',
        // headerTooltip: 'Patients meet shared savings criteria',
        width: 1,
        resizable: false,

        // suppressSizeToFit: true,
        // sortable: true,
        comparator: compareNumber,
        filter: 'agNumberColumnFilter',
        suppressMenu: true,
        filterParams: {
            filterOptions: ['empty', 'inRange', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
            suppressMenu: true,
            inRangeInclusive: true,
            includeBlanksInLessThan: true
        },
        cellRenderer: (params): string => {
            return '';
        }
    }, {
        headerName: 'Spirometry last date',
        field: 'cohortProcedureLastDate',
        headerTooltip: 'Spirometry last date',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
        filterParams: {
            comparator: convertDateAndCompare
        }
    }, {
        headerName: 'Pulmonologist Name',
        field: 'cohortSpecialistLastProviderName',
        headerTooltip: 'Pulmonologist Name',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true
    }, {
        headerName: 'Pulmonologist last date',
        field: 'cohortSpecialistLastDate',
        headerTooltip: 'Pulmonologist last date',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
        filterParams: {
            comparator: convertDateAndCompare
        }
    },
    {
        headerName: 'Align to Clinical Program',
        headerTooltip: 'Patients that align to the clinical program criteria for patient outreach',
        field: 'alignToClinical',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        valueGetter(data: any): string {
            return data.data.alignToClinical == "Y" ? "Yes" : "No";
        },
    },
    {
        headerName: 'Echo last date',
        field: 'hfCohortProcedureLastDate',
        headerTooltip: 'Echo last date',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
        filterParams: {
            comparator: convertDateAndCompare
        }
    }, {
        headerName: 'Cardiologist',
        field: 'hfCohortSpecialistLastProviderName',
        headerTooltip: 'Cardiologist',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true
    }, {
        headerName: 'Cardiologist last date',
        field: 'hfCohortSpecialistLastDate',
        headerTooltip: 'Cardiologist last date',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
            if (params.value) {
                const date = new Date(params.value);
                return `${formatDate(date, dateFormat, exportParams.locale)}`;
            }
            return '';
        },
        filterParams: {
            comparator: convertDateAndCompare
        }
    }, {
        headerName: 'Total Gap Weight',
        headerTooltip: 'Displays the highest possible weight that can be coded if all open chronic Chronic Conditions gaps are closed',
        field: 'totalGapWt',
        minWidth: 150,
        maxWidth: 200,
        sortable: true,
        comparator: compareNumber,
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: ['empty', 'inRange', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
            suppressAndOrCondition: true,
            inRangeInclusive: true,
            includeBlanksInLessThan: true
        },
        valueFormatter(data: any): string {
            return data.value?.toFixed(3) || 'N/A';
        }
    },{
        headerName: 'Last G2025-CS',
        field: 'lastCsthDate',
        headerTooltip: 'Date of the last billed G2025 code with a CS modifer',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        resizable: true,
        filter: 'agDateColumnFilter',
        cellRenderer: (params): string => {
          if (params.value) {
            const date = new Date(params.value);
            return `${formatDate(date, dateFormat, exportParams.locale)}`;
          }
          return '';
        },
        filterParams: {
          comparator: convertDateAndCompare
        }
      },{
        headerName: 'AWV Workflow Status',
        field: 'allAWVPatientsListStatus',
        headerTooltip: 'Displays which status the patient is in within the AWV Opportunities workflow',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        valueGetter(data: any): string {
            return data.data.allAWVPatientsListStatus;
        },
        cellRenderer: (params): string => {
            return getDisplayStringForAWVWorklistStatusEnum(params.value);
        },
        filterParams: {
            valueFormatter: (data: any) => {
                return getDisplayStringForAWVWorklistStatusEnum(data.value);
            },
        }
      },
      {
        headerName: 'Last Self-Reported AWV/IPPE Date',
        field: 'newStatusDate',
        headerTooltip: 'Displays the date of service for the last AWV that has been Self Reported',
        minWidth: 250,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
          if (params.value) {
              return moment.utc(params.value).format("MM/DD/YYYY")
          }
          return '';
        },
        filterParams: {
          comparator: convertDateAndCompare
        }
      },
      {
        headerName: 'Last EHR AWV/IPPE Date',
        field: 'lastEHRAWVIPPEDate',
        headerTooltip: 'Displays the date of service for the last AWV or IPPE reported by an EHR feed',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: (params): string => {
          if (params.value) {
            return moment.utc(params.value).format("MM/DD/YYYY")
          }
          return '';
        },
        filterParams: {
          comparator: convertDateAndCompare
        }
      },
      {
        headerName: 'Last EHR AWV/IPPE Community',
        headerTooltip: 'Displays the community who reported the data for the last reported AWV/IPPE from the EHR source',
        field: 'ehrLastAwvCommunity',
        minWidth: 250,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        hide: true
       },
       {
        headerName: 'Last EHR AWV/IPPE Provider',
        headerTooltip: 'Displays the provider who performed the AWV/IPPE from the EHR source',
        field: 'ehrLastAwvProvider',
        minWidth: 250,
        suppressSizeToFit: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        hide: true
       },
     {
      headerName: 'Last Reported AWV/IPPE Source',
      headerTooltip: 'Displays the source of the last reported AWV/IPPE',
      field: 'ehrReportedLastAwvSource',
      minWidth: 250,
      suppressSizeToFit: true,
      sortable: true,
      filter: 'agSetColumnFilter',
      resizable: true,
     },
     {
      headerName: 'Last Reported AWV/IPPE Date',
      field: 'ehrReportedLastAwvDate',
      headerTooltip: 'Displays the date of the last reported AWV/IPPE',
      minWidth: 100,
      suppressSizeToFit: true,
      sortable: true,
      filter: 'agDateColumnFilter',
      resizable: true,
      cellRenderer: (params): string => {
        if (params.value) {
          return moment.utc(params.value).format("MM/DD/YYYY")
        }
        return '';
      },
      filterParams: {
        comparator: convertDateAndCompare
      }
    },{
        headerName: '3 Day Waiver',
        field: 'isUsingSnf3DayWaiver',
        headerTooltip: '3 Day Waiver has been used for this patient\'s admission',
        minWidth: 100,
        suppressSizeToFit: true,
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        valueGetter: (params) => {
            return params.data.isUsingSnf3DayWaiver ? "Yes" : "No"
        },
    },
  ]
};
