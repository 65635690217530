import { HttpClient } from  '@angular/common/http';
import { Injectable } from  '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {CareOrchestrationConstants} from "@shared/models/module-constants";

/*
Temporary Service for Care Orchestration - simulating patient enrolment
 */

@Injectable({
  providedIn:  'root'
})
export class PatientAwvEnrolService {

  constructor(private http: HttpClient) { }

  public async enrol(patientId: string) {
    console.info(`Enrolling patient ${patientId}`);
    let url = `${environment.careOrchApiUrl}/programs/awv/enrolments/`;

    try {
      let lastYear = new Date();
      lastYear.setFullYear(lastYear.getFullYear() - 1);
      let requestBody = {
        chPatId: patientId,
        communityId: 'AR10',
        criteriaSatisfiedDateTime: new Date().toISOString(),
        lastAwvDetails: {
          date: lastYear.toISOString().split('T')[0],
          source: 'PepClinicalData'
        }
      };
      return await this.http.post<any>(url, requestBody).toPromise();
    } catch (e) {
      console.error(e);
    }
  }
}
