<ag-grid-angular
    coachAgGridResize
    class='ag-theme-material dynamicBody'
    [columnDefs]='columnNamesLevelOne'
    [defaultColDef]='defaultColDef'
    [rowData]='CaravanFormAdminResponseData'
    (gridReady)='onGridReady($event)'
    [frameworkComponents]='frameworkComponents'
    [masterDetail]='true'
    [detailCellRendererParams]='detailCellRendererParams'
    [context]="context"
    [detailRowAutoHeight]="true"
    [isRowMaster]="isRowMaster"
    [icons]="icons">
</ag-grid-angular>
<div class="footer">
  <button class="button is-small"  (click)="onBtnExport()">Export</button>
  <ng-container *ngIf="formID == '3'">
    <button class="button is-small"  (click)="onBtnExportResponses()">Export Responses</button>
  </ng-container>
</div>

