import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CareModule } from '@care/care.module';
import { HomeModule } from '@views/home/home.module';
import { SubmitModule } from './submit/submit.module';
import { TrackModule } from '@track/track.module';
import { ImproveModule } from './improve/improve.module';
import { EulaAgreementComponent } from './eula-agreement/eula-agreement.component';
import { TimeoutComponent } from './timeout/timeout.component';
import { SignoutComponent } from './signout/signout.component';
import { AutoLoginComponent } from './auto-lopgin/auto-login.component';
import { NoAuthComponent } from './no-auth/no-auth.component';
import { RootComponent } from './root/root.component';

@NgModule({
  declarations: [EulaAgreementComponent, TimeoutComponent, SignoutComponent, AutoLoginComponent, NoAuthComponent, RootComponent],
  imports: [
    SharedModule,
    CareModule,
    HomeModule,
    SubmitModule,
    TrackModule,
    ImproveModule,
  ],
  exports: []
})
export class ViewsModule { }
