import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { CareFeatureConstants, PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class AwvFilesPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.AnnualWellnessVisits: PresetCategory.Worklists;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.AwvFiles,
      presetKey: CarePatientItem.AwvFiles,
      label: 'In Home AWV Files',
      filters: [],
      componentId: this.isIHECohortEnabled ? CareComponentConstants.IHECohort : null,
      hidePreset: true,
      dontStoreName: true,
      patientSidebar: true,
      filterSettings: null,
      listDefinition: 'Download files from In-Home Annual Wellness Visit',
      columns: this.getPresetColumns(),
      patientUrlQueryParams: {
        tab: 'Worklist Details',
        subtab: 'In Home AWV Files'
      },
      defaultFilterModel: {
        IHEDownloadStatus: {
          filterType: 'set',
          values: ['New', 'Partial']
        }
      }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    return [
      { select: true },
      { current_MBI: true },
      { fullName: true },
      { dob: true },
      { gender: true },
      { IHEAWVVisitDate: true },
      { IHEfileActions: true },
      { IHEDownloadDate: true },
      { IHEDownloadStatus: true },
      { assignment: true },
      { attributedPractice: true },
      { attributedCP: true },
      { renderingProviderName: true }
    ];
  }
}
