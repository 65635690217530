import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import {
  PatientWorkflowScheduledComponent
} from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-scheduled/patient-workflow-scheduled.component';
import {
  PatientWorkflowCompletedComponent
} from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-completed/patient-workflow-completed.component';
import {
  PatientWorkflowCallBackComponent
} from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-call-back/patient-workflow-call-back.component';
import {
  PatientWorkflowRemoveComponent
} from '@shared/modules/patient-facesheet/tabs/patient-awv-scheduling/patient-workflow-remove/patient-workflow-remove.component';
import { AwvService } from '../../../../../api/care-orch/services/awv.service';
import { AwvWorkflow } from '../../../../../api/care-orch/models/awv-workflow';
import { Outcome } from '../../../../../api/care-orch/models/outcome';
import {
  ActivityType,
  AwvWorkflowStatuses,
  CallBackOutcome,
  CallbackProperties,
  OutcomeType,
  ScheduledOutcome,
  ScheduledProperties,
  SchedulingActivity
} from '../../../../../api/care-orch/models';
import { CareOrchestrationConstants } from '@shared/models/module-constants';
import { Observable } from 'rxjs';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import { Program } from '@shared/services/mixpanel/events/workflow-events';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';

@Component({
  selector: 'coach-patient-awv-scheduling',
  templateUrl: './patient-awv-scheduling.component.html',
  styleUrls: ['./patient-awv-scheduling.component.scss']
})
export class PatientAwvSchedulingComponent {
  @Input() public patient: Patient;
  @Input() public awvWorkflow: AwvWorkflow;
  @Input() public tier: IHierarchyTier;
  @Input() public mobileScreenSize: boolean;
  @Input() sidebarMode?: boolean;
  @Input() public isTabOpen: boolean;
  @Input() public properties: CallbackProperties;
  @Output() patientUpdated: EventEmitter<null> = new EventEmitter();
  @Output() awvWorkflowUpdated: EventEmitter<Observable<AwvWorkflow>> = new EventEmitter();
  @Output() public activityDeleted: EventEmitter<ActivityType> = new EventEmitter();

  @ViewChild(PatientWorkflowScheduledComponent) patientWorkflowScheduledComponent: PatientWorkflowScheduledComponent;
  @ViewChild(PatientWorkflowCompletedComponent) patientWorkflowCompletedComponent: PatientWorkflowCompletedComponent;
  @ViewChild(PatientWorkflowCallBackComponent) patientWorkflowCallBackComponent: PatientWorkflowCallBackComponent;
  @ViewChild(PatientWorkflowRemoveComponent) patientWorkflowRemoveComponent: PatientWorkflowRemoveComponent;

  user: wpapi.model.Professional;
  professionalsById: { [key: string]: wpapi.model.Professional } = {};
  today: string = WorkflowDateTimeUtil.todayDateOnlyString();
  selectedActivity: number = 0;
  scheduledOutcomeProperties = {} as ScheduledProperties;
  callbackOutcomeProperties = {} as CallbackProperties;
  allAwvWorkflowStatuses = AwvWorkflowStatuses;

  constructor(private awvService: AwvService, private workflowEvents: WorkflowEventsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.patient) {
      this.setSelectedActivity(0);
    }
    if (this.awvWorkflow){
      this.scheduledOutcomeProperties = null;
      this.callbackOutcomeProperties = null;
      if (this.awvWorkflow.status === this.allAwvWorkflowStatuses.Scheduled){
        const schedulingActivities = this.awvWorkflow.completedActivities
          .filter( x  => x.type === 'SchedulingActivity').map(x => x as SchedulingActivity);
        const scheduledOutcome = schedulingActivities.find( x => x.outcome.type === OutcomeType.ScheduledOutcome).outcome as ScheduledOutcome;
        this.scheduledOutcomeProperties = scheduledOutcome.properties;
      }
      if (this.awvWorkflow.status === this.allAwvWorkflowStatuses.OutreachAttempted) {
        const callbackActivities = this.awvWorkflow.inProgressActivities
          .filter(x => x.type === 'SchedulingActivity').map(x => x as SchedulingActivity);
        const callbackOutcome = callbackActivities.find(x => x.outcome.type === OutcomeType.CallBackOutcome).outcome as CallBackOutcome;
        this.callbackOutcomeProperties = callbackOutcome.properties;
      }
    }
  }
  clearUnselectedActivityForms(){
    if (this.selectedActivity === 1){
      this.patientWorkflowScheduledComponent.clearFields();
    }
    else if (this.selectedActivity === 2){
      this.patientWorkflowCompletedComponent.clearFields();
    }
    else if (this.selectedActivity === 3){
      this.patientWorkflowCallBackComponent.clearFields();
    }
    else if (this.selectedActivity === 4){
      this.patientWorkflowRemoveComponent.clearFields();
    }
  }
  setSelectedActivity(activityNumber) {

    // Clearing the form(s) before selecting/unselecting the workflow(s)
    this.clearUnselectedActivityForms();

    if (this.selectedActivity === activityNumber) {
      this.selectedActivity = 0;
    }
    else
    {
      this.selectedActivity = activityNumber;
    }
  }

  outcomeSaved(outcome: Outcome): void {
    const activityToSave = { type: ActivityType.SchedulingActivity, outcome } as SchedulingActivity;
    if (this.scheduledOutcomeProperties || this.callbackOutcomeProperties) {
      this.workflowEvents.trackEdit(activityToSave);
    } else {
      this.workflowEvents.trackSave(activityToSave);
    }

    const obsUpdatedWorkflow = this.awvService.updateWorkflowActivity({
      programId: CareOrchestrationConstants.AwvProgramId,
      chPatId: this.patient.trackAwvPatient?.chPatID || Number(this.patient.trackPatient.chPatId),
      body: activityToSave
    });

    this.awvWorkflowUpdated.emit(obsUpdatedWorkflow);
  }

  activityDeletedEvent(activityType: ActivityType): void {
    this.activityDeleted.emit(activityType);
  }
}


