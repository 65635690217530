<coach-header-title-portal>
  <coach-header-breadcrumbs [showBreadcrumbs]="true" class="row--verticalCenter u-preserve"></coach-header-breadcrumbs>
</coach-header-title-portal>

<ag-grid-angular class="ag-theme-material clinician-grid dynamicBody" [pagination]="true"
  [paginationAutoPageSize]="true" multiSortKey="ctrl" [rowData]="rowData | async" [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)" 
  (filterChanged)="filterChanged($event)" tooltipShowDelay="500">
</ag-grid-angular>

<coach-export-report-button class="export-button" [gridApi]="gridApi" reportName="CliniciansReport">
</coach-export-report-button>

<coach-hierarchy-required pageName="Chronic Conditions Dashboard reports" [requiredTier]="2"></coach-hierarchy-required>