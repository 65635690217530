import { Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Access } from '@shared/services/graphql/access.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class HasAccessBase implements OnDestroy {

    constructor(protected userAccessService: UserAccessService,
                protected templateRef: TemplateRef<any>,
                protected viewContainer: ViewContainerRef) {
        userAccessService.currentAccess$.pipe(takeUntil(this.killTrigger)).subscribe(access => {
            this.updateAccess(access);
        });
    }

    killTrigger: Subject<void> = new Subject();
    private hasView = false;

    ngOnDestroy(): void {
        this.killTrigger.next();
    }

    updateAccess(access: Access): void {
        const hasAccess = this.getAccess(access);
        if (hasAccess && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!hasAccess && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    abstract getAccess(access: Access): boolean;

}
