/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Activity } from '../models/activity';
import { ActivityType } from '../models/activity-type';
import { AwvWorkflow } from '../models/awv-workflow';
import { AwvWorkflowHistoryPaged } from '../models/awv-workflow-history-paged';
import { PageParameters } from '../models/page-parameters';


/**
 * Represents resources related to the Annual Wellness Visit Program.
 */
@Injectable({
  providedIn: 'root',
})
export class AwvService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getWorkflowActivities
   */
  static readonly GetWorkflowActivitiesPath = '/programs/{programId}/workflows/{chPatId}';

  /**
   * Gets the given patient's workflow in its current state.
   *
   * Gets the given patient's workflow in its current state.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkflowActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkflowActivities$Response(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AwvWorkflow>> {

    const rb = new RequestBuilder(this.rootUrl, AwvService.GetWorkflowActivitiesPath, 'get');
    if (params) {
      rb.path('programId', params.programId, {});
      rb.path('chPatId', params.chPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AwvWorkflow>;
      })
    );
  }

  /**
   * Gets the given patient's workflow in its current state.
   *
   * Gets the given patient's workflow in its current state.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkflowActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkflowActivities(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;
  },
  context?: HttpContext

): Observable<AwvWorkflow> {

    return this.getWorkflowActivities$Response(params,context).pipe(
      map((r: StrictHttpResponse<AwvWorkflow>) => r.body as AwvWorkflow)
    );
  }

  /**
   * Path part for operation updateWorkflowActivity
   */
  static readonly UpdateWorkflowActivityPath = '/programs/{programId}/workflows/{chPatId}';

  /**
   * Update a Workflow Activity.
   *
   * Updates a workflow activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkflowActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkflowActivity$Response(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;

    /**
     * The activity to be updated on a workflow
     */
    body?: Activity
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AwvWorkflow>> {

    const rb = new RequestBuilder(this.rootUrl, AwvService.UpdateWorkflowActivityPath, 'put');
    if (params) {
      rb.path('programId', params.programId, {});
      rb.path('chPatId', params.chPatId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AwvWorkflow>;
      })
    );
  }

  /**
   * Update a Workflow Activity.
   *
   * Updates a workflow activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkflowActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkflowActivity(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;

    /**
     * The activity to be updated on a workflow
     */
    body?: Activity
  },
  context?: HttpContext

): Observable<AwvWorkflow> {

    return this.updateWorkflowActivity$Response(params,context).pipe(
      map((r: StrictHttpResponse<AwvWorkflow>) => r.body as AwvWorkflow)
    );
  }

  /**
   * Path part for operation rollbackWorkflowActivity
   */
  static readonly RollbackWorkflowActivityPath = '/programs/{programId}/workflows/{chPatId}/{typeOfActivityToRollback}';

  /**
   * Rollback a Workflow Activity.
   *
   * Rollsback a Workflow Activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rollbackWorkflowActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  rollbackWorkflowActivity$Response(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;

    /**
     * Unique identifier for the Activity rollback.
     */
    typeOfActivityToRollback: ActivityType;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AwvWorkflow>> {

    const rb = new RequestBuilder(this.rootUrl, AwvService.RollbackWorkflowActivityPath, 'delete');
    if (params) {
      rb.path('programId', params.programId, {});
      rb.path('chPatId', params.chPatId, {});
      rb.path('typeOfActivityToRollback', params.typeOfActivityToRollback, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AwvWorkflow>;
      })
    );
  }

  /**
   * Rollback a Workflow Activity.
   *
   * Rollsback a Workflow Activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rollbackWorkflowActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rollbackWorkflowActivity(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;

    /**
     * Unique identifier for the Activity rollback.
     */
    typeOfActivityToRollback: ActivityType;
  },
  context?: HttpContext

): Observable<AwvWorkflow> {

    return this.rollbackWorkflowActivity$Response(params,context).pipe(
      map((r: StrictHttpResponse<AwvWorkflow>) => r.body as AwvWorkflow)
    );
  }

  /**
   * Path part for operation getWorkflowHistoryPaged
   */
  static readonly GetWorkflowHistoryPagedPath = '/programs/{programId}/workflows/{chPatId}/history';

  /**
   * Gets the history of the given patient's workflow in pages.
   *
   * Gets the history of the given patient's workflow in pages.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkflowHistoryPaged()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkflowHistoryPaged$Response(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;
    pageParameters?: PageParameters;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AwvWorkflowHistoryPaged>> {

    const rb = new RequestBuilder(this.rootUrl, AwvService.GetWorkflowHistoryPagedPath, 'get');
    if (params) {
      rb.path('programId', params.programId, {});
      rb.path('chPatId', params.chPatId, {});
      rb.query('pageParameters', params.pageParameters, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AwvWorkflowHistoryPaged>;
      })
    );
  }

  /**
   * Gets the history of the given patient's workflow in pages.
   *
   * Gets the history of the given patient's workflow in pages.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkflowHistoryPaged$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkflowHistoryPaged(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;

    /**
     * Unique identifier for the patient.
     */
    chPatId: number;
    pageParameters?: PageParameters;
  },
  context?: HttpContext

): Observable<AwvWorkflowHistoryPaged> {

    return this.getWorkflowHistoryPaged$Response(params,context).pipe(
      map((r: StrictHttpResponse<AwvWorkflowHistoryPaged>) => r.body as AwvWorkflowHistoryPaged)
    );
  }

}
