import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module'
import { DirectivesModule } from './directives/directives.module'
import { CoachIconComponent } from './components/coach-icon/coach-icon.component';
import { CoachTabsComponent } from './components/coach-tabs/coach-tabs.component';
import { CoachToggleComponent } from './components/coach-toggle/coach-toggle.component';
import { EulaComponent } from './components/eula/eula.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CoachTogglePaneComponent } from './components/coach-toggle-pane/coach-toggle-pane.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { HeaderTitlePortalComponent } from './components/portals/header-title-portal/header-title-portal.component';
import { PortalModule } from '@angular/cdk/portal';
import { HeaderBreadcrumbsComponent } from '@shared/components/header-breadcrumbs/header-breadcrumbs.component';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { AgGridResizeDirective } from '@shared/directives/ag-grid-resize/ag-grid-resize.directive';
import { HierarchyRequiredComponent } from './components/hierarchy-required/hierarchy-required.component';
import { ColumnsSettingsComponent } from './components/grid/columns-settings/columns-settings/columns-settings.component';
import { SubmitUpcomingSubmissionSummary } from '../views/submit/shared/components/upcoming-submission-summary/upcoming-submission-summary';
import { TrackPerformanceWidgetComponent } from './components/track-performance-widget/track-performance-widget.component';
import { PhonePipe } from './pipes/phone.pipe';
import { SharedRedirectComponent } from './components/shared-redirect/shared-redirect.component';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { LoadingContainerComponent } from './components/loading-container/loading-container.component';
import { ExportReportButtonComponent } from './components/export-report-button/export-report-button.component';
import { CheckboxRendererComponent } from './components/grid/checkbox-renderer/checkbox-renderer.component';
import { CheckboxHeaderRendererComponent } from './components/grid/checkbox-header-renderer/checkbox-header-renderer.component';
import { InvalidInputComponent } from '@shared/components/form-validation/invalid-input/invalid-input.component';
import { AttributionFilterPaneComponent } from './components/attribution-filter-pane/attribution-filter-pane.component';
import { QualityService } from '@shared/services/quality-dashboard/quality.service';

const SharedModules = [
  CommonModule,
  RouterModule,
  PipesModule,
  FormsModule,
  ReactiveFormsModule,
  DirectivesModule,
  PortalModule,
  OverlayModule
];

// Only list components that are used frequently throughout the entire app.
// (Global components that are used throughout the app, but are not frequently used, import directly into component/module where it is used.)
const SharedComponents = [
  CoachIconComponent,
  CoachTabsComponent,
  CoachToggleComponent,
  CoachTogglePaneComponent,
  LoadingIndicatorComponent,
  HeaderTitlePortalComponent,
  EulaComponent,
  HeaderBreadcrumbsComponent,
  RouterLinkRendererComponent,
  HierarchyRequiredComponent,
  SubmitUpcomingSubmissionSummary,
  TrackPerformanceWidgetComponent,
  ButtonToggleComponent,
  LoadingContainerComponent,
  ExportReportButtonComponent,
  CheckboxRendererComponent,
  ColumnsSettingsComponent,
  SharedRedirectComponent,
  CheckboxHeaderRendererComponent,
  PhonePipe,
  InvalidInputComponent,
  AttributionFilterPaneComponent
];

@NgModule({
    declarations: [
        ...SharedComponents
    ],
    imports: [
        ...SharedModules
    ],
    exports: [
        ...SharedModules,
        ...SharedComponents
    ],
    providers: [
        QualityService
    ]
})
export class SharedModule { }
