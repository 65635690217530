import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LogAuditElementID } from '@shared/models/audit-constants';
import { RecentsElement } from '../recents-cache/recents-cache.service';
import { RouterHistoryService } from '../router-history/router-history.service';

export interface SearchDocument {
  path: string;
  title: string;
  phi: boolean;
  tier_node: string;
}

export interface PreviewResponse {
  uri: string;
  folder: number;
  file: string;
  tab: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileSearchService {
  public authToken: string;
  public headers: HttpHeaders;
  public results$: BehaviorSubject<SearchDocument[]>;
  public previewUrl$: BehaviorSubject<string>;
  tierNode: string;
  tier: number;

  constructor(
    private http: HttpClient,
    private tierService: HierarchyTierService,
    private authService: AuthService,
    private routerHistoryService: RouterHistoryService
  ) {
    this.results$ = new BehaviorSubject([]);
    this.previewUrl$ = new BehaviorSubject('');
    this.authToken = this.authService.getToken();
    this.headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.authToken}`
    );

    this.tierService.currentTier$.subscribe((tier) => {
      if (tier) {
        this.tier = tier.tier;
        this.tierNode = tier.selectedTierId;
      }
    });
  }

  search(query: string): void {
    this.http
      .post(
        environment.coachApiUrl + '/api/search/documents',
        {
          query,
          node: this.tierNode,
        },
        { headers: this.headers, responseType: 'json' }
      )
      .toPromise()
      .then((results: SearchDocument[]) => {
        if (results) {
          this.results$.next(results);
        } else {
          this.results$.next([]);
        }
      });
  }

  pushToRecents(response: PreviewResponse): void {
    const url = `${this.tierService.currentTierUrl}/improve/${response.tab}?folder=${response.folder}&file=${response.file}`;
    const elements: RecentsElement[] = [
      {
        id: LogAuditElementID.DocumentEntryId,
        value: response.file,
      },
    ];
    this.routerHistoryService.log({ url, elements });
  }

  changePreviewUrl(fullPath: string, tierNode: string): void {
    // empty string arg resets preview
    if (fullPath === '') {
      this.previewUrl$.next('');
    } else {
      // trim docrepo url
      const blobPath = fullPath.replace(environment.blobStorageUrl + '/', '');
      this.http
        .post(
          environment.coachApiUrl + '/api/search/preview',
          { blobPath, tierNode },
          { headers: this.headers, responseType: 'json' }
        )
        .subscribe((response: PreviewResponse) => {
          this.previewUrl$.next(response.uri);
          if (this.tier != 1) {
            this.pushToRecents(response);
          }
        });
    }
  }
}
