import { Component, OnDestroy, OnInit } from '@angular/core';
import { HccDashboardTierMetricsService } from '@track/track-clinical/hcc-dashboard/metrics/hcc-dashboard-tier-metrics.service';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { CoachTabs } from '@shared/components/coach-tabs/coach-tabs';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { HccDashboardFilterService } from './hcc-dashboard-filter.service';
import { AttributionFilterModel } from '@shared/components/attribution-filter-pane/attribution-filter-model';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { combineLatest } from 'rxjs';
import { deepCopy } from '@shared/utilities';

@Component({
  selector: 'coach-hcc-dashboard',
  templateUrl: './hcc-dashboard.component.html',
  host: {
    class: 'coach-hcc-dashboard'
  }
})
export class HccDashboardComponent extends KillSubscriptions implements OnInit, OnDestroy {
  hccEnhanced2FeatureFlag = TrackFeatureConstants.HccDashboardEnhanced2FeatureFlag;
  isDashboardRoute = false;
  canAccessPhi: boolean;

  private readonly CIN_NAME = 'CIN';

  public tabsData: CoachTabs[] = [
    {
      name: 'Overview',
      routerLink: ['overview'],
    },
    {
      name: 'Clinicians',
      routerLink: ['clinicians'],
    }
  ];

  formatValue = (value: any) => {
    const roundedValue = Math.round(value);
    return `${roundedValue}%`;
  }

  constructor(
    public hccDashboardTierMetricsService: HccDashboardTierMetricsService,
    private userAccessService: UserAccessService,
    private featureFlagService: FeatureFlagService,
    private hccDashboardFilterService: HccDashboardFilterService,
    private hierarchyTierService: HierarchyTierService,
    private router: Router) {

    super();

    // This is a temporary solution to account for some awkwardness of switching to nested views
    this.router.events.pipe(takeUntil(this.killTrigger)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.setRouteData(event.url);
      }
    });
  }

  ngOnInit(): void {
    this.setRouteData(this.router.url);

    const hasFeature = this.featureFlagService.hasFeatureFlag(this.hccEnhanced2FeatureFlag);

    if (hasFeature) {
      this.tabsData.splice(1, 0, {
        name: 'Practices',
        routerLink: ['practices'],
      });
    }

    this.userAccessService.currentAccess$.pipe(takeUntil(this.killTrigger)).subscribe(access => {
      this.canAccessPhi = access?.phi;

      if (this.canAccessPhi && !this.tabsData?.find(x => x.name === 'Patients')) {
        this.tabsData.push({
          name: 'Patients',
          routerLink: ['patients'],
        });
      }
    });

    combineLatest([this.hierarchyTierService.currentTier$, this.hccDashboardFilterService.filterModel$]).pipe(
      takeUntil(this.killTrigger),
      filter(x => x[0] != null)).subscribe(x => {
        const [tierInfo, filterValue] = x;

        const cinAdded = this.tabsData.some(x => x.name === this.CIN_NAME);

        if (tierInfo.selectedItem.cinFlag === 1 && filterValue?.isCinSelected && filterValue?.selectedCin != null) {
          if (!cinAdded) {
            this.tabsData.splice(1, 0, {
              name: this.CIN_NAME,
              routerLink: ['cins'],
            });
            this.tabsData = deepCopy(this.tabsData);
          }
        } else {
          const index = this.tabsData.findIndex(x => x.name === this.CIN_NAME);
          if (index > -1) {
            this.tabsData.splice(index, 1);
            this.tabsData = deepCopy(this.tabsData);
          }
        }
      });
  }

  setRouteData(url: string): void {
    const urlSegments = url.split('/');
    const path = urlSegments[urlSegments.length - 1];
    this.isDashboardRoute = path === 'overview' || path === 'dashboard';
  }

  filterModelChange(model: AttributionFilterModel): void {
    this.hccDashboardFilterService.setFilterModel(model);
  }
}
