import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Patient } from '@shared/models/patient';
import { HccHealthMaintenanceDetails } from '@shared/models/hcc-care-gaps';
import { forkJoin } from 'rxjs';
import moment from 'moment';
import { PatientService } from '@shared/services/patient/patient.service';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { ErrorHandlerService } from '@shared/services/logging/error-handler.service'


@Component({
  selector: 'coach-patient-health-maintenance',
  templateUrl: './patient-health-maintenance.component.html',
  styleUrls: ['./patient-health-maintenance.component.scss']
})

export class PatientHealthMaintenanceComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;

  public loading = true;

  public maintenance: any = {};
  public maintenanceDetailsByDateDesc: any[] = [];

  acpCategory = 'ACP';
  awvCategory = 'AWV';
  ccmCategory = 'CCM';

  acpDetails: HccHealthMaintenanceDetails;
  awvDetails: HccHealthMaintenanceDetails;
  ccmDetails: HccHealthMaintenanceDetails;
  today: moment.Moment;

  constructor(
    private _patientService: PatientService, private toastrMessageService: ToastrMessageService, private errorHandlerService: ErrorHandlerService
  ) {
    this.today = moment();
  }

  ngOnInit(): void {
    if (this.patient) this.loading = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadHealthData();
  }

  private OnDataReceived() {
    this.getDetails();
  }

  private getDetails() {
    // ACP
    this.acpDetails = this.maintenanceDetailsByDateDesc.find(
      (y) => y.category == this.acpCategory
    );
    // AWV
    this.awvDetails = this.maintenanceDetailsByDateDesc.find(
      (y) => y.category == this.awvCategory
    );
    
    // CCM
    this.ccmDetails = this.maintenanceDetailsByDateDesc.find(
      (y) => y.category == this.ccmCategory
    );
  }

  private loadHealthData() {
    if (this.patient) {
      if (this.patient?.trackPatient?.chPatId) {
        forkJoin({
          reqHealthMaintenance: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'healthMaintenance'
          ),
          reqHealthMaintenanceDetails:
            this._patientService.getTrackPatientFacesheet(
              this.patient.trackPatient.chPatId,
              'healthMaintenanceDetails'
            ),
        }).subscribe(
          ({
            reqHealthMaintenance,
            reqHealthMaintenanceDetails,

          }) => {
            this.maintenance = reqHealthMaintenance || {};
            // sort  details by date so the most recent is first, getDetails() will use the first matching items in the list
            this.maintenanceDetailsByDateDesc = reqHealthMaintenanceDetails?.sort((a, b) => moment(b.lastDate).diff(moment(a.lastDate))) || [];
          },
          (errors) => {
            this.toastrMessageService.error(errors)
            this.errorHandlerService.handleError(errors)
          },
          () => {
            this.OnDataReceived();
          }
        );
      }
    }
  } 
  getDateDifference(date: string): string {
    let result = ''
    var diffDuration = moment.duration(moment().diff(date));
    let years = diffDuration.years()
    let months = diffDuration.months()
    let days = diffDuration.days() 
    if (years) {
      result += `${years}y`
    }
    if (months) {
      result += `${result.length > 0 ? ' ' : ''}${months}m`
    }
    if (!years && !months && days) {
      result = "<1mo"
    }
    return result.length ? result + " ago" : "No Record Found";
  };
  public getDaysDifference(date: string): boolean {
    let years =
      this.today.diff(date, 'years', false) > 0
        ? `${this.today.diff(date, 'years', false)}yr`
        : '';
    return (years.length >= 1);
  }

  public getFormattedName(name: string): string {
    if (name.indexOf(',') != -1) {
      let segments = name.split(',');
      let reformattedName = segments[1] + ' ' + segments[0];
      return this.toTitleCase(reformattedName.trim());
    }
    else {
      return this.toTitleCase(name);
    }
  }
  toTitleCase(str: string): string {
    if(!str) return '';
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  getCodeAndProviderNameAsString(code: string, providerName: string): string {
    if (!code && !providerName) return '';
    return (code ? `${code} - ` : '') + (providerName ? this.getFormattedName(providerName) : 'unknown') + ', ';
  }
}
