<button class="pullTab top--xs disable-rotate" (click)="togglePatientFilters()"
  [ngClass]="{'is-open': !isPatientFiltersOpen}" aria-label="Toggle filter panel">
  <coach-icon *ngIf = "isPatientFiltersOpen" iconName="chevrons-left"></coach-icon>
  <coach-icon *ngIf = "!isPatientFiltersOpen" iconName="chevrons-right"></coach-icon>
  
</button>
<div class="header">
  <div *hasFeatureFlag="PatientListsUpdateFlag" class="title u-display--flex u-flex-direction--row u-justify-content--spaceBetween padding--none u-width--full">
    <div class="title title-tooltip-wrapper">Patient Lists</div>
    <div class="tooltip-wrapper">
      <button class="button is-small is-icon border--none has-tooltip" (click)="openNewFilterDrawer()">
        <span class="tooltip" id = "care-filter-tooltip">Create a custom patient list</span>
        <coach-icon class="transition-fix" iconName="plus" iconColor="currentColor">
        </coach-icon>
      </button>
    </div>
  </div>
  <div class="titles" *doesNotHaveFeatureFlag="PatientListsUpdateFlag" >
    <div class="title">Filters</div>
  </div>
</div>
<div *hasFeatureFlag="PatientListsUpdateFlag" class="tabs--fullView tabs tabs--secondary u-display--flex u-flex-direction--row">
  <div class="tab" [ngClass]="{'is-active': activeTab == 'standard', 'u-disabled': isDrillInOpen}" (click)="switchListsActiveTab('standard')">Standard</div>
  <div class="tab" [ngClass]="{'is-active': activeTab == 'custom', 'u-disabled': isDrillInOpen}" (click)="switchListsActiveTab('custom')">Custom</div>
</div>
<div class="column u-fill u-position--relative">
  <div class="dynamicBody">       
    <ng-container *ngFor="let presetGroup of allPresetGroups">    
      <!-- (isPatientListsUpdateFlagEnabled ? (activeTab == 'custom' && presetGroup.category == 'Custom list' ? true : false ) : true) --> 

      <ng-container *ngIf= "(activeTab == 'custom' ? true : false) && (presetGroup.category == 'Custom list') && !(presetGroup.presets?.length > 0)" >
        <div class="row" style="padding-left:5%; font-size: 16px; padding-top: 10%;">
           <p> <b> No custom patient lists created </b> </p> 
        </div>
      </ng-container> 

      <ng-container *ngIf="!presetGroup.hideGroup && (isPatientListsUpdateFlagEnabled ? ((activeTab == 'standard' && presetGroup.category !== 'Custom list') || (activeTab == 'custom' && presetGroup.category == 'Custom list')) : true )">
        <div class="subheader">{{presetGroup.category}}</div>
        <div class="list">
          <ng-container *ngFor="let preset of presetGroup.presets">
            <div *ngIf="!preset.hidePreset" class="list-item list-item--complex formatting--none" [ngClass]="{'is-active': preset.name == selectedPreset?.name || preset.label == selectedPreset?.label}"
              (click)="onSelectPreset(preset)">
              <div class="u-align-items--top list-item-main" [ngClass]="{'u-progress u-opacity--lighter': loadingStatus}">
                <div class="row">
                  <div class="field margin-right--small">{{preset.label}}</div> <div *ngIf="preset.alerts" class="badge">{{preset.alerts > 99 ? "99+" : preset.alerts}}</div>
                </div>
              </div>
              <div *ngIf="preset.filterComponent != null || preset.filterSettings" class="list-item-action u-cursor--pointer"
                [ngClass]="{'u-progress u-opacity--lighter': loadingStatus}" (click)="toggleDrillInOpen(preset)">
                <coach-icon class="transition-fix" iconName="filter" iconColor="currentColor">
                </coach-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="drillIn--queue" [ngClass]="{'is-open': isDrillInOpen}">
    <div class="header u-align-items--center">
      <button class="button is-small is-icon border--none" (click)="toggleDrillInOpen()">
        <coach-icon iconName="chevron-left" iconColor="default"></coach-icon>
      </button>
      <div class="titles t5 padding--none" *hasFeatureFlag="PatientListsUpdateFlag" >
        <div class="field margin-right--smallest" *ngIf="!presetNameEditorToggle && !isNewFilterDrawerOpen">{{selectedPreset?.label}}</div>
        <input *ngIf="isNewFilterDrawerOpen && newPreset" [ngClass]="{'invalid': filterNameIsAlreadyUsed(newPreset.newName) || newPreset.newName.length < 1}" [(ngModel)]="newPreset.newName" placeholder="Filter name" type="text">
        <input *ngIf="presetNameEditorToggle" [ngClass]="{'invalid': filterNameIsAlreadyUsed(selectedPreset.newName) || selectedPreset.newName.length < 1}" [(ngModel)]="selectedPreset.newName" placeholder="Filter name" type="text">
      </div>
      <div class="titles t5" *doesNotHaveFeatureFlag="PatientListsUpdateFlag" >
        <div class="field margin-right--smallest">{{selectedPreset?.label}}</div>
      </div>
      <button class="button is-small is-icon border--none" (click)="presetNameEditorToggle = true" *ngIf="isPatientListsUpdateFlagEnabled && !isNewFilterDrawerOpen && selectedPreset?.origin && !presetNameEditorToggle">
        <coach-icon iconName="edit" iconColor="currentColor"></coach-icon>
      </button>
    </div>
    <div class="dynamicBody padding">
      <div *ngFor="let selectedFilter of selectedFilters" class="padding-bottom">
        <label for="{{selectedFilter.label | removeWhitespace}}" class="label">{{selectedFilter.label}}</label>
        <select name="{{selectedFilter.label | removeWhitespace}}" id="{{selectedFilter.label | removeWhitespace}}">
          <option *ngFor="let value of selectedFilter.values" value="value">{{value}}</option>
        </select>
      </div>
      <ng-template #customFilters> </ng-template>
      <coach-care-patients-filters-settings *ngIf="selectedPreset && selectedPreset.filterSettings && isDrillInOpen && !isNewFilterDrawerOpen"
        [filterName]="selectedPreset.presetKey"
        [filterNewName]="selectedPreset.newName ? selectedPreset.newName : (selectedPreset.origin ? null : (selectedPreset.presetKey))"
        [loadingStatus]="loadingStatus" 
        [versionKey]="selectedPreset.versionKey"
        [selectedPresetSettings]="selectedPresetSettings" 
        [category]="selectedPreset.category"
        [defaultFilterModel]="selectedPreset.defaultFilterModel"
        [origin]="selectedPreset.origin"
        [filterNames]="getUsedFilterNames()" 
        (updatedFilterSettings)="updateFilterSettings($event)"
        (newFilterSettings)="addCustomFilterPreset($event)"
        (updateFilterName)="renameCustomFilterPreset($event)"
        (deleteCustomFilterPreset)="deleteCustomFilterPreset()"
        [filterNameIsAlreadyUsed]="filterNameIsAlreadyUsed(selectedPreset.newName)">
      </coach-care-patients-filters-settings>
      <!-- NEW FILTER PRESET FORM -->
      <coach-care-patients-filters-settings *ngIf="isDrillInOpen && isNewFilterDrawerOpen && isPatientListsUpdateFlagEnabled"
        [filterName]="newPreset.label"
        [filterNewName]="newPreset.newName"
        [versionKey]="newPreset.versionKey"
        [loadingStatus]="loadingStatus" 
        [selectedPresetSettings]="newPreset.filterSettings" 
        [category]="newPreset.category"
        [origin]="newPreset.origin"
        [filterNames]="getUsedFilterNames()" 
        [isNewFilter]="true" 
        (updatedFilterSettings)="updateFilterSettings($event)"
        (newFilterSettings)="addCustomFilterPreset($event)"
        (deleteCustomFilterPreset)="deleteCustomFilterPreset()"
        [filterNameIsAlreadyUsed]="filterNameIsAlreadyUsed(newPreset.newName)">
      </coach-care-patients-filters-settings>
    </div>
  </div>
</div>
