import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * A button toggle component that displays a list of selectable options.
 * 
 * @example
 * <coach-button-toggle [options]="['Option 1', 'Option 2']" [(selectedItem)]="selectedItem">
 * </coach-button-toggle>
 */
@Component({
  selector: 'coach-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent {

  private _options: string[];
  /** Array of selectable options as strings */
  @Input() set options(value: string[]) {
    this._options = value;

    if (this.selectedItem == null && this._options.length > 0) {
      this.selectedItem = this._options[0];
    }
  }
  get options(): string[] {
    return this._options;
  }

  /** Currently selected option */
  @Input()  selectedItem: string;
  @Output() selectedItemChange: EventEmitter<string> = new EventEmitter();
  
  public selectedValue = '';

  constructor() { }

  selectOption(option: string): void {
    this.selectedItem = option;
    this.selectedItemChange.emit(this.selectedItem);
  }

}
