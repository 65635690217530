<div #chartContainer></div>
<div class="row" [ngClass]="{
  'chart-headers u-preserve': patientFacesheetSize == 'desktop',
  'patientChart-header padding-topBottom--small padding-leftRight--large u-background-color--white': patientFacesheetSize == 'tablet',
  'u-display--flex u-flex-direction--row u-flex-wrap--wrap u-justify-content--spaceAround u-align-items--center padding': patientFacesheetSize == 'mobile'
}" *ngIf="patient">
  <!--
    EXPANDED size > 1175px
    CONDENSED size >= 921px AND <= 1175px
    MOBILE size < 921px
   -->

  <!-- EXPANDED DEMOGRAPHICS -->
  <div class="u-display--flex u-fill padding-topBottom padding-leftRight--large"
    *ngIf="patientFacesheetSize == 'desktop'">
    <div class="profile-photo square--smaller avi">
      <img alt="patient photo" src="{{patient.avatarUrl()}}" *ngIf="patient.avatarUrl()">
      <ng-container *ngIf="!patient.avatarUrl()">{{ patient.displayInitials() | initialsFromName }}</ng-container>
    </div>
    <div class="u-display--flex u-justify-content--spaceBetween u-fill">
      <div class="titles t2 margin-leftRight--small u-fill border-right" style="width: 50%">
        <div class="title text-break white-space--normal" id="patientDisplayNameExpanded">
          {{patient.displayName()}}
        </div>
        <div class="subtitle" style="word-break: break-word">
          {{patient.displayAge()}} {{patient.displayGender()}} | {{patient.displayDob()}} | {{
          patient.displayIdentifier() }}<span *ngIf="patient.mbi"> |
            MBI: {{patient.mbi | mask: 6 }}
          </span>
        </div>

        <div class="subtitle">
          {{ titleLine([patient.displayPhone(), patient.email]) }}
        </div>
        <div *ngIf="patient.dod" class="subtitle">
          {{ patient.displayDod() }}
        </div>
        <div *ngIf="!patient.trackPatient" class="field u-color--brand--3">
          Not attributed
        </div>
      </div>
      <div class="u-display--flex u-justify-content--spaceBetween" style="width: 50%">
        <div class="u-flex-direction--row">
          <div class="group margin--smaller subtitle" *ngIf="patient.tier5_Name || patient.tier4_Name">
            <div>{{displayPatientProviderAssignment}}</div>
            <div class="u-text-transform--capitalize u-bold">{{ displayProviderName }}</div>
          </div>
          <div class="group margin--smaller subtitle" *ngIf="displayRiskLevel">
            <div> Projected Risk </div>
            <div class="field u-display--flex">
              <span class="u-bold">{{ patient?.trackPatient?.predictedRiskLevel}}
              </span>
              <!-- temporarily dusabled CC-2347 -->
              <span *ngIf="false" class="iconImg">
                <!--src="assets/icons/noun_uptrend_1959567.svg"-->
                <img *ngIf="patient?.trackPatient?.riskTrend == 'Increasing'" src="assets/icons/whiteRedUptrend.svg">
                <img *ngIf="patient?.trackPatient?.riskTrend == 'Decreasing'" src="assets/icons/whiteGreenDwntrend.svg">
                <img *ngIf="patient?.trackPatient?.riskTrend == 'Same'" src="assets/icons/whiteBlueNotrend.svg">
              </span>
            </div>
          </div>
          <div class="group margin--smaller subtitle" *ngIf="patient.tier5_Name || patient.tier4_Name">
            <div>Enrollment Status</div>
            <div class="u-text-transform--capitalize u-bold">{{ displayEnrollmentType }}</div>
          </div>

        </div>
        <div class="actionButtonWrapper" *ngIf="patientFacesheetSize == 'desktop'">
          <button class="button is-small" (click)="openScopeSelectDropdown()" #scopeSelectOrigin>Actions</button>
        </div>
      </div>
    </div>
  </div>

  <!-- CONDENSED DEMOGRAPHICS -->


  <div class="u-display--flex u-fill"
    *ngIf="patientFacesheetSize == 'tablet'">
    <div class="profile-photo square--smaller avi">
      <img alt="patient photo" src="{{patient.avatarUrl()}}" *ngIf="patient.avatarUrl()">
      <ng-container *ngIf="!patient.avatarUrl()">{{ patient.displayInitials() | initialsFromName }}</ng-container>
    </div>
    <div class="u-display--flex u-justify-content--spaceBetween u-fill">
      <div class="titles t4 margin-leftRight--small u-fill border-right" style="width: 50%">
        <div class="text-break white-space--normal" id="patientDisplayNameExpanded">
          {{patient.displayName()}}
        </div>
        <div class="subtitle text-break white-space--normal">
          {{patient.displayAge()}} {{patient.displayGender()}} | {{patient.displayDob()}}
          <span *ngIf="patient.displayIdentifier()">| {{
            patient.displayIdentifier() }}
          </span>
        </div>
        <div class="subtitle text-break white-space--normal" *ngIf="patient.mbi">
          MBI: {{patient.mbi | mask: 6 }}
        </div>

        <div class="subtitle text-break white-space--normal">
          {{ patient.displayPhone() }}
        </div>
        <div class="subtitle text-break white-space--normal">
          {{ patient.email }}
        </div>
        <div *ngIf="patient.dod" class="subtitle text-break white-space--normal">
          {{ patient.displayDod() }}
        </div>
        <div *ngIf="!patient.trackPatient" class="text-break white-space--normal field u-color--brand--3">
          Not attributed
        </div>
      </div>
      <div class="u-display--flex u-justify-content--spaceBetween" style="width: 50%">
        <div class="u-flex-direction--row">
          <div class="group margin--smaller subtitle" *ngIf="patient.tier5_Name || patient.tier4_Name">
            <div>{{displayPatientProviderAssignment}}</div>
            <div class="u-text-transform--capitalize u-bold">{{ displayProviderName }}</div>
          </div>
          <div class="group margin--smaller subtitle" *ngIf="displayRiskLevel">
            <div> Projected Risk </div>
            <div class="field u-display--flex">
              <span class="u-bold">{{ patient?.trackPatient?.predictedRiskLevel}}
              </span>
              <!-- temporarily dusabled CC-2347 -->
              <span *ngIf="false" class="iconImg">
                <!--src="assets/icons/noun_uptrend_1959567.svg"-->
                <img *ngIf="patient?.trackPatient?.riskTrend == 'Increasing'" src="assets/icons/whiteRedUptrend.svg">
                <img *ngIf="patient?.trackPatient?.riskTrend == 'Decreasing'" src="assets/icons/whiteGreenDwntrend.svg">
                <img *ngIf="patient?.trackPatient?.riskTrend == 'Same'" src="assets/icons/whiteBlueNotrend.svg">
              </span>
            </div>
          </div>
          <div class="group margin--smaller subtitle" *ngIf="patient.tier5_Name || patient.tier4_Name">
            <div>Enrollment Status</div>
            <div class="u-text-transform--capitalize u-bold">{{ displayEnrollmentType }}</div>
          </div>
        </div>
        <div class="actionButtonWrapper">
          <button class="button is-small" (click)="openScopeSelectDropdown()" #scopeSelectOrigin>Actions</button>
        </div>
      </div>
    </div>
  </div>


  <!-- MOBILE DEMOGRAPHICS -->


  <div *ngIf="patientFacesheetSize == 'mobile'"
    class="chart-header-patient u-display--flex u-align-items--center facesheet-demographics-top--mobile">
    <div class="profile-photo circle--smallest avi u-border-radius--rounded u-width--full u-height--full">
      <img alt="patient photo" src="{{patient.avatarUrl()}}" *ngIf="patient.avatarUrl()">
      <ng-container *ngIf="!patient.avatarUrl()">{{ patient.displayInitials() | initialsFromName }}</ng-container>
    </div>
  </div>

  <!-- MOBILE ACTION BUTTON -->
  <button *ngIf="patientFacesheetSize == 'mobile'" class="button" (click)="openScopeSelectDropdown()"
    #scopeSelectOrigin>Actions</button>

  <!-- TIMETRACKING -->
  <ng-container *hasFeature="careFeatureConstants.PatientsFeature">
    <div class="column" [ngClass]="{
        'u-width--large border-left': patientFacesheetSize == 'desktop',
        'u-width--smaller u-display--flex u-justify-content--center': patientFacesheetSize != 'desktop'
      }" *ngIf='!patient.hideTabsAndDisableTimeTracking'>
      <coach-patient-time-tracking [desktopSize]="patientFacesheetSize == 'desktop'" [patient]="patient" [tier]="tier">
      </coach-patient-time-tracking>
    </div>
  </ng-container>


</div>
<!-- MOBILE DEMOGRAPHICS 2 -->
<div *ngIf="patientFacesheetSize == 'mobile'"
  class="u-text-align--center t3 padding-leftRight--smallest padding-bottom--small">
  <div class="title" id="patientDisplayNameCondensed">
    {{patient.displayName()}}
  </div>
  <div class="u-display--flex u-flex-direction--row u-flex-wrap--wrap u-justify-content--center">
    <div class="u-breakText--all u-font-size--small margin-leftRight--smaller">{{patient.displayAge()}} {{patient.displayGender()}}</div>
    <div class="u-breakText--all u-font-size--small margin-leftRight--smaller">{{patient.displayDob()}} </div>
    <div class="u-breakText--all u-font-size--small margin-leftRight--smaller" *ngIf="patient.mbi">MBI: {{patient.mbi}}
    </div>
    <div class="u-breakText--all u-font-size--small margin-leftRight--smaller"
      *ngIf="patient.displayIdentifier() && patient.displayIdentifier().length > 0">{{patient.displayIdentifier()}}
    </div>
    <div class="u-breakText--all u-font-size--small margin-leftRight--smaller">{{patient.displayPhone()}} </div>
    <div class="u-breakText--all u-font-size--small margin-leftRight--smaller">{{patient.email}} </div>
    <div class="u-breakText--all u-font-size--small margin-leftRight--smaller" *ngIf="patient.dod">{{
      patient.displayDod() }}</div>
    <div *ngIf="!patient.trackPatient" class="field u-color--brand--3">
      Not attributed
    </div>
  </div>
  <p class="u-breakText--all margin-bottom--none u-font-size--small" *ngIf="patient.tier5_Name || patient.tier4_Name">
    {{displayPatientProviderAssignment}}: <span class="u-text-transform--capitalize u-bold">{{ displayProviderName }}</span>
  </p>
  <p class="u-breakText--all margin-bottom--none u-font-size--small" *ngIf="displayRiskLevel">
    Projected Risk: <span class="u-text-transform--capitalize u-bold">{{ patient?.trackPatient?.predictedRiskLevel }}</span>
  </p>
  <p class="u-breakText--all margin-bottom--none u-font-size--small" *ngIf="patient.tier5_Name || patient.tier4_Name">
    Enrollment Status <span class="u-text-transform--capitalize u-bold">{{ displayEnrollmentType }}</span>
  </p>

</div>
<!-- MODAL -->
<ng-container *ngIf="modalStatus.isOpen">
  <div class="overlay is-open">
    <div class="modal patientInviteModal u-width--larger">
      <coach-single-patient-invite-form *ngIf="modalStatus.target == 'modalPatientInviteForm'" (closeForm)="closeModal()"
        [patient]="patient" [tier]="tier"></coach-single-patient-invite-form>
      <coach-patient-edit-form *ngIf="modalStatus.target == 'modalPatientEditForm'" (closeForm)="closeModal()"
        [patient]="patient" [tier]="tier"></coach-patient-edit-form>
    </div>
  </div>
</ng-container>
