import { faDoorClosed } from '@fortawesome/free-solid-svg-icons';
import {
  ComplianceFormQuestionSection,
  ComplianceFormAnswer,
} from '../models/forms/compliance-form-question';
import { AppConstants } from '@shared/models/constants/app-constants';

export const COMPLIANCE_FORM_QUESTION_SECTIONS: ComplianceFormQuestionSection[] = [
  {
    id: 1,
    name: 'General Compliance Oversight',
    questions: [
      {
        code: '42 CFR 425.300a1',
        questionId: '15',
        number: '1.1',
        infoText:
        'If your organization has one or more ACO compliance contacts on file with us, the answer to this should be ‘yes.’',
        questionText:
          'Does your organization have a designated employee(s) who is responsible for ensuring compliance with ACO requirements? ',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.300a3',
        questionId: '16',
        number: '1.2',
        infoText:
          'To respond \'yes\', your organization must have an organizational compliance hotline available to report general compliance concerns. Use of the CVS Ethics Line should be limited to reporting concerns that involve the ACO.',
        questionText:
          'Does your organization provide an anonymous reporting mechanism (i.e. compliance hotline) for individuals to report potential compliance issues?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.300a3',
        questionId: '17',
        number: '1.3',
        infoText:
          'The CVS Ethics Line can be reached at by phone at 1-877-287-2040, via the Web at www.cvshealth.com/ethicsline, or via email at EthicsBusinessConduct @CVSHealth.com. Please select \'yes\' once you have taken note of this information.',
        questionText:
          'Does your Compliance Officer know how to get in touch with the ACO Compliance Officer to report issues involving the ACO or ACO operations?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Policy & Procedure',
    questions: [
      {
        code: '42 CFR 425.208b',
        questionId: '18',
        number: '2.1.1',
        infoText:
        'Policy templates are avaiable in Coach.  Your organization may also utilize its own policies that cover these requirements. Each of the federal laws must be referenced in your organization\'s policies.',
        questionText:
          'Does your organization have policies in place that require your organization to comply with the Federal Criminal Law?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.208b | 13 U.S.C. 3729 et seq.',
        questionId: '19',
        number: '2.1.2',
        infoText:
        'Policy templates are avaiable in Coach.  Your organization may also utilize its own policies that cover these requirements. Each of the federal laws must be referenced in your organization\'s policies.',
        questionText:
          'Does your organization have policies in place that require your organization to comply with The False Claims Act?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.208b | 42 U.S.C. 1320a-7b(b)',
        questionId: '20',
        number: '2.1.3',
        infoText:
        'Policy templates are avaiable in Coach.  Your organization may also utilize its own policies that cover these requirements. Each of the federal laws must be referenced in your organization\'s policies.',
        questionText:
          'Does your organization have policies in place that require your organization to comply with The Anti-Kickback Statute?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.208b | 42 U.S.C. 1320a-7a',
        questionId: '21',
        number: '2.1.4',
        infoText:
        'Policy templates are avaiable in Coach.  Your organization may also utilize its own policies that cover these requirements. Each of the federal laws must be referenced in your organization\'s policies.',
        questionText:
          'Does your organization have policies in place that require your organization to comply with The Civil Monetary Penalties law?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.208b | 42 U.S.C. 1395nn',
        questionId: '22',
        number: '2.1.5',
        infoText:
        'Policy templates are avaiable in Coach.  Your organization may also utilize its own policies that cover these requirements. Each of the federal laws must be referenced in your organization\'s policies.',
        questionText:
          'Does your organization have policies in place that require your organization to comply with The Physician Self-Referral law?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.304a',
        questionId: '23',
        number: '2.2',
        infoText:
        'Note that a general patient inducement policy must specifically mention the prohibition on inducing beneficiaries to remain with the ACO. A model policy template is available in Coach. Your organization may also develop its own general beneficiary inducement policy that must include the ACO requirements.',
        questionText:
          'Does your organization have a policy in place prohibiting the giving of gifts or other remuneration to induce beneficiaries to receive certain items or services or to remain with the ACO?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.314',
        questionId: '24',
        number: '2.3',
        infoText:
          'This requirement may be satisfied either with a general policy on record retention that specifically addresses ACO requirements or by adopting the model policy that is avaialble in Coach. If using a general record retention policy, please note the Shared Savings Program required retention period is longer than most other record retention periods at ten years from the date of exit from the program. Additional requirements also apply.',
        questionText:
          'Does your organization have policies in place to ensure ACO related records are retained as required per 42 CFR 425.314?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        requiredForQuestions: [
          {
            subQuestionId: '79',
            subQuestionReqResp: ComplianceFormAnswer.yes
          }
        ],
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        questionId: '79',
        number: '2.3A',
        questionText:
          'If yes, please upload a copy of your Record Retention policy here.',
        answer: ComplianceFormAnswer.no,
        disabled: true,
        fileUpload: true,
        standardQuestion: false,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.any,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.disabled,
            pointValue: 0,
          }
        ],
      },

    ],
  },
  {
    id: 3,
    name: 'Beneficiary Communications (Marketing & Notices)',
    questions: [
      {
        code: '42 CFR 425.312',
        questionId: '26',
        number: '3.1',
        infoText:
        'The CMS poster is required to be posted in all ACO facilities.  Generally, the most visible location to post the poster is in patient waiting areas.  Please note that for 2024, CMS has two versions of the poster available. Please answer \'yes\' to confirm your organization is using the correct version of the poster.',
        questionText:
          'Has your organization posted the correct 2024 CMS poster in all ACO facilities? ',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.312',
        questionId: '27',
        number: '3.2',
        infoText:
          'The Beneficary Information Notification is a separate document from the ACO Poster. The Notice must be provided to all traditional (fee-for-service) Medicare beneficaries who receive a primary care service from your organization. Benficiaries need only receive the Notice once per ACO contract cycle. Snail mail, email, patient portal, or point are care, in any combination, are all acceptable means of dissemination. Distribution of the Notice must be maintained for audit purposes.',
        questionText:
          'Does your organization have a process in place to ensure Medicare beneficiaries receive the Beneficiary Information Notification at or prior to their first primary care visit of the ACO contract cycle?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.312',
        questionId: '28',
        number: '3.3',
        infoText:
        'Beneficiaries must be provided with a follow-up communication at their next primary care visit or within 180 days of sending the Notice, whichever comes first. This notification is intended to afford the ACO the opportunity to have a meaningful dialogue with a beneficary about the benefits of receiving care in an ACO. Note that sending the Notice a second time does NOT meet the follow-up communication requirements.',
        questionText:
          'Does your organization have a process in place to meet the notification follow up requirements?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        requiredForQuestions: [
          {
            subQuestionId: '73',
            subQuestionReqResp: ComplianceFormAnswer.yes
          }
        ],
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        questionId: '73',
        number: '3.3A',
        questionText:
          'If yes, how is your organization conducting follow-up to the Notice?',
        answer: ComplianceFormAnswer.no,
        textResponseOnly: true,
        disabled: true,
        standardQuestion: false,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.any,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.disabled,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.312',
        questionId: '29',
        number: '3.4',
        infoText:
          'The Notice must be available to beneficaries upon request in all ACO primary care settings.',
        questionText:
          'Does your organization make the Beneficiary Information Notification available in primary care settings upon request (i.e. on display or behind the desk with staff who are knowledgeable about the ACO)?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Compliance Training',
    questions: [
      {
        code: '42 CFR 425.300a4',
        questionId: '30',
        number: '4.1',
        infoText:
          'All Participant compliance contacts must complete at least one ACO compliance training module via Learn in Coach by August of each performance year to meet this annual requirement. If you are unsure whether or not your organization has participated in an eligible ACO compliance training, please reach out to aco-compliance@signifyhealth.com and we can confirm whether or not this requirement has been met.',
        questionText:
          'Has your organization\'s ACO compliance contact completed an ACO compliance training in Coach via the Learn application within the past twelve months?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.208a',
        questionId: '31',
        number: '4.2.1',
        infoText:
          'ACO compliance training for your staff, executive decision makers and providers may be incorporated into general compliance training.  Signify Health conducts compliance updates and training for the ACO Board.  Question 4.2.1 refers to the Board of your organization.',
        questionText:
          'Do the Staff members and Executive Decision Makers (C-Suite, governing body members, organizational board members) at your organization receive compliance training at least annually that includes requirements related to ACO participation?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },

      {
        code: '42 CFR 425.208a',
        questionId: '32',
        number: '4.2.2',
        infoText:
          'Compliance training for providers should include all providers billing under the ACO TIN, whether or not they are employed, contracted, part-time, per diem, etc. Sample training materials for ACO providers are available in Coach.',
        questionText:
          'Do the Providers billing under your organization\'s TIN (all) at your organization receive compliance training at least annually that includes requirements related to ACO participation?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'Provider Oversight',
    questions: [
      {
        code: '42 CFR 425.210b',
        questionId: '34',
        number: '5.1',
        infoText:
          'All ACO providers (providers that bill under an ACO TIN) must receive a notification that includes a statement that they are required to comply with all Shared Savings Program rules and regulations. Notifying only your employed physicians will not satisfy this requirement.',
        questionText:
          'Does your organization include a provision in all contracts or arrangements with providers billing under your TIN that requires compliance with Shared Savings Program rules and regulations? Note: This can be satisfied via provider notification, contract amendment, through provider onboarding, etc.',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.210a',
        questionId: '35',
        number: '5.2',
        infoText:
          'The Participation Agreement is the legal contract signed between your organization and CMS upon entering the Shared Savings Program. If you do not have a copy of your Participation Agreement you may reach out to a member of your regional team or email aco-compliance@signifyhealth.com. You should keep a copy of your Participation Agreement on file to share with any providers who request it.',
        questionText:
          'Has your organization made a copy of the ACO participation agreement available to all providers billing under your organization\'s TIN?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },

      {
        code: '42 CFR 425.118c',
        questionId: '36',
        number: '5.3',
        infoText:
          'Note that submitting provider additions/deletions to Signify Health does not satisfy this requirement as Signify Health does not have the authority to access or modify PECOS.',
        questionText:
          'Does your organization have a process in place to submit additions and deletions to your PECOS provider list within 30 days of the effective change?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.304b',
        questionId: '37',
        number: '5.4.1',
        infoText:
          'All employees, execuitives, governing body members, and providers billing under your TIN(s) should be included in periodic screening against the OIG\'s List of Excluded Individuals and Entities.',
        questionText:
          'Does your organization conduct periodic (at minimum, annual) screenings of Employees against the OIG\'s List of Excluded Individuals and Entities?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        requiredForQuestions: [
          {
            subQuestionId: '81',
            subQuestionReqResp: ComplianceFormAnswer.yes
          }
        ],
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        questionId: '81',
        number: '5.4.1A',
        questionText:
          'If yes, approximately how frequently are the exclusion screenings performed?',
        answer: ComplianceFormAnswer.no,
        textResponseOnly: true,
        disabled: true,
        standardQuestion: false,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.any,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.disabled,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.304b',
        questionId: '38',
        number: '5.4.2',
        infoText:
          'All employees, execuitives, governing body members, and providers billing under your TIN(s) should be included in periodic screening against the OIG\'s List of Excluded Individuals and Entities.',
        questionText:
          'Does your organization conduct periodic (at minimum, annual) screenings of Executives against the OIG\'s List of Excluded Individuals and Entities?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        requiredForQuestions: [
          {
            subQuestionId: '84',
            subQuestionReqResp: ComplianceFormAnswer.yes
          }
        ],
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        questionId: '84',
        number: '5.4.2A',
        questionText:
          'If yes, approximately how frequently are the exclusion screenings performed?',
        answer: ComplianceFormAnswer.no,
        textResponseOnly: true,
        disabled: true,
        standardQuestion: false,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.any,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.disabled,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.304b',
        questionId: '39',
        number: '5.4.3',
        infoText:
          'All employees, execuitives, governing body members, and providers billing under your TIN(s) should be included in periodic screening against the OIG\'s List of Excluded Individuals and Entities.',
        questionText:
          'Does your organization conduct periodic (at minimum, annual) screenings of Governing Body Members against the OIG\'s List of Excluded Individuals and Entities?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        requiredForQuestions: [
          {
            subQuestionId: '85',
            subQuestionReqResp: ComplianceFormAnswer.yes
          }
        ],
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        questionId: '85',
        number: '5.4.3A',
        questionText:
          'If yes, approximately how frequently are the exclusion screenings performed?',
        answer: ComplianceFormAnswer.no,
        textResponseOnly: true,
        disabled: true,
        standardQuestion: false,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.any,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.disabled,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.304b',
        questionId: '40',
        number: '5.4.4',
        infoText:
          'All employees, execuitives, governing body members, and providers billing under your TIN(s) should be included in periodic screening against the OIG\'s List of Excluded Individuals and Entities.',
        questionText:
          'Does your organization conduct periodic (at minimum, annual) screenings of Providers billing under your organization\'s TIN (all) against the OIG\'s List of Excluded Individuals and Entities?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        requiredForQuestions: [
          {
            subQuestionId: '86',
            subQuestionReqResp: ComplianceFormAnswer.yes
          }
        ],
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        questionId: '86',
        number: '5.4.4A',
        questionText:
          'If yes, approximately how frequently are the exclusion screenings performed?',
        answer: ComplianceFormAnswer.no,
        textResponseOnly: true,
        disabled: true,
        standardQuestion: false,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.any,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.disabled,
            pointValue: 0,
          }
        ],
      },
    ],
  },
  {
    id: 6,
    name: 'Data Privacy & Security',
    questions: [
      {
        code: '42 CFR 425.700 et seq, DUA',
        questionId: '41',
        number: '6.1',
        infoText:
          'This requirement may be satisfied with an ACO-specific or general HIPAA compliance policy. You are not required to submit the policy; however, we are always happy to review.',
        questionText:
          'Does your organization have policies in place to ensure the privacy and security of patient data and compliance with related federal laws (i.e. HIPAA and HITECH)?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.700 et seq, DUA',
        questionId: '42',
        number: '6.2',
        infoText:
          'This question pertains to the downloading and storing of raw patient claims data received by CMS. If your organization only downloads your population health tool\'s analyses, please respond \'yes.\'',
        questionText:
          'If downloading raw claims data, do members of your organization ensure that the data is downloaded to secure platforms and not shared with third party vendors outside the scope of the ACO\'s Data Use Agreement (DUA)? If members of your organization do not download raw claims data into your system, please mark "Yes."',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
      {
        code: '42 CFR 425.700 et seq, DUA',
        questionId: '43',
        number: '6.3',
        infoText:
          '',
        questionText:
          'Does your organization limit the use of CMS claims data to activities related to coordinating care and improving the quality and efficiency of care? Improper use outside this scope would include using the data to reduce, limit or restrict care for specific beneficiaries, or for publication and presentation to audiences outside of the ACO.',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 0,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 0,
          }
        ],
      },
    ],
  },
  {
    id: 7,
    name: 'Patient Incentives: This section is informational only, implementation of patient incentive programs are NOT required',
    questions: [
      {
        questionId: '44',
        number: '7.1',
        infoText:
          'Participating in a ACO waiver or choosing not to implement one will not impact the overall score on the self-assessment. All Signify Health-supported ACO Boards have approved the use of ACO patient incentive waivers for transportation, medication, and durable medical equipment. Respond \'yes\' to this question only if your organization has actively implemented one or more of these waivers. If your organization has not implemented an ACO patient incentive waiver, please respond \'no.\'',
        questionText:
          'Does your organization have an ACO patient incentive waiver in place to provide free or discounted items or services?',
        answer: ComplianceFormAnswer.no,
        standardQuestion: true,
        requiredForQuestions: [
          {
            subQuestionId: '76',
            subQuestionReqResp: ComplianceFormAnswer.yes
          },
        ],
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.yes,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.no,
            pointValue: 1,
          },
          {
            questionAnswer: ComplianceFormAnswer.partial,
            pointValue: 1,
          }
        ],
      },
 
      {

        questionId: '76',
        number: '7.1A',
        questionText:
          'If yes, select any waivers you are using (select all that apply): DME, Transportation, Medications & Delivery, Other',
        answer: ComplianceFormAnswer.no,
        checkListResponseOnly: true,
        standardQuestion: false,
        checkListOptions: [
          {
            checkId: 1,
            checkText: 'DME',
          },
          {
            checkId: 2,
            checkText: 'Transportation',
          },
          {
            checkId: 3,
            checkText: 'Medications & Delivery',
          },
          {
            checkId: 4,
            checkText: 'Other',
          },
        ],
        disabled: true,
        pointValue: [
          {
            questionAnswer: ComplianceFormAnswer.any,
            pointValue: 1,
          }
        ],
      },

    ],
  },
];
