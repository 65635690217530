import { AfterViewInit, Component, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ImproveFeatureConstants,
  ModuleConstants,
  HomeComponentConstants,
  SubmitFeatureConstants,
  SubmitFeatureNames,
  TrackFeatureConstants,
  CareFeatureConstants,
  SubmitFormConstants
} from '@shared/models/module-constants';
import { FolderLookupService } from '@shared/services/folder-lookup/folder-lookup.service';
import { FormConstants } from '@shared/models/forms/form-constants';
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { CardWidgetService } from '@api/track/services/card-widget.service';
import { CaravanFormSubmissionPeriodOverview } from '@shared/models/forms/form-submissionID-model';
import { AppConstants } from '@shared/models/constants/app-constants';
import { CardWidget } from '@api/track/models/card-widget';
import { MarkdownComponent } from 'ngx-markdown';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { LinkClickedEventsService } from '@shared/services/mixpanel/events/link-clicked-events.service';

const {
  FinancialUtilizationFeature,
  ClinicalFeature,
  QualityFeature,
  QualityFeatureName,
  ValueCalculatorFeature,
  ValueCalculatorFeatureFlag,
  ValueCalculatorName,
  PerformanceDashboardFeature,
  CompareFeature,
  CompareFeatureName,
  CompareFeatureFlag
} = TrackFeatureConstants;

@Component({
  selector: 'coach-home',
  templateUrl: './home.component.html',
  host: {
    class: 'coach-home'
  }
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  hccvipFolderId: number;
  hccvipSubscription: Subscription;
  fromCaravanPHIFolderId: number;
  fromCaravanPHISubscription: Subscription;
  qualityCampaignFolderId: number;
  qualityCampaignSubscription: Subscription;
  widgetMarkdown: any;
  widgetSubscription: Subscription;
  cardWidgetIDs: string[];
  currentTier: string;
  phiAccess: boolean = false;
  formSubmissionOverview: CaravanFormSubmissionPeriodOverview[];
  currentDate: string;
  app = AppConstants;

  @ViewChild('postDiv', { static: false })
  private postDiv: MarkdownComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAccessService: UserAccessService,
    private folderService: FolderLookupService,
    private formsService: SubmitFormsService,
    private cardService: CardWidgetService,
    private featureFlagService: FeatureFlagService,
    private linkClickedEventsService: LinkClickedEventsService
  ) {
    this.hccvipSubscription = this.folderService.hccvipFolderId$.subscribe(
      hccvipFolderId => (this.hccvipFolderId = hccvipFolderId)
    );
    this.fromCaravanPHISubscription = this.folderService.fromCaravanPHIFolderId$.subscribe(
      fromCaravanPHIFolderId => (this.fromCaravanPHIFolderId = fromCaravanPHIFolderId)
    );
    this.qualityCampaignSubscription = this.folderService.qualityCampaignsFolderId$.subscribe(
      qualityCampaignFolderId => {
        this.qualityCampaignFolderId = qualityCampaignFolderId;
      }
    );
    this.userAccessService.currentAccess$.pipe().subscribe(access => {
      this.phiAccess = access?.phi;

      if (this.userAccessService.hasModule(access, ModuleConstants.SubmitModule)) {
        this.formsService.getSubmitPeriodsOverview(0).subscribe(data => {
          this.formSubmissionOverview = data;
          this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        });
      }
    });
    this.widgetSubscription = this.cardService.cardWidgetGet().subscribe(WelcomeCard => {
      this.widgetMarkdown = WelcomeCard.reduce((group, cardWidget) => {
        const { cardWidgetId } = cardWidget;
        group[cardWidgetId] = group[cardWidgetId] ?? [];
        group[cardWidgetId].push(cardWidget);
        return group;
      }, {});
      this.cardWidgetIDs = Object.keys(this.widgetMarkdown);
    });
  }

  careModule = ModuleConstants.CareModule;
  improveModule = ModuleConstants.ImproveModule;
  trackModule = ModuleConstants.TrackModule;
  submitModule = ModuleConstants.SubmitModule;

  marketingWidgetComponent = HomeComponentConstants.MarketingWidget;
  marketingWidgetComponent2 = HomeComponentConstants.MarketingWidget2;

  improveContactsFeature = ImproveFeatureConstants.ContactsFeature;
  improveACOBoardFeature = ImproveFeatureConstants.ACOBoardFeature;
  improveSteeringCommiteeFeature = ImproveFeatureConstants.SteeringCommiteeFeature;
  improvePracticeImprovementFeature = ImproveFeatureConstants.PracticeImprovementFeature;
  improveITFeature = ImproveFeatureConstants.InformationTechnologyFeature;
  improveEventFeature = ImproveFeatureConstants.EventsFeature;

  trackClinicalFeature = ClinicalFeature;
  trackFinancialFeature = FinancialUtilizationFeature;
  trackQualityFeature = QualityFeature;
  trackQualityFeatureName = QualityFeatureName;
  trackValueFeature = ValueCalculatorFeature;
  trackValueFeatureFlag = ValueCalculatorFeatureFlag;
  trackValueName = ValueCalculatorName;
  trackCompareFeature = CompareFeature;
  trackCompareFeatureFlag = CompareFeatureFlag;
  trackCompareName = CompareFeatureName;
  trackPerformanceFeature = PerformanceDashboardFeature;
  carePrescriptionFeature = CareFeatureConstants.CarePrescriptionFeature;
  carePrescriptionProcessingFeature = CareFeatureConstants.CarePrescriptionProcessingFeature;
  careExceptionsFeature = CareFeatureConstants.CarePrescriptionExceptionsFeature;
  HCCVIPCohortList: string = CareFeatureConstants.HCCVIPCohortList;
  HCCListRevamp: string = CareFeatureConstants.HCCListRevamp;

  submitQualityFeature = SubmitFeatureConstants.QualityFormFeature;
  submitQuailityName = SubmitFeatureNames.Quality;
  submitMeetingFeature = SubmitFeatureConstants.MeetingFormFeature;
  submitMeetingName = SubmitFeatureNames.Meeting;
  submitComplianceFeature = SubmitFeatureConstants.ComplianceFormFeature;
  submitComplianceName = SubmitFeatureNames.Compliance;
  submitPIFeature = SubmitFeatureConstants.PromotingInteroperabilityFormFeature;
  submitPIName = SubmitFeatureNames.PI;

  qualityOpenInfo = FormConstants.QualityOpeningInfo;
  qualityOpenDueDate = FormConstants.QualityOpenDueDate;
  qualityrequirements = FormConstants.Quality.requirements;
  qualityFormId = SubmitFormConstants.QualityFormComponent;
  complianceOpenInfo = FormConstants.ComplianceOpeningInfo;
  complianceOpenDueDate = FormConstants.ComplianceOpenDueDate;
  complianceFormId = SubmitFormConstants.ComplianceFormComponent;
  piOpenInfo = FormConstants.PIOpeningInfo;
  piOpenDueDate = FormConstants.PIOpenDueDate;
  piFormId = SubmitFormConstants.PromotingInteroperabilityFormComponent;
  meetingWidgetName = FormConstants.MeetingWidgetName;
  meetingOpenInfo = FormConstants.MeetingOpeningInfo;
  meetingOpenDueDate = FormConstants.MeetingOpenDueDate;

  public masonryOptions: NgxMasonryOptions = {
    gutter: 25,
    horizontalOrder: true,
    percentPosition: true
  };

  @ViewChild(NgxMasonryComponent) public masonry: NgxMasonryComponent;
  @ViewChildren(NgxMasonryComponent) masonryContent: QueryList<any>;

  ngAfterViewInit() {
    this.masonry.layoutComplete.subscribe((e: any) => {
      this.resetLayout();
    });
  }

  ngOnDestroy() {
    if (this.hccvipSubscription) {
      this.hccvipSubscription.unsubscribe();
    }
    if (this.fromCaravanPHISubscription) {
      this.fromCaravanPHISubscription.unsubscribe();
    }
    if (this.qualityCampaignSubscription) {
      this.qualityCampaignSubscription.unsubscribe();
    }
    if (this.widgetSubscription) {
      this.widgetSubscription.unsubscribe();
    }
  }

  public resetLayout() {
    this.masonry.reloadItems();
    this.masonry.layout();
  }

  goToItems = (e: Event, path: string, query?: unknown | null): void => {
    e.preventDefault();

    this.trackLinkClick(e, path, query);

    this.router.navigate(['..' + path], {
      queryParams: query,
      relativeTo: this.activatedRoute.parent
    });
  };

  onCareClick(event: Event) {
    event.preventDefault();

    const hasFeature = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag);
    const path = hasFeature ? '/care/summary-tab' : '/care';

    this.trackLinkClick(event, path);

    this.router.navigate(['..' + path], {
      relativeTo: this.activatedRoute.parent
    });
  }

  /**
   * Send link clicks to mixpanel
   */
  trackLinkClick(event: Event, targetPage: string, query: { [key: string]: any } = {}): void {
    try {
      const { filterPreset } = query;
      const el = event.target as HTMLElement;
      const linkText = el.innerText;
      const linkId = el.id;
      this.linkClickedEventsService.sendLinkClickedEvent({
        linkId,
        linkText,
        filterPreset,
        targetPage
      });
    } catch (error) {
      console.error(error);
    }
  }
}
