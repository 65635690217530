import { Component, OnInit } from '@angular/core';
import { faPhone, faSms, faMinus, faUserMd, faCheckSquare, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'coach-care-settings',
  templateUrl: './care-settings.component.html'
})
export class CareSettingsComponent implements OnInit {
  faMinus = faMinus;
  faPhone = faPhone;
  faSms = faSms;
  faUserMd = faUserMd;
  faCheckSquare = faCheckSquare;
  faTimesCircle = faTimesCircle;
  constructor() { }

  ngOnInit(): void {
  }

  public showModalDemo: boolean = false;

  public toggleModalDemo() {
    this.showModalDemo = !this.showModalDemo;
  }

}
