import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, UrlTree, PRIMARY_OUTLET } from '@angular/router';
import { IBreadcrumb } from '@shared/models/breadcrumbs';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRouteService } from '@shared/services/activated-route/activated-route.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';

// TEMP
export const MockBreadcrumbs = [
  {
    label: 'crumb 1',
    urlSegment: 'crumb-1'
  },
  {
    label: 'crumb 2',
    urlSegment: 'crumb-2'
  },
  {
    label: 'crumb 3',
    urlSegment: 'crumb-3'
  }
]

@Component({
  selector: 'coach-header-breadcrumbs',
  templateUrl: './header-breadcrumbs.component.html',
  host: {
    class: 'coach-header-breadcrumbs'
  }
})
export class HeaderBreadcrumbsComponent implements OnInit, OnChanges {

  @Input() showBreadcrumbs: boolean;
  @Output() activeViewCrumb: EventEmitter<IBreadcrumb> = new EventEmitter();
  public activatedRoute$: BehaviorSubject<ActivatedRoute>;
  public breadcrumbs: IBreadcrumb[];

  private tierRoute = ':tierName';
  private tierIdRoute = ':tierId';

  constructor(
    private _activatedRoute: ActivatedRouteService,
    private hierarchyTierService: HierarchyTierService,
    ) {
      this.activatedRoute$ = this._activatedRoute.activatedRoute$;
    }
    
    // --- UNDER CONSTRUCTION ---
    
  ngOnInit(): void {
    if (this.showBreadcrumbs) {
      const route = this.activatedRoute$?.value?.root;
      if(route){
        this.breadcrumbs = this.buildBreadcrumb(this.activatedRoute$.value?.root);
      }
      
    }
  }

  ngOnChanges(): void {
    if (this.showBreadcrumbs) {
      const route = this.activatedRoute$?.value?.root;
      if(route){
        this.breadcrumbs = this.buildBreadcrumb(route);
      }
      
    }
  }

  public buildBreadcrumb(
    route: ActivatedRoute,
    url: string= '',
    breadcrumbs: IBreadcrumb[]=[]
  ) {
    // shortening for ease
    const r = route;
    const rc = r.routeConfig;
    
    //If no routeConfig is avalailable we are on the root path
    let label = rc && rc.data ? rc.data.label : '';
    let path = rc ? rc.path : '';

    if (path.indexOf(this.tierRoute) > -1) {
      const abv = this.hierarchyTierService.getTierAbbreviationFromRoute();
      path = path.replace(this.tierRoute, abv);
    }

    if (path.indexOf(this.tierIdRoute) > -1) {
      const tierId = this.hierarchyTierService.getTierIdFromRoute();
      path = path.replace(this.tierIdRoute, tierId);
    }

    // check if Patient Facesheet and replace with Patients List
    if (label === 'Patient') {
      label = 'Patients';
      path = 'patients';
    }

    //In the routeConfig the complete path is not available, so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;
    
    const breadcrumb: IBreadcrumb = {
        label: label,
        urlSegment: nextUrl,
    };

    const isDuplicate = breadcrumbs.some(x => x.label === breadcrumb.label && x.urlSegment === breadcrumb.urlSegment);
    const newBreadcrumbs = breadcrumb.label && !isDuplicate ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    
    if (r.firstChild) {
      // If we are not on our current path yet, there will be more children to look after, to build our breadcumb
      return this.buildBreadcrumb(r.firstChild, nextUrl, newBreadcrumbs);
    } else {
      this.activeViewCrumb.emit(newBreadcrumbs[0]);
    }
    
    return newBreadcrumbs.slice(1);

  }

}
