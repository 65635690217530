<div class="u-display--flex u-flex-direction--row u-height--full">
  <coach-attribution-filter-pane *hasFeatureFlag="hccEnhanced2FeatureFlag" title="Chronic Conditions Dashboard"
    (currentFilterModelChange)="filterModelChange($event)"></coach-attribution-filter-pane>

  <div class="u-display--flex u-flex-direction--column u-height--full u-width--full">
    <coach-tabs [tabsData]="tabsData" class="dashboard-tabs u-background-color--white border-bottom"></coach-tabs>
    <div class="titles t3 u-position--absolute margin-left--largest hcc-dashboard-header-title">
      <div class="title row--verticalCenter">
        <div class="margin-topBottom u-display--flex u-flex-direction--column">
          <div class="u-display--flex u-flex-direction--row u-align-items--center"><span
              class="margin-right--smallest">Chronic Conditions Dashboard</span>
          </div>
          <!-- Will be added with ZBR-10315 -->
          <!-- <small><i class="u-color--interaction u-font-weight--light"><span>Filter: Attributed</span></i></small> -->
        </div>
      </div>
    </div>


    <router-outlet class="outlet--column"></router-outlet>
  </div>
</div>