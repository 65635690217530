/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserDetailsResponse } from '../models/user-details-response';
import { UsersDetailsRequest } from '../models/users-details-request';
import { UsersDetailsResponse } from '../models/users-details-response';

@Injectable({
  providedIn: 'root',
})
export class UserDetailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userDetail
   */
  static readonly UserDetailPath = '/users-details/{userId}';

  /**
   * Fetch user details for a single user given a userId.
   *
   * Fetch user details for a single user given the userId in the url. Example: GET /users-details/16092
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDetail$Response(params: {

    /**
     * Internal key for user whose information is being requested
     */
    userId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserDetailService.UserDetailPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailsResponse>;
      })
    );
  }

  /**
   * Fetch user details for a single user given a userId.
   *
   * Fetch user details for a single user given the userId in the url. Example: GET /users-details/16092
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDetail(params: {

    /**
     * Internal key for user whose information is being requested
     */
    userId: number;
  },
  context?: HttpContext

): Observable<UserDetailsResponse> {

    return this.userDetail$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserDetailsResponse>) => r.body as UserDetailsResponse)
    );
  }

  /**
   * Path part for operation usersDetails
   */
  static readonly UsersDetailsPath = '/users-details';

  /**
   * Fetch user details for a list of users.
   *
   * Fetch user details for multiple users given an array of userIds in the request body
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersDetails()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  usersDetails$Response(params: {

    /**
     * List of userIds
     */
    body: UsersDetailsRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UsersDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserDetailService.UsersDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersDetailsResponse>;
      })
    );
  }

  /**
   * Fetch user details for a list of users.
   *
   * Fetch user details for multiple users given an array of userIds in the request body
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersDetails$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  usersDetails(params: {

    /**
     * List of userIds
     */
    body: UsersDetailsRequest
  },
  context?: HttpContext

): Observable<UsersDetailsResponse> {

    return this.usersDetails$Response(params,context).pipe(
      map((r: StrictHttpResponse<UsersDetailsResponse>) => r.body as UsersDetailsResponse)
    );
  }

}
