import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  TimeTrackingMonth,
  TimeTrackingYear,
} from './track-time-tracking-interfaces';
import moment from 'moment';
@Component({
  selector: 'coach-track-time-tracking-filter',
  templateUrl: './track-time-tracking-filter.component.html',
  host: {
    class: 'track-time-tracking-filter',
  },
})
export class TrackTimeTrackingFilterComponent implements OnInit {
  public isFilterPaneOpen: boolean;
  public currentYear = moment().year();
  public currentMonth = moment().month();
  public selectedYear: TimeTrackingYear;
  public selectedMonth: TimeTrackingMonth;
  @Output() selectedYearChange: EventEmitter<TimeTrackingYear> = new EventEmitter<TimeTrackingYear>();
  @Output() selectedMonthChange: EventEmitter<TimeTrackingMonth> = new EventEmitter<TimeTrackingMonth>();
  @Output() export: EventEmitter<String> = new EventEmitter<String>();
  @Input() selectedList: {detailed: boolean}

  years: TimeTrackingYear[] = [];

  months: TimeTrackingMonth[] = [
    { display: 'January', value: 0 },
    { display: 'February', value: 1 },
    { display: 'March', value: 2 },
    { display: 'April', value: 3 },
    { display: 'May', value: 4 },
    { display: 'June', value: 5 },
    { display: 'July', value: 6 },
    { display: 'August', value: 7 },
    { display: 'September', value: 8 },
    { display: 'October', value: 9 },
    { display: 'November', value: 10 },
    { display: 'December', value: 11 },
  ];

  constructor() {
    let todayYear = moment().year()
    while (todayYear >= 2019) {
      this.years.push({ display: String(todayYear), value: todayYear });
      todayYear--;
    }
  }

  ngOnInit(): void {
    this.selectedYear = this.years.find(x => x.value === this.currentYear);
    this.yearSelected()
    this.selectedMonth = this.months[this.currentMonth];
    this.monthSelected()

  }

  public applyClick(): void {
    console.log(
      `Selected values: ${this.selectedMonth.display} ${this.selectedYear.display}`
    );
  }
  public yearSelected(): void {
    this.selectedYearChange.emit(this.selectedYear);
  }

  public monthSelected(): void {
    this.selectedMonthChange.emit(this.selectedMonth);
  }
  public exportClick(action:string): void {
    this.export.emit(action)
  }
}
