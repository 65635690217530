<meta name="viewport" content="width=device-width, initial-scale=1">
<div class = "content-body"> 
<div class="scroll">   
    <div class="header">
        <div class="header-rectangle" style="background-color: var(--brand--1);">
            <h2 class="header-title">Steering Committee</h2>
            <p class="header-sub-title">
                A Steering Committee is a quarterly meeting between members, leadership, and your {{app.CompanyName}} Health Team to determine next steps, goals, opportunities, and direction.
            </p>            
        </div>
        <img class="img-responsive header-image" src="assets\img\illustrations\SH_PEP_Illustrations_Improve_Sum_Steer_1700x1100_20221114.svg"/>
    </div>

  <br>
  <br>   
  <div class = "description">
    <p>
      A Steering Committee is a quarterly meeting between members, leadership, and your {{app.CompanyName}} Health Team to determine next steps, goals, opportunities, and direction.  <br>
     <br>
      Like checking your compass to see if you are on course, these meetings include data that show all Medicare claims information for your patients. This information is used to determine next steps, goals, opportunities, and direction. <br>
      <br>
      In this section, you can review information from past Steering Committee meetings, prepare for your next meeting, review goals, and evaluate progress. 
    </p>
  </div>

<!--
  <table> 
    <tr> 
      <td class = 'item1'> 
        <h4> Most Recent Meeting <br> Date</h4>   
        <p> Here are the documents from the most recent meeting:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>
      
      <td class = 'item2'> 
        <h4> Important Content <br> </h4>   
        <p> Here are some useful links:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>

    </tr> 
  
  </table>
-->

</div>  
</div> 


