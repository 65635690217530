<ng-container [formGroup]="parentForm">
  <div [formGroupName]="questionInfo.name" class="u-background-color--white">
    <div class="submitFormBlock-header">
      <div class="row">
        <h6 class="margin--none u-fill u-opacity--light">
          {{questionInfo.measureId}} <span *ngIf="questionInfo.measureId != null">|</span> {{questionInfo.pointsText}}: {{maxPoints}} Points
          <!-- {{questionInfo.pointsText}}: {{maxPoints}} Points -->
        </h6>
        <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small" iconColor="primary"
          (click)="toggleInfoText()"></coach-icon>
      </div>
      <div class="u-bold margin-top--smallest"> {{questionInfo.description}}</div>

      <div class="margin-top" *ngIf="questionInfo.allowExclusion">
        <input [attr.disabled]="isDisabled ? true : null" type="checkbox" formControlName="exclusion"
          (change)="exclusionChanged()" id="pi-exclusion-{{questionInfo.measureId}}">
        <label for="pi-exclusion-{{questionInfo.measureId}}">Request Exclusion</label>
      </div>

    </div>
    <ng-container formArrayName="FormQResponses">
      <div class="submitFormBlock-main" [formGroupName]="0"  *ngIf="!exclusionSelected">
        <div class="button-group is-primary">
          <div class="group">
            <input type="radio" id="pi-{{questionInfo.name}}-radio1" [value]="no" (change)="changeResponse(no)" formControlName="UserResponse">
            <label for="pi-{{questionInfo.name}}-radio1">No</label>
          </div>
          <div class="group">
            <input type="radio" id="pi-{{questionInfo.name}}-radio2" [value]="yes" (change)="changeResponse(yes)" formControlName="UserResponse" >
            <label for="pi-{{questionInfo.name}}-radio2">Yes</label>
          </div>

        </div>

      </div>
    </ng-container>
  </div>
</ng-container>




<div *ngIf="showExclusionModal" class="overlay overlay--light is-open">
  <div class="modal">
    <div class="header">
      <div class="titles">
        <div class="title">Proceed With Exemption for {{questionInfo.description}}?</div>
      </div>
    </div>
    <div class="dynamicBody padding">
      {{questionInfo.exclusionText}}
    </div>
    <div class="footer">
      <button class="button margin-right border--none" (click)="cancelExclusion()">No</button>
      <button class="button is-primary" (click)="confirmExclusion()">Yes</button>
    </div>
  </div>
</div>


