import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { takeUntil } from 'rxjs/operators';
import { ClinicianRoster, providerList } from '@shared/models/docRepo/clinician-roster-model';
import { ExcelService, workBook, WorkSheetTypes } from '@shared/services/excel-export/excel.service';
import { UserInfo } from '@shared/services/auth-service/auth-interface';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-clinician-roster',
  templateUrl: './clinician-roster.component.html',
  styleUrls: ['./clinician-roster.component.scss']
})
export class ClinicianRosterComponent extends KillSubscriptions implements OnInit {
  columnDefs = [];

  rowData: providerList[];
  showInstructionsModal: boolean;

  private gridApi: any;
  public ClinicianRoster: ClinicianRoster[];
  public progID: number;

  public isAuthenticated$: Observable<boolean>;
  public userSub: Subscription;
  public userInfo$: Observable<UserInfo>;
  private _isLoggedIn: boolean = false;
  private _currentUser: UserInfo;

  private workbookData: workBook[];

  private msspColumnDefs = [
    { headerName: 'CMS ACO ID', field: 'ACO_ID', sortable: true, filter: true, resizable: true },
    { headerName: 'ACO Name', field: 'ACO_Name', sortable: true, filter: true, resizable: true, hide: true },
    { headerName: 'Community Code', field: 'ICC', sortable: true, filter: true, resizable: true },
    { headerName: 'Participant Code', field: 'Prin_Part_ICC', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Participant Name',
      field: 'Prin_Part_Name',
      sortable: true,
      filter: true,
      resizable: true,
      hide: true
    },
    { headerName: 'Practice Identifier', field: 'practice_last_4_tin', sortable: true, filter: true, resizable: true },
    { headerName: 'Practice Name', field: 'Practice_Legal_Name', sortable: true, filter: true, resizable: true },
    { headerName: 'Practice DBA Name', field: 'Practice_DBA_Name', sortable: true, filter: true, resizable: true },
    { headerName: 'Provider NPI', field: 'Individual_Provider_NPI', sortable: true, filter: true, resizable: true },
    { headerName: 'First Name', field: 'First_Name', sortable: true, filter: true, resizable: true },
    { headerName: 'Last Name', field: 'Last_Name', sortable: true, filter: true, resizable: true },
    { headerName: 'Credentials', field: 'Credentials', sortable: true, filter: true, resizable: true, hide: true },
    {
      headerName: 'Status',
      field: 'Provider_Status_in_CaravanHealth_Internal_Records',
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: 'Specify Edit/Remove/Add',
      field: '',
      sortable: true,
      filter: true,
      resizable: true,
      hide: true,
      value: [{ name: 'Accurate' }, { name: 'Add' }, { name: 'Drop' }]
    },
    { headerName: 'Notes to Caravan Health', field: '', sortable: true, filter: true, resizable: true, hide: true }
  ];

  private commercialColumnDefs = [
    { headerName: 'CMS ACO ID', field: 'ACO_ID', sortable: true, filter: true, resizable: true },
    { headerName: 'ACO Name', field: 'ACO_Name', sortable: true, filter: true, resizable: true, hide: true },
    { headerName: 'Community Code', field: 'ICC', sortable: true, filter: true, resizable: true },
    { headerName: 'Participant Code', field: 'Prin_Part_ICC', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Participant Name',
      field: 'Prin_Part_Name',
      sortable: true,
      filter: true,
      resizable: true,
      hide: true
    },
    { headerName: 'Practice Identifier', field: 'practice_last_4_tin', sortable: true, filter: true, resizable: true },
    { headerName: 'Practice Name', field: 'Practice_Legal_Name', sortable: true, filter: true, resizable: true },
    { headerName: 'Practice DBA Name', field: 'Practice_DBA_Name', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Practice_ADDID_Name',
      field: 'Practice_ADDID_Name',
      sortable: true,
      filter: true,
      resizable: true,
      hide: true
    },
    {
      headerName: 'Practice_AddID_Value',
      field: 'Practice_AddID_Value',
      sortable: true,
      filter: true,
      resizable: true
    },

    { headerName: 'Provider NPI', field: 'Individual_Provider_NPI', sortable: true, filter: true, resizable: true },
    { headerName: 'First Name', field: 'First_Name', sortable: true, filter: true, resizable: true },
    { headerName: 'Last Name', field: 'Last_Name', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Ind_ADDID_Name',
      field: 'Ind_ADDID_Name',
      sortable: true,
      filter: true,
      resizable: true,
      hide: true
    },
    {
      headerName: 'Ind_AddID_Value',
      field: 'Ind_AddID_Value',
      sortable: true,
      filter: true,
      resizable: true,
      hide: true
    },
    { headerName: 'Credentials', field: 'Credentials', sortable: true, filter: true, resizable: true, hide: true },
    {
      headerName: 'Status',
      field: 'Provider_Status_in_CaravanHealth_Internal_Records',
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: 'Specify Edit/Remove/Add',
      field: '',
      sortable: true,
      filter: true,
      resizable: true,
      hide: true,
      value: [{ name: 'Accurate' }, { name: 'Add' }, { name: 'Drop' }]
    },
    { headerName: 'Notes to Caravan Health', field: '', sortable: true, filter: true, resizable: true, hide: true }
  ];

  supportEmail: string = AppConstants.SupportEmail;

  private instructionsSheet = [
    {
      workSheet: 'Instructions',
      workSheetType: WorkSheetTypes.freeForm,
      rows: [
        {
          row: 1,
          cols: [
            {
              column: 'A',
              value: 'Requested On:',
              styling: { font: { bold: true }, alignment: { vertical: 'bottom', horizontal: 'left' } },
              columnWidth: 17.43
            },
            {
              column: 'B',
              value: formatDate(Date.now(), 'MM/dd/yyyy', this.locale),
              styling: { alignment: { vertical: 'bottom', horizontal: 'left' } },
              columnWidth: 35
            }
          ]
        },
        {
          row: 3,
          cols: [
            {
              column: 'A',
              value: 'Requested By:',
              styling: { font: { bold: true }, alignment: { vertical: 'bottom', horizontal: 'left' } }
            },
            { column: 'B', value: '', styling: { alignment: { vertical: 'bottom', horizontal: 'left' } } }
          ]
        },
        {
          row: 5,
          cols: [
            {
              column: 'A',
              value: 'Request Method:',
              styling: { font: { bold: true }, alignment: { vertical: 'bottom', horizontal: 'left' } }
            },
            {
              column: 'B',
              value: 'Downloaded via Coach',
              styling: { alignment: { vertical: 'bottom', horizontal: 'left' } }
            }
          ]
        },
        {
          row: 7,
          cols: [
            {
              column: 'A',
              value: 'Completed On:',
              styling: { font: { bold: true }, alignment: { vertical: 'bottom', horizontal: 'left' } }
            },
            {
              column: 'B',
              value: formatDate(Date.now(), 'MM/dd/yyyy', this.locale),
              styling: { alignment: { vertical: 'bottom', horizontal: 'right' } }
            }
          ]
        },
        {
          row: 9,
          cols: [
            {
              column: 'A',
              value: 'Purpose:',
              styling: {
                font: { bold: true },
                border: { bottom: { style: 'thin' } },
                alignment: { vertical: 'bottom', horizontal: 'left' }
              }
            }
          ]
        },
        {
          row: 10,
          cols: [
            {
              column: 'B',
              value:
                'This file is to attest to Caravan Health the clinicians currently participating in the communities ACO.',
              styling: {
                border: { top: { style: 'thin' } },
                alignment: { wrapText: true, vertical: 'top', horizontal: 'left' }
              },
              mergeCells: 'B10:F16'
            }
          ]
        },
        {
          row: 17,
          cols: [
            {
              column: 'A',
              value: 'Description:',
              styling: {
                font: { bold: true },
                border: { bottom: { style: 'thin' } },
                alignment: { vertical: 'bottom', horizontal: 'left' }
              }
            }
          ]
        },
        {
          row: 18,
          cols: [
            {
              column: 'B',
              value:
                "The data contained within this report is coming from Caravan Health's internal records and is used to identify the clinicians who are participating in the community's ACOs.",
              styling: {
                border: { top: { style: 'thin' } },
                alignment: { wrapText: true, vertical: 'top', horizontal: 'left' }
              },
              mergeCells: 'B18:F25'
            }
          ]
        },
        {
          row: 26,
          cols: [
            {
              column: 'A',
              value: 'Instructions:',
              styling: {
                font: { bold: true },
                border: { bottom: { style: 'thin' } },
                alignment: { vertical: 'bottom', horizontal: 'left' }
              }
            }
          ]
        },
        {
          row: 27,
          cols: [
            {
              column: 'B',
              value: {
                richText: [
                  {
                    font: { size: 11, color: { theme: 1 }, name: 'Calibri', family: 2, scheme: 'minor' },
                    text: `Please make any required changes in the PECOS system first. Then update this spreadsheet. Once completed please submit the workbook as an attachment on a new ticket at ${this.supportEmail}. \r\n\nPlease update the column Q on each row to state if it is correct or not. Column R is provided for any note you may want to leave us for that given row.`
                  }
                ]
              },
              styling: {
                border: { top: { style: 'thin' } },
                alignment: { wrapText: true, vertical: 'top', horizontal: 'left' }
              },
              mergeCells: 'B27:F33'
            }
          ]
        },
        {
          row: 34,
          cols: [
            {
              column: 'B',
              value: {
                text: 'To update PECOS via the internet portal, please go to pecos.cms.hhs.gov',
                hyperlink: 'https://pecos.cms.hhs.gov',
                tooltip: 'https://pecos.cms.hhs.gov'
              },
              styling: { alignment: { wrapText: true, vertical: 'top', horizontal: 'left' } },
              mergeCells: 'B34:F36'
            }
          ]
        },
        {
          row: 37,
          rowHeight: 41,
          cols: [
            {
              column: 'B',
              value: {
                text: 'If you have trouble with PECOS or require technical assistance please go to https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/downloads/MedEnroll_PECOS_Contact_FactSheet_ICN903766.pdf for troubleshooting help.',
                hyperlink:
                  'https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/downloads/MedEnroll_PECOS_Contact_FactSheet_ICN903766.pdf',
                tooltip:
                  'https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/downloads/MedEnroll_PECOS_Contact_FactSheet_ICN903766.pdf'
              },
              styling: { alignment: { wrapText: true, vertical: 'top', horizontal: 'left' } },
              mergeCells: 'B37:F39'
            }
          ]
        }
      ]
    }
  ];

  transformData(clinicianRoster: ClinicianRoster[]): workBook[] {
    let outputWorkbook = [] as workBook[];

    const useremail = this._currentUser?.email || null;
    this.instructionsSheet[0].rows[1].cols[1].value = useremail;

    outputWorkbook.push(this.instructionsSheet[0]);

    clinicianRoster.forEach(program => {
      let rows = [];
      if (program.progID == '1' && program.progGroupID == '1') {
        const dataStructure = this.msspColumnDefs;

        program.providerList.forEach(entry => {
          const row = dataStructure.reduce(
            (prev, next) => ({
              ...prev,
              [next.headerName]:
                next.field == ''
                  ? next.value != undefined
                    ? next.value.map(({ name }) => name)
                    : ''
                  : entry[next.field]
            }),
            {}
          );

          rows.push(row);
        });
      } else if (program.progGroupID == '2') {
        const dataStructure = this.commercialColumnDefs;

        program.providerList.forEach(entry => {
          const row = dataStructure.reduce(
            (prev, next) => ({
              ...prev,
              [next.headerName]:
                next.field == ''
                  ? next.value != undefined
                    ? next.value.map(({ name }) => name)
                    : ''
                  : entry[next.field]
            }),
            {}
          );

          rows.push(row);
        });
      }

      const workSheet = {
        workSheet: program.progNameShort + ' List',
        workSheetType: WorkSheetTypes.table,
        theme: 'TableStyleMedium2',
        rows: rows
      };

      outputWorkbook.push(workSheet);
    });

    return outputWorkbook;
  }

  transform(data) {
    const noOfRowaToGenerate = 10;
    return data.map(({ name, values }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value) ? next.value.map(({ name }) => name) : next.value
        }),
        {}
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers)
      };
    });
  }

  constructor(
    private _authService: AuthService,
    @Inject(LOCALE_ID) private locale: string,
    private fileDownloadService: FileDownloadService,
    private hierarchyTierService: HierarchyTierService
  ) {
    super();
  }

  private excelService = new ExcelService();

  ngOnInit(): void {
    this.userInfo$ = this._authService.userInfo$;
    this.userSub = this.userInfo$.subscribe(userInfo => {
      this._currentUser = userInfo;
    });
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
      if (tier != null) {
        let altTier = '';
        if (tier.highestTier == null) {
          altTier = tier.selectedTierId;
        } else {
          switch (tier.highestTier) {
            case 1:
              altTier = tier.selectedItem.tier1_ID;
              break;
            case 2:
              altTier = tier.selectedItem.tier2_ID;
              break;
            case 3:
              altTier = tier.selectedItem.tier3_ID;
              break;
            case 4:
              altTier = tier.selectedItem.tier4_ID;
              break;
            default:
              altTier = tier.selectedItem.tier2_ID;
              break;
          }
        }
        this.fileDownloadService.getClinicianRoster(tier.selectedItem.tier2_ID, altTier).subscribe(rosterJson => {
          this.ClinicianRoster = rosterJson;

          this.progID = parseInt(this.ClinicianRoster[0].progID) || 0;

          if (this.progID == 1) {
            this.gridApi.setColumnDefs([]);
            this.gridApi.setColumnDefs(this.msspColumnDefs);
          } else {
            this.gridApi.setColumnDefs([]);
            this.gridApi.setColumnDefs(this.commercialColumnDefs);
          }

          this.rowData = this.ClinicianRoster[0].providerList || [];
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
  }

  onFirstDataRendered(params: any): void {
    params.api.sizeColumnsToFit();
  }

  formatPhi(phi: boolean): string {
    return phi ? 'Yes' : 'No';
  }

  closeModal(): void {
    this.showInstructionsModal = false;
  }

  export(): void {
    const timestamp = formatDate(new Date(), 'yyyyMMdd', this.locale);

    this.workbookData = this.transformData(this.ClinicianRoster);

    this.excelService.exportAsExcel(
      this.workbookData,
      `${this.hierarchyTierService.currentTier$.value.selectedItem.tier2_ID}_Clinician_Roster_ ${timestamp}`
    );

    this.showInstructionsModal = true;
  }

  public selectClinicianRoster(program) {
    this.progID = program.target.value;

    if (this.progID == 1) {
      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.msspColumnDefs);
    } else {
      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.commercialColumnDefs);
    }
    this.rowData = this.ClinicianRoster[program.target.selectedIndex].providerList || [];
  }
}
