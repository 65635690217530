import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CareComponent } from '@care/care.component';
import { CarePatientsComponent } from '@care/views/care-patients/care-patients.component';
import { CarePatientComponent } from '@care/views/care-patient/care-patient.component';
import { CareSettingsComponent } from '@care/views/care-settings/care-settings.component';
import { CareAllPrescriptionsComponent } from './views/care-all-prescriptions/care-all-prescriptions.component';
import { CarePrescriptionsComponent } from './views/care-prescriptions/care-prescriptions.component';
import { CarePrescriptionsProcessingComponent } from './views/care-patients/care-prescriptions-processing/care-prescriptions-processing.component';
import { CarePrescriptionsInvoicingComponent } from './views/care-prescriptions-invoicing/care-prescriptions-invoicing.component';
import { CarePatientsContainerComponent } from './views/care-patients/refactor-poc/care-patients-container/care-patients-container.component';
import { CareSummaryTabComponent } from './views/care-summary-tab';
import { HasFeatureGuard } from '@shared/guards';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { WellpepperResolverService } from '@shared/services/WellpepperUser.resolver';

const routes: Routes = [
  {
    path: '',
    component: CareComponent,
    data: {
      label: 'Care',
      helpfile: 'LEARN_CARE_General_2020'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'patients'
      },
      {
        path: 'summary-tab',
        component: CareSummaryTabComponent,
        data: {
          label: 'Summary-Tile',
          featureFlag:CareFeatureConstants.CareLandingPageFlag
        },
        canActivate:[HasFeatureGuard]
      },
      {
        path: 'patients',
        component: CarePatientsComponent,
        resolve: { user: WellpepperResolverService },
        data: {
          label: 'Patients'
        }
      },
      {
        path: 'patients2',
        component: CarePatientsContainerComponent,
        data: {
          label: 'Patients'
        }
      },
      {
        path: 'patient/:id',
        component: CarePatientComponent,
        data: {
          label: 'Patient',
          helpfile: 'LEARN_CARE_AM_HCCFacesheet_2020'
        }
      },
      {
        path: 'settings',
        component: CareSettingsComponent,
        data: {
          label: 'Settings'
        }
      },
      {
        path: 'allPrescriptions',
        component: CareAllPrescriptionsComponent,
        data: {
          label: '340B Prescriptions',
          helpfile: 'LEARN_CARE_340B'
        }
      },
      {
        path: 'prescriptions',
        component: CarePrescriptionsComponent,
        data: {
          label: 'Prescriptions',
          helpfile: 'LEARN_CARE_340B'
        }
      },
      {
        path: 'prescriptions-processing',
        component: CarePrescriptionsProcessingComponent,
        data: {
          label: 'Prescriptions-Processing',
          helpfile: 'LEARN_CARE_340B'
        }
      },
      {
        path: 'prescriptions-invoicing',
        component: CarePrescriptionsInvoicingComponent,
        data: {
          label: 'Invoicing',
          helpfile: 'LEARN_CARE_340B'
        }
      }
    ]
  }
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   redirectTo: ''
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CareRoutingModule {}
