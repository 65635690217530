import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {Patient} from '@shared/models/patient';
import {ToastrMessageService} from '@shared/services/toastr-message/toastr-message.service';
import {
  Outcome,
  OutcomeType,
  PatientDidNotAnswerOutcome,
  PatientDidNotAnswerProperties,
  PatientWasRemindedOutcome,
  PatientWasRemindedProperties,
  PropertiesType
} from '@api/care-orch/models';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';

export enum WasRemindedOutcome {
  PATIENT_WAS_REMINDED,
  PATIENT_DID_NOT_ANSWER
}

const DefaultOutcome = WasRemindedOutcome.PATIENT_WAS_REMINDED;

@Component({
  selector: 'coach-patient-step-reminder-form',
  templateUrl: './patient-step-reminder-form.component.html',
  styleUrls: ['./patient-step-reminder-form.component.scss']
})
export class PatientStepReminderFormComponent implements OnChanges, OnInit{
  @Input() public patient: Patient;
  @Input() public outcome: Outcome;
  @Input() public attendedOutcome: Outcome;
  @Output() public outcomeSaved: EventEmitter<Outcome> = new EventEmitter();

  WasRemindedOutcome = WasRemindedOutcome;

  selectedOutcome = DefaultOutcome;
  healthRisk = false;
  needsTransportation = false;
  needsInterpreter = false;
  medications = false;
  labsDocumentation = false;
  comment = '';
  isReadOnly = false;
  allFieldsEmpty = true;
  requiredFieldsSatisfied = true;
  commentCharacterLimit = CareOrchestrationConstants.commentBoxCharacterLimit;

  constructor(private toastrMessageService: ToastrMessageService) {
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.patient) {
      this.clearFields();
    }
    if (this.outcome) {
      this.prePopWithReminderOutcome();
    }
    this.fieldChanged();
  }

  ngOnInit(){
    if (this.attendedOutcome) {
      this.isReadOnly = true;
      this.requiredFieldsSatisfied = false;
    }
  }

  copyComment(commentBox): void{
    commentBox.select();
    document.execCommand('copy');
    commentBox.setSelectionRange(0, 0);
  }

  fieldChanged(): void {
    if (this.selectedOutcome !== WasRemindedOutcome.PATIENT_WAS_REMINDED) {
      this.clearPatientWasRemindedSubFields();
    }
    this.checkAnyFieldHasValue();
    this.checkRequiredFieldsSatisfied();
  }

  private checkAnyFieldHasValue(): void {
    if (this.outcome) {
      this.allFieldsEmpty = (this.outcome.type === OutcomeType.PatientWasRemindedOutcome
        && this.selectedOutcome === WasRemindedOutcome.PATIENT_WAS_REMINDED) ||
        (this.outcome.type === OutcomeType.PatientDidNotAnswerOutcome
        && this.selectedOutcome === WasRemindedOutcome.PATIENT_DID_NOT_ANSWER);
    } else {
      this.allFieldsEmpty = this.selectedOutcome === DefaultOutcome;
    }

    if (!this.isReadOnly && (this.healthRisk || this.needsTransportation || this.needsInterpreter || this.medications ||
      this.labsDocumentation || this.comment)) {
      this.allFieldsEmpty = false;
    }
  }

  private checkRequiredFieldsSatisfied(): void {
    // if the reminder form is editable, the save button should be active
    this.requiredFieldsSatisfied = !this.isReadOnly;
  }

  clearPatientWasRemindedSubFields(): void {
    this.healthRisk = false;
    this.needsTransportation = false;
    this.needsInterpreter = false;
    this.medications = false;
    this.labsDocumentation = false;
  }

  clearFields() {
    if (this.outcome) {
      this.prePopWithReminderOutcome();
    } else {
      this.selectedOutcome = DefaultOutcome;
      this.clearPatientWasRemindedSubFields();
    }
    this.comment = '';
    this.fieldChanged();
  }

  save() {
    let outcome;

    switch (this.selectedOutcome) {
      case WasRemindedOutcome.PATIENT_WAS_REMINDED:
        outcome = this.buildPatientWasRemindedOutcome();
        break;
      case WasRemindedOutcome.PATIENT_DID_NOT_ANSWER:
        outcome = this.buildPatientDidNotAnswerOutcome();
        break;
      default:
        this.toastrMessageService.error(new Error('The selected outcome for the reminder activity is not recognized!'));
        return;
    }

    if (outcome != null) {
      this.outcomeSaved.emit(outcome);
    }
  }

  private buildPatientWasRemindedOutcome(): PatientWasRemindedOutcome {
    return {
      properties: {
        remindedDateTime:  WorkflowDateTimeUtil.todayDateOnlyString(),
        healthRiskAssessmentStarted: this.healthRisk,
        needsTransportation: this.needsTransportation,
        needsInterpreter: this.needsInterpreter,
        medications: this.medications,
        labsDocumentation: this.labsDocumentation,
        comment: this.comment ? this.comment : null,
        type: PropertiesType.PatientWasRemindedProperties
      } as PatientWasRemindedProperties,
      type: OutcomeType.PatientWasRemindedOutcome
    };
  }

  private buildPatientDidNotAnswerOutcome(): PatientDidNotAnswerOutcome {
    return {
      properties: {
        comment: this.comment ? this.comment : null,
        type: PropertiesType.PatientDidNotAnswerProperties
      } as PatientDidNotAnswerProperties,
      type: OutcomeType.PatientDidNotAnswerOutcome
    };
  }

  private prePopWithReminderOutcome(): void {
    switch (this.outcome.type) {
      case OutcomeType.PatientWasRemindedOutcome:
        this.prePopPatWasRemindedOutcome(this.outcome as PatientWasRemindedOutcome);
        break;
      case OutcomeType.PatientDidNotAnswerOutcome:
        this.prePopPatDidNotAnswerOutcome();
        break;
      default:
        this.toastrMessageService.error(new Error('The incoming outcome for the reminder activity is not recognized!'));
    }
  }

  private prePopPatWasRemindedOutcome(outcome: PatientWasRemindedOutcome): void {
    this.selectedOutcome = WasRemindedOutcome.PATIENT_WAS_REMINDED;
    this.healthRisk = outcome.properties.healthRiskAssessmentStarted;
    this.needsTransportation = outcome.properties.needsTransportation;
    this.needsInterpreter = outcome.properties.needsInterpreter;
    this.medications = outcome.properties.medications;
    this.labsDocumentation = outcome.properties.labsDocumentation;
    this.isReadOnly = true;
  }

  private prePopPatDidNotAnswerOutcome(): void {
    this.selectedOutcome = WasRemindedOutcome.PATIENT_DID_NOT_ANSWER;
  }

  stringifyForm(): string {
    let value = '';

    switch (this.selectedOutcome) {
      case WasRemindedOutcome.PATIENT_WAS_REMINDED:
        if (this.outcome) {
          value = 'Patient reminded of AWV appointment on ' +
            WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat((this.outcome as PatientWasRemindedOutcome)
              .properties.remindedDateTime);
        } else {
          value = 'Patient reminded of AWV appointment on ' + WorkflowDateTimeUtil.todayToMonthDateFormat();
        }

        if (this.healthRisk) {
          value += '. Health Risk Assessment Started';
        }

        if (this.needsTransportation) {
          value += '. Patient needs transportation';
        }

        if (this.needsInterpreter) {
          value += '. Patient needs interpreter';
        }

        if (this.medications || this.labsDocumentation) {
          value += '. Patient reminded to bring';

          if (this.medications) {
            value += ' medications';

            if (this.labsDocumentation) {
              value += ' and';
            }
          }

          if (this.labsDocumentation) {
            value += ' labs/documentation';
          }
        }
        break;
      case WasRemindedOutcome.PATIENT_DID_NOT_ANSWER:
        if (this.outcome) {
          value = 'Patient did not answer AWV reminder call on ' +
            WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat((this.outcome as PatientWasRemindedOutcome)
              .properties.remindedDateTime);
        } else {
          value = 'Patient did not answer AWV reminder call on ' + WorkflowDateTimeUtil.todayToMonthDateFormat();
        }
    }

    if (this.comment) {
      value += '. ' + this.comment;
    }

    return value;
  }
}


