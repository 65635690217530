  <ag-grid-angular
      coachAgGridResize
      class='ag-theme-material dynamicBody'
      [rowData]="CaravanFormAdminData"
      [columnDefs]="columnNamesLevelOne"
      rowSelection="multiple"
      [defaultColDef]="defaultColDef"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
      [frameworkComponents]="frameworkComponents"
      [context]="context"
      >
  </ag-grid-angular>
  <div class="footer">
    <button class="button is-small"  (click)="onBtnExport()">Export</button>
    <ng-container *ngIf="formID == '3'">
      <button class="button is-small"  (click)="onBtnExportResponses()">Export Responses</button>
    </ng-container>
  </div>

