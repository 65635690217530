import { Component, OnInit } from '@angular/core';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { ChartItem } from '../chart-item';
import { switchMap, takeUntil } from 'rxjs/operators';
import { HccDashboardTierMetricsService } from '../metrics/hcc-dashboard-tier-metrics.service';
import { HccDasbhoardCodingOpportunitiesService } from '../coding-opportunities/hcc-dasbhoard-coding-opportunities.service';
import { HccDashboardCodingOpportunity } from '../coding-opportunities/hcc-dashboard-coding-opportunity';
import { GridFilterService, PATIENT_DETAIL_REPORT_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { CarePatientItem, TrackFeatureConstants } from '@shared/models/module-constants';
import { HccDashboardService } from '@api/track/services';
import { HccDashboardFilterService } from '../hcc-dashboard-filter.service';
import { Observable, combineLatest } from 'rxjs';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';
import { HccDashboardDiseaseGroupSummary } from '@api/track/models';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'coach-hcc-dashboard-summary',
  templateUrl: './hcc-dashboard-summary.component.html',
  styleUrls: ['./hcc-dashboard-summary.component.scss']
})
export class HccDashboardSummaryComponent extends KillSubscriptions implements OnInit {
  view: number[] = [400, 275];
  acoAverage: number;
  annualClosureRate: ChartItem[] = [];
  codingOpportunities: HccDashboardCodingOpportunity[] = [];
  opportunityMax: number;
  colorScheme = {
    domain: ['#007D8A', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  canAccessPhi: boolean;
  hccEnhanced2FeatureFlag = TrackFeatureConstants.HccDashboardEnhanced2FeatureFlag;
  diseaseGroups$: Observable<HccDashboardDiseaseGroupSummary[]>;
  top50Preset: CarePatientItem.HccTop50;

  constructor(
    private hierarchyTierService: HierarchyTierService,
    private opportunitiesService: HccDasbhoardCodingOpportunitiesService,
    private gridFilterService: GridFilterService,
    private hccDashboardService: HccDashboardService,
    protected filterService: GridFilterService,
    private hccDashboardFilterService: HccDashboardFilterService,
    private userAccessService: UserAccessService,
    private hccDashboardTierMetricsService: HccDashboardTierMetricsService,
    private route: ActivatedRoute,
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
      if (tier != null && tier.tier != 1) {
        this.resetCharts();
        this.getTierMetrics(tier.tier, tier.selectedTierId);
        this.getOpportunities(tier.tier, tier.selectedTierId);
      }
    });

    this.userAccessService.currentAccess$.pipe(takeUntil(this.killTrigger)).subscribe(access => {
      this.canAccessPhi = access?.phi;
    });

    this.diseaseGroups$ =
      combineLatest([this.hierarchyTierService.currentTier$, this.hccDashboardFilterService.filterModel$]).pipe(
        takeUntil(this.killTrigger),
        switchMap(x => {
          const [tierInfo, filterValue] = x;
          const tierNum = filterValue?.isCinSelected ? -1 : tierInfo.tier;
          const tierId = filterValue?.isCinSelected ? filterValue.selectedCin?.id.toString() : tierInfo.selectedTierId;

          return this.hccDashboardService.hccDashboardDiseaseGroupsGet(
            {
              tierNum: tierNum,
              tierId: tierId,
              attributionStatus: filterValue == null || !filterValue.isMostAssignable ? ATTRIBUTED : MOST_ASSIGNABLE
            }
          );
        }));
  }

  formatValue = (value: any) => {
    const roundedValue = Math.round(value);
    return `${roundedValue}%`;
  }

  resetCharts(): void {
    this.codingOpportunities = [];
    this.annualClosureRate = [];
    this.acoAverage = 0;
  }

  getTierMetrics(tierNum: number, tierId: string): void {
    this.hccDashboardTierMetricsService.getMetrics(tierNum, tierId).subscribe(x => {
      let currentYearMetrics = x.find(metric => metric.currentYear);

      if (currentYearMetrics == null) {
        currentYearMetrics = {
          acoClosureRate: null, conditionCount: null, currentYear: null, q1ClosureRate: null,
          q1Goal: null, q2ClosureRate: null, q2Goal: null, q3ClosureRate: null, q3Goal: null, q4ClosureRate: null,
          q4Goal: null, remediation: null, tierClosureRate: null, lastUpdateDate: null
        };
      }

      const metricsResults = [{ name: 'Tier Closure Rate', value: currentYearMetrics.tierClosureRate }];
      this.annualClosureRate = [...metricsResults];
      this.acoAverage = currentYearMetrics.acoClosureRate;
    });
  }

  getOpportunities(tierNum: number, tierId: string): void {
    this.opportunitiesService.getOpportunites(tierNum, tierId).subscribe(x => {
      this.codingOpportunities = x;
    });
  }

  navigateToPatientDetails(): void {
    this.gridFilterService.resetFilters(PATIENT_DETAIL_REPORT_GRID_KEY);
  }

  navigateToTop50(): void {
    const path = '../../../care/patients';

    this.router.navigate([path], { relativeTo: this.route.parent, queryParams: { filterPreset: CarePatientItem.HccTop50 } });
  }

}
