import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PatientService, ITrackApiPatient } from '@shared/services/patient/patient.service';
import { Patient } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { CareFeatureConstants } from '@shared/models/module-constants';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { AuditService } from '@shared/services/audit-service/audit.service';

@Component({
  selector: 'coach-patient-utilization',
  templateUrl: './patient-utilization.component.html',
  styleUrls: ['./patient-utilization.component.scss']
})
export class PatientUtilizationComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input() public tp: ITrackApiPatient;
  public loading = true;
  today: moment.Moment;
  public facesheet: any = {};
  public costUtilization: any = {};
  public isLoaded = false;
  constructor(
    private _patientService: PatientService,
    private auditService: AuditService
  ) {
    this.today = moment();
  }


  ngOnInit(): void {
    if (this.patient) this.loading = true;
  }

  ngOnChanges() {
    this.loadHealthData();
  }

  private loadHealthData() {
    if (this.patient) {
      if (this.patient?.trackPatient?.chPatId) {
        forkJoin({
          reqFacesheet: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            ''
          ),
          reqCostUtilization: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'costUtilization'
          ),
        }).subscribe(
          ({
            reqFacesheet,
            reqCostUtilization
          }) => {
            this.facesheet = reqFacesheet || {};
            this.costUtilization = reqCostUtilization || {};
          },
          (errors) => {
            console.log('ERROR =>', errors);
          },
          () => {
            this.isLoaded = true;
          }
        );

      } else {
        this.isLoaded = false;
      }

    }
  }
  toTitleCase(str: string): string {
    if(!str) return '';
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  isNumber(element: any): boolean {
    return !isNaN(element);
  }
} 
