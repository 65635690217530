import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { PRIMARY_OUTLET, UrlTree } from '@angular/router';
// import { ActivatedRouteService } from '@shared/services/activated-route/activated-route.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderCondensedService {

  public isHeaderCondensed$: BehaviorSubject<boolean>;
  public collapseSidebar$: BehaviorSubject<boolean>;
  
  constructor(
    // private _activatedRoute: ActivatedRouteService,
  ) {
    
    this.isHeaderCondensed$ = new BehaviorSubject(false);
    this.collapseSidebar$ = new BehaviorSubject(null);

    // this._activatedRoute.currentUrl$.subscribe(
    //   ( url: UrlTree ) : void => {
    //     this.condenseHeaderOnDeepRoute(url);
    //   }
    // );
  }

  public setHeaderCondensed(isCondensed: boolean) {
    this.isHeaderCondensed$.next(isCondensed);
  }
  public setSidebarStatus(isCollapsed: boolean) {
    this.collapseSidebar$.next(isCollapsed);
  }
  
  // public condenseHeaderOnDeepRoute(url: UrlTree) {
  //   if (url) {
  //     var segmentCount = url.root.children[PRIMARY_OUTLET].segments.length;
  //     this.isHeaderCondensed$.next((segmentCount > 2) ? true : false);
  //   }
  // }
}
