<div class="dropdown u-max-height--largest">
  <div class="header row--verticalCenter">
    <div class="tabs tabs--secondary u-fill">
      <div
        *hasModule="careModule"
        class="tab"
        [ngClass]="{ 'is-active': selectedTab == 'patients' }"
        (click)="setTab('patients')"
      >
        Patients
      </div>
      <div
        class="tab"
        [ngClass]="{ 'is-active': selectedTab == 'files' }"
        (click)="setTab('files')"
      >
        Files
      </div>
    </div>
    <div *ngIf="selectedTab == 'patients'">
      <input
        class="toggle"
        id="advancedToggle"
        type="checkbox"
        (click)="toggleAdvancedSearch()"
        [(ngModel)]="advancedSearch"
      />
      <label for="advancedToggle">Advanced</label>
    </div>
  </div>
  <div class="padding">
    <ng-container *ngIf="selectedTab == 'patients' && hasCareModule">
      <ng-container *ngIf="advancedSearch">
        <form [formGroup]="patientAdvancedSearch" (ngSubmit)="submit()">
          <div class="row row--verticalCenter">
            <div class="u-fill margin-right--small">
              <div class="row margin-bottom--small">
                <input
                  class="u-fill"
                  name="name"
                  type="search"
                  placeholder="Last Name, First"
                  #primarySearchField
                  formControlName="name"
                />
              </div>
              <div class="row">
                <input
                  class="u-fill padding--small"
                  name="dob"
                  type="date"
                  placeholder="mm/dd/yyyy"
                  formControlName="dob"
                />
                <input
                  class="u-fill margin-left--smaller"
                  name="mbi"
                  type="search"
                  placeholder="xxxx-xxx-xxxx"
                  formControlName="mbi"
                  minlength="11"
                  maxlength="13"
                />
              </div>
            </div>
            <button
              class="button u-rounded padding--none u-justify-content--center"
              [disabled]="!patientAdvancedSearch.valid"
              (click)="submit()"
              data-cy="patientAdvancedSearchButton"
            >
              <coach-icon iconName="search"></coach-icon>
            </button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="!advancedSearch">
        <form [formGroup]="patientSearch" (ngSubmit)="submit()">
          <div class="search u-rounded">
            <input
              name="patient"
              placeholder="Search patients..."
              type="search"
              #primarySearchField
              formControlName="searchTerm"
              required
            />
            <button
              class="button"
              (click)="submit()"
              type="submit"
              [disabled]="!patientSearch.valid"
              data-cy="patientSearchButton"
            >
              <coach-icon iconName="search"></coach-icon>
            </button>
          </div>
        </form>
      </ng-container>

      <div
        *ngIf="patientSearchResults == null"
        class="u-italic u-opacity--light margin-top u-font-size--small padding padding-top--smaller"
      >
        Search by Name (last, first) or by DOB (mm/dd/yyyy) or by MBI
        (xxxx-xxx-xxxx) and then press
        <span class="u-bold">&#60;Enter&#62;</span> to search
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'files'">
      <form [formGroup]="fileSearch" (ngSubmit)="submit()">
        <div class="search u-rounded">
          <input
            name="file"
            placeholder="Search files..."
            type="search"
            formControlName="file"
            #primarySearchField
            required
          />
          <button
            class="button"
            type="submit"
            (click)="submit()"
            [disabled]="!fileSearch.valid"
          >
            <coach-icon iconName="search"></coach-icon>
          </button>
        </div>
      </form>
      <div
        class="u-italic u-opacity--light margin-top u-font-size--small padding padding-top--smaller"
      >
        Search for file names or other content and then press
        <span class="u-bold">&#60;Enter&#62;</span> to search
      </div>
    </ng-container>
  </div>
  <div class="dynamicBody">
    <ng-container *ngIf="performingPatientSearch()">
      <div
        class="row--verticalCenter padding padding-topBottom--smaller u-font-size--smaller u-background-color--black--4"
      >
        <div class="u-fill">
          {{ patientSearchResults.length }}
          {{ patientSearchResults.length == 1 ? "result" : "results" }}
        </div>
        <button
          (click)="resetPatientSearch()"
          class="button is-unstyled is-small"
        >
          clear
        </button>
      </div>
      <div class="list">
        <div
          class="list-item"
          *ngFor="let result of patientSearchResults; let idx = index"
          (click)="selectPatientResult(result)"
        >
          <div class="row" [attr.data-cy]="'patientSearchResult_' + idx">
            <div class="field">{{ result.fullName }}</div>
          </div>
          <div class="row">
            <div
              class="field margins-right--smallest"
              [innerHtml]="getFormattedPatientDemographicsData(result)"
            ></div>
            <div class="field">{{ result.mbi | mbi }}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        patientSearchResults !== null && patientSearchResults?.length === 0
      "
    >
      <div class="padding u-italic">No results found</div>
    </ng-container>
  </div>
</div>
