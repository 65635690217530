import { AwvWorkflow } from '@api/care-orch/models/awv-workflow';
import { Activity } from '@api/care-orch/models/activity';
import {
  ChangeType,
  Program,
  TierIds,
  WorkflowChangeEvent,
  WorkflowDeleteEvent,
  WorkflowEditEvent,
  WorkflowEventId,
  WorkflowSaveEvent
} from '@shared/services/mixpanel/events/workflow-events';
import { Injectable } from '@angular/core';
import { MixpanelService } from '@shared/services/mixpanel/mixpanel.service';
import { ActivityType } from '@api/care-orch/models/activity-type';

@Injectable({
  providedIn: 'root'
})
export class WorkflowEventsService {
  program: Program;
  chPatId: number;
  userEmail: string;
  startTime: number;
  changeType: ChangeType;
  activity: Activity;
  activityType: ActivityType;
  tierIds: TierIds;

  constructor(private mixpanel: MixpanelService) {}

  private clearTrackedState(): void {
    this.startTime = null;
    this.changeType = null;
    this.activity = null;
    this.activityType = null;
  }

  public trackProgramWithPatient(program: Program, chPatId: number, tierIds: TierIds, userEmail: string): void {
    this.clearTrackedState();
    this.program = program;
    this.chPatId = chPatId;
    this.tierIds = tierIds;
    this.userEmail = userEmail;
  }

  public trackSave(activity: Activity): void {
    this.changeType = ChangeType.SAVE;
    this.startTime = Date.now();
    this.activity = activity;
  }

  public trackEdit(newActivity: Activity): void {
    this.changeType = ChangeType.EDIT;
    this.startTime = Date.now();
    this.activity = newActivity;
  }

  public trackDelete(activityType: ActivityType): void {
    this.changeType = ChangeType.DELETE;
    this.startTime = Date.now();
    this.activityType = activityType;
  }

  public emit(oldWorkflow: AwvWorkflow, newWorkflow: AwvWorkflow, error?: any): void {
    const durationInMs = Date.now() - this.startTime;

    let event: WorkflowChangeEvent;
    let eventId: string;
    switch (this.changeType) {
      case ChangeType.SAVE:
        event = new WorkflowSaveEvent(this.program, this.chPatId, this.userEmail,
          oldWorkflow.status, newWorkflow.status, durationInMs, this.tierIds, error, this.activity);
        eventId = WorkflowEventId.WORKFLOW_SAVE;
        break;
      case ChangeType.EDIT:
        const oldActivity = oldWorkflow.completedActivities.concat(oldWorkflow.inProgressActivities)
          .find((a) => a.type === this.activity.type);
        event = new WorkflowEditEvent(this.program, this.chPatId, this.userEmail,
          oldWorkflow.status, newWorkflow.status, durationInMs, this.tierIds,
          error, oldActivity, this.activity);
        eventId = WorkflowEventId.WORKFLOW_EDIT;
        break;
      case ChangeType.DELETE:
        event = new WorkflowDeleteEvent(this.program, this.chPatId, this.userEmail,
          oldWorkflow.status, newWorkflow.status, durationInMs, this.tierIds, error, this.activityType);
        eventId = WorkflowEventId.WORKFLOW_DELETE;
        break;
    }

    this.mixpanel.track(eventId, event);
    this.clearTrackedState();
  }
}

