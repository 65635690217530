import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HccCliniciansService } from './hcc-clinicians-service.service';
import { HccDashboardClinician } from './hcc-dashboard-clinician';

import { GridFilterService } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { CLINICIANS_REPORT_GRID_KEY, PATIENT_SUMMARY_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { environment } from 'src/environments/environment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { share, takeUntil } from 'rxjs/operators';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';

@Component({
  selector: 'coach-clinicians-report',
  templateUrl: './clinicians-report.component.html',
  host: {
    class: 'coach-clinicians-report'
  },
  providers: [MaskPipe]
})
export class CliniciansReportComponent extends PersistentGridFilters implements OnInit, OnDestroy {

  tierNum: number;
  tierId: string;
  isLoaded: boolean;

  columnDefs = [
    { headerName: 'Practice Name', field: 'practiceName', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Clinician', field: 'clinicianName', sortable: true, filter: true, resizable: true,
      cellRendererFramework: RouterLinkRendererComponent,
      cellRendererParams: {
        valueOverride: (data: HccDashboardClinician): string => {
          return data.clinicianNPI == null ? data.practiceName : data.clinicianName;
        },
        linkClicked: (data: HccDashboardClinician) => {
          if (this.userAccessService.currentAccess$.value?.phi) {
            this.gridFilterService.resetFilters(PATIENT_SUMMARY_GRID_KEY);

            let clinician: string;
            let queryParams: Params;

            if (data.clinicianNPI != null) {
              clinician = data.clinicianNPI;
            } else {
              clinician = data.practiceName;
              queryParams = { isPractice: true };
            }

            this.router.navigate(['patients', clinician], { relativeTo: this.route.parent, queryParams: queryParams });
          }
        },
        isDisabled: (data: HccDashboardClinician) => {
          return !this.userAccessService.currentAccess$.value?.phi;
        }
      }
    },
    {
      headerName: 'NPI', field: 'clinicianNPI', sortable: true, filter: true, resizable: true, valueFormatter: params => {
        const clinician: HccDashboardClinician = params.data;

        if (clinician == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(clinician.clinicianNPI, 6);
        }
        else {
          return clinician.clinicianNPI;
        }
      }, keyCreator: params => {
        if (environment.maskSensitiveData) {
          return this.mask.transform(params.value, 6);
        }
        else {
          return params.value;
        }
      }
    },
    { headerName: 'Specialty', field: 'clinicianSpecialty', sortable: true, filter: true, resizable: true },
    { headerName: 'Patient Count', field: 'patientCount', sortable: true, filter: true, resizable: true },
    {
      headerName: 'Chronic Conditions Closure Gaps', field: 'hccGaps', sortable: true, filter: true,
      sort: { direction: 'asc', priority: 0 }, resizable: true
    },
    {
      headerName: 'Overall Chronic Conditions Closure Rate', field: 'overallHCCClosureRate', sortable: true, filter: true,
      valueFormatter: (params: any) => {
        const clinician: HccDashboardClinician = params.data;
        return clinician == null ? null : Math.round(clinician.overallHCCClosureRate) + '%';
      }
    }
  ];

  rowData: any;

    public hccEnhanced2FeatureFlag = TrackFeatureConstants.HccDashboardEnhanced2FeatureFlag;

  constructor(private patientSummaryService: HccCliniciansService, private route: ActivatedRoute,
    private router: Router, private gridFilterService: GridFilterService,
    protected filterService: GridFilterService, private mask: MaskPipe, private featureFlagService: FeatureFlagService,
    private userAccessService: UserAccessService, private hierarchyTierService: HierarchyTierService) {
    super(filterService, CLINICIANS_REPORT_GRID_KEY);
  }

  ngOnInit(): void {
    const hasFeature = this.featureFlagService.hasFeatureFlag(this.hccEnhanced2FeatureFlag);

    if (!hasFeature) {
      this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger), share()).subscribe(tier => {
        if (tier != null) {
          this.tierNum = tier.tier;
          this.tierId = tier.selectedTierId;

          this.rowData = this.patientSummaryService.getClinicians(this.tierNum, this.tierId);
        }
      });

      this.userAccessService.currentAccess$.pipe(takeUntil(this.killTrigger), share()).subscribe(access => {
        this.isLoaded = access != null;
      });
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridColumnApi.getColumn('hccGaps').setSort('desc');
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    }

    params.api.sizeColumnsToFit();
  }
}
