<ng-container *ngIf="showModal" style="font-size: x-small">
  <coach-sub-fill-dialog [showDialog]="showSubFillDialog" [dialogMessage]="'Do you wish to reject all subsequent fills for this claim?

  '" (buttonClicked)="onSubFillButtonClicked($event)"></coach-sub-fill-dialog>

  <div class="overlay is-open">
    <div class="modal u-width--larger">
      <div class="header">
        <div class="titles">
          <div class="title" style="text-align: center">Reject Rx</div>
          <div
            class="subtitle"
            style="text-align: center; border-bottom: 2px solid lightgray"
          >
            Paste text into EMR and then reject
          </div>
        </div>
      </div>

      <div style="margin: auto; font-style: italic; font-size: small">
        <p style="color: red; font-weight: bold">
          This RX was not eligible for 340B due to the<br />following reason(s):
        </p>
        <ul>
          <!-- <li *ngIf="(prescriptionInfo.visit_Note_Missing || prescriptionInfo.visit_Note_Diagnosis === '') 
            && (prescriptionInfo.visit_Note_Missing || prescriptionInfo.visit_Note_Diagnosis === '')
            && (prescriptionInfo.cE_Related_Referral_Missing)">
            Unknown
          </li> -->

          <li *ngIf="(!prescriptionInfo.visit_Note_Missing) 
            && (!prescriptionInfo.cE_Visit_Note_Missing)
            && (!prescriptionInfo.cE_Related_Referral_Missing);else errorMessages">
            Unknown
          </li>

          <ng-template #errorMessages >
            <li *ngIf="prescriptionInfo.visit_Note_Missing">
              There was no visit for a Dx prior to
              {{ formatDate(selectedPatientInfo.firstFilled) }}
            </li>
            <li *ngIf="prescriptionInfo.cE_Visit_Note_Missing">
              There was no visit for a related Dx at a covered entity prior to
              {{ formatDate(selectedPatientInfo.firstFilled) }}
            </li>
            <li *ngIf="prescriptionInfo.cE_Related_Referral_Missing">
              There was no referral to {{ selectedPatientInfo.prescriberName }}
            </li>
            <li *ngIf="!prescriptionInfo.follow_Up_Plan">
              There was no follow-up plan
            </li>
          </ng-template>
        </ul>
      </div>

      <div class="dynamicBody padding" #noteContent>
        <strong><u>CARE COORDINATION NOTE</u></strong
        ><br />
        Patient Name: {{ patientName }}<br />
        DOB: {{ patientDobText }}<br />
        MBI: {{ patientMbi | mask: 6 }}<br />
        By: {{ getLoggedInUserName() }}<br />
        Date: {{ formatDate(getTodaysDate()) }}<br /><br />

        <u>340B External Prescription</u><br />
        <strong>{{ selectedPatientInfo.prescriptionName }}</strong
        ><br />
        by {{ selectedPatientInfo.prescriberName }}<br />
        First filled {{ formatDate(selectedPatientInfo.firstFilled)
        }}<br /><br />

        <div *ngIf="!prescriptionInfo.visit_Note_Missing">
          <u>Visit Note from {{ selectedPatientInfo.prescriberName }}</u
          ><br />

          <div>
            <p>
              Visit Date: {{ formatDate(prescriptionInfo.visit_Note_Visit_Date)
              }}<br />
              Rx Diagnosis: {{ prescriptionInfo.visit_Note_Diagnosis }}
            </p>
          </div>
        </div>

        <div *ngIf="!prescriptionInfo.cE_Visit_Note_Missing">
          <p style="text-decoration: underline; margin-bottom: 1px">
            Related Visit By Covered Entity:
          </p>
          <span>
            Visit Date: {{ formatDate(prescriptionInfo.cE_Visit_Date) }}<br />
            Provider: {{ prescriptionInfo.cE_Provider }}<br />
            CE Location: {{ prescriptionInfo.cE_Location }}<br />
          </span>
        </div>
        <br />

        <div *ngIf="!prescriptionInfo.cE_Related_Referral_Missing">
          <p style="text-decoration: underline; margin-bottom: 1px">
            Related Referral By Covered Entity:
          </p>
          <span>
            Referral Date:
            {{ formatDate(prescriptionInfo.cE_Related_Referral_Date) }}<br />
          </span>
        </div>
        <br />

        <div *ngIf="prescriptionInfo.follow_Up_Plan">
          <p style="text-decoration: underline; margin-bottom: 1px">
            Follow-Up Plan:
          </p>
          <span [innerHTML]="formattedFollowUpPlan"><br /> </span>
        </div>
        <br />
      </div>
      <div
        class="footer"
        style="background-color: #eee; justify-content: space-around"
      >
        <button class="border--none" (click)="toggleVisitNoteModal()">
          Cancel
        </button>
        <button class="button border--4" (click)="copyNote()">Copy Text</button>
        <button class="button is-primary" (click)="toggleSubFillDialog()">Reject</button>
      </div>
    </div>
  </div>
</ng-container>
