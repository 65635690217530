import { KillSubscriptions } from '../kill-subscriptions';

export abstract class CoachGridBase extends KillSubscriptions {
  public gridApi: any;
  public gridColumnApi: any;

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSizeChanged(params: any): void {
    this.gridApi?.sizeColumnsToFit();
  }

  onModelUpdated(): void {
    this.gridApi?.sizeColumnsToFit();
  }
}
