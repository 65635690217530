import { formatDate } from '@angular/common';
import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HccFacesheetScheduleService } from '../hcc-facesheet-schedule.service';

@Component({
  selector: 'coach-facesheet-schedule-filters',
  templateUrl: './facesheet-schedule-filters.component.html',
  styleUrls: ['./facesheet-schedule-filters.component.scss']
})
export class FacesheetScheduleFiltersComponent implements OnInit {

  scheduleForm: UntypedFormGroup;
  minDate: string = formatDate(new Date(), 'MM/dd/yyyy', this.locale);

  constructor(private formBuilder: UntypedFormBuilder, 
              public hccFacesheetScheduleService: HccFacesheetScheduleService,
              @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
    this.scheduleForm = this.formBuilder.group({
      fromDate: this.hccFacesheetScheduleService.fromDate,
      toDate: this.hccFacesheetScheduleService.toDate
    });
  }

  loadSchedules(): void {
    this.hccFacesheetScheduleService.loadSchedules$.next();
  }

  onFromDateChange(event: any): void {
    this.minDate = formatDate(event.target.value, 'MM/dd/yyyy', this.locale);
    const fromDate = new Date(this.minDate);
    const toDate = new Date(this.scheduleForm.controls.toDate.value);

    if (fromDate > toDate) {
      this.scheduleForm.controls.toDate.setValue(event.target.value);
    }
  }

}
