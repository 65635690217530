import { CaravanPracticeDropdown, CaravanSpecialStatus } from '@shared/models/forms/forms-backend';

export const EHR_VENDORS: string[] = [
  'Allscripts',
  'AthenaHealth',
  'Cerner',
  'CPSI',
  'eClinicalWorks',
  'e-MDs',
  'Epic',
  'GE Healthcare',
  'Greenway Health',
  'Healthland',
  'Meditech',
  'NextGen',
  'Other'
];

export const PATIENT_POP_DATA_SOURCES: string[] = [
  'From EHR',
  // 'Manual Abstraction on Random Patients',
  // 'Lightbeam Clinical Interface'
];

export const PATIENT_POP_DATA_TYPES: string[] = [
  'All Payors',
  // 'Medicare' untill end of 2021
];

export const MOCK_PRACTICES: CaravanPracticeDropdown[] = [
  {
    Fac_ID: 123456,
    Name: 'Tingen Family Care',
  },
  {
    Fac_ID: 234567,
    Name: 'Practice Two',
  },
  {
    Fac_ID: 345678,
    Name: 'Practice Three',
  }
];

export const FORMS_SPECIAL_STATUSES: CaravanSpecialStatus[] = [
  {
    specialStatusID: 1,
    formID: 1,
    specialStatus: 'N/A',
    isDeleted: 0,
    isVisible: 0
  },
  {
    specialStatusID: 2,
    formID: 1,
    specialStatus: 'ASC Based - Special Status',
    isDeleted: 1,
    isVisible: 1
  },
  {
    specialStatusID: 3,
    formID: 1,
    specialStatus: 'Can\'t Access Data',
    isDeleted: 1,
    isVisible: 1
  },
  {
    specialStatusID: 4,
    formID: 1,
    specialStatus: 'Empty TIN',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 5,
    formID: 1,
    specialStatus: 'Exclusion - Vendor Having Issues',
    isDeleted: 1,
    isVisible: 1
  },
  {
    specialStatusID: 6,
    formID: 1,
    specialStatus: 'Hospital-based',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 7,
    formID: 1,
    specialStatus: 'In Process of Upgrade',
    isDeleted: 1,
    isVisible: 1
  },
  {
    specialStatusID: 8,
    formID: 1,
    specialStatus: 'Non-Patient Facing',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 9,
    formID: 1,
    specialStatus: 'Small Practice',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 10,
    formID: 1,
    specialStatus: 'Small Practice Waiver - Received',
    isDeleted: 1,
    isVisible: 1
  },
  {
    specialStatusID: 11,
    formID: 1,
    specialStatus: 'Use a Registry for Reporting',
    isDeleted: 1,
    isVisible: 1
  },
  {
    specialStatusID: 12,
    formID: 1,
    specialStatus: 'Other Reason(s)',
    isDeleted: 0,
    isVisible: 0
  },
  {
    specialStatusID: 13,
    formID: 2,
    specialStatus: 'N/A',
    isDeleted: 0,
    isVisible: 0
  },
  {
    specialStatusID: 14,
    formID: 2,
    specialStatus: 'EHR Can\'t Produce Report',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 15,
    formID: 2,
    specialStatus: 'Merged TIN',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 16,
    formID: 2,
    specialStatus: 'Empty TIN',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 17,
    formID: 2,
    specialStatus: 'Using Paper Charts',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 18,
    formID: 2,
    specialStatus: 'Hospital-based',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 19,
    formID: 2,
    specialStatus: 'No Part B',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 20,
    formID: 2,
    specialStatus: 'In Process of EHR Upgrade',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 21,
    formID: 3,
    specialStatus: '',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 22,
    formID: 2,
    specialStatus: 'Not Primary Care',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 23,
    formID: 2,
    specialStatus: 'Other Reason(s)',
    isDeleted: 0,
    isVisible: 0
  },
  {
    specialStatusID: 25,
    formID: 1,
    specialStatus: 'Merged TIN',
    isDeleted: 0,
    isVisible: 1
  },
  {
    specialStatusID: 26,
    formID: 2,
    specialStatus: 'EHR Can\'t Produce Report - Manual Abstraction',
    isDeleted: 0,
    isVisible: 0
  }
];
