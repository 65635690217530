/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AwvOppScheduleStatus } from '../models/awv-opp-schedule-status';
import { AwvWorklistMap } from '../models/awv-worklist-map';
import { InsertOrUpdateAwvScheduleRequest } from '../models/insert-or-update-awv-schedule-request';

@Injectable({
  providedIn: 'root',
})
export class AwvWorklistService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation awvWorklistGet
   */
  static readonly AwvWorklistGetPath = '/AwvWorklist';

  /**
   * Load the AWV Worklist.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awvWorklistGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awvWorklistGet$Response(params: {

    /**
     * Tier number (2 = Community, 3 = Practice)
     */
    TierNum: number;

    /**
     * Community or Practice ID
     */
    TierId: string;

    /**
     * Start of Date Range
     */
    BeginDate?: string;

    /**
     * End of Date Range
     */
    EndDate?: string;

    /**
     * Type of visit - AWV or IPPE
     */
    AwvType?: string;

    /**
     * Opportunity Status - To Do, Scheduled, or NULL for All
     */
    AwvOppStatus?: string;

    /**
     * DOB from Patient Search
     */
    DateOfBirth?: string;

    /**
     * Search term to use for searching on patient name
     */
    NameSearchTerm?: string;

    /**
     * ID of the component from which the request is being made. Defaults to AWV Worklist component.
     */
    ComponentId?: number;

    /**
     * Patient identifier
     */
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AwvWorklistMap>>> {

    const rb = new RequestBuilder(this.rootUrl, AwvWorklistService.AwvWorklistGetPath, 'get');
    if (params) {
      rb.query('TierNum', params.TierNum, {});
      rb.query('TierId', params.TierId, {});
      rb.query('BeginDate', params.BeginDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('AwvType', params.AwvType, {});
      rb.query('AwvOppStatus', params.AwvOppStatus, {});
      rb.query('DateOfBirth', params.DateOfBirth, {});
      rb.query('NameSearchTerm', params.NameSearchTerm, {});
      rb.query('ComponentId', params.ComponentId, {});
      rb.query('ChPatId', params.ChPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AwvWorklistMap>>;
      })
    );
  }

  /**
   * Load the AWV Worklist.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awvWorklistGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awvWorklistGet(params: {

    /**
     * Tier number (2 = Community, 3 = Practice)
     */
    TierNum: number;

    /**
     * Community or Practice ID
     */
    TierId: string;

    /**
     * Start of Date Range
     */
    BeginDate?: string;

    /**
     * End of Date Range
     */
    EndDate?: string;

    /**
     * Type of visit - AWV or IPPE
     */
    AwvType?: string;

    /**
     * Opportunity Status - To Do, Scheduled, or NULL for All
     */
    AwvOppStatus?: string;

    /**
     * DOB from Patient Search
     */
    DateOfBirth?: string;

    /**
     * Search term to use for searching on patient name
     */
    NameSearchTerm?: string;

    /**
     * ID of the component from which the request is being made. Defaults to AWV Worklist component.
     */
    ComponentId?: number;

    /**
     * Patient identifier
     */
    ChPatId?: number;
  },
  context?: HttpContext

): Observable<Array<AwvWorklistMap>> {

    return this.awvWorklistGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AwvWorklistMap>>) => r.body as Array<AwvWorklistMap>)
    );
  }

  /**
   * Path part for operation awvWorklistPost
   */
  static readonly AwvWorklistPostPath = '/AwvWorklist';

  /**
   * Create or edit scheduled date.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awvWorklistPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  awvWorklistPost$Response(params?: {
    body?: InsertOrUpdateAwvScheduleRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AwvWorklistService.AwvWorklistPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create or edit scheduled date.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awvWorklistPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  awvWorklistPost(params?: {
    body?: InsertOrUpdateAwvScheduleRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.awvWorklistPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation awvWorklistStatusGet
   */
  static readonly AwvWorklistStatusGetPath = '/AwvWorklist/status';

  /**
   * Get AWV Worklist Schedule Statuses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awvWorklistStatusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awvWorklistStatusGet$Response(params?: {
    isEnabled?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AwvOppScheduleStatus>>> {

    const rb = new RequestBuilder(this.rootUrl, AwvWorklistService.AwvWorklistStatusGetPath, 'get');
    if (params) {
      rb.query('isEnabled', params.isEnabled, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AwvOppScheduleStatus>>;
      })
    );
  }

  /**
   * Get AWV Worklist Schedule Statuses.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awvWorklistStatusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awvWorklistStatusGet(params?: {
    isEnabled?: boolean;
  },
  context?: HttpContext

): Observable<Array<AwvOppScheduleStatus>> {

    return this.awvWorklistStatusGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AwvOppScheduleStatus>>) => r.body as Array<AwvOppScheduleStatus>)
    );
  }

}
