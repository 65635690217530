<ag-grid-angular 
  coachAgGridResize 
  class="ag-theme-material schedule-grid dynamicBody"
  row-animation
  [pagination]="true" 
  [paginationAutoPageSize]="true"
  multiSortKey="ctrl" 
  [rowData]="rowData | async" 
  [columnDefs]="columnDefs" 
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="onFirstDataRendered($event)" 
  [rowSelection]="'multiple'" 
  [isRowSelectable]="isRowSelectable"  
  (filterChanged)="agFilterChanged($event)"
  (selectionChanged)="onSelectionChanged($event)" 
  (columnMoved)="onColumnUpdated()" 
  (sortChanged)="onColumnUpdated()"
  [localeText]="localeText" 
  [overlayNoRowsTemplate]="loadingStatus?'Loading':'No data'"
  [overlayLoadingTemplate]="'Loading'"
  (rowDataChanged)="onRowDataChanged()"
  >
</ag-grid-angular>