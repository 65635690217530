import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import {
  BARRIERS_DATA,
  SUCCESSES_DATA,
  QUARTERS_DATA,
  MONTHS_DATA,
} from './submit-meeting';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { UserInfo } from '@shared/services/auth-service/auth-interface';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { FormTierDropdown } from '@shared/models/forms/forms-backend';
import { ToastrService } from 'ngx-toastr';
import {
  SubmitFeatureConstants,
  SubmitFeatureNames,
} from '@shared/models/module-constants';
import { FormConstants } from '@shared/models/forms/form-constants';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'coach-submit-meeting',
  templateUrl: './submit-meeting.component.html',
})
export class SubmitMeetingComponent implements OnInit, OnDestroy {
  submitMeetingName = SubmitFeatureNames.Meeting;

  public principleParticipants: FormTierDropdown[] = [];
  public selectedCommunity;
  public ppSet: boolean;

  get successesFormArray(): any {
    return this.successesForm.get('successes') as UntypedFormArray;
  }

  get barriersFormArray(): any {
    return this.barriersForm.get('barriers') as UntypedFormArray;
  }

  public selectedPP;

  // Form
  public meetingForm: UntypedFormGroup;
  public uploadedAgendaFileName = 'No file chosen';
  public uploadedMinutesFileName = 'No file chosen';

  // Sub forms
  public successesForm: UntypedFormGroup;
  public barriersForm: UntypedFormGroup;

  // Form Subscriptions
  public successesFormSub: Subscription;
  public barriersFormSub: Subscription;

  // Subscriptions
  public authSub: Subscription;
  public userSub: Subscription;

  // Form Data
  public currentTier: IHierarchyTier;
  public barriersList = BARRIERS_DATA;
  public successesList = SUCCESSES_DATA;
  public quartersData = QUARTERS_DATA;
  public monthsData = MONTHS_DATA;

  public hasOtherBarriers = false;
  public otherBarriers: string;
  public hasOtherSuccesses = false;
  public otherSuccesses: string;

  // UI Settings
  public isQueueOpen = true;
  public formSaving = false;

  // User Info
  public isAuthenticated$: Observable<boolean>;
  public userInfo$: Observable<UserInfo>;
  private _isLoggedIn = false;
  private _currentUser: UserInfo;

  public isInfoTextOpen = false;
  public infoText: string;
  public infoMeasureLinkFile: string;

  public selectCommunity = FormConstants.MeetingCommunitySelection;

  public readonly principleParticipantHelpText =
    'Lists names of Principle Participants in the Community. To complete data for a given Principle Participant, select the Name in the drop down.';

  @ViewChild('meetingForm_community') meetingForm_community: ElementRef;
  @ViewChild('meetingForm_otherSuccesses')
  meetingForm_otherSuccesses: ElementRef;
  @ViewChild('meetingForm_otherBarriers') meetingForm_otherBarriers: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private hierarchyTierService: HierarchyTierService,
    private qualityService: SubmitFormsService,
    private toastr: ToastrService
  ) {
    this.resetCheckboxes();
  }

  toggleQueueOpen(): void {
    this.isQueueOpen = !this.isQueueOpen;
  }

  private addSuccessCheckboxes(): void {
    this.successesList.forEach(() =>
      this.successesFormArray.push(new UntypedFormControl(false))
    );
  }

  private addBarriersCheckboxes(): void {
    this.barriersList.forEach(() =>
      this.barriersFormArray.push(new UntypedFormControl(false))
    );
  }

  public initializeMeetingForm(): void {
    function quarterOfTheYear(date): number {
      const month = date.getMonth() + 1;
      return Math.ceil(month / 3);
    }

    const newDate = new Date();
    const userID = this._currentUser?.dnn_id || null;
    const userEmail = this._currentUser?.email || null;
    const nowJson = newDate.toJSON();
    const nowQtr = quarterOfTheYear(newDate);

    this.meetingForm = this.formBuilder.group({
      FormID: [5],
      FormVersionID: [4],
      FormName: ['MEETING'],
      FormCategoryID: [1],
      Email: [userEmail], // set
      Tier3_ID: [null, Validators.required], // set
      Tier3_Name: [null, Validators.required], // set
      SpecialStatus: [null],
      RegReportOne: [null],
      RegReportTwo: [null],
      FormQuarter: [nowQtr],
      CEHRT: [2021],
      FormCreateDateTime: [nowJson],
      CreatedByUserID: [userID],
      ClosedDateTime: [null],
      LastUpdateUserID: [userID],
      LastUpdateDateTime: [nowJson],
      isComplete: [0],
      TotalPoints: [0],
      UserFormID: [null],
      UserID: [userID],
      FormQ51: this.formBuilder.group({
        QuestionID: [51],
        QuestionText: ['Community Name'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null, Validators.required], // set
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ52: this.formBuilder.group({
        QuestionID: [52],
        QuestionText: ['Number of Roadmaps'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null], // set
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ53: this.formBuilder.group({
        QuestionID: [53],
        QuestionText: ['Cohort Quarter'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null], // set
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ54: this.formBuilder.group({
        QuestionID: [54],
        QuestionText: ['Roadmap Month'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ55: this.formBuilder.group({
        QuestionID: [55],
        QuestionText: ['Roadmap Successes'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ56: this.formBuilder.group({
        QuestionID: [56],
        QuestionText: ['Other Roadmap Successes'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null], // set
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ57: this.formBuilder.group({
        QuestionID: [57],
        QuestionText: ['Roadmap Bariers'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null], // set
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ58: this.formBuilder.group({
        QuestionID: [58],
        QuestionText: ['Other Roadmap Barriers'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ59: this.formBuilder.group({
        QuestionID: [59],
        QuestionText: ['Cohort Successes'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null], // set
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ60: this.formBuilder.group({
        QuestionID: [60],
        QuestionText: ['Cohort Barriers'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      FormQ61: this.formBuilder.group({
        QuestionID: [61],
        QuestionText: ['Other Cohort Barriers'],
        FormQCategoryID: [2], // meeting
        LastUpdateDateTime: [nowJson], // Date
        LastUpdateUserID: [userID],
        FormQResponses: this.formBuilder.array([
          this.formBuilder.group({
            UserResponse: [null],
            ResponsePointValue: [null],
            FormResponseDataTypeID: [3],
            FormResponseTypeID: [7],
            ResponseDateTime: [nowJson], // Date
            ResponseUserID: [userID],
            LastUpdateDateTime: [nowJson], // Date
            LastUpdateUserID: [userID],
          }),
        ]),
      }),
      // FormQ62: this.formBuilder.group({
      //   QuestionID: [62],
      //   QuestionText: ['Minutes'],
      //   FormQCategoryID: [2], // meeting
      //   LastUpdateDateTime: [nowJson], // Date
      //   LastUpdateUserID: [userID],
      //   FormQResponses: this.formBuilder.array([
      //     this.formBuilder.group({
      //       UserResponse: [null, Validators.required],
      //       ResponsePointValue: [null],
      //       FormResponseDataTypeID: [3],
      //       FormResponseTypeID: [7],
      //       ResponseDateTime: [nowJson], // Date
      //       ResponseUserID: [userID],
      //       LastUpdateDateTime: [nowJson], // Date
      //       LastUpdateUserID: [userID],
      //     })
      //   ])
      // }),
      // FormQ63: this.formBuilder.group({
      //   QuestionID: [63],
      //   QuestionText: ['Agenda'],
      //   FormQCategoryID: [2], // meeting
      //   LastUpdateDateTime: [nowJson], // Date
      //   LastUpdateUserID: [userID],
      //   FormQResponses: this.formBuilder.array([
      //     this.formBuilder.group({
      //       UserResponse: [null],
      //       ResponsePointValue: [null],
      //       FormResponseDataTypeID: [3],
      //       FormResponseTypeID: [7],
      //       ResponseDateTime: [nowJson], // Date
      //       ResponseUserID: [userID],
      //       LastUpdateDateTime: [nowJson], // Date
      //       LastUpdateUserID: [userID],
      //     })
      //   ])
      // }),
      type: ['monthly', [Validators.required]],
      // Quarterly Specific
      quarter: [''],
      brightSpot: [''],
      barriers: [''],
      // Monthly Specific
      month: [''],
      meetingCount: [''],
      successes: [''],
      // Attachments
      agenda: [''],
      minutes: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this._authService.isAuthenticated$;
    this.userInfo$ = this._authService.userInfo$;
    this.authSub = this.isAuthenticated$.subscribe(
      (isLoggedIn) => {
        this._isLoggedIn = isLoggedIn;
      },
      (error) => {}
    );
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      this._currentUser = userInfo;
    });

    this.hierarchyTierService.currentTier$?.subscribe((x) => {
      this.currentTier = x;
      this.selectedCommunity = this.currentTier.selectedItem.tierName;
    });

    this.initializeMeetingForm();

    this.qualityService
      .getListOfSpecificTier(null, SubmitFeatureConstants.MeetingFormFeature)
      .subscribe((data) => {
        this.setPrincipleParticipant(data);
      });

    this.successesFormSub = this.successesForm.valueChanges.subscribe(() => {
      this.updateSelectedSuccesses();
    });

    this.barriersFormSub = this.barriersForm.valueChanges.subscribe(() => {
      this.updateSelectedBarriers();
    });
  }

  updateSelectedSuccesses(): void {
    const selectedSuccesses = this.successesForm
      .get('successes')
      .value.map((selected, i) => (selected ? this.successesList[i] : null))
      .filter((p) => p !== null);

    let selectedSuccessCheckboxes;
    if (this.otherSuccesses && this.hasOtherSuccesses) {
      if (selectedSuccesses.length > 0) {
        selectedSuccessCheckboxes =
          selectedSuccesses.map((p) => p.value).toString() +
          ', ' +
          this.otherSuccesses;
      } else {
        selectedSuccessCheckboxes = this.otherSuccesses;
      }
    } else {
      selectedSuccessCheckboxes = selectedSuccesses
        .map((p) => p.value)
        .toString();
    }
    this.meetingForm.patchValue({ successes: selectedSuccessCheckboxes });
  }

  updateSelectedBarriers(): void {
    const selectedBarriers = this.barriersForm
      .get('barriers')
      .value.map((selected, i) => (selected ? this.barriersList[i] : null))
      .filter((p) => p !== null);

    let selectedBarriersCheckboxes;
    if (this.otherBarriers && this.hasOtherBarriers) {
      if (selectedBarriers.length > 0) {
        selectedBarriersCheckboxes =
          selectedBarriers.map((p) => p.value).toString() +
          ', ' +
          this.otherBarriers;
      } else {
        selectedBarriersCheckboxes = this.otherBarriers;
      }
    } else {
      selectedBarriersCheckboxes = selectedBarriers
        .map((p) => p.value)
        .toString();
    }
    this.meetingForm.patchValue({ barriers: selectedBarriersCheckboxes });
  }

  toggleOnFocusOtherSuccesses(): void {
    // Focuses the textarea upon checking, clears and updates checkbox array if unchecking
    setTimeout(() => {
      // this will make the execution after element is rendered in the DOM after change
      if (this.hasOtherSuccesses) {
        this.meetingForm_otherSuccesses.nativeElement.focus();
      } else {
        this.otherSuccesses = '';
        this.updateSelectedSuccesses();
      }
    }, 0);
  }

  toggleOnFocusOtherBarriers(): void {
    setTimeout(() => {
      // this will make the execution after element is rendered in the DOM after change
      if (this.hasOtherBarriers) {
        this.meetingForm_otherBarriers.nativeElement.focus();
      } else {
        this.otherBarriers = '';
        this.updateSelectedBarriers();
      }
    }, 0);
  }

  public onAgendaFileChange(event): void {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.meetingForm.patchValue({ agenda: file });
      this.uploadedAgendaFileName = file.name || 'File name not found';
    } else {
      this.resetUploadForm();
    }
    this.meetingForm.get('agenda').markAsTouched();
  }

  public onMinutesFileChange(event): void {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.meetingForm.patchValue({ minutes: file });
      this.uploadedMinutesFileName = file.name || 'File name not found';
    } else {
      this.resetUploadForm();
    }
    this.meetingForm.get('minutes').markAsTouched();
  }

  private resetUploadForm(): void {
    this.uploadedAgendaFileName = 'No file chosen';
    this.uploadedMinutesFileName = 'No file chosen';
    this.clearFileInput(document.getElementById('meeting-agenda'));
    this.clearFileInput(document.getElementById('meeting-minutes'));
    this.initializeMeetingForm();
    this.resetCheckboxes();
    this.meetingForm
      .get('FormQ51.FormQResponses.0.UserResponse')
      .setValue(this.selectedCommunity);
    if (
      this.principleParticipants != null &&
      this.principleParticipants.length == 1
    ) {
      this.setRequiredTierInfo(
        this.principleParticipants[0].TierID,
        this.principleParticipants[0].TierName
      );
    } else {
      this.selectedPP = null;
      this.ppSet = false;
    }
  }

  onSubmit(): void {
    if (this.meetingForm.invalid) {
      this.meetingForm.markAllAsTouched();
      console.error(
        'Submit Clicked: ' +
          String.fromCodePoint(0x1f525) +
          '%cMeeting Form is Invalid' +
          String.fromCodePoint(0x1f525),
        'font-weight: 700; text-transform: uppercase; color: red;'
      );
      this.toastr.warning('Failed To Save', 'Invalid Meeting Form');
    } else {
      this.toastr.info('Form Submitting', 'Meeting Form');

      console.log(
        String.fromCodePoint(0x1f389) +
          String.fromCodePoint(0x1f389) +
          ' %cMeeting Form is Valid !! :) Here is what we got: ' +
          String.fromCodePoint(0x1f389),
        'font-weight: 900; text-transform: uppercase; color: darkseagreen'
      );

      this.meetingForm
        .get('FormQ55.FormQResponses.0.UserResponse')
        .setValue(this.meetingForm.value.successes);

      if (this.meetingForm.value.type === 'quarterly') {
        // Cohort
        this.meetingForm.get('FormName').setValue('Quarterly Cohort Meeting');

        this.meetingForm
          .get('FormQ53.FormQResponses.0.UserResponse')
          .setValue(this.meetingForm.value.quarter);

        this.meetingForm
          .get('FormQ59.FormQResponses.0.UserResponse')
          .setValue(this.meetingForm.value.brightSpot);

        this.meetingForm
          .get('FormQ60.FormQResponses.0.UserResponse')
          .setValue(this.meetingForm.value.barriers);

        this.meetingForm.removeControl('FormQ55');
        this.meetingForm.removeControl('FormQ57');
        this.meetingForm.removeControl('FormQ54');
        this.meetingForm.removeControl('FormQ52');
      } else {
        // Roadmap
        this.meetingForm.get('FormName').setValue('Monthly Roadmap Meeting');

        this.meetingForm
          .get('FormQ52.FormQResponses.0.UserResponse')
          .setValue(this.meetingForm.value.meetingCount);

        this.meetingForm
          .get('FormQ54.FormQResponses.0.UserResponse')
          .setValue(this.meetingForm.value.month);

        this.meetingForm
          .get('FormQ55.FormQResponses.0.UserResponse')
          .setValue(this.meetingForm.value.successes);

        this.meetingForm
          .get('FormQ57.FormQResponses.0.UserResponse')
          .setValue(this.meetingForm.value.barriers);

        this.meetingForm.removeControl('FormQ59');
        this.meetingForm.removeControl('FormQ60');
        this.meetingForm.removeControl('FormQ53');
      }

      this.meetingForm.patchValue({ isComplete: 1 });

      const formCopy = Object.assign({}, this.meetingForm.getRawValue());

      if (formCopy.SpecialStatus == null) {
        formCopy.SpecialStatus = '';
      }

      this.formSaving = true;
      const uploadAgendaFile = this.meetingForm.get('agenda').value;
      const uploadMinutesFile = this.meetingForm.get('minutes').value;
      this.qualityService
        .save([uploadAgendaFile, uploadMinutesFile], formCopy)
        .pipe(finalize(() => (this.formSaving = false)))
        .subscribe(
          () => {
            this.toastr.success('Form Submitted', 'Meeting Form');
            this.resetUploadForm();
          },
          (err) => {
            console.error(err);
            this.toastr.error('Form Failed To Save', 'Meeting Form');
          }
        );
    }
  }

  resetCheckboxes(): void {
    this.successesForm = this.formBuilder.group({
      successes: this.formBuilder.array([]),
    });
    this.barriersForm = this.formBuilder.group({
      barriers: this.formBuilder.array([]),
    });
    this.addSuccessCheckboxes();
    this.addBarriersCheckboxes();
  }

  clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  ngOnDestroy() {
    this.successesFormSub.unsubscribe();
    this.barriersFormSub.unsubscribe();
  }

  public toggleInfoText(details: string): void {
    this.isInfoTextOpen = !this.isInfoTextOpen;
    if (details != null) {
      this.infoText = details;
    }
  }

  // Initial page load we get
  private setPrincipleParticipant(data: string): void {
    this.principleParticipants = JSON.parse(data);

    if (
      this.principleParticipants != null &&
      this.principleParticipants.length == 1
    ) {
      this.setRequiredTierInfo(
        this.principleParticipants[0].TierID,
        this.principleParticipants[0].TierName
      );
    }
  }

  public ppSelected(tierID: string): void {
    const pp = this.principleParticipants.find((x) => x.TierID === tierID);
    this.setRequiredTierInfo(pp.TierID, this.principleParticipants[0].TierName);
  }

  private setRequiredTierInfo(id: string, name: string) {
    this.meetingForm.get('Tier3_ID').setValue(id);
    this.meetingForm.get('Tier3_ID').markAsTouched({ onlySelf: true });
    this.meetingForm.get('Tier3_Name').setValue(name);
    this.meetingForm.get('Tier3_Name').markAsTouched({ onlySelf: true });
    this.meetingForm
      .get('FormQ51.FormQResponses.0.UserResponse')
      .setValue(this.selectedCommunity);
    this.ppSet = true;
  }
}
