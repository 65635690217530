import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { TrackClinicalComponent } from './track-clinical.component';
import { TrackClinicalRoutingModule } from './track-clincal-routing.module';
import { HccDashboardComponent } from './hcc-dashboard/hcc-dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CodingOpportunityChartComponent } from './hcc-dashboard/coding-opportunities/coding-opportunity-chart/coding-opportunity-chart.component';
import { QuarterlyMilestoneChartComponent } from './hcc-dashboard/quarterly-milestone-chart/quarterly-milestone-chart.component';
import { HccConditionChartComponent } from './hcc-dashboard/hcc-condition-chart/hcc-condition-chart.component';
import { HistoricalQuarterlyMilestonesComponent } from './hcc-dashboard/historical-quarterly-milestones/historical-quarterly-milestones.component';
import { CliniciansReportComponent } from './hcc-dashboard/dashboard-reports/clinicians-report/clinicians-report.component';
import { PatientSummaryReportComponent } from './hcc-dashboard/dashboard-reports/patient-summary-report/patient-summary-report.component';
import { PatientDetailReportComponent } from './hcc-dashboard/dashboard-reports/patient-detail-report/patient-detail-report.component';
import { AgGridModule } from 'ag-grid-angular';
import { TrackClinicalWidgetsComponent } from './track-clinical-widgets/track-clinical-widgets.component';
import { TrackTimeTrackingComponent } from './track-time-tracking/track-time-tracking.component';
import { TrackTimeTrackingFilterComponent } from './track-time-tracking/track-time-tracking-filter/track-time-tracking-filter.component';
import { HccDashboardSummaryComponent } from './hcc-dashboard/hcc-dashboard-summary/hcc-dashboard-summary.component';
import { CliniciansReport2Component } from './hcc-dashboard/dashboard-reports/clinicians-report/clinicians-report2/clinicians-report2.component';
import { PatientSummaryReport2Component } from './hcc-dashboard/dashboard-reports/patient-summary-report/patient-summary-report2/patient-summary-report2.component';
import { PatientDetailReport2Component } from './hcc-dashboard/dashboard-reports/patient-detail-report/patient-detail-report2/patient-detail-report2.component';
import { PracticesReportComponent } from './hcc-dashboard/dashboard-reports/practices-report/practices-report.component';
import { CinsReportComponent } from './hcc-dashboard/dashboard-reports/cins-report/cins-report.component';
import { HccTopDiseaseGroupsComponent } from './hcc-dashboard/hcc-dashboard-summary/hcc-top-disease-groups/hcc-top-disease-groups.component';
import { HccClosureRateChartComponent } from './hcc-dashboard/hcc-dashboard-summary/hcc-closure-rate-chart/hcc-closure-rate-chart.component';

@NgModule({
  declarations: [
    TrackClinicalComponent,
    HccDashboardComponent,
    CodingOpportunityChartComponent,
    QuarterlyMilestoneChartComponent,
    HccConditionChartComponent,
    HistoricalQuarterlyMilestonesComponent,
    CliniciansReportComponent,
    PatientSummaryReportComponent,
    PatientDetailReportComponent,
    TrackClinicalWidgetsComponent,
    TrackTimeTrackingComponent,
    TrackTimeTrackingFilterComponent,
    HccDashboardSummaryComponent,
    CliniciansReport2Component,
    PatientSummaryReport2Component,
    PatientDetailReport2Component,
    PracticesReportComponent,
    CinsReportComponent,
    HccTopDiseaseGroupsComponent,
    HccClosureRateChartComponent,
  ],
  imports: [
    SharedModule,
    TrackClinicalRoutingModule,
    NgxChartsModule,
    AgGridModule,
  ],
})
export class TrackClinicalModule {}
