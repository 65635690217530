import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { HccDashboardService } from '@api/track/services';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { HccDashboardFilterService } from '../../hcc-dashboard-filter.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';
import { HccDashboardClosureRate } from '@api/track/models';
import HccClosureRateDataItem from './hcc-closure-rate-data-item';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_animated from '@amcharts/amcharts5/themes/Animated';
import { getNameFromMonthNumber } from '@shared/utilities';
import { formatPercent } from '@angular/common';

@Component({
  selector: 'coach-hcc-closure-rate-chart',
  templateUrl: './hcc-closure-rate-chart.component.html',
  host: {
    class: 'coach-hcc-closure-rate-chart'
  }
})
export class HccClosureRateChartComponent extends KillSubscriptions implements OnInit, OnDestroy {

  closuresByQuarter: HccDashboardClosureRate[];
  closuresByMonth: HccDashboardClosureRate[];
  closuresTableData: HccClosureRateDataItem[][];
  closuresTableHeaderData: HccClosureRateDataItem[];

  public chartId = 'hcc-closure-rate-chart-' + Math.random();
  private chart: am5xy.XYChart;
  private chartRoot: am5.Root;
  private xAxis: am5xy.Axis<am5xy.AxisRenderer>;
  private yAxis: am5xy.Axis<am5xy.AxisRenderer>;
  private trendingSeries: am5xy.LineSeries;
  private compareSeries: am5xy.LineSeries;

  public readonly quarter = 'Quarter';
  public readonly month = 'Month';

  public toggleOptions: string[] = [this.quarter, this.month];
  public selectedToggle$ = new BehaviorSubject(this.quarter);
  public selectedToggle = this.quarter;

  public selectedCompareIndex = 0;


  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private hccDashboardService: HccDashboardService,
    private hccDashboardFilterService: HccDashboardFilterService,
    private hierarchyTierService: HierarchyTierService,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.hierarchyTierService.currentTier$, this.hccDashboardFilterService.filterModel$, this.selectedToggle$]).pipe(
      takeUntil(this.killTrigger),
      switchMap(x => {
        const [tierInfo, filterValue, compareTo] = x;
        const tierNum = filterValue?.isCinSelected ? -1 : tierInfo.tier;
        const tierId = filterValue?.isCinSelected ? filterValue.selectedCin?.id.toString() : tierInfo.selectedTierId;

        return this.hccDashboardService.hccDashboardClosureRatesGet(
          {
            TierNum: tierNum,
            TierId: tierId,
            AttributionStatus: filterValue == null || !filterValue.isMostAssignable ? ATTRIBUTED : MOST_ASSIGNABLE,
            Tier1Id: tierNum > 1 ? tierInfo.selectedItem.tier1_ID : null,
            Tier2Id: tierNum > 2 ? tierInfo.selectedItem.tier2_ID : null,
            Tier3Id: tierNum > 3 ? tierInfo.selectedItem.tier3_ID : null,
            DatePart: compareTo == null || compareTo.length === 0 ? null : compareTo[0]
          }
        );
      })).subscribe(x => {
        if (x.length < 3) {
          throw new Error(`Expected hccDashboardClosureRatesGet to return at least 3 result sets. Found ${x.length}.`);
        }

        this.selectedCompareIndex = 0;

        if (this.chartRoot) {
          this.chartRoot.dispose();
        }

        const hasAnyData = x.some(values => values.length > 0);

        if (hasAnyData) {
          this.closuresByQuarter = x[0];
          this.closuresByMonth = x[1];
          const closureData = x.slice(2);

          this.closuresTableData = closureData.map(array => array.map(x => {
            const item = x as HccClosureRateDataItem;
            item.tierTitleHtml = this.getTierName(item);
            return item;
          }).sort(this.sortByDateValue));

          this.selectedCompareIndex = this.closuresTableData.findIndex(x => x.length > 0);
          this.closuresTableHeaderData = this.closuresTableData[this.selectedCompareIndex];

          this.chart = this.configureChart();
        } else {
          this.closuresByQuarter = undefined;
          this.closuresByMonth = undefined;
          this.closuresTableData = undefined;
        }
      });
  }

  ngOnDestroy(): void {
    this.chart?.dispose();
  }

  getTierName(item: HccClosureRateDataItem): string {
    const currentTierItem = this.hierarchyTierService.currentTier$.value;
    let tierName: string;

    switch (item.tierID) {
      case currentTierItem.selectedTierId:
        tierName = 'Last Year';
        break;
      case currentTierItem.selectedItem.tier1_ID:
        tierName = 'ACO';
        break;
      case currentTierItem.selectedItem.tier2_ID:
        tierName = 'Community';
        break;
      case currentTierItem.selectedItem.tier3_ID:
        tierName = 'Participant';
        break;
    }

    const tierHtml = `<span class='u-bold'>${tierName}</span><span class='u-font-size--smallest'> (${item.dtYear})</span>`;

    return tierHtml;
  }

  private configureChart(): am5xy.XYChart {
    const root = this.chartRoot = am5.Root.new(this.chartId);

    root.setThemes([
      am5themes_animated.new(root)
    ]);

    const xyChart = am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      maxTooltipDistance: 0,
      pinchZoomX: true
    });

    const chart = root.container.children.push(xyChart);

    chart.get('colors').set('colors', [
      am5.color('#007D8A'),
      am5.color('#B3B2B0')
    ]);

    const axis = this.getXAxis(root);
    const xAxis = this.xAxis = chart.xAxes.push(axis);

    xAxis.get('renderer').grid.template.set('forceHidden', true);

    const yAxis = this.yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: 100,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    chart.set('cursor', am5xy.XYCursor.new(root, {}));

    // Closure rate trending line
    this.trendingSeries = this.getTrendingRateSeries(root, xAxis, yAxis);
    const series = chart.series.push(this.trendingSeries);

    // Compare to rate trending line
    this.compareSeries = this.getCompareToSeries(root, xAxis, yAxis);
    const series2 = chart.series.push(this.compareSeries);

    series.get('tooltip').label.adapters.add('html', (text, target) => {
      const closureItem: ClosureRateChartItem = target?.dataItem?.dataContext as ClosureRateChartItem;

      let tooltipText = '';

      if (this.selectedToggle === this.quarter) {
        tooltipText = `
          <div class="u-color--white">
            <div>${getNameFromMonthNumber(closureItem?.months[0]?.dtValue - 1)}: ${formatPercent(closureItem?.months[0]?.avgClosure, this.locale)}</div>
            <div>${getNameFromMonthNumber(closureItem?.months[1]?.dtValue - 1)}: ${formatPercent(closureItem?.months[1]?.avgClosure, this.locale)}</div>
            <div>${getNameFromMonthNumber(closureItem?.months[2]?.dtValue - 1)}: ${formatPercent(closureItem?.months[2]?.avgClosure, this.locale)}</div>
          </div>
        `;
      } else {
        tooltipText = `
          <div class="u-color--white">
            <div>${formatPercent(closureItem?.value / 100, this.locale)}</div>
          </div>
        `;
      }

      return tooltipText;
    });

    series.events.once('datavalidated', () => {
      series.bullets.push((root, series, dataItem) => {
        const closureItem: ClosureRateChartItem = dataItem?.dataContext as ClosureRateChartItem;

        const bullet = am5.Container.new(root, {
        });

        const bulletCircle = am5.Circle.new(root, {
          radius: 5,
          fill: am5.color('#FFF'),
          stroke: series.get('fill'),
        });

        bullet.children.push(bulletCircle);

        const hasLabel = this.selectedToggle === this.quarter || closureItem?.intervalValue % 3 === 0;

        if (hasLabel) {
          bullet.children.push(am5.Label.new(root, {
            html: `<strong>${formatPercent(closureItem?.value / 100, this.locale)}</strong>`,
            centerX: 30,
            centerY: am5.percent(0),
            dx: 10
          }));
        }

        return am5.Bullet.new(root, {
          sprite: bullet,
        });
      });
    });

    series.bulletsContainer._setParent(chart.seriesContainer);

    const currentYear = new Date().getFullYear();
    const closureRates = this.selectedToggle === this.quarter ? this.closuresByQuarter : this.closuresByMonth;

    const sortedData = closureRates.filter(x => x.dtYear === currentYear).sort(this.sortByDateValue);
    const data = sortedData.map(x => { return { interval: this.getInterval(x), value: x.avgClosure * 100, months: this.getMonths(x), intervalValue: x.dtValue }; });

    // Compare defaults to the last year data
    const compareData = this.getCompareData(this.selectedCompareIndex);

    if (compareData.length > 0) {
      xAxis.data.setAll(compareData);
    } else {
      xAxis.data.setAll(data);
    }

    series.data.setAll(data);
    series2.data.setAll(compareData);

    series.appear();
    series2.appear();

    chart.appear(1000, 100);

    return chart;
  }

  private getXAxis(root: am5.Root): am5xy.Axis<am5xy.AxisRenderer> {
    const axis = am5xy.CategoryAxis.new(root, {
      categoryField: 'interval',
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 20
      })
    });

    return axis;
  }

  private getCompareData(index: number): ClosureRateChartItem[] {
    return this.closuresTableData[index].map(x => { return { interval: this.getInterval(x), value: x.avgClosure * 100, months: this.getMonths(x), intervalValue: x.dtValue }; });
  }

  private getTrendingRateSeries(root: am5.Root, xAxis: am5xy.Axis<am5xy.AxisRenderer>, yAxis: am5xy.Axis<am5xy.AxisRenderer>): am5xy.LineSeries {
    const tooltip = am5.Tooltip.new(root, {});
    const series = am5xy.LineSeries.new(root, {
      name: 'Current',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      categoryXField: 'interval',
      legendValueText: '{valueY}',
      tooltip: tooltip
    });

    return series;
  }

  private getCompareToSeries(root: am5.Root, xAxis: am5xy.Axis<am5xy.AxisRenderer>, yAxis: am5xy.Axis<am5xy.AxisRenderer>): am5xy.LineSeries {
    const series = am5xy.LineSeries.new(root, {
      name: 'Last Year',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      categoryXField: 'interval',
      legendValueText: '{valueY}',
      stroke: am5.color('#B3B2B0')
    });

    return series;
  }

  getInterval(item: HccDashboardClosureRate): string {
    if (this.selectedToggle === this.quarter) {
      return `${item.dtPart}${item.dtValue}`;
    } else {
      return getNameFromMonthNumber(item.dtValue - 1);
    }
  }

  getMonths(item: HccDashboardClosureRate): HccDashboardClosureRate[] {
    return this.closuresByMonth.filter(x => x.dtValue <= item.dtValue * 3 && x.dtValue > (item.dtValue - 1) * 3).sort(this.sortByDateValue);
  }

  sortByDateValue(a: HccDashboardClosureRate, b: HccDashboardClosureRate): number {
    return a.dtValue - b.dtValue;
  }

  compareToggle(value: string): void {
    this.selectedToggle$.next(value);
  }

  setSelectedCompare(array: HccClosureRateDataItem[], index: number): void {
    this.selectedCompareIndex = index;
    this.chart.series.removeValue(this.compareSeries);
    this.compareSeries.dispose();

    this.compareSeries = this.getCompareToSeries(this.chartRoot, this.xAxis, this.yAxis);
    const newSeries = this.chart.series.push(this.compareSeries);
    const compareData = this.getCompareData(index);

    newSeries.data.setAll(compareData);
    newSeries.appear();
  }

}

class ClosureRateChartItem {
  intervalValue: number;
  interval: string;
  value: number;
  months: HccDashboardClosureRate[];
}
