
import { formatDate } from '@angular/common';
import moment from 'moment';
import {
  formatDate as fmtDate,
  mapOverallOptionsToStrings,
  statusSortPriorityMap,
  removeLeadingZeros
} from '../../care-prescriptions/shared-lib';
import { compareString } from '@shared/components/grid/grid-functions';
import { ColDef } from 'ag-grid-community';

export function compareStringPlain(
  a: string,
  b: string,
  nodeA?,
  nodeB?,
  isInverted?
): 0 | 1 | -1 {
  a == null ? (a = '') : (a = a.trim());
  b == null ? (b = '') : (b = b.trim());
  if (a == b) {
    return 0;
  }
  if (a == '') return isInverted ? -1 : 1;
  if (b == '') return isInverted ? 1 : -1;
  const c = a.localeCompare(b, undefined, {
    sensitivity: 'base',
    numeric: false,
  });
  return c == 0 ? 0 : c < 0 ? -1 : 1;
}

export function compareDate(a: Date, b: Date): 0 | 1 | -1 {
  if (a.getTime() == b.getTime()) {
    return 0;
  }
  return a.getTime() < b.getTime() ? 1 : -1;
}

export const COLUMN_DEFS: Array<ColDef> = [
  {
    headerName: '',
    field: 'isProcessed',
    minWidth: 30,
    maxWidth: 30,
    resizable: false,
    checkboxSelection: true,
    suppressSizeToFit: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: 'RxNumber',
    field: 'rxNumber',
    minWidth: 100,
    resizable: true,
    sortable: true,
    cellRenderer: (data: any): string => {
      return data.data?.rxNumber;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'NAME',
    field: 'patientName',
    maxWidth: 200,
    minWidth: 200,
    comparator: compareString,
    sortable: true,
    resizable: true,
    filter: 'agSetColumnFilter',
    cellRenderer: (data: any): string => {
      return data.data?.patientName;
    },
  },
  {
    headerName: 'PatientDOB',
    field: 'dob',
    maxWidth: 130,
    minWidth: 110,
    suppressAutoSize: true,
    sortable: true,
    comparator: compareString,
    cellRenderer: (data: any): string => {
      return moment(data.data?.dob).format('L');
    },
    valueGetter: params => {
      return fmtDate(params.data.dob);
    },
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Dispensed',
    field: 'dispensedFillDate',
    maxWidth: 120,
    minWidth: 110,
    suppressAutoSize: false,
    cellRenderer: (data): string => {
      return data.data?.firstFilled
        ? formatDate(new Date(data.data?.fillDate), 'MM/dd/yy', 'en_US').trim()
        : '';
    },
    valueGetter: (data: any): string => {
      return fmtDate(data.data?.fillDate);
    },
    getQuickFilterText: params => {
      return params.value;
    },
    sortable: true,
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'FILL DATE HIDDEN',
    field: 'fillDate',
    maxWidth: 130,
    suppressAutoSize: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    hide: true,
    cellRenderer: (data): string => {
      return data.data?.firstFilled
        ? formatDate(new Date(data.data?.fillDate), 'MM/dd/yy', 'en_US').trim()
        : '';
    },
    valueGetter: (data: any) => {
      let result = formatDate(new Date(data.data?.fillDate), 'yyyy/MM/dd', 'en_US').trim();
      return result;
    },
    getQuickFilterText: params => {
      return params.value;
    },
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'FillNumber',
    field: 'clPrescriptionFillNumber',
    maxWidth: 100,
    minWidth: 60,
    resizable: true,
    sortable: true,
    cellRenderer: (data: any): string => {
      return removeLeadingZeros(data.data?.clPrescriptionFillNumber);
    },
    valueGetter: (data: any): string => {
      return removeLeadingZeros(data.data?.clPrescriptionFillNumber);
    },
    filter: 'agSetColumnFilter',
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      valueA = new Number(valueA);
      valueB = new Number(valueB);
      if (valueA == valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
    }
  },
  {
    headerName: 'NDCDesc',
    field: 'medicationDescription',
    resizable: true,
    sortable: true,
    filter: 'agSetColumnFilter',
    comparator: compareStringPlain,
    cellRenderer: (data): string => {
      return data.data?.medicationDescription;
    },
  },
  {
    headerName: 'NDC',
    field: 'ndc',
    maxWidth: 120,
    suppressAutoSize: true,
    sortable: true,
    cellRenderer: (data): string => {
      return data.data?.ndc;
    },
    suppressSizeToFit: true,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'PRESCRIBER',
    field: 'prescriberName',
    maxWidth: 220,
    resizable: true,
    sortable: true,
    comparator: compareString,
    cellRenderer: (data): string => {
      return data.data?.prescriberName;
    },
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'PRESCRIBER PHONE',
    field: 'prescriberPhone',
    maxWidth: 160,
    suppressAutoSize: true,
    hide: true,
    comparator: compareStringPlain,
    cellRenderer: (data): string => {
      return data.data?.prescriberPhone;
    },
  },
  {
    headerName: 'LOCATION',
    field: 'location',
    resizable: true,
    hide: true,
    comparator: compareStringPlain,
    cellRenderer: (data): string => {
      return data.data?.location;
    },
  },
  {
    headerName: 'PatientZip',
    field: 'patientZip',
    resizable: true,
    hide: true,
    comparator: compareStringPlain,
    cellRenderer: (data): string => {
      return data.data?.patientZip;
    },
  },
  {
    headerName: 'PatientLast',
    field: 'patientLastName',
    minWidth: 200,
    comparator: compareString,
    resizable: true,
    hide: true,
    valueGetter: (data: any): string => {
      const fullName = data.data.patientName;
      let splitName = fullName.split(' ').filter(x => x !== '');
      return splitName[splitName.length - 1];
    },
  },
  {
    headerName: 'PatientFirst',
    field: 'patientFirstName',
    minWidth: 200,
    comparator: compareString,
    resizable: true,
    hide: true,
    valueGetter: (data: any): string => {
      const fullName = data.data.patientName;
      let splitName = fullName.split(' ').filter(x => x !== '');
      return splitName[0];
    },
  },
  {
    headerName: 'Quantity',
    field: 'prescriptionQuantity',
    minWidth: 200,
    resizable: true,
    hide: true,
    cellRenderer: (data: any): string => {
      return data.data?.prescriptionQuantity;
    },
  },
  {
    headerName: 'DaysSupply',
    field: 'daysSupply',
    minWidth: 200,
    resizable: true,
    hide: true,
    cellRenderer: (data: any): string => {
      return data.data?.daysSupply;
    },
  },
  {
    headerName: 'DEA',
    field: 'prescriberDEA',
    minWidth: 200,
    resizable: true,
    hide: true,
    cellRenderer: (data: any): string => {
      return data.data?.prescriberDEA;
    },
  },
  {
    headerName: 'NPI',
    field: 'prescriberNPI',
    minWidth: 200,
    resizable: true,
    hide: true,
    cellRenderer: (data: any): string => {
      return data.data?.prescriberNPI;
    },
  },
  {
    headerName: 'FIRST FILLED HIDDEN',
    field: 'firstFilledHidden',
    maxWidth: 120,
    suppressAutoSize: true,
    hide: true,
    valueGetter: (params) => {
      return fmtDate(params.data.firstFilled);
    },
  },
  {
    headerName: 'PHARMACY NAME',
    field: 'pharmacyNamesHidden',
    resizable: true,
    sortable: true,
    comparator: compareStringPlain,
    cellRenderer: (data): string => {
      return data.data.pharmacyName;
    },
    valueGetter: (params) => {
      return params.data.pharmacyName;
    },
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'PHARMACY NAME (OTHER)',
    field: 'pharmacyNamesOtherHidden',
    resizable: true,
    sortable: true,
    comparator: compareStringPlain,
    cellRenderer: (data): string => {
      let result = data.data?.pharmacyNameOther ? data.data?.pharmacyNameOther : data.data?.pharmacyName;
      return result;
    },
    valueGetter: (params) => {
      let result = params.data?.pharmacyNameOther ? params.data?.pharmacyNameOther : params.data?.pharmacyName;
      return result;
    },
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'PHARMACY ADDRESS',
    field: 'pharmacyAddress',
    resizable: true,
    sortable: true,
    comparator: compareStringPlain,
    cellRenderer: (data): string => {
      return data.data.pharmacy_Address;
    },
    valueGetter: (params) => {
      return params.data.pharmacy_Address;
    },
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'STATUS',
    field: 'status',
    maxWidth: 140,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    hide: true,
    getQuickFilterText: (params) => {
      return params.value;
    },
    cellRenderer: (data) => {
      return mapOverallOptionsToStrings(data.data?.status ?? -1);
    },
    comparator: (s1, s2) => {
      let sx1 = statusSortPriorityMap.get(s1);
      let sx2 = statusSortPriorityMap.get(s2);
      return sx1 - sx2;
    },
  },
  {
    headerName: 'Revenue',
    field: 'revenue',
    resizable: true,
    comparator: compareStringPlain,
    hide: true,
    cellRenderer: (data): string => {
      return '';
    },
    valueGetter: (params) => {
      return '';
    },
  },
  {
    headerName: 'BIN',
    field: 'bin',
    resizable: true,
    comparator: compareStringPlain,
    hide: true,
    cellRenderer: (data): string => {
      return '';
    },
    valueGetter: (params) => {
      return '';
    },
  },
  {
    headerName: 'PCN',
    field: 'pcn',
    resizable: true,
    comparator: compareStringPlain,
    hide: true,
    cellRenderer: (data): string => {
      return '';
    },
    valueGetter: (params) => {
      return '';
    },
  },
  {
    headerName: 'GroupNumber',
    field: 'groupNumber',
    resizable: true,
    comparator: compareStringPlain,
    hide: true,
    cellRenderer: (data): string => {
      return '';
    },
    valueGetter: (params) => {
      return '';
    },
  },
  {
    headerName: 'DateWritten',
    field: 'dateWritten',
    resizable: true,
    comparator: compareStringPlain,
    hide: true,
    cellRenderer: (data): string => {
      return '';
    },
    valueGetter: (params) => {
      return '';
    },
  },
  {
    headerName: 'PHARMACY NPI',
    field: 'npi',
    minWidth: 120,
    resizable: true,
    sortable: true,
    valueGetter: (data: any): string => {
      return data.data?.pharmacyNpi;
    },
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'MCOCardholderID',
    field: 'mcoCardholderId',
    resizable: true,
    comparator: compareStringPlain,
    hide: true,
    cellRenderer: (data): string => {
      return '';
    },
    valueGetter: (params) => {
      return '';
    },
  },
  {
    headerName: 'Price',
    field: 'awpPrice',
    minWidth: 100,
    resizable: true,
    sortable: true,
    cellRenderer: (data): string => {
      const price = data.data?.claimType === 'CMS'
         ? `$${data.data?.awpPrice.toFixed(2)}`
         : `$${data.data?.tpaPrice.toFixed(2)}`;
      return price;
    },
    valueGetter: data => {
      const price = data.data?.claimType === 'CMS'
         ? +data.data?.awpPrice.toFixed(2)
         : +data.data?.tpaPrice.toFixed(2);
      return price;
    },
    filter: 'agSetColumnFilter',
  },

  {
    headerName: 'Claim Source',
    field: 'tpaName',
    minWidth: 120,
    suppressAutoSize: true,
    hide: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },

  {
    headerName: 'Claim Type',
    field: 'claimType',
    minWidth: 100,
    resizable: true,
    sortable: true,
    hide: true,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Drug Manufacturer',
    field: 'drugManufacturer',
    minWidth: 120,
    suppressAutoSize: true,
    hide: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
];
