/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InsertOrUpdateNoteRequest } from '../models/insert-or-update-note-request';
import { Note } from '../models/note';

@Injectable({
  providedIn: 'root',
})
export class NotesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation notesGet
   */
  static readonly NotesGetPath = '/Notes';

  /**
   * Returns Notes for a given patient.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesGet$Response(params: {

    /**
     * ChPatId of selected patient
     */
    ChPatId: number;

    /**
     * Note type requested
     * 1 = AWV Worklist
     * 3 = In-Home AWV
     */
    NoteTypeId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Note>>> {

    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesGetPath, 'get');
    if (params) {
      rb.query('ChPatId', params.ChPatId, {});
      rb.query('NoteTypeId', params.NoteTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Note>>;
      })
    );
  }

  /**
   * Returns Notes for a given patient.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesGet(params: {

    /**
     * ChPatId of selected patient
     */
    ChPatId: number;

    /**
     * Note type requested
     * 1 = AWV Worklist
     * 3 = In-Home AWV
     */
    NoteTypeId: number;
  },
  context?: HttpContext

): Observable<Array<Note>> {

    return this.notesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Note>>) => r.body as Array<Note>)
    );
  }

  /**
   * Path part for operation notesPost
   */
  static readonly NotesPostPath = '/Notes';

  /**
   * Create or edit a Note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notesPost$Response(params?: {

    /**
     * Note insert/update request
     */
    body?: InsertOrUpdateNoteRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create or edit a Note.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notesPost(params?: {

    /**
     * Note insert/update request
     */
    body?: InsertOrUpdateNoteRequest
  },
  context?: HttpContext

): Observable<number> {

    return this.notesPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation notesDelete
   */
  static readonly NotesDeletePath = '/Notes';

  /**
   * Deletes a specific Note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesDelete$Response(params: {

    /**
     * ID of note to be deleted
     */
    NoteId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesDeletePath, 'delete');
    if (params) {
      rb.query('NoteId', params.NoteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a specific Note.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesDelete(params: {

    /**
     * ID of note to be deleted
     */
    NoteId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.notesDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
