<div
  class="tabs--fullView tabs tabs--secondary u-fill"
  [ngClass]="{
    'sidebar--condensed': !isSidebarOpen,
    'sidebar--expanded': isSidebarOpen
  }"
>
  <ng-container *ngFor="let tab of availableTabs">
    <div
      (click)="switchTab(tab.name)"
      class="tab {{
        tab.name == 'Alerts + Messages' ? 'u-position--relative' : ''
      }}"
      [routerLink]="tab.routerLink"
      routerLinkActive="is-active"
    >
      {{ tab.name }}
    </div>
  </ng-container>
</div>

<div
  class="tabs--condensedView"
  [ngClass]="{
    'sidebar--condensed': !isSidebarOpen,
    'sidebar--expanded': isSidebarOpen
  }"
>
  <button
    class="button is-unstyled margin--smallest row--verticalCenter"
    (click)="isTabDropdownOpen = !isTabDropdownOpen"
    type="button"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    <span class="margin-right u-bold">
      {{ activeTab }}
    </span>
    <coach-icon iconName="chevron-down" iconColor="currentColor"></coach-icon>
  </button>

  <ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isTabDropdownOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="isTabDropdownOpen = false"
>
    <div class="dropdown">
      <div class="dynamicBody list">
        <ng-container *ngFor="let tab of availableTabs">
          <div
            class="list-item"
            [routerLink]="tab.routerLink"
            routerLinkActive="is-active"
            (click)="switchTab(tab.name)"
          >
            {{ tab.name }}
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
