import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'coach-sub-fill-dialog',
  templateUrl: './sub-fill-dialog.component.html',
  styleUrls: ['./sub-fill-dialog.component.scss']
})
export class SubFillDialogComponent implements OnInit {

  @Input() showDialog: boolean = false;
  @Input() dialogMessage: string = 'Are you sure?';
  @Output() buttonClicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onButtonClicked(buttonValue: string) {
    this.buttonClicked.emit(buttonValue);
  }

}
