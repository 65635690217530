import { Component, OnInit } from '@angular/core';
import { DocRepo } from '@shared/models/docRepo/list';
import { ImproveFeatureNames } from '@shared/models/module-constants';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';

@Component({
  selector: 'coach-improve-practice-improvement',
  templateUrl: './improve-practice-improvement.component.html',
  host: {
    class: 'row u-fill'
  }
})
export class ImprovePracticeImprovementComponent implements OnInit {

  public docRepoData: DocRepo[];
  public tabName: string = ImproveFeatureNames.PracticeImprovement;

  constructor(
      private fileDownloadService: FileDownloadService
    ) { }

  ngOnInit(): void {
    this.fileDownloadService.generalFolderList$.subscribe(data => {
      if (data.length !== 0) {
        this.docRepoData = this.fileDownloadService
        .getChildrenByCollectionName(this.tabName, data);
      }
    });
  }
}
