import { Component, OnInit, LOCALE_ID, Inject} from '@angular/core';
import { formatDate } from '@angular/common';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AwvWorklistService } from '../awv-worklist.service';

@Component({
  selector: 'coach-awv-worklist-filters',
  templateUrl: './awv-worklist-filters.component.html',
  styleUrls: ['./awv-worklist-filters.component.scss']
})
export class AwvWorklistFiltersComponent implements OnInit {

  filtersForm: UntypedFormGroup;
  minDate: string = formatDate(new Date('1901-01-01'), 'MM/dd/yyyy', this.locale);
  constructor(private formBuilder: UntypedFormBuilder, public awvWorklistService: AwvWorklistService, @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
    this.filtersForm = this.formBuilder.group({
      fromDate: this.awvWorklistService.fromDate,
      toDate: this.awvWorklistService.toDate
    });
  }

  loadItems(): void {
    this.awvWorklistService.loadItems$.next();
  }

  onFromDateChange(event: any): void {
    if (event.target.value === "") {
      this.minDate = formatDate('1901-01-01', 'MM/dd/yyyy', this.locale);
    } else {
      this.minDate = formatDate(event.target.value, 'MM/dd/yyyy', this.locale);
    }
    const fromDate = new Date(this.minDate);
    const toDate = new Date(this.filtersForm.controls.toDate.value);
    
    if (fromDate > toDate) {
      this.filtersForm.controls.toDate.setValue(event.target.value);
    }
  }

  onToDateChange(): void {
    if (this.filtersForm.controls.fromDate.value === "") {
      this.filtersForm.controls.fromDate.value === formatDate(new Date(), 'MM/dd/yyyy', this.locale);
    } 
  }
}
