import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AwvWorklistService {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    const today = new Date();
    this.fromDate = new UntypedFormControl();
    this.toDate = new UntypedFormControl(formatDate(today, 'yyyy-MM-dd', this.locale), [Validators.required, this.awvCustomDateValidator(this.fromDate)]);
   }

  fromDate: UntypedFormControl;
  toDate: UntypedFormControl;
  loadItems$: Subject<void> = new Subject<void>();
  
  // Resets fromDate and toDate
  public clearDates(){
    const today = new Date();
    this.fromDate.setValue(null);
    this.toDate.setValue(formatDate(today, 'yyyy-MM-dd', this.locale));    
  }

  public hasDateChanged(): boolean {
      let fromDateChanged = false;
      if (this.fromDate.value){
        const momFromDate = moment(this.fromDate.value).startOf('day');
        const momDefDate = moment('1901-01-01');
        fromDateChanged = !momFromDate.isSame(momDefDate, 'day');
      }

      let toDateChanged = false;
      if (!this.toDate.value){
         toDateChanged = true;
      }
      else if (this.toDate.value){
        const momToDate = moment(this.toDate.value).startOf('day');
        const momToday = moment();
        toDateChanged = !momToDate.isSame(momToday, 'day');
  
      }
      return fromDateChanged || toDateChanged;
  }

  private awvCustomDateValidator(otherDateControl: AbstractControl): ValidatorFn {
    const defaultDate: Date = new Date('1901-01-01');
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value == null || otherDateControl.value == null) {
            return null;
        }

        const controlDate = new Date(control.value);
        const otherDate = otherDateControl.value === "" ? defaultDate : new Date(otherDateControl.value);
        const isAfter = controlDate >= otherDate;
        return isAfter ? null : { dateMinimum: true };
    };
  }
}
