import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { PatientService } from '@shared/services/patient/patient.service';
import { Patient } from '@shared/models/patient';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { AuthService } from '@shared/services/auth-service/auth.service'; 
@Component({
  selector: 'coach-sms-standard-schedule',
  templateUrl: './sms-standard-schedule.component.html',
  styleUrls: ['./sms-standard-schedule.component.scss']
})

export class SmsStandardScheduleComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public phases: Array<wpapi.model.TwilioScheduleReportInterval>;
  @Input() public program: string;
  @Output() onCancel = new EventEmitter<boolean>();
  isStopped : boolean = false;
  isStopRequested : boolean = false;
  scheduleStopped: boolean = false;
  currentUser: wpapi.model.Professional;

  constructor(
    private AuthService: AuthService,
    private patientService: PatientService,
  ) {
    this.getCurrentUserInfo();
  }

  ngOnChanges(){
    if(this.phases.find(phase => phase.status == 'Stopped')){
      this.isStopped = true;
    } else {
      this.isStopped = false;
    }
  }

  close(switchToCustom: boolean) {
    this.onCancel.emit(switchToCustom);
  }

  private async getCurrentUserInfo() {
    this.currentUser = await this.AuthService.getWellpepperUserInfo();
  }

  formatDate(date: wpapi.model.LocalDate): string {
    return new Date(date.year,date.month - 1, date.date).toLocaleDateString('en-US')
  }

  get twilioTask() {
    return this.patient?.tasks?.find(task => task.type == "twilioStudioFlow" && task.isActive);
  }

  stopRequest(request : boolean) {
    this.isStopRequested = request; 
  }
  
  async onSave() {
    let userId = this.patient.carePatient._id;
    if (this.isStopRequested){
      this.stopSchedule(userId);
      window.location.reload();
    }
  }

  async stopSchedule(userId: string) {
    let stopTwilioFlowSchedule = await this.patientService.stopTwilioFlowSchedule(userId, this.twilioTask._id);
    return stopTwilioFlowSchedule;
  }

  getCurrentDate(): string{
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; 
    let dd = today.getDate();
    
    
    const currentDate = mm + '/' + dd + '/' + yyyy;
    return currentDate;
  }

  ngOnInit(): void {
  }

}
