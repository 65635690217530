import { Component, OnInit } from '@angular/core';
import { FINANCIAL_REPORTS_FEATURE, QUALITY_REPORTS_FEATURE } from '@track/track-constants';
import { TrackReportMenuItem } from '@track/track-reports/track-report-menu-item';
import { TrackReportMenuItemsService } from '@track/track-reports/services/track-report-menu-items.service';
import { Observable } from 'rxjs';
import { TrackReportService } from '@track/track-reports/services/track-report.service';
import { TrackComponentConstants, TrackFeatureConstants, ModuleConstants } from '@shared/models/module-constants';
import { ActivatedRoute, Router } from '@angular/router';

const {
  FinancialUtilizationFeature,
  ClinicalFeature,
  QualityFeature,
  QualityFeatureName,
  ValueCalculatorFeatureFlag,
  ValueCalculatorName,
  ValueCalculatorFeature,
  CompareFeatureFlag,
  CompareFeature,
  CompareFeatureName,
} = TrackFeatureConstants;

@Component({
  selector: 'coach-track-summary',
  templateUrl: './track-summary.component.html'
})
export class TrackSummaryComponent implements OnInit {

  constructor(private menuItemService: TrackReportMenuItemsService,
              private reportService: TrackReportService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  readonly qualityFeature: number = QUALITY_REPORTS_FEATURE;
  readonly financialFeature: number = FINANCIAL_REPORTS_FEATURE;
  readonly trackValueCalculatorFeature: number = ValueCalculatorFeature;
  readonly valueCalculatorFeatureFlag: string = ValueCalculatorFeatureFlag;

  readonly trackClinicalFeature = ClinicalFeature;
  readonly trackFinancialFeature = FinancialUtilizationFeature;
  readonly trackQualityFeature = QualityFeature;
  readonly trackQualityFeatureName = QualityFeatureName;
  readonly trackHccComponent = TrackComponentConstants.ChronicConditions;
  readonly trackValueCalculatorComponent = TrackComponentConstants.ValueCalculator;
  readonly careModule = ModuleConstants.CareModule;
  readonly valueCalculatorReportName = TrackComponentConstants.ValueCalculatorReportName;
  readonly ValueCalculatorFeatureName = ValueCalculatorName;
  readonly performanceDashboardFeature = TrackFeatureConstants.PerformanceDashboardFeature;
  readonly performanceDashboardFeatureFlag = TrackFeatureConstants.PerformanceDashboardFeatureFlag;
  readonly compareFeature = CompareFeature;
  readonly compareFeatureFlag = CompareFeatureFlag;
  readonly compareFeatureName = CompareFeatureName;
  readonly compareComponent = TrackComponentConstants.CompareComponent;
  readonly qualityDashboardComponent = TrackComponentConstants.QualityDashboardComponent;
  readonly qualityDashboardFeatureFlag: string = TrackFeatureConstants.QualityDashboardFeatureFlag;

  menuItems$: Observable<TrackReportMenuItem[]>;

  ngOnInit(): void {
    this.menuItems$ = this.menuItemService.getAllFromCache();
  }

  loadReport(item: TrackReportMenuItem): void {
    this.reportService.runReport(item);
  }

  goToItems = (e: Event, path: string, query?: unknown | null): void => {
    e.preventDefault();
    this.router.navigate(['..' + path], {
      queryParams: query,
      relativeTo: this.activatedRoute.parent,
    });
  }

}
