import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { environment } from 'src/environments/environment';
import { PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class ClinicalProgramCopdPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const item: IPatientFilterPreset = {
      category: PresetCategory.ClinicalProgram,
      name: CarePatientItem.ClinicalProgramCOPD,

      label: 'COPD',
      filters: [],
      diseaseCohortNameFromTrackApi: 'COPD Clinical Program',
      componentId: CareComponentConstants.PatientDiseaseCohorts,
      hidePreset: true,
      hidePresetAdditionCondition: this.userAccessService.hasComponent(
        this.userAccess,
        CareComponentConstants.CareManagement
      ),
      presetKey: 'Clinical_programs-COPD',
      versionKey: `columnState_${this.userId}_${CarePatientItem.ClinicalProgramCOPD}`,
      columns: this.getPresetColumns(),
      hiddenColumnsByDefault: [
        // https://caravanhealth.atlassian.net/browse/CC-3535
        'attributedPractice',
        'pyRaf',
        'raF_YTD',
        'openRAFWt',
        'awvTypeDue',
        'lastAWVDate',
        'ccmEligible',
        'diseaseCohorts',
        'phone1',
        'phone2',
        'current_MBI',
        'hccGapCount',
        'totalSpend'
      ],
      defaultFilterModel: {
        isMostImpactable: {
          filterType: 'set',
          values: ['Yes']
        }
      }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean }[] {
    return [
      { select: true },
      { fullName: true },
      { attributedCP: true },
      { dob_age: true },
      { gender: true },
      { current_MBI: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      { spirometryProcedure_LastDate: true },
      { pulmonologistProcedure_LastProviderName: true },
      { pulmonologistProcedure_LastDate: true },
      { copdDxCount: true },
      { lastCcmDate: true },
      { ccmLastProcCode: true},
      { isMostImpactable: true },
      { lastVisit: true },
      { edVisits: true },
      { officeVisits: true },
      { inpatientVisits: true },
      {
        hccGapCount: this.agGridCustomFilteringFlag
      },
      { totalSpend: true },
      {
        diseaseCohorts: this.agGridCustomFilteringFlag
      },
      {
        ccmEligible: this.agGridCustomFilteringFlag
      },
      {
        raF_YTD: this.agGridCustomFilteringFlag
      },
      { openRAFWt: true },
      { attributedPractice: true },
      { awvTypeDue: true },
      { lastAWVDate: true },
      { pyRaf: true },
      { phone1: true },
      { phone2: true }
    ];
  }
}
