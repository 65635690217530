import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl, UntypedFormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { CheckboxQuestion, CheckboxQuestionCheckboxOptions, ExclusionRequestDetails} from '@shared/models/forms/forms-frontend';
import { ToolTipMeasureDetails } from '@shared/models/forms/tool-tip-measure-details';
import { SecurityRiskQuestion } from '@shared/models/forms/forms-frontend';

@Component({
  selector: 'coach-submit-interoperability-yes-no',
  templateUrl: './submit-interoperability-yes-no.component.html',
  styleUrls: ['./submit-interoperability-yes-no.component.scss'],
})
export class SubmitInteroperabilityYesNoComponent implements OnInit {
  @Input() public parentForm: UntypedFormGroup;
  @Input() public questionInfo: CheckboxQuestion;

  _maxPoints: number;
  @Input() set maxPoints(value: number) {
    this._maxPoints = value;


  }
  get maxPoints(): number {
    return this._maxPoints;
  }

  @Output() radioButtonChanged = new EventEmitter<string>();
  @Output()
  showHelpText = new EventEmitter<ToolTipMeasureDetails>();

  @Output() checkBoxExclusionChanged = new EventEmitter<ExclusionRequestDetails>();


  public no = 'no';
  public unset = 'unset'
  public yes = 'yes';

  public exclusionSelected = false;


  private hasInitialized = false;

  public showExclusionModal = false;

  public get isDisabled(): boolean {
    return (
      this.parentForm.get(`${this.questionInfo.name}.isDisabled`)?.value ===
      true
    );
  }

  get pointsControl(): AbstractControl {
    return this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.ResponsePointValue`
    );
  }



  ngOnInit(): void {

    const response: string = this.parentForm
    .get(`${this.questionInfo.name}.FormQResponses.0.UserResponse`)
    ?.value?.toString();
    const completeControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.complete`
    );

    if (response != null && response.indexOf(this.yes) >= 0) {
      completeControl?.setValue(this.yes);
      this.changeResponse(this.yes);
    } else if (response != null && response.indexOf(this.no) >= 0) {
      completeControl?.setValue(this.no);
      this.changeResponse(this.no);
    } else {
      completeControl?.setValue(this.unset);
    }
    }

  public changeResponse(x) {


    if (x  == this.yes) {
      this.pointsControl.setValue(this.maxPoints);
    } else {
      this.pointsControl.setValue(0);
    }


    this.radioButtonChanged.emit(x);

    this.parentForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });

  }

  toggleInfoText(): void {
    this.showHelpText.emit({
      detailsText: this.questionInfo.helpText,
      modalLinks: this.questionInfo.modalLinks,
    });
  }


  exclusionChanged(): void {
    const question = this.parentForm.controls[this.questionInfo.name];
    const exclusionControl = question.get('exclusion');

    if (exclusionControl.value) {
      this.showExclusionModal = true;
    } else {
      this.setFormType(this.responseTypeId);
      //this.updatePoints();
      this.exclusionSelected = false;
      this.enableDisableQuestions(question, 'enable');
      this.checkBoxExclusionChanged.emit({
        questionname: this.questionInfo.name,
        status: false,
      });
    }
  }

  confirmExclusion(): void {
    const question = this.parentForm.controls[this.questionInfo.name];

    const pointsControl = question.get('FormQResponses.0.ResponsePointValue');
    pointsControl.setValue(-1);
    this.setFormType(this.exclusionTypeId);
    this.showExclusionModal = false;
    this.enableDisableQuestions(question, 'disable');

    this.exclusionSelected = true;


    this.checkBoxExclusionChanged.emit({
      questionname: this.questionInfo.name,
      status: true,
    });
  }

  cancelExclusion(): void {
    const question = this.parentForm.controls[this.questionInfo.name];
    const exclusionControl = question.get('exclusion');
    exclusionControl.setValue(false);
    this.showExclusionModal = false;
  }


  enableDisableQuestions(
    question: AbstractControl,
    enableOrDisable: 'enable' | 'disable'
  ): void {
    if (enableOrDisable === 'enable') {
      question.get('FormQResponses').enable({ emitEvent: false });
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(null, { emitEvent: false });
      question
        .get('FormQResponses.0.UserResponse')
        .reset(null, { emitEvent: false });
    } else if (enableOrDisable === 'disable') {
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(0, { emitEvent: false });
      question
        .get('FormQResponses.0.UserResponse')
        .reset('', { emitEvent: false });
    }
  }

  setFormType(type: number): void {
    const formTypeControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.FormResponseTypeID`
    );
    formTypeControl.setValue(type);
  }

  private readonly exclusionTypeId = 3;
  private readonly responseTypeId = 4;

}
