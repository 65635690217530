import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';
/**
 * Mixpanel service
 *
 * @export
 * @class MixpanelService
 * @see https://docs.mixpanel.com/docs/tracking/reference/javascript
 */
@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  /**
   * Mixpanel initialization
   * @param projectToken this will need to be per environment
   * @param debug
   * @param pageViews
   */
  init(projectToken: string, debug: boolean, pageViews: boolean): void {
    mixpanel.init(projectToken, { debug, track_pageview: pageViews, persistence: 'localStorage' });
  }

  /**
   * Track an event with Mixpanel and send properties
   * @param id the id of the element to track
   * @param event any additional event properties to pass to Mixpanel
   */
  track(id: string, event: any = {}): void {
    mixpanel.track(id, event);
  }

  /**
   * Track a link click with Mixpanel
   * @param id A valid DOM query selector
   * @param eventName Name of the event to send to Mixpanel
   * @param properties Any additional properties to send to Mixpanel
   */
  trackLink(id: string, eventName: string, properties: any = {}): void {
    mixpanel.track_links(id, eventName, properties);
  }

  /**
   * Track a form submission with Mixpanel.
   * This function will wait up to 300ms for the mixpanel servers to respond.
   * @param id A valid DOM query selector
   * @param eventName Name of the event to send to Mixpanel
   * @param properties Any additional properties to send to Mixpanel
   */
  trackForm(id: string, eventName: string, properties: any = {}): void {
    mixpanel.track_forms(id, eventName, properties);
  }

  /**
   rackForm(id: string, eventName: string, properties: any = {}): void {
   if (this.isEnabled()) {
   mixpanel.track_forms(id, eventName, properties);
   }
   }   * Idenfitify a user with Mixpanel
   * @param id the id of the user to identify
   */
  identify(id: string): void {
    mixpanel.identify(id);
  }

  /**
   * Set properties on a user record.
   * @param properties A set of properties to describe the current user
   */
  userSet(properties: { [key: string]: any }): void {
    mixpanel.people.set(properties);
  }

  /**
   * Set properties on a user record, only if they do not yet exist.
   * This will not overwrite previous people property values, unlike
   * people.set().
   * @param properties A set of properties to describe the current user
   */
  userSetOnce(properties: { [key: string]: any }): void {
    mixpanel.people.set_once(properties);
  }

  /**
   * Reset the mixpanel super properties and allow user to re-identify
   * Called on logout
   */
  reset(): void {
    mixpanel.reset();
  }
}
