import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICurrentRoute } from '@shared/models/activated-route';

export interface TabSwitchState {
  isSummaryTabSelected: boolean;
  isQuickFillTabSelected: boolean;
  isCareNoteSelected: boolean;
}

@Component({
  selector: 'coach-tab-switch',
  templateUrl: './tab-switch.component.html',
  styleUrls: ['./tab-switch.component.scss'],
})
export class TabSwitchComponent implements OnInit {
  public isTabDropdownOpen = true;
  public currentRoute$: BehaviorSubject<ICurrentRoute>;
  public activeView: ICurrentRoute;
  public isSidebarOpen: boolean = true;

  @Input() isSummaryTabSelected: boolean;
  @Input() isQuickFillTabSelected: boolean;
  @Input() isCareNoteSelected: boolean;

  @Output() onTabClicked = new EventEmitter<TabSwitchState>();

  constructor() {}

  updateTabs(): void {
  }

  ngOnInit(): void {
  }

  onSummaryTabSelected() {
    this.isSummaryTabSelected = true;
    this.isQuickFillTabSelected = false;
    this.isCareNoteSelected = false;
    this.emitEvent();
  }

  onQuickFillTabSelected() {
    this.isSummaryTabSelected = false;
    this.isQuickFillTabSelected = true;
    this.isCareNoteSelected = false;
    this.emitEvent();
  }

  onCareNoteSelected() {
    this.isSummaryTabSelected = false;
    this.isQuickFillTabSelected = false;
    this.isCareNoteSelected = true;
    this.emitEvent();
  }

  emitEvent() {
    this.onTabClicked.emit({
      isSummaryTabSelected: this.isSummaryTabSelected,
      isQuickFillTabSelected: this.isQuickFillTabSelected,
      isCareNoteSelected: this.isCareNoteSelected
    });
  }
}
