import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { IRecentMatcher } from './recent-matcher';
import { getDisplayStringForAWVWorklistStatusEnum } from '@shared/models/patient';

export class PatientsCohortMatcher implements IRecentMatcher {
  match(url: string): boolean {
    if (url.includes('care/patients?filterPreset')) {
      return true;
    } else {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createLog(url: string): RecentLogData {
    return {
      url: url,
      elements: []
    };
  }

  title(url: string): string {
    let queryParams = this.getJsonFromUrl(url);
    if (queryParams?.filterTitle)
      return (
        queryParams?.filterTitle +
        (queryParams?.AWVPatientsStatus
          ? `: ${getDisplayStringForAWVWorklistStatusEnum(queryParams?.AWVPatientsStatus)}`
          : '')
      );
    let filter = queryParams?.filterPreset;
    switch (filter) {
      case 'awv-opportunities':
        return 'AWV Opportunities';
      case 'pac-navigator-program':
        return 'PAC Navigator Program';
      case 'ed-utilization':
        return 'ED Utilization';
      case 'hcc-vip-cohort':
        return 'Chronic Conditions VIP Cohort';
      case 'chf':
        return 'CHF';
      case 'copd':
        return 'COPD';
      case 'care mangement':
        return 'Care Management';
      default:
        return filter
          ?.split('-')
          ?.map(w => (w.length ? w[0].toUpperCase() + w.substring(1) : ''))
          ?.join(' ');
    }
  }
  getJsonFromUrl(url: string): { [key: string]: string } {
    if (!url) url = location.search;
    var query = url.substr(1);
    var result = {};
    query.split('&').forEach(function (part) {
      var item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }
}
