import { Component, OnInit, Inject, LOCALE_ID} from '@angular/core';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { formatDate } from '@angular/common';
import { CaravanFormAdminACO, CaravanFormAdminPracticeRespView, CaravanFormAdminFormResponsesView} from '@shared/models/forms/form-admin-model';
import { FormSubmission } from '@shared/models/forms/forms-backend';
import { CaravanFormAdminSubmitPeriod, CaravanFormAdminResponseReport  } from '@shared/models/forms/form-submissionID-model';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { ExcelService, workBook, WorkSheetTypes } from '@shared/services/excel-export/excel.service';
@Component({
  selector: 'coach-submit-compliance-admin-report',
  templateUrl: './submit-compliance-admin-report.component.html',
  host: {
    class: 'row u-fill',
  },
  styleUrls: ['../shared/submit-generic-admin-report.component.scss']
})
export class SubmitComplianceAdminReportComponent implements OnInit {

public formsSubmissionsOverviewACO: CaravanFormAdminACO[];
public formsSubmissionsOverviewComm: CaravanFormAdminPracticeRespView[];
public formSubmissions: FormSubmission[];

public formSubmissionsPracticeResps: CaravanFormAdminPracticeRespView[];
public formSubmissionResponse: CaravanFormAdminFormResponsesView[];
public formID = '3';
public reportName = 'Compliance_Admin'
public TierID = 'ACO'

public isResponseHidden: boolean = true;
public isPracticeHidden: boolean = true;
public isICCHidden: boolean = true;
public isACOHidden: boolean = false;

public gridApi: any;
private icc: string;
private aco: string;

public selectedFormSubPeriod: number;

public caravanFormAdminSubmitPeriod: CaravanFormAdminSubmitPeriod[];
public caravanFormAdminResponses: CaravanFormAdminResponseReport[];
public submitPeriodParam: number;
public readonly QualityFormId = 3;

private excelService = new ExcelService();
private workbookData: workBook[];

private formResponseDownloadFields = [
  { headerName: 'User Form ID', field: 'userFormID', sortable: true, filter: true, resizable: true },
  { headerName: 'Tier2 ID', field: 'tier2ID', sortable: true, filter: true, resizable: true },
  { headerName: 'Tier3 ID', field: 'tier3ID', sortable: true, filter: true, resizable: true },
  { headerName: 'Submitter Email', field: 'submitterEmail', sortable: true, filter: true, resizable: true },
  { headerName: 'Form Create Date Time', field: 'formCreateDateTime', sortable: true, filter: true, resizable: true },
  { headerName: 'Last Update Date Time', field: 'lastUpdateDateTime', sortable: true, filter: true, resizable: true },
  { headerName: 'Total Points', field: 'totalPoints', sortable: true, filter: true, resizable: true },
  { headerName: 'Question Name', field: 'questionName', sortable: true, filter: true, resizable: true },
  { headerName: 'Question Text', field: 'questionText', sortable: true, filter: true, resizable: true },
  { headerName: 'User Response', field: 'userResponse', sortable: true, filter: true, resizable: true },
  { headerName: 'Resonse Point Value', field: 'resonsePointValue', sortable: true, filter: true, resizable: true },
  { headerName: 'Submission Status', field: 'submissionStatus', sortable: true, filter: true, resizable: true },
];

constructor(
  private qualityService: SubmitFormsService, @Inject(LOCALE_ID) private locale: string
) {}

  public columnNamesACO = [{ headerName: 'ACO ID', field: 'acoID', cellRenderer: 'agGroupCellRenderer', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,} },
    { headerName: 'ACO Name', field: 'acoName', filter: true, minWidth: 300 },
    { headerName: 'Percentage Complete', field: 'acoPercentageComplete', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}  },
    { headerName: 'Last Update', field: 'acoLastUpdateTime', filter: true, minWidth: 200  },
    { headerName: 'View Communities', field: 'acoID',cellRenderer: 'clickableParentComponent',editable: false,minWidth: 150}
  ];

  public columnNamesComm = [{ headerName: 'ICC',  field: 'ICC', cellRenderer: 'agGroupCellRenderer', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,} },
    { headerName: 'Region',  field: 'regionID', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}   },
    { headerName: 'Community Name',  field: 'communityName', filter: true, filterParams: {applyMiniFilterWhileTyping: true,} , minWidth: 400   },
    { headerName: 'Percentage Complete',field: 'commPercentageComplete', filter: true, suppressSizeToFit: true},
    { headerName: 'Last Update', field: 'commLastUpdateTime', filter: true, minWidth: 200 },
    { headerName: 'View Principals', field: 'ICC', cellRenderer: 'clickableParentComponent', colId: 'params', editable: false,  minWidth: 150 }
    ];

  public practiceCellRendererParams = [ { headerName: 'ICC',  field: 'ICC',   filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}, maxWidth: 75  },
   {  headerName: 'Region',  field: 'regionID', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,} , maxWidth: 75   },
   {  headerName: 'Principal Name', field: 'tier3Name', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,},maxWidth: 380, minWidth: 320 },
   {  headerName: 'Principal ID', field: 'tier3ID', filter: true, suppressSizeToFit: true, filterParams: {applyMiniFilterWhileTyping: true,}, maxWidth: 135  },
   {  headerName: 'Has Entry', field: 'hasEntry', filter: true, suppressSizeToFit: true, maxWidth: 100 },
   {  headerName: 'Special Status', field: 'specialStatus', filter: true, suppressSizeToFit: true, minWidth: 125  },
   {  headerName: 'Total Points', field: 'totalPoints', filter: true , suppressSizeToFit: true, maxWidth: 115 },
   {  headerName: 'Last Update', field: 'lastUpdateTime', filter: true , suppressSizeToFit: true,  maxWidth: 140  },
   {  headerName: 'Updated By', field: 'updatedByUsername', filter: true, suppressSizeToFit: true, minWidth: 185   },
   {  headerName: 'User Form ID', field: 'UserFormID', filter: true, suppressSizeToFit: true},
   {  headerName: 'Primary User Form ID', field: 'PrimaryUserFormID', filter: true, suppressSizeToFit: true },
    {  headerName: 'View Responses', field: 'formResponses', filter: true, cellRenderer: 'clickableParentComponent', minWidth: 150 }
  ];

  public formQuestionResponses = [{ headerName: 'Question', field: 'QuestionID', filter: true, suppressSizeToFit: true, maxWidth: 100  },
    {  headerName: 'Question Text', field: 'QuestionText', filter: true, suppressSizeToFit: true, minWidth: 600, resizable: true  },
    {  headerName: 'Point Value', field: 'PointValue', filter: true, maxWidth: 150,  resizable: true },
    {  headerName: 'Text Response', field: 'TextResponse', filter: true, resizable: true  }
  ];

  ngOnInit(): void {
    this.qualityService.getAdminReportSubmitPeriods(this.QualityFormId).subscribe((data) => {
      this.caravanFormAdminSubmitPeriod = data;
      if (this.submitPeriodParam == null) {
        this.selectedFormSubPeriod = this.caravanFormAdminSubmitPeriod[0].caravanFormSubmitPeriodID;
      } else
      {
        this.selectedFormSubPeriod = this.submitPeriodParam;
      }

      this.refreshOverview();
    });
  }

  resultClickedICC(data): void{
    this.icc = data.cell;
    this.TierID = data.cell;
    this.isPracticeHidden = false;
    this.isICCHidden = true;
    this.refreshPractice();
  }

  resultClickedPrincipal(data): void{
    this.isResponseHidden = false;
    this.isPracticeHidden = true;
    this.formSubmissionResponse = data.cell;
  }

  refreshPractice(): void{
    const tierlevel = '4';
    const submissionID = this.selectedFormSubPeriod.toString();
    this.qualityService.getAdminReportPracticeResponseView(this.formID, this.icc, tierlevel, submissionID).subscribe((data) => {
      this.formSubmissionsPracticeResps = data;
    });
  }

  resultClickedACO(data): void{
    this.aco = data.cell;
    this.TierID = data.cell;
    this.isICCHidden = false;
    this.isACOHidden = true;
    this.refreshCommunity();
  }

  refreshCommunity(): void{
    const tierlevel = '2';
    const submissionID = this.selectedFormSubPeriod.toString();
    this.qualityService.getAdminReportPracticeResponseView(this.formID, this.aco, tierlevel, submissionID).subscribe((data) => {
      this.formsSubmissionsOverviewComm = data;
    });
  }

  returnToOverview(): void{
    this.TierID = 'ACO';
    this.isResponseHidden = true;
    this.isPracticeHidden = true;
    this.isICCHidden = true;
    this.isACOHidden = false;
    this.formSubmissionsPracticeResps= [];
    this.formsSubmissionsOverviewComm= [];
  }

  returnToCommunity(): void{
    this.TierID = this.aco;
    this.isResponseHidden = true;
    this.isPracticeHidden = true;
    this.isICCHidden = false;
    this.formSubmissionsPracticeResps= [];
  }

  returnToPrincipal(): void{
    this.TierID = this.icc;
    this.isResponseHidden = true;
    this.isPracticeHidden = false;
    this.formSubmissionResponse= [];
  }

  refreshOverview(): void{
    const submissionID = this.selectedFormSubPeriod.toString();
    this.qualityService.getAdminReportResponseOverview(this.formID, submissionID).subscribe((data) => {
      this.formsSubmissionsOverviewACO = data;
    });
  }

  selectSubmissionPeriod(submissionPeriodID): void {
    this.selectedFormSubPeriod = submissionPeriodID.target.value;
    this.refreshOverview();

  }

  onBtnExportResponses(): void {

    this.qualityService.getAdminQuestionResponseReport(this.QualityFormId, this.selectedFormSubPeriod).subscribe((data) => {
      this.caravanFormAdminResponses = data;


      const timestamp = formatDate(new Date(), 'yyyyMMdd', this.locale);

      const submitPeriodId = this.selectedFormSubPeriod.toString();

      this.workbookData = this.transformData(this.caravanFormAdminResponses);

      this.excelService.exportAsExcel(this.workbookData, `ComplianceForm_${submitPeriodId}_Responses_${timestamp}`);

    });

  }





  transformData (responseData: CaravanFormAdminResponseReport[]): workBook[] {
    let outputWorkbook = [] as workBook[];


      let rows = [];

        const dataStructure = this.formResponseDownloadFields;

        responseData.forEach((entry) => {
          const row = dataStructure.reduce((prev, next) =>
          ({...prev, [next.headerName]:  (next.field == '') ?'' : entry[next.field]}), {});

          rows.push(row);
        });



      const workSheet = {
        workSheet: 'List',
        workSheetType: WorkSheetTypes.table,
        theme: 'TableStyleMedium2',
        rows: rows
      };

      outputWorkbook.push(workSheet);



    return  outputWorkbook;

  }


}
