import {CallbackReasons, RemovedReasons} from '@api/care-orch/models';

export class PatientAwvUtilities  {
  public static removeReasonToEnum(reason: string): string {
    switch (reason) {
      case ('Patient has declined'):
        return 'PATIENT_HAS_DECLINED';
      case ('Patient is unreachable'):
        return 'PATIENT_IS_UNREACHABLE';
      case ('Patient is under hospice care'):
        return 'PATIENT_IS_UNDER_HOSPICE_CARE';
      case ('Patient is in a skilled nursing facility'):
        return 'PATIENT_IS_IN_A_SKILLED_NURSING_FACILITY';
      case ('Patient is in an inpatient facility'):
        return 'PATIENT_IS_IN_AN_INPATIENT_FACILITY';
      case ('Patient is deceased'):
        return 'PATIENT_IS_DECEASED';
      case ('Patient moved out of the service area'):
        return 'PATIENT_MOVED_OUT_OF_THE_SERVICE_AREA';
      case ('Insurance plan does not cover AWV'):
        return 'INSURANCE_PLAN_DOES_NOT_COVER_AWV';
      case ('AWV was completed by external provider'):
        return 'AWV_WAS_COMPLETED_BY_EXTERNAL_PROVIDER';
      case ('Other'):
        return 'OTHER';
      default:
        return 'PATIENT_IS_UNREACHABLE';
    }
  }
  public static removedReasonToString(reason: string): string {
    switch (reason) {
      case RemovedReasons.PatientHasDeclined:
        return 'Patient has declined';
      case RemovedReasons.PatientIsUnreachable:
        return 'Patient is unreachable';
      case RemovedReasons.PatientIsUnderHospiceCare:
        return 'Patient is under hospice care';
      case RemovedReasons.PatientIsInASkilledNursingFacility:
        return 'Patient is in a skilled nursing facility';
      case RemovedReasons.PatientIsInAnInpatientFacility:
        return 'Patient is in an inpatient facility';
      case RemovedReasons.PatientIsDeceased:
        return 'Patient is deceased';
      case RemovedReasons.PatientMovedOutOfTheServiceArea:
        return 'Patient moved out of the service area';
      case RemovedReasons.InsurancePlanDoesNotCoverAwv:
        return 'Insurance plan does not cover AWV';
      case RemovedReasons.AwvWasCompletedByExternalProvider:
        return 'AWV was completed by external provider';
      case RemovedReasons.Other:
        return 'Other';
      default:
        return '';
    }
  }

  public static callBackReasonToEnum(reason: string): string {
    switch (reason) {
      case ('Patient unreachable'):
        return 'PATIENT_UNREACHABLE';
      case ('Patient traveling'):
        return 'PATIENT_TRAVELING';
      case ('Patient requested callback'):
        return 'PATIENT_REQUESTED_CALLBACK';
      case ('Patient requires translator'):
        return 'PATIENT_REQUIRES_TRANSLATOR';
      case ('Provider requested callback'):
        return 'PROVIDER_REQUESTED_CALLBACK';
      case ('Patient is in a skilled nursing facility'):
        return 'PATIENT_IS_IN_A_SKILLED_NURSING_FACILITY';
      case ('Patient is in an inpatient facility'):
        return 'PATIENT_IS_IN_AN_INPATIENT_FACILITY';
      case ('Other'):
        return 'OTHER';
    }
  }

  public static callBackReasonToString(reason: string): string {
    switch (reason) {
      case (CallbackReasons.PatientUnreachable):
        return 'Patient unreachable';
      case (CallbackReasons.PatientTraveling):
        return 'Patient traveling';
      case (CallbackReasons.PatientRequestedCallback):
        return 'Patient requested callback';
      case (CallbackReasons.PatientRequiresTranslator):
        return 'Patient requires translator';
      case (CallbackReasons.ProviderRequestedCallback):
        return 'Provider requested callback';
      case (CallbackReasons.PatientIsInASkilledNursingFacility):
        return 'Patient is in a skilled nursing facility';
      case (CallbackReasons.PatientIsInAnInpatientFacility):
        return 'Patient is in an inpatient facility';
      case (CallbackReasons.Other):
        return 'Other';
    }
  }
}
