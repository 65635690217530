import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AgGridLocalSettingsService } from '@shared/services/ag-grid/ag-grid.service';
import { ColDef, ColumnApi, ColumnState } from 'ag-grid-community';
@Component({
  selector: 'coach-columns-settings',
  templateUrl: './columns-settings.component.html',
  styleUrls: ['./columns-settings.component.scss']
})
export class ColumnsSettingsComponent implements OnInit {
  @Input() columnDefs: Array<ColDef>;
  @Input() columnApi: ColumnApi
  @Input() presetName: string;
  @Input() presetSettings: string;
  @Input() versionKey: string;
  @Output() public closeForm: EventEmitter<any> = new EventEmitter()
  columnSettingsList: Array<ColumnState & { display: boolean, headerName: string }> = []
  constructor(private agGridLocalSettingsService: AgGridLocalSettingsService) { }

  ngOnInit(): void {
    let columnState = this.columnApi?.getColumnState()
    columnState?.forEach(cs => {
      let col = this.columnDefs.filter(cd => cd.field == cs.colId)
      if (col?.[0]?.headerName) {
        this.columnSettingsList.push(Object.assign(cs, { display: !cs.hide, headerName: col[0].headerName }))
      }
    })
  }
  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

  }
  closeModal(): void {
    this.closeForm.emit()
  }
  submitChanges(): void {
    let oldColumnState = this.columnApi.getColumnState()
    let columnSettingsListObj = {} 
    this.columnSettingsList.forEach(cs => {
      columnSettingsListObj[cs.colId] = cs
    })
    for(let i in oldColumnState){
      let ocs = oldColumnState[i]
      if(columnSettingsListObj[ocs.colId]){
        ocs.hide = !columnSettingsListObj[ocs.colId].display
      }
    }
    this.columnApi.applyColumnState({state:oldColumnState})
    this.agGridLocalSettingsService.storeColumnSettingsInLocalStorage(this.presetSettings || this.presetName, this.columnApi.getColumnState(),this.versionKey);
    this.closeModal();
  }
}
