
<ng-container *ngIf="requiredError">
  <div class='margin--small invalid-text'>Required</div>
</ng-container>

<ng-container *ngIf="minError">
  <div class='margin--small invalid-text'>Must be later than or equal to {{input?.min | date: 'MM/dd/yyyy'}}</div>
</ng-container>

<ng-container *ngIf="maxError">
  <div class='margin--small invalid-text'>Must be earlier than or equal to {{input?.max | date: 'MM/dd/yyyy'}}</div>
</ng-container>
