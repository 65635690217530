import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HeaderCondensedService } from '@shared/services/header-condensed/header-condensed.service';
import { PatientAlertService } from '@shared/services/patient-alert/patient-alert.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';

import { Patient } from '@shared/models/patient';
import { PatientService } from '@shared/services/patient/patient.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Activity } from '@shared/models/activity';
import { EpisodeOfCare } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/wp-model';
import moment from 'moment';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';

const MESSAGE_REFRESH_INTERVAL_MS: number = 10000;

@Component({
  selector: 'coach-care-patient',
  templateUrl: './care-patient.component.html',
  inputs: ['patient'],
})
export class CarePatientComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;
  public patientId: string;
  public src: string;
  public tier: IHierarchyTier = null;
  private tierId: string;
  interval;
  private orgId: string = null;
  private professionList = new Array();
  constructor(
    private _route: ActivatedRoute,
    private _isHeaderCondensed: HeaderCondensedService,
    private PatientService: PatientService,
    private patientAlertService: PatientAlertService,
    private HierarchyTierService: HierarchyTierService,
    private ToastrMessageService: ToastrMessageService
  ) {
    // CC-2818 Don't need to subscribe to this since we're now refreshing
    // when the route params change
    // HierarchyTierService.currentTier$.subscribe((tier) => {
    //   this.tier = tier;
    //   this.tierId = this?.tier?.selectedTierId;
    //   if (tier) {
    //     this.refresh();
    //   }
    // });

    _route.params.subscribe((next: Params) => {
      this.tier = HierarchyTierService.currentTier$.getValue();
      this.tierId = this.tier?.selectedTierId;
      this.refresh();
    });
  }

  ngOnInit(): void {
    this._isHeaderCondensed.setHeaderCondensed(true);
  }
  ngOnDestroy(): void {
    this._isHeaderCondensed.setHeaderCondensed(false);
  }

  async refresh() {
    const orgId = await this.PatientService.getWpOrgIdFromTierId(this.tierId);
    const params = this._route.snapshot.params;
    this.patient = await this.PatientService.getPatient(orgId, params.id);
    if(!this.patient.id) {
      this.ToastrMessageService.error(new Error(`Patient ${params.id} not found`));
    }
    this.patient.hideTabsAndDisableTimeTracking = orgId ? false : true;
    this.patient.activities = [];
    if (!this.patient) {
      return;
    }
    let promises: Array<Promise<unknown>> = [];
    if (this.patient?.carePatient?._id && this.tierId) {
      // CC-4562 - getTasks needs completions initialized but before that was only happening in this.getPatientAlerts
      await this.getCompletions();
      const wpPatientId = this.patient.carePatient._id;
      await Promise.all([
        this.getTasks(),
        this.addPatientHistoryToActivityList(),
        this.getUserActivity(),
        this.getPatientAlerts(wpPatientId)
      ]);
    }
    promises.push(
      this.PatientService.getProfessional(this.patient.professionalId).then(
        (p) => {
          this.patient.professional = p;
        }
      )
    );
    await Promise.all(promises);
  }

  // ============== GETTERS ======================
  async getCompletions() {
    if (this.patient.completions) {
      return;
    }
    this.patient.completions =
      (await this.PatientService.getCompletionActivity(
        this.tierId,
        this.patient
      )) || [];
    let completionById = {};
    for (let c in this.patient.completions) {
      completionById[this.patient.completions[c]._id] = this.patient.completions[c];
    }
  }

  async getPatientAlerts(id: string) {
    if (!id) {
      return;
    }
    let alerts = await this.patientAlertService.getPatientAlerts(
      this.tierId,
      id
    );
    if (alerts) {
      alerts.sort((a, b) => {
        if (moment(a.createdAt).isAfter(moment(b.createdAt))) return -1;
        else return 1;
      });
      let completionById = {};
      alerts.forEach(async (a) => {
        // console.log("a.createdAt =>", a.createdAt)
        if (a.relatedObjects) {
          a.completion = completionById[a.relatedObjects[0].id];
        }
      });
      this.patient.alerts = {
        handled: alerts.filter((a) => !a.isActive),
        unhandled: alerts.filter((a) => a.isActive),
        all: alerts,
      };
    }
    this.addInactiveByProfessionalNamesAndActivityList().then;
  }
  private async addInactiveByProfessionalNamesAndActivityList() {
    this.gatherInactiveByProfesionalNamesIds();
    let promises = new Array();
    this.professionList.forEach((element) => {
      promises.push(this.PatientService.getProfessional(element.inactiveById));
    });
    const completeResult = Promise.all(promises)
      .then((profs) => {
        let i = 0;
        profs.forEach((prof) => {
          this.professionList[i].firstName = prof.firstName;
          this.professionList[i].lastName = prof.lastName;
          i++;
        });
        this.addAlertsToActivityList();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  private gatherInactiveByProfesionalNamesIds() {
    this.patient.alerts.all.forEach((a) => {
      if (a.inactiveAt && Date.parse(a.updatedAt.toString()) < Date.now()) {
        if (a.inactiveBy) {
          //&& self.professionals) {
          if (
            !this.professionList.find(
              ({ inactiveById }) => inactiveById === a.inactiveBy
            )
          ) {
            let handledByProfessional = {
              inactiveById: a.inactiveBy,
              firstName: null,
              lastName: null,
            };
            this.professionList.push(handledByProfessional);
          }
        }
      }
    });
  }

  async getUserActivity() {
    this.patient.userActivity = await this.PatientService.getUserActivity(
      this.tierId,
      this.patient
    );
    this.addLoginHistoryToActivityList();
  }
  async getTasks() {
    this.patient.tasks = await this.PatientService.getTasks(
      this.tierId,
      this.patient
    );
    this.addCompletionsToActivityList();
  }
  private getTaskFromId(id) {
    let task;
    if (this.patient.tasks) {
      for (var i = 0; i < this.patient.tasks.length; i++) {
        if (this.patient.tasks[i]._id == id) {
          task = this.patient.tasks[i];
          break;
        }
      }
    }
    return task;
  }

  // =============== ADDERS ======================
  private addLoginHistoryToActivityList(): void {
    // Invited
    if (this.patient.userActivity && this.patient.userActivity.logins) {
      let loginAct: Activity;
      this.patient.userActivity.logins.forEach((l) => {
        loginAct = {
          type: 'login',
          date: l.createdAt,
          title: 'login',
          description: null,
          detail: [
            {
              label: `login attempts: ${l.loginAttempts}`,
              content: l.success ? 'success' : 'failure',
            },
          ],
        };
        this.patient.activities.push(loginAct);
      });
      this.patient.activities.sort((a, b) => {
        if (Date.parse(a.date) > Date.parse(b.date)) return -1;
        else return 1;
      });
    }
  }
  private addPatientHistoryToActivityList(): void {
    // Invited
    if (!this.patient) return;
    if (this.patient.carePatient?.createdAt) {
      let invitedAct: Activity = {
        type: 'invitation',
        date: this.patient.carePatient.createdAt,
        title: 'Invited',
        description: null,
        detail: null,
      };
      this.patient.activities.push(invitedAct);
    }

    // Procedure date
    if (this.patient.includedObjects?.episodesOfCare) {
      this.patient.includedObjects.episodesOfCare.forEach((ep) => {
        let eps: EpisodeOfCare = ep;
        let procedureDateAsUtc = Date.parse(eps.procedureDate);
        let procedureDateLocal = new Date(procedureDateAsUtc).toDateString();
        let epsAct: Activity = {
          type: 'procedure',
          date: procedureDateLocal,
          title: eps.procedureName,
          description: eps.procedureName,
          detail: null,
        };
        this.patient.activities.push(epsAct);
      });
    }
    // Dischareg/Readmit
    if (this.patient.statusHistory) {
      this.patient.statusHistory.forEach((sh) => {
        let shAct: Activity;
        if (sh.hasOwnProperty('isDischarged')) {
          if (sh.isDischarged) {
            //let event = new Event("discharge", moment(sh.updatedAt.iso), "Discharged", "Patient discharged");
            shAct = {
              type: 'discharge',
              date: sh.updatedAt.iso,
              title: 'Discharged',
              description: 'Patient discharged',
              detail: null,
            };
            this.patient.activities.push(shAct);
          } else {
            //let event = new Event("admit", moment(sh.updatedAt.iso), "Admitted", "Patient admitted");
            shAct = {
              type: 'admit',
              date: sh.updatedAt.iso,
              title: 'Admitted',
              description: 'Patient admitted',
              detail: null,
            };
            this.patient.activities.push(shAct);
          }
        }
      });
    }
    this.patient.activities.sort((a, b) => {
      if (Date.parse(a.date) > Date.parse(b.date)) return -1;
      else return 1;
    });
  }
  private addCompletionsToActivityList(): void {
    this.patient.completions &&
      this.patient.completions.forEach((c) => {
        if (this.shouldShowCompletionInHistory(c)) {
          let completionDate = new Date(c.createdAt); // date object treated as local beginning-of-day
          if (Date.parse(c.createdAt) < Date.now()) {
            let cActivity;
            let t;
            if (!c.name && (t = this.getTaskFromId(c.taskId))) {
              c.name = t.name;
            }
            if (!c.surveyResponseId) {
              cActivity = {
                type: 'completion',
                date: c.createdAt,
                title: 'Task completion',
                description: c.name,
                detail: [
                  {
                    label: 'Task',
                    content: c.name,
                  },
                ],
              };
              //type: string, date: moment.Moment, title: string, desc: string
              //cActivity = new Event("completion", moment(completionDate), "Task completion", c.name);
              //cActivity.detail.push({label:"Task", content:c.name});
            } else if (c.surveyResponseId) {
              //cActivity = new Event("completion", moment(completionDate), "Survey completion", c.name);
              //cActivity.detail.push({label:"Survey", content:c.name});
              cActivity = {
                type: 'completion',
                date: c.createdAt,
                title: 'Survey completion',
                description: c.name,
                detail: [
                  {
                    label: 'Survey',
                    content: c.name,
                  },
                ],
              };
            } else {
              // TODO - Don't have anything sensible to say about other completion events. We'll add them
              // here once we figure out what we want to do with them.
              // completionEvent = new Event("completion", moment(completionDate), "Completion", c.name);
            }
            if (cActivity) {
              //cActivity._source = c;
              this.patient.activities.push(cActivity);
            }
          }
        }
      });
    this.patient.activities &&
      this.patient.activities.sort((a, b) => {
        if (Date.parse(a.date) > Date.parse(b.date)) return -1;
        else return 1;
      });
  }
  public addAlertsToActivityList(): void {
    this.patient.alerts?.all?.forEach((a) => {
      if (!a.inactiveAt && Date.parse(a.createdAt.toString()) < Date.now()) {
        this.patient.activities.push({
          type: 'alert raised',
          date: a.createdAt,
          title: 'Alert raised',
          description: 'Alert raised',
          detail: [
            {
              label: 'Alert',
              content: a.title,
            },
          ],
        });
      } else if (
        a.inactiveAt &&
        Date.parse(a.updatedAt.toString()) < Date.now()
      ) {
        const activity = {
          type: 'alert dismissed',
          date: `${a.inactiveAt}`,
          title: 'Alert closed',
          description: 'Alert closed',
          detail: [
            {
              label: 'Alert',
              content: a.title,
            },
          ],
        };
        this.patient.activities.push(activity);

        if (a.inactiveBy) {
          let handledByProfessional;
          if (
            !(handledByProfessional = this.professionList.find(
              ({ inactiveById }) => inactiveById === a.inactiveBy
            ))
          ) {
            handledByProfessional = { firstName: 'unknown', lastName: '' }; //_.find(self.professionals, (p:any) => p._id === a.inactiveBy);
            console.error('professionl Name not found: ', a.inactiveBy);
          }
          if (handledByProfessional) {
            activity.detail.push({
              label: 'Closed by',
              content:
                handledByProfessional.firstName +
                ' ' +
                handledByProfessional.lastName,
            });
          }
        }
        if (a.inactiveAt) {
          activity.detail.push({
            label: 'Original alert raised',
            content: moment(a.createdAt)
              .format('MMM DD, yyyy hh:mm')
              .toString(),
          });
        }
        if (a.inactiveReason) {
          activity.detail.push({
            label: 'Reason',
            //inActiveReason
            //Info is cached
            content: this.inactiveReasonTextFromAlertDismissReason(
              a.inactiveReason
            ),
          });
        }
      }
    });
    this.patient.activities.sort((a, b) => {
      if (Date.parse(a.date) > Date.parse(b.date)) return -1;
      else return 1;
    });
  }

  //  ================ HELPERS ===================
  formatDate(date: string): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  public inactiveReasonTextFromAlertDismissReason(reason: string): string {
    switch (reason) {
      case 'ignore':
        return 'Ignored';
      case 'phone':
        return 'Phoned patient';
      case 'handoff':
        return 'Handed off to PA or MD';
      case 'message':
        return 'Messaged patient';
      case 'reviewed':
        return 'Reviewed';
      default:
        if (reason && reason.length) {
          return reason.substring(0, 1).toUpperCase() + reason.substring(1);
        }
    }
  }
  private shouldShowCompletionInHistory(c: any): boolean {
    if (c && c.taskType === 'patientsatisfaction') {
      return false;
    }
    return true;
  }}
