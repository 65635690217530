<ng-container *hasFeatureFlag="hccEnhanced2FeatureFlag">
  <coach-clinicians-report2></coach-clinicians-report2>
</ng-container>
<ng-container *doesNotHaveFeatureFlag="hccEnhanced2FeatureFlag">
  <coach-header-title-portal>
    <coach-header-breadcrumbs [showBreadcrumbs]="true"
      class="row--verticalCenter u-preserve"></coach-header-breadcrumbs>
  </coach-header-title-portal>

  <ng-container *ngIf="isLoaded">
    <ag-grid-angular class="ag-theme-material clinician-grid dynamicBody" [pagination]="true"
      [paginationAutoPageSize]="true" multiSortKey="ctrl" [rowData]="rowData | async" [columnDefs]="columnDefs"
      (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)" 
      coachAgGridResize (filterChanged)="filterChanged($event)">
    </ag-grid-angular>

    <coach-export-report-button class="export-button" [gridApi]="gridApi" reportName="CliniciansReport">
    </coach-export-report-button>
  </ng-container>

  <coach-hierarchy-required pageName="Chronic Conditions Dashboard reports" [requiredTier]="2"></coach-hierarchy-required>
</ng-container>