import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { HelpPanelComponent } from './help-panel.component';

import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [HelpPanelComponent],
  imports: [
    SharedModule,
    MarkdownModule.forChild()
  ],
  exports: [HelpPanelComponent]
})
export class HelpPanelModule { }
