import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth-service/auth.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocRepo } from '@shared/models/docRepo/list';
import { BehaviorSubject } from 'rxjs';
import { ClinicianRoster } from '@shared/models/docRepo/clinician-roster-model';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  public generalFolderList$: BehaviorSubject<DocRepo[]>;
  public selectedFolder$: BehaviorSubject<DocRepo>;
  private get headers(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authService.IdToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    });
  }
  public uploadFolders: DocRepo[];

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.generalFolderList$ = new BehaviorSubject([]);
    this.selectedFolder$ = new BehaviorSubject(null);
  }

  downloadFile(doc: string): any {
    return this.http.get(doc, { responseType: 'blob' });
  }

  fetchDocRepoList(tierNode: string): void {
    const listResult = this.http.get<DocRepo[]>(
      environment.coachApiUrl + `/api/docRepo/list/${tierNode}`,
      { headers: this.headers, responseType: 'json' }
    );
    listResult.subscribe(data => {
      if (data && data.length !== 0) {
        this.generalFolderList$.next(data[0].Children);
        this.setupUploadDocRepoPaths(data[0].Children);
      }
    });
  }

  getChildrenByCollectionName(tabName: string, docRepoList: DocRepo[]): DocRepo[] {
    const yourFolderData = docRepoList.filter(item => item.Title === tabName);
    if (yourFolderData && yourFolderData[0] && yourFolderData[0].Children) {
      return yourFolderData[0].Children;
    }
  }

  getSpecificFile(fileID: number): any {
    let response: Observable<any>;
    const params: HttpParams = new HttpParams()
      .set('fileID', fileID.toString());
    response = this.http.get<any>(environment.coachApiUrl + '/api/docRepo/file',
      { headers: this.headers, params, responseType: 'text' as 'json' });
    return response;
  }

  updateSelectedFolder(docRepo: DocRepo): void {
    this.selectedFolder$.next(docRepo);
  }

  private setupUploadDocRepoPaths(allDocRepo: DocRepo[] ): void {

    const PIDocs = this.getChildrenByCollectionName('Practice Improvement', allDocRepo);
    const toCaravanPHI = PIDocs.filter(item => item.Title === 'To Caravan WITH PHI')[0];
    const toCaravan = PIDocs.filter(item => item.Title === 'To Caravan NO PHI')[0];
    this.uploadFolders = [toCaravan, toCaravanPHI];
  }

  getMyTeamPage(tierID: string): Observable<string> {
    const params: HttpParams = new HttpParams().set('tierID', tierID);

    return this.http.get<string>(environment.coachApiUrl + '/api/docRepo/myTeam',
      { headers: this.headers, params, responseType: 'text' as 'json' });
  }

  getClientContacts(tierID: string, altTierID: string): Observable<string> {
    const params: HttpParams = new HttpParams().set('tierID', tierID).set('altTierID', altTierID);

    return this.http.get<string>(environment.coachApiUrl + '/api/docRepo/clientContacts',
      { headers: this.headers, params, responseType: 'text' as 'json' });
  }

  getClinicianRoster(tierID: string, altTierID: string): Observable<ClinicianRoster[]> {
    const params: HttpParams = new HttpParams().set('tierID', tierID).set('altTierID', altTierID);

    return this.http.get<ClinicianRoster[]>(environment.coachApiUrl + '/api/docRepo/clinicianRosters',
      { headers: this.headers, params });
  }
}
