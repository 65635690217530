import { Component, Inject, InjectionToken, Input, OnInit, SimpleChanges } from '@angular/core';
import { SelectedEventValue } from '../header-scope-dropdown/header-scope-dropdown.component';

export interface NavigationPortalData {
  highestTier: number;
}
export const NAVIGATION_PORTAL_DATA = new InjectionToken<NavigationPortalData>('NAVIGATION_PORTAL_DATA');

interface TierInfo {
  hierarchyTier: number;
  parentId: string;
}

@Component({
  selector: 'coach-header-scope-overlay',
  templateUrl: './header-scope-overlay.component.html',
  styleUrls: ['./header-scope-overlay.scss'],
  host : {
    class: 'scopeSelectDropdownWrapper'
  }
})
export class HeaderScopeOverlayComponent implements OnInit {
  private highestTier: number;
  public scope: string;
  constructor(
    @Inject(NAVIGATION_PORTAL_DATA) data: NavigationPortalData,
  ) {
    this.highestTier = data.highestTier;
    console.log(this.highestTier);

    for (let i = this.highestTier; i <= 4; i++) {
      const tier: TierInfo = {
        parentId: i === this.highestTier ? null : '',
        hierarchyTier: i,
      };
      this.tiers.push(tier);
    }
    console.log(this.tiers);
  }
  public tiers: TierInfo[] = [];
  public changed: SelectedEventValue;
  isEditDetails = true;

  ngOnInit(): void {
  }

  toggleDrillIn(): void {
    this.isEditDetails = !this.isEditDetails;
  }

  applyScope(): void {
    console.log(this.scope);
  }

  selectionChange($event): void {
    this.scope = $event.target.value;
    switch ($event.target.id) {
      case 'header-scope-dropdown-aco':
        this.tiers[1].parentId = $event.target.value;
        this.tiers[2].parentId = '';
        this.tiers[3].parentId = '';
        break;
      case 'header-scope-dropdown-community':
        const prpTier = 3 - this.highestTier;
        this.tiers[prpTier].parentId = $event.target.value;
        this.tiers[prpTier + 1].parentId = '';
        break;
      case 'header-scope-dropdown-principal':
        const praTier = 4 - this.highestTier;
        this.tiers[praTier].parentId = $event.target.value;
        break;
    }
  }

}
