
import { formatDate } from '@angular/common';
import { toInteger } from 'lodash';
import {
  formatDate as fmtDate,
  invoiceStatusPriorityMap,
} from '../care-prescriptions/shared-lib';
import { ColDef } from 'ag-grid-community';

function getSortCompatibleDate(dateStr) {
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let result = `${year}/${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}`;
  return result;
}

export const COLUMN_DEFS: Array<ColDef> = [
  {
    headerName: 'Status',
    field: 'status',
    maxWidth: 180,
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.status;
    },
    comparator: (s1, s2) => {
      let sx1 = invoiceStatusPriorityMap.get(s1);
      let sx2 = invoiceStatusPriorityMap.get(s2);
      return sx1 - sx2;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
  {
    headerName: 'Claim ID',
    field: 'claimId',
    maxWidth: 120,
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.claimId;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
  {
    headerName: 'Rx Number',
    field: 'rxNumber',
    maxWidth: 120,
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.rxNumber;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
  {
    headerName: 'Fill Number',
    field: 'fillNumber',
    maxWidth: 120,
    resizable: true,
    cellRenderer: (data: any): string => {
      let result = data.data?.fillNumber?.replace(/^0+/, '');
      return result || '0';
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    valueGetter: (data: any): number => {
      return toInteger(data.data?.fillNumber);
    },
    sortable: true,
  },
  {
    headerName: 'NDC',
    field: 'ndc',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.ndc;
    },
    valueGetter: (data: any): number => {
      return toInteger(data.data?.ndc);
    },
    sortable: true,
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'NDC Description',
    field: 'medicationDescription',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.medicationDescription;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
  {
    headerName: 'Cost Amount',
    field: 'costAmount',
    resizable: true,
    cellRenderer: (data: any): string => {
      return `$${(data?.data.costAmount).toFixed(2)}`;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
    valueGetter: (data: any): number => {
      return toInteger(data.data?.costAmount);
    },
    sortable: true,
  },
  {
    headerName: 'Caravan Fee',
    field: 'caravanFee',
    resizable: true,
    cellRenderer: (data: any): string => {
      return `$${(data?.data?.caravanFee).toFixed(2)}`;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
    valueGetter: (data: any): number => {
      return parseFloat(data.data?.caravanFee);
    },
    sortable: true,
  },
  {
    headerName: 'Created On',
    field: 'fillDate',
    resizable: true,
    hide: true,
    cellRenderer: (data: any): string => {
      return fmtDate(data.data?.createdOn);
    },
    valueGetter: (data: any): string => {
      let result = formatDate(new Date(data.data?.createdOn), 'yyyy/MM/dd', 'en_US').trim();
      return result;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },
  {
    headerName: 'Contract Pharmacy',
    field: 'contractPharmacy',
    resizable: true,
    hide: true,
    cellRenderer: (data: any): string => {
      return data.data?.contractPharmacy;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },
  {
    headerName: 'TPA Name',
    field: 'tpaName',
    resizable: true,
    hide: true,
    cellRenderer: (data: any): string => {
      return data.data?.tpaName;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },
  {
    headerName: 'Claim Type',
    field: 'claimType',
    minWidth: 100,
    resizable: true,
    sortable: true,
    hide: true,
    filter: 'agSetColumnFilter',
  },

  {
    headerName: 'Claim Source',
    field: 'tpaName',
    minWidth: 120,
    suppressAutoSize: true,
    hide: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },

  {
    headerName: 'Drug Manufacturer',
    field: 'drugManufacturer',
    minWidth: 120,
    suppressAutoSize: true,
    hide: false,
    filter: 'agSetColumnFilter',
    sortable: true,
  },
  
  {
    headerName: 'Pending On',
    field: 'pendingDate',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.pendingDate ? formatDate(data.data?.pendingDate, 'MM/dd/yy', 'en_US').trim() : '';
    },
    valueGetter: (data: any): string => {
      let result;
      if (data.data?.pendingDate) {
        result = formatDate(new Date(data.data?.pendingDate), 'yyyy/MM/dd', 'en_US').trim();
      } else {
        result = '';
      }
      return result;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },
  {
    headerName: 'Invoicing On',
    field: 'invoicingDate',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.invoicingDate ? formatDate(data.data?.invoicingDate, 'MM/dd/yy', 'en_US').trim() : '';
    },
    valueGetter: (data: any): string => {
      let result;
      if (data.data?.invoicingDate) {
        result = formatDate(new Date(data.data?.invoicingDate), 'yyyy/MM/dd', 'en_US').trim();
      } else {
        result = '';
      }
      return result;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },
  {
    headerName: 'Invoiced On',
    field: 'invoicedDate',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.invoicedDate ? formatDate(data.data?.invoicedDate, 'MM/dd/yy', 'en_US').trim() : '';
    },
    valueGetter: (data: any): string => {
      let result;
      if (data.data?.invoicedDate) {
        result = formatDate(new Date(data.data?.invoicedDate), 'yyyy/MM/dd', 'en_US').trim();
      } else {
        result = '';
      }
      return result;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },
  {
    headerName: 'Rejected On',
    field: 'rejectedDate',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.rejectedDate ? formatDate(data.data?.rejectedDate, 'MM/dd/yy', 'en_US').trim() : '';
    },
    valueGetter: (data: any): string => {
      let result;
      if (data.data?.rejectedDate) {
        result = formatDate(new Date(data.data?.rejectedDate), 'yyyy/MM/dd', 'en_US').trim();
      } else {
        result = '';
      }
      return result;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },
  {
    headerName: 'Rejected Needs Credit On',
    field: 'rejectedNeedsCreditDate',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.rejectedNeedsCreditDate ? formatDate(data.data?.rejectedNeedsCreditDate, 'MM/dd/yy', 'en_US').trim() : '';
    },
    valueGetter: (data: any): string => {
      let result;
      if (data.data?.rejectedNeedsCreditDate) {
        result = formatDate(new Date(data.data?.rejectedNeedsCreditDate), 'yyyy/MM/dd', 'en_US').trim();
      } else {
        result = '';
      }
      return result;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },  
  {
    headerName: 'Credited On',
    field: 'creditedDate',
    resizable: true,
    cellRenderer: (data: any): string => {
      return data.data?.creditedDate ? formatDate(data.data?.creditedDate, 'MM/dd/yy', 'en_US').trim() : '';
    },
    valueGetter: (data: any): string => {
      let result;
      if (data.data?.creditedDate) {
        result = formatDate(new Date(data.data?.creditedDate), 'yyyy/MM/dd', 'en_US').trim();
      } else {
        result = '';
      }
      return result;
    },
    suppressSizeToFit: false,
    filter: 'agSetColumnFilter',
    editable: false,
  },  
];
