import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Patient } from '@shared/models/patient';
import moment from 'moment/moment';
import { ScheduledOutcome } from '../../../../../../api/care-orch/models/scheduled-outcome';
import { OutcomeType } from '../../../../../../api/care-orch/models/outcome-type';
import { PropertiesType } from '../../../../../../api/care-orch/models/properties-type';
import { ScheduledProperties } from '../../../../../../api/care-orch/models/scheduled-properties';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {PracticeListResult} from '@api/track/models/practice-list-result';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import { PracticeService } from '@shared/services/hierarchy/practice.service';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export class Practice {
  name: string;
  constructor(name) { this.name = name; }
}

export class Comment {
  createdDateTime: string;
  createdUserDisplayName: string;
  commentText: string;
  constructor(createdDateTime, createdUserDisplayName, commentText) {
    this.createdDateTime = createdDateTime;
    this.createdUserDisplayName = createdUserDisplayName;
    this.commentText = commentText;
  }
}

@Component({
  selector: 'coach-patient-workflow-scheduled',
  templateUrl: './patient-workflow-scheduled.component.html',
  styleUrls: ['./patient-workflow-scheduled.component.scss']
})
export class PatientWorkflowScheduledComponent implements OnInit {
  @Input() public set patient(patient: Patient){
    if (patient) {
      this.clearFields();
      this.loadPracticesDropdown(patient);
    }
  }
  @Input() public properties: ScheduledProperties;
  @Input() public editModeActive = false;
  @Output() outcomeSaved: EventEmitter<ScheduledOutcome> = new EventEmitter();
  @Output() deleteActivity: EventEmitter<string> = new EventEmitter();

  scheduledDate = '';
  scheduledPracticeId = '';
  providerName = '';
  comment = '';
  practices: PracticeListResult[];
  allFieldsEmpty = true;
  requiredFieldsSatisfied = false;
  commentCharacterLimit = CareOrchestrationConstants.commentBoxCharacterLimit;
  providerFieldCharacterLimit = CareOrchestrationConstants.providerFieldCharacterLimit;
  showRemoveDialog = false;
  minScheduledDate = moment().subtract(1, 'year').format('yyyy-MM-DD');
  maxScheduledDate = moment().add(1, 'year').format('yyyy-MM-DD');
  control = new UntypedFormControl();
  filteredPractices: PracticeListResult[];
  practiceOnLoad: string;

  constructor(private practiceService: PracticeService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.properties){
      if (this.properties.date) {
        this.scheduledDate = WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.properties.date);
      }
      this.providerName = this.properties.providerName;
    }
  }
  private _filter(value: string): PracticeListResult[] {
    const filterValue = this._normalizeValue(value);
    return this.practices.filter(practice => this._normalizeValue(practice.tier4Name).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  loadPracticesDropdown(patient: Patient): void {
    this.practiceService.getPractices(patient).then(practices => {
      this.practices = practices;
      this.filteredPractices = this._filter('');
      if (this.properties && this.properties.practiceId) {
          this.scheduledPracticeId = this.properties.practiceId;
          this.practiceOnLoad = this.practices.find(x => x.tier4Id === this.scheduledPracticeId).tier4Name.toString();
          this.control.setValue(this.practiceOnLoad);
        } else {
          this.scheduledPracticeId = '';
        }
    });
  }
  filterPractices(value: string ): void{
    this.filteredPractices = value ? this._filter(value) : [...this.practices];
  }
  displayFn(practice?: string): string {
    return practice;
  }
  getPracticeId(event: MatAutocompleteSelectedEvent): void{
    this.scheduledPracticeId = event.option.id;
    this.fieldChanged();
  }

copyComment(commentBox): void {
    commentBox.select();
    document.execCommand('copy');
    commentBox.setSelectionRange(0, 0);
  }

  fieldChanged(): void {
    this.checkAnyFieldHasValue();
    this.checkRequiredFieldsSatisfied();

  }

  private checkAnyFieldHasValue(): void {
    if (this.scheduledDate === '' && this.scheduledPracticeId === '' && this.comment === '' && this.providerName === '') {
      this.allFieldsEmpty = true;
      return;
    }

    this.allFieldsEmpty = false;
  }

  public isScheduledDateValid(): boolean {
    const scheduledToDate =  moment(this.scheduledDate, 'YYYY-MM-DD').toDate();
    const minScheduledToDate =  moment(this.minScheduledDate, 'YYYY-MM-DD').toDate();
    const maxScheduledToDate =  moment(this.maxScheduledDate, 'YYYY-MM-DD').toDate();

    return scheduledToDate >= minScheduledToDate && scheduledToDate <= maxScheduledToDate;
  }

  private checkRequiredFieldsSatisfied(): void  {
    if (!this.editModeActive){
      if (this.isScheduledDateValid()) {
        this.requiredFieldsSatisfied = true;
        return;
      }
      this.requiredFieldsSatisfied = false;
    }
    else {
      this.checkIfFieldsWereEdited();
    }
  }
  private checkIfFieldsWereEdited(): void {
    let date = this.properties.date;
    date = date.substring(0, 10);
    if (date !== this.scheduledDate && this.isScheduledDateValid()) {
      this.requiredFieldsSatisfied = true;
      return;
    }
    if (this.properties.providerName !== this.providerName) {
      this.requiredFieldsSatisfied = true;
      return;
    }
    if (this.properties.practiceId !== this.scheduledPracticeId) {
      this.requiredFieldsSatisfied = true;
      return;
    }
    if ('' !== this.comment) {
      this.requiredFieldsSatisfied = true;
      return;
    }
    this.requiredFieldsSatisfied = false;
  }

  clearFields(): void {
    if (!this.editModeActive) {
      this.scheduledDate = '';
      this.scheduledPracticeId = '';
      this.control.setValue('');
      this.providerName = '';
      this.comment = '';
      this.fieldChanged();
    }
    else{
      this.resetFields();
    }
  }
  private resetFields(): void {
    this.scheduledDate = this.properties.date.substring(0, 10);
    this.scheduledPracticeId = this.properties.practiceId;
    this.control.setValue(this.practiceOnLoad);
    this.providerName = this.properties.providerName;
    this.comment = '';
    this.requiredFieldsSatisfied = false;
    this.allFieldsEmpty = true;
  }

  save() {
    const scheduledOutcome = {
      type: OutcomeType.ScheduledOutcome,
        properties: {
          type: PropertiesType.ScheduledProperties,
          date: WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.scheduledDate),
          practiceId: this.scheduledPracticeId ? this.scheduledPracticeId :  null,
          providerName: this.providerName ? this.providerName : null,
          comment: this.comment ? this.comment : null,
        } as unknown as ScheduledProperties
    } as ScheduledOutcome;
    if (scheduledOutcome != null) {
      this.outcomeSaved.emit(scheduledOutcome);
    }

    this.clearFields();
  }

  onDialogButtonClick(buttonValue: string): void {
    switch (buttonValue){
      case 'cancel':
        this.showRemoveDialog = false;
        break;
      case 'delete':
        this.deleteActivity.emit();
    }
  }

  showDialog(): void {
    this.showRemoveDialog = true;
  }

  stringifyForm(): string {
    if (!this.scheduledDate) {
      return '';
    }

    let value = 'AWV scheduled for ' + WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat(this.scheduledDate);

    if (this.providerName) {
      value += ' with ' + this.providerName;
    }

    if (this.scheduledPracticeId) {
      value += ' at ' + this.practices.find(p => p.tier4Id === this.scheduledPracticeId).tier4Name;
    }

    if (this.comment) {
      value += '. ' + this.comment;
    }

    return value;
  }
}


