import {Component, Input} from '@angular/core';
import { AwvWorkflow } from '@api/care-orch/models/awv-workflow';
import { AwvService } from '@api/care-orch/services/awv.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { History } from '@shared/modules/patient-facesheet/tabs/patient-awv-history/patient-awv-history.component';
import { Patient } from '@shared/models/patient';
import { CallbackProperties } from '@api/care-orch/models/callback-properties';
import { PatientAwvUtilities } from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/patient-awv-utilities.component';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';


@Component({
  selector: 'coach-patient-awv-callback',
  templateUrl: './patient-awv-callback.component.html',
  styleUrls: ['./patient-awv-callback.component.scss']
})
export class PatientAwvCallbackComponent {
  @Input() public patientId: string;
  @Input() public patient: Patient;
  @Input() public awvWorkflow: AwvWorkflow;
  @Input() public properties: CallbackProperties;

  callbackReason = '';
  callbackDate = '';
  comment = '';
  history: History[] = [];

  constructor(private awvService: AwvService, private authService: AuthService) {
    console.log(this.awvWorkflow);
  }

  ngOnChanges(){
    if (this.properties){
      this.callbackReason = PatientAwvUtilities.callBackReasonToString(this.properties.reason);
      this.callbackDate = WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.properties.date);
      this.comment = this.properties.comment;
    }
  }
}
