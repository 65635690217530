import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Patient } from '@shared/models/patient';
@Component({
  selector: 'coach-patient-alert',
  templateUrl: './patient-alert.component.html',
  styleUrls: ['./patient-alert.component.scss'],
})
export class PatientAlertComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  goal = '';

  constructor() {}

  private get userGoal() {
    return this.patient?.carePatient?.userGoal || null
  }

  ngOnInit(): void {}

  ngOnChanges() {
    this.goal = this.userGoal;
  }
  get isPatientInvited():boolean {
    return this?.patient?.carePatient?.accountType === 'patient';
  } 
}
