import { HierarchyTierEnum } from './hierarchy-tier-enum';
import { HierarchyTierItem } from './hierarchy-tier-item';

export const CHTIN_PREFIX = 'CHTIN';

export interface IHierarchyTier {
    readonly name: string;
    readonly abbreviation: string;
    readonly type: HierarchyTierEnum;
    readonly tier: number;
    items: HierarchyTierItem[];
    selectedItem: HierarchyTierItem;
    isLoading: boolean;
    highestTier: number;
    selectedTierId: string;
    selectedTierIdForDisplay: string;
    equals(other: IHierarchyTier): boolean;
    getTierId(item: HierarchyTierItem, tierNum: number): string;
}

export class HierarchyTier implements IHierarchyTier {
    name: string;
    abbreviation: string;
    type: HierarchyTierEnum;
    tier: number;
    items: HierarchyTierItem[];
    selectedItem: HierarchyTierItem = null;
    isLoading: boolean;

    get highestTier(): number {
        if (this.items == null || this.items.length === 0) {
            return null;
        }

        return this.items[0].highestAccessTier;
    }

    get selectedTierIdForDisplay(): string {
        if (this.type === HierarchyTierEnum.Practice && this.selectedItem?.chtinid != null) {
            return `${CHTIN_PREFIX}.${this.selectedItem?.chtinid}.${this.selectedItem?.supplementalID}`;
        }

        return this.getTierId(this.selectedItem, this.tier);
    }

    get selectedTierId(): string {
        return this.getTierId(this.selectedItem, this.tier);
    }

    getTierId(item: HierarchyTierItem, tierNum: number): string {
        return item == null ? null : item['tier' + tierNum + '_ID'];
    }

    equals(other: IHierarchyTier): boolean {
        if (other == null || other.selectedItem == null || this.selectedItem == null) {
            return false;
        }

        return other.selectedItem.tier === this.selectedItem.tier &&
            other.selectedItem.tier1_ID === this.selectedItem.tier1_ID &&
            other.selectedItem.tier2_ID === this.selectedItem.tier2_ID &&
            other.selectedItem.tier3_ID === this.selectedItem.tier3_ID &&
            other.selectedItem.tier4_ID === this.selectedItem.tier4_ID;
    }
}

export class AcoTier extends HierarchyTier {
    tier = 1;
    name = 'ACO';
    abbreviation = 'aco';
    type = HierarchyTierEnum.Aco;
}

export class CommunityTier extends HierarchyTier {
    tier = 2;
    name = 'Community';
    abbreviation = 'com';
    type = HierarchyTierEnum.Community;
}

export class PrincipalParticipantTier extends HierarchyTier {
    tier = 3;
    name = 'Principal Participant';
    abbreviation = 'ppr';
    type = HierarchyTierEnum.PrincipalParticipant;
}

export class PracticeTier extends HierarchyTier {
    tier = 4;
    name = 'Practice';
    abbreviation = 'pra';
    type = HierarchyTierEnum.Practice;
}
