<div class="u-height--fit-content form-background">
  <div class="u-font-size--small">

    <div class="row padding-bottom--small">

        <input #scheduledDateInput placeholder="AWV scheduled date" id="scheduledDateInput" (input)="fieldChanged()"
            [(ngModel)]="scheduledDate" type="date" [min]="minScheduledDate" [max]="maxScheduledDate" required>
        <coach-invalid-input [input]='scheduledDateInput'></coach-invalid-input>
      </div>
      <div class="row padding-bottom--small">
        <form class="form-reportwrapper ">
          <label class='practice-selector'>
          <input type='text'
                 id="scheduledPracticeSelector"
                 placeholder="Practice"
                 [formControl]="control"
                 (input)="filterPractices($event.target.value);"
                 [matAutocomplete]="auto">
            <svg class="icon" ng-reflect-ng-class=""><use href="assets/icons/Magicons.svg#icon-search"></use></svg>
          </label>
          <mat-autocomplete class="u-font-size--smallest" #auto="matAutocomplete"(optionSelected)='getPracticeId($event)' [displayWith]='displayFn'>
            <mat-option *ngFor="let practice of filteredPractices" [value]="practice.tier4Name" [id]="practice.tier4Id">
              {{practice.tier4Name}}
            </mat-option>
          </mat-autocomplete>
        </form>

      </div>

    <div class="row padding-bottom--small">
      <input type="text" [(ngModel)]="providerName" placeholder="Provider" id="testProviderTextScheduled" (input)="fieldChanged()" [maxlength]="providerFieldCharacterLimit">
    </div>
    <div class="row padding-bottom--smallest">
      <textarea class="resize--none" [(ngModel)]="comment" placeholder="Add a comment" id="scheduledComment" rows="6"
          [maxlength]="commentCharacterLimit" (input)="fieldChanged()" #commentbox></textarea>
    </div>

    <div class="row padding-bottom--small">
      <div class="span--6 u-font-weight--light" [ngClass]="{'character-limit-reached': commentbox.value.length == commentCharacterLimit }">{{commentbox.value.length}}/{{commentCharacterLimit}}</div>
      <div class="span--6 u-text-align--right u-cursor--pointer u-font-weight--light padding-right--small" id='testCopyTextScheduled' (click)="copyComment(commentbox)">Copy text</div>
    </div>
    <coach-patient-awv-inline-error></coach-patient-awv-inline-error>
    <div class="row padding-top--small u-align-items--center margin-bottom--small">
      <coach-icon *ngIf="editModeActive" class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" iconName="trash" iconColor="primary" id='testDeleteBtn' (click)="showDialog()"></coach-icon>
      <coach-icon class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" id='testCopyBtnScheduled' iconName="copy" iconColor="primary" [copy]="stringifyForm()"></coach-icon>
      <coach-patient-awv-remove-modal-dialog [showDialog]="showRemoveDialog" (buttonClicked)="onDialogButtonClick($event)"></coach-patient-awv-remove-modal-dialog>
      <button class="span--3 button is-unstyled u-flex--right is-small  margin-right--small cancel-btn u-color--brand--1" id="testCancelBtnScheduled" (click)="clearFields()" [disabled]="allFieldsEmpty">Cancel</button>
      <button class="span--3 button is-small is-primary" id="testSaveBtnScheduled" (click)="save()" [disabled]="!requiredFieldsSatisfied">Save</button>
    </div>
  </div>
</div>
