export class DocRepo {
  Author: string;
  BlobPath?: string;
  Children?: DocRepo[];
  CollectionId: number;
  Created: string;
  Deleted: boolean;
  Container?: string;
  EntryId: number;
  EntryType: string;
  FileSize?: number;
  Hidden: boolean;
  InheritAttributes: boolean;
  OriginalFileName?: string;
  IsApproved: boolean;
  LastModified: string;
  LastVersionId: number;
  Owner: number;
  Path: string;
  Title: string;
  ContainsPHI: boolean;
  Version: number;
}


