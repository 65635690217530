import { Injectable } from '@angular/core';
import {PracticeListResult} from "@api/track/models/practice-list-result";
import {HierarchyService} from "@api/track/services/hierarchy.service";
import {ToastrMessageService} from "@shared/services/toastr-message/toastr-message.service";
import {Patient} from "@shared/models/patient";

@Injectable({
  providedIn: 'root',
})
export class PracticeService {

  lastTierNum: number;
  lastTierId: string;
  lastPracticeList: PracticeListResult[];

  constructor(private hierarchyService: HierarchyService, private toastrMessageService: ToastrMessageService) {}

  public async getPracticeById(patient: Patient, practiceId: string): Promise<string> {
    return (await this.getPractices(patient)).find(p => p.tier4Id === practiceId).tier4Name;
  }

  public async getPractices(patient: Patient): Promise<PracticeListResult[]> {
    let tierId = patient.tier3_ID;
    let tierNum = 3;

    if (!tierId) {
      tierId = patient.tier2_ID;
      tierNum = 2;
    }

    if (!tierId) {
      console.error('No tier 2 for this patient, unable to fetch practices list');
      this.resetLastResult();
      return [];
    }

    // if we haven't dealt with this tier 3 in the past, we need to go fetch its list of practices
    if (this.lastPracticeList == null || this.lastTierNum !== tierNum || this.lastTierId !== tierId) {
      this.resetLastResult();

      try {
        const resp = await this.hierarchyService.hierarchyPracticesGet({tierNum, tierId}).toPromise();
        this.lastTierNum = tierNum;
        this.lastTierId = tierId;
        this.lastPracticeList = resp;
      } catch (e) {
        this.toastrMessageService.error(e);
      }
    }

    return this.lastPracticeList;
  }

  private resetLastResult(): void {
    this.lastTierNum = null;
    this.lastTierId = null;
    this.lastPracticeList = null;
  }
}
