<ng-container *ngIf="isSidebarOpen">
  <div class="subheader u-background-color--brand--2" *isNotTierNum="1">
    Recent
  </div>
  <div class="list sidebar--recents animations--sequential" *isNotTierNum="1">
    <ng-container *ngFor="let item of recentItems">
      <coach-sidebar-recent-element
        [item]="item"
      ></coach-sidebar-recent-element>
    </ng-container>
  </div>
</ng-container>
