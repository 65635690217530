<label class="label margin-top">Level</label>
<select name="filterType" [(ngModel)]="selectedFilterType" (ngModelChange)="filterTypeSelected($event)">
    <option *ngFor="let type of reportFilterTypes" [ngValue]="type">
        {{type}}
    </option>
</select>

<ng-container *ngIf="selectedFilterType !== acoLevel">
    <label class="label margin-top">{{selectedFilterType}}</label>
    <select name="items" [(ngModel)]="selectedItem" (ngModelChange)="itemSelected($event)" *ngIf="!isLoading">
        <option *ngIf="filterItems?.length === 0" disabled [ngValue]="null">None Available</option>
        <option *ngFor="let item of filterItems" [ngValue]="item">
            {{item.filterTypeName ? item.filterTypeName : item.filterTypeID}}
        </option>
    </select>
    <div class="select-placeholder loading-indicator u-display--flex" disabled="true" *ngIf="isLoading">
        <coach-loading-indicator class="u-flex--right"></coach-loading-indicator>
    </div>
</ng-container>