import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import {
  ComplianceFormQuestionSection,
  ComplianceFormSectionAnswers,
} from '@shared/models/forms/compliance-form-question';
import { COMPLIANCE_FORM_QUESTION_SECTIONS } from '@shared/mocks/mock-compliance-form-questions';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { Observable, Subscription, forkJoin  } from 'rxjs';
import { UserInfo } from '@shared/services/auth-service/auth-interface';
import { AuthService } from '@shared/services/auth-service/auth.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
// import { SubmitComplianceService, Participant } from './submit-compliance.service';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { finalize, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
//Clean up later
import { FormConstants, ComplianceFormSettings } from '@shared/models/forms/form-constants';
import { SubmitFeatureConstants  } from '@shared/models/module-constants';
import {
  FormResponses,
  FormSubmission,
  tier3participants,
  CaravanFormRemediationInfo,
} from '@shared/models/forms/forms-backend';
import { CaravanFormSubmissionPeriod  } from '@shared/models/forms/form-submissionID-model';
import { ActivatedRoute, Router } from '@angular/router';
import { number } from '@amcharts/amcharts4/core';
import moment from 'moment';

@Component({
  selector: 'coach-submit-compliance',
  templateUrl: './submit-compliance.component.html',
})
export class SubmitComplianceComponent
  extends KillSubscriptions
  implements OnInit {
  public clearForm: EventEmitter<boolean> = new EventEmitter();
  public formSaving = false;
  public showRemediationModal: boolean;
  // User Info
  public isAuthenticated$: Observable<boolean>;
  public userInfo$: Observable<UserInfo>;
  private _isLoggedIn = false;
  private _currentUser: UserInfo;

  // Subscriptions
  public authSub: Subscription;
  public userSub: Subscription;
  public selectionSub: Subscription;
  public tier3FormSub: Subscription;
  public allSubs: Subscription[];

  // Other UI
  public isAddEditFormActive: boolean = false;
  public dueDate: Date;
  public dueDateSoft: Date;
  public copy: ComplianceFormSettings = FormConstants.Compliance;
  public selectedFormSubPeriod: number;

  //Other
  readonly formsAdminFormFeatureFlag: string = SubmitFeatureConstants.FormsAdminFormFeatureFlag;
  readonly formsAdminFormFeature: number = SubmitFeatureConstants.FormsAdminFormFeature;
  public stepAddEdit= '1';
  public shwSpecialStatus: boolean = false;
  public submitPeriodParam: number;
  public readonly complianceyFormId = 3;
  public formSubmissions: FormSubmission[];
  public caravanFormSubmissionPeriod: CaravanFormSubmissionPeriod[];
  public caravanFormRemediationInfo: CaravanFormRemediationInfo[];
  public isFormQueueOpen: boolean = true;

  public static readonly disabled = 'disabled';

  public compScore: number;
  public displayRemediation: boolean;

  // Practice Select "Sub" Form
  public tier3Form: UntypedFormGroup;
  public participantList: tier3participants[] = [];

  // Form
  public complianceForm: UntypedFormGroup;
  public uploadFormComb: UntypedFormGroup;

  public currentTier: IHierarchyTier;
  public mockQuestionSections: ComplianceFormQuestionSection[] = COMPLIANCE_FORM_QUESTION_SECTIONS;
  public sectionsCompleted: number;
  public tierSelections: number;
  public allSectionsComplete: boolean = false;
  public allAnswers: ComplianceFormSectionAnswers[] = [
    {
      status: null,
      answers: [],
    },
    {
      status: null,
      answers: [],
    },
    {
      status: null,
      answers: [],
    },
    {
      status: null,
      answers: [],
    },
    {
      status: null,
      answers: [],
    },
    {
      status: null,
      answers: [],
    },
    {
      status: null,
      answers: [],
    },
  ];

  get participantsFormArray() {
    return this.tier3Form.get('participants') as UntypedFormArray;
  }

  private addPracticeCheckboxes() {
    this.participantList.forEach(() =>
      this.participantsFormArray.push(new UntypedFormControl(false))
    );
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _cd: ChangeDetectorRef,
    private qualityService: SubmitFormsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.tier3Form = this.formBuilder.group({
      participants: this.formBuilder.array([]),
    });
    this.uploadFormComb = this.formBuilder.group({ file: [null, Validators.required] });
    this.addPracticeCheckboxes();
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this._authService.isAuthenticated$;
    this.userInfo$ = this._authService.userInfo$;

    this.authSub = this.isAuthenticated$
      ?.pipe(takeUntil(this.killTrigger))
      .subscribe(
        (isLoggedIn) => {
          this._isLoggedIn = isLoggedIn;
        },
        (error) => {}
      );

    this.userSub = this.userInfo$
      ?.pipe(takeUntil(this.killTrigger))
      .subscribe((userInfo) => {
        this._currentUser = userInfo;
      });

    this.route.queryParams
      .subscribe(params => {
        if (params.submitperiod != null){
          this.submitPeriodParam = params.submitperiod;
        }

      }
    );

    this.qualityService.getSubmitPeriodDetails(this.complianceyFormId).subscribe((data) => {
      this.caravanFormSubmissionPeriod = data;
      if (this.submitPeriodParam == null) {
        this.selectedFormSubPeriod = this.caravanFormSubmissionPeriod[0].caravanFormSubmitPeriodID;
        this.dueDate = this.caravanFormSubmissionPeriod[0].dueDate;
        this.updateSubmissionPeriodConfigs(0);
      } else
      {
        this.selectedFormSubPeriod = this.submitPeriodParam;
        this.dueDate = this.caravanFormSubmissionPeriod.find(i => i.caravanFormSubmitPeriodID == this.submitPeriodParam).dueDate;
        this.updateSubmissionPeriodConfigs(this.caravanFormSubmissionPeriod.findIndex(i => i.caravanFormSubmitPeriodID == this.submitPeriodParam));
      }

      this.generateComplianceForm();
      this.answerUpdated([0, 0, null, null]);

      this.refreshFormData();
    });
  }
  public refreshFormData(): void {
    const formID = this.complianceyFormId.toString();
    const submissionID = this.selectedFormSubPeriod.toString();

    this.qualityService.getSubmissions(formID, submissionID).subscribe((data) => {
      this.formSubmissions = data;
    });
  }

  public isQueueOpen = true;

  public toggleQueueOpen(): void {
    this.isQueueOpen = !this.isQueueOpen;
  }

  private waiverQuestionsDisabled = true;

  private setWaiverQuestionsDisabledStatus(status: boolean, subQuestionId: string): void {
    const section = this.mockQuestionSections.findIndex(e => e.questions.findIndex(ee =>ee.questionId == subQuestionId)!= -1);
    const question = this.mockQuestionSections[section].questions.findIndex(e => e.questionId == subQuestionId);

    this.mockQuestionSections[section].questions[question].disabled = status;
    // set additional waiver flag if 7.2 is answered with yes
    if (section == 6 && question == 1){
      this.waiverQuestionsDisabled = status;
    }
  }

  public answerUpdated($event: [number, number, string, string]): void {
    const sectionIndex = $event[0];
    const questionIndex = $event[1];
    const answerValue = $event[2];
    const questionID = $event[3];
    const QuestionInfo = this.mockQuestionSections[sectionIndex].questions[questionIndex];

    this.allAnswers[sectionIndex].answers[questionIndex] = answerValue;

    // show additional questions based on response
    if (QuestionInfo.requiredForQuestions != null && answerValue != null) {
        QuestionInfo.requiredForQuestions.forEach((subQuestion) => {
            if (answerValue === subQuestion.subQuestionReqResp.toString()) {
              this.setWaiverQuestionsDisabledStatus(false, subQuestion.subQuestionId);
            } else {
              this.setWaiverQuestionsDisabledStatus(true, subQuestion.subQuestionId);
          }
        });
    }

    if (this.allAnswers[sectionIndex].answers.some((v) => v !== null)) {
      if (this.allAnswers[sectionIndex].answers.every((v) => v !== null)) {
        this.allAnswers[sectionIndex].status = 'completed';
      }
    }

    if (questionID != null && answerValue != null){
      const property = 'FormQ' + questionID;
      const response = this.complianceForm.get(`${property}.FormQResponses.0.UserResponse`);
      response.setValue(answerValue);

      //Set point Values
      const ResponsePointValue = this.complianceForm.get(`${property}.FormQResponses.0.ResponsePointValue`);
      const q = this.mockQuestionSections[sectionIndex].questions[questionIndex];
      let respValue = answerValue;
      if (q.standardQuestion == true){
        if (respValue != 'no' && respValue != 'yes' && respValue != null && respValue != undefined) {
          respValue = 'partial';
        }
        const points = q.pointValue.find((x) => x.questionAnswer === respValue);
        ResponsePointValue.setValue(points.pointValue);
      }
      else {
        if (respValue != '' && respValue != null && respValue != undefined){
          //Update to use some form of logic to get additional scores
          respValue = 'any';
          const points = q.pointValue.find((x) => x.questionAnswer === respValue);

          ResponsePointValue.setValue(points.pointValue);
        }
        else{
          ResponsePointValue.setValue(0);
        }
      }

      //Check if section complete
      let sectionComplete = true;
        this.mockQuestionSections[sectionIndex].questions.forEach((q) => {
          if(q.disabled == undefined || (q.disabled == false && q.requiredIfVisible == true)){
            const prop = 'FormQ' + q.questionId;
            const resp = this.complianceForm.get(`${prop}.FormQResponses.0.UserResponse`);
            if(resp.value == null){
              sectionComplete = false;
            }
          }
        });

      if (sectionComplete == true){
        this.allAnswers[sectionIndex].status = 'completed';
      } else {
        this.allAnswers[sectionIndex].status = 'started';
      }
    }

    this.getSectionsCompleted();
  }

  public fileUpdated($event: [number, number, any, string]): void {
    const sectionIndex = $event[0];
    const questionIndex = $event[1];
    const fileUpload = $event[2];
    const questionID = $event[3];
    const QuestionInfo = this.mockQuestionSections[sectionIndex].questions[questionIndex];

    const file = fileUpload;
    if (fileUpload) {
      const file = fileUpload;
      this.uploadFormComb.patchValue({ file: file });
      this._cd.markForCheck();
    } else {
      this.resetUploadForm();
    }
    this.uploadFormComb.get('file').markAsTouched();

    const filename = file.name;

    if (questionID != null && filename != null){
      const property = 'FormQ' + questionID;
      const response = this.complianceForm.get(`${property}.FormQResponses.0.UserResponse`);
      response.setValue(filename);
    }
    this.getSectionsCompleted();

  }

  private resetUploadForm(): void {
    this.uploadFormComb.reset({ file: null });
    this._cd.markForCheck();
  }

  public getSectionsCompleted(): void {
    this.sectionsCompleted = this.allAnswers.filter(
      (o) => o.status === 'completed'
    ).length;
    if (this.sectionsCompleted === this.allAnswers.length){
      this.allSectionsComplete = true;
    }
    else {
      this.allSectionsComplete = false;
    }
  }

  public clearFormClick(): void {
    this.clearForm.emit(true);
    this.participantsFormArray.clear();
    this.allAnswers = [
      {
        status: null,
        answers: [],
      },
      {
        status: null,
        answers: [],
      },
      {
        status: null,
        answers: [],
      },
      {
        status: null,
        answers: [],
      },
      {
        status: null,
        answers: [],
      },
      {
        status: null,
        answers: [],
      },
      {
        status: null,
        answers: [],
      },
    ];
    this.generateComplianceForm();
    this.answerUpdated([0, 0, null, null]);

    this.refreshFormData();
    this.toggleAddEditFormActive();
  }

  public generateComplianceForm(): void {
    const quarterOfTheYear = (date: Date) => {
      const month: number = date.getMonth() + 1;
      return Math.ceil(month / 3);
    };

    const today = new Date();
    const userID = this._currentUser?.dnn_id || null;
    const userEmail = this._currentUser?.email || null;
    const nowJson = today.toJSON();
    const nowQtr = quarterOfTheYear(today);

      this.complianceForm = this.formBuilder.group({
        FormID: [3],
        FormVersionID: [3],
        FormName: ['Compliance'],
        FormCategoryID: [1],
        Email: [userEmail], // set
        Tier3_ID: [null, Validators.required], // set
        Tier3_Name: [null, Validators.required], // set
        SpecialStatus: [null],
        RegReportOne: [null],
        RegReportTwo: [null],
        FormQuarter: [nowQtr],
        CEHRT: [2021],
        FormCreateDateTime: [nowJson],
        CreatedByUserID: [userID],
        ClosedDateTime: [null],
        LastUpdateUserID: [userID],
        LastUpdateDateTime: [nowJson],
        isComplete: [0],
        TotalPoints: [0],
        UserFormID: [null],
        UserID: [userID],
        SubmitPeriodID: [this.selectedFormSubPeriod.toString()],
        FormQ15: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[0].questions[0].questionId,
          QuestionText: [
            `${this.mockQuestionSections[0].questions[0].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[0].answers[0]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ16: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[0].questions[1].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[0].questions[1].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[0].answers[1]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ17: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[0].questions[2].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[0].questions[2].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[0].answers[2]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ18: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[0].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[0].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[0]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ19: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[1].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[1].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[1]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ20: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[2].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[2].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[2]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ21: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[3].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[3].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[3]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ22: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[4].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[4].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[4]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ23: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[5].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[5].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[5]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),

        FormQ24: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[6].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[6].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[6]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ79: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[1].questions[7].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[1].questions[7].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[1].answers[7]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ26: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[2].questions[0].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[2].questions[0].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[2].answers[0]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ27: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[2].questions[1].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[2].questions[1].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[2].answers[1]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ28: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[2].questions[2].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[2].questions[2].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[2].answers[2]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ73: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[2].questions[3].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[2].questions[3].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[2].answers[3]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ29: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[2].questions[4].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[2].questions[4].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[2].answers[4]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ30: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[3].questions[0].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[3].questions[0].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[3].answers[0]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ31: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[3].questions[1].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[3].questions[1].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[3].answers[1]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
       

        FormQ32: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[3].questions[2].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[3].questions[2].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[3].answers[2]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),

        FormQ34: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[0].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[0].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[0]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ35: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[1].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[1].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[1]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),

        FormQ36: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[2].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[2].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[2]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ37: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[3].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[3].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[3]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),

        FormQ81: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[4].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[4].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[4]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ38: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[5].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[5].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[5]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ84: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[6].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[6].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[6]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ39: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[7].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[7].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[7]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ85: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[8].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[8].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[8]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ40: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[9].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[9].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[9]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ86: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[4].questions[10].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[4].questions[10].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[4].answers[10]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ41: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[5].questions[0].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[5].questions[0].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[5].answers[0]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ42: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[5].questions[1].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[5].questions[1].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[5].answers[1]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ43: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[5].questions[2].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[5].questions[2].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[5].answers[2]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
        FormQ44: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[6].questions[0].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[6].questions[0].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[6].answers[0]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
      
        FormQ76: this.formBuilder.group({
          QuestionID: this.mockQuestionSections[6].questions[1].questionId,
          QuestionText: [
            `Question ${this.mockQuestionSections[6].questions[1].questionText}`,
          ],
          FormQCategoryID: [1],
          LastUpdateDateTime: [nowJson],
          LastUpdateUserID: [userID],
          FormQResponses: this.formBuilder.array([
            this.formBuilder.group({
              UserResponse: [this.allAnswers[6].answers[1]],
              ResponsePointValue: [null],
              FormResponseDataTypeID: [3],
              FormResponseTypeID: [7],
              ResponseDateTime: [nowJson],
              ResponseUserID: [userID],
              LastUpdateDateTime: [nowJson],
              LastUpdateUserID: [userID],
            }),
          ]),
        }),
      });

    this.tier3FormSub = this.tier3Form.valueChanges.subscribe(() => {
      this.updateSelectedParticipants();
    });

    this.allSubs = [
      this.tier3FormSub,
      this.authSub,
      this.userSub,
    ];
  }
  public onSubmit(isComplete: boolean): void {
    let validSubmit: boolean;
    const validDraft = !isComplete;

    validSubmit = isComplete && this.complianceForm.valid;


    for (const section in this.mockQuestionSections) {
      this.mockQuestionSections[section].questions.forEach((q) => {
        if(q.disabled == true){
          const prop = 'FormQ' + q.questionId;
          const resp = this.complianceForm.get(`${prop}.FormQResponses.0.UserResponse`);
          const respPoints = this.complianceForm.get(`${prop}.FormQResponses.0.ResponsePointValue`);
          const points = q.pointValue.find((x) => x.questionAnswer === SubmitComplianceComponent.disabled);
          if (points != undefined){
            respPoints.setValue(points);
          }
          else {
            respPoints.setValue(null);
          }
          resp.setValue(null);

        }
      });
    }


    if (validSubmit) {
      this.toastr.info('Form Submitting', 'Compliance Form');
      this.calcTotalPoints(true);
    }
    if (validDraft) {
      this.toastr.info('Draft Saving', 'Compliance Form');
      this.calcTotalPoints(false);
    }



    if (validSubmit || validDraft) {
      this.complianceForm.controls['isComplete'].setValue(isComplete ? 1 : 0);


      let formCopy = Object.assign({}, this.complianceForm.getRawValue());

      this.formSaving = true;
      const uploadFile = this.uploadFormComb.get('file').value;
      this.qualityService
        .save([uploadFile], formCopy)
        .pipe(finalize(() => (this.formSaving = false)))
        .subscribe(
          () => {
            if (validSubmit)
              this.toastr.success('Form Submitted', 'Compliance Form');
            if (validDraft) this.toastr.success('Draft Saved', 'Compliance Form');
            this.refreshFormData();
            this.resetForms();
            this.clearFormClick();
            this.participantsFormArray.clear();
          },
          (err) => {
            console.error(err);
            this.toastr.error('Form Failed To Save', 'Compliance Form');
          }
        );
    } else if (isComplete) {
      this.complianceForm.markAllAsTouched();
      this.uploadFormComb.markAllAsTouched();
    } else if (!isComplete) {
      this.uploadFormComb.markAllAsTouched();
    }

  }

  resetForms(): void {
    this.generateComplianceForm();

    this.resetUploadForm();

    this.updateForm();
  }

  newSubmission(): void {
    const submissionID = this.selectedFormSubPeriod.toString();

    this.qualityService
      .getListOfSpecificTier(null, SubmitFeatureConstants.ComplianceFormFeature, submissionID)
      .subscribe((data) => {
        this.setParticipants(data);
        this.toggleAddEditFormActive();
       });

  }
  formsReport(): void {
     this.router.navigate(['compliance/formreport'], { relativeTo: this.route.parent });
  }
  editForm(submission: FormSubmission): void {
    const submissionID = this.selectedFormSubPeriod.toString();
    const specialStatusIncluded = this.shwSpecialStatus? '1':'0';

    forkJoin([
      this.qualityService.getListOfSpecificTier(
        submission.UserFormID,
        SubmitFeatureConstants.ComplianceFormFeature,
        submissionID,
        specialStatusIncluded
      ),
      this.qualityService.getFormResponses(submission.UserFormID),
    ]).subscribe((data) => {
      const submission = data[1][0];
      const tier3Ids = submission.SelectedTier3s.split('; ').map((id) =>
        id
      );
      const tier3 = JSON.parse(data[0]);
      if (tier3[0].Name) {
        !submission.SelectedFacName
          ? (submission.SelectedFacName = tier3[0].Name)
          : null;
          this.complianceForm.get('Tier3_Name').markAsTouched({ onlySelf: true });
      }

      this.setParticipants(data[0], tier3Ids);
      this.loadFromSubmission(submission);
      this.toggleAddEditFormActive();

    });
  }
  private setParticipants(data: string, tierIds: string[] = []): void {
    if(data != ''){
      this.participantList = JSON.parse(data);
      this.participantsFormArray.clear();
      this.participantList?.forEach((participant) => {
        const isSet = tierIds.some((x) => x === participant.TierID);
        this.participantsFormArray.push(new UntypedFormControl(isSet));
      });
    }
  }

  public updateSelectedParticipants() {
    const selectedParticipants: tier3participants[] = this.tier3Form
      .get('participants')
      .value.map((selected, i) => (selected ? this.participantList[i] : null))
      .filter((p) => p !== null);
    // update fac_id
    const selectedParticipantTierIds = selectedParticipants
      .map((p) => p.TierID)
      .toString();
    this.complianceForm.patchValue(
      { Tier3_ID: selectedParticipantTierIds },
      { emitEvent: false }
    );
    this.complianceForm.get('Tier3_ID').markAsTouched({ onlySelf: true });
    // update names
    const selectedParticipantNames = selectedParticipants
      .map((p) => p.TierName)
      .toString();
    this.complianceForm.patchValue({ Tier3_Name: selectedParticipantNames });
    this.complianceForm.get('Tier3_Name').markAsTouched({ onlySelf: true });
  }

  loadFromSubmission(submission: FormResponses): void {
    this.complianceForm.get('UserFormID').setValue(submission.UserFormID);
    this.complianceForm.get('TotalPoints').setValue(submission.TotalPoints);

    submission.Q.forEach((question) => {
      for (const property in this.complianceForm.controls) {
        const questionControl = this.complianceForm.get(`${property}.QuestionID`);
        const questionId = questionControl?.value;

        if (questionControl != null && questionId == question.ID) {
          const response = this.complianceForm.get(
            `${property}.FormQResponses.0.UserResponse`
          );

          const points = this.complianceForm.get(
            `${property}.FormQResponses.0.ResponsePointValue`
          );

          if (response != null && points != null) {
            response.setValue(question.Response);
            points.setValue(Number(question.Points));
          }
        }
      }
    });

    this.updateForm();
  }

  public updateForm() {
    //todo
    this.calcTotalPoints(false);
  }
  public calcTotalPoints(finalizeScore: boolean) {

    if (finalizeScore == false){
      this.complianceForm.patchValue({ TotalPoints: null }, { emitEvent: false });
    }
    else{
    let totalPoints = 0;
    let totalQuestions = 0;

    for (let sectionIndex in this.mockQuestionSections) {

      this.mockQuestionSections[sectionIndex].questions.forEach((q) => {
        if(q.disabled == false || q.disabled == undefined){
          const prop = 'FormQ' + q.questionId;
          const resp = this.complianceForm.get(`${prop}.FormQResponses.0.UserResponse`);
          totalQuestions = totalQuestions + 1;

          let respValue = resp.value;

          if (q.standardQuestion == true){


            if (respValue != 'no' && respValue != 'yes' && respValue != null && respValue != undefined) {
              respValue = 'partial';
            }
            if (respValue != null && respValue != undefined){
              const points = q.pointValue.find((x) => x.questionAnswer === respValue);
              totalPoints = totalPoints + points.pointValue;
            }
          }
          else {
            if (resp.value != '' && resp.value != null && resp.value != undefined){
              //Update to use some form of logic to get additional scores
              respValue = 'any';
              const points = q.pointValue.find((x) => x.questionAnswer === respValue);
              totalPoints = totalPoints + points.pointValue;
            }
          }
        }
        else if (q.disabled == true){
          const points = q.pointValue.find((x) => x.questionAnswer === SubmitComplianceComponent.disabled);
          if (points != undefined){
            totalQuestions = totalQuestions + 1;
            totalPoints = totalPoints + points.pointValue;
          }

        }
      });
    }
    const totalPercent = ((totalPoints / totalQuestions) * 100).toFixed(0);

    this.complianceForm.patchValue({ TotalPoints: totalPercent }, { emitEvent: false });
  }

  }

  public toggleAddEditFormActive() {
    this.isAddEditFormActive = !this.isAddEditFormActive;
  }
  public updateSubmissionPeriodConfigs(submissionPeriodID) {
    const submitPeriod = this.caravanFormSubmissionPeriod[submissionPeriodID];
    for (let config in submitPeriod.configs) {
      if (submitPeriod.configs[config].configValue !== null) {
          this.copy[submitPeriod.configs[config].configName] = submitPeriod.configs[config].configValue.toString();
          if (submitPeriod.configs[config].configName == 'dueDateSoft') {
            this.dueDateSoft = new Date(submitPeriod.configs[config].configValue);
          }
      }
    }
    if (this.dueDateSoft == undefined){
      this.dueDateSoft = this.dueDate;
    }
  }

  public showRemediation(submission: FormSubmission): void {
    this.caravanFormRemediationInfo = [];
    this.qualityService
      .getFormRemediationInfo(submission.UserFormID)
      .subscribe((data) => {
        this.caravanFormRemediationInfo = data;

        if (this.caravanFormRemediationInfo[0].Questions){
          this.caravanFormRemediationInfo[0].Questions.forEach((question) => {
          const section = this.mockQuestionSections.findIndex(e => e.questions.findIndex(ee =>ee.questionId == question.ID.toString())!= -1);
          const questionID = this.mockQuestionSections[section].questions.find(e => e.questionId == question.ID.toString());
          question.questionID = questionID.number;
          });
      }

        this.showRemediationModal = true;
       });

    this.compScore = submission.TotalPoints;
    if (this.compScore < 100){
      this.displayRemediation = true;
    }
    else {
      this.displayRemediation = false;
    }

  }
  public hideRemediation(): void {
    this.showRemediationModal = false;
  }
  public print(): void {
    window.print();
  }



}
