/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Inject, Injectable } from '@angular/core';
import { PRESET_ITEMS, IPresetItemContract } from './utils';
import { CareFeatureConstants, CarePatientItem, PresetCategory } from '@shared/models/module-constants';
import { IPatientFilterPreset, IPatientFilterPresetGroup } from '@shared/models/patient-filters';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { InterfaceSourceTier } from '@care/components/facesheet-schedule/interface-source-tier';
import { AgGridLocalSettingsService, FilterSettings } from '@shared/services/ag-grid/ag-grid.service';
import { cloneDeep } from 'lodash';
import {
  AgGridTableFilterSetting,
  filterSettings
} from '@care/views/care-patients/care-patients-filters/filter-settings';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { InterfaceSourceTiersService } from '@care/components/facesheet-schedule/interface-source-tiers.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { Access } from '@shared/services/graphql/access.service';

@Injectable({
  providedIn: 'root'
})
export class PresetGroupService {
  private allPresetGroups: IPatientFilterPresetGroup[] = [];
  private user: wpapi.model.Professional = null;
  private userAccess: Access;
  newPreset: IPatientFilterPreset = null;

  private get allPresetItems() {
    return this.presetItems.map(r => r.getPresetItem());
  }

  private get generalPresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.General);
  }

  private get facesheetPresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.Facesheet);
  }

  private get cohortPresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.Cohorts);
  }

  private get clinicalProgramPresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.ClinicalProgram);
  }

  private get worklistsPresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.Worklists);
  }

  private get chronicConditionsPresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.ChronicConditions);
  }
  private get annualWellnessVisitsPresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.AnnualWellnessVisits);
  }
  private get postAcuteCarePresetItems() {
    return this.allPresetItems.filter(p => p.category === PresetCategory.PostAcuteCare);
  }

  get presetGroups() {
    return this.allPresetGroups;
  }

  constructor(
    private featureFlagService: FeatureFlagService,
    private authService: AuthService,
    private hierarchyTierService: HierarchyTierService,
    private agGridLocalSettingsService: AgGridLocalSettingsService,
    private interfaceSourceTiersService: InterfaceSourceTiersService,
    private userAccessService: UserAccessService,
    @Inject(PRESET_ITEMS) private presetItems: ReadonlyArray<IPresetItemContract>
  ) {
    this.userAccessService.currentAccess$.subscribe(async access => {
      if (access == null) {
        return;
      }
      this.userAccess = access;
    });

    this.getPresetGroup();
  }

  private async getPresetGroup() {
    this.user = await this.authService.getWellpepperUserInfo();

    const presetGroups = [
      {
        category: PresetCategory.CustomList,
        hideGroup: false,
        presets: []
      },
      {
        category: PresetCategory.AnnualWellnessVisits,
        hideGroup: false,
        featureFlagRequired: CareFeatureConstants.CareLandingPageFlag,
        presets: this.annualWellnessVisitsPresetItems
      },
      {
        category: PresetCategory.ChronicConditions,
        hideGroup: false,
        featureFlagRequired: CareFeatureConstants.CareLandingPageFlag,
        presets: this.chronicConditionsPresetItems
      },
      {
        category: PresetCategory.PostAcuteCare,
        hideGroup: false,
        featureFlagRequired: CareFeatureConstants.CareLandingPageFlag,
        presets: this.postAcuteCarePresetItems
      },
      {
        category: PresetCategory.General,
        hideGroup: false,
        presets: this.generalPresetItems
      },
      {
        category: PresetCategory.Facesheet,
        hideGroup: false,
        hideIfFeatureFlagIsActive: CareFeatureConstants.CareLandingPageFlag,
        presets: this.facesheetPresetItems
      },
      {
        category: PresetCategory.Cohorts,
        hideGroup: false,
        hideIfFeatureFlagIsActive: CareFeatureConstants.CareLandingPageFlag,
        presets: this.cohortPresetItems
      },
      {
        category: PresetCategory.Worklists,
        hideGroup: false,
        hideIfFeatureFlagIsActive: CareFeatureConstants.CareLandingPageFlag,
        presets: this.worklistsPresetItems
      },
    ];

    this.allPresetGroups = presetGroups.filter(pg => {
      return true;
    });

    this.allPresetGroups = presetGroups.filter(pg => {
      if (pg.featureFlagRequired) {
        return this.featureFlagService.hasFeatureFlag(pg.featureFlagRequired);
      }
      if (pg.hideIfFeatureFlagIsActive) {
        return !this.featureFlagService.hasFeatureFlag(pg.hideIfFeatureFlagIsActive);
      }
      return true;
    });
    await this.grantAccessToPresetGroups();
  }

  async grantAccessToPresetGroups() {
    let user = await this.authService.getWellpepperUserInfo();
    let group: IPatientFilterPresetGroup;
    let preset: IPatientFilterPreset;

    for (let g in this.allPresetGroups) {
      group = this.allPresetGroups[g];
      for (let p in group.presets) {
        preset = group.presets[p];
        if ('filterSettings' in preset && !preset.filterSettings) {
          if (this.featureFlagService.hasFeatureFlag(CareFeatureConstants.AgGridCustomFilteringFlag)) {
            const filterSetting = this.getSkeletonFilterSettingsByName(preset);
            const originName = preset.origin || preset.presetKey;
            preset.filterSettings = await this.getLocalstorageFilterSettings(
              preset.category,
              preset.presetKey,
              filterSetting,
              user?._id,
              originName
            );
          } else {
            preset.filterSettings = null;
          }
        }
        if (preset.componentId != null) {
          preset.hidePreset =
            !this.userAccessService.hasComponent(this.userAccess, preset.componentId) ||
            preset.hidePresetAdditionCondition;
        }
      }
      group.hideGroup = group.presets.every(preset => preset.hidePreset);
    }

    const facesheetGroup = this.allPresetGroups.find(x => x.category === PresetCategory.Facesheet);
    if (facesheetGroup && !facesheetGroup.hideGroup) {
      this.interfaceSourceTiersService.getAllFromCache().subscribe(interfaceTiers => {
        let hasInterface = false;
        interfaceTiers.forEach(interfaceTier => {
          hasInterface = hasInterface || this.hasInterface(interfaceTier);
        });

        facesheetGroup.hideGroup = !hasInterface;
      });
    }
  }

  getSkeletonFilterSettingsByName(preset: IPatientFilterPreset): FilterSettings {
    // after CC-4422 some of the filter names don't have spaces, so in some cases we need to remove spaces by using .replace(/\s/g,'')
    return cloneDeep(
      filterSettings?.[preset.presetKey] ||
        filterSettings?.[preset.name] ||
        filterSettings?.[preset.origin?.replace(/\s/g, '')]
    );
  }

  hasInterface(interfaceTier: InterfaceSourceTier): boolean {
    const currentTier = this.hierarchyTierService.currentTier$.value;

    if (currentTier == null || interfaceTier == null) {
      return false;
    }

    let hasInterface = false;

    switch (interfaceTier.configTier) {
      case 4:
        hasInterface =
          interfaceTier.ACO_ID === currentTier.selectedItem.tier1_ID &&
          interfaceTier.tier2_ID === currentTier.selectedItem.tier2_ID &&
          interfaceTier.tier3_ID === currentTier.selectedItem.tier3_ID &&
          interfaceTier.tier4_ID === currentTier.selectedItem.tier4_ID;
        break;
      case 3:
        hasInterface =
          interfaceTier.ACO_ID === currentTier.selectedItem.tier1_ID &&
          interfaceTier.tier2_ID === currentTier.selectedItem.tier2_ID &&
          interfaceTier.tier3_ID === currentTier.selectedItem.tier3_ID;
        break;
      case 2:
        hasInterface =
          interfaceTier.ACO_ID === currentTier.selectedItem.tier1_ID &&
          interfaceTier.tier2_ID === currentTier.selectedItem.tier2_ID;
        break;
      case 1:
        hasInterface = interfaceTier.ACO_ID === currentTier.selectedItem.tier1_ID;
        break;
    }

    return hasInterface;
  }

  async getLocalstorageFilterSettings(
    categoryName: PresetCategory,
    filterName: string,
    currentFilterSettings: FilterSettings,
    userId: string,
    originName: CarePatientItem | string
  ): Promise<FilterSettings> {
    const storedFilterSettings: FilterSettings =
      await this.agGridLocalSettingsService.getStoredFilterSettingsFromLocalStorage(
        categoryName,
        filterName,
        userId,
        originName
      );
    if (storedFilterSettings?.rows) {
      let currentFilterSettingsElement: AgGridTableFilterSetting;
      let storedFilterSettingsElement: AgGridTableFilterSetting;
      for (let r in storedFilterSettings.rows) {
        // here it pulls stored filter settings and merges it with filter settings skeleton
        for (let fs in currentFilterSettings?.rows) {
          currentFilterSettingsElement = currentFilterSettings.rows[fs];
          storedFilterSettingsElement = storedFilterSettings.rows[fs];
          if (currentFilterSettingsElement.title == storedFilterSettingsElement.title) {
            currentFilterSettingsElement.excluded = storedFilterSettingsElement.excluded;
            currentFilterSettingsElement.dateFrom = storedFilterSettingsElement.dateFrom;
            currentFilterSettingsElement.dateTo = storedFilterSettingsElement.dateTo;
            currentFilterSettingsElement.filter = storedFilterSettingsElement.filter;
            currentFilterSettingsElement.filterTo = storedFilterSettingsElement.filterTo;
            currentFilterSettingsElement.filterType = storedFilterSettingsElement.filterType;
            currentFilterSettingsElement.type = storedFilterSettingsElement.type;
          }
        }
      }
    }

    return currentFilterSettings;
  }

  getAllPresets() {
    if (this.allPresetGroups && this.allPresetGroups.length) {
      return this.allPresetGroups.reduce(
        (presets: IPatientFilterPreset[], group: IPatientFilterPresetGroup) => presets.concat(group.presets),
        []
      );
    }
    return this.allPresetItems;
  }

  /** Find a preset based on it's name */
  getPresetByName(carePatientItem: CarePatientItem | string): IPatientFilterPreset {
    return this.getAllPresets().find(r => r.name === carePatientItem);
  }

  /** Find a preset from query params - this is specialized logic to deal with legacy
   * URLs
   * @param filterPresetQueryParam - the query param tthat contains the preset name from the enum CarePatientItem
   * @param filterTitleQueryParam - support looking up legacy routes using the urlLabel property in the preset
   */
  getPresetByFilterQueryParams(filterPresetQueryParam: string, filterTitleQueryParam: string): IPatientFilterPreset {
    if (!filterPresetQueryParam) {  //CC-4850 Alert list is loaded dynamically so "name" field is assigned undefined at the start.
      return;
    }
    return (
      this.getPresetByMatchingNameOrTitle(filterPresetQueryParam, filterTitleQueryParam) ||
      this.getPresetByMatchingNameWithLabel(filterPresetQueryParam)
    );
  }

  /** CC-4507 match "filterPreset=awv-opportunities" with preset.urlLabel:"AWV opportunities" */
  getPresetByMatchingNameWithLabel(filterPresetQueryParam: string): IPatientFilterPreset {
    filterPresetQueryParam = filterPresetQueryParam?.toLowerCase()?.replace(/-/g, ' ');
    return this.getAllPresets().find(r => {
      const labelLowered = r.label?.toLowerCase();
      return labelLowered === filterPresetQueryParam; // Match filterTitle=AWV%20Opportunities
    });
  }

  /** Match either preset.name or preset.title with the filterPreset or filterTitle query params respectively */
  getPresetByMatchingNameOrTitle(filterPresetQueryParam: string, filterTitleQueryParam: string): IPatientFilterPreset {
    filterPresetQueryParam = filterPresetQueryParam?.toLowerCase();
    filterTitleQueryParam = filterTitleQueryParam?.toLowerCase();

    return this.getAllPresets().find(r => {
      const nameLowered = r.name?.toLowerCase();
      const urlLabelLowered = r.urlLabel?.toLowerCase();
      return (
        nameLowered === filterPresetQueryParam || // Match filterPreset=AWVOpportunities with name:"AWVOpportunities"
        nameLowered?.replace(/-/g, ' ') === filterPresetQueryParam || // ???
        (filterTitleQueryParam && urlLabelLowered === filterTitleQueryParam) // Match filterTitle=AWV%20Opportunities with urlLabel:"AWV Opportunities"
      );
    });
  }

  // REVIEW: need to find a better way to initialize the temp preset
  setTempPreset(preset: IPatientFilterPreset): void {
    // pulls temporary custom filter preset
    this.newPreset = preset;
  }
  getTempPreset(): IPatientFilterPreset {
    return this.newPreset;
  }
}
