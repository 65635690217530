import { Component, OnInit } from '@angular/core';
import { CoachTabs } from '@shared/components/coach-tabs/coach-tabs';
import { GridApi } from 'ag-grid-community';
export interface FilterOption {
    headerLabel: string;
    filterInstanceLabel: string;
    optionLabels: string[];
    checkedByDefault: string[];
    dataMapper: (data: any) => string;
    customMonthLabelPresent?: boolean;
    customMonthLabel?: string;
    filterByDateAndStatus?: boolean;
}
@Component({
  selector: 'coach-care-all-prescriptions',
  templateUrl: './care-all-prescriptions.component.html',
  host: {
    class: 'page-content coach-care-patients',
  },
  styleUrls: ['./care-all-prescriptions.component.scss']
})
export class CareAllPrescriptionsComponent implements OnInit {
  selectedList: CoachTabs;
  gridApi: GridApi;
  filterOptions: FilterOption[];
  constructor() { }

  ngOnInit(): void {
  }
  listSelected(list: CoachTabs): void {
    this.selectedList = list;
  }

  gridApiChanged(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }
  filterOptionsChanged(filterOptions: FilterOption[]): void {
    this.filterOptions = filterOptions;
  }
}
