/* tslint:disable */
/* eslint-disable */
export enum CallbackReasons {
  PatientUnreachable = 'PATIENT_UNREACHABLE',
  PatientTraveling = 'PATIENT_TRAVELING',
  PatientRequestedCallback = 'PATIENT_REQUESTED_CALLBACK',
  PatientRequiresTranslator = 'PATIENT_REQUIRES_TRANSLATOR',
  ProviderRequestedCallback = 'PROVIDER_REQUESTED_CALLBACK',
  PatientIsInASkilledNursingFacility = 'PATIENT_IS_IN_A_SKILLED_NURSING_FACILITY',
  PatientIsInAnInpatientFacility = 'PATIENT_IS_IN_AN_INPATIENT_FACILITY',
  Other = 'OTHER'
}
