<meta name="viewport" content="width=device-width, initial-scale=1">
<div class = "content-body"> 
<div class="scroll">   
    <div class="header">
        <div class="header-rectangle" style="background-color: rgb(255,140,25);">
            <h2 class="header-title">Events </h2>
            <p class="header-sub-title">
              {{app.CompanyName}} Health Educational Events is dedicated to bringing you one-of-a-kind learning and interactive opportunities through webinars, events, training, cohorts, and accredited education.  
            </p>            
        </div>
        <img class="img-fluid header-image" src="assets\img\illustrations\SH_PEP_Illustrations_Improve_Sum_Events_1700x1100_20221114.svg"/>
    </div>

  <br>
  <br>   
 
  
  <div class = "description">
    <p> Our Educational Events bring together {{app.CompanyName}} Health experts, industry thought leaders, and clients to share best practices and latest innovations for succeeding in population health and improving patient care. Our signature events include:  </p>
        <ul> 
        <li>  <p> Accountable Care Symposium  </p> </li>
        <li> <p> Quality Improvement Workshops </p> </li>
        <li> <p> PHN 201: Advanced Health Coaching </p> </li>        
        </ul>    
  <br>
  <p>      
    Additionally, clients have access to our unique and proprietary resources, On-Demand trainings, and Live events that are current with industry trends and client needs through {{app.CompanyName}} Learn. 
  </p>
  </div>

  <!--
  <table> 
    <tr> 
      <td class = 'item1'> 
        <h4> Most Recent Meeting <br> Date</h4>   
        <p> Here are the documents from the most recent meeting:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>
      
      <td class = 'item2'> 
        <h4> Important Content <br> </h4>   
        <p> Here are some useful links:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>

    </tr> 
  
  </table>
-->

</div>  
</div> 



