<button class="pullTab top--xs disable-rotate" (click)="togglePatientFilters()"
  [ngClass]="{'is-open': !isPatientFiltersOpen}" aria-label="Toggle filter panel">
  <coach-icon *ngIf = "isPatientFiltersOpen" iconName="chevrons-left"></coach-icon>
  <coach-icon *ngIf = "!isPatientFiltersOpen" iconName="chevrons-right"></coach-icon>
  
</button>
<div class="header">
  <div class="title u-display--flex u-flex-direction--row u-justify-content--spaceBetween padding--none u-width--full">
    <div class="title title-tooltip-wrapper">Patient Lists</div>
    <div class="tooltip-wrapper">
    </div>
  </div>
</div>
<div class="column u-fill u-position--relative">
  <div class="dynamicBody">       
    <ng-container *ngFor="let presetGroup of allPresetGroups">    
      <ng-container>
        <div class="subheader">{{presetGroup.category}}</div>
        <div class="list">
          <ng-container *ngFor="let preset of presetGroup.presets">
            <div class="list-item list-item--complex formatting--none" [ngClass]="{'is-active': isActivePreset(preset)}"
              (click)="selectPreset((selectedPreset && preset.label == selectedPreset.label) ? selectedPreset : preset)">
              <div class="list-item-main" [ngClass]="{'u-progress u-opacity--lighter': loadingStatus}">
                <div class="row">
                  <div class="field margin-right--smallest">{{preset.label}}</div>
                </div>
              </div>
              <div *ngIf="preset.filterComponent != null || preset.filterSettings" class="list-item-action u-cursor--pointer"
                [ngClass]="{'u-progress u-opacity--lighter': loadingStatus}">
                <coach-icon class="transition-fix" iconName="filter" iconColor="currentColor">
                </coach-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
