import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HccDashboardClinician } from '../hcc-dashboard-clinician';
import { GridFilterService } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { CLINICIANS_REPORT_GRID_KEY, PATIENT_SUMMARY_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { environment } from 'src/environments/environment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { HccDashboardService } from '@api/track/services';
import { HccDashboardFilterService } from '@track/track-clinical/hcc-dashboard/hcc-dashboard-filter.service';
import { combineLatest } from 'rxjs';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';

@Component({
  selector: 'coach-clinicians-report2',
  templateUrl: './clinicians-report2.component.html',
  host: {
    class: 'coach-clinicians-report2'
  },
  providers: [MaskPipe]
})
export class CliniciansReport2Component extends PersistentGridFilters implements OnInit, OnDestroy {

  tierNum: number;
  tierId: string;

  columnDefs = [
    {
      headerName: 'Practice Name', field: 'practiceName', tooltipField: 'practiceName', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the name of the practice the clinician is associated with'
    },
    {
      headerName: 'Clinician', field: 'clinicianName', tooltipField: 'clinicianName', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the name of the clinician with an attributed/assignable population. This column will also display the names of RHC and FQHCs with an attributed/assignable population',
      cellRendererFramework: RouterLinkRendererComponent,
      cellRendererParams: {
        valueOverride: (data: HccDashboardClinician): string => {
          return data.clinicianNPI == null ? data.practiceName : data.clinicianName;
        },
        linkClicked: (data: HccDashboardClinician) => {
          if (this.userAccessService.currentAccess$.value?.phi) {
            this.gridFilterService.resetFilters(PATIENT_SUMMARY_GRID_KEY);

            let clinician: string;
            let queryParams: Params;

            if (data.clinicianNPI != null) {
              clinician = data.clinicianNPI;
            } else {
              clinician = data.practiceName;
              queryParams = { isPractice: true };
            }

            this.router.navigate(['patients', clinician], { relativeTo: this.route.parent, queryParams: queryParams });
          }
        },
        isDisabled: (data: HccDashboardClinician) => {
          return !this.userAccessService.currentAccess$.value?.phi;
        }
      }
    },
    {
      headerName: 'Clinician NPI', field: 'clinicianNPI', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Clinician\'s National Provider Identifier',
      valueFormatter: params => {
        const clinician: HccDashboardClinician = params.data;

        if (clinician == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(clinician.clinicianNPI, 6);
        }
        else {
          return clinician.clinicianNPI;
        }
      }, keyCreator: params => {
        if (environment.maskSensitiveData) {
          return this.mask.transform(params.value, 6);
        }
        else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Clinician Speciality', field: 'clinicianSpecialty', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Clinician\'s specialty designation from claims'
    },
    {
      headerName: 'Patient Count', field: 'patientCount', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Count of patient attributed or attributed/assignable to the clinician/RHC/FQHC based on filter selected.'
    },
    {
      hide: true, // Hidden with ZBR-12923
      headerName: 'Chronic Conditions Open Gaps', field: 'hccGaps', sortable: true, filter: true, width: 275,
      sort: { direction: 'asc', priority: 0 }, resizable: true,
      headerTooltip: 'Count of open chronic condition gaps.'
    },
    {
      headerName: 'Annual Closure Rate', field: 'overallHCCClosureRate', sortable: true, filter: true,
      headerTooltip: 'Percentage of chronic condition gaps closed by claims in the current calendar year.',
      valueFormatter: (params: any) => {
        const clinician: HccDashboardClinician = params.data;
        return clinician == null ? null : Math.round(clinician.overallHCCClosureRate) + '%';
      }
    }
  ];

  rowData: any;

  
  constructor(private hccDashboardService: HccDashboardService, private route: ActivatedRoute,
    private router: Router, private gridFilterService: GridFilterService,
    protected filterService: GridFilterService, private mask: MaskPipe,
    private hccDashboardFilterService: HccDashboardFilterService,
    private userAccessService: UserAccessService, private hierarchyTierService: HierarchyTierService) {
    super(filterService, CLINICIANS_REPORT_GRID_KEY);
  }

  ngOnInit(): void {
    this.rowData =
      combineLatest([this.hierarchyTierService.currentTier$, this.hccDashboardFilterService.filterModel$]).pipe(
        takeUntil(this.killTrigger),
        filter(x => x[0] != null),
        switchMap(x => {
          const [tierInfo, filterValue] = x;
          const tierNum = filterValue?.isCinSelected ? -1 : tierInfo.tier;
          const tierId = filterValue?.isCinSelected ? filterValue.selectedCin?.id.toString() : tierInfo.selectedTierId;

          return this.hccDashboardService.hccDashboardCliniciansGet(
            {
              tierNum: tierNum,
              tierId: tierId,
              attributionStatus: filterValue == null || !filterValue.isMostAssignable ? ATTRIBUTED : MOST_ASSIGNABLE
            }
          );
        }));
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridColumnApi.getColumn('hccGaps').setSort('desc');
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    }
  }
}