import { Component, OnDestroy } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, RowSelectedEvent } from 'ag-grid-community';

/**
 * Custom AG Grid header renderer for checkbox selection
 * Uses Coach themed checkboxes and incorporates custom behavior
 * 
 * @param {number?} maxSelected: optional number indicating the maximum amount of selected rows
 * 
 * @example
 * public columnDefs: ColDef[] = [
 *     { headerComponentFramework: CheckboxHeaderRendererComponent, headerComponentParams: { maxSelected: 20 } }
 * ];
 */
@Component({
  selector: 'coach-checkbox-header-renderer',
  templateUrl: './checkbox-header-renderer.component.html',
  styleUrls: ['./checkbox-header-renderer.component.scss']
})
export class CheckboxHeaderRendererComponent implements IHeaderAngularComp, OnDestroy {
  public params: any;
  isSelected: boolean;

  private rowListener = (params: RowSelectedEvent) => {
    const totalNodes = this.params.api.getRenderedNodes();
    const selectedNodes = this.params.api.getSelectedNodes();

    this.isSelected = totalNodes.length === selectedNodes.length || (this.params.maxSelected && selectedNodes.length >= this.params.maxSelected);
  };

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  agInit(params: IHeaderParams): void {
    this.params = params;

    params?.api?.addEventListener('rowSelected', this.rowListener);
  }

  ngOnDestroy(): void {
    this.params?.api?.removeEventListener('rowSelected', this.rowListener);
  }

  onSelected(): void {
    if (this.isSelected) {
      const nodes = this.params.api.getRenderedNodes();
      if (this.params.maxSelected && nodes.length > this.params.maxSelected) {
        let selectedNodeCount: number = this.params.api.getSelectedNodes().length;
        nodes.forEach((node, i) => {
          if (selectedNodeCount < this.params.maxSelected && !node.isSelected()) {
            node.setSelected(true);
            selectedNodeCount++;
          }
        });
      } else {
        this.params.api.selectAllFiltered();
      }
    } else {
      this.params.api.deselectAll();
    }
  }

}
