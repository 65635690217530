import { CoachGridBase } from './coach-grid-base';
import { GridFilterModel } from './grid-filter-model';
import { GridFilterService } from './grid-filter.service';

export abstract class PersistentGridFilters extends CoachGridBase {
  constructor(
    protected filterService: GridFilterService,
    public gridKey: string
  ) {
    super();
  }

  protected skipUpdate: boolean;

  filterChanged(params: any): void {
    if (this.skipUpdate) {
      this.skipUpdate = false;
      return;
    }
    const model = params.api.getFilterModel();
    const existing = this.getFilterModel();

    if (existing != null) {
      existing.filterModel = model;
    } else {
      this.filterService.filterModels.push({
        key: this.gridKey,
        filterModel: model,
      });
    }
  }

  hasFilterModel(): boolean {
    return this.filterService.filterModels.some(
      (x) => x.key === this.gridKey && x.filterModel != null
    );
  }

  getFilterModel(): GridFilterModel {
    return this.filterService.filterModels.find((x) => x.key === this.gridKey);
  }

  getColumnState(): any {
    return this.getFilterModel().columnState;
  }

  saveColumnState(columnApi: any, api: any, url: string): void {
    const state = columnApi.getColumnState();
    const model = api.getFilterModel();
    const existing = this.getFilterModel();
    console.log(JSON.stringify(model));
    if (existing != null) {
      console.log('Existing not null');
      existing.columnState = state;
      existing.filterModel = model;
      existing.url = url;
    } else {
      console.log('Pushing column state');
      console.log(`State: ${JSON.stringify(state)}`);
      console.log(`Model: ${JSON.stringify(model)}`);
      this.filterService.filterModels.push({
        key: this.gridKey,
        filterModel: model,
        columnState: state,
        url: url,
      });
    }
  }

  restoreFilterModel(gridApi: any): void {
    const filterModel = this.getFilterModel();
    if (filterModel != null) {
      gridApi.setFilterModel(filterModel.filterModel);
      this.skipUpdate = true;
      gridApi.onFilterChanged();
    }
  }

  restoreColumnState(columnApi: any, gridApi): void {
    const savedColumnState = this.getColumnState();
    if (savedColumnState != null) {
      columnApi.applyColumnState({ state: savedColumnState });
      this.skipUpdate = true;
    }
    this.restoreFilterModel(gridApi);
  }
}
