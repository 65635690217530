import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { ITrackApiIHEFilesListVisitInfo, PatientService } from '@shared/services/patient/patient.service';
import moment from 'moment';
import { IconDefinition, faComment, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { fileStatus, PatientIHEFileDownloadInfo, PatientIHEFileInfo, PatientIHEFileType, PatientIHEFileTypeLabels, AssessmentFileTypes } from '@shared/models/patient-ihe-data';
import _, { forEach } from 'lodash';
import { PatientViewModel } from '@shared/models/patient';


//TODO - Change once we have an IHE data type
export interface iheHistoryElement {
    createdBy?: string,
    createdById?: number,
    createdAt?: string,
    proName: string,
    proAvatar?: string,
    action?: 'upload' | 'download' | 'note'
    noteText?: string,
    noteId?: number,
    faIcon?: IconDefinition,
    fileType?: PatientIHEFileType
}

export interface fileInfo {
    name: string,
    downloadStatus: fileStatus;
    downloadDetails?: {
        userName: string;
        downloadDate: Date;
    }
}
export interface note {
    createDateTime: string;
    createUserDisplayName?: string;
    createUserFullName: string;
    createUserName: string;
    createdByUserID: number;
    lastUpdateDateTime: string;
    lastUpdateUserID: number;
    lastUpdateUserFullName?: string;
    noteID: number;
    noteText: string;
    updateUserName: string;
}
export interface downloadInfo {
    downloadedAtUtc: string;
    downloadedByName: string;
    downloadedByUserId: number;
}

export interface IHEDownloadStatusObject {
    IHEAssessmentFilesLastUpdated: Date,
    IHEAssessmentFilesLastDownloaded: Date,
    IHECMRFileLastUpdated: Date,
    IHECMRFileLastDownloaded: Date,
    IHEDownloadDate: Date;
    notOnlyCMRFile: boolean;
}

type downloadStatus = 'New' | 'Partial' | 'Done';


@Component({
    selector: 'coach-patient-ihe-worklist',
    templateUrl: './patient-ihe-worklist.component.html',
    styleUrls: ['./patient-ihe-worklist.component.scss']
})
export class PatientIHEWorklistComponent implements OnInit {
    @Input() public patient: Patient;
    @Input() public tier: IHierarchyTier;
    @Input() public mobileScreenSize: boolean;
    @Input() sidebarMode?: boolean;
    @Input() public isTabOpen: boolean;
    @Output() patientUpdated: EventEmitter<null> = new EventEmitter()

    isFormOpen = false;
    historyDetails: iheHistoryElement[] = [];
    professionalsById: { [key: string]: wpapi.model.Professional } = {};
    inHomeVisitDate: Date;
    downloadStatus: downloadStatus = 'New';
    files: Array<PatientIHEFileInfo> = [];
    note: string = '';
    notes: note[] = [];
    user: wpapi.model.Professional;
    filesStatus: { [key: string]: string } = {};
    ITrackApiIHEFilesListVisitInfo: ITrackApiIHEFilesListVisitInfo = null;
    private get tierId(): string {
        return this?.tier?.selectedTierId;
    };
    private get tierNum(): number {
        return this?.tier?.tier;
    };

    downloading = false;
    patientIHEFileTypeLabels: { [key in PatientIHEFileType]: string } = PatientIHEFileTypeLabels;
    filesByTypeName: { [key in PatientIHEFileType]?: PatientIHEFileInfo } = {};
    areAllFilesAvailable: boolean = false;

    constructor(private PatientService: PatientService, private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) { }

    ngOnInit(): void {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.patient) {
            this.resetInfo();
            this.getIheFilesInfo();
            this.getNotes();
            this.getCurrentUserInfo();
        }
    }

    resetInfo(): void {
        this.files = [];
        this.filesByTypeName = {};
        this.ITrackApiIHEFilesListVisitInfo = null;
        this.note = '';
        this.inHomeVisitDate = null;
        this.historyDetails = [];
    }


    updateFileInPatientData(file): void {
        if (this.patient.trackPatient?.visitInfo) {
            for (let f in this.patient.trackPatient?.visitInfo?.fileInfo) {
                if (this.patient.trackPatient?.visitInfo?.fileInfo[f].fileId == file.fileId) {
                    this.patient.trackPatient.visitInfo.fileInfo[f] = file;
                    break;
                }
            }
        }
    }

    // FILE FUNCTIONS -----------------------------------------------------------------------------------------------------------------------------------------
    async downloadFile(file: PatientIHEFileInfo) {
        let tempFileDownloadStatus = _.clone(file.fileDownloadStatus);
        file.fileDownloadStatus = 'Downloading';
        try {
            await this.PatientService.downloadIHEFile(file.fileId, this.patient.chPatId, this.tierId, this.tierNum, `${file.fileType}_${this.patient.firstName + "_" + this.patient.lastName}`);
            file.fileDownloadStatus = 'Downloaded';
            this.updateFileDownloadHistoryInfo(file);
            this.updateFileInPatientData(file);
            this.patientUpdated.emit();
        } catch (error) {
            file.fileDownloadStatus = tempFileDownloadStatus;
            console.error(error);
        }
    }
    async downloadAllFiles() {
        if (this.downloading) {
            return;
        }
        this.downloading = true;
        let tempDownloadStatus: { [key: string]: fileStatus } = {}
        for (let file_idx in this.files) {
            tempDownloadStatus[file_idx] = _.clone(this.files[file_idx].fileDownloadStatus)
            this.files[file_idx].fileDownloadStatus = 'Downloading';
        };
        try {
            await this.PatientService.downloadIHEFile(this.files.map(file => file.fileId), this.patient.chPatId, this.tierId, this.tierNum, `IH_AWV_Files_${this.patient.firstName + "_" + this.patient.lastName}`)
            for (let file_idx in this.files) {
                this.files[file_idx].fileDownloadStatus = 'Downloaded';
                this.updateFileDownloadHistoryInfo(this.files[file_idx]);
                this.updateFileInPatientData(this.files[file_idx]);
            };
            this.downloading = false;
            this.patientUpdated.emit();

        } catch (error) {
            console.log("error", error)
            for (let file_idx in this.files) {
                this.files[file_idx].fileDownloadStatus = tempDownloadStatus[file_idx];
            };
            this.downloading = false;
            throw error;
        }
    }
    updateFileDownloadHistoryInfo(file: PatientIHEFileInfo): void {
        if (file.downloadInfo.current) {
            if (!file.downloadInfo.history) {
                file.downloadInfo.history = []
            }
            file.downloadInfo.history.push(file.downloadInfo.current)
        }
        file.downloadInfo.current = {
            downloadedAtUtc: moment.utc().toString(),
            downloadedByName: this.user.firstName + ' ' + this.user.lastName,
            downloadedByUserId: null
        };
    }
    // END OF FILE FUNCTIONS ----------------------------------------------------------------------------------------------------------------------------------


    // NOTE FUNCTIONS -----------------------------------------------------------------------------------------------------------------------------------------

    async getNotes() {
        let notesHistory: note[] = await this.PatientService.getNote(this.patient.chPatId, 3);
        this.notes = notesHistory;
    }
    async postNote() {
        let noteId = await this.PatientService.postNote(this.patient.chPatId, this.note, 3, null, null)
        this.notes.push({
            createDateTime: moment.utc().toString(),
            createUserFullName: this.user?.firstName + ' ' + this.user?.lastName,
            createUserName: this.user?.email,
            createdByUserID: null,
            lastUpdateDateTime: moment.utc().toString(),
            lastUpdateUserID: null,
            lastUpdateUserFullName: null,
            noteID: noteId,
            noteText: this.note,
            updateUserName: this.user?.email,
        })
        this.note = '';
    }
    // END OF NOTE FUNCTIONS ----------------------------------------------------------------------------------------------------------------------------------


    // FILES LIST ---------------------------------------------------------------------------------------------------------------------------------------------
    async getIheFilesInfo() {
        try {
            if (this.patient.trackPatient.fileInfo) {
                this.files = this.patient.trackPatient.fileInfo;
            } else {
                let ITrackApiIHEFilesListVisitInfos = await this.PatientService.getIHEDataForPatient(this.tierNum, this.tierId, this.patient.chPatId);
                this.ITrackApiIHEFilesListVisitInfo = ITrackApiIHEFilesListVisitInfos?.[0]
                this.files = this.ITrackApiIHEFilesListVisitInfo?.fileInfo;
            }
            if (!this.files) {
                this.files = [];
            }
            this.files.forEach(file => {
                this.filesByTypeName[file.fileType] = file;
                if (!file.fileDownloadStatus) {
                    file.fileDownloadStatus = file.downloadInfo?.current ? 'Downloaded' : 'New'
                }
            })

            this.sortHistoryListByDate();

            this.areAllFilesAvailable = Object.keys(this.filesByTypeName).length == Object.keys(this.patientIHEFileTypeLabels).length;

            this.getDownloadStatus();
            const visitDate = this.patient.trackPatient?.IHEAWVVisitDate || this.ITrackApiIHEFilesListVisitInfo?.visitDate;
            this.inHomeVisitDate = visitDate ? moment(visitDate).toDate() : null;
        } catch (error) {
            console.log(error);
        }
    }
    // END OF FILES LIST --------------------------------------------------------------------------------------------------------------------------------------


    // HISTORY LIST -------------------------------------------------------------------------------------------------------------------------------------------
    convertFilesHistoryElementIntoIHEHistoryElements(downloadInfo: { current?: PatientIHEFileDownloadInfo, history?: PatientIHEFileDownloadInfo[] }, fileType: PatientIHEFileType): iheHistoryElement[] {

        let result: iheHistoryElement[] = [];
        if (downloadInfo.current) {
            downloadInfo.current.downloadedAtUtc = moment.utc(downloadInfo.current.downloadedAtUtc).toString();
            result.push(this.convertFilesHistoryElementIntoIHEHistoryElement(downloadInfo.current, fileType))
        }
        if (downloadInfo.history?.length) {
            downloadInfo.history.forEach(info => {
                info.downloadedAtUtc = moment.utc(info.downloadedAtUtc).toString();
                result.push(this.convertFilesHistoryElementIntoIHEHistoryElement(info, fileType))
            })
        }
        return result;
    }
    convertFilesHistoryElementIntoIHEHistoryElement(downloadInfo: PatientIHEFileDownloadInfo, fileType: PatientIHEFileType): iheHistoryElement {
        return {
            createdBy: downloadInfo.downloadedByName,
            createdById: downloadInfo.downloadedByUserId,
            createdAt: moment(downloadInfo.downloadedAtUtc).local().format('YYYY-MM-DD HH:mm:ss'),
            proName: downloadInfo.downloadedByName,
            fileType: fileType,
            action: 'download',
            faIcon: faDownload
        };
    }
    convertNotesElementIntoIHEHistoryElement(note: note): iheHistoryElement {
        return {
            createdBy: note.createUserFullName?.trim() || note.createUserDisplayName,
            createdById: note.createdByUserID,
            createdAt: moment(note.createDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
            proName: note.createUserName,
            noteText: note.noteText,
            noteId: note.noteID,
            action: 'note',
            faIcon: faComment
        }
    }
    addElementIntoHistoryList(el: iheHistoryElement): void {
        this.historyDetails.push(el);
    };
    sortHistoryListByDate(): void {
        this.historyDetails.sort((a, b) => {
            if (moment(a.createdAt).isBefore(moment(b.createdAt))) return 1;
            else if (moment(a.createdAt).isAfter(moment(b.createdAt))) return -1;
            else return 0;
        });
    }

    getLiveUpdatedHistoryList(): iheHistoryElement[] {
        let result: iheHistoryElement[] = [];
        //notes
        this.notes.forEach(note => {
            note.createDateTime = moment.utc(note.createDateTime).toString();
            result.push(this.convertNotesElementIntoIHEHistoryElement(note));
        })

        // files

        this.files.forEach(file => {
            result = result.concat(this.convertFilesHistoryElementIntoIHEHistoryElements(file.downloadInfo, file.fileType))
        })
        if (result.length != this.historyDetails.length) {
            result.sort((a, b) => {
                if (moment(a.createdAt).isBefore(moment(b.createdAt))) return 1;
                else if (moment(a.createdAt).isAfter(moment(b.createdAt))) return -1;
                else return 0;
            });
            this.historyDetails = result;
        }
        return this.historyDetails;
    }
    // END OF HISTORY LIST ------------------------------------------------------------------------------------------------------------------------------------


    // USER FUNCTIONS -----------------------------------------------------------------------------------------------------------------------------------------
    async getCurrentUserInfo() {
        this.user = await this.authService.getWellpepperUserInfo()
    }
    getProfessionalsInfo(proIds: string[]): void {
        proIds?.forEach(id => {
            if (!this.professionalsById[id]) {
                this.PatientService.getProfessional(id).then(professional => {
                    this.professionalsById[id] = professional;
                })
            }
        })
    }

    proAvatar(proId: string): string {
        return this.professionalsById?.[proId]?.avatar?.url;
    }

    proName(proId: string): string {
        let fName = this.professionalsById?.[proId]?.firstName;
        if (fName) {
            fName += ' ';
        }
        return fName + this.professionalsById?.[proId]?.lastName;
    }

    getFormattedDownloadDetails(file: fileInfo): string {
        return file.downloadDetails.userName + ' ' + file.downloadDetails.downloadDate.toLocaleString();
    }

    getDownloadStatus(): downloadStatus {
        let fileInfoDatesResult = PatientViewModel.getLatestFileInfoDates(this.files);
        let IHEDownloadStatusObject: IHEDownloadStatusObject = PatientViewModel.assignLatestFileInfoDates(null, fileInfoDatesResult)
        if (IHEDownloadStatusObject.IHEAssessmentFilesLastUpdated && IHEDownloadStatusObject.IHECMRFileLastUpdated &&
            IHEDownloadStatusObject.IHEAssessmentFilesLastDownloaded && IHEDownloadStatusObject.IHECMRFileLastDownloaded) {
            return 'Done';
        } else if(IHEDownloadStatusObject.notOnlyCMRFile && IHEDownloadStatusObject.IHECMRFileLastUpdated && IHEDownloadStatusObject.IHECMRFileLastDownloaded){
            return ('Done')
        }else if (!IHEDownloadStatusObject.IHEAssessmentFilesLastUpdated && !IHEDownloadStatusObject.IHEAssessmentFilesLastDownloaded && IHEDownloadStatusObject.IHECMRFileLastUpdated && IHEDownloadStatusObject.IHECMRFileLastDownloaded) {
            return 'Partial';
        } else if (IHEDownloadStatusObject.IHEAssessmentFilesLastUpdated && IHEDownloadStatusObject.IHEAssessmentFilesLastDownloaded && !IHEDownloadStatusObject.IHECMRFileLastUpdated && !IHEDownloadStatusObject.IHECMRFileLastDownloaded) {
            return 'Done';
        } else if (IHEDownloadStatusObject.IHEDownloadDate) {
            return 'Partial';
        } else {
            return 'New';
        }
    }
    // END OF USER FUNCTIONS ----------------------------------------------------------------------------------------------------------------------------------

}

