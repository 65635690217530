<ng-container *ngIf="isAddEditFormActive; else complianceDashboard">
<div class="page-content u-position--relative">
  <div class="page-content-main">
    <div class="dynamicBody row u-background-color--white">
      <div class="queue u-position--relative animation--content--fadeIn"
        [ngClass]="isFormQueueOpen ? 'is-open border-right' : 'is-closed'">
        <div class="dynamicBody padding margins-bottom--large">
            <div class="label margin-bottom--smallest">
              Participant Selection
            </div>

            <form [formGroup]="tier3Form" class="clump padding--small" [ngClass]="{
              'is-invalid':
              complianceForm.get('Tier3_Name').invalid &&
                (complianceForm.get('Tier3_Name').dirty ||
                complianceForm.get('Tier3_Name').touched)
            }">
            <ng-container formArrayName="participants">
              <div *ngFor="
                  let practice of participantsFormArray.controls;
                  let i = index
                ">
                <input type="checkbox" id="practice_{{ participantList[i].TierID }}" [formControlName]="i" />
                <label for="practice_{{ participantList[i].TierID }}">
                  <span class="u-bold u-fill">{{
                    participantList[i].TierName
                    }}</span>
                </label>
              </div>

              <div *ngIf="participantsFormArray.length === 0">
                <div class="u-italic">No participants available</div>
              </div>
              <div *ngIf="
                  complianceForm.get('Tier3_Name').invalid &&
                  (complianceForm.get('Tier3_Name').dirty ||
                    complianceForm.get('Tier3_Name').touched)
                ">
                <div class="u-italic u-color--danger padding-top--small">
                  At least one participant is required
                </div>
              </div>
            </ng-container>
          </form>

        </div>
      </div>


      <div class="dynamicBody row">
        <div class="dynamicBody u-position--relative padding--larger padding-top--none u-overflow-x--auto">
          <ng-container *ngFor="let section of mockQuestionSections; let si = index">
            <h3>{{ section.name }}</h3>
            <coach-compliance-form-question *ngFor="let question of section.questions; let qi = index"
              [question]="question" [sectionIndex]="si" [clearForm]="clearForm" [questionIndex]="qi" [complianceForm]="complianceForm"
              (answerUpdated)="answerUpdated($event)" (fileUpdated)="fileUpdated($event)">
            </coach-compliance-form-question>
            <!-- <div>Section Complete: {{sectionComplete(i)}}</div> -->
          </ng-container>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="column u-justify-content--center u-flex--right">
        <div class="margin-bottom--smaller u-opacity--lighter u-italic padding-leftRight--small">
          <span class="u-bold">{{ sectionsCompleted }}</span> out of 7 sections
        </div>
        <div class="row stepperBar">
          <ng-container *ngFor="let section of mockQuestionSections; let i = index">
            <div class="stepperBar--step" [ngClass]="{
                'step--completed': allAnswers[i].status === 'completed',
                'step--started': allAnswers[i].status === 'started'
              }"></div>
          </ng-container>
        </div>
      </div>
      <button class="button margin-left--largest is-unstyled" (click)="clearFormClick()">
        Cancel
      </button>
      <button class="button" type="button" (click)="onSubmit(false)">
        Save as Draft
      </button>
      <button class="button is-primary margin-left--small" [disabled]="!allSectionsComplete || participantList.length ==0"
        (click)="onSubmit(true)">
        Submit
      </button>
    </div>
  </div>
</div>
</ng-container>

<ng-template #complianceDashboard>
  <div class="page-content">
    <div class="queue u-position--relative animation--content--fadeIn"
      [ngClass]="isQueueOpen ? 'is-open border-right' : 'is-closed'">
      <button class="pullTab top--xs" (click)="toggleQueueOpen()" [ngClass]="{ 'is-open': isQueueOpen }"
        aria-label="Toggle Practice Improvement List Panel">
        <coach-icon iconName="chevrons-right"></coach-icon>
      </button>
      <div class="header">
        <div class="titles t4">
          <div class="title">{{copy.overviewTitle}}</div>
          <div class="subtitle row--baseline">
            <span class="u-fill u-bold">{{copy.submissionPeriod}}</span>
            <span class="label margin-right--smaller">Due</span>
            <span>{{dueDateSoft | date:'MMM dd'}}</span>
          </div>
        </div>
      </div>
      <div class="dynamicBody padding">
        <h4 class="margin-top">Welcome!</h4>
        <p><span class="u-bolder">{{copy.requirements}}</span></p>
        <p>
          {{copy.instructionsPart1}}<span class="u-bolder">{{copy.submissionPeriod}}</span>{{copy.instructionsPart2}}
        </p>
        <div class="alert is-info margin-topBottom--larger padding--small">
          <span class="u-bolder">{{copy.noteTextPart1}}</span>{{copy.noteTextPart2}}
          <span class="u-bolder">{{copy.noteTextPart3}}</span>{{copy.noteTextPart4}}
          <span class="u-bolder">{{copy.noteTextPart5}}</span>{{copy.noteTextPart6}}
        </div>
      </div>
      <div class="border-top--5 padding u-font-size--smallest u-opacity--light">
        {{copy.disclaimer}}
      </div>
    </div>
    <div class="page-content-main">
      <div class="dynamicBody padding--larger padding-top--largest u-background-color--white">

        <div class="row--wrap row--baseline padding-top--largest">
          <h3
            class="margin-top--none margin-right--large margin-bottom--large bean u-background-color--view u-color--white u-preserve">
            Step {{stepAddEdit}}
          </h3>
          <div class="submit-stepHeader column">
            <h3 class="margin-top--none margin-bottom--small">
              Add/Edit Forms
            </h3>
            <p class="u-opacity--light">We keep track of all forms submitted or saved as draft. Come back here to review
              and update any submitted or saved forms prior to the submission deadline of <b>{{dueDateSoft | date:'MMMM dd, yyyy'}}</b>.
            </p>
          </div>
        </div>

        <div class="row margin-topBottom--larger padding-topBottom padding-right u-overflow-x--auto">
          <table class="table--tight u-width--full">
            <thead>
              <tr>
                <th class="border-right--none">
                  <h5 class="margin--none">Name</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Total Score</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Submitter</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Date/Time</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Status</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Edit</h5>
                </th>
                <th class="border-right--none" style="text-align:center">
                  <h5 class="margin--none">Remediation Needed</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="formSubmissions == null || formSubmissions.length === 0">
                <td colspan="6">
                  <span class="u-opacity--lighter u-italic u-center u-display--flex">No Submissions</span>
                </td>
              </tr>
              <tr *ngFor="let submission of formSubmissions">
                <td>
                  {{ submission.PrincipalParticipantName }}
                  <!-- <div *ngIf="submission.SpecialStatus != null" class="u-color--danger">
                    <b>Special Status</b>: {{ submission.SpecialStatus }}
                  </div> -->
                </td>
                <td>{{ submission.TotalPoints/100 | percent:"2.0-0"}}</td>
                <td>{{ submission.SubmitterEmail }}</td>
                <td>{{ submission.LastUpdateDateTime | date: "short" }}</td>
                <td>{{ submission.ISComplete ? "Complete" : "Pending" }}</td>
                <td>
                  <a [routerLink]="" (click)="editForm(submission)">Edit</a>
                </td>
                <td *ngIf="submission.ISComplete" style="text-align:center">
                  <a *ngIf="submission.TotalPoints == 100" [routerLink]="" (click)="showRemediation(submission)">No</a>
                  <a *ngIf="submission.TotalPoints != 100" [routerLink]="" (click)="showRemediation(submission)">Yes</a>
                </td>
                <td *ngIf="!submission.ISComplete">
                  N/A
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="footer">
        <div class="form-reportwrapper" *hasFeatureFlag="formsAdminFormFeatureFlag" >
          <button class="button is-primary" *hasFeature="formsAdminFormFeature"  (click)="formsReport()">Submission Status Report</button>
        </div>

        <button class="button is-primary" (click)="newSubmission()">+ Compliance Form</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-container *ngIf="showRemediationModal">
  <div class="overlay is-open">
    <div class="modal">
      <div class="header">
        <div class="titles">
          <div class="title u-display--flex u-justify-content--spaceBetween">
            <span>Compliance Form Score Summary & Remediation Steps </span>
          </div>
        </div>
      </div>
      <div class="padding">

        {{ caravanFormRemediationInfo[0].Message }}
      </div>

      <div class="dynamicBody padding" *ngIf="displayRemediation">
        <h5 class="margin--none">Suggested Remediation:</h5>
        <table class="table--tight u-width--full">
          <thead>
            <tr>
              <th class="border-right--none">
                <h6 class="margin--none">Question ID</h6>
              </th>
              <th class="border-right--none">
                <h6 class="margin--none">General Compliance Oversight</h6>
              </th>
              <th class="border-right--none">
                <h6 class="margin--none">Suggested remediation</h6>
              </th>

            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let question of caravanFormRemediationInfo[0].Questions">
              <td>{{ question.questionID }}</td>
              <td>{{ question.Text }}</td>
              <td>{{ question.RemediationText }}</td>

            </tr>
          </tbody>
        </table>
      </div>
      <div class="padding">
        Please note that you can resubmit the form to reflect any organizational changes that may impact your overall score and compliance status by  <span class="u-bolder">{{copy.remediationCloseDate}}</span>.
        Contact aco-compliance@signifyhealth.com or a member of your Signify Health regional team if you have any questions.
      </div>

      <div class="footer">
        <div class="form-reportwrapper"><button class="button is-primary"  (click)="print()">Print</button></div>
        <button class="button is-primary"  (click)="hideRemediation()">Close</button>
      </div>
    </div>
  </div>
</ng-container>
