import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import * as fileSaver from 'file-saver';
import { DocRepo } from '@shared/models/docRepo/list';
import { catchError } from 'rxjs/operators';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { DocumentService } from '@shared/services/doc-exchange/document.service';
import { MyTeamComponent } from '@views/improve/shared/doc-repo/components/my-team/my-team.component';
import { ClientContactsComponent } from './components/client-contacts/client-contacts.component';
import { ClinicianRosterComponent } from './components/clinician-roster/clinician-roster.component';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Router, ActivatedRoute } from '@angular/router';
import { DocRepoConstants } from '@shared/models/module-constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';

const CONTACTS_APPLICATION_FILES = ["215","217","218"]

@Component({
  selector: 'coach-doc-repo',
  templateUrl: './doc-repo.component.html',
  styleUrls: ['./doc-repo.component.scss'],
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'row u-fill u-overflow--hidden' },
})
export class DocRepoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isDocUploadEnabled: boolean;
  @Input() public tabDocRepoFolders: DocRepo[];
  @Input() public selectedTabName: string;

  public canInteract = false;
  public docURL: string = null;
  public error: Error;
  public fileClicked = false;
  public fileData: any = '';
  public fileParam: number;
  public folder: DocRepo;
  public folderParam: number;
  public isQueueOpen = true;
  public viewerControl: any;
  public currentTier$: Observable<IHierarchyTier>;
  public tier: IHierarchyTier;
  public selectedTierId: string;
  public viewingSessionId: string;
  public safeSrc: SafeResourceUrl;
  public unSafeSrc: string;
  public breadCrumbStack = [];
  public showNavBar;
  public currentSelectedFolderChildren: DocRepo[];

  public originalSharedButtonText = ' Share';

  public sharedButtonText = this.originalSharedButtonText;

  private queryParamSubscription: Subscription;
  private customComponent: ViewContainerRef;

  selectedFolder: DocRepo;

  @ViewChild('customComponent', { read: ViewContainerRef })
  set content(content: ViewContainerRef) {
    if (content != null) {
      this.customComponent = content;
      this.setCustomComponent();
    }
  }

  constructor(
    private documentService: DocumentService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private fileDownloadService: FileDownloadService,
    private hierarchyTierService: HierarchyTierService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toastrMessageService: ToastrMessageService
  ) {
    this.queryParamSubscription = route.queryParams.subscribe((params) => {
      const { folder, file } = params;
      this.folderParam = folder;
      this.fileParam = file;
      if (this.tabDocRepoFolders && folder) {
        this.navigateToFolderById(folder);
      }
      if(this.tabDocRepoFolders && CONTACTS_APPLICATION_FILES.includes(file)){
        const selectedFile = this.tabDocRepoFolders.find(
          (file) => file.EntryId == this.fileParam
        );
        if (selectedFile) {
          this.fileSelected(selectedFile, false);
        }
      }
      else if (this.tabDocRepoFolders && file) {
        const selectedFile = this.selectedFolder.Children.find(
          (file) => file.EntryId == this.fileParam
        );
        if (selectedFile) {
          this.fileSelected(selectedFile, false);
        }
      }
    });
  }

  ngOnInit(): void {
    this.currentTier$ = this.hierarchyTierService.currentTier$.asObservable();

    this.hierarchyTierService.currentTier$.subscribe((value) => {
      if (value != null) {
        this.tier = value;
        this.selectedTierId = value.selectedTierId;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabDocRepoFolders?.currentValue) {
      this.currentSelectedFolderChildren = this.tabDocRepoFolders;
      if (this.tabDocRepoFolders != null) {
        const masterDocRepo = new DocRepo();
        masterDocRepo.Children = this.tabDocRepoFolders;
        this.breadCrumbStack.push(masterDocRepo);
        this.currentSelectedFolderChildren = this.tabDocRepoFolders;
        if (this.folderParam) {
          this.navigateToFolderById(this.folderParam);
        }
        if (this.fileParam) {
          const selectedFile = this.selectedFolder.Children.find(
            (file) => file.EntryId == this.fileParam
          );
          if (selectedFile) {
            this.fileSelected(selectedFile);
          } else {
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                folder: this.folderParam,
              },
            });
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.queryParamSubscription.unsubscribe();
  }

  public toggleQueueOpen(): void {
    this.isQueueOpen = !this.isQueueOpen;
  }

  public updateFilesCompleted(event: any) {
    this.fileDownloadService.fetchDocRepoList(
      this.hierarchyTierService.currentTier$.value.selectedTierId
    );
  }

  public innerFileClicked(event: DocRepo): void {
    // CC-2941 - let's just navigate to the route rather than calling fileSelected
    // which gets called inside the ngOnChanges handler
    // this.fileSelected(event);
    if (!event.EntryId) {
      this.toastrMessageService.error(new Error("innerFileClicked without event.EntryId"));
      return;
    }
    this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          folder: this.folderParam,
          file: event.EntryId
      },
    });
  }

  public fileSelected(file: DocRepo, updateRouter = true): void {
    console.log(`*** doc-repo.component.fileSelected "${file.Title}"`);
    // console.log(new Error().stack);
    // console.log(file);

    // Could also use Path or BlobPath
    if (this.fileData?.EntryId == file.EntryId) {
      console.log(`*** docRepo.component.fileSelected - file hasn't changed: ignore this call`);
      return;
    }
    this.fileClicked = true;
    this.fileData = file;
    this.sharedButtonText = this.originalSharedButtonText;
    const fileExt = file.OriginalFileName?.slice(
      file.OriginalFileName.length - 4
    );
    if (updateRouter) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          folder: this.folderParam,
          file: file.EntryId,
        },
      });
    }

    if (file.EntryType === DocRepoConstants.PageEntryType) {
      this.setCustomComponent();
    } else if (
      (file.EntryType === DocRepoConstants.TextPlainEntryType ||
        file.EntryType === DocRepoConstants.TextHtmlEntryType ||
        file.EntryType === DocRepoConstants.URLEntryType) &&
      file.OriginalFileName.length > 4 &&
      fileExt == '.url'
    ) {
      file.EntryType = DocRepoConstants.URLEntryType;
      this.fileDownloadService
        .getSpecificFile(file.EntryId)
        .subscribe((data) => {
          this.fileDownloadService.downloadFile(data).subscribe((response) => {
            response.text().then((data) => {
              const textUrlStart = data.indexOf('URL=');
              const textUrlA = data.slice(textUrlStart + 4);
              let textUrl;
              if (textUrlA.indexOf('\n') >= 1) {
                textUrl = textUrlA.slice(0, textUrlA.indexOf('\n') - 1);
              } else {
                textUrl = textUrlA;
              }
              if (textUrl.length >= 3) {
                this.unSafeSrc = textUrl;
                this.safeSrc =
                  this.sanitizer.bypassSecurityTrustResourceUrl(textUrl);
              } else {
                console.warn('Non-functional URL:', textUrl);
              }
            });
          });
        });
    } else if (file.EntryType !== DocRepoConstants.YoutubeEntryType) {
      this.fileDownloadService
        .getSpecificFile(file.EntryId)
        .subscribe((data) => {
          console.log(`*** subscribed to fileDownloadService for "${data}"`)
          this.docURL = data;

          // Create a viewing session for the document we want to view, then update
          // this.viewingSessionId. Setting a value for this.viewingSessionId will
          // send the value (via data binding in the HTML template) to the child
          // prizmdoc-viewer-wrapper component, causing it to initialize.
          this.documentService
            .createViewingSessionForDocument(file.Title, data, fileExt)
            .pipe(
              catchError((err) => {
                this.error = err;
                return EMPTY;
              })
            )
            .subscribe((viewingSessionId: string) => {
              this.viewingSessionId = viewingSessionId;
            });
        });
    }
  }

  setCustomComponent(): void {
    if (this.customComponent != null) {
      this.customComponent.clear();
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          this.getCustomComponent()
        );
      this.customComponent.createComponent(componentFactory);
    }
  }

  getCustomComponent(): any {
    switch (this.fileData.EntryId) {
      case 215:
        return MyTeamComponent;
      case 217:
        return ClientContactsComponent;
      case 218:
        return ClinicianRosterComponent;
    }
    return null;
  }

  download(doc: string): void {
    this.fileDownloadService.downloadFile(doc).subscribe((response) => {
      const blob: any = new Blob([response], {
        type: 'text/json; charset=utf-8',
      });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, this.fileData.OriginalFileName);
    }),
      (error: string) => {
        console.error('Error downloading the file' + error);
      },
      () => {
        console.info('File downloaded successfully');
      };
  }

  // Call this method to store a reference to the viewerControl so we can use its API.
  setViewerControl(viewerControl: any): void {
    // Store a reference to the viewerControl.
    this.viewerControl = viewerControl;

    // Update our "canInteract" variable whenever the viewer is fully ready. For
    // the full list of PCCViewer.EventType values you can subscribe to, see
    // https://help.accusoft.com/PrizmDoc/v13.13/HTML/PCCViewer.html#.EventType
    this.viewerControl.on(
      (window as any).PCCViewer.EventType.ViewerReady,
      () => {
        this.canInteract = true;
      }
    );
  }

  // Calls the viewerControl API to navigate to the next page.
  nextPage(): void {
    this.viewerControl.changeToNextPage();
  }

  // Calls the viewerControl API to navigate to the previous page.
  previousPage(): void {
    this.viewerControl.changeToPrevPage();
  }

  onIframeClick(): void {
    window.open(this.unSafeSrc, '_blank');
  }

  sharedClicked(event): void {
    let copyText = window.location.href;
    this.sharedButtonText = 'Copied';
    // copyText.replace()
    const start = copyText.indexOf('/', 10);
    const end = copyText.indexOf('/improve', 10);
    const stringToBeSwapped = copyText.substring(start, end);
    copyText = copyText.replace(stringToBeSwapped, '/shared');
    console.log(`Copied Shareable link to document. ${copyText}`);
    navigator.clipboard.writeText(copyText);
  }

  public recursiveFindFolder(
    folderId: number,
    current: DocRepo[],
    stack: DocRepo[] = []
  ): DocRepo[] {
    for (let i = 0; i < current.length; i++) {
      if (current[i].EntryId == folderId) {
        return [...stack, current[i]];
      } else if (current[i].EntryType === 'Folder' && current[i].Children) {
        const result = this.recursiveFindFolder(folderId, current[i].Children, [
          ...stack,
          current[i],
        ]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  public navigateToFolderById(folderId: number): void {
    const stack = this.recursiveFindFolder(folderId, this.tabDocRepoFolders);
    if (stack) {
      this.breadCrumbStack.length = 1;
      stack.forEach((folder) => this.selectedFolderListener(folder));
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {},
      });
    }
  }

  public selectedFolderListener(event: DocRepo): void {
    this.selectedFolder = event;
    if (this.breadCrumbStack[this.breadCrumbStack.length - 1] !== event) {
      this.breadCrumbStack.push(event);
    }
    this.currentSelectedFolderChildren = event.Children;
    this.showNavBar = true;
  }

  public drillOutClicked(event: DocRepo): void {
    this.breadCrumbStack.pop();
    this.selectedFolder = this.breadCrumbStack[this.breadCrumbStack.length - 1];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        folder: this.selectedFolder.EntryId,
      },
    });
    this.currentSelectedFolderChildren = this.selectedFolder.Children;
    this.showNavBar = this.breadCrumbStack.length - 1 > 0;
  }
}
