<div class="
    queue
    border-right
    u-position--relative
    animation--content--fadeIn
    u-justify-content--spaceBetween
  " [ngClass]="isQueueOpen ? 'is-open' : 'is-closed'">
  <button class="pullTab top--xs" (click)="toggleQueueOpen()" [ngClass]="{ 'is-open': isQueueOpen }"
    aria-label="Toggle File List Panel">
    <coach-icon iconName="chevrons-right"></coach-icon>
  </button>
  <div class="header">
    <div class="titles">
      <div class="title">{{ selectedTabName ? selectedTabName : null }}</div>
    </div>
  </div>

  <!-- Search may be added to docRepo queue later. Hiding for now as it is not implemented
  <div class="border-bottom padding--small padding-topBottom--smallest">
    <div class="search">
      <input type="search" placeholder="Search...">
      <button>
        <svg class="icon">
          <use href="assets/icons/Magicons.svg#icon-search"/>
        </svg>
      </button>
    </div>
  </div> -->

  <div class="dynamicBody">
    <coach-doc-repo-folder-nav *ngIf="showNavBar" [folder]="selectedFolder"
      (drillOutClickEmitter)="drillOutClicked($event)">
    </coach-doc-repo-folder-nav>
    <coach-doc-repo-directory [docRepoData]="currentSelectedFolderChildren"
      (innerFileClickedEmitter)="innerFileClicked($event)" (selectedFolderEmitter)="selectedFolderListener($event)"
      class="doc-repo-directory-element-list" [showNavBar]="showNavBar">
    </coach-doc-repo-directory>
  </div>
  <!-- Keep this upload ouside of any deeper divs. -->
  <coach-doc-upload *ngIf="isDocUploadEnabled" [docRepoData]="tabDocRepoFolders" class="border-top--4 coach-doc-upload"
    (updateFileTrigger)="updateFilesCompleted($event)">
  </coach-doc-upload>
</div>
<!-- TODO: Pull out into its own component -->
<div class="column u-background-color--white u-fill border-left">
  <ng-container *ngIf="fileClicked; else noFileSelected">
    <div class="header padding-left--largest">
      <div class="titles t4">
        <div class="title">{{ fileData.Title }}</div>
        <div class="subtitle row margins-right--small">
          {{ fileData.LastModified | date: "short" }} - {{ fileData.Author }}
        </div>
      </div>

      <div title="Copy Link">      
        <button type="button" class= "button border--none margin-left" aria-label="Share Button" (click)="sharedClicked($event)">
         <img
           src="assets\viewer-assets\img\share_icon.png"
           width="25px"
           height="25px"
           alt="share-icon"
           style="margin-left: 0; cursor: pointer; padding-right: 5%;"                         
         />    
         <div>  
          <span style="vertical-align: 0px; color: black">{{sharedButtonText}}</span>      
       </div>                  
        </button>   
      </div>
       
  </div>
  
    <ng-container [ngSwitch]="fileData.EntryType">
      <div *ngSwitchCase="'youtube'" class="u-fill row--verticalCenter u-center">
        <app-video [videoId]="fileData.BlobPath"></app-video>
      </div>
      <div *ngSwitchCase="'URL'" class="dynamicBody padding">
        <a [href]="safeSrc" target="_blank">Click here to open in new tab if preview fails to load</a>
        <iframe appIframeTracker (iframeClick)="onIframeClick()" [src]="safeSrc" title="" width="100%"
          height="100%"></iframe>
      </div>
      <div *ngSwitchCase="'Page'" class="dynamicBody padding">
        <ng-template *ngIf="(currentTier$ | async)?.tier !== 1; else acoSelected" #customComponent>
        </ng-template>
        <ng-template #acoSelected>
          <div class="
              dynamicBody
              row--verticalCenter
              u-center u-italic
              u-opacity--lighter
              u-font-size--large
              u-thin
            ">
            Please select a community to view this page
          </div>
        </ng-template>
      </div>
      <div *ngSwitchDefault class="dynamicBody">
        <coach-prizmdoc-viewer-wrapper viewingSessionId="{{ viewingSessionId }}"
          (viewerControl)="setViewerControl($event)" [tier]="tier" [entryId]="fileData.EntryId"
          [tabName]="selectedTabName" style="width: 100%; height: 90%">
        </coach-prizmdoc-viewer-wrapper>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noFileSelected>
    <div class="
        dynamicBody 
        u-center             
        "    
     >
     <coach-contacts *ngIf="selectedTabName === 'Contacts' "> </coach-contacts> 
     <coach-aco-onboard *ngIf="selectedTabName === 'ACO Board' "> </coach-aco-onboard> 
     <coach-steering-committee *ngIf="selectedTabName === 'Steering Committee' "> </coach-steering-committee>
     <coach-practice-improvement *ngIf="selectedTabName === 'Practice Improvement' "> </coach-practice-improvement>  
     <coach-information-technology *ngIf="selectedTabName === 'Information Technology' "> </coach-information-technology> 
     <coach-events *ngIf="selectedTabName === 'Events' "> </coach-events>      
     
    </div>
  </ng-template>
</div>
