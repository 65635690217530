import { formatDate } from '@angular/common';
import {
  compareDate,
  compareNumber,
  compareString,
} from '@shared/components/grid/grid-functions';

import { ExceptionsCellRendererComponent } from '../exceptions-cell-renderer/exceptions-cell-renderer.component';
import { ColDef } from 'ag-grid-community';

export const COLUMN_DEFS: Array<ColDef> = [
  {
    headerName: ' ',
    field: 'ImageYes',
    autoHeight: true,
    minWidth: 30,
    maxWidth: 30,
    cellStyle: {textAlign: 'center', 'padding-top': '12px'},
    sortable: false,
    cellRendererFramework: ExceptionsCellRendererComponent,
    cellRendererParams: {
      activeColor: 'green',
    },
    valueGetter: (params) => {
      return 'check-circle';
    },
  },
  {
    headerName: ' ',
    field: 'ImageNo',
    autoHeight: true,
    minWidth: 40,
    maxWidth: 40,
    cellStyle: {textAlign: 'center', 'padding-top': '12px'},
    sortable: false,
    cellRendererFramework: ExceptionsCellRendererComponent,
    cellRendererParams: {
      activeColor: 'red',
    },
    valueGetter: (params) => {
      return 'x-circle';
    },
  },
  {
    headerName: 'RX NUMBER',
    field: 'rxNumber',
    maxWidth: 120,
    suppressAutoSize: true,
    sortable: true,
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      return params.data.rxNum;
    },
  },
  {
    headerName: 'FILLED',
    field: 'fillDate',
    maxWidth: 130,
    suppressAutoSize: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    cellRenderer: (data): string => {
      return data.data?.dispenseDate
        ? formatDate(
            new Date(data.data?.dispenseDate),
            'MM/dd/yy',
            'en_US'
          ).trim()
        : '';
    },
    valueGetter: (data: any) => {
      let result = formatDate(
        new Date(data.data?.dispenseDate),
        'yyyy/MM/dd',
        'en_US'
      ).trim();
      return result;
    },
    getQuickFilterText: (params) => {
      return params.value;
    },
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'FILL#',
    field: 'clPrescriptionFillNumber',
    maxWidth: 75,
    filter: 'agSetColumnFilter',
    resizable: true,
    hide: false,
    sortable: true,
    cellRenderer: (data: any): string => {
      return data.data?.rxFillNum;
    },
    valueGetter: (params) => {
      return parseInt(params.data?.rxFillNum);
    },
  },
  {
    headerName: 'NAME',
    field: 'patientName',
    minWidth: 240,
    comparator: compareString,
    resizable: true,
    filter: 'agSetColumnFilter',
    sortable: true,
    cellRenderer: (data: any): string => {
      return `${data.data?.patientFirstName.trim() ?? ''} ${data.data?.patientLastName.trim() ?? ''}`;
    },
    valueGetter: (data) => {
      return `${data.data?.patientFirstName.trim() ?? ''} ${data.data?.patientLastName.trim() ?? ''}`;
    },
  },
  {
    headerName: 'DOB',
    field: 'dob',
    maxWidth: 120,
    suppressAutoSize: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    sortable: true,
    cellRenderer: (data: any): string => {
      return formatDate(data.data?.patientDob,
        'MM/dd/yy',
        'en_US');
    },
    valueGetter: (data) => {
      return formatDate(data.data?.patientDob,
        'yyyy/MM/dd',
        'en_US');
    },
  },
  {
    headerName: 'NDC',
    field: 'ndc',
    maxWidth: 120,
    suppressAutoSize: true,
    comparator: compareNumber,
    filter: 'agSetColumnFilter',
    sortable: true,
    cellRenderer: (data: any): string => {
      return data.data?.medNdc;
    },
    valueGetter: (data: any): number => {
      return parseInt(data.data?.medNdc);
    },
  },

  {
    headerName: 'PRESCRIPTION',
    field: 'medDesc',
    resizable: true,
    sortable: true,
    comparator: compareString,
    filter: 'agSetColumnFilter',
    minWidth: 320,
    cellRenderer: (data): string => {
      return data.data?.medDesc;
    },
  },
  {
    headerName: 'QTY',
    field: 'medQty',
    resizable: true,
    sortable: true,
    comparator: compareNumber,
    filter: 'agSetColumnFilter',
  },
];
