import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Patient} from '@shared/models/patient';
import {OutcomeType} from '@api/care-orch/models/outcome-type';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {PracticeListResult} from '@api/track/models/practice-list-result';
import {ToastrMessageService} from '@shared/services/toastr-message/toastr-message.service';
import {PatientAttendedOutcome} from '@api/care-orch/models/patient-attended-outcome';
import {PatientAttendedProperties} from '@api/care-orch/models/patient-attended-properties';
import {PropertiesType} from '@api/care-orch/models/properties-type';
import {PatientDidNotAttendOutcome} from '@api/care-orch/models/patient-did-not-attend-outcome';
import {PatientDidNotAttendProperties} from '@api/care-orch/models/patient-did-not-attend-properties';
import {Outcome} from '@api/care-orch/models/outcome';
import {ActivityType} from '@api/care-orch/models/activity-type';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import { ScheduledOutcome } from '@api/care-orch/models';
import { PracticeService } from '@shared/services/hierarchy/practice.service';
import { OnChanges } from '@angular/core';
import { NextAwvDateValidationService } from '@shared/services/form-validation/awv/next-awv-date-validation.service';

export class Comment {
  createdDateTime: string;
  createdUserDisplayName: string;
  commentText: string;
  constructor(createdDateTime, createdUserDisplayName, commentText) {
    this.createdDateTime = createdDateTime;
    this.createdUserDisplayName = createdUserDisplayName;
    this.commentText = commentText;
  }
}

export enum AttendOutcome{
  PATIENT_ATTENDED_AWV,
  PATIENT_DID_NOT_ATTEND_AWV
}

const DefaultOutcome = AttendOutcome.PATIENT_ATTENDED_AWV;

@Component({
  selector: 'coach-patient-step-completed-form',
  templateUrl: './patient-step-completed-form.component.html',
  styleUrls: ['./patient-step-completed-form.component.scss']
})
export class PatientStepCompletedFormComponent implements OnChanges {
  @Input() public patient: Patient;
  @Input() public editModeActive = false;
  @Input() public outcome: Outcome;
  @Input() public scheduledOutcome: ScheduledOutcome;
  @Output() outcomeSaved: EventEmitter<Outcome> = new EventEmitter();
  @Output() deleteActivity: EventEmitter<ActivityType> = new EventEmitter();

  today: string = WorkflowDateTimeUtil.todayDateOnlyString();
  minNextAwvDate: string = WorkflowDateTimeUtil.minNextAwvDate();
  maxNextAwvDate: string = WorkflowDateTimeUtil.maxNextAwvDate();
  comment = '';
  nextAwvDate = '';
  practices: PracticeListResult[];
  allFieldsEmpty = true;
  requiredFieldsSatisfied = true;
  commentCharacterlimit = CareOrchestrationConstants.commentBoxCharacterLimit;
  @ViewChild('extprovchkbx') checkbox: ElementRef;
  nextAwvDateChecked = false;
  AttendOutcome = AttendOutcome;
  isReadOnly = false;
  attendedAwv = false;
  selectedOutcome = DefaultOutcome;
  showRemoveDialog = false;
  scheduledDate = '';
  scheduledPracticeId = '';
  attendedOutcome: PatientAttendedOutcome;

  constructor(private practiceService: PracticeService, private toastrMessageService: ToastrMessageService,
              private nextAwvDateValidationService: NextAwvDateValidationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.patient) {
      this.clearFields();
      this.loadPracticesDropdown();
    }
    if (this.scheduledOutcome) {
      this.scheduledDate = this.scheduledOutcome.properties.date;
    }
    if (this.scheduledDate) {
      this.minNextAwvDate = WorkflowDateTimeUtil.minNextAwvDate(this.scheduledDate);
      this.maxNextAwvDate = WorkflowDateTimeUtil.maxNextAwvDate(this.scheduledDate);

      if (this.outcome){
        this.prePopWithCompletedOutcome();
        this.requiredFieldsSatisfied = false; // Flag set to false which disables "save" button on opening edit view
      } else {
        // logic for enabling/disabling completed view when scheduled date changed accordingly
        if (this.isScheduledDateGreaterThanToday()) {
          this.isReadOnly = true;
          this.requiredFieldsSatisfied = false;
        } else{
          this.isReadOnly = false;
          this.requiredFieldsSatisfied = true;
        }

        this.allFieldsEmpty = true;
      }
    }
  }

  loadPracticesDropdown(): void {
    this.practiceService.getPractices(this.patient).then(practices => {
      this.practices = practices;

      if (this.scheduledOutcome && this.scheduledOutcome.properties.practiceId) {
        this.scheduledPracticeId = this.scheduledOutcome.properties.practiceId;
      } else {
        this.scheduledPracticeId = '';
      }
    });
  }

  copyComment(commentBox): void{
    commentBox.select();
    document.execCommand('copy');
    commentBox.setSelectionRange(0, 0);
  }

  fieldChanged(shouldClearFields): void {
    // clearing of all fields only if we switch between radio buttons
    if (shouldClearFields){
      this.clearFields();
    }
    this.checkAnyFieldHasValue(shouldClearFields);
    this.checkRequiredFieldsSatisfied(shouldClearFields);
  }


  private prePopWithCompletedOutcome(): void {
    switch (this.outcome.type) {
      case OutcomeType.PatientAttendedOutcome:
        this.attendedOutcome = this.outcome as PatientAttendedOutcome;
        this.prePopPatAttendedOutcome(this.attendedOutcome);
        this.attendedAwv = true;
        break;
      case OutcomeType.PatientDidNotAttendOutcome:
        this.prePopPatDidNotAttendOutcome();
        break;
      default:
        this.toastrMessageService.error(new Error('The incoming outcome for the reminder activity is not recognized!'));
    }
  }

  private prePopPatAttendedOutcome(outcome: PatientAttendedOutcome): void{
    this.selectedOutcome = AttendOutcome.PATIENT_ATTENDED_AWV;
    this.nextAwvDate = outcome.properties.nextAwvDate ?  WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(outcome.properties.nextAwvDate) : '';
    if (this.nextAwvDate) {
      this.nextAwvDateChecked = true;
    } else {
      this.nextAwvDateChecked = false;
    }
  }

  private prePopPatDidNotAttendOutcome(): void{
    this.selectedOutcome = AttendOutcome.PATIENT_DID_NOT_ATTEND_AWV;
  }
  private checkAnyFieldHasValue(shouldClearFields: boolean): void {
    if (shouldClearFields){
      this.allFieldsEmpty = false;
      return;
    }
    else {
      if (this.comment && this.nextAwvDate !== this.attendedOutcome.properties.nextAwvDate ?
        WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.attendedOutcome.properties.nextAwvDate) : '') {
        this.allFieldsEmpty = true;
        return;
      }
    }

    this.allFieldsEmpty = false;
  }

  isNextAwvDateChangedAndHasValidValue(): boolean{
    return this.nextAwvDateValidationService.isNextAwvDateChangedAndHasValidValue(this.nextAwvDateChecked, this.nextAwvDate,
      this.minNextAwvDate, this.maxNextAwvDate, this.outcome);
  }

  private checkRequiredFieldsSatisfied(shouldClearFields: boolean): void {
    if (shouldClearFields){
      this.requiredFieldsSatisfied = true;
      return;
    }
    if (this.nextAwvDateValidationService.isNextAwvDateChangedAndHasValidValue(this.nextAwvDateChecked, this.nextAwvDate,
      this.minNextAwvDate, this.maxNextAwvDate, this.outcome)) {
      this.requiredFieldsSatisfied = true;
      return;
    }
    this.requiredFieldsSatisfied = false;
    return;
  }

  clearFields(): void {
    if (!this.editModeActive) {
      this.comment = '';
      this.nextAwvDate = '';
      this.nextAwvDateChecked = false;
    }
    else{
      this.resetFields();
    }
  }

  private resetFields(): void{
    this.allFieldsEmpty = true;
    if (null !== this.outcome) {
      this.requiredFieldsSatisfied = false;
      this.prePopPatAttendedOutcome(this.outcome as PatientAttendedOutcome);
    }
    else{
      this.requiredFieldsSatisfied = true;
      this.nextAwvDate = '';
      this.nextAwvDateChecked = false;
      this.selectedOutcome = AttendOutcome.PATIENT_ATTENDED_AWV;
    }
    this.comment = '';
  }

  isScheduledDateGreaterThanToday(): boolean{
    return WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.scheduledDate) > this.today;
  }

  private buildPatientAttendedAWVOutcome(): PatientAttendedOutcome{
    return{
      type: OutcomeType.PatientAttendedOutcome,
      properties: {
        type: PropertiesType.PatientAttendedProperties,
        nextAwvDate:  this.nextAwvDate ? WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.nextAwvDate) : null,
        completedDate:  WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.scheduledDate),
        comment: this.comment ? this.comment : null
      }as PatientAttendedProperties
    };

  }
  private buildPatientDidNotAttendOutcome(): PatientDidNotAttendOutcome{
    return{
      type: OutcomeType.PatientDidNotAttendOutcome,
      properties: {
        type: PropertiesType.PatientDidNotAttendProperties,
        comment: this.comment ? this.comment : null
      }as PatientDidNotAttendProperties,
    };
  }
  save(): void {
    let outcome;
    switch (this.selectedOutcome){
      case AttendOutcome.PATIENT_ATTENDED_AWV:
        outcome = this.buildPatientAttendedAWVOutcome();
        break;
      case AttendOutcome.PATIENT_DID_NOT_ATTEND_AWV:
        outcome = this.buildPatientDidNotAttendOutcome();
        break;
      default:
        this.toastrMessageService.error(new Error('The selected outcome for the completed/attended activity is not recognized!'));
        return;
    }
    if (outcome != null) {
      this.outcomeSaved.emit(outcome);
      this.clearFields();
    }
  }

  onDialogButtonClick(buttonValue: string): void {
    switch (buttonValue){
      case 'cancel':
        this.showRemoveDialog = !this.showRemoveDialog;
        break;
      case 'delete':
          this.showRemoveDialog = false;
          this.deleteActivity.emit(ActivityType.AttendedActivity);
    }
  }

  showDialog(): void {
    this.showRemoveDialog = true;
  }

  stringifyForm(): string {
    let value = '';

    if (!this.scheduledDate) {
      return '';
    }

    switch (this.selectedOutcome) {
      case AttendOutcome.PATIENT_ATTENDED_AWV:
        value = 'AWV completed on ' + WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat(this.scheduledDate);
        break;
      case AttendOutcome.PATIENT_DID_NOT_ATTEND_AWV:
        value = 'AWV not attended on ' + WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat(this.scheduledDate);
        break;
    }

    if (this.scheduledOutcome) {
      if (this.scheduledOutcome.properties.providerName) {
        value += ' with ' + this.scheduledOutcome.properties.providerName;
      }

      if (this.scheduledPracticeId) {
        value += ' at ' + this.practices.find(p => p.tier4Id === this.scheduledPracticeId).tier4Name;
      }
    }

    if (this.nextAwvDate) {
      value += '. Next AWV date scheduled for ' + WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat(this.nextAwvDate);
    }

    if (this.comment) {
      value += '. ' + this.comment;
    }

    return value;
  }

  toggleNextAwvDateInput(): void{
    this.nextAwvDateChecked = !this.nextAwvDateChecked;
    if (this.nextAwvDateChecked === false) {
        this.nextAwvDate = '';
    } else {
      if (this.outcome && (this.outcome as PatientAttendedOutcome).properties.nextAwvDate) {
        this.nextAwvDate =
          WorkflowDateTimeUtil.dateTimeStringToDateOnlyString((this.outcome as PatientAttendedOutcome).properties.nextAwvDate);
      }
    }
    this.fieldChanged(false);
  }
}


