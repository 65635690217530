import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-redirect',
  templateUrl: './shared-redirect.component.html',
  styleUrls: ['./shared-redirect.component.scss'],
})
export class SharedRedirectComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
