<div class="dynamicBody">
  <div class="widgets">
    <ng-container *hasFeature="performanceDashboardFeature">
      <div class="widget-column column" *hasFeatureFlag="performanceDashboardFeatureFlag">
        <coach-track-performance-widget [navigateToItem]="goToItems"></coach-track-performance-widget>
      </div>
    </ng-container>
    <div class="widget-column column" *hasFeature="trackFinancialFeature">
      <div class="widget u-fill" data-cy="financialUtilization_track_summary_widget">
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">Financial + Utilization</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Track_Sum_FU_1700x1100_20221114.svg" />
          <p class="u-italic u-opacity--light margin-top--smaller">
            These tools will help you find the financial opportunities that are
            revealed in your claims.
          </p>
          <ul class="margin-top--large margins-bottom--small">
            <ng-container *ngFor="let item of menuItems$ | async">
              <li *ngIf="item.logComponentID === financialFeature">
                <a [routerLink]="" (click)="loadReport(item)">{{
                  item.menuName
                  }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <!-- Hidden per COM-5012 -->
      <!-- <div class="widget">
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">Return on Investment</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <h3 class="margin--none">Return on Investment</h3>
          <p class="u-italic u-opacity--light margin-top--smaller">Nobody wins unless everybody wins. Let us show you how Caravan Health pays for itself in the long run!</p>
          <ul class="margin-top--large margins-bottom--small">
            <li><a href="#">Total Return on Investment</a></li>
          </ul>
        </div>
      </div> -->
    </div>
    <div class="widget-column column" *hasFeature="trackClinicalFeature">
      <!-- Hidden per COM-5012 -->
      <!-- <div class="widget">
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">Favorites</div>
          </div>
        </div>
        <div class="dynamicBody">
          <div class="list">
            <div class="list-item">
              <div class="row">
                <div class="field">HCC Dashboard</div>
                <div class="field">06/12/20</div>
              </div>
            </div>
            <div class="list-item">
              <div class="row">
                <div class="field">ROI Projections</div>
                <div class="field">06/12/20</div>
              </div>
            </div>
            <div class="list-item">
              <div class="row">
                <div class="field">Time Tracking</div>
                <div class="field">05/31/20</div>
              </div>
            </div>
            <div class="list-item">
              <div class="row">
                <div class="field">Network Utilization</div>
                <div class="field">04/17/20</div>
              </div>
            </div>
            <div class="list-item">
              <div class="row">
                <div class="field">Quality Form Summary</div>
                <div class="field">03/15/20</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="widget u-fill" data-cy="clinical_track_summary_widget">
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">Clinical</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Track_Sum_Clin_1700x1100_20221114.svg" />
          <p class="u-italic u-opacity--light margin-top--smaller">
            Track your progress toward your clinical goals. Use These tools to
            identify and close these process gaps!
          </p>
          <ul class="margin-top--large margins-bottom--small">
            <li *hasComponent="trackHccComponent">
              <a [routerLink]="['../clinical/dashboard']">Chronic Conditions Dashboard</a>
            </li>
            <!-- Hidden per COM-5012 -->
            <!-- <li><a href="#">Annual Wellness Visits</a></li> -->
            <li *hasModule="careModule">
              <a [routerLink]="['../clinical/trackTimeTracking']">Time Tracking</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="widget-column column" *hasFeature="trackQualityFeature">
      <div class="widget u-fill" data-cy="qualityInterop_track_summary_widget">
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">{{trackQualityFeatureName}}</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Track_Sum_QI_1700x1100_20221114.svg" />
          <p class="u-italic u-opacity--light margin-top--smaller">
            Quality is job #1. Use these tools to stay on top of your processes
            to ensure you are getting top scores.
          </p>
          <ul class="margin-top--large margins-bottom--small">
            <ng-container *hasFeatureFlag="qualityDashboardFeatureFlag">
              <li *hasComponent="qualityDashboardComponent">
                <a [routerLink]="['../quality/dashboard']">Quality Dashboard</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of menuItems$ | async">
              <li *ngIf="item.logComponentID === qualityFeature">
                <a [routerLink]="" (click)="loadReport(item)">{{
                  item.menuName
                  }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <ng-container *hasFeature="trackValueCalculatorFeature">
      <div class="widget-column column" *hasFeatureFlag="valueCalculatorFeatureFlag">
        <div class="widget u-fill" data-cy="msspValue_track_summary_widget">
          <div class="header header--slanted">
            <div class="titles t5">
              <div class="title">{{ValueCalculatorFeatureName}}</div>
            </div>
          </div>
          <div class="dynamicBody padding--large">
            <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Track_Sum_MSSP_1700x1100_20221114.svg" />
            <p class="u-italic u-opacity--light margin-top--smaller">
              This tool informs you of your value earned each year from participating in the Medicare Shared Savings
              Program.
            </p>
            <ul class="margin-top--large margins-bottom--small">
              <li *hasComponent="trackValueCalculatorComponent">
                <a [routerLink]="['../value-calculator/valueCalculator']">{{valueCalculatorReportName}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *hasFeature="compareFeature">
      <div class="widget-column column" *hasFeatureFlag="compareFeatureFlag">
        <div class="widget u-fill" data-cy="compare_track_summary_widget">
          <div class="header header--slanted">
            <div class="titles t5">
              <div class="title">{{compareFeatureName}}</div>
            </div>
          </div>
          <div class="dynamicBody padding--large">
            <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Practice_Compare_1700x1100_20230502.png" />
            <p class="u-italic u-opacity--light margin-top--smaller">
              This tool allows you to easily compare your performance in the Medicare Shared Savings Program against your peers in the community, identify areas of opportunity, and recognize high performers.
            </p>
            <ul class="margin-top--large margins-bottom--small">
              <li *hasComponent="compareComponent">
                <a [routerLink]="['../compare']">{{compareFeatureName}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>