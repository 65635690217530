<input class="hideFileInput" *hasFeatureFlag="importFeatureFlag" type="file" multiple
    (change)="onFileInputClicked($event)" #fileUpload>

<a *hasFeatureFlag="importFeatureFlag" (click)="onImportButtonClick()" style="display: inline; cursor: pointer; margin-bottom: 0;">Attach
    File</a>

<div *ngFor="let file of files" style="color: black;">
    <coach-file-control [filename]="file" [tier2Id]="tier2Id" [prescriptionRefId]="prescriptionRefID" (setMessage)="setMessage($event)"
        (removeFile)="removeFile($event)"></coach-file-control>
</div>
<p style="color: black;">{{message}}</p>
