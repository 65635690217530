<div class="drag-drop-container">
  <div class="header padding-bottom--smaller darker-border-bottom">
    <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--small">
      <div class="open-gaps-title">
        <span class="titles t5 u-bold">OPEN GAPS</span>
        <div class="icon-loading">
          <coach-loading-indicator class="loading-indicator"
            [ngClass]="{'is-hidden':!loading}"></coach-loading-indicator>
        </div>
      </div>
    </div>
  </div>

  <!-- Open Gaps -->

  <ul class="open-gaps-container">

    <li *ngFor="let group of openGaps; let groupIdx = index">
      <div class="group-title">
        <span class="sub-title">{{group.groupname}}</span>
      </div>

      <ul>

        <li *ngFor="let diag of group.diagnoses; let diagIdx = index">
          <div class="diag-container">
            <div class="buttons">
              <a class="icon-link margin-right" (mouseover)="showTooltipClosed(groupIdx, diagIdx)"
                (mouseout)="hideTooltipClosed(groupIdx, diagIdx)">
                <coach-icon iconName="facesheet-check" iconColor="default" class="facesheet-icon"
                  (click)="markAsClosed(diag)"></coach-icon>
                <div *ngIf="diag.tooltipMarkAsClosed" class="tooltip">
                  <div class="tooltip-title">Mark as Closed</div>
                  <span class="tooltip-desc">
                    This condition has been reviewed and coded.
                    This action will move the item into the Closed Gaps section below
                  </span>
                </div>
              </a>

              <a class="icon-link" (mouseover)="showTooltipNotApplicable(groupIdx, diagIdx)"
                (mouseout)="hideTooltipNotApplicable(groupIdx, diagIdx)">
                <coach-icon iconName="facesheet-square" iconColor="default"
                  (click)="markAsNotApplicable(diag)"></coach-icon>
                <div *ngIf="diag.tooltipMarkAsNotApplicable" class="tooltip">
                  <div class="tooltip-title">Mark as Not Applicable</div>
                  <span class="tooltip-desc">
                    This condition has been reviewed and
                    it no longer applies to this patient.
                    This action will move the item into the
                    Closed Gaps section below
                  </span>
                </div>
              </a>
            </div>

            <div class="desc">
              <span>{{diag.dx | DXCodeFormat}}: {{diag.desc}}</span>
              <span [class]="diag.isChronic ? 'rounded-yellow' : 'rounded-grey'">
                {{ diag.isChronic ? 'Chronic' : 'Acute' }}
              </span>
              <a *ngIf="diag.interactions &&  diag.interactions.length > 0" class="icon-link facesheet-link-container"
                (mouseover)="showTooltip(groupIdx, diagIdx)" (mouseout)="hideTooltip(groupIdx, diagIdx)">
                <coach-icon class="tooltip-icon" iconName="facesheet-link" iconColor="default"></coach-icon>
                <span> {{diag.interactions.length > 1 ? diag.interactions.length : ''}}</span>
                <div *ngIf="diag.tooltipInteractions" class="tooltip">
                  <div class="tooltip-title">Disease Interactions</div>
                  <ul class="tooltip-list">
                    <li *ngFor="let item of diag.interactions">{{ item.interactionname }}</li>
                  </ul>
                </div>
              </a>
            </div>

            <div class="provider-name u-display--flex u-justify-content--spaceBetween u-font-size--small">
              <div class="u-thin u-text--nowrap">{{diag.providername | titlecase}}, {{diag.dos | date:'MM/dd/yy'}}</div>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>

  <div class="closed-items-container">
    <div class="header padding-bottom--smaller darker-border-bottom">
      <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--small">
        <div class="closed-gaps-title">
          <div class="u-bold">CLOSED GAPS</div>
          <div class="icon-loading">
            <coach-loading-indicator class="loading-indicator" [ngClass]="{'is-hidden':!loading}"></coach-loading-indicator>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showNoneStatus" class="none-text u-italic">None</div>

    <!-- Mark as Closed via Assessment -->
    <ul *ngIf="closedViaAssessment.length>0">
      <li>
        <div class="group-title">
          <span class="sub-title">Marked as Closed via Assessment</span>
        </div>

        <ul>
          <li *ngFor="let diag of closedViaAssessment">
            <div class="diag-container">
              <div class="buttons">
                <a class="icon-link">
                  <coach-icon iconName="facesheet-open-check" iconColor="default" class="facesheet-icon"></coach-icon>
                </a>
              </div>

              <div class="desc">
                <span> {{diag.dx | DXCodeFormat}}: {{diag.desc}}</span>
                <span [class]="diag.isChronic ? 'rounded-yellow' : 'rounded-grey'">
                  {{ diag.isChronic ? 'Chronic' : 'Acute' }}
                </span>
              </div>

              <div class="provider-name u-display--flex u-justify-content--spaceBetween u-font-size--small">
                <div class="u-thin u-text--nowrap">
                  <button class="border--none background-inherit u-color--interaction btn-undo"
                    (click)="undoClosed(diag)">Undo</button>
                  <span class="divider">|</span>
                  <span> {{diag.providername | titlecase}}, {{diag.dos | date:'MM/dd/yy'}}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <!-- Mark as Not Applicable via Assessment -->
    <ul *ngIf="closedNotApplicable.length>0">
      <li>
        <div class="group-title">
          <span class="sub-title">Marked as Not Applicable via Assessment</span>
        </div>

        <ul>
          <li *ngFor="let diag of closedNotApplicable">
            <div class="diag-container">
              <div class="buttons">
                <a class="icon-link">
                  <coach-icon iconName="facesheet-squarelight" iconColor="default" class="facesheet-icon"></coach-icon>
                </a>
              </div>

              <div class="desc">
                <span> {{diag.dx | DXCodeFormat}}: {{diag.desc}}</span>
                <span [class]="diag.isChronic ? 'rounded-yellow' : 'rounded-grey'">
                  {{ diag.isChronic ? 'Chronic' : 'Acute' }}
                </span>
              </div>

              <div class="provider-name u-display--flex u-justify-content--spaceBetween u-font-size--small">
                <div class="u-thin u-text--nowrap">
                  <button class="border--none background-inherit u-color--interaction btn-undo"
                    (click)="undoNotApplicable(diag)">Undo</button>
                  <span class="divider">|</span>
                  <span> {{diag.providername | titlecase}}, {{diag.dos | date:'MM/dd/yy'}}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <!-- Closed via claim -->
    <ul>
      <li *ngFor="let group of closedByClaim">
        <div class="group-title">
          <span class="sub-title">Closed via claim</span>
        </div>

        <ul>
          <li *ngFor="let diag of group.diagnoses">
            <div class="diag-container">
              <div class="buttons">
                <a class="icon-link">
                  <coach-icon iconName="facesheet-filled-check" iconColor="default" class="facesheet-icon"></coach-icon>
                </a>
              </div>

              <div class="desc">
                <span> {{diag.dx | DXCodeFormat}}: {{diag.desc}}</span>
                <span [class]="diag.isChronic ? 'rounded-yellow' : 'rounded-grey'">
                  {{ diag.isChronic ? 'Chronic' : 'Acute' }}
                </span>


              </div>

              <div class="provider-name u-display--flex u-justify-content--spaceBetween u-font-size--small">
                <div class="u-thin u-text--nowrap">{{diag.providername | titlecase}}, {{diag.dos | date:'MM/dd/yy'}}
                </div>
              </div>
            </div>


          </li>
        </ul>
      </li>
    </ul>

    <!-- Closed due to hierarchy -->
    <ul>
      <li *ngFor="let group of closedByHierarchy">
        <div class="group-title">
          <span class="sub-title">Closed due to hierarchy</span>
        </div>

        <ul>
          <li *ngFor="let diag of group.diagnoses">
            <div class="diag-container">
              <div class="buttons">
                <a class="icon-link">
                  <coach-icon iconName="facesheet-filled-minus" iconColor="default" class="facesheet-icon"></coach-icon>
                </a>
              </div>

              <div class="desc">
                <span>{{diag.dx | DXCodeFormat}}: {{diag.desc}}</span>
                <span [class]="diag.isChronic ? 'rounded-yellow' : 'rounded-grey'">
                  {{ diag.isChronic ? 'Chronic' : 'Acute' }}
                </span>
              </div>

              <div class="provider-name u-display--flex u-justify-content--spaceBetween u-font-size--small">
                <div class="u-thin u-text--nowrap">{{diag.providername | titlecase}}, {{diag.dos | date:'MM/dd/yy'}}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>