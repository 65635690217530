import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class  PhonePipe implements PipeTransform {

  transform(value: string): string {
    if (value == 'null null') {
      return '';
    }

    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]} ${value.slice(-1)}`;
    }
    return '';
  }

}