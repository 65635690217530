<div class="ed-container">
  <div class="grid-container">
    <ag-grid-angular coachAgGridResize row-animation style="width: 100%; height: 100%"
      class="ag-theme-material ed-grid" [rowData]="rowData" [columnDefs]="columnDefs"
      [overlayNoRowsTemplate]="loadingStatus?'Loading':'No data'" (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered($event)" (rowClicked)="onRowClicked($event)" [pagination]="true"
      [paginationAutoPageSize]="true" [rowSelection]="'multiple'" [rowMultiSelectWithClick]="true" (selectionChanged)="onSelectionChanged($event)"
      (columnMoved)="onColumnUpdated()"
      (sortChanged)="onColumnUpdated()"
      (filterChanged)="agFilterChanged($event)"
      [localeText]="localeText"
      [suppressRowClickSelection]="true"
      >
    </ag-grid-angular>
<!--     <div class="export-buttons">
      <button [disabled]="!this.dataExists()" class="button is-small margin-right" (click)="onExportClick()">
        Export
      </button>
    </div> -->
  </div>
</div>
