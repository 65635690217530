import { QualityDashboardComponent } from "@track/track-quality/quality-dashboard/quality-dashboard.component";

export enum ComponentId {
  PatientList = 1,
  AWVWorklist = 2,
  EDUtilizationWorklist = 3,
  PatientDiseaseCohorts = 4,
  PatientCareGaps = 5,
  PatientTimeline = 6,
  TimeTracking = 7,
  HCCFacesheetBySchedule = 8,  
  ChronicConditions = 10,
  TimeTrackingReport = 11,
  ACONetworkUtilization = 12,
  ClinicalEpisodes = 13,
  EndofLife = 14,
  KeyPerformanceIndicators = 15,
  PostAcuteCare = 16,
  PromotingInteroperabilitySubmission = 17,
  QualityPortalSubmission = 18,
  UnofficialCMSQuality = 19,
  Dashboard = 20,
  Contacts = 21,
  ACOBoard = 22,
  SteeringCommitee = 23,
  PracticeImprovement = 24,
  InformationTechnology = 25,
  Events = 26,
  QualityFormv = 27,
  ComplianceForm = 28,
  PromotingInteroperabilityForm = 29,
  MeetingFormPartnership = 30,
  Learn = 31,
  EULA = 32,
  Banner = 33,
  Application = 34,
  Recents = 40,
  PerformanceDashboard = 52,
  QualityDashboardComponent = 63
}

export enum SubComponentId {
  PatientList = 1,
  PatientSummary = 2,
  CarePlan = 3,
  AlertManagement = 4,
  Messaging = 5,
  Surveys = 6,
  Invitations = 7,
  ProviderReport = 8,
  TimeLine = 9,
  PatientDetail = 10,
  PerformanceDashboard = 26,
  QualityDashboardSubComponentMeasure = 16,
  QualityDashboardSubComponentAllMeasures = 17,
  QualityDashboardSubComponentPatientsList = 18
}

export enum LogAuditElementID {
  ACO_ID = 4,
  ChPatId = 19,
  Tier2_ID = 12,
  Tier3_ID = 13,
  Tier4_ID = 14,
  Tier5_ID = 15,
  OrgId = 20,
  DocumentOwner = 21,
  DocumentEntryId = 22,
  ReportGUID = 23,
}

export enum ActionId {
  Delete = 1,
  Move = 2,
  Update = 3,
  Login = 4,
  ReportAccess = 5,
  PHIAccess = 6,
  Insert = 7,
  Approve = 8,
  PrintExport = 9,
  Navigate = 11,
}

export class UrlExceptionsToRemove {
  public static readonly Params = [
    'attemptCounter'
  ];
}
