import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { EulaService } from '@shared/services/eula-agreement/eula.service';
import { UserEula } from '@shared/services/eula-agreement/user-eula';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '../kill-subscriptions';

@Component({
  selector: 'coach-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent extends KillSubscriptions implements OnInit {

  public isLoading = false;
  public eula$: Observable<UserEula[]>;

  constructor(
    private eulaService: EulaService,
    private router: Router,
    private hierarchyTierService: HierarchyTierService,
    protected authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.eula$ = this.eulaService.getAllFromCache();
  }

  public acceptEULA(): void {
    this.eulaService.setShowEULA(false);
    this.isLoading = true;
    this.eulaService.post().subscribe({ next: () => {
      this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
        if (tier != null) {
          this.router.navigate([tier.abbreviation, tier.selectedTierIdForDisplay, 'home']);
        }
      });
    }, complete: () => this.isLoading = false });
  }

  public declineEULA(): void {
    this.authService.logout();
  }

}
