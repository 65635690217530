import { Activity } from '@api/care-orch/models/activity';
import { ActivityType } from '@api/care-orch/models/activity-type';
import { AwvWorkflowStatuses } from '@api/care-orch/models/awv-workflow-statuses';

export abstract class WorkflowChangeEvent {
  protected constructor(public program: string,
                        public chPatId: number,
                        public userEmail: string,
                        public oldStatus: AwvWorkflowStatuses,
                        public newStatus: AwvWorkflowStatuses,
                        public durationInMs: number,
                        public tierIds: TierIds,
                        public error?: any) {}
}

export class WorkflowSaveEvent extends WorkflowChangeEvent {
  constructor(public program: string,
              public chPatId: number,
              public userEmail: string,
              public oldStatus: AwvWorkflowStatuses,
              public newStatus: AwvWorkflowStatuses,
              public durationInMs: number,
              public tierIds: TierIds,
              public error: any,
              public activity: Activity) {
    super(program, chPatId, userEmail, oldStatus, newStatus, durationInMs, tierIds, error);
  }
}

export class WorkflowEditEvent extends WorkflowChangeEvent {
  constructor(public program: string,
              public chPatId: number,
              public userEmail: string,
              public oldStatus: AwvWorkflowStatuses,
              public newStatus: AwvWorkflowStatuses,
              public durationInMs: number,
              public tierIds: TierIds,
              public error: any,
              public oldActivity: Activity,
              public newActivity: Activity) {
    super(program, chPatId, userEmail, oldStatus, newStatus, durationInMs, tierIds, error);
  }
}

export class WorkflowDeleteEvent extends WorkflowChangeEvent {
  constructor(public program: string,
              public chPatId: number,
              public userEmail: string,
              public oldStatus: AwvWorkflowStatuses,
              public newStatus: AwvWorkflowStatuses,
              public durationInMs: number,
              public tierIds: TierIds,
              public error: any,
              public deletedActivityType: ActivityType) {
    super(program, chPatId, userEmail, oldStatus, newStatus, durationInMs, tierIds, error);
  }
}

export enum Program {
  AWV = 'AWV'
}

export enum ChangeType {
  SAVE = 'Save',
  EDIT = 'Edit',
  DELETE = 'Delete'
}

export enum WorkflowEventId {
  WORKFLOW_SAVE = 'Workflow Save',
  WORKFLOW_EDIT = 'Workflow Edit',
  WORKFLOW_DELETE = 'Workflow Delete'
}

export class TierIds {
   constructor(public tier2Id: string,
               public tier3Id: string,
               public tier4Id: string) {
  }
}
