<div>
    <div class="u-display--flex u-justify-content--right margin--large">
        <span class="expand-button" (click)="openAllTabs()">Expand All</span>
        <span class="margin-leftRight--small">|</span>
        <span class="expand-button" (click)="closeAllTabs()">Collapse All</span>
    </div>
    <coach-patient-awv-workflow *hasFeatureFlagAndAllComponents="CareOrchAccessReqs" [patient]="patient" [tier]="tier" [mobileScreenSize]="mobileScreenSize" [isTabOpen]="openTabs['AWV Opportunities']"></coach-patient-awv-workflow>
    <coach-patient-pac-navigator [patient]="patient" [tier]="tier" [mobileScreenSize]="mobileScreenSize" [isTabOpen]="openTabs['PAC Navigator Program']"></coach-patient-pac-navigator>
    <ng-container *hasFeatureFlag="IHECohort" >
        <coach-patient-ihe-worklist *hasComponent="IHECohortComponentId" [patient]="patient" [tier]="tier" [mobileScreenSize]="mobileScreenSize" [isTabOpen]="openTabs['In Home AWV Files']"></coach-patient-ihe-worklist>
    </ng-container>
    <coach-patient-awv-report *doesNotHaveFeatureFlagOrAnyComponents="CareOrchAccessReqs" [patient]="patient" [tier]="tier" [mobileScreenSize]="mobileScreenSize" [isTabOpen]="openTabs['AWV Opportunities']"></coach-patient-awv-report>
</div>
