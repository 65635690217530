<div class="u-height--fit-content margin-auto row min-height">
  <div class="span--2 u-border-radius--rounded">
    <coach-stepper class="stepper" [icon]="'bell'" [activityCompleted]="isActivityComplete"></coach-stepper>
  </div>
  <div class="span--10">
    <div class="row" (click)="isTabOpen = !isTabOpen">
      <div class="span--10 padding-left--small pointer">
        <div [ngClass]="{'tabOpen': isTabOpen === true }" class="u-bold">Appointment Reminder</div>
        <div *ngIf="!reminderOutcome && !attendedOutcome" class="u-color--brand--6 u-font-weight--light" id='testReminderDueDate'>Due: {{ dueDateTime | date:'MM/dd/yyyy' }}</div>
        <ng-container *ngIf="reminderOutcome">
          <div *ngIf="reminderOutcome.type == outcomeTypes.PatientWasRemindedOutcome" class="u-color--brand--6 u-font-weight--light">Reminded on {{ remindedDateTime | date:'MM/dd/yyyy' }}</div>
          <div *ngIf="reminderOutcome.type == outcomeTypes.PatientDidNotAnswerOutcome" class="u-color--brand--6 u-font-weight--light">No answer</div>
        </ng-container>
      </div>
      <div class="span--2 padding-left--small" id='testAppointmentReminderArrow'>
        <coach-icon [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" iconColor="currentColor"></coach-icon>
      </div>
    </div>
    <div class="padding-top--large">
      <coach-patient-step-reminder-form *ngIf="isTabOpen" class="padding-top--large padding-bottom--large padding-right--small" [attendedOutcome]= "attendedOutcome" [outcome]="reminderOutcome" (outcomeSaved)="this.outcomeSaved($event)"></coach-patient-step-reminder-form>
    </div>
  </div>
</div>
