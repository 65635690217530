import { Injectable } from '@angular/core';
import { PatientEngagement } from '@shared/enums';
import { CareComponentConstants, CarePatientItem, PresetCategory } from '@shared/models/module-constants';
import { IPatientFilterPreset, IPatientFilterPresetGroup } from '@shared/models/patient-filters';
import { AgGridLocalSettingsService } from '@shared/services/ag-grid/ag-grid.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { CareFeatureConstants } from "@shared/models/module-constants";
import { Assignment } from '@shared/enums/assignment.enum';


@Injectable({
  providedIn: 'root'
})
export class PresetSettingsService {

  private selectedPreset = new BehaviorSubject<IPatientFilterPreset>(null);
  public selectedPreset$ = this.selectedPreset.asObservable();
  private facesheetCategory = PresetCategory.Facesheet;
  private allPatientsPresetName = CarePatientItem.AllPatients;
  private isCombinedSMSCohortsEnabled = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CombineSMSCohorts);

  constructor(private agGridLocalSettingsService: AgGridLocalSettingsService, private featureFlagService: FeatureFlagService) { }

  // REVIEW I'm not sure this belongs in this service, I feel the current preset is something the presentation
  // component (in this case care-patients-filters.component.ts) owns. I feel this service could have two jobs
  // (1) building the preset list from static & dynamically retrieved values and
  // (2) saving and restoring the filter settings for a particular preset
  public async selectPreset(preset: IPatientFilterPreset): Promise<void> {
    const currentName = this.selectedPreset?.value?.name ?? null;
    if (preset.name !== currentName) {
      const filterSettings = await this.agGridLocalSettingsService.getStoredFilterSettingsFromLocalStorage(preset.category, preset.keyForFilterSettingsInLocalStorage || preset.name, null, preset.origin ? preset.origin : preset.keyForFilterSettingsInLocalStorage || preset.name);
      if (filterSettings) {
        preset.filterSettings = filterSettings;
      }

      this.selectedPreset.next(preset);
    }
  }

  public getPresetGroups(): IPatientFilterPresetGroup[] {
    return [
      {
        category: PresetCategory.General,
        hideGroup: true,
        presets: [
          {
            category: PresetCategory.General,
            name: this.allPatientsPresetName,
            label: this.allPatientsPresetName,
            filters: [],
            componentId: CareComponentConstants.PatientList,
            hidePreset: true,
            filterComponent: null,
            filterSettings: null,
            dontStoreName: true,
            columns: [
              { select: true },
              { fullName: true },
              { mbi: true },
              { dob_age: true },
              { gender: true },
              { assignment: true },
              { predictedRiskLevel: environment.displayRiskLevel },
              { attributedCP: true },
              { awvTypeDue: true },
              { lastAWVDate: true },
              { lastClaimAWVDate: true },
              { lastAWVProvider: true },
              { lastClaimAWVProvider: true },
              { ihAwvPatient: true },
              { lastCCMDateAndCode: true },
              { raF_YTD: true },
              { pyRaf: true },
              { openRAFWt: true },
              { lastVisit: true },
              { attributedPractice: true },
              { totalCost: true },
              { edVisits: true },
              { officeVisits: true },
              { inpatientVisits: true },
              { enrollmentType: true },
              { phoneNumber: true },
              { dodBoolean: true },
            ]
          },
        ],
      },
      {
        category: this.facesheetCategory,
        hideGroup: true,
        presets: [
          {
            category: this.facesheetCategory,
            name: CarePatientItem.FacesheetSchedule,
            label: 'Facesheet Schedule',
            filters: [],
            componentId: CareComponentConstants.HccFacesheetBySchedule,
            hidePreset: true,
            /**
            TODO:
            move this preset columnDefs into care-patients component.
            Currently, FacesheetScheduleGridComponent ignores this list
            */
            columns: [{ status: true },
            { appointmentDate: true },
            { appointmentTime: true },
            { clinicianName: true },
            { patientName: true },
            { patientId: true },
            { dob: true },
            { mbi: true },
            { assignment: true },
            { attributedCP: true },
            { appointmentInfo: true },
            { locationId: true },
            { currentRiskLevel: true },
            { predictedRiskLevel: environment.displayRiskLevel }],
          }
        ]
      },
      {
        category: PresetCategory.Cohorts,
        hideGroup: true,
        presets: [
          {
            category: PresetCategory.Cohorts,
            name: CarePatientItem.AwvOpportunities,
            label: 'AWV Opportunities',
            filters: [],
            componentId: CareComponentConstants.PatientDiseaseCohorts,
            hidePreset: true,
            dontStoreName: true,
            patientSidebar: true,
            patientUrlQueryParams: { tab: 'Worklist Details', subtab: 'AWV Opportunities' },
            columns: [
              { select: true },
              { fullName: true },
              { mbi: true },
              { dob_age: true },
              { gender: true },
              { assignment: true },
              { predictedRiskLevel: environment.displayRiskLevel, sort: 'desc' },
              { attributedCP: true },
              { awvTypeDue: true },
              { statusDate: true },
              { lastAWVDate: true },
              { lastAWVProvider: true },
              { ihAwvPatient: true },
              { lastCCMDateAndCode: true },
              { openRAFWt: true },
              { lastVisit: true },
              { attributedPractice: true },
              { totalCost: true },
              { edVisits: true },
              { officeVisits: true },
              { inpatientVisits: true },
              { enrollmentType: true },
              { phoneNumber: true },
            ],
            defaultFilterModel: {
              assignment: {
                filterType: 'set',
                values: [Assignment.Attributed]
              },
              statusDate: {
                filterType: 'set',
                values: [null]
              }
            },

          },
          {
            category: PresetCategory.Cohorts,
            name: CarePatientItem.EdUtilization,
            label: 'ED Utilization',
            filters: [],
            componentId: CareComponentConstants.EdUtilizationWorklist,
            hidePreset: true,
            columns: [
              { select: true },
              { patientName: true },
              { dob: true },
              { gender: true },
              { assignment: true },
              { predictedRiskLevel: environment.displayRiskLevel },
              { pyRaf: true },
              { mbi: true },
              { attributedCP: true },
              { lastVisit: true },
              { totalCost: true },
              { edVisits: true },
              { openRAFWt: true },
              { attributedPractice: true },
              { phoneNumber: true },
              { awvTypeDue: true },
              { lastAWVDate: true },
            ],
            apiParams: { track: { TopCount: "20", OrderBy: 'openRAFWt', IncludeDeceased: 'false' } }
          },
          {
            category: PresetCategory.Cohorts,
            name: CarePatientItem.CareManagementAllProgram,
            label: 'Care Management',
            filters: [],
            componentId: CareComponentConstants.CareManagement,
            hidePreset: true,
            filterComponent: null,
            filterSettings: null,
            listDefinition: 'Target your patients for care management and clinical programs',
            columns: [
              { select: true },
              { mbi: true },
              { fullName: true },
              { dob: true },
              { gender: true },
              { attributedCP: true },
              { predictedRiskLevel: environment.displayRiskLevel },
              { lastCCMDateAndCode: true },
              { ccmEligible: true },
              { lastVisit: true },
              { officeVisits: true },
              { edVisits: true },
              { inpatientVisits: true },
              { ssDenom: true },
              { pyRaf: true },
              { openRAFWt: true },
              { totalSpend: true },
              { hccGapCount: true },
              { awvTypeDue: true },
              { lastAWVDate: true },
              { attributedPractice: true },
              { enrollmentType: true },
              { ssPercent: true },
              { attributedPractice: true },
            ],
            hiddenColumnsByDefault: [
              'raF_YTD',
              'pyRaf',
              'openRAFWt',
              'totalSpend',
              'hccGapCount',
              'awvTypeDue',
              'lastAWVDate',
              'attributedPractice',
              'enrollmentType',
              'attributedPractice'
            ],
            defaultFilterModel: {
              ssPercent: {
                filterType: 'number',
                type: 'lessThanOrEqual',
                filter: 15,
              }
            },
          },
        ],
      },
      // {
      //   category: 'Clinical programs',
      //   hideGroup: true,
      //   presets: [
      //     {
      //       category: 'Clinical programs',
      //       name: 'Heart Failure',
      //       label: 'Heart Failure',
      //       diseaseCohortNameFromTrackApi: 'Heart Failure Clinical Program',
      //       filters: [],
      //       componentId: CareComponentConstants.PatientDiseaseCohorts,
      //       hidePreset: true,
      //       settingsName: 'Clinical_programs-Heart_Failure',
      //       columns: [
      //         { select: true },
      //         { fullName: true },
      //         { attributedCP: true },
      //         { dob_age: true },
      //         { gender: true },
      //         { current_MBI: true },
      //         { predictedRiskLevel: environment.displayRiskLevel },
      //         { echoProcedure_LastDate: true },
      //         { cardiologistProcedure_LastProviderName: true },
      //         { cardiologistProcedure_LastDate: true },
      //         { hfDxCount: true },
      //         { lastCCMDateAndCode: true },
      //         { isMostImpactable: true },
      //         { lastVisit: true },
      //         { edVisits: true },
      //         { officeVisits: true },
      //         { inpatientVisits: true },
      //         { totalSpend: true },
      //         { openRAFWt: true },
      //         { attributedPractice: true },
      //         { awvTypeDue: true },
      //         { lastAWVDate: true },
      //         { pyRaf: true },
      //         { phone1: true },
      //         { phone2: true },
      //       ],
      //       hiddenColumnsByDefault: [ // https://caravanhealth.atlassian.net/browse/CC-3535
      //         'attributedPractice',
      //         'pyRaf',
      //         'raF_YTD',
      //         'openRAFWt',
      //         'awvTypeDue',
      //         'lastAWVDate',
      //         'ccmEligible',
      //         'diseaseCohorts',
      //         'phone1',
      //         'phone2',
      //         'current_MBI',
      //         'hccGapCount',
      //         'totalSpend'
      //       ],
      //       defaultFilterModel: {
      //         isMostImpactable: {
      //           filterType: 'set',
      //           values: ['Yes']
      //         }
      //       },
      //     },
      //     {
      //       category: 'Clinical programs',
      //       name: 'COPD',
      //       label: 'COPD',
      //       filters: [],
      //       diseaseCohortNameFromTrackApi: 'COPD Clinical Program',
      //       componentId: CareComponentConstants.PatientDiseaseCohorts,
      //       hidePreset: true,
      //       settingsName: 'Clinical_programs-COPD',
      //       columns: [
      //         { select: true },
      //         { fullName: true },
      //         { attributedCP: true },
      //         { dob_age: true },
      //         { gender: true },
      //         { current_MBI: true },
      //         { predictedRiskLevel: environment.displayRiskLevel },
      //         { spirometryProcedure_LastDate: true },
      //         { pulmonologistProcedure_LastProviderName: true },
      //         { pulmonologistProcedure_LastDate: true },
      //         { copdDxCount: true },
      //         { lastCCMDateAndCode: true },
      //         { isMostImpactable: true },
      //         { lastVisit: true },
      //         { edVisits: true },
      //         { officeVisits: true },
      //         { inpatientVisits: true },
      //         { totalSpend: true },
      //         { openRAFWt: true },
      //         { attributedPractice: true },
      //         { awvTypeDue: true },
      //         { lastAWVDate: true },
      //         { pyRaf: true },
      //         { phone1: true },
      //         { phone2: true },
      //       ],
      //       hiddenColumnsByDefault: [ // https://caravanhealth.atlassian.net/browse/CC-3535
      //         'attributedPractice',
      //         'pyRaf',
      //         'raF_YTD',
      //         'openRAFWt',
      //         'awvTypeDue',
      //         'lastAWVDate',
      //         'ccmEligible',
      //         'diseaseCohorts',
      //         'phone1',
      //         'phone2',
      //         'current_MBI',
      //         'hccGapCount',
      //         'totalSpend'
      //       ],
      //       defaultFilterModel: {
      //         isMostImpactable: {
      //           filterType: 'set',
      //           values: ['Yes']
      //         }
      //       },
      //     },
      //     {
      //       category: 'Clinical programs',
      //       name: 'Hypertension',
      //       label: 'Hypertension',
      //       filters: [],
      //       componentId: CareComponentConstants.PatientDiseaseCohorts,
      //       hidePreset: true,
      //       columns: [{ select: true },
      //       { fullName: true },
      //       { current_MBI: true },
      //       { attributedCP: true },
      //       { dob_age: true },
      //       { gender: true },
      //       { assignment: true },
      //       { predictedRiskLevel: environment.displayRiskLevel, sort: 'desc' },
      //       { totalSpend: true },
      //       { openRAFWt: true },
      //       { lastVisit: true },
      //       { attributedPractice: true },
      //       { awvTypeDue: true },
      //       { lastAWVDate: true },
      //       { edVisits: true },
      //       { pyRaf: true },
      //       ],
      //       defaultFilterModel: {
      //         predictedRiskLevel: {
      //           filterType: 'set',
      //           values: ['High']
      //         }
      //       },
      //     },
      //   ]
      // },
      {
        category: PresetCategory.Worklists,
        hideGroup: true,
        presets: [
          {
            category: PresetCategory.Worklists,
            name: CarePatientItem.AwvSchedule,
            label: 'In Home AWV Schedule',
            filters: [],
            componentId: CareComponentConstants.IHESchedule,
            hidePreset: true,
            filterSettings: null,
            listDefinition: 'View patient status in the In-Home Annual Wellness Visit program',
            columns: [
              { select: true },
              { mbi: true },
              { fullName: true },
              { dob: true },
              { gender: true },
              { phoneNumber: true },
              { address: true },
              { scheduleStatus: true },
              { schedulingDescription: true },
              { callDate: true },
              { dateOfService: true },
              { assignment: true },
              { renderingProvider: true },
              { attributedPractice: true },
              { attributedCP: true },
              { hccGapCount: true },
              { predictedRiskLevel: true },
              { lastAWVDate: true },
              { lastCCMDate: true },
              { ccmEligible: true },
              { lastVisit: true },
              { diseaseCohorts: true },
              { totalCost: true },
              { edVisits: true },
              { openRAFWt: true },
              { raF_YTD: true },
            ]
          },
          {
            category: PresetCategory.Worklists,
            name: CarePatientItem.AwvFiles,
            label: 'In Home AWV Files',
            filters: [],
            componentId: CareComponentConstants.IHECohort,
            hidePreset: true,
            dontStoreName: true,
            patientSidebar: true,
            filterSettings: null,
            listDefinition: 'Download files from In-Home Annual Wellness Visit',
            columns: [
              { select: true },
              { current_MBI: true },
              { fullName: true },
              { dob: true },
              { gender: true },
              { IHEAWVVisitDate: true },
              { IHEfileActions: true },
              { IHEDownloadDate: true },
              { IHEDownloadStatus: true },
              { assignment: true },
              { attributedPractice: true },
              { attributedCP: true },
              { renderingProviderName: true },
            ],
            patientUrlQueryParams: { tab: 'Worklist Details', subtab: 'In Home AWV Files' },
            defaultFilterModel: {
              IHEDownloadStatus: {
                filterType: 'set',
                values: ['New', 'Partial']
              }
            },
          },
          {
            category: PresetCategory.Worklists,
            name: CarePatientItem.AwvBilling,
            label: 'In-Home AWV Billing',
            filters: [],
            componentId: CareComponentConstants.IHEBilling,
            hidePreset: true,
            filterSettings: null,
            columns: [
              { select: true },
              { mbi: true },
              { fullName: true },
              { dob: true },
              { gender: true },
              { phoneNumber: true },
              { address: true },
              { IHEAWVVisitDate: true },
              { cptCode: true },
              { claimFrequencyCode: true },
              { IHEAWVBatchDate: true },
              { iheDxCount: true },
              { renderingProvider: true },
              { attributedPractice: true },
              { attributedCP: true },
            ],
            patientUrlQueryParams: { tab: 'Worklist Details' },
          },
          {
            category: PresetCategory.Worklists,
            name: CarePatientItem.PacNavigatorProgram,
            label: 'PAC Navigator Program',
            filters: [],
            componentId: CareComponentConstants.PACNavigatorProgram,
            hidePreset: true,
            dontStoreName: true,
            columns: [
              { select: true },
              { fullName: true },
              { dob: true },
              { mbi: true },
              { predictedRiskLevel: environment.displayRiskLevel },
              { attributedCP: true },
              { snfName: true },
              { snfAdmittedAt: true },
              { snfDischargedAt: true },
              { snfStatus: true },
              { snfFlagsList: true },
              { assignment: true },
              { gender: true },
              { attributedPractice: true },
              { lastAWVDate: true },
              { lastAWVProvider: true },
              { ihAwvPatient: true },
              { hccGapCount: true },
              { openRAFWt: true },
              { pyRaf: true },
              { lastCCMDate: true },
              { edVisits: true },
              { inpatientVisits: true },
              { officeVisits: true },
              { lastVisit: true },
              { enrollmentType: true },
            ],
            patientSidebar: true,
            patientUrlQueryParams: { tab: 'Worklist Details', subtab: 'PAC Navigator Program' },
            defaultFilterModel: {
              snfStatus: {
                filterType: 'set',
                values: ['Admitted', 'Discharged']
              }
            },
            requiredPatientFields: ['snfStatus'],
          }, {
            category: PresetCategory.Worklists,
            name: CarePatientItem.SmsOutreachProgram,
            label: 'SMS Outreach Program',
            filters: [],
            componentId: PatientEngagement.PatientTwilioSMS,
            hidePreset: true,
            dontStoreName: true,
            columns: [
              { select: true },
              { mbi: true },
              { fullName: true },
              { dob: true },
              { gender: true },
              { attributedCP: true },
              { predictedRiskLevel: environment.displayRiskLevel },
              { actions: this.isCombinedSMSCohortsEnabled },
              { lastResponse: true },
              { lastResponseDate: true },
              { enrollmentDate: true },
              { status: true },
              { professionalName: this.isCombinedSMSCohortsEnabled },
              { lastCCMDate: this.isCombinedSMSCohortsEnabled },
              { ccmLastProcCode: this.isCombinedSMSCohortsEnabled },
              { phoneNumber: true },
              { attributedPractice: true },
              { lastVisit: true },
              { lastAWVDate: true },
              { diseaseCohorts: true },
              { openRAFWt: true },
              { raF_YTD: true },
              { hccGapCount: true },
              { ccmEligible: this.isCombinedSMSCohortsEnabled },
              { assignment:this.isCombinedSMSCohortsEnabled },
            ],
            hiddenColumnsByDefault: this.isCombinedSMSCohortsEnabled ?
            [
              'lastAWVDate',
              'ccmEligible',
              'diseaseCohorts',
              'openRAFWt',
              'raF_YTD',
              'hccGapCount',
              'assignment'
            ] : [] ,
            defaultFilterModel: {
              status: {
                filterType: 'set',
                values: ['Active']
              }
            },
            // patientsDataExtender: async (patients: PatientViewModel[]): Promise<PatientViewModel[]> => {
            //   let tasks = await this._orgService.getTasksByOrg(this.orgId, 'twilioStudioFlow');
            //   let completions = await this._orgService.getCompletionsByOrg(this.orgId, 'twilioStudioFlow');
            //   let patientDataById = {};
            //   tasks?.forEach(task => {
            //     if (!patientDataById[task.userId]) {
            //       patientDataById[task.userId] = {};
            //     };
            //     patientDataById[task.userId].status = task.isActive;
            //   });
            //   completions?.forEach(completion => {
            //     if (completion.event?.msg && completion.event?.event != 'undeliverable') {
            //       if (!patientDataById[completion.userId]) {
            //         patientDataById[completion.userId] = {};
            //       };
            //       patientDataById[completion.userId].lastResponseDate = completion.event?.time || '';
            //       patientDataById[completion.userId].lastResponse = completion.event?.event == 'undeliverable' ? 'Undeliverable' : completion.event?.msg;
            //     }
            //   });
            //   let filteredPatients: PatientViewModel[] = [];
            //   patients?.forEach(patient => {
            //     if (patientDataById[patient?.carePatient?._id]) {
            //       patient.status = patientDataById[patient.carePatient._id].status ? "Active" : "Inactive";
            //       patient.lastResponseDate = patientDataById[patient.carePatient._id].lastResponseDate;
            //       patient.lastResponse = patientDataById[patient.carePatient._id].lastResponse;
            //       if (patient.carePatient?.identifiers?.find(identifier => identifier.issuer == "https://identity.caravanhealth.com/smscareplanphonenumber")) {
            //         patient.enrollmentDate = patient.carePatient?.identifiers?.find(identifier => identifier.issuer == "https://identity.caravanhealth.com/smscareplanphonenumber")?.createdAt;
            //       };
            //       filteredPatients.push(patient);
            //     };
            //   });
            //   return filteredPatients;
            // }
          },
        ]
      },
      // {
      //   category: 'CHRONIC CONDITIONS',
      //   hideGroup: true,
      //   presets: [
      //     {
      //       category: 'CHRONIC CONDITIONS',
      //       name: 'CHF',
      //       label: 'CHF',
      //       diseaseCohortNameFromTrackApi: 'Heart Failure',
      //       filters: [],
      //       componentId: CareComponentConstants.PatientDiseaseCohorts,
      //       hidePreset: true,
      //       columns: [
      //         { select: true },
      //         { fullName: true },
      //         { current_MBI: true },
      //         { attributedCP: true },
      //         { phone1: true },
      //         { phone2: true },
      //         { dob_age: true },
      //         { gender: true },
      //         { assignment: true },
      //         { predictedRiskLevel: environment.displayRiskLevel },
      //         { actions: true },
      //         { totalSpend: true },
      //         { openRAFWt: true },
      //         { lastVisit: true },
      //         { attributedPractice: true },
      //         { awvTypeDue: true },
      //         { lastAWVDate: true },
      //         { edVisits: true },
      //         { pyRaf: true },
      //       ],
      //     },
      //     {
      //       category: 'CHRONIC CONDITIONS',
      //       name: 'COPD',
      //       label: 'COPD',
      //       filters: [],
      //       componentId: CareComponentConstants.PatientDiseaseCohorts,
      //       hidePreset: true,
      //       columns: [{ select: true },
      //       { fullName: true },
      //       { current_MBI: true },
      //       { attributedCP: true },
      //       { phone1: true },
      //       { phone2: true },
      //       { dob_age: true },
      //       { gender: true },
      //       { assignment: true },
      //       { predictedRiskLevel: environment.displayRiskLevel },
      //       { totalSpend: true },
      //       { openRAFWt: true },
      //       { lastVisit: true },
      //       { attributedPractice: true },
      //       { awvTypeDue: true },
      //       { lastAWVDate: true },
      //       { edVisits: true },
      //       { pyRaf: true },
      //       ],
      //     },
      //     {
      //       category: 'CHRONIC CONDITIONS',
      //       name: 'Diabetes',
      //       label: 'Diabetes',
      //       filters: [],
      //       componentId: CareComponentConstants.PatientDiseaseCohorts,
      //       hidePreset: true,
      //       columns: [{ select: true },
      //       { fullName: true },
      //       { current_MBI: true },
      //       { attributedCP: true },
      //       { phone1: true },
      //       { phone2: true },
      //       { dob_age: true },
      //       { gender: true },
      //       { assignment: true },
      //       { predictedRiskLevel: environment.displayRiskLevel },
      //       { totalSpend: true },
      //       { openRAFWt: true },
      //       { lastVisit: true },
      //       { attributedPractice: true },
      //       { awvTypeDue: true },
      //       { lastAWVDate: true },
      //       { edVisits: true },
      //       { pyRaf: true },
      //       ],
      //     }
      //   ],
      // }
    ];
  }
}
