import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HelpPanelService {

  public isHelpPanelOpen$: BehaviorSubject<boolean>;
  public isHelpPanelPinned$: BehaviorSubject<boolean>;

  constructor() {
    this.isHelpPanelOpen$ = new BehaviorSubject(false);
    this.isHelpPanelPinned$ = new BehaviorSubject(false);
  }

  // START: felt cute, might delete l8ter
  public setHelpPanelOpen(isOpen: boolean) {
    this.isHelpPanelOpen$.next(isOpen);
  }
  
  public setHelpPanelPinned(isPinned: boolean) {
    this.isHelpPanelPinned$.next(isPinned);
  }
  // END: felt cute, might delete l8ter

  public toggleHelpPanelOpen() {
    const nextValue = !this.isHelpPanelOpen$.value;
    this.isHelpPanelOpen$.next(nextValue);
  }
  
  public toggleHelpPanelPinned() {
    const nextValue = !this.isHelpPanelPinned$.value;
    this.isHelpPanelPinned$.next(nextValue);
  }

}
