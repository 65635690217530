import { QualityFormQuestion } from '@shared/models/forms/forms-frontend';
import { FormConstants } from '@shared/models/forms/form-constants';

export const QUALITY_FORM_TOOLTIPS = {
  tooltipPracticeText: {
    html: '<p>Lists practice(s) with attribution in the ACO. Click all practices that share an EHR to indicate that the submitted Quality data covers all selected practices..</p>',
  },
  tooltipExemptText: {
    html: '<p>Indicate whether selected practice(s) are exempt. If exempt, please indicate the reason in "Special Status" below.</p>',
  },
  tooltipSpecialStatusText: {
    html: '<p>Select a new Special Status, for a particular practice.</p>',
  },
  tooltipEHRText: {
    html: '<p>The twelve most popular EHRs are listed. If your EHR is not indicated, please select Other.</p>',
  },
  tooltipDataSourceText: {
    html: `<p>${FormConstants.Quality.dataSourceToolTipText}</p>`,
  },
  tooltipPatientPopText: {
    html: '<p>Data should represent patients from All Payors to mirror and prepare for upcoming CMS changes for PY2022</p>',
  },
  tooltipUploadQualityMeasureReportText: {
    html: '<p>Before submitting, please upload an electronic copy of the EHR report or excel template used to complete the form for verification and accountability.</p>',
  },
};

export const QUALITY_FORM_QUESTIONS: QualityFormQuestion[] = [
  {
    questionID: 1,
    descText: 'CARE-2 | ACO-13 |  Higher score indicates better quality',
    questionText: 'Falls: Screening for Future Falls Risk',
    isLowPointsIdeal: false,
    infoText: `<p>Percentage of patients 65 years of age and older who were screened for future fall risk during the measurement period.</p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123484"  target="_blank">Link to Measure Specification</a></p>

    `,
    minDenominator: 1,
  },
  {
    questionID: 2,
    descText: 'DM-2 | ACO-27 |  Lower score indicates better quality',
    questionText: 'Diabetes: Hemoglobin A1c Poor Control (>9.0%)',
    isLowPointsIdeal: true,
    infoText: `<p>Percentage of patients 18 - 75 years of age with diabetes who had hemoglobin A1c > 9.0% during the measurement period.</p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123468"  target="_blank">Link to Measure Specification</a></p>
    `,
    minDenominator: 1,
  },
  {
    questionID: 5,
    descText: 'HTN-2 | ACO-28 |  Higher score indicates better quality',
    questionText: 'Controlling High Blood Pressure',
    isLowPointsIdeal: false,
    infoText: `<p>Percentage of patients 18 - 85 years of age who had a diagnosis of essential hypertension starting before and continuing into, or starting during the first six months of the measurement period.</p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123489"  target="_blank">Link to Measure Specification</a></p>
    `,
    minDenominator: 1,
  },
  {
    questionID: 3,
    descText: 'PREV-5 | ACO-20 |  Higher score indicates better quality',
    questionText: 'Breast Cancer Screening',
    isLowPointsIdeal: false,
    infoText: `<p>Percentage of women 50 - 74 years of age who had a mammogram to screen for breast cancer in the 27 months prior to the end of the measurement period. </p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123465"  target="_blank">Link to Measure Specification</a></p>
    `,
    minDenominator: 1,
  },
  {
    questionID: 4,
    descText: 'PREV-6 | ACO-19 |  Higher score indicates better quality',
    questionText: 'Colorectal Cancer Screening',
    isLowPointsIdeal: false,
    infoText: `<p>Percentage of adults 45 -75 years of age who had appropriate screening for colorectal cancer.</p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123479"  target="_blank">Link to Measure Specification</a></p>
    `,
    minDenominator: 1,
  },
  {
    questionID: 7,
    descText: 'PREV-7 | ACO-14 |  Higher score indicates better quality',
    questionText: 'Preventive Care and Screening: Influenza Immunization',
    isLowPointsIdeal: false,
    infoText: `<p>Percentage of patients aged 6 months and older seen for a visit during the measurement period who received an influenza immunization OR who reported previous receipt of an influenza immunization.</p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123472"  target="_blank">Link to Measure Specification</a></p>
    `,
    minDenominator: 1,
  },
  {
    questionID: 49,
    descText: 'PREV-10 | ACO-18 |  Higher score indicates better quality',
    questionText:
      'Preventive Care and Screening: Tobacco Use: Screening and Cessation Intervention',
    isLowPointsIdeal: false,
    infoText: `<p>Percentage of patients aged 18 years and older who were screened for tobacco use one or more times during the measurement period or in the six months prior to the measurement period AND who received tobacco cessation intervention during the measurement period or in the six months prior to the measurement period if identified as a tobacco user.</p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123485"  target="_blank">Link to Measure Specification</a></p>
    `,
    minDenominator: 1,
  },
  {
    questionID: 6,
    descText: 'PREV-12 | ACO-18 |  Higher score indicates better quality',
    questionText:
      'Preventive Care and Screening: Screening for Depression and Follow-Up Plan',
    isLowPointsIdeal: false,
    infoText: `<p>Percentage of patients aged 12 years and older screened for depression on the date of the encounter or 14 days prior to the date of the encounter using an age appropriate standardized depression screening tool AND if positive, a follow-up plan is documented on the date of the eligible encounter. </p>
    <p><a href="https://coach.caravan.health/shared/improve/practice-improvement?folder=1123464&file=1123469"  target="_blank">Link to Measure Specification</a></p>
    `,
    minDenominator: 1,
  },
];
