import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import {
  FileSearchService,
  SearchDocument,
} from '@shared/services/doc-exchange/file-search.service';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '@shared/services/doc-exchange/document.service';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';

@Component({
  selector: 'coach-improve-search',
  templateUrl: './improve-search.component.html',
  styleUrls: ['./improve-search.component.scss'],
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'row u-fill u-overflow--hidden' },
})
export class ImproveSearchComponent implements OnInit, OnDestroy {
  public docURL: string = null;
  public isQueueOpen = true;
  public fileClicked = false;
  public error: Error;
  public fileData;
  public searchResults = [];
  private previewSubscription: Subscription;
  public viewingSessionId: string;
  public viewerControl: any;
  public canInteract = false;
  public selectedFile: SearchDocument;
  public selectedFilePathFormated: string;
  public safeSrc: SafeResourceUrl;
  public unSafeSrc: string;
  public fileType: string;
  public tier: IHierarchyTier;
  public originalSharedButtonText = 'Share';
  public sharedButtonText = this.originalSharedButtonText;
  public tabname = "Practice Improvement"

  constructor(
    private route: ActivatedRoute,
    private fileDownloadService: FileDownloadService,
    private fileSearchService: FileSearchService,
    private documentService: DocumentService,
    private sanitizer: DomSanitizer,
    private tierService: HierarchyTierService
  ) {}

  ngOnInit(): void {
    this.tierService.currentTier$.subscribe((value) => {
      if (value != null) {
        this.tier = value;
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params.query) {
        this.fileSearchService.search(params.query);
        this.fileSearchService.results$.subscribe((results) => {
          this.searchResults = results;
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.previewSubscription) {
      this.previewSubscription.unsubscribe();
    }
  }

  public toggleQueueOpen(): void {
    this.isQueueOpen = !this.isQueueOpen;
  }

  public fileSelected(file: SearchDocument): void {
    this.selectedFile = file;

    const decodedPath = decodeURI(file.path);
    const substring = decodedPath.substring(
      decodedPath.indexOf('/') + 1,
      decodedPath.length
    );
    this.selectedFilePathFormated = substring;

    this.fileSearchService.changePreviewUrl(file.path, file.tier_node);

    this.previewSubscription = this.fileSearchService.previewUrl$.subscribe(
      (url) => {
        const fileExt = file.title.slice(file.title.length - 4);

        if (file.title.length > 4 && fileExt === '.url') {
          console.log(`*** Subscribing to "${file.title}"`);
          this.fileType = 'URL';
          this.fileDownloadService.downloadFile(url).subscribe((response) => {
            response.text().then((data) => {
              const textUrlStart = data.indexOf('URL=');
              const textUrlA = data.slice(textUrlStart + 4);
              let textUrl;
              if (textUrlA.indexOf('\n') >= 1) {
                textUrl = textUrlA.slice(0, textUrlA.indexOf('\n') - 1);
              } else {
                textUrl = textUrlA;
              }
              if (textUrl.length >= 3) {
                this.unSafeSrc = textUrl;
                console.log(textUrl);
                this.safeSrc =
                  this.sanitizer.bypassSecurityTrustResourceUrl(textUrl);
                console.log(this.safeSrc);
              } else {
                console.warn('Non-functional URL:', textUrl);
              }
            });
          });
        } else {
          this.fileType = 'default';
          // Create a viewing session for the document we want to view, then update
          // this.viewingSessionId. Setting a value for this.viewingSessionId will
          // send the value (via data binding in the HTML template) to the child
          // prizmdoc-viewer-wrapper component, causing it to initialize.
          this.documentService
            .createViewingSessionForDocument(file.title, url, fileExt)
            .pipe(
              catchError((err) => {
                this.error = err;
                return EMPTY;
              })
            )
            .subscribe((viewingSessionId: string) => {
              this.viewingSessionId = viewingSessionId;
            });
        }
        this.docURL = url;
        this.fileClicked = true;
      }
    );
  }

  download(doc: string): void {
    try {
      this.fileDownloadService.downloadFile(doc).subscribe((response) => {
        const blob: any = new Blob([response], {
          type: 'text/json; charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, this.fileData.OriginalFileName);
      });
    } catch (error) {
      console.error('Error downloading the file' + error);
    }
  }

  onIframeClick(): void {
    window.open(this.unSafeSrc, '_blank');
  }

  sharedClicked(event): void {
    let copyText = window.location.href;
    this.sharedButtonText = 'Copied';
    // copyText.replace()
    const start = copyText.indexOf('/', 10);
    const end = copyText.indexOf('/improve', 10);
    const stringToBeSwapped = copyText.substring(start, end);
    copyText = copyText.replace(stringToBeSwapped, '/shared');
    console.log(`Copied Shareable link to document. ${copyText}`);
    navigator.clipboard.writeText(copyText);
  }

  // Call this method to store a reference to the viewerControl so we can use its API.
  setViewerControl(viewerControl: any): void {
    // Store a reference to the viewerControl.
    this.viewerControl = viewerControl;

    // Update our "canInteract" variable whenever the viewer is fully ready. For
    // the full list of PCCViewer.EventType values you can subscribe to, see
    // https://help.accusoft.com/PrizmDoc/v13.13/HTML/PCCViewer.html#.EventType
    this.viewerControl.on(
      (window as any).PCCViewer.EventType.ViewerReady,
      () => {
        this.canInteract = true;
      }
    );
  }
}
