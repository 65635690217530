<table class="patientTimeline table--unstyled margin-topBottom margin-leftRight--large">
    <tr>
        <th>Dates of Service</th>
        <th>Provider (NPI) / Facility</th>
        <th>Diagnosis / Procedures</th>
    </tr>
    <ng-container *ngFor="let item of timelineItems">
        <tr *ngIf="item.newMonthSeparator" class="patientTimeline--month">
            <td colspan="3">
                <div class="u-font-size--smallest" attr.data-month="{{ item.claimFromDate | date:'MMMM y' }}"></div>
            </td>
        </tr>
        <tr>
            <td class="d6">{{ item.claimFromDate | date:'MMM d' }}</td>
            <td>
                <div class="u-bold">{{ item.renderingName | titlecase }} <span class="u-font-size--small u-font-weight--normal">({{ item.renderingNPI }})</span></div>
            </td>
            <td>
                <div class="u-bold">{{ item.primaryDx }} {{ item.primaryDxDesc ? ': ' + item.primaryDxDesc : '' }}</div>
                <div>
                    <ul>
                        <li>{{ item.procedureCode }} {{ item.procedureDesc ? ': ' + item.procedureDesc : '' }}</li>
                    </ul>
                </div>
            </td>
        </tr>
    </ng-container>
</table>