import { Injectable } from '@angular/core';
import { OutcomeType } from '@api/care-orch/models/outcome-type';
import { PatientAttendedOutcome } from '@api/care-orch/models/patient-attended-outcome';
import { WorkflowDateTimeUtil } from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import { Outcome } from '@api/care-orch/models/outcome';
import { CompletedProperties } from '@api/care-orch/models/completed-properties';

@Injectable({
  providedIn: 'root'
})
export class NextAwvDateValidationService {

  constructor() { }

  isNextAwvDateChangedAndHasValidValue( nextAwvDateChecked: boolean, nextAwvDate: string,
                                        minNextAwvDate: string, maxNextAwvDate: string, outcome: Outcome): boolean{
    const newFormFill = () => nextAwvDateChecked && !this.nextAwvDateWasPreviouslySaved(outcome)
      && nextAwvDate !== "" && (nextAwvDate > minNextAwvDate && nextAwvDate < maxNextAwvDate);
    if (newFormFill()){
      return true;
    }
    const nextAwvDatePreviouslyUncheckedButIsNowChecked = () => !nextAwvDateChecked && this.nextAwvDateWasPreviouslySaved(outcome);
    if (nextAwvDatePreviouslyUncheckedButIsNowChecked()){
      return true;
    }
    const previouslySavedNextAwvDateChanged = () => nextAwvDateChecked && this.nextAwvDateWasPreviouslySaved(outcome) &&
      this.nextAwvDateWasSavedPreviouslyAndChanged(outcome, nextAwvDate) && (nextAwvDate > minNextAwvDate && nextAwvDate < maxNextAwvDate);
    if (previouslySavedNextAwvDateChanged()){
      return true;
    }
    return false;
  }

  isNextAwvDateChangedAndHasValidValueForCompleted(nextAwvDateChecked: boolean, nextAwvDate: string,
                                                   minNextAwvDate: string, maxNextAwvDate: string,
                                                   properties: CompletedProperties): boolean{
    const newFormFill = () => nextAwvDateChecked
      && !this.nextAwvDateWasPreviouslySavedForCompleted(properties)
      && nextAwvDate !== "" && (nextAwvDate > minNextAwvDate && nextAwvDate < maxNextAwvDate);
    if (newFormFill()){
      return true;
    }
    const nextAwvDatePreviouslyUncheckedButIsNowChecked = () => !nextAwvDateChecked
      && this.nextAwvDateWasPreviouslySavedForCompleted(properties);
    if (nextAwvDatePreviouslyUncheckedButIsNowChecked()){
      return true;
    }
    const previouslySavedNextAwvDateChanged = () => nextAwvDateChecked
      && this.nextAwvDateWasPreviouslySavedForCompleted(properties) &&
      this.nextAwvDateWasSavedPreviouslyAndChangedForCompleted(properties, nextAwvDate)
      && (nextAwvDate > minNextAwvDate && nextAwvDate < maxNextAwvDate);
    if (previouslySavedNextAwvDateChanged()){
      return true;
    }
    return false;
  }

  nextAwvDateWasPreviouslySaved(outcome: Outcome): boolean {
    if (null == outcome){
      return false;
    }
    switch (outcome.type) {
      case OutcomeType.PatientAttendedOutcome:
        const attendedOutcome = outcome as PatientAttendedOutcome;
        if (attendedOutcome.properties.nextAwvDate !== '') {
          return true;
        }
        else {
          return false;
        }
      case OutcomeType.PatientDidNotAttendOutcome:
        return false;
    }
  }

  nextAwvDateWasSavedPreviouslyAndChanged(outcome: Outcome, nextAwvDate: string): boolean {
    if (null == outcome){
      return false;
    }
    switch (outcome.type) {
      case OutcomeType.PatientAttendedOutcome:
        const attendedOutcome = outcome as PatientAttendedOutcome;
        if (WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(attendedOutcome.properties.nextAwvDate) !== nextAwvDate) {
          return true;
        }
        else {
          return false;
        }
      case OutcomeType.PatientDidNotAttendOutcome:
        return false;
    }
  }

  nextAwvDateWasPreviouslySavedForCompleted(properties: CompletedProperties): boolean {
    if (null == properties){
      return false;
    }
    if (properties.nextAwvDate !== '') {
      return true;
    }
    else {return false; }
  }

  nextAwvDateWasSavedPreviouslyAndChangedForCompleted(properties: CompletedProperties, nextAwvDate: string): boolean {
    if (null == properties){
      return false;
    }
    if (WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(properties.nextAwvDate) !== nextAwvDate) {
      return true;
    }
    else {return false; }
  }
}
