import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from './header.component';
import { HeaderScopeOverlayComponent } from './header-scope-overlay/header-scope-overlay.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { HeaderScopeDropdownComponent } from './header-scope-dropdown/header-scope-dropdown.component';
import { HeaderHierarchyOverlayComponent } from './header-hierarchy-overlay/header-hierarchy-overlay.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { HeaderProcessesComponent } from './header-processes/header-processes.component';
import { HeaderProcessProgressComponent } from './header-processes/header-process-progress/header-process-progress.component';

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderScopeOverlayComponent,
        HeaderScopeDropdownComponent,
        HeaderSearchComponent,
        HeaderHierarchyOverlayComponent,
        HeaderProcessesComponent,
        HeaderProcessProgressComponent
    ],
    imports: [
        SharedModule,
        PortalModule,
        OverlayModule
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule { }
