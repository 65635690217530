import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import {
  CareComponentConstants,
  CareFeatureConstants,
  CareOrchestrationConstants,
  CarePatientItem,
  PresetCategory
} from '@shared/models/module-constants';
import { AwvWorklistFiltersComponent } from '@care/components/awv-worklist/awv-worklist-filters/awv-worklist-filters.component';
import moment from 'moment';
import { PatientEngagementComponentIds } from '@shared/enums';
import { environment } from 'src/environments/environment';
import { Assignment } from '@shared/enums/assignment.enum';

@Injectable({
  providedIn: 'root'
})
export class AwvPatientsWorklistPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categotyName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.AnnualWellnessVisits: PresetCategory.Cohorts;
    if (this.awvPatientsWorklist) {
      const item: IPatientFilterPreset = {
        category: categotyName,
        name: CarePatientItem.AwvOpportunities,
        presetKey: CarePatientItem.AwvOpportunities,
        label: 'AWV Opportunities',
        filters: [],
        componentId: CareComponentConstants.AwvPatientsWorklist,
        hidePreset: true,
        filterComponent: AwvWorklistFiltersComponent,
        dontStoreName: true,
        patientSidebar: true,
        patientUrlQueryParams: {
          tab: 'Facesheet',
          subtab: 'AWV Opportunities'
        },
        columns: this.getPresetColumns(),
        hiddenColumnsByDefault: this.getHiddenColumns(),
        defaultFilterModel: {
          assignment: {
            filterType: 'set',
            values: [Assignment.Attributed]
          },
          lastAWVDate: {
            dateFrom: moment().subtract(1, 'year').format('YYYY-MM-DD hh:mm:ss'), // format "2023-05-02 00:00:00",
            dateTo: null,
            filterType: 'date',
            type: 'lessThanOrEqual'
          },
          allAWVPatientsListStatus: {
            filterType: 'set',
            values: ['READY_TO_SCHEDULE', 'READY_TO_REMIND', 'READY_TO_SELF_REPORT']
          }
        },
        selectedByDefault: true,
        AWVPatientsStatusURLQuery: true
      };

      item.versionKey = this.getVersionKey(item.presetKey);
      return item;
    } else {
      // If user doesn't have acccess it uses this.
      const item: IPatientFilterPreset = {
        category: PresetCategory.AnnualWellnessVisits,
        name: CarePatientItem.AwvOpportunities,
        presetKey: CarePatientItem.AwvOpportunities,
        label: 'AWV Opportunities',
        filters: [],
        componentId: CareComponentConstants.PatientDiseaseCohorts,
        hidePreset: true,
        filterComponent: AwvWorklistFiltersComponent,
        dontStoreName: true,
        patientSidebar: true,
        patientUrlQueryParams: {
          tab: 'Facesheet',
          subtab: 'AWV Opportunities'
        },
        columns: this.getPresetColumns(),
        hiddenColumnsByDefault: this.getHiddenColumns(),
        defaultFilterModel: {
          assignment: {
            filterType: 'set',
            values: [Assignment.Attributed]
          },          
          newStatusDate: {
            filterType: 'set',
            values: [null]
          }
        },
        selectedByDefault: true
      };

      item.versionKey = this.getVersionKey(item.presetKey);
      return item;
    }
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    if (this.awvPatientsWorklist) {
      return [
        { select: true },
        { fullName: true },
        { mbi: true },
        { dob_age: true },
        { gender: true },
        { assignment: true },
        {
          predictedRiskLevel: environment.displayRiskLevel,
          sort: 'desc'
        },
        { attributedCP: true },
        {
          allAWVPatientsListStatus: true
        },
        { awvTypeDue: true },
        { lastAWVDate: !this.ehrFlag },
        { lastClaimAWVDate: this.ehrFlag },
        { lastAWVProvider: !this.ehrFlag },
        { lastClaimAWVProvider: this.ehrFlag },
        { statusDate: !this.ehrFlag },
        { newStatusDate: this.ehrFlag },
        { lastEHRAWVIPPEDate: this.ehrFlag },
        { ehrLastAwvCommunity: this.ehrFlag },
        { ehrLastAwvProvider: this.ehrFlag },
        { ehrReportedLastAwvDate: this.ehrFlag },
        { ehrReportedLastAwvSource: this.ehrFlag },
        { ihAwvPatient: true },
        {
          hccGapCount: this.agGridCustomFilteringFlag
        },
        {
          diseaseCohorts: this.agGridCustomFilteringFlag
        },
        { lastCcmDate: true },
        { ccmLastProcCode: true},
        {
          ccmEligible: this.agGridCustomFilteringFlag
        },
        {
          raF_YTD: this.agGridCustomFilteringFlag
        },
        {
          pyRaf: this.agGridCustomFilteringFlag
        },
        { openRAFWt: true },
        { lastVisit: true },
        { attributedPractice: true },
        { totalCost: true },
        { edVisits: true },
        { officeVisits: true },
        { inpatientVisits: true },
        { enrollmentType: true },
        { phoneNumber: true },

        {
          actions: this.userAccessService.hasComponent(this.userAccess, PatientEngagementComponentIds)
        },
        { lastCsthDate: true },
        { awvWorkflowStatus: true }
      ];
    }

    return [
      { select: true },
      { fullName: true },
      { mbi: true },
      { dob_age: true },
      { gender: true },
      { assignment: true },
      {
        predictedRiskLevel: environment.displayRiskLevel, sort: 'desc'
      },
      { attributedCP: true },
      {
        awvWorkflowStatus: this.hasCareOrchFeatureFlagAndComponents()
      },
      { awvTypeDue: true },
      { lastAWVDate: !this.ehrFlag },
      {
        lastClaimAWVDate: this.ehrFlag
      },
      {
        lastAWVProvider: !this.ehrFlag
      },
      {
        lastClaimAWVProvider: this.ehrFlag
      },
      { statusDate: !this.ehrFlag },
      { newStatusDate: this.ehrFlag },
      {
        lastEHRAWVIPPEDate: this.ehrFlag
      },
      {
        ehrLastAwvCommunity: this.ehrFlag
      },
      {
        ehrLastAwvProvider: this.ehrFlag
      },
      {
        ehrReportedLastAwvDate: this.ehrFlag
      },
      {
        ehrReportedLastAwvSource: this.ehrFlag
      },
      { ihAwvPatient: true },
      {
        hccGapCount: this.agGridCustomFilteringFlag
      },
      {
        diseaseCohorts: this.agGridCustomFilteringFlag
      },
      { lastCcmDate: true },
      { ccmLastProcCode: true},
      {
        ccmEligible: this.agGridCustomFilteringFlag
      },
      {
        raF_YTD: this.agGridCustomFilteringFlag
      },
      {
        pyRaf: this.agGridCustomFilteringFlag
      },
      { openRAFWt: true },
      { lastVisit: true },
      { attributedPractice: true },
      { totalCost: true },
      { edVisits: true },
      { officeVisits: true },
      { inpatientVisits: true },
      { enrollmentType: true },
      { phoneNumber: true },

      {
        actions: this.userAccessService.hasComponent(this.userAccess, PatientEngagementComponentIds)
      },
      { lastCsthDate: true }
    ];
  }

  private hasCareOrchFeatureFlagAndComponents(): boolean {
    return (
      this.featureFlagService.hasFeatureFlag(CareOrchestrationConstants.GridViewAccessRequirements.featureFlagId) &&
      CareOrchestrationConstants.GridViewAccessRequirements.componentIds.every(id =>
        this.userAccessService.hasComponent(this.userAccessService.currentAccess$.value, id)
      )
    );
  }

  private getHiddenColumns(): string[] {
    const hiddenColumns = ['lastCsthDate', 'ehrLastAwvProvider', 'ehrLastAwvCommunity'];

    if (!this.hideEhrColumnsInAwvOpportunities) {
      return hiddenColumns;
    }

    return hiddenColumns.concat(['newStatusDate', 'lastEHRAWVIPPEDate']);
  }

  private getVersionKey(presetKey: string) {
    if (this.hideEhrColumnsInAwvOpportunities) {
      return `columnState_v2_${this.userId}_${presetKey}`;
    }
    return `columnState_${this.userId}_${presetKey}`;
  }

  private get hideEhrColumnsInAwvOpportunities() {
    return this.featureFlagService.hasFeatureFlag(CareFeatureConstants.HideEHRColumnsInAwvOpportunities);
  }
}
