import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { LogAuditElementID } from '../audit-constants';
import { IRecentMatcher } from './recent-matcher';
export class ReportMatcher implements IRecentMatcher {
  match(url: string): boolean {
    if (url.includes('track/report/')) {
      return true;
    } else {
      return false;
    }
  }

  createLog(url: string): RecentLogData {
    const segments = url.split('/');
    if (segments.length > 7) {
      segments.length = 7;
    }
    return {
      url: segments.join('/'),
      elements: [
        {
          id: LogAuditElementID.ReportGUID,
          value: url.split('/')[5],
        },
      ],
    };
  }

  // null to trigger lookup call
  title(url: string): null {
    return null;
  }
}
