import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl, UntypedFormGroup, FormArray, UntypedFormControl, FormBuilder } from '@angular/forms';
import { DateQuestion, CheckboxQuestionCheckboxOptions, ExclusionRequestDetails} from '@shared/models/forms/forms-frontend';
import { ToolTipMeasureDetails } from '@shared/models/forms/tool-tip-measure-details';
import moment, { Moment } from 'moment';

@Component({
  selector: 'coach-submit-interoperability-date-range',
  templateUrl: './submit-interoperability-date-range.component.html',
  styleUrls: ['./submit-interoperability-date-range.component.scss'],
})
export class SubmitInteroperabilityDateRangeComponent implements OnInit {
  @Input() public parentForm: UntypedFormGroup;
  @Input() public questionInfo: DateQuestion;
  @Output() showHelpText = new EventEmitter<ToolTipMeasureDetails>();

  public startDateText = 'startDate';
  public endDateText = 'endDate';

  public startDateValue;
  public endDateValue;

  public dateRange;

  public errors = [];

  private hasInitialized = false;

  public showExclusionModal = false;

  public dateDifference;

  public get isDisabled(): boolean {
    return (
      this.parentForm.get(`${this.questionInfo.name}.isDisabled`)?.value ===
      true
    );
  }

  public errTexts = {
    StartShort :'Start date must be at least {{ReqDayDiff}} days before end date',
    EndShort : 'End date must be at least {{ReqDayDiff}} days after start date',
    StartFuture : 'Start date is in the futre, please select actual start date',
    EndFuture : 'End date is a future date',
    InvalidStartDate : 'Enter Valid Date',
    InvalidEndDate : 'Enter Valid Date'}


  get startControl(): AbstractControl {
    return this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.periodStartDate`
    );
  }

  get endControl(): AbstractControl {
    return this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.periodEndDate`
    );
  }

  ngOnInit(): void {

    this.dateDifference = this.questionInfo.requiredDayDif;

    this.errTexts.StartShort = this.errTexts.StartShort.replace('{{ReqDayDiff}}', this.dateDifference.toString());
    this.errTexts.EndShort = this.errTexts.EndShort.replace('{{ReqDayDiff}}', this.dateDifference.toString());

    const response: string = this.parentForm
    .get(`${this.questionInfo.name}.FormQResponses.0.UserResponse`)
    ?.value?.toString();

      if (response != null) {
        const responseDate = response.split("|");
        this.startControl.setValue(responseDate[0]);
        this.endControl.setValue(responseDate[1]);

        this.startDateValue = moment(responseDate[0]);
        this.endDateValue = moment(responseDate[1]);

        this.dateRange = new Date(this.startDateValue).toISOString().slice(0,10) +  "|" + new Date(this.endDateValue).toISOString().slice(0,10);

        this.toggleErrorTexts();


      } 
    }

  public changeResponse(dateValue, controlName) {

    const dateChanged =  moment(dateValue, 'YYYY-MM-DD');

    const today = moment();

    const endDate = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.periodEndDate`
    ) as UntypedFormControl;
  
    const startDate = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.periodStartDate`
    ) as UntypedFormControl;

    const responeValue = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.UserResponse`
    ) as UntypedFormControl;

    if (controlName  === this.startDateText) {
      if (dateChanged.isValid()) {
        this.startDateValue =  dateChanged;
        if (this.endDateValue == null) {
          this.endDateValue = moment(dateChanged).add(this.dateDifference, 'days');
          const newEndDate = new Date(this.endDateValue).toISOString().slice(0,10); 
          this.endControl.setValue(newEndDate);
          
        } 

        this.dateRange = new Date(this.startDateValue).toISOString().slice(0,10) +  "|" + new Date(this.endDateValue).toISOString().slice(0,10);

        responeValue.setValue(this.dateRange);
      }
      else 
        {
          this.startControl.setValue('');
          this.startDateValue = null;
          responeValue.setValue(null);
        }

      
    } else if (controlName  === this.endDateText) { 
        if (dateChanged.isValid()) {
          this.endDateValue =  dateChanged;

          if (this.startDateValue == null) {
            this.startDateValue = moment(dateChanged).subtract(this.dateDifference, 'days');
            const newEndDate = new Date(this.startDateValue).toISOString().slice(0,10); 
            this.startControl.setValue(newEndDate);
            
          } 
        
          this.dateRange = new Date(this.startDateValue).toISOString().slice(0,10) +  "|" + new Date(this.endDateValue).toISOString().slice(0,10);

          responeValue.setValue(this.dateRange);
      }
      else 
      {
        this.endControl.setValue('');
        this.endDateValue = null;
        responeValue.setValue(null);
      }
    

    } 

    this.toggleErrorTexts(dateValue, controlName);

    this.parentForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });

  }

  public toggleErrorTexts(dateValue?, controlName?): void {

    const today = moment();

    //Check if Start date is in the future
    const startFutureExists = this.errors.some((e) => e === this.errTexts.StartFuture);
        if (this.startDateValue > today) {
          if (!startFutureExists) {
            this.errors.push(this.errTexts.StartFuture);
          }
        } else {
          if (startFutureExists) {
            const i = this.errors.indexOf(this.errTexts.StartFuture);
            this.errors.splice(i, 1);
          }
        }

    //Check if End date is in the future
    const endFutureExists = this.errors.some((e) => e === this.errTexts.EndFuture);
    if (this.endDateValue > today) {

      if (!endFutureExists) {
        this.errors.push(this.errTexts.EndFuture);
      }
    } else {
      if (endFutureExists) {
        const i = this.errors.indexOf(this.errTexts.EndFuture);
        this.errors.splice(i, 1);
      }
    }
  

      const inValidStartDateExists = this.errors.some((e) => e === this.errTexts.InvalidStartDate);
      const inValidEndDateExists = this.errors.some((e) => e === this.errTexts.InvalidEndDate);
      //Determine which control is being used
      if (controlName  === this.startDateText) {
        const dateChanged =  moment(dateValue, 'YYYY-MM-DD');
        //Determine if the changed start date is valid
        if (!dateChanged.isValid()) 
          {

            if (!inValidStartDateExists) {
              this.errors.push(this.errTexts.InvalidStartDate);
            }
          } else {
            if (inValidStartDateExists) {
              const i = this.errors.indexOf(this.errTexts.InvalidStartDate);
              this.errors.splice(i, 1);
            }

        }

        //Determine if start date is greater than the required difference
        const startShortExists = this.errors.some((e) => e === this.errTexts.StartShort);
        const endShortExists = this.errors.some((e) => e === this.errTexts.EndShort);
        if (this.endDateValue.diff(this.startDateValue, 'days') < this.dateDifference) {
          if (!startShortExists) {
            this.errors.push(this.errTexts.StartShort);
          }
        } else {
          if (startShortExists) {
            const i = this.errors.indexOf(this.errTexts.StartShort);
            this.errors.splice(i, 1);         
          }
          if (endShortExists) {
            const j = this.errors.indexOf(this.errTexts.EndShort);
            this.errors.splice(j, 1);
          }
        }

        
      }     
      else if (controlName  === this.endDateText) {
        const dateChanged =  moment(dateValue, 'YYYY-MM-DD');
        //Determine if the changed end date is valid
        if (!dateChanged.isValid()) {

            if (!inValidEndDateExists) {
              this.errors.push(this.errTexts.InvalidEndDate);
            }
          } else {
            if (inValidEndDateExists) {
              const i = this.errors.indexOf(this.errTexts.InvalidEndDate);
              this.errors.splice(i, 1);
            }

        }

        //Determine if end date is greater than the required difference
        const endShortExists = this.errors.some((e) => e === this.errTexts.EndShort);
        const startShortExists = this.errors.some((e) => e === this.errTexts.StartShort);
        if (this.endDateValue.diff(this.startDateValue, 'days') < this.dateDifference) {
          if (!endShortExists) {
            this.errors.push(this.errTexts.EndShort);
          }
        } else {
          if (endShortExists) {
            const i = this.errors.indexOf(this.errTexts.EndShort);
            this.errors.splice(i, 1);         
          }
          if (startShortExists) {
            const j = this.errors.indexOf(this.errTexts.StartShort);
            this.errors.splice(j, 1);
          }
        }
      }
      //If neither control has been touched/when editing saved/submitted forms
      else {
        //Check if the start value is null but end date is not null. Probably shouldn't be able to happen
        if (this.startDateValue == null && this.endDateValue != null) {
          if (!inValidStartDateExists) {
            this.errors.push(this.errTexts.InvalidStartDate);
          }
        } else {
          if (inValidStartDateExists) {
            const i = this.errors.indexOf(this.errTexts.InvalidStartDate);
            this.errors.splice(i, 1);
          }
        }

        //Check if the end value is null but start date is not null. Probably shouldn't be able to happen
        if (this.endDateValue == null && this.startDateValue != null) {
          if (!inValidEndDateExists) {
            this.errors.push(this.errTexts.InvalidEndDate);
          }
        } else {
          if (inValidEndDateExists) {
            const i = this.errors.indexOf(this.errTexts.InvalidEndDate);
            this.errors.splice(i, 1);
          }
        }

        //When editing, end date is less than the required difference
        const endShortExists = this.errors.some((e) => e === this.errTexts.EndShort);
        const startShortExists = this.errors.some((e) => e === this.errTexts.StartShort);
        if (this.endDateValue.diff(this.startDateValue, 'days') < this.dateDifference) {
          if (!endShortExists) {
            this.errors.push(this.errTexts.EndShort);
          }
        } else {
          if (endShortExists) {
            const i = this.errors.indexOf(this.errTexts.EndShort);
            this.errors.splice(i, 1);        
          }
          if (startShortExists) {
            const j = this.errors.indexOf(this.errTexts.StartShort);
            this.errors.splice(j, 1);
          }
        }
      }
  
  }



  enableDisableQuestions(
    question: AbstractControl,
    enableOrDisable: 'enable' | 'disable'
  ): void {
    if (enableOrDisable === 'enable') {
      question.get('FormQResponses').enable({ emitEvent: false });
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(null, { emitEvent: false });
      question
        .get('FormQResponses.0.UserResponse')
        .reset(null, { emitEvent: false });
    } else if (enableOrDisable === 'disable') {
      question
        .get('FormQResponses.0.ResponsePointValue')
        .reset(0, { emitEvent: false });
      question
        .get('FormQResponses.0.UserResponse')
        .reset('', { emitEvent: false });
    }
  }

  showInfoWithText () {
    this.showHelpText.emit({
      detailsText: this.questionInfo.helpText,
      modalLinks: this.questionInfo.modalLinks,
    });
  }

  setFormType(type: number): void {
    const formTypeControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.FormResponseTypeID`
    );
    formTypeControl.setValue(type);
  }


}
