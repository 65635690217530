<div class="dynamicBody">
  <div class="widgets">
    <ng-container *ngIf="reportComponentId == qualityReportsFeatureId">
      <ng-container *hasFeatureFlag="qualityDashboardFeatureFlag">
        <div class="widget" *hasComponent="qualityDashboardComponent">
          <div class="dynamicBody padding--large">
            <h3 class="margin--none">Quality Dashboard</h3>
            <img class="widget-illustration"
              src="assets/img/illustrations/SH_PEP_Illustrations_Track_QualityDash_1700x1100_20230809.png" />
            <p class="u-italic u-opacity--light margin-top--smaller">
              Track quality measure performance, in real time, using claims and EHR clinical data to pinpoint quality care
              gaps, compare performance against various benchmarks and targets, and drill down to practice and provider
              levels.
            </p>
            <div class="row u-justify-content--center">
              <button class="button" [routerLink]="['dashboard']">Open</button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let item of menuItems$ | async">
      <div *ngIf="item.logComponentID === reportComponentId" class="widget">
        <div class="dynamicBody padding--large">
          <h3 class="margin--none">{{item.menuName}}</h3>
          <img *ngIf="item.menuIllustration" class="widget-illustration"
            src="assets/img/illustrations/{{item.menuIllustration}}" />
          <p class="u-italic u-opacity--light margin-top--smaller" [innerHtml]="item.menuItemDescription"></p>
          <div class="row u-justify-content--center">
            <button class="button" (click)="openReport(item)">Open</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>