import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Access } from '@shared/services/graphql/access.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { HasAccessBase } from './has-access';

/**
 * Only renders element if the user's current access includes ANY of the supplied Component IDs
 *
 * @example - with one component ID
 * <div *hasComponent="trackHccComponentId"></div>
 *
 * @example - will render if has any of the component ids
 * <div *hasComponent="[id1, id2]"></div>
 *
 */
@Directive({
  selector: '[hasComponent]'
})
export class HasComponentDirective extends HasAccessBase {
  constructor(
    protected userAccessService: UserAccessService,
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef
  ) {
    super(userAccessService, templateRef, viewContainer);
  }

  componentIds: Array<number> = [];

  @Input()
  set hasComponent(value: number | Array<number>) {
    if (Array.isArray(value)) {
      this.componentIds = value;
    } else {
      this.componentIds = [value];
    }
    this.updateAccess(this.userAccessService.currentAccess$.value);
  }

  getAccess(access: Access): boolean { 
    if(this.componentIds) {

    for (let id of this.componentIds) {
      if (this.userAccessService.hasComponent(access, id)) {
        return true;

       }
     }
   }
    return false;
  }
}
