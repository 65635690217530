import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TrackComponent } from './track.component';
import { TrackSummaryComponent } from './track-summary/track-summary.component';
import { TrackFinancialUtilizationComponent } from './track-financial-utilization/track-financial-utilization.component';
import { TrackReturnOnInvestmentComponent } from './track-return-on-investment/track-return-on-investment.component';
import { TrackQualityComponent } from './track-quality/track-quality.component';
import { TrackReportComponent } from './track-reports/track-report/track-report.component';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { AuthGuard } from '../auth.guard';
import { TrackTimeTrackingFilterComponent } from './track-clinical/track-time-tracking/track-time-tracking-filter/track-time-tracking-filter.component';
import { TrackTimeTrackingComponent } from './track-clinical/track-time-tracking/track-time-tracking.component';
import { QualityDashboardComponent } from './track-quality/quality-dashboard/quality-dashboard.component';
import { QualityAdminComponent } from './track-quality/quality-admin/quality-admin.component';
const routes: Routes = [
  {
    path: '',
    component: TrackComponent,
    canActivateChild: [AuthGuard],
    data: {
      label: 'Track',
      helpfile: 'LEARN_TRACK_General_2020'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'summary',
      },
      {
        path: 'summary',
        component: TrackSummaryComponent,
        data: {
          label: 'Summary',
        },
      },
      {
        path: 'dashboard',
        component: TrackSummaryComponent,
        data: {
          label: 'Summary',
          helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
        },
      },
      {
        path: 'financial-utilization',
        component: TrackFinancialUtilizationComponent,
        data: {
          label: 'Financial + Utilization',
          featureId: TrackFeatureConstants.FinancialUtilizationFeature.valueOf(), // Causes an error when generating documentation via compodoc unless using .valueOf()
          helpfile: 'LEARN_TRACK_FU_2020',
        },
      },
      {
        path: 'clinical',
        loadChildren: () =>
          import('@track/track-clinical/track-clinical.module').then(
            (m) => m.TrackClinicalModule
          ),
        data: {
          label: 'Clinical',
          featureId: TrackFeatureConstants.ClinicalFeature.valueOf(),
          helpfile: 'LEARN_TRACK_CLINICAL_2020',
        },
      },
      {
        path: 'value-calculator',
        loadChildren: () =>
          import('@track/track-value-calculator/track-value-calculator.module').then(
            (m) => m.TrackValueCalculatorModule
          ),
        data: {
          label: 'Value Calculator',
          featureId: TrackFeatureConstants.ValueCalculatorFeature.valueOf(),
          helpfile: 'LEARN_TRACK_MSSP',
          featureFlag: TrackFeatureConstants.ValueCalculatorFeatureFlag.valueOf()
        },
      },
      {
        path: 'performance',
        loadChildren: () =>
          import('@track/track-performance/track-performance.module').then(
            (m) => m.TrackPerformanceModule
          ),
        data: {
          label: 'Performance Dashboard',
          featureId: TrackFeatureConstants.PerformanceDashboardFeature.valueOf(),
          helpfile: 'LEARN_TRACK_General_2020', // TODO: Update with helpfile when available
          featureFlag: TrackFeatureConstants.PerformanceDashboardFeatureFlag.valueOf()
        },
      },
      {
        path: 'compare',
        loadChildren: () =>
          import('@track/track-compare/track-compare.module').then(
            (m) => m.TrackCompareModule
          ),
        data: {
          label: 'Compare',
          featureId: TrackFeatureConstants.CompareFeature.valueOf(),
          helpfile: 'LEARN_TRACK_General_2020', // TODO: Update with helpfile when available
          featureFlag: TrackFeatureConstants.CompareFeatureFlag.valueOf()
        },
      },
      {
        path: 'quality',
        loadChildren: () =>
          import('@track/track-quality/quality.module').then(
            (m) => m.QualityModule
          ),
          data: {
            featureId: TrackFeatureConstants.QualityFeature.valueOf(),
            helpfile: 'LEARN_TRACK_QualityMeasures_2020',
          }
      },
      {
        path: 'return-on-investment',
        component: TrackReturnOnInvestmentComponent,
        data: {
          label: 'Return on Investment',
        },
      },
      {
        path: 'report/:id/:menuItem/:year/:quarter/:filterType/:filterTypeId',
        component: TrackReportComponent,
        data: {
          label: 'Report',
        },
      },
      {
        path: 'report/:id/:menuItem/:year/:quarter',
        component: TrackReportComponent,
        data: {
          label: 'Report',
        },
      },
      {
        path: 'report/:id/:menuItem',
        component: TrackReportComponent,
        data: {
          label: 'Report',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TrackRoutingModule {}
