import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { SubmitRoutingModule } from './submit-routing.module';
import { SubmitComponent } from './submit.component';
import { SubmitSummaryComponent } from './submit-summary/submit-summary.component';
import { SubmitQualityComponent } from './submit-quality/submit-quality.component';
import { SubmitComplianceComponent } from './submit-compliance/submit-compliance.component';
import { SubmitPromotingInteroperabilityComponent } from './submit-promoting-interoperability/submit-promoting-interoperability.component';

import { PatientFacesheetModule } from '@shared/modules/patient-facesheet/patient-facesheet.module';
import { ComplianceFormQuestionComponent } from './submit-compliance/compliance-form-question/compliance-form-question.component';
import { FormBlockComponent } from './submit-quality/submission-form/form-block/form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SubmitMeetingComponent } from './submit-meeting/submit-meeting.component';
import { ComplianceFilterComponent } from './submit-compliance/compliance-filter/compliance-filter.component';
import { SubmitInteroperabilityQuestionComponent } from './submit-promoting-interoperability/submit-interoperability-question/submit-interoperability-question.component';
import { SubmitInteroperabilitySecurityRiskComponent } from './submit-promoting-interoperability/submit-interoperability-security-risk/submit-interoperability-security-risk.component';
import { SubmitInteroperabilityYesNoComponent } from './submit-promoting-interoperability/submit-interoperability-yes-no/submit-interoperability-yes-no.component';
import { SubmitInteroperabilityRegistryQuestionComponent } from './submit-promoting-interoperability/submit-interoperability-registry-question/submit-interoperability-registry-question.component';
import { CheckboxQuestionComponent } from './shared/components/checkbox-question/checkbox-question.component';

import { AdminFormReport } from './admin-report/shared/admin-report.component';
import { SubmitQualityAdminReportComponent } from './admin-report/submit-quality-report/submit-quality-admin-report.component';
import { SubmitPromotingInteroperabilityAdminReportComponent } from './admin-report/submit-promoting-interoperability-report/submit-promoting-interoperability-admin-report.component';
import { SubmitComplianceAdminReportComponent } from './admin-report/submit-compliance-report/submit-compliance-admin-report.component';
import { AgGridModule } from 'ag-grid-angular';

import {ClickableParentComponent} from './admin-report/shared/clickable.parent.component';
import {ClickableModule} from './admin-report/shared/clickable.module';
import { DetailCellRenderer } from './admin-report/shared/detail-cell-renderer.component';
import { AdminFormReportPractice } from './admin-report/shared/admin-report-practice.component';
import { SubmitInteroperabilityDateRangeComponent } from './submit-promoting-interoperability/submit-interoperability-date-range/submit-interoperability-date-range.component';



@NgModule({
  declarations: [
    SubmitComponent,
    SubmitSummaryComponent,
    SubmitQualityComponent,
    SubmitComplianceComponent,
    SubmitPromotingInteroperabilityComponent,
    ComplianceFormQuestionComponent,
    FormBlockComponent,
    SubmitMeetingComponent,
    ComplianceFilterComponent,
    SubmitInteroperabilityQuestionComponent,
    SubmitInteroperabilitySecurityRiskComponent,
    SubmitInteroperabilityYesNoComponent,
    SubmitInteroperabilityRegistryQuestionComponent,
    CheckboxQuestionComponent,
    AdminFormReport,
    SubmitQualityAdminReportComponent,
    SubmitComplianceAdminReportComponent,
    SubmitPromotingInteroperabilityAdminReportComponent,
    DetailCellRenderer,
    AdminFormReportPractice,
    SubmitInteroperabilityDateRangeComponent
  ],
  imports: [
    SharedModule,
    SubmitRoutingModule,
    PatientFacesheetModule,
    AgGridModule,
    ReactiveFormsModule,    
    ClickableModule
  ],
})
export class SubmitModule {}
