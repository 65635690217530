import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  LOCALE_ID,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { PatientSearchResult } from '@shared/services/patient/patient-search-result';
import { PatientSearchService } from '@shared/services/patient/patient-search.service';
import { GetAgeFromDOB } from '@shared/pipes/age.pipe';
import { MbiFormatPipe } from '@shared/pipes/format-patient-mbi.pipe';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { ModuleConstants } from '@shared/models/module-constants';
import { HasModuleDirective } from '@shared/directives/has-access/has-module.directive';
import { CustomValidators } from '@shared/validators';

@Component({
  selector: 'coach-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  providers: [GetAgeFromDOB, MbiFormatPipe],
})
export class HeaderSearchComponent extends KillSubscriptions implements OnInit {
  constructor(
    private patientSearchService: PatientSearchService,
    private hierarchyTierService: HierarchyTierService,
    private userAccessService: UserAccessService,
    private getAge: GetAgeFromDOB,
    private router: Router,
    private fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super();

  }

  @ViewChild('primarySearchField') primarySearchField: ElementRef;
  public hasCareModule: boolean;
  public selectedTab: string;
  public advancedSearch = false;
  public patientSearchResults: PatientSearchResult[];
  public careModule = ModuleConstants.CareModule;
  private mbiRegex: RegExp = /^[1-9]{1}[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz|^0-9]{1}[0-9]{1}[0-9]{1}$/;
  private dateRegex: RegExp = /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/;
  private nameRegex: RegExp = /(.*[a-z]){3}/i;
  private dateNoSlashes: RegExp = /^([0]?[1-9]|1[0-2])(\d{1,2})(\d{4})$/;

  private minAllowedDate: Date = new Date('1/1/1753')
  private maxAllowedDate: Date = new Date('12/31/9999')
  private allowedDateReg: RegExp = /^(175[3-9]|17[6-9][0-9]|1[89][0-9]{2}|[2-9][0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;


  @Output() patientSelected: EventEmitter<void> = new EventEmitter<void>();
  public patientSearch = this.fb.group({
    searchTerm: ['', [Validators.required, Validators.minLength(3)]],
  });

  public patientAdvancedSearch = this.fb.group({
    name: ['', [Validators.minLength(3)]],
    dob: ['', [Validators.pattern(this.allowedDateReg)]],
    mbi: ['', [Validators.pattern(this.mbiRegex)]],
  });

  public fileSearch = this.fb.group({
    file: ['', [Validators.required, Validators.minLength(1)]],
  });

  ngOnInit(): void {
    this.userAccessService.currentAccess$
      .pipe(takeUntil(this.killTrigger))
      .subscribe((access) => {
        this.hasCareModule = this.userAccessService.hasModule(access, this.careModule);
      });

    this.setTab(this.hasCareModule ? 'patients' : 'files');
    this.focusPrimarySearchInput();

    if (this.patientSearchService.savedSearch.advancedSearch) {
      this.advancedSearch = true;
    }
    if (this.patientSearchService.patientSearchResults?.length > 1) {
      this.patientSearchResults = this.patientSearchService.patientSearchResults;
      this.patientSearch.controls['searchTerm'].setValue(this.patientSearchService.savedSearch.savedSearchTerm);
      this.patientAdvancedSearch.controls['name'].setValue(this.patientSearchService.savedSearch.name);
      this.patientAdvancedSearch.controls['mbi'].setValue(this.patientSearchService.savedSearch.mbi);
      this.patientAdvancedSearch.controls['dob'].setValue(this.patientSearchService.savedSearch.dob);
    }
  }

  setTab(tab: string): void {
    this.selectedTab = tab;
    this.focusPrimarySearchInput();
  }

  toggleAdvancedSearch(): void {
    this.focusPrimarySearchInput();
    this.resetPatientSearch();
  }

  focusPrimarySearchInput(): void {
    setTimeout(() => {
      // this will make the execution after element is rendered in the DOM after change
      this.primarySearchField?.nativeElement.focus();
    }, 0);
  }

  submit(): void {
    if (this.advancedSearch && this.selectedTab === 'patients') {
      this.searchPatient();
    } else if (this.selectedTab === 'patients') {

      this.searchPatient();
    } else if (this.selectedTab === 'files') {
      this.searchFile();
    }
  }

  searchFile(): void {
    this.navigateToFileResults(this.fileSearch.value.file);
  }

  searchPatient(): void {
    if (this.advancedSearch) {
      this.patientSearchService.savedSearch = {
        advancedSearch: true,
        name: this.patientAdvancedSearch.get('name').value ? this.patientAdvancedSearch.get('name').value : '',
        mbi: this.patientAdvancedSearch.get('mbi').value ? this.patientAdvancedSearch.get('mbi').value : '',
        dob: this.patientAdvancedSearch.get('dob').value ? this.patientAdvancedSearch.get('dob').value : ''
      };

      this.patientSearchService
        .search({
          tierId: this.hierarchyTierService.currentTier$.value.selectedTierId,
          tierNum: this.hierarchyTierService.currentTier$.value.tier,
          dateOfBirth: this.patientAdvancedSearch.get('dob').value
            ? new Date(
              this.patientAdvancedSearch.get('dob').value
            ).toISOString()
            : null,

          mbi: this.patientAdvancedSearch.get('mbi').value,
          rowCount: 20,
          nameSearchTerm: this.patientAdvancedSearch.get('name').value,
        })
        .subscribe((results) => {
          if (results.length === 1) {
            const path =
              this.hierarchyTierService.currentTier$.value.abbreviation +
              '/' +
              this.hierarchyTierService.currentTier$.value.selectedTierId +
              '/care/patient/' +
              results[0].chPatID;

            this.router.navigate([path]);

            this.patientSelected.emit();
            return;
          }
          this.patientSearchResults = results;
        });
    } else {
      let mbi: string = null;
      let name: string = null;
      let date: string = null;

      let searchTerm = this.patientSearch.get('searchTerm');
      this.patientSearchService.savedSearch = {
        advancedSearch: false,
        savedSearchTerm: searchTerm.value,
      };
      let matchDate = searchTerm.value.match(this.dateRegex);
      let matchDateNoSlashes = searchTerm.value.match(this.dateNoSlashes);

      if (searchTerm.value.match(this.mbiRegex)) {
        mbi = searchTerm.value;
      } else if (matchDate || matchDateNoSlashes) {
        const checkDate = new Date(searchTerm.value);

        if (checkDate >= this.minAllowedDate && checkDate <= this.maxAllowedDate){
          date = new Date(searchTerm.value).toISOString();
        }

      } else if (searchTerm.value.match(this.nameRegex)) {
        name = searchTerm.value;
      } else {
        this.patientSearchResults = [];
        return;
      }

      this.patientSearchService
        .search({
          tierId: this.hierarchyTierService.currentTier$.value.selectedTierId,
          tierNum: this.hierarchyTierService.currentTier$.value.tier,
          dateOfBirth: date,
          mbi: mbi,
          rowCount: 20,
          nameSearchTerm: name,
        })
        .subscribe((results) => {
          if (results.length === 1) {
            const path =
              this.hierarchyTierService.currentTier$.value.abbreviation +
              '/' +
              this.hierarchyTierService.currentTier$.value.selectedTierId +
              '/care/patient/' +
              results[0].chPatID;
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([path]);
              });
            this.patientSelected.emit();
            return;
          }
          this.patientSearchResults = results;
        });
    }
  }

  selectPatientResult(result): void {
    this.patientSelected.emit();
    const path =
      this.hierarchyTierService.currentTier$.value.abbreviation +
      '/' +
      this.hierarchyTierService.currentTier$.value.selectedTierId +
      '/care/patient/' +
      result.chPatID;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([path]);
    });
  }

  navigateToFileResults(query): void {
    const path =
      this.hierarchyTierService.currentTier$.value.abbreviation +
      '/' +
      this.hierarchyTierService.currentTier$.value.selectedTierId +
      '/improve/search';
    this.router.navigate([path], { queryParams: { query } });
  }

  resetPatientSearch(): void {
    this.patientSearch.reset();
    this.patientAdvancedSearch.reset();
    this.patientSearchService.clearSavedData();
    this.patientSearchResults = null;
  }

  getFormattedPatientDemographicsData(patient): string {
    const dob = new Date(patient.dob).toLocaleDateString();
    const age = this.getAge.transform(patient.dob);
    const gender = patient.gender;

    let html = `
      <span>${formatDate(dob, 'MM/dd/yyyy', this.locale)}</span>
      <span>${age}y</span>
      <span>${gender}</span>
    `;

    return html;
  }

  performingPatientSearch(): boolean {
    return this.selectedTab === 'patients' &&
           this.patientSearchResults != null &&
           this.patientSearchResults.length > 0;
  }
}
