<div class="dropdown u-width--large">
    <div class="dynamicBody padding">
        <ng-container *ngFor="let hierarchy of hierarchyTierService.hierarchyTiers">
            <label class="label margin-top">{{hierarchy.name}}</label>
            <select *ngIf="!anyLoading(hierarchy)" [disabled]="isDisabled(hierarchy)"
                [(ngModel)]="hierarchy.selectedItem" [ngClass]="{'disable-cursor': isDisabled(hierarchy)}"
                (ngModelChange)="hierarchyTierService.tierSelected($event, hierarchy)">
                <option
                    *ngIf="isDisabled(hierarchy) || hierarchy.tier > hierarchy.highestTier"
                    [ngValue]="null">All</option>
                <option *ngFor="let item of hierarchy.items" [ngValue]="item">
                    {{item.tierName}}
                </option>
            </select>
            <div class="select-placeholder loading-indicator u-display--flex" disabled="true"
                *ngIf="anyLoading(hierarchy)">
                <coach-loading-indicator class="u-flex--right"></coach-loading-indicator>
            </div>
        </ng-container>
    </div>
</div>