import { Component } from '@angular/core';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { fileStatus, PatientIHEFileInfo } from '@shared/models/patient-ihe-data';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { PatientService } from '@shared/services/patient/patient.service';
import { GridApi } from 'ag-grid-community/main';
import _ from 'lodash';
import moment from 'moment';
import { PatientViewModel } from '@shared/models/patient';

@Component({
  templateUrl: 'iheFiles.html'
})
export class IheFilesColumnRendererComponent {
  downloadStatus: { [key: string]: 'not downloaded' | 'downloading' | 'downloaded' } = {
    summary: 'not downloaded',
    CMR: 'not downloaded'
  }
  tierId: string;
  tierNum: number;
  chPatId: string;
  files: Array<PatientIHEFileInfo> = [];
  splittedFiles: { [key: string]: PatientIHEFileInfo[] } = {
    CMR: [], // Case Management Referral
    summary: [] // Assessment, PPP, PCP Summary
  };
  CMRFileIds: string[] = [];
  summaryFileIds: string[] = [];
  fullName: string = '';
  user: wpapi.model.Professional;
  gridApi: GridApi = null;
  testObj: any;
  patientData: PatientViewModel;
  emitUpdatingPatientByIdInList: any;

  constructor(private PatientService: PatientService, private authService: AuthService) {
    this.getCurrentUserInfo();
  }
  agInit(params): void {
    this.patientData = params.data;
    this.files = params.data.trackPatient?.fileInfo;
    this.files.forEach(file => {
      if (file.fileType == 'Casemgmt') {
        this.splittedFiles.CMR.push(file);
        this.CMRFileIds.push(file.fileId)
      } else {
        this.splittedFiles.summary.push(file);
        this.summaryFileIds.push(file.fileId)
      }
    })
    this.tierId = params.getTierId();
    this.tierNum = params.getTierNum();
    this.gridApi = params.getGridApi();
    this.chPatId = params.data.chPatId;
    this.fullName = params.data.fullName;
    this.emitUpdatingPatientByIdInList = params.updatePatientByIdInList;
    if (params.data.IHEAssessmentFilesLastDownloaded || this.splittedFiles.summary.filter(f => f.fileDownloadStatus == 'Downloaded').length == this.splittedFiles.summary.length) {
      this.downloadStatus.summary = 'downloaded';
    }
    if (params.data.IHECMRFileLastDownloaded || this.splittedFiles.CMR.filter(f => f.fileDownloadStatus == 'Downloaded').length == this.splittedFiles.CMR.length) {
      this.downloadStatus.CMR = 'downloaded';
    }
  }
  async downloadFile(fileIds: string[], listName: string) {
    this.downloadStatus[listName] = 'downloading';
    let tempDownloadStatus: { [key: string]: fileStatus } = {}

    for (let id in fileIds) {
      let file = this.files.find(file => file.fileId == fileIds[id]);
      if (file) {
        tempDownloadStatus[file.fileId] = _.clone(file.fileDownloadStatus);
        file.fileDownloadStatus = "Downloading";
      }
    }
    try {
      await this.PatientService.downloadIHEFile(fileIds, this.chPatId, this.tierId, this.tierNum, listName == 'summary' ? `Assessment_Files_${this.patientData.firstName}_${this.patientData.lastName}` : `CMR_${this.patientData.firstName}_${this.patientData.lastName}`);
      for (let id in fileIds) {
        let file = this.files.find(file => file.fileId == fileIds[id]);
        if (file) {
          tempDownloadStatus[file.fileId] = _.clone(file.fileDownloadStatus);
          file.fileDownloadStatus = "Downloaded";
          this.updateFileDownloadHistoryInfo(file);
        }
      }
      this.patientData.IHEDownloadDate = moment.utc().toDate();

      let fileInfoDatesResult = PatientViewModel.getLatestFileInfoDates(this.files);
      PatientViewModel.assignLatestFileInfoDates(this.patientData, fileInfoDatesResult)
      this.gridApi.refreshCells();
      this.emitUpdatingPatientByIdInList(this.patientData)
    } catch (error) {
      this.downloadStatus[listName] = 'not downloaded';
      console.error(error);
      for (let id in fileIds) {
        let file = this.files.find(file => file.fileId == fileIds[id]);
        if (file) {
          file.fileDownloadStatus = tempDownloadStatus[file.fileId];
        }
      }
    }
    this.downloadStatus[listName] = 'downloaded';
  }
  getIconName(listStatus: string): string {
    switch (listStatus) {
      case 'downloaded':
        return 'check-circle-2';
      case 'not downloaded':
        return 'arrow-down-circle-filled';
      case 'downloading':
        return 'check-circle-2';
    }
  }
  updateFileDownloadHistoryInfo(file: PatientIHEFileInfo): void {
    if (file.downloadInfo.current) {
      if (!file.downloadInfo.history) {
        file.downloadInfo.history = []
      }
      file.downloadInfo.history.push(file.downloadInfo.current)
    }
    file.downloadInfo.current = {
      downloadedAtUtc: moment.utc().toString(),
      downloadedByName: this.user.firstName + " " + this.user.lastName,
      downloadedByUserId: null
    };
  }
  async getCurrentUserInfo() {
    this.user = await this.authService.getWellpepperUserInfo()
  }
}

