import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { OrgService } from '@shared/services/org-service/org.service';
import { Patient } from '@shared/models/patient';
import { PatientService, ITrackApiAwvPatient, EpisodeOfCareProtocolAssignmentViewModel } from '@shared/services/patient/patient.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import _ from 'lodash';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { faUser, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { common } from '@hcd-caravanhealth/pkg-wptypes';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { Activity } from '@shared/models/activity';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { Protocol } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/protocol';
import { Procedure } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/organization';
import { PatientIdentifiersIssuers } from '@shared/models/module-constants';
import { PatientEngagement } from '@shared/enums';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'coach-patient-invite-form',
  templateUrl: './patient-invite-form.component.html',
  styleUrls: ['./patient-invite-form.component.scss'],
})
export class PatientInviteFormComponent implements OnInit, OnChanges {
  @Input() public patient;
  @Input() public patients;
  @Output() public closeForm: EventEmitter<any> = new EventEmitter();
  @Output() public patientInvited: EventEmitter<{ _id: string }>;
  @Input() tier: IHierarchyTier;

  isModuleSelectorIsOpen: boolean;
  bulkInviteStatus: { message: string; counter: number };
  patientClone: Patient & {
    inviteStatus?: { loading: number; message: string };
  } = null;
  orgData: any = null;
  selectHolder: any = {
    primaryProfessional: {},
    newProtocol: {},
    newModules: [],
    newProcedure: {
      allowedProtocols: [],
    },
    extendedFields: {},
  };
  protocols: any = [];
  protocolsById: any = {};
  modulesById: any = {};
  professionals: wpapi.model.Professional[] = null;
  professionalsById: any = {};
  inviteStatus: any = {
    loading: 0, // 0 - no activity, 1 - loading, 2 - success, -1 - failed
    message: '',
  };
  welcomeMail: any = {};
  orgId: string = null;
  formStep: 1 | 2 | 3 = 1;
  private emitter: any;
  selectedProtocol: string = null;
  selectedProcedure: string = null;
  faUser = faUser;
  faFileAlt = faFileAlt;
  bulkPatientsProfessionalId: string;
  isPatientInvited: boolean = false;
  assignedProcedures: Procedure[];
  smsEnrollmentAgreed: boolean = false;
  private currentUser: wpapi.model.Professional;
  PatientEngagement = PatientEngagement;

  constructor(
    private OrgService: OrgService,
    private PatientService: PatientService,
    private auditService: AuditService,
    private ToastrMessageService: ToastrMessageService,
    private AuthService: AuthService,
    private toast: ToastrService,
  ) {
    this.getCurrentUserInfo();
  }

  // Note: Angular doesn't wait if you mark ngOnInit async
  ngOnInit() {
    this.getAndStoreProtocolsForOrg();
    this.loadPatientInviteDetailsForOrg();
    if (this.patients) {
      this.patients.forEach((p) => {
        if (p.dob) {
          p.dob = moment(p.dob).format('MM/DD/YYYY');
        }
      });
      this.bulkInviteStatus = {
        message: '',
        counter: 0,
      };
    }
    this.isPatientInvited =
      this.patient?.carePatient?.accountType === 'patient';
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectHolder = {
      primaryProfessional: {},
      newProtocol: {},
      newModules: [],
      newProcedure: {
        allowedProtocols: [],
      },
      extendedFields: {},
    };
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.patient &&
      changes.patient.currentValue &&
      changes.patient.currentValue != changes.patient.previousValue
    ) {
      this.patientClone = _.cloneDeep(changes.patient.currentValue);
    }
  }
  close(action_target: string): void {
    this.closeForm.emit(action_target);
  }
  // ======== DATA GETTERS ============

  private async getCurrentUserInfo() {
    this.currentUser = await this.AuthService.getWellpepperUserInfo();
  }

  async getAndStoreProtocolsForOrg() {
    if(!this.orgId) {
      this.orgId = await this.PatientService.getWpOrgIdFromTierId(
        this.tier?.selectedTierId
      );
    }
    let protocols: any = await this.OrgService.getProtocolsForOrg(this.orgId);
    protocols.forEach((p) => {
      this.protocolsById[p._id] = p;
      this.storeModuleNames(p.modules);
    });
  };
  async loadPatientInviteDetailsForOrg() {
    if(!this.orgId) {
      this.orgId = await this.PatientService.getWpOrgIdFromTierId(
        this.tier?.selectedTierId
      );
    }

    return Promise.all([
      this.getProfessionalsForOrg(this.orgId),
      this.OrgService.getOrg(this.orgId)
        .then(org=>
          { this.orgData=org;
        })
    ]);
  }
  async getProfessionalsForOrg(orgId) {
    this.professionals = await this.OrgService.getProfessionalsForOrg(orgId);
    this.professionals = this.professionals.filter(
      (p) =>
        p.elegibleRoles?.filter((er) => er == 'primaryProfessional').length > 0
    );
    this.professionals.forEach((p) => {
      this.professionalsById[p._id] = p;
    });
  }
  storeModuleNames(modules) {
    if (modules) {
      modules.forEach(async (m) => {
        this.modulesById[m.protocolId] = await this.OrgService.getModuleName(
          m.protocolId
        );
      });
    }
  }

  // ========= RE-INVITE =============
  async reinvite(
    patient: Patient & { inviteStatus?: { loading: number; message: string } }
  ) {
    /**
     * @steps
    * 1. check whether org or protocol have inviteEmail
     * 2. Update patient's password
     * 3. Send invitation
     */

     let activeEpisodesOfCare = await this.PatientService.getPatientEpisodesOfCare(this.tier?.selectedTierId, this.patient);

    if (!patient.inviteStatus) {
      patient.inviteStatus = {
        loading: null,
        message: null,
      };
    }
    patient.inviteStatus.loading = 1;
    this.welcomeMail = Object.assign(
      { variables: {} },
      defaultWelcomeMail,
      this.orgData?.invitations?.welcomeMail,
      { shouldSend: false }
    );
    let newPassword = this.genRandomPassword();
    let wpUpdate = await this.PatientService.updateCarePatient(
      patient.carePatient._id,
      {
        password: newPassword,
      }
    );
    if (!wpUpdate) {
      patient.inviteStatus.loading = -1;
      patient.inviteStatus.message = "Can't send invitation";
      return;
    }
    this.welcomeMail.variables.password = newPassword;
    this.welcomeMail.sender = patient.professionalId;
    let addNotificationForPatient =
      await this.PatientService.addNotificationForPatient(
        patient.carePatient._id,
        this.welcomeMail
      );
    if(this.patient.notifications){
      this.patient.notifications.push(addNotificationForPatient);
    }
    let userId = this.patient.carePatient._id;
    let taskList = await this.PatientService.getTasks(this.tier, this.patient);
    let taskId = "";
    let userHasSeenSMSOptOut = false;
    for (const task of taskList ){
      if (task.userId == userId && task.type == 'twilioStudioFlow'){
        taskId = task._id;
      }
    }
    let triggerTwilioFlowForPatient = await this.PatientService.triggerTwilioFlowForPatient(this.orgId, userId, taskId, userHasSeenSMSOptOut);

    if (!addNotificationForPatient) {
      patient.inviteStatus.loading = -1;
      patient.inviteStatus.message = "Can't send invitation";
      return false;
    } else {
      patient.inviteStatus.loading = 2;
      patient.inviteStatus.message = 'Successfully sent invitation';
      return true;
    }
  }

  // ========= CREATE A NEW PATIENT =============
  async submit(
    patient: Patient & {
      inviteStatus?: { loading: number; message: string };
      professionalName?: string;
    } & {
      trackAwvPatient?: ITrackApiAwvPatient;
    },
    professionalId: string
  ) {
    if (!patient.inviteStatus) {
      patient.inviteStatus = {
        loading: null,
        message: null,
      };
    }
    patient.inviteStatus.loading = 1;
    patient.inviteStatus.message = 'Storing patient';

    let patientResult = await this.createUser(patient, professionalId);
    if (!patientResult) {
      patient.inviteStatus.loading = -1;

      if(!patient.inviteStatus.message)
      {

        patient.inviteStatus.message =
        patientResult?.error?.message || "Can't create user";
      }
      return;
    }
    let assignedProceduresAndProtocolsResult =
      await this.assignProceduresAndProtocols(
        patient,
        professionalId,
        patientResult
      );
    if (!assignedProceduresAndProtocolsResult) {
      return;
    }

    /*
        // SMS is sent automatically from the backend
        if (this.emailAndSmsInvitationEligible.email) {
          await this.sendEmailInvite(patient, professionalId, patientResult);

      }
    */

    if(this.smsEnrollmentAgreed){

      let enrolledinSMSby = `${
        this.currentUser.lastName && this.currentUser.firstName
          ? ` ${this.currentUser.firstName} ${this.currentUser.lastName} `
          : `${this.currentUser.email}`
      }`;
      let invitedtoSMS: Activity = {
        type: 'invitation',
        date: new Date().toString(),
        title: 'Enrolled in SMS by ' + enrolledinSMSby,
        description: null,
        detail: null,
      };
      this.patient.activities.push(invitedtoSMS);
      this.patient.activities.sort((a, b) => {
        if (moment(a.createdAt).isAfter(moment(b.createdAt))){
          return -1;
        }
        else if (moment(a.createdAt).isSame(moment(b.createdAt))){
          return 0;
        }
        else{
         return 1;
        }
      });
    }
      patient.inviteStatus.loading = 2;
      patient.inviteStatus.message =
        'Successfully invited and sent notification';
      let invitedAct: Activity = {
        type: 'invitation',
        date: new Date().toString(),
        title: 'Invited',
        description: null,
        detail: null,
      };
      this.patient.activities.push(invitedAct);
      return true;


  }

  async createUser(
    patient: Patient & {
      inviteStatus?: { loading: number; message: string };
      professionalName?: string;
    } & {
      trackAwvPatient?: ITrackApiAwvPatient;
    },
    professionalId: string
  ): Promise<any> {
    let date = new Date();
    // TODO - Add a "PatientCreate" type in wpapi.model

    const orgMappedTierId =
      await this.PatientService.getWpMappedTierIdFromTierId(
        this.tier?.selectedTierId
      );
    const orgMappedTierNum =
      await this.PatientService.getWpMappedTierNumFromTierId(
        this.tier?.selectedTierId
      );
    let patientDetails: wpapi.model.Patient &
      common.PermissionedObject & {
        password: string;
        requiresPasswordReset: boolean;
      } = {
      ACL: {
        '57980332bba6670e7409e9e5': { read: true, write: true },
        [this.orgId]: { read: true, write: true },
        [professionalId]: { read: true, write: true },
      },
      accountType: 'patient',
      currentTimeZoneName:
        patient.currentTimeZoneName ||
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      currentTimeZoneOffsetSec:
        patient.currentTimeZoneOffsetSec || date.getTimezoneOffset() * -60,
      dob:
        patient.dob ||
        patient.trackPatient?.dob ||
        patient.trackAwvPatient?.dob, // trackAwvPatient
      email:
        patient.email ||
        patient.trackPatient?.email ||
        patient.trackAwvPatient?.email,
      firstName: patient.firstName,
      gender: this.getGenderFromString(
        patient.gender ||
          patient.trackPatient?.gender ||
          patient.trackAwvPatient?.gender
      ),
      lastName: patient.lastName,
      mbi:
        patient.mbi ||
        patient.trackPatient?.mbi ||
        patient.trackAwvPatient?.mbi,
      mrn:
        patient.mrn
        // Track API doesn't return a MRN afaik
        /* ||
        patient.trackPatient?.mrn ||
        patient.trackAwvPatient?.mrn,
        */,

      nickName:
        patient.nickName?.trim() ||
        patient.trackPatient?.preferredName ||
        patient.trackAwvPatient?.preferredName ||
        '',
      // CC-3242 would like to generate the initial password on the server side
      // but this doesn't work because the API currently doesn't integrate sending a
      // the invitation email with temporary password.
      // The password is generated but then the cleartext value is thrown away.
      // hints: {
      //   generatePassword: true
      // },
      // Leaving the password generation here
      password: this.genRandomPassword(),
      requiresPasswordReset: true,
      phoneNumber: patient.phoneNumber?.replace(/\D+/g, '') || '',
      professionalId: professionalId,
      userMustConfirmDetails: true,
      // CC-1727 - username may have been pre-generated, don't use it for an email address which would have been collected in this form.
      username:
        patient.email ||
        patient.trackPatient?.email ||
        patient.trackAwvPatient?.email,
      identifiers: [
        // Don't save chPatId as an identifier - it's not going to be unique
        {
          type: 'custom',
          issuer: environment.patientIdentityProvider.issuer,
          subject: environment.patientIdentityProvider.formatSubject(
            orgMappedTierId,
            patient.chPatId
          ),
          createdAt: new Date().toDateString(),
          provider: 'custom'
        }
      ],
    };


    if(this.emailAndSmsInvitationEligible.sms) {
    try {
      if(this.smsEnrollmentAgreed){
        let enrolledinSMSby = `${
          this.currentUser.lastName && this.currentUser.firstName
            ? ` ${this.currentUser.firstName} ${this.currentUser.lastName} `
            : `${this.currentUser.email}`
        }`;

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        patientDetails.verbalConsent =  {
          acceptedBy: this.currentUser._id,
          acceptedByName: enrolledinSMSby,
          acceptedByEulaId: 'ObjectId("639b9ced9ebf57f25b5da44a")', /* Hardcoded for now */
          acceptedDate: mm + '/' + dd + '/' + yyyy,
        };
      }
    }
    catch(e){
      console.log('SMS Enrollment Error:' , e);
    }

    }

    if(this.emailAndSmsInvitationEligible.sms) {
      patientDetails.identifiers.push({
        issuer: PatientIdentifiersIssuers.SmsCarePlanPhoneNumber,
        type: 'custom',
        subject: this.PatientService.formatPhoneNumberForTwilio(patient.phoneNumber),
        createdAt: new Date().toDateString(),
        provider: 'custom',
        canBeUpdated: true
      })
    }
    if (patient?.carePatient?._id) {
      patientDetails._id = patient.carePatient._id;
    }
    let patientResult: any = await this.PatientService.createUser(
      patientDetails
    );
    if (!patientResult || patientResult.error) {
    //Temporary placeholder only, need to pre-check the dusplicate phone number/email in an earlier screen in next story.
      if(patientResult.status === 409 && this.smsEnrollmentAgreed){
        const isEmailAndSmsSubscribed = Object.values(this.protocolsById).filter((r:any)=> r.isSelected).length > 1;
        patient.inviteStatus.message =
         `This phone number ${isEmailAndSmsSubscribed ? 'or email' : ''} is already in use, please ${isEmailAndSmsSubscribed ? '' : 'update phone number or '}contact Signify Health for help`

      }
      return false;
    } else if (this.patientClone) {
      // Using Object.assign generates errors when assigning to read-only properties
      this.patient.refreshFromCareAndTrackPatient(
        patientResult,
        this.patientClone.trackPatient
      ); // Object.assign(patient, patientResult)
      this.patient.professional = await this.PatientService.getProfessional(
        patientResult.professionalId
      );
      this.patient.phoneNumber = Patient.formatPhoneNumber(
        this.patient.phoneNumber
      );
    } else {
      patient.carePatient = patientResult;
      patient.professionalName = (
        await this.PatientService.getProfessional(patientResult.professionalId)
      ).firstName;
    }

    await this.PatientService.updateTrackPatient(
      orgMappedTierId,
      orgMappedTierNum,
      {
        email: patientDetails.email,
        preferredName: patientDetails.nickName || '',
        phone: patientDetails.phoneNumber,
        tierID: orgMappedTierId,
        tierNum: orgMappedTierNum,
        carePatientId: patientResult._id,
        mbi: patientDetails.mbi,
        firstName: patient.firstName,
        lastName: patient.lastName,
        middleName: patient.middleName,
        dateOfBirth: patientDetails.dob,
        gender: patientDetails.gender?.toUpperCase()?.[0],
        chPatId: Number(patient.chPatId),
        ChPatID: Number(patient.chPatId),
        supplementalId:
          patient.supplementalId || patient.trackPatient?.supplementalID,
      }
    );

    this.auditService.auditLog(
      'Invite Patient',
      PatientEngagement.PatientApp,
      SubComponentId.Invitations,
      ActionId.Insert,
      patient.chPatId,
      {
        tier: orgMappedTierNum,
        selectedTierId: orgMappedTierId,
      } as IHierarchyTier,
      null
    );
    patientResult.password = patientDetails.password;
    this.patient.phoneNumber = patientDetails.phoneNumber;
    return patientResult;
  }
  async assignProceduresAndProtocols(
    patient: Patient & {
      inviteStatus?: { loading: number; message: string };
      professionalName?: string;
    } & {
      trackAwvPatient?: ITrackApiAwvPatient;
    },
    professionalId: string,
    patientResult: any
  ): Promise<boolean> {
    for (let p in this.orgData.availableProcedures) {
      if (this.orgData.availableProcedures[p].isSelected) {
        patient.inviteStatus.message = `Assigning procedure ${this.orgData.availableProcedures[p].title}`;
        // assignedProcedures
        var episodeOfCare: any = { isActive: true };
        if (this.orgData.hasProcedures) {
          var newProcedure = this.orgData.availableProcedures[p];
          episodeOfCare.procedureName = newProcedure
            ? newProcedure.name
            : 'Procedure';
          if (newProcedure && newProcedure.shortName) {
            episodeOfCare.procedureShortName = newProcedure.shortName;
          }
          if (newProcedure && newProcedure.extendedFields) {
            episodeOfCare.extendedFields = newProcedure.extendedFields;
          }
        } else {
          episodeOfCare = {
            isActive: true,
            procedureName: 'Surgery',
            procedureShortName: 'Surgery',
          };
        }
        episodeOfCare.procedureTimeZoneName = professionalId
          ? this.professionalsById[professionalId].currentTimeZoneName
          : patient.currentTimeZoneName ||
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        let assignedEpisodeOfCare =
          await this.PatientService.assignEpisodeOfCare({
            episodeOfCare: episodeOfCare,
            patient: patientResult,
          });
        if (!assignedEpisodeOfCare) {
          patient.inviteStatus.loading = -1;
          patient.inviteStatus.message = "Can't assign episode of care";
          return false;
        }
        // =================== ASSIGNING PROTOCOLS ===================
        for (let p2 in this.orgData.availableProcedures[p].allowedProtocols) {
          let prot = this.orgData.availableProcedures[p].allowedProtocols[p2];
          if (this.protocolsById[prot]?.isSelected) {
            patient.inviteStatus.message = `Assigning protocol ${this.protocolsById[prot].name}`;
            let modules = {};
            this.protocolsById[prot]?.modules?.forEach((m) => {
              if (m.isActive) {
                modules[m['protocolId']] = {
                  isActive: true,
                };
              }
            });
            let assignedProtocol = await this.PatientService.assignProtocol({
              protocol: this.protocolsById[prot],
              patient: patientResult,
              modules: modules,
              episodeOfCare: assignedEpisodeOfCare,
            });
            if (this.doesProtocolHaveTwilioFlowTask(this.protocolsById[prot])){
              let userId = this.patient.carePatient._id;
              let taskId = '';
              let userHasSeenSMSOptOut = false;
              let taskList : any = assignedProtocol.tasks;
              for (const task of taskList ){
                if (task.userId == userId && task.type == 'twilioStudioFlow'){
                  taskId = task._id;
                }
              }
              let triggerTwilioFlowForPatient = await this.PatientService.triggerTwilioFlowForPatient(this.orgId, userId, taskId, userHasSeenSMSOptOut);
              if(triggerTwilioFlowForPatient){
                this.toast.success('Invitation for Daily Monitoring Program is sent. It may take a few minutes for delivery.');
              }
            }
            if (!assignedProtocol) {
              patient.inviteStatus.loading = -1;
              patient.inviteStatus.message = "Can't assign protocol";
              return false;
            }
          }
        }
      }
    }
    return true;
  }
  async sendEmailInvite(
    patient: Patient & {
      inviteStatus?: { loading: number; message: string };
      professionalName?: string;
    } & {
      trackAwvPatient?: ITrackApiAwvPatient;
    },
    professionalId: string,
    patientResult: any
  ): Promise<boolean> {

    this.welcomeMail = Object.assign(
      { variables: {} },
      defaultWelcomeMail,
      this.orgData?.invitations?.welcomeMail,
      { shouldSend: true }
    );
    if(!this.welcomeMail.text){
      patient.inviteStatus.message = 'Successfully invited. Notification process is skipped. Organization doesn\'t have the invite text.';
      return true;
    }
    this.welcomeMail.variables.password = patientResult.password;
    this.welcomeMail.sender = professionalId;
    let addNotificationForPatient = await this.PatientService.addNotificationForPatient(patientResult._id, this.welcomeMail);

    if (!addNotificationForPatient) {
      patient.inviteStatus.loading = -1;
      patient.inviteStatus.message = "Can't send notification, but patient was successfully added";
    }else {
      patient.inviteStatus.loading = 2;
      patient.inviteStatus.message =
        'Successfully invited and sent notification';
      let invitedAct: Activity = {
        type: 'invitation',
        date: new Date().toString(),
        title: 'Invited',
        description: null,
        detail: null,
      };
      this.patient.activities.push(invitedAct);
      return true;
    }
  };

  switchPage(num: number): void {
    this.formStep += num;
  }
  public toggleModuleSelectorOpen(protocolId?: string): void {
    for (let p_Id in this.protocolsById) {
      if (this.protocolsById[p_Id]) {
        this.protocolsById[p_Id].isModuleSelectorIsOpen =
          p_Id == protocolId && !this.protocolsById[p_Id].isModuleSelectorIsOpen
            ? true
            : false;
      }
    }
  }
  selectProtocol(procedure: any, protocolId: string): void {
    if (this.protocolsById[protocolId]) {
      this.protocolsById[protocolId].isSelected = this.protocolsById[protocolId]
        .isSelected
        ? false
        : true;
    }
    procedure.isSelected =
      procedure.allowedProtocols.filter(
        (protocol) => this.protocolsById[protocol]?.isSelected
      ).length > 0
        ? true
        : false;
  }
  checkProcedureAndProtocol(procedure: any, protocolId: string): void {
    if (
      this.protocolsById[protocolId] &&
      this.getSelectedModules(protocolId).length > 0
    ) {
      this.protocolsById[protocolId].isSelected = true;
      procedure.isSelected =
        procedure.allowedProtocols.filter(
          (protocol) => this.protocolsById[protocol]?.isSelected
        ).length > 0
          ? true
          : false;
    }
  }
  getSelectedModules(protocolId: string): any[] {
    return this.protocolsById[protocolId]?.modules
      ? this.protocolsById[protocolId].modules.filter((m) => m.isActive)
      : [];
  }
  public getSelectedProceduresLength(): number {
    // Add more rules if needed
    return this.orgData.availableProcedures.filter(
      (procedure) => procedure.isSelected
    ).length;
  }
  isPatientValid(): boolean {
    return (
      this.patientClone.professionalId?.length > 0 &&
      this.patientClone.gender?.length > 0 &&
      this.isInputValid(this.patientClone, 'phoneNumber') &&
      this.isInputValid(this.patientClone, 'email') &&
      this.patientClone.email?.length > 1
    );
  }
  isInputValid(patient: Patient, target: string): boolean {
    switch(target){
      case 'email':
        return this.PatientService.isEmailFormatValid(patient[target])
      case 'nickName':
        return patient[target].length > 0
      case 'phoneNumber':
        return this.PatientService.isPhoneNumberFormatValid(patient[target])
    }
  }
  doesProtocolHaveTwilioFlowTask(protocol: Protocol | EpisodeOfCareProtocolAssignmentViewModel): boolean {
    return !!protocol.tasks?.find(t => t.type == "twilioStudioFlow");
  }

  get isSMSPatient(): boolean {
    let result = false
    const smsTask = this.patient.tasks.find(task => task.isActive && task.type == "twilioStudioFlow")
    
    if(smsTask)
      return true;
    else
      return false;
  }
  /**
   * Check whether selected protocol will send an SMS or email invitation
   */
  get emailAndSmsInvitationEligible(): { email: boolean, sms: boolean } {
    let result = { email: !!this.orgData?.invitations?.welcomeMail, sms: false };
    for (let procedure in this.orgData?.availableProcedures) {
      if (this.orgData.availableProcedures[procedure].isSelected) {
        for (let allowedProtocolId in this.orgData.availableProcedures[procedure].allowedProtocols) {
          if (result.email && result.sms) return result;
          const prot = this.orgData.availableProcedures[procedure].allowedProtocols[allowedProtocolId];
          if (this.protocolsById[prot]?.isSelected) {
            if (this.doesProtocolHaveTwilioFlowTask(this.protocolsById[prot])) {
              result.sms = true
            } else if (!result.email) {
              result.email = !!this.protocolsById[prot].invitations?.welcomeMail; // if org invite is missing, then check protocol invite
            }
          }
        }
      }
    };
    return result;
  }



  async test() {
    let result = { email: !!this.orgData?.invitations?.welcomeMail, sms: false };

    console.log("result =>", result)
    return result;
  }
  // ========= BULK INVITE =============
  isPatientValidForInvitation(
    patient: Patient & { inviteStatus?: any; careId?: string }
  ): boolean {
    return (
      (!patient.carePatient || patient.inviteStatus) &&
      this.isInputValid(patient, 'email') &&
      this.isInputValid(patient, 'phoneNumber') &&
      (patient.dob?.length > 0 || patient.dob?.length > 0) &&
      patient.firstName?.length > 0 &&
      patient.lastName?.length > 0 &&
      !patient.careId
    );
  }
  getValidPatientsLength(): number {
    return this.patients.filter((p) => this.isPatientValidForInvitation(p))
      .length;
  }
  checkAllPatientsInvitedStatus(loadingCode: number): number {
    return this.patients.filter(
      (p) =>
        this.isPatientValidForInvitation(p) &&
        p.inviteStatus?.loading == loadingCode
    ).length;
  }
  async submitMultiplePatients() {
    this.bulkInviteStatus.counter = 0;
    this.patients.forEach((patient) => {
      if (this.isPatientValidForInvitation(patient)) {
        this.submit(patient, this.bulkPatientsProfessionalId);
      }
      this.bulkInviteStatus.counter++;
    });
    this.bulkInviteStatus.message = 'done';
  }
  // ========= PASSWORD GENERATOR =========
  getRandomNum(lbound: number, ubound: number): number {
    'use strict';

    // CC-3242 use Crypto.getRandomValues instead of Math.random
    const arr = new Uint32Array(1);
    crypto.getRandomValues(arr);
    const val = arr[0].valueOf()/Math.pow(2,32);
    return Math.floor(val * (ubound - lbound)) + lbound;
  }

  getRandomChar(number, lower, upper, other, extra): string {
    'use strict';
    var numberChars = '23456789';
    var lowerChars = 'abcdefghijkmnpqrstuvwxyz';
    var upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var otherChars = '`~!@#$%^&*()-_=+[{]}\\|;:\'",<.>/? ';
    var charSet = extra;
    if (number === true) {
      charSet += numberChars;
    }
    if (lower === true) {
      charSet += lowerChars;
    }
    if (upper === true) {
      charSet += upperChars;
    }
    if (other === true) {
      charSet += otherChars;
    }
    return charSet.charAt(this.getRandomNum(0, charSet.length));
  }

  genPassword(
    length,
    extraChars,
    firstNumber,
    firstLower,
    firstUpper,
    firstOther,
    latterNumber,
    latterLower,
    latterUpper,
    latterOther
  ): string {
    'use strict';

    var rc = '';
    if (length > 0) {
      rc =
        rc +
        this.getRandomChar(
          firstNumber,
          firstLower,
          firstUpper,
          firstOther,
          extraChars
        );
    }
    // this enforces that the password satisfies the (current) wp password complexity criteria
    if (latterNumber && !firstNumber) {
      rc = rc + this.getRandomChar(true, false, false, false, extraChars);
    }
    if (latterLower && !firstLower) {
      rc = rc + this.getRandomChar(false, true, false, false, extraChars);
    }
    if (latterUpper && !firstUpper) {
      rc = rc + this.getRandomChar(false, false, true, false, extraChars);
    }
    if (latterOther && !firstOther) {
      rc = rc + this.getRandomChar(false, false, false, true, extraChars);
    }
    for (var idx = rc.length; idx < length; ++idx) {
      rc =
        rc +
        this.getRandomChar(
          latterNumber,
          latterLower,
          latterUpper,
          latterOther,
          extraChars
        );
    }
    return rc;
  }

  getGenderFromString(s: string): common.UserGender {
    if (!s || s.length < 1) {
      return 'unknown';
    }

    switch (s[0].toLowerCase()) {
      case 'm':
        return 'male';
      case 'f':
        return 'female';
      case 'o':
        return 'other';
      default:
        return 'unknown';
    }
  }

  genRandomPassword(): string {
    // randomly start with a letter or number
    var firstNum = this.getRandomNum(0, 2) == 1;
    return this.genPassword(
      8, //length
      '', //extraChars,
      firstNum, // firstNumber,
      !firstNum, // firstLower,
      false, // firstUpper,
      false, // firstOther,
      true, // latterNumber,
      true, // latterLower,
      false, // latterUpper,
      false
    ); //latterOther)
  }
}

const defaultWelcomeMail = {
  method: 'email',
  subject: 'Welcome to Wellpepper',
  text: `Dear {{recipient.firstName}},

We get it - when you have health concerns, you want answers fast.
That’s why our staff here at {{sender.organizationName}} are using an app called Wellpepper to communicate with our patients

This free app lets you:
\t• Send/receive messages with your clinical team
\t• Take surveys related to your health
\t• Get education on your specific health conditions
\t• Track and share your vitals
\t• And much more!

Still have questions? Our nurses can help you understand the app and any privacy concerns you may have.

To get started, download the app and sign in with your user name and temporary password provided below.

for iOS (iPhone, iPad): https://wppr.co/ios
for Android: https://wppr.co/android
or visit our web portal: {{link_patientweb}}
If the above link doesn’t work for you, please visit https://patient.wellpepper.com and enter in your username and temporary password.

Your username: {{recipient.username}}
nYour temporary password: {{password}}

We look forward to hearing from you.

Your {{sender.organizationName}} doctors and nurses
`,
  html: `
<!DOCTYPE html>
<html>

<head>
  <title>Wellpepper Invitation</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <style type="text/css">
    /* CLIENT-SPECIFIC STYLES */
    body,
    table,
    td,
    a {
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    /* Prevent WebKit and Windows mobile changing default text sizes */
    table,
    td {
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    /* Remove spacing between tables in Outlook 2007 and up */
    img {
      -ms-interpolation-mode: bicubic;
    }

    /* Allow smoother rendering of resized image in Internet Explorer */
    /* RESET STYLES */
    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
    }

    table {
      border-collapse: collapse !important;
    }

    body {
      height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
    }

    /* iOS BLUE LINKS */
    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    /* MOBILE STYLES */
    @media screen and (max-width: 525px) {

      /* ALLOWS FOR FLUID TABLES */
      .wrapper {
        width: 100% !important;
        max-width: 100% !important;
      }

      /* ADJUSTS LAYOUT OF LOGO IMAGE */
      .logo img {
        margin: 0 auto !important;
      }

      /* USE THESE CLASSES TO HIDE CONTENT ON MOBILE */
      .mobile-hide {
        display: none !important;
      }

      .img-max {
        max-width: 100% !important;
        width: 100% !important;
        height: auto !important;
      }

      /* FULL-WIDTH TABLES */
      .responsive-table {
        width: 100% !important;
      }

      /* UTILITY CLASSES FOR ADJUSTING PADDING ON MOBILE */
      .padding {
        padding: 10px 5% 15px 5% !important;
      }

      .padding-meta {
        padding: 30px 5% 0px 5% !important;
        text-align: center;
      }

      .no-padding {
        padding: 0 !important;
      }

      .section-padding {
        padding: 50px 15px 50px 15px !important;
      }

      /* ADJUST BUTTONS ON MOBILE */
      .mobile-button-container {
        margin: 0 auto;
        width: 100% !important;
      }

      .mobile-button {
        padding: 15px !important;
        border: 0 !important;
        font-size: 16px !important;
        display: block !important;
      }
    }

    /* ANDROID CENTER FIX */
    div[style*="margin: 16px 0;"] {
      margin: 0 !important;
    }
  </style>
</head>

<body style="margin: 0 !important; padding: 0 !important;">
  <!-- HIDDEN PREHEADER TEXT -->
  <div style="display: none; font-size: 1px; color: #fefefe; line-height: 1px; font-family: Helvetica, Arial,
    sans-serif; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden;">Staying connected at home</div>
  <!-- HEADER -->
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
    <tr>
      <td bgcolor="#ffffff" align="center">
        <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="center" valign="top" width="500"><![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 500px;" class="wrapper">
          <tr>
            <td align="center" valign="top" style="padding: 15px 0;" class="logo"><a href="#" target="_blank"><img
                  alt="Logo"
                  src="https://s3.amazonaws.com/content.wellpepper.com/{{sender.organization}}/logo_landscape.png"
                  height="120" style="display: block; font-family: Helvetica, Arial, sans-serif; color: #ffffff;
                  font-size: 16px; height: 120px;" border="0"></a></td>
          </tr>
        </table>
        <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
      </td>
    </tr>
    <tr>
      <td bgcolor="#ffffff" align="center" style="padding: 40px 15px 70px 15px;" class="section-padding">
        <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="center" valign="top" width="500"><![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 500px;"
          class="responsive-table">
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <!-- COPY -->
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td style="font-size: 16px; font-family: Helvetica, Arial, sans-serif; color: #333333;"
                          class="padding">Dear {{recipient.firstName}},</td>
                      </tr>
                      <tr>
                        <td style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica,
                          Arial, sans-serif; color: #666666;" class="padding">We get it – when you have health
                          concerns, you want answers <strong>fast</strong>.</td>
                      </tr>
                      <tr>
                        <td style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica,
                          Arial, sans-serif; color: #666666;" class="padding">That’s why our staff here at
                          {{sender.organizationName}} are using an app called <strong><em>Wellpepper</em></strong> to
                          communicate with our patients</td>
                      </tr>
                      <tr>
                        <td style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica,
                          Arial, sans-serif; color: #666666;" class="padding">This free app lets you:</td>
                      </tr>
                      <tr>
                        <td style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica,
                          Arial, sans-serif; color: #666666;" class="padding">
                          <ul>
                            <li>Send/receive messages with your clinical team</li>
                            <li>Take surveys related to your health</li>
                            <li>Get education on your specific health conditions</li>
                            <li>Track and share your vitals</li>
                            <li>And much more!</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica,
                          Arial, sans-serif; color: #666666;" class="padding">Still have questions? Our nurses can
                          help you understand the app and any privacy concerns you may have.
                          <strong>{{organization.phoneNumber}}</strong></td>
                      </tr>
                      <tr>
                        <td style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica,
                          Arial, sans-serif; color: #666666;" class="padding">We look forward to hearing from you.
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica,
                          Arial, sans-serif; color: #666666;" class="padding"><strong><em>Your
                              {{sender.organizationName}} doctors and nurses</em></strong></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
      </td>
    </tr>
    <tr>
      <td bgcolor="#F5F7FA" align="center" style="padding: 70px 15px 70px 15px;" class="section-padding">
        <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="center" valign="top" width="500"><![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 500px;"
          class="responsive-table">
          <tr>
            <td>
              <!-- TITLE SECTION AND COPY -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td align="center" style="font-size: 25px; font-family: Helvetica, Arial, sans-serif; color:
                    #333333;" class="padding">Get the App</td>
                </tr>
                <tr>
                  <td align="center" style="padding: 20px 0 20px 0; font-size: 16px; line-height: 25px; font-family:
                    Helvetica, Arial, sans-serif; color: #666666;" class="padding">To get started, download the app
                    and sign in with your user name and temporary password provided below.</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center" height="100%" valign="top" width="100%">
              <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="center" valign="top" width="500"><![endif]-->
              <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:500px;">
                <tr>
                  <td align="center" valign="top" style="font-size:0;" class="padding">
                    <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="left" valign="top" width="240"><![endif]-->
                    <div style="display:inline-block; margin: 0 -2px; max-width:50%; min-width:240px;
                      vertical-align:top; width:100%;" class="wrapper">
                      <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                        style="max-width:240px;" class="wrapper">
                        <tr>
                          <td align="center" valign="top">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                              <tr>
                                <td style="padding: 20px 0 20px 0;">
                                  <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                    <tr>
                                      <td align="center" bgcolor="#F5F7FA" valign="middle"><a href="https://wppr.co/ios"
                                          target="_blank"><img
                                            src="https://lavavein.com/assets/wellpepper/app-store.png" width="160"
                                            height="48" style="display: block; color: #666666; font-family:
                                            Helvetica, arial, sans-serif; font-size: 13px; width: 160px; height: 48px;"
                                            alt="Download on the App Store" border="0" class="img-max"></a></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!--[if (gte mso 9)|(IE)]></td><td width="20" style="font-size: 1px;">&nbsp;</td><td align="right" valign="top" width="240"><![endif]-->
                    <div style="display:inline-block; margin: 0 -2px; max-width:50%; min-width:240px;
                      vertical-align:top; width:100%;" class="wrapper">
                      <table cellspacing="0" cellpadding="0" border="0" width="100%">
                        <tr>
                          <td>
                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                              style="max-width:240px; float: right;" class="wrapper">
                              <tr>
                                <td align="center" valign="top">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>
                                      <td style="padding: 20px 0 20px 0;">
                                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                          <tr>
                                            <td align="center" bgcolor="#F5F7FA" valign="middle"><a
                                                href="https://wppr.co/android" target="_blank"><img
                                                  src="https://lavavein.com/assets/wellpepper/google-play.png"
                                                  width="160" height="48" style="display: block; color: #666666;
                                                  font-family: Helvetica, arial, sans-serif; font-size: 13px; width:
                                                  160px; height: 48px;" alt="Get it on Google Play" border="0"
                                                  class="img-max"></a></td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                  </td>
                </tr>
              </table>
              <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
            </td>
          </tr>
          <tr>
            <td align="center" height="100%" valign="top" width="100%">
              <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="center" valign="top" width="500"><![endif]-->
              <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:500px;">
                <tr>
                  <td align="center" valign="top" style="font-size:0;" class="padding">
                    <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="left" valign="top" width="240"><![endif]-->
                    <div style="display:inline-block; margin: 0 -2px; max-width:50%; min-width:240px;
                      vertical-align:top; width:100%;" class="wrapper">
                      <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                        style="max-width:240px;" class="wrapper">
                        <tr>
                          <td align="center" valign="top">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                              <tr>
                                <td style="padding: 20px 0 30px 0;">
                                  <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                    <tr>
                                      <td align="center" style="padding: 5px 0 0 0; font-family: Arial, sans-serif;
                                        color: #666666; font-size: 14px; line-height: 20px;" bgcolor="#F5F7FA">USER
                                        NAME:</td>
                                    </tr>
                                    <tr>
                                      <td align="center" style="padding: 15px 0 0 0; font-family: Arial, sans-serif;
                                        color: #333333; font-size: 20px;" bgcolor="#F5F7FA">{{recipient.username}}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!--[if (gte mso 9)|(IE)]></td>                  <td width="20" style="font-size: 1px;">&nbsp;</td><td align="right" valign="top" width="240"><![endif]-->
                    <div style="display:inline-block; margin: 0 -2px; max-width:50%; min-width:240px;
                      vertical-align:top; width:100%;" class="wrapper">
                      <table cellspacing="0" cellpadding="0" border="0" width="100%">
                        <tr>
                          <td class="mso-right">
                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                              style="max-width:240px; float: right;" class="wrapper">
                              <tr>
                                <td align="center" valign="top">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>
                                      <td style="padding: 20px 0 30px 0;">
                                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                          <tr>
                                            <td align="center" style="padding: 5px 0 0 0; font-family: Arial,
                                              sans-serif; color: #666666; font-size: 14px; line-height: 20px;"
                                              bgcolor="#F5F7FA">TEMPORARY PASSWORD:</td>
                                          </tr>
                                          <tr>
                                            <td align="center" style="padding: 15px 0 0 0; font-family: Arial,
                                              sans-serif; color: #333333; font-size: 20px;" bgcolor="#F5F7FA">
                                              {{password}}</td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                  </td>
                </tr>
              </table>
              <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
            </td>
          </tr>
          <tr>
            <td>
              <!-- COPY -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td align="center" style="font-size: 25px; font-family: Helvetica, Arial, sans-serif; color:
                    #333333; padding-top: 30px;" class="padding">Or Visit Our Web Portal</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <!-- BULLETPROOF BUTTON -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td align="center" style="padding-top: 25px;" class="padding">
                    <table border="0" cellspacing="0" cellpadding="0" class="mobile-button-container">
                      <tr>
                        <td align="center" style="border-radius: 3px;" bgcolor="#000000"><a href="{{link_patientweb}}"
                            target="_blank" style="font-size: 16px; font-family:
                            Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; color: #ffffff;
                            text-decoration: none; border-radius: 3px; padding: 15px 25px; border: 1px solid #000000;
                            display: inline-block;" class="mobile-button">Web Portal &rarr;</a></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <!-- DETAILS SECTION AND COPY -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td align="center" style="padding: 20px 0 20px 0; font-size: 16px; line-height: 25px; font-family:
                    Helvetica, Arial, sans-serif; color: #666666;" class="padding">If the above link doesn’t work for
                    you, please visit https://patient.wellpepper.com and enter in your username and temporary password.
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
      </td>
    </tr>
    <tr>
      <td bgcolor="#ffffff" align="center" style="padding: 20px 0px;">
        <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="500"><tr><td align="center" valign="top" width="500"><![endif]-->
        <!-- UNSUBSCRIBE COPY -->
        <!-- We don't store most of this information for our orgs and don't have a way to templatize this anyway -->
        <!--<table width="100%" border="0" cellspacing="0" cellpadding="0" align="center" style="max-width: 500px;" class="responsive-table"><tr><td align="center" style="font-size: 12px; line-height: 18px; font-family: Helvetica, Arial, sans-serif; color:#666666;">85 Sierra Park Rd PO Box 660 Mammoth Lakes, CA 93546<br><a href="https://mammothhospital.org" target="_blank" style="color: #666666; text-decoration: none;">https://mammothhospital.org</a> &nbsp;|&nbsp; 760-934-3311</td></tr></table>-->
        <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
      </td>
    </tr>
  </table>
</body>

</html>
`,
};
