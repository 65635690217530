import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Params } from '@angular/router';

/** Creates a deep copy of an object or array preserving all proprites, methods, and property descriptors
 * @author https://medium.com/javascript-in-plain-english/deep-clone-an-object-and-preserve-its-type-with-typescript-d488c35e5574
 */
export function deepCopy<T>(source: T): T {
    return Array.isArray(source)
        ? source.map(item => deepCopy(item))
        : source instanceof Date
            ? new Date(source.getTime())
            : source && typeof source === 'object'
                ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                    o[prop] = deepCopy(source[prop]);
                    Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
                    return o;
                }, Object.create(Object.getPrototypeOf(source)))
                : source as T;
}

export function stringToNumber(input: string): number | null {
    const convertedValue = parseInt(input, 10);
    return Number.isNaN(convertedValue) ? null : convertedValue;
}

export function objectToParams(request: any): HttpParams {
    let params: HttpParams = new HttpParams();

    Object.keys(request).forEach(key => {
        if (request[key] != null) {
            params = params.append(key, request[key]);
        }
    });

    return params;
}

export class DateValidators {
    static dateGreaterThanOrEqual(otherDateControl: AbstractControl): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null || otherDateControl.value == null) {
                return null;
            }

            const controlDate = new Date(control.value);
            const otherDate = new Date(otherDateControl.value);
            const isAfter = controlDate >= otherDate;
            return isAfter ? null : { dateMinimum: true };
        };
    }
}

export function printPdf(file: ArrayBuffer, title: string): void {
    const url = window.URL.createObjectURL(new Blob([file as BlobPart], { type: 'application/pdf' }));

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.title = title;
    document.body.appendChild(iframe);

    if (navigator.userAgent.indexOf("Chrome") > -1) {
        iframe.contentWindow.print();
    } else {
        setTimeout(() => iframe.contentWindow.print(), 2000);
    }
}

/**
 * Finds all unique occurences of provided property in an array of objects
 * @param {T[]} array Array of objects
 * @param {string} propertyName Name of property to find unique values in
 */
export function uniqueInArray<T>(array: T[], propertyName: string): T[] {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
}

/** 
 * Group a given array of objects by key
 * @param {T[]} list Array of objects
 * @param {(item: T) => K} getKey Function which returns the object key to group on
 * @returns {Record<K, T[]>} Record object containing grouped array items
 */
export function groupBy<T, K extends keyof never>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
    return list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);
}

/**
 * Force the browser to download a file
 * @param fileName Name to save downloaded file as
 * @param content Binary file content
 * @param contentType Media type of file
 */
export function downloadFile(fileName: string, content: ArrayBuffer, contentType: string): void {
    const url = window.URL.createObjectURL(new Blob([content as BlobPart], { type: contentType }));

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('style', 'display: none');
    link.href = url;
    link.download = fileName;
    link.click();
}

/**
 * Copy supplied text to the clipboard
 * @param text The text to copy
 */
export function copyTextToClipboard(text: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
}

/**
 * Get base URL and query parameters object seperately from URL as string
 * @param url The full URL including query parameters string
 * @returns { url: string, params: Params } Base URL string and query parameters object
 */
export function getUrlAndParams(url: string): { baseUrl: string, params: Params } {
    const urlParts = url.split('?');
    const searchParams = new URLSearchParams(urlParts[1]);
    const params: Params = {};

    searchParams.forEach((value, key) => params[key] = value);

    return {
        baseUrl: urlParts[0],
        params: params
    };
}

export function formatNullableDate(date: Date, format: string, locale: string) : string {
    return date == null ? '' : formatDate(date, 'MM/dd/YYYY', locale);
}

/**
 * Given the numerical month, returns a string containing the formatted month name
 * @param month Month number (0 - 11)
 * @param format Format of month name (long, short, etc)
 * @returns String with formatted month name
 */
export function getNameFromMonthNumber(month: number, format: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined = 'short') : string {
    const date = new Date();
    date.setMonth(month);
    const monthName = date.toLocaleString('default', { month: format });
    return monthName;
}
