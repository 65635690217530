import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'coach-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements OnInit {
  public static idInc: number = 0;

  @Input() groupOptions;
  @Input() UIUpdates340BFeatureFlag: boolean;
  @Input() idx: number;
  @Input() activeDropdownPointer: number | 'month' | null;
  @Input() isDateSelected: boolean = false;
  @Output() optionChecked = new EventEmitter();
  @Output() radioChecked = new EventEmitter();
  @Output() activeDropdownPointerSelected: EventEmitter<number> = new EventEmitter();

  allIsChecked: boolean = false;
  uniqueAllCheckboxId: string = `All-${FilterGroupComponent.idInc++}`;
  useRadioButtons: boolean = false;

  form: UntypedFormGroup;
  options: Array<any>;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      isArray: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.useRadioButtons = this.groupOptions.useRadioButtons ?? false;

    this.options = this.groupOptions.optionLabels.map((option) => {
      let isSelected = this.groupOptions.checkedByDefault.includes(option);
      return { name: option, value: option, isSelected: isSelected };
    });

    if (!this.useRadioButtons) {
      this.initializeCheckboxes();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isDateSelected) {
      let radioButtons: any = document.getElementsByName('itemSelect');
      for (let i = 0; i < radioButtons.length; i++) {
        if (radioButtons[i].checked) {
          radioButtons[i].checked = false;
        }
      }
    }
  }

  getUniqueAllCheckboxId() {
    return this.uniqueAllCheckboxId;
  }

  initializeCheckboxes() {
    if (this.groupOptions.checkedByDefault.includes('All')) {
      this.allIsChecked = true;
      this.setCheckedTo(true);
    } else {
      this.options.forEach((option) => {
        this.onCbChange({
          target: {
            checked: option.isSelected,
            value: option.value,
          },
        }, option);
      });
    }
  }

  setCheckedTo(state) {
    this.options.forEach((option) => {
      option.isSelected = state;
      this.onCbChange({
        target: {
          checked: state,
          value: option.value,
        },
      }, option);
    });
  }

  onAllChecked(e) {
    const isArray: UntypedFormArray = this.form.get('isArray') as UntypedFormArray;
    if (e.srcElement.checked) {
      this.setCheckedTo(true);
    } else {
      this.setCheckedTo(false);
      isArray.controls.splice(0);
    }
    this.allIsChecked = e.srcElement.checked;
    this.onSubmit();
  }

  confirmClick(event) {
    if (!this.isDateSelected) {
      event.preventDefault();
      alert("Please select a month from the dropdown to filter on.");
    }
  }

  onRadioChange(event, option) {
    this.radioChecked.emit({event, option});
  }

  onCbChange(e, option) {
    const isArray: UntypedFormArray = this.form.get('isArray') as UntypedFormArray;

    if (e.target.checked) {
      isArray.push(new UntypedFormControl(e.target.value));
      option.isSelected = true;
    } else {
      let i: number = 0;
      option.isSelected = false;
      isArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == e.target.value) {
          isArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    this.onSubmit();
  }

  onSubmit() {
    this.optionChecked.emit({
      headerLabel: this.groupOptions.headerLabel,
      options: this.form.value,
    });
  }
  getSelectedOptions(): string[] {
    return this.options.filter(option => option.isSelected).map(option => option.name);
  }
  openAccordion(): void {
    this.activeDropdownPointer = this.activeDropdownPointer == this.idx ? null : this.idx;
    this.activeDropdownPointerSelected.emit(this.idx)
  }
  getChevronStatus(): string {
    return this.activeDropdownPointer == this.idx ? 'chevron-up' : 'chevron-down';
  }
  checkAllOptions(): void {
    document.getElementById(this.uniqueAllCheckboxId).click()
  }

}
