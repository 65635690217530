import {
  Component,
  Input,
  OnInit,
  LOCALE_ID,
  Inject,
  SimpleChanges,
  ViewEncapsulation,
  OnChanges
} from '@angular/core';
import { PatientService } from '@shared/services/patient/patient.service';
import { Patient } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { formatDate } from '@angular/common';
import { HeaderCondensedService } from '@shared/services/header-condensed/header-condensed.service';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { GridApi, ColumnApi, Module, ColDef } from 'ag-grid-community';

@Component({
  selector: 'coach-patient-reminders',
  templateUrl: './patient-reminders.component.html',
  styleUrls: ['./patient-reminders.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PatientRemindersComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input() public mobileScreenSize: boolean;
  gridApi: GridApi;
  columnApi: ColumnApi;
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get patientId() {
    return this?.patient?.carePatient?._id;
  }
  
  
  readonly defaultColDef: ColDef = {
    resizable: true,
  };
  readonly columnDefs = [
    {
      headerName: 'Date',
      field: 'createdAt',
      // minWidth: 75,
      sortable: true,
      valueFormatter: (params) =>
        params?.value ? formatDate(params.value, 'MM/dd/yyyy h:mm a', this.locale) : null,
    },
    {
      headerName: 'Type',
      field: 'method',
      // minWidth: 75,
      sortable: true,
    },
    {
      headerName: 'Summary',
      field: 'text',
      // minWidth: 75,
    },
    {
      headerName: 'Status',
      field: 'status',
      // minWidth: 75,
      sortable: true,
    }
  ];
  domLayout: string = 'autoHeight'
  modalViewNotification: boolean = false;
  selectedNotification: wpapi.model.Notification = null
  newNotification: {
    method: "email" | "sms" | null,
    subject: string,
    text: string,
    variableSubstitutionsAccordionIsOpen: boolean,
    sender?: string
  } = {
    method: null,
    subject: '',
    text: '',
    variableSubstitutionsAccordionIsOpen: false,
    sender: ''
  }
  manualRecordToLog: string = '';
  constructor(private patientService: PatientService, 
    @Inject(LOCALE_ID) private locale: string,
    private HeaderCondensedService: HeaderCondensedService,
    private toast: ToastrService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.patientId) {
      this.getNotificationsForPatient(this.patient)
      this.resetReminderForm()
    }
  }
  onGridReady(param: any): void {
    // aggrid on init
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
    this.columnApi.applyColumnState({state:[{ colId: 'createdAt', sort: 'desc' }]}); // set grid status to default
  }
  public onFirstDataRendered(params: any): void {
    params.api.sizeColumnsToFit();
  }
  public onRowClicked(event: any): void {
    if(event?.data){
      this.selectedNotification = event?.data
      console.log("this.selectedNotification =>", this.selectedNotification)
      this.openModal('modalViewNotification')
    }
   }

  async getNotificationsForPatient(patient) {
    this.patient.notifications = await this.patientService.getNotificationsForPatient(patient)
  }
  openModal(target: string, closeSidebar?: boolean): void {
    this[target] = true;
    // make modal closable and close sidebar
    if (closeSidebar) {
      this.HeaderCondensedService.setSidebarStatus(true);
    }
  }

  resetReminderForm(): void {
    this.newNotification = {
      method: this.patient.email ? 'email' : (this.patient.phoneNumber ? 'sms' : null),
      subject: '',
      text: '',
      variableSubstitutionsAccordionIsOpen: false
    }
  }
 
  getChevronStatus(): string {
    return this.newNotification.variableSubstitutionsAccordionIsOpen ? 'chevron-up' : 'chevron-down'
  }
  isNewNotificationValid(): boolean {
    if(!this.newNotification.text?.length){
      return false
    } else if(this.newNotification.method == 'email' && !this.newNotification.subject?.length){
      return false
    } else if( this.newNotification.method == 'sms' && (!this.patient.phoneNumber || this.newNotification.text?.length > 160)){
      return false
    }
    return true
  }

  getPatientProfessionalNameAndOrgName(): string {
    if(this?.patient?.professional){
      return `${this.patient.professional?.firstName} ${this.patient.professional?.lastName} (${this.patient.professional?.organizationName})`
    } else {
      return ''
    }
  }

  async sendNotification() {
    this.newNotification.sender = this.patient.professionalId
    let addNotificationForPatient = await this.patientService.addNotificationForPatient( this.patient.carePatient._id,  this.newNotification );
    if(addNotificationForPatient){
      this.toast.success('Invitation sent. It may take a few minutes for delivery.')
      this.patient.notifications.push(addNotificationForPatient)
      this.gridApi.setRowData(this.patient.notifications)
      this.resetReminderForm()
    }
  }
  async sendManualLog() {
    //manualRecordToLog
    let addmanualRecordForPatient = await this.patientService.addNotificationForPatient( this.patient.carePatient._id,  {method: "manual", text: this.manualRecordToLog, sender: this.patient.professionalId} );
    if(addmanualRecordForPatient){
      this.toast.success('Notice is added.')
      this.patient.notifications.push(addmanualRecordForPatient)
      this.gridApi.setRowData(this.patient.notifications)
      this.manualRecordToLog = ''
    }
  }
  splitStringByLines(str: string): string {
    return str.split("\n").join("<br>")
  }
}
