import differenceInYears from 'date-fns/differenceInYears';
import has from 'lodash-es/has';
import isUndefined from 'lodash-es/isUndefined';
import get from 'lodash-es/get';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { ITrackApiAwvPatient, ITrackApiPatient } from '@shared/services/patient/patient.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { PatientSnfInfo, PatientSnfStatusItem } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/user';
import {AwvWorkflowStatuses as COWorkflowStatus} from 'src/app/api/care-orch/models/awv-workflow-statuses';
import {
  IWPAPIPatient,
  ITrackCohortPatient,
  ITrackCareManagementPatient,
  ITrackApiEdUtilizationPatient,
  ITrackApiIHEFilesListVisitInfo as ITrackApiIHEFilesListVisitInfo,
  IIHEAWVPatient,
  ITrackApiIHEBillingListVisitInfo,
  AWVJsonData
} from '@shared/services/patient/patient.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { PatientIHEFileInfo } from '@shared/models/patient-ihe-data';
import { IHEDownloadStatusObject } from '@shared/modules/patient-facesheet/tabs/patient-ihe-worklist/patient-ihe-worklist.component';
// REVIEW - recommend moving these Care API types to the patient service where they
// are returned or to the Care API types package.
// Models stored here should probably be only coach-web internal models
export interface IPatient {
  firstName: string;
  nickName?: string;
  middleName?: string;
  lastName: string;
  [key: string]: any; // temprorary solution before we figure out what keys we going to remove
}

export interface PatientAlert {
  id: number;
  patientId: string;
  isUnread?: boolean;
  isUnhandled?: boolean;
  alertType?: string;
}

export interface PatientMessage {
  id: number;
  patientId: string;
  isUnread?: boolean;
  isReplied?: boolean;
  subject?: string;
  body?: string;
}

export class Patient implements IPatient {
  // [key: string]: any;

  /** Caravan patient id (chPatId) */
  id:string | number;

  // Shared properties
  firstName: string;
  middleName?: string;
  preferredName?: string;
  lastName: string;
  nickName?: string;
  gender?:string;
  dob?:string;
  dod?:string;
  email?:string;
  phoneNumber?:string;


  /** From carePatient.professionalId */
  professionalId?:string;
  completions?: any[]
  userActivity?: any;
  activities?: any[];
  tasks?: any[];
  attributedCP:string;


  /**
   * If already invited to patient engagement this should exist. Patient data
   * is partitioned by tierId
   */
  carePatient?: wpapi.model.Patient & {tierId?:string, snfInfo? :  {
    current?: PatientSnfInfo,
    history?: PatientSnfInfo[]
  }};
  /** If retrieved from track patient search api */
  trackPatient?: ITrackApiPatient;
  trackAwvPatient?: ITrackApiAwvPatient;
  /** If retrieved from AWV worklist api  */
  awvPatient?: ITrackApiAwvPatient;
  /** Patient’s current Tier5 provider name from fctAttributionResults most recent quarter. If Tier 5 name is NULL, display Tier 4 name */
  tier4_Name?: string;
  tier5_Name?: string;
  tier3_ID?: string;
  tier2_ID?: string;

  // Calculated or delegated properties
  get currentTimeZoneName() { return this?.carePatient?.currentTimeZoneName }
  set currentTimeZoneName(value:string) { if (this.carePatient) { this.carePatient.currentTimeZoneName = value; } }
  get currentTimeZoneOffsetSec() { return this?.carePatient?.currentTimeZoneOffsetSec }
  set currentTimeZoneOffsetSec(value:number) { if (this.carePatient) { this.carePatient.currentTimeZoneOffsetSec = value; }}
  get chPatId() { return this?.trackPatient?.chPatId || this?.carePatient?.chPatId || this.id?.toString() }
  get mbi() { return this?.trackPatient?.mbi || this?.trackAwvPatient?.mbi || this?.carePatient?.mbi }
  get mrn() { return this?.carePatient?.mrn }
  get username() { return this?.carePatient?.username }
  get statusHistory() { return this?.carePatient?.statusHistory }
  get includedObjects() { return this?.carePatient?.includedObjects }
  get createdAt() { return this?.carePatient?.createdAt }
  get photo() { return this?.carePatient?.avatar?.url }
  get raF_YTD() { return this?.trackPatient?.raF_YTD }
  get enrollmentType() { return this?.trackPatient?.enrollmentType }
  get ssDenom(){return this?.trackPatient?.ssDenom}
  get assignment() { return this?.trackPatient?.assignment }

  // Additional view model info
  professional?: wpapi.model.Professional;
  alerts?: {
    handled: Array<wpapi.model.Alert>;
    unhandled: Array<wpapi.model.Alert>;
    all: Array<wpapi.model.Alert>;
  };
  hideTabsAndDisableTimeTracking: boolean;
  supplementalId?: number;
  notifications: Array<wpapi.model.Notification>;
  episodesOfCare?: Array<wpapi.model.EpisodeOfCare>;

  snfInfo?: {
    current?: PatientSnfInfo,
    history?: PatientSnfInfo[]
  };

  statusDate?: string;
  verbalConsent?: {
    acceptedBy: String;
    acceptedByName: string;
    acceptedByEulaId: string;
    acceptedDate: string;
  }

  /** Only use factory methods to construct this object */
  private constructor() {}


  static fromCareAndTrackPatient(carePatient:wpapi.model.Patient & { tierId?: string}, trackPatient?:ITrackApiPatient) {
    let result = new Patient();
    result.refreshFromCareAndTrackPatient(carePatient, trackPatient);
    return result;
  }
  static fromCareAndTrackAwvPatient(carePatient:wpapi.model.Patient & { tierId?: string}, trackAwvPatient?:ITrackApiAwvPatient) {
    let result = new Patient();
    result.refreshFromCareAndTrackAwvPatient(carePatient, trackAwvPatient);
    return result;
  }

  static getChPatIdAndTierIdFromCarePatient(carePatient: wpapi.model.Patient):{tierId:string, chPatId:string}  {
    const nowTicks = new Date().valueOf();
    const patientInTierSubject = carePatient?.identifiers?.
      find(i=>
        i.issuer === environment.patientIdentityProvider.issuer &&
        (!i.effectiveAt || (new Date(i.effectiveAt).valueOf() < nowTicks)) &&
        (!i.expiresAt || (new Date(i.expiresAt).valueOf() > nowTicks))
      )?.subject;
    const patientInTierSubjectObj= patientInTierSubject &&
      environment.patientIdentityProvider.parseSubject(patientInTierSubject);
    return <{tierId:string, chPatId:string}>patientInTierSubjectObj;
  }

  refreshFromCareAndTrackPatient(carePatient:wpapi.model.CaravanPatient, trackPatient?:ITrackApiPatient) {
    this.id = trackPatient?.chPatId;
    this.carePatient = carePatient;
    this.trackPatient = trackPatient;
    if (carePatient) {
      this.carePatient = carePatient;
      const patientInTierSubjectObj = Patient.getChPatIdAndTierIdFromCarePatient(carePatient);
      carePatient.tierId = patientInTierSubjectObj?.tierId;
      if (trackPatient && this.id != patientInTierSubjectObj?.chPatId) {
        console.error(`Something is wrong! track patient with chPatId ${trackPatient.chPatId} does not match care patient with chPatId ${patientInTierSubjectObj?.chPatId}`);
        // REVIEW - misleading data could be shown. Should we alert the user?
      }
    }

    // 20201129 - taking the opportunity to make the type checking stricter
    // Generically copy the other stuff over for now.
    // Object.assign(result, trackPatient);
    // Object.assign(result, carePatient);

    if (carePatient) {
      if (carePatient.firstName) this.firstName = carePatient.firstName;
      if (carePatient.lastName) this.lastName = carePatient.lastName;
      this.professionalId = carePatient.professionalId;
      // These values are primary from trackPatient
      this.dob == this.dob || carePatient.gender;
      this.dob == this.dob || carePatient.dob;

      // These values can be overridden
      this.email = carePatient.email || this.email;
      this.phoneNumber = Patient.formatPhoneNumber(carePatient.phoneNumber) || this.phoneNumber;
      this.nickName = carePatient.nickName?.trim();
      this.snfInfo = carePatient.snfInfo;
    }

    // CC-3159 - track values override care values
    if (trackPatient) {
      this.dob = trackPatient.dob ? moment(trackPatient.dob, "MM/DD/YYYY").format("YYYY-MM-DD") : null;
      this.dod = trackPatient.dod ? moment(trackPatient.dod, "MM/DD/YYYY").format("YYYY-MM-DD") : null;
      this.firstName = trackPatient.firstName;
      this.lastName = trackPatient.lastName;
      this.gender = trackPatient.gender?.toLowerCase();
      this.email = trackPatient.email;
      this.phoneNumber = Patient.formatPhoneNumber(trackPatient.phone1 || trackPatient.phone2);
      this.attributedCP = trackPatient.attributedCP;
      this.tier4_Name = trackPatient.tier4_Name;
      this.tier5_Name = trackPatient.tier5_Name;
      this.tier3_ID = trackPatient.tier3_ID;
      this.tier2_ID = trackPatient.tier2_ID;
    }

    this.carePatient = carePatient;
    this.trackPatient = trackPatient;
    return this;
  }
  refreshFromCareAndTrackAwvPatient(carePatient: wpapi.model.CaravanPatient, trackAwvPatient?: ITrackApiAwvPatient) {
    this.id = trackAwvPatient?.chPatID?.toString();
    this.carePatient = carePatient;
    this.trackAwvPatient = trackAwvPatient;
    if (carePatient) {
      this.carePatient = carePatient;
      const patientInTierSubjectObj = Patient.getChPatIdAndTierIdFromCarePatient(carePatient);
      carePatient.tierId = patientInTierSubjectObj?.tierId;
      if (trackAwvPatient && this.id != patientInTierSubjectObj?.chPatId) {
        console.error(`Something is wrong! track patient with chPatId ${trackAwvPatient.chPatId} does not match care patient with chPatId ${patientInTierSubjectObj?.chPatId}`);
      }
    }

    if (carePatient) {
      if (carePatient.firstName) this.firstName = carePatient.firstName;
      if (carePatient.lastName) this.lastName = carePatient.lastName;
      this.professionalId = carePatient.professionalId;
      // These values are primary from trackAwvPatient
      this.dob == this.dob || carePatient.gender;
      this.dob == this.dob || carePatient.dob;

      // These values can be overridden
      this.email = carePatient.email || this.email;
      this.phoneNumber = Patient.formatPhoneNumber(carePatient.phoneNumber) || this.phoneNumber;
      this.nickName = carePatient.nickName?.trim();
      this.snfInfo = carePatient.snfInfo;
    }

    if (trackAwvPatient) {
      let lastFirst = trackAwvPatient.patientName.split(',').map(x => x.trim());
      this.dob = moment(trackAwvPatient.dob).format("YYYY-MM-DD");
      this.firstName = lastFirst?.[1];
      this.lastName = lastFirst?.[0];
      this.gender = trackAwvPatient.gender?.toLowerCase();
      this.email = trackAwvPatient.email;
      this.phoneNumber = Patient.formatPhoneNumber(trackAwvPatient.phone1);
      this.attributedCP = trackAwvPatient.attributedCP;
      this.tier4_Name = trackAwvPatient.tier4_Name;
      this.tier5_Name = trackAwvPatient.tier5_Name;
      this.tier3_ID = trackAwvPatient.tier3_ID;
      this.tier2_ID = trackAwvPatient.tier2_ID;
      this.statusDate = trackAwvPatient.statusDate;
    }

    this.carePatient = carePatient;
    return this;
  }
  displayPreferredName(): string {
    return this.nickName || this.firstName;
  }

  isInvited():boolean {
    return this.carePatient && "patient" === this.carePatient.accountType;
  }
  displayInitials(): string {
    return `${this.lastName?.[0]} ${this.firstName?.[0]}`
  }
  // Full name for display
  displayName(): string {
    return `${this.lastName}, ${this.firstName} ${this.nickName?.trim().length > 0 ? `"(${this.nickName.trim()})"`: ''}`
  }

  displayAge(): string {
    return !this.dob || this.dob === 'Unknown' ? '' : `${differenceInYears(this.dod ? new Date(this.dod) : new Date(), new Date(this.dob))}yo`;
  }

  displayDob(): string {
    return !this.dob || this.dob === 'Unknown' ? '' : `DOB: ${moment(this.dob).format("L")}`;
  }
  displayDod(): string {
    return !this.dod || this.dod === 'Unknown' ? '' : `Deceased: ${moment(this.dod).format("L")}`;
  }

  displayEmail(): string {
    return isUndefined(this.email) ? '' : `<a href="mailto:${this.email}">${this.email}></a>`;
  }

  displayPhone(): string {
    return this.phoneNumber;
  }

  avatarUrl(): string | null {
    return this.photo || get(this, 'avatar.url', null);
  }

  identifier(): { name: string; id: string; } {
    switch(true) {
      case has(this, 'mbi'):
        return { name: 'MBI', id: this.mbi };
      case has(this, 'mrn'):
        return { name: 'MRN', id: this.mrn };
    }
  }

  displayIdentifier(): string {
    const id = this.identifier();
    return isUndefined(id) ? '' : `${this.identifier().name}: ${this.identifier().id}`;
  }
  displayGender(): string {
    return this.gender ? this.gender.charAt(0).toUpperCase() : 'Unknown'
  }
  static formatPhoneNumber = (phoneNumber: string): string => {
    /**
     * @param str as a phone number in any format
     * @return converted phone number into (XXX) XXX-XXXX format
     */
    phoneNumber = ('' + phoneNumber).replace(/\D/g, ''); // clears all non-digit chars
    let match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/); // checks if number is valid
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  };
  static displayGender = (gender: string): string => {
    return gender ? gender.charAt(0).toUpperCase() + gender.slice(1) : 'Unknown'
  };

  getThisYearPercentageBar(): number {
    return (this.trackPatient?.raF_YTD || 0) / ((this.trackPatient?.raF_YTD || 0) + (this.trackPatient?.openRAFWt || 0)) * 100
  }
  getLastYearPercentageBar(): number {
    return (this.trackPatient?.pyRaf || 0) / ((this.trackPatient?.raF_YTD || 0) + (this.trackPatient?.openRAFWt || 0)) * 100
  }
}

export type PatientSnfStatus = 'Admitted' | 'Discharged' | 'Discharged after 30 days' | 'Removed';
export type DownloadStatus = 'New' | 'Partial' | 'Done';
enum YesNoEnum {
    Yes = 1,
    No = 0
}

// Used for visual presentation
enum AWVWorklistDisplayStatusEnum {
  ALL_PATIENTS = 'ALL PATIENTS',
  READY_TO_SCHEDULE = 'READY TO SCHEDULE',
  CALL_BACK_LATER = 'CALL BACK LATER',
  READY_TO_REMIND = 'READY TO REMIND',
  SCHEDULED = 'SCHEDULED',
  READY_TO_SELF_REPORT = 'READY TO SELF-REPORT',
  COMPLETED = 'COMPLETED',
  INELIGIBLE = 'INELIGIBLE',
  PATIENT_DECLINED = 'PATIENT DECLINED',
  DEFERRED_TO_SIGNIFY = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}
export class PatientViewModel {

    // =========================== fields/columns/types ==========================

    select?: boolean;
    fullName?: string;
    src?: string;
    chPatId?: string;

    bloodSugar?: number;
    weight?: number;
    gain?: string;
    alertsCounter?: number;

    email?: string;
    phoneNumber?: string;
    dob?: Date; // DOB, age, gender
    dod?: Date; // date of death
    dodBoolean?: string;
    professionalName?: string;
    assignment?: string;
    gender?: string;
    patientId?: string | number;
    lastActivityAt?: string;
    lastName?: string;
    firstName?: string;

    // Track
    attributedCP?: string;
    currentRiskLevel?: string;
    predictedRiskLevel?: string;
    probability?: string;
    riskTrend?: string;
    raF_YTD?: string;
    openRAFWt?: number;
    totalCost?: string;
    attributedCPTier?: number;
    attributedPractice?: string;
    enrollmentType?: string;
    ssDenom?: number;
    ssPercent?: number;
    alignToClinical?: string;
    cohortProcedureLastDate: string;
    cohortSpecialistLastProviderName: string;
    cohortSpecialistLastDate: string;
    hfCohortProcedureLastDate: string;
    hfCohortSpecialistLastProviderName: string;
    hfCohortSpecialistLastDate: string;
    totalGapWt: number;
    chronicConditionList: string;
    cohortDxCount?: number;

    // AWV
    raf?: number;
    pyRaf?: string;
    awvTypeDue?: string;
    lastAWVDate?: string;
    lastClaimAWVDate?: string;
    lastAWVProvider?: string;
    lastClaimAWVProvider?: string;
    awvStatusDisplay?: string;
    hasNotes?: boolean;
    supplementalId?: string;
    lastEHRAWVIPPEDate?: string;
    newStatusDate?: string;
    ehrLastAwvCommunity?: string;
    ehrLastAwvProvider?:string;
    ehrReportedLastAwvSource?:string;
    ehrReportedLastAwvDate?: string;
    //Cohort
    totalSpend?: string;
    // TODO - these duplicate some others and should be reconciled at some point
    current_MBI?: string;
    phone?: string;
    phone1?: string;
    phone2?: string;
    careId?: string;
    lastLogin?: string;
    spirometryProcedure_LastDate?: string;
    pulmonologistProcedure_LastProviderName?: string;
    pulmonologistProcedure_LastDate?: string;
    copdDxCount?: number;
    echoProcedure_LastDate?: string;
    cardiologistProcedure_LastProviderName?: string;
    cardiologistProcedure_LastDate?: string;
    hfDxCount?: number;
    officeVisits?: number;
    inpatientVisits?: number;
    isMostImpactable?: string;
    lastCCMDateAndCode?: string;
    ccmLastProcCode?: string;

    //ED
    patientName?: string;
    // age?: string; This is now calculated
    mbi?: string;
    provider?: string;
    lastVisit?: Date;
    edVisits?: string;
    hccGapCount?: number;

    // Alert filters
    bp?: string;
    pulse?: number;
    systolic?: number;
    diastolic?: number;

    ccmEligible?: string;
    diseaseCohortIds?: number[];
    diseaseCohorts?: string[];

    // CC-3471: pac navigator / snf info
    snfAdmittedAt?: string;
    snfDischargedAt?: string;
    snfName?: string;
    snfHistory?: PatientSnfInfo[];
    //snfStatus?: PatientSnfStatus;

    //IHE
    /** Date of the visit. Practially not possible within the time frame visible in the view but a patient could have multiple visits */
    IHEAWVVisitDate?: string;
    ihAwvPatient?: string;
    /** REVIEW Not sure whether we need an actual field for this */
    IHEfileActions?: string;
    /** Status of the VISIT */
    IHEStatus?: string;
    IHEStatusLastUpdated?: Date;
    // File info - all are calculated from file download and update history
    IHEAssessmentFilesLastUpdated?: Date;
    IHEAssessmentFilesLastDownloaded?: Date;
    IHECMRFileLastUpdated?: Date;
    IHECMRFileLastDownloaded?: Date;
    notOnlyCMRFile?: boolean;
    awvStatusId?: number;
    //IHELastDownloadDateofAnyFile : Date;
    IHEDownloadDate?: Date;
    renderingProviderName?: string;
    //IHEDownloadStatus?: "New" | "Partial" | "Done";
    IHEAWVBatchDate?: Date;
    cptCode?: string;
    iheDxCount?: string;
    claimFrequencyCode?: string;

    snfFlagsList?: PatientSnfStatusItem[];
    address?: string;
    scheduleStatus?: string;
    schedulingDescription?: string;
    callDate?: string // last outreach date
    dateOfService?: string // appointment date
    renderingProvider?: string;
    lastCCMDate?: string;
    lastCcmDate?: string;
    alert?: string;
    status?: string;
    enrollmentDate?: string;
    smsProgram?: string;
    lastResponseDate?: string;
    lastResponse?: string;
    statusDate?: string;
    lastCsthDate?: Date;
    withoutFacesheet?: boolean;

    trackPatient?: ITrackApiPatient;
    carePatient?: IWPAPIPatient;
    trackAwvPatient?: ITrackApiAwvPatient;
    trackCohortPatient?: ITrackCohortPatient;
    trackCareManagementPatient?: ITrackCareManagementPatient;
    trackIHEPatient?: ITrackApiIHEFilesListVisitInfo;

    actions?: {
        alertsCount?: number;
        tier?: IHierarchyTier;
        id: string;
        totalActionCounts?: number;
    };
    tierId?: string;
    tierNum?: number;
    allAWVPatientsListStatus?: AwvWorkflowStatusForPatientListDisplay;
    isUsingSnf3DayWaiver?: boolean;
    constructor() { }

    // ============================ get functions ============================
    get snfStatus(): PatientSnfStatus {
        const thirtyDaysAgoDate = moment().subtract(30, 'days').startOf('day');
        let status: PatientSnfStatus;

        if (this.snfName && this.snfAdmittedAt && !this.snfDischargedAt && !(moment(this.snfDischargedAt).isBefore(thirtyDaysAgoDate))) {
            status = 'Admitted';
        }
        else if (this.snfName && this.snfAdmittedAt && this.snfDischargedAt && (moment(this.snfDischargedAt).isBefore(thirtyDaysAgoDate))) {
            status = 'Discharged after 30 days';
        }
        else if (this.snfName && this.snfAdmittedAt && this.snfDischargedAt && (moment(this.snfDischargedAt).isSameOrAfter(thirtyDaysAgoDate))) {
            status = 'Discharged';
        }
        else if (!this.snfName && !this.snfAdmittedAt && this.snfHistory) {
            status = 'Removed';
        }
        return status;
    }

    get IHEDownloadStatus(): DownloadStatus {
        let status: DownloadStatus;
        if (this.IHEAssessmentFilesLastUpdated && this.IHECMRFileLastUpdated &&
            this.IHEAssessmentFilesLastDownloaded && this.IHECMRFileLastDownloaded) {
            status = 'Done';
        } else if (this.notOnlyCMRFile && this.IHECMRFileLastUpdated && this.IHECMRFileLastDownloaded && moment(this.IHECMRFileLastUpdated).isBefore(this.IHECMRFileLastDownloaded)) {
            status = 'Done';
        } else if (!this.IHEAssessmentFilesLastUpdated && !this.IHEAssessmentFilesLastDownloaded && this.IHECMRFileLastUpdated && this.IHECMRFileLastDownloaded && moment(this.IHECMRFileLastUpdated).isBefore(this.IHECMRFileLastDownloaded)) {
            status = 'Partial';
        } else if (this.IHEAssessmentFilesLastUpdated && this.IHEAssessmentFilesLastDownloaded && !this.IHECMRFileLastUpdated && !this.IHECMRFileLastDownloaded && moment(this.IHEAssessmentFilesLastUpdated).isBefore(this.IHEAssessmentFilesLastDownloaded)) {
            status = 'Done';
        } else if (this.IHEDownloadDate) {
            status = 'Partial';
        } else {
            status = 'New';
        }
        return status;
    }

    /** Return age in years */
    get age(): number {
        let dob = moment(this.dob);
        if (!dob.isValid()) {
            return null;
        }

        let years = moment(this.dod ? this.dod : void 0).diff(dob, 'years');
        return years;
    }

    get dob_age() {
        return {
            dob: this.dob,
            age: this.age,
            toString: function () {
                let date = new Date(this.dob);
                if (isNaN(date.getTime())) return 'unknown';
                var year = date.getFullYear();

                var month = (1 + date.getMonth())?.toString();
                month = month.length > 1 ? month : '0' + month;

                var day = date.getDate()?.toString();
                day = day.length > 1 ? day : '0' + day;

                return month + '/' + day + '/' + year;
            },
        };
    }

    // =========================== model converters ==========================

    static fromTrackAndCare(
        tp: ITrackApiPatient,
        cp: IWPAPIPatient,
        tier: IHierarchyTier,
        assignment?: string,
        currentRiskLevel?: string,
        predictedRiskLevel?: string,
        probability?: number,
        riskTrend?: string
    ): PatientViewModel {
        let res: PatientViewModel = new PatientViewModel();
        if (cp) {
            res.chPatId = Patient.getChPatIdAndTierIdFromCarePatient(cp)?.chPatId;
            res.fullName = `${cp.lastName}, ${cp.firstName}`;
            res.lastName = cp.lastName;
            res.firstName = cp.firstName;
            res.dob = cp.dob ? new Date(cp.dob) : null;
            res.gender = Patient.displayGender(cp.gender);
            res.bp = `${cp.systolic || '-'}/${cp.diastolic || '-'}`;
            res.phoneNumber = Patient.formatPhoneNumber(cp.phoneNumber);
            res.professionalName = cp.professionalName;
            res.lastActivityAt = cp.lastActivityAt;
            res.carePatient = cp;
            res.pulse = cp.pulse;
            res.bloodSugar = cp.bloodSugar || '-';
            res.patientId = cp._id;
            res.actions = {
                tier: tier,
                alertsCount: cp['alerts']?.length,
                id: Patient.getChPatIdAndTierIdFromCarePatient(cp)?.chPatId,
                totalActionCounts: cp['alerts']?.length,
            };
            res.alertsCounter = cp['alerts']?.length;
            (res.weight = cp.weight), (res.gain = cp.gain);
            res.mbi = cp.mbi;
            (res.assignment = assignment ? assignment : tp?.assignment),
                (res.currentRiskLevel = currentRiskLevel
                    ? currentRiskLevel
                    : tp?.currentRiskLevel);
            res.predictedRiskLevel = predictedRiskLevel
                ? predictedRiskLevel
                : tp?.predictedRiskLevel;
            res.probability = probability
                ? probability?.toString()
                : tp?.probability?.toFixed(2);
            res.riskTrend = riskTrend ? riskTrend : tp?.riskTrend;
            res.snfAdmittedAt = cp.snfInfo?.current?.snfAdmittedAt;
            res.snfDischargedAt = cp.snfInfo?.current?.snfDischargedAt;
            res.snfName = cp.snfInfo?.current?.snfName;
            res.snfHistory = cp.snfInfo?.history;
            res.snfFlagsList = cp.snfInfo?.current?.snfStatus;
            res.isUsingSnf3DayWaiver = cp.snfInfo?.current?.isUsingSnf3DayWaiver;
        }
        // CC-3159 track values override care values
        if (tp) {
            res.chPatId = tp.chPatId?.toString();
            res.fullName = `${tp.lastName}, ${tp.firstName}`;
            res.lastName = tp.lastName;
            res.firstName = tp.firstName;
            res.gender = Patient.displayGender(tp.gender);
            res.dob = tp.dob ? new Date(tp.dob) : null;
            res.dod = tp.dod ? new Date(tp.dod) : null;
            res.dodBoolean = tp.dod ? 'Yes' : 'No';
            res.phoneNumber = Patient.formatPhoneNumber(tp.phone1);
            res.patientId = tp.chPatId;
            res.tierId = tier.selectedTierId;
            res.trackPatient = tp;
            res.withoutFacesheet = tp?.hasFacesheet == 0;
            res.actions = { ...{ tier: tier, id: tp.chPatId?.toString(), withoutFacesheet: res.withoutFacesheet}, ...(res.actions || {}) };
            res.mbi = tp.mbi;
            res.email = tp.email;
            res.assignment = tp?.assignment;
            res.attributedCP = tp?.attributedCP;
            res.currentRiskLevel = tp?.currentRiskLevel;
            res.predictedRiskLevel = tp?.predictedRiskLevel;
            res.probability = tp?.probability?.toFixed(2);
            res.riskTrend = tp?.riskTrend;
            res.ccmEligible = tp?.ccmEligible ? 'Eligible' : null;
            res.diseaseCohorts = tp?.diseaseCohorts;
            res.awvTypeDue = tp?.awvTypeDue;
            res.lastAWVDate = changeDateToYYYYMMDDorEmptyString(tp?.lastAWVDate);
            res.lastClaimAWVDate = res.lastAWVDate;
            res.lastAWVProvider = tp?.lastAwvProvider;
            res.lastClaimAWVProvider = tp?.lastAwvProvider;
            res.awvStatusId = tp.awvStatusId;
            res.ihAwvPatient = tp.ihAwvPatient ? 'Enrolled' : 'Not Enrolled';
            res.edVisits = tp?.edVisits?.toString();
            res.hccGapCount = tp?.hccGapCount;
            res.raF_YTD = `${tp.raF_YTD?.toFixed(3) || ''}`;
            res.openRAFWt = tp.openRAFWt;
            res.totalCost = tp.totalCost ? `${tp.totalCost.toFixed(2)}` : null;
            res.attributedCPTier = tp.attributedCPTier;
            res.lastVisit = tp.lastVisit;
            res.attributedPractice = tp.tier4_Name;
            res.enrollmentType = tp.enrollmentType?.toLowerCase() == "cannot be determined" ? "Opted Out of Claims Sharing" : tp.enrollmentType;
            res.officeVisits = tp.officeVisits;
            res.inpatientVisits = tp.inpatientVisits;
            res.ccmLastProcCode = tp.ccmLastProcCode;
            res.lastCcmDate = changeDateToYYYYMMDDorEmptyString(tp?.lastCCMDate);
            res.lastCCMDate = res.lastCcmDate;
            res.lastCCMDateAndCode = tp.lastCCMDate || tp.ccmLastProcCode ? `${tp.lastCCMDate ? moment(tp.lastCCMDate).format("MM/DD/YYYY") : ''} ${tp.lastCCMDate && tp.ccmLastProcCode ? "-" : ""} ${tp.ccmLastProcCode || ''}` : null;
            res.pyRaf = `${tp.pyRaf?.toFixed(3) || ''}`;
            res.lastCsthDate = tp?.lastCsthDate;
          }
        return res;
    }
    static fromCareManagementPatient(
        cmp: ITrackCareManagementPatient,
        tier: IHierarchyTier
    ): PatientViewModel {
        let res: PatientViewModel = new PatientViewModel();
        res.lastName = cmp.lastName;
        res.firstName = cmp.firstName;
        res.chPatId = cmp.chPatId?.toString();
        res.fullName = `${cmp.lastName}, ${cmp.firstName}`;
        res.dob = cmp.dateOfBirth ? new Date(cmp.dateOfBirth) : null;
        res.gender = `${(cmp.genderStr && cmp.genderStr[0].toUpperCase()) || '?'}`;
        res.mbi = cmp.currentMbi;
        res.attributedCPTier = cmp.attributedCpTier;
        res.currentRiskLevel = cmp.currentRiskLevel;
        res.predictedRiskLevel = cmp.predictedRiskLevel;
        res.attributedCP = `${cmp.attributedCp}`;
        res.ccmEligible = cmp?.ccmEligible ? 'Eligible' : null;
        res.raF_YTD = `${cmp.ytdRaf?.toFixed(3) || ''}`;
        res.totalSpend = `${cmp.totalSpend?.toFixed(2)}`;
        // res.actions = {
        //   alertsCount: additionalParams?.alertsCount,
        //   tier: tier,
        //   unreadMessagesCount: additionalParams?.unreadMessagesCount,
        //   id: cp.chPatID?.toString(),
        // };
        res.careId = null;
        res.lastLogin = null;
        res.lastActivityAt = null;
        res.hccGapCount = cmp.hccGapCount;
        res.diseaseCohorts = cmp?.diseaseCohorts;
        res.lastVisit = cmp.lastProvVisitDate ? moment(cmp.lastProvVisitDate)?.toDate() : null;
        res.openRAFWt = cmp.totalGapWt;
        res.awvTypeDue = cmp.awvTypeDue;
        res.lastAWVDate = changeDateToYYYYMMDDorEmptyString(cmp.lastAwvDate);
        res.edVisits = cmp.edVisits?.toString();
        res.pyRaf = `${cmp.pyRaf?.toFixed(3) || ''}`;
        res.copdDxCount = cmp.cohortDxCount;
        res.hfDxCount = cmp.cohortDxCount;
        res.ccmLastProcCode = cmp.ccmLastProcCode || cmp.lastCcmCode; //Source of the data is still in lastCcmCode.
        res.lastCcmDate = changeDateToYYYYMMDDorEmptyString(cmp.lastCcmDate);
        res.lastCCMDateAndCode = cmp.lastCcmDate || cmp.lastCcmCode ? `${cmp.lastCcmDate ? moment(cmp.lastCcmDate).format("MM/DD/YYYY") : ''} ${cmp.lastCcmDate && cmp.lastCcmCode ? "-" : ""} ${cmp.lastCcmCode || ''}` : null;
        res.officeVisits = cmp.officeVisits;
        res.inpatientVisits = cmp.inpatientVisits;
        res.ssDenom = cmp.ssDenom;
        res.enrollmentType = cmp.enrollmentStatus;
        res.cohortProcedureLastDate = changeDateToYYYYMMDDorEmptyString(cmp.cohortProcedureLastDate);
        res.cohortSpecialistLastProviderName = cmp.cohortSpecialistLastProviderName;
        res.cohortSpecialistLastDate = changeDateToYYYYMMDDorEmptyString(cmp.cohortSpecialistLastDate);
        res.hfCohortProcedureLastDate = changeDateToYYYYMMDDorEmptyString(cmp.cohortProcedureLastDate);
        res.hfCohortSpecialistLastProviderName = cmp.cohortSpecialistLastProviderName;
        res.hfCohortSpecialistLastDate = changeDateToYYYYMMDDorEmptyString(cmp.cohortSpecialistLastDate);
        res.assignment = cmp.assignment;
        res.totalGapWt = cmp.totalGapWt;
        res.chronicConditionList = cmp?.chronicConditionList;
        res.alignToClinical = cmp.alignToClinical;
        res.attributedPractice = cmp.tier4Name;
        res.ssPercent = (cmp.ssPercent || 1) * 100;
        res.isMostImpactable = YesNoEnum[cmp.alignToClinical];
        return res;
    }
    static fromCohortPatient(
        cp: ITrackCohortPatient,
        tier: IHierarchyTier,
        additionalParams: {
            lastLogin: string;
            lastActivityAt: string;
            alertsCount: number;
            careId: string;
        }
    ): PatientViewModel {
        let res: PatientViewModel = new PatientViewModel();
        res.lastName = cp.lastName;
        res.firstName = cp.firstName;
        res.email = cp.email;
        res.chPatId = cp.chPatID?.toString();
        res.fullName = `${cp.lastName}, ${cp.firstName}`;
        res.dob = cp.dob ? new Date(cp.dob) : null;
        res.gender = `${(cp.genderStr && cp.genderStr[0].toUpperCase()) || '?'}`;
        res.current_MBI = `${cp.current_MBI}`;
        res.assignment = cp.assignment;
        res.currentRiskLevel = cp.currentRiskLevel;
        res.predictedRiskLevel = cp.predictedRiskLevel;
        res.probability = cp.probability?.toFixed(2);
        res.riskTrend = cp.riskTrend;
        res.attributedCP = `${cp.attributedCP}`;
        res.phone = Patient.formatPhoneNumber(cp.phone1);
        res.ccmEligible = cp?.ccmEligible ? 'Eligible' : null;
        res.phone1 = cp.phone1 && cp.phone1_Type ? `${Patient.formatPhoneNumber(cp.phone1)}` : '';
        res.phone2 = cp.phone2 && cp.phone2_Type ? `${Patient.formatPhoneNumber(cp.phone1)}` : '';
        res.raF_YTD = `${cp.raF_YTD?.toFixed(3) || ''}`;
        res.totalSpend = `${cp.totalSpend?.toFixed(2)}`;
        res.actions = {
            alertsCount: additionalParams?.alertsCount,
            tier: tier,
            id: cp.chPatID?.toString(),
                totalActionCounts: additionalParams?.alertsCount,
        };
        res.careId = additionalParams?.careId;
        res.lastLogin = additionalParams?.lastLogin;
        res.lastActivityAt = additionalParams?.lastActivityAt;
        res.hccGapCount = cp.hccGapCount;
        res.diseaseCohorts = cp?.diseaseCohorts;
        res.lastVisit = cp.lastVisit;
        res.openRAFWt = cp.openRAFWt;
        res.attributedPractice = cp.tier4_Name;
        res.awvTypeDue = cp.awvTypeDue;
        res.lastAWVDate =  changeDateToYYYYMMDDorEmptyString(cp.lastAWVDate);
        res.edVisits = cp.edVisits?.toString();
        res.pyRaf = `${cp.pyRaf?.toFixed(3) || ''}`;
        res.spirometryProcedure_LastDate = cp.cohortProcedure_LastDate;
        res.pulmonologistProcedure_LastProviderName = cp.cohortSpecialist_LastProviderName;
        res.pulmonologistProcedure_LastDate = cp.cohortSpecialist_LastDate;
        res.copdDxCount = cp.cohortDxCount;
        res.echoProcedure_LastDate = cp.cohortProcedure_LastDate;
        res.cardiologistProcedure_LastProviderName = cp.cohortSpecialist_LastProviderName;
        res.cardiologistProcedure_LastDate = cp.cohortSpecialist_LastDate;
        res.hfDxCount = cp.cohortDxCount;
        res.ccmLastProcCode = cp.ccmLastProcCode;
        res.lastCcmDate = changeDateToYYYYMMDDorEmptyString(cp.lastCcmDate);
        res.lastCCMDateAndCode = cp.lastCcmDate || cp.ccmLastProcCode ? `${cp.lastCcmDate ? moment(cp.lastCcmDate).format("MM/DD/YYYY") : ''} ${cp.lastCcmDate && cp.ccmLastProcCode ? "-" : ""} ${cp.ccmLastProcCode || ''}` : null;
        res.isMostImpactable = YesNoEnum[cp.alignToClinical];
        res.officeVisits = cp.officeVisits;
        res.inpatientVisits = cp.inpatientVisits;
        return res;
    }

    // REVIEW Strictly speaking we probably shouldn't be using a "patient view model" as each
    // row is a visit, not a patient.
    // It probably means we should look at this whole area as a candidate for refactoring
    // how we share functionality (e.g. export to csv etc.) between the different grid views
    static fromIheVisit(
        visit: ITrackApiIHEFilesListVisitInfo
    ): PatientViewModel {
        let res: PatientViewModel = new PatientViewModel();
        res.lastName = visit?.patientInfo?.lastName;
        res.firstName = visit?.patientInfo?.firstName;
        res.chPatId = visit?.patientInfo?.chPatId?.toString();
        res.fullName = `${visit?.patientInfo?.lastName}, ${visit?.patientInfo?.firstName}`;
        res.dob = visit?.patientInfo?.dob ? moment(visit?.patientInfo?.dob).toDate() : null;
        res.gender = visit?.patientInfo?.gender;
        res.current_MBI = `${visit?.patientInfo?.mbi}`;
        res.mbi = `${visit?.patientInfo?.mbi}`;
        res.assignment = visit?.patientInfo?.attributionStatus;
        res.attributedCP = visit?.patientInfo?.attributedCp;
        // TODO - need this field
        // res.attributedCP = patient?.patientInfo?.attributedCP;
        res.IHEAWVVisitDate = visit.visitDate ? new Date(visit.visitDate).toLocaleDateString() : null;
        res.trackPatient = visit?.patientInfo;
        res.renderingProviderName = visit?.renderingProviderName;
        if (visit.fileInfo) {
            let fileInfoDatesResult = this.getLatestFileInfoDates(visit.fileInfo);
            this.assignLatestFileInfoDates(res, fileInfoDatesResult);

            if (res.trackPatient) {
                res.trackPatient.fileInfo = visit.fileInfo;
                res.trackPatient.visitInfo = visit;
                res.trackPatient.IHEAWVVisitDate = visit.visitDate;
            }
        }
        return res;
    }

    static fromIheBilling(
        patient: ITrackApiIHEBillingListVisitInfo
    ): PatientViewModel {
        let res: PatientViewModel = new PatientViewModel();
        res.fullName = patient.fullName;
        res.firstName = patient.firstName;
        res.lastName = patient.lastName;
        res.chPatId = patient.chPatId;
        res.current_MBI = patient.mbi;
        res.mbi = patient.mbi;
        res.dob = moment(patient.mbrDob).toDate();
        res.gender = patient.mbrGender == 'M' ? 'Male' : patient.mbrGender === 'F' ? 'Female' : 'Unknown';
        res.phoneNumber = patient.mbrPhone;
        res.address = PatientViewModel.patientAddressFromIHEAWVPatient(patient);
        res.IHEAWVBatchDate = moment(patient.reportDate).toDate();
        res.cptCode = patient.cptCode;
        res.iheDxCount = patient.dxCount?.toString();
        res.raF_YTD = patient.rafYtd?.toString();
        res.renderingProvider = `${patient?.renderingProviderLastName}, ${patient?.renderingProviderFirstName}`;
        res.attributedPractice = patient.facilityName;
        res.ccmEligible = patient.ccmEligible ? 'Eligible' : null;
        res.hccGapCount = patient.hccGapCount;
        res.predictedRiskLevel = patient.predictedRiskLevel;
        res.diseaseCohortIds = patient.chronicConditions ? patient.chronicConditions.split(',').map((e) => parseInt(e)) : [];
        res.totalCost = patient.totalCost?.toString();
        res.lastVisit = moment(patient.lastProviderVisit).toDate();
        res.edVisits = patient.edVisits?.toString();
        res.openRAFWt = patient.openRafWt;
        res.attributedCP = patient.attributedCp;
        res.IHEAWVVisitDate = patient.dos;
        res.claimFrequencyCode = patient.frequencyType === '1' ? 'New' : patient.frequencyType === '7' ? 'Corrected' : 'Voided';
        res.lastCCMDate = changeDateToYYYYMMDDorEmptyString(patient.lastCcmDate);
        res.lastCcmDate = changeDateToYYYYMMDDorEmptyString(patient.lastCcmDate);
        res.lastAWVDate = changeDateToYYYYMMDDorEmptyString(patient.lastAwvDate);
        return res;
    }


    static fromEdUtilizationPatient(
        ep: ITrackApiEdUtilizationPatient,
        tier: IHierarchyTier,
        additionalParams: {
            lastLogin: string;
            lastActivityAt: string;
            alertsCount: number;
            careId: string;
        }
    ): PatientViewModel {
        let res = new PatientViewModel();
        res.lastName = ep.patientName.split('; ')[0];
        res.firstName = ep.patientName.split('; ')[1];
        res.email = ep.email || null;
        res.patientName = `${ep.patientName}`;
        res.dob = ep.dob ? new Date(ep.dob) : null;
        res.gender = `${(ep.gender && ep.gender.toUpperCase()) || '?'}`;
        res.mbi = `${ep.mbi}`;
        res.attributedCP = ep.attributedCP;
        res.chPatId = ep.chPatID?.toString();
        res.lastVisit = ep.lastVisit;
        res.pyRaf = `${ep.pyRaf?.toFixed(3) || ''}`;
        res.totalCost = ep?.totalCost?.toString();
        res.assignment = ep.assignment;
        res.currentRiskLevel = ep.currentRiskLevel;
        res.predictedRiskLevel = ep.predictedRiskLevel;
        res.probability = ep.probability?.toFixed(2);
        res.riskTrend = ep.riskTrend;
        res.hccGapCount = ep.hccGapCount;
        res.ccmEligible = ep?.ccmEligible ? 'Eligible' : null;
        res.edVisits = `${ep.edVisits}`;
        res.actions = {
            alertsCount: additionalParams?.alertsCount,
            tier: tier,
            id: ep?.chPatID?.toString(),
            totalActionCounts: additionalParams?.alertsCount

        };
        res.careId = additionalParams?.careId;
        res.lastLogin = additionalParams?.lastLogin;
        res.diseaseCohorts = ep?.diseaseCohorts;
        res.lastActivityAt = additionalParams?.lastActivityAt;
        res.raF_YTD = `${ep.raF_YTD?.toFixed(3) || ''}`;
        res.openRAFWt = ep.openRAFWt;
        res.attributedPractice = ep.tier4_Name;
        res.awvTypeDue = ep.awvTypeDue;
        res.phoneNumber = Patient.formatPhoneNumber(ep.phone1);
        res.lastAWVDate = changeDateToYYYYMMDDorEmptyString(ep.lastAWVDate);

        return res;
    }

    static fromAwvAndCare(
        ap: ITrackApiAwvPatient,
        cp: IWPAPIPatient,
        tier: IHierarchyTier,
        additionalParams: {
            lastLogin: string;
            lastActivityAt: string;
            alertsCount: number;
            careId: string;
        }
    ): PatientViewModel {
        let res = new PatientViewModel();
        // CC-3159 track data now overrides care data
        if (cp) {
            Object.assign(res, {
                fullName: `${cp.lastName}, ${cp.firstName}`,
                lastName: cp.lastName,
                firstName: cp.firstName,
                dob: cp.dob ? new Date(cp.dob) : ap.dob,
                gender: (cp.gender && cp.gender.toUpperCase()) || '?',
                bp: `${cp.systolic || '-'}/${cp.diastolic || '-'}`,
                phoneNumber: Patient.formatPhoneNumber(cp.phoneNumber),
                professionalName: cp.professionalName,
                lastActivityAt: cp.lastActivityAt,
                src: 'wp',
                carePatient: cp,
                pulse: cp.pulse,
                bloodSugar: cp.bloodSugar || '-',
                patientId: cp._id,
                careId: additionalParams?.careId,
            });
        }
        if (ap) {
            let lastFirst = ap.patientName.split(',').map(x => x.trim());
            ap.rankedLastAwvDate = ap.rankedLastAwvDate ? moment(ap.rankedLastAwvDate).format("yyyy-MM-DD") : null;
            res.chPatId = `${ap.chPatID}`;
            res.supplementalId = `${ap.supplementalID}`;
            res.fullName = `${ap.patientName}`;
            res.lastName = lastFirst[0];
            res.firstName = lastFirst.length > 1 ? lastFirst[1] : null;
            res.dob = ap.dob ? new Date(ap.dob) : null;
            res.gender = (ap.gender && ap.gender[0].toUpperCase()) || '?';
            // patientId = ap.mbi; CC-2670 = Not sure why MBI used as PatientId ?
            res.pyRaf = `${ap.pyRaf?.toFixed(3) || ''}`;
            res.attributedCP = ap.attributedCP;
            res.awvTypeDue = ap.awvTypeDue;
            res.lastAWVDate = ap.lastAWVDate;
            res.lastClaimAWVDate = changeDateToYYYYMMDDorEmptyString(ap.lastAWVDate);
            res.awvStatusDisplay = ap.awvStatusDisplay;
            res.hasNotes = ap.hasNotes;
            res.trackAwvPatient = ap;
            res.actions = {
                alertsCount: additionalParams?.alertsCount,
                tier: tier,
                id: ap?.chPatID?.toString(),
                totalActionCounts: additionalParams?.alertsCount
            };
            res.email = ap.email;
            res.careId = additionalParams?.careId;
            res.lastLogin = additionalParams?.lastLogin;
            res.lastActivityAt = additionalParams?.lastActivityAt;
            res.mbi = ap.mbi;
            res.assignment = ap.assignment;
            res.currentRiskLevel = ap.currentRiskLevel;
            res.predictedRiskLevel = ap.predictedRiskLevel;
            res.probability = ap.probability?.toFixed(2);
            res.riskTrend = ap.riskTrend;
            res.hccGapCount = ap.hccGapCount;
            res.ccmEligible = ap?.ccmEligible ? 'Eligible' : null;
            res.diseaseCohorts = ap?.diseaseCohorts;
            res.raF_YTD = `${ap.raF_YTD?.toFixed(3) || ''}`;
            res.lastVisit = ap?.lastVisit;
            res.openRAFWt = ap.openRAFWt;
            res.attributedPractice = ap.tier4_Name;
            res.enrollmentType = ap.enrollmentType?.toLowerCase() == "cannot be determined" ? "Opted Out of Claims Sharing" : ap.enrollmentType;
            res.edVisits = ap.edVisits;
            res.inpatientVisits = ap.inpatientVisits;
            res.officeVisits = ap.officeVisits;
            res.ccmLastProcCode = ap.ccmLastProcCode;
            res.lastCcmDate = changeDateToYYYYMMDDorEmptyString(ap.lastCcmDate);
            res.lastCCMDateAndCode = ap.lastCcmDate || ap.ccmLastProcCode ? `${ap.lastCcmDate ? moment(ap.lastCcmDate).format("MM/DD/YYYY") : ''} ${ap.lastCcmDate && ap.ccmLastProcCode ? "-" : ""} ${ap.ccmLastProcCode || ''}` : null;
            res.phoneNumber = Patient.formatPhoneNumber(ap.phone1);
            res.totalCost = ap.totalCost ? `${ap.totalCost.toFixed(2)}` : null;
            res.lastAWVProvider = ap.lastAwvProvider;
            res.lastClaimAWVProvider = ap.lastAwvProvider;
            res.awvStatusId = ap.awvStatusId;
            res.ihAwvPatient = ap.ihAwvPatient ? 'Enrolled' : 'Not Enrolled';
            res.lastCsthDate = ap.lastCsthDate;
            res.allAWVPatientsListStatus = calculateWorkflowStatus(ap.coWorkflowStatus, ap.coWorkflowDueDate, ap.coWorkflowScheduledDate, ap.coWorkflowCompletedDate);
            if(ap.awvJson){
              res.ehrLastAwvCommunity = getDataFromObject(ap.awvJson,'community');
              res.lastEHRAWVIPPEDate = checkAndCutOffTimeFromDate(getDataFromObject(ap.awvJson, 'date'));
              res.ehrLastAwvProvider = getDataFromObject(ap.awvJson, 'provider');
              res.newStatusDate = getSelfReportDateFromAwv(ap.awvJson);
            }
            res.ehrReportedLastAwvSource = changeUIFriendlyName(ap.rankedLastAwvDateSource);
            res.ehrReportedLastAwvDate = checkAndCutOffTimeFromDate(ap.rankedLastAwvDate);
        }
        return res;
    }

    static fromIHEAWVSchedule(
        patient: IIHEAWVPatient
    ): PatientViewModel {
        let res: PatientViewModel = new PatientViewModel();
        // CC-3159 track values override care values
        if (patient) {
            res.chPatId = patient.chPatId?.toString();
            res.fullName = `${patient.lastName}, ${patient.firstName}`;
            res.lastName = patient.lastName;
            res.firstName = patient.firstName;
            res.gender = Patient.displayGender(patient.mbrGender);
            res.mbi = patient.mbi;
            res.dob = patient.dob ? new Date(patient.dob) : null;
            res.phoneNumber = patient.mbrTelephone;
            res.address = this.patientAddressFromIHEAWVPatient(patient);
            res.scheduleStatus = patient.outreachSchedulingStatus;
            res.schedulingDescription = patient.outreachSchedulingDesc;
            res.callDate = patient.outreachCallDateTime;
            res.dateOfService = patient.dos;
            res.assignment = patient.assignment;
            res.renderingProvider = patient.evalProviderName;
            res.attributedPractice = patient.tier4Name;
            res.attributedCP = patient.attributedCp;
            res.hccGapCount = patient.hccGapCount;
            res.predictedRiskLevel = patient.predictedRiskLevel;
            res.lastAWVDate = changeDateToYYYYMMDDorEmptyString(patient.lastAwvDate);
            res.lastCCMDate = changeDateToYYYYMMDDorEmptyString(patient.lastCcmDate);
            res.ccmEligible = patient.ccmEligible ? 'Eligible' : null;
            res.lastVisit = patient.lastVisit ? moment(patient.lastVisit).toDate() : null;
            res.diseaseCohorts = patient.diseaseCohorts;
            res.totalCost = patient.totalCost?.toString();
            res.edVisits = patient.edVisits?.toString();
            res.openRAFWt = patient.openRafWt;
            res.raF_YTD = patient.rafYtd?.toString()
        }
        return res;
    }

    // =============================== helpers ===============================

    static assignLatestFileInfoDates(patient: PatientViewModel, fileInfoDates: {
        mostRecentAssessmentFileUpdatedMoment: moment.Moment,
        lastFullDownloadOfAssessmentFilesMoment: moment.Moment,
        mostRecentCmrFileUpdatedMoment: moment.Moment,
        lastDownloadOfCmrFileMoment: moment.Moment,
        lastDownloadOfAnyFileMoment: moment.Moment
        notOnlyCMRFile: boolean;
    }): IHEDownloadStatusObject {
        let result: IHEDownloadStatusObject = {
            IHEAssessmentFilesLastUpdated: fileInfoDates.mostRecentAssessmentFileUpdatedMoment?.toDate(),
            IHEAssessmentFilesLastDownloaded: fileInfoDates.lastFullDownloadOfAssessmentFilesMoment?.toDate(),
            IHECMRFileLastUpdated: fileInfoDates.mostRecentCmrFileUpdatedMoment?.toDate(),
            IHECMRFileLastDownloaded: fileInfoDates.lastDownloadOfCmrFileMoment?.toDate(),
            IHEDownloadDate: fileInfoDates.lastDownloadOfAnyFileMoment?.toDate(),
            notOnlyCMRFile: fileInfoDates.notOnlyCMRFile
        };
        if (patient) {
            patient.notOnlyCMRFile = fileInfoDates.notOnlyCMRFile;
            patient.IHEAssessmentFilesLastUpdated = fileInfoDates.mostRecentAssessmentFileUpdatedMoment?.toDate();
            patient.IHEAssessmentFilesLastDownloaded = fileInfoDates.lastFullDownloadOfAssessmentFilesMoment?.toDate();
            patient.IHECMRFileLastUpdated = fileInfoDates.mostRecentCmrFileUpdatedMoment?.toDate();
            patient.IHECMRFileLastDownloaded = fileInfoDates.lastDownloadOfCmrFileMoment?.toDate();
            // patient.IHELastDownloadDateofAnyFile = fileInfoDates.lastDownloadOfAnyFileMoment?.toDate();
            if (fileInfoDates.lastDownloadOfAnyFileMoment && !(patient.IHEDownloadDate && moment(patient.IHEDownloadDate).isAfter(fileInfoDates.lastDownloadOfAnyFileMoment))) {
                patient.IHEDownloadDate = fileInfoDates.lastDownloadOfAnyFileMoment.toDate();
            }
        }
        return result;
    }
    static getLatestFileInfoDates(fileInfo: PatientIHEFileInfo[]): {
        mostRecentAssessmentFileUpdatedMoment: moment.Moment,
        lastFullDownloadOfAssessmentFilesMoment: moment.Moment,
        mostRecentCmrFileUpdatedMoment: moment.Moment,
        lastDownloadOfCmrFileMoment: moment.Moment,
        lastDownloadOfAnyFileMoment: moment.Moment,
        notOnlyCMRFile: boolean
    } {
        let mostRecentAssessmentFileUpdatedMoment: moment.Moment = null;
        let lastFullDownloadOfAssessmentFilesMoment: moment.Moment = null;
        let mostRecentCmrFileUpdatedMoment: moment.Moment = null;
        let lastDownloadOfCmrFileMoment: moment.Moment = null;
        let lastDownloadOfAnyFileMoment: moment.Moment = null;
        let notOnlyCMRFile = true;
        let assessmentNotFound: boolean = false;
        fileInfo.forEach(fi => { //changed map to forEach
            let downloadedAtUtc = fi.downloadInfo?.current?.downloadedAtUtc ? moment.utc(fi.downloadInfo?.current?.downloadedAtUtc) : null;
            let updatedAtUtc = fi.availabilityInfo?.current?.statusDateUtc ? moment.utc(fi.availabilityInfo?.current?.statusDateUtc) : null;
            if (downloadedAtUtc && !(lastDownloadOfAnyFileMoment?.isAfter(downloadedAtUtc))) {
                lastDownloadOfAnyFileMoment = downloadedAtUtc;
            }
            if (fi.fileType == "Casemgmt") {
                lastDownloadOfCmrFileMoment = downloadedAtUtc;
                mostRecentCmrFileUpdatedMoment = updatedAtUtc;
            } else {
                notOnlyCMRFile = false;
                if (!downloadedAtUtc || assessmentNotFound || (updatedAtUtc && downloadedAtUtc && downloadedAtUtc.isBefore(updatedAtUtc))) { // ned explanation here
                    assessmentNotFound = true;
                    mostRecentAssessmentFileUpdatedMoment = null;
                    lastFullDownloadOfAssessmentFilesMoment = null;
                } else {
                    mostRecentAssessmentFileUpdatedMoment = updatedAtUtc;
                    lastFullDownloadOfAssessmentFilesMoment = downloadedAtUtc;
                }
            }
        });

        return { mostRecentAssessmentFileUpdatedMoment, lastFullDownloadOfAssessmentFilesMoment, mostRecentCmrFileUpdatedMoment, lastDownloadOfCmrFileMoment, lastDownloadOfAnyFileMoment, notOnlyCMRFile };
    };
    static patientAddressFromIHEAWVPatient(patient: IIHEAWVPatient | ITrackApiIHEBillingListVisitInfo): string {
        let addressFields = [patient.mbrAddressLine1, patient.mbrAddressLine2, patient.mbrCity, patient.mbrState, patient.mbrZip];
        let addressString = '';
        addressFields.forEach((field: string, index: number) => {
            if (field && index != addressFields.length - 1) {
                addressString += field + ', ';
            } else if (field) {
                addressString += field;
            }
        });
        return addressString;
    }
  }
export enum AwvWorkflowStatusForPatientListDisplay {
  AllPatients = 'ALL_PATIENTS', // Used on front when user selects all patients button
  ReadyToSchedule = 'READY_TO_SCHEDULE',
  CallBackLater = 'CALL_BACK_LATER',
  ReadyToRemind = 'READY_TO_REMIND',
  Scheduled = 'SCHEDULED',
  ReadyToSelfReport = 'READY_TO_SELF_REPORT',
  Completed = 'COMPLETED',
  Ineligible = 'INELIGIBLE',
  PatientDeclined = 'PATIENT_DECLINED',
  Other = 'OTHER',
  Unknown = 'UNKNOWN'
}

export enum sourceNametoDisplay{
  CARE_ORCH_SELF_REPORTED = 'Self-Reported',
  PEP_SELF_REPORTED = 'Self-Reported',
  CLINICAL_DATA = 'EHR Feed',
  CMS_CLAIMS_DATA = 'CMS Claims'
}

export function calculateWorkflowStatus(
  COWorkflowStatus: COWorkflowStatus,
  COWorkflowDueDate: string,
  COWorkflowScheduledDate: string,
  COWorkflowCompletedDate: string,
  Now?: Date
  ): AwvWorkflowStatusForPatientListDisplay {
    if(!Now) Now = moment().locale('en').toDate();
    if(COWorkflowStatus === 'READY_FOR_OUTREACH')
    return AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule;
    if(COWorkflowStatus === 'OUTREACH_ATTEMPTED' && moment(Now).locale('en').isSameOrAfter(moment(COWorkflowDueDate).locale('en')))
    return AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule;
    if(COWorkflowStatus === 'OUTREACH_ATTEMPTED' && moment(Now).locale('en').isBefore(moment(COWorkflowDueDate).locale('en')))
    return AwvWorkflowStatusForPatientListDisplay.CallBackLater;
    if(COWorkflowStatus === 'SCHEDULED' && moment(Now).locale('en').isSameOrAfter(moment(COWorkflowDueDate).locale('en')) && moment(Now).locale('en').isBefore(moment(COWorkflowScheduledDate).locale('en')))
    return AwvWorkflowStatusForPatientListDisplay.ReadyToRemind;
    if(COWorkflowStatus === 'SCHEDULED' && moment(Now).locale('en').isSameOrBefore(moment(COWorkflowDueDate).locale('en')))
    return AwvWorkflowStatusForPatientListDisplay.Scheduled;
    if(COWorkflowStatus === 'SCHEDULED' && moment(Now).locale('en').isSameOrAfter(moment(COWorkflowScheduledDate).locale('en')))
    return AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport;
    if(COWorkflowStatus === 'COMPLETED')
    return AwvWorkflowStatusForPatientListDisplay.Completed;
    if(COWorkflowStatus === 'INELIGIBLE')
    return AwvWorkflowStatusForPatientListDisplay.Ineligible;
    if(COWorkflowStatus === 'DECLINED')
    return AwvWorkflowStatusForPatientListDisplay.PatientDeclined;
    if(COWorkflowStatus === 'DEFERRED_TO_SIGNIFY')
    return AwvWorkflowStatusForPatientListDisplay.Other;
    return AwvWorkflowStatusForPatientListDisplay.Unknown;
}

export function getDisplayStringForAWVWorklistStatusEnum(status: string): string {
  return AWVWorklistDisplayStatusEnum[status] || status;
};

function getDataFromObject(awvJson: AWVJsonData[], dataType: 'date' | 'provider' | 'community'): string{
  let object;
  if(awvJson[0].Source === 'CLINICAL_DATA'){
    object = awvJson[0];
  }else if(awvJson[1]&& awvJson[1].Source === 'CLINICAL_DATA'){
    object = awvJson[1];
  } else{
    return '';
  }
  switch (dataType) {
    case 'date':
      return object.Date;
    case 'provider':
      return object.Provider;
    case 'community':
      return object.EHRTierName;
    default:
      return '';
  }
}
function changeDateToYYYYMMDDorEmptyString(input: string): string{
  if(!input) return '';

  if(moment(input).isValid()){
    return moment.utc(input).format("YYYY-MM-DD");
  } else {
      return '';
  }
}
function getSelfReportDateFromAwv(awvJson: AWVJsonData[]): string{
  for (let i = 0; i < awvJson.length; i++) {
    if(awvJson[i].Source == 'SELF_REPORTED') return checkAndCutOffTimeFromDate(awvJson[i].Date);
  }
  return null;
}
function checkAndCutOffTimeFromDate(input:string):string{
  if(!input) return null;

  if(moment(input).isValid()){
    return moment.utc(input).format("YYYY-MM-DD");
  } else {
      // Invalid date format, return the input as is.
      return input;
  }
}
function changeUIFriendlyName(rankedLastAwvDateSource: string): sourceNametoDisplay{
  return sourceNametoDisplay[rankedLastAwvDateSource] || null;
}
