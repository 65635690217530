import { Injectable } from '@angular/core';
import { AttributionFilterModel } from '@shared/components/attribution-filter-pane/attribution-filter-model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HccDashboardFilterService {

  private filterModel = new BehaviorSubject<AttributionFilterModel>(undefined);
  public filterModel$ = this.filterModel.asObservable();

  constructor() { }

  public setFilterModel(filterModel: AttributionFilterModel): void {
    this.filterModel.next(filterModel);
  }
}
