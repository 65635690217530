import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  HostListener,
  Injector,
  ElementRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  faPhone,
  faSms,
  faChartLine,
  faCommentDots,
  faEnvelope,
  faStop
} from '@fortawesome/free-solid-svg-icons';
import { startOfMonth, endOfMonth, subMonths } from 'date-fns';
import {

  ComponentPortal,
  PortalInjector,
} from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

import { Patient } from '@shared/models/patient';
import {
  ITimeTrackingEvent,
  PatientService,
} from '@shared/services/patient/patient.service';
import {
  TimeTrackingDropdownComponent,
  NAVIGATION_PORTAL_DATA,
} from './patient-time-tracking-dropdown/patient-time-tracking-dropdown.component';
import { HeaderCondensedService } from '@shared/services/header-condensed/header-condensed.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import _ from 'lodash';
import { timeTrackingInputLength } from "@shared/modules/patient-facesheet/patient-facesheet.constants"
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { ToastrService } from 'ngx-toastr';

interface TimeTrackingEvent extends ITimeTrackingEvent {
  /** Used only for data binding to an input control */
  durationMinsText?: string;
  durationMins: number;
  startDatePicker?: string;
}

@Component({
  selector: 'coach-patient-time-tracking',
  templateUrl: './patient-time-tracking.component.html',
  styleUrls: ['./patient-time-tracking.component.scss'],
  providers: [Overlay],
})
export class PatientTimeTrackingComponent implements OnInit {
  @HostListener('window:beforeunload')
  beforeUnloadHandler(): void {
    // this is triggered in case if bookmark/browser/window is closed
    if (this.differenceCounter) {
      this.stopTimer(false);
    }
  }
  @Input()
  public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input() public desktopSize: boolean;
  private get tierId(): string {
    return this?.tier?.selectedTierId;
  }

  // View model
  public today: Date = null;
  public startOfMonth: Date = null;
  public endOfMonth: Date = null;
  public startCounter: Date = null;
  public differenceCounter: Date = null;
  time: number = 0;
  showDeleted = false;
  display;
  interval;
  timeTrackerActionLabels: object = {
    chart: 'Update chart',
    email: 'Send Email',
    phone: 'Call patient',
    sms: 'Send SMS',
    other: 'Other',
  };
  patientId: string;
  patientSrc: string;
  newTimeTrackingEvent: TimeTrackingEvent;
  currentTimeTrackingEvent: TimeTrackingEvent;
  timeTrackerActionComment: string = '';
  timeTrackerTotal: number = 0;
  timeTrackerEvents: any = {};
  timeTrackerEvents_loadingStatus: boolean;
  deletedTimeTrackerEvents: any = {};
  deletedTimeTrackerEvents_loadingStatus: boolean;

  modalAddTimeDisplayBool: boolean = false;
  modalEditTimeDisplayBool: boolean = false;
  modalViewDetailsDisplayBool: boolean = false;
  modalMessageDisplayBool: boolean = false;
  modalMobileEndTimerBool: boolean = false;
  modalMessage: any = {};


  // Icons
  faPhone = faPhone;
  faSms = faSms;
  faChartLine = faChartLine;
  faCommentDots = faCommentDots;
  faEnvelope = faEnvelope;
  faStop = faStop;


  private overlayRef: OverlayRef;
  commentMaxLength = timeTrackingInputLength.comment
  mask = new MaskPipe();
  public totalNoOfDeletedEvents = 0;
  constructor(
    private _route: ActivatedRoute,
    private _patientService: PatientService,
    private _injector: Injector,
    public overlay: Overlay,
    private vcRef: ViewContainerRef,
    private HeaderCondensedService: HeaderCondensedService,
    private toastr: ToastrService
  ) {
    this.today = new Date();
    this.startOfMonth = startOfMonth(this.today);
    this.endOfMonth = endOfMonth(this.today);
    this.resetNewTimeTrackingEvent();
  }
  @ViewChild('scopeSelectOrigin') scopeSelectOrigin: ElementRef;
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.patient && changes.patient.currentValue) {
      this.patientSrc = this._route.snapshot.paramMap.get('src');
      this.patientId = this.patient.chPatId;
      this.getTimeTrackingEvents(this.patientId);
      this.getDeletedTimeTrackingEvents(this.patientId);
    }
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // this is triggered on page change
    if (this.differenceCounter) {
      this.stopTimer(false);
    }
  }

  // ------------------------------------------------------------------------------
  // ----------------------------- LISTENERS --------------------------------------
  // ------------------------------------------------------------------------------
  startTimer(): void {
    this.startCounter = new Date();
    this.resetNewTimeTrackingEvent();
    this.newTimeTrackingEvent.start = this.startCounter.toISOString();
    this.interval = setInterval(async () => {
      let currentTime = new Date();
      let difference = currentTime.getTime() - this.startCounter.getTime();
      this.differenceCounter = new Date(difference);
      let seconds = Math.floor(this.differenceCounter.getTime() / 1000);
      if (seconds / 60 >= 1 && seconds % 60 == 0) {
        this.newTimeTrackingEvent.durationSeconds = Math.floor(
          this.differenceCounter.getTime() / 1000
        );
        // CC-1858 await isn't strictly needed here but adding it in case more code is
        // added after it.
        await this.updateTrackingEvent(this.newTimeTrackingEvent, false, 'timer');
      }
    }, 1000);
  }
  /**
   * @param mobile a boolean that opens modalMobileEndTimer in order to enter a time entry inputs such as comment, type, and etc
   */
  async stopTimer(mobile: boolean) {
    clearInterval(this.interval);
    // 2 second grace
    if (this.differenceCounter.getTime() / 1000 >= 2) {
      // add 1 minute if less than that. multiply by 60 since its stored in seconds
      this.newTimeTrackingEvent.durationSeconds = this.roundUpMinutes(
        this.differenceCounter.getTime(), 'ms') * 60;
      if (mobile) {
        this.openModal('modalMobileEndTimerBool', true);
      }
      await this.updateTrackingEvent(this.newTimeTrackingEvent, !mobile, 'timer');
      if (!mobile) {
        this.resetNewTimeTrackingEvent();
      }
    }
    this.differenceCounter = null;
  }

  roundUpMinutes(time: number, type: 'ms' | 'sec'): number {
    return Math.ceil(type == 'ms' ? time / 60000 : time / 60);
  }
  openCloseModal(target: any): void {
    this[target] = !this[target];
  }
  closeModalOnBackgroundClick(target: string, event: any): void {
    if (event.target.getAttribute('role') == 'modal-background') {
      this[target] = false;
    }
  }
  openEditModal(target: TimeTrackingEvent): void {
    this.modalViewDetailsDisplayBool = false;
    this.currentTimeTrackingEvent = JSON.parse(JSON.stringify(target));
    this.currentTimeTrackingEvent.durationMinsText = this.currentTimeTrackingEvent.durationMins.toString()
    this.openModal('modalEditTimeDisplayBool');
  }
  selectMonth(pivot: number): void {
    // History pagination
    this.startOfMonth = subMonths(this.startOfMonth, pivot);
    this.endOfMonth = endOfMonth(this.startOfMonth);
    this.getTimeTrackingEvents(this.patientId);
  }
  export(action: string): void {
    let data = `"Patient Name: ${this.patient.lastName}, ${this.patient.firstName}"\n`;
    data += `"DOB: ${this.patient.dob}"\n`;
    data += `"MRN: ${this.patient.mrn ? this.mask.transform(this.patient.mrn, 6) : 'not found'}"\n`;
    data += `"Time Tracker Report for ${this.startOfMonth.getMonth() + 1
      }/${this.startOfMonth.getUTCFullYear()}"\n`;
    data += `Total Time: ${this.timeTrackerTotal} minutes \n`;
    data += '"Created At","Duration in Minutes","Activity","Notes","Created By"\n';
    for (let day in this.timeTrackerEvents) {
      for (let event in this.timeTrackerEvents[day].events) {
        let entry = this.timeTrackerEvents[day].events[event];
        let createdAt = new Date(entry.createdAt);
        let escapedNotes = entry.notes ? entry.notes.replaceAll(',', ' ') : '';
        data += `"${createdAt.getFullYear()}-${createdAt.getMonth() + 1
          }-${createdAt.getDate()}", ${Math.floor(entry.durationSeconds / 60)}, ${this.timeTrackerActionLabels[entry.activity]
          }, ${escapedNotes}, ${this.getCreatorFullNameAsString(entry.createdByName) || 'name not found'}\n`;
      }
    }
    if (action == 'copy') {
      let el = document.createElement('textarea');
      el.value = data;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    } else {
      let filename = `PHI_TimeReport_${this.patient.lastName},${this.patient.firstName
        }_${this.startOfMonth.getMonth() + 1
        }/${this.startOfMonth.getUTCFullYear()}.csv`;
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
      hiddenElement.target = '_blank';
      hiddenElement.download = filename;
      hiddenElement.click();
    }
  }
  async updateEntry(event: TimeTrackingEvent, inputType: 'direct' | 'timer') {
    if ("direct" === inputType) {
      // Sync numeric value with bound text
      event.durationMins = parseInt(event.durationMinsText);
    }
    event.durationSeconds =
      event.durationMins != 0
        ? event.durationMins * 60
        : event.durationSeconds || 0;
    //need to compare if startDatePicker value was changed
    let tempStartValue: any = new Date(
      new Date(event.start).setHours(0, 0, 0, 0)
    );
    tempStartValue = tempStartValue.toISOString().split('T')[0];
    if (tempStartValue != event.startDatePicker) {
      let newDateStamp = new Date();
      newDateStamp.setFullYear(Number(event.startDatePicker.split('-')[0]));
      newDateStamp.setMonth(Number(event.startDatePicker.split('-')[1]) - 1);
      newDateStamp.setDate(Number(event.startDatePicker.split('-')[2]));
      event.start = newDateStamp.toISOString();
    }
    await this.updateTrackingEvent(event, false, inputType);
    this.resetNewTimeTrackingEvent();
  }
  async deleteEntry(event: TimeTrackingEvent) {
    await this.deleteTrackingEvent(event);
  }

  displayMessage(title: string, body: object): void {
    this.modalMessage = {
      title: title,
      body: body,
    };
    this.openModal('modalMessageDisplayBool');
  }
  getCreatorFullNameAsString(createdByName: { firstName: string, lastName: string }): string {
    return `${createdByName?.lastName?.length > 0 ? createdByName.lastName : ''}${createdByName?.firstName?.length > 0 ? " " + createdByName?.firstName : ''}`
  }
  // ------------------------------------------------------------------------------
  // --------------------------- BACKEND CALLS ------------------------------------
  // ------------------------------------------------------------------------------
  async getTimeTrackingEvents(chPatId: string) {
    try {
      this.timeTrackerEvents_loadingStatus = true;
      let allTimeTrackingEvents: any = await this._patientService.getTimeTrackingEvents(
        this.tierId,
        chPatId,
        this.startOfMonth,
        this.endOfMonth
      );
      allTimeTrackingEvents.sort((a: any, b: any) => {
        let valA = new Date(a.start);
        let valB = new Date(b.start);
        return valA > valB;
      });
      this.timeTrackerEvents = {};
      this.timeTrackerTotal = 0;
      allTimeTrackingEvents.forEach((event) => {
        event.durationMins = Math.floor((event.durationSeconds || 0) / 60);
        let startDatePicker = new Date(
          new Date(event.start).setHours(0, 0, 0, 0)
        ); // need to have a separate value for a datipicker because of time format. Datepicker won't accept dates with time stamp
        event.startDatePicker = startDatePicker.toISOString().split('T')[0];
        event.old_start = startDatePicker.toISOString().split('T')[0];
        this.timeTrackerTotal += this.roundUpMinutes(
          event.durationSeconds || 0,
          'sec'
        );
        let dateKey = new Date(
          new Date(event.start).setHours(0, 0, 0, 0)
        ).getTime();
        if (!this.timeTrackerEvents[dateKey]) {
          this.timeTrackerEvents[dateKey] = {
            date: dateKey,
            events: [],
          };
        }
        this.timeTrackerEvents[dateKey].events.push(event);
      });
      this.timeTrackerEvents_loadingStatus = false;
    } catch (err) {
      // TODO - can there be a better way of handling different error types that could be thrown?
      const message = err.error?.message || err.status || err.message;
      this.toastr.error(
        `I couldn't get time tracking events. If this error persists, please contact support: ${message}`
      );
    }
  }

  async getDeletedTimeTrackingEvents(chPatId: string) {
    try {
      this.deletedTimeTrackerEvents_loadingStatus = true;
      let allTimeTrackingEvents: any = await this._patientService.getDeletedTimeTrackingEvents(
        this.tierId,
        chPatId,
        this.startOfMonth,
        this.endOfMonth
      );
      allTimeTrackingEvents.sort((a: any, b: any) => {
        let valA = new Date(a.start);
        let valB = new Date(b.start);
        return valA > valB;
      });
      this.deletedTimeTrackerEvents = {};
      allTimeTrackingEvents.forEach((event) => {
        event.durationMins = Math.floor((event.durationSeconds || 0) / 60);
        let startDatePicker = new Date(
          new Date(event.start).setHours(0, 0, 0, 0)
        ); // need to have a separate value for a datipicker because of time format. Datepicker won't accept dates with time stamp
        event.startDatePicker = startDatePicker.toISOString().split('T')[0];
        event.old_start = startDatePicker.toISOString().split('T')[0];
        let dateKey = new Date(
          new Date(event.start).setHours(0, 0, 0, 0)
        ).getTime();
        if (!this.deletedTimeTrackerEvents[dateKey]) {
          this.deletedTimeTrackerEvents[dateKey] = {
            date: dateKey,
            events: [],
          };
        }
        this.deletedTimeTrackerEvents[dateKey].events.push(event);
        this.totalNoOfDeletedEvents++;
      });
      this.deletedTimeTrackerEvents_loadingStatus = false;
    } catch (err) {
      // TODO - can there be a better way of handling different error types that could be thrown?
      const message = err.error?.message || err.status || err.message;
      this.toastr.error(
        `I couldn't get deleted time tracking events. If this error persists, please contact support: ${message}`
      );
    }
  }
  async updateTrackingEvent(
    targetEvent: TimeTrackingEvent,
    openModal: boolean,
    source: 'direct' | 'timer'
  ) {
    targetEvent.patientId = this.patientId;
    targetEvent.source = source;
    try {
      let newTimeEvent: any = await this._patientService.uploadTimeTrackingEvent(
        this.tierId,
        targetEvent
      );
      if (!newTimeEvent) {
        return;
      }
      targetEvent._id
        ? this.updateExistingTimeEntry(targetEvent, newTimeEvent)
        : this.addNewTimeEntry(newTimeEvent);
      this.timeTrackerTotal += this.roundUpMinutes(
        newTimeEvent.durationSeconds || 0,
        'sec'
      );
      if (openModal) {
        let dateStamp = new Date(newTimeEvent.start);
        this.displayMessage(
          `New entry added for ${dateStamp.getMonth() + 1
          }/${dateStamp.getDate()}/${dateStamp.getFullYear()}`,
          {
            'Recorded time': this.roundUpMinutes(newTimeEvent.durationSeconds, 'sec'),
            Activity: `${this.timeTrackerActionLabels[newTimeEvent.activity]}`,
            Comments: `${newTimeEvent.notes ? newTimeEvent.notes : '-'}`,
          }
        );
      }
    } catch (err) {
      // TODO - can there be a better way of handling different error types that could be thrown?
      const message = err.error?.message || err.status || err.message;
      alert(
        `I couldn't save the time tracking event. If this error persists, please contact support: ${message}`
      );
    }
  }


  OnDeleteEntryClick(): void {
    this.modalEditTimeDisplayBool = false;
    this.deleteEntry(this.currentTimeTrackingEvent);
    this.openModal('modalViewDetailsDisplayBool');
  }
  async deleteTrackingEvent(
    targetEvent: TimeTrackingEvent) {
    targetEvent.patientId = this.patientId;
    try {
      await this._patientService.deleteTimeTrackingEvent(
        this.tierId,
        targetEvent
      );
      this.addDeletedTimeEntry(targetEvent);
    } catch (err) {
      // TODO - can there be a better way of handling different error types that could be thrown?
      const message = err.error?.message || err.status || err.message;
      alert(
        `I couldn't save the time tracking event. If this error persists, please contact support: ${message}`
      );
    }
  }
  /** Refresh view model after  entry delete */
  addDeletedTimeEntry(timeEvent: TimeTrackingEvent): void {
    let date;
    for (date in this.timeTrackerEvents) {
      for (let event in this.timeTrackerEvents[date].events) {
        this.timeTrackerEvents[date].events = this.timeTrackerEvents[date]
          .events.filter((item) => item._id !== timeEvent._id);
        if (this.timeTrackerEvents[date].events.length === 0) {
          delete this.timeTrackerEvents[date];
        }
      }
    }
    //deduct time
    this.timeTrackerTotal -= this.roundUpMinutes(
      timeEvent.durationSeconds || 0,
      'sec'
    );
    //add to delete list
    if (!this.deletedTimeTrackerEvents[date]) {
      this.deletedTimeTrackerEvents[date] = {
        date: date,
        events: [],
      };
    }
    this.deletedTimeTrackerEvents[date].events.push(timeEvent);
    this.totalNoOfDeletedEvents++;
  }
  /** Refresh view model after new entry added */
  addNewTimeEntry(newTimeEvent: TimeTrackingEvent): void {
    this.newTimeTrackingEvent._id = newTimeEvent._id;
    let newDateKey = new Date(
      new Date(newTimeEvent.start).setHours(0, 0, 0, 0)
    );
    let startDatePicker = new Date(
      new Date(newTimeEvent.start).setHours(0, 0, 0, 0)
    );
    newTimeEvent.startDatePicker = startDatePicker.toISOString().split('T')[0];
    newTimeEvent.durationMins = Math.ceil(
      (newTimeEvent.durationSeconds || 0) / 60
    );
    if (!this.timeTrackerEvents[newDateKey.getTime()]) {
      this.timeTrackerEvents[newDateKey.getTime()] = {
        date: newDateKey,
        events: [],
      };
    }
    this.timeTrackerEvents[newDateKey.getTime()].events.push(newTimeEvent);
  }
  /** Refresh view model after entry updated */
  updateExistingTimeEntry(
    timeEvent: TimeTrackingEvent,
    newTimeEvent: TimeTrackingEvent
  ): void {
    let newDateKey = new Date(
      new Date(newTimeEvent.start).setHours(0, 0, 0, 0)
    );
    let startDatePicker = new Date(
      new Date(newTimeEvent.start).setHours(0, 0, 0, 0)
    );
    newTimeEvent.startDatePicker = startDatePicker.toISOString().split('T')[0];
    newTimeEvent.durationMins = Math.floor(
      (newTimeEvent.durationSeconds || 0) / 60
    );
    newTimeEvent.createdByName = timeEvent.createdByName
    for (let date in this.timeTrackerEvents) {
      for (let event in this.timeTrackerEvents[date].events) {
        if (timeEvent._id == this.timeTrackerEvents[date].events[event]._id) {
          this.timeTrackerTotal -= this.roundUpMinutes(
            this.timeTrackerEvents[date].events[event].durationSeconds || 0,
            'sec'
          );
          if (
            this.timeTrackerEvents[date].events[event].start ===
            newTimeEvent.start
          ) {
            // if dates match
            this.timeTrackerEvents[date].events[event] = newTimeEvent;
          } else if (
            this.timeTrackerEvents[date].events[event].start !==
            newTimeEvent.start
          ) {
            // if dates don't match
            // removes old entry from date list
            this.timeTrackerEvents[date].events = this.timeTrackerEvents[
              date
            ].events.filter((item) => item._id !== timeEvent._id);
            // remove date list if it's empty
            if (this.timeTrackerEvents[date].events.length === 0) {
              delete this.timeTrackerEvents[date];
            }
            // add a new value into another date list
            if (!this.timeTrackerEvents[newDateKey.getTime()]) {
              this.timeTrackerEvents[newDateKey.getTime()] = {
                date: newDateKey,
                events: [],
              };
            }
            this.timeTrackerEvents[newDateKey.getTime()].events.push(
              newTimeEvent
            );
          }
          break;
        }
      }
    }
  }
  // ------------------------------------------------------------------------------
  // ------------------------------- MISC -----------------------------------------
  // ------------------------------------------------------------------------------
  openModal(target: string, closeSidebar?: boolean): void {
    this[target] = true;
    // make modal closable and close sidebar
    if (closeSidebar) {
      this.HeaderCondensedService.setSidebarStatus(true);
    }
  }
  getObjectLength(obj: object): number {
    return Object.keys(obj).length;
  }

  resetNewTimeTrackingEvent(): void {
    let startDatePicker = new Date(new Date().setHours(0, 0, 0, 0)); // need to have a separate value for a datipicker because of time format. Datepicker won't accept dates with time stamp
    this.newTimeTrackingEvent = {
      activity: 'other',
      durationMins: 0,
      durationSeconds: 0,
      notes: '',
      start: null,
      startDatePicker: startDatePicker.toISOString().split('T')[0],
    };
  }
  getValueType(value: any): string {
    return typeof value;
  }
  scopeSelectDropdownPortal: ComponentPortal<TimeTrackingDropdownComponent>;
  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(NAVIGATION_PORTAL_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }
  public openScopeSelectDropdown() {
    const scopeSelectDropdownPortal = new ComponentPortal(
      TimeTrackingDropdownComponent,
      this.vcRef,
      this.createInjector({
        emitter: this.dropDownEmitterFunc,
      })
    );
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.scopeSelectOrigin)
        .withPositions([ { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]),
    });
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(scopeSelectDropdownPortal);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    this.overlayRef
      .outsidePointerEvents()
      .subscribe(() => this.overlayRef.detach());
  }
  get dropDownEmitterFunc() {
    return this.dropDownEmitter.bind(this);
  }

  dropDownEmitter(action: string): void {
    this.overlayRef.detach();
    switch (action) {
      case 'startTimer':
        this.startTimer();
        break;
      case 'addTime':
        this.openModal('modalAddTimeDisplayBool', true);
        break;
      case 'viewDetails':
        this.showDeleted = false;
        this.openModal('modalViewDetailsDisplayBool', true);
        break;
    }
  }

  get disableNewEventSave() {
    return !this.isValidDurationMins(this.newTimeTrackingEvent?.durationMinsText) || !this.newTimeTrackingEvent?.startDatePicker;
  }

  get disableCurrentEventSave() {
    return !this.isValidDurationMins(this.currentTimeTrackingEvent?.durationMinsText) || !this.currentTimeTrackingEvent?.startDatePicker;
  }

  addTimeToNewEvent(mins: number) {
    this.addTimeToEvent(this.newTimeTrackingEvent, mins);
  }

  addTimeToCurrentEvent(mins: number) {
    this.addTimeToEvent(this.currentTimeTrackingEvent, mins);
  }

  addTimeToEvent(event: TimeTrackingEvent, mins: number) {
    event.durationMins = parseInt(event.durationMinsText || "0");
    if (isNaN(event.durationMins)) {
      return;
    }
    event.durationMins += mins;
    if (event.durationMins > 999) {
      event.durationMins = 999;
    }
    event.durationMinsText = event.durationMins.toString();
  }

  isValidDurationMins(val: string): boolean {
    // Good to know that:
    // parseInt("2x") => 2, not NaN, so using a regex check instead
    return /^[1-9][0-9]{0,2}$/.test(val);
  }
}
