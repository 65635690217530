import { Component, Input, OnInit } from '@angular/core';

/**
 * Used to display an indeterminate loading spinner.
 *
 * @example 
 * <coach-loading-indicator
 *                [size]="40">
 * </coach-loading-indicator>
 */
@Component({
  selector: 'coach-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  host: {
    class: 'coach-loading-indicator'
  }
})
export class LoadingIndicatorComponent implements OnInit {

  /** Height and width in pixels of the loading indicator */
  @Input() size = 20;

  constructor() { }

  ngOnInit(): void {
  }

}
