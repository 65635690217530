import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ClassesJoinTrimPipe } from '@shared/pipes/classes-join-trim.pipe';

/**
 * Component used for displaying icons. Icon definitions are stored in the 
 * Magicons.svg file.
 * 
 * @example
 * <coach-icon
 *                iconName="home"
 *                iconColor="default"
 *                iconSize="small">
 * </coach-icon>
 */
@Component({
  selector: 'coach-icon',
  templateUrl: './coach-icon.component.html',
  host: {
    class: 'coach-icon'
  },
  providers: [
    ClassesJoinTrimPipe
  ]
})
export class CoachIconComponent implements OnInit, OnChanges {

  /** Icon name. Must match an icon defition in Magicons.svg */
  @Input() iconName: string = 'home';
  /** Icon color (default, primary, white, currentColor) */
  @Input() iconColor: string = null;
  /** Icon size (small, large) */
  @Input() iconSize: string = null;
  /** Icon weight (thin, bolder) */
  @Input() iconWeight: string = null;

  public iconUseTag: SafeHtml;

  constructor(private sanitizer: DomSanitizer, private _classesJoinTrim: ClassesJoinTrimPipe) { }

  ngOnInit(): void {
    this.iconUseTag = this.buildIconUseTag(this.iconName);
    this.setIconColor(this.iconColor);
    this.setIconSize(this.iconSize);
    this.setIconWeight(this.iconWeight);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.iconName.firstChange) {
      this.iconUseTag = this.buildIconUseTag(changes.iconName.currentValue);
    }
  }

  public setIconColor(color: string) {
    this.iconColor = color ? `icon--${color}` : null;
  }

  public setIconSize(size: string) {
    if (size) {
      size = size.toLowerCase();
      switch (size) {
        case 'small' || 'is-small':
          this.iconSize = 'is-small';
          break;
        case 'large' || 'is-large':
          this.iconSize = 'is-large';
          break;
        case 'medium' || 'is-medium':
          this.iconSize = 'is-medium';
          break;
        default:
          this.iconSize = null;
          break;
      }
    } else {
      this.iconSize = null;
    }
  }

  public setIconWeight(weight: string): string {
    if (weight) {
      weight = weight.toLowerCase();
      switch (weight) {
        case 'thin':
          this.iconWeight = 'is-thin';
          break;
        case 'bolder':
          this.iconWeight = 'is-bolder';
          break;
        default:
          this.iconWeight = null;
          break;
      }
    } else {
      return null;
    }
  }

  public buildIconUseTag(iconName: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<use href="assets/icons/Magicons.svg#icon-' + iconName + '"/>'
    );
  }

  public iconClasses(): string {
    var classes = [
      this.iconColor,
      this.iconSize,
      this.iconWeight
    ];
    return this._classesJoinTrim.transform(classes);
  }

}
