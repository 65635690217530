export enum ComplianceFormAnswer {
  yes = 'yes',
  no = 'no',
  noy = 'noy',
  partial = 'partial',
  yespartial = 'yespartial',
  any = 'any',
  disabled = 'disabled',
}

export interface ComplianceFormSubsequentQuestions {
  subQuestionId: string;
  subQuestionReqResp: ComplianceFormAnswer
}


export interface ComplianceFormQuestionScoring {
  questionAnswer: ComplianceFormAnswer;
  pointValue: number
}
export interface ComplianceFormQuestionChecklistOptions {
  checkId: number;
  checkText: string
}
export interface ComplianceFormQuestion {
  // Draft. Need actual schema.
  code?: string;
  questionId: string;
  number: string;
  infoText?: string;
  questionText: string;
  answer: ComplianceFormAnswer;
  disabled?: boolean;
  standardQuestion: boolean;
  pointValue?: ComplianceFormQuestionScoring[],

  textResponseOnly?: boolean;
  checkListResponseOnly?: boolean;
  checkListOptions?: ComplianceFormQuestionChecklistOptions[],
  fileUpload?: boolean;
  requiredIfVisible?: boolean;
  fileText?: string;
  requiredForQuestions?: ComplianceFormSubsequentQuestions[];

}

export interface ComplianceFormQuestionSection {
  // Draft. Need actual schema.
  id: number;
  name: string;
  questions: ComplianceFormQuestion[];
}

export interface ComplianceFormSectionAnswers {
  status: string;
  answers: string[];
}
