import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { AwvWorkflow } from '@api/care-orch/models/awv-workflow';
import { CareOrchestrationConstants } from '@shared/models/module-constants';
import { AwvService } from '@api/care-orch/services/awv.service';
import { Observable } from 'rxjs';
import { Activity } from '@api/care-orch/models/activity';
import { ActivityType } from '@api/care-orch/models/activity-type';


@Component({
  selector: 'coach-patient-awv-reminder',
  templateUrl: './patient-awv-reminder.component.html',
  styleUrls: ['./patient-awv-reminder.component.scss']
})
export class PatientAwvReminderComponent {
  @Input() public mobileScreenSize: boolean;
  @Input() public patient: Patient;
  @Input() sidebarMode?: boolean;
  @Input() public awvWorkflow: AwvWorkflow;
  @Output() public awvWorkflowUpdated: EventEmitter<Observable<AwvWorkflow>> = new EventEmitter();
  @Output() public activityDeleted: EventEmitter<ActivityType> = new EventEmitter();

  constructor(private awvService: AwvService) {}

  activitySaved(activity: Activity): void {
    const obsUpdatedWorkflow = this.awvService.updateWorkflowActivity({
      programId: CareOrchestrationConstants.AwvProgramId,
      chPatId: this.patient.trackAwvPatient?.chPatID || Number(this.patient.trackPatient.chPatId),
      body: activity
    });

    this.awvWorkflowUpdated.emit(obsUpdatedWorkflow);
  }

  activityDeletedEvent(activityType: ActivityType): void {
    this.activityDeleted.emit(activityType);
  }
}


