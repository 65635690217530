import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-information-technology',
  templateUrl: './information-technology.component.html',
  styleUrls: ['./information-technology.component.scss']
})
export class InformationTechnologyComponent implements OnInit {
  app = AppConstants;
  
  constructor() { }

  ngOnInit(): void {
  }

}
