import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FACESHEET_SCHEDULE_GRID_KEY, GridFilterService } from '@shared/components/grid/grid-filter.service';
import { FacesheetScheduleGridComponent } from '../facesheet-schedule-grid/facesheet-schedule-grid.component';
import { HccFacesheetScheduleItem } from '../hcc-facesheet-schedule-item';
import { HccFacesheetScheduleService } from '../hcc-facesheet-schedule.service';
import { printPdf } from '@shared/utilities';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { CareComponentConstants, TrackFeatureConstants } from '@shared/models/module-constants';
import { ColumnApi, GridApi } from 'ag-grid-community';
@Component({
  selector: 'coach-facesheet-schedule',
  templateUrl: './facesheet-schedule.component.html',
  styleUrls: ['./facesheet-schedule.component.scss']
})
export class FacesheetScheduleComponent extends KillSubscriptions implements OnInit {

  communitiesLoading: boolean;
  practicesLoading: boolean;
  acosLoading: boolean;
  hasData: boolean;

  @ViewChild(FacesheetScheduleGridComponent) facesheetGrid: FacesheetScheduleGridComponent;
  @Input() patientDataByChPatId: any[]
  @Input() fcButtonsStatus: {fcPdfLoading: boolean, fcPrintLoading: boolean}
  @Input() localeText: {[key: string]: string};
  @Output() public selectedPatients: EventEmitter<any[]> = new EventEmitter();  
  @Output() public secondaryColumnApiEmitter: EventEmitter<ColumnApi> = new EventEmitter() // sends columnApi to care-patients where it's used in columnsSettingsModal to hide/display columns;
  @Output() public agFilterChangedEmitter: EventEmitter<GridApi> = new EventEmitter() // sends gridApi instance to care-patients when filter is applied;
  @Output() public setSelectedPatientId: EventEmitter<string> = new EventEmitter();

  constructor(public hccFacesheetScheduleService: HccFacesheetScheduleService, 
              protected gridFilterService: GridFilterService,
              private hierarchyTierService: HierarchyTierService,
              private featureFlagService: FeatureFlagService) { 
                super();
              }

  ngOnInit(): void {
    this.hccFacesheetScheduleService.loadSchedules$.pipe(takeUntil(this.killTrigger)).subscribe(() => {
      this.loadSchedules(false);
    });
  }

  ngAfterViewInit(): void {
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
      if (tier != null) {
        this.loadSchedules(true);
      }
    });
  }
  
  clear(): void {
    this.facesheetGrid.clear();    
  }

  filter(isFirstFilter: boolean): void {
    const community = this.hierarchyTierService.currentTier$.value?.selectedItem.tier2_ID;
    const practice = this.hierarchyTierService.currentTier$.value?.selectedItem.tier4_ID;
    const tier3Id = this.hierarchyTierService.currentTier$.value?.selectedItem.tier3_ID;
    const fromDate = this.hccFacesheetScheduleService.fromDate.value;
    const toDate = this.hccFacesheetScheduleService.toDate.value;

    this.facesheetGrid?.filter(fromDate, toDate, community, practice, null, isFirstFilter,tier3Id);
  }

  loadSchedules(isFirstFilter: boolean): void {
    if (isFirstFilter) {
      this.gridFilterService.resetFilters(FACESHEET_SCHEDULE_GRID_KEY);
    }
    
    this.filter(isFirstFilter);
    this.facesheetGrid?.rowData.subscribe((rd) => {
      this.hasData = rd.length > 0 ? true : false;
    });
  }

  getSelectedItems(): HccFacesheetScheduleItem[] {
    const selectedItems = this.facesheetGrid?.getSelectedItems();
    if (selectedItems?.length === 0) {
      return;
    }

    return selectedItems.map(patient => {
      const item: HccFacesheetScheduleItem = { patientMbi: patient.currentMbi, facesheetScheduleID: patient.facesheetScheduleID, batchStageId: patient.batchStageId, chPatId: patient.chPatId };
      return item;
    });
  }

  print(expanded: boolean, tierNum: number, tierId: string): void {
    if (this.featureFlagService.hasFeatureFlag(TrackFeatureConstants.FacesheetExportAsyncFeatureFlag)) {
      this.startExport(false, expanded, tierNum, tierId);
    } else {
      this.printSynchronous(expanded);
    }
  }

  printSynchronous(expanded?: boolean): void {
    const selectedPatients = this.getSelectedItems();

    if (selectedPatients == null || selectedPatients.length === 0) {
      return;
    }

    this.fcButtonsStatus.fcPrintLoading = true;
    this.hccFacesheetScheduleService.printPdf(selectedPatients).subscribe(x => {
      printPdf(x, this.hierarchyTierService.currentTier$.value?.selectedItem.tierName);

      this.fcButtonsStatus.fcPrintLoading = false;
    }, () => {
      this.fcButtonsStatus.fcPrintLoading = false;
    });
  }

  export(expanded: boolean, tierNum: number, tierId: string): void {
    if (this.featureFlagService.hasFeatureFlag(TrackFeatureConstants.FacesheetExportAsyncFeatureFlag)) {
      this.startExport(true, expanded, tierNum, tierId);
    } else {
      this.exportSynchronous(expanded);
    }
  }

  exportSynchronous(expanded?: boolean): void {
    const selectedPatients = this.getSelectedItems();
    if (selectedPatients == null || selectedPatients.length === 0) {
      return;
    }

    this.fcButtonsStatus.fcPdfLoading = true
    this.hccFacesheetScheduleService.exportPdfs(selectedPatients).subscribe(x => {
      const url = window.URL.createObjectURL(new Blob([x as BlobPart], { type: 'application/zip' }));

      const link = document.createElement('a');
      document.body.appendChild(link);
      link.setAttribute('style', 'display: none');
      link.href = url;
      link.download = this.hierarchyTierService.currentTier$.value?.selectedItem.tierName + '.zip';
      link.click();
      this.fcButtonsStatus.fcPdfLoading = false;
    }, () => {
      this.fcButtonsStatus.fcPdfLoading = false;
    });
  }


  private startExport(exportToZip: boolean, expanded: boolean, tierNum: number, tierId: string): void {
    const selectedPatients = this.getSelectedItems();
    if (selectedPatients == null || selectedPatients.length === 0) {
      return;
    }

    const batchStageId = selectedPatients[0].batchStageId;
    this.hccFacesheetScheduleService.bulkExportPrint(exportToZip, selectedPatients, CareComponentConstants.HccFacesheetBySchedule, batchStageId.toString(), batchStageId, expanded, tierNum, tierId);
  }

  public externalPatientSelected(patients: []): void {
    this.selectedPatients.emit(patients);
  }
  externalSecondaryColumnApiEmitter(columnApi: ColumnApi): void {
    this.secondaryColumnApiEmitter.emit(columnApi)
  }
  externalAgFilterChangedEmitterEmitter(gridApi: GridApi): void {
    this.agFilterChangedEmitter.emit(gridApi)
  }
  emitSelectedPatientId(id: string): void{
    this.setSelectedPatientId.emit(id)
  }
}

