import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { MessageService } from '../message-service/message.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RestApiService {

   httpOptions = {
    headers: new HttpHeaders({
      'content-Type': 'application/json',
    })
  };
  constructor(private httpClient: HttpClient, private messageService: MessageService)
  {
  }

 public handleHttpErrorResponse(httpError: HttpErrorResponse, operation: string)
  {
    if (!environment.production)
    {
      // console.warn('error error:',httpError.error);
      // console.warn('error message:',httpError.error.userMessage);
    }

    let messageTxt = (httpError.error != null &&  httpError.error.userMessage != null) ?
      httpError.error.userMessage : null;
    if (messageTxt == null) {
      messageTxt = (httpError.error != null &&  httpError.error.message != null) ?
      httpError.error.message :
           `server returned code "${httpError.status}: with body "${httpError.error}"`;
     }
    httpError.type;
    const message: any = {message: messageTxt, result: 'error', url: httpError.url,
    status: httpError.status, op: operation};
    this.messageService.add(message);
    // console.warn(' message in Service:',this.messageService.top());

    return messageTxt;
  }
  public handleHttpSuccessResponse(httpResponse: HttpResponse<string>, operation: string)
  {
      const message: any = {message: httpResponse.body, result: 'success', url: httpResponse.url,
     status: httpResponse.status, op: operation};
      this.messageService.add(message);
  }
  public handleHttpSuccessResponseBody(res: any, operation: string)
  {
    const message: any = {message: res, result: 'success', url: res.url,
    status: 'success', op: operation};
    this.messageService.add(message);
  }

  public postHttp(url: string, token: any): boolean{
    this.messageService.clear();
    let succeeded = false;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json', Authorization: 'Bearer ' + token,
      })
    };
    let bodyTxt: any;
    this.httpClient.post(url, this.httpOptions).subscribe(success => {
      // console.warn("success callback", success);
      this.handleHttpSuccessResponseBody(success, 'POST');
      succeeded = true;
    }, error => { // second parameter is to listen for error
      if (this.retryableError(error)) {
        retry(2);
      }
      this.handleHttpErrorResponse(error, 'POST');
    });
    return succeeded;

  }
public redirectTo(url){
  document.location.href = url;

}
  public getHttp(url: string, token?: any): Observable<object>{
    this.messageService.clear();
    let httpOptions: any;
    if (token) {
      httpOptions = {
        headers: new HttpHeaders({
          'content-Type': 'application/json', Authorization: 'Bearer ' + token,
          })
        };
    }
    else {
      httpOptions = {
        headers: new HttpHeaders({
          'content-Type': 'application/json',
          })
        };
    }
    return this.httpClient.get(url, httpOptions);
// .pipe(catchError(this.handleError('Post', validEmail)));

  }
  public  retryableError(error: HttpErrorResponse): boolean {
    return (error.status !== 404 && error.status !== 400 && error.status !== 403);
  }
  public  requestAgainError(error: HttpErrorResponse): boolean {
    return (error.status === 403);
  }
}
