import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import moment, { Moment } from 'moment';

/**
 * Custom validators for Angular Reactive Forms
 */
export class CustomValidators {
    /**
     * Validates that the control value is greater than or equal to another control
     * @param {string} otherControlPath Absolute path of control to compare against
     */
    static greaterThanOrEqual(otherControlPath: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const otherControl = control?.root?.get(otherControlPath);
            if (control == null || otherControl == null || control.value == null || otherControl.value == null) {
                return null;
            }

            const isAfter = control.value >= otherControl.value;

            return isAfter ? null : { notGreaterThan: true };
        };
    }

    /**
     * Validates that the control value is less than or equal to another control
     * @param {string} otherControlPath Absolute path of control to compare against
     */
    static lessThanOrEqual(otherControlPath: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const otherControl = control?.root?.get(otherControlPath);
            if (control == null || otherControl == null || control.value == null || otherControl.value == null) {
                return null;
            }

            const isAfter = control.value <= otherControl.value;

            return isAfter ? null : { notGreaterThan: true };
        };
    }

    /**
     * Validates that the control value is greater than or equal to another control or zero
     * @param {string} otherControlPath Absolute path of control to compare against
     */
    static greaterThanMinOrZero(minValue: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          if (control == null || control.value == null) {
              return null;
          }
          let isAfter: boolean;

          if (control.value >= minValue || control.value == 0)
          {
            isAfter = true;
          }
          else
          {
            isAfter = false;
          }

          return isAfter ? null : { notGreaterThan: true };
      };
  }

  static startDateIsInPast(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control == null || control.value == null) {
          return null;
      }
      let isPast: boolean;

      const responseDate = control.value.split("|"); 

      const startDate = moment(responseDate[0], 'YYYY-MM-DD');

      const endDate = moment(responseDate[1], 'YYYY-MM-DD');

      const today = moment();

      if (today >= startDate)
      {
        isPast = true;
      }
      else
      {
        isPast = false;
      }

      return isPast ? null : { startIsInPast: true };
  };
  }

  static sufficentDateRange(reqDateDiff: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control == null || control.value == null) {
          return null;
      }
      let isEnoughDays: boolean;

      const responseDate = control.value.split("|"); 

      const startDate = moment(responseDate[0], 'YYYY-MM-DD');

      const endDate = moment(responseDate[1], 'YYYY-MM-DD');

      if (endDate.diff(startDate, 'days') < reqDateDiff) {
            isEnoughDays = false;
        }
        else 
        {
            isEnoughDays = true;
        }

    
      return isEnoughDays ? null : { sufficentDateRange: true };
  };
}

}

  

