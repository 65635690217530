/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AwvWorklistService } from './services/awv-worklist.service';
import { CardWidgetService } from './services/card-widget.service';
import { CompareService } from './services/compare.service';
import { EdUtilizationWorklistService } from './services/ed-utilization-worklist.service';
import { EulaService } from './services/eula.service';
import { FacesheetService } from './services/facesheet.service';
import { FacesheetScheduleService } from './services/facesheet-schedule.service';
import { HccDashboardService } from './services/hcc-dashboard.service';
import { HierarchyService } from './services/hierarchy.service';
import { NotesService } from './services/notes.service';
import { PatientsService } from './services/patients.service';
import { PerformanceDashboardService } from './services/performance-dashboard.service';
import { ReportService } from './services/report.service';
import { ReportItemsService } from './services/report-items.service';
import { SnfService } from './services/snf.service';
import { Sp340BClaimsService } from './services/sp-340-b-claims.service';
import { Sp340BInvoiceService } from './services/sp-340-b-invoice.service';
import { StatusService } from './services/status.service';
import { UserService } from './services/user.service';
import { ValueCalculatorService } from './services/value-calculator.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AwvWorklistService,
    CardWidgetService,
    CompareService,
    EdUtilizationWorklistService,
    EulaService,
    FacesheetService,
    FacesheetScheduleService,
    HccDashboardService,
    HierarchyService,
    NotesService,
    PatientsService,
    PerformanceDashboardService,
    ReportService,
    ReportItemsService,
    SnfService,
    Sp340BClaimsService,
    Sp340BInvoiceService,
    StatusService,
    UserService,
    ValueCalculatorService,
    ApiConfiguration
  ],
})
export class TrackApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<TrackApiModule> {
    return {
      ngModule: TrackApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: TrackApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('TrackApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
