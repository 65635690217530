import { Injectable } from '@angular/core';
import { MixpanelService } from '../mixpanel.service';
import { Router } from '@angular/router';

const LinkClickedEventId = 'Link Clicked';

// These patterns are used to remove tierId and patientId from the url
// Copied from similar logic found in AppInsights processing
const urlReplacementPatterns = [
  {
    pattern: '(?<tierId>/(aco|com|ppr|pra)/[a-zA-Z0-9.]+/)',
    group: 'tierId',
    replacement: '/'
  },
  {
    pattern: '(/care/patient(?<patientId>/[0-9]+))',
    group: 'patientId',
    replacement: ''
  }
];

export interface LinkClickedEventProperties {
  linkText: string;
  //href minus the domain, tierId, and patientId and query params
  targetPage?: string;
  //url of the page of link minus the domain, tierId, and patientId and query params
  sourcePage?: string;
  //id of the link - if it has one
  linkId?: string;
  //section of the page where the link is located - arbitrary at the moment. Still need to define or remove 
  //waiting for feedback from Jenna
  sourceSection?: string;
  //subsection of the page where the link is located - arbitrary at the moment. Still need to define or remove
  sourceSubSection?: string;
  //filter preset that was applied to the care/patient page.  filterPreset values are not consistent across the app
  filterPreset?: string;
  //status of the AWV patients filter on the care/patient page
  AWVPatientsStatus?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LinkClickedEventsService {
  constructor(private mixpanelService: MixpanelService, private router: Router) {}

  /**
   * Send link click event to MixPanel
   */
  public sendLinkClickedEvent(props: LinkClickedEventProperties): void {
    try {
      const sourcePage = this.parseSourcePage(this.router.url);

      this.mixpanelService.track(LinkClickedEventId, {
        ...props,
        sourcePage
      });
    } catch (error) {
      //
      console.error('Error sending data to Mixpanel', error);
    }
  }

  // extracted from similar function in app-insights.service.ts
  // TODO: refactor to share code
  // TODO: add unit tests
  /**
   *
   * @param path
   * @returns The path with tierId and patientId segments replaced
   */
  private parseSourcePage(url: string): string {
    let path = url.split('?')[0];

    urlReplacementPatterns.forEach(pattern => {
      const re = new RegExp(pattern.pattern);
      const matches = re.exec(path);
      if (matches != null) {
        path = path.replace(matches.groups[pattern.group], pattern.replacement);
      }
    });

    return path;
  }
}
