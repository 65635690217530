import { formatNumber } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { HccDashboardService } from '@api/track/services';
import { CINS_REPORT_GRID_KEY, GridFilterService } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { HccDashboardFilterService } from '../../hcc-dashboard-filter.service';

import { combineLatest, of } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'coach-cins-report',
  templateUrl: './cins-report.component.html',
  styleUrls: ['./cins-report.component.scss']
})
export class CinsReportComponent extends PersistentGridFilters implements OnInit {
  tierNum: number;
  tierId: string;

  columnDefs = [
    {
      headerName: 'Community',
      field: 'communityName',
      tooltipField: 'communityName',
      sortable: true,
      filter: true,
      resizable: true,
      headerTooltip: 'Displays the name of the community included in the hierarchy'
    },
    {
      headerName: 'Patient Count',
      field: 'patientCount',
      sortable: true,
      filter: true,
      resizable: true,
      headerTooltip:
        'Count of patient attributed or attributed/assignable to the clinician/RHC/FQHC based on filter selection.'
    },
    {
      hide: true, // Hidden with ZBR-12923
      headerName: 'Chronic Condition Open Gaps',
      field: 'hccGaps',
      sortable: true,
      filter: true,
      resizable: true,
      headerTooltip: 'Count of open chronic condition gaps'
    },
    {
      headerName: 'Annual Closure Rate',
      field: 'overallHCCClosureRate',
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: params => formatNumber(params.value, this.locale, '1.0-3'),
      headerTooltip: 'Percentage of chronic conditions gaps closed by claims in the current calendar year.'
    }
  ];

  rowData: any;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private hccDashboardService: HccDashboardService,
    protected filterService: GridFilterService,
    private hccDashboardFilterService: HccDashboardFilterService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(filterService, CINS_REPORT_GRID_KEY);
  }

  ngOnInit(): void {
    this.rowData = this.hccDashboardFilterService.filterModel$.pipe(
      takeUntil(this.killTrigger),
      switchMap(filterValue => {
        const cinId = filterValue?.selectedCin?.id;

        if (cinId == null) {
          const path = 'overview';

          this.router.navigate([path], { relativeTo: this.route.parent });
          return of();
        }

        return this.hccDashboardService.hccDashboardCinsGet({
          cinId: cinId,
          attributionStatus: filterValue == null || !filterValue.isMostAssignable ? ATTRIBUTED : MOST_ASSIGNABLE
        });
      })
    );
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridColumnApi.getColumn('hccGaps').setSort('desc');
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    }
  }
}
