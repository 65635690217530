import { Injectable } from '@angular/core';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { HierarchyTierItem, SingleHierarchyTierItem } from '@shared/models/hierarchy/hierarchy-tier-item';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth-service/auth.service';
import { environment } from 'src/environments/environment';
import {
  AcoTier,
  CHTIN_PREFIX,
  CommunityTier,
  IHierarchyTier,
  PracticeTier,
  PrincipalParticipantTier
} from '@shared/models/hierarchy/hierarchy-tier';
import { HierarchyTierEnum } from '@shared/models/hierarchy/hierarchy-tier-enum';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ActivatedRouteService } from '../activated-route/activated-route.service';
import { deepCopy } from '@shared/utilities';
import { UserTierService } from './user-tier.service';

@Injectable({
  providedIn: 'root'
})
export class HierarchyTierService extends AzureApiBase<HierarchyTierItem> {
  public hierarchyTiers: IHierarchyTier[] = [
    new AcoTier(),
    new CommunityTier(),
    new PrincipalParticipantTier(),
    new PracticeTier()
  ];
  /** BehaviorSubject containing the value of the currently selected hierarchy tier. Will emit new values */
  public currentTier$ = new BehaviorSubject<IHierarchyTier>(null);
  /** Contains the currently selected tier that will update currentTier$ once the selection is finalized  */
  public currentTierStaged: IHierarchyTier;

  /** Will emit true once all tiers have loaded (not just currently selected tier) */
  public allTiersLoaded$ = new ReplaySubject<boolean>(1);

  private restoreTier: IHierarchyTier;
  private currentTierKey = 'tier';
  private currentTierIdKey = 'tierId';
  private currentUserIdKey = 'userId';
  constructor(
    http: HttpClient,
    authService: AuthService,
    private route: ActivatedRouteService,
    private userTierService: UserTierService
  ) {
    super(http, authService, environment.trackApiUrl, '/hierarchy');

    this.authService.isAuthenticated$?.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.userTierService.updateUserTierInfo().subscribe({
          error: () => this.initializeHierarchy(),
          complete: () => this.initializeHierarchy()
        });
      }
    });
  }

  public get currentTierUrl(): string {
    const currentTier = this.currentTier$.value;

    return currentTier == null ? null : `/${currentTier.abbreviation}/${currentTier.selectedTierIdForDisplay}`;
  }

  private async initializeHierarchy() {
    const tierAbbreviation = this.getTierAbbreviationFromRoute();
    let tierId = this.getTierIdFromRoute();
    this.restoreTier = this.hierarchyTiers.find(x => x.abbreviation === tierAbbreviation);
    const storedUserId = this.authService?.read(this.currentUserIdKey);

    const freshUserID = this.authService?.idp_sub;

    if (this.restoreTier == null) {
      if (freshUserID === storedUserId) {
        const storedTier = this.authService.read(this.currentTierKey);
        tierId = this.authService.read(this.currentTierIdKey);
        this.restoreTier = this.hierarchyTiers.find(x => x.tier === storedTier);
      }
    }

    if (this.restoreTier != null && tierId != null) {
      const tierNum = this.restoreTier.tier;
      const chtin = this.getChTin(tierId);

      this.getTier(tierNum, tierId, chtin?.chtinid, chtin?.supplementalID).subscribe(tierItem => {
        if (tierItem != null) {
          this.restoreTier.selectedItem = {
            highestAccessTier: 1,
            phiFlag: 0,
            tier: tierNum,
            userID: 0,
            tier1_ID: tierItem.tier1_ID,
            tier1_Name: tierItem.tier1_Name,
            tier2_ID: tierItem.tier2_ID,
            tier2_Name: tierItem.tier2_Name,
            tier3_ID: tierItem.tier3_ID,
            tier3_Name: tierItem.tier3_Name,
            tier4_ID: tierItem.tier4_ID,
            tier4_Name: tierItem.tier4_Name,
            tierName: tierItem['tier' + tierNum + '_Name'],
            chtinid: tierItem.chtinid,
            supplementalID: tierItem.supplementalID,
            cinFlag: tierItem.cinFlag
          };
          this.updateCurrentTier(this.restoreTier);
        }

        this.getTiers(this.hierarchyTiers.find(x => x.type === HierarchyTierEnum.Aco));
      });
    } else {
      this.getTiers(this.hierarchyTiers.find(x => x.type === HierarchyTierEnum.Aco));
    }
  }

  private getChTin(tierId: string): {
    chtinid: number;
    supplementalID: number;
  } {
    const segments = tierId.split('.');
    if (segments.length < 3 || segments[0] !== CHTIN_PREFIX) {
      return null;
    }

    return {
      chtinid: parseInt(segments[1]) || 0,
      supplementalID: parseInt(segments[2]) || 0
    };
  }

  private getTiers(tier: IHierarchyTier): void {
    const parentTier = this.getParent(tier.tier);
    let params = new HttpParams().set('tier', tier.tier.toString());

    if (parentTier != null && parentTier.selectedItem != null) {
      params = params.append('parentId', parentTier.selectedTierId);
      params = params.append('tier1Id', parentTier.selectedItem.tier1_ID);
    }

    tier.isLoading = true;
    this.getAll(null, params).subscribe({
      next: items => {
        tier.items = items;
        const childTier = this.getChild(tier.tier);

        // If no tier has been selected and tier is present on route, select item base on route value
        if (this.restoreTier != null) {
          const restoreTierId = this.restoreTier.getTierId(this.restoreTier.selectedItem, tier.tier);

          tier.selectedItem = tier.items.find(x => tier.getTierId(x, x.tier) === restoreTierId) || null;

          if (tier.selectedItem != null) {
            if (this.restoreTier.tier === tier.tier) {
              this.currentTierStaged = tier;
              this.applyCurrentTier();
              this.restoreTier = null;
            }

            if (childTier != null) {
              this.getTiers(childTier);
            }
          }
        }

        if (tier.selectedItem == null && tier.items.length > 0 && tier.highestTier >= tier.tier) {
          tier.selectedItem = tier.items[0];

          this.updateCurrentTier(tier);

          if (childTier != null) {
            this.getTiers(childTier);
          }
        } else if (tier.selectedItem == null || tier.tier == 4) {
          this.allTiersLoaded$.next(true);
        }
      },
      complete: () => (tier.isLoading = false)
    });
  }

  private updateCurrentTier(tier: IHierarchyTier): void {
    // Only broadcast if user's highest tier access is less than or equal to selected tier
    if (tier.highestTier <= tier.tier) {
      this.currentTierStaged = tier;
      if (this.currentTier$.value == null) {
        this.applyCurrentTier();
      }
    }
  }

  public getRouteValue(position: number): string {
    const url = environment.useHashStrategy ? window.location.hash : window.location.pathname;
    position = environment.useHashStrategy ? position + 1 : position;
    const params = url.split('/');
    if (params != null && params.length > position) {
      return params[position];
    }
  }

  public getTierAbbreviationFromRoute(): string {
    return this.getRouteValue(1);
  }

  public getTierIdFromRoute(): string {
    return this.getRouteValue(2);
  }

  private clearChildren(tier: IHierarchyTier): void {
    let child = this.getChild(tier.tier);

    while (child != null) {
      child.items = [];
      child.selectedItem = null;
      child = this.getChild(child.tier);
    }
  }

  private getTier(
    tier: number,
    tierId: string,
    chTinId: number = null,
    supplementalId: number = null
  ): Observable<SingleHierarchyTierItem> {
    let params = new HttpParams().set('tier', tier.toString()).append('tierId', tierId);
    if (chTinId != null) {
      params = params.append('chTinId', chTinId.toString());
    }
    if (supplementalId != null) {
      params = params.append('supplementalId', supplementalId.toString());
    }

    return this.get<SingleHierarchyTierItem>('/tier', params);
  }

  public applyCurrentTier(): void {
    if (this.currentTierStaged == null) {
      return;
    }

    if (!this.currentTierStaged.equals(this.currentTier$.value)) {
      const newTier = deepCopy<IHierarchyTier>(this.currentTierStaged);
      this.currentTier$.next(newTier);
    }

    this.authService.write(this.currentTierKey, this.currentTierStaged.tier);
    this.authService.write(this.currentTierIdKey, this.currentTierStaged.selectedTierIdForDisplay);
    this.authService.write(this.currentUserIdKey, this.authService.getUserId());
  }

  public tierSelected(item: HierarchyTierItem, tier: IHierarchyTier): void {
    const child = this.getChild(tier.tier);

    if (child != null) {
      this.clearChildren(tier);
    }

    if (item != null) {
      this.updateCurrentTier(tier);

      if (child != null) {
        this.getTiers(child);
      }
    } else {
      const parent = this.getParent(tier.tier);
      this.updateCurrentTier(parent);
    }
  }
  public updateSelectedItem(tierIndex: number, newSelectedItem: HierarchyTierItem): void {
    this.hierarchyTiers[tierIndex].selectedItem = newSelectedItem;
  }
  public getParent(tier: number): IHierarchyTier {
    const hierarchyTier = this.hierarchyTiers.find(x => x.tier === tier - 1);

    return hierarchyTier;
  }

  public getChild(tier: number): IHierarchyTier {
    const hierarchyTier = this.hierarchyTiers.find(x => x.tier === tier + 1);

    return hierarchyTier;
  }

  public async hierarchyTierItemFromId(tierId: string): Promise<HierarchyTierItem> {
    if (!this.hierarchyTiers) {
      return null;
    }

    for (let i = 0; i < this.hierarchyTiers.length; i++) {
      let items = this.hierarchyTiers[i].items;
      if (!items) {
        // REVIEW sometimes isLoading is true, what can we wait for to get the whole list?
        // This function ought to be async and wait for the whole accessible
        // hierarchy to be loaded.
        if (!this.hierarchyTiers[i].selectedItem) {
          // probably isLoading is true
          continue;
        }
        // Pushing the selected item seems to work but feels rather icky
        items = [this.hierarchyTiers[i].selectedItem];
      }
      for (let j = 0; j < items.length; j++) {
        const item = items[j];
        if (!item) {
          continue;
        }
        switch (item.tier) {
          case 1:
            if (item.tier1_ID === tierId) {
              return item;
            }
            break;
          case 2:
            if (item.tier2_ID === tierId) {
              return item;
            }
            break;
          case 3:
            if (item.tier3_ID === tierId) {
              return item;
            }
            break;
          case 4:
            if (item.tier4_ID === tierId) {
              return item;
            }
            break;
          default:
            continue;
        }
      }
    }
    return null;
  }
}
