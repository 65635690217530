<div class="queue claim-validation patient-sidebar-wrapper border-left u-box-shadow--2"
    [ngClass]="{ 'is-validation-closed ': !isSidebarOpen }" #mainPanel style="top: 0;">
    <button class="pushTab top--xs" *ngIf="patientId" style="color: teal; background-color: white; cursor: pointer"
        [ngClass]="{
            'is-validation-open': !isSidebarOpen
        }" (click)="toggleValidatePanel()" aria-label="Toggle Validation Panel aaaaa">
        <coach-icon iconName="chevron-right"></coach-icon>
    </button>
    <div *ngIf="!patient" class="loading-wrapper">
        <coach-loading-indicator [size]="50" class="padding-top--smaller margin-right--smaller">
        </coach-loading-indicator>
    </div>
    <div class="patientContent dynamicBody padding--small u-justify-content--spaceBetween" *ngIf="patient">
        <!-- one of the ways to reuse patient component -->
        <!-- <coach-patient-facesheet  [patient]="patient" [tier]="tier"></coach-patient-facesheet> -->
        <div class="sidebar-body">
            <!-- demographics -->
            <div class="u-display--flex dynamic-flex-wrap">
                <div class="profile-photo square--smaller avi margin-right">
                    <img alt="patient photo" src="{{patient.avatarUrl()}}" *ngIf="patient.avatarUrl()">
                    <ng-container *ngIf="!patient.avatarUrl()">{{ patient.displayInitials() | initialsFromName }}
                    </ng-container>
                </div>
                <div class="t2 u-fill">
                    <div class="row">
                        <div class="u-color--brand--6 u-font-size--large" id='testSidebarPatientName'>
                          <a *ngIf="!isLinkDisabled" [routerLink]="patientUrl" [queryParams]="patientUrlQueryParams">{{patient.displayName()}}</a>
                          <a *ngIf="isLinkDisabled" style="color: black;">{{patient.displayName()}}</a>
                        </div>
                    </div>
                    <div class="row" id='testSidebarPatientAgeAndDOB'>
                        {{patient.displayAge()}}, {{patient.displayGender()}} | {{patient.displayDob()}}
                    </div>
                    <div *ngIf="patient.dod" class="row">
                        {{ patient.displayDod() }}
                    </div>
                    <div class="row" id='testSidebarPatientMBI'>
                        MBI: {{patient.mbi | mask: 6 }}
                    </div>
                    <div class="row" *ngIf="patient.tier5_Name || patient.tier4_Name" id='testSidebarPatientAtrributedProvider'>
                        Attributed Provider: {{ patient.tier5_Name?patient.tier5_Name:patient.tier4_Name }}
                    </div>
                </div>
            </div>
            <coach-patient-pac-navigator *ngIf="filterTitle === 'PAC Navigator Program'" [patient]="patient" [tier]="tier" [mobileScreenSize]="true" [sidebarMode]="true" (patientUpdated)="patientUpdated()" [isTabOpen]="true"></coach-patient-pac-navigator>
            <coach-patient-ihe-worklist *ngIf="filterTitle === 'In Home AWV Files'" [patient]="patient" [tier]="tier" [mobileScreenSize]="true" [sidebarMode]="true" (patientUpdated)="patientUpdated()" [isTabOpen]="true"></coach-patient-ihe-worklist>
          <ng-container *ngIf="filterTitle === 'AWV Opportunities'">
            <div *doesNotHaveFeatureFlagOrAnyComponents="CareOrchAccessReqs">
              <coach-patient-awv-report *ngIf="filterTitle === 'AWV Opportunities'" [patient]="patient" [tier]="tier" [mobileScreenSize]="true" [sidebarMode]="true" (patientUpdated)="patientUpdated()" [isTabOpen]="true"></coach-patient-awv-report>
            </div>
            <div *hasFeatureFlagAndAllComponents="CareOrchAccessReqs">
              <ng-container *ngIf="!inError">
                <coach-patient-awv-banner [patient]="patient" [awvWorkflowStatus]=awvWorkflowStatus></coach-patient-awv-banner>
                <coach-patient-last-awv-details [patient]="patient"></coach-patient-last-awv-details>
                <coach-patient-awv-enrol [patient]="patient" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Other  && isLowerEnvironment()"></coach-patient-awv-enrol>
              </ng-container>
              <coach-patient-awv-error [inError]="inError" [errorTitle]="errorTitle" [errorMessage]="errorMessage"></coach-patient-awv-error>
              <coach-patient-awv-scheduling *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.ReadyForOutreach || awvWorkflowStatus == allAwvWorkflowStatuses.OutreachAttempted" [patient]="patient" [awvWorkflow]="awvWorkflow" [tier]="tier" [mobileScreenSize]="true" [sidebarMode]="true" (patientUpdated)="patientUpdated()" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" [isTabOpen]="true"></coach-patient-awv-scheduling>
              <coach-patient-awv-reminder *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Scheduled || (awvWorkflowStatus == allAwvWorkflowStatuses.Completed && attendedActivityWasSubmitted === true)" [patient]="patient" [awvWorkflow]="awvWorkflow" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" (activityDeleted)="activityDeleted($event)"></coach-patient-awv-reminder>
              <coach-patient-awv-removed *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Declined || awvWorkflowStatus == allAwvWorkflowStatuses.Ineligible" [patient]="patient" [awvWorkflow]="awvWorkflow" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" (activityDeleted)="activityDeleted($event)"></coach-patient-awv-removed>
              <coach-patient-awv-completed *ngIf="awvWorkflowStatus == allAwvWorkflowStatuses.Completed && attendedActivityWasSubmitted === false"  [patient]="patient" [awvWorkflow]="awvWorkflow" (awvWorkflowUpdated)="awvWorkflowUpdated($event)" (activityDeleted)="activityDeleted($event)"></coach-patient-awv-completed>
              <coach-patient-awv-deferred *ngIf="awvWorkflowStatus === allAwvWorkflowStatuses.DeferredToSignify"></coach-patient-awv-deferred>
              <coach-patient-awv-history *ngIf="awvWorkflowStatus != allAwvWorkflowStatuses.Other" [awvWorkflow]="awvWorkflow" [patientId]="patient.chPatId"></coach-patient-awv-history>
              <coach-patient-awv-loading [isLoading]="isLoading"></coach-patient-awv-loading>
            </div>
          </ng-container>
        </div>
    </div>
</div>
