import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { CoachException } from '@shared/models/exceptions/coach-exception';
import { AppInsightsLoggingService } from './app-insights-logging.service';
import { ErrorLoggingService } from './error-logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  /** Custom global error handler to catch any unhandled exceptions */
  constructor(private loggingService: AppInsightsLoggingService, @Inject(Injector) private readonly injector: Injector) {
    super();
  }

  // Directly inject error logging service to avoid cyclical dependency error
  // This may warrant further investigation as to why we get this error when injecting this service via the constructor
  // I suspect it may be due to a dependency in the AuthService
  // https://stackoverflow.com/questions/65645124/circular-dependency-in-di-detected-for-applicationref-how-to-fix-it
  private get errorLoggingService(): ErrorLoggingService {
    return this.injector.get(ErrorLoggingService);
  }

  /** Catches any unhandled exceptions and logs them to Azure Application Insights. 
   * 
   * If exception is a {@link CoachException} also calls the Error Log API to log the exception to the CoachSQL database 
   */
  handleError(error: Error): void {
    console.error(error);
    this.loggingService.logException(error);

    if (error instanceof CoachException) {
      this.errorLoggingService.logError(error.logError).subscribe();
    }
  }
}
