import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserAccessService} from '@shared/services/user-access/user-access.service';
import {takeUntil} from 'rxjs/operators';
import {FeatureFlagService} from '@shared/services/feature-flag/feature-flag.service';
import {Subject} from 'rxjs';

/**
 * Only renders element if the user's current access includes the provided feature flag and all the provided components
 *
 * @example - with feature flag ID and component IDs
 * <div *hasFeatureFlagAndAllComponents="{featureFlagId: "exmapleId", componentIds: [123, 456]}"></div>
 *
 */
@Directive({
  selector: '[hasFeatureFlagAndAllComponents]'
})
export class HasFeatureFlagAndAllComponents implements OnDestroy {
  constructor(
    protected featureFlagService: FeatureFlagService,
    protected userAccessService: UserAccessService,
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef
  ) {
    featureFlagService.featureFlags$.pipe(takeUntil(this.killTrigger)).subscribe(_ => this.updateAccess());
    userAccessService.currentAccess$.pipe(takeUntil(this.killTrigger)).subscribe(_ => this.updateAccess());
  }

  featureFlagId: string;
  componentIds: Array<number> = [];

  @Input()
  set hasFeatureFlagAndAllComponents(params: { featureFlagId: string, componentIds: number|Array<number> }) {
    this.featureFlagId = params.featureFlagId;
    if (Array.isArray(params.componentIds)) {
      this.componentIds = params.componentIds;
    } else {
      this.componentIds = [params.componentIds];
    }

    this.updateAccess();
  }

  killTrigger: Subject<void> = new Subject();
  private hasView = false;

  ngOnDestroy(): void {
    this.killTrigger.next();
  }

  updateAccess(): void {
    const hasAccess = this.hasFlag() && this.hasAllComponents();
    if (hasAccess && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!hasAccess && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  hasFlag(): boolean {
    return this.featureFlagService.hasFeatureFlag(this.featureFlagId);
  }

  hasAllComponents(): boolean {
    return this.componentIds.every(id =>
      this.userAccessService.hasComponent(this.userAccessService.currentAccess$.value, id))
  }
}
