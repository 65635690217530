import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SummaryTile, SummaryTileItem } from '@models/summary';
import { LinkClickedEventsService } from '@shared/services/mixpanel/events/link-clicked-events.service';

@Component({
  selector: 'coach-care-summary-tile',
  templateUrl: './care-summary-tile.component.html',
  host: {
    class: 'coach-care-summary-tile'
  }
})
export class CareSummaryTileComponent {
  @Input()
  summaryTile: SummaryTile;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private linkClickedEventsService: LinkClickedEventsService
  ) {}

  onItemClick(event: Event, item: SummaryTileItem, subItem?: SummaryTileItem): void {
    event.preventDefault();
    const updatedPath = item.path ? item.path : '../care/patients';

    this.trackLinkClick(event, this.summaryTile, item, subItem);

    this.router.navigate([updatedPath], {
      relativeTo: this.activatedRoute.parent,
      queryParams: {
        filterPreset: subItem ? subItem.filterPreset : item.filterPreset,
        filterTitle: subItem ? subItem.filterTitle : item.filterTitle,
        AWVPatientsStatus: subItem ? subItem.awvPatientsStatus : item.awvPatientsStatus || null
      }
    });
  }

  /**
   * Send link clicks to mixpanel
   */
  trackLinkClick(event: Event, summaryTile: SummaryTile, item: SummaryTileItem, subItem: SummaryTileItem): void {
    try {
      const filterPreset = subItem ? subItem.filterPreset : item.filterPreset;
      const AWVPatientsStatus = subItem ? subItem.awvPatientsStatus : item.awvPatientsStatus ?? undefined;

      const linkText = (event.target as HTMLElement).innerText;
      const targetPage = item.path ? item.path : '/care/patients';
      this.linkClickedEventsService.sendLinkClickedEvent({
        linkText,
        filterPreset,
        sourceSection: summaryTile.title,
        sourceSubSection: subItem ? item.linkText : undefined,
        targetPage,
        AWVPatientsStatus
      });
    } catch (error) {
      console.error(error);
    }
  }
}
