import { Component, Renderer2, OnInit, Inject } from '@angular/core'; // enableProdMode
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { HeaderCondensedService } from '@shared/services/header-condensed/header-condensed.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { UserIdleService } from 'angular-user-idle';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { MixpanelService } from '@shared/services/mixpanel/mixpanel.service';
import { AppConstants } from '@shared/models/constants/app-constants';
import { TierInfo } from '@shared/services/auth-service/auth-interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public isSidebarOpen;
  public isHeaderCondensed$: BehaviorSubject<boolean>;
  public isAuthenticated$: Observable<boolean>;
  public userData$: Observable<any>;
  userActivityTimer;
  userInactive: Subject<any> = new Subject();
  renewIdpTimer;
  renewIdp: Subject<any> = new Subject();
  isTimeoutDialogOpen: boolean;
  timeLeft: number;

  constructor(
    private _renderer: Renderer2,
    private _isHeaderCondensed: HeaderCondensedService,
    private authService: AuthService,
    private router: Router,
    private hierarchyTierService: HierarchyTierService,
    private route: ActivatedRoute,
    private userAccessService: UserAccessService,
    private userIdle: UserIdleService,
    private featureFlagService: FeatureFlagService,
    private mixpanelService: MixpanelService,
    @Inject(DOCUMENT) document
  ) {
    this.isHeaderCondensed$ = this._isHeaderCondensed.isHeaderCondensed$;
    this.isHeaderCondensed$.subscribe(() => this.toggleCondensedClass());

    this.hierarchyTierService.currentTier$
      .pipe(
        filter(currentTier => currentTier != null),
        switchMap(currentTier =>
          this.featureFlagService.updateFeatureFlags(currentTier).pipe(
            switchMap(() => this.userAccessService.updateAccess(currentTier)),
            map(() => currentTier)
          )
        )
      )
      .subscribe(currentTier => this.hierarchyChanged(currentTier));

    this.userAccessService.currentAccess$.subscribe(access => {
      const allChildren = this.flattenChildren(this.route.snapshot.children);
      let hasAccess = true;
      allChildren.forEach(child => {
        hasAccess = hasAccess && this.userAccessService.accessRestricted(child.data, access);
      });

      if (!hasAccess) {
        this.router.navigate(['/home']);
      }
    });
  }

  /** When the hiearchy changes, update navigation to reflect newly selected tier */
  async hierarchyChanged(currentTier: IHierarchyTier): Promise<void> {
    // Try to navigate to stored endpoint first, otherwise navigate to current url for tier
    const hasNavigated = await this.navigateToStoredEndpoint();

    const url = this.router.url;
    const isSharedLink = url.indexOf('shared') > -1;

    if (!hasNavigated || isSharedLink) {
      let index: number;
      if (isSharedLink) {
        // shared url segment stands in for tier/id segments, so don't trim module
        index = url.split('/', 2).join('/').length;
      } else {
        index = url.split('/', 3).join('/').length;
      }
      const tierId = currentTier.selectedTierIdForDisplay;
      const newUrl = `/${currentTier.abbreviation}/${tierId}${url.slice(index)}`;

      const currentUrlTier = this.hierarchyTierService.getTierIdFromRoute();

      if (currentUrlTier !== tierId) {
        this.router.navigateByUrl(newUrl);
      }
    }
  }

  flattenChildren(children: ActivatedRouteSnapshot[]): ActivatedRouteSnapshot[] {
    const items: ActivatedRouteSnapshot[] = [];
    children.forEach(x => {
      if (x.children && x.children.length > 0) {
        const moreItems = [x, ...this.flattenChildren(x.children)];
        items.push(...moreItems);
      } else {
        items.push(x);
      }
    });

    return items;
  }

  SetUATimer() {}
  public toggleCondensedClass() {
    if (this.isHeaderCondensed$.value) {
      this._renderer.addClass(document.body, 'is-headerCondensed');
    }
    if (!this.isHeaderCondensed$.value) {
      this._renderer.removeClass(document.body, 'is-headerCondensed');
    }
  }

  ngOnInit(): void {
    this.authService.init(this);
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.userData$ = this.authService.userData$;
    // this.setRenewIdpTimeout(); // COM-8023 removing manual token refresh in lieu of silent renew
    this.isSidebarOpen = this.getSetSidebarStateStore();

    const mixpanelConfig = environment.mixpanel;
    this.mixpanelService.init(mixpanelConfig.projectId, mixpanelConfig.debug, mixpanelConfig.pageViews);
    this.authService.userInfo$.subscribe(user => {
      if (user?.dnn_id) {
        this.mixpanelService.identify(user.dnn_id);
        this.setMixPanelUserProperties(user.email, user.name, user.tiers);
      }
    });

    this.startIdleWatch();
  }
  public startIdleWatch(): void {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      this.timeLeft = this.userIdle.getConfigValue().timeout - count;

      const notAuthenticated = this.userAccessService.currentAccess$.value == null;
      if (notAuthenticated) {
        this.continueSession();
      } else if (!this.isTimeoutDialogOpen && count != null) {
        this.showTimeOutMessage();
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.showSessionEnd();
    });
  }

  logout(): void {
    this.mixpanelService.reset();
    this.authService.logout();
  }

  showSessionEnd(): void {
    this.authService.write(AuthService.TIMED_OUT, true);
    const url = this.router.url.split('?')[0];
    this.authService.write('coachRedirect', url);
    this.authService.write('queryParams', this.route.snapshot.queryParams);
    this.logout();
  }

  continueSession(): void {
    this.userIdle.stopTimer();
    this.isTimeoutDialogOpen = false;
  }

  showTimeOutMessage(): void {
    this.isTimeoutDialogOpen = true;
  }

  public toggleSidebar(event) {
    this.isSidebarOpen = event;
    localStorage.setItem('isSidebarOpenStore', this.isSidebarOpen);
  }

  private getSetSidebarStateStore(): any {
    // Called on init to check and set sidebar state. If none, default open/true
    const storedState = localStorage.getItem('isSidebarOpenStore');
    if (storedState != undefined) {
      return JSON.parse(storedState);
    } else {
      return true;
    }
  }

  private isInvalidRedirect(path: string): boolean {
    return (
      !path ||
      this.router.url.includes('autologin') ||
      this.router.url === path ||
      path === '/_/_/home' ||
      path === '/' ||
      path === '/timeout'
    );
  }

  // called as a callback from checkAuth call in the authService.init()
  private async navigateToStoredEndpoint(): Promise<boolean> {
    const path = this.authService.read('coachRedirect');

    if (this.isInvalidRedirect(path)) {
      return false;
    }

    if (path.toString().includes('/noauth')) {
      // to do: put in GUARDED HOME PAGE HERE.
      return await this.router.navigate(['/noauth']);
    } else {
      this.authService.write('coachRedirect', null);
      const qparams = this.authService.read('queryParams');
      // to do: only params or query params so using queryParams
      // let params = this.read('params');
      this.authService.write('queryParams', null);
      if (qparams) {
        return await this.router.navigate([path], { queryParams: qparams });
      } else {
        return await this.router.navigateByUrl(path);
      }
    }
  }

  /**
   * Set properties on Mixpanel User Profile
   * @param email
   * @param name
   * @param tiers
   */
  private setMixPanelUserProperties(email: string, name: string, tiers: Array<TierInfo>): void {
    try {
      this.mixpanelService.userSet({
        $email: email,
        $name: name,
        tiers,
        updatedBy: AppConstants.ApplicationName
      });

      this.mixpanelService.userSetOnce({ createdBy: AppConstants.ApplicationName });
    } catch (error) {
      //Just log and do nothing
      console.error('Mixpanel error', error);
    }
  }

  // COM-8023: Removing manual IdentityServer token refresh as this was causing the entire app to reload
  // IDP token does not need refreshing as it is not used for authentication
  // Rather the B2C token is used for auth and is automatically refreshed in the angular-auth-oidc-client library when token is close to expiration via silent renew

  // IdpTimerCallback() {
  //   if (
  //     this.authService.isIdpExpiringIfSignedIn(55) &&
  //     !this.router.url.includes('timeout')
  //   ) {
  //     this.renewIdp.next(Date.now());
  //   }
  // }
  // setRenewIdpTimeout() {
  //   this.renewIdpTimer = setInterval(() => {
  //     this.IdpTimerCallback();
  //   }, 1000 * 60 * 2);

  //   this.renewIdp.subscribe(() => {
  //     this.relogin();
  //   });
  // }

  // relogin() {
  //   //soft logout-which will force login without popup
  //   // which will renew theIdpToken
  //   let url = this.router.url; //window.location.pathname;
  //   this.authService.write('coachRedirect', url);
  //   this.authService.write('queryParams', null);
  //   this.router.getCurrentNavigation;
  //   this.authService.write('coachRedirect', url);
  //   this.authService.write('queryParams', null);
  //   this.authService.logoffLocal();
  //   this.router.navigate(['/autologin']);
  // }
}

