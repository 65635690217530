import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { OrgService } from '@shared/services/org-service/org.service';
import { Patient } from '@shared/models/patient'; 
import { PatientService, ITrackApiAwvPatient, EpisodeOfCareProtocolAssignmentViewModel } from '@shared/services/patient/patient.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import _ from 'lodash';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { faUser, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { common } from '@hcd-caravanhealth/pkg-wptypes';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { Activity } from '@shared/models/activity';
import { Protocol } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/protocol';
import { Procedure } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/organization';
import { PatientIdentifiersIssuers } from '@shared/models/module-constants';
import { PatientEngagement } from '@shared/enums';
import { ToastrService } from 'ngx-toastr';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { wpdb } from '@hcd-caravanhealth/pkg-wptypes';

@Component({
  selector: 'coach-single-patient-invite-form',
  templateUrl: './single-patient-invite-form.component.html',
  styleUrls: ['./single-patient-invite-form.component.scss'],
})
export class SinglePatientInviteFormComponent implements OnInit, OnChanges {
  @Input() public patient;
  @Input() public patients;
  @Output() public closeForm: EventEmitter<any> = new EventEmitter();
  @Output() public patientInvited: EventEmitter<{ _id: string }>;
  @Input() tier: IHierarchyTier;

  isModuleSelectorIsOpen: boolean;
  bulkInviteStatus: { message: string; counter: number };
  patientClone: Patient & {
    inviteStatus?: { loading: number; message: string };
  } = null;
  orgData: any = null;
  selectHolder: any = {
    primaryProfessional: {},
    newProtocol: {},
    newModules: [],
    newProcedure: {
      allowedProtocols: [],
    },
    extendedFields: {},
  };
  protocols: any = [];
  protocolsById: any = {};
  modulesById: any = {};
  professionals: wpapi.model.Professional[] = null;
  professionalsById: any = {};
  inviteStatus: any = {
    loading: 0, // 0 - no activity, 1 - loading, 2 - success, -1 - failed
    message: '',
  };
  welcomeMail: any = {};
  orgId: string = null;
  formStep: 1 | 2 | 3 = 1;
  private emitter: any;
  selectedProtocol: string = null;
  selectedProcedure: string = null;
  faUser = faUser;
  faFileAlt = faFileAlt;
  bulkPatientsProfessionalId: string;
  isPatientInvited: boolean = false;
  assignedProcedures: Procedure[];
  smsEnrollmentAgreed: boolean = false;
  private currentUser: wpapi.model.Professional;
  PatientEngagement = PatientEngagement;
  isSMSInviteFlagEnabled: boolean = false;
  SMSInviteFlag: string = CareFeatureConstants.SMSInviteFlag;
  isSMSPatient: boolean = false;
  invitationEligible: boolean = false;

  constructor(
    private OrgService: OrgService,
    private PatientService: PatientService,
    private auditService: AuditService,
    private AuthService: AuthService,
    private toast: ToastrService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.getCurrentUserInfo();
    this.isSMSInviteFlagEnabled = this.featureFlagService.hasFeatureFlag(
      this.SMSInviteFlag
    );
  }     

  async ngOnInit() {
    this.getAndStoreProtocolsForOrg();
    this.loadPatientInviteDetailsForOrg();
    if (this.patients) {
      this.patients.forEach((p) => {
        if (p.dob) {
          p.dob = moment(p.dob).format('MM/DD/YYYY');
        }
      });
    }
    this.isPatientInvited =
      this.patient?.carePatient?.accountType === 'patient';
    this.isInvitationEligible();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectHolder = {
      primaryProfessional: {},
      newProtocol: {},
      newModules: [],
      newProcedure: {
        allowedProtocols: [],
      },
      extendedFields: {},
    };
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes.patient &&
      changes.patient.currentValue &&
      changes.patient.currentValue != changes.patient.previousValue
    ) {
      this.patientClone = _.cloneDeep(changes.patient.currentValue);
    }

    const smsTask = this.patient.tasks.find(task => task.isActive && task.type == "twilioStudioFlow")
    if(smsTask){
      this.isSMSPatient = true;
    }

    this.isInvitationEligible();

  }

  close(action_target: string): void {
    this.closeForm.emit(action_target);
  }
  // ======== DATA GETTERS ============

  isInvitationEligible() {
    for (let procedure in this.orgData?.availableProcedures) {
      if (this.orgData.availableProcedures[procedure].isSelected) {
        for (let protocol in this.orgData.availableProcedures[procedure].allowedProtocols) {
          let prot = this.orgData.availableProcedures[procedure].allowedProtocols[protocol];
          if (this.protocolsById[prot]?.isSelected) {
            if (this.doesProtocolHaveTwilioFlowTask(this.protocolsById[prot])) {
              this.invitationEligible = true
              return
            }
          }
        }
      }
    }
  }
  private async getCurrentUserInfo(): Promise<void> {
    this.currentUser = await this.AuthService.getWellpepperUserInfo();
  }

  async getAndStoreProtocolsForOrg(): Promise<void> {
    if(!this.orgId) {
      this.orgId = await this.PatientService.getWpOrgIdFromTierId(
        this.tier?.selectedTierId
      );
    }
    let protocols: any = await this.OrgService.getProtocolsForOrg(this.orgId);
    protocols.forEach((p) => {
        this.protocolsById[p._id] = p;
        this.storeModuleNames(p.modules);
    });
  };
  
  async loadPatientInviteDetailsForOrg(): Promise<void> {
    if(!this.orgId) {
      this.orgId = await this.PatientService.getWpOrgIdFromTierId(
        this.tier?.selectedTierId
      );
    }
    this.getProfessionalsForOrg(this.orgId);
    let org: any = await this.OrgService.getOrg(this.orgId);

    function filterProcedureExtendedFields(fields) {
      return _.filter(fields, function (field) {
        switch (field.name) {
          case 'procedure':
          case 'procedureLaterality':
          case 'proceureLaterality': // this spelling is as-built
            return false;
          default:
            return true;
        }
      });
    }

    function getAvailableProceduresFromInvitations(org): any[] {
      var availableProcedures = [];
      if (org.data.invitations && org.data.invitations.extendedFields) {
        _.map(org.invitations.extendedFields, function (field) {
          switch (field.name) {
            case 'procedure':
              availableProcedures = _.map(field.options, function (option) {
                var procedure = {
                  identifiers: [{ type: 'name', identifier: option.value }],
                  name: option.value,
                  title: option.title,
                  extendedFields: [
                    {
                      name: 'laterality',
                      title: 'Laterality',
                      fieldOptions: [
                        { value: '', title: 'None' },
                        { value: 'Left', title: 'Left' },
                        { value: 'Right', title: 'Right' },
                      ],
                      getTitle: function () {
                        var value = this.value;
                        var option = _.find(
                          this.fieldOptions,
                          function (o: { title: string; value: any }) {
                            return o.value == value;
                          }
                        );
                        if (option) {
                          return option.title;
                        }
                        return value;
                      },
                    },
                  ],
                };
                return procedure;
              });
              return false;
          }
        });
      }
      return availableProcedures;
    }

    if (org) {
      if (!org.availableProcedures) {
        if (org.invitations && org.invitations.extendedFields) {
          org.availableProcedures = getAvailableProceduresFromInvitations(org);
          org.invitations.extendedFields = filterProcedureExtendedFields(
            org.invitations.extendedFields
          );
          org.hasProcedures =
            org.availableProcedures &&
            Array.isArray(org.availableProcedures) &&
            org.availableProcedures.length > 0;
        } else {
          org.hasProcedures = false;
        }
      } else {
        org.hasProcedures = true;
      } 
      if (org.invitations && org.invitations.extendedFields) {
        org.invitations.extendedFields = filterProcedureExtendedFields(
          org.invitations.extendedFields
        );
      }
    }
    this.orgData = org;
  }

  async getProfessionalsForOrg(orgId) {
    this.professionals = await this.OrgService.getProfessionalsForOrg(orgId);
    this.professionals = this.professionals.filter(
      (p) =>
        p.elegibleRoles?.filter((er) => er == 'primaryProfessional').length > 0
    );
    this.professionals.forEach((p) => {
      this.professionalsById[p._id] = p;
    });
  }

  storeModuleNames(modules) {
    if (modules) {
      modules.forEach(async (m) => {
        this.modulesById[m.protocolId] = await this.OrgService.getModuleName(
          m.protocolId
        );
      });
    }
  }

  // ========= Send Invite =============
  async sendInvite(
    patient?: Patient & { inviteStatus?: { loading: number; message: string } }, inviteInfo?: {userId: any, taskList: wpapi.model.Task[], taskId: string, userHasSeenSMSOptOut: boolean}
  ) {
    if (!patient.inviteStatus) {
      patient.inviteStatus = {
        loading: null,
        message: null,
      };
    }
    patient.inviteStatus.loading = 1;

    let triggerTwilioFlowForPatient: wpapi.model.Notification;

    if(inviteInfo) {
      triggerTwilioFlowForPatient = await this.PatientService.triggerTwilioFlowForPatient(this.orgId, inviteInfo.userId, inviteInfo.taskId, inviteInfo.userHasSeenSMSOptOut);

    } else {
      let userId = this.patient.carePatient._id;
      let taskList = await this.PatientService.getTasks(this.tier, this.patient);
      let task = taskList.find((task) => { 
        return task.userId == userId && task.type == 'twilioStudioFlow' 
      });
      let taskId = task._id;
      let userHasSeenSMSOptOut = false;
      triggerTwilioFlowForPatient = await this.PatientService.triggerTwilioFlowForPatient(this.orgId, userId, taskId, userHasSeenSMSOptOut);

      if (!triggerTwilioFlowForPatient) {
        patient.inviteStatus.loading = -1;
        patient.inviteStatus.message = "Invitation failed to resend";
        return false;
      } else {
        patient.inviteStatus.loading = 2;
        patient.inviteStatus.message = 'Successfully resent invitation';
        return true;
      }
    }
  }

  // ========= CREATE A NEW PATIENT =============
  async submit(
    patient: Patient & {
      inviteStatus?: { loading: number; message: string };
      professionalName?: string;
    } & {
      trackAwvPatient?: ITrackApiAwvPatient;
    },
    professionalId: string
  ) {
    if (!patient.inviteStatus) {
      patient.inviteStatus = {
        loading: null,
        message: null,
      };
    }
    patient.inviteStatus.loading = 1;
    patient.inviteStatus.message = 'Storing patient';

    let patientResult = await this.createUser(patient, professionalId);
    if (!patientResult) {
      patient.inviteStatus.loading = -1;

      if(!patient.inviteStatus.message)
      {

        patient.inviteStatus.message =
        patientResult?.error?.message || "Can't create user";
      }
      return;
    }
    let assignedProceduresAndProtocolsResult =
      await this.assignProceduresAndProtocols(
        patient,
        professionalId,
        patientResult
      );
    if (!assignedProceduresAndProtocolsResult) {
      return;
    }

    if(this.smsEnrollmentAgreed){

      let enrolledinSMSby = `${
        this.currentUser.lastName && this.currentUser.firstName
          ? ` ${this.currentUser.firstName} ${this.currentUser.lastName} `
          : `${this.currentUser.email}`
      }`;
      let invitedtoSMS: Activity = {
        type: 'invitation',
        date: new Date().toString(),
        title: 'Enrolled in SMS by ' + enrolledinSMSby,
        description: null,
        detail: null,
      };
      this.patient.activities.push(invitedtoSMS);
      this.patient.activities.sort((a, b) => {
        if (moment(a.createdAt).isAfter(moment(b.createdAt))){
          return -1;
        }
        else if (moment(a.createdAt).isSame(moment(b.createdAt))){
          return 0;
        }
        else{
         return 1;
        }
      });
    }
      patient.inviteStatus.loading = 2;
      patient.inviteStatus.message =
        'Successfully invited and sent notification';
      let invitedAct: Activity = {
        type: 'invitation',
        date: new Date().toString(),
        title: 'Invited',
        description: null,
        detail: null,
      };
      this.patient.activities.push(invitedAct);
      window.location.reload();
      return true;
  }

  async createUser(
    patient: Patient & {
      inviteStatus?: { loading: number; message: string };
      professionalName?: string;
    } & {
      trackAwvPatient?: ITrackApiAwvPatient;
    },
    professionalId: string
  ): Promise<any> {
    let date = new Date();
    this.isInvitationEligible();
    // TODO - Add a "PatientCreate" type in wpapi.model

    const orgMappedTierId =
      await this.PatientService.getWpMappedTierIdFromTierId(
        this.tier?.selectedTierId
      );
    const orgMappedTierNum =
      await this.PatientService.getWpMappedTierNumFromTierId(
        this.tier?.selectedTierId
      );
    let patientDetails: wpapi.model.Patient &
      common.PermissionedObject = {
      ACL: {
        '57980332bba6670e7409e9e5': { read: true, write: true },
        [this.orgId]: { read: true, write: true },
        [professionalId]: { read: true, write: true },
      },
      
      accountType: 'patient',
      currentTimeZoneName:
        patient.currentTimeZoneName ||
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      currentTimeZoneOffsetSec:
        patient.currentTimeZoneOffsetSec || date.getTimezoneOffset() * -60,
      dob:
        patient.dob ||
        patient.trackPatient?.dob ||
        patient.trackAwvPatient?.dob, // trackAwvPatient
      email:
        patient.email ||
        patient.trackPatient?.email ||
        patient.trackAwvPatient?.email,
      firstName: patient.firstName,
      gender: this.getGenderFromString(
        patient.gender ||
          patient.trackPatient?.gender ||
          patient.trackAwvPatient?.gender
      ),
      lastName: patient.lastName,
      mbi:
        patient.mbi ||
        patient.trackPatient?.mbi ||
        patient.trackAwvPatient?.mbi,
      mrn:
        patient.mrn
        // Track API doesn't return a MRN afaik
        /* ||
        patient.trackPatient?.mrn ||
        patient.trackAwvPatient?.mrn,
        */,

      nickName:
        patient.nickName?.trim() ||
        patient.trackPatient?.preferredName ||
        patient.trackAwvPatient?.preferredName ||
        '',
      phoneNumber: patient.phoneNumber?.replace(/\D+/g, '') || '',
      professionalId: professionalId,
      userMustConfirmDetails: true,
      // CC-1727 - username may have been pre-generated, don't use it for an email address which would have been collected in this form.
      username:
        patient.email ||
        patient.trackPatient?.email ||
        patient.trackAwvPatient?.email,
      identifiers: [
        // Don't save chPatId as an identifier - it's not going to be unique
        {
          type: 'custom',
          issuer: environment.patientIdentityProvider.issuer,
          subject: environment.patientIdentityProvider.formatSubject(
            orgMappedTierId,
            patient.chPatId
          ),
          createdAt: new Date().toDateString(),
          provider: 'custom'
        }
      ],
    };


    if(this.invitationEligible) {
    try {
      if(this.smsEnrollmentAgreed){
        let enrolledinSMSby = `${
          this.currentUser.lastName && this.currentUser.firstName
            ? ` ${this.currentUser.firstName} ${this.currentUser.lastName} `
            : `${this.currentUser.email}`
        }`;

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        patientDetails.verbalConsent =  {
          acceptedBy: this.currentUser._id,
          acceptedByName: enrolledinSMSby,
          acceptedByEulaId: 'ObjectId("639b9ced9ebf57f25b5da44a")', /* Hardcoded for now */
          acceptedDate: mm + '/' + dd + '/' + yyyy,
        };
      }
    }
    catch(e){
      console.log('SMS Enrollment Error:' , e);
    }

    }

    if(this.invitationEligible) {
      patientDetails.identifiers.push({
        issuer: PatientIdentifiersIssuers.SmsCarePlanPhoneNumber,
        type: 'custom',
        subject: this.PatientService.formatPhoneNumberForTwilio(patient.phoneNumber),
        createdAt: new Date().toDateString(),
        provider: 'custom',
        canBeUpdated: true
      })
    }
    if (patient?.carePatient?._id) {
      patientDetails._id = patient.carePatient._id;
    }
    let patientResult: any = await this.PatientService.createUser(
      patientDetails
    );
    if (!patientResult || patientResult.error) {
    //Temporary placeholder only, need to pre-check the dusplicate phone number/email in an earlier screen in next story.
      if(patientResult.status === 409 && this.smsEnrollmentAgreed){
        const isEmailAndSmsSubscribed = Object.values(this.protocolsById).filter((r:any)=> r.isSelected).length > 1;
        patient.inviteStatus.message =
         `This phone number ${isEmailAndSmsSubscribed ? 'or email' : ''} is already in use, please ${isEmailAndSmsSubscribed ? '' : 'update phone number or '}contact Signify Health for help`

      }
      return false;
    } else if (this.patientClone) {
      // Using Object.assign generates errors when assigning to read-only properties
      this.patient.refreshFromCareAndTrackPatient(
        patientResult,
        this.patientClone.trackPatient
      ); // Object.assign(patient, patientResult)
      this.patient.professional = await this.PatientService.getProfessional(
        patientResult.professionalId
      );
      this.patient.phoneNumber = Patient.formatPhoneNumber(
        this.patient.phoneNumber
      );
    } else {
      patient.carePatient = patientResult;
      patient.professionalName = (
        await this.PatientService.getProfessional(patientResult.professionalId)
      ).firstName;
    }

    await this.PatientService.updateTrackPatient(
      orgMappedTierId,
      orgMappedTierNum,
      {
        email: patientDetails.email,
        preferredName: patientDetails.nickName || '',
        phone: patientDetails.phoneNumber,
        tierID: orgMappedTierId,
        tierNum: orgMappedTierNum,
        carePatientId: patientResult._id,
        mbi: patientDetails.mbi,
        firstName: patient.firstName,
        lastName: patient.lastName,
        middleName: patient.middleName,
        dateOfBirth: patientDetails.dob,
        gender: patientDetails.gender?.toUpperCase()?.[0],
        chPatId: Number(patient.chPatId),
        ChPatID: Number(patient.chPatId),
        supplementalId:
          patient.supplementalId || patient.trackPatient?.supplementalID,
      }
    );

    this.auditService.auditLog(
      'Invite Patient',
      PatientEngagement.PatientApp,
      SubComponentId.Invitations,
      ActionId.Insert,
      patient.chPatId,
      {
        tier: orgMappedTierNum,
        selectedTierId: orgMappedTierId,
      } as IHierarchyTier,
      null
    );
    this.patient.phoneNumber = patientDetails.phoneNumber;
    return patientResult;
  }
  async assignProceduresAndProtocols(
    patient: Patient & {
      inviteStatus?: { loading: number; message: string };
      professionalName?: string;
    } & {
      trackAwvPatient?: ITrackApiAwvPatient;
    },
    professionalId: string,
    patientResult: any
  ): Promise<boolean> {
    for (let p in this.orgData.availableProcedures) {
      if (this.orgData.availableProcedures[p].isSelected) {
        patient.inviteStatus.message = `Assigning procedure ${this.orgData.availableProcedures[p].title}`;
        // assignedProcedures
        var episodeOfCare: any = { isActive: true };
        if (this.orgData.hasProcedures) {
          var newProcedure = this.orgData.availableProcedures[p];
          episodeOfCare.procedureName = newProcedure
            ? newProcedure.name
            : 'Procedure';
          if (newProcedure && newProcedure.shortName) {
            episodeOfCare.procedureShortName = newProcedure.shortName;
          }
          if (newProcedure && newProcedure.extendedFields) {
            episodeOfCare.extendedFields = newProcedure.extendedFields;
          }
        } else {
          episodeOfCare = {
            isActive: true,
            procedureName: 'Surgery',
            procedureShortName: 'Surgery',
          };
        }
        episodeOfCare.procedureTimeZoneName = professionalId
          ? this.professionalsById[professionalId].currentTimeZoneName
          : patient.currentTimeZoneName ||
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        let assignedEpisodeOfCare =
          await this.PatientService.assignEpisodeOfCare({
            episodeOfCare: episodeOfCare,
            patient: patientResult,
          });
        if (!assignedEpisodeOfCare) {
          patient.inviteStatus.loading = -1;
          patient.inviteStatus.message = "Can't assign episode of care";
          return false;
        }
        // =================== ASSIGNING PROTOCOLS ===================
        for (let p2 in this.orgData.availableProcedures[p].allowedProtocols) {
          let prot = this.orgData.availableProcedures[p].allowedProtocols[p2];
          if (this.protocolsById[prot]?.isSelected) {
            patient.inviteStatus.message = `Assigning protocol ${this.protocolsById[prot].name}`;
            let modules = {};
            this.protocolsById[prot]?.modules?.forEach((m) => {
              if (m.isActive) {
                modules[m['protocolId']] = {
                  isActive: true,
                };
              }
            });
            let assignedProtocol = await this.PatientService.assignProtocol({
              protocol: this.protocolsById[prot],
              patient: patientResult,
              modules: modules,
              episodeOfCare: assignedEpisodeOfCare,
            });
            if (this.doesProtocolHaveTwilioFlowTask(this.protocolsById[prot])){
              let userId = this.patient.carePatient._id;
              let taskId = '';
              let userHasSeenSMSOptOut = false;
              let taskList : any = assignedProtocol.tasks;
              let twiliotask : wpdb.model.TwilioFlowTask;
              for (const task of taskList ){
                if (task.userId == userId && task.type == 'twilioStudioFlow' && task.isActive){
                  taskId = task._id;
                  twiliotask = task
                }
              }
              
              if (twiliotask?.twilioInfo?.scheduleDefinition){
                const frequency = 'weekday'; 
                const isAdaptive = true;
                const twilioFlowSchedule = await this.PatientService.createTwilioFlowSchedule(userId, taskId, frequency,isAdaptive);
              } 
              let triggerTwilioFlowForPatient = await this.PatientService.triggerTwilioFlowForPatient(this.orgId, userId, taskId, userHasSeenSMSOptOut);
              if(triggerTwilioFlowForPatient){
                this.toast.success('Invitation for Daily Monitoring Program is sent. It may take a few minutes for delivery.');
              let inviteInfo = {
                userId: userId,
                taskList: taskList,
                taskId: taskId,
                userHasSeenSMSOptOut: userHasSeenSMSOptOut
              }
              this.sendInvite(null, inviteInfo)
            }
            if (!assignedProtocol) {
              patient.inviteStatus.loading = -1;
              patient.inviteStatus.message = "Can't assign protocol";
              return false;
            }
          }
        }
      }
    }
    return true;
  }
}

  public toggleModuleSelectorOpen(protocolId?: string): void {
    for (let p_Id in this.protocolsById) {
      if (this.protocolsById[p_Id]) {
        this.protocolsById[p_Id].isModuleSelectorIsOpen =
          p_Id == protocolId && !this.protocolsById[p_Id].isModuleSelectorIsOpen
            ? true
            : false;
      }
    }
  }

  selectProtocol(procedure: any, protocolId: string): void {
    for (const key in this.protocolsById) {
        if(protocolId === key){
          this.protocolsById[protocolId].isSelected = this.protocolsById[protocolId]
          .isSelected
          ? false
          : true;
        } else {
          this.protocolsById[key].isSelected = false;
        }
    }

    this.orgData.availableProcedures.forEach((p, index) => {
      if(procedure === p){
        this.orgData.availableProcedures[index].isSelected = true;
      } else {
        this.orgData.availableProcedures[index].isSelected = false;
      }
    });
  }

  checkProcedureAndProtocol(procedure: any, protocolId: string): void {
    if (
      this.protocolsById[protocolId] &&
      this.getSelectedModules(protocolId).length > 0
    ) {
      this.protocolsById[protocolId].isSelected = true;
      procedure.isSelected =
        procedure.allowedProtocols.filter(
          (protocol) => this.protocolsById[protocol]?.isSelected
        ).length > 0
          ? true
          : false;
    }
  }

  getSelectedModules(protocolId: string): any[] {
    return this.protocolsById[protocolId]?.modules
      ? this.protocolsById[protocolId].modules.filter((m) => m.isActive)
      : [];
  }

  public getSelectedProceduresLength(): number {
    // Add more rules if needed
    return this.orgData.availableProcedures.filter(
      (procedure) => procedure.isSelected
    ).length;
  }
  
  public isProtocolSelected(): boolean {
    // Add more rules if needed
    for(const protocolId in this.protocolsById){
      if(this.protocolsById[protocolId].isSelected){
        return true
      }
    }
    
    return false
  }
  
  isPatientValid(): boolean {
    if(this.isSMSInviteFlagEnabled){
      return (
        this.patientClone.professionalId?.length > 0 &&
        this.patientClone.gender?.length > 0 &&
        this.isInputValid(this.patientClone, 'phoneNumber') &&
        (this.isInputValid(this.patientClone, 'email') || !this.patientClone.email || this.patientClone.email == "")
      );
    } else {
      const professional = this.professionals.find((p) => {
        let isDefaultProfessional = p.elegibleRoles.find(role => role === "defaultProfessional")
        return isDefaultProfessional ? true : false
      })
      this.patientClone.professionalId = professional._id
      return (
        this.patientClone.gender?.length > 0 &&
        this.isInputValid(this.patientClone, 'phoneNumber') &&
        (this.isInputValid(this.patientClone, 'email') || !this.patientClone.email)
      );
    }
  }

  isInputValid(patient: Patient, target: string): boolean {
    switch(target){
      case 'email':
        if(!patient.email || patient.email == ""){
          return true; //Email is now optional so no input is valid
        } else {
          return this.PatientService.isEmailFormatValid(patient[target])
        }
      case 'nickName':
        return patient[target].length > 0
      case 'phoneNumber':
        return this.PatientService.isPhoneNumberFormatValid(patient[target])
    }
  }

  doesProtocolHaveTwilioFlowTask(protocol: Protocol | EpisodeOfCareProtocolAssignmentViewModel): boolean {
    return !!protocol.tasks?.find(t => t.type == "twilioStudioFlow");
  }

  getGenderFromString(s: string): common.UserGender {
    if (!s || s.length < 1) {
      return 'unknown';
    }

    switch (s[0].toLowerCase()) {
      case 'm':
        return 'male';
      case 'f':
        return 'female';
      case 'o':
        return 'other';
      default:
        return 'unknown';
    }
  }

}