export class FormConstants {
  public static Quality = {
    overviewTitle: 'Quality Submissions',
    title: 'Quality Form',
    submissionPeriod: 'Loading',
    dueDate3rdParagraph: '',
    requirements: '',
    instructionsPart1:
      'Before moving to step 1, please be prepared with an EHR Quality report with aggregated numerator and denominator counts for patient screenings and care gaps closed between ',
    instructionsPart2:
      ' on your clinical quality measures. Please review the instructions in steps 1 and 2 before submitting the form.',
    noteTextPart1: 'Note: ',
    noteTextPart2:
      ' On the form, if your practice EHR configuration covers all practices listed, then one submission will cover all your practices. If your practices are on disparate EHRs or databases, to the extent possible and/or applicable, please submit data for ',
    noteTextPart3: ' each of your practices',
    noteTextPart4: ' participating in the ACO.',
    disclaimer:
      'Disclaimer: This tool is intended for estimating purposes only, and does not reflect or predict the ACO or MIPS Quality score assigned by CMS.',
    dataSourceToolTipText:
      'Data should be generated from your EHR dashboard for the 2021 calendar year. No other options available during this submission period.',
    shwSpecialStatus: true,
    useCurrentHierarchy: true,
    drpDataSource: ['From EHR'],
    drpEHR: ['Allscripts|AthenaHealth|Cerner|CPSI|eClinicalWorks|e-MDs|Epic|GE Healthcare|Greenway Health|Healthland|Meditech|NextGen|Other'],
    drpPatientPop: ['All Payors'],
    instructionDataSrc: 'Select data source',
    stepSpecialStatus1Text: 'The following practices have been previously identified as having a special status and therefore exempt from this submission process.',
    stepSpecialStatus2Text: 'Please review to ensure that these exemptions are still accurate. If a practice status has changed, update to the appropriate status or click remove so that we can include the practice in this submission.',
    stepAddEditText: 'We keep track of all forms submitted or saved as draft. Come back here to review and update any submitted or saved forms prior to the submission deadline of ',

    tooltipEHRText: '<p>The twelve most popular EHRs are listed. If your EHR is not indicated, please select Other.</p>',
    tooltipDataSourceText: '<p>Data should be generated from your EHR dashboard for the 2021 calendar year. No other options available during this submission period.</p>',
    tooltipPatientPopText: '<p>Data should represent patients from All Payors to mirror and prepare for upcoming CMS changes for PY2022</p>',

    tooltipPracticeText: '<p>Lists practice(s) with attribution in the ACO. Click all practices that share an EHR to indicate that the submitted Quality data covers all selected practices.</p>',
    tooltipExemptText: '<p>Indicate whether selected practice(s) are exempt. If exempt, please indicate the reason in "Special Status" below.</p>',
    tooltipSpecialStatusText: '<p>Select a new Special Status, for a particular practice.</p>',
    tooltipUploadQualityMeasureReportText: '<p>Before submitting, please upload an electronic copy of the EHR report or excel template used to complete the form for verification and accountability.</p>',
  };
  public static QualityOpeningInfo = '2021 YTD - 8/31, EHR only ';
  public static QualityOpenDueDate = '(Opening January 2022)';
  public static QualityPatientPopulationToolTipText =
    'Data should represent patients from All Payors. The Medicare Only option is not available during this submission period.';

  public static ComplianceOpeningInfo = '2021 Self-Assessment ';
  public static ComplianceOpenDueDate = '(Not Open)';

  public static PIOpeningInfo = '2021 Best 90 days ';
  public static PIOpenDueDate = '(Opening January 2022)';


  public static MeetingWidgetName = 'Partnership Meetings';
  public static MeetingOpeningInfo = 'Meeting Upload(s) ';
  public static MeetingOpenDueDate = '(due End of Month, Recurring)';
  public static MeetingCommunitySelection =
    "From the hierarchy above, please change 'Principle Participants' and 'Practice' to 'All'";

    public static PromotingInterop = {
      overviewTitle: 'Promoting Interoperability',
      title: 'Promoting Interoperability',
      submissionPeriod: 'Loading',
      instructionsPart1:
        'Please be prepared with numerator and denominator counts for the ',
        instructionsPart2:
        'best 90 day period',
        instructionsPart3:
        ' (or YTD depending on EHR capability) on the Promoting Interoperability measures',
        instructionsPart4:
        'Then follow the steps to submit your form(s) for each practice participating in the ACO.',
      noteTextPart1: 'Note: ',
      noteTextPart2:
        ' To the extent possible and/or applicable, we\'d like to collect distinct measure data from the patient populations of',
      noteTextPart3:
        'each of your practices ',
      noteTextPart4:
        'participating in the ACO.',
      disclaimer:
        'Disclaimer: This tool is intended for estimating purposes only, and does not reflect or predict the ACO or MIPS Quality score assigned by CMS.',
      shwSpecialStatus: true,
      drpEHR: ['Allscripts|AthenaHealth|Cerner|CPSI|eClinicalWorks|e-MDs|Epic|GE Healthcare|Greenway Health|Healthland|Meditech|NextGen|Other'],
      infoMeasureLinkFolder: '1025025',
      infoScoringLinkFolder: '1025016',
      infoScoringLinkFile: '1025595',
      formNoteInternal:
        'Fill in the following fields based on the best 90-day snapshot in the reporting period.',
      stepSpecialStatus1Text:
        'The following practices have been previously identified as having a special status and therefore exempt from this submission process.',
      stepSpecialStatus2Text:
        'Please review to ensure that these exemptions are still accurate. If a practice status has changed, update to the appropriate status or or click remove so that we can include the practice in this submission.',
      stepAddEditText:
        'We keep track of all forms submitted or saved as draft. Come back here to review and update any submitted or saved forms prior to the submission deadline of ',

    };

    public static Compliance = {
      overviewTitle: 'Compliance Submissions',
      title: 'Compliance Form',
      submissionPeriod: 'Loading',
      requirements: '',
      instructionsPart1:
        'The 2022 self-assessment has officially begun! Completed self-assessments are due no later than ',
      instructionsPart2:
        '. To assist you in completing the self-assessment, we have a number of resources available including an FAQ and \'how to\' instructional video.  Please find these resources via the following path Caravan Coach > Improve > Practice Improvement > Compliance > Self-Assessment',
      noteTextPart1: 'Note: ',
      noteTextPart2:
        'While the self-assessment exercise ends on ',
      noteTextPart3: 'April 4th',
      noteTextPart4: ', remember you have until ',
      noteTextPart5: 'May 6th',
      noteTextPart6: ' to resubmit your self-assessment for improved credit.',
      disclaimer:
        '',
      dataSourceToolTipText:
        '',
      dueDateSoft: '',
      remediationCloseDate: '',
    };

}

export interface  QualityFormSettings {
  overviewTitle: string;
  title: string;
  submissionPeriod: string;
  dueDate3rdParagraph: string;
  requirements: string;
  instructionsPart1: string;
  instructionsPart2: string;
  noteTextPart1: string;
  noteTextPart2: string;
  noteTextPart3: string;
  noteTextPart4: string;
  disclaimer: string;
  dataSourceToolTipText: string;
  shwSpecialStatus: boolean;
  useCurrentHierarchy: boolean;
  drpDataSource: string[];
  drpEHR: string[];
  drpPatientPop: string[];
  instructionDataSrc: string;
  stepSpecialStatus1Text: string;
  stepSpecialStatus2Text: string;
  stepAddEditText: string;

  tooltipEHRText: string;
  tooltipDataSourceText: string;
  tooltipPatientPopText: string;
  tooltipPracticeText: string;
  tooltipExemptText: string;
  tooltipSpecialStatusText: string;
  tooltipUploadQualityMeasureReportText: string;



}

export interface  PromotingInteropFormSettings {
  overviewTitle: string;
  title: string;
  submissionPeriod: string;
  instructionsPart1: string;
  instructionsPart2: string;
  instructionsPart3: string;
  instructionsPart4: string;
  noteTextPart1: string;
  noteTextPart2: string;
  noteTextPart3: string;
  noteTextPart4: string;
  disclaimer: string;
  shwSpecialStatus: boolean;
  drpEHR: string[];
  infoMeasureLinkFolder: string;
  infoScoringLinkFolder: string;
  infoScoringLinkFile: string;
  formNoteInternal: string;
  stepSpecialStatus1Text: string;
  stepSpecialStatus2Text: string;
  stepAddEditText: string;

}

export interface  ComplianceFormSettings {
  overviewTitle: string;
  title: string;
  submissionPeriod: string;
  requirements: string;
  instructionsPart1: string;
  instructionsPart2: string;
  noteTextPart1: string;
  noteTextPart2: string;
  noteTextPart3: string;
  noteTextPart4: string;
  noteTextPart5: string;
  noteTextPart6: string;
  disclaimer: string;
  dataSourceToolTipText: string;
  dueDateSoft: string;
  remediationCloseDate: string;
}
