import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Patient } from '@shared/models/patient';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { ITrackApiIHEFilesListVisitInfo, PatientService } from '@shared/services/patient/patient.service';
import moment from 'moment';
import { IconDefinition, faComment, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { fileStatus, PatientIHEFileDownloadInfo, PatientIHEFileInfo, PatientIHEFileType, PatientIHEFileTypeLabels, AssessmentFileTypes } from '@shared/models/patient-ihe-data';
import _, { forEach } from 'lodash';
import { PatientViewModel } from '@shared/models/patient';
import { is } from 'date-fns/locale';
import { AwvWorklistFiltersComponent } from '@care/components/awv-worklist/awv-worklist-filters/awv-worklist-filters.component';
import { note } from '@shared/modules/patient-facesheet/tabs/patient-ihe-worklist/patient-ihe-worklist.component';

export interface awvHistoryElement {
  createdBy?: string,
  createdById?: number,
  createdAt?: string,
  proName: string,
  proAvatar?: string,
  action?: 'upload' | 'download' | 'note'
  noteText?: string,
  noteId?: number,
  faIcon?: IconDefinition,
  fileType?: PatientIHEFileType
}
@Component({
  selector: 'coach-patient-awv-report',
  templateUrl: './patient-awv-report.component.html',
  styleUrls: ['./patient-awv-report.component.scss']
})
export class PatientAwvReportComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input() public mobileScreenSize: boolean;
  @Input() sidebarMode?: boolean;
  @Input() public isTabOpen: boolean;
  @Output() patientUpdated: EventEmitter<null> = new EventEmitter();

  historyDetails: awvHistoryElement[] = [];
  note: string = '';
  notes: note[] = [];
  user: wpapi.model.Professional;
  professionalsById: { [key: string]: wpapi.model.Professional } = {};
  dateChange: boolean = false;
  statusDate: string ='';


  today: string = moment().format("yyyy-MM-DD");
  oneYearAgo: string = moment().subtract(1,'year').format("yyyy-MM-DD");
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get tierNum() {
    return this?.tier?.tier;
  }
  constructor(private patientService: PatientService,private authService: AuthService) { }

  async ngOnInit() {
    if (!this.patient?.trackAwvPatient && this.patient?.chPatId) {
      await this.getAndAssignStatusDateFromTrackAwvPatientInfo();
    }
    this.statusDate = this.patient.statusDate;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.patient) {
      this.resetInfo();
      this.getNotes();
      this.getCurrentUserInfo();
    }
  }

  resetInfo(): void {
    this.note = '';
    this.historyDetails = [];
    this.statusDate = this.patient.statusDate;
  }
  async setChangeDateFlag(){
    this.dateChange = true;
  }
  async updateStatusDate() {
    if ((this.patient.trackAwvPatient?.statusDate != this.statusDate) || !this.statusDate ) {
        try {
          let scheduleId: number = null; // get patient schedule ID
          await this.patientService.updateAwvStatusDate(Number(this.patient.chPatId || this.patient.id), this.statusDate || null, scheduleId)
          if (this.patient?.trackAwvPatient) {
            this.patient.trackAwvPatient.statusDate = this.statusDate ? moment(this.statusDate).format("MM/DD/YYYY") : null;
            this.patient.statusDate = this.patient.trackAwvPatient.statusDate;
            this.patientUpdated.emit();
          }
          this.dateChange = false;
          return;
        } catch (error) {
          console.error(error)
        }
    }
  };
  async getNotes() {
    let notesHistory: note[] = await this.patientService.getNote(this.patient.chPatId, 1);
    this.notes = notesHistory;
  }
  async postNote() {
    if(!this.note.length){

    } else{
      let noteId = await this.patientService.postNote(this.patient.chPatId, this.note, 1, null, null)
      this.notes.push({
          createDateTime: moment.utc().toString(),
          createUserFullName: this.user?.firstName + ' ' + this.user?.lastName,
          createUserName: this.user?.email,
          createdByUserID: null,
          lastUpdateDateTime: moment.utc().toString(),
          lastUpdateUserID: null,
          lastUpdateUserFullName: null,
          noteID: noteId,
          noteText: this.note,
          updateUserName: this.user?.email,
      })
      this.note = '';
    }
  }
  convertNotesElementIntoIHEHistoryElement(note: note): awvHistoryElement {
    return {
        createdBy: note.createUserFullName?.trim() || note.createUserDisplayName,
        createdById: note.createdByUserID,
        createdAt: moment(note.createDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
        proName: note.createUserName,
        noteText: note.noteText,
        noteId: note.noteID,
        action: 'note',
        faIcon: faComment
    }
  }
  addElementIntoHistoryList(el: awvHistoryElement): void {
    this.historyDetails.push(el);
  };
  getLiveUpdatedHistoryList(): awvHistoryElement[] {
    let result: awvHistoryElement[] = [];
    //notes
    this.notes.forEach(note => {
        note.createDateTime = moment.utc(note.createDateTime).toString();
        result.push(this.convertNotesElementIntoIHEHistoryElement(note));
    })
    if (result.length != this.historyDetails.length) {
        result.sort((a, b) => {
            if (moment(a.createdAt).isBefore(moment(b.createdAt))) return 1;
            else if (moment(a.createdAt).isAfter(moment(b.createdAt))) return -1;
            else return 0;
        });
        this.historyDetails = result;
    }
    return this.historyDetails;
  }
  async getCurrentUserInfo() {
    this.user = await this.authService.getWellpepperUserInfo()
  }
  getProfessionalsInfo(proIds: string[]): void {
    proIds?.forEach(id => {
        if (!this.professionalsById[id]) {
            this.patientService.getProfessional(id).then(professional => {
                this.professionalsById[id] = professional;
            })
        }
    })
}
  proAvatar(proId: string): string {
    return this.professionalsById?.[proId]?.avatar?.url;
  }
  async getAndAssignStatusDateFromTrackAwvPatientInfo() {
    const awvPatientInfo = await this.patientService.getTrackAwvPatients(this.tierNum, this.tierId, null, null, this.patient.chPatId);
    if (awvPatientInfo?.[0]) {
      this.patient.statusDate = moment(awvPatientInfo[0].statusDate).format("yyyy-MM-DD");
    }
  }
  sortHistoryListByDate(): void {
    this.historyDetails.sort((a, b) => {
        if (moment(a.createdAt).isBefore(moment(b.createdAt))) return 1;
        else if (moment(a.createdAt).isAfter(moment(b.createdAt))) return -1;
        else return 0;
    });
  }
  isDateBeforeOneYearAgo(): boolean {
    if (!this.statusDate) return false;
    const targetDate = moment(this.statusDate).add(1,'day');
    return targetDate.isValid() && targetDate.isBefore(moment().subtract(1,'year'));
  }
  isDateAfterToday(): boolean {
    if (!this.statusDate) return false;
    const targetDate = moment(this.statusDate);
    return targetDate.isValid() && targetDate.isAfter(moment());
  }
}


