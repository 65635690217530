import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'coach-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent {

  @Input() name: string;
  @Input() email: string;
  @Input() phone: string;
  @Input() description: string;
  @Input() title: string;

  constructor() { }
}
