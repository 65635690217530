import { Injectable } from '@angular/core';
import { HccDashboardClinician } from './hcc-dashboard-clinician';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HccCliniciansService extends AzureApiBase<HccDashboardClinician> {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/hccDashboard/clinicians');
  }

  getClinicians(tierNum: number, tierId: string): Observable<HccDashboardClinician[]> {
    const params: HttpParams = new HttpParams()
      .set('tierNum', tierNum == null ? '' : tierNum.toString())
      .set('tierId', tierId);

    return this.getAll(null, params);
  }
}
