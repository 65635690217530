import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ClientAccount, ClientContact } from './client-contact';
import { formatDate } from '@angular/common';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { takeUntil } from 'rxjs/operators';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss']
})
export class ClientContactsComponent extends KillSubscriptions implements OnInit {
  columnDefs = [
    { headerName: 'Account Name', field: 'accountName', sortable: true, filter: true, resizable: true },
    { headerName: 'Contact Name', field: 'contactName', sortable: true, filter: true, resizable: true },
    { headerName: 'Point-of-Contact Role', field: 'pocRole', sortable: true, filter: true, resizable: true },
    { headerName: 'Email', field: 'email', sortable: true, filter: true, resizable: true },
    { headerName: 'PHI', field: 'phi', sortable: true, filter: true, resizable: true, width: 50 },
    { headerName: 'Specify Edit/Remove/Add', field: '', sortable: true, filter: true, resizable: true, hide: true },
    { headerName: 'Notes to Caravan Health', field: '', sortable: true, filter: true, resizable: true, hide: true }
  ];

  rowData: ClientContact[];
  supportEmail: string = AppConstants.SupportEmail;
  private gridApi: any;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fileDownloadService: FileDownloadService,
    private hierarchyTierService: HierarchyTierService
  ) {
    super();
  }

  ngOnInit(): void {
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(tier => {
      if (tier != null) {
        let altTier = '';
        if (tier.highestTier == null) {
          altTier = tier.selectedTierId;
        } else {
          switch (tier.highestTier) {
            case 1:
              altTier = tier.selectedItem.tier1_ID;
              break;
            case 2:
              altTier = tier.selectedItem.tier2_ID;
              break;
            case 3:
              altTier = tier.selectedItem.tier3_ID;
              break;
            case 4:
              altTier = tier.selectedItem.tier4_ID;
              break;
            default:
              altTier = tier.selectedItem.tier2_ID;
              break;
          }
        }
        this.fileDownloadService
          .getClientContacts(tier.selectedItem.tier2_ID, altTier)
          .subscribe((accountsJson: string) => {
            const serializedAccounts: ClientAccount[] = JSON.parse(accountsJson) || [];
            const contacts: ClientContact[] = [];
            serializedAccounts.forEach(account =>
              account.c.forEach(contact =>
                contacts.push({
                  accountName: account.AccountName,
                  contactName: contact.FullName,
                  email: contact.Email,
                  phi: contact.PHI,
                  pocRole: contact.PointOfContactRole
                })
              )
            );

            this.rowData = contacts;
          });
      }
    });
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
  }

  onFirstDataRendered(params: any): void {
    params.api.sizeColumnsToFit();
  }

  export(): void {
    const timestamp = formatDate(new Date(), 'yyyyMMdd', this.locale);
    this.gridApi.exportDataAsExcel({
      allColumns: true,
      fileName: `${this.hierarchyTierService.currentTier$.value.selectedItem.tier2_ID}_Community_Contacts_${timestamp}.xlsx`
    });
  }
}
