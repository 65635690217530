/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacesheetExportDownloadRequest } from '../models/facesheet-export-download-request';
import { FacesheetScheduleItem } from '../models/facesheet-schedule-item';
import { HccFacesheetScheduleMap } from '../models/hcc-facesheet-schedule-map';
import { InterfaceSourceTier } from '../models/interface-source-tier';

@Injectable({
  providedIn: 'root',
})
export class FacesheetScheduleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation facesheetSchedulePdfPost
   */
  static readonly FacesheetSchedulePdfPostPath = '/FacesheetSchedule/pdf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetSchedulePdfPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetSchedulePdfPost$Response(params?: {
    body?: Array<FacesheetScheduleItem>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetScheduleService.FacesheetSchedulePdfPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetSchedulePdfPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetSchedulePdfPost(params?: {
    body?: Array<FacesheetScheduleItem>
  },
  context?: HttpContext

): Observable<void> {

    return this.facesheetSchedulePdfPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation facesheetScheduleExportPost
   */
  static readonly FacesheetScheduleExportPostPath = '/FacesheetSchedule/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetScheduleExportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetScheduleExportPost$Response(params?: {
    body?: Array<FacesheetScheduleItem>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetScheduleService.FacesheetScheduleExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetScheduleExportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetScheduleExportPost(params?: {
    body?: Array<FacesheetScheduleItem>
  },
  context?: HttpContext

): Observable<void> {

    return this.facesheetScheduleExportPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation facesheetScheduleGet
   */
  static readonly FacesheetScheduleGetPath = '/FacesheetSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetScheduleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetScheduleGet$Response(params?: {
    from?: string;
    to?: string;
    communityId?: string;
    practiceId?: string;
    providerId?: string;
    tier3Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HccFacesheetScheduleMap>>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetScheduleService.FacesheetScheduleGetPath, 'get');
    if (params) {
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('communityId', params.communityId, {});
      rb.query('practiceId', params.practiceId, {});
      rb.query('providerId', params.providerId, {});
      rb.query('tier3Id', params.tier3Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HccFacesheetScheduleMap>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetScheduleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetScheduleGet(params?: {
    from?: string;
    to?: string;
    communityId?: string;
    practiceId?: string;
    providerId?: string;
    tier3Id?: string;
  },
  context?: HttpContext

): Observable<Array<HccFacesheetScheduleMap>> {

    return this.facesheetScheduleGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HccFacesheetScheduleMap>>) => r.body as Array<HccFacesheetScheduleMap>)
    );
  }

  /**
   * Path part for operation facesheetScheduleInterfaceTiersGet
   */
  static readonly FacesheetScheduleInterfaceTiersGetPath = '/FacesheetSchedule/interfaceTiers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetScheduleInterfaceTiersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetScheduleInterfaceTiersGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<InterfaceSourceTier>>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetScheduleService.FacesheetScheduleInterfaceTiersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InterfaceSourceTier>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetScheduleInterfaceTiersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facesheetScheduleInterfaceTiersGet(params?: {
  },
  context?: HttpContext

): Observable<Array<InterfaceSourceTier>> {

    return this.facesheetScheduleInterfaceTiersGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<InterfaceSourceTier>>) => r.body as Array<InterfaceSourceTier>)
    );
  }

  /**
   * Path part for operation facesheetScheduleDownloadExportPost
   */
  static readonly FacesheetScheduleDownloadExportPostPath = '/FacesheetSchedule/downloadExport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facesheetScheduleDownloadExportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetScheduleDownloadExportPost$Response(params?: {
    body?: FacesheetExportDownloadRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FacesheetScheduleService.FacesheetScheduleDownloadExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `facesheetScheduleDownloadExportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  facesheetScheduleDownloadExportPost(params?: {
    body?: FacesheetExportDownloadRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.facesheetScheduleDownloadExportPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
