import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ImproveComponent } from './improve.component';
import { ImproveSummaryComponent } from '@views/improve/improve-summary/improve-summary.component';
import { ImproveAcoBoardComponent } from '@views/improve/improve-aco-board/improve-aco-board.component';
import { ImproveSearchComponent } from '@views/improve/improve-search/improve-search.component';
import { ImproveContactsComponent } from '@views/improve/improve-contacts/improve-contacts.component';
import { ImproveSteeringCommitteeComponent } from '@views/improve/improve-steering-committee/improve-steering-committee.component';
import { ImprovePracticeImprovementComponent } from '@views/improve/improve-practice-improvement/improve-practice-improvement.component';
import { ImproveInformationTechnologyComponent } from '@views/improve/improve-information-technology/improve-information-technology.component';
import { ImproveEventsComponent } from '@views/improve/improve-events/improve-events.component';

const routes: Routes = [
  {
    path: '',
    component: ImproveComponent,
    data: {
      label: 'Improve',
      helpfile: 'LEARN_IMPROVE_General_2020',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'summary',
      },
      {
        path: 'summary',
        component: ImproveSummaryComponent,
        data: {
          label: 'Summary',
        },
      },
      {
        path: 'aco-board',
        component: ImproveAcoBoardComponent,
        data: {
          label: 'ACO Board',
        },
      },
      {
        path: 'practice-improvement',
        component: ImprovePracticeImprovementComponent,
        data: {
          label: 'Practice Improvement',
        },
      },
      {
        path: 'information-technology',
        component: ImproveInformationTechnologyComponent,
        data: {
          label: 'Information Technology',
        },
      },
      {
        path: 'contacts',
        component: ImproveContactsComponent,
        data: {
          label: 'Contacts',
          helpfile: '',
        },
      },
      {
        path: 'search',
        component: ImproveSearchComponent,
        data: {
          label: '',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ImproveRoutingModule {}
