<div class="modalOverlay" *ngIf="showDialog">
<div class="bg-align" *ngIf="showDialog">
  <dialog open>
    <div class="row padding">
      <div class="span--11 u-bold u-font-size--large">{{dialogHeader}}</div>
      <coach-icon class="span--1 u-cursor--pointer" iconName="x" iconColor="black" (click)="onButtonClicked('cancel')"></coach-icon>
    </div>
    <hr>
    <div class="padding">{{dialogMessage}}</div>
    <hr>
    <div class="row buttonRow u-flex-direction--column-reverse">
      <button class="span--9 button delete-button u-color--white is-primary margin--small u-border-radius" id='testPopUpDeleteBtn' (click)="onButtonClicked('delete')">Delete</button>
      <button class="span--3 button  is-unstyled cancel-btn margin--small"  id='testPopUpCancelBtn' (click)="onButtonClicked('cancel')">Cancel</button>
    </div>
  </dialog>
</div>
</div>
