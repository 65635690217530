<div class="subheader" *ngIf="patient && patient.alerts && patient.alerts.unhandled.length > 0">Unhandled ({{patient.alerts.unhandled.length}})</div>
<div class="list" *ngIf="patient && patient.alerts && patient.alerts.unhandled.length > 0">
  <div class="list-item formatting--none" *ngFor="let alert of patient.alerts.unhandled; let idx = index" (click)="selectAlert(alert)" [hidden]="alert">
    <div class="row list-item-row--tertiary">
      <h6 class="margin--none">{{alert.filterDesc}}</h6>
      <h6 class="margin--none u-right">{{alert.updatedAt | date}}</h6>
    </div>
    <div class="row list-item-row--primary">
      <p class="margin-bottom--none u-color--brand--4">{{alert.desc}}</p>
    </div>
    <div class="row list-item-row--primary">
      <div class="field u-color--brand--3" *ngIf="alert.completion && alert.completion.systolic != null && alert.completion.diastolic != null">{{alert.completion.systolic}}/{{alert.completion.diastolic}}</div>
      <div class="field u-color--brand--3" *ngIf="alert.completion && alert.completion.bloodSugar != null">{{alert.completion.bloodSugar}} {{alert.completion.bloodSugarUnits}}</div>
      <div class="field u-color--brand--3" *ngIf="alert.completion && alert.completion.weight != null">Weight: {{alert.completion.weight}} {{alert.completion.weightUnits}}</div>
    </div>
    <div class="row margin-top--small button-group u-width--full custom-alertActionPanel-buttons" *ngIf="selectedAlert && selectedAlert['_id'] == alert._id">
      <button type="button" class="button has-tooltip" (click)="$event.stopPropagation(); deselectAlert()">
        <span class="tooltip">Close</span><fa-icon class='fa-sm' [icon]="faMinus"></fa-icon></button>
      <button type="button" class="button has-tooltip" (click)="alert.inactiveReason = 'phone'" [ngClass]="{'is-primary': alert.inactiveReason == 'phone'}"><span class="tooltip">Phoned patient</span><fa-icon class='fa-sm' [icon]="faPhone"></fa-icon></button>
      <button type="button" class="button has-tooltip" (click)="alert.inactiveReason = 'message'" [ngClass]="{'is-primary': alert.inactiveReason == 'message'}"><span class="tooltip">Sent message to patient</span><fa-icon class='fa-sm' [icon]="faSms"></fa-icon></button>
      <button type="button" class="button has-tooltip" (click)="alert.inactiveReason = 'handoff'" [ngClass]="{'is-primary': alert.inactiveReason == 'handoff'}"><span class="tooltip">Handed off to PA/MD</span><fa-icon class='fa-sm' [icon]="faUserMd"></fa-icon></button>
      <button type="button" class="button has-tooltip" (click)="alert.inactiveReason = 'reviewed'" [ngClass]="{'is-primary': alert.inactiveReason == 'reviewed'}"><span class="tooltip">Reviewed</span><fa-icon class='fa-sm' [icon]="faCheckSquare"></fa-icon></button>
      <button type="button" class="button has-tooltip" (click)="alert.inactiveReason = 'ignore'" [ngClass]="{'is-primary': alert.inactiveReason == 'ignore'}"><span class="tooltip">Ignored</span><fa-icon class='fa-sm' [icon]="faTimesCircle"></fa-icon></button>
    </div>
    <div *ngIf="selectedAlert && selectedAlert['_id'] == alert._id">
      <input placeholder="Please enter comments" [(ngModel)]="alert.inactiveReasonText" [maxlength]="commentMaxLength">
      <div class="text-right subtitle"><small>{{alert.inactiveReasonText?alert.inactiveReasonText.length:0}}/{{commentMaxLength}}</small></div>
    </div>
    <div class="row margin-top--small u-width--full u-justify-content--spaceBetween" *ngIf="selectedAlert && selectedAlert['_id'] == alert._id">
      <button class="button is-small" (click)="openModalCopyToClipBoard(alert)">Copy to clipboard</button>
      <button class="button is-primary is-small" [disabled]="!alert.inactiveReason || alert.inactiveReason.length < 1" [ngClass]="{'u-disabled': !alert.inactiveReason || alert.inactiveReason.length < 1 }" (click)="dismissAlert(alert)">Dismiss</button>
    </div>
  </div>
</div>
<div class="subheader" *ngIf="patient && patient.alerts && patient.alerts.handled.length > 0">Previously Handled ({{patient.alerts.handled.length}})</div>
<div class="list" *ngIf="patient && patient.alerts && patient.alerts.handled.length > 0">
  <div class="list-item formatting--none" *ngFor="let alert of patient.alerts.handled; let idx = index" (click)="selectAlert(alert)" [hidden]="alert">
    <div class="row list-item-row--tertiary">
      <h6 class="margin--none">{{alert.filterDesc}}</h6>
      <h6 class="margin--none u-right">{{alert.createdAt | date}}</h6>
    </div>
    <div class="row list-item-row--primary">
      <p class="margin-bottom--none u-color--brand--4">{{alert.desc}}</p>
    </div>
    <div class="row list-item-row--primary">
      <div class="field u-color--brand--3" *ngIf="alert.completion && alert.completion.systolic != null && alert.completion.diastolic != null">{{alert.completion.systolic}}/{{alert.completion.diastolic}}</div>
      <div class="field u-color--brand--3" *ngIf="alert.completion && alert.completion.bloodSugar != null">{{alert.completion.bloodSugar}} {{alert.completion.bloodSugarUnits}}</div>
      <div class="field u-color--brand--3" *ngIf="alert.completion && alert.completion.weight != null">Weight: {{alert.completion.weight}} {{alert.completion.weightUnits}}</div>
    </div>
    <div class="row list-item-row--tertiary">
      <p class="margin--none" *ngIf="alert.inactiveByFullName">Dismissed by {{dismissedByNameForAlert(alert)}}</p>
      <p class="field margin--none u-right">{{alert.inactiveAt | date}}</p>
    </div>
    <div class="row margin-top--small button-group u-width--full custom-alertActionPanel-buttons" *ngIf="selectedAlert && selectedAlert['_id'] == alert._id && alert.inactiveReason">
      <button type="button" class="button has-tooltip" (click)="$event.stopPropagation(); deselectAlert()">
        <span class="tooltip">Close</span><fa-icon class='fa-sm' [icon]="faMinus"></fa-icon></button>
      <button type="button" class="button u-cursor--not-allowed has-tooltip" [ngClass]="{'is-primary': alert.inactiveReason == 'phone'}"><span class="tooltip">Phoned patient</span><fa-icon class='fa-sm' [icon]="faPhone"></fa-icon></button>
      <button type="button" class="button u-cursor--not-allowed has-tooltip" [ngClass]="{'is-primary': alert.inactiveReason == 'message'}"><span class="tooltip">Sent message to patient</span><fa-icon class='fa-sm' [icon]="faSms"></fa-icon></button>
      <button type="button" class="button u-cursor--not-allowed has-tooltip" [ngClass]="{'is-primary': alert.inactiveReason == 'handoff'}"><span class="tooltip">Handed off to PA/MD</span><fa-icon class='fa-sm' [icon]="faUserMd"></fa-icon></button>
      <button type="button" class="button u-cursor--not-allowed has-tooltip" [ngClass]="{'is-primary': alert.inactiveReason == 'reviewed'}"><span class="tooltip">Reviewed</span><fa-icon class='fa-sm' [icon]="faCheckSquare"></fa-icon></button>
      <button type="button" class="button u-cursor--not-allowed has-tooltip" [ngClass]="{'is-primary': alert.inactiveReason == 'ignore'}"><span class="tooltip">Ignored</span><fa-icon class='fa-sm' [icon]="faTimesCircle"></fa-icon></button>
    </div>
    <div class="row" *ngIf="selectedAlert && selectedAlert['_id'] == alert._id && alert.inactiveReasonText" >
      <input [disabled]="true" class="u-cursor--not-allowed"  [(ngModel)]="alert.inactiveReasonText">
    </div>
    <div class="row margin-top--small u-width--full u-justify-content--spaceBetween" *ngIf="selectedAlert && selectedAlert['_id'] == alert._id">
      <button class="button is-small" (click)="openModalCopyToClipBoard(alert)">Copy to clipboard</button>
    </div>
  </div>
</div>

<ng-container *ngIf="copyToClipBoardAlert">
  <div role="modal-background" (click)="closeModalOnBackgroundClick($event.target)" class="overlay is-open">
    <div class="modal u-width--larger">
      <div class="header">
        <div class="titles">
          <div class="title">Copy to Clipboard</div>
          <div class="subtitle">Click the copy button to copy the below text to the clipboard</div>
        </div>
      </div>
      <div class="dynamicBody padding">
        <p>Name: {{patient.lastName}} {{patient.firstName}}</p>
        <p>DOB: {{patient.dob | date }}</p>
        <p *ngIf="patient.mrn">MRN: {{ patient.mrn | mask: 6 }}</p>
        <p>Alert: {{copyToClipBoardAlert.filterDesc}}</p>
        <p>Date Reported: {{copyToClipBoardAlert.createdAt | date: "medium"}}</p>
        <p>Description: {{copyToClipBoardAlert.desc}}</p>
        <p *ngIf="copyToClipBoardAlert.completion && copyToClipBoardAlert.completion.systolic != null && copyToClipBoardAlert.completion.diastolic != null">Pressure: {{copyToClipBoardAlert.completion.systolic}}/{{copyToClipBoardAlert.completion.diastolic}}</p>
        <p *ngIf="copyToClipBoardAlert.completion && copyToClipBoardAlert.completion.bloodSugar != null">Sugar: {{copyToClipBoardAlert.completion.bloodSugar}} {{copyToClipBoardAlert.completion.bloodSugarUnits}}</p>
        <p *ngIf="copyToClipBoardAlert.completion && copyToClipBoardAlert.completion.weight != null">Weight: {{copyToClipBoardAlert.completion.weight}} {{copyToClipBoardAlert.completion.weightUnits}}</p>
        <p *ngIf="!copyToClipBoardAlert.isActive && copyToClipBoardAlert.inactiveByFullName">Dismissed by {{dismissedByNameForAlert(copyToClipBoardAlert)}}</p>
        <p *ngIf="!copyToClipBoardAlert.isActive && copyToClipBoardAlert.inactiveAt">Dismissed at {{copyToClipBoardAlert.inactiveAt | date: "medium"}}</p>
        <p *ngIf="!copyToClipBoardAlert.isActive && copyToClipBoardAlert.inactiveReason">Action done: 
          <span *ngIf="copyToClipBoardAlert.inactiveReason == 'phone'">Phoned patient</span>
          <span *ngIf="copyToClipBoardAlert.inactiveReason == 'message'">Sent message to patient</span>
          <span *ngIf="copyToClipBoardAlert.inactiveReason == 'handoff'">Handed off to PA/MD</span>
          <span *ngIf="copyToClipBoardAlert.inactiveReason == 'reviewed'">Reviewed</span>
          <span *ngIf="copyToClipBoardAlert.inactiveReason == 'ignore'">Ignored</span>
        </p>
        <p class="text-break" *ngIf="copyToClipBoardAlert.inactiveReasonText && copyToClipBoardAlert.inactiveReasonText.length > 0">Comment: {{copyToClipBoardAlert.inactiveReasonText}}</p>
      </div>
      <div class="footer">
        <button class="button margin-right border--none" (click)="copyToClipBoardText()">Copy</button>
        <button class="button is-primary" (click)="copyToClipBoardAlert = null">Close</button>
      </div>
    </div>
  </div>
</ng-container>