import { Component, OnInit } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { TrackComponentConstants, ModuleConstants } from '@shared/models/module-constants';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { takeUntil } from 'rxjs/operators';
import {
  PatientService,
  ITrackApiPatient,
  ITrackApiAwvPatient,
  IWPAPIPatient,
  ITrackCohortPatient,
  ITrackApiEdUtilizationPatient,
} from '@shared/services/patient/patient.service';
@Component({
  selector: 'coach-track-clinical-widgets',
  templateUrl: './track-clinical-widgets.component.html',
  styleUrls: ['./track-clinical-widgets.component.scss']
})
export class TrackClinicalWidgetsComponent implements OnInit {
  public tier: IHierarchyTier;
  public orgId: string;
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get tierNum() {
    return this?.tier?.tier;
  }
  constructor(private _patientService: PatientService, private HierarchyTierService: HierarchyTierService) { 
    HierarchyTierService.currentTier$.pipe().subscribe(async (tier) => {
      if (this.tier?.selectedTierId !== tier?.selectedTierId) {
        this.tier = tier;
        if (this.tier?.selectedTierId) {
          this.orgId = await(this._patientService.getWpOrgIdFromTierId(this.tier.selectedTierId));
        } else {
          this.orgId = null;
        }
      }
    });
  }
  careModule = ModuleConstants.CareModule;
  trackHccComponent = TrackComponentConstants.ChronicConditions;

  ngOnInit(): void {
  }
}
