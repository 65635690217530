<div class="patientGeneralInfoCard">
  <div class="patientInfo patientHeader">
    <div class="patientHeaderComponent">
      <div class="patientName u-font-weight--normal u-font-size--small" [ngStyle]="{'cursor': showPatientFaceSheet ? 'pointer' : ''}"
        (click)="navigateToPatientFacesheet()">
        {{ selectedPatientInfo?.patientName }}
      </div>
      <div class="patientData" style="color: gray">
        DOB:
        <strong>{{ formatDate(selectedPatientInfo?.patientDob) }} </strong> | Sex:
        <strong>{{ getPatientSex() }}</strong><br />
        MBI: <strong>{{ getMbi() }}</strong><br />
        Primary Care:
        <strong *ngIf="selectedPatientInfo?.primaryProviderName">{{ selectedPatientInfo?.primaryProviderName }}
          {{ selectedPatientInfo?.primaryProviderCredentials }}</strong>
      </div>
    </div>
    <div class="patientHeaderComponent" *hasFeatureFlag="importFeatureFlag">
      <button *ngIf="claimStatus== 1001" (click)="exportClaimFiles()" class="u-background-color--accentColor">
        <coach-icon iconName="download" class="icon--white"></coach-icon>
      </button>
    </div>
  </div>
</div>

<coach-patient-header-help [fillDate]="formatDate(selectedPatientInfo?.firstFilled)"
  [showInstructions]="showInstructions"></coach-patient-header-help>

<div class="prescriptionInfoCard u-background-color--accentColor">
  <div class="rxImage">
    <img src="assets/img/care/rx.svg" alt="Rx Image" />
  </div>
  <div class="prescriptionData">
    <div class="prescriptionName">
      {{ selectedPatientInfo?.prescriptionName }}
    </div>
    By {{ selectedPatientInfo?.prescriberName }} -
    {{ selectedPatientInfo?.prescriberSpecialty }}<br />
    {{ formatPhone(selectedPatientInfo?.prescriberPhone) }}<br />
    First filled {{ formatDate(selectedPatientInfo?.firstFilled) }}
  </div>
</div>