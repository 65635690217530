import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { PatientService } from '@shared/services/patient/patient.service'
import { Patient } from '@shared/models/patient';
import _ from 'lodash';
import { wpapi, wpdb } from "@hcd-caravanhealth/pkg-wptypes";
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';


@Component({
  selector: 'coach-patient-edit-form',
  templateUrl: './patient-edit-form.component.html',
  styleUrls: ['./patient-edit-form.component.scss']
})

export class PatientEditFormComponent implements OnInit {
  @Input() public patient: Patient;
  @Output() public closeForm: EventEmitter<any> = new EventEmitter();
  @Input() tier: IHierarchyTier;

  patientClone: Patient;
  patientEditStatus: 'failed' | 'ready' | 'success' | 'processing' = 'ready'
  isModuleSelectorIsOpen: boolean;

  public get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get tierNum() { return this?.tier?.tier };
  private emitter: any;
  constructor(private PatientService: PatientService) { }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes?.patient?.currentValue){
      this.patientClone = _.cloneDeep(changes.patient.currentValue)
    }
  }
  async ngOnInit() {

  }
  close(action_target: string): void {
    this.closeForm.emit(action_target);
  }

  async updatePatient() {
    this.patientEditStatus = 'processing';
    this.patientClone.phoneNumber = Patient.formatPhoneNumber(this.patientClone.phoneNumber);
    if (this.patientClone.carePatient) {
      const smsIdentifierIndex = this.patientClone.carePatient.identifiers.findIndex(identifier => identifier.issuer === 'https://identity.caravanhealth.com/smscareplanphonenumber');
    
      try {
        if (smsIdentifierIndex !== -1) {
          const wpUpdate = await this.PatientService.updateCarePatient(this.patientClone.carePatient._id, {
            email: this.patientClone.email,
            nickName: this.patientClone.nickName?.trim() || "",
            phoneNumber: this.patientClone.phoneNumber.replace(/\D+/g, ''),
            identifiers: [{
              issuer: this.patientClone.carePatient.identifiers[smsIdentifierIndex].issuer,
              type: this.patientClone.carePatient.identifiers[smsIdentifierIndex].type,
              subject: '+1' + this.patientClone.phoneNumber.replace(/\D+/g, ''),
              createdAt: this.patientClone.carePatient.identifiers[smsIdentifierIndex].createdAt,
              provider: this.patientClone.carePatient.identifiers[smsIdentifierIndex].provider,
              canBeUpdated: true
            }]
          }); 
        }
      else{
        const wpUpdate = await this.PatientService.updateCarePatient(this.patientClone.carePatient._id, {
          email: this.patientClone.email,
          nickName: this.patientClone.nickName?.trim() || "",
          phoneNumber: this.patientClone.phoneNumber.replace(/\D+/g, '')
        }); 
      }   
      } catch (error) {
        this.patientEditStatus = 'failed';
        console.log(error);
        return;
      }
    }
  
    if (this.patientClone.trackPatient){
      try{
        let trackUpdate = await this.PatientService.updateTrackPatient(this.tierId, this.tierNum, {
          email:this.patientClone.email,
          preferredName: this.patientClone.nickName?.trim() ||this.patientClone.preferredName || "",
          phone:this.patientClone.phoneNumber.replace(/\D+/g, ''),
          tierID: this.tierId,
          tierNum: this.tierNum,
          carePatientId:this.patientClone.carePatient._id,
          mbi:this.patientClone.mbi,
          firstName:this.patientClone.firstName,
          lastName:this.patientClone.lastName,
          middleName:this.patientClone.middleName,
          dateOfBirth: this.patientClone.dob,
          gender:this.patientClone.gender[0].toUpperCase(),
          chPatId: Number(this.patientClone.chPatId),
          ChPatID: Number(this.patientClone.chPatId),
          supplementalId:this.patientClone.trackPatient.supplementalID
        });
      } catch(error) {
        this.patientEditStatus = 'failed';
        console.log(error);
        return;
      }
    }
  
    this.patient = Object.assign(this.patient, this.patientClone);
    this.patientEditStatus = 'success';
  }

  //  VALIDATORS
  isInputValid(target: string): boolean {
    switch(target){
      case 'email':
        return this.PatientService.isEmailFormatValid(this.patientClone[target])
      case 'nickName':
        return this.patientClone[target].length > 0
      case 'phoneNumber':
        return this.PatientService.isPhoneNumberFormatValid(this.patientClone[target])
    }
  }
  isFirstDigitValid(target: string): boolean {
    let regexp = /^(\+\d{1,2}\s)?\(?[2-9].*$/;
      return regexp.test(this.patientClone[target]);

  }
}

