<div class="u-height--fit-content form-background">
  <div class="u-font-size--small">

    <div class="row padding-bottom--small u-flex-direction--column">
      <input [(ngModel)]="selectedOutcome" type="radio" id="patWasReminded" name="was_reminded_outcome" [value]="WasRemindedOutcome.PATIENT_WAS_REMINDED" (change)="fieldChanged()" [disabled]="this.isReadOnly">
      <label for="patWasReminded">Patient was reminded of appointment</label>

      <div *ngIf="selectedOutcome == WasRemindedOutcome.PATIENT_WAS_REMINDED" class="margin-left--large padding-left--smaller">
        <input [(ngModel)]="healthRisk" id="checkHealthRisk" type="checkbox" name="checkHealthRisk" (change)="fieldChanged()" [disabled]="this.isReadOnly" #checkHealthRisk>
        <label for="checkHealthRisk" class="u-text-align--right">Health Risk Assessment started</label>

        <input [(ngModel)]="needsTransportation" id="checkTransportation" type="checkbox" name="checkTransportation" (change)="fieldChanged()" [disabled]="this.isReadOnly" #checkTransportation>
        <label for="checkTransportation" class="u-text-align--right">Needs transportation</label>

        <input [(ngModel)]="needsInterpreter" id="checkInterpreter" type="checkbox" name="checkInterpreter" (change)="fieldChanged()" [disabled]="this.isReadOnly" #checkInterpreter>
        <label for="checkInterpreter" class="u-text-align--right">Needs interpreter</label>

        <div class="u-bold padding-top--small">Patient reminded to bring:</div>

        <input [(ngModel)]="medications" id="checkMedications" type="checkbox" name="checkMedications" (change)="fieldChanged()" [disabled]="this.isReadOnly" #checkMedications>
        <label for="checkMedications" class="u-text-align--right">Medications</label>

        <input [(ngModel)]="labsDocumentation"id="checkLabsDocs" type="checkbox" name="checkLabsDocs" (change)="fieldChanged()" [disabled]="this.isReadOnly" #checkLabsDocs>
        <label for="checkLabsDocs">Labs/Documentation (as applicable)</label>
      </div>

      <input [(ngModel)]="selectedOutcome" type="radio" id="patDidNotAnswer" name="was_reminded_outcome" [value]="WasRemindedOutcome.PATIENT_DID_NOT_ANSWER" (change)="fieldChanged()" [disabled]="this.isReadOnly">
      <label for="patDidNotAnswer">Patient did not answer</label><br>
    </div>

    <div class="row padding-bottom--smallest">
      <textarea class="resize--none" [(ngModel)]="comment" placeholder="Add a comment" id="scheduledComment" rows="6"
                [maxlength]="commentCharacterLimit" (input)="fieldChanged()" [disabled]="this.isReadOnly" #commentbox></textarea>
    </div>

    <div class="row padding-bottom--small">
      <div class="span--6 u-font-weight--light" [ngClass]="{'character-limit-reached': commentbox.value.length == commentCharacterLimit }">{{commentbox.value.length}}/{{commentCharacterLimit}}</div>
      <div class="span--6 u-text-align--right u-cursor--pointer u-font-weight--light padding-right--small u-width--full" id='testCopyText' (click)="copyComment(commentbox)">Copy text</div>
    </div>
    <coach-patient-awv-inline-error></coach-patient-awv-inline-error>
    <div class="row padding-top--small">
      <coach-icon class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" iconName="copy" iconColor="primary" id='testCopyBtn' [copy]="stringifyForm()"></coach-icon>
      <button class="span--9 button is-unstyled u-flex--right is-small margin-bottom--small margin-right--small cancel-btn u-color--brand--1" id="cancelBtn" (click)="clearFields()" [disabled]="allFieldsEmpty">Cancel</button>
      <button class="span--3 button is-small is-primary margin-bottom--small" id="saveBtn" (click)="save()" [disabled]="!requiredFieldsSatisfied">Save</button>
    </div>
  </div>
</div>
