<coach-care-patients-filters
  (presetSelected)="filterChange($event)"
  (storeFilterModel)="storeFilterModel($event)"
  [loadingStatus]="loadingStatus"
  [resetFilter]="resetFilter"
  [selectedPresetSettings]="selectedPreset?.filterSettings"
  [clinicalProgram]="selectedClinicalProgramName"
  (updatePresetFilterSettingsTrigger)="updatePresetFilterSettings($event)"
>
</coach-care-patients-filters>

<div class="page-content-main">
  <div class="header row--verticalCenter padding-topBottom--none" >
    <div class="titles">
      <div class="title row--verticalCenter">
        <div class="u-display--flex u-align-items--center">
          <div class="margin-topBottom u-display--flex u-flex-direction--column">
            <div class="u-display--flex u-flex-direction--row u-align-items--center">
              <span class="margin-right--smaller">{{ selectedPreset?.label }}</span>
              <div class="has-tooltip tooltip--right" *ngIf="selectedPreset?.listDefinition ">
                <coach-icon class="icon icon--primary is-medium" iconName="info">
                </coach-icon>
                <span class="tooltip is-right u-width--content">{{selectedPreset.listDefinition}}</span>
              </div>
            </div>
            <small *ngIf="filterAppliedBool">
              <i class="u-color--interaction u-font-weight--light">
                <span>Filter applied - </span> <button class="border--none background-inherit" (click)="clear()">Clear</button>
              </i>
            </small>
          </div>
          <button *ngIf="loadingStatus" (click)="cancelPatientsLoadingProcess()"
            class="button margin-left--small is-smaller margin-topBottom--none padding-topBottom--smaller">
            <span>
              <div class="loader margin-right"></div>
            </span>
            Cancel
          </button>
        </div>

        <div class="u-display--flex u-align-items--center">
          <div *ngIf="showCareManagementPreset" class="clinical-program-wrapper">
            <label class="label padding-bottom--none" for="clinicalProgram">Clinical Program</label>
            <select id="clinicalProgram" name="clinicalProgram" class="field u-color--interaction u-width--medium"
              [formControl]="clinicalProgramFormControl" (change)="onClinicalProgramChange($event)">
              <option value="">All Programs</option>
              <option *ngFor="let cohort of filteredDiseaseCohortDefinitions" [ngValue]="cohort.cohortID">
                {{cohort.cohortName}}
              </option>
            </select>
          </div>

          <ng-container *hasFeatureFlag="'AgGridCustomFiltering'">
            <coach-icon *ngIf="!loadingStatus" (click)="columnsSettingsModal = true" iconName="settings"
              class="icon--selectable"></coach-icon>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-container *hasComponent="AWVOpportunitiesId">
    <div class="padding-topBottom--none u-background-color--white awv-padding-left" *ngIf="showAWVButtons">
      <div class="titles">
        <div class="row button-holder">
          <button class="button button-awv button-awv-first" [ngClass]="{'selected-button': !AWVPatientsStatus }" (click)="setAWVPatientsStatusURLParams('All AWV Patients', [AwvWorkflowStatusForPatientListDisplay.AllPatients])"> 
            <div class="container">
              <coach-icon iconName="users" class="margin-right--smaller"></coach-icon>
              <div class="text-container">
                <span class="first-row u-font-size--small">All AWV Patients</span>
                <span class="second-row u-color--highlight u-font-weight--light u-font-size--smallest"></span>
              </div>
            </div>
          </button>

          <div class="vertical-line"></div>


          <button class="button button-awv" [ngClass]="{'selected-button': AWVPatientsStatus === AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule || AWVPatientsStatus === AwvWorkflowStatusForPatientListDisplay.CallBackLater}" (click)="setAWVPatientsStatusURLParams('Patients due for AWVs', [AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule, AwvWorkflowStatusForPatientListDisplay.CallBackLater])" >
            <div class="container">
              <coach-icon iconName="calendar" class="margin-right--smaller"></coach-icon>
              <div class="text-container">
                <span class="first-row u-font-size--small">Ready to Schedule</span>
                <span class="second-row u-color--highlight u-font-weight--light u-font-size--smallest">Patients due for AWVs</span>
              </div>
            </div>
          </button>

          <button class="button button-awv" [ngClass]="{'selected-button': AWVPatientsStatus === AwvWorkflowStatusForPatientListDisplay.ReadyToRemind || AWVPatientsStatus === AwvWorkflowStatusForPatientListDisplay.Scheduled}" (click)="setAWVPatientsStatusURLParams('Ready to Remind', [AwvWorkflowStatusForPatientListDisplay.ReadyToRemind, AwvWorkflowStatusForPatientListDisplay.Scheduled])">
            <div class="container">
              <coach-icon iconName="month" class="margin-right--smaller"></coach-icon>
              <div class="text-container">
                <span class="first-row u-font-size--small">Ready to Remind</span>
                <span class="second-row u-color--highlight u-font-weight--light u-font-size--smallest">Patients with upcoming AWVs</span>
              </div>
            </div>
          </button>
          <button class="button button-awv" [ngClass]="{'selected-button': AWVPatientsStatus === AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport}" (click)="setAWVPatientsStatusURLParams('Ready to Self-Report', [AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport])">

            <div class="container">
              <coach-icon iconName="calendar-complete" class="margin-right--smaller"></coach-icon>
              <div class="text-container">
                <span class="first-row u-font-size--small">Ready to Self-Report</span>
                <span class="second-row u-color--highlight u-font-weight--light u-font-size--smallest">Patients with AWVs to be documented</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <coach-care-patient-sidebar *ngIf="selectedPatientId" [orgId]="orgId" [patientId]="selectedPatientId" [tier]="tier"
    [forcePatientSideBar]="forcePatientSideBar" [patientUrlQueryParams]="selectedPreset?.patientUrlQueryParams"
    (updatePatientByIdInList)="updatePatientByIdInList($event)" [selectedPatient]="selectedPatient"></coach-care-patient-sidebar>
  <div *ngIf="selectedPresetIsChronicConditions" class="dynamicBody" style="height: 100%">
    <coach-disease-cohort-grid [rowData]="patients" [loadingStatus]="loadingStatus" [filterName]="selectedPreset?.name" [columnDefs]="externalColumnDefs" [localeText]="AG_GRID_LOCALE_CARE"
      (selectedPatients)="externalPatientSelected($event)" [hiddenColumnsByDefault]="selectedPreset?.hiddenColumnsByDefault" (secondaryColumnApiEmitter)="updateSecondaryColumnApi($event)"
      (agFilterChangedEmitter)="checkIfFilterIsApplied($event)" (setSelectedPatientId)="setSelectedPatientId($event)">
    </coach-disease-cohort-grid>
  </div>
  <div *ngIf="selectedPreset?.name === CarePatientItem.EdUtilization" class="dynamicBody" style="height: 100%">
    <coach-ed-utilization-grid [rowData]="patients" [loadingStatus]="loadingStatus" [columnDefs]="externalColumnDefs" (selectedPatients)="externalPatientSelected($event)" [localeText]="AG_GRID_LOCALE_CARE"
      (secondaryColumnApiEmitter)="updateSecondaryColumnApi($event)" [hiddenColumnsByDefault]="selectedPreset?.hiddenColumnsByDefault" (agFilterChangedEmitter)="checkIfFilterIsApplied($event)"
      (setSelectedPatientId)="setSelectedPatientId($event)" ></coach-ed-utilization-grid>
  </div>
  <div *ngIf="selectedPreset?.name === CarePatientItem.FacesheetSchedule" class="dynamicBody u-height--full">
    <coach-facesheet-schedule [patientDataByChPatId]="patientDataByChPatId"
      (selectedPatients)="externalPatientSelected($event)" [fcButtonsStatus]="fcButtonsStatus" [localeText]="AG_GRID_LOCALE_CARE"
      (secondaryColumnApiEmitter)="updateSecondaryColumnApi($event)" (agFilterChangedEmitter)="checkIfFilterIsApplied($event)"
      (setSelectedPatientId)="setSelectedPatientId($event)"></coach-facesheet-schedule>
  </div>
  <div
    [hidden]="
    selectedPresetIsChronicConditions ||
      selectedPreset?.name == CarePatientItem.EdUtilization ||
      selectedPreset?.name == CarePatientItem.FacesheetSchedule
    "
    class="dynamicBody"
    style="height: 100%"
  >
    <ag-grid-angular
      coachAgGridResize
      class="ag-theme-material"
      row-animation
      style="width: 100%; height: 100%"
      [columnDefs]="[]"
      [defaultColDef]="defaultColDef"
      [localeText]="AG_GRID_LOCALE_CARE"      
      [overlayNoRowsTemplate]="loadingMsg"      
      [pagination]="true"
      [paginationAutoPageSize]="true"
      [rowData]="patients"
      [rowMultiSelectWithClick]="true"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      [sortingOrder]="['asc','desc',null]"
      (columnMoved)="onColumnUpdated()"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      (filterChanged)="agFilterChanged($event)"
      (rowDataChanged)="onRowDataChanged($event)"
      (rowClicked)="onRowClicked($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (sortChanged)="onColumnUpdated()"
      (columnEverythingChanged)="columnEverythingChanged($event)"
    >
    </ag-grid-angular>
  </div>
  <div class="footer u-justify-content--spaceBetween">


    <div class="u-display--flex">
      <!-- NEW facesheet -->
      <ng-container *hasFeatureFlag="facesheetUIUpdates">
          <button class="button is-primary is-small row--verticalCenter margin-left--small" [disabled]="(getSelectedPatients()).length < 1" *ngIf="!loadingStatus" (click)="facesheetVersionModal = true; facesheetVersionType = 'export'">
            <coach-loading-indicator class="padding-top--smaller margin-right--smaller" *ngIf="fcButtonsStatus.fcPdfLoading"></coach-loading-indicator>
            <div>Export</div>
          </button>

          <button class="button is-primary is-small row--verticalCenter margin-left--small" [disabled]="(getSelectedPatients()).length < 1" *ngIf="!loadingStatus" (click)="facesheetVersionModal = true; facesheetVersionType = 'print'">
            <coach-loading-indicator class="padding-top--smaller margin-right--smaller" *ngIf="fcButtonsStatus.fcPrintLoading"></coach-loading-indicator>
            <div>Print</div>
          </button>
      </ng-container>
    </div>

    <div class="u-display--flex u-justify-content--right">
      <button class="button is-primary is-small row--verticalCenter margin-left--small"
      *ngIf="!loadingStatus && selectedPreset?.name !== CarePatientItem.FacesheetSchedule"
      [disabled]="(getSelectedPatients()).length < 1"
      (click)="onExportCSVClick()">
        <div>Export CSV</div>
      </button>

      <!-- OLD facesheet -->
      <ng-container *doesNotHaveFeatureFlag="facesheetUIUpdates">
        <button class="button is-primary is-small row--verticalCenter margin-left--small" *ngIf="!loadingStatus && selectedPreset?.name == CarePatientItem.FacesheetSchedule" (click)="onFacesheetExportClick()">
          <coach-icon iconName="upload" iconColor="currentColor" class="margin-right--smaller" *ngIf="!fcButtonsStatus.fcPdfLoading"></coach-icon>
          <coach-loading-indicator class="padding-top--smaller margin-right--smaller" *ngIf="fcButtonsStatus.fcPdfLoading"></coach-loading-indicator>
          <div>Export</div>
        </button>

        <button class="button is-primary is-small row--verticalCenter margin-left--small" *ngIf="!loadingStatus && selectedPreset?.name == CarePatientItem.FacesheetSchedule" (click)="onFacesheetPrintClick()">
        <coach-loading-indicator class="padding-top--smaller margin-right--smaller" *ngIf="fcButtonsStatus.fcPrintLoading"></coach-loading-indicator>
          <div>Print</div>
        </button>
      </ng-container>

      <button class="button is-small row--verticalCenter margin-left--small is-primary" (click)="openBulkInvite()" [hidden]="loadingStatus || !orgId"
        *hasComponent="PatientEngagement.PatientApp" [disabled]="(disableBulkInviteButton | async)">
        <div>Invite patients</div>
      </button>
    </div>
  </div>
</div>

<!-- INVITE PATIENT MODAL -->
<ng-container *ngIf="bulkInviteModal">
  <div
    class="overlay is-open"
    role="modal-background"
    (click)="closeModalOnBackgroundClick($event.target, 'bulkInviteModal')"
  >
    <div class="modal patientInviteModal u-width--larger">
      <coach-patient-invite-form
        (closeForm)="closeBulkInviteModal($event)"
        [patients]="inviteList"
        [tier]="tier"
      ></coach-patient-invite-form>
    </div>
  </div>
</ng-container>

<!-- COLUMN SETTINGS MODAL -->
<ng-container *ngIf="columnsSettingsModal">
  <div class="overlay is-open" role="modal-background"
    (click)="closeModalOnBackgroundClick($event.target, 'columnsSettingsModal')">
    <div class="modal patientInviteModal u-width--larger">
      <coach-columns-settings
      [columnDefs]="columnDefs"
      (closeForm)="columnsSettingsModal = false"
      [columnApi]="selectedPresetIsChronicConditions ||
      selectedPreset?.name == CarePatientItem.EdUtilization ||
      selectedPreset?.name == CarePatientItem.FacesheetSchedule ? secondaryColumnApi : columnApi"
      [presetName]="selectedPreset?.name"
      [presetSettings]="selectedPreset?.presetKey"
      [versionKey]="selectedPreset?.versionKey"
      ></coach-columns-settings>
    </div>
  </div>
</ng-container>


<!-- FACESHEET VERSION SELECTION -->
<ng-container *ngIf="facesheetVersionModal">
  <div class="overlay is-open" role="modal-background" (click)="closeModalOnBackgroundClick($event.target, 'facesheetVersionModal')">
    <div class="modal">
      <div class="header">
        <div class="title u-text-align--center">Select Facesheet Version</div>
      </div>
      <div class="u-display--flex u-flex-direction--column padding">
        <div><input [value]="false" [(ngModel)]="facesheetVersionExpanded" [checked]="!facesheetVersionExpanded" id="CondensedVersionRadioButton" type="radio"><label for="CondensedVersionRadioButton">Condensed Version</label></div>
        <div><input [value]="true" [(ngModel)]="facesheetVersionExpanded" [checked]="facesheetVersionExpanded" id="ExpandedVersionRadioButton" type="radio"><label for="ExpandedVersionRadioButton">Expanded Version</label></div>
      </div>
      <div class="footer">
        <button class="button is-primary is-small row--verticalCenter margin-left--small" *ngIf="facesheetVersionType == 'export'" (click)="facesheetExport('export')">
          <coach-loading-indicator class="padding-top--smaller margin-right--smaller" *ngIf="fcButtonsStatus.fcPdfLoading"></coach-loading-indicator>
          <div>Export</div>
        </button>

        <button class="button is-primary is-small  row--verticalCenter margin-left--small" *ngIf="facesheetVersionType == 'print'" (click)="facesheetExport('print')">
          <coach-loading-indicator class="padding-top--smaller margin-right--smaller" *ngIf="fcButtonsStatus.fcPrintLoading"></coach-loading-indicator>
          <div>Print</div>
        </button>
      </div>
    </div>
  </div>
</ng-container>
