<div class="dynamicBody">
    <div class="widgets">
        <div
          class="widget" *hasComponent="trackHccComponent">
          <div class="dynamicBody padding--large">
            <h3 class="margin--none">Chronic Conditions Dashboard</h3>
            <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Track_Clinic_HCC_1700x1100_20221114.svg" />
            <p class="u-italic u-opacity--light margin-top--smaller">A dashboard and reporting tool that provides powerful insights around chronic conditions and diagnoses capture opportunities.</p>
            <div class="row u-justify-content--center">
              <button class="button" [routerLink]="['dashboard']" >Open</button>
            </div>
          </div>
        </div>
        <div
          class="widget" *hasModule="careModule">
          <div class="dynamicBody padding--large">
            <h3 class="margin--none">Time Tracking</h3>
            <img class="widget-illustration"
                src="assets/img/illustrations/SH_PEP_Illustrations_Track_Clinic_Time_1700x1100_20221114.svg" />
            <p class="u-italic u-opacity--light margin-top--smaller">Monthly reports of cumulative Care Management hours to support tracking and billing efforts.</p>
            <div class="row u-justify-content--center">
              <button class="button" [routerLink]="['trackTimeTracking']">Open</button>
            </div>
          </div>
        </div>
    </div>
  </div>