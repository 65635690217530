import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
  Input,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  OnChanges
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { SpacesToDashesPipe } from '@shared/pipes/spaces-to-dashes.pipe';
import { PatientAlertService } from '@shared/services/patient-alert/patient-alert.service';
import { FilterToggleService } from '@shared/services/filter-toggle/filter-toggle.service';
import { PatientService } from '@shared/services/patient/patient.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';

import {
  IPatientFilterPresetGroup,
  IPatientFilterPreset,
  IPatientFilter,
} from '@shared/models/patient-filters';
import {
    CarePatientItem,
  PresetCategory,
} from '@shared/models/module-constants';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { ActivatedRouteService } from '@shared/services/activated-route/activated-route.service';

import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { Access } from '@shared/services/graphql/access.service';

import { filterSettings, AgGridTableFilterSetting } from './filter-settings';
import {
  AgGridLocalSettingsService,
  FilterSettings
} from '@shared/services/ag-grid/ag-grid.service';
import _ from 'lodash';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { CareFeatureConstants } from '@shared/models/module-constants';

import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { PatientEngagement } from '@shared/enums';
import { ClinicalProgram } from '@shared/models/module-constants';
import { CarePatientsPresetGroupService } from './care-patients-preset-group.service';
import { PresetGroupService } from '@care/preset-group/preset-group.service';
import { LinkClickedEventsService } from '@shared/services/mixpanel/events/link-clicked-events.service';

export type FilterOpenState = 'is-open' | 'is-closed';

@Component({
  selector: 'coach-care-patients-filters',
  templateUrl: './care-patients-filters.component.html',
  providers: [SpacesToDashesPipe],
})
export class CarePatientsFiltersComponent
  extends KillSubscriptions
  implements OnInit, OnChanges
{
  @Input() public loadingStatus: boolean;
  @Input() public resetFilter: boolean;
  @Input() public selectedPresetSettings: FilterSettings;
  @Input() clinicalProgram: ClinicalProgram = ClinicalProgram.AllProgram; // Pass clinical program and set All Program as default
  // @Output() public presetSelected: EventEmitter<IPatientFilterPreset> = new EventEmitter();
  // REVIEW - can we remove these events and/or move them to a shared filter settings service or replace them with observables?
  @Output() public storeFilterModel: EventEmitter<IPatientFilterPreset> =
    new EventEmitter();
  @Output()
  public updatePresetFilterSettingsTrigger: EventEmitter<FilterSettings> =
    new EventEmitter();
  AgGridCustomFilteringFlag: string =
    CareFeatureConstants.AgGridCustomFilteringFlag;
  PatientListsUpdateFlag: string = CareFeatureConstants.PatientListsUpdateFlag;
  ClinicalProgramsListFlag: string =
    CareFeatureConstants.ClinicalProgramsListFlag;
  HCCVIPCohortList: string = CareFeatureConstants.HCCVIPCohortList;
  PACNavigatorProgram: string = CareFeatureConstants.PACNavigatorProgram;
  IHECohort: string = CareFeatureConstants.IHECohort;
  SMSOutreachProgram: string = CareFeatureConstants.SMSOutreachProgram;
  InHomeAWVScheduleList: string = CareFeatureConstants.InHomeAWVScheduleList;
  HCCListRevamp: string = CareFeatureConstants.HCCListRevamp;
  isHCCVIPCohortListEnabled: boolean = false;
  isPACNavigatorProgramEnabled: boolean = false;
  isPatientListsUpdateFlagEnabled: boolean = false;
  isHCCListRevampEnabled: boolean = false;
  public isIHECohortEnabled : boolean = false;
  public isSMSOutreachProgramEnabled : boolean = false;
  public isInHomeAWVScheduleList : boolean = false;
  public isCombinedSMSCohortsEnabled = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CombineSMSCohorts);

  public isPatientFiltersOpen: boolean = true;
  public isDrillInOpen: boolean = false;

  public facesheetCategory = 'FACESHEET';

  public selectedPreset: IPatientFilterPreset;
  public allFilters: IPatientFilter[];
  public selectedCategory: string;
  public selectedFilters: IPatientFilter[];
  private tier: IHierarchyTier;
  private userAccess: Access;
  carePatientsFiltersSettingsRef: ComponentRef<any>;
  orgId: string;
  smsAlerts: number;

  @HostBinding('class')
  get pclass() {
    const sharedClasses =
      'queue border-right u-position--relative coach-care-patients-filters';
    return this.isPatientFiltersOpen
      ? `${sharedClasses} is-open`
      : `${sharedClasses} is-closed`;
  }


  get allPresetGroups(){
    return this.presetGroupService.presetGroups;
  }

  dynamicFilters: any = {};
  filterSettings: any = filterSettings;
  isNewFilterDrawerOpen: boolean = false;
  newPreset: IPatientFilterPreset;
  activeTab: 'standard' | 'custom' = 'standard';
  previousPreset: CarePatientItem | string = null;
  presetNameEditorToggle: boolean = false;
  activeOrgAlerts: Array<
    wpapi.model.Alert & { filterDesc?: string; userId?: string }
  > = [];
  @ViewChild('customFilters', { read: ViewContainerRef })
  presetFiltersComponent: ViewContainerRef;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _routeService: ActivatedRouteService,
    private _patientAlertService: PatientAlertService,
    private PatientService: PatientService,
    private userAccessService: UserAccessService,
    private filterToggleService: FilterToggleService,
    private hierarchyTierService: HierarchyTierService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private featureFlagService: FeatureFlagService,
    private agGridLocalSettingsService: AgGridLocalSettingsService,
    private authService: AuthService,
    private carePatientsPresetGroupService: CarePatientsPresetGroupService,
    private presetGroupService: PresetGroupService,
    private linkClickedEventsService: LinkClickedEventsService,
  ) {
    super();
    this.featureFlagService.featureFlags$.subscribe(async () => {
      this.isPatientListsUpdateFlagEnabled =
        this.featureFlagService.hasFeatureFlag(this.PatientListsUpdateFlag);
      this.isHCCVIPCohortListEnabled = this.featureFlagService.hasFeatureFlag(
        this.HCCVIPCohortList
      );
      this.isPACNavigatorProgramEnabled =
        this.featureFlagService.hasFeatureFlag(this.PACNavigatorProgram);
      this.isHCCListRevampEnabled = this.featureFlagService.hasFeatureFlag(
        this.HCCListRevamp
      );
      this.isIHECohortEnabled = this.featureFlagService.hasFeatureFlag(
        this.IHECohort
      );
      this.isSMSOutreachProgramEnabled = this.featureFlagService.hasFeatureFlag(
        this.SMSOutreachProgram
      );
      this.isInHomeAWVScheduleList = this.featureFlagService.hasFeatureFlag(
        this.InHomeAWVScheduleList
      );
      await this.refreshPresetsList();
    });

    this.userAccessService.currentAccess$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (access) => {
        if (access == null) {
          return;
        }
        this.userAccess = access;
        await this.refreshPresetsList();
      });

    this.hierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (tier) => {
        if (this.tier?.selectedTierId !== tier?.selectedTierId) {
          this.tier = tier;
          if (this.tier?.selectedTierId) {
            this.orgId = await this.PatientService.getWpOrgIdFromTierId(
              this.tier.selectedTierId
            );
          } else {
            this.orgId = null;
          }
          await this.refreshPresetsList();
          const filterPreset = this._route.snapshot.queryParams['filterPreset'];
          const filterTitle = this._route.snapshot.queryParams['filterTitle'];

          if (filterPreset) {
            if (!this.selectPresetWithString(filterPreset, filterTitle)) {
              this.selectFirstPreset();
            }
          } else if (!this.selectedPreset) {
            this.selectFirstPreset();
          }
        }
      });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.tier) {
      await this.refreshPresetsList();
      const filterPresetURLName =
        this._route.snapshot.queryParams['filterPreset']?.toLowerCase();
      filterPresetURLName
        ? this.selectPresetWithString(filterPresetURLName)
        : this.selectFirstPreset();
    }
    if (this.resetFilter) {      
      this.selectPreset(null);
    }
  }

  async ngOnInit() {
    this._routeService.currentRoute$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (event) => {
        const filterPreset =
          this._route.snapshot.queryParams['filterPreset']?.toLowerCase();
        const filterTitle = this._route.snapshot.queryParams['filterTitle'];

        if (filterPreset) {
          this.selectPresetWithString(filterPreset, filterTitle);
        } else if (!this.selectedPreset) {
          this.selectFirstPreset();
        }
      });

  }

  isActivePreset(preset: IPatientFilterPreset) {
    return (
      this.selectedPreset &&
      (preset.name == this.selectedPreset.name ||
        (preset.label === 'Care Management' &&
          this.selectedPreset.label === preset.label))
    );
  }

  async refreshPresetsList() {

    if (
      !this.userAccess ||
      !this.featureFlagService.featureFlags$?.value?.length ||
      !this.tier
    ) {
      return;
    }
    // REVIEW - would suggest moving all preset group initialization to a service which
    // may mean all of these following lines.
    await this.getAlertsAndMessages();
    await this.retrieveAllCustomPresets('Custom list');
    if (this.selectedPreset) {      
      this.selectPreset(this.selectedPreset);
    } else {
      const filterPreset =
        this._route.snapshot.queryParams['filterPreset']?.toLowerCase();
      const filterTitle = this._route.snapshot.queryParams['filterTitle'];
      if (filterPreset) {
        this.selectPresetWithString(filterPreset, filterTitle);
      } else if (!this.selectedPreset) {
        this.selectFirstPreset();
      }
    }
  }

  /** Select a preset based on stuff in the query string
   * @param filterPresetQueryParam - the filterPreset query param - this is matched to "name" case-insensitive
   * @param filterTitleQueryParam - the filterTitle query param - this is matched to "urlLabel"
   *  case-insensitive after replacing hyphens with spaces
   */
  public selectPresetWithString(
    filterPresetQueryParam: string,
    filterTitleQueryParam?: string
  ): boolean {
    if (this.selectedPreset?.name == filterPresetQueryParam) {
      return true;
    }
    const preset =
      this.presetGroupService.getPresetByFilterQueryParams(
        filterPresetQueryParam,
        filterTitleQueryParam
      );
    if (preset) {      
      this.isDrillInOpen = false;
      this.selectPreset(preset);
      return true;
    }
    return false;
  }

  public toggleDrillInOpen(preset: IPatientFilterPreset = null): void {
    if (this.loadingStatus) {
      return;
    }
    if (preset) {
      if(preset.name !== CarePatientItem.CareManagementAllProgram)  {
        preset.newName = preset.name;
      }
      if(preset.category === PresetCategory.CustomList){
        preset.presetKey = preset.name;
      }
    }
    this.isDrillInOpen = !this.isDrillInOpen;
    if (!this.isDrillInOpen) {
      this.presetNameEditorToggle = false;
    }
    if (this.isNewFilterDrawerOpen && this.isPatientListsUpdateFlagEnabled) {
      // close new filter form
      this.isNewFilterDrawerOpen = false;
      const updatedPreset = this.presetGroupService.getPresetByName(
        this.previousPreset || CarePatientItem.AllPatients
      );
      
      this.selectPreset(updatedPreset); // switch back either to previous filter/preset or to "All Patients"
    }
    this.presetFiltersComponent.clear();
    if (this.isDrillInOpen && preset?.filterComponent != null) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          preset.filterComponent
        );
      const ref = this.presetFiltersComponent.createComponent(componentFactory);
    }
  }

  public selectFirstPreset(): void {
    if (!this.selectedPreset && typeof this.selectedPreset == 'object') {
      return;
    }
    let allPresetGroups = this.allPresetGroups.filter((pg) => !pg.hideGroup);
    this.selectedPreset = null;
    allPresetGroups.forEach((pg) => {
      pg.presets.forEach((p) => {
        if (p.selectedByDefault) {
          this.selectedPreset = p;
        }
      });
    });
    if (!this.selectedPreset) {
      this.selectedPreset = allPresetGroups[0]?.presets?.[0];
    }
    this.filterFilters();
    this.emitPreset();
    this.updateUrl();
  }

  public async selectPreset(preset: IPatientFilterPreset) {
    
    if (preset?.name !== this.selectedPreset?.name) {
      this.isDrillInOpen = false;
      this.selectedPreset = preset;
      if (this.selectedPreset && 'filterSettings' in this.selectedPreset) {
        let user = await this.authService.getWellpepperUserInfo();
        const filterSetting = this.presetGroupService.getSkeletonFilterSettingsByName(preset);
        const originName =
          preset.origin ||
          preset.presetKey;
        preset.filterSettings = await this.getLocalstorageFilterSettings(
          preset.category,
          preset.presetKey,
          filterSetting,
          user?._id,
          originName
        );
      }

      this.filterFilters();
      this.emitPreset();
      this.updateUrl();
    }
  }

  public emitPreset(): void {
    this.switchListsActiveTab(
      this.selectedPreset?.category == PresetCategory.CustomList
        ? 'custom'
        : 'standard'
    );
    // this.presetSelected.emit(this.selectedPreset);
  }

  onSelectPreset(preset: IPatientFilterPreset) {
    // this.loadingStatus ? null : this.selectPreset((this.selectedPreset && preset.label == this.selectedPreset.label) ? this.selectedPreset : preset)
    if (this.loadingStatus || this.selectedPreset?.label == preset.label) {
      return;
    }

    // Send preset click event to MixPanel
    this.linkClickedEventsService.sendLinkClickedEvent({
      sourceSection: 'Patient Lists',
      sourceSubSection: preset.category,
      linkText: preset.label,
      targetPage: '/care/patients',
      filterPreset: preset.presetKey
    });

    this.selectPreset(preset);
  }

  public togglePatientFilters(): void {
    this.isPatientFiltersOpen = !this.isPatientFiltersOpen;
    this.filterToggleService.toggleFilterPane(this.isPatientFiltersOpen);
  }

  public filterFilters(): void {
    this.selectedCategory = this.selectedPreset?.category;
  }

  public updateUrl(): void {
    let attemptCounter: number;
    /*
    REVIEW: find a more elegant way to handle this.
    Need attemptCounter to refresh URL, technically.
    There’s a race condition issue:  open any other list but AWV Opportunities, refresh the page, then the list won’t get loaded.
    What happens: after page reload, the URL listener in CarePatients is trying to call filterChange(),
    but CarePatientsPresetGroupService and CarePatientsFiltersComponent
    are not ready at that moment and there are no available cohorts. As soon as the lists are initialized,
    the URL remains the same and there was no way to notify CarePatients about it, so by updating one of the query params,
    it triggers the URL listener again.
    */
    const filterPreset = this._route.snapshot.queryParams['filterPreset'];
    const AWVPatientsStatus = this.selectedPreset.AWVPatientsStatusURLQuery
      ? this._route.snapshot.queryParams['AWVPatientsStatus']
      : null;
    if (filterPreset == this.selectedPreset.name) {
      attemptCounter = this._route.snapshot.queryParams['attemptCounter']
        ? Number(this._route.snapshot.queryParams['attemptCounter']) + 1
        : 1;
    } else {
      attemptCounter = 0;
    }
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        filterPreset: this.selectedPreset.name,
        filterTitle:
          this.selectedPreset?.urlLabel ||
          this.selectedPreset?.label ||
          this.selectedPreset?.name,
        newPreset: this.selectedPreset.temporary,
        attemptCounter: attemptCounter,
        AWVPatientsStatus: AWVPatientsStatus,
      },
    });
  }

  async getAlertsAndMessages(): Promise<void> {
    if (!this.tier?.selectedTierId) {
      return;
    }
    if (
      !(
        this.userAccessService.hasComponent(
          this.userAccess,
          PatientEngagement.PatientApp
        ) ||
        this.userAccessService.hasComponent(
          this.userAccess,
          PatientEngagement.PatientTwilioSMS
        )
      )
    ) {
      return;
    }
    const orgId = await this.PatientService.getWpOrgIdFromTierId(
      this.tier?.selectedTierId
    );

    let activeAlertsByFilterDesc: { [filterDesc: string]: boolean } = {};

    if (!this.activeOrgAlerts.length) {
      this.activeOrgAlerts = orgId
        ? await this._patientAlertService.getWPAPIorgAlerts(orgId)
        : [];
    }

    if(this.isCombinedSMSCohortsEnabled){
      this.smsAlerts = 0;
      this.activeOrgAlerts.forEach(alert => {
        if(alert.filterDesc === "COPD SMS Alert" || alert.filterDesc === "COPD SMS Alert - Help Requested"){
            this.smsAlerts += 1
        }
      })
      let worklists = this.allPresetGroups.findIndex(e => e.category === 'Chronic Conditions')
      let preset = this.allPresetGroups[worklists].presets.findIndex(e => e.name === 'SmsOutreachProgram')
      this.allPresetGroups[worklists].presets[preset].alerts = this.smsAlerts;
    }

    this.activeOrgAlerts = this.activeOrgAlerts.filter(alert =>{
      if(alert.filterDesc === "COPD SMS Alert" || alert.filterDesc === "COPD SMS Alert - Help Requested"){
        return !this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CombineSMSCohorts);
      }
      return true;
    });

    this.activeOrgAlerts.forEach(alert => {
      activeAlertsByFilterDesc[alert.filterDesc] = true;
    });
  }

  // ========================= CUSTOM FILTER SETTINGS ============================

  async retrieveAllCustomPresets(categoryName: string) {
    const originColumns = this.presetGroupService.getPresetByName(
      CarePatientItem.AllPatients
    ).columns;
    let presets: IPatientFilterPreset[] =
      await this.agGridLocalSettingsService.retrieveAllCustomPresets(
        categoryName,
        originColumns
      );
    if (presets.length < 1) {
      return;
    }
    presets.forEach((preset) => {
      if (!preset.label) {
        preset.label = preset.name;
      }
    });
    let targetCategory: IPatientFilterPresetGroup = this.allPresetGroups.filter(
      (pg) => pg.category == categoryName
    )?.[0];
    if (targetCategory) {
      targetCategory.presets = presets;
      targetCategory.hideGroup = false;
    }
  }

  async getLocalstorageFilterSettings(
    categoryName: PresetCategory,
    filterName: string,
    currentFilterSettings: FilterSettings,
    userId: string,
    originName: CarePatientItem | string
  ): Promise<FilterSettings> {
    let storedFilterSettings: FilterSettings =
      await this.agGridLocalSettingsService.getStoredFilterSettingsFromLocalStorage(
        categoryName,
        filterName,
        userId,
        originName
      );
    if (storedFilterSettings?.rows) {
      let currentFilterSettingsElement: AgGridTableFilterSetting;
      let storedFilterSettingsElement: AgGridTableFilterSetting;
      for (let r in storedFilterSettings.rows) {
        // here it pulls stored filter settings and merges it with filter settings skeleton
        for (let fs in currentFilterSettings?.rows) {
          currentFilterSettingsElement = currentFilterSettings.rows[fs];
          storedFilterSettingsElement = storedFilterSettings.rows[fs];
          if (
            currentFilterSettingsElement.title ==
            storedFilterSettingsElement.title
          ) {
            currentFilterSettingsElement.excluded =
              storedFilterSettingsElement.excluded;
            currentFilterSettingsElement.dateFrom =
              storedFilterSettingsElement.dateFrom;
            currentFilterSettingsElement.dateTo =
              storedFilterSettingsElement.dateTo;
            currentFilterSettingsElement.filter =
              storedFilterSettingsElement.filter;
            currentFilterSettingsElement.filterTo =
              storedFilterSettingsElement.filterTo;
            currentFilterSettingsElement.filterType =
              storedFilterSettingsElement.filterType;
            currentFilterSettingsElement.type =
              storedFilterSettingsElement.type;
          }
        }
      }
    }

    return currentFilterSettings;
  }

  updateFilterSettings(filterSettings: FilterSettings): void {
    this.presetNameEditorToggle = false;
    if (this.selectedPreset) {
      this.selectedPreset.filterSettings = _.cloneDeep(filterSettings);
      this.updatePresetFilterSettingsTrigger.emit(filterSettings);
    }
  }
  deleteCustomFilterPreset() {
    let category: IPatientFilterPresetGroup = this.allPresetGroups.filter(
      (pg) => pg.category == this.selectedPreset.category
    )[0];
    if (category) {
      category.presets =
        category.presets?.filter(
          (preset) => preset.name !== this.selectedPreset.name
        ) || [];
      if (category.presets?.length == 0) {
        category.hideGroup = true;
      }
    }
    this.selectPresetWithString(this.selectedPreset?.origin);
    this.switchListsActiveTab('standard');
  }
  getUsedFilterNames(): string[] {
    /**
     * need to add "storedPresetNames" to avoid overwriting storedPresetNames list in localStorage
     * `agGrid_${categoryName}_storedPresetNames_${userId}` = stores all custom preset/filter names (string[])
     * `agGrid_${categoryName}_${filterName}_${userId}` = stores custom preset/filter
     */
    let names = ['storedPresetNames'];
    this.allPresetGroups.forEach((pg) => {
      names = names.concat(pg.presets.map((p) => p.name));
    });
    return names;
  }
  filterNameIsAlreadyUsed(newName: string): boolean {
    return this.selectedPreset.name == newName
      ? this.getUsedFilterNames().filter((f) => f == newName).length > 1
      : this.getUsedFilterNames().includes(newName);
  }
  async addCustomFilterPreset(settings: {
    name: CarePatientItem;
    filters: FilterSettings;
  }) {
    let indexForGeneralCategory: number | undefined = undefined;
    let indexForAllPatients: number | undefined = undefined;
    for (let i = 0; i < this.allPresetGroups.length; i++) {
      if (this.allPresetGroups[i].category === 'General') {
        for(let y = 0; y < this.allPresetGroups[i].presets.length; y++){
          if(this.allPresetGroups[i].presets[y].name==='AllPatients'){
            indexForGeneralCategory = i;
            indexForAllPatients = y;
            break;
          }
        }
        break;
      }
    }
    let preset = await this.agGridLocalSettingsService.addNewCustomPreset(
      PresetCategory.CustomList,
      settings.name,
      this.allPresetGroups[indexForGeneralCategory].presets[indexForAllPatients],
      _.cloneDeep(settings.filters)
    );
    let category: IPatientFilterPresetGroup = this.allPresetGroups.filter(
      (pg) => pg.category == PresetCategory.CustomList
    )[0];
    preset.temporary = false;

    if (category) {
      category.presets.push(preset);
      category.hideGroup = false;
    }
    if (this.isPatientListsUpdateFlagEnabled) {
      this.isDrillInOpen = false;
      this.isNewFilterDrawerOpen = false;
      this.presetNameEditorToggle = false;
    } else {
      this.toggleDrillInOpen();
    }
    this.selectedPreset = preset;
    this.updateUrl();
    this.switchListsActiveTab('custom');
  }
  async renameCustomFilterPreset(settings: {
    oldName: CarePatientItem;
    newName: CarePatientItem;
  }) {
    let preset = this.presetGroupService.getPresetByName(
      settings.oldName
    );
    if (preset) {
      preset.name = settings.newName;
      preset.label = settings.newName;
      this.updateUrl();
    }
    this.presetNameEditorToggle = false;
  }

  openNewFilterDrawer(): void {
    if (this.isNewFilterDrawerOpen) {
      return;
    }

    // store previous preset name in order to switch back if saving is canceled
    this.previousPreset = this.selectedPreset?.name;

    // cloning "All Patients" list
    this.newPreset = _.cloneDeep(
      this.presetGroupService.getPresetByName(
        CarePatientItem.AllPatients
      )
    );
    this.newPreset.origin = CarePatientItem.AllPatients;
    this.newPreset.name = CarePatientItem.CustomPatientList;

    // need to check if name "Custom Patient List" is not in use.
    // the loop bellow adds extra number to the end of the name
    if (
      this.presetGroupService.getPresetByName(this.newPreset.name)
    ) {
      let idx = 1;
      while (
        this.presetGroupService.getPresetByName(
          (this.newPreset.name + idx) as CarePatientItem
        )
      ) {
        idx++;
      }
      this.newPreset.name = this.newPreset.name.concat(
        idx.toString()
      ) as CarePatientItem;
      this.newPreset.label = this.newPreset.name;
    }

    this.newPreset.category = PresetCategory.CustomList;
    this.newPreset.origin = CarePatientItem.AllPatients;
    this.newPreset.presetKey = this.newPreset.name;

    // need to clear all filter settings
    this.newPreset.filterSettings?.rows.forEach((r) => {
      r.excluded = [];
    });

    // mark as temprorary filter in order to block storing filter model
    this.newPreset.temporary = true;
    this.presetGroupService.setTempPreset(this.newPreset);
    
    this.selectPreset(this.newPreset);
    if (this.isDrillInOpen) {
      this.isDrillInOpen = false;
    }
    this.toggleDrillInOpen(this.newPreset);
    this.isNewFilterDrawerOpen = true;
  }

  switchListsActiveTab(tab: 'standard' | 'custom'): void {
    if (!this.isNewFilterDrawerOpen && !this.isDrillInOpen) {
      this.activeTab = tab;
    }
  }

  /**
   * filters presets that meet featureFlagRequired and hideIfFeatureFlagIsActive conditions
   * @param presetGroups
   * @returns
   */
  filterPresetGroupsByFeatureFlag(
    presetGroups: IPatientFilterPresetGroup[]
  ): IPatientFilterPresetGroup[] {
    return presetGroups.filter((pg) => {
      if (pg.featureFlagRequired) {
        return this.featureFlagService.hasFeatureFlag(pg.featureFlagRequired);
      }
      if (pg.hideIfFeatureFlagIsActive) {
        return !this.featureFlagService.hasFeatureFlag(
          pg.hideIfFeatureFlagIsActive
        );
      }
      return true;
    });
  }
  splitStringIntoWords(string: string): string {
    return (
      string[0] +
      string
        .slice(1, string.length)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }
}
