import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { environment } from 'src/environments/environment';
import { CareFeatureConstants, PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class CareManagementAllProgramPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ? PresetCategory.ChronicConditions : PresetCategory.Cohorts;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.CareManagementAllProgram,
      presetKey: 'Care Management',
      label: 'Care Management',
      urlLabel: 'Care Management AllProgram',
      filters: [],
      componentId: CareComponentConstants.CareManagement,
      hidePreset: true,
      filterComponent: null,
      filterSettings: null,
      listDefinition: 'Target your patients for care management and clinical programs',
      columns: this.getPresetColumns(),
      hiddenColumnsByDefault: [
        'raF_YTD',
        'pyRaf',
        'totalSpend',
        'hccGapCount',
        'openRAFWt',
        'awvTypeDue',
        'lastAWVDate',
        'attributedPractice',
        'enrollmentType',
      ],
      defaultFilterModel: { 
        ssPercent: {
          filterType: 'number',
          type: 'lessThanOrEqual',
          filter: 15
        }
      }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}_2`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean }[] {
    return [
      { select: true },
      { mbi: true },
      { fullName: true },
      { dob: true },
      { gender: true },
      { attributedCP: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      { diseaseCohorts: true },
      { ccmLastProcCode: true},
      { lastCcmDate: true },
      { ccmEligible: true },
      { lastVisit: true },
      { officeVisits: true },
      { edVisits: true },
      { inpatientVisits: true },
      { ssDenom: true },
      { raF_YTD: this.agGridCustomFilteringFlag },
      { pyRaf: true },
      { totalSpend: true },
      { hccGapCount: true },
      { openRAFWt: true },
      { awvTypeDue: true }, 
      { lastAWVDate: true },
      { attributedPractice: true },
      { enrollmentType: true },
      { ssPercent: true }
    ];
  }
}
