<div>
  <ul
    style="
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      color: rgb(123, 123, 123);
      margin-right: 8px;
      margin-top: 8px;
      cursor: pointer;
    "
  >
    <li
      (click)="onSummaryTabSelected()"
      [ngClass]="{ selected: isInvoiceDetailsTabSelected }"
    >
      Invoice Details
    </li>
    <li
      (click)="onQuickFillTabSelected()"
      [ngClass]="{ selected: isClaimInfoTabSelected }"
    >
      Claim Info
    </li>
    <li
      (click)="onCareNoteSelected()"
      [ngClass]="{ selected: isClaimHistorySelected }"
    >
      Claim History
    </li>
  </ul>
</div>
