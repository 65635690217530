import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { HttpClient,HttpResponse, HttpHeaders, HttpErrorResponse, HttpInterceptor ,HttpHandler,HttpEvent, HttpRequest} from '@angular/common/http';
import { AuthService } from '../auth-service/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    //private secureRoutes = ['http://my.route.io/secureapi'];

    constructor(private authService: AuthService, @Inject('secureRoutes')private secureRoutes:any) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Ensure we send the token only to routes which are secured
       // if (!this.secureRoutes.find((x) => req.url.startsWith(x))) {
        //    return next.handle(req);
        //}
        const token = this.getToken(req.url);
        if (!!token) {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
              }
            });
          }
        return next.handle(req);
    }

    private getToken(url:string):string{
        let token:string;
        const item = this.secureRoutes.find(item=>item.url===url);

        if (item?.tokenType === 'idp_access_token')
          token = this.authService.idp_access_token;
        else if (item?.tokenType === 'idToken' ||
          item?.tokenType === 'idtokenAndSubId' ||
          url.indexOf(environment.apiBaseUrl) > -1 ||
          url.indexOf(environment.trackApiUrl) > -1 || // to move subcription Id here too
          url.indexOf(environment.userDetailApiUrl) > -1)
            token = this.authService.IdToken;

        return token;
    }
}
export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ];
