<div class="section" *ngIf="patient">
    <!-- HEADER -->
    <div class="header border-top--none border-bottom padding-bottom--smaller">
        <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--large">
            <div class="titles t4">
                <b class="title">HEALTH MAINTENANCE</b>
            </div>
        </div>
    </div>
    


   
    <div class="margin-left--larger margin-right--smaller margin-topBottom--smaller">

        <table class="care-gaps-table"> 
            <tbody>
                <tr>
                    <td class="icon-placeholder">
                        <!-- https://caravanhealth.atlassian.net/browse/CC-3533 display AWV clock if either AWV is > 12 months OR isn’t found -->
                        <coach-icon *ngIf="getDaysDifference(maintenance.awvLastVisit) || !maintenance.awvLastVisit" class="transition-fix" iconName="alarm-clock" iconColor="currentColor"></coach-icon> 
                    </td>
                    <td>
                        <b>Annual Wellness Visit </b>
                        <span class="u-color--highlight u-italic">({{maintenance.awvLastVisit ? getDateDifference(maintenance.awvLastVisit) : 'No Record Found'}}) </span>
                    </td>
                    <td>
                        <div class="u-thin u-text-align--right" style="text-transform: capitalize;" *ngIf="maintenance.awvLastVisit">
                            <span *ngIf="awvDetails">{{getCodeAndProviderNameAsString(awvDetails.procCodeList, awvDetails.provider)}}</span>{{maintenance.awvLastVisit | date:'MM/dd/yy'}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="icon-placeholder"> 
                        <!-- KEEP EMPTY -->
                    </td>
                    <td>
                        <b> Advance Care Planning </b>
                        <span class="u-color--highlight u-italic">({{ maintenance.acpLastVisit ? getDateDifference(maintenance.acpLastVisit) : 'No Record Found'}})</span>
                    </td>
                    <td>
                        <div class="u-thin u-text-align--right" *ngIf="maintenance.acpLastVisit">
                            <span *ngIf="acpDetails">{{getCodeAndProviderNameAsString(acpDetails.procCodeList, acpDetails.provider)}}</span>{{maintenance.acpLastVisit | date:'MM/dd/yy'}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="icon-placeholder">
                        <!-- KEEP EMPTY -->
                    </td>
                    <td>
                        <b>Chronic Care Management </b>
                        <span class="u-color--highlight u-italic"> ({{ maintenance.ccmLastVisit ? getDateDifference(maintenance.ccmLastVisit) : 'No Record Found'}}) </span>
                    </td>
                    <td>
                        <div class="u-thin u-display--flex u-align-items--center u-text-align--right u-justify-content--right">
                            <span *ngIf="ccmDetails">{{getCodeAndProviderNameAsString(ccmDetails.procCodeList, ccmDetails.provider)}}</span>{{maintenance.ccmLastVisit | date:'MM/dd/yy'}}
                            <div class="u-display--flex u-align-items--center margin-left--small" *ngIf="patient.trackPatient.ccmEligible && !maintenance.ccmLastVisit">
                                <coach-icon class="transition-fix" iconName="check-circle" iconColor="currentColor" iconSize="small">
                                </coach-icon> CCM
                                Eligible
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table> 
    </div> 

</div>