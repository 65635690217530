import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ActionId,
  ComponentId,
  LogAuditElementID,
  SubComponentId,
} from '@shared/models/audit-constants';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { PatientEngagement } from '@shared/enums';

export interface LogAuditElements {
  LogAuditElementID: number;
  ElementValue: string;
}

export interface LogAuditRequest {
  Details: string;
  ComponentId: number;
  ActionId?: number;
  ModuleId?: number;
  SubComponentId?: number;
  LogAuditElements: LogAuditElements[];
}

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private auditBaseURL: string;
  private moduleIdCare = 2;
  constructor(
    private http: HttpClient,
    private ToastrMessageService: ToastrMessageService
  ) {
    this.auditBaseURL = `${environment.apiBaseUrl}/logging/audit`;
  }
  public auditLog(
    detail: string,
    compId: ComponentId | PatientEngagement,
    subComp: SubComponentId,
    actionId: ActionId,
    chPatId: string,
    tier: IHierarchyTier,
    orgId: string,
    elements?: LogAuditElements[],
    moduleId: number = null
  ): void {
    const logAuditRequest: LogAuditRequest = this.getAuditRequest(
      detail,
      compId,
      subComp,
      actionId,
      chPatId,
      tier,
      orgId,
      elements,
      moduleId
    );
    this.postAuditLogging(logAuditRequest).subscribe(() => {});
  }

  private getAuditRequest(
    detail: string,
    compId: ComponentId | PatientEngagement,
    subComp: SubComponentId,
    actionId: ActionId,
    chPatId: string,
    tier: IHierarchyTier,
    orgId: string,
    elements?: LogAuditElements[],
    moduleId: number = null
  ): LogAuditRequest {
    const auditStruct: LogAuditRequest = {
      Details: detail,
      ComponentId: compId,
      ActionId: actionId,
      ModuleId: moduleId ?? this.moduleIdCare,
      SubComponentId: subComp,
      LogAuditElements: elements ? elements : [],
    };
    if (chPatId) {
      auditStruct.LogAuditElements.push({
        LogAuditElementID: LogAuditElementID.ChPatId,
        ElementValue: chPatId,
      });
    }
    if (orgId) {
      auditStruct.LogAuditElements.push({
        LogAuditElementID: LogAuditElementID.OrgId,
        ElementValue: orgId,
      });
    }
    if (tier) {
      let elementId;
      switch (tier.tier) {
        case 1:
          elementId = LogAuditElementID.ACO_ID;
          break;
        case 2:
          elementId = LogAuditElementID.Tier2_ID;
          break;
        case 3:
          elementId = LogAuditElementID.Tier3_ID;
          break;
        case 4:
          elementId = LogAuditElementID.Tier4_ID;
          break;
      }
      if (elementId) {
        auditStruct.LogAuditElements.push({
          LogAuditElementID: elementId,
          ElementValue: tier.selectedTierId,
        });
      }
    }
    return auditStruct;
  }
  public postAuditLogging(logAuditRequest: LogAuditRequest): Observable<any> {
    let url = `${this.auditBaseURL}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey, // move sub Id to authIntercept too
        //'Authorization': `Bearer ${this.userToken}`,
      },
      params: {},
      responseType: 'blob' as 'json', // hack to allow compilation
    };
    return this.http.post(url, logAuditRequest, options).pipe(
      tap(
        (data) => {},
        // Log the result or error
        (error) => {
          console.error(error);
          this.ToastrMessageService.error(error);
        }
      )
    );
  }
}
