import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DocRepo } from '@shared/models/docRepo/list';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'coach-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss'],
})
export class DocUploadComponent implements OnInit, OnDestroy {
  // Document Upload
  URL = `${environment.coachApiUrl}/api/docRepo/uploadFile`;
  public uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  response: string;

  @Input() docRepoData: DocRepo[];
  public uploadDestination: DocRepo;
  public uploadDestinations: DocRepo[];
  public uploadContainsPHI: boolean;
  private currentUserFullName: string;
  private currentTier: IHierarchyTier;
  private tierServiceSubscription: Subscription;
  private authServiceSubscription: Subscription;
  @Output() updateFileTrigger: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private tierService: HierarchyTierService,
    private fileDownloadService: FileDownloadService,
    private toast: ToastrService,
  ) {
    this.uploader = new FileUploader({
      url: this.URL,
      itemAlias: 'file',
      headers: [
        {
          name: 'Authorization',
          value: `Bearer ${this.authService.getToken()}`,
        },
      ],
      allowedMimeType: ['application/vnd.ms-powerpoint','application/vnd.rar', 'application/zip', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'application/vnd.ms-excel', 'ppt', 'text/csv'],
    });

    this.tierServiceSubscription = tierService.currentTier$.subscribe((tier) => {
      this.currentTier = tier;
    });

    this.authServiceSubscription = this.authService.userInfo$.subscribe((data) => {
      this.currentUserFullName = data.name;
    });

    this.hasBaseDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe((res) => (this.response = res));
  }

  public cancelUpload(): void {
    this.uploader.clearQueue();
    this.fileDownloadService.updateSelectedFolder(null);
  }

  public fileOverBase(e: any): void {
    console.log('file is over base');
    this.hasBaseDropZoneOver = e;
  }

  public onChange(isChecked: boolean): void {
    this.uploadContainsPHI = isChecked;
    if (isChecked) {
      console.log('uploading PHI data to folder');
      this.uploadDestination = this.uploadDestinations[1];
    } else {
      this.uploadDestination = this.uploadDestinations[0];
    }
  }

  ngOnInit(): void {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadDestinations = this.fileDownloadService.uploadFolders;
      this.uploadDestination = this.uploadDestinations[0];
    };

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData) => {
      const collectionID = this.uploadDestination.EntryId.toString();
      form.append('collectionID', collectionID);

      const folderPath = 'Practice Improvement/' + this.uploadDestination.Title;
      form.append('folderPath', folderPath);
      if (this.currentTier.selectedItem.tier ==1){
        form.append('tierNode', this.currentTier.selectedItem.tier1_ID);
      }
      else {
        form.append('tierNode', this.currentTier.selectedItem.tier2_ID);
      }


      let phi = 'false';
      if (this.uploadContainsPHI) {
        phi = this.uploadContainsPHI.toString();
      }
      form.append('phi', phi);

      form.append('author', this.currentUserFullName);

      return { fileItem, form };
    };

    this.uploader.onCompleteItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      if (status === 200 || status === 204) {
        this.toast.success('File uploaded successfully!');
      } else {
        this.toast.error('File upload failed.');
      }
    };

    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      this.updateFileTrigger.emit(null);
    };
  }

  ngOnDestroy(): void {
    this.authServiceSubscription.unsubscribe();
    this.tierServiceSubscription.unsubscribe();
  }

  private getFolderPathFromIDs(
    docRepoData: DocRepo[],
    folderID: string
  ): string {
    for (const entry of docRepoData) {
      if (folderID === entry.EntryId.toString()) {
        return entry.Title;
      }
    }
    console.log('Could not build Path from Ids');
    throw new Error('Could not build Path from Ids');
  }
}
