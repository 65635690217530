import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { UserAccessService } from '@shared/services/user-access/user-access.service';

@Directive({
  selector: '[hasFeatureFlagDefined]'
})
export class HasFeatureFlagDefinedDirective implements OnInit, OnDestroy {
  @Input()
  set hasFeatureFlagDefined(value: string) {
    this.featureFlag = value;
  }

  @Input()
  set hasFeatureFlagDefinedFeatureId(value: number) {
    this.featureId = value;
  }

  @Input()
  set hasFeatureFlagDefinedComponentId(value: number) {
    this.componentId = value;
  }

  private featureFlag: string;
  private featureId: number;
  private componentId: number;
  private destroy$ = new Subject<void>();
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
    private userAccessService: UserAccessService
  ) {}

  ngOnInit(): void {
    combineLatest([this.featureFlagService.featureFlags$, this.userAccessService.currentAccess$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([featureFlags, currentAccess]) => {
        if (this.featureFlag && !this.featureFlagService.hasFeatureFlag(this.featureFlag)) {
          this.viewContainer.clear();
          this.hasView = false;
          return;
        } else if (
          this.featureId &&
          !this.userAccessService.currentAccess$.value.modules.some(m =>
            m.features.some(f => f.featureId === this.featureId)
          )
        ) {
          this.viewContainer.clear();
          this.hasView = false;
          return;
        } else if (this.componentId && !this.userAccessService.hasComponent(currentAccess, this.componentId)) {
          this.viewContainer.clear();
          this.hasView = false;
          return;
        }
        
        if (!this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
