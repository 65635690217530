<div [ngClass]="{'widget margin--large u-height--fit-content': !sidebarMode }">
  <div class="header" *ngIf="!sidebarMode">
      <div class="titles">
          <div class="title">
              <span class="margin-right--small">AWV Scheduling</span>
              <span *ngIf="patient && !isTabOpen" class="u-font-size--small u-font-weight--smaller"></span>
          </div>
      </div>
      <coach-icon class="u-cursor--pointer" iconSize="large" [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" (click)="isTabOpen = !isTabOpen"></coach-icon>
  </div>
  <div>
      <div  *ngIf="patient && isTabOpen"  class="u-display--flex u-flex-direction--row ihe-content-wrapper" [ngClass]="{'u-flex-wrap--wrap padding--small': mobileScreenSize, 'padding--large': !mobileScreenSize}">
          <div class="u-display--flex u-flex-direction--column margin-bottom--large padding-leftRightTopBottom--small" [ngClass]="{'u-width--webkit-fill-available': mobileScreenSize, 'span--6': !mobileScreenSize}">
            <div class="titles t4">
              <div class="title padding--small row u-bold u-display--flex pcc-margin-top u-text-white-space--break-spaces" id='testMessageText'>Call patient to schedule their AWV</div>
            </div>
            <coach-patient-awv-callback *ngIf="awvWorkflow && awvWorkflow.status == allAwvWorkflowStatuses.OutreachAttempted" [properties]="callbackOutcomeProperties" [awvWorkflow]="awvWorkflow"></coach-patient-awv-callback>
            <div class="row--wrap u-font-size--smallest padding-top--small">
                <button [ngClass]="{'activity-btn-selected': selectedActivity == 1 }" id="awvScheduled" class="activity-button activity-btn-hover u-flex-direction--column u-display--flex margin--smaller border--1 u-border-radius--large u-cursor--pointer span--3 u-truncate--none u-text-align--center padding--smaller u-background-color--none" (click)="setSelectedActivity(1)">
                  <div class="row u-align-self--center">
                    <coach-icon class="icon icon--default is-medium u-align-self--center" iconName="calendar" >
                    </coach-icon>
                  </div>
                  <div class="row padding-top--smallest">AWV Scheduled</div>
                </button>
              <button [ngClass]="{'activity-btn-selected': selectedActivity == 2 }" id="awvCompleted" class="activity-button activity-btn-hover u-flex-direction--column u-display--flex margin--smaller border--1 u-border-radius--large u-cursor--pointer span--3 u-truncate--none u-text-align--center padding--smaller u-background-color--none" (click)="setSelectedActivity(2)">
                <coach-icon class="icon icon--default is-medium u-align-self--center" iconName="calendar-complete" >
                </coach-icon>
                <div class="row padding-top--smallest">AWV Completed</div>
              </button>
              <button [ngClass]="{'activity-btn-selected': selectedActivity == 3 }" id="callBack" class="activity-button activity-btn-hover u-flex-direction--column u-display--flex margin--smaller border--1 u-border-radius--large u-cursor--pointer span--3 u-truncate--none u-text-align--center padding--smaller u-background-color--none" (click)="setSelectedActivity(3)">
                <coach-icon class="icon icon--default is-medium u-align-self--center" iconName="phone-callback" >
                </coach-icon>
                <div class="row padding-top--smallest">Call Back Later</div>
              </button>
              <button [ngClass]="{'activity-btn-selected': selectedActivity == 4 }" id="remove" class="activity-button activity-btn-hover u-flex-direction--column u-display--flex margin--smaller border--1 u-border-radius--large u-cursor--pointer span--3 u-truncate--none u-text-align--center padding--smaller u-background-color--none" (click)="setSelectedActivity(4)">
                <coach-icon class="icon icon--default is-medium u-align-self--center" iconName="x" >
                </coach-icon>
                <div class="row padding-top--smallest">Remove From List</div>
              </button>
            </div>
            <div class="u-font-size--small padding-top--small">
              <coach-patient-workflow-scheduled [ngClass]="{'activity-form-deselected': selectedActivity != 1 }" [patient]="patient" (outcomeSaved)="this.outcomeSaved($event);" [properties]=scheduledOutcomeProperties [editModeActive]="false"></coach-patient-workflow-scheduled>
              <coach-patient-workflow-completed [ngClass]="{'activity-form-deselected': selectedActivity != 2 }" [patient]="patient" (outcomeSaved)="this.outcomeSaved($event);" [editModeActive]="false"></coach-patient-workflow-completed>
              <coach-patient-workflow-call-back [ngClass]="{'activity-form-deselected': selectedActivity != 3 }" [patient]="patient" (outcomeSaved)="this.outcomeSaved($event);" [properties]=callbackOutcomeProperties></coach-patient-workflow-call-back>
              <coach-patient-workflow-remove [ngClass]="{'activity-form-deselected': selectedActivity != 4 }" [patient]="patient" (outcomeSaved)="this.outcomeSaved($event);" [editModeActive]="false"></coach-patient-workflow-remove>
            </div>
          </div>
      </div>
  </div>
</div>
