import { Component, OnInit } from '@angular/core';
import { CoachTabs } from '@shared/components/coach-tabs/coach-tabs';
import { TrackFeatureConstants } from '@shared/models/module-constants';

const {
  FinancialUtilizationFeature,
  ClinicalFeature,
  QualityFeature,
  QualityFeatureName,
  ValueCalculatorName,
  ValueCalculatorFeature,
  ValueCalculatorFeatureFlag,
  PerformanceDashboardName,
  PerformanceDashboardFeature,
  PerformanceDashboardFeatureFlag,
  CompareFeatureName,
  CompareFeature,
  CompareFeatureFlag,
} = TrackFeatureConstants;

@Component({
  selector: 'coach-track',
  templateUrl: './track.component.html',
  host: {
    class: 'coach-track',
  },
})
export class TrackComponent implements OnInit {
  public tabsData: CoachTabs[] = [
    {
      name: 'Summary',
      routerLink: ['summary'],
      featureId: null,
    },
    {
      name: PerformanceDashboardName,
      routerLink: ['performance'],
      featureId: PerformanceDashboardFeature,
      featureFlag: PerformanceDashboardFeatureFlag
    },
    {
      name: 'Financial + Utilization',
      routerLink: ['financial-utilization'],
      featureId: FinancialUtilizationFeature,
    },
    {
      name: 'Clinical',
      routerLink: ['clinical'],
      featureId: ClinicalFeature,
    },
    {
      name: QualityFeatureName,
      routerLink: ['quality'],
      featureId: QualityFeature,
    },
    {
      name: ValueCalculatorName,
      routerLink: ['value-calculator'],
      featureId: ValueCalculatorFeature,
      featureFlag: ValueCalculatorFeatureFlag
    },
    {
      name: CompareFeatureName,
      routerLink: ['compare'],
      featureId: CompareFeature,
      featureFlag: CompareFeatureFlag
    },
  ];

  constructor() { }

  ngOnInit(): void { }
}
