/* tslint:disable */
/* eslint-disable */

/**
 * Source of a history change
 */
export enum HistorySourceType {
  CareOrchSystem = 'CareOrchSystem',
  CareOrchSelfReported = 'CareOrchSelfReported',
  CareOrchAdmin = 'CareOrchAdmin',
  PepSystem = 'PepSystem',
  PepSelfReported = 'PepSelfReported',
  CmsClaimsData = 'CmsClaimsData',
  EhrClinicalData = 'EhrClinicalData',
  InHomeAwv = 'InHomeAwv'
}
