<ng-container [formGroup]="parentForm">
  <div class="u-background-color--white" [formGroupName]="questionInfo.name" [ngClass]="{'u-opacity--lightest': isDisabled}">
    <div class="submitFormBlock-header">
      <div class="row">
        <h6 class="margin--none u-fill u-opacity--light">
          {{questionInfo.measureId}} <span *ngIf="questionInfo.measureId != null">|</span> {{questionInfo.pointsText}}: {{maxPoints}} Points
        </h6>
        <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small" iconColor="primary"
          (click)="toggleInfoText()"></coach-icon>
      </div>
      <div class="u-bold">{{questionInfo.description}}</div>
      <div class="margin-top" *ngIf="questionInfo.allowExclusion">
        <input [attr.disabled]="isDisabled ? true : null" type="checkbox" formControlName="exclusion"
          (change)="exclusionChanged()" id="pi-exclusion-{{questionInfo.measureId}}">
        <label for="pi-exclusion-{{questionInfo.measureId}}">Request Exclusion</label>
      </div>
    </div>
    <div class="submitFormBlock-main" *ngIf="!isExcluded? true : null">
      <form [formGroup]="formCheck">
      <ng-container formArrayName="chkbxlst">
        <div *ngFor="
            let option of questionInfo.checkBoxOptions;
            let i = index
          ">
          <input type="checkbox" [attr.disabled]="isDisabled ? true : null" id="practice_{{questionInfo.name}}_{{ questionInfo.checkBoxOptions[i].checkId }}" [formControlName]="i" />
          <label for="practice_{{questionInfo.name}}_{{ questionInfo.checkBoxOptions[i].checkId }}">
            <span class="u-bold u-fill">{{
              questionInfo.checkBoxOptions[i].checkText
              }}</span>
          </label>
        </div>
      </ng-container>
    </form>
    </div>
  </div>



  <div *ngIf="showExclusionModal" class="overlay overlay--light is-open">
    <div class="modal">
      <div class="header">
        <div class="titles">
          <div class="title">Proceed With Exemption for {{questionInfo.description}}?</div>
        </div>
      </div>
      <div class="dynamicBody padding">
        {{questionInfo.exclusionText}}
      </div>
      <div class="footer">
        <button class="button margin-right border--none" (click)="cancelExclusion()">No</button>
        <button class="button is-primary" (click)="confirmExclusion()">Yes</button>
      </div>
    </div>
  </div>
