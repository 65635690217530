import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {ReminderActivity} from '@api/care-orch/models/reminder-activity';
import {AwvWorkflow} from '@api/care-orch/models/awv-workflow';
import {Outcome} from '@api/care-orch/models/outcome';
import {OutcomeType} from '@api/care-orch/models/outcome-type';
import {Activity} from '@api/care-orch/models/activity';
import {PatientWasRemindedOutcome} from '@api/care-orch/models/patient-was-reminded-outcome';
import {AttendedActivity} from '@api/care-orch/models/attended-activity';
import {PatientAttendedOutcome} from '@api/care-orch/models/patient-attended-outcome';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import { OnChanges } from '@angular/core';
import { AwvWorkflowService } from '@shared/services/awv-workflow/awv-workflow.service';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';


@Component({
  selector: 'coach-patient-step-reminder',
  templateUrl: './patient-step-reminder.component.html',
  styleUrls: ['./patient-step-reminder.component.scss']
})
export class PatientStepReminderComponent implements OnInit, OnChanges{
  @Input() public awvWorkflow: AwvWorkflow;
  @Output() public activitySaved: EventEmitter<Activity> = new EventEmitter();

  public attendedOutcome: Outcome;
  public isTabOpen: boolean;
  public reminderOutcome: Outcome;
  public isActivityComplete = false;
  public dueDateTime = '';
  public remindedDateTime = '';
  public outcomeTypes = OutcomeType;

  constructor(private awvWorkflowService: AwvWorkflowService, private workflowEvents: WorkflowEventsService) {
  }

  ngOnInit(): void {
    this.awvWorkflowService.updateSuccessful$.subscribe(() => this.isTabOpen = false);
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.awvWorkflow){
      this.reminderOutcome = null;
      this.isActivityComplete = false;
      this.attendedOutcome = null;
      this.remindedDateTime = '';
      this.dueDateTime = WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.awvWorkflow.dueDateTime);

      const completeReminderActivities = this.awvWorkflow.completedActivities
        .filter( x  => x.type === 'ReminderActivity').map(x => x as ReminderActivity);
      const inProgressReminderActivities = this.awvWorkflow.inProgressActivities
        .filter( x  => x.type === 'ReminderActivity').map(x => x as ReminderActivity);
      const completeAttendedActivities = this.awvWorkflow.completedActivities
            .filter( x  => x.type === 'AttendedActivity').map(x => x as AttendedActivity);
      if (completeReminderActivities.length === 1) {
        this.reminderOutcome = completeReminderActivities.pop().outcome;

        if (this.reminderOutcome.type === OutcomeType.PatientWasRemindedOutcome) {
          this.isActivityComplete = true;
          this.remindedDateTime = (this.reminderOutcome as PatientWasRemindedOutcome).properties.remindedDateTime;
        }
      }
      if (completeAttendedActivities.length > 0){
         this.attendedOutcome = completeAttendedActivities.find(x => x.outcome.type === OutcomeType.PatientAttendedOutcome)
           .outcome as PatientAttendedOutcome;
      }
      if (inProgressReminderActivities.length === 1) {
        this.reminderOutcome = inProgressReminderActivities.pop().outcome;
      }
    }
  }

  outcomeSaved(outcome: Outcome): void {
    const activity = {
      type: 'ReminderActivity',
      outcome
    } as ReminderActivity;

    if (this.reminderOutcome) {
      this.workflowEvents.trackEdit(activity);
    } else {
      this.workflowEvents.trackSave(activity);
    }

    this.activitySaved.emit(activity);
  }
}


