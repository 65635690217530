<div class="dynamicBody">
  <ng-container>
    <ngx-masonry class="widgets widgets-masonry" [options]="masonryOptions">

      <!--Widget 1-->
      <ng-container *ngIf="widgetMarkdown != undefined" >
        <ng-container *ngFor="let cardWidget of cardWidgetIDs">
          <div  class="widget" data-cy="whatsNew_widget" ngxMasonryItem>
            <div class="header header--slanted">
              <div class="titles t5">
                <div class="title">{{widgetMarkdown[cardWidget][0].cardWidgetTitle}}</div>
              </div>
            </div>
            <!--section 1-->
            <div class="padding--small" *ngFor="let section of widgetMarkdown[cardWidget]">
              <div markdown #postDiv [data]="section.contentMarkdown"></div>
            </div>
            </div>

        </ng-container>
      </ng-container>

      <!--Widget 2 -->
      <coach-track-performance-widget [navigateToItem]="goToItems"></coach-track-performance-widget>

      <!--Widget 3-->
      <div class="widget" data-cy="care_widget" ngxMasonryItem *hasModule="careModule">
        <div
          class="header header--slanted u-accentColor--care u-hoverEffect"
          (click)="onCareClick($event)"
        >
          <div class="titles t5">
            <div class="title">Care for your patients</div>
          </div>
          <div
            class="
              label
              row--verticalCenter
              u-color--accentColor
              widget--label
              padding--none
            "
          >
            <coach-icon
              iconName="users"
              iconColor="currentColor"
              class="margin-right--smaller"
              iconSize="small"
            >
            </coach-icon>
            <span>Care</span>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img
            class="widget-illustration"
            src="assets/img/illustrations/SH_PEP_Illustrations_Care_Main_1700x1100_20221114.svg"
          />
          <p class="u-italic u-opacity--light margin-top--smaller">
            ACO success starts and ends with caring for your patients. {{app.ApplicationName}} allows you to manage your patient populations, highlighting
            gaps and risk scores, and then provides you with tools to engage
            your patients towards healthier outcomes.
          </p>
          <ul class="margin-top--large margins-bottom--small">
            <li>
              <a
                href="AWV Opportunities"
                (click)="
                  goToItems($event, '/care/patients', {
                    filterPreset: 'awv-opportunities'
                  })
                "
                >AWV Opportunities</a
              >
            </li>
            <li>
              <a
                href="Care Management"
                (click)="
                  goToItems($event, '/care/patients', {
                    filterPreset: 'care-management'
                  })
                "
                >Care Management</a
              >
            </li>
            <li *hasFeatureFlag="HCCListRevamp">
              <a href="Top 50" (click)="
                  goToItems($event, '/care/patients', {
                    filterPreset: 'hcc-top-50'
                  })
                "
                >Top 50</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!--Widget 2-->
      <div class="widget" data-cy="track_widget" ngxMasonryItem *hasModule="trackModule">
        <div
          class="header header--slanted u-accentColor--track u-hoverEffect"
          (click)="goToItems($event, '/track')"
        >
          <div class="titles t5">
            <div class="title">Track your performance</div>
          </div>
          <div
            class="
              label
              row--verticalCenter
              u-color--accentColor
              widget--label
              padding--none
            "
          >
            <coach-icon
              iconName="trending-up"
              iconColor="currentColor"
              class="margin-right--smaller"
              iconSize="small"
            >
            </coach-icon>
            <span>Track</span>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img
            class="widget-illustration"
            src="assets/img/illustrations/SH_PEP_Illustrations_Track_Main_1700x1100_20221114.svg"
          />
          <p class="u-italic u-opacity--light margin-top--smaller">
            Knowledge is power! The first step to improving is recognizing your
            opportunities. These tools will bring your data to life and
            highlight the areas to focus on.
          </p>
          <ul class="margin-top--large margins-bottom--small">
            <li *hasFeature="trackFinancialFeature">
              <a
                href="#"
                (click)="goToItems($event, '/track/financial-utilization')"
                >Financial + Utilization</a
              >
            </li>
            <li *hasFeature="trackClinicalFeature">
              <a href="#" (click)="goToItems($event, '/track/clinical')"
                >Clinical</a
              >
            </li>
            <li *hasFeature="trackQualityFeature">
              <a href="#" (click)="goToItems($event, '/track/quality')">{{
                trackQualityFeatureName
              }}</a>
            </li>
            <ng-container *hasFeatureFlag="trackValueFeatureFlag">
              <li *hasFeature="trackValueFeature">
                <a
                  href="#"
                  (click)="goToItems($event, '/track/value-calculator')"
                  >{{ trackValueName }}</a
                >
              </li>
            </ng-container>
            <ng-container *hasFeatureFlag="trackCompareFeatureFlag">
              <li *hasFeature="trackCompareFeature">
                <a
                  href="#"
                  (click)="goToItems($event, '/track/compare')"
                  >{{ trackCompareName }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <!--Widget 3 -->
      <div class="widget" data-cy="improve_widget" ngxMasonryItem *hasModule="improveModule">
        <div
          class="header header--slanted u-accentColor--improve u-hoverEffect"
          (click)="goToItems($event, '/improve')"
        >
          <div class="titles t5">
            <div class="title">Improve your practice</div>
          </div>
          <div
            class="
              label
              row--verticalCenter
              u-color--accentColor
              widget--label
              padding--none
            "
          >
            <coach-icon
              iconName="award"
              iconColor="currentColor"
              class="margin-right--smaller"
              iconSize="small"
            >
            </coach-icon>
            <span>Improve</span>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img
            class="widget-illustration"
            src="assets/img/illustrations/SH_PEP_Illustrations_Improve_Main_1700x1100_20221114.svg"
          />
          <p class="u-italic u-opacity--light margin-top--smaller">
            Welcome to the {{app.CompanyNameFull}} family! We're here to help you maximize your
            results by sharing proven best practices that will improve your
            practice.
          </p>
          <ul class="margin-top--large margins-bottom--small">
            <li *hasFeature="improveContactsFeature">
              <a href="#" (click)="goToItems($event, '/improve/contacts')"
                >Contacts</a
              >
            </li>
            <li *hasFeature="improvePracticeImprovementFeature">
              <a
                href="#"
                (click)="goToItems($event, '/improve/practice-improvement')"
                >Practice Improvement</a
              >
            </li>
            <li *hasFeature="improveITFeature">
              <a
                href="#"
                (click)="goToItems($event, '/improve/information-technology')"
                >Information Technology</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- The following commented section will be added back in after 340B MVP release. -->
      <!-- ---------------------------------------------------------------------------------- -->
      <!-- <div class="widget" ngxMasonryItem *hasFeature="carePrescriptionFeature">
        <div class="header header--slanted u-accentColor--care u-hoverEffect" (click)="goToItems($event, '/care/prescriptions')">
          <div class="titles t5">
            <div class="title">340B PRESCRIPTIONS</div>
          </div>
          <div class="label row--verticalCenter u-color--accentColor widget--label padding--none">
            <coach-icon
              iconName="users"
              iconColor="currentColor"
              class="margin-right--smaller"
              iconSize="small">
            </coach-icon>
            <span>Care</span>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <p class="u-italic u-opacity--light margin-top--smaller">
            Did you know that your ACO work can significantly increase your 340B discounts?
          </p>
        </div>
      </div> -->

      <!-- <div class="widget" ngxMasonryItem *hasFeature="carePrescriptionProcessingFeature">
        <div class="header header--slanted u-accentColor--care u-hoverEffect" (click)="goToItems($event, '/care/prescriptions-processing')">
          <div class="titles t5">
            <div class="title">340B PRESCRIPTIONS PROCESSING</div>
          </div>
          <div class="label row--verticalCenter u-color--accentColor widget--label padding--none">
            <coach-icon
              iconName="users"
              iconColor="currentColor"
              class="margin-right--smaller"
              iconSize="small">
            </coach-icon>
            <span>Care</span>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <p class="u-italic u-opacity--light margin-top--smaller">
            Did you know that your ACO work can significantly increase your 340B discounts?
          </p>
        </div>
      </div> -->

      <!-- ---------------------------------------------------------------------------------- -->

      =

      <!--Widget 4-->
      <div class="widget" data-cy="submit_widget" ngxMasonryItem *hasModule="submitModule">
        <div
          class="header header--slanted u-accentColor--submit u-hoverEffect"
          (click)="goToItems($event, '/submit')"
        >
          <div class="titles t5">
            <div class="title">Submit your results</div>
          </div>
          <div
            class="
              label
              row--verticalCenter
              u-color--accentColor
              widget--label
              padding--none
            "
          >
            <coach-icon
              iconName="upload"
              iconColor="currentColor"
              class="margin-right--smaller"
              iconSize="small"
            >
            </coach-icon>
            <span>Submit</span>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img
            class="widget-illustration"
            src="assets/img/illustrations/SH_PEP_Illustrations_Submit_Main_1700x1100_20221114.svg"
          />
          <p class="u-italic u-opacity--light margin-top--smaller">
            We're here to help! Sharing your results allows you to see how
            you're doing compared to others in your ACO and give us the details
            we need to provide unique insights for your practice.
          </p>
          <coach-submit-upcoming-submission-summary></coach-submit-upcoming-submission-summary>
        </div>
      </div>

      <!--Widget 5-->
      <div class="widget" data-cy="learn_widget" ngxMasonryItem *hasFeatureFlag="'CaravanLearn'">
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">{{app.CompanyName}} Learn</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <img
            class="widget-illustration"
            src="assets/img/illustrations/SH_PEP_Illustrations_Learn_Main_1700x1100_20221114.svg"
          />
          <p class="u-italic u-opacity--light margin-top--smaller">
            Welcome! {{app.CompanyName}} Learn hosts all of our high-quality education and
            content in an intuitively organized, easy to use system. Click the
            link below to access {{app.CompanyName}} Learn's full library and engage with
            learning tools, forum opportunities, best practices, and webinars
            that will enhance overall patient care.
          </p>
          <div class="margin-top--large">
            <ul class="margin-bottom--small">
              <li>
                <a href="https://learn.caravan.health/" target="_blank"
                  >{{app.CompanyName}} Learn
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--Widget 6a-->
      <ng-container *doesNotHaveFeatureFlag="HCCListRevamp">
        <ng-container *doesNotHaveFeatureFlag="HCCVIPCohortList">
          <div *hasComponent="marketingWidgetComponent" class="widget" ngxMasonryItem>
            <div class="header header--slanted">
              <div class="titles t5">
                <div class="title">{{app.ApplicationName}} Chronic Conditions VIP List</div>
              </div>
            </div>
            <div class="dynamicBody padding--large">
              <p class="u-italic u-opacity--light margin-top--smaller">
                The Chronic Conditions VIP List is a table of patients created by {{app.CompanyName}}'s
                data experts specifically for you! This file is a workable list of
                patients whom you can contact to achieve the greatest difference in
                your overall Chronic Conditions gap rate and Risk Adjustment Factor.
              </p>
              <div class="margin-top--large">
                <ul *ngIf="hccvipFolderId != null" class="margin-bottom--small">
                  <li>
                    <a href="{{app.ApplicationName}} Chronic Conditions VIP List"
                      (click)="goToItems($event, '/improve/practice-improvement', {folder: hccvipFolderId})">{{app.ApplicationName}} Chronic Conditions VIP
                      List</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>



        <!--Widget 6b-->
        <ng-container *hasFeatureFlag="HCCVIPCohortList">
          <ng-container *hasModule="careModule">
            <div *ngIf="phiAccess" class="widget" ngxMasonryItem>
              <div class="header header--slanted">
                <div class="titles t5">
                  <div class="title">{{app.ApplicationName}} Chronic Conditions VIP List</div>
                </div>
              </div>
              <div class="dynamicBody padding--large">
                <p class="u-italic u-opacity--light margin-top--smaller">
                  The Chronic Conditions VIP List is a table of patients created by {{app.CompanyName}}'s
                  data experts specifically for you! This file is a workable list of
                  patients whom you can contact to achieve the greatest difference in
                  your overall Chronic Conditions gap rate and Risk Adjustment Factor.
                </p>
                <div class="margin-top--large">
                  <ul class="margin-bottom--small">
                    <li>
                      <a href="{{app.ApplicationName}} Chronic Conditions VIP List"
                        (click)="goToItems($event, '/care/patients', { filterPreset: 'hcc-vip-cohort' } )">{{app.ApplicationName}} Chronic Conditions VIP List</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>



      <!--Widget 7

      -->

      <!--Widget 8-->
      <!-- REMOVED https://caravanhealth.atlassian.net/browse/CC-3528 -->
      <!-- <div class="widget" ngxMasonryItem>
        <div class="header header--slanted">
          <div class="titles t5">
            <div class="title">Getting started with Coach</div>
          </div>
        </div>
        <div class="dynamicBody padding--large">
          <p class="u-italic u-opacity--light margin-top--smaller">
            Welcome aboard! To ensure you're getting the most out of Caravan
            Coach, we've pulled together a few resources to help you get
            started.
          </p>
          <div class="margin-top--large">
            <div class="label">Useful Links:</div>
            <ul class="margin-bottom--small">
              <li>
                <a
                  href="Navigating Caravan Coach"
                  (click)="
                    goToItems($event, '/improve/information-technology', {
                      folder: 4152,
                      file: 4156
                    })
                  "
                  >Navigating Caravan Coach</a
                >
              </li>
              <li>
                <a
                  href="Coach User Guide"
                  (click)="
                    goToItems($event, '/improve/information-technology', {
                      folder: 3582,
                      file: 4151
                    })
                  "
                  >Coach User Guide</a
                >
              </li>
              <li>
                <a
                  href="https://caravanhealth.com/educational-events/"
                  target="_blank"
                  >Coach Training
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </ngx-masonry>
  </ng-container>
</div>
