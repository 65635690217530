import { HttpClient, HttpParams } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { AzureApiBase } from '@shared/services/azure-api/azure-api-base';
import { environment } from 'src/environments/environment';
import { HccFacesheetSchedule } from './hcc-facesheet-schedule';
import { HccFacesheetScheduleItem } from './hcc-facesheet-schedule-item';
import { Observable, Subject } from 'rxjs';
import { DateValidators } from '@shared/utilities';
import { formatDate } from '@angular/common';
import { FacesheetExportDownloadRequest } from './facesheet-download-request';
import { FacesheetExportBatch } from './facesheet-schedule/facesheet-export-batch';
import { Process } from '@shared/services/processing/process';
import { ProcessingService } from '@shared/services/processing/processing.service';

@Injectable({
  providedIn: 'root'
})
export class HccFacesheetScheduleService extends AzureApiBase<HccFacesheetSchedule> {
  constructor(http: HttpClient,
              authService: AuthService,
              @Inject(LOCALE_ID) private locale: string,
              private processingService: ProcessingService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    super(http, authService, environment.trackApiUrl, '/facesheetSchedule');

    const today = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    this.fromDate = new UntypedFormControl(today, [Validators.required]);
    this.toDate = new UntypedFormControl(today, [Validators.required, DateValidators.dateGreaterThanOrEqual(this.fromDate)]);
  }

  fromDate: UntypedFormControl;
  toDate: UntypedFormControl;
  loadSchedules$: Subject<void> = new Subject<void>();

  getSchedules(from: string, to: string, communityId: string, practiceId: string, providerId: string,tier3Id: string): Observable<HccFacesheetSchedule[]> {
    let params: HttpParams = new HttpParams()
      .set('from', from)
      .set('to', to)
      .set('communityId', communityId);

    if (practiceId != null) {
      params = params.append('practiceId', practiceId);
    }

    if (providerId != null) {
      params = params.append('providerId', providerId);
    }

    if (tier3Id != null) {
      params = params.append('tier3Id', tier3Id);
    }

    return this.getAll(null, params);
  }

  printPdf(items: HccFacesheetScheduleItem[]): Observable<ArrayBuffer> {
    return this.postPdf(items, '/pdf');
  }

  exportPdfs(items: HccFacesheetScheduleItem[]): Observable<ArrayBuffer> {
    return this.postZip(items, '/export');
  }

  downloadExport(request: FacesheetExportDownloadRequest): Observable<ArrayBuffer> {
    return this.postZip(request, '/downloadExport');
  }

  /** Export or Print multiple patient facesheets
   * @param {boolean} exportToZip Set to true for exporting individual PDFs that will be zipped together, otherwise a single PDF will be generated
   * @param {HccFacesheetScheduleItem[]} selectedPatients List of patients to export/print
   * @param {number} componentId Component from which the export is initiated (for logging purposes)
   * @param {string} fileNameIdentifier String to be appended to generated file name
   * @param {number} batchStageId Set batch stage ID if this export was previously staged (currently only applies to facesheet by schedule)
   */
  bulkExportPrint(exportToZip: boolean, selectedPatients: HccFacesheetScheduleItem[], componentId: number, fileNameIdentifier: string, batchStageId: number = null, expanded: boolean, tierNum: number, tierId: string): void {
    const facesheetExport: FacesheetExportBatch = {
      exportToZip: exportToZip,
      facesheetScheduleItems: selectedPatients,
      fileNameIdentifier: fileNameIdentifier,
      componentId: componentId,
      batchStageId: batchStageId,
      expanded: expanded,
      tierNum: tierNum,
      tierId: tierId
    };

    import('./facesheet-export/facesheet-export.component').then(c =>{
      const process: Process<any> = {
        componentFactory: this.componentFactoryResolver.resolveComponentFactory(c.FacesheetExportComponent),
        message: JSON.stringify(facesheetExport),
        queue: 'facesheet-export',
        status: {
          completionPercentage: 0,
          details: '',
          isComplete: false,
          isFailed: false,
          isCancelled: false
        },
        title: `Facesheet`,
      }; 
  
      this.processingService.addNewProcess(process);
    });      
  }
}
