import { Component, Inject, InjectionToken, Output, OnInit,  EventEmitter } from '@angular/core';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { PatientEngagementComponentIds } from '@shared/enums';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Patient } from '@shared/models/patient';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { PatientService } from '@shared/services/patient/patient.service';
import { takeUntil } from 'rxjs/operators';

export interface NavigationPortalData {
  emitter: any;
  patient: Patient;
}
export const NAVIGATION_PORTAL_DATA = new InjectionToken<NavigationPortalData>('NAVIGATION_PORTAL_DATA');
@Component({
  selector: 'patient-action-dropdown',
  templateUrl: './patient-action-dropdown.component.html',
  styleUrls: ['./patient-action-dropdown.component.scss'],
  host : {
    class: 'scopeSelectDropdownWrapper'
  }
})
export class PatientActionDropdownComponent extends KillSubscriptions implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  private emitter: any;
  patient: Patient;
  modalTest: boolean = false
  counter: number = 0;
  loadingPdf:boolean = false;
  /** Wellpepper org id */
  orgId: string;
  tier: IHierarchyTier;
  PatientEngagementComponentIds=PatientEngagementComponentIds;
  detailedPdf: boolean = false;

  constructor(
    @Inject(NAVIGATION_PORTAL_DATA) data: NavigationPortalData,
    private patientService : PatientService,
    private hierarchyTierService: HierarchyTierService
  ) {
    super();
    this.emitter = data.emitter;
    this.patient = data.patient;
    this.hierarchyTierService.currentTier$.pipe(takeUntil(this.killTrigger)).subscribe(async (tier) => {
      this.tier = tier;
      if (tier?.selectedTierId) {
        this.orgId = await(this.patientService.getWpOrgIdFromTierId(tier.selectedTierId));
      } else {
        this.orgId = null;
      }
    });
  }

  ngOnInit(): void {
  }
  emitAction(action_target: string): void{
    this.emitter(action_target)
  }
  get isPatientInvited():boolean {
    return this?.patient?.carePatient?.accountType === 'patient';
  }
  printPdfClick() {
    this.openTrackPatientFacesheetPdf(this.patient.trackPatient?.chPatId)
  }
  public openTrackPatientFacesheetPdf(CHPatID:string){
    {
      this.loadingPdf = true;
      this.patientService.postTrackPatientFacesheetPdf(CHPatID, this.tier.selectedTierId, this.tier.selectedItem.tier, this.detailedPdf).subscribe(res => {
        console.log(res);
        const url = window.URL.createObjectURL(res);
        window.open(url,'_blank');
      }, error => {
        // using tap to log it in the service
        //console.error(error);
        //this.ToastrMessageService.error(error);
      }, ()=>{
        this.loadingPdf = false;
      })
    }

  }
}
