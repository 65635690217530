import { Component } from '@angular/core';
import { SummaryTile, SUMMARY_TILES, SummaryTileOrder } from '@models/summary';

@Component({
  selector: 'coach-care-summary-tab',
  templateUrl: './care-summary-tab.component.html',
  host: {
    class: 'page-content'
  }
})
export class CareSummaryTabComponent {
  summaryTiles: SummaryTile[] = SUMMARY_TILES.sort(
    (s1, s2) => SummaryTileOrder.indexOf(s1.title) - SummaryTileOrder.indexOf(s2.title)
  );
}
