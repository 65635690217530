import { Component, OnInit, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { GridFilterService, PATIENT_DETAIL_REPORT_GRID_KEY } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { TrackFeatureConstants } from '@shared/models/module-constants';
import { combineLatest } from 'rxjs';
import { HccDashboardFilterService } from '@track/track-clinical/hcc-dashboard/hcc-dashboard-filter.service';
import { HccDashboardService } from '@api/track/services';
import { ATTRIBUTED, MOST_ASSIGNABLE } from '@track/track-constants';
import { HccDashboardPatientDetailV2 } from '@api/track/models';

@Component({
  selector: 'coach-patient-detail-report2',
  templateUrl: './patient-detail-report2.component.html',
  host: {
    class: 'coach-patient-detail-report2'
  },
  providers: [MaskPipe]
})
export class PatientDetailReport2Component extends PersistentGridFilters implements OnInit, OnDestroy {
  columnDefs = [
    {
      headerName: 'MBI', field: 'current_MBI', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Medicare Beneficiary Identifier',
      valueFormatter: params => {
        const patient: HccDashboardPatientDetailV2 = params.data;

        if (patient == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(patient.current_MBI, 6);
        }
        else {
          return patient.current_MBI;
        }
      }, keyCreator: params => {
        if (environment.maskSensitiveData) {
          return this.mask.transform(params.value, 6);
        }
        else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Patient Name', field: 'patientName', sortable: true, filter: true, tooltipField: 'patientName', resizable: true,
      cellRendererFramework: RouterLinkRendererComponent,
      cellRendererParams: {
        linkClicked: (data: HccDashboardPatientDetailV2) => {
          const path = '../../../care/patient';

          this.router.navigate([path, data.chPatID], { relativeTo: this.route.parent });
        }
      },
      headerTooltip: 'Patient Name',
    },
    {
      headerName: 'Date of Birth', field: 'dob', sortable: true, filter: true, width: 175, resizable: true,
      valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale),
      headerTooltip: 'Patient Date of Birth'
    },
    {
      headerName: 'Attributed Provider', field: 'providerName', sortable: true, filter: true, tooltipField: 'providerName', resizable: true,
      headerTooltip: 'Displays the name of the clinician that the beneficiary is attributed/assignable to. For RHC/FQHCs, the name of the practice will be displayed in this column.'
    },
    {
      headerName: 'Clinician NPI', field: 'tierID', sortable: true, filter: !environment.maskSensitiveData, width: 175, resizable: true,
      valueFormatter: params => {
        const patient: HccDashboardPatientDetailV2 = params.data;

        if (patient == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(patient.tierID.toString(), 6);
        }
        else {
          return patient.tierID;
        }
      },
      headerTooltip: 'Clinician\'s National Provider Identifier'
    },
    {
      headerName: 'Clinician Specialty', field: 'provSplty', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Clinician\'s specialty designation from claims'
    },
    {
      headerName: 'Chronic Condition Description', field: 'hccDesc', sortable: true, filter: true, resizable: true, width: 300,
      headerTooltip: 'Chronic condition label from CMS'
    },
    {
      headerName: 'Disease Group', field: 'groupDescription', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Disease group label from CMS'
    },
    {
      headerName: 'Diagnosis Code & Description', field: 'dxDesc', sortable: true, filter: true, resizable: true, width: 300,
      tooltipField: 'dxDesc',
      valueFormatter: params => `${params.data.dx} - ${params.data.dxDesc}`,
      headerTooltip: 'Diagnosis code and description from the claim that created the chronic condition gap'
    },
    {
      headerName: 'Date of Service', field: 'dos', sortable: true, filter: true, width: 175, resizable: true,
      valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale),
      headerTooltip: 'Displays the date of service on the claim that created the chronic condition gap'
    },
    {
      headerName: 'Coded by Provider', field: 'claimProviderName', sortable: true, filter: true,
      tooltipField: 'claimProviderName', resizable: true,
      headerTooltip: 'Displays the name of the provider on the claim that created the chronic condition gap'
    },
    {
      headerName: 'Chronic Condition Weight Closed YTD', field: 'raF_YTD', sortable: true, filter: true, resizable: true, width: 300,
      headerTooltip: 'Displays beneficiary\'s closed chronic conditions for the current calendar year.'
    },
    {
      headerName: 'Attribution Status', field: 'attributionStatus', sortable: true, filter: true, resizable: true,
      headerTooltip: 'Displays the patients assignment status'
    },
    {
      headerName: 'Last Claims AWV/IPPE Date', field: 'lastAWVDate', sortable: true, filter: true, resizable: true, valueFormatter: params => params.value ? formatDate(params.value, 'MM/dd/yyyy', this.locale) : '',
      headerTooltip: 'Displays the date of service for the last AWV or IPPE based on CMS Claims Data.'
    },
    {
      headerName: 'Last Claims AWV/IPPE Provider', field: 'lastAWVProvider', sortable: true, filter: true, resizable: true, width: 300,
      headerTooltip: 'Displays the name of the provider who performed the last AWV or IPPE based on CMS Claims Data.'
    },
  ];

  rowData: any;

  private clinician: string;
  private hccFilter: string;
  public isPracticeFilter: boolean;
  private diseaseGroupFilter: string;

  
  public hccEnhanced2FeatureFlag = TrackFeatureConstants.HccDashboardEnhanced2FeatureFlag;

  constructor(private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string, private router: Router, private hccDashboardFilterService: HccDashboardFilterService,
    protected filterService: GridFilterService, private mask: MaskPipe, private hccDashboardService: HccDashboardService,
    private hierarchyTierService: HierarchyTierService) {

    super(filterService, PATIENT_DETAIL_REPORT_GRID_KEY);
  }

  ngOnInit(): void {
    this.rowData =
      combineLatest([this.hierarchyTierService.currentTier$, this.hccDashboardFilterService.filterModel$]).pipe(
        takeUntil(this.killTrigger),
        filter(x => x[0] != null),
        switchMap(x => {
          const [tierInfo, filterValue] = x;
          const tierNum = filterValue?.isCinSelected ? -1 : tierInfo.tier;
          const tierId = filterValue?.isCinSelected ? filterValue.selectedCin?.id.toString() : tierInfo.selectedTierId;

          this.clinician = this.route.snapshot.params.clinician;
          this.hccFilter = this.route.snapshot.queryParams.hcc;
          this.isPracticeFilter = this.route.snapshot.queryParams.isPractice;
          this.diseaseGroupFilter = this.route.snapshot.queryParams.diseaseGroup;

          return this.hccDashboardService.hccDashboardPatientDetailsV2Get(
            {
              tierNum: tierNum,
              tierId: tierId,
              tier1Id: tierInfo.selectedItem.tier1_ID,
              attributionStatus: filterValue == null || !filterValue.isMostAssignable ? ATTRIBUTED : MOST_ASSIGNABLE
            }
          );
        }));
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const sort = [
      {
        colId: 'gapCount',
        sort: 'desc'
      },
      {
        colId: 'patientName',
        sort: 'asc'
      },
      {
        colId: 'dob',
        sort: 'asc'
      }
    ];
    this.gridApi.setSortModel(sort);
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    } else if (this.clinician != null) {
      const filterInstance = this.isPracticeFilter ? this.gridApi.getFilterInstance('providerName') : this.gridApi.getFilterInstance('tierID');

      filterInstance.setModel({
        type: 'set',
        values: [this.clinician]
      });

      this.gridApi.onFilterChanged();
    } else if (this.hccFilter != null) {
      const filterInstance = this.gridApi.getFilterInstance('hcc');

      filterInstance.setModel({
        type: 'set',
        values: [this.hccFilter]
      });

      this.gridApi.onFilterChanged();
    } else if (this.diseaseGroupFilter != null) {
      const filterInstance = this.gridApi.getFilterInstance('groupDescription');

      filterInstance.setModel({
        type: 'set',
        values: [this.diseaseGroupFilter]
      });

      this.gridApi.onFilterChanged();
    }
  }
}
