<div class="queue claim-validation border-left u-box-shadow--2"
  [ngClass]="{ 'is-validation-closed ': !isValidationPanelOpen }" #mainPanel>
  <coach-request-note [selectedPatientInfo]="selectedPatientInfo" [showModal]="toggleVisitNoteModal"
    [visitNoteRequestedDate]="prescriptionDetails.visit_Note_Requested_Date" (modalToggled)="requestVisitNote()"
    (noteRequested)="onNoteRequested($event)"></coach-request-note>
  <coach-request-care-coordination-note [selectedPatientInfo]="selectedPatientInfo"
    [prescriptionInfo]="prescriptionDetails" [showModal]="toggleCareCoordinationNoteModal"
    (modalToggled)="requestCareCoordinationNote()" (claimApproved)="removeClaimFromGrid($event)">
  </coach-request-care-coordination-note>
  <coach-rejected-claim [selectedPatientInfo]="selectedPatientInfo" [prescriptionInfo]="prescriptionDetails"
    [showModal]="toggleRejectionModal" (modalToggled)="toggleRejectModal()" (claimRejected)="onRejectClaim($event)">
  </coach-rejected-claim>
  <coach-claim-note [showModal]="showClaimNote" (modalToggled)="onClickClaimNote()"
    (saveClaimNote)="saveClaimNote($event)" [claimNote]="prescriptionDetails.claim_Note"
    [selectedPatientInfo]="selectedPatientInfo"></coach-claim-note>

  <button class="pushTab top--xs" style="color: teal; background-color: white; cursor: pointer"
    (click)="toggleValidatePanel()" [disabled]="!selectedPatientInfo" [ngClass]="{
      'is-validation-open': isValidationPanelOpen,
      'is-validation-disabled': !selectedPatientInfo
    }" aria-label="Toggle Validation Panel">
    <coach-icon iconName="chevron-left"></coach-icon>
  </button>

  <div class="patientContent">
    <coach-patient-header
      [selectedPatientInfo]="selectedPatientInfo"
      [prescriptionDetail]="prescriptionDetails"
      [route]="route"
      [claimStatus]="prescriptionDetails.claim_Status"
      [tier2Id]="tier2Id"
      [showPatientFaceSheet]="true"
    ></coach-patient-header>

    <div class="claimValidationPart" style="margin-top: -2rem">
      <div class="claimPanelLanding">
        <div style="font-weight: bold">
          Visit Note from {{ selectedPatientInfo.prescriberName }}
        </div>

        <div style="display: inline-flex; margin-top: 24px; white-space: nowrap">
          <p style="font-size: smaller">
            Visit Date:
          </p>
          <input max="2999-12-31" min="2000-01-01" type="date" [ngModel]="prescriptionDetails.visit_Note_Visit_Date"
            (ngModelChange)="updateVisitDate($event)" style="width: 50%; margin-left: 1.3rem; margin-top: -1.25rem"
            [ngStyle]="{ borderColor: !visitDateIsValid ? 'red' : 'inherit' }" />
          <div style="margin-top: -1rem; margin-left: 1rem">
            <input id="visitNoteMissing" type="checkbox" style="margin-right: 0"
              [ngModel]="prescriptionDetails.visit_Note_Missing" (ngModelChange)="updateVisitNoteMissing($event)" />
            <label for="visitNoteMissing" style="font-size: smaller; padding-left: 2.25rem; white-space: nowrap;">
              Unobtainable
            </label>
          </div>
        </div>
        <br />

        <p class="requestNote" style="font-size: xx-small; text-align: center; margin-top: -1.4rem"
          *ngIf="!visitDateIsValid">
          <span style="font-style: italic; color: red">Date must be prior to
            {{ formatDate(selectedPatientInfo.firstFilled) }}</span>
        </p>

        <div style="display: inline-flex">
          <p style="font-size: smaller; margin-right: -0.7rem">
            Diagnosis:
          </p>
          <input #diagnosis type="text" placeholder="Diagnosis..." style="margin-left: 2rem; margin-top: -1.25rem; width: 276px"
            [ngClass]="{'errorState': diagnosis.value.length == 0}" [(ngModel)]="prescriptionDetails.visit_Note_Diagnosis" (input)="onUpdateTypedText()" />
        </div>
        <br />

        <div
          class="requestNote"
          style="font-size: small; text-align: center; margin-top: -1rem; display: inline-block;"
        >
          <span
            style="font-style: italic; color: grey"
            *ngIf="!prescriptionDetails.visit_Note_Requested_Date"
            >Can't find the note in your EMR?</span
          >
          <span
            style="font-style: italic; color: grey;"
            *ngIf="prescriptionDetails.visit_Note_Requested_Date"
            >Note was requested on
            {{
              formatDate(prescriptionDetails.visit_Note_Requested_Date)
            }}</span
          >
          <a
            *ngIf="!prescriptionDetails.visit_Note_Requested_Date"
            (click)="requestVisitNote()"
            style="margin-left: 4px; cursor: pointer"
            >Request Note...</a
          >
          <a
            *ngIf="prescriptionDetails.visit_Note_Requested_Date"
            (click)="requestVisitNote()"
            style="margin-left: 4px; cursor: pointer"
            >Request again</a
          >
          <span style="color: gray;"> | </span>
          <coach-file-import [claimId]="claimPk" [prescriptionRefID]="prescriptionRefID" [tier2Id]="tier2Id"></coach-file-import>
        
        </div> 
        <br />

        <div style="font-weight: bold;">
          Related Referral by Covered Entity
        </div>

        <div style="display: inline-flex; margin-top: 24px">
          <p style="font-size: smaller">Refer Date:</p>
          <input max="2999-12-31" min="2000-01-01" type="date"
            style="width: 50%; margin-left: 1.4rem; margin-top: -1.25rem"
            [ngModel]="prescriptionDetails.cE_Related_Referral_Date" (ngModelChange)="updateReferralDate($event)"
            [ngStyle]="{
              borderColor: !ceReferralDateIsValid ? 'red' : 'black'
            }" />
          <div style="margin-top: -1rem; margin-left: 1rem">
            <input id="coveredEntityVisitNoteMissing" type="checkbox" style="margin-right: 0"
              [ngModel]="prescriptionDetails.cE_Related_Referral_Missing"
              (ngModelChange)="updateCeRelatedReferralMissing($event)" />
            <label for="coveredEntityVisitNoteMissing" style="
                font-size: smaller;
                padding-left: 2.25rem;
                white-space: nowrap;
              ">Missing</label>
          </div>
        </div>

        <p class="requestNote" style="font-size: xx-small; text-align: center" *ngIf="!ceReferralDateIsValid">
          <span style="font-style: italic; color: red">Date must be prior to
            {{ formatDate(selectedPatientInfo.firstFilled) }}</span>
        </p>

        <div style="font-weight: bold; margin-top: 1rem">
          Related Visit by Covered Entity
        </div>

        <div style="display: inline-flex; margin-top: 24px">
          <p style="font-size: smaller">Visit Date:</p>
          <input max="2999-12-31" min="2000-01-01" type="date" [ngModel]="prescriptionDetails.cE_Visit_Date"
            (ngModelChange)="updateCeVisitDate($event)" style="width: 50%; margin-left: 2rem; margin-top: -1.25rem"
            [ngStyle]="{ borderColor: !ceVisitDateIsValid ? 'red' : 'inherit' }" />
          <div style="margin-top: -1rem; margin-left: 1rem">
            <input id="referralDateMissing" type="checkbox" style="margin-right: 0"
              [ngModel]="prescriptionDetails.cE_Visit_Note_Missing"
              (ngModelChange)="updateCeVisitNoteMissing($event)" />
            <label for="referralDateMissing" style="
                font-size: smaller;
                padding-left: 2.25rem;
                white-space: nowrap;
              ">Missing</label>
          </div>
        </div>
        <br />

        <p class="requestNote" style="font-size: xx-small; text-align: center; margin-top: -1.5rem"
          *ngIf="!ceVisitDateIsValid">
          <span style="font-style: italic; color: red">Date must be prior to
            {{ formatDate(selectedPatientInfo.firstFilled) }}</span>
        </p>

        <div style="display: inline-flex">
          <p style="font-size: smaller; margin-right: 0.65rem">Provider:</p>
          <input #provider type="text" placeholder="Provider..." style="margin-left: 2rem; margin-top: -1.25rem; width: 70%"
          [ngClass]="{'errorState': provider.value.length == 0}" [(ngModel)]="prescriptionDetails.cE_Provider" (input)="onUpdateProvider()" />
        </div>

        <div style="display: inline-flex; margin-top: 2rem">
          <p style="font-size: smaller; margin-right: 0.65rem">CE Location:</p>
          <select #ceLocationsSelect placeholder="Select CE location..."
            style="margin-left: 0.15rem; margin-top: -1.25rem; width: 70%" (input)="updateCeLocationInFollowUpPlan()"
            [ngClass]="{'errorState': ceLocationsSelect.value == 'None'}">
            <option *ngFor="let location of ceLocations">
              {{ location.display }}
            </option>
          </select>
        </div>

        <div class="noteSection" style="margin-bottom: 64px">
          <div style="font-weight: bold">Follow-Up Plan</div>
          <div class="noteContent">
            <textarea #followUp style="height: 100px" [(ngModel)]="prescriptionDetails.follow_Up_Plan"
              (input)="onUpdateTypedText()"
              [ngClass]="{'errorState': followUp.value.length == 0}"
              ></textarea>
          </div>
        </div>

        <footer style="
            position: fixed;
            right: 0;
            bottom: 0;
            width: 427px;
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            border-top: 4px solid lightgray;
            padding-top: 8px;
            height: 64px;
          " [ngStyle]="{
            visibility: isValidationPanelOpen ? 'visible' : 'hidden'
          }">
          <coach-icon iconName="edit" style="
              margin-left: 16px;
              margin-bottom: 16px;
              margin-top: 12px;
              cursor: pointer;
            " (click)="onClickClaimNote()" [ngStyle]="{
              backgroundColor: prescriptionDetails.claim_Note
                ? '#31A7AD'
                : 'inherit'
            }"></coach-icon>
          <div style="
              font-size: smaller;
              margin-top: 0.5rem;
              margin-top: 16px;
              cursor: pointer;
            " (click)="onSaveAsPending(saveAsPending)" #saveAsPending>
            <div *ngIf="validateHasNote(); else savePending">
              Save as Has Note
            </div>
            <ng-template #savePending> Save as Pending </ng-template>
          </div>
          <button class="button btn-successis-small"
            style="font-size: smaller; margin-bottom: 1.5rem" (click)="toggleRejectionModal = !toggleRejectionModal">
            Reject...
          </button>
          <button [disabled]="!approveButtonEnabled" class="button btn-successis-small"
            style="font-size: smaller; margin-bottom: 1.5rem" (click)="
              toggleCareCoordinationNoteModal = !toggleCareCoordinationNoteModal
            " #approveButton>
            Approve...
          </button>
        </footer>
      </div>
    </div>
  </div>
</div>
