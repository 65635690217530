<div class="u-display--flex u-flex-direction--column u-justify-content--spaceBetween u-height--full" *ngIf="!loadingStatus && settings && settings.rows">
    <div class="list scroll-list-y">
        <ng-container *ngFor="let row of settings.rows; let r_idx = index">
            <div class="accordion list-item" [class.is-open]="row.isOpen" *ngIf="(row.type == 'set' && row.options.length > 0) || row.type != 'set'">
                <div class="row u-center u-justify-content--spaceBetween"
                    [class.u-color--brand--3]="!isRowValid(row)">
                    <div class="field header border--none cursor-default u-flex-direction--column row-title">
                        {{row.title}}
                        <div class="selection-list">
                            <ng-container *ngIf="row.includeAllOrNoneSelection">
                                <small class="selection-element u-color--highlight">
                                    {{doesExcludedContainValue(r_idx, 'Yes')?'No':'Yes'}}
                                </small>
                            </ng-container>

                            <ng-container *ngIf="!row.includeAllOrNoneSelection && row.type == 'set'">
                                <small class="selection-element u-color--highlight"
                                    *ngFor="let selected of checkedOptions[r_idx]; let s_idx = index">
                                    {{selected}}
                                    <span *ngIf="s_idx + 1 < checkedOptions[r_idx].length">,</span>
                                </small>
                            </ng-container>
                            <ng-container *ngIf="!row.includeAllOrNoneSelection && row.type !== 'set'">
                                <small class="selection-element u-color--highlight">
                                    {{getRowValueLabelText(row)}}
                                </small>
                            </ng-container>

                        </div>
                    </div>
                    <coach-icon 
                        *ngIf="displayCheckAllOptionsButton(row)"
                        iconName="x-circle" 
                        (click)="checkAllOptions(r_idx)"
                        iconColor="currentColor">
                    </coach-icon>
                    <coach-icon iconName={{getChevronStatus(r_idx)}} (click)="openAccordion(r_idx)"
                        iconColor="currentColor">
                    </coach-icon>
                </div>
                <div class="content content-planRow shadowLess">
                    <!-- Regular selection -->
                    <ng-container *ngIf="!row.includeAllOrNoneSelection && !row.percentage">
                        <ng-container *ngIf="row.type == 'set'">
                            <div>
                                <input [checked]="row.excluded.length < 1" id="checkbox_{{r_idx}}_all"
                                    (change)="checkAllOptions(r_idx)" type="checkbox">
                                <label for="checkbox_{{r_idx}}_all">All</label>
                            </div>
                            <div *ngFor="let option of row.options; let p_idx = index">
                                <input [checked]="isOptionChecked(option, r_idx)" id="checkbox_{{r_idx}}_{{p_idx}}"
                                    value="{{option}}" (change)="checkOption(option, r_idx)" type="checkbox">
                                <label for="checkbox_{{r_idx}}_{{p_idx}}">{{option}}</label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="row.type != 'set'">
                            <div>
                                <ng-container *ngIf="row.filterType != 'date'">
                                    <label class="label" for="rowTypeSelector">Type</label>
                                    <select id="rowTypeSelector" name="rowTypeSelector" (ngModelChange)="updateFilterValue(row)" [(ngModel)]="row.type">
                                        <option [ngValue]="null" [selected]="!row.type || row.type == 'unset'">Unset</option>
                                        <option *ngFor="let option of row.filterOptions" [value]="option.value">{{option.label}}</option>
                                    </select>
                                </ng-container>
                                
                                <ng-container *ngIf="row.filterType == 'number'">
                                    <input *ngIf="row.type" class="margin-top--smaller" [class.u-color--brand--3]="(!row.filter && row.filter !== 0) || (row.type == 'inRange' && row.filter > row.filterTo)" id="filterNumberInput" [(ngModel)]="row.filter" type="number">
                                    <input *ngIf="row.type == 'inRange'" class="margin-top--smaller" [class.u-color--brand--3]="row.type == 'inRange' && row.filter > row.filterTo" id="filterNumberToInput" [(ngModel)]="row.filterTo" type="number">
                                </ng-container>
                                <!-- <ng-container *ngIf="row.filterType == 'date'&& row.type == 'inRange'" > -->
                                <ng-container *ngIf="row.filterType == 'date'" >
                                    <label class="label margin-top">From Date</label>
                                    <input class="margin-top--smaller" id="filteDateInput" [(ngModel)]="row.dateFrom" type="date">
                                    <label class="label margin-top">To Date</label>
                                    <input class="margin-top--smaller" id="filteDateToInput" [(ngModel)]="row.dateTo" type="date">
                                </ng-container>
                                <!-- <ng-container *ngIf="row.filterType == 'date'&& row.type != 'inRange'" >
                                  <label class="label margin-top">Date</label>
                                  <input *ngIf="row.type" class="margin-top--smaller" id="filteDateInput" [(ngModel)]="row.dateFrom" type="date">
                                  <input *ngIf="row.type == 'inRange'" class="margin-top--smaller" id="filteDateToInput" [(ngModel)]="row.dateTo" type="date">
                              </ng-container> -->
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- includeAllOrNoneSelection -->
                    <ng-container *ngIf="row.includeAllOrNoneSelection">
                        <div class="">
                            <input [checked]="!doesExcludedContainValue(r_idx, 'Yes')" id="checkbox_{{r_idx}}_yes"
                                (change)="checkIncludeAllOrNoneSelection(r_idx, true)" type="radio">
                            <label for="checkbox_{{r_idx}}_yes">Yes</label>
                            <input [checked]="doesExcludedContainValue(r_idx, 'Yes')" id="checkbox_{{r_idx}}_no"
                                (change)="checkIncludeAllOrNoneSelection(r_idx, false)" type="radio">
                            <label for="checkbox_{{r_idx}}_no">No</label>
                        </div>
                    </ng-container>

                    <!-- Percentage -->
                    <!-- TODO: make this part dynamic, currently it's kinda hardcoded -->
                    <ng-container *ngIf="row.percentage">
                        <div style="outline-width: 0;">
                            <input id="radio_button_ssDenum_15" type="radio" (change)="row.filter = $event.target.value" value="15" [checked]="row.filter == 15">
                            <label for="radio_button_ssDenum_15">
                                <p class="margin--none">Top 15% <span class="u-italic u-opacity--lightest">(these patients are included in the Shared Savings denominator)</span></p>
                            </label>
                            <input id="radio_button_ssDenum_range" type="radio" (change)="row.filter = $event.target.value" value="16" [checked]="row.filter >= 16">
                            <label for="radio_button_ssDenum_range">
                                Select Range
                            </label>
                            <ng-container  *ngIf="row.filter >= 16">
                                <div style="padding: 0px 22px;">                                    
                                    <div class="range" style="--step:10; --min:16; --max:100; width: -webkit-fill-available;">
                                        <input type="range" class="range_input" (ngModelChange)="preventValueToBeBelowMin(row, 16)" [(ngModel)]="row.filter" min="16" max="100" step="1"> 
                                    </div>
                                    <div style="width: -webkit-fill-available; display: flex;" class="padding-top--smallest">         
                                        <div class="range_number" [ngClass]="{'u-color--brand--1 u-bold': row.filter == 0}"></div>                          
                                        <div class="range_number" (click)="row.filter = 20" [ngClass]="{'u-color--brand--1 u-bold': row.filter == 20}">20</div>
                                        <div class="range_number" (click)="row.filter = 40" [ngClass]="{'u-color--brand--1 u-bold': row.filter == 40}">40</div>
                                        <div class="range_number" (click)="row.filter = 60" [ngClass]="{'u-color--brand--1 u-bold': row.filter == 60}">60</div>
                                        <div class="range_number" (click)="row.filter = 80" [ngClass]="{'u-color--brand--1 u-bold': row.filter == 80}">80</div>
                                        <div class="range_number" (click)="row.filter = 100" [ngClass]="{'u-color--brand--1 u-bold': row.filter == 100}">All</div>
                                    </div> 
                                </div>                             
                            </ng-container>                            
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="!isPatientListsUpdateFlagEnabled">
        <div class=" u-display--flex u-justify-content--spaceBetween margin-top--smaller">
            <button (click)="clearFilters()" class="button is-small is-primary is-borderless">Clear</button>
            <button *ngIf="origin" (click)="openDeleteFilterModal = true" class="button is-small is-primary is-borderless">Delete</button>
            <button [disabled]="!isFormValid()" (click)="openSaveFilterModal = true"
                class="button is-small">Save
                as...</button>

            <button [disabled]="!isFormValid() || filterNameIsAlreadyUsed || !filterNewName || !isFormChanged()" (click)="saveChanges()" class="button is-small is-primary is-borderless"> Save </button>
        </div>
    </ng-container>

    <ng-container *ngIf="isPatientListsUpdateFlagEnabled">
        <div *ngIf="!isNewFilter" class="u-display--flex u-justify-content--spaceBetween margin-left--smaller">
            <button (click)="clearFilters()" class="button is-small is-primary is-borderless">Clear</button>
            <button *ngIf="origin" (click)="openDeleteFilterModal = true" class="button is-small is-primary is-borderless">Delete</button>
            <button [disabled]="!isFormValid() || filterNameIsAlreadyUsed || !filterNewName || !isFormChanged()" (click)="saveChanges()" class="button is-small is-primary is-borderless"> Save </button>
        </div>


        <div *ngIf="isNewFilter" class="u-display--flex u-position--sticky margin-top--smaller u-justify-content--right">
            <!-- SAVE NEW FILTER -->
            <div class="u-display--flex u-align-items--center">
                <small class="u-color--brand--3 margin-right" *ngIf="!filterNewName || filterNameIsAlreadyUsed">
                    <span *ngIf="!filterNewName">Name is missing</span>
                    <span *ngIf="filterNameIsAlreadyUsed">This name is already in use</span>
                </small>
                <button [disabled]="!filterNewName || filterNameIsAlreadyUsed  || !isFormValid()" (click)="createNewFilter()" class="button is-primary is-small has-tooltip">
                    Save
                </button>
            </div>
        </div>
    </ng-container>


</div>


<div *ngIf="loadingStatus" class="u-display--flex u-justify-content--center">
    <coach-loading-indicator size="40"></coach-loading-indicator>
</div>

<!-- CREATE FILTER -->
<ng-container *ngIf="openSaveFilterModal">
    <div class="overlay is-open u-position--fixed">
        <div class="modal u-width--larger">
            <div class="header">
                <div class="titles">
                    <div class="title text-center">Save custom filter</div>
                </div>
            </div>
            <div class="dynamicBody padding">
                <input [(ngModel)]="newFilter" placeholder="Filter name" type="text">
            </div>
            <div class="footer u-display--flex u-align-items--center">
                <small class="u-color--brand--3 margin-right" *ngIf="filterNameIsAlreadyUsed">This name is already in
                    use</small>
                <button class="button margin-right border--none" (click)="openSaveFilterModal = false">Cancel</button>
                <button class="button is-primary" [disabled]="!newFilter || filterNameIsAlreadyUsed"
                    (click)="createNewFilter()">Save</button>
            </div>
        </div>
    </div>
</ng-container>

<!-- DELETE FILTER -->
<ng-container *ngIf="openDeleteFilterModal">
    <div class="overlay is-open u-position--fixed">
        <div class="modal u-width--larger">
            <div class="header">
                <div class="titles">
                    <div class="title text-center">Delete custom filter</div>
                </div>
            </div>
            <div class="dynamicBody padding">
                <p class="u-text-align--center">Are you sure you want to delete {{filterName}}?</p>
            </div>
            <div class="footer u-display--flex u-align-items--center">
                <button class="button margin-right border--none" (click)="openDeleteFilterModal = false">Cancel</button>
                <button class="button is-primary"
                    (click)="deleteCustomFilter()">Delete</button>
            </div>
        </div>
    </div>
</ng-container>
