import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocRepo } from '@shared/models/docRepo/list';

@Component({
  selector: 'coach-doc-repo-folder-nav',
  templateUrl: './doc-repo-folder-nav.component.html',
  styleUrls: ['./doc-repo-folder-nav.component.scss']
})
export class DocRepoFolderNavComponent {

  @Input() public folder: DocRepo;
  @Output() public drillOutClickEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public drillOutClicked(): void {
    this.drillOutClickEmitter.emit(this.folder);
  }
}
