import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  HostListener,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am5themes_animated from '@amcharts/amcharts5/themes/Animated';
import { PatientService } from '@shared/services/patient/patient.service';
import { Patient } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { faChartLine, faList } from '@fortawesome/free-solid-svg-icons';
import {
  Alert,
} from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/alert';
import { common as wp, wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import moment from 'moment';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { wpdb } from '@hcd-caravanhealth/pkg-wptypes';
import { ChangeDetectorRef } from '@angular/core';



export interface SMSCopdChartCompletion {
  date?: string;
  dateLabel?: string;
  msg?: string;
  time?: number;
  type?: string;
  Yes?: number;
  No?: number;
  Other?: number;
}

type SmsChartSettings = {
  chartRange: string;
  completions: any[] | null;
  chartType: string;
  fourWeeksReports?: any;
  sixMonthReports?: any;
  listToChartToggle?: any;
  scheduleSettings?: SMSScheduleSettings;
};

type SMSScheduleSettings = {
    startDate: string;
    endDate?: string;
    stoppedDate?: string;
    stoppedBy?: {
      firstName?: string;
      lastName?: string;
    }
    frequency: string;
    duration: wpapi.model.Duration;
    currentStep: number;
    totalSteps: number;
    phases: Array<wpapi.model.TwilioScheduleReportInterval>;
};

type CustomScheduleSettings = {
  startDate?: string;
  frequency?: string;
  stratedBy?: string;
  stoppedBy?: string;
  stoppedAt?: string;
};

type frequency = {
  Monthly: string;
  Weekdays: string;
  MWF: string;
  Weekly: string;
  Biweekly: string;
};


@Component({
  selector: 'coach-patient-summary',
  templateUrl: './patient-summary.component.html',
  styleUrls: ['./patient-summary.component.scss'],
})
export class PatientSummaryComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public patientCompletions: wpdb.model.Completion[];
  @Input() public patientTasks: wpdb.model.Task[];
  @Input() public tier: IHierarchyTier;
  showStandardSchedule = false;
  showCustomSchedule = false;
  schedulePhases: Array<wpapi.model.TwilioScheduleReportInterval> = []
  isMobile = true;
  faChartLine = faChartLine;
  faList = faList;
  bpChartSettings: any = {};
  sugarChartSettings: any = {};
  weightChartSettings: any = {};
  subType: string;
  enrolledPrograms: any = {
    hasCOPD: false,
    hasHF: false
  }
  programCompletions: any = {};
  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get patientId() {
    return this?.patient?.carePatient?._id;
  }
  mask = new MaskPipe();
  msgCategories: { [key: string]: { label: string, value: number } } = {
    OK: { label: "1", value: 1 },
    messageFromUser: { label: "1", value: 1 },
    trouble: { label: "2", value: -1 },
    createAlert: { label: "2", value: -1 }
  }
  smsChartSettingsCOPD: SmsChartSettings = {
    chartRange: "4w",
    completions: null,
    chartType: "sms"
  };

  smsChartSettingsHF: SmsChartSettings = {
    chartRange: "4w",
    completions: null,
    chartType: "sms"
  }
  isScheduleCustom: boolean = false;
  isScheduleAdaptive: boolean = false;
  customScheduleSettings: CustomScheduleSettings;
  Adaptivephases: Array<wpapi.model.TwilioScheduleReportInterval>;
  stoppedByUser: string;
  frequency: frequency = {
    Monthly: 'monthly',
    Weekdays: 'weekday',
    MWF: 'mwf',
    Weekly: 'weekly',
    Biweekly: 'biweekly'
  }
  isCustomScheduleActive: boolean;
  isScheduleStopped: boolean = false;
  scheduleProgram: string = "";


  constructor(
    private PatientService: PatientService,
    private auditService: AuditService,
    private cdr: ChangeDetectorRef
  ) {
    am4core.useTheme(am4themes_animated);
    let COPDTask = this.patient?.tasks?.find(task => task.type == "twilioStudioFlow" && task.subType == "COPD");
    if (COPDTask) {
      this.getReport(COPDTask, "COPD");
    }
    let HFTask = this.patient?.tasks?.find(task => task.type == "twilioStudioFlow" && task.subType === "Heart Failure");
    if (HFTask) {
      this.getReport(HFTask, "HF");
    }
  }
  ngOnInit() {
    this.isMobile = window.innerWidth < 400;
  }


  ngAfterViewInit(){
    this.buildCharts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.patientId || this.patientCompletions || this.patientTasks) {
      if (!this.bpChartSettings.reportsData) {
        this.renderChart(
          this.patientId,
          'bpChartSettings',
          'bloodPressure',
          'bpChart',
          'Blood Pressure',
          [
            {
              label: 'systolic',
              rangeLabel: 'systolic',
              title: 'Systolic',
              color: 'rgba(50, 157, 189, 0.5)',
            },
            {
              label: 'diastolic',
              rangeLabel: 'diastolic',
              title: 'Diastolic',
              color: 'rgba(243, 140, 32, 0.6)',
            },
          ],
          true
        );
      }
      if (!this.sugarChartSettings.reportsData) {
        this.renderChart(
          this.patientId,
          'sugarChartSettings',
          'bloodSugar',
          'sugarChart',
          'Blood Sugar',
          [
            {
              label: 'bloodSugar',
              rangeLabel: 'bloodSugar',
              title: 'Sugar',
              color: 'rgba(50, 157, 189, 0.5)',
            },
          ],
          true
        );
      }
      if (!this.bpChartSettings.reportsData) {
        this.renderChart(
          this.patientId,
          'weightChartSettings',
          'weight',
          'weightChart',
          'Weight',
          [
            {
              label: 'weight',
              rangeLabel: 'weight',
              title: 'Weight',
              color: 'rgba(50, 157, 189, 0.5)',
            },
          ],
          true
        );
      }

      let twilioStudioTasks = this.patient.tasks?.filter(task => task.type == "twilioStudioFlow");
      let enrolledProgramsList = twilioStudioTasks.map(task => task.subType);

      enrolledProgramsList.forEach(subType => {
        if (subType === "COPD") {
          this.enrolledPrograms.hasCOPD = true;
        }
        else if (subType === "Heart Failure") {
          this.enrolledPrograms.hasHF = true;
        }
      })
      this.cdr.detectChanges();
      this.buildCharts();


      this.auditService.auditLog(
        'Patient Summary',
        ComponentId.Dashboard,
        SubComponentId.PatientSummary,
        ActionId.PHIAccess,
        this.patient.chPatId,
        this.tier,
        null
      );
    }
  }

  async buildCharts(){
    if (this.patient.completions && (!(this.smsChartSettingsCOPD.completions?.length) || !(this.smsChartSettingsHF.completions?.length)) && this.patient.tasks?.find(task => task.type == "twilioStudioFlow")) {
      if (this.enrolledPrograms.hasCOPD) {
        let programTask = this.patient.tasks?.find(task => task.type == "twilioStudioFlow" && task.subType == "COPD");
        let report = this.getReport(programTask, "COPD");
        this.programCompletions = this.patient.completions?.filter(completions => completions.taskId == programTask._id);
        this.smsChartSettingsCOPD.completions = this.prepareCompletionsIntoChartColumns(this.programCompletions);
        this.create4WeeksSMSCOPDChart("smsChart4weeksCOPD", this.smsChartSettingsCOPD.completions.filter(r => moment(r.date).isAfter(moment().subtract(4, "week"))), this.smsChartSettingsCOPD);
        this.create6MonthSMSCOPDChart("smsChart6monthCOPD", this.smsChartSettingsCOPD.completions.filter(r => moment(r.date).isAfter(moment().subtract(6, "month"))), this.smsChartSettingsCOPD);
      }
      if (this.enrolledPrograms.hasHF) {
        let programTask = this.patient.tasks?.find(task => task.type == "twilioStudioFlow" && task.subType == "Heart Failure");
        this.programCompletions = this.patient.completions?.filter(completions => completions.taskId == programTask._id);
        this.smsChartSettingsHF.completions = this.prepareCompletionsIntoChartColumns(this.programCompletions);
        let report = this.getReport(programTask, "HF");
        this.create4WeeksSMSCOPDChart("smsChart4weeksHF", this.smsChartSettingsHF.completions.filter(r => moment(r.date).isAfter(moment().subtract(4, "week"))), this.smsChartSettingsHF);
        this.create6MonthSMSCOPDChart("smsChart6monthHF", this.smsChartSettingsHF.completions.filter(r => moment(r.date).isAfter(moment().subtract(6, "month"))), this.smsChartSettingsHF);
      }

    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 400;
  }

  prepareCompletionsIntoChartColumns(completions: wpdb.model.TwilioCompletion[]): SMSCopdChartCompletion[] {
    let result: SMSCopdChartCompletion[] = [];
    completions.forEach(completion => {
      // this.subType = this.patient.tasks?.find(task => (task.type == "twilioStudioFlow" && (task._id == new ObjectId(completion.taskId)))).subType;
      if (!!(completion.event?.msg) && completion.taskType == "twilioStudioFlow") {
        try {
          let SMSCopdChartCompletion: SMSCopdChartCompletion = {
            date: completion.event.time,
            dateLabel: moment(completion.event.time).format('MMMM Do YYYY, h:mm:ss a'),
            msg: completion.event.msg,
            time: moment(completion.event.time).toDate().getTime()
          }
          let msgCategory = completion.msgCategory || completion.event?.event;
          if (this.msgCategories[msgCategory]) {
            SMSCopdChartCompletion[this.msgCategories[msgCategory]?.label] = this.msgCategories[msgCategory]?.value;
          } else {
            SMSCopdChartCompletion.Other = -1;
          }
          SMSCopdChartCompletion.type = this.msgCategories[msgCategory]?.label || 'Other';
          result.push(SMSCopdChartCompletion);
        } catch (error) {
          console.error(error)
        }
      }
    })
    return result.sort((a, b) => {
      return moment(a.date).isAfter(moment(b.date)) ? 1 : moment(a.date).isBefore(moment(b.date)) ? -1 : 0
    });
  }
  // =========================================== CHART LOGIC ===========================================
  // Documentation read here => https://www.amcharts.com/docs/v4/

  async renderChart(
    patientId: string,
    chartObjectName: string,
    chartType: string,
    chartId: string,
    chartLabel: string,
    labels: any[],
    includeGoals?: boolean
  ) {

    let reportsData = this[chartObjectName].completions || await this.PatientService.getUserChartReports(patientId, chartType, null, null);
    if (!reportsData || reportsData.length < 1) {
      return;
    }

    reportsData.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    this[chartObjectName] = {
      days: 'all',
      listToChartToggle: false,
      chartDateReserve: [],
      reportsData: reportsData,
      dataExists: true,
      chart: this.createChart(chartId),
      chartLabel: chartLabel,
      chartType: chartType,
      tableDisplayDays: 'all',
    };
    let chartObject = this[chartObjectName];
    this.setupChartSettings(chartObject.chart);
    chartObject.chart.data = this.convertReportsData(reportsData, labels);



    let reports = chartObject.chart.data;

    let lastReport = reports[reports.length - 1];
    if (includeGoals) {
      let firstReportCreatedAt = reports[0]?.createdAt
        ? moment(reports[0]?.createdAt)
          .subtract(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss')
        : null;

      // SET 1st and last GOALS
      if (reports?.filter((data) => data.goal).length > 0) {
        // SET OPENING GOAL
        for (let report = 0; report < reports.length; report++) {
          if (
            reports[report].goal_effectiveOn &&
            moment(reports[report].goal_effectiveOn).isBefore(
              moment(firstReportCreatedAt)
            )
          ) {
            reports[report].goal_effectiveOn = firstReportCreatedAt;
          } else {
            break;
          }
        }
        // ADD CLOSING GOAL
        let closingGoal = {
          goal_effectiveOn: lastReport?.createdAt
            ? moment(lastReport.createdAt)
              .add(1, 'day')
              .format('YYYY-MM-DD HH:mm:ss')
            : null,
        };
        reports.push(closingGoal);
      }

    } else {

    }
    try {
      // CREATE CHART LINES
      labels.forEach((l) => {
        if (includeGoals) {
          this.createChartLines(l.label, l.title, l.color, chartObject);
          if (reports.filter((data) => data.goal).length > 0) {
            (reports[reports.length - 1][`goal_${l.rangeLabel}_bottom`] =
              lastReport[`goal_${l.rangeLabel}_bottom`]),
              (reports[reports.length - 1][`goal_${l.rangeLabel}_top`] =
                lastReport[`goal_${l.rangeLabel}_top`]);
            this.createBackgroundGoalLine(
              chartObject,
              `goal_${l.rangeLabel}_bottom`,
              `goal_${l.rangeLabel}_top`,
              0.3,
              l.color,
              l.color,
              `goal_effectiveOn`
            );
            this.createBackgroundGoalLine(
              chartObject,
              null,
              `goal_${l.rangeLabel}_bottom`,
              null,
              l.color,
              null,
              `goal_effectiveOn`
            );
          }
        } else {
          // this.createColumnLines(l.label, l.title, l.color, chartObject);
        }
      });
      chartObject.chart.legend = new am4charts.Legend();
      chartObject.chart.cursor = new am4charts.XYCursor();
      chartObject.chart.scrollbarX = new am4core.Scrollbar();
    } catch (error) {
      console.error(error)
    }
  }
  createChart(chartId: string): am4charts.Chart {
    let chart = am4core.create(chartId, am4charts.XYChart);
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm:ss';
    return chart;
  }
  create4WeeksSMSCOPDChart(chartId, reports, smsChartSettings): void {

    // Create Y-axis
    try {
      let root = am5.Root.new(chartId);
      root.setThemes([
        am5themes_animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomX"
      }));
      cursor.lineY.set("visible", false);

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      }));

      let yRenderer = am5xy.AxisRendererY.new(root, {})
      yRenderer.labels.template.set('visible', false); // removes labels https://stackoverflow.com/questions/71766069/amcharts-5-remove-y-axis

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        min: -3,
        max: 3,
      }));

      // SET UP LEGEND
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      // SET UP COLUMNS/SERIES
      const makeSeries = function(name, backgroundColor) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name,
          valueXField: "time",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{msg}\n{dateLabel}"
          }),
          fill: am5.color(backgroundColor)
        }));

        series.data.setAll(reports.filter(el => el.hasOwnProperty(name)));
        series.appear();
        series.columns.template.setAll({
          cornerRadiusTL: 5,
          cornerRadiusTR: 5,
          width: 15
        });
        // add series into legend
        legend.data.push(series);
      };

      // add series
      makeSeries("1", "rgba(154, 187, 190, 1)");
      makeSeries("2", "rgba(250, 86, 9, 1)");
      makeSeries("Other", "rgba(250, 212, 20, 1)");


      chart.appear(1000, 100);
      smsChartSettings.fourWeeksReports = reports
    } catch (error) {
      console.error(error)
    }

  }
  create6MonthSMSCOPDChart(chartId, reports, smsChartSettings): void {
    let dataByMonth = {};

    let data = [];
    reports.forEach(completion => {
      try {
        let completionMonth = moment(completion.date).startOf('month').valueOf();
        if (!dataByMonth[completionMonth]) {
          dataByMonth[completionMonth] = [];
        }
        dataByMonth[completionMonth].push(completion)
      } catch (error) {
        console.error(error)
      }
    })
    let sixMonthsAgo = moment().subtract(5, "month").startOf('month');
    while (sixMonthsAgo.isBefore(moment())) {
      let monthSum = {
        "1": 0,
        "2": 0,
        "Other": 0,
        "month": moment(Number(sixMonthsAgo)).format('MMMM')
      };
      if (dataByMonth[sixMonthsAgo.valueOf()]) {
        dataByMonth[sixMonthsAgo.valueOf()].forEach(completion => {
          monthSum[completion.type]++;
        });
      }
      data.push(monthSum);
      sixMonthsAgo.add(1, "month")
    }
    let root = am5.Root.new(chartId);
    root.numberFormatter.setAll({
      numberFormat: "#,###",
      numericFields: ["valueY"]
    })

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout
    }));

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "vertical",
      forceHidden: true
    }));



    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "month",
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {
        labelText: 'test'
      }),
    }));

    xAxis.data.setAll(data);
    // Y-Axis
    // let yRenderer = am5xy.AxisRendererY.new(root, { })
    // yRenderer.labels.template.set('visible', false); // removes labels https://stackoverflow.com/questions/71766069/amcharts-5-remove-y-axis
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, backgroundColor) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        // stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: name,
        categoryXField: "month",
        fill: am5.color(backgroundColor),
        sequencedInterpolation: true,
        marginLeft: -5,
        marginRight: -5,
      }));

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        tooltipY: am5.percent(10),
        width: am5.percent(90)
      });
      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      legend.data.push(series);
    }

    makeSeries("1", "rgba(154, 187, 190, 1)");
    makeSeries("2", "rgba(250, 86, 9, 1)");
    makeSeries("Other", "rgba(250, 212, 20, 1)");
    smsChartSettings.sixMonthReports = data
    return;
  }
  setupChartSettings(chart: any) {
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.baseInterval = {
      timeUnit: 'minute',
      count: 1,
    };
    dateAxis.tooltipDateFormat = 'HH:mm, d MMMM';
    chart.yAxes.push(new am4charts.ValueAxis());
  }
  createChartLines(
    field: string,
    name: string,
    color: string,
    targetChartObj: any
  ): void {
    let series = targetChartObj.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = 'date';
    series.name = name;
    series.tooltipText = `[b]{valueY}[/]`;
    series.strokeWidth = 8;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color(color);
    if (color) {
      series.stroke = am4core.color(color);
    }

    let circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.radius = 10;
    circleBullet.circle.fill = am4core.color(color);
    circleBullet.circle.fillOpacity = 0.5;
    circleBullet.circle.stroke = am4core.color(color);
    circleBullet.circle.strokeOpacity = 0.5;
  }
  createBackgroundGoalLine(
    targetChartObj: any,
    openValueY: string,
    valueY: string,
    fillOpacity: number,
    stroke: am4core.Color,
    fill: am4core.Color,
    effectiveOn?
  ): void {
    let backgroundGoalLine = targetChartObj.chart.series.push(
      new am4charts.LineSeries()
    );
    if (effectiveOn) backgroundGoalLine.dataFields.dateX = effectiveOn;
    //  || null;
    backgroundGoalLine.dataFields.valueY = valueY;
    backgroundGoalLine.sequencedInterpolation = true;
    if (openValueY) {
      backgroundGoalLine.dataFields.openValueY = openValueY;
    }
    if (fillOpacity) {
      backgroundGoalLine.fillOpacity = fillOpacity;
    }
    if (stroke) {
      backgroundGoalLine.stroke = stroke;
    }
    if (fill) {
      backgroundGoalLine.fill = fill;
    }
    backgroundGoalLine.tensionX = 0.8;
    backgroundGoalLine.hiddenInLegend = true;
    backgroundGoalLine.hideTooltip();
  }
  isDateInRange(date: any, range: any): boolean {
    if (!range || range == 'all') {
      return true;
    }
    let rangeDate = new Date();
    rangeDate.setDate(rangeDate.getDate() - range);
    return rangeDate.getTime() < new Date(date).getTime();
  }
  convertReportsData(dataList: Array<any>, labels: any[]): Array<any> {
    dataList.forEach((el) => {
      el.date = moment(el.recordedAt ? el.recordedAt : el.createdAt).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      labels.forEach((l) => {
        el[`goal_${l.label}_top`] =
          el.goal?.[l.label]?.rangeTop || el.goal?.[l.label]?.top || null;
        el[`goal_${l.label}_bottom`] =
          el.goal?.[l.label]?.rangeBottom || el.goal?.[l.label]?.bottom || null;
      });

      el.goal_effectiveOn = el.goal?.effectiveOn
        ? moment(el.goal.effectiveOn).format('YYYY-MM-DD HH:mm:ss')
        : null;
    });
    return dataList;
  }

  // =========================================== EXPORT FUNCTIONS ======================================

  export(action: 'csv' | 'copy', target: any): void {
    try {
      let filename: string = "";
      let extraColumns;
      if (target.chartType == 'bloodPressure') {
        extraColumns = `"BP", "Pulse",`;
      } else if (target.chartType == 'bloodSugar') {
        extraColumns = '"Sugar", "Last ate",';
      } else if (target.chartType == 'weight') {
        extraColumns = '"Weight,"';
      }
      let data = `"Patient Name: ${this.patient.lastName}, ${this.patient.firstName}"\n`;
      data += `"DOB: ${this.patient.dob}"\n`;
      data += `"MRN: ${this.patient.mrn ? this.mask.transform(this.patient.mrn, 6) : 'not found'}"\n`;
      let today: string = moment().format('l');
      let startDate: string = "";
      if (target.chartType !== 'sms') {
        startDate = `${target.tableDisplayDays !== 'all' ? `from ${moment(moment().subtract(target.tableDisplayDays, 'days')).format('l')} to ` : ''}`;
      } else {
        startDate = `from ${target.chartRange == '4w' ? `${moment().subtract(4, "weeks").format("l")}` : `${moment().subtract(5, "months").format("l")}`} to `;
      }
      filename = `Summary Report ${startDate}${today}`;
      data += `"${filename}"\n`;
      data += `"Date/Time",`;
      if (target.chartType == 'bloodPressure') {
        data += `"BP", "Pulse"`;
      } else if (target.chartType == 'bloodSugar') {
        data += '"Sugar", "Last ate"';
      } else if (target.chartType == 'weight') {
        data += '"Weight"';
      } else if (target.chartType == 'sms') {
        if (target.chartRange == '4w') {
          data += '"Message", Category';
        } else {
          data += 'Yes, No, Other';
        }
      }
      if (target.chartType !== 'sms') {
        data += `,"Comment", "Alert"`;
      }
      data += `\n`;
      (target.reportsData || (target.chartRange == '4w' ? target.fourWeeksReports : target.sixMonthReports))?.forEach((report) => {
        if (report._id && (target.tableDisplayDays == 'all' || moment(report.createdAt).isAfter(moment().subtract(target.tableDisplayDays, 'days')))) {
          let alertToText = '';
          this.connectAlertWithCompletion(report._id).forEach((alert, idx) => {
            alertToText += `${this.connectAlertWithCompletion(report._id).length > 1
              ? `${idx + 1}) `
              : ''
              }Desc: ${alert.desc}${alert.isActive
                ? ''
                : `; Closed by ${alert.inactiveByFullName || '[name not found]'
                } ${alert.inactiveAt
                  ? `at ${moment(alert.inactiveAt).format(
                    'MMM Do YYYY h:mm:ss a'
                  )}`
                  : ''
                }`
              }. `;
          });
          data += `"${moment(report.createdAt || report.date).format('lll')}",`;
          if (target.chartType == 'bloodPressure') {
            data += `${report.systolic}/${report.diastolic}, ${report.pulse},`;
          } else if (target.chartType == 'bloodSugar') {
            data += `${report.bloodSugar}, ${report.lastAte} ${report.lastAteUnits},`;
          } else if (target.chartType == 'weight') {
            data += `${report.weight},`;
          }
          data += `${report.comment || ''}, ${alertToText}\n`;
        } else if (target.chartType == 'sms') {
          if (target.chartRange == '4w') {
            data += `"${moment(report.date).format('lll')}", ${report.msg}, ${report.type}\n`;
          } else {
            data += `"${report.month}", ${report.yes}, ${report.no}, ${report.other}\n`;
          }
        }
      });
      if (action == 'copy') {
        let el = document.createElement('textarea');
        el.value = data;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      } else {
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${filename}.csv`;
        hiddenElement.click();
      }
    } catch (error) {
      console.error(error)
    }
  }

  // =========================================== HELPERS ===============================================
  connectAlertWithCompletion(completionId: string): Alert[] {
    return this.patient?.alerts
      ? this.patient.alerts.handled
        .filter((a) => a.relatedObjects[0]?.id == completionId)
        .concat(
          this.patient.alerts.unhandled.filter(
            (a) => a.relatedObjects[0]?.id == completionId
          )
        )
      : [];
  }

  toggleStandardSchedule() {
    if (this.isScheduleCustom) {
      this.showCustomSchedule = !this.showCustomSchedule;
    }
    else {
      this.showStandardSchedule = !this.showStandardSchedule;
    }
  };

  hideStandardSchedule(value: boolean) {
    this.showStandardSchedule = false;
    if (value) {
      this.showCustomSchedule = true;
    }
    else{
    this.cdr.detectChanges();
    this.buildCharts();
    }
  }

  hideCustomSchedule(value: boolean) {
    this.showCustomSchedule = false;
    if (value) {
      this.showCustomSchedule = true;
    }
  }

  async getReport(twilioTask, program) {
    this.scheduleProgram = program;
    let report = this.PatientService.getSMSScheduleReport(this.patientId, twilioTask._id);
    this.isScheduleCustom = twilioTask?.schedule?.isCustom === true || twilioTask?.scheduleRevisions && !twilioTask?.schedule;
    this.isScheduleStopped = twilioTask?.scheduleRevisions && !twilioTask?.schedule
    this.isScheduleAdaptive = twilioTask?.schedule?.isAdaptive === true;
    report.then((res) => {
      if (this.isScheduleAdaptive) {
        let currentReport = res.report.find((report) => report.status === 'Current') as wpapi.model.TwilioAdaptiveScheduleReport
        let stoppedReport = res.report.find((report) => report.status === 'Stopped') as wpapi.model.TwilioAdaptiveScheduleReport
        if (currentReport) {
          let currentPhase = currentReport.phases.find((phase) => phase.status === 'Current')
          if (currentPhase) {
            let scheduleSettings: SMSScheduleSettings = {
              startDate: new Date(currentPhase.startDate.year, currentPhase.startDate.month - 1, currentPhase.startDate.date).toLocaleDateString('en-US'),
              endDate: new Date(currentPhase.finishDate.year, currentPhase.finishDate.month - 1, currentPhase.finishDate.date).toLocaleDateString('en-US'),
              frequency: currentPhase.frequencyDescription,
              duration: currentPhase.duration,
              currentStep: currentReport.phases.indexOf(currentPhase) + 1,
              totalSteps: currentReport.phases.length,
              phases: currentReport.phases
            };
            this.schedulePhases = scheduleSettings.phases;
            if (program === "COPD") {
              this.smsChartSettingsCOPD.scheduleSettings = scheduleSettings;
            }
            else if (program == "HF") {
              this.smsChartSettingsHF.scheduleSettings = scheduleSettings;
            }

            this.Adaptivephases = currentReport.phases;
          }
        } else if(stoppedReport) {
          let stoppedPhase = stoppedReport.phases.find((phase) => phase.status == 'Stopped')
          if (stoppedPhase) {
            let scheduleSettings: SMSScheduleSettings = {
              startDate: new Date(stoppedPhase.startDate.year, stoppedPhase.startDate.month - 1, stoppedPhase.startDate.date).toLocaleDateString('en-US'),
              endDate: new Date(stoppedPhase.finishDate.year, stoppedPhase.finishDate.month - 1, stoppedPhase.finishDate.date).toLocaleDateString('en-US'),
              frequency: stoppedPhase.frequencyDescription,
              duration: stoppedPhase.duration,
              currentStep: stoppedReport.phases.indexOf(stoppedPhase) + 1,
              totalSteps: stoppedReport.phases.length,
              phases: stoppedReport.phases,
              stoppedBy: stoppedPhase?.stoppedBy,
              stoppedDate: new Date(stoppedPhase.stopDate.year, stoppedPhase.stopDate.month - 1, stoppedPhase.stopDate.date).toLocaleDateString('en-US'),
            };

            this.schedulePhases = scheduleSettings.phases;
            if (program === "COPD"){
              this.smsChartSettingsCOPD.scheduleSettings = scheduleSettings;
            }
            else if (program == "HF"){
              this.smsChartSettingsHF.scheduleSettings = scheduleSettings;
            }
          }
        }
      }
      else if (this.isScheduleCustom && !this.isScheduleStopped) {
        this.getcustomScheduleSettings(res, true);
      }
      else if ((!this.isScheduleAdaptive && !this.isScheduleCustom) || this.isScheduleStopped) {
        this.getcustomScheduleSettings(res, false);
      }
    })
      .catch((error) => {
        console.error(error);
      })
  }

  getcustomScheduleSettings(res, isCustomActive) {
    if (isCustomActive) {
      let previousAdaptiveReport = res.report.find((report) => report.status === "Stopped" && report.isCustom == false) as wpapi.model.TwilioAdaptiveScheduleReport;
      this.Adaptivephases = previousAdaptiveReport?.phases;
      let customReport = res.report.find((report) => report.status === 'Current' && report.isCustom == true) as wpapi.model.TwilioNonAdaptiveScheduleReport;
      this.customScheduleSettings = {
        startDate: new Date(customReport?.startDate?.year, customReport?.startDate?.month - 1, customReport?.startDate?.date).toLocaleDateString('en-US'),
        frequency: this.mapCustomFrequency(customReport?.frequency),
        stratedBy: (customReport?.createdBy?.firstName)? customReport?.createdBy?.firstName + ' ' + customReport?.createdBy?.lastName : customReport?.createdBy?.email
      }
      let stoppedAdaptivePhase = previousAdaptiveReport?.phases.find((phase) => phase.status == 'Stopped');
      this.stoppedByUser = (stoppedAdaptivePhase?.stoppedBy?.firstName)? stoppedAdaptivePhase?.stoppedBy?.firstName + ' ' + stoppedAdaptivePhase?.stoppedBy?.lastName : stoppedAdaptivePhase?.stoppedBy?.email 
    }
    else if ((!this.isScheduleAdaptive && !this.isScheduleCustom) || this.isScheduleStopped) {
      let customReport = res.report.find((report) => report.status === 'Stopped' && report.isCustom == true) as wpapi.model.TwilioNonAdaptiveScheduleReport;
      let previousAdaptiveReport = res.report.find((report) => report.status === "Stopped" && report.isCustom == false) as wpapi.model.TwilioAdaptiveScheduleReport;
      this.Adaptivephases = previousAdaptiveReport?.phases;
      this.customScheduleSettings = {
        stoppedBy: (customReport?.stoppedBy?.firstName)? customReport?.stoppedBy?.firstName + ' ' + customReport?.stoppedBy?.lastName : customReport?.stoppedBy?.email, 
        stoppedAt: new Date(customReport?.stoppedAt).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
      }
    }
  }

  mapCustomFrequency(frequency: string) {
    switch(frequency) {
      case this.frequency.Monthly:
        return "First Mon of each month";
      case this.frequency.MWF:
        return "First Mon of each month";
      case this.frequency.Weekdays:
        return "Mon - Fri of each week";
      case this.frequency.Weekly: 
        return "Mon of each week"
      case this.frequency.Biweekly: 
        return "Every other Mon";
    }
  }


  handleCancel(){
    this.showCustomSchedule = false; 
    this.showStandardSchedule = false;
    this.cdr.detectChanges();
    this.buildCharts();
  }
}