import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BackendError} from '@shared/services/awv-workflow/backend-error';
import { AwvActivityChangeEvent } from '@shared/services/awv-workflow/awv-activity-change-event';

@Injectable({
  providedIn: 'root'
})
export class AwvWorkflowService {
  private _backendError$ = new Subject<BackendError>();
  private _updateSuccessful$ = new Subject<void>();
  private _awvActivityChangeEvent$ = new Subject<AwvActivityChangeEvent>();
/*
Expose backendError as observable to conceal subject like behaviour.
*/
  public get backendError$(): Observable<BackendError> {
    return this._backendError$;
  }
  public get updateSuccessful$(): Observable<void> {
    return this._updateSuccessful$;
  }
  public get awvActivityChangeEvent$(): Observable<AwvActivityChangeEvent> {
    return this._awvActivityChangeEvent$;
  }
  public publishBackendError(backendError: BackendError): void{
    this._backendError$.next(backendError);
  }

  public publishUpdateSuccessful(): void {
    this._updateSuccessful$.next();
  }

  public publishAwvActivityChangeEvent(awvActivityChangeEvent: AwvActivityChangeEvent): void{
    this._awvActivityChangeEvent$.next(awvActivityChangeEvent);
  }

}
