import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'coach-patient-awv-error',
  templateUrl: './patient-awv-error.component.html',
  styleUrls: ['./patient-awv-error.component.scss']
})
export class PatientAwvErrorComponent {

  @Input() public inError: boolean;
  @Input() public errorTitle: string;
  @Input() public errorMessage: string;

}
