import { Injectable } from '@angular/core';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { PatientEngagement, } from '@shared/enums';
import { PresetCategory, CarePatientItem } from '@shared/models/module-constants';
import { PatientViewModel } from '@shared/models/patient';
import { environment } from 'src/environments/environment';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { CareFeatureConstants } from "@shared/models/module-constants";
import { smsProgramType } from '@shared/enums/sms-programType.enum';
import moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class SmsOutreachProgramPreset extends PresetItemBase implements IPresetItemContract {
  activeOrgAlerts: Array<wpapi.model.Alert & { filterDesc?: string; userId?: string }> = [];
  private isCombinedSMSCohortsEnabled = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CombineSMSCohorts);
  getPresetItem(): IPatientFilterPreset {
    const categoryName: PresetCategory = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.CareLandingPageFlag) ?  PresetCategory.ChronicConditions: PresetCategory.Worklists;
    const item: IPatientFilterPreset = {
      category: categoryName,
      name: CarePatientItem.SmsOutreachProgram,
      presetKey: CarePatientItem.SmsOutreachProgram,
      label: 'SMS Outreach Program',
      filters: [],
      componentId: this.isSMSOutreachProgramEnabled ? PatientEngagement.PatientTwilioSMS : null,
      hidePreset: true,
      dontStoreName: true,
      hiddenColumnsByDefault: this.getHiddenColumns(),
      columns: this.getPresetColumns(),
      defaultFilterModel: {
        status: {
          filterType: 'set',
          values: ['Active']
        }
      },
      patientsDataExtender: async (patients: PatientViewModel[], orgId: string): Promise<PatientViewModel[]> => {
        let tasks = await this._orgService.getTasksByOrg(orgId, 'twilioStudioFlow');
        let completions = await this._orgService.getCompletionsByOrg(orgId, 'twilioStudioFlow');
        let patientDataById = {};
        tasks?.forEach(task => {
          if (!patientDataById[task.userId]) {
            patientDataById[task.userId] = {};
          }
          patientDataById[task.userId].status = task.isActive;
          let smsProgram : smsProgramType;
          if (task.type === "twilioStudioFlow") {
            switch (task.subType) {
                case "COPD":
                    smsProgram = smsProgramType.COPD;
                    break;
                case "Heart Failure":
                    smsProgram = smsProgramType.HF;
                    break;
            }
          }
          patientDataById[task.userId].smsProgram = this.programTypeDisplayString(smsProgram);
        });
        completions?.forEach(completion => {
          if (completion.event?.msg) {
            if (!patientDataById[completion.userId]) {
              patientDataById[completion.userId] = {};
            }
            patientDataById[completion.userId].lastResponseDate = completion.event?.time || '';
            patientDataById[completion.userId].lastResponse =
              completion.event?.event == 'undeliverable' ? 'Undeliverable' : completion.event?.msg;
          }
        });

        if (!this.activeOrgAlerts?.length) {
          this.activeOrgAlerts = await this._patientAlertService.getWPAPIorgAlerts(orgId);
        }
        this.activeOrgAlerts?.forEach(alert => {
          if (alert.title == 'COPD SMS Alert') {
            if (!patientDataById[alert.userId]) {
              patientDataById[alert.userId] = {};
            }
            patientDataById[alert.userId].alert = true;
          }
        });
        let filteredPatients: PatientViewModel[] = [];
        patients?.forEach(patient => {
          if (patientDataById[patient?.carePatient?._id]) {
            patient.status = patientDataById[patient.carePatient._id].status ? 'Active' : 'Inactive';
            const lastResponseDateTemp = patientDataById[patient.carePatient._id].lastResponseDate;
            if (lastResponseDateTemp) {
              patient.lastResponseDate = moment.utc(lastResponseDateTemp).format("YYYY-MM-DD");
            } else {
              patient.lastResponseDate = "";
            }
            patient.lastResponse = patientDataById[patient.carePatient._id].lastResponse;
            patient.smsProgram = patientDataById[patient.carePatient._id].smsProgram;
            if (
              patient.carePatient?.identifiers?.find(
                identifier => identifier.issuer == 'https://identity.caravanhealth.com/smscareplanphonenumber'
              )
            ) {
              patient.enrollmentDate = patient.carePatient?.identifiers?.find(
                identifier => identifier.issuer == 'https://identity.caravanhealth.com/smscareplanphonenumber'
              )?.createdAt;
              patient.enrollmentDate = patient.enrollmentDate ? moment.utc(patient.enrollmentDate).format("YYYY-MM-DD") : "";
            }
            filteredPatients.push(patient);
          }
        });
        return filteredPatients;
      }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private programTypeDisplayString(taskSubType : smsProgramType) : string{
    if (taskSubType == smsProgramType.COPD){
      return "COPD";
    }
    else if (taskSubType == smsProgramType.HF){
    return "Heart Failure"
    }
  }

  private getHiddenColumns(): string[] {
    return this.isCombinedSMSCohortsEnabled ?
            [
              'lastAWVDate',
              'ccmEligible',
              'diseaseCohorts',
              'openRAFWt',
              'raF_YTD',
              'hccGapCount',
              'assignment'
            ] : []
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    return [
      { select: true },
      { mbi: true },
      { fullName: true },
      { dob: true },
      { gender: true },
      { attributedCP: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      { actions: this.isCombinedSMSCohortsEnabled, sort: 'desc'  },
      { lastResponse: true },
      { lastResponseDate: true },
      { enrollmentDate: true },
      { smsProgram : true},
      { status: true },
      { professionalName: this.isCombinedSMSCohortsEnabled },
      { lastCcmDate: this.isCombinedSMSCohortsEnabled },
      { ccmLastProcCode: this.isCombinedSMSCohortsEnabled },
      { phoneNumber: true },
      { attributedPractice: true },
      { lastVisit: true },
      { lastAWVDate: true },
      { ccmEligible: this.isCombinedSMSCohortsEnabled },
      { diseaseCohorts: true },
      { openRAFWt: true },
      { raF_YTD: true },
      { hccGapCount: true },
      { assignment:this.isCombinedSMSCohortsEnabled },
    ];
  }
}
