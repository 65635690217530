<div class="queue border-right u-position--relative animation--content--fadeIn"
  [ngClass]="isQueueOpen ? 'is-open' : 'is-closed'">
  <button class="pullTab top--xs" (click)="toggleQueueOpen()" [ngClass]="{ 'is-open': isQueueOpen }"
    aria-label="Toggle File List Panel">
    <coach-icon iconName="chevrons-right"></coach-icon>
  </button>
  <div class="header">
    <div class="titles">
      <div class="title">File Search Results</div>
    </div>
  </div>
  <div class="dynamicBody u-position--relative">
    <div class="dynamicBody">
      <ng-container *ngIf="searchResults.length; else noItems">
        <div class="list">
          <div *ngFor="let file of searchResults" class="list-item" (click)="fileSelected(file)">
            <div class="row">
              <div class="field u-text-wrap u-wrap">
                {{ file.title }}
              </div>
              <div *ngIf="file.phi" class="field">Contains PHI</div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noItems>
        <div class="padding u-opacity--light u-italic">No Files</div>
      </ng-template>
    </div>
  </div>
</div>

<div class="column u-background-color--white u-fill border-left">
  <ng-container *ngIf="fileClicked; else noFileSelected">
    <div class="header padding-left--largest">
      <div class="titles t4">
        <div class="title">{{ selectedFile ? selectedFile.title : null }}</div>
        <div *ngIf="fileClicked" class="subtitle row margins-right--small">
          {{ selectedFilePathFormated }}
        </div>
      </div>

      <button type="button" class= "button border--none margin-left" aria-label="Share Button" (click)="sharedClicked($event)">
         <img
           src="assets\viewer-assets\img\share_icon.png"
           width="25px"
           height="25px"
           alt="share-icon"
           style="margin-left: 0; cursor: pointer; padding-right: 5%;"
         />
         <div>
          <span style="vertical-align: 0px; color: black">{{sharedButtonText}}</span>
       </div>
        </button>
    </div>

    <ng-container [ngSwitch]="fileType">
      <div *ngSwitchCase="'URL'" class="dynamicBody padding">
        <a [href]="safeSrc" target="_blank">
          Click here to open in new tab if preview fails to load
        </a>
        <iframe appIframeTracker (iframeClick)="onIframeClick()" [src]="safeSrc" title="" width="100%" height="100%">
        </iframe>
      </div>
      <div *ngSwitchDefault class="dynamicBody">
        <coach-prizmdoc-viewer-wrapper [tier]="tier" viewingSessionId="{{ viewingSessionId }}"
        [tabName]="tabname"  (viewerControl)="setViewerControl($event)" tab style="width: 100%; height: 90%">
        </coach-prizmdoc-viewer-wrapper>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noFileSelected>
    <div class="
        dynamicBody
        row--verticalCenter
        u-center u-italic
        u-opacity--lighter
        u-font-size--large
        u-thin
      ">
      No File Selected
    </div>
  </ng-template>
</div>
