import { IPatientFilterPreset } from '@shared/models/patient-filters';
import {
  CareComponentConstants,
  CareFeatureConstants,
  CarePatientItem,
  PresetCategory
} from '@shared/models/module-constants';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { Assignment } from '@shared/enums/assignment.enum';

@Injectable({
  providedIn: 'root'
})
export class AllPatientsPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const item: IPatientFilterPreset = {
      category: PresetCategory.General,
      name: CarePatientItem.AllPatients,
      presetKey: CarePatientItem.AllPatients,
      label: 'All Patients',
      filters: [],
      componentId: CareComponentConstants.PatientList,
      hidePreset: true,
      filterComponent: null,
      filterSettings: null,
      dontStoreName: true,
      columns: this.getPresetColumns(),
      hiddenColumnsByDefault: ['lastCsthDate'],
      defaultFilterModel: {
        assignment: {
          filterType: 'set',
          values: [Assignment.Attributed, Assignment.MostAssignable]
        },
      },
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean | string }[] {
    const agGridCustomFilteringFlag = this.featureFlagService.hasFeatureFlag(
      CareFeatureConstants.AgGridCustomFilteringFlag
    );
    return [
      { select: true },
      { fullName: true },
      { mbi: true },
      { dob_age: true },
      { gender: true },
      { assignment: true },
      {
        predictedRiskLevel: environment.displayRiskLevel
      },
      { attributedCP: true },
      { awvTypeDue: true },
      { lastAWVDate: true },
      { lastAWVProvider: true },
      { ihAwvPatient: true },
      { hccGapCount: agGridCustomFilteringFlag },
      { diseaseCohorts: agGridCustomFilteringFlag },
      { lastCcmDate: true },
      { ccmLastProcCode: true},
      { ccmEligible: agGridCustomFilteringFlag },
      { raF_YTD: true },
      { pyRaf: true },
      { openRAFWt: true },
      { lastVisit: true },
      { attributedPractice: true },
      { totalCost: true },
      { edVisits: true },
      { officeVisits: true },
      { inpatientVisits: true },
      { enrollmentType: true },
      { phoneNumber: true },
      { lastActivityAt: this.hasPatientApp },
      { professionalName: this.hasPatientApp || this.hasPatientTwilioSMS },
      { actions: this.hasPatientApp },
      { dodBoolean: true },
      { lastCsthDate: true }
    ];
  }
}
