<div class="titles t3">
    <div class="title row--verticalCenter u-color--brand--1 u-display--flex">Top 5 Disease Groups
        <coach-icon class="margin-left--small" iconName="info"
            title="This section will need to load based on hierarchy selection (Tiers 1-4), CIN level and attribution type (Attributed or Attributed + Most Assignable) selected in the slide out filter.">
        </coach-icon>
    </div>
</div>
<div class="list u-width--full margin-top--large">
    <div *ngFor="let group of data; let i = index" class="list-item"
        [style.pointer-events]="canAccessPhi ? 'auto' : 'none'">
        <div class="row">
            <div *ngIf="canAccessPhi" class="field u-color--interaction" (click)="navigateToPatientDetails()"
                [routerLink]="['../patientDetails']" [queryParams]="{diseaseGroup: group.groupDescription}">{{i+1}}.
                {{group.groupDescription}}</div>
            <div *ngIf="!canAccessPhi" class="field">{{i+1}}. {{group.groupDescription}}</div>

            <div class="field u-bold">{{group.patientCount}}</div>
        </div>
    </div>
</div>