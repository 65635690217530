import { Component, OnInit } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';

@Component({
  selector: 'coach-header-hierarchy-overlay',
  templateUrl: './header-hierarchy-overlay.component.html',
  host: {
    class: 'header-hierarchy-overlay'
  }
})
export class HeaderHierarchyOverlayComponent implements OnInit {

  constructor(public hierarchyTierService: HierarchyTierService) { }

  ngOnInit(): void {
  }

  anyLoading(tier: IHierarchyTier): boolean {
    return this.hierarchyTierService.hierarchyTiers.some(x => x.isLoading && x.tier <= tier.tier);
  }

  isDisabled(tier: IHierarchyTier): boolean {
    return tier.items == null || tier.items.length === 0;
  }
}
