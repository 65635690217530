import { formatDate } from '@angular/common';
import { EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FACESHEET_SCHEDULE_GRID_KEY, GridFilterService } from '@shared/components/grid/grid-filter.service';
import { PersistentGridFilters } from '@shared/components/grid/persistent-grid-filters';
import { RouterLinkRendererComponent } from '@shared/components/grid/router-link-renderer/router-link-renderer.component';
import { MaskPipe } from '@shared/pipes/mask.pipe';
import { environment } from 'src/environments/environment';
import { HccFacesheetSchedule } from '../hcc-facesheet-schedule';
import { HccFacesheetScheduleService } from '../hcc-facesheet-schedule.service';
import { FilterToggleService } from '@shared/services/filter-toggle/filter-toggle.service';
import { compareString, compareRiskCategory } from "@shared/components/grid/grid-functions"
import { AgGridLocalSettingsService } from '@shared/services/ag-grid/ag-grid.service';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { CarePatientItem, PresetCategory } from '@shared/models/module-constants';
import { ColumnApi, FilterChangedEvent, GridApi } from 'ag-grid-community';

@Component({
  selector: 'coach-facesheet-schedule-grid',
  templateUrl: './facesheet-schedule-grid.component.html',
  styleUrls: ['./facesheet-schedule-grid.component.scss'],
  providers: [MaskPipe]
})

export class FacesheetScheduleGridComponent extends PersistentGridFilters implements OnInit {
  columnDefs = [
    { headerName: 'Status', field: 'status', sortable: true, filter: true, minWidth: 125, width: 125, checkboxSelection: true, headerCheckboxSelection: true, resizable: true, suppressSizeToFit: true },
    { headerName: 'Appointment Date', field: 'appointmentDate', sortable: true, filter: true, minWidth: 150, width: 150, valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale), resizable: true, suppressSizeToFit: true },
    { headerName: 'Appointment Time', field: 'appointmentTime', sortable: true, filter: true, minWidth: 150, width: 150, valueFormatter: params => formatDate(params.value, 'h:mm a', this.locale), resizable: true, suppressSizeToFit: true },
    {
      headerName: 'Clinician', field: 'clinicianName', sortable: true, filter: true, resizable: true, suppressSizeToFit: true, filterParams: {
        showTooltips: true,
      }
    },
    {
      headerName: 'Patient Name', field: 'patientName', sortable: true, filter: true, resizable: true, suppressSizeToFit: true,
      cellRendererFramework: RouterLinkRendererComponent,
      cellRendererParams: {
        linkClicked: (data: HccFacesheetSchedule): void => {
          this.setSelectedPatientId.emit(data.chPatId.toString())
        },
        isDisabled: (data: HccFacesheetSchedule): boolean => {
          return data == null || !data.isAvailable;
        }
      },
      filterParams: {
        showTooltips: true,
      }
    },
    { headerName: 'Patient ID', field: 'patientId', sortable: true, filter: true, resizable: true, suppressSizeToFit: true },
    { headerName: 'Date of Birth', field: 'dob', sortable: true, filter: true, minWidth: 125, width: 125, valueFormatter: params => formatDate(params.value, 'MM/dd/yyyy', this.locale), resizable: true, suppressSizeToFit: true },
    {
      headerName: 'MBI', field: 'mbi', sortable: true, filter: true, resizable: true, suppressSizeToFit: true, valueFormatter: params => {
        const patient: HccFacesheetSchedule = params.data;

        if (patient == null) {
          return null;
        }
        else if (environment.maskSensitiveData) {
          return this.mask.transform(patient.mbi, 6);
        }
        else {
          return patient.mbi;
        }
      }, keyCreator: params => {
        if (environment.maskSensitiveData) {
          return this.mask.transform(params.value, 6);
        }
        else {
          return params.value;
        }
      },
      cellRenderer: params => {
        return `<span ${(params.rowIndex === 0 || params.rowIndex === 1) ? `[attr.data-cy]="patient-row_${params.rowIndex}"` : void 0}>${params.value}</span>`;
      }
    },
    { headerName: 'Assignment', field: 'assignment', filter: true, minWidth: 140, sortable: true },
    {
      headerName: 'Attributed Provider',
      field: 'attributedCP',
      minWidth: 200,
      sortable: true,
      comparator: compareString,
      filter: 'agSetColumnFilter',
      filterParams: {
        showTooltips: true,
      }
    },
    {
      headerName: 'Appointment Info', field: 'appointmentInfo', sortable: true, filter: true, resizable: true, suppressSizeToFit: true, filterParams: {
        showTooltips: true,
      }
    },
    { headerName: 'Location ID', field: 'locationId', sortable: true, filter: true, resizable: true, suppressSizeToFit: true },
    // { headerName: 'Current Risk Category', field: 'currentRiskLevel', filter:true, minWidth: 185, sortable: true,  comparator: compareRiskCategory  },
    { headerName: 'Predicted Risk Category', field: 'predictedRiskLevel', filter: true, minWidth: 190, sortable: true, comparator: compareRiskCategory },
    // { headerName: 'Probability Risk Category', field: 'probability', filter:true, minWidth: 205, sortable: true },
    //{ headerName: 'Risk Trend', field: 'riskTrend', filter:true, minWidth: 120, sortable: true, cellRendererFramework: LinkCellRendererComponent,
    //  comparator: compareRiskTrend },
  ];
  @Input() patientDataByChPatId: any[]
  @Input() localeText: { [key: string]: string };
  @Output() public selectedPatients: EventEmitter<any[]> = new EventEmitter();
  @Output() public secondaryColumnApiEmitter: EventEmitter<ColumnApi> = new EventEmitter() // sends columnApi to care-patients where it's used in columnsSettingsModal to hide/display columns;
  @Output() public agFilterChangedEmitter: EventEmitter<GridApi> = new EventEmitter() // sends gridApi instance to care-patients when filter is applied;
  @Output() public setSelectedPatientId: EventEmitter<string> = new EventEmitter() 
  
  rowData: any;

  user: wpapi.model.Professional;
  
  loadingStatus: boolean = false;
  constructor(@Inject(LOCALE_ID) private locale: string,
    private hccFacesheetScheduleService: HccFacesheetScheduleService,
    private mask: MaskPipe, private router: Router,
    private route: ActivatedRoute,
    protected filterService: GridFilterService,
    public filterToggleService: FilterToggleService,
    private agGridLocalSettingsService: AgGridLocalSettingsService,
    private authService: AuthService) {

    super(filterService, FACESHEET_SCHEDULE_GRID_KEY);
    if (!environment.displayRiskLevel) {
      this.columnDefs.splice(this.columnDefs.length - 1, 1); // CHANGE TO ADD ADDITIONAL RISK COLUMNS splice(this.columnDefs.length -4 , 4);
      this.columnDefs = this.columnDefs.filter(col => col.field != 'predictedRiskLevel');

    }
  }

  ngOnInit(): void { }

  /**
   * 
   * @param from From date
   * @param to To date
   * @param communityId Community on which to filter
   * @param practiceId Practice on which to filter
   * @param providerNpi Provider NPI on which to filter
   * @param isFirstFilter Indicates whether or not this is the initial load of the grid data
   */
  filter(from: string, to: string, communityId: string, practiceId: string, providerNpi: string, isFirstFilter: boolean,tier3Id: string): void {
    this.loadingStatus = true;
    this.skipUpdate = true;
    this.rowData = this.hccFacesheetScheduleService.getSchedules(from, to, communityId, practiceId, providerNpi,tier3Id);
    const component = this;
    this.rowData.subscribe((data) => {
      this.loadingStatus = false;
      setTimeout(() => {
        const filterInstance = component.gridApi.getFilterInstance('status');
        if (isFirstFilter) {
          filterInstance.setModel({
            type: 'set',
            values: ['Available'],
          });
        } else if (this.hasFilterModel()) {
          this.restoreFilterModel(this.gridApi);
        }

        component.gridApi.selectAll();
        component.gridApi.sizeColumnsToFit();
        component.gridApi.onFilterChanged();
      });
    });
  }

  isRowSelectable(rowNode: any): boolean {
    const scheduleItem: HccFacesheetSchedule = rowNode.data;
    return scheduleItem != null ? scheduleItem.isAvailable : false;
  }

  getSelectedItems(): HccFacesheetSchedule[] {
    const nodes: HccFacesheetSchedule[] = [];

    this.gridApi?.forEachNodeAfterFilter(node => {
      if (node.isSelected()) {
        nodes.push(node.data);
      }
    });

    return nodes;
  }

  
  public clear(){        
    this.gridApi.setFilterModel(null);
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    this.gridColumnApi.getColumn('appointmentTime').setSort('asc');
    this.setLocalColumnSettings();
    this.secondaryColumnApiEmitter.emit(this.gridColumnApi);
    this.getAndApplyFilterModel()
  }

  onFirstDataRendered(params: any): void {
    if (this.hasFilterModel()) {
      this.restoreFilterModel(params.api);
    }

    const filterInstance = params.api.getFilterInstance('status');
    filterInstance.setModel({
      filterType: 'set',
      values: ['Available'],
    });
    this.gridApi.onFilterChanged();

    params.api.sizeColumnsToFit();
  }

  public onSelectionChanged(event: any): void {
    const nodes: any[] = this.gridApi.getSelectedNodes();
    const patients = [];
    nodes.forEach(p => {
      const patient = p.data;
      const parts = patient.patientName.split(' ');
      if (parts.length > 0) {
        patient.lastName = parts.pop();
        patient.firstName = parts.join(' ');
      }
      patient.careId = this.patientDataByChPatId?.[patient.chPatId]?.careId;
      patients.push(patient);
    });
    this.selectedPatients.emit(patients);
  }
  onColumnUpdated(): void {
    this.agGridLocalSettingsService.storeColumnSettingsInLocalStorage(CarePatientItem.FacesheetSchedule, this.gridColumnApi.getColumnState(), null);
  }

  async setLocalColumnSettings(): Promise<void> {
    if (!this.user) {
      this.user = await this.authService.getWellpepperUserInfo();
    }
    this.agGridLocalSettingsService.setColumnSettings(this.gridColumnApi, CarePatientItem.FacesheetSchedule, this.user._id, null , null);
  }

  agFilterChanged(event: FilterChangedEvent): void {
    this.agFilterChangedEmitter.emit(this.gridApi);
    if(event?.afterDataChange != null){ 
      this.storeFilterModel(); 
    }
  }
  storeFilterModel(): void { 
    this.agGridLocalSettingsService.storeFilterModelInLocalStorage(PresetCategory.Facesheet, CarePatientItem.FacesheetSchedule, this.gridApi.getFilterModel());
  }
  async getAndApplyFilterModel() {
    let storedFilterModel = await this.agGridLocalSettingsService.getStoredFilterModelFromLocalStorage(PresetCategory.Facesheet, CarePatientItem.FacesheetSchedule)
    this.gridApi.setFilterModel(storedFilterModel)
  }
  onRowDataChanged(): void {
    this.getAndApplyFilterModel();
  }
}

