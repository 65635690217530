import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class AzureApiBase<T> {
    constructor(protected http: HttpClient, protected authService: AuthService,
                public apiUrl: string, public basePath: string) {

        this.value = new BehaviorSubject<T>(null);
        this.values = new BehaviorSubject<T[]>(null);
    }

    private value: BehaviorSubject<T>;
    protected values: BehaviorSubject<T[]>;

    public getFromCache(route: string = null, params: HttpParams = null): Observable<T> {
        if (this.value.value == null) {
            return this.getSingle(route, params);
        }

        return this.value.asObservable();
    }

    public getAllFromCache(route: string = null, params: HttpParams = null): Observable<T[]> {
        if (this.values.value == null) {
            return this.getAll(route, params);
        }

        return this.values.asObservable();
    }

    public getAll(route: string = null, params: HttpParams = null): Observable<T[]> {
        const observable = this.get<T[]>(route, params);

        observable.subscribe(values => {
            this.values.next(values);
        });

        return observable;
    }

    public getSingle(route: string = null, params: HttpParams = null): Observable<T> {
        const observable = this.get<T>(route, params);

        observable.subscribe(value => {
            this.value.next(value);
        });

        return observable;
    }

    public get<T1>(route: string = null, params: HttpParams = null): Observable<T1> {
        const options = this.buildOptions();
        if (params != null) {
            options.params = params;
        }

        let observable: Observable<T1>;

        const url = this.buildUrl(route);

        observable = this.http.get<T1>(url, options as {}).pipe(share());

        return observable;
    }

    public post(route: string = null, request: any = null): Observable<any> {
        const url = this.buildUrl(route);
        const observable = this.http.post(url, request, this.buildOptions() as {}).pipe(share());

        observable.subscribe(value => {
            if (value == null) {
                return;
            }

            if (Array.isArray(value)){
                this.values.next(value);
            } else {
                this.value.next(value as T);
            }
        });

        return observable;
    }

    public postPdf(request: any, route: string = this.basePath): Observable<ArrayBuffer> {
        const url = this.buildUrl(route);
        const options = this.buildOptions();
        options.responseType = 'blob';
        options.headers.set('Accept', 'application/pdf');
        const observable = this.http.post<ArrayBuffer>(url, request, options as {}).pipe(share());

        return observable;
    }

    public postZip(request: any, route: string = this.basePath): Observable<ArrayBuffer> {
        const url = this.buildUrl(route);
        const options = this.buildOptions();
        options.responseType = 'arrayBuffer';
        options.headers.set('Accept', 'application/pdf');
        const observable = this.http.post<ArrayBuffer>(url, request, options as {}).pipe(share());

        return observable;
    }

    private buildUrl(route: string): string {
        return `${this.apiUrl}${this.basePath}${route == null ? '' : route}`;
    }

    private buildOptions(): {
        headers?: HttpHeaders;
        observe?: 'body';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType?: string;
        withCredentials?: boolean;
    } {
        return {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.authService.IdToken}`,
                'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
            })
        };
    }
}