import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { AzureApiBase } from '../azure-api/azure-api-base';
import { PatientTimeline } from './patient-timeline-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientTimelineService  extends AzureApiBase<PatientTimeline> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/patients/timeline');
  }

  getTimelineItems(chPatId: number, supplementalId: number): Observable<PatientTimeline[]> {
    const params = new HttpParams().set('chPatId', chPatId.toString())
      .append('supplementalId', supplementalId.toString());
    return this.getAll(null, params);
  }
}
