import { Component, Input, OnInit } from '@angular/core';
import { GetInitialsFromNamePipe } from '@shared/pipes/get-initials-from-name.pipe';
import { RouterHistoryService } from '@shared/services/router-history/router-history.service';
import {
  RecentsCacheService,
  RecentsItem,
} from '@shared/services/recents-cache/recents-cache.service';
import { TierNameLookupService } from '@shared/services/tier-name-lookup/tier-name-lookup.service';

@Component({
  selector: 'coach-sidebar-recent',
  templateUrl: './sidebar-recent.component.html',
  providers: [GetInitialsFromNamePipe],
})
export class SidebarRecentComponent implements OnInit {
  public recentItems: RecentsItem[] = [];

  @Input() isSidebarOpen: boolean;

  constructor(
    public _getInitialsFromName: GetInitialsFromNamePipe,
    public historyService: RouterHistoryService,
    public recentsCache: RecentsCacheService,
    public tierNameLookupService: TierNameLookupService
  ) {}

  ngOnInit(): void {
    this.recentsCache.recents$.subscribe(async (recents) => {
      const tierIds = recents.map((r) => {
        return r.navURL.split('/')[2];
      });
      await this.tierNameLookupService.preLoadTierNames(tierIds);
      this.recentItems = recents;
    });
  }
}
