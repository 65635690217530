import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TrackComponentConstants } from '@shared/models/module-constants';
import { CliniciansReportComponent } from './hcc-dashboard/dashboard-reports/clinicians-report/clinicians-report.component';
import { PatientDetailReportComponent } from './hcc-dashboard/dashboard-reports/patient-detail-report/patient-detail-report.component';
import { PatientSummaryReportComponent } from './hcc-dashboard/dashboard-reports/patient-summary-report/patient-summary-report.component';
import { HccDashboardSummaryComponent } from './hcc-dashboard/hcc-dashboard-summary/hcc-dashboard-summary.component';
import { HccDashboardComponent } from './hcc-dashboard/hcc-dashboard.component';
import { TrackClinicalWidgetsComponent } from './track-clinical-widgets/track-clinical-widgets.component';
import { TrackClinicalComponent } from './track-clinical.component';
import { TrackTimeTrackingComponent } from './track-time-tracking/track-time-tracking.component';
import { PracticesReportComponent } from './hcc-dashboard/dashboard-reports/practices-report/practices-report.component';
import { CinsReportComponent } from './hcc-dashboard/dashboard-reports/cins-report/cins-report.component';

const routes: Routes = [
  {
    path: '',
    component: TrackClinicalComponent,
    data: {
      label: 'Clinical',
      helpfile: 'LEARN_TRACK_CLINICAL_2020',
    },
    children: [
      {
        path: '',
        component: TrackClinicalWidgetsComponent,
        data: {
          label: 'Summary',
          helpfile: 'LEARN_TRACK_CLINICAL_2020',
        },
      },
      {
        path: 'dashboard',
        data: {
          label: 'Dashboard',
          componentId: TrackComponentConstants.ChronicConditions.valueOf(),
          helpfile: 'LEARN_TRACK_CLINICAL_2020',
        },
        children: [
          {
            path: '',
            component: HccDashboardComponent,
            data: {
              label: 'Chronic Conditions Dashboard',
              helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
            },
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'overview',
              },
              {
                path: 'overview',
                component: HccDashboardSummaryComponent,
                data: {
                  label: 'Chronic Conditions Dashboard Overview',
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
              {
                path: 'cins',
                component: CinsReportComponent,
                data: {
                  label: 'CINs',
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
              {
                path: 'practices',
                component: PracticesReportComponent,
                data: {
                  label: 'Practices',
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
              {
                path: 'clinicians',
                component: CliniciansReportComponent,
                data: {
                  label: 'Clinicians',
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
              {
                path: 'patients/:clinician',
                component: PatientSummaryReportComponent,
                data: {
                  label: 'Patients',
                  requirePhi: true,
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
              {
                path: 'patients',
                component: PatientSummaryReportComponent,
                data: {
                  label: 'Patients',
                  requirePhi: true,
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
              {
                path: 'patientDetails/:clinician',
                component: PatientDetailReportComponent,
                data: {
                  label: 'Patient Details',
                  requirePhi: true,
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
              {
                path: 'patientDetails',
                component: PatientDetailReportComponent,
                data: {
                  label: 'Patient Details',
                  requirePhi: true,
                  helpfile: 'LEARN_TRACK_Clinical_HCCBasic_2020',
                },
              },
            ]
          },
        ],
      },
      {
        path: 'trackTimeTracking',
        component: TrackTimeTrackingComponent,
        data: {
          label: 'Time Tracking',
          helpfile: 'LEARN_TRACK_CLINICAL_TIMETRACKING_2020',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TrackClinicalRoutingModule { }
