<div class="section" *ngIf="patient">
    <!-- HEADER -->
    <div class="header border-top--none border-bottom padding-bottom--smaller">
        <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--large">
            <div class="titles t4">
                <b class="title">QUALITY</b>
            </div>
        </div>
    </div>




    <div class="margin-left--larger margin-right--smaller margin-topBottom--smaller">

        <table class="care-gaps-table">
            <tbody>
                <!-- MAMMOGRAM -->
                <tr *ngIf="patient.gender == 'female'">
                    <td class="icon-placeholder">
                        <!-- keep empty -->
                    </td>
                    <td>
                        <b>Mammogram</b>
                        <span class="u-color--highlight u-italic">({{ qualityData.mammogram_timePeriod }}) </span>
                    </td>
                    <td>
                        <div class="u-thin u-text-align--right" style="text-transform: capitalize;">
                            <span *ngIf="qualityData.mammogramLastVisitPROCCode">
                                {{qualityData.mammogramLastVisitPROCCode}} -
                            </span>
                            <span *ngIf="qualityData.mammogramLastVisitFacility">
                                {{getFormattedName(qualityData.mammogramLastVisitFacility)}},
                            </span> {{ qualityData.mammogram | date:'MM/dd/yy' }}
                        </div>
                    </td>
                </tr>
                <!-- COLONOSCOPY -->
                <tr>
                    <td class="icon-placeholder">
                        <!-- keep empty -->
                    </td>
                    <td>
                        <b>Colorectal Cancer Screening </b>
                        <span class="u-color--highlight u-italic">({{ qualityData.colonoscopy_timePeriod }}) </span>
                    </td>
                    <td>
                        <div class="u-thin u-text-align--right" style="text-transform: capitalize;">
                            <span *ngIf="qualityData.colonoscopyLastVisitPROCCode">
                                {{qualityData.colonoscopyLastVisitPROCCode}} -
                            </span>
                            <span *ngIf="qualityData.colonoscopyLastVisitFacility">
                                {{getFormattedName(qualityData.colonoscopyLastVisitFacility)}},
                            </span> {{ qualityData.colonoscopy | date:'MM/dd/yy' }}
                        </div>
                    </td>
                </tr>

                <!-- FLU VAC -->
                <tr>
                    <td class="icon-placeholder">
                        <!-- keep empty -->
                    </td>
                    <td>
                        <b>Flu Vaccine </b>
                        <span class="u-color--highlight u-italic">({{ qualityData.fluVac_timePeriod }}) </span>
                    </td>
                    <td>
                        <div class="u-thin u-text-align--right" style="text-transform: capitalize;">
                            <span *ngIf="qualityData.fluLastVisitPROCCode">
                                {{qualityData.fluLastVisitPROCCode}} -
                            </span>
                            <span *ngIf="qualityData.fluLastVisitFacility">
                                {{getFormattedName(qualityData.fluLastVisitFacility)}},
                            </span> {{ qualityData.fluVac | date:'MM/dd/yy' }}
                        </div>
                    </td>
                </tr>



                <!-- PNEUMOCOCCAL VAL -->
                <tr>
                    <td class="icon-placeholder">
                        <!-- keep empty -->
                    </td>
                    <td>
                        <b>Pneumococcal Vaccine </b>
                        <span class="u-color--highlight u-italic">({{ qualityData.pneumococcalVac_timePeriod }}) </span>
                    </td>
                    <td>
                        <div class="u-thin u-text-align--right" style="text-transform: capitalize;">
                            <span *ngIf="qualityData.pneumoniaLastVisitPROCCode">
                                {{qualityData.pneumoniaLastVisitPROCCode}} -
                            </span>
                            <span *ngIf="qualityData.pneumoniaLastVisitFacility">
                                {{getFormattedName(qualityData.pneumoniaLastVisitFacility)}},
                            </span> {{ qualityData.pneumococcalVac | date:'MM/dd/yy' }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>