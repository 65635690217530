<div class="dropdown u-max-height--largest processes-dropdown">
  <div class="dynamicBody padding text-center">
    <span class="u-italic" *ngIf="processes?.length === 0">No items to show</span>
    <div *ngFor="let process of processes; last as isLast" class="process-container">
      <div class="text-left padding-bottom--smaller padding-top--smaller">{{process.title}} <span
          class="u-opacity--lighter percentage-complete"
          *ngIf="!process?.status?.isComplete && !process?.status?.isFailed">{{process.status?.completionPercentage}}%</span>
      </div>
      <coach-header-process-progress [process]="process"></coach-header-process-progress>
    </div>
  </div>
</div>