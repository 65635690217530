import { Component, Input, OnInit } from '@angular/core';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { Patient } from '@shared/models/patient';
import { PatientService } from '@shared/services/patient/patient.service';
import moment from 'moment';

@Component({
  selector: 'coach-patient-quality-opportunities',
  templateUrl: './patient-quality-opportunities.component.html',
  styleUrls: ['./patient-quality-opportunities.component.scss']
})
export class PatientQualityOpportunitiesComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  qualityData: { [key: string]: string } = {};
  constructor(private _patientService: PatientService) { 
    
  }

  ngOnInit(): void { 
  }
  ngOnChanges(): void {
    this.getPreventiveData();
  }
  async getPreventiveData() {
    this.qualityData = await this._patientService?.getTrackPatientFacesheet(
      this.patient?.trackPatient.chPatId,
      'preventive'
    )
    this.qualityData.colonoscopy_timePeriod = this.getDateDifference(this.qualityData.colonoscopy)
    this.qualityData.fluVac_timePeriod = this.getDateDifference(this.qualityData.fluVac) 
    this.qualityData.mammogram_timePeriod = this.getDateDifference(this.qualityData.mammogram) 
    this.qualityData.pneumococcalVac_timePeriod = this.getDateDifference(this.qualityData.pneumococcalVac) 
  } 
  getDateDifference(date: string): string {
    let result = '';
    var diffDuration = moment.duration(moment().diff(moment(date, "MM/DD/YYYY")));
    let years = diffDuration.years()
    let months = diffDuration.months()
    let days = diffDuration.days() 
    if (years) {
      result += `${years}y`
    }
    if (months) {
      result += `${result.length > 0 ? ' ' : ''}${months}m`
    }
    if (!years && !months && days) {
      result = "<1mo"
    }
    return result.length ? result + " ago" : "No Record Found";
  };
  public getFormattedName(name: string): string {
    if (name.indexOf(',') != -1) {
      let segments = name.split(',');
      let reformattedName = segments[1] + ' ' + segments[0];
      return this.toTitleCase(reformattedName.trim());
    }
    else {
      return this.toTitleCase(name);
    }
  }
  toTitleCase(str: string): string {
    if(!str) return '';
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
