import {Component, Input, SimpleChanges} from '@angular/core';
import {AwvService} from '@api/care-orch/services/awv.service';
import {CareOrchestrationConstants} from '@shared/models/module-constants';
import {WorkflowHistoryChange} from '@api/care-orch/models/workflow-history-change';
import {ChangeTypes} from '@api/care-orch/models/change-types';
import {SchedulingActivity} from '@api/care-orch/models/scheduling-activity';
import {OutcomeType} from '@api/care-orch/models/outcome-type';
import {ScheduledOutcome} from '@api/care-orch/models/scheduled-outcome';
import {AwvWorkflow} from '@api/care-orch/models/awv-workflow';
import {RemovedOutcome} from '@api/care-orch/models/removed-outcome';
import {CompletedOutcome} from '@api/care-orch/models/completed-outcome';
import {
  PatientAwvUtilities
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/patient-awv-utilities.component';
import {AuthService} from '@shared/services/auth-service/auth.service';
import {ReminderActivity} from '@api/care-orch/models/reminder-activity';
import {PatientWasRemindedOutcome} from '@api/care-orch/models/patient-was-reminded-outcome';
import {PatientDidNotAnswerOutcome} from '@api/care-orch/models/patient-did-not-answer-outcome';
import {CallBackOutcome} from '@api/care-orch/models/call-back-outcome';
import {AttendedActivity} from '@api/care-orch/models/attended-activity';
import {PatientDidNotAttendOutcome} from '@api/care-orch/models/patient-did-not-attend-outcome';
import {PatientAttendedOutcome} from '@api/care-orch/models/patient-attended-outcome';
import {SubmissionInfo} from '@api/care-orch/models/submission-info';
import {HistorySourceType} from '@api/care-orch/models/history-source-type';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import {UserDetailService} from '@api/user-detail/services/user-detail.service';
import {UsersDetailsResponse} from '@api/user-detail/models/users-details-response';
import _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {AwvWorkflowHistoryPaged} from '@api/care-orch/models/awv-workflow-history-paged';

export interface History {
  recordedChange: WorkflowHistoryChange;
  displayName: string;
  changeType: ChangeTypes;
  description: string;
  comment: string;
}

const userSources = [HistorySourceType.PepSelfReported, HistorySourceType.CareOrchSelfReported]

@Component({
  selector: 'coach-patient-awv-history',
  templateUrl: './patient-awv-history.component.html',
  styleUrls: ['./patient-awv-history.component.scss']
})
export class PatientAwvHistoryComponent {
  @Input() public patientId: string;
  @Input() public awvWorkflow: AwvWorkflow;

  history: History[] = [];
  loadingHistory: History[] = [];
  usersDetailsResponse: UsersDetailsResponse;
  workflowHistoryPagedResponse: AwvWorkflowHistoryPaged;
  userIds = Array<number>();
  coachUser = CareOrchestrationConstants.coachUser;
  isLoading = false;
  constructor(private awvService: AwvService, private authService: AuthService, private userDetailService: UserDetailService, private toastr: ToastrService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (this.patientId) {
      await this.fetchHistory();
    } else {
      this.history = [];
      this.loadingHistory = [];
    }
  }

  private async fetchHistory() {
    let fetchedHistory = [];
    this.loadingHistory = [];
    this.history = [];
    let remainingPages = 1;
    let currentPage = 1;
    this.isLoading = true;

    while (remainingPages > 0) {
      const pagedHistory = await this.getNextHistoryPage(currentPage);
      remainingPages = pagedHistory.pageCount - currentPage;
      // for each pageHistory.results we want to extract the userId if we don't already know about the userID
      pagedHistory.results.forEach( (element) => {
        const submissionInfo = element.submissionInfo;
        const userId = submissionInfo.submittedByUserId;
        if (!_.isNil(userId) && !this.userIds.includes(userId)){
          this.userIds.push(userId);
        }
      });
      fetchedHistory = fetchedHistory.concat(pagedHistory.results
        .map(change => this.enrichChange(change)));
      currentPage++;
    }
    this.loadingHistory = fetchedHistory.reverse();
    // Make one batch call to the userDetails service with the list of ids
    if (!_.isEmpty(this.userIds)){
      await this.callUsersDetails();
    }
    else {
      this.history = this.loadingHistory;
    }
    this.isLoading = false;
  }

  private enrichChange(change: WorkflowHistoryChange): History {
    const recordedChange = change;
    const displayName = this.getDisplayName(change.submissionInfo);
    const changeType = change.type;
    let description = '';
    let comment: string;

    switch (change.type) {
      case ChangeTypes.Create:
        description = 'Created AWV Opportunity';
        break;

      case ChangeTypes.Update:
        const updatedActivity = change.activity;

        if (updatedActivity == null && change.legacyAwvNote != null) {
          description = 'Added Comment';
          comment = change.legacyAwvNote;
        } else {
          switch (updatedActivity.type) {
            case 'SchedulingActivity':
              const schedulingActivity = updatedActivity as SchedulingActivity;
              switch (schedulingActivity.outcome.type) {
                case OutcomeType.ScheduledOutcome:
                  const scheduledOutcome = schedulingActivity.outcome as ScheduledOutcome;
                  description = 'AWV scheduled for ' + WorkflowDateTimeUtil.dateTimeStringToUSDateOnlyString(scheduledOutcome.properties.date);
                  comment = scheduledOutcome.properties.comment;
                  break;
                case OutcomeType.CompletedOutcome:
                  const completedOutcome = schedulingActivity.outcome as CompletedOutcome;
                  description = 'AWV completed on ' + WorkflowDateTimeUtil.dateTimeStringToUSDateOnlyString(completedOutcome.properties.date);
                  comment = completedOutcome.properties.comment;
                  break;
                case OutcomeType.CallBackOutcome:
                  const callbackOutcome = schedulingActivity.outcome as CallBackOutcome;
                  description = 'Outreach attempted';
                  comment = callbackOutcome.properties.comment;
                  break;
                case OutcomeType.RemovedOutcome:
                  const removedOutcome = schedulingActivity.outcome as RemovedOutcome;
                  description = 'Removed from list - ' + PatientAwvUtilities.removedReasonToString(removedOutcome.properties.reason);
                  comment = removedOutcome.properties.comment;
                  break;
                case OutcomeType.DeferredToSignifyOutcome:
                  description = 'Patient was added to the In-Home AWV list';
                  break;
              }
              break;
            case 'ReminderActivity':
              const reminderActivity = updatedActivity as ReminderActivity;
              switch (reminderActivity.outcome.type) {
                case OutcomeType.PatientWasRemindedOutcome:
                  const patientWasRemindedOutcome = reminderActivity.outcome as PatientWasRemindedOutcome;
                  description = 'Patient reminded of appointment';
                  comment = patientWasRemindedOutcome.properties.comment;
                  break;
                case OutcomeType.PatientDidNotAnswerOutcome:
                  const patientDidNotAnswerOutcome = reminderActivity.outcome as PatientDidNotAnswerOutcome;
                  description = 'Patient did not answer';
                  comment = patientDidNotAnswerOutcome.properties.comment;
                  break;
              }
              break;
            case 'AttendedActivity':
              const attendedActivity = updatedActivity as AttendedActivity;
              switch (attendedActivity.outcome.type) {
                case OutcomeType.PatientAttendedOutcome:
                  const attendedOutcome = attendedActivity.outcome as PatientAttendedOutcome;
                  description = 'AWV completed on ' + WorkflowDateTimeUtil.dateTimeStringToUSDateOnlyString(attendedOutcome.properties.completedDate);
                  comment = attendedOutcome.properties.comment;
                  break;
                case OutcomeType.PatientDidNotAttendOutcome:
                  const patientDidNotAttendOutcome = attendedActivity.outcome as PatientDidNotAttendOutcome;
                  description = 'Patient did not attend AWV';
                  comment = patientDidNotAttendOutcome.properties.comment;
                  break;
              }
              break;
          }
        }
        break;

      case ChangeTypes.Rollback:
        description = 'Deleted AWV activity';
        break;

      case ChangeTypes.Edit:
        description = 'Updated AWV Details';
        const editedActivity = change.activity;
        switch (editedActivity.type) {
          case 'SchedulingActivity':
            const schedulingActivity = editedActivity as SchedulingActivity;
            switch (schedulingActivity.outcome.type) {
              case OutcomeType.ScheduledOutcome:
                const scheduledOutcome = schedulingActivity.outcome as ScheduledOutcome;
                comment = scheduledOutcome.properties.comment;
                break;
              case OutcomeType.CompletedOutcome:
                const completedOutcome = schedulingActivity.outcome as CompletedOutcome;
                comment = completedOutcome.properties.comment;
                break;
              case OutcomeType.CallBackOutcome:
                const callbackOutcome = schedulingActivity.outcome as CallBackOutcome;
                comment = callbackOutcome.properties.comment;
                break;
              case OutcomeType.RemovedOutcome:
                const removedOutcome = schedulingActivity.outcome as RemovedOutcome;
                comment = removedOutcome.properties.comment;
                break;
            }
            break;
          case 'ReminderActivity':
            const reminderActivity = editedActivity as ReminderActivity;
            switch (reminderActivity.outcome.type) {
              case OutcomeType.PatientWasRemindedOutcome:
                const patientWasRemindedOutcome = reminderActivity.outcome as PatientWasRemindedOutcome;
                comment = patientWasRemindedOutcome.properties.comment;
                break;
              case OutcomeType.PatientDidNotAnswerOutcome:
                const patientDidNotAnswerOutcome = reminderActivity.outcome as PatientDidNotAnswerOutcome;
                comment = patientDidNotAnswerOutcome.properties.comment;
                break;
            }
            break;
          case 'AttendedActivity':
            const attendedActivity = editedActivity as AttendedActivity;
            switch (attendedActivity.outcome.type) {
              case OutcomeType.PatientAttendedOutcome:
                const patientAttendedOutcome = attendedActivity.outcome as PatientAttendedOutcome;
                comment = patientAttendedOutcome.properties.comment;
                break;
              case OutcomeType.PatientDidNotAttendOutcome:
                const patientDidNotAttendOutcome = attendedActivity.outcome as PatientDidNotAttendOutcome;
                comment = patientDidNotAttendOutcome.properties.comment;
                break;
            }
            break;
        }
        break;
    }

    return {
      recordedChange,
      displayName,
      changeType,
      description,
      comment
    };
  }

  private async getNextHistoryPage(pageNumber: number) {
    const obsWorkflowHistoryPagedResponse = await this.awvService.getWorkflowHistoryPaged({
      programId: CareOrchestrationConstants.AwvProgramId,
      chPatId: parseInt(this.patientId),
      pageParameters: {
        pageNumber,
        pageSize: CareOrchestrationConstants.FetchAwvWorkflowHistoryPageSize
      }
    });
    try {
      this.workflowHistoryPagedResponse = await obsWorkflowHistoryPagedResponse.toPromise();
    } catch (e) {
        this.toastr.error(`${e.status}: ${e.message}`, CareOrchestrationConstants.workflowHistoryErrorMessageFrontend);
        this.isLoading = false;
    }
    return this.workflowHistoryPagedResponse;
  }

  private getDisplayName(submissionInfo: SubmissionInfo): string {
    switch (submissionInfo.submittedBySource) {
      case HistorySourceType.PepSystem:
      case HistorySourceType.CareOrchSystem:
        return 'System';
      case HistorySourceType.CareOrchAdmin:
        return 'Admin';
      case HistorySourceType.CmsClaimsData:
        return 'CMS Claims Data';
      case HistorySourceType.EhrClinicalData:
        return 'EHR Feed';
      case HistorySourceType.InHomeAwv:
        return 'In Home AWV';
      case HistorySourceType.PepSelfReported:
        return this.coachUser;
      case HistorySourceType.CareOrchSelfReported:
        default:
        return null;
    }
  }

  private async callUsersDetails(): Promise<void>{
    const obsUsersDetailsResponse = await this.userDetailService.usersDetails({body: {userIds: this.userIds}});
    obsUsersDetailsResponse.subscribe(UsersDetailResponse => {
      this.usersDetailsResponse = UsersDetailResponse;
      this.mapUsersDetails();
    }, error => this.toastr.error(`${error.status}: ${error.message}`, CareOrchestrationConstants.userDetailsErrorMessageFrontend));
  }

  private mapUsersDetails(): void {
    // map usersDetails API Response object to each element in the history
    this.loadingHistory.forEach((element) => {
      const userId = element.recordedChange.submissionInfo.submittedByUserId;
      if (userId == null || !userSources.includes(element.recordedChange.submissionInfo.submittedBySource)) {
        return;
      }
      const userDetails = this.usersDetailsResponse.usersDetails[userId.toString()];
      if (userDetails !== undefined){
        const firstName = userDetails.firstName;
        const lastName = userDetails.lastName;
        const displayName = userDetails.displayName;
        const email = userDetails.email;
        if (firstName.length !== 0 && lastName.length !== 0) {
          element.displayName = firstName + ' ' + lastName;
          return;
        }
        if (displayName.length !== 0) {
          element.displayName = displayName;
          return;
        }
        if (email.length !== 0) {
          element.displayName = email;
          return;
        }
        else {
          element.displayName = 'Me';
        }
      }
    });
    this.history = this.loadingHistory;
  }
}
