import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { ICurrentRoute } from '@shared/models/activated-route';

@Injectable({
  providedIn: 'root',
})
export class ActivatedRouteService {
  public activatedRoute$: BehaviorSubject<ActivatedRoute>;
  public currentUrl$: BehaviorSubject<UrlTree>;
  public currentRoute$: BehaviorSubject<ICurrentRoute>;

  constructor(private _router: Router) {
    this.activatedRoute$ = new BehaviorSubject(null);
    this.currentUrl$ = new BehaviorSubject(null);
    this.currentRoute$ = new BehaviorSubject(null);
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((e: NavigationEnd) => {
        if (e instanceof NavigationEnd) {
          var routerState = this._router.routerState;
          this.activatedRoute$.next(routerState.root);
          this.currentUrl$.next(
            this._router.parseUrl(routerState.snapshot.url)
          );
          this.getCurrentRoute(routerState.root);
        }
      });
  }

  public getCurrentRoute(route: ActivatedRoute): void {
    const r = route;
    const rc = r.routeConfig;
    if (r.firstChild) {
      this.getCurrentRoute(r.firstChild);
    } else {
      this.currentRoute$.next({ label: rc?.data?.label, path: rc?.path });
    }
  }
}
