import { Component, OnInit } from '@angular/core';
import { DocRepo } from '@shared/models/docRepo/list';
import { ImproveFeatureNames } from '@shared/models/module-constants';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';

@Component({
  selector: 'coach-improve-contacts',
  templateUrl: './improve-contacts.component.html',
  host: {
    class: 'row u-fill'
  }
})
export class ImproveContactsComponent implements OnInit {

  public docRepoData: DocRepo[];
  public tabName: string = ImproveFeatureNames.Contacts;

  constructor(
    private fileDownloadService: FileDownloadService
  ) { }

  ngOnInit(): void {
    this.fileDownloadService.generalFolderList$.subscribe(data => {
      if (data.length !== 0) {
        this.docRepoData = this.fileDownloadService
        .getChildrenByCollectionName(this.tabName, data);
      }
    });
  }
}
