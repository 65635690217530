import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ImproveRoutingModule } from './improve-routing.module';
import { ImproveComponent } from './improve.component';
import { ImproveSummaryComponent } from './improve-summary/improve-summary.component';
import { ImproveContactsComponent } from './improve-contacts/improve-contacts.component';
import { ImproveAcoBoardComponent } from './improve-aco-board/improve-aco-board.component';
import { ImproveSearchComponent } from './improve-search/improve-search.component';
import { ImproveSteeringCommitteeComponent } from './improve-steering-committee/improve-steering-committee.component';
import { ImprovePracticeImprovementComponent } from './improve-practice-improvement/improve-practice-improvement.component';
import { ImproveInformationTechnologyComponent } from './improve-information-technology/improve-information-technology.component';
import { ImproveEventsComponent } from './improve-events/improve-events.component';
import { DocRepoComponent } from './shared/doc-repo/doc-repo.component';
import { DocUploadComponent } from './shared/doc-upload/doc-upload.component';
import { DocRepoDirectoryComponent } from './shared/doc-repo/components/doc-repo-directory/doc-repo-directory.component';
import { DocRepoItemComponent } from './shared/doc-repo/components/doc-repo-item/doc-repo-item.component';
import { DocRepoFolderNavComponent } from './shared/doc-repo/components/doc-repo-folder-nav/doc-repo-folder-nav.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule } from '@angular/forms';
import { PrizmDocViewerWrapperComponent } from './shared/prizmdoc-viewer-wrapper/prizmdoc-viewer-wrapper.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VideoModule } from './shared/youtube-video/video.module';
import { MyTeamComponent } from './shared/doc-repo/components/my-team/my-team.component';
import { ClientContactsComponent } from './shared/doc-repo/components/client-contacts/client-contacts.component';
import { ClinicianRosterComponent } from './shared/doc-repo/components/clinician-roster/clinician-roster.component';
import { AgGridModule } from 'ag-grid-angular';
import { TeamMemberComponent } from './shared/doc-repo/components/my-team/team-member/team-member.component';
import { IframeTrackerDirective } from './shared/doc-repo/iframe-tracker.directive';
import { ContactsComponent } from './shared/doc-repo/tab-components/contacts/contacts.component';
import { SteeringCommitteeComponent } from './shared/doc-repo/tab-components/steering-committee/steering-committee.component';
import { AcoOnboardComponent } from './shared/doc-repo/tab-components/aco-onboard/aco-onboard.component';
import { PracticeImprovementComponent } from './shared/doc-repo/tab-components/practice-improvement/practice-improvement.component';
import { InformationTechnologyComponent } from './shared/doc-repo/tab-components/information-technology/information-technology.component';
import { EventsComponent } from './shared/doc-repo/tab-components/events/events.component';

@NgModule({
  declarations: [
    ImproveComponent,
    ImproveSummaryComponent,
    ImproveSearchComponent,
    ImproveContactsComponent,
    ImproveAcoBoardComponent,
    ImproveSteeringCommitteeComponent,
    ImprovePracticeImprovementComponent,
    ImproveInformationTechnologyComponent,
    ImproveEventsComponent,
    DocRepoComponent,
    DocRepoDirectoryComponent,
    DocRepoItemComponent,
    DocRepoFolderNavComponent,
    DocUploadComponent,
    PrizmDocViewerWrapperComponent,
    DocUploadComponent,
    MyTeamComponent,
    ClientContactsComponent,
    ClinicianRosterComponent,
    TeamMemberComponent,
    IframeTrackerDirective,
    ContactsComponent,
    SteeringCommitteeComponent,
    AcoOnboardComponent,
    PracticeImprovementComponent,
    InformationTechnologyComponent,
    EventsComponent
  ],
  imports: [
    SharedModule,
    ImproveRoutingModule,
    FileUploadModule,
    FormsModule,
    YouTubePlayerModule,
    VideoModule,
    AgGridModule
  ]
})
export class ImproveModule {}
