<div class="icon-wrapper" style="height: 100%">
    <div style="width: 24px" *ngIf="!splittedFiles.summary.length"></div>
    <div class="pseudo-tooltip u-cursor--pointer" *ngIf="splittedFiles.summary.length" title="Assessment, PPP, PCP Summary">
        <coach-icon *ngIf="downloadStatus.summary != 'downloading'" iconColor="tile"
            (click)="downloadFile(summaryFileIds, 'summary')"
            [iconName]="downloadStatus.summary == 'downloaded' ? 'check-circle-2' : 'arrow-down-circle-filled'">
        </coach-icon>
        <div class="loader" style="width: 24px; height: 24px" *ngIf="downloadStatus.summary == 'downloading'"></div>
    </div>
    <div style="width: 24px" *ngIf="!splittedFiles.CMR.length"></div>

    <div class="has-tooltip u-cursor--pointer" *ngIf="splittedFiles.CMR.length"
        title="Case Management Referral">
        <coach-icon *ngIf="downloadStatus.CMR != 'downloading'" iconColor="yellow"
            (click)="downloadFile(CMRFileIds, 'CMR')"
            [iconName]="downloadStatus.CMR == 'downloaded' ? 'check-circle-2' : 'arrow-down-circle-filled'">
        </coach-icon>
        <div class="loader" style="width: 24px; height: 24px" *ngIf="downloadStatus.CMR == 'downloading'"></div>
    </div>
</div>