<coach-header-title-portal>
    <coach-header-breadcrumbs [showBreadcrumbs]="true"
        class="row--verticalCenter u-preserve"></coach-header-breadcrumbs>
</coach-header-title-portal>

<ag-grid-angular class="ag-theme-material summary-grid dynamicBody" [pagination]="true" [paginationAutoPageSize]="true"
    multiSortKey="ctrl" [rowData]="rowData | async" [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)" tooltipShowDelay="500"
    (filterChanged)="filterChanged($event)">
</ag-grid-angular>

<coach-export-report-button class="export-button" [gridApi]="gridApi"
    reportName="PatientDetailReport"></coach-export-report-button>

<coach-hierarchy-required pageName="Chronic Conditions Dashboard reports" [requiredTier]="2"></coach-hierarchy-required>