import { Pipe, PipeTransform } from '@angular/core';
import { getNameFromMonthNumber } from '@shared/utilities';

/**
 * Returns a formatted month value given a corresponding number (e.g. 0 => Jan, 1 => Feb, etc.)
 */
@Pipe({
  name: 'monthNumber'
})
export class MonthNumberPipe implements PipeTransform {

  transform(value: number, format: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined = undefined): string {
    return getNameFromMonthNumber(value, format);
  }
}
