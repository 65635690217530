<div
  style="
    font-size: x-small;
    margin: 1.75rem;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 24px;
  "
  #mainDiv
>
  <div *ngIf="showInstructions" style="display: inline-flexbox; cursor: pointer;">
    <p (click)="toggleDisplay()" style="margin-left: 2rem; font-size: xx-small; font-weight: bolder">
      For this Rx to be 340B eligible you need...
    </p>
    <coach-icon
      style="margin-left: 34rem; margin-top: -3rem; cursor: pointer"
      [iconName]="currentArrow"
      iconColor="currentColor"
      (click)="toggleDisplay()"
    ></coach-icon>
  </div>

  <div style="width: 75%; margin: auto" [hidden]="isHidden">
    <coach-icon
      style="display: inline-block"
      iconName="file"
      iconColor="currentColor"
      class="margin-right--smaller"
    ></coach-icon>

    <div style="margin-left: 3rem; margin-top: -2.5rem; font-style: italic">
      Visit note from the external prescriber that supports this Rx before
      {{fillDate}}<br />
    </div>

    <p></p>

    <coach-icon
      iconName="trending-up"
      iconColor="currentColor"
      class="margin-right--smaller"
    ></coach-icon>

    <div style="margin-left: 3rem; margin-top: -2.5rem; font-style: italic">
      Visit and referral for a related diagnosis from a Covered Entity before
      {{fillDate}}
    </div>

    <p></p>

    <coach-icon
      iconName="switch-view"
      iconColor="currentColor"
      class="margin-right--smaller"
    ></coach-icon>

    <div style="margin-left: 3rem; margin-top: -2.5rem; font-style: italic">
      Follow-up plan that shows you are coordinating care
    </div>
  </div>
</div>
