import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRouteService } from '@shared/services/activated-route/activated-route.service';
import { BehaviorSubject } from 'rxjs';
import { ICurrentRoute } from '@shared/models/activated-route';
import { CoachTabs } from './coach-tabs';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { KillSubscriptions } from '../kill-subscriptions';
import { takeUntil } from 'rxjs/operators';
import { SideBarToggleService } from '@shared/services/sidebar-toggle/sidebar-toggle.service';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { Router } from '@angular/router';

/**
 * Component for displaying tab navigation.
 *
 * @example
 * <coach-tabs
 *                [tabsData]="tabsData">
 * </coach-tabs>
 */
@Component({
  selector: 'coach-tabs',
  templateUrl: './coach-tabs.component.html',
  host: {
    class: 'coach-tabs',
  },
})
export class CoachTabsComponent extends KillSubscriptions implements OnInit {
  private _tabsData: CoachTabs[];
  /** (Required) Array containing the tab definitions  */
  @Input() set tabsData(value: CoachTabs[]) {
    this._tabsData = value;
    this.updateTabs();
  }
  get tabsData(): CoachTabs[] {
    return this._tabsData;
  }

  availableTabs: CoachTabs[];

  public isTabDropdownOpen = false;
  public currentRoute$: BehaviorSubject<ICurrentRoute>;
  public activeView: ICurrentRoute;
  public isSidebarOpen: boolean;
  public activeTab: string;

  constructor(
    private _activatedRoute: ActivatedRouteService,
    private userAccessService: UserAccessService,
    private sidebarToggleService: SideBarToggleService,
    protected featureFlagService: FeatureFlagService,
    private router: Router
  ) {
    super();
    this.sidebarToggleService.sidebarToggle
      .pipe(takeUntil(this.killTrigger))
      .subscribe((isOpen) => {
        this.isSidebarOpen = isOpen;
      });

    this.currentRoute$ = this._activatedRoute.currentRoute$;
    this.currentRoute$
      .pipe(takeUntil(this.killTrigger))
      .subscribe((whenChanged) => (this.activeView = this.currentRoute$.value));
    this.userAccessService.currentAccess$
      .pipe(takeUntil(this.killTrigger))
      .subscribe((access) => {
        this.updateTabs();
      });

    this.featureFlagService.featureFlags$.pipe(takeUntil(this.killTrigger)).subscribe(() => { this.updateTabs(); });
  }

  updateTabs(): void {
    if (
      this.userAccessService.currentAccess$.value != null &&
      this.tabsData != null
    ) {
      this.availableTabs = this.tabsData?.filter((tab) => {
        const hasFeatureId = tab.featureId == null ||
          this.userAccessService.currentAccess$.value.modules.some((m) =>
            m.features.some((f) => f.featureId === tab.featureId)
          );
        const hasFeatureFlag = tab.featureFlag == null || this.featureFlagService.hasFeatureFlag(tab.featureFlag);
        const hideIfFeatureFlagIsActive: boolean = this.featureFlagService.hasFeatureFlag(tab.hideIfFeatureFlagIsActive)
        return hasFeatureId && hasFeatureFlag && !hideIfFeatureFlagIsActive
      });
    }
  }

  ngOnInit(): void {
    this.activeView = this.currentRoute$.value;
    // Determine initial tab based on current URL
    const urlSegments = this.router.url.split('/');
    this.activeTab = this.tabsData.find(
      x => x.routerLink.some(
        routerLink => urlSegments.some(segment => segment === routerLink)
      )
    )?.name ?? this.activeView?.label;
  }

  public switchTab(selectedTab: string): void {
    this.activeTab = selectedTab;
    this.isTabDropdownOpen = false;
  }
}
