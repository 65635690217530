<ng-container *ngIf="isAddEditFormActive; else PromotingInteropDashboard">
  <form [formGroup]="submitPI" (ngSubmit)="onSubmit(true)" class="page-content">
    <div class="page-content-main u-background-color--dark--5">
      <div class="dynamicBody row">
        <div class="queue u-position--relative animation--content--fadeIn"
          [ngClass]="isFormQueueOpen ? 'is-open border-right' : 'is-closed'">
          <button class="pullTab top--xs" (click)="toggleFormQueueOpen()" [ngClass]="{ 'is-open': isFormQueueOpen }">
            <coach-icon iconName="chevrons-right"></coach-icon>
          </button>
          <div class="header">
            <div class="titles t3">
              <div class="title">{{ copy.title }}</div>
              <div class="subtitle row--baseline">
                <span class="u-fill u-bold">{{copy.submissionPeriod}}</span>
                <span class="label margin-right--smaller">Due</span>
                <span>{{dueDate | date:'MMM dd'}}</span>
              </div>
            </div>
          </div>
          <div class="dynamicBody padding margins-bottom--large">
            <div class="alert is-info padding--small u-font-size--small">
              {{ copy.formNoteInternal }}
            </div>

            <div class="group">
              <label class="label" for="piPractice">
                Practice(s)
                <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                  iconColor="primary" (click)="showInfoWithText(practiceHelpText)"></coach-icon>
              </label>
              <select class="field" name="piPractice" id="piPractice" formControlName="practices"
                (change)="practiceSelected($event.target.value)" required autofocus>
                <option *ngIf="practices != null" [ngValue]="null" disabled selected>Select Practice</option>
                <option *ngIf="practices == null" [value]="null" disabled>
                  No Available Practices
                </option>

                <option *ngFor="let practice of practices" [value]="practice.Fac_ID">
                  {{ practice.Name }}
                </option>
              </select>
            </div>

            <div class="row--right row--verticalCenter"> 
              <coach-icon class="icon--selectable margin-right--smaller" iconName="info" iconSize="small"
                iconColor="primary" (click)="showInfoWithText(exemptHelpText)"></coach-icon>
              <input class="toggle" id="togglea" type="checkbox" formControlName="isSpecialExemption"
                (change)="isSpecialStatusChanged()" />
              <label for="togglea"> Special Status Exemption </label>
            </div>

            <div class="group" *ngIf="submitPI.controls['isSpecialExemption'].value">
              <label class="label" for="specialStatusID">
                Exemption Special Status
                <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                  iconColor="primary" (click)="showInfoWithText(specialStatusHelpText)"></coach-icon>
              </label>
              <select class="field" formControlName="SpecialStatus" name="specialStatusID" id="specialStatusID"
                (change)="specialExemptionChange()">
                <option [value]="null" disabled>Select Special Status</option>
                <option *ngFor="let specialStatus of specialStatusList" [value]="specialStatus.specialStatusID">
                  {{ specialStatus.specialStatus }}
                </option>
              </select>
              <div *ngIf="
                  submitPI.get('SpecialStatus').invalid &&
                  (submitPI.get('SpecialStatus').dirty ||
                    submitPI.get('SpecialStatus').touched)
                ">
                <div class="u-italic u-color--danger padding-top--small">
                  Exemption Special Status required
                </div>
              </div>
            </div>


            <div class="group">
              <label class="label" for="ehrvendor">
                EHR Vendor
                <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                  iconColor="primary" (click)="showInfoWithText(ehrVendorHelpText)"></coach-icon>
              </label>
              <select class="field" name="ehrvendor" id="ehrvendor" formControlName="EHRVendor">
                <option [value]="null" disabled>Select EHR Vendor</option>
                <option *ngFor="let vendor of ehrVendors" [value]="vendor">
                  {{ vendor }}
                </option>
              </select>
            </div>

            <div class="group" *ngIf="!submitPI.controls['isSpecialExemption'].value">                
                <coach-submit-interoperability-date-range class="field"
                  [questionInfo]="formQuestionConstants.Q83Date"
                  [parentForm]="submitPI"
                  (showHelpText)="toggleInfoText($event)"
                  >
                </coach-submit-interoperability-date-range>
            </div>

            <div class="group" *ngIf="!submitPI.controls['isSpecialExemption'].value">
              <div class="label margin-bottom--smaller">
                Upload PI Measures Report
                <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small"
                  iconColor="primary" (click)="showInfoWithText(uploadHelpText)"></coach-icon>
              </div>
              <div class="field">
                <div class="fileUploadWrapper clump padding--small u-width--full row--verticalCenter">
                  <div class="inputButtonCombo u-preserve margin-right--small">
                    <input type="file" (change)="onFileChange($event)" />
                    <input type="hidden" formControlName="uploadFile" />
                    <button class="button is-small" [ngClass]="{'is-primary': uploadedFileName === 'No file chosen'}">
                      {{ uploadedFileName === "No file chosen" ? "Upload" : "Change"}}
                      File
                    </button>
                  </div>
                  <div>{{ uploadedFileName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dynamicBody" [ngClass]="{ column: submitPI.controls['isSpecialExemption'].value }">
          <div *ngIf="submitPI.controls['isSpecialExemption'].value"
            class="u-fill row u-center u-italic u-opacity--lighter">
            You are exempt from submitting interoperability data at this time
            due to the special status selected.
          </div>
          <div class="submitForms u-background-color--white" *ngIf="!submitPI.controls['isSpecialExemption'].value">
            <coach-submit-interoperability-security-risk class="submitFormBlock clump border--4"
              [parentForm]="submitPI" (showHelpText)="toggleInfoText($event)"
              [questionInfo]="formQuestionConstants.Q8SecurityRisk">
            </coach-submit-interoperability-security-risk>

            <coach-submit-interoperability-question class="submitFormBlock clump border--4"
            [questionInfo]="formQuestionConstants.Q9ePrescibe"
            [parentForm]="submitPI"
            [maxPoints]="ePrescribeMax"
              (showHelpText)="toggleInfoText($event)"
              (checkBoxChanged)="exclusionRequest($event)">
            </coach-submit-interoperability-question>

            <coach-submit-interoperability-question class="submitFormBlock clump border--4"
              [questionInfo]="formQuestionConstants.Q10SupportSend"
               [parentForm]="submitPI"
               [maxPoints]="supportSendMax"
              (showHelpText)="toggleInfoText($event)"
              (checkBoxChanged)="exclusionRequest($event)">
            </coach-submit-interoperability-question>

            <coach-submit-interoperability-question class="submitFormBlock clump border--4"
              [questionInfo]="formQuestionConstants.Q11SupportReceive"
              [parentForm]="submitPI"
              [maxPoints]="supportReceiveMax"
              (showHelpText)="toggleInfoText($event)"
              (checkBoxChanged)="exclusionRequest($event)">
            </coach-submit-interoperability-question>

            <coach-checkbox-question class="submitFormBlock clump border--4" [parentForm]="submitPI"
              [questionInfo]="formQuestionConstants.Q71HealthInformationExchange"
              [maxPoints]="hieMax"
              (showHelpText)="toggleInfoText($event)" (checkBoxChanged)="hieChanged($event)">
            </coach-checkbox-question>

            <coach-submit-interoperability-yes-no class="submitFormBlock clump border--4"
            [parentForm]="submitPI" (showHelpText)="toggleInfoText($event)"
            [questionInfo]="formQuestionConstants.Q82TEFCA"
            [maxPoints]="tefcaMax"
            (radioButtonChanged)="exclusionRequestTEFCA($event)">
            </coach-submit-interoperability-yes-no>

            <coach-submit-interoperability-registry-question class="submitFormBlock clump border--4"
              [interopForm]="submitPI">
            </coach-submit-interoperability-registry-question>

            <coach-submit-interoperability-question class="submitFormBlock clump border--4"
              [questionInfo]="formQuestionConstants.Q12ProvideAccess"
              [parentForm]="submitPI"
              [maxPoints]="providePatientsMax"
               (showHelpText)="toggleInfoText($event)">
            </coach-submit-interoperability-question>

            <coach-submit-interoperability-yes-no class="submitFormBlock clump border--4"
            [parentForm]="submitPI" (showHelpText)="toggleInfoText($event)"
            [questionInfo]="formQuestionConstants.Q14DrugMonitor"
            [maxPoints]="drugMonitorsMax"
            (checkBoxExclusionChanged)="exclusionRequestCheckBox($event)">
            </coach-submit-interoperability-yes-no>

            <coach-submit-interoperability-security-risk class="submitFormBlock clump border--4"
              [parentForm]="submitPI" (showHelpText)="toggleInfoText($event)"
              [questionInfo]="formQuestionConstants.Q77HighPriorityGuideSAFER">
            </coach-submit-interoperability-security-risk>

            <coach-checkbox-question class="submitFormBlock clump border--4" [parentForm]="submitPI"
              [questionInfo]="formQuestionConstants.Q78BonusRegistryReporting"
              [maxPoints]="formQuestionConstants.Q78BonusRegistryReporting.maxPoints" (showHelpText)="toggleInfoText($event)">
            </coach-checkbox-question>
          </div>
        </div>
      </div>
      <div class="footer margins-left--small">
        <div class="u-fill row u-center">
          <b class="margin-right--smaller">{{ totalPoints }}</b> out of 100
          points
        </div>
        <button class="button is-unstyled" type="button" [disabled]="formSaving" (click)="cancel()">
          Cancel
        </button>
        <button class="button" type="button" [disabled]="formSaving" (click)="onSubmit(false)">
          Save as Draft
        </button>
        <button class="button is-primary" type="submit" [disabled]="!submitPI.valid || formSaving">
          Submit
        </button>
      </div>
      <!-- // TODO: pull out into shared component. -->
      <div class="overlay overlay--light is-open" *ngIf="isInfoTextOpen">
        <div class="modal margin u-max-width--larger" (click)="$event.stopPropagation()">
          <div class="dynamicBody padding--large">
            <p [innerHtml]="infoText"></p>

              <div class="margin-top margin-bottom" *ngIf="this.infoMeasureLinkFile != null">
                <div *ngFor="
                let modalLink of this.infoMeasureLinkFile">
                  <div class="text-center" *ngIf="modalLink.folderID==null && modalLink.entryID != null">
                    <a [routerLink]="['../../improve/practice-improvement']" [queryParams]="{
                        folder: copy.infoMeasureLinkFolder,
                        file: modalLink.entryID
                      }" target="_blank">{{modalLink.linkText}}</a>
                  </div>
                  <!-- For the scoring methodology folder option -->
                  <div class="text-center" *ngIf="modalLink.folderID ==null && modalLink.entryID == null">
                    <a [routerLink]="['../../improve/practice-improvement']" [queryParams]="{
                        folder: copy.infoScoringLinkFolder,
                        file: copy.infoScoringLinkFile
                      }" target="_blank">{{modalLink.linkText}}</a>
                  </div>

                  <div class="text-center" *ngIf="modalLink.folderID !=null && modalLink.entryID  != null">
                    <a [routerLink]="['../../improve/practice-improvement']" [queryParams]="{
                        folder: modalLink.folderID,
                        file: modalLink.entryID
                      }" target="_blank">{{modalLink.linkText}}</a>
                  </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <button class="button is-primary" (click)="toggleInfoText(null); $event.stopPropagation()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #PromotingInteropDashboard>
  <div class="page-content">
    <div class="queue u-position--relative animation--content--fadeIn"
      [ngClass]="isQueueOpen ? 'is-open border-right' : 'is-closed'">
      <button class="pullTab top--xs" (click)="toggleQueueOpen()" [ngClass]="{ 'is-open': isQueueOpen }"
        aria-label="Toggle Practice Improvement List Panel">
        <coach-icon iconName="chevrons-right"></coach-icon>
      </button>
      <div class="header">
        <div class="titles t3">
          <div class="title">{{ copy.overviewTitle }}</div>
          <div class="subtitle row--baseline">
            <span class="u-fill u-bold">{{ copy.submissionPeriod }}</span>
            <span class="label margin-right--smaller">Due</span>
            <span>{{dueDate | date:'MMM dd'}}</span>
          </div>
        </div>
      </div>
      <div class="dynamicBody padding">
        <h4 class="margin-top">Welcome!</h4>

        <p>
          {{ copy.instructionsPart1 }}
          <b>{{ copy.instructionsPart2 }}</b> {{ copy.instructionsPart3 }}
        </p>

        <p>
          {{ copy.instructionsPart4 }}
        </p>

        <div class="alert is-info margin-topBottom--larger padding--small">
          <span class="u-bolder">{{ copy.noteTextPart1 }}</span>
          {{ copy.noteTextPart2 }}
          <span class="u-bolder">{{ copy.noteTextPart3 }}</span>
          {{ copy.noteTextPart4 }}
        </div>
      </div>

      <div class="border-top--5 padding u-font-size--smallest u-opacity--light">
        {{ copy.disclaimer }}
      </div>
    </div>
    <div class="page-content-main">
      <div class="dynamicBody padding--larger padding-top--largest u-background-color--white">
        <div class="row--wrap row--baseline">
          <h3
            class="margin-top--none margin-right--large margin-bottom--large bean u-background-color--view u-color--white u-preserve">
            Step 1
          </h3>
          <div class="submit-stepHeader column">
            <h3 class="margin-top--none margin-bottom--small">
              Review Special Status Exemptions
            </h3>
            <p class="u-opacity--light">
              {{ copy.stepSpecialStatus1Text }}
            </p>
            <p class="u-opacity--light">

              {{ copy.stepSpecialStatus2Text }}
            </p>
          </div>
        </div>

        <table class="table--tight margin-topBottom--larger u-width--full">
          <thead>
            <tr>
              <th class="border-right--none">
                <h5 class="margin--none">Practice Name</h5>
              </th>
              <th class="border-right--none">
                <h5 class="margin--none">Exemption Status</h5>
              </th>
              <th class="border-right--none">
                <h5 class="margin--none">Remove</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="
                specialStatusSummary == null ||
                specialStatusSummary.length === 0
              ">
              <td colspan="3">
                <span class="u-opacity--lighter u-italic u-center u-display--flex">No Exemptions</span>
              </td>
            </tr>
            <tr *ngFor="let statusSummary of specialStatusSummary">
              <td>{{ statusSummary.Practice }}</td>
              <td class="u-display--flex">
                <select class="border--none u-color--interaction" name="exemptstatus"
                  [(ngModel)]="statusSummary.SpecialStatusID" (ngModelChange)="updateSpecialStatus(statusSummary)">
                  <option *ngFor="let specialStatus of specialStatusList" [ngValue]="specialStatus.specialStatusID">
                    {{ specialStatus.specialStatus }}
                  </option>
                </select>
                <input placeholder="Other Reason" class="margin-left"
                  *ngIf="statusSummary.SpecialStatusID === OtherSpecialStatusId" [(ngModel)]="statusSummary.OtherReason"
                  (ngModelChange)="updateSpecialStatus(statusSummary)" />
              </td>
              <td>
                <a [routerLink]="" (click)="removeConfirmation(statusSummary)">Remove</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row--wrap row--baseline padding-top--largest">
          <h3
            class="margin-top--none margin-right--large margin-bottom--large bean u-background-color--view u-color--white u-preserve">
            Step 2
          </h3>
          <div class="submit-stepHeader column">
            <h3 class="margin-top--none margin-bottom--small">
              Add/Edit Forms
            </h3>
            <p class="u-opacity--light">
              {{ copy.stepAddEditText }}
              <b>{{dueDate | date:'MMMM dd, yyyy'}}</b>
            </p>
          </div>
        </div>

        <div class="row margin-topBottom--larger padding-topBottom padding-right u-overflow-x--auto">
          <table class="table--tight u-width--full">
            <thead>
              <tr>
                <th class="border-right--none">
                  <h5 class="margin--none">Name</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Total Points</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Submitter</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Date/Time</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Status</h5>
                </th>
                <th class="border-right--none">
                  <h5 class="margin--none">Edit</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="formSubmissions == null || formSubmissions.length === 0">
                <td colspan="6">
                  <span class="u-opacity--lighter u-italic u-center u-display--flex">No Submissions</span>
                </td>
              </tr>
              <tr *ngFor="let submission of formSubmissions">
                <td>
                  {{ submission.Practice }}
                  <div *ngIf="submission.SpecialStatus != null" class="u-color--danger">
                    <b>Special Status</b>: {{ submission.SpecialStatus }}
                  </div>
                </td>
                <td>{{ submission.TotalPoints | number: "1.2-2" }}</td>
                <td>{{ submission.SubmitterEmail }}</td>
                <td>{{ submission.LastUpdateDateTime | date: "short" }}</td>
                <td>{{ submission.ISComplete ? "Complete" : "Pending" }}</td>
                <td>
                  <a [routerLink]="" (click)="editForm(submission)">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="footer">
        <div class="form-reportwrapper" *hasFeatureFlag="formsAdminFormFeatureFlag" >
          <button class="button is-primary" *hasFeature="formsAdminFormFeature"  (click)="formsReport()">Submission Status Report</button>
        </div>

        <button class="button is-primary" (click)="newSubmission()">
          + PI Form
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="showRemoveModal" class="overlay overlay--light is-open">
  <div class="modal">
    <div class="header">
      <div class="titles">
        <div class="title">Remove Special Status Exemption</div>
      </div>
    </div>
    <div class="dynamicBody padding">
      Are you sure you would like to remove this Special Status Exemption
      submission?
    </div>
    <div class="footer">
      <button class="button margin-right border--none" (click)="showRemoveModal = false">
        No
      </button>
      <button class="button is-primary" (click)="removeSubmission()">
        Yes, Remove
      </button>
    </div>
  </div>
</div>
