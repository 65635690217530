import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HierarchyTierService } from '../hierarchy/hierarchy-tier.service';

interface FolderLookupResult {
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class FolderLookupService {
  private currentTier: string;
  public hccvipFolderId$ = new BehaviorSubject<number>(null);
  public fromCaravanPHIFolderId$ = new BehaviorSubject<number>(null);
  public qualityCampaignsFolderId$ = new BehaviorSubject<number>(null);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private tierService: HierarchyTierService
  ) {
    this.tierService.currentTier$.subscribe((tier) => {
      if (tier && tier.selectedTierId !== this.currentTier) {
        this.currentTier = tier.selectedTierId;
        this.getHccvipFolderId();
        this.getFromCaravanPHIFolderId();
        this.getQualityCampaignsFolderId();
      }
    });
  }

  private async getHccvipFolderId(): Promise<void> {
    const authToken = this.authService.getToken();
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });
    const result = await this.httpClient
      .get<FolderLookupResult>(
        `${environment.coachApiUrl}/api/docRepo/hccvip/${this.currentTier}`,
        {
          responseType: 'json',
          headers: httpHeaders,
        }
      )
      .toPromise();
    if (result.id) {
      this.hccvipFolderId$.next(result.id);
    } else {
      this.hccvipFolderId$.next(null);
    }
  }

  private async getFromCaravanPHIFolderId(): Promise<void> {
    const authToken = this.authService.getToken();
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });
    const result = await this.httpClient
      .get<FolderLookupResult>(
        `${environment.coachApiUrl}/api/docRepo/folder/${this.currentTier}/fromcaravanphi`,
        {
          responseType: 'json',
          headers: httpHeaders,
        }
      )
      .toPromise();
    if (result.id) {
      this.fromCaravanPHIFolderId$.next(result.id);
    } else {
      this.fromCaravanPHIFolderId$.next(null);
    }
  }

  private async getQualityCampaignsFolderId(): Promise<void> {
    const authToken = this.authService.getToken();
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });
    const result = await this.httpClient
      .get<FolderLookupResult>(
        `${environment.coachApiUrl}/api/docRepo/folder/${this.currentTier}/specificFolderGeneric?folderName=Fall 2022&parentFolderName=Quality Campaigns&grandParentfolderName=From Caravan WITH PHI`,
        {
          responseType: 'json',
          headers: httpHeaders,
        }
      )
      .toPromise();
    if (result.id) {
      this.qualityCampaignsFolderId$.next(result.id);
    } else {
      this.qualityCampaignsFolderId$.next(null);
    }
  }
}
