<div
    class="padding-right--larger padding-left--larger padding-top dynamicBody u-background-color--white hcc-dashboard-summary">
    <div class="row dashboard-row">
        <div class="column u-width--full padding border-right">
            <ng-container *doesNotHaveFeatureFlag="hccEnhanced2FeatureFlag">
                <div class="titles t3">
                    <div class="title row--verticalCenter u-color--brand--1 u-display--flex">Annual Closure Rate
                        <coach-icon class="margin-left--small" iconName="info"
                            title="Percentage based on the number of chronic conditions coded in the current calendar year divided by the number of chronic conditions not coded in the current year and the previous two years.">
                        </coach-icon>
                    </div>
                </div>
                <div class="chart-container margin-top--large">
                    <ngx-charts-gauge [view]="view" [scheme]="colorScheme" [results]="annualClosureRate"
                        [valueFormatting]="formatValue" [showAxis]="false">
                    </ngx-charts-gauge>
                    <span *ngIf="acoAverage != null" class="aco-average">ACO AVERAGE = {{acoAverage |
                        number:'1.0-0'}}%</span>
                </div>
            </ng-container>

            <coach-hcc-closure-rate-chart *hasFeatureFlag="hccEnhanced2FeatureFlag"></coach-hcc-closure-rate-chart>
        </div>
        <div class="column u-width--full padding">
            <ng-container *doesNotHaveFeatureFlag="hccEnhanced2FeatureFlag">
                <div class="titles t3">
                    <div class="title row--verticalCenter u-color--brand--1 u-display--flex">Your Top 5 Open Chronic
                        Conditions
                        <coach-icon class="margin-left--small" iconName="info"
                            title="A list of the top five chronic Chronic Conditions with the highest number of gaps that have been coded for within the past three years but have not been coded in the current calendar year.">
                        </coach-icon>
                    </div>
                </div>
                <div class="list u-width--full margin-top--large">
                    <div *ngFor="let opportunity of codingOpportunities; let i = index" class="list-item"
                        [style.pointer-events]="canAccessPhi ? 'auto' : 'none'">
                        <div class="row">
                            <div *ngIf="canAccessPhi" class="field u-color--interaction"
                                (click)="navigateToPatientDetails()" [routerLink]="['../patientDetails']"
                                [queryParams]="{hcc: opportunity.hcc}">{{i+1}}. {{opportunity.hccDesc}}</div>
                            <div *ngIf="!canAccessPhi" class="field">{{i+1}}. {{opportunity.hccDesc}}</div>

                            <div class="field u-bold">{{opportunity.opportunityCount}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <coach-hcc-top-disease-groups *hasFeatureFlag="hccEnhanced2FeatureFlag" [data]="diseaseGroups$ | async"
                [canAccessPhi]="canAccessPhi"></coach-hcc-top-disease-groups>
        </div>
    </div>
    <div class="row dashboard-row u-color--brand--1" *ngIf="canAccessPhi">
        <div class="column u-width--full padding border-right">
            <div class="titles t3">
                <div class="title row--verticalCenter u-display--flex">Quick Links to Your Care Patient Lists
                </div>
            </div>
            <div class="row margin-top--large">
                <button (click)="navigateToTop50()"
                    class="button">
                    <span>Go to Top 50 patient list</span>
                    <coach-icon class="icon--currentColor" iconName="chevron-right"></coach-icon>
                </button>
            </div>
            <div class="row margin-top--large">
                <button [routerLink]="['../../../../care/patients']" [queryParams]="{filterPreset: 'awv-opportunities'}"
                    class="button">
                    <span>Go to AWV Opportunities list</span>
                    <coach-icon class="icon--currentColor" iconName="chevron-right"></coach-icon>
                </button>
            </div>
        </div>
        <div class="column u-width--full padding">

        </div>
    </div>
</div>
<coach-hierarchy-required pageName="Chronic Conditions Dashboard" [requiredTier]="2"></coach-hierarchy-required>
