/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BillingReportResult } from '../models/billing-report-result';
import { PatientIheVisit } from '../models/patient-ihe-visit';
import { ScheduleGetResult } from '../models/schedule-get-result';

@Injectable({
  providedIn: 'root',
})
export class IheService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPatientIheVisits
   */
  static readonly GetPatientIheVisitsPath = '/patientIheVisitList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPatientIheVisits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPatientIheVisits$Response(params: {
    tierId: string;
    tierNum: number;
    chPatId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PatientIheVisit>>> {

    const rb = new RequestBuilder(this.rootUrl, IheService.GetPatientIheVisitsPath, 'get');
    if (params) {
      rb.query('tierId', params.tierId, {});
      rb.query('tierNum', params.tierNum, {});
      rb.query('chPatId', params.chPatId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PatientIheVisit>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPatientIheVisits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPatientIheVisits(params: {
    tierId: string;
    tierNum: number;
    chPatId?: number;
  },
  context?: HttpContext

): Observable<Array<PatientIheVisit>> {

    return this.getPatientIheVisits$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PatientIheVisit>>) => r.body as Array<PatientIheVisit>)
    );
  }

  /**
   * Path part for operation getIhAwvSchedule
   */
  static readonly GetIhAwvSchedulePath = '/scheduleGet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIhAwvSchedule()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIhAwvSchedule$Response(params: {
    tierId: string;
    tierNum: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ScheduleGetResult>>> {

    const rb = new RequestBuilder(this.rootUrl, IheService.GetIhAwvSchedulePath, 'get');
    if (params) {
      rb.query('tierId', params.tierId, {});
      rb.query('tierNum', params.tierNum, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ScheduleGetResult>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIhAwvSchedule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIhAwvSchedule(params: {
    tierId: string;
    tierNum: string;
  },
  context?: HttpContext

): Observable<Array<ScheduleGetResult>> {

    return this.getIhAwvSchedule$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ScheduleGetResult>>) => r.body as Array<ScheduleGetResult>)
    );
  }

  /**
   * Path part for operation getIhAwvBilling
   */
  static readonly GetIhAwvBillingPath = '/billingGet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIhAwvBilling()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIhAwvBilling$Response(params: {
    tierId: string;
    tierNum: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BillingReportResult>>> {

    const rb = new RequestBuilder(this.rootUrl, IheService.GetIhAwvBillingPath, 'get');
    if (params) {
      rb.query('tierId', params.tierId, {});
      rb.query('tierNum', params.tierNum, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BillingReportResult>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIhAwvBilling$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIhAwvBilling(params: {
    tierId: string;
    tierNum: string;
  },
  context?: HttpContext

): Observable<Array<BillingReportResult>> {

    return this.getIhAwvBilling$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BillingReportResult>>) => r.body as Array<BillingReportResult>)
    );
  }

}
