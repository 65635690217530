<div class="u-display--flex">
    <div class="titles t3">
        <div class="title row--verticalCenter u-color--brand--1 u-display--flex">Chronic Conditions Closure Rates
            <!-- <coach-icon class="margin-left--small" iconName="info"
            title="TBD and approved by legal.">
        </coach-icon> -->
        </div>
    </div>
    <div class="u-display--flex u-flex--right u-align-items--center">
        <span class="subtitle padding-right">VIEW BY</span>
        <div class="button-group is-primary">
            <div *ngFor="let option of toggleOptions" class="group">
                <input type="radio" id="{{option}}" [(ngModel)]="selectedToggle" [value]="option"
                    (ngModelChange)="compareToggle($event)" />
                <label for="{{option}}">{{option}}</label>
            </div>
        </div>
    </div>
</div>
<div [id]="chartId" class="u-width--full closure-rate-chart"></div>
<div class="margin-top--large closure-rate-table"
    *ngIf="closuresTableData && closuresTableData.length > 0 && closuresTableHeaderData.length > 0">
    <table class="table--2 u-width--full">
        <tr>
            <th>Compared To...</th>
            <ng-container *ngFor="let data of closuresTableHeaderData; index as i">
                <th class="u-bolder" *ngIf="i > closuresTableHeaderData.length - 5">
                    <span *ngIf="data.dtPart === 'Q'">{{data.dtPart}}{{data.dtValue}}</span>
                    <span *ngIf="data.dtPart === 'M'">{{data.dtValue - 1 | monthNumber}}</span>
                </th>
            </ng-container>
        </tr>
        <tr class="" [ngClass]="i === selectedCompareIndex ? 'selected-compare' : 'row-item u-cursor--pointer'"
            *ngFor="let array of closuresTableData; index as i" (click)="setSelectedCompare(array, i)">
            <td [innerHTML]="array[0]?.tierTitleHtml"></td>
            <ng-container *ngFor="let data of array; index as j">
                <td *ngIf="j > array.length - 5">
                    <span *ngIf="data.avgClosure">{{data.avgClosure | percent}}</span>
                    <span *ngIf="data.avgClosure == null">—</span>
                </td>
            </ng-container>
        </tr>
    </table>
</div>