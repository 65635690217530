import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';


import { COLUMN_DEFS } from './care-exceptions.columns';
import {
  IClaimException,
} from '@shared/models/prescription';
import { IPrescriptionFilterPreset } from '@shared/models/prescription-filters';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { Subscription } from 'rxjs';
import {
  ClaimStatus,
} from '../../care-prescriptions/shared-lib';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { FilterOption } from '../../care-all-prescriptions/care-all-prescriptions.component';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-community';

@Component({
  selector: 'coach-care-exceptions',
  templateUrl: './care-exceptions.component.html',
  host: {
    class: 'page-content coach-care-prescriptions',
  },
})
export class CareExceptionsComponent
  extends KillSubscriptions
  implements OnInit, OnDestroy
{
  public static ACCESS_EXCEPTION_QUEUE = 'Accessed 340B Exception Queue';

  public claimExceptions: Array<IClaimException> = [];
  public dataLoaded: boolean = false;
  public loadingStatus = true;
  public cancelLoadingBool = false;
  public resetFilter = false;
  public selectedPreset: IPrescriptionFilterPreset;
  
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  public selectedPatientInfo: any;
  readonly defaultColDef: ColDef = {
    resizable: true,
  };
  readonly columnDefs = COLUMN_DEFS;

  public dateRangeLow: Date;
  public dateRangeHigh: Date;

  public orgId: string = null;
  public tier: IHierarchyTier = null;
  public rowCount: number = 10;
  public pageNo: number = 1;

  public gridObject: any = null;
  public currentRow: any = null;
  public redrawRowInterval: any;

  authSubscription: Subscription;
  currentUser: any;

  filterOptions: FilterOption[];
  ExceptionQueue: boolean;
  
  @Output() public gridApiEmitter: EventEmitter<GridApi> = new EventEmitter();
  @Output() public filterOptionsEmitter: EventEmitter<FilterOption[]> =
    new EventEmitter();

  constructor(
    private _route: ActivatedRoute,
    private HierarchyTierService: HierarchyTierService,
    private PrescriptionService: PrescriptionService,
    private toastr: ToastrService,
    private router: Router,
    protected featureFlagService: FeatureFlagService,
    private authService: AuthService
  ) {
    super();
    HierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (tier) => {
        if (this.tier?.selectedTierId !== tier?.selectedTierId) {
          this.tier = tier;
          this.claimExceptions = (await this.getClaimExceptions()).map(ex => {
            ex.fillDate = ex.dispenseDate;
            return ex;
          });
          this.initializeFilter();
        }
      });
    this.ExceptionQueue = this.featureFlagService.hasFeatureFlag(
      CareFeatureConstants.ExceptionQueue340B
    );
  }

  ngOnInit(): void {
    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    clearInterval(this.redrawRowInterval);
  }
  
  initializeFilter() {
    this.filterOptions = [];
    this.filterOptionsEmitter.emit(this.filterOptions);
    this.gridApi.refreshCells();
    this.loadingStatus = false;
  }

  onGridReady(param: any): void {
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
    this.rowCount = this.gridApi.getDisplayedRowCount();
    this.pageNo = 1;

    this.setDefaultSortOrder();
    this.gridApiEmitter.emit(this.gridApi);
  }

  onFirstDataRendered(params: any): void {
    this.gridObject = params;
    this.dataLoaded = true;
  }

  setDefaultSortOrder() {
    this.columnApi.applyColumnState({
      state: [
        {
          colId: 'rxNumber',
          sort: 'asc',
          sortIndex: 0,
        },
        {
          colId: 'fillDate',
          sort: 'asc',
          sortIndex: 1,
        }
      ],
      defaultState: { sort: null },
    });
  }

  public cancelPrescriptionsLoadingProcess(): void {
    this.cancelLoadingBool = true;
    this.loadingStatus = false;
    this.resetFilter = true;
    let that = this;
    setTimeout(function () {
      that.resetFilter = false;
    }, 0);
  }

  async getClaimExceptions(): Promise<IClaimException[]> {
    const { selectedItem } = this.tier;
    let prescriptions;
    try {
      prescriptions = this.PrescriptionService.getClaimExceptions(
        selectedItem.tier2_ID,
      );
    } catch (e) {
      this.toastr.error(`${e.status}: ${e.statusText}`, 'Error');
    }

    return prescriptions; 
  }

  writeAccessAuditMessage(action: string) {
    const dateRange = this.getAuditMessageDateRange();
    setTimeout(async () => {
      this.PrescriptionService.writeAuditMessage({
        entityid: parseInt(this.currentUser.dnn_id),
        moduleid: 2, // care
        componentid: 36,
        actionid: 6, // phi access
        tablename: 'Coach340BClaims',
        auditdesc: `User ID ${this.currentUser.dnn_id} ${action} for date range ${dateRange}`,
        applicationID: 2,
      });
    }, 0);
  }

  getAuditMessageDateRange() {
    return `${this.dateRangeLow?.toLocaleDateString() ?? 'All'} - ${
      this.dateRangeHigh?.toLocaleDateString() ?? 'All'
    }`;
  }

  onCellClicked(event) {
    switch (event.value) {
      case 'check-circle':
        this.loadingStatus = true;
        this.PrescriptionService.moveExceptionToClaim(event.data.exceptionId)
          .then(() => {
            this.removeAffectedRows(event);
            this.loadingStatus = false;
          });
        break;
      case 'x-circle':
        this.loadingStatus = true;
        this.PrescriptionService.rejectException(event.data.exceptionId)
          .then(() => {
            this.removeAffectedRows(event);
            this.loadingStatus = false;
          });
        break;
    }
  }

  removeAffectedRows(event) {
    const affectedRows = [];
      event.api.forEachNode((node) => {
        if (event.data.exceptionId == node.data.exceptionId) {
          affectedRows.push(node.data);
        }
    });
    this.gridApi.applyTransaction({ remove: affectedRows });
  }

}
