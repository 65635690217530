import { Injectable } from '@angular/core';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { PatientEngagement, PatientEngagementComponentIds } from '@shared/enums';
import { CareComponentConstants, CareFeatureConstants } from '@shared/models/module-constants';
import { SpacesToDashesPipe } from '@shared/pipes/spaces-to-dashes.pipe';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { Access } from '@shared/services/graphql/access.service';
import { OrgService } from '@shared/services/org-service/org.service';
import { PatientAlertService } from '@shared/services/patient-alert/patient-alert.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PresetItemBase {
  protected userAccess: Access = null;
  protected hasPatientApp: boolean = null;
  protected hasPatientTwilioSMS: boolean = null;
  protected hidePresetAdditionCondition: boolean = null;
  protected agGridCustomFilteringFlag: boolean = null;
  protected awvPatientsWorklist: boolean = null;
  protected ehrFlag: boolean = null;
  protected isIHECohortEnabled: boolean = null;
  protected isInHomeAWVScheduleList: boolean = null;
  protected isHCCVIPCohortListEnabled: boolean = null;
  protected isSMSOutreachProgramEnabled: boolean = null;
  protected isHCCListRevampEnabled: boolean = null;
  protected hasPAC3DayWaiver: boolean = null;
  protected get userId() {
    return this.authService.wellpepperUser?._id;
  }

  constructor(
    public featureFlagService: FeatureFlagService,
    public userAccessService: UserAccessService,
    public _orgService: OrgService,
    public _patientAlertService: PatientAlertService,
    public spacesToDashes: SpacesToDashesPipe,
    public authService: AuthService
  ) {
    this.userAccessService.currentAccess$
      .pipe(
        map(access => {
          if (access == null) {
            return;
          }
          this.userAccess = access;
          this.hasPatientApp = this.userAccessService.hasComponent(this.userAccess, PatientEngagement.PatientApp);
          this.hasPatientTwilioSMS = this.userAccessService.hasComponent(
            this.userAccess,
            PatientEngagement.PatientTwilioSMS
          );
          this.hidePresetAdditionCondition = this.userAccessService.hasComponent(
            this.userAccess,
            CareComponentConstants.CareManagement
          );

          this.awvPatientsWorklist = this.userAccessService.hasComponent(
            this.userAccess,
            CareComponentConstants.AwvPatientsWorklist
          );

          this.hasPAC3DayWaiver = this.userAccessService.hasComponent(
            this.userAccess,
            CareComponentConstants.PAC3DayWaiver
          );

        }),
        switchMap(() => this.featureFlagService.featureFlags$)
      )
      .subscribe(() => {
        this.agGridCustomFilteringFlag = this.featureFlagService.hasFeatureFlag(
          CareFeatureConstants.AgGridCustomFilteringFlag
        );

        this.ehrFlag = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.EHR);

        this.isIHECohortEnabled = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.IHECohort);

        this.isInHomeAWVScheduleList = this.featureFlagService.hasFeatureFlag(
          CareFeatureConstants.InHomeAWVScheduleList
        );

        this.isHCCVIPCohortListEnabled = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.HCCVIPCohortList);

        this.isHCCListRevampEnabled = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.HCCListRevamp);

        this.isSMSOutreachProgramEnabled = this.featureFlagService.hasFeatureFlag(
          CareFeatureConstants.SMSOutreachProgram
        );        

      });
  }
}
