<div *ngIf="this.title !== undefined" class="list-item row--verticalCenter recent-element">
  <div class="list-item-avi">
    <div class="avi">
      <coach-icon
        *ngIf="module === 'CARE'"
        iconName="users"
        iconSize="large"
        iconColor="currentColor"
      ></coach-icon>
      <coach-icon
        *ngIf="module === 'TRACK'"
        iconName="trending-up"
        iconSize="large"
        iconColor="currentColor"
      ></coach-icon>
      <coach-icon
        *ngIf="module === 'IMPROVE'"
        iconName="award"
        iconSize="large"
        iconColor="currentColor"
      ></coach-icon>
      <coach-icon
        *ngIf="module === 'SUBMIT'"
        iconName="upload"
        iconSize="large"
        iconColor="currentColor"
      ></coach-icon>
      <coach-icon
        *ngIf="module === 'HOME'"
        iconName="home"
        iconSize="large"
        iconColor="currentColor"
      ></coach-icon>
    </div>
  </div>
  <div
    class="list-item-main"
    [routerLink]="routerURL"
    [queryParams]="queryParams"
  >
    <div class="u-font-size--smallest u-thin">{{ module }}</div>
    <div class="u-bold row">
      {{ title }}
    </div>
    <div class="u-font-size--smallest u-italic u-text-transform--capitalize">
      {{ tierName }}
    </div>
  </div>
</div>
