import { Component, ViewChild } from '@angular/core';
import { CoachIconComponent } from '@shared/components/coach-icon/coach-icon.component';
import { RowNode } from 'ag-grid-community';

@Component({
  selector: 'coach-exceptions-cell-renderer',
  templateUrl: './exceptions-cell-renderer.component.html',
})
export class ExceptionsCellRendererComponent {
  @ViewChild('icon') icon: CoachIconComponent;
  params: any;

  agInit(params: any) {
    this.params = params;

    params.node.addEventListener(RowNode.EVENT_MOUSE_ENTER, () => {
      this.icon.setIconColor(params.activeColor);
    });

    params.node.addEventListener(RowNode.EVENT_MOUSE_LEAVE, () => {
      this.icon.setIconColor('default');
    });
  }
}
