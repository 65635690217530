import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserActivity } from '@shared/models/user-activity';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';
import { common as wp, wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { format } from 'date-fns';
import { UserInfo } from '@shared/services/auth-service/auth-interface';
import { Patient } from '@shared/models/patient';
import {
  PatientIHEFileInfo,
  PatientIHEFilesNote,
  PatientIHEFileType,
  AssessmentFileTypes
} from '@shared/models/patient-ihe-data';

import * as _ from 'lodash';
import moment from 'moment';

import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';
import { HierarchyTierService } from '../hierarchy/hierarchy-tier.service';
import { CaravanPatient, PatientSnfInfo } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/user';
import { ITrackAPIParams, IWPAPIParams } from '@shared/models/patient-filters';

import { downloadFile } from '@shared/utilities';
import { note } from '@shared/modules/patient-facesheet/tabs/patient-ihe-worklist/patient-ihe-worklist.component';
import { IEmailTemplate } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/emailTemplate';
import { DiseaseCohortDefinition } from 'src/app/api/track/models/disease-cohort-definition';
import { AwvWorkflowStatuses } from 'src/app/api/care-orch/models/awv-workflow-statuses';

/** 2020-09-26 Reverse-engineered from patient list call */
export interface ITrackApiPatientListApiResult {
  totalRecords: number;
  results: Array<ITrackApiPatient>;
}

export interface ITrackApiCohortPatientListApiResult {
  totalRecords: number;
  results: Array<ITrackCohortPatient>;
}

export interface ITrackApiEdUtilizationPatientListApiResult {
  totalRecords: number;
  results: Array<ITrackApiEdUtilizationPatient>;
}

export class CareOrgNotFoundError extends Error {
  constructor(message) {
    super(message);
  }
}

enum PatientNoteType {
  PatientNoteTypeIHAWV = 1
}

export interface ITimeTrackingEvent {
  activity: string;
  start: string;
  durationSeconds?: number;
  /** caravan patient id not wellpepper user id */
  patientId?: string;
  /** wellpepper provider id */
  providerId?: string;
  providerName?: string;
  source?: 'direct' | 'timer';
  notes?: string;
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  createdByName?: { firstName: string; lastName: string };
}

export interface ITrackApiPatient {
  chPatId?: string;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  gender?: 'Male' | 'Female' | 'Unknown';
  /** MM/DD/YYYY */
  dob?: string;
  mbi?: string;
  phone1?: string;
  phone1_Type?: string;
  phone2?: string;
  phone2_Type?: string;
  email?: string;
  acO_Name?: string;
  tier2_ID?: string;
  tier2_Name?: string;
  tier3_ID?: string;
  tier3_Name?: string;
  tier4_ID?: string;
  tier4_Name?: string;
  tier5_ID?: string;
  tier5_Name?: string;
  /** "Attributed" */
  assignment?: string;
  currentRiskLevel?: string;
  predictedRiskLevel?: string;
  probability?: number;
  riskTrend?: string;
  ssDenom?: number;
  enrollmentType?: string;
  dod?: string;
  dodBoolean?: string;
  demographicWt?: string;
  raF_YTD?: number;
  openRAFWt?: number;
  supplementalID?: number;
  attributedCP?: string;
  attributedCp?: string;
  ccmEligible?: string;
  diseaseCohortIDs?: number[];
  diseaseCohorts?: string[];
  totalCost?: number;
  awvTypeDue?: string;
  lastAWVDate?: string;
  lastAwvProvider?: string;
  edVisits?: number;
  hccGapCount?: number;
  attributedCPTier?: number;
  attributedCpTier?: string;
  pyRaf?: number;
  lastVisit?: Date;
  middleName?: string;
  attributionStatus?: string;
  renderingProviderName?: string;
  renderingProviderNpi?: string;
  lastExportDateTime?: string;
  lastUpdateDateTime?: string;
  lastExportUserName?: string;
  lastExportUserId?: number;
  fileInfo?: PatientIHEFileInfo[];
  IHEAWVVisitDate?: string;
  awvStatusId?: number;
  visitInfo?: ITrackApiIHEFilesListVisitInfo;
  lastCCMDateAndCode?: string;
  inpatientVisits?: number;
  officeVisits?: number;
  lastCCMDate?: string;
  ccmLastProcCode?: string;
  lastCcmDate?: string;
  statusDate?: string;
  ihAwvPatient?: number;
  lastCsthDate?: Date;
  hasFacesheet?: number;
}

export interface IIHEAWVPatient {
  acoId: string;
  apptStartTime: string;
  attributedCp: string;
  attributedCpTier: number;
  awvTypeDue: string;
  ccmEligible: number;
  ccmLastProcCode: string;
  ccnType: string;
  chPatId: number;
  clinicProviderName: string;
  clinicProviderNpi: string;
  clinicProviderPhone: string;
  currentRiskLevel: string;
  deepestStatusCode: number;
  deepestStatusCodeDesc: string;
  deepestStatusType: string;
  demographicWt: number;
  diseaseCohortIds: string;
  dob: string;
  dos: any;
  edVisits: number;
  evalProviderName: string;
  evalProviderNpi: any;
  evaluationOpReportId: number;
  firstName: string;
  fullName: string;
  hccGapCount: number;
  lastAwvDate: any;
  lastAWVProvider?: string;
  lastAwvDateOpReport: string;
  lastCcmDate: any;
  lastName: string;
  lastVisit: string;
  mbi: string;
  mbrAddressLine1: string;
  mbrAddressLine2: string;
  mbrCity: string;
  mbrCounty: string;
  mbrGender: string;
  mbrHomePhone: string;
  mbrPreferredLanguage: string;
  mbrState: string;
  mbrTelephone: string;
  mbrZip: string;
  opStatusLastUpdateDateTime: any;
  openRafWt: number;
  outreachCallDateTime: string;
  outreachDoNotCall: string;
  outreachSchedulingCode: number;
  outreachSchedulingDesc: string;
  outreachSchedulingStatus: string;
  predictedRiskLevel: string;
  previousYearDos: string;
  probability: number;
  programYear: number;
  pyRaf: number;
  rafYtd: number;
  reportDate: string;
  riskTrend: string;
  tier1Id: string;
  tier1Name: string;
  tier2Id: string;
  tier2Name: string;
  tier3Id: string;
  tier3Name: string;
  tier4IdCcn: string;
  tier4IdGrpId: string;
  tier4IdTin: string;
  tier4Name: string;
  tier5Id: string;
  tier5Name: string;
  tmlName: string;
  totalCost: number;
  assignment?: string;
  diseaseCohorts?: string[];
  awvStatusId?: number;
}

export interface ITrackCohortPatient {
  current_MBI?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  email?: string;
  phone1?: string;
  phone1_Type?: string;
  phone2?: string;
  phone2_Type?: string;
  zipCode?: string;
  genderCode?: string;
  genderStr?: string;
  cohortId?: number;
  cohort?: string;
  cohortDesc?: string;
  cohortMonthYear?: string;
  patCohortAddDateTime?: string;
  raF_YTD?: number;
  chPatID?: number;
  supplementalID?: number;
  totalSpend?: number;
  attributedCP?: string;
  assignment?: string;
  currentRiskLevel?: string;
  predictedRiskLevel?: string;
  probability?: number;
  riskTrend: string;
  ccmEligible?: number;
  hccGapCount?: number;
  diseaseCohorts: string[];
  diseaseCohortIDs?: number[];
  lastVisit?: Date;
  openRAFWt?: number;
  tier4_Name?: string;
  awvTypeDue?: string;
  lastAWVDate?: string;
  edVisits?: number;
  pyRaf?: number;
  cohortProcedure_LastDate?: string;
  cohortSpecialist_LastProviderName?: string;
  cohortSpecialist_LastDate?: string;
  cohortDxCount?: number;
  officeVisits?: number;
  inpatientVisits?: number;
  isMostImpactable?: string;
  alignToClinical?: string;
  lastCCMDateAndCode?: string;
  lastCcmDate?: string;
  ccmLastProcCode?: string;
  lastCsthDate?: Date;
}
export interface ITrackCareManagementPatient {
  attributedCp?: string;
  attributedCpTier?: number;
  awvTypeDue?: string;
  ccmEligible?: string;
  chPatId?: string;
  chronicConditionList?: string;
  cohortDxCount?: number;
  cohortProcedure?: string;
  cohortProcedureLastCode?: string;
  cohortProcedureLastDate?: string;
  cohortSpecialist?: string;
  cohortSpecialistLastDate?: string;
  assignment: string;
  cohortSpecialistLastProviderName?: string;
  cohortSpecialistLastProviderNpi?: string;
  currentMbi?: string;
  currentRiskLevel?: string;
  dateOfBirth?: string;
  diseaseCohorts?: string[];
  edVisits?: number;
  enrollmentStatus?: string;
  firstName?: string;
  genderCode?: string;
  genderStr?: string;
  hccGapCount?: number;
  inpatientVisits?: number;
  lastAwvDate?: string;
  lastCcmCode?: string;
  lastCcmDate?: string;
  ccmLastProcCode?: string;
  lastName?: string;
  lastProvVisitCode?: string;
  lastProvVisitDate?: string;
  officeVisits?: number;
  predictedRiskLevel?: string;
  pyRaf?: number;
  ssDenom?: number;
  ssPercent?: number;
  supplementalId?: string;
  totalGapWt?: number;
  totalSpend?: number;
  ytdRaf?: number;
  alignToClinical?: string;
  tier4Name: string;
  lastCsthDate?: Date;
}
export interface ITrackApiEdUtilizationPatient {
  mbi: string;
  patientName: string;
  dob: string;
  age: string;
  gender: string;
  attributedCP: string;
  lastVisit: Date;
  pyRaf: number;
  chPatID: number;
  supplementalID: number;
  totalCost: number;
  edVisits: number;
  email?: string;
  assignment?: string;
  currentRiskLevel?: string;
  predictedRiskLevel?: string;
  probability?: number;
  riskTrend: string;
  ccmEligible?: string;
  hccGapCount?: number;
  diseaseCohorts: string[];
  diseaseCohortIDs?: number[];
  raF_YTD?: number;
  openRAFWt?: number;
  tier4_Name?: string;
  phone1?: string;
  awvTypeDue?: string;
  lastAWVDate?: string;
}

export interface ITrackApiAwvPatient {
  chPatID: number;
  chPatId: number;
  supplementalID: number;
  mbi: string;
  patientName: string; //"CLARK, DANIEL ",
  dob: string;
  age: number;
  gender: 'Male' | 'Female' | 'Unknown';
  attributedCP: string;
  lastAWVDate: string;
  allAWVPatientsListStatus?: string;
  enrollmentDate: string;
  awvTypeDue: string;
  pyRaf: number;
  awvStatusDisplay: string;
  awvDateDue: string;
  lastAwvProvider?: string;
  awvStatusId?: number;
  scheduleID: string;
  scheduleDate: string;
  hasNotes: boolean;
  email?: string;
  assignment: string;
  currentRiskLevel?: string;
  predictedRiskLevel?: string;
  probability?: number;
  hccGapCount?: number;
  riskTrend: string;
  ccmEligible?: string;
  diseaseCohorts: string[];
  diseaseCohortIDs?: number[];
  raF_YTD?: number;
  lastVisit?: Date;
  openRAFWt?: number;
  tier5_Name?: string;
  tier5_ID?: string;
  tier4_Name?: string;
  tier4_ID?: string;
  tier3_ID?: string;
  tier2_ID?: string;
  totalCost?: number;
  phone1?: string;
  ssDenom?: number;
  enrollmentType?: string;
  lastCCMDateAndCode?: string;
  edVisits?: string;
  inpatientVisits?: number;
  officeVisits?: number;
  lastCcmDate?: string;
  ccmLastProcCode?: string;
  firstName?: string;
  lastName?: string;
  statusDate?: string;
  preferredName?: string;
  ihAwvPatient?: number;
  lastCsthDate?: Date;

  coWorkflowStatus?: AwvWorkflowStatuses;
  coWorkflowDueDate?: string;
  coWorkflowScheduledDate?: string;
  coWorkflowCompletedDate?: string;
  awvJson?: AWVJsonData[];
  rankedLastAwvDate?: string;
  rankedLastAwvDateSource?: string;
  hasFacesheet?: number;
}
export interface AWVJsonData {
  Date: string;
  Source: string;
  Provider: string;
  EHRTierNumber: string;
  EHRTierID: string;
  EHRTierName: string;
}
// TODO - move this back to pkg-wptypes once the interface has settled down enough
export interface IWPAPIPatient extends wpapi.model.CaravanPatient {
  alerts?: Array<any>;
  diastolic?: number;
  gain?: string;
  bloodSugar: any;
  lastActivityAt?: string;
  professionalName?: string;
  pulse?: number;
  systolic?: number;
  weight?: number;
  status?: string;
  alert?: string;
  enrollmentDate?: Date;
  lastResponseDate?: Date;
  lastCsthDate?: Date;
}

/**
 * From GET /ihawv/patientIheVisitList
 * NOTE these represent visits, not patients
 */
export interface ITrackApiIHEFilesListVisitInfo {
  // tierId: string;

  visitId: string;
  evaluationId: string;
  /** UTC? Use only the date part?  */
  visitDate: string;
  visitStatus: string;
  visitStatusCode?: number;
  visitStatusDescription: string;

  patientInfo: ITrackApiPatient;
  renderingProviderName?: string;
  fileStatus: string;
  fileInfo: Array<PatientIHEFileInfo>;
}

export interface EpisodeOfCareViewModel extends wpapi.model.EpisodeOfCare {
  protocols?: Array<EpisodeOfCareProtocolAssignmentViewModel>;
  unattachedTasks?: Array<wpapi.model.Task>;
}
export interface IProcedure extends wpapi.model.Procedure {
  protocols?: Array<EpisodeOfCareProtocolAssignmentViewModel>;
}
export interface EpisodeOfCareProtocolAssignmentViewModel extends wpapi.model.EpisodeOfCareProtocolAssignment {
  protocol?: wpapi.model.Protocol;
  tasks?: Array<ProtocolTaskViewModel>;
  modules: Array<EpisodeOfCareModuleAssignmentViewModel>;
  isActive?: boolean;
  isOpen?: boolean;
  activeTaskCount?: number;
  parentEpisodeOfCare?: EpisodeOfCareViewModel;
  invitations?: {
    welcomeMail?: IEmailTemplate;
  };
  _id?: string;
}

export interface EpisodeOfCareModuleAssignmentViewModel extends wpapi.model.EpisodeOfCareModuleAssignment {
  protocol?: wpapi.model.Protocol;
  templateTasks?: Array<ProtocolTaskViewModel>;
  assignedTasks?: Array<ProtocolTaskViewModel>;
  isActive?: boolean;
  isOpen?: boolean;
  activeTaskCount?: number;
  parentProtocol?: EpisodeOfCareProtocolAssignmentViewModel;
}
export interface TaskViewModel extends wpapi.model.Task {
  swimlane: 'once' | 'daily' | 'weekly' | 'monthly';
}

export interface ProtocolTaskViewModel extends wpapi.model.ProtocolTask {
  swimlane?: 'once' | 'daily' | 'weekly' | 'monthly';
  _id?: string;
  userId?: string;
  protocolId?: string;
}
export interface CarePlanViewModel {
  episodesOfCare: Array<EpisodeOfCareViewModel>;
  moduleActivateDeactivateInfo: ModuleActivateDeactivateDialogViewModel;
}

export interface ModuleActivateDeactivateDialogViewModel {
  module?: EpisodeOfCareModuleAssignmentViewModel;
  operation?: 'Assign' | 'Activate' | 'Deactivate';
  tasksToAssign?: Array<ProtocolTaskViewModel>;
  tasksNotToBeDeactivated?: Array<ProtocolTaskViewModel>;
  tasksToActivate?: Array<ProtocolTaskViewModel>;
  tasksToLeaveAlone?: Array<ProtocolTaskViewModel>;
  tasksToBeDeactivated?: Array<ProtocolTaskViewModel>;
  protocolTasksToAssign?: Array<ProtocolTaskViewModel>;
  protocolTasksToActivate?: Array<ProtocolTaskViewModel>;
  protocolTasksToBeDeactivated?: Array<ProtocolTaskViewModel>;
  protocolTasksNotToBeDeactivated?: Array<ProtocolTaskViewModel>;
  protocolTasksToLeaveAlone?: Array<ProtocolTaskViewModel>;
  protocol?: wpapi.model.Protocol;
  modulesToActivate?: EpisodeOfCareModuleAssignmentViewModel[];
  modulesToBeDeactivated?: EpisodeOfCareModuleAssignmentViewModel[];
  modulesToLeaveAlone?: EpisodeOfCareModuleAssignmentViewModel[];
}
export interface ITrackApiIHEBillingListVisitInfo {
  mbi: string; //MBI
  chPatId: string;
  firstName: string;
  lastName;
  string;
  fullName: string; //Name
  mbrDob: string; // DOB
  mbrGender: 'M' | 'F' | 'Unknown'; //Gender
  mbrPhone: string; //Phone
  mbrAddressLine1: string; //Address
  mbrAddressLine2: string; //Address
  mbrCity: string; //Address
  mbrState: string; //Address
  mbrZip: string; //Address
  mbrCounty: string; //Address
  reportDate: string; //Batch Date
  cptCode: string; //CPT Code
  dxCount: number; //DX count
  rafYtd: number; //Chronic Condition Weight Closed YTD
  renderingProviderLastName: string; //Rendering Provider
  renderingProviderFirstName: string; //Rendering Provider
  lastCcmDate?: string; //Last CCM Date
  lastAwvDate: string;
  ccmEligible: number; //CCM Elligible
  hccGapCount: number; //HCC Closure Gaps
  predictedRiskLevel: string; //Predicted Risk Category
  chronicConditions: string; //Chronic Conditions
  totalCost: number; //Health Care Costs
  lastProviderVisit: string; //Last Provider visit
  edVisits: number; //Ed Utilization
  openRafWt: number; //Total Gap Weight
  attributedCp: string; //Attributed Care Provider
  facilityName: string; //Rendering Provider
  dos: string; // Visit Date
  frequencyType: string;
}

export interface SurveyResponse extends wpapi.model.SurveyResponse {
  responses?: { [key: string]: any };
}
export interface Completion extends wpapi.model.Completion {
  weight?: number;
  event?: any;
}
export interface UserSurvey extends wpapi.model.UserSurvey {
  pages?: any;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  wpBaseURL: string = environment.wpApiUrl;
  trackBaseURL: string = environment.trackApiUrl;
  apiBaseUrl: string = environment.apiBaseUrl;

  // userToken: string = '';
  user: UserInfo;
  idpUserToken: string = '';
  auditBaseURL: string;

  /** Caches mapping from tier ids to the org id. Typically multiple tierIds map to a single orgId
   * e.g. a community and all it's practices.
   */
  private tierIdToOrgIdMap: { [tierId: string]: Promise<string> } = {};
  /** Caches mapping from an org to it's main tierId. This is a 1-1 mapping */
  private orgIdToTierIdMap: { [orgId: string]: string } = {};
  diseaseCohortDefinitions: DiseaseCohortDefinition[] = [];
  constructor(
    private http: HttpClient,
    private _authService: AuthService,
    private HierarchyTierService: HierarchyTierService,
    private ToastrMessageService: ToastrMessageService
  ) {
    this.idpUserToken = this._authService.idp_access_token;
  }

  async getWellpepperPatients(
    tierId: string,
    sort: string,
    filter: string,
    wpapiPArams: IWPAPIParams = {}
  ): Promise<Array<IWPAPIPatient>> {
    try {
      // TODO - use the request interceptor to inject the tokend
      let headers = {
        Authorization: 'Bearer ' + this._authService.IdToken,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      };

      let orgId = await this.getWpOrgIdFromTierId(tierId);
      if (!orgId) {
        return [];
      }

      let url = `${this.wpBaseURL}/orgs/${orgId}/patients2`;
      const options = {
        headers: headers,
        params: {
          ...{
            filter: filter || null,
            order: sort || null
          },
          ...wpapiPArams
        }
      };
      return await this.http.get<Array<IWPAPIPatient>>(url, options).toPromise();
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  async getTrackPatients(
    tierNum: number,
    TierId: string,
    trackApiParams: ITrackAPIParams = {}
  ): Promise<ITrackApiPatientListApiResult> {
    let url = `${this.trackBaseURL}/Patients/list`;
    const TierNum: string = tierNum.toString();
    const options: { [key: string]: any } = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: { ...{ TierNum, TierId }, ...trackApiParams }
    };
    try {
      return await this.http.get<ITrackApiPatientListApiResult>(url, options).toPromise();
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      throw e;
    }
  }

  getCareManagementPatients(
    tierNum: number,
    tierId: string,
    cohortId: number
  ): Observable<ITrackCareManagementPatient[]> {
    const url = `${this.trackBaseURL}/Patients/careManagement`;
    const TierNum: string = tierNum.toString();
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: { ...{ TierNum, TierId: tierId, CohortId: cohortId } }
    };

    return this.http.get<ITrackCareManagementPatient[]>(url, options).pipe(
      catchError(error => {
        if (environment.production) {
          // TODO - need to decide what to display
          this.ToastrMessageService.error(error);
        }
        console.error(error);
        throw error;
      })
    );
  }
  /** Get the tierId the org. This is the tierId that the org is mapped to. */
  getTierIdFromWpOrgId(orgId: string) {
    return this.orgIdToTierIdMap[orgId];
  }

  /** Return the tierID that's mapped to a Wellpepper org, even if I'm looking at
   * a child node.
   * E.g. getWpMappedTierIdFromTierId("OR13.01") => "OR13"
   */
  async getWpMappedTierIdFromTierId(tierId: string) {
    const orgId = await this.getWpOrgIdFromTierId(tierId);
    if (!orgId) {
      return null;
    }
    return this.getTierIdFromWpOrgId(orgId);
  }
  async getWpMappedTierNumFromTierId(tierId: string) {
    // currently we support only tierNum 2
    // TODO (in some near future) rebuild this function
    return 2;
  }

  /** Get the related Wellpepper organization for patient engagement
   * This might not be the tier passed in.
   * REVIEW - this could use a unit test but may need refactoring for testability
   */
  async getWpOrgIdFromTierId(tierId: string) {
    // console.debug("getWpOrgIdFromTierId " + tierId);

    // Don't get the result directly from the API, need to traverse up the hierarchy
    // const orgId = await this.getOrgIdFromWellpepperApi(tierId);
    // if (typeof orgId !== "undefined") {
    //   return orgId;
    // }

    const item = await this.HierarchyTierService.hierarchyTierItemFromId(tierId);
    if (!item) {
      // console.debug(`getWpOrgIdFromTierId ${tierId} - no hierarchy TierItem`)
      // Probably not initialized yet.
      return null;
    }

    const tierIds = [item.tier1_ID, item.tier2_ID, item.tier3_ID, item.tier4_ID].filter(x => !!x);

    let currentTierIndex = tierIds.findIndex(x => x === tierId);
    if (currentTierIndex < 0) {
      // console.debug(`getWpOrgIdFromTierId(${tierId}) - not found in hierarchyTiers`)
      throw new CareOrgNotFoundError(
        `getWpOrgIdFromTierId: tierId ${tierId} is not present in HierarchyTierService.hierarchyTiers`
      );
    }

    // REVIEW - there's a bit of a race condition here - would be good to refactor this some more so that
    // multiple requests aren't submitted when this function is called asynchronously several times.
    // console.debug(`getWpOrgIdFromTierId(${tierId}) starting from index ${currentTierIndex}`)
    while (currentTierIndex >= 0) {
      const currentTierId = tierIds[currentTierIndex];
      // console.debug(`getWpOrgIdFromTierId(${tierId}) processing ${currentTierId}`);
      let orgId = await this.getOrgIdFromWellpepperApi(currentTierId);
      if (orgId) {
        // console.debug(`getWpOrgIdFromTierId(${tierId}) - found orgId ${orgId} for ${currentTierId}`);
        return orgId;
      }
      currentTierIndex--;
    }
    // console.debug(`getWpOrgIdFromTierId(${tierId}) not found in hierarchyTiers`, item);
    return null;
  }

  async getOrgIdFromWellpepperApi(tierId: string): Promise<string> {
    // console.log("getOrgIdFromWellpepperApi: tierIdToOrgIdMap", this.tierIdToOrgIdMap);
    if (this.tierIdToOrgIdMap[tierId]) {
      // Already requested, return the same promise.
      return this.tierIdToOrgIdMap[tierId];
    }

    // CC-3200 don't use ?where as WAF doesn't like it.
    // Environment is implicit in the bearer token
    const headers = {
      Authorization: 'Bearer ' + this._authService.IdToken,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const getOrgUrl = `${this.wpBaseURL}/orgsForTierId/${tierId}`;
    // console.debug(`getOrgIdFromWellpepperApi requesting for ${tierId}`);
    let orgPromise = this.http.get<wpapi.model.Organization>(getOrgUrl, { headers: headers }).toPromise();
    this.tierIdToOrgIdMap[tierId] = orgPromise
      .then(org => {
        // console.debug(`getOrgIdFromWellpepperApi *** Mapped ${tierId} to ${org._id}`);
        this.orgIdToTierIdMap[org._id] = tierId;
        return org._id;
      })
      .catch(err => {
        if (err.status === 401) {
          // CC-???? 2022-03-24 - if a user is restricted to access at the PP or practice level they'll
          // get a 401 when attempting the lookup. Because of this we'll ignore the 401 and just return an empty value.
          return null;
        }

        // If it's a 404 it's expected that sometimes it's not provisioned - ignore it
        // console.debug(`getOrgIdFromWellpepperApi *** ${tierId} not found`);
        if (!(err instanceof HttpErrorResponse) || err.status !== 404) {
          throw err;
        }
        return null;
      });
    return this.tierIdToOrgIdMap[tierId];
  }

  async getTrackAwvPatients(
    tierNum: number,
    tierId: string,
    beginDate?: Date,
    endDate?: Date,
    chpatid?: string,
    componentId?: string
  ): Promise<Array<ITrackApiAwvPatient>> {
    let url = `${this.trackBaseURL}/AwvWorklist`;

    const TierNum: string = tierNum.toString();
    const params: { [key: string]: string } = { TierNum, TierId: tierId };
    if (beginDate) {
      params.BeginDate = format(beginDate, 'MM/dd/y');
    }
    if (endDate) {
      params.EndDate = format(endDate, 'MM/dd/y');
    }
    if (chpatid) {
      params.ChPatId = chpatid;
    }
    if (componentId) {
      params.componentId = componentId;
    }

    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: params
    };

    try {
      return await this.http.get<Array<ITrackApiAwvPatient>>(url, options).toPromise();
    } catch (e) {
      this.ToastrMessageService.error(e);
    }
  }

  async getTrackCohortPatients(
    tierNum: number,
    TierId: string,
    cohortId?: number
  ): Promise<ITrackApiCohortPatientListApiResult> {
    let url = `${this.trackBaseURL}/Patients/cohorts`;

    const TierNum: string = tierNum.toString();
    const CohortId: string = cohortId.toString();
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: { TierNum, TierId, CohortId }
    };

    try {
      return await this.http.get<ITrackApiCohortPatientListApiResult>(url, options).toPromise();
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
    }
  }

  async getTrackEDUtilizationPatients(tierNum: number, tierId: string): Promise<Array<ITrackApiEdUtilizationPatient>> {
    const url = `${this.trackBaseURL}/EdUtilizationWorklist`;
    const TierNum: string = tierNum.toString();
    const TierId: string = tierId;

    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: { TierNum, TierId }
    };

    try {
      return await this.http.get<Array<ITrackApiEdUtilizationPatient>>(url, options).toPromise();
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
    }
  }

  async getTrackIHEAWVScheduleListPatients(tierNum: number, tierId: string) {
    let url = `${this.apiBaseUrl}/ihawv/scheduleGet`;

    const params: { tierNum: string; tierId: string } = {
      tierNum: `${tierNum}`,
      tierId
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: params
    };

    return await this.http.get<Array<IIHEAWVPatient>>(url, options).toPromise();
  }

  async getTrackIHEListPatients(
    tierNum: number,
    tierId: string,
    fromDate?: Date,
    toDate?: Date
  ): Promise<Array<ITrackApiIHEFilesListVisitInfo>> {
    let url = `${this.apiBaseUrl}/ihawv/patientIheVisitList`;

    const params: { tierNum: string; tierId: string } = {
      tierNum: `${tierNum}`,
      tierId
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: params
    };

    let patientsVisits = await this.http.get<Array<ITrackApiIHEFilesListVisitInfo>>(url, options).toPromise();
    return patientsVisits?.filter(visit => this.filterIHEFiles(visit));
  }

  async getTrackIHEBillingListPatients(
    tierNum: number,
    tierId: string,
    fromDate?: Date,
    toDate?: Date
  ): Promise<Array<ITrackApiIHEBillingListVisitInfo>> {
    let url = `${this.apiBaseUrl}/ihawv/billingGet`;

    const params: { tierNum: string; tierId: string } = {
      tierNum: `${tierNum}`,
      tierId
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: params
    };

    return await this.http.get<Array<ITrackApiIHEBillingListVisitInfo>>(url, options).toPromise();
  }

  /** possibly optional - if the data is returned from the track/Patients?chpatid=... endpoint
   * we can add the data to the Track patient interface.
   */
  async getIHEDataForPatient(
    tierNum: number,
    tierId: string,
    chPatId: string
  ): Promise<Array<ITrackApiIHEFilesListVisitInfo>> {
    let url = `${this.apiBaseUrl}/ihawv/patientIHeVisitList`;

    const params = {
      tierNum: `${tierNum}`,
      tierId,
      chPatId
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: params
    };

    const visits = await this.http.get<Array<ITrackApiIHEFilesListVisitInfo>>(url, options).toPromise();
    return visits?.filter(visit => this.filterIHEFiles(visit));
  }

  filterIHEFiles(visit: ITrackApiIHEFilesListVisitInfo): boolean {
    // remove patients who don't have either all 3 summary files or CMR
    try {
      let assessmentFileTypes: { [key in PatientIHEFileType]?: boolean } = AssessmentFileTypes.reduce(
        (a, v) => ({ ...a, [v]: true }),
        {}
      );
      let casemgmtFile = { Casemgmt: true };
      visit.fileInfo?.forEach(v => {
        delete assessmentFileTypes[v.fileType];
        delete casemgmtFile[v.fileType];
      });
      return !Object.keys(assessmentFileTypes).length || !Object.keys(casemgmtFile).length;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  /** Store a *comment* for the patient's IH-AWV file status
   * 20220922 - editable file status has been removed from the specification
   * only the comment/note can be created.
   */
  async putIHEFileInfoForVisit(
    tierNum: number,
    tierId: string,
    chpatid: string,
    visitId: string,
    comment: string
  ): Promise<void> {
    let url = `${this.trackBaseURL}/Notes`;

    const payload = {
      chpatid,
      text: comment,
      evaluationId: visitId,
      noteTypeId: PatientNoteType.PatientNoteTypeIHAWV
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    await this.http.post(url, payload, options).toPromise();
  }

  async downloadIHEFileNoteForPatient(
    tierId: string,
    tierNum: number,
    chPatId: string,
    visitId: string
  ): Promise<Array<PatientIHEFilesNote>> {
    let url = `${this.trackBaseURL}/Notes`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: {
        chpatid: chPatId,
        evaluationId: visitId,
        noteTypeId: `${PatientNoteType.PatientNoteTypeIHAWV}`
      }
    };
    let fileNotes: Array<PatientIHEFilesNote> = await this.http
      .get<Array<PatientIHEFilesNote>>(url, options)
      .toPromise();
    return fileNotes;
  }

  /** Get patient with data from all patient data sources
   * Track api
   * Wellpepper api
   * @param chPatId - Caravan Patient ID
   * @param orgId - Wellpepper organization ID
   *    TODO - perhaps should be tierId
   * @returns - a promise resolving to combined patient data model
   */
  async getPatient(orgId: string, chPatId: string): Promise<Patient> {
    const patientIdIssuer = environment.patientIdentityProvider.issuer;
    const wpapiPromise = new Promise<wpapi.model.CaravanPatient>((resolve, reject) => {
      try {
        // const orgId = await this.getWpOrgIdFromTierId(tierId);
        const tierIdForWpPatient = this.orgIdToTierIdMap[orgId];
        const subject = `${tierIdForWpPatient}:${chPatId}`;
        const wpapiUrl = `${this.wpBaseURL}/users/federated?type=custom&issuer=${encodeURIComponent(
          patientIdIssuer
        )}&subject=${encodeURIComponent(subject)}`;

        this.http
          .get<wpapi.model.Patient>(wpapiUrl, {
            headers: {
              'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
              Authorization: 'Bearer ' + this._authService.IdToken
            }
          })
          .toPromise()
          .then(value => resolve(value))
          .catch(err => reject(err));
      } catch (err) {
        reject(err);
      }
    });

    const trackUrl = `${this.trackBaseURL}/Patients?ChPatId=${chPatId}`;
    try {
      const trackPromise = this.http
        .get<ITrackApiPatient>(trackUrl, {
          headers: {
            Authorization: 'Bearer ' + this._authService.IdToken,
            'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
          }
        })
        .toPromise();
      const [wpResult, trackResult] = await Promise.allSettled([wpapiPromise, trackPromise]);

      const wpPatient = wpResult.status === 'fulfilled' ? wpResult.value : null;

      const trackPatient = trackResult.status === 'fulfilled' ? trackResult.value : null;

      return Patient.fromCareAndTrackPatient(wpPatient, trackPatient);
    } catch (error) {
      this.ToastrMessageService.error(error);
    }
  }

  /** Get the "care co-ordinator" for the patient from the wellpepper API */
  async getProfessional(professionalId: string): Promise<wpapi.model.Professional> {
    if (!professionalId) {
      return null;
    }
    const url = `${this.wpBaseURL}/users/${professionalId}`;
    return this.http
      .get<wpapi.model.Professional>(url, {
        headers: {
          'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
          Authorization: `Bearer ${this._authService.IdToken}`
        }
      })
      .toPromise();
  }

  async getTimeTrackingEvents(
    tierId: string,
    chPatId: string,
    firstDate: Date,
    lastDate: Date
  ): Promise<Array<ITimeTrackingEvent>> {
    let orgId = await this.getWpOrgIdFromTierId(tierId);
    if (!orgId) {
      return [];
    }
    let url = `${this.wpBaseURL}/orgs/${orgId}/chpatid/${chPatId}/timeTrackingEvents`;
    if (firstDate && lastDate) {
      url += `?not-before=${encodeURIComponent(firstDate.toISOString())}&not-after=${encodeURIComponent(
        lastDate.toISOString()
      )}`;
    } else if (firstDate) {
      url += `?not-before=${encodeURIComponent(firstDate.toISOString())}`;
    } else if (lastDate) {
      url += `?not-after=${encodeURIComponent(lastDate.toISOString())}`;
    }
    let res = await this.http
      .get<Array<wpapi.model.TimeTrackingEvent>>(url, {
        headers: {
          'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
          Authorization: `Bearer ${this._authService.IdToken}`
        }
      })
      .toPromise();
    return res.map(x => {
      return {
        activity: x.activity,
        start: x.start,
        durationSeconds: x.durationSeconds,
        providerId: x.providerId,
        source: x.source,
        createdAt: x.createdAt,
        createdBy: x.createdBy,
        _id: x._id,
        notes: x.notes,
        createdByName: x.createdByName
      };
    });
  }
  /** getDeletedTimeTrackingE
   * @param tierId
   * @param chPatId
   * @param firstDate
   * @param lastDate
   */
  async getDeletedTimeTrackingEvents(
    tierId: string,
    chPatId: string,
    firstDate: Date,
    lastDate: Date
  ): Promise<Array<ITimeTrackingEvent>> {
    let orgId = await this.getWpOrgIdFromTierId(tierId);
    if (!orgId) {
      return [];
    }
    let url = `${this.wpBaseURL}/orgs/${orgId}/chpatid/${chPatId}/timeTrackingEvents/deleted`;
    if (firstDate && lastDate) {
      url += `?not-before=${encodeURIComponent(firstDate.toISOString())}&not-after=${encodeURIComponent(
        lastDate.toISOString()
      )}`;
    } else if (firstDate) {
      url += `?not-before=${encodeURIComponent(firstDate.toISOString())}`;
    } else if (lastDate) {
      url += `?not-after=${encodeURIComponent(lastDate.toISOString())}`;
    }
    let res = await this.http
      .get<Array<wpapi.model.TimeTrackingEvent>>(url, {
        headers: {
          'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
          Authorization: `Bearer ${this._authService.IdToken}`
        }
      })
      .toPromise();
    return res.map(x => {
      return {
        activity: x.activity,
        start: x.start,
        durationSeconds: x.durationSeconds,
        providerId: x.providerId,
        source: x.source,
        createdAt: x.createdAt,
        createdBy: x.createdBy,
        _id: x._id,
        notes: x.notes,
        createdByName: x.createdByName
      };
    });
  }

  /** deleteTimeTrackingEvent
   * @param tierId
   * @param ITimeTrackingEvent
   *
   */
  async deleteTimeTrackingEvent(tierId: string, event: ITimeTrackingEvent) {
    const orgId = await this.getWpOrgIdFromTierId(tierId);
    if (!orgId) {
      this.ToastrMessageService.error(
        new Error("Can't upload time tracking data: requires setup for patient engagement")
      );
      return;
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    let url = `${this.wpBaseURL}/orgs/${orgId}/chpatid/${event.patientId}/timeTrackingEvents`;
    url += `/${event._id}`;
    try {
      return await this.http.delete(url, options).toPromise();
    } catch (e) {
      console.error(e);
      this.ToastrMessageService.error(e);
    }
  }

  async uploadTimeTrackingEvent(tierId: string, event: ITimeTrackingEvent) {
    const orgId = await this.getWpOrgIdFromTierId(tierId);
    if (!orgId) {
      this.ToastrMessageService.error(
        new Error("Can't upload time tracking data: requires setup for patient engagement")
      );
      return;
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    let url = `${this.wpBaseURL}/orgs/${orgId}/chpatid/${event.patientId}/timeTrackingEvents`;
    if (event._id) {
      // for update make sure to pass only 'start', 'source', 'activity', 'notes' or 'durationSeconds'
      let reWriteEvent = {
        start: event.start,
        source: event.source,
        activity: event.activity,
        notes: event.notes,
        durationSeconds: event.durationSeconds
      };
      url += `/${event._id}`;
      try {
        return await this.http.put(url, reWriteEvent, options).toPromise();
      } catch (e) {
        console.error(e);
        this.ToastrMessageService.error(e);
      }
    } else {
      try {
        return await this.http.post(url, event, options).toPromise();
      } catch (e) {
        console.error(e);
        this.ToastrMessageService.error(e);
      }
    }
  }

  async getMyPatientsActiveAlerts(orgId: string): Promise<{ [aboutUserId: string]: number }> {
    let url = `${this.wpBaseURL}/orgs/${orgId}/alerts/countByChPatId`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };

    try {
      return await this.http
        .get<{
          [aboutUserId: string]: number;
        }>(url, options)
        .toPromise();
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        alert('Failed to get unread messages - API call failed: ' + e.message || e.status);
      }
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }
  async getCarePatientData(orgId: string): Promise<{ [aboutUserId: string]: number }> {
    let url = `${this.wpBaseURL}/orgs/${orgId}/patientIds`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };

    try {
      return await this.http
        .get<{
          [aboutUserId: string]: number;
        }>(url, options)
        .toPromise();
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
        alert('Failed to get unread messages - API call failed: ' + e.message || e.status);
      }
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }

  async createUser(patientObj): Promise<wpapi.model.Patient> {
    let url = `${this.wpBaseURL}/users`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };

    try {
      return await this.http
        .post<wpapi.model.Patient>(url, patientObj, options)
        // TODO - this looks like the wrong return type
        .toPromise();
    } catch (e) {
      if (environment.production) {
        // TODO - need to decide what to display
      }
      //TODO - Remove this once the API changes are done for the SMS invite.
      if (e.status !== 409) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      return e;
    }
  }
  async assignEpisodesOfCare(patientId: string, episodesOfCare: any) {
    let url = `${this.wpBaseURL}/users/${patientId}/episodesOfCare`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    try {
      return await this.http.post<{ [aboutUserId: string]: number }>(url, episodesOfCare, options).toPromise();
    } catch (e) {
      if (environment.production) {
      }
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }
  async assignEpisodeOfCare(options) {
    var episodeOfCare = options.episodeOfCare;
    var patient = options.patient;

    if (!episodeOfCare) {
      throw new Error('episodeOfCare is required');
    }
    if (!episodeOfCare.procedureName) {
      throw new Error('episodeOfCare.procedureName is required');
    }
    if (!patient) {
      throw new Error('Patient is required');
    }
    if (!patient._id) {
      throw new Error('Patient._id is required');
    }

    var data = {
      name: episodeOfCare.procedureName,
      procedureName: episodeOfCare.procedureName,
      procedureShortName: episodeOfCare.procedureShortName,
      procedureDate: episodeOfCare.procedureDate,
      procedureTimeZoneName: episodeOfCare.procedureTimeZoneName,
      extendedFields: episodeOfCare.extendedFields
    };
    const headers_options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    var promise = this.http
      .post(`${this.wpBaseURL}/users/${patient._id}/episodesOfCare`, data, headers_options)
      .toPromise()
      .then(
        function (response: any) {
          return response;
        },
        function (response) {
          this.ToastrMessageService.error(response);
          throw new Error(response);
        }
      );
    if (episodeOfCare.protocolId) {
      return promise.then(episodeOfCare => {
        return this.assignProtocol({
          protocol: { _id: episodeOfCare.protocolId },
          episodeOfCare: episodeOfCare,
          patient: patient
        }).then(protocol => {
          episodeOfCare.protocols = [protocol];
          return episodeOfCare;
        });
      });
    } else {
      return promise.then(function (episodeOfCare) {
        return episodeOfCare;
      });
    }
  }
  async assignProtocol(data) {
    var protocol = data.protocol;
    var patient = data.patient;
    var episodeOfCare = data.episodeOfCare;
    var modules = data.modules;
    if (!protocol) {
      throw 'protocol is required';
    }
    if (!protocol._id) {
      throw 'protocol id not found';
    }
    if (!patient) {
      throw 'patient is required';
    }
    if (!patient._id) {
      throw 'patient id not found';
    }
    const headers_options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    // TODO - finish refactoring: normalize caller treatment of response data so we can reduce duplicate handling below.
    var request;
    var promise;
    let url = `${this.wpBaseURL}/users/${patient._id}/protocols/${protocol._id}`;
    if (episodeOfCare) {
      if (!episodeOfCare._id) {
        throw 'episodeOfCare id not found';
      }
      try {
        return await this.http
          .post<wpapi.model.Protocol>(
            url,
            {
              episodeOfCareId: episodeOfCare._id,
              modules: modules
            },
            headers_options
          )
          .toPromise();
      } catch (error) {
        console.log('error =>', error);
      }
    } else {
      promise = this.http
        .post(`${this.wpBaseURL}/users/${patient._id}/protocols/${protocol._id}`, {}, headers_options)
        .toPromise()
        .then(
          // success
          (response: any) => {
            // The returned tasks & surveys might not have the protocol ID added.
            // Instead of relying on the API, add it here.
            _.each(response.userSurveys, us => this.addProtocolAsIncludedObject(us, protocol));
            _.each(response.tasks, t => this.addProtocolAsIncludedObject(t, protocol));

            try {
              return [response.userSurveys, response.tasks];
            } catch (error) {
              this.ToastrMessageService.error(error);
              // console.log('ERROR =>', error);
            }
          },
          // error
          response => {
            this.ToastrMessageService.error(response);
            throw new Error(response);
          }
        );
    }
  }

  /** Add a protocol as included object for a Task or UserSurvey
   * TODO - should roll this into the calling function as it's behavior is dependent
   * on the specific object type.
   */
  private addProtocolAsIncludedObject(x, p) {
    if (!(x.includedObjects && x.includedObjects.protocol)) {
      x.includedObjects = x.includedObjects || {};
      x.includedObjects.protocol = p;
    }
  }
  async addNotificationForPatient(patientId, notification): Promise<wpapi.model.Notification> {
    let url = `${this.wpBaseURL}/users/${patientId}/notifications`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    try {
      return await this.http.post<wpapi.model.Notification>(url, notification, options).toPromise();
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
    }
  }

  async getNotificationsForPatient(patient): Promise<Array<wpapi.model.Notification>> {
    if (!patient.carePatient) {
      return [];
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    const url = `${this.wpBaseURL}/users/${patient.carePatient._id}/notifications`;
    try {
      return await this.http.get<Array<wpapi.model.Notification>>(url, options).toPromise();
    } catch (e) {
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }

  /** postMessageToPatientList
   * @param patientIds - array of wellpepper (care) patient ids
   * @param tierId
   * @param msgText
   */

  // completions
  async getCompletionActivity(tierId, patient): Promise<Array<Completion>> {
    if (!patient.carePatient) {
      return [];
    }
    const orgId = await this.getWpOrgIdFromTierId(tierId);
    if (!orgId) {
      return [];
    }

    const url = `${this.wpBaseURL}/orgs/${orgId}/chpatid/${patient.chPatId}/completions`;
    const options = {
      headers: {
        Authorization: 'Bearer ' + this._authService.IdToken,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    };
    try {
      if (url) {
        return await this.http.get<Array<Completion>>(url, options).toPromise();
      }
    } catch (e) {
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }

  // tasks
  /// users/:userId/tasks';
  async getTasks(tierId, patient): Promise<Array<wpapi.model.Task>> {
    if (!patient?.carePatient) {
      return [];
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    const url = `${this.wpBaseURL}/users/${patient.carePatient._id}/tasks`; //await this.getWpUrl(patientId,'tasks'); //
    try {
      return await this.http.get<Array<wpapi.model.Task>>(url, options).toPromise();
    } catch (e) {
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }
  // logins
  async getUserActivity(tierId, patient): Promise<UserActivity> {
    if (!patient.carePatient) {
      return null;
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    const url = `${this.wpBaseURL}/users/${patient.carePatient._id}/activity`;
    try {
      return await this.http.get<UserActivity>(url, options).toPromise();
    } catch (e) {
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }
  // alert triggered/dismissed
  async getUserAlerts(tierId, patient): Promise<Array<wpapi.model.Alert>> {
    if (!patient.carePatient) {
      return [];
    }
    let orgId = await this.getWpOrgIdFromTierId(tierId);
    if (!orgId) {
      return [];
    }

    const url = `${this.wpBaseURL}/orgs/${orgId}/chpatid/${patient.chPatId}/alerts`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    try {
      return await this.http.get<Array<wpapi.model.Alert>>(url, options).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  // #region Surveys
  // var responseEndPoint = config.REST_API_ROOT + '/2/users/' + userId + '/surveyResponses/' + surveyResponseId;
  async getSurveyResponses(patientId, limit, skip, where): Promise<Array<SurveyResponse>> {
    if (!patientId) {
      return [];
    }
    const url = `${this.wpBaseURL}/users/${patientId}/surveyResponses?limit=${limit}&skip=${skip}&where=${where}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    try {
      return await this.http.get<Array<SurveyResponse>>(url, options).toPromise();
    } catch (e) {
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }

  async getSurvey(surveyId: string): Promise<UserSurvey> {
    if (!surveyId) {
      return null;
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    const url = `${this.wpBaseURL}/surveys/${surveyId}`;
    try {
      return await this.http.get<UserSurvey>(url, options).toPromise();
    } catch (e) {
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }
  //#endregion

  // chart reports
  async getUserChartReports(
    patientId: string,
    reportType: string,
    startDate: string,
    endDate: string
  ): Promise<Array<Completion>> {
    if (!patientId) {
      return [];
    }
    const url = `${this.wpBaseURL}/users/${patientId}/reports2/${reportType}/${startDate}/${endDate}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    try {
      return await this.http.get<Array<Completion>>(url, options).toPromise();
    } catch (e) {
      this.ToastrMessageService.error(e);
      console.error(e);
    }
  }
  // https://coachapiqa-ms.azure-api.net/track/HccDashboard/opportunities?CHPatID=129315

  /** Get episodes of care for a patient from the wellpepper API
   * tierId (required)
   * patient (required) consolidated patient object
   */
  async getPatientEpisodesOfCare(tierId: string, patient: Patient): Promise<EpisodeOfCareViewModel[]> {
    if (!patient.carePatient) {
      return [];
    }
    const url = `${this.wpBaseURL}/users/${patient.carePatient._id}/episodesOfCare`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    return await this.http.get<wpapi.model.EpisodeOfCare[]>(url, options).toPromise();
  }

  async getPatientTasks(tierId: string, patient: Patient): Promise<wpapi.model.Task[]> {
    if (!patient.carePatient) {
      return [];
    }
    const url = `${this.wpBaseURL}/users/${patient.carePatient._id}/tasks`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    return await this.http.get<wpapi.model.Task[]>(url, options).toPromise();
  }
  async updateTask(tierId: string, patient: Patient, taskId: string, taskData: any): Promise<boolean> {
    if (!patient.carePatient) {
      return false;
    }
    const url = `${this.wpBaseURL}/users/${patient.carePatient._id}/tasks/${taskId}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    let updatedTask;
    try {
      updatedTask = await this.http.put<{ message?: string }>(url, taskData, options).toPromise();
      return updatedTask?.message == 'object updated';
    } catch (error) {
      this.ToastrMessageService.error(error);
      return false;
    }
  }

  async activateOrDeactivateTask(task: { userId?: string; _id?: string }, activate: boolean) {
    if (!task || !task.userId || !task._id) {
      throw new Error('activateOrDeactivateTask: task must have id and userId');
    }
    const url = `${this.wpBaseURL}/users/${task.userId}/tasks/${task._id}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    return await this.http.put(url, { isActive: activate }, options).toPromise();
  }

  /**
   *
   * @param userId - wellpepper user id
   * @param episodeOfCareId
   * @param protocolId
   * @param moduleId
   * @param activate
   */
  async activateOrDeactivateModule(info: {
    userId: string;
    episodeOfCareId: string;
    protocolId: string;
    moduleId: string;
    activate: boolean;
  }) {
    if (!info || !info.userId || !info.episodeOfCareId || !info.protocolId || !info.moduleId) {
      throw new Error('activateOrDeactivateModule must have all values provided');
    }
    const url = `${this.wpBaseURL}/users/${info.userId}/episodesofcare/${info.episodeOfCareId}/protocols/${info.protocolId}/modules/${info.moduleId}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    return await this.http.put(url, { isActive: info.activate }, options).toPromise();
  }
  async updateCarePatient(cp_id: string, data: any): Promise<boolean> {
    if (!cp_id) {
      return null;
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: 'Bearer ' + this._authService.IdToken
      }
    };
    const url = `${this.wpBaseURL}/users/${cp_id}`;
    let result = await this.http.put<{ message?: string }>(url, data, options).toPromise();
    return result?.message == 'object updated';
  }
  public getTrackPatientFacesheet(CHPatID: string, facesheetName: string, isChronic: boolean = null): Promise<any> {
    // result might vary depending on facesheetName
    const url = `${this.trackBaseURL}/facesheet/${facesheetName}`;
    let IsChronic: string = null;
    if (isChronic !== null) {
      IsChronic = isChronic ? 'true' : 'false';
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: IsChronic == null ? { CHPatID } : { CHPatID, IsChronic }
    };

    return this.http.get<any>(url, options).toPromise();
  }
  // async updateTrackPatient(tierId: string, tierNum: number, patient: Patient & { trackPatient?: any, carePatient?: any }): Promise<any>{
  async updateTrackPatient(
    tierId: string,
    tierNum: number,
    data: {
      email: string;
      preferredName: string;
      phone: string;
      tierID: string;
      tierNum: number;
      carePatientId: string;
      mbi: any;
      firstName: string;
      lastName: string;
      middleName: string;
      dateOfBirth: string;
      gender: string;
      chPatId: number;
      ChPatID: number;
      supplementalId: number;
    }
  ): Promise<any> {
    let url = `${this.trackBaseURL}/Patients/update`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    data.dateOfBirth = moment(data.dateOfBirth).format('MM/DD/YYYY');
    return await this.http.post(url, data, options).toPromise();
  }

  public postTrackPatientFacesheetPdf(
    CHPatID: string,
    tierId: string,
    tierNum: number,
    detailedPdf?: boolean
  ): Observable<any> {
    let url = `${this.trackBaseURL}/Facesheet/pdf`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: { CHPatID, tierId, tierNum: tierNum.toString(), expanded: detailedPdf ? 'true' : 'false' },
      responseType: 'blob' as 'json' // hack to allow compilation
    };
    return this.http.post(url, null, options).pipe(
      tap(
        data => {
          //console.log('pdf downloaded')
        },
        // Log the result or error
        error => {
          // console.error(error);
          this.ToastrMessageService.error(error);
        }
      )
    );
  }

  async getDiseaseCohortData(): Promise<DiseaseCohortDefinition[]> {
    if (this.diseaseCohortDefinitions.length < 1) {
      let url = `${this.trackBaseURL}/Patients/diseaseCohortDefinitions`;
      const options = {
        headers: {
          'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
          Authorization: `Bearer ${this._authService.IdToken}`
        }
      };
      this.diseaseCohortDefinitions = await this.http.get<DiseaseCohortDefinition[]>(url, options).toPromise();
    }
    return this.diseaseCohortDefinitions;
  }
  convertDiseaseCohortIdToName(ids: number[] | string[] = []): string[] {
    let res = [];
    ids?.forEach(id => {
      let cohort = this.diseaseCohortDefinitions?.filter(ds => ds.cohortID == Number(id))[0]?.cohortName;
      if (cohort) {
        res.push(cohort);
      }
    });
    res.length == 0 ? res.push('') : void 0;
    return res;
  }
  splitStringIntoWords(string: string): string {
    return (
      string[0] +
      string
        .slice(1, string.length)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }

  async updatePatientSnfInfo(
    tierId: string,
    chPatId: string,
    snfInfo: PatientSnfInfo
  ): Promise<CaravanPatient['snfInfo']> {
    try {
      // TODO - use the request interceptor to inject the tokend
      let headers = {
        Authorization: 'Bearer ' + this._authService.IdToken,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      };
      delete snfInfo.createdAt;
      delete snfInfo.createdBy;

      let orgId = await this.getWpOrgIdFromTierId(tierId);
      if (!orgId) {
        return null;
      }
      let url = `${this.wpBaseURL}/orgs/${orgId}/chpatid/${chPatId}/snfInfo`;
      const options = {
        headers: headers
      };
      return await this.http.put<CaravanPatient['snfInfo']>(url, snfInfo, options).toPromise();
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
      this.ToastrMessageService.error(e);
      throw e;
    }
  }
  isPhoneNumberFormatValid(phoneNumber: string): boolean {
    return /^(\+\d{1,2}\s)?\(?[2-9]\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNumber);
  }
  isEmailFormatValid(email: string): boolean {
    return new RegExp('[a-zA-Z0-9_\\.\\+-]+[(?:@|#)]+[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+').test(email);
  }
  public getPatientProtocols(patId: string): Promise<Array<wpapi.model.Protocol>> {
    let url = `${this.wpBaseURL}/users/${patId}/protocols`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };

    return this.http.get<Array<wpapi.model.Protocol>>(url, options).toPromise();
  }
  formatPhoneNumberForTwilio(phoneNumber: string): string {
    return phoneNumber ? '+1' + phoneNumber.replace(/\D+/g, '') : '';
  }

  async getTrackHccVipCohort(TrackApiParams: ITrackAPIParams): Promise<ITrackApiPatient[]> {
    let url = `${this.trackBaseURL}/Patients/hccVipCohort`;
    let idToken = await this._authService.IdToken;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${idToken}`
      },
      params: { ...{}, ...TrackApiParams }
    };

    return this.http.get(url, options).toPromise() as any;
  }
  async getCareManagementCohort(TrackApiParams: ITrackAPIParams): Promise<any> {
    let url = `${this.trackBaseURL}/Patients/careManagement`;
    let idToken = await this._authService.IdToken;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${idToken}`
      },
      params: { ...{}, ...TrackApiParams }
    };

    return this.http.get(url, options).toPromise() as any;
  }
  /**
   * Notes/comments
   * @param ChPatId (string, nullable): patient id
   * @param Text (text): text of the note
   * @param NoteTypeId (number): note type request 1 => AWV Worklist, 3 => In-Home AWV
   * @param NoteId (number, nullable): if updating, ID specifying note to be updated
   * @param EvaluationId (number, nullable): if updating, ID specifying note to be updated
   */
  async getNote(ChPatId: string, NoteTypeId: number): Promise<Array<note>> {
    let url = `${this.trackBaseURL}/Notes`;
    let idToken = await this._authService.IdToken;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${idToken}`
      },
      params: {
        ChPatId,
        NoteTypeId: NoteTypeId.toString()
      }
    };
    return this.http.get<Array<note>>(url, options).toPromise();
  }
  async postNote(chPatId: string, text: string, noteTypeId: number, noteId: number, evaluationId: number) {
    let url = `${this.trackBaseURL}/Notes`;
    let idToken = await this._authService.IdToken;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${idToken}`
      }
    };
    const body = {
      chPatId,
      text,
      noteTypeId,
      noteId,
      evaluationId
    };
    return this.http.post(url, body, options).toPromise() as any;
  }

  async downloadIHEFile(fileId: string | string[], chPatID: string, tierId: string, tierNum: number, fileName: string) {
    let url = `${this.apiBaseUrl}/coach/api/patient/ihawvfiles/${chPatID}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      params: { tierId, tierNum: tierNum.toString(), fileId },
      responseType: 'blob' as 'json' // hack to allow compilation
    };

    let file = (await this.http.get(url, options).toPromise()) as any;
    if (typeof fileId != 'string' && fileId.length == 1) {
      return await downloadFile(fileName, file, 'application/pdf');
    }
    return await downloadFile(fileName, file, typeof fileId == 'string' ? 'application/pdf' : 'application/zip');
  }

  async updateAwvStatusDate(chPatId: number, statusDate: string, scheduleId: number) {
    // Create or edit scheduled date.
    let url = `${this.trackBaseURL}/AwvWorklist`;
    let idToken = await this._authService.IdToken;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${idToken}`
      }
    };
    const body = {
      chPatId,
      statusDate,
      scheduleId
    };
    return this.http.post(url, body, options).toPromise() as any;
  }

  async triggerTwilioFlowForPatient(
    orgId: string,
    userId: string,
    taskId: string,
    userHasSeenSMSOptOut: boolean
  ): Promise<wpapi.model.Notification> {
    let url = `${this.wpBaseURL}/orgs/${orgId}/user/${userId}/triggerTwilioFlowTask/${taskId}/${userHasSeenSMSOptOut}`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    try {
      let result = await this.http.post<any>(url, {}, options).toPromise();
      return result;
    } catch (e) {
      if (environment.production) {
        this.ToastrMessageService.error(e);
      }
      console.error(e);
    }
  }

  async createTwilioFlowSchedule(
    userId: string,
    taskId: string,
    frequency: string,
    isAdaptive: boolean
  ): Promise<wpapi.model.CreateScheduleResponse> {
    const url = `${this.wpBaseURL}/users/${userId}/tasks/${taskId}/twilioflow/schedule`; // /users/:userId/tasks/:taskId/twilioflow/schedule

    const requestBody = {
      startDate: this.getNextBusinessDay(),
      frequency: frequency,
      isAdaptive: isAdaptive
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    let result = await this.http.post<wpapi.model.CreateScheduleResponse>(url, requestBody, options).toPromise();
    return result;
  }

  async getSMSScheduleReport(userId: string, taskId: string): Promise<wpapi.model.TwilioScheduleReportResponse> {
    const url = `${this.wpBaseURL}/users/${userId}/tasks/${taskId}/twilioflow/schedule/report`; // /users/:userId/tasks/:taskId/twilioflow/schedule
    const today = new Date();
    const requestBody = {
      currentDate: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        date: today.getDate()
      }
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      }
    };
    let result = await this.http.post<wpapi.model.TwilioScheduleReportResponse>(url, requestBody, options).toPromise();
    return result;
  }

  async stopTwilioFlowSchedule(userId: string, taskId: string) {
    let url = `${this.wpBaseURL}/users/${userId}/tasks/${taskId}/twilioflow/schedule`;
    const today = new Date();
    const body: wpapi.model.StopScheduleRequestPayload = {
      stopDate: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        date: today.getDate()
      }
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        Authorization: `Bearer ${this._authService.IdToken}`
      },
      body: body
    };
    let result = await this.http.delete(url, options).toPromise();
    return result;
  }

  getNextBusinessDay() {
    const today = new Date();
    const nextDay = new Date(today);
    if (nextDay.getDay() === 5) {
      nextDay.setDate(nextDay.getDate() + 3);
    } else if (nextDay.getDay() === 6) {
      nextDay.setDate(nextDay.getDate() + 2);
    } else {
      nextDay.setDate(nextDay.getDate() + 1);
    }

    return {
      year: nextDay.getFullYear(),
      month: nextDay.getMonth() + 1,
      date: nextDay.getDate()
    };
  }
}
