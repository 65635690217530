import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { environment } from 'src/environments/environment';
import { PatientEngagement, PatientEngagementComponentIds } from '@shared/enums';
import { PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';

@Injectable({
  providedIn: 'root'
})
export class EdUtilizationPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const item: IPatientFilterPreset = {
      category: PresetCategory.Cohorts,
      name: CarePatientItem.EdUtilization,
      presetKey: CarePatientItem.EdUtilization,
      label: 'ED Utilization',
      filters: [],
      componentId: CareComponentConstants.EdUtilizationWorklist,
      hidePreset: true,
      hidePresetAdditionCondition: this.userAccessService.hasComponent(
        this.userAccess,
        CareComponentConstants.CareManagement
      ),
      columns: this.getPresetColumns(),
      apiParams: {
        track: {
          TopCount: '20',
          OrderBy: 'openRAFWt',
          IncludeDeceased: 'false'
        }
      }
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean }[] {
    return [
      { select: true },
      { patientName: true },
      { dob: true },
      { gender: true },
      { assignment: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      {
        actions: this.userAccessService.hasComponent(this.userAccess, PatientEngagementComponentIds)
      },
      { pyRaf: true },
      { mbi: true },
      { attributedCP: true },
      { lastVisit: true },
      { totalCost: true },
      { edVisits: true },
      {
        hccGapCount: this.agGridCustomFilteringFlag
      },
      {
        diseaseCohorts: this.agGridCustomFilteringFlag
      },
      {
        ccmEligible: this.agGridCustomFilteringFlag
      },
      {
        raF_YTD: this.agGridCustomFilteringFlag
      },
      { openRAFWt: true },
      { attributedPractice: true },
      { phoneNumber: true },
      { awvTypeDue: true },
      { lastAWVDate: true }
    ];
  }
}
