/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HierarchyTierItem } from '../models/hierarchy-tier-item';
import { HierarchyTierName } from '../models/hierarchy-tier-name';
import { PracticeListResult } from '../models/practice-list-result';
import { ReportTierInfo } from '../models/report-tier-info';

@Injectable({
  providedIn: 'root',
})
export class HierarchyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation hierarchyGet
   */
  static readonly HierarchyGetPath = '/Hierarchy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hierarchyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyGet$Response(params?: {
    tier?: number;
    parentId?: string;
    cin?: number;
    region?: number;
    quarter?: number;
    year?: number;
    menuId?: number;
    tier1Id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReportTierInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, HierarchyService.HierarchyGetPath, 'get');
    if (params) {
      rb.query('tier', params.tier, {});
      rb.query('parentId', params.parentId, {});
      rb.query('cin', params.cin, {});
      rb.query('region', params.region, {});
      rb.query('quarter', params.quarter, {});
      rb.query('year', params.year, {});
      rb.query('menuId', params.menuId, {});
      rb.query('tier1Id', params.tier1Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReportTierInfo>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hierarchyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyGet(params?: {
    tier?: number;
    parentId?: string;
    cin?: number;
    region?: number;
    quarter?: number;
    year?: number;
    menuId?: number;
    tier1Id?: string;
  },
  context?: HttpContext

): Observable<Array<ReportTierInfo>> {

    return this.hierarchyGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReportTierInfo>>) => r.body as Array<ReportTierInfo>)
    );
  }

  /**
   * Path part for operation hierarchyTierGet
   */
  static readonly HierarchyTierGetPath = '/Hierarchy/tier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hierarchyTierGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyTierGet$Response(params?: {
    tier?: number;
    tierId?: string;
    chTinId?: number;
    supplementalId?: number;
    quarter?: number;
    year?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<HierarchyTierItem>> {

    const rb = new RequestBuilder(this.rootUrl, HierarchyService.HierarchyTierGetPath, 'get');
    if (params) {
      rb.query('tier', params.tier, {});
      rb.query('tierId', params.tierId, {});
      rb.query('chTinId', params.chTinId, {});
      rb.query('supplementalId', params.supplementalId, {});
      rb.query('quarter', params.quarter, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HierarchyTierItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hierarchyTierGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyTierGet(params?: {
    tier?: number;
    tierId?: string;
    chTinId?: number;
    supplementalId?: number;
    quarter?: number;
    year?: number;
  },
  context?: HttpContext

): Observable<HierarchyTierItem> {

    return this.hierarchyTierGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<HierarchyTierItem>) => r.body as HierarchyTierItem)
    );
  }

  /**
   * Path part for operation hierarchyTiersGet
   */
  static readonly HierarchyTiersGetPath = '/Hierarchy/tiers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hierarchyTiersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyTiersGet$Response(params?: {
    tierIds?: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<HierarchyTierName>>> {

    const rb = new RequestBuilder(this.rootUrl, HierarchyService.HierarchyTiersGetPath, 'get');
    if (params) {
      rb.query('tierIds', params.tierIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HierarchyTierName>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hierarchyTiersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyTiersGet(params?: {
    tierIds?: Array<string>;
  },
  context?: HttpContext

): Observable<Array<HierarchyTierName>> {

    return this.hierarchyTiersGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<HierarchyTierName>>) => r.body as Array<HierarchyTierName>)
    );
  }

  /**
   * Path part for operation hierarchyPracticesGet
   */
  static readonly HierarchyPracticesGetPath = '/Hierarchy/practices';

  /**
   * List practices for a given tier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hierarchyPracticesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyPracticesGet$Response(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PracticeListResult>>> {

    const rb = new RequestBuilder(this.rootUrl, HierarchyService.HierarchyPracticesGetPath, 'get');
    if (params) {
      rb.query('tierNum', params.tierNum, {});
      rb.query('tierId', params.tierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PracticeListResult>>;
      })
    );
  }

  /**
   * List practices for a given tier.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hierarchyPracticesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hierarchyPracticesGet(params?: {
    tierNum?: number;
    tierId?: string;
  },
  context?: HttpContext

): Observable<Array<PracticeListResult>> {

    return this.hierarchyPracticesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PracticeListResult>>) => r.body as Array<PracticeListResult>)
    );
  }

}
