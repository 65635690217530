<div class="u-height--full u-display--flex u-flex-direction--column">
    <div class="margin-topBottom">
        <div><strong>Note:</strong> Please review the point-of-contact(s) for your account(s) below.</div>
        <strong>ACO Champions</strong>, please send email to <a
            href="mailto:{{supportEmail}}">{{supportEmail}}</a> with any updates and the action(s) to
        be taken. For multiple changes, Download to an Excel sheet and submit as an attachment with any edits noted to
        <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.
    </div>

    <ag-grid-angular class="ag-theme-material contacts-grid u-height--full" [pagination]="true"
        [paginationAutoPageSize]="true" multiSortKey="ctrl" [rowData]="rowData" [columnDefs]="columnDefs"
        (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"  coachAgGridResize>
    </ag-grid-angular>
    <button [disabled]="rowData == null || rowData.length === 0" class="button is-small margin-right export-button" (click)="export()"> Export </button>
</div>