import { ProcessCellForExportParams } from "ag-grid-community";
import moment from "moment";

export function compareString(
    a: string,
    b: string,
    nodeA?,
    nodeB?,
    isInverted?
): 0 | 1 | -1 {
    a == null ? (a = '') : (a = a.trim());
    b == null ? (b = '') : (b = b.trim());

    if (a == b) {
        return 0;
    }
    // Always sort empty stuff to the end
    if (a == '') return isInverted ? -1 : 1;
    if (b == '') return isInverted ? 1 : -1;

    // Strings need to be compared in the same way as the server side
    // REVIEW There's some subtle issues here: sql azure database uses
    // SQL_Latin1_General_CP1_CI_AS while mongodb presumably uses en_US as described here
    // https://docs.mongodb.com/manual/reference/collation-locales-defaults/#collation-languages-locales
    // which should be similar to what happens using localeCompare.
    // Because they're not exactly the same there may be some character combinations
    //  which could cause the output to get "stuck" on one input stream while trying to merge them.
    const c = a.localeCompare(b, undefined, {
        sensitivity: 'base',
        numeric: true,
    });
    return c == 0 ? 0 : c < 0 ? -1 : 1;
}

export function compareStringPlain(
    a: string,
    b: string,
    nodeA?,
    nodeB?,
    isInverted?
): 0 | 1 | -1 {
    a == null ? (a = '') : (a = a.trim());
    b == null ? (b = '') : (b = b.trim());

    if (a == b) {
        return 0;
    }
    // Always sort empty stuff to the end
    if (a == '') return isInverted ? -1 : 1;
    if (b == '') return isInverted ? 1 : -1;

    // Strings need to be compared in the same way as the server side
    // REVIEW There's some subtle issues here: sql azure database uses
    // SQL_Latin1_General_CP1_CI_AS while mongodb presumably uses en_US as described here
    // https://docs.mongodb.com/manual/reference/collation-locales-defaults/#collation-languages-locales
    // which should be similar to what happens using localeCompare.
    // Because they're not exactly the same there may be some character combinations
    //  which could cause the output to get "stuck" on one input stream while trying to merge them.
    const c = a.localeCompare(b, undefined, {
        sensitivity: 'base',
        numeric: false,
    });
    return c == 0 ? 0 : c < 0 ? -1 : 1;
}

export function compareNumber(
    a: number,
    b: number,
    nodeA,
    nodeB,
    isInverted
): 0 | 1 | -1 {
    if (a == b) {
        return 0;
    }
    if (null == a || isNaN(a)) return isInverted ? -1 : 1;
    if (null == b || isNaN(b)) return isInverted ? 1 : -1;
    return a < b ? -1 : 1;
}
export function compareRiskTrend(
    a: string,
    b: string,
    nodeA,
    nodeB,
    isInverted
): 0 | 1 | -1 {
    if (null == a) return isInverted ? -1 : 1;
    if (null == b) return isInverted ? 1 : -1;

    var riskTrends = {};
    riskTrends['Decreasing'] = 0;
    riskTrends['Same'] = 1;
    riskTrends['Increasing'] = 2;

    let result = riskTrends[a] - riskTrends[b]
    if (result < -2 || result > 2)
        result = 2;

    if (result == 0) {
        return 0;
    }

    return result < 0 ? -1 : 1;
}

export function compareRiskCategory(
    a: string,
    b: string,
    nodeA,
    nodeB,
    isInverted
): 0 | 1 | -1 {
    if (null == a) return isInverted ? -1 : 1;
    if (null == b) return isInverted ? 1 : -1;
    var riskCategories = {};
    riskCategories['Low'] = 0;
    riskCategories['Medium'] = 1;
    riskCategories['High'] = 2;

    let result = riskCategories[a] - riskCategories[b]
    if (result < -2 || result > 2)
        result = 2;

    if (result == 0) {
        return 0;
    }

    return result < 0 ? -1 : 1;
}
export function compareDate(
    a: Date,
    b: Date,
): 0 | 1 | -1 {
    if (a.getTime() == b.getTime()) {
        return 0;
    }
    return a.getTime() < b.getTime() ? 1 : -1;
}

export function currencyCellRenderer(params: any) {
    var inrFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    });
    return params.value ? inrFormat.format(params.value) : 'N/A';
}

export function dodCellRenderer(params: ProcessCellForExportParams): string | null {
    return params?.node?.data?.dod || null;
}

export function compareStringAndNumber(
    a: number,
    b: number,
    nodeA,
    nodeB,
    isInverted
): 0 | 1 | -1 {
    if (a == b) {
        return 0;
    }
    if (null == a || isNaN(a)) return isInverted ? -1 : 1;
    if (null == b || isNaN(b)) return isInverted ? 1 : -1;
    return a < b ? -1 : 1;
}

export function convertDateAndCompare(newDate: Date, oldDate: string): 0 | 1 | -1 {
    if (!oldDate || !newDate) {
      return 0;
    }

    const formattedNewDate = moment.utc(newDate).format('YYYY/MM/DD');
    const formattedOldDate = moment.utc(oldDate).format('YYYY/MM/DD');

    if (formattedOldDate < formattedNewDate) {
      return -1;
    } else if (formattedOldDate > formattedNewDate) {
      return 1;
    } else {
      return 0;
    }
  }

export function compareActions(action1, action2) {
  const sum1 =  action1.alertsCount || 0;
  const sum2 =  action2.alertsCount || 0;

  if (sum1 < sum2) {
    return -1;
  } else if (sum1 > sum2) {
    return 1;
  } else {
    return 0;
  }
}

