import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { DocRepo } from '@shared/models/docRepo/list';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'coach-doc-repo-directory',
  templateUrl: './doc-repo-directory.component.html',
  styleUrls: ['./doc-repo-directory.component.scss']
})
export class DocRepoDirectoryComponent extends KillSubscriptions implements OnChanges, OnInit {

  @Input() public docRepoData: DocRepo[];
  @Input() public showNavBar: any;
  public isQueueDrilledIn = false;
  public fileParam: number;
  public folderParam: number;
  public fileClicked = false;
  public folder: DocRepo;
  public fileData: any = '';
  public error: Error;
  public viewerControl: any;

  @Output() public innerFileClickedEmitter: EventEmitter<any> = new EventEmitter(true);
  @Output() public queueDrillInEmitter: EventEmitter<any> = new EventEmitter(true);
  @Output() public selectedFolderEmitter: EventEmitter<any> = new EventEmitter(true);

  private queryParamSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private fileDownloadService: FileDownloadService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.queryParamSubscription = this.route.queryParams?.pipe(takeUntil(this.killTrigger))
    .subscribe(params => {
      const { folder, file } = params;
      this.folderParam = folder;
      this.fileParam = file;
    });
  }

  get selectedFolder(): DocRepo {
    return this.folder;
  }
  get selectedFolderName(): string {
    return this.folder ? this.folder.Title : null;
  }
  get selectedFoldersFiles(): DocRepo[] {
    return this.folder ? this.folder.Children : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.docRepoData?.currentValue) {
      // order of both sorts matters here.
      this.sortAlphabetically();
      this.sortFoldersTop();
    }
  }

  private sortAlphabetically() {
    this.docRepoData.sort((a,b) => {
      if (a.Title > b.Title) {
        return 1;
      }
      if (a.Title < b.Title) {
        return -1;
      }
      return 0;
    });
  }

  private sortFoldersTop() {
    this.docRepoData.sort((a, b) => {
      if(a.EntryType === 'Folder' && b.EntryType !== 'Folder' ) {
        return -1;
      }
      if (a.EntryType !== 'Folder' && b.EntryType === 'Folder') {
        return 1;
      }
      return 0;
    });
  }


  public toggleQueueDrillIn(item: DocRepo): void {
    if (!item) {
      this.fileClicked = false;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {}
      });
    } else {
      if (item.EntryType == 'Folder') {
        this.folder = item;
        this.fileDownloadService.updateSelectedFolder(item); // maybe remove
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { folder: item.EntryId }
        });
        this.selectedFolderEmitter.emit(item);
      } else {
        this.fileSelected(item);
      }
    }
  }

  public fileSelected(file: DocRepo): void {
    this.innerFileClickedEmitter.emit(file);
    this.fileData = file;
  }
}
