import { NgModule } from '@angular/core';

import { CountToArrayPipe } from './count-to-array.pipe';
import { ClassesJoinTrimPipe } from './classes-join-trim.pipe';
import { GetInitialsFromNamePipe } from './get-initials-from-name.pipe';
import { GetAgeFromDOB } from './age.pipe';
import { RemoveStringWhitespacePipe } from './remove-string-whitespace.pipe';
import { SpacesToDashesPipe } from './spaces-to-dashes.pipe';
import { MbiFormatPipe } from './format-patient-mbi.pipe';
import { MaskPipe } from './mask.pipe';
import { MinuteSecondsPipe } from './minute-seconds.pipe';
import { MonthNumberPipe } from './month-number.pipe';
import { DXCodeFormatPipe } from './dx-code-format.pipe';


@NgModule({
  declarations:[
    CountToArrayPipe,
    ClassesJoinTrimPipe,
    GetInitialsFromNamePipe,
    GetAgeFromDOB,
    RemoveStringWhitespacePipe,
    MbiFormatPipe,
    SpacesToDashesPipe,
    MaskPipe,
    MinuteSecondsPipe,
    MonthNumberPipe,
    DXCodeFormatPipe
  ],
  imports:[],
  exports:[
    CountToArrayPipe,
    ClassesJoinTrimPipe,
    GetInitialsFromNamePipe,
    GetAgeFromDOB,
    RemoveStringWhitespacePipe,
    MbiFormatPipe,
    SpacesToDashesPipe,
    MaskPipe,
    MinuteSecondsPipe,
    MonthNumberPipe,
    DXCodeFormatPipe
  ],
  providers: [
    ClassesJoinTrimPipe,
    GetInitialsFromNamePipe,
    GetAgeFromDOB,
    RemoveStringWhitespacePipe,
    MbiFormatPipe,
    SpacesToDashesPipe,
    MaskPipe,
    MinuteSecondsPipe,
    MonthNumberPipe
  ]
})

export class PipesModule { }
