import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  BE_SERVER_URL = environment.coachApiUrl;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  // Asks the application server to create a viewing session for a particular
  // document. In this sample, documents are identified by a filename. In a real
  // application, you might use something else, such as a database identifier,
  // to identify your documents. The application server will then make a request
  // to the PrizmDoc Viewer backend to create a new viewing session for the
  // specified document, returning a new viewingSessionId. This viewingSessionId
  // is what your Angular app needs in order to instantiate the viewer.
  createViewingSessionForDocument(
    fileTitle: string,
    fileURL: string,
    fileExt: string
  ): Observable<string> {
    console.log("*** createViewingSessionForDocument");
    // console.log(new Error().stack);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.authService.IdToken}`,
    });
    const encoded = encodeURIComponent(fileURL);
    return this.httpClient
      .get(
        `${this.BE_SERVER_URL}/api/docRepo/server/beginViewing?documentURL=${encoded}&documentTitle=${fileTitle}&fileExt=${fileExt}`,
        { responseType: 'json', headers: httpHeaders }
      )
      .pipe(pluck('viewingSessionId'));
  }
}
