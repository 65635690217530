<ng-container [formGroup]="parentForm">
  <div [formGroupName]="questionInfo.name" class="u-background-color--white" [ngClass]="{'u-opacity--lightest': isDisabled}">
    <div class="submitFormBlock-header">
      <div class="row">
        <h6 class="margin--none u-fill u-opacity--light">
          {{questionInfo.measureId}} | Max: {{maxPoints}} Points
        </h6>
        <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small" iconColor="primary"
          (click)="toggleInfoText()"></coach-icon>
      </div>
      <div class="u-bold margin-top--smallest">{{questionInfo.description}}</div>
      <div class="margin-top" *ngIf="questionInfo.allowExclusion">
        <input [attr.disabled]="isDisabled ? true : null" type="checkbox" formControlName="exclusion"
          (change)="exclusionChanged()" id="pi-exclusion-{{questionInfo.measureId}}">
        <label for="pi-exclusion-{{questionInfo.measureId}}">Request Exclusion</label>
      </div>
    </div>
    <div class="submitFormBlock-main row" formArrayName="FormQResponses"
      *ngIf="!parentForm.controls[questionInfo.name].get('exclusion').value">
      <div class="submitFormBlock-inputs">

        <ng-container [formGroupName]="1">
          <input [readonly]="isDisabled" formControlName="ResponsePointValue" type="number"
            (change)="numeratorChanged()" placeholder="Num" min="0"
            [max]="parentForm.controls[questionInfo.name].get('FormQResponses.2.ResponsePointValue').value">
        </ng-container>

        <div class="divider"></div>

        <ng-container [formGroupName]="2">
          <input [readonly]="isDisabled" formControlName="ResponsePointValue" type="number"
            (change)="denominatorChanged()" placeholder="Den"
            [min]="parentForm.controls[questionInfo.name].get('FormQResponses.1.ResponsePointValue').value">
        </ng-container>

        <ng-container [formGroupName]="0">
          <input [readonly]="isDisabled" type="number" formControlName="ResponsePointValue" placeholder="Pts" min="0"
            hidden>
        </ng-container>
      </div>
      <div class="submitFormBlock-graphic">
        <div class="row--baseline u-justify-content--center">
          <h2 class="margin--none">
            {{parentForm.controls[questionInfo.name].get('FormQResponses.0.ResponsePointValue').value
            || 0}}
          </h2>
          <div class="u-font-size--large u-font-weight--light">pts</div>
        </div>
        <div class="barGraph margin-top" attr.data-percentage="{{percentRounded}}"
          style="--bar-graph--percentage: {{percentRounded}}%">
          <div class="bar"></div>
        </div>
        <div class="submitFormBlock-graphic-labels">
          <div class="submitFormBlock-graphic-labels-percentage">{{percentage | percent}}</div>
        </div>
      </div>
    </div>

    <div class="submitFormBlock-alerts u-italic u-color--danger padding-left--smaller paddings-top--small"
    *ngIf="errors && errors?.length">
    <div *ngFor="let error of errors">{{ error }}</div>
  </div>

  </div>
</ng-container>

<div *ngIf="showExclusionModal" class="overlay overlay--light is-open">
  <div class="modal">
    <div class="header">
      <div class="titles">
        <div class="title">Proceed With Exemption for {{questionInfo.description}}?</div>
      </div>
    </div>
    <div class="dynamicBody padding">
      {{questionInfo.exclusionText}}
    </div>
    <div class="footer">
      <button class="button margin-right border--none" (click)="cancelExclusion()">No</button>
      <button class="button is-primary" (click)="confirmExclusion()">Yes</button>
    </div>
  </div>
</div>
