<div class="page-content-main u-height--full" >
  <div class="banner">
    <span>Submit Period &nbsp;</span>
    <select class="field u-color--interaction u-width--larger" name="submissionPeriod" id="subPeriod"  [(ngModel)]="selectedFormSubPeriod" (change)="selectSubmissionPeriod($event)">
      <option *ngFor="let period of caravanFormAdminSubmitPeriod" [value]="period.caravanFormSubmitPeriodID">
        {{ period.openingInfo }}
      </option>
    </select>
  </div>

<ng-container *ngIf="!isACOHidden">
  <div class="banner">
    <button class="button-current is-small banner" (click)="refreshOverview()">ACO List</button>
  </div>
  <coach-admin-form-report [CaravanFormAdminData]="formsSubmissionsOverviewACO"
    [columnNamesLevelOne]="columnNamesACO"
    [tierID]="TierID"
    [reportName]="reportName"
    (resultClickedEvent)="resultClickedACO($event)"
    [formID]="formID"
    (resultResponseReport)="onBtnExportResponses()"
    style="height: 95%; display: flex; flex-direction: column;">
  </coach-admin-form-report>
</ng-container>


<ng-container *ngIf="!isICCHidden">
  <div class="banner">
    <button class="button-return is-small banner" (click)="returnToOverview()">ACO List</button>
    <button class="button-current is-small banner" (click)="refreshCommunity()">Community List</button>
  </div>
  <coach-admin-form-report [CaravanFormAdminData]="formsSubmissionsOverviewComm"
    [columnNamesLevelOne]="columnNamesComm"
    [tierID]="TierID"
    [reportName]="reportName"
    (resultClickedEvent)="resultClickedICC($event)"
    [formID]="formID"
    (resultResponseReport)="onBtnExportResponses()"
    class="ag-grid-displayed">
  </coach-admin-form-report>
</ng-container>

<ng-container *ngIf="!isPracticeHidden">
  <div class="banner">
    <button class="button-return is-small banner" (click)="returnToOverview()">ACO List</button>
    <button class="button-return is-small banner" (click)="returnToCommunity()">Community List</button>
    <button class="button-current is-small banner" (click)="refreshPractice()">Principal Participant List</button>

  </div>
  <coach-admin-form-report [CaravanFormAdminData]="formSubmissionsPracticeResps"
    [columnNamesLevelOne]="practiceCellRendererParams"
    [tierID]="TierID"
    [reportName]="reportName"
    (resultClickedEvent)="resultClickedPrincipal($event)"
    [formID]="formID"
    (resultResponseReport)="onBtnExportResponses()"
    class="ag-grid-displayed">
  </coach-admin-form-report>
</ng-container>

<ng-container *ngIf="!isResponseHidden">
  <div class="banner">
    <button class="button-return is-small banner" (click)="returnToOverview()">ACO List</button>
    <button class="button-return is-small banner" (click)="returnToCommunity()">Community List</button>
    <button class="button-return is-small banner" (click)="returnToPrincipal()">Principal Participant List</button>
    <button class="button-current is-small banner" (click)="refreshPractice()">Form Response</button>
  </div>
  <coach-admin-form-report-practice [CaravanFormAdminResponseData]="formSubmissionResponse"
      [columnNamesLevelOne]="formQuestionResponses"
      [columnNamesLevelTwo]="formQuestionResponses"
      [tierID]="TierID"
      [reportName]="reportName"
      [formID]="formID"
      (resultResponseReport)="onBtnExportResponses()"
      class="ag-grid-displayed">
    </coach-admin-form-report-practice>
</ng-container>
</div>










