import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'coach-track-clinical',
  templateUrl: './track-clinical.component.html'
})
export class TrackClinicalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
