import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@shared/models/constants/app-constants';

@Component({
  selector: 'coach-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  app = AppConstants;
  
  constructor() { }

  ngOnInit(): void {
  }

}
