import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SubmitFeatureConstants,
  SubmitFeatureNames,
  SubmitFormConstants,
} from '@shared/models/module-constants';
import { FormConstants } from '@shared/models/forms/form-constants';
import { SubmitFormsService } from '@shared/services/forms/submit-forms.service';

import { CaravanFormSubmissionPeriodOverview  } from '@shared/models/forms/form-submissionID-model';
import moment from 'moment';
import { AppConstants } from '@shared/models/constants/app-constants';

const {
  SummaryFormFeature,
  QualityFormFeature,
  ComplianceFormFeature,
  PromotingInteroperabilityFormFeature,
  MeetingFormFeature,
} = SubmitFeatureConstants;

@Component({
  selector: 'coach-submit-summary',
  templateUrl: './submit-summary.component.html',
})
export class SubmitSummaryComponent implements OnInit {
  summaryForm = SummaryFormFeature;
  qualityForm = QualityFormFeature;
  complianceForm = ComplianceFormFeature;
  promotingInteroperabilityForm = PromotingInteroperabilityFormFeature;
  meetingForm = MeetingFormFeature;
  submitMeetingName = SubmitFeatureNames.Meeting;

  qualityFeature = SubmitFeatureConstants.QualityFormFeature;
  quailityName = SubmitFeatureNames.Quality;
  qualityOpenInfo = FormConstants.QualityOpeningInfo;
  qualityOpenDueDate = FormConstants.QualityOpenDueDate;
  qualityrequirements = FormConstants.Quality.requirements;
  qualityFormId = SubmitFormConstants.QualityFormComponent;
  qualityFormOpen: boolean = false;

  complianceFeature = SubmitFeatureConstants.ComplianceFormFeature;
  complianceName = SubmitFeatureNames.Compliance;
  complianceOpenInfo = FormConstants.ComplianceOpeningInfo;
  complianceOpenDueDate = FormConstants.ComplianceOpenDueDate;
  complianceFormId = SubmitFormConstants.ComplianceFormComponent;
  complianceFormOpen: boolean = false;

  PIFeature = SubmitFeatureConstants.PromotingInteroperabilityFormFeature;
  PIName = SubmitFeatureNames.PI;
  piOpenInfo = FormConstants.PIOpeningInfo;
  piOpenDueDate = FormConstants.PIOpenDueDate;
  piFormId = SubmitFormConstants.PromotingInteroperabilityFormComponent;
  piFormOpen: boolean = false;

  meetingFeature = SubmitFeatureConstants.MeetingFormFeature;
  meetingName = SubmitFeatureNames.Meeting;
  meetingWidgetName = FormConstants.MeetingWidgetName;
  meetingOpenInfo = FormConstants.MeetingOpeningInfo;
  meetingOpenDueDate = FormConstants.MeetingOpenDueDate;
  currentDate: string;

  app = AppConstants;

  public caravanFormSubmissionOverview: CaravanFormSubmissionPeriodOverview[];

  constructor(
    private hierarchyTierService: HierarchyTierService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formsService: SubmitFormsService,
  ) {}

  ngOnInit(): void {
    this.formsService.getSubmitPeriodsOverview(0).subscribe((data) => {
      this.caravanFormSubmissionOverview = data;
      this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    });
  }

  recieveFormSubmissionOverviewData($event): void {
    this.caravanFormSubmissionOverview = $event;
    this.checkFormOpenStatus();
  }

  checkFormOpenStatus(): void {
    const todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    for (let i = 0;  i < this.caravanFormSubmissionOverview.length; i++) {
      let openDate = this.caravanFormSubmissionOverview[i].openDate;
      if (moment(openDate).isBefore(todayDate)){
        if (this.caravanFormSubmissionOverview[i].formID == this.piFormId) {
          this.piFormOpen = true;
        }
        if (this.caravanFormSubmissionOverview[i].formID == this.complianceFormId) {
          this.complianceFormOpen = true;
        }
        if (this.caravanFormSubmissionOverview[i].formID == this.qualityFormId) {
          this.qualityFormOpen = true;
        }
      }
    }
  }

  routeToTab(tab: string): void {
    const path =
      this.hierarchyTierService.currentTier$.value.abbreviation +
      '/' +
      this.hierarchyTierService.currentTier$.value.selectedTierId +
      '/submit/' +
      tab;
    this.router.navigateByUrl(path);
  }

  public goToItems(e, path: string, query?: object | null): void {
    e.preventDefault();
    this.router.navigate(['..' + path], {
      queryParams: query,
      relativeTo: this.activatedRoute.parent,
    });
  }
}
