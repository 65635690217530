import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'initialsFromName'
})
export class GetInitialsFromNamePipe implements PipeTransform {
  transform(name: string): string {
    if (name && name.length) {
      if(name.includes(" ")) {
        return name.split("")[0] + name.split(" ").pop().split("")[0];
      } else {
        return name.split("")[0];
      }
    }else {
      return null;
    }
  }
}
