import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { IRecentMatcher } from './recent-matcher';
export class TimeTrackingMatcher implements IRecentMatcher {
  match(url: string): boolean {
    if (url.includes('track/clinical/trackTimeTracking')) {
      return true;
    } else {
      return false;
    }
  }

  createLog(url: string): RecentLogData {
    return {
      url,
      elements: [],
    };
  }

  title(url: string): string {
    return 'Time Tracking';
  }
}
