import { Component, Input, OnInit } from '@angular/core';
import { HccDashboardDiseaseGroupSummary } from '@api/track/models';
import { GridFilterService, PATIENT_DETAIL_REPORT_GRID_KEY } from '@shared/components/grid/grid-filter.service';

@Component({
  selector: 'coach-hcc-top-disease-groups',
  templateUrl: './hcc-top-disease-groups.component.html',
  styleUrls: ['./hcc-top-disease-groups.component.scss']
})
export class HccTopDiseaseGroupsComponent implements OnInit {

  @Input()
  data: HccDashboardDiseaseGroupSummary[];

  @Input()
  canAccessPhi: boolean;

  constructor(private gridFilterService: GridFilterService) { }

  ngOnInit(): void {
  }

  navigateToPatientDetails() {
    this.gridFilterService.resetFilters(PATIENT_DETAIL_REPORT_GRID_KEY);
  }

}
