import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { Module } from './module.service';
import { modules } from './fragments';

export interface AccessData {
  access: Access;
}

export interface Access {
  tierNum: number;
  tierId: string;
  reporting: boolean | null;
  phi: boolean | null;
  isInternal: boolean | null;
  modules: Module[];
}

@Injectable({
  providedIn: 'root'
})
export class AccessGQL extends Query<AccessData> {

  document = gql`
  query access($nodeId: String!) {
    access(nodeId: $nodeId) {
      tierNum
      tierId
      phi
      isInternal
      reporting
      ...modules
    }
  }
  ${modules}
`;
}
