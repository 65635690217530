import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, UrlTree } from '@angular/router';
import { IView, Views } from '@shared/models/views';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRouteService } from '@shared/services/activated-route/activated-route.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveViewService {
  public allViews: IView[];
  public activeView$: BehaviorSubject<IView>;

  constructor(private _activatedRoute: ActivatedRouteService) {
    this.allViews = Views;
    this.activeView$ = new BehaviorSubject(this.allViews[0]);

    this._activatedRoute.currentUrl$.subscribe((url: UrlTree): void => {
      if (url) {
        const viewPath = url.root.children[PRIMARY_OUTLET]?.segments[2]?.path;
        if (viewPath) {
          this.activeView$.next(this.allViews.find(view => view.path === viewPath));
        }
      }
    });
  }
}
