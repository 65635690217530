
<h6 class="margin-topBottom--none padding-left">Upload Files</h6>
<div class="uploadFile margin--small"
  ng2FileDrop
  [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
  (fileOver)="fileOverBase($event)"
  [uploader]="uploader">
  <ng-container *ngIf="uploader?.queue?.length == 0 ">
    <div class="column u-center">
      <div>Drag/Drop files here</div>
      <div class="u-italic u-opacity--lighter margin-bottom--smaller">
        or
      </div>
      <input type="file" multiple name='files'
      ng2FileSelect [uploader]="uploader" />
    </div>
  </ng-container>
</div>


<div class="overlay is-open overlay--light" *ngIf="uploader?.queue?.length != 0 ">
  <div class="modal u-width--large u-max-height--largest">
    <div class="header">
      <div class="titles">
        <div class="title">Upload to... </div>
        <div class="subtitle">
          {{uploadDestination ? uploadDestination.Title : 'Select a folder'}}
        </div>
      </div>
      <button class="button is-icon border--none" (click)="cancelUpload()">
        <coach-icon iconName="x" iconColor="default"></coach-icon>
      </button>
    </div>
    <div class="dynamicBody u-position--relative">
      <div class="list">
          <div *ngFor="let item of uploadDestinations"
            class="list-item"
            [ngClass]="{'is-active': uploadDestination == item}">
            <div class="row" >
              <div class="field u-text-wrap">{{item.Title}}</div>
              <div *ngIf="item.Children">
                <div class="field u-opacity--lighter u-preserve u-italic">
                  ({{item.Children.length}})
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <ng-container *ngIf="uploader?.queue?.length == 1 ">
      <div *ngFor="let item of uploader.queue"
          class="u-background-color--black--4 border-top border-bottom padding padding-bottom--small">
        <div class="uploadModal-file">
          <div class="row--wrap u-font-size--small">
            <div class="u-fill u-bold">{{ item?.file?.name }}</div>
            <div class="u-opacity--light">{{ item?.file?.size/1024/1024 | number:'.2' }} MB</div>
          </div>
        </div>
        <div class="phiCheckbox row--verticalCenter">
          <input id="phi-{{item?.file?.name}}"
                (change)="onChange($event.target.checked)"
                type="checkbox">
          <label for="phi-{{item?.file?.name}}" class="u-fill">This file contains PHI</label>
          <coach-icon class="phiIndicator u-color--danger" iconName="alert"
              iconColor="currentColor" iconSize="small"></coach-icon>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="uploader?.queue?.length > 1 ">
      <div class="uploadModal-fileList column u-max-height--small border-top border--bottom">
        <div class="subheader row">
          <div class="u-fill">File</div>
          <div class="u-color--danger">Contains PHI</div>
        </div>
        <div class="dynamicBody u-background-color--black--4">
          <div class="list list--static">
            <div *ngFor="let item of uploader.queue" class="list-item padding--smaller">
              <div class="row--verticalCenter">
                <button class="button is-small border--none is-icon" (click)="item.remove()">
                  <coach-icon iconName="x" iconColor="interaction" iconSize="small">
                  </coach-icon>
                </button>
                <div class="u-fill padding-left--small">
                  {{ item?.file?.name }}
                </div>
                <div class="phiCheckbox">
                  <input id="{{item?.file?.name}}"
                        (change)="onChange($event.target.checked)"
                        type="checkbox"/>
                  <label for="{{item?.file?.name}}"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="footer">
      <button class="button margin-right border--none" (click)="cancelUpload()">
        Cancel
      </button>
      <button class="button is-primary" (click)="uploader.uploadAll()"
              [attr.disabled]="uploadDestination ? null : ''">
        Upload
      </button>
    </div>
  </div>
</div>
