import { Injectable } from '@angular/core';
import { GridFilterModel } from './grid-filter-model';

export const PATIENT_SUMMARY_GRID_KEY = 'patientSummaryReport';
export const CLINICIANS_REPORT_GRID_KEY = 'cliniciansReport';
export const PATIENT_DETAIL_REPORT_GRID_KEY = 'patientDetailReport';
export const FACESHEET_SCHEDULE_GRID_KEY = 'facesheetSchedule';
export const DISEASE_COHORT_GRID_KEY = 'carePatients';
export const ED_UTILIZATION_GRID_KEY = 'edUtilizationPatients';
export const PRACTICES_REPORT_GRID_KEY = 'practicesReport';
export const CINS_REPORT_GRID_KEY = 'cinsReport';

@Injectable({
  providedIn: 'root',
})
export class GridFilterService {
  filterModels: GridFilterModel[] = [];

  resetFilters(gridKey: string = null): void {
    if (gridKey != null) {
      const model = this.filterModels.find((x) => x.key === gridKey);
      if (model) {
        model.filterModel = null;
      }
    } else {
      this.filterModels = [];
    }
  }

  constructor() {}
}
