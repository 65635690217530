<div class="eocList " *ngIf="vm && vm.episodesOfCare">
  <div class="widget margin--large u-height--fit-content" [ngClass]="{'u-align-self--stretch': vm.episodesOfCare.length == 1}" *ngFor="let eoc of vm.episodesOfCare; index as eoc_idx">
    <div class="header">
      <div class="titles">
        <div class="title">{{eoc.name}}</div>
      </div>
      <div class="field row--baseline">
        <button class="button is-borderless is-smaller u-height--webkit-fill-available" (click)="expandCollapseAll(true, eoc_idx)">Expand All</button>
       | 
        <button class="button is-borderless is-smaller u-height--webkit-fill-available" (click)="expandCollapseAll(false, eoc_idx)">Collapse All</button>
      </div>
    </div>
    <div class="dynamicBody padding--small">
      <!-- Single protocol - hide protocol title -->
      <div *ngIf="eoc.protocols.length === 1">
        <div class="accordion" [class.is-open]="eoc.protocols[0].isOpen" *ngIf="eoc.protocols[0].tasks && eoc.protocols[0].tasks.length > 0">
          <div class="header">
            <div class="row" style="padding-left: 42px">
              <label>Base Tasks ({{ eoc.protocols[0].activeTaskCount }} of {{ eoc.protocols[0].tasks.length }} active)</label>
            </div>
            <!-- accordion button is always last (see _accordion.scss) -->
            <button class="button is-small margin-right--small" (click)="eoc.protocols[0].isOpen = !eoc.protocols[0].isOpen"></button>
          </div>
          <div class="content row cadence-wrapper">
            <div class="swimlane cadence-header header">Once</div>
            <div class="swimlane cadence-header header">Daily</div>
            <div class="swimlane cadence-header header">Weekly</div>
            <div class="swimlane cadence-header header">Monthly</div>
          </div>
          <div class="content content-planRow row swimlane-wrapper" style="height: auto;" *ngFor="let tasks of [eoc.protocols[0].tasks]">
            <div class="swimlane">
              <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(tasks,'once')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
            </div>
            <div class="swimlane">
              <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(tasks,'daily')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
            </div>
            <div class="swimlane">
              <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(tasks,'weekly')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
            </div>
            <div class="swimlane">
              <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(tasks,'monthly')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
            </div>
          </div>
        </div>
        <div *ngFor="let m of eoc.protocols[0].modules">
          <!-- component coach-patient-careplan-module -->
          <div class="module accordion" [class.is-open]="m.isOpen">
            <div class="header">
              <div class="row">
                <button class="button is-icon is-primary is-small margin-right--small" (click)="moduleCheckClicked(m)">
                  <svg class="icon" *ngIf="m.state === 'active'"><use href="assets/icons/Magicons.svg#icon-check"></use></svg>
                </button>
                <label>{{ m.protocol?.name }}  
                  <span class="margin-left--small" *ngIf="m.assignedTasks && m.assignedTasks.length > 0">
                    ({{ m.activeTaskCount }} of {{ m.assignedTasks.length }} tasks active)
                  </span>
                </label>
              </div>
              <button class="button is-small margin-right--small" (click)="m.isOpen = !m.isOpen"></button>
            </div>
            <div class="content row" *ngIf="m.assignedTasks && m.assignedTasks.length > 0">
              <div class="task-header" >Assigned tasks</div>
            </div>
            <div class="content row cadence-wrapper" *ngIf="m.assignedTasks && m.assignedTasks.length > 0">
              <div class="swimlane header cadence-header">Once</div>
              <div class="swimlane header cadence-header">Daily</div>
              <div class="swimlane header cadence-header">Weekly</div>
              <div class="swimlane header cadence-header">Monthly</div>
            </div>
            <div class="content row swimlane-wrapper" *ngIf="m.assignedTasks && m.assignedTasks.length > 0">
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.assignedTasks, null)" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.assignedTasks, 'once')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.assignedTasks, 'daily')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.assignedTasks, 'weekly')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.assignedTasks, 'monthly')" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
            </div>

            <div class="content row" *ngIf="!m.assignedTasks || m.assignedTasks.length === 0">
              <div class="task-header" >Template tasks (module not assigned)</div>
            </div>
            <div class="content row cadence-wrapper" *ngIf="!m.assignedTasks || m.assignedTasks.length === 0">
              <div class="swimlane header cadence-header">Once</div>
              <div class="swimlane header cadence-header">Daily</div>
              <div class="swimlane header cadence-header">Weekly</div>
              <div class="swimlane header cadence-header">Monthly</div>
            </div>
            <div class="content row swimlane-wrapper" *ngIf="!m.assignedTasks || m.assignedTasks.length === 0">
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.templateTasks, null)" [templateTask]="t"></coach-careplan-patient-task>
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.templateTasks, 'once')" [templateTask]="t"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.templateTasks, 'daily')" [templateTask]="t"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.templateTasks, 'weekly')" [templateTask]="t"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(m.templateTasks, 'monthly')" [templateTask]="t"></coach-careplan-patient-task>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="vm?.moduleActivateDeactivateInfo" class="module-activate-dialog">
  <div *ngFor="let x of [vm.moduleActivateDeactivateInfo]" class="overlay is-open">
    <div class="modal">
      <div class="header">
        <div class="titles">
          <div class="title">{{ x.operation }} {{ x.module.protocol.name }}</div>
        </div>
      </div>
      <div class="dynamicBody ">
        <div class="list border u-width--larger" *ngIf="x.tasksToAssign.length > 0">
          <div class="subheader">These tasks will be assigned to the patient</div>
          <div class="list-item" *ngFor="let t of x.tasksToAssign">
            <div class="row">
              <div class="field">{{t.name}}</div>
              <div class="field">{{getTaskTypeText(t.type)}}</div>
            </div>
          </div>
        </div>        
        <div class="list border u-width--larger" *ngIf="x.tasksToActivate.length > 0">
          <div class="subheader">These tasks will be activated</div>
          <div class="list-item" *ngFor="let t of x.tasksToActivate">
            <div class="row">
              <div class="field">{{t.name}}</div>
              <div class="field">{{getTaskTypeText(t.type)}}</div>
            </div>
          </div>
        </div>        
        <div class="list border u-width--larger" *ngIf="x.tasksNotToBeDeactivated.length > 0">
          <div class="subheader">These tasks are already active in another module and will be left unchanged</div>
          <div class="list-item" *ngFor="let t of x.tasksNotToBeDeactivated">
            <div class="row">
              <div class="field">{{t.name}}</div>
              <div class="field">{{getTaskTypeText(t.type)}}</div>
            </div>
          </div>
        </div>        
        <div class="list border u-width--larger" *ngIf="x.tasksToBeDeactivated.length > 0">
          <div class="subheader">{{x.tasksToBeDeactivated.length}} tasks will be deactivated</div>
          <div class="list-item" *ngFor="let t of x.tasksToBeDeactivated">
            <div class="row">
              <div class="field">{{t.name}}</div>
              <div class="field">{{getTaskTypeText(t.type)}}</div>
            </div>
          </div>
        </div>        
      </div>
      <div class="footer">
        <button class="button margin-right border--none" (click)="vm.moduleActivateDeactivateInfo = null">Cancel</button>
        <button class="button is-primary" (click)="activateOrDeactivateModule(vm.moduleActivateDeactivateInfo)">{{ x.operation }}</button>
      </div>
    </div>
  </div>
</ng-container>