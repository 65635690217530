<care-all-prescriptions-filters 
    (listSelected)="listSelected($event)" 
    [gridApi]="gridApi" 
    [filterOptions]="filterOptions">
</care-all-prescriptions-filters>
<ng-container *ngIf="selectedList">
    <coach-care-exceptions *ngIf="selectedList.name == 'Pre-validation Exceptions'" 
        (gridApiEmitter)="gridApiChanged($event)"
        (filterOptionsEmitter)="filterOptionsChanged($event)">
    </coach-care-exceptions>
    <coach-care-prescriptions *ngIf="selectedList.name == 'Validate 340B'" 
        (gridApiEmitter)="gridApiChanged($event)"
        (filterOptionsEmitter)="filterOptionsChanged($event)">
    </coach-care-prescriptions>
    <coach-care-prescriptions-processing *ngIf="selectedList.name == 'Process 340B'"
        (gridApiEmitter)="gridApiChanged($event)" 
        (filterOptionsEmitter)="filterOptionsChanged($event)">
    </coach-care-prescriptions-processing>
    <coach-care-prescriptions-invoicing *ngIf="selectedList.name == 'Invoice 340B'"
        (gridApiEmitter)="gridApiChanged($event)" 
        (filterOptionsEmitter)="filterOptionsChanged($event)">
    </coach-care-prescriptions-invoicing>
</ng-container>