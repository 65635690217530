<div>
  <ul
    style="
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      color: rgb(123, 123, 123);
      margin-right: 8px;
      margin-top: 8px;
      cursor: pointer;
    "
  >
    <li
      (click)="onSummaryTabSelected()"
      [ngClass]="{ selected: isSummaryTabSelected }"
    >
      Summary
    </li>
    <li
      (click)="onQuickFillTabSelected()"
      [ngClass]="{ selected: isQuickFillTabSelected }"
    >
      Quick Fill
    </li>
    <li
      (click)="onCareNoteSelected()"
      [ngClass]="{ selected: isCareNoteSelected }"
    >
      Care Coordination Note
    </li>
  </ul>
</div>
