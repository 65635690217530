import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { AzureApiBase } from '../azure-api/azure-api-base';
import { TrackPerformanceWidgetData } from './track-performance-widget-data';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrackPerformanceWidgetApiService extends AzureApiBase<TrackPerformanceWidgetData> {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService, environment.trackApiUrl, '/performanceDashboard');
  }

  private cache$ = new Map<string, Observable<TrackPerformanceWidgetData[]>>();

  getWidgetData(tierNum: number, tierId: string): Observable<TrackPerformanceWidgetData[]> {
    const params: HttpParams = new HttpParams()
      .set('tierNum', tierNum?.toString())
      .set('tierId', tierId);

    const key = `${tierNum}-${tierId}`;
    if (!this.cache$.get(key)) {
      this.cache$.set(key, this.getAll('/summary', params).pipe(shareReplay(1)));
    }

    return this.cache$.get(key);
  }
}
