<div class="panel u-fill u-overflow-x--hidden border-right border-top" [ngClass]="(isHelpPanelOpen$ | async) ? 'is-open' : 'is-closed'" (clickAway)="toggleOpen()" [clickAwayDisabled]="getIsDisabled()">
  <div class="header row--verticalCenter">
    <div class="titles">
      <div class="title">Help Center</div>
    </div>
    <button class="button is-icon border--none" (click)="togglePinned(); $event.stopPropagation();">
      <coach-icon [iconName]="(isHelpPanelPinned$ | async) ? 'pin--filled' : 'pin'"></coach-icon>
    </button>
  </div>
  <div class="dynamicBody padding">
  <div markdown #postDiv [data]="helpfile"></div>
  </div>
  <div class="footer">
    <div class="column u-fill">
      <div class="u-italic margin-bottom--small u-opacity--lighter">Can't find what you're looking for?</div>
      <div class="row">
        <a class="button is-small row--verticalCenter" href="mailto:{{ supportEmail }}">
          <coach-icon iconName="mail" iconColor="currentColor" class="margin-right--smaller"></coach-icon>
          <div>Get Help</div>
        </a>
      </div>
    </div>
  </div>
</div>
