/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmbeddedReport } from '../models/embedded-report';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reportGet
   */
  static readonly ReportGetPath = '/Report';

  /**
   * Retrieves the access token required to access the Power BI Pro account data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportGet$Response(params?: {
    ReportTierNum?: number;
    ReportTierID?: string;
    AttributionQuarter?: number;
    AttributionYear?: number;
    PHIFlag?: boolean;
    MenuItemId?: number;
    ReportId?: string;
    RegionId?: number;
    CinId?: number;
    CinName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EmbeddedReport>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportGetPath, 'get');
    if (params) {
      rb.query('ReportTierNum', params.ReportTierNum, {});
      rb.query('ReportTierID', params.ReportTierID, {});
      rb.query('AttributionQuarter', params.AttributionQuarter, {});
      rb.query('AttributionYear', params.AttributionYear, {});
      rb.query('PHIFlag', params.PHIFlag, {});
      rb.query('MenuItemId', params.MenuItemId, {});
      rb.query('ReportId', params.ReportId, {});
      rb.query('RegionId', params.RegionId, {});
      rb.query('CinId', params.CinId, {});
      rb.query('CinName', params.CinName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmbeddedReport>;
      })
    );
  }

  /**
   * Retrieves the access token required to access the Power BI Pro account data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportGet(params?: {
    ReportTierNum?: number;
    ReportTierID?: string;
    AttributionQuarter?: number;
    AttributionYear?: number;
    PHIFlag?: boolean;
    MenuItemId?: number;
    ReportId?: string;
    RegionId?: number;
    CinId?: number;
    CinName?: string;
  },
  context?: HttpContext

): Observable<EmbeddedReport> {

    return this.reportGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<EmbeddedReport>) => r.body as EmbeddedReport)
    );
  }

  /**
   * Path part for operation reportDashboardGet
   */
  static readonly ReportDashboardGetPath = '/Report/dashboard';

  /**
   * Retrieves the access token required to access the specified Power BI dashboard report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportDashboardGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportDashboardGet$Response(params?: {
    reportId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportDashboardGetPath, 'get');
    if (params) {
      rb.query('reportId', params.reportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Retrieves the access token required to access the specified Power BI dashboard report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportDashboardGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportDashboardGet(params?: {
    reportId?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.reportDashboardGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reportAccessTokenGet
   */
  static readonly ReportAccessTokenGetPath = '/Report/accessToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportAccessTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportAccessTokenGet$Response(params?: {
    reportId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EmbeddedReport>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportAccessTokenGetPath, 'get');
    if (params) {
      rb.query('reportId', params.reportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmbeddedReport>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportAccessTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportAccessTokenGet(params?: {
    reportId?: string;
  },
  context?: HttpContext

): Observable<EmbeddedReport> {

    return this.reportAccessTokenGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<EmbeddedReport>) => r.body as EmbeddedReport)
    );
  }

  /**
   * Path part for operation reportTokenUsageGet
   */
  static readonly ReportTokenUsageGetPath = '/Report/tokenUsage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportTokenUsageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportTokenUsageGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportTokenUsageGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportTokenUsageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportTokenUsageGet(params?: {
  },
  context?: HttpContext

): Observable<number> {

    return this.reportTokenUsageGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
