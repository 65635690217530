import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';

import { COLUMN_DEFS } from './care-prescription.columns';
import {
  IApprovedPrescription,
  IPrescription,
  IPrescriptionDetail,
} from '@shared/models/prescription';
import { IPrescriptionFilterPreset } from '@shared/models/prescription-filters';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { ToastrService } from 'ngx-toastr';
import { ClaimStatus, InvoiceStatus, InvoiceRejectionReason, B340StringConstants, formatDateFourDigitYear } from '../../care-prescriptions/shared-lib';
import { Subscription } from 'rxjs';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { FilterOption } from '@care/views/care-all-prescriptions/care-all-prescriptions.component'
import { Exception } from '@microsoft/applicationinsights-web';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
interface Tpa {
  tpaId: number;
  tpaName: string;
}

@Component({
  selector: 'coach-care-prescriptions-processing',
  templateUrl: './care-prescriptions-processing.component.html',
  host: {
    class: 'page-content coach-care-prescriptions',
  },
})
export class CarePrescriptionsProcessingComponent
  extends KillSubscriptions
  implements OnInit, OnDestroy
{
  public static AUDIT_ACCESS_MESSAGE = 'Accessed 340B Processing Queue';
  public static AUDIT_PRINT_MESSAGE = 'Printed 340B TPA Approved List';

  public isClaimSelected: boolean = false;

  public prescriptions: Array<IApprovedPrescription> = [];
  public dataLoaded: boolean = false;
  public loadingStatus = true;
  public cancelLoadingBool = false;
  public resetFilter = false;
  public selectedPreset: IPrescriptionFilterPreset;
  public prescriptionDetail: IPrescriptionDetail;
  
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  public selectedPatientInfo: any;
  readonly defaultColDef: ColDef = {
    resizable: true,
    headerCheckboxSelectionFilteredOnly: true,
  };
  readonly columnDefs = COLUMN_DEFS;

  public orgId: string = null;
  public tier: IHierarchyTier = null;
  public rowCount: number = 10;
  public pageNo: number = 1;

  public redrawRowInterval: any;

  dateRangeLower: Date;
  dateRangeHigher: Date;

  public gridObject: any = null;

  authSubscription: Subscription;
  currentUser: any;

  currentRow;

  filterOptions: FilterOption[];
  isPatientFiltersOpen: boolean = false;
  tpa: Tpa;

  public claimPanelStatus: number = 1;
  
  UIUpdates340BFeatureFlag: boolean;
  @Output() public gridApiEmitter: EventEmitter<GridApi> = new EventEmitter();
  @Output() public filterOptionsEmitter: EventEmitter<FilterOption[]> = new EventEmitter();
  
  b340StringConstants: B340StringConstants = new B340StringConstants();
  selectedExportOption = this.b340StringConstants.EXPORT_MENU_TITLE;

  constructor(
    private _route: ActivatedRoute,
    private HierarchyTierService: HierarchyTierService,
    private PrescriptionService: PrescriptionService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    protected featureFlagService: FeatureFlagService,
  ) {
    super();
    HierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (tier) => {
        if (this.tier?.selectedTierId !== tier?.selectedTierId) {
          this.tier = tier;
          this.prescriptions = await this.getPrescriptions();
          this.initializeFilter();
        }
      });
      this.UIUpdates340BFeatureFlag = this.featureFlagService.hasFeatureFlag(CareFeatureConstants.UIUpdates340B)
  }

  ngOnInit(): void {
    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    clearInterval(this.redrawRowInterval);
  }

  onGridReady(param: any): void {
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
    this.rowCount = this.gridApi.getDisplayedRowCount();
    this.pageNo = 1;
    this.gridApiEmitter.emit(this.gridApi)
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.columnApi.getColumns().forEach(function (column) {
      allColumnIds.push(column.getColId());
    });
    this.columnApi.autoSizeColumns(allColumnIds);
  }

  onFirstDataRendered(params: any): void {
    this.gridObject = params;
    this.dataLoaded = true;
  }

  public async filterChange(event: any) {}

  public togglePatientFilters(): void {
    this.isPatientFiltersOpen = !this.isPatientFiltersOpen;
  }

  removeRow(id: number = -1) {
    let selectedData = this.gridApi.getSelectedRows();
    if (id !== -1) {
      selectedData = selectedData.filter(item => item.claimPk === id);
    }
    this.gridApi.applyTransaction({ remove: selectedData });
  }

  public async onRowClicked(event: any): Promise<void> {
    this.selectedPatientInfo = {
      claimPk: event.data.claimPk,
      claimId: event.data.claimId,
      claimType: event.data.claimType,
      acoId: event.data.acoId,
      tier2_id: event.data.tier2_id,
      tier3_id: event.data.tier3_id,
      tier4_grpId: event.data.tier4_grpId,
      rxNumber: event.data.rxNumber,
      patientName: event.data.patientName,
      patientDob: event.data.dob,
      patientSex: event.data.sex,
      mbi: event.data.mbi,
      patientPrimaryCare: '',
      ndc: event.data.ndc,
      prescriptionName: event.data.prescriptionName,
      prescriberName: event.data.prescriberName,
      prescriberCredentials: event.data.prescriberCredentials,
      prescriberSpecialty: event.data.prescriberSpecialty,
      prescriberPhone: event.data.prescriberPhone,
      location: event.data.location,
      firstFilled: event.data.firstFilled,
      npi: event.data.npi,
      orgName: event.data.pharmacyNameOther
        ? event.data.pharmacyNameOther
        : event.data.pharmacyName,
      pharmacy_Address: event.data.pharmacy_Address,
      patientZip: event.data.patientZip,
      prescriptionQuantity: event.data.prescriptionQuantity,
      clPrescriptionFillNumber: event.data.clPrescriptionFillNumber,
      daysSupply: event.data.daysSupply,
      prescriberDEA: event.data.prescriberDEA,
      prescriberNPI: event.data.prescriberNPI,
      status: event.data.status,
      userId: this.currentUser.dnn_id,
      medicationDescription: event.medicationDescription,
      gender: event.data.gender,
      pharmacyNpi: event.data.pharmacyNpi,
      pharmacyOtherAddress: event.data.pharmacyOtherAddress,
      pharmacyOtherCity: event.data.pharmacyOtherCity,
      pharmacyOtherPhone: event.data.pharmacyOtherPhone,
      pharmacyOtherState: event.data.pharmacyOtherState,
      pharmacyOtherZip: event.data.pharmacyOtherZip,
      prescriptionRefId: event.data.prescriptionRefID,
      drugManufacturer: event.data.drugManufacturer,
    };

    this.PrescriptionService.setSelectedProcessingClaimInfo({
      selectedPatientInfo: this.selectedPatientInfo,
      rowId: event.node.id,
    });

    this.currentRow = event;
  }

  public cancelPrescriptionsLoadingProcess(): void {
    this.cancelLoadingBool = true;
    this.loadingStatus = false;
    this.resetFilter = true;
    let that = this;
    setTimeout(function () {
      that.resetFilter = false;
    }, 1000);
  }

  initializeFilter() {
    const { selectedItem } = this.tier;

    this.PrescriptionService.getPrescribers(
      selectedItem.tier1_ID,
      selectedItem.tier2_ID,
      selectedItem.tier3_ID,
      selectedItem.tier4_ID
    ).then((prescribers) => {
      const filterOptions = [
        {
          headerLabel: 'Status',
          filterInstanceLabel: 'status',
          optionLabels: ['Approved', 'Processed'],
          checkedByDefault: ['Approved'],
          dataMapper: (data) => {
            switch (data) {
              case 'Approved':
                return `${ClaimStatus.APPROVED}`;
              case 'Processed':
                return `${ClaimStatus.PROCESSED}`;
            }
          },
        },
      ];
      prescribers.shift();

      filterOptions.push({
        headerLabel: 'External Prescribers',
        filterInstanceLabel: 'prescriberName',
        checkedByDefault: ['All'],
        optionLabels: prescribers
        .filter(p => p.name && p.name !== ' ')
        .map((prescriber) => {
          return prescriber.name;
        }),
        dataMapper: null,
      });
      
      let pharmacyNames = [];

      this.PrescriptionService.getPharmacyNames(
        selectedItem.tier1_ID,
        selectedItem.tier2_ID,
        selectedItem.tier3_ID,
        selectedItem.tier4_ID
      ).then((names) => {

        pharmacyNames = names.map((pair) => {
          return pair.secondName ? pair.secondName : pair.firstName;
        });

        pharmacyNames = Array.from(new Set(pharmacyNames));
        
        filterOptions.unshift({
          headerLabel: 'Pharmacy Names',
          filterInstanceLabel: 'pharmacyNamesOtherHidden',
          optionLabels: pharmacyNames,
          checkedByDefault: ['All'],
          dataMapper: null,
        });

        filterOptions.push({
          headerLabel: 'Claim Source',
          filterInstanceLabel: 'tpaName',
          checkedByDefault: ['All'],
          optionLabels: this.b340StringConstants.FILTER_SHARED_OPTION_LABELS_TPANAME,
          dataMapper: null,
        });

        this.filterOptions = filterOptions;
        this.filterOptionsEmitter.emit(this.filterOptions)

        this.loadingStatus = false;
      });
    });
  }

  generateTpaOutputFile() {
    const dateRange = this.getFilterDateRange();
    const auditOptions = {
      entityid: parseInt(this.currentUser.dnn_id),
      moduleid: 2, // care
      componentid: 36,
      actionid: 9,
      tablename: 'Coach340BDetail',
      auditdesc: `User ID ${this.currentUser.dnn_id} Printed 340B TPA approved list for date range ${dateRange}`,
      applicationID: 2,
    };
    this.PrescriptionService.writeAuditMessage(auditOptions);

    function removeEntryFromKey(keys, toRemove) {
      const index = keys.findIndex((column) => column === toRemove);
      if (index >= 0) {
        keys.splice(index, 1);
      }
    }

    const generateColumnsForExcel = () => {
      const keys = this.columnApi
        .getColumns()
        .map((column) => column.getColId());

      removeEntryFromKey(keys, 'isProcessed');
      removeEntryFromKey(keys, 'patientName');
      removeEntryFromKey(keys, 'mbi');
      removeEntryFromKey(keys, 'prescriberPhone');
      removeEntryFromKey(keys, 'location');
      removeEntryFromKey(keys, 'prescriberName');
      removeEntryFromKey(keys, 'prescriptionName');
      removeEntryFromKey(keys, 'firstFilledHidden');
      removeEntryFromKey(keys, 'pharmacyNamesHidden');
      removeEntryFromKey(keys, 'status');
      removeEntryFromKey(keys, 'awpPrice');
      removeEntryFromKey(keys, 'claimType');
      removeEntryFromKey(keys, 'drugManufacturer');
      removeEntryFromKey(keys, 'claimType_1');
      removeEntryFromKey(keys, 'tpaName');

      return keys;
    };

    const columnKeys = generateColumnsForExcel();

    const swapColumns = (col1, col2) => {
      const col1Index = columnKeys.findIndex((column) => column === col1);
      const col2Index = columnKeys.findIndex((column) => column === col2);
      const temp = columnKeys[col1Index];
      columnKeys[col1Index] = columnKeys[col2Index];
      columnKeys[col2Index] = temp;
    };

    swapColumns('rxNumber', 'dob');
    swapColumns('dob', 'ndc');
    swapColumns('dob', 'medicationDescription');
    swapColumns('patientZip', 'dob');
    swapColumns('patientZip', 'prescriptionQuantity');
    swapColumns('clPrescriptionFillNumber', 'prescriptionQuantity');
    swapColumns('revenue', 'daysSupply');
    swapColumns('prescriberDEA', 'clPrescriptionFillNumber');
    swapColumns('prescriberNPI', 'daysSupply');
    swapColumns('prescriberDEA', 'prescriberNPI');
    swapColumns('bin', 'prescriberNPI');
    swapColumns('bin', 'pcn');
    swapColumns('groupNumber', 'pcn');
    swapColumns('groupNumber', 'dateWritten');
    swapColumns('rxNumber', 'fillDate');
    swapColumns('prescriptionQuantity', 'ndc');
    swapColumns('medicationDescription', 'dateWritten');
    swapColumns('dateWritten', 'patientLastName');
    swapColumns('dateWritten', 'patientFirstName');
    swapColumns('dob', 'dateWritten');
    swapColumns('revenue', 'prescriptionQuantity');
    swapColumns('revenue', 'clPrescriptionFillNumber');
    swapColumns('clPrescriptionFillNumber', 'daysSupply');
    swapColumns('pharmacyNamesOtherHidden', 'daysSupply');
    swapColumns('pharmacyAddress', 'prescriberDEA');
    swapColumns('pharmacyAddress', 'prescriberNPI');
    swapColumns('pharmacyAddress', 'bin');
    swapColumns('dateWritten', 'npi');

    let ndcIndex = columnKeys.findIndex(
      (elem) => elem === 'pharmacyNamesOtherHidden'
    );
    columnKeys.splice(ndcIndex, 1);

    ndcIndex = columnKeys.findIndex(
      (elem) => elem === 'npi'
    );
    columnKeys.splice(ndcIndex, 1);

    ndcIndex = columnKeys.findIndex(
      (elem) => elem === 'pharmacyAddress'
    );
    columnKeys.splice(ndcIndex, 1);

    removeEntryFromKey(columnKeys, 'fillDate');

    this.gridApi.exportDataAsExcel({
      columnKeys,
      onlySelected: true,
      processCellCallback: (params) => {
        switch (params.column.getColId()) {
          case 'patientName_1':
            var fullName = params.value;
            var commaIndex = fullName.indexOf(',');
            var lastName = fullName.substring(0, commaIndex);
            return lastName;
          case 'patientName_2':
            fullName = params.value;
            commaIndex = fullName.indexOf(',');
            var firstName = fullName.substring(commaIndex + 1);
            return firstName.trim();
          default:
            return params.value;
        }
      },
    });
  }

  markSelectedRowsAsProcessed() {
    if (this.onCanProcessClaims()) {
      const selectedData = this.gridApi.getSelectedRows();
      this.PrescriptionService.setClaimToProcessed(
        selectedData.map((claim) => {
          return {
            claimId: claim.claimId,
            prescriptionId: claim.claimPk
          };
        })
      )
      .then(() => {
        const invoices = selectedData.map((claim) => {
          return {
            id: null,
            claimId: claim.claimPk,
            claimType: claim.claimType,
            invoiceQStateID: 1,
            priceSpread: 0,
            costAmount: 0,
            tpaId: null,
            tpaName: '',
            rejectByUserId: null,
            rejectDateTime: null,
            rejectNote: '',
            rejectReasonId: null,
            isEnabled: 1,
            createdOn: null,
            lastUpdateUserId: parseInt(this.currentUser.dnn_id),
            lastUpdatedOn: null,
            rxNumber: null,
            fillNumber: null,
            ndc: null,
            medicationDescription: null,
            status: null
          };
        });
        this.PrescriptionService.submitInvoices(invoices);
      })
      .then(() => this.removeRow())
    }
  }

  async undoProcessedClaim(id: number) {
    this.loadingStatus = true;
    const invoiceStatusId = await this.PrescriptionService.getInvoiceId(id);
    if (invoiceStatusId.id < 3) {
      this.PrescriptionService.undoProcessClaim(id, this.selectedPatientInfo.claimType).then(() => this.removeRow(id));
    } else {
      alert('Sorry, but that claim has already been invoiced and cannot be reset.');
    }
    this.loadingStatus = false;
  }

  onRowSelected() {
    const claims = this.gridApi.getSelectedNodes();
    if (
      claims.length > 0 &&
      claims.every((claim) => claim.data.status == ClaimStatus.APPROVED)
    ) {
      this.isClaimSelected = true;
    } else {
      this.isClaimSelected = false;
    }
  }

  onCanProcessClaims(): boolean {
    const selectedData = this.gridApi.getSelectedRows();
    return selectedData.every((claim) => claim.status == ClaimStatus.APPROVED);
  }

  selectionChanged(e) {
    switch (e.target.value) {
      case this.b340StringConstants.EXPORT_MACRO_HELIX:
        this.generateTpaOutputFile();
        break;
      case this.b340StringConstants.EXPORT_CVS:
        this.generateCvsFile();
        break;
      case this.b340StringConstants.EXPORTS_EXPORT:
        this.generateFullExport();
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.selectedExportOption = this.b340StringConstants.EXPORT_MENU_TITLE;
    }, 0);
  }

  generateFullExport() {
    let tbl = document.createElement('table');
    let data = this.buildTableData();
    let visibleNodes = this.buildVisibleClaimsNodeSet();
    let filteredData = data.filter(claimObj => visibleNodes.has(claimObj.claimPk)).map(claimObj => {
      delete claimObj.claimPk;
      claimObj.drugManufacturer = claimObj.drugManufacturer ?? '';
      return claimObj;
    });

    this.generateTable(tbl, filteredData);
    this.generateTableHead(tbl, filteredData[0]);

    const csv = this.toCsv(tbl);
    this.download(csv, 'download.csv');
  }

  generateTable(table, data) {
    for (let element of data) {
      let row = table.insertRow();
      for (let key in element) {
        let cell = row.insertCell();
        let text = document.createTextNode(element[key]);
        cell.appendChild(text);
      }
    }
  }

  generateTableHead(table, data) {
    let thead = table.createTHead();
    let row = thead.insertRow();
    for (let key in data) {
      let th = document.createElement("th");
      let text = document.createTextNode(key);
      th.appendChild(text);
      row.appendChild(th);
    }
  }

  toCsv = function (table) {
    // Query all rows
    const rows = table.querySelectorAll('tr');

    return [].slice
        .call(rows)
        .map(function (row) {
            // Query all cells
            const cells = row.querySelectorAll('th,td');
            return [].slice
                .call(cells)
                .map(function (cell) {
                    return cell.textContent;
                })
                .join(',');
        })
        .join('\n');
  };
  
  download = function (text, fileName) {
    const link = document.createElement('a');
    link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
    link.setAttribute('download', fileName);

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  buildTableData(): any {
    return this.prescriptions.map(p => {
      return {
        "claimPk": p.claimPk,
        "Tier2_Name": p.tier2_Name,
        "Rx Number": p.rxNumber,
        "Bene_Full Name": `"${p.patientName}"`,
        "Bene DOB": formatDateFourDigitYear(p.dob),
        "NDC": p.ndc,
        "Medication_Description": `"${p.prescriptionName}"`,
        "Fill Number": p.clPrescriptionFillNumber,
        "Prescription_Filled_Date": formatDateFourDigitYear(p.fillDate),
        "Date_Initial_Fill": formatDateFourDigitYear(p.firstFilled),
        "Prescribing_Provider_NPI": p.npi,
        "Prescribing_Provider_Name": `"${p.prescriberName}"`,
        "Provider_Address": `"${p.location}"`,
        "Prescribing_Provider_Specialty": `"${p.prescriberSpecialty}"`,
        "Service_Pharmacy_NPI": p.servicePharmacyNpi,
        "Pharmacy_Org_Name": `"${p.pharmacyName}"`,
        "Pharmacy_Other_Org_Name": `"${p.pharmacyNameOther}"`,
        "Pharmacy_Address": `"${p.pharmacy_Address}"`,
        "Pharmacy_Address_Other": `"${p.pharmacyOtherAddress || ''}"`,
        "Price": `$${p.claimType === 'CMS' ? p.awpPrice : p.tpaPrice}`,
        "Claim Source": p.claimType === "CMS" ? "CMS" : p.tpaName,
        "drugManufacturer": p.drugManufacturer,
      }
    })
  }

  buildVisibleClaimsNodeSet() {
    let pageCount = this.gridApi.paginationGetTotalPages();
    let originalPage = this.gridApi.paginationGetCurrentPage();
    this.gridApi.paginationGoToFirstPage();
    let nodes = [];
    for (let i = 0; i < pageCount; i++) {
      let currentPageNodes = this.gridApi.getRenderedNodes();
      nodes.push(currentPageNodes);
      this.gridApi.paginationGoToNextPage();
    }
    this.gridApi.paginationGoToPage(originalPage);
    return new Set(nodes.flat(1).map(node => node.data.claimPk));
  }

  generateCvsFile() {
    const columnKeys = ['rxNumber', 'npi', 'clPrescriptionFillNumber']
    this.gridApi.exportDataAsExcel({
      onlySelected: true,
      columnKeys
    });
  }

  markCurrentRowAsProcessed(id) {
    this.gridApi.forEachNode((rowNode, index) => {
      if (rowNode.data.claimPk === id) {
        rowNode.setSelected(true);
      } else {
        rowNode.setSelected(false);
      }
    });
    this.markSelectedRowsAsProcessed();
  }

  async getPrescriptions(): Promise<IApprovedPrescription[]> {
    const { selectedItem } = this.tier;
    let prescriptions;
    try {
      prescriptions = this.PrescriptionService.getApprovedClaims(
        selectedItem.tier1_ID,
        selectedItem.tier2_ID,
        selectedItem.tier3_ID,
        selectedItem.tier4_ID
      );
    } catch (e) {
      this.toastr.error(`${e.status}: ${e.statusText}`, 'Error');
    }

    this.writeAccessAuditMessage(
      CarePrescriptionsProcessingComponent.AUDIT_ACCESS_MESSAGE
    );

    return prescriptions;
  }

  writeAccessAuditMessage(action: string) {
    const dateRange = this.getFilterDateRange();

    setTimeout(async () => {
      this.PrescriptionService.writeAuditMessage({
        entityid: parseInt(this.currentUser.dnn_id),
        moduleid: 2, // care
        componentid: 36,
        actionid: 6, // phi access
        tablename: 'Coach340BClaims',
        auditdesc: `User ID ${this.currentUser.dnn_id} ${action} for date range ${dateRange}`,
        applicationID: 2,
      });
    }, 0);
  }

  getFilterDateRange() {
    return `${this.dateRangeLower?.toLocaleDateString() ?? 'All'} - ${
      this.dateRangeHigher?.toLocaleDateString() ?? 'All'
    }`;
  }

  async getPrescriptionDetail(claimId: number): Promise<void> {
    try {
      this.prescriptionDetail =
        await this.PrescriptionService.getPrescriptionDetail(
          this.selectedPatientInfo.claimPk,
          this.selectedPatientInfo.acoId,
          this.selectedPatientInfo.tier2_id,
          this.selectedPatientInfo.tier3_id,
          this.selectedPatientInfo.tier4_grpId,
          claimId,
          this.currentUser.dnn_id,
          this.selectedPatientInfo
        );
    } catch (e) {
      this.toastr.error(`${e.status}: ${e.statusText}`, `Error`);
    }
  }
}
