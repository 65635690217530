import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Abstract component for unsubscribing to subscriptions on component destruction.
 * 
 * @example
 * this.someSubscription.pipe(takeUntil(this.killTrigger)).subscribe(...);
 */
@Directive()
export abstract class KillSubscriptions implements OnDestroy {
    killTrigger: Subject<void> = new Subject();

    ngOnDestroy(): void {
        this.killTrigger.next();
    }
}