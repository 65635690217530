import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { IRecentMatcher } from './recent-matcher';
export class HCCDashboardMatcher implements IRecentMatcher {
  match(url: string): boolean {
    if (url.includes('track/clinical/dashboard')) {
      return true;
    } else {
      return false;
    }
  }

  createLog(url: string): RecentLogData {
    return {
      url,
      elements: [],
    };
  }

  title(url: string): string {
    if (url.includes('patients')) {
      return 'Chronic Conditions Dashboard | Patients';
    } else if (url.includes('clinicians')) {
      return 'Chronic Conditions Dashboard | Clinicians';
    } else if (url.includes('patientDetails')) {
      return 'Chronic Conditions Dashboard | Patient Details';
    } else if (url.includes('practices')) {
      return 'Chronic Conditions Dashboard | Practices';
    } else if (url.includes('cins')) {
      return 'Chronic Conditions Dashboard | CIN';
    } else {
      return 'Chronic Conditions Dashboard';
    }
  }
}
