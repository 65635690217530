import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';
import { MaskPipe } from '@shared/pipes/mask.pipe';

import { formatDate } from '../../shared-lib';

import pdfMake from "pdfmake/build/pdfmake";  
import pdfFonts from "pdfmake/build/vfs_fonts";  
import { CareFeatureConstants } from '@shared/models/module-constants';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'coach-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.scss'],
})
export class PatientHeaderComponent implements OnInit {
  importFeatureFlag: string = CareFeatureConstants.FileImport340B;
  
  @Input() selectedPatientInfo: any = [];
  @Input() prescriptionDetail: any;
  @Input() route: ActivatedRoute;
  @Input() showInstructions: boolean = true;
  @Input() claimStatus: number;
  @Input() tier2Id: string;
  @Input() showPatientFaceSheet: boolean = false;

  constructor(
    private router: Router,
    private prescriptionService: PrescriptionService,
    private mask: MaskPipe
  ) {}
  
  ngOnInit(): void {}
  
  public formatDate(dateStr) {
    return dateStr ? new Date(dateStr).toLocaleDateString() : 'Unknown';
  }

  public formatPhone(value) {
    if (!value) {
      return '';
    }

    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  }

  public getPatientSex() {
    return this.selectedPatientInfo?.gender ?? '';
  }

  navigateToPatientFacesheet() {
    if (this.showPatientFaceSheet) {
      this.router.navigate([`../patient/${this.selectedPatientInfo.chPatId}`], {
        relativeTo: this.route,
      });
    }
  }

  exportClaimFiles(): void {
    this.prescriptionService.downloadAttachedFiles(this.tier2Id, this.selectedPatientInfo.prescriptionRefID);
    this.generatePDF();
  }

  getVisitDate() {
    let aDate = Date.parse(this.prescriptionDetail.visit_Note_Visit_Date);
    let defaultDate = Date.parse('01/01/70');

    if (aDate == defaultDate) {
      return '';
    }
    return this.prescriptionDetail.visit_Note_Visit_Date;
  }

  getMbi() {
    if (this.selectedPatientInfo && this.selectedPatientInfo.claimType !== 'TPA') {
      return this.mask.transform(this.selectedPatientInfo.mbi, 6)
    } else {
      return "N/A";
    }
  }

  async generatePDF() {
    let originalVisitDate = this.prescriptionDetail.visit_Note_Visit_Date;
    let newVisitDate = new Date(originalVisitDate);
    newVisitDate.setDate(newVisitDate.getDate() + 1);

    let dateFormat = /d{4}-d{2}-d{2}/;
    if (!dateFormat.test(this.prescriptionDetail.visit_Note_Visit_Date)) {
      this.prescriptionDetail.visit_Note_Visit_Date = formatDate(newVisitDate);
      if (this.prescriptionDetail.visit_Note_Visit_Date === '12/31/69') {
        this.prescriptionDetail.visit_Note_Visit_Date = 'n/a';
      }
    }
    
    let username = await this.prescriptionService.getUserFullName(parseInt(this.selectedPatientInfo.userId));
    let followUpPlan = this.prescriptionDetail.follow_Up_Plan
      .replace('[CE Location]', this.prescriptionDetail.cE_Location)
    
    let docDefinition = {  
      content: [
        {
          text: 'Care Coordination Note',
          fontSize: 14,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        
        {
          text: `Patient Name: ${this.selectedPatientInfo.patientName}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `DOB: ${formatDate(this.selectedPatientInfo.patientDob)}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `MBI: ${ this.getMbi() }`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },

        {
          text: `By: ${this.prescriptionDetail.claim_Status_Set_By}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `Date: ${formatDate(this.prescriptionDetail.claim_Status_Set_On_Date)}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: ' ',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: '340B External Prescription',
          fontSize: 14,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        {
          text: this.selectedPatientInfo.prescriptionName,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: true
        },
        {
          text: `by ${this.selectedPatientInfo.prescriberName}${this.selectedPatientInfo.prescriberCredentials 
            ? ", " + this.selectedPatientInfo.prescriberCredentials : ''}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `First filled ${formatDate(this.selectedPatientInfo.firstFilled)}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: ' ',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: `Visit Note from ${this.prescriptionDetail.visitProvider}:`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        {
          text: `Visit Date: ${this.getVisitDate()}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false,
        },
        {
          text: `Rx Diagnosis: ${this.prescriptionDetail.visit_Note_Diagnosis}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false,
        },
        {
          text: ' ',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
        {
          text: 'Follow-Up Plan:',
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: true,
          decoration: 'underline'
        },
        {
          text: `${followUpPlan}`,
          fontSize: 11,
          alignment: 'left',
          color: '#000000',
          bold: false
        },
      ]
    };

    const ceRelatedReferralDate = formatDate(this.selectedPatientInfo.ceRelatedReferralDate);
    const ceVisitDate = formatDate(this.selectedPatientInfo.ceVisitDate);

    if (ceVisitDate !== '12/31/69'
        && ceVisitDate !== '01/01/70') {
      docDefinition.content.push({
        text: 'Related Visit By Covered Entity:',
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: true,
        decoration: 'underline'
      });
      docDefinition.content.push({
        text: `Visit Date: ${ceVisitDate}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
      docDefinition.content.push({
        text: `Provider: ${this.prescriptionDetail.cE_Provider}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
      docDefinition.content.push({
        text: `CE Location: ${this.prescriptionDetail.cE_Location}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
      docDefinition.content.push({
        text: ' ',
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
    }
    if (ceRelatedReferralDate !== '12/31/69'
        && ceRelatedReferralDate !== '01/01/70') {
      docDefinition.content.push({
        text: 'Related Referral By Covered Entity:',
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: true,
        decoration: 'underline'
      });
      docDefinition.content.push({
        text: `Referral Date: ${ceRelatedReferralDate}`,
        fontSize: 11,
        alignment: 'left',
        color: '#000000',
        bold: false,
      });
    }
   
    pdfMake.createPdf(docDefinition).download();

    this.prescriptionDetail.visit_Note_Visit_Date = originalVisitDate;
  }  
}
