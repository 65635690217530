import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  UntypedFormArray,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  ComplianceFormQuestion,
  ComplianceFormAnswer,
  ComplianceFormQuestionChecklistOptions,
} from '@shared/models/forms/compliance-form-question';

@Component({
  selector: 'coach-compliance-form-question',
  templateUrl: './compliance-form-question.component.html',
  host: {
    class: 'coach-compliance-form-question',
  },
})
export class ComplianceFormQuestionComponent implements OnInit {
  @Input() public question: ComplianceFormQuestion;
  @Input() public sectionIndex: number;
  @Input() public questionIndex: number;
  @Input() public clearForm: EventEmitter<boolean>;
  @Input() complianceForm: UntypedFormGroup; // Import form from parent component
  @Output() public answerUpdated: EventEmitter<
    [number, number, string, string]
  > = new EventEmitter(); // [sectionIndex, questionIndex, value, questionID]
  @Output() public fileUpdated: EventEmitter<
    [number, number, any, string ]
  > = new EventEmitter();

  public answer = ComplianceFormAnswer;
  public formAnswerValue: string;
  public isInfoTextOpen = false;
  public answerForm: UntypedFormGroup;

  public afa: AbstractControl;
  public partialText: AbstractControl;
  public textRespOnly: AbstractControl;
  public fileUp: AbstractControl;
  //public chkbxlstOtherTxt: AbstractControl;
  public formCheckSub: Subscription;
  public formCheck: UntypedFormGroup;
  public compCheckList: ComplianceFormQuestionChecklistOptions[] = [];
  //public otherCheck: boolean = false;

  // fileUpload "Sub" Form
  public uploadedFileName: string = 'No file chosen';

  private addCheckboxes() {
    if (this.question != undefined) {
      if (this.question.checkListResponseOnly == true){
        this.question.checkListOptions.forEach(() =>
          this.chklstFormArray.push(new UntypedFormControl(false))
        );
      }
    }
}

get chklstFormArray() {
  return this.formCheck.get('chkbxlst') as UntypedFormArray;
}

  constructor(
    fb: UntypedFormBuilder,
    private _cd: ChangeDetectorRef,) {
    this.answerForm = fb.group({
      formAnswer: fb.control('', [Validators.required]),
      formPartialText: fb.control(''),
      formTextRespOnly: fb.control(''),
      fileUp: fb.control(''),
      file: [null]
    });
    this.formCheck = fb.group({
      chkbxlst: fb.array([]),
    });
    //this.uploadForm = fb.group({ file: [null, Validators.required] });
    this.addCheckboxes();
  }



  ngOnInit(): void {
    if (this.clearForm) {
      this.clearForm.subscribe(() => {
        this.answerForm.reset();
      });
    }
    if (this.question.checkListResponseOnly == true){
      this.addCheckboxes();
      this.formCheckSub = this.formCheck.valueChanges.subscribe(() => {
        this.updateSelectedCheckList();
      });
      this.compCheckList = this.question.checkListOptions;
    }

    this.afa = this.answerForm.controls['formAnswer'];
    this.partialText = this.answerForm.controls['formPartialText'];
    this.textRespOnly = this.answerForm.controls['formTextRespOnly'];
    this.fileUp = this.answerForm.controls['fileUp'];

    this.formAnswerValue = this.afa.value || null;
    this.answerUpdated.emit([
      this.sectionIndex,
      this.questionIndex,
      this.formAnswerValue,
      this.question.questionId
    ]);
    this.afa.valueChanges.subscribe((change) => {
      this.formAnswerValue = change;
      if (this.formAnswerValue !== 'partial' && this.question.standardQuestion == true) {
        this.answerUpdated.emit([
          this.sectionIndex,
          this.questionIndex,
          this.formAnswerValue,
          this.question.questionId
        ]);
      } else if (this.formAnswerValue == 'partial' && this.question.standardQuestion == true && this.question.requiredForQuestions != null) {
        this.answerUpdated.emit([
          this.sectionIndex,
          this.questionIndex,
          this.formAnswerValue,
          this.question.questionId
        ]);
      }
    });
    this.textRespOnly.valueChanges.subscribe(() => {
      this.answerUpdated.emit([
        this.sectionIndex,
        this.questionIndex,
        this.textRespOnly.value,
        this.question.questionId
      ]);
    });
    this.partialText.valueChanges.subscribe(() => {
      this.answerUpdated.emit([
        this.sectionIndex,
        this.questionIndex,
        this.partialText.value,
        this.question.questionId
      ]);
    });
    this.fileUp.valueChanges.subscribe(() => {
      this.answerUpdated.emit([
        this.sectionIndex,
        this.questionIndex,
        this.uploadedFileName,
        this.question.questionId
      ]);
    });


    const property = 'FormQ' + this.question.questionId;
    const questionControl = this.complianceForm.get(`${property}.FormQResponses.0.UserResponse`);

    if (questionControl.value != '' && questionControl.value != null) {
      if(this.question.standardQuestion == true){
        this.formAnswerValue = questionControl.value;
        this.answerForm.controls['formAnswer'].setValue(questionControl.value);

        if (questionControl.value != 'no' && questionControl.value != 'yes' && this.question.standardQuestion == true) {
          this.formAnswerValue = 'partial';
          this.answerForm.controls['formPartialText'].setValue(questionControl.value);
          this.answerForm.controls['formAnswer'].setValue('partial');

         }
      } else if (this.question.textResponseOnly == true) {
        this.formAnswerValue = questionControl.value;
        this.answerForm.controls['formTextRespOnly'].setValue(questionControl.value);
          this.answerForm.controls['formAnswer'].setValue(questionControl.value);

      } else if (this.question.fileUpload == true) {
        this.formAnswerValue = questionControl.value;
        this.uploadedFileName = questionControl.value;
        this.answerForm.controls['fileUp'].setValue(questionControl.value);
        this.answerForm.controls['formAnswer'].setValue(questionControl.value);

      } else if (this.question.checkListResponseOnly == true) {
        this.formAnswerValue = 'partial';
        this.answerForm.controls['formAnswer'].setValue(questionControl.value);
        const chkList = questionControl.value.split(',');

        this.chklstFormArray.clear();
        this.question.checkListOptions?.forEach((chk) => {
          const isSet = chkList.some((x) => x === chk.checkText);
          this.chklstFormArray.push(new UntypedFormControl(isSet));
        });

      }

    }
  }

  public onFileChange(event): void {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.answerForm.patchValue({ file: file });
      this.uploadedFileName = file.name || 'File name not found';
      this._cd.markForCheck();

      this.answerForm.controls['formAnswer'].setValue(file.name);

      this.fileUpdated.emit([
        this.sectionIndex,
        this.questionIndex,
        file,
        this.question.questionId
      ]);
      this.answerUpdated.emit([
        this.sectionIndex,
        this.questionIndex,
        file.name,
        this.question.questionId
      ]);

    } else {
      this.resetUploadForm();
    }

  }

  private resetUploadForm(): void {
    this.answerForm.reset({ file: null });
    this.uploadedFileName = 'No file chosen';
    this._cd.markForCheck();
  }

  public toggleInfoText(): void {
    this.isInfoTextOpen = !this.isInfoTextOpen;
  }

  public reset(): void {
    this.answerForm.reset();
  }

  public updateSelectedCheckList() {
    const selectedCheckList: ComplianceFormQuestionChecklistOptions[] = this.formCheck
      .get('chkbxlst')
      .value.map((selected, i) => (selected ? this.compCheckList[i] : null))
      .filter((p) => p !== null);


    // update names
    const selectedselectedCheckListValues = selectedCheckList
      .map((p) => p.checkText)
      .toString();

      // const selectedOther = selectedCheckList.find(i => i.checkText == 'Other');

      // if(selectedOther){
      //   this.otherCheck = true;
      // }
      // else {
      //     this.otherCheck = false;
      // }


      this.answerUpdated.emit([
        this.sectionIndex,
        this.questionIndex,
        selectedselectedCheckListValues,
        this.question.questionId
      ]);

  }
}
