<div class="sidebar-logo u-position--relative u-preserve">
  <coach-sidebar-logo [isSidebarOpen]="isSidebarOpen" (click)="navigateHome()"></coach-sidebar-logo>
  <button
    class="pullTab"
    (click)="toggleSidebar()"
    [ngClass]="{ 'is-open': isSidebarOpen }"
    aria-label="Toggle Sidebar"
  >
    <coach-icon iconName="chevrons-right"></coach-icon>
  </button>
</div>
<div class="sidebar-main u-fill column u-gradient">
  <coach-sidebar-menu
    class="
      sidebar-main-items
      u-preserve
      animation--content--fadeIn
      animations--sequential
    "
    [isSidebarOpen]="isSidebarOpen"
    (itemClicked)="menuItemClicked($event)"
  >
 
</coach-sidebar-menu>

  <ng-container *hasFeatureFlag="Recents">
    <coach-sidebar-recent
      class="sidebar-main-recent dynamicBody"
      style="overflow: hidden"
      [isSidebarOpen]="isSidebarOpen"
    >
    </coach-sidebar-recent>
  </ng-container>
  <coach-sidebar-account
    class="sidebar-main-account u-flex--bottom u-preserve"
    [isSidebarOpen]="isSidebarOpen"
  >
  </coach-sidebar-account>
</div>
