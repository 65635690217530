<div class="u-height--fit-content form-background">
  <div class="u-font-size--small">

    <div class="row">
      <select #removeReasonSelector [(ngModel)]="removeReason" id="removeReasonSelector" name="removeReasonSelector"
              (change)="fieldChanged()" required>
        <option value="" disabled selected>* Reason for removing</option>
        <option *ngFor="let reason of reasons" [ngValue]="reason">{{reason}}</option>
      </select>
    </div>
    <div class="row padding-bottom--small">
      <coach-invalid-input [select]='removeReasonSelector'></coach-invalid-input>
    </div>

    <div class="row padding-bottom--smallest">
      <textarea class="resize--none" [(ngModel)]="comment" placeholder="Add a comment" id="removeComment" rows="6"
                [maxlength]="commentCharacterLimit" (input)="fieldChanged()" #commentbox></textarea>
    </div>

    <div class="row padding-bottom--small">
      <div class="span--6 u-font-weight--light" [ngClass]="{'character-limit-reached': commentbox.value.length == commentCharacterLimit }">{{commentbox.value.length}}/{{commentCharacterLimit}}</div>
      <div class="span--6 u-text-align--right u-cursor--pointer u-font-weight--light padding-right--small" id='testRemoveCopyText' (click)="copyComment(commentbox)">Copy text</div>
    </div>
    <coach-patient-awv-inline-error></coach-patient-awv-inline-error>
    <div class="row padding-top--small u-align-items--center margin-bottom--small">
      <coach-icon *ngIf="editModeActive" class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" iconName="trash" iconColor="primary" id='testRemoveDeleteBtn' (click)="showDialog()"></coach-icon>
      <coach-icon class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" id='testRemoveCopyBtn' iconName="copy" iconColor="primary" [copy]="stringifyForm()"></coach-icon>
      <coach-patient-awv-remove-modal-dialog [showDialog]="showRemoveDialog" (buttonClicked)="onDialogButtonClick($event)"></coach-patient-awv-remove-modal-dialog>
      <button class="span--6 button is-unstyled u-flex--right is-small margin-right--small cancel-btn u-color--brand--1" id="testRemoveCancelBtn" (click)="clearFields()" [disabled]="allFieldsEmpty">Cancel</button>
      <button class="span--3 button is-small is-primary" id="testRemoveSaveBtn" (click)="save()" [disabled]="!requiredFieldsSatisfied">Save</button>
    </div>
  </div>
</div>
