/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StatusResponse } from '../models/status-response';

@Injectable({
  providedIn: 'root',
})
export class StatusService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation statusGet
   */
  static readonly StatusGetPath = '/Status';

  /**
   * Get API status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<StatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatusService.StatusGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusResponse>;
      })
    );
  }

  /**
   * Get API status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusGet(params?: {
  },
  context?: HttpContext

): Observable<StatusResponse> {

    return this.statusGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<StatusResponse>) => r.body as StatusResponse)
    );
  }

  /**
   * Path part for operation statusDelete
   */
  static readonly StatusDeletePath = '/Status';

  /**
   * Test application exception.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusDelete$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StatusService.StatusDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Test application exception.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusDelete(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.statusDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation statusHealthGet
   */
  static readonly StatusHealthGetPath = '/Status/health';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusHealthGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusHealthGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, StatusService.StatusHealthGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusHealthGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusHealthGet(params?: {
  },
  context?: HttpContext

): Observable<string> {

    return this.statusHealthGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
