<div class="row" [ngClass]="{'margin-top padding-leftRight--small': !minimalView}">
  <div class="span--6 u-bold u-font-size--large " id='testSidebarAWVWorkflow'> AWV Workflow</div>
  <div *ngIf="!isLoading"
       class="padding-leftRight--small u-border-radius--rounded u-color--white u-center u-display--flex u-flex--right u-font-size--default u-font-weight--normal" id='testSidebarAWVStatus'
       [ngClass]="{
          outreach: awvWorkflowStatus === 'READY_FOR_OUTREACH',
          outreachAttempted: awvWorkflowStatus === 'OUTREACH_ATTEMPTED',
          scheduled : awvWorkflowStatus === 'SCHEDULED',
          completed : awvWorkflowStatus === 'COMPLETED',
          declined : awvWorkflowStatus === 'DECLINED',
          ineligible : awvWorkflowStatus === 'INELIGIBLE',
          other : awvWorkflowStatus === 'OTHER',
          deferred : awvWorkflowStatus === 'DEFERRED_TO_SIGNIFY'
        }">{{convertAwvStatusToCamelCase(awvWorkflowStatus)}}
  </div>
</div>
<div class="row u-font-weight--light" [ngClass]="{'margin-bottom padding-leftRight--small': !minimalView}">
  <div *ngIf="'Inactive' === convertAwvStatusToCamelCase((awvWorkflowStatus)) && !isLoading">Patient will be re-evaluated at a later date</div>
</div>
