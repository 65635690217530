<div class="widget margin--large margin-bottom--none">
    <div class="header">
      <div class="titles t5 u-display--flex u-flex-direction--row u-align-items--center">
        <div  class="title u-width--fit-content margin-right--large">{{program}} SMS OUTREACH PROGRAM - Settings</div>
      </div>
    </div>
    <div class="dynamicBody margin-bottom--small">
        <div class=" margin--large">
            <div class="margin-bottom">
                <label class="label" >QUESTION</label>
                <div class="u-italic u-opacity--light u-font-size--large u-font-weight--lighter">Can you do your usual activities without feeling more short of breath, having trouble breathing or coughing more?</div>
            </div>
            
            <div>
                <label class="label margin-bottom--small" >SMS SCHEDULE</label>
                <div *ngFor="let phase of phases; index as i; last as isLast">
                    <div *ngIf="phase.status != 'Starts' || !isStopRequested">
                        <div class="u-display--flex u-flex-direction--row">
                            <div [ngClass]="{'currentStep': phase.status == 'Current' && !isStopRequested, 'stoppedStep': (phase.status == 'Current' && isStopRequested) || phase.status == 'Stopped', 'pastStep': phase.status == 'Finished', 'futureStep': phase.status == 'Starts'}" 
                                class="step margin-right--small u-display--flex u-justify-content--center">{{i + 1}}</div>
                            <div class="u-display--flex phaseText"><p class="margin-right--smaller">{{phase.frequencyDescription}}  </p><p *ngIf="phase.duration"> for {{phase.duration.period}} {{phase.duration.units}}</p></div>
                            <div class="u-display--flex u-flex-direction--row phaseText phaseDates"> 
                                <p class="margin--none" *ngIf="phase.status == 'Finished'">Finished:</p>
                                <p class="margin--none" *ngIf="phase.status == 'Current' && !isStopRequested">Started:</p>
                                <p class="margin--none stopped-text" *ngIf="phase.status == 'Current' && isStopRequested"> Stopped by  {{this.currentUser.firstName + ' ' + this.currentUser.lastName}} : </p>
                                <p class="margin--none stopped-text" *ngIf="phase.status == 'Stopped'"> Stopped by  {{ phase.stoppedBy.firstName + ' ' + phase.stoppedBy.lastName}} : </p>
                                <p class="margin--none" *ngIf="phase.status == 'Starts'">Starts:</p>
                                <div class="u-display--flex u-flex-direction--row phaseText phaseDates" *ngIf="phase.status != 'Stopped'">
                                    <p class="margin-left--smaller">{{formatDate(phase.startDate)}} </p>
                                    <p class="margin-leftRight--small">-</p>
                                    <p *ngIf="phase.finishDate && !isStopRequested">{{formatDate(phase.finishDate)}}</p>
                                    <p *ngIf="!phase.finishDate && !isStopRequested" class="u-italic u-opacity--light">no end date</p>
                                    <p *ngIf="isStopRequested"> {{getCurrentDate()}} </p>                                </div>
                                <div class="u-display--flex u-flex-direction--row phaseText phaseDates" *ngIf="phase.status == 'Stopped'">
                                    <p class="margin-left--smaller">{{formatDate(phase.startDate)}} </p>
                                    <p class="margin-leftRight--small">-</p>
                                    <p *ngIf="phase.finishDate">{{formatDate(phase.finishDate)}}</p>
                                    <p *ngIf="phase.stoppedAt">{{formatDate(phase.stoppedAt)}}</p>
                                    <p *ngIf="!phase.finishDate" class="u-italic u-opacity--light">no end date</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isLast && (phase.status != 'Current' || !isStopRequested)" class="verticalLine"></div>
                </div>
                </div>
            </div>
        </div>
        <div class="u-display--flex u-flex-direction--row container u-width--fit-content margin-left--large">
            <p *ngIf="!isStopped"> <a  (click)="stopRequest(true)"> Stop </a> </p>
            <p *ngIf="!isStopped" class="margin-leftRight"> | </p>
            <p> <a  (click)="close(true)"> Switch to Custom Schedule </a> </p>
        </div>
    </div>
    <div class="u-display--flex u-justify-content--right footer">
        <button class="button is-borderless margin-right--small" (click)="close(false)">Cancel</button>
        <button class="button is-primary" (click)="onSave()">
            <span>Save</span>
        </button>
    </div>
</div>