<div>
  <div class="progress-container margin-right">
    <div class="progress" *ngIf="!process?.status?.isComplete && !process?.status?.isFailed">
      <span class="progress-bar" [ngStyle]="{'width': process?.status?.completionPercentage + '%'}"></span>
    </div>
    <ng-container>
      <ng-template #completed></ng-template>
    </ng-container>
    <div *ngIf="process?.status?.isFailed" class="u-color--danger">
      There was an error processing this request
    </div>
  </div>
  <coach-icon class="u-cursor--pointer u-opacity--lighter" iconName="x" (click)="remove()"></coach-icon>
</div>