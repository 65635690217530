import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { forkJoin } from 'rxjs';
import { PatientService, ITrackApiPatient } from '@shared/services/patient/patient.service';
import { Patient } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import moment from 'moment';
import {
  HccVaccination,
  HccHealthMaintenanceDetails,
  HccCodingOpportunity,
} from '@shared/models/hcc-care-gaps';
import { AuditService } from '@shared/services/audit-service/audit.service';
import {
  SubComponentId,
  ComponentId,
  ActionId,
} from '@shared/models/audit-constants';
import { CareFeatureConstants } from "@shared/models/module-constants";
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';

@Component({
  selector: 'patient-care-gaps',
  templateUrl: './patient-care-gaps.component.html',
})
export class PatientCareGapsComponent implements OnInit, OnChanges {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input () public tp : ITrackApiPatient; 
  @Input() public mobileScreenSize: boolean;

  public loading = true;

  private get tierId() {
    return this?.tier?.selectedTierId;
  }
  private get tierNum() {
    return this?.tier?.tier;
  }

  public facesheet: any = {};
  public maintenance: any = {};
  public costUtilization: any = {};
  public preventive: any = {};
  public maintenanceDetails: any[] = [];

  public isLoaded = false;
  acpCategory = 'ACP';
  awvCategory = 'AWV';
  ccmCategory = 'CCM';

  currentVaccinations: HccVaccination[] = [];
  acpDetails: HccHealthMaintenanceDetails;
  awvDetails: HccHealthMaintenanceDetails;
  ccmDetails: HccHealthMaintenanceDetails[];

  chronicOpportunities: HccCodingOpportunity[] = [];
  acuteOpportunities: HccCodingOpportunity[] = [];
  chronicInteractions: HccCodingOpportunity[];
  totalChronicWeight: number;
  isChronicLoaded: boolean;
  isAcuteLoaded: boolean;
  today: moment.Moment;
  showDetails: boolean = true;
  FacesheetUIUpdatesFeatureFlag: string = CareFeatureConstants.FacesheetUIUpdatesFlag;
  FacesheetV28: string = CareFeatureConstants.FacesheetV28;

  progress: number = 75;
  constructor(
    private _patientService: PatientService,
    private auditService: AuditService,
    private featureFlagService: FeatureFlagService
  ) {
    this.today = moment();
  }

  ngOnInit(): void {
    if (this.patient) this.loading = true;
  }

  ngOnChanges() {
    this.loadHealthData();
  }
  private OnDataReceived() {
    this.currentVaccinations = [];
    this.currentVaccinations.push({
      name: 'Colonoscopy Screening',
      receivedDate: this.preventive.colonoscopy,
    });
    this.currentVaccinations.push({
      name: 'Flu Vaccine',
      receivedDate: this.preventive.fluVac,
    });
    this.currentVaccinations.push({
      name: 'Pneumococcal Vaccine',
      receivedDate: this.preventive.pneumococcalVac,
    });
    let mammogram: HccVaccination = {
      name: 'Mammogram',
      receivedDate: this.preventive.mammogram,
    };
    if (this.facesheet.gender == 'Female')
      this.currentVaccinations.unshift(mammogram);
    this.getDetails();
    this.filterCodingOpportunties();
  }
  private getDetails() {
    this.acpDetails = this.maintenanceDetails.find(
      (y) => y.category == this.acpCategory
    );
    this.awvDetails = this.maintenanceDetails.find(
      (y) => y.category == this.awvCategory
    );
    this.ccmDetails = this.maintenanceDetails.filter(
      (y) => y.category == this.ccmCategory
    );
  }
  filterInteractions(
    opportunities: HccCodingOpportunity[]
  ): HccCodingOpportunity[] {
    let interactions: HccCodingOpportunity[] = [];
    interactions = opportunities.filter((x) => x.isInteraction);
    interactions.forEach((x) => {
      const i = opportunities.indexOf(x);
      opportunities.splice(i, 1);
    });
    return interactions;
  }

  private filterCodingOpportunties() {
    this.totalChronicWeight = this.chronicOpportunities.reduce(
      (prev, next) => prev + next.weight,
      0
    );
    this.chronicInteractions = this.filterInteractions(
      this.chronicOpportunities
    );
  }
  private loadHealthData() {
    if (this.patient && !this.featureFlagService.hasFeatureFlag(this.FacesheetUIUpdatesFeatureFlag)) {
      if (this.patient?.trackPatient?.chPatId) {
        this.isLoaded = false;
        forkJoin({
          reqFacesheet: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            ''
          ),
          reqHealthMaintenance: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'healthMaintenance'
          ),
          reqCostUtilization: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'costUtilization'
          ),
          reqPreventive: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'preventive'
          ),
          reqHealthMaintenanceDetails:
            this._patientService.getTrackPatientFacesheet(
              this.patient.trackPatient.chPatId,
              'healthMaintenanceDetails'
            ),
          reqChronicOpportunities:
            this._patientService.getTrackPatientFacesheet(
              this.patient.trackPatient.chPatId,
              'opportunities',
              true
            ),
          reqAcuteOpportunities: this._patientService.getTrackPatientFacesheet(
            this.patient.trackPatient.chPatId,
            'opportunities',
            false
          ),
        }).subscribe(
          ({
            reqFacesheet,
            reqHealthMaintenance,
            reqCostUtilization,
            reqPreventive,
            reqHealthMaintenanceDetails,
            reqChronicOpportunities,
            reqAcuteOpportunities,
          }) => {
            this.facesheet = reqFacesheet || {};
            this.maintenance = reqHealthMaintenance || {};
            this.costUtilization = reqCostUtilization || {};
            this.preventive = reqPreventive || {};
            this.maintenanceDetails = reqHealthMaintenanceDetails || [];
            this.chronicOpportunities = reqChronicOpportunities || [];
            this.acuteOpportunities = reqAcuteOpportunities || [];
          },
          (errors) => {
            console.log('ERROR =>', errors);
            this.isLoaded = true;
          },
          () => {
            this.isLoaded = true;
            this.OnDataReceived();
          }
        );
        this.auditService.auditLog(
          'Patient Care Gaps',
          ComponentId.PatientCareGaps,
          SubComponentId.PatientDetail,
          ActionId.PHIAccess,
          this.patient.chPatId,
          this.tier,
          null
        );
      } else {
        this.isLoaded = false;
      }
      this.auditService.auditLog(
        'Patient Care Gaps',
        ComponentId.PatientCareGaps,
        SubComponentId.PatientDetail,
        ActionId.PHIAccess,
        this.patient.chPatId,
        this.tier,
        null
      );
    }
  }

  public getDateDifference(date: string): string {
    let years =
      this.today.diff(date, 'years', false) > 0
        ? `${this.today.diff(date, 'years', false)}yr`
        : '';
    let months =
      this.today.diff(date, 'months', false) % 12 > 0
        ? `${this.today.diff(date, 'months', false) % 12}mo`
        : '';
    let days =
      this.today.diff(date, 'months', false) < 1
        ? `${this.today.diff(date, 'days', false) % 30} days`
        : '';
    return `${years}${
      years.length > 0 && months.length > 0 ? ', ' : ''
    }${months}${days}`;
  }

  public formatAcuteOpportunity(
    acuteOpportunity: HccCodingOpportunity
  ): string {
    return !acuteOpportunity.isInteraction
      ? `${acuteOpportunity.hccId} - ${acuteOpportunity.hccDescription}`
      : `${acuteOpportunity.hccDescription}`;
  }
}
