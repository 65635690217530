import { LogError } from '../../services/logging/log-error';

export class CoachException extends Error {

     /** Custom exception used to log errors to the CoachSQL database */
     constructor(error: LogError, message?: string) {
          super(message == null ? error.errorDescription : message);
          this.logError = error;
     }

     logError: LogError;
}