import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { IView, Views } from '@shared/models/views';
import { HelpPanelService } from '@shared/services/help-panel/help-panel.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Access } from '@shared/services/graphql/access.service';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { ModuleConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { takeUntil } from 'rxjs/operators';
import { MixpanelService } from '@shared/services/mixpanel/mixpanel.service';

@Component({
  selector: 'coach-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
})
export class SidebarMenuComponent implements OnInit, OnDestroy {
  @Input() isSidebarOpen: boolean;
  @Output() itemClicked: EventEmitter<any> = new EventEmitter();

  public breakpointObserver;
  public isFloatingMenu: boolean;

  public views: IView[];
  public menuItems: IView[];
  public helpItem: IView;

  public isHelpPanelOpen$: BehaviorSubject<boolean>;
  private destroy$ = new Subject<void>();

  constructor(
    private _helpPanelService: HelpPanelService,
    private _breakpointObserver: BreakpointObserver,
    private hierarchyTierService: HierarchyTierService,
    private userAccessService: UserAccessService,
    private featureFlagService: FeatureFlagService,
    private mixpanelService: MixpanelService
  ) {
    this.isHelpPanelOpen$ = this._helpPanelService.isHelpPanelOpen$;
    this.breakpointObserver = this._breakpointObserver
      .observe(['(max-width: 650px)'])
      .subscribe((result) => {
        if (result.matches) {
          this.isFloatingMenu = true;
        } else {
          this.isFloatingMenu = false;
        }
      });
  }

  ngOnInit(): void {
    this.views = Views;
    this.helpItem = {
      label: 'Get Help',
      icon: 'help-circle',
      path: null,
      urlSegment: null, // doesn't route
      moduleId: null
    };

    this.menuItems = [...this.views, this.helpItem];

    this.hierarchyTierService.currentTier$
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentTier) => {
        if (currentTier != null) {
          this.views.forEach((item) => {
            const tierId = currentTier.selectedTierIdForDisplay;
            item.urlSegment = `/${currentTier.abbreviation}/${tierId}/${item.path}`;
          });
        }
      });

    this.userAccessService.currentAccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((access) => {
        this.getAccess(access);
      });
  }

  private getAccess(access: Access): void {
    let views: IView[] = [];
    if (access != null) {
      views = this.views.filter((view) => {
        // If hide is set on the views
        if (view.hideIfFeatureFlag) {
          return !this.featureFlagService.hasFeatureFlag(
            view.hideIfFeatureFlag
          );
        }

        let giveAccess = false;
        if (access?.modules != null) {
          for (let idx = 0; idx < access.modules.length; idx++) {
            if (view.hasPHI && !access.phi) {
              giveAccess = false;
              break;
            }
            if (
              view.moduleId === ModuleConstants.HomeModule ||
              view.moduleId === access.modules[idx].moduleId
            ) {
              giveAccess = true;
              break;
            }
          }
          if (view.featureFlag && giveAccess) {
            giveAccess = this.featureFlagService.hasFeatureFlag(
              view.featureFlag
            );
          }

          return giveAccess;
        } else {
          return true;
        }
      });
    }

    this.menuItems = [...views, this.helpItem];
  }

  toggleHelpPanelOpen() {
    this._helpPanelService.toggleHelpPanelOpen();
  }

  menuItemClicked(e:Event, item) {
    const id = (e.target as HTMLElement).id;
    this.mixpanelService.track(id, { item });
    if (this.isSidebarOpen && this.isFloatingMenu) {
      this.isSidebarOpen = !this.isSidebarOpen;
      this.itemClicked.emit(this.isSidebarOpen);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
