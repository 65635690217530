import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@shared/services/patient/patient.service';
import { Patient } from '@shared/models/patient';
import {Activity, Detail} from '@shared/models/activity';
import { DatePipe } from '@angular/common';
import {UserActivity, Login, ReadSession} from '@shared/models/user-activity';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { wpapi } from "@hcd-caravanhealth/pkg-wptypes";

@Component({
  selector: 'coach-patient-activity',
  templateUrl: './patient-activity.component.html',
  styleUrls: ['./patient-activity.component.scss']
})

export class PatientActivityComponent implements OnInit {
  @Input() public patient: Patient & { activities?: Array<any>};
  @Input() public tier: IHierarchyTier;
  constructor(
    private _patientService: PatientService,
    private _route: ActivatedRoute) {
  }

  private get tierId() {
    return this?.tier?.selectedTierId;
  } 
  ngOnInit(): void { 

  }

}
