<!-- Reminder History -->
<ng-container *ngIf="patient && patient.notifications">
    <div class="widget margin--large u-height--fit-content">
        <div class="header">
            <div class="titles">
                <div class="title">
                    Reminder History
                </div>
            </div>
        </div>
        <div class="dynamicBody padding">
            <ag-grid-angular row-animation class="ag-theme-material"
                [defaultColDef]="defaultColDef"
                [rowData]="patient.notifications" [columnDefs]="columnDefs" [overlayNoRowsTemplate]="'&lt;p&gt;No data&lt;/p&gt;'"
                (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
                (rowClicked)="onRowClicked($event)" [pagination]="true" [paginationPageSize]="5"   [domLayout]="domLayout"
                [sortingOrder]="['asc','desc',null]">
            </ag-grid-angular>
        </div>
    </div>
    
    <!-- How to Use -->
    <div class="widget margin--large u-height--fit-content" >
        <div class="header">
            <div class="titles">
                <div class="title u-display--flex">
                    <coach-icon iconName="info" class="margin-right--small"></coach-icon>
                    How to use
                </div>
            </div>
        </div>
        <div class="dynamicBody padding">
            Use the Wellpepper Reminder Composer Form to manually send a reminder to a patient.
            <br>
            <b>Reminder:</b> information sent through email or text Notification is not secure, and details of the
            patient's
            treatment should not be included in the Notification.
        </div>
    </div>
    
    <!-- <div class="u-display--flex u-flex-direction--row  margin--large"  [ngClass]="{'u-flex-wrap--wrap': mobileScreenSize}">
        <div class="dynamicBody " [ngClass]="{'margin-bottom--large u-width--full': mobileScreenSize, 'margin-right--small widget_wrapper': !mobileScreenSize}">
            <div class="widget u-height--full">
                <div class="header">
                    <div class="titles">
                        <div class="title u-display--flex">
                            <coach-icon iconName="info" class="margin-right--small"></coach-icon>
                            How to use
                        </div>
                    </div>
                </div>
                <div class="dynamicBody padding">
                    Use the Wellpepper Reminder Composer Form to manually send a reminder to a patient.
                    <br>
                    <b>Reminder:</b> information sent through email or text Notification is not secure, and details of the
                    patient's
                    treatment should not be included in the Notification.
                </div>
            </div>
        </div> 
        <div class="dynamicBody" [ngClass]="{'u-width--full': mobileScreenSize, 'widget_wrapper': !mobileScreenSize}">
            <div class="widget u-height--fit-content">
                <div class="header">
                    <div class="titles">
                        <div class="title">
                            Manually Record Patient Communication
                        </div>
                    </div>
                </div>
                <div class="dynamicBody padding">
                    <div class="u-display--flex u-flex-direction--column">
                        <label for="textInput">Notes</label>
                        <textarea id="textInput" [(ngModel)]="manualRecordToLog" rows="6"
                            placeholder="Record phone call notes, email sent through your own mail, or other external patient communications here. It will be added to the log on this page."
                            class="u-width--full"></textarea>
                    </div>
                    <div class="u-width--full u-display--flex u-justify-content--right">
                        <button [disabled]="manualRecordToLog.length < 1" (click)="sendManualLog()"
                            class="button is-primary is-small margin-top--small">Add to Log</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!--  Wellpepper Reminder Composer -->
    <div class="widget margin--large u-height--fit-content">
        <div class="header">
            <div class="titles">
                <div class="title">
                    Wellpepper Reminder Composer
                </div>
            </div>
        </div>
        <div class="u-display--flex u-flex-direction--row u-flex-wrap--wrap">
            <!-- new Notification form -->
            <div class="dynamicBody padding" style="min-width: 400px; width: 50%;">
                <div class="margin-bottom--small">
                    <label for="modeButtons">Mode</label>
                    <div id="modeButtons" class="u-display--flex margin-top--smallest">
                        <div class="margin-right--small">
                            <input type="radio" id="modeButtonEmail"
                                [(ngModel)]='newNotification.method'
                                [disabled]="!patient.email"
                                class="u-width--full"
                                value='email'>
                            <label for="modeButtonEmail">Email</label>
                        </div>
                        <div class="margin-right--small">
                            <input type="radio" id="modeButtonText" [disabled]="!patient.phoneNumber" [(ngModel)]='newNotification.method'
                             class="u-width--full"
                            value='sms'>
                            <label for="modeButtonText">Text</label>
                        </div>
                    </div>
                </div> 
                <div class="margin-bottom--small">
                    <label for="fromInput">From</label>
                    <div id="fromInput" class="u-display--flex margin-top--smallest">
                        <input [disabled]="true" [value]="newNotification.method == 'email' ? getPatientProfessionalNameAndOrgName() : '(Coach\'s SMS #)' " type="text" class="u-width--full">
                    </div>
                </div> 
                <div class="margin-bottom--small" *ngIf="newNotification.method == 'email'">
                    <label for="toInputEmail">To</label>
                    <div id="toInputEmail" class="u-display--flex margin-top--smallest">
                        <input [disabled]="true" [(ngModel)]="patient.email" type="text" class="u-width--full">
                    </div>
                </div> 
                <div class="margin-bottom--small" *ngIf="newNotification.method == 'sms'">
                    <label for="toInputSMS">To</label>
                    <div id="toInputSMS" class="u-display--flex margin-top--smallest">
                        <input [disabled]="true" [(ngModel)]="patient.phoneNumber" type="text" class="u-width--full">
                    </div>
                </div> 
                <div class="margin-bottom--small" *ngIf="newNotification.method == 'email'">
                    <label for="subjectInput">Subject</label>
                    <div id="subjectInput" class="u-display--flex margin-top--smallest">
                        <input [(ngModel)]="newNotification.subject" type="text" class="u-width--full">
                    </div>
                </div> 
                <div class="u-display--flex u-flex-direction--column">
                    <label for="textInput">Message</label>
                    <textarea id="textInput" *ngIf="newNotification.method == 'email'" [(ngModel)]="newNotification.text" rows="6" class="u-width--full"></textarea>
                    <textarea id="textInput" *ngIf="newNotification.method == 'sms'" [(ngModel)]="newNotification.text" maxlength="160" rows="6" class="u-width--full"></textarea>
                </div> 
                <div class="u-width--full u-display--flex u-justify-content--spaceBetween">
                    <span>
                        <span class="u-font-size--small u-color--brand--2" 
                            [class.u-color--brand--3]="newNotification.text.length > 160"
                            *ngIf="newNotification.method == 'sms'">{{newNotification.text?.length?newNotification.text.length:0}}/160 characters</span>
                    </span>
                    <button [disabled]="!isNewNotificationValid()" (click)="sendNotification()"
                        class="button is-primary is-small margin-top--small">Send</button>
                </div>
                <div>
                </div>
            </div>
    
            <!-- variable substitution help -->
            <div class="dynamicBody padding" style="min-width: 400px; width: 50%;">
                <div class="accordion list border u-width--full" [class.is-open]="newNotification.variableSubstitutionsAccordionIsOpen">
                    <div class="subheader">
                        <div class="row u-center u-justify-content--spaceBetween">
                            <div class="u-display--flex u-flex-direction--row u-align-items--center u-text-transform--capitalize">
                                <coach-icon iconName="info" class="margin-right--small"></coach-icon>
                                Variable Substitutions
                            </div>
                            <coach-icon iconName={{getChevronStatus()}}
                                class="u-cursor--pointer"
                                (click)="newNotification.variableSubstitutionsAccordionIsOpen = !newNotification.variableSubstitutionsAccordionIsOpen"
                                iconColor="currentColor">
                            </coach-icon>
                        </div>
                    </div>
                    <div class="content content-planRow shadowLess">
                        You can use variables in this reminder mail, which will be substituted by the
                        server when the Notification is sent. Here are some variables you might like to use:
                        <ul>
                            <li><code ngNonBindable>{{recipient.fullName}}</code> is the full name of the recipient. You can also use:<ul>
                                    <li><code ngNonBindable>{{recipient.firstName}}</code> </li>
                                    <li><code ngNonBindable>{{recipient.lastName}}</code> </li>
                                </ul>
                            </li>
                            <li><code ngNonBindable>{{sender.fullName}}</code> is the full name of the assigned professional. Similarly, you can use:<ul>
                                    <li><code ngNonBindable>{{sender.firstName}}</code></li>
                                    <li><code ngNonBindable>{{sender.lastName}}</code></li>
                                </ul>
                            </li>
        
                            <li><code ngNonBindable>{{sender.organizationName}}</code> is the the assigned professional's organization
                                name </li>
                            <li><code ngNonBindable>{{link_outcomes}}</code> generates a link to the outcome survey site which allows
                                the user to get one-click access to their outcome surveys (e.g. without needing to enter their username
                                and temporary password).</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
      

</ng-container>





<!-- view notification modal -->
<ng-container *ngIf="modalViewNotification && selectedNotification">
    <div class="overlay is-open" role="modal-background">
        <div class="modal u-width--largest">
            <div class="header">
                <h4 class="margin--none text-break">
                    <span *ngIf="selectedNotification.method == 'email'">{{selectedNotification.subject}}</span>
                    <span *ngIf="selectedNotification.method == 'sms'">SMS</span>
                    <span *ngIf="selectedNotification.method == 'manual'">Manual</span>
                </h4>
            </div>
            <div class="dynamicBody padding text-break" [innerHTML]="selectedNotification.method == 'email' && selectedNotification.html ? selectedNotification.html : splitStringByLines(selectedNotification.text)">
            </div>
            <div class="footer">
                <button class="button margin-right border--none" (click)="modalViewNotification = false">Close</button>
            </div>
        </div>
    </div>
</ng-container>