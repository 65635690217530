/* tslint:disable */
/* eslint-disable */

/**
 * ChangeTypes
 */
export enum ChangeTypes {
  Create = 'Create',
  Update = 'Update',
  Rollback = 'Rollback',
  Edit = 'Edit'
}
