// In home AWV status and file disposition
//

export interface PatientIHEFilesNote {
  note: string; // "file status comment",
  createdByUserId: number; // 12345,
  createdByName: string; // "Nora Nurse"
  createdAt: Date
}

/**
 * Represents an instance of an in home AWV
 * Patient:Visit is a 1:many relationship
 */
export interface PatientIHEVisit {
  /** Read-only from the UI - primary key */
  visitId: string;
  /** Evaluation ID from Signify IHE - not needed to show in the UI at this point */
  evaluationId: string;
  /** Read-only from the coach UI */
  visitDate: string; // YYYY-MM-DD
  /** Relaxed typing on codes & status which may change */
  visitStatusCode?: number;
  visitStatus?: string;
  visitStatusDescription?: string;
  /** Disposition for the files related to a visit - read-only from the coach-web perspective */
  fileInfo: Array<PatientIHEFileInfo>;
  /** OPEN - notes are scoped to the visit/evaluation, NOT specifically to the files list */
  notes: Array<PatientIHEFilesNote>;
}

/** A file that is output for a visit */
export interface PatientIHEFileInfo {
  fileId: string; // TODO - what exactly should this be?
  fileType: PatientIHEFileType;
  /** TODO could make this an enumerated type */
  fileTypeId: number;
  fileTypeCaption: string;
  fileName: string;
  /** Full URL in blob storage */
  filePath: string;
  batchID: number;
  /** Whether the file has been downloaded */
  downloadInfo?: {
    current?: PatientIHEFileDownloadInfo;
    history?: Array<PatientIHEFileDownloadInfo>;
  };
  availabilityInfo?: {
    current?: PatientIHEFileAvailabilityInfo;
    history?: Array<PatientIHEFileAvailabilityInfo>;
  },
  fileDownloadStatus?: fileStatus
}

export declare type PatientIHEFileType =
  "Assessment" | // WAS "assessment" |
  "PPP" | // WAS "ppp" |
  "PCP" | // WAS "summary" |
  "Casemgmt" // WAS "cmr"
;

export const AssessmentFileTypes:PatientIHEFileType[] = ["Assessment", "PPP", "PCP"];
export const PatientIHEFileTypeLabels:{[key in PatientIHEFileType]:string} = {
  "Assessment" : "Assessment",
  "PPP" : "Personal Prevention Plan",
  "PCP" : "PCP Summary",
  "Casemgmt": "Case Management Referral"
}

export interface PatientIHEFileDownloadInfo {
  /** ISO8601 */
  downloadedAtUtc: string;
  downloadedByName: string;
  downloadedByUserId: number;
}

export interface PatientIHEFileAvailabilityInfo {
  /** ISO8601 */
  statusDateUtc: string;
  statusDescription: string;
  statusUpdatedByName: string;
}

export declare type fileStatus = 'New' | 'Downloading' | 'Downloaded';
