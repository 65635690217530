import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {Patient} from '@shared/models/patient';
import {AwvWorkflow} from '@api/care-orch/models/awv-workflow';
import {SchedulingActivity} from '@api/care-orch/models/scheduling-activity';
import {OutcomeType} from '@api/care-orch/models/outcome-type';
import {ScheduledOutcome} from '@api/care-orch/models/scheduled-outcome';
import {ScheduledProperties} from '@api/care-orch/models/scheduled-properties';
import {PracticeService} from '@shared/services/hierarchy/practice.service';
import {Activity} from '@api/care-orch/models/activity';
import {Outcome} from '@api/care-orch/models/outcome';
import {ActivityType} from '@api/care-orch/models/activity-type';
import { AwvWorkflowService } from '@shared/services/awv-workflow/awv-workflow.service';
import { OnChanges } from '@angular/core';
import { WorkflowEventsService } from '@shared/services/mixpanel/events/workflow-events.service';

@Component({
  selector: 'coach-patient-step-scheduled',
  templateUrl: './patient-step-scheduled.component.html',
  styleUrls: ['./patient-step-scheduled.component.scss']
})
export class PatientStepScheduledComponent implements OnInit, OnChanges{
  @Input() public mobileScreenSize: boolean;
  @Input() public patient: Patient;
  @Input() sidebarMode?: boolean;
  @Input() public activityStatus: string;
  @Input() public awvWorkflow: AwvWorkflow;
  @Output() public activitySaved: EventEmitter<Activity> = new EventEmitter();
  @Output() public activityDeleted: EventEmitter<ActivityType> = new EventEmitter();

  isTabOpen = false;
  scheduledOutcomeProperties = {} as ScheduledProperties;
  practiceName = '';

  constructor(private practiceService: PracticeService,
              private awvWorkflowService: AwvWorkflowService,
              private workflowEvents: WorkflowEventsService) {}

  ngOnInit(): void {
    this.awvWorkflowService.updateSuccessful$.subscribe(() => this.isTabOpen = false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.awvWorkflow){
      const schedulingActivities = this.awvWorkflow.completedActivities
        .filter( x  => x.type === 'SchedulingActivity').map(x => x as SchedulingActivity);
      const scheduledOutcome = schedulingActivities.find( x => x.outcome.type === OutcomeType.ScheduledOutcome).outcome as ScheduledOutcome;
      this.scheduledOutcomeProperties = scheduledOutcome.properties;
      if (scheduledOutcome.properties.practiceId) {
        this.practiceService.getPracticeById(this.patient, scheduledOutcome.properties.practiceId).then(p => this.practiceName = p);
      }
    }
  }

  outcomeSaved(outcome: Outcome): void {
    const activity = {
      type: 'SchedulingActivity',
      outcome
    } as SchedulingActivity;

    if (this.scheduledOutcomeProperties) {
      this.workflowEvents.trackEdit(activity);
    } else {
      this.workflowEvents.trackSave(activity);
    }

    this.activitySaved.emit(activity);
  }

  deleteSchedulingActivity(): void {
    this.activityDeleted.emit(ActivityType.SchedulingActivity);
  }
}


