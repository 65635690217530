import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'spacesToDashes'
})
export class SpacesToDashesPipe implements PipeTransform {
  transform(text: string): string {
    if (text && text.length) {
      let output = text.toLowerCase();
      output = output.replace(/(^\s+|[^a-zA-Z0-9 ]+|\s+$)/g,"");
      output = output.replace(/\s+/g, "-");
      return output;
    } else {
      return null;
    }
  }
}