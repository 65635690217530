import { Component, Input } from '@angular/core';

/**
 * Used to display a loading indicator overlaid on top of child elements. 
 * Set position relative on a parent element to position the loading indicator in the desired location.
 *
 * @example 
 * <div class="u-position--relative">
 *  <coach-loading-container [isLoading]="isLoading">
 *     ...child elements
 *  </coach-loading-container>
 * </div>
 */
@Component({
  selector: 'coach-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss']
})
export class LoadingContainerComponent {

  /** Whether or not to display the loading indicator */
  @Input() isLoading = false;

  @Input() containerCss = '';

  constructor() { }

}
