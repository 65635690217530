import { NgModule } from '@angular/core';
import { PRESET_ITEMS } from './utils';
import { AllPatientsPreset } from './general';
import {
  ClinicalProgramCopdPreset,
  ClinicalProgramHeartFailurePreset,
  ClinicalProgramHypertensionPreset
} from './clinical-program';
import {
  EdUtilizationPreset,
} from './cohorts';
import {
  AwvPatientsWorklistPreset,
  HccListPreset,
  AwvBillingPreset,
  AwvFilesPreset,
  AwvSchedulePreset,
  FacesheetPreset
} from './annual-wellness-visits'
import {
  PacNavigatorProgramPreset,
} from './post-acute-care';
import {
  CareManagementAllProgramPreset,
  CareManagementCopdPreset,
  CareManagementHeartFailurePreset,
  CareManagementHypertensionPreset,
  SmsOutreachProgramPreset
} from './chronic-conditions';
import { ActiveAlertsPreset } from './alerts';
import { ActiveAlertsAndMessagesPreset, UnreadMessagesPreset } from './alerts-and-messages';

@NgModule({
  providers: [
    {
      provide: PRESET_ITEMS,
      useExisting: AwvPatientsWorklistPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: AwvSchedulePreset,
      multi: true
    },
    { provide: PRESET_ITEMS, useExisting: AllPatientsPreset, multi: true },
    {
      provide: PRESET_ITEMS,
      useExisting: ClinicalProgramHeartFailurePreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: AwvFilesPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: AwvBillingPreset,
      multi: true
    },
    { provide: PRESET_ITEMS, useExisting: FacesheetPreset, multi: true },
    {
      provide: PRESET_ITEMS,
      useExisting: CareManagementAllProgramPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: CareManagementCopdPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: CareManagementHeartFailurePreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: CareManagementHypertensionPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: ClinicalProgramCopdPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: ClinicalProgramHypertensionPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: EdUtilizationPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: HccListPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: SmsOutreachProgramPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: PacNavigatorProgramPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: ActiveAlertsPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: ActiveAlertsAndMessagesPreset,
      multi: true
    },
    {
      provide: PRESET_ITEMS,
      useExisting: UnreadMessagesPreset,
      multi: true
    }
  ]
})
export class PresetGroupModule {}
