import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { setContext } from '@apollo/client/link/context';

import { environment } from 'src/environments/environment';

export function createApollo(httpLink: HttpLink, authService: AuthService): ApolloClientOptions<any> {
  const auth = setContext((operation, context) => {
    return {
      headers: {
        Authorization: `Bearer ${authService.getToken()}`
      }
    };
  });

  const link = ApolloLink.from([auth, httpLink.create({ uri: `${environment.coachApiUrl}/graphql` })]);

  return {
    link,
    cache: new InMemoryCache()
  };
}
@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService]
    }
  ]
})
export class GraphQLModule {}
