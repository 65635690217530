import { Component, Inject, InjectionToken, Output, OnInit, EventEmitter } from '@angular/core';

export interface NavigationPortalData {
  emitter: any;
}
export const NAVIGATION_PORTAL_DATA = new InjectionToken<NavigationPortalData>('NAVIGATION_PORTAL_DATA');

interface TierInfo {
  hierarchyTier: any;
  parentId: string;
}
@Component({
  selector: 'patient-time-tracking-dropdown',
  templateUrl: './patient-time-tracking-dropdown.component.html',
  styleUrls: ['./patient-time-tracking-dropdown.component.scss'],
  host: {
    class: 'scopeSelectDropdownWrapper'
  }
})
export class TimeTrackingDropdownComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  private emitter: any;
  constructor(
    @Inject(NAVIGATION_PORTAL_DATA) data: NavigationPortalData,
  ) {
    this.emitter = data.emitter;
  }

  ngOnInit(): void {
  }
  emitAction(action_number: string): void {
    this.emitter(action_number)
  }
}
