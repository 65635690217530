<ng-container [formGroup]="parentForm">
  <div [formGroupName]="questionInfo.name" class="u-background-color--white">
    <div class="submitFormBlock-header">
      <div class="row">
        <h6 class="margin--none u-fill u-opacity--light">
          {{questionInfo.measureId}} | Max: N/A
          <!-- {{questionInfo.pointsText}}: {{maxPoints}} Points -->
        </h6>
        <coach-icon class="icon--selectable margin-left--smaller" iconName="info" iconSize="small" iconColor="primary"
          (click)="toggleInfoText()"></coach-icon>
      </div>
      <div class="u-bold margin-top--smallest"> {{questionInfo.description}}</div>
    </div>
    <ng-container formArrayName="FormQResponses">
      <div class="submitFormBlock-main" [formGroupName]="0">
        <div class="button-group is-primary">
          <div class="group">
            <input type="radio" id="pi-{{questionInfo.name}}-radio1" [value]="no" formControlName="UserResponse">
            <label for="pi-{{questionInfo.name}}-radio1">No</label>
          </div>
          <div class="group">
            <input type="radio" id="pi-{{questionInfo.name}}-radio2" [value]="willComplete" formControlName="UserResponse" >
            <label for="pi-{{questionInfo.name}}-radio2">Will Complete</label>
          </div>
          <div class="group">
            <input type="radio" id="pi-{{questionInfo.name}}-radio3" [value]="complete" formControlName="UserResponse">
            <label for="pi-{{questionInfo.name}}-radio3">Completed</label>
          </div>
        </div>
        <div class="row margin-top--large"
          *ngIf="questionInfo.promptQuarter == true &&(parentForm.controls[questionInfo.name]?.get('FormQResponses.0.UserResponse')?.value === willComplete ||
          parentForm.controls[questionInfo.name]?.get('FormQResponses.0.UserResponse')?.value === 'WillCompleteinQ1' ||
          parentForm.controls[questionInfo.name]?.get('FormQResponses.0.UserResponse')?.value === 'WillCompleteinQ2' ||
          parentForm.controls[questionInfo.name]?.get('FormQResponses.0.UserResponse')?.value === 'WillCompleteinQ3' ||
          parentForm.controls[questionInfo.name]?.get('FormQResponses.0.UserResponse')?.value === 'WillCompleteinQ4')">
          <div class="group">
            <div class="label">Will Complete in Quarter</div>
            <div class="field row--wrap margins-inline">
              <div>
                <input value="WillCompleteinQ1" type="radio" id="pi-{{questionInfo.name}}-hie-1-1" formControlName="UserResponse">
                <label for="pi-{{questionInfo.name}}-hie-1-1">Q1</label>
              </div>
              <div>
                <input value="WillCompleteinQ2" type="radio" id="pi-{{questionInfo.name}}-hie-1-2" formControlName="UserResponse">
                <label for="pi-{{questionInfo.name}}-hie-1-2">Q2</label>
              </div>
              <div>
                <input value="WillCompleteinQ3" type="radio" id="pi-{{questionInfo.name}}-hie-1-3" formControlName="UserResponse">
                <label for="pi-{{questionInfo.name}}-hie-1-3">Q3</label>
              </div>
              <div>
                <input value="WillCompleteinQ4" type="radio" id="pi-{{questionInfo.name}}-hie-1-4" formControlName="UserResponse">
                <label for="pi-{{questionInfo.name}}-hie-1-4">Q4</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
