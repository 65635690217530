import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * Component for adding a toggle-able side panel. Uses content projection
 * to specify header and body sections.
 * 
 * @example
 * <coach-toggle-pane
 *                iconName="chevrons-right"
 *                [iconRotates]="true"
 *                [(isOpen)]="isFilterPaneOpen">
 *                <div pane-header>...</div>
 *                <div pane-body>...</div>
 * </coach-toggle-pane>
 */
@Component({
  selector: 'coach-toggle-pane',
  templateUrl: './coach-toggle-pane.component.html',
  host: {
    class: 'coach-toggle-pane'
  }
})
export class CoachTogglePaneComponent implements OnInit {

  public toggledOpenDefault: boolean;

  @Input() public iconName = 'chevrons-right';
  @Input() public iconRotates = true;
  @Input() public isOpen: boolean;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.toggledOpenDefault = this.isOpen;
  }

  paneToggled(): void {
    this.isOpen = !this.isOpen;

    this.isOpenChange.emit(this.isOpen);
  }
}
