<ng-container *ngIf="isSidebarOpen">
  <div class="sidebar-buttons row--wrap">
    <ng-container *ngFor="let item of menuItems">
      <button class="button is-contrast" [attr.data-cy]="'sidebar_button_'+item.label" (click)="menuItemClicked($event, item)" *ngIf="item.urlSegment !== null"
        [routerLink]="item.urlSegment" routerLinkActive="is-active" [routerLinkActiveOptions]="{ exact: false }"
        [attr.aria-label]="'Navigate to ' + item.label" id="sidebarButton_{{item.label}}">
        <coach-icon iconName="{{ item.icon }}" iconColor="currentColor"></coach-icon>
        <div>{{ item.label }}</div>
        <!--  Badge examples  -->
        <ng-container *ngIf="item.label == 'Track'">
          <!-- Hidden per COM-5012, will be implemented later -->
          <!--
          <div class="badges">
            <div class="badge is-warning">23</div>
          </div>
          -->
        </ng-container>
        <ng-container *ngIf="item.label == 'Care'">
          <!-- <div class="badges">
            <div class="badge">3</div>
            <div class="badge u-colors--info">9</div>
          </div> -->
        </ng-container>
      </button>
      <button class="button is-contrast" *ngIf="item.urlSegment === null"
        (click)="toggleHelpPanelOpen(); $event.stopPropagation();">
        <coach-icon iconName="{{ item.icon }}" iconColor="currentColor"></coach-icon>
        <div>{{ item.label }}</div>
      </button>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!isSidebarOpen">
  <ng-container *ngFor="let item of menuItems">
    <ng-container *ngIf="item.label !== 'Home'">
      <div class="sidebar-item" *ngIf="item.urlSegment !== null" routerLink="/{{ item.urlSegment }}"
        routerLinkActive="is-active">
        <div class="sidebar-item-icon">
          <coach-icon iconName="{{ item.icon }}" iconColor="currentColor"></coach-icon>
        </div>
        <div class="sidebar-item-label">{{ item.label }}</div>

        <!--  Badge examples  -->
        <ng-container *ngIf="item.label == 'Track'">
          <!-- <div class="badges">
            <div class="badge is-warning">23</div>
          </div> -->
        </ng-container>
        <ng-container *ngIf="item.label == 'Care'">
          <!-- <div class="badges">
            <div class="badge">3</div>
            <div class="badge u-colors--info">9</div>
          </div> -->
        </ng-container>
      </div>
      <div class="sidebar-item" *ngIf="item.urlSegment === null"
        (click)="toggleHelpPanelOpen(); $event.stopPropagation()">
        <div class="sidebar-item-icon">
          <coach-icon iconName="{{ item.icon }}" iconColor="currentColor"></coach-icon>
        </div>
        <div class="sidebar-item-label">{{ item.label }}</div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
