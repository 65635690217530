<meta name="viewport" content="width=device-width, initial-scale=1">
<div class = "content-body"> 
<div class="scroll">   
    <div class="header">
        <div class="header-rectangle" style="background-color: var(--brand--2);">
            <h2 class="header-title">ACO Board</h2>
            <p class="header-sub-title">
              Each {{app.CompanyName}} Health ACO has its own Board of Directors. The ACO Board is made up of elected members and meets quarterly. 
            </p>            
        </div>
        <img class="img-fluid header-image" src="assets\img\illustrations\SH_PEP_Illustrations_Improve_Sum_ACOBoard_1700x1100_20221206.svg"/>
    </div>

  <br>
  <br>   
  <div class = "description">
    <p>Your ACO board meets to discuss: </p>    
        <ul>
        <li> <p> Admitting communities to, or dismissing communities from, the ACO </p></li>
        <li> <p> Setting criteria by which Shared Savings will be distributed among members </p> </li>
        <li><p>  Agreeing on waivers available to the ACO </p> </li>
        <li> <p> Reviewing progress and determining incentives </p> </li>
        </ul>    
  </div>

  <p style= "padding-left: 5%;">
    While only elected members are invited to participate in board meetings, everyone is invited to listen to meetings and review minutes. In this section, you will find ACO Board materials for review and resources for discussion.
  </p>
<!--
  <table> 
    <tr> 
      <td class = 'item1'> 
        <h4> Most Recent Meeting <br> Date</h4>   
        <p> Here are the documents from the most recent meeting:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>
      
      <td class = 'item2'> 
        <h4> Important Content <br> </h4>   
        <p> Here are some useful links:</p>   
        <ul>
          <li> <a> Item 1 </a></li>
          <li> <a> Item 2 </a> </li>
          <li> <a> Item 3 </a> </li> 
        </ul>   
      </td>

    </tr> 
  
  </table>
-->

</div>  
</div> 


