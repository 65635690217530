/* tslint:disable */
/* eslint-disable */

/**
 * status of a workflow
 */
export enum AwvWorkflowStatuses {
  ReadyForOutreach = 'READY_FOR_OUTREACH',
  OutreachAttempted = 'OUTREACH_ATTEMPTED',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Ineligible = 'INELIGIBLE',
  DeferredToSignify = 'DEFERRED_TO_SIGNIFY',
  Other = 'OTHER'
}
