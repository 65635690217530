import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { PatientEngagement, PatientEngagementComponentIds } from '@shared/enums';
import { PresetCategory, CarePatientItem, CareComponentConstants } from '@shared/models/module-constants';
import { environment } from 'src/environments/environment.coachDev';

@Injectable({
  providedIn: 'root'
})
export class ChronicConditionsChfPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(): IPatientFilterPreset {
    const item: IPatientFilterPreset = {
      category: PresetCategory.ChronicConditions,
      name: CarePatientItem.Chf,
      label: 'CHF',
      diseaseCohortNameFromTrackApi: 'Heart Failure',
      filters: [],
      componentId: CareComponentConstants.PatientDiseaseCohorts,
      hidePreset: true,
      columns: this.getPresetColumns(),
      presetKey: CarePatientItem.Chf
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(): { [key in string]: boolean }[] {
    return [
      { select: true },
      { fullName: true },
      { current_MBI: true },
      { attributedCP: true },
      { phone1: true },
      { phone2: true },
      { dob_age: true },
      { gender: true },
      { assignment: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      {
        actions: this.userAccessService.hasComponent(this.userAccess, PatientEngagementComponentIds)
      },
      { totalSpend: true },
      {
        hccGapCount: this.agGridCustomFilteringFlag
      },
      {
        diseaseCohorts: this.agGridCustomFilteringFlag
      },
      {
        ccmEligible: this.agGridCustomFilteringFlag
      },
      {
        raF_YTD: this.agGridCustomFilteringFlag
      },
      { openRAFWt: true },
      { lastVisit: true },
      { attributedPractice: true },
      { awvTypeDue: true },
      { lastAWVDate: true },
      { edVisits: true },
      { pyRaf: true }
    ];
  }
}
