import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth-service/auth.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocRepo } from '@shared/models/docRepo/list';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import {
  FormResponses,
  FormSubmission,
  FormSubmissionResult,
  SpecialStatusSummary,
  UpdateQualityStatusParams,
  CaravanFormRemediationInfo,
} from '@shared/models/forms/forms-backend';
import { HierarchyTierService } from '../hierarchy/hierarchy-tier.service';
import {
  SubmitFeatureConstants,
  SubmitFeatureToFormId,
} from '@shared/models/module-constants';
import {CaravanFormAdminACO,
  CaravanFormAdminPracticeRespView,
  CaravanFormAdminICC
} from '@shared/models/forms/form-admin-model';
import {CaravanFormSubmissionPeriodOverview,
  CaravanFormSubmissionPeriod,
  CaravanFormAdminSubmitPeriod,
  CaravanFormAdminResponseReport
} from '@shared/models/forms/form-submissionID-model';

@Injectable({
  providedIn: 'root',
})
export class SubmitFormsService implements OnDestroy {
  public generalFolderList$: BehaviorSubject<DocRepo[]>;
  public selectedFolder$: BehaviorSubject<DocRepo>;
  private get headers(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authService.IdToken}`,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    });
  }
  public uploadFolders: DocRepo[];
  tierNode: string;
  private tierServiceSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private tierService: HierarchyTierService
  ) {
    this.generalFolderList$ = new BehaviorSubject([]);
    this.selectedFolder$ = new BehaviorSubject(null);
    this.tierServiceSubscription = this.tierService.currentTier$.subscribe(
      (tier) => {
        if (tier) {
          this.tierNode = tier.selectedTierId;
        }
      }
    );
  }
  ngOnDestroy(): void {
    this.tierServiceSubscription.unsubscribe();
  }

  getListOfSpecificTier(
    userFormID: number = null,
    formNumber: number,
    submitPeriodID: string = null,
    specialStatusIncluded: string = null,
    useCurrentHierarchy: string = null
  ): Observable<string> {
    let formType = '';
    switch (formNumber) {
      case SubmitFeatureConstants.QualityFormFeature:
        formType = 'getPractices';
        break;
      case SubmitFeatureConstants.PromotingInteroperabilityFormFeature:
        formType = 'getInteroperabilityPractices';
        break;
      case SubmitFeatureConstants.MeetingFormFeature:
        formType = 'getParticipants';
        break;
      case SubmitFeatureConstants.ComplianceFormFeature:
        formType = 'getParticipants';
        break;
      default:
        return null;
    }

    const userFormIdParameter =
      userFormID != null
        ? userFormID.toString()
        : SubmitFeatureToFormId.map.get(formNumber).toString();

    const params: HttpParams = new HttpParams()
      .set('userFormID', userFormIdParameter)
      .set('tier', this.tierNode)
      .set('submitPeriodID', submitPeriodID)
      .set('formID', SubmitFeatureToFormId.map.get(formNumber).toString())
      .set('specialStatusIncluded', specialStatusIncluded)
      .set('useCurrentHierarchy', useCurrentHierarchy);
    const response = this.http.get<string>(
      environment.coachApiUrl + '/api/formsRepo/' + formType,
      { headers: this.headers, params, responseType: 'text' as 'json' }
    );
    return response;
  }

  getStatuses(formID: string): Observable<string> {
    let response: Observable<string>;
    const params: HttpParams = new HttpParams()
      .set('formID', formID)
      .set('tier', this.tierNode);
    response = this.http.get<string>(
      environment.coachApiUrl + '/api/formsRepo/getStatuses',
      { headers: this.headers, params, responseType: 'text' as 'json' }
    );
    return response;
  }

  getStatusSummary(formID: string): Observable<SpecialStatusSummary[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID)
      .set('tier', this.tierNode);
    const response = this.http.get<SpecialStatusSummary[]>(
      environment.coachApiUrl + '/api/formsRepo/getStatusSummary',
      { headers: this.headers, params }
    );
    return response;
  }

  getSubmissions(formID: string, submitPeriodID: string): Observable<FormSubmission[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID)
      .set('tier', this.tierNode)
      .set('submitPeriodID', submitPeriodID);
    const response = this.http.get<FormSubmission[]>(
      environment.coachApiUrl + '/api/formsRepo/getSubmissions',
      { headers: this.headers, params }
    );
    return response;
  }

  getAdminReportResponseOverview(formID: string, submitPeriodID: string): Observable<CaravanFormAdminACO[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID)
      .set('tier', this.tierNode)
      .set('submitPeriodID', submitPeriodID);
    const response = this.http.get<CaravanFormAdminACO[]>(
      environment.coachApiUrl + '/api/formsRepo/getAdminReportResponseOverview',
      { headers: this.headers, params }
    );
    return response;
  }

  getAdminReportPracticeResponseView(formID: string, tierID: string, tierLevel: string, submitPeriodID: string): Observable<CaravanFormAdminPracticeRespView[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID)
      .set('tier', this.tierNode)
      .set('tierID', tierID)
      .set('tierLevel', tierLevel)
      .set('submitPeriodID', submitPeriodID);
    const response = this.http.get<CaravanFormAdminPracticeRespView[]>(
      environment.coachApiUrl + '/api/formsRepo/getAdminReportTierResponseView',
      { headers: this.headers, params }
    );
    return response;
  }
  getAdminReportICCOverview(formID: string, tierID: string, tierLevel: string, submitPeriodID: string): Observable<CaravanFormAdminICC[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID)
      .set('tier', this.tierNode)
      .set('tierID', tierID)
      .set('tierLevel', tierLevel)
      .set('submitPeriodID', submitPeriodID);
    const response = this.http.get<CaravanFormAdminICC[]>(
      environment.coachApiUrl + '/api/formsRepo/getAdminReportTierResponseView',
      { headers: this.headers, params }
    );
    return response;
  }

  getSavedPractices(formID: string): any {
    let response: Observable<any>;
    const params: HttpParams = new HttpParams()
      .set('formID', formID)
      .set('tier', this.tierNode);
    response = this.http.get<any>(
      environment.coachApiUrl + '/api/formsRepo/getSavedPractices',
      { headers: this.headers, params, responseType: 'text' as 'json' }
    );
    return response;
  }

  getFormResponses(userFormID: number): Observable<FormResponses[]> {
    const params: HttpParams = new HttpParams()
      .set('userFormID', userFormID.toString())
      .set('tier', this.tierNode);
    const response = this.http.get<FormResponses[]>(
      environment.coachApiUrl + '/api/formsRepo/getFormResponses',
      { headers: this.headers, params }
    );
    return response;
  }

  save(files: File[], form: any): Observable<FormSubmissionResult> {
    const formDataString = JSON.stringify(form);

    const formData = new FormData();
    files?.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('formDataString', formDataString);
    formData.append('tier', this.tierNode);

    return this.http.post<FormSubmissionResult>(
      environment.coachApiUrl + '/api/formsRepo/save',
      formData,
      { headers: this.headers, responseType: 'json', reportProgress: true }
    );
  }

  updateQualityStatus(params: UpdateQualityStatusParams): Observable<any> {
    params.tier = this.tierNode;
    return this.http.post(
      environment.coachApiUrl + '/api/formsRepo/updateQualityStatus',
      params,
      { headers: this.headers }
    );
  }

  savedSubmitPeriodsOverview: Observable<CaravanFormSubmissionPeriodOverview[]>;

  getSubmitPeriodsOverview(formID: number): Observable<CaravanFormSubmissionPeriodOverview[]> {
    if (this.savedSubmitPeriodsOverview) {
      return this.savedSubmitPeriodsOverview;
    } else if (this.tierNode == null) {
      this.tierService.currentTier$.subscribe(
        (tier) => {
          if (tier) {
            this.tierNode = tier.selectedTierId;
            const params: HttpParams = new HttpParams()
            .set('formID', formID.toString())
            .set('tier', this.tierNode);
          this.savedSubmitPeriodsOverview = this.http.get<CaravanFormSubmissionPeriodOverview[]>(
            environment.coachApiUrl + '/api/formsRepo/getSubmitPeriodsOverview',
            { headers: this.headers, params }
          ).pipe(share());
          return this.savedSubmitPeriodsOverview;
          }
        }
      );
     } else
   if (this.tierNode != null) {
        const params: HttpParams = new HttpParams()
          .set('formID', formID.toString())
          .set('tier', this.tierNode);
        this.savedSubmitPeriodsOverview = this.http.get<CaravanFormSubmissionPeriodOverview[]>(
          environment.coachApiUrl + '/api/formsRepo/getSubmitPeriodsOverview',
          { headers: this.headers, params }
        ).pipe(share());
        return this.savedSubmitPeriodsOverview;
    }
}

  getSubmitPeriodDetails(formID: number): Observable<CaravanFormSubmissionPeriod[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID.toString())
      .set('tier', this.tierNode);
    const response = this.http.get<CaravanFormSubmissionPeriod[]>(
      environment.coachApiUrl + '/api/formsRepo/getSubmitPeriodDetails',
      { headers: this.headers, params }
    );
    return response;
  }

  getAdminReportSubmitPeriods(formID: number): Observable<CaravanFormAdminSubmitPeriod[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID.toString())
      .set('tier', this.tierNode);
    const response = this.http.get<CaravanFormAdminSubmitPeriod[]>(
      environment.coachApiUrl + '/api/formsRepo/getAdminReportSubmitPeriods',
      { headers: this.headers, params }
    );
    return response;
  }

  getFolderGeneric(folder: string, parentFolder: string, grandparentFolder: string): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('folderName', folder.toString())
      .set('parentFolderName', parentFolder.toString())
      .set('grandParentfolderName', grandparentFolder.toString());
    const response = this.http.get<CaravanFormAdminSubmitPeriod[]>(
      environment.coachApiUrl + `/api/docRepo/folder/${this.tierNode}/specificFolderGeneric`,
      { headers: this.headers, params }
    );
    return response;
  }

  getFormRemediationInfo(userFormID: number): Observable<CaravanFormRemediationInfo[]> {
    const params: HttpParams = new HttpParams()
      .set('userFormID', userFormID.toString())
      .set('tier', this.tierNode);
    const response = this.http.get<CaravanFormRemediationInfo[]>(
      environment.coachApiUrl + '/api/formsRepo/getRemediationInfo',
      { headers: this.headers, params }
    );
    return response;
  }

  getAdminQuestionResponseReport(formID: number, submitPeriodID: number): Observable<CaravanFormAdminResponseReport[]> {
    const params: HttpParams = new HttpParams()
      .set('formID', formID.toString())
      .set('tier', this.tierNode)
      .set('submitPeriodID', submitPeriodID.toString());
    const response = this.http.get<CaravanFormAdminResponseReport[]>(
      environment.coachApiUrl + '/api/formsRepo/getAdminQuestionResponseReport',
      { headers: this.headers, params }
    );
    return response;
  }
}
