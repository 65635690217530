<div class="row">
  <div *ngIf="history.length > 0 || isLoading" class="u-display--flex u-flex-direction--column history-wrapper span--12" id='testHistoryWrapper'>
    <div class="list u-width--full u-display--flex u-flex-direction--column">
      <span class="subtitle padding--small padding-top--large padding-bottom--small" id='testHistoryText'>HISTORY</span>
      <coach-patient-awv-loading [isLoading]="isLoading" [isHistory]="true" class="loader-history"></coach-patient-awv-loading>
      <ng-container *ngFor="let change of history; index as idx">
        <div class="list-item--complex list-item--singleHitArea">
          <div class="list-item-avi">
            <div class="avi">
              <div class="nameAvatar" *ngIf="change.displayName">
                {{ change.displayName | initialsFromName }}
              </div>
              <div class="nameAvatar" *ngIf="!change.displayName">P</div>
            </div>
          </div>
          <div class="list-item-main u-justify-content--center">
            <div class="row u-display--flex u-justify-content--spaceBetween">
              <div class="field u-bold" id='testHistoryName'>
                <span *ngIf="change.displayName">{{change.displayName}}</span>
                <span *ngIf="!change.displayName">{{coachUser}}</span>
              </div>
              <div class="row--verticalCenter margin-right--smallest u-font-size--smallest text-right" id='TestHistoryTimeStamp'>
                {{change.recordedChange.submissionInfo.submittedDate | date: 'MM/dd/yy h:mm a'}}
              </div>
            </div>
            <div class="u-display--flex u-flex-direction--row u-font-size--smallest" id='testHistoryActionText'>
              <div class="u-display--flex u-flex-direction--row u-align-items--top">
                <span class="subtitle u-text-white-space--break-spaces">{{change.description}}</span>
              </div>
            </div>
            <div *ngIf="change.comment" class="u-display--flex u-flex-direction--row u-font-size--smallest u-font-weight--light">
              <div class="u-display--flex u-flex-direction--row u-align-items--top">
                <span class="subtitle u-italic u-text-white-space--break-spaces" id='testHistoryComment'>{{change.comment}}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
