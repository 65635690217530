import { Component, OnInit } from '@angular/core';
import { ImproveFeatureNames } from '@shared/models/module-constants';
import { FileDownloadService } from '@shared/services/doc-exchange/file-download.service';

@Component({
  selector: 'coach-improve-information-technology',
  templateUrl: './improve-information-technology.component.html',
  host: {
    class: 'row u-fill'
  }
})
export class ImproveInformationTechnologyComponent implements OnInit {

  public docRepoData;
  public tabName: string = ImproveFeatureNames.InformationTechnology;

  constructor(
    private fileDownloadService: FileDownloadService
  ) {}

  ngOnInit(): void {

    this.fileDownloadService.generalFolderList$.subscribe(data => {
      if (data.length !== 0) {
        this.docRepoData = this.fileDownloadService
        .getChildrenByCollectionName(this.tabName, data);
      }
    });
  }
}
