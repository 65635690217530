import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, maskCount?: number): string {
    if (value == null) {
      return '';
    }

    if (!environment.maskSensitiveData) {
      return value;
    }

    if (maskCount == null) {
      maskCount = value.length;
    }

    let maskedString = value.slice(maskCount + 1);
    maskedString = 'X'.repeat(maskCount) + maskedString;

    return maskedString;
  }

}
