/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PatientAuditLogs } from '../models/patient-audit-logs';


/**
 * Represents resources related to the audit logs.
 */
@Injectable({
  providedIn: 'root',
})
export class AuditLogService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllAuditLogs
   */
  static readonly GetAllAuditLogsPath = '/audit';

  /**
   * Get all  audit logs.
   *
   * Get all patient and program audit logs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAuditLogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAuditLogs$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PatientAuditLogs>> {

    const rb = new RequestBuilder(this.rootUrl, AuditLogService.GetAllAuditLogsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatientAuditLogs>;
      })
    );
  }

  /**
   * Get all  audit logs.
   *
   * Get all patient and program audit logs.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAuditLogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAuditLogs(params?: {
  },
  context?: HttpContext

): Observable<PatientAuditLogs> {

    return this.getAllAuditLogs$Response(params,context).pipe(
      map((r: StrictHttpResponse<PatientAuditLogs>) => r.body as PatientAuditLogs)
    );
  }

  /**
   * Path part for operation getAuditLogsForProgram
   */
  static readonly GetAuditLogsForProgramPath = '/audit/programs/{programId}';

  /**
   * Get audit logs for program.
   *
   * Get audit logs for a program.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuditLogsForProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogsForProgram$Response(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PatientAuditLogs>> {

    const rb = new RequestBuilder(this.rootUrl, AuditLogService.GetAuditLogsForProgramPath, 'get');
    if (params) {
      rb.path('programId', params.programId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatientAuditLogs>;
      })
    );
  }

  /**
   * Get audit logs for program.
   *
   * Get audit logs for a program.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuditLogsForProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogsForProgram(params: {

    /**
     * Unique identifier for the program.
     */
    programId: number;
  },
  context?: HttpContext

): Observable<PatientAuditLogs> {

    return this.getAuditLogsForProgram$Response(params,context).pipe(
      map((r: StrictHttpResponse<PatientAuditLogs>) => r.body as PatientAuditLogs)
    );
  }

  /**
   * Path part for operation getAuditLogsForPatient
   */
  static readonly GetAuditLogsForPatientPath = '/audit/patients/{censeoId}';

  /**
   * Get audit logs for patient.
   *
   * Get audit logs for patient.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuditLogsForPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogsForPatient$Response(params: {

    /**
     * Unique identifier for the patient.
     */
    censeoId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PatientAuditLogs>> {

    const rb = new RequestBuilder(this.rootUrl, AuditLogService.GetAuditLogsForPatientPath, 'get');
    if (params) {
      rb.path('censeoId', params.censeoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatientAuditLogs>;
      })
    );
  }

  /**
   * Get audit logs for patient.
   *
   * Get audit logs for patient.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuditLogsForPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogsForPatient(params: {

    /**
     * Unique identifier for the patient.
     */
    censeoId: string;
  },
  context?: HttpContext

): Observable<PatientAuditLogs> {

    return this.getAuditLogsForPatient$Response(params,context).pipe(
      map((r: StrictHttpResponse<PatientAuditLogs>) => r.body as PatientAuditLogs)
    );
  }

}
