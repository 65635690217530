<div class="u-height--fit-content form-background">
  <div class="u-font-size--small">

    <div disabled="checked" class="row border--1 u-border-radius">
      <button [disabled]="checked" [ngClass]="{'reminder-btn-selected': selectedReminder == 1 }" id = "dayTab" (click)="setSelectedReminderDate(1)" class="reminder-btn span--4 u-text-align--center padding--small u-cursor--pointer">1 day</button>
      <button [disabled]="checked" [ngClass]="{'reminder-btn-selected': selectedReminder == 2 }" id = "weekTab" (click)="setSelectedReminderDate(2)" class="reminder-btn span--4 u-text-align--center padding--small u-cursor--pointer">1 week</button>
      <button [disabled]="checked" [ngClass]="{'reminder-btn-selected': selectedReminder == 3 }" id ="oneMonthTab"(click)="setSelectedReminderDate(3)" class="reminder-btn span--4 u-text-align--center padding--small u-cursor--pointer">1 month</button>
      <button [disabled]="checked" [ngClass]="{'reminder-btn-selected': selectedReminder == 4 }" id = "threeMonthTab"(click)="setSelectedReminderDate(4)" class="reminder-btn span--4 u-text-align--center padding--small u-cursor--pointer">3 months</button>
    </div>
    <div class="padding-top--small padding-bottom--small u-font-weight--light" id='testCallBackDateText'>Selected Date: {{reminderDate | date: 'MM/dd/yy'}}</div>

    <div class="row">
      <input id="customDatechkbx" type="checkbox" name="customdatechkbx" [checked]="checked" (change)="customDateChange()" #customdatechkbx >
      <label id="testCallbackCustomDatechkbx" for="customDatechkbx" class="u-text-align--right">Use Custom Date</label>
    </div>

    <div class="row">
      <input #callBackDateInput [disabled]="!checked" [required]="checked" placeholder="AWV reminder date" id="reminderDateInput" (input)="fieldChanged(); setSelectedReminderDate(0);"
             [(ngModel)]="reminderDate" type="date" [min]="reminderDateMin" [max]="reminderDateMax">
    </div>
    <div class="row padding-bottom--small">
      <coach-invalid-input [input]='callBackDateInput'></coach-invalid-input>
    </div>

    <div class="row">
      <select #callBackReasonSelector [(ngModel)]="callBackReason" id="callBackReasonSelector" name="callBackReasonSelector"
              (change)="fieldChanged()" required>
        <option value="" disabled selected>* Callback Reason</option>
        <option *ngFor="let reason of reasons" [ngValue]="reason">{{reason}}</option>
      </select>
    </div>
    <div class="row padding-bottom--small">
      <coach-invalid-input [select]='callBackReasonSelector'></coach-invalid-input>
    </div>

    <div class="row padding-bottom--smallest">
      <textarea class="resize--none" [(ngModel)]="comment" placeholder="Add a comment" id="callBackComment" rows="6"
                [maxlength]="commentCharacterLimit" (input)="fieldChanged()" #commentbox></textarea>
    </div>
    <coach-patient-awv-inline-error></coach-patient-awv-inline-error>
    <div class="row padding-bottom--small">
      <div class="span--6 u-font-weight--light" [ngClass]="{'character-limit-reached': commentbox.value.length == commentCharacterLimit }">{{commentbox.value.length}}/{{commentCharacterLimit}}</div>
      <div class="span--6 u-text-align--right u-cursor--pointer u-font-weight--light padding-right--small" id='callbackCopyText' (click)="copyComment(commentbox)">Copy text</div>
    </div>
    <div class="row padding-top--small">
      <coach-icon class="span--6 icon icon--default is-medium u-align-items--center u-cursor--pointer margin--small" iconName="copy" iconColor="primary" id='callbackCopyButton' [copy]="stringifyForm()"></coach-icon>
      <button class="span--9 button is-unstyled u-flex--right is-small margin-bottom--small margin-right--small cancel-btn u-color--brand--1" id="testCallbackCancelBtn" (click)="clearFields()" [disabled]="allFieldsEmpty">Cancel</button>
      <button class="span--3 button is-small is-primary margin-bottom--small" id="testCallbackSaveBtn" (click)="save()" [disabled]="!requiredFieldsSatisfied">Save</button>
    </div>
  </div>
</div>
