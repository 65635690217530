import { Injectable } from '@angular/core';
import { PresetItemBase, IPresetItemContract } from '../utils';
import { IPatientFilterPreset } from '@shared/models/patient-filters';
import { PatientEngagement } from '@shared/enums';
import { PresetCategory, CareComponentConstants } from '@shared/models/module-constants';
import { environment } from 'src/environments/environment.coachDev';

@Injectable({
  providedIn: 'root'
})
export class ActiveAlertsAndMessagesPreset extends PresetItemBase implements IPresetItemContract {
  getPresetItem(name?: string, label?: string): IPatientFilterPreset {
    const item: IPatientFilterPreset = {
      category: PresetCategory.AlertsAndMessages,
      name,
      label,
      hidePreset: false,
      filters: [],
      type: 'alerts',
      componentId: CareComponentConstants.PatientList,
      columns: this.getPresetColumns(name),
      presetKey: name
    };

    item.versionKey = `columnState_${this.userId}_${item.presetKey}`;
    return item;
  }

  private getPresetColumns(alert: string): { [key in string]: boolean }[] {
    if (!alert) {
      return [];
    }
    return [
      { select: true },
      { fullName: true },
      { dob_age: true },
      { gender: true },
      { mbi: true },
      { bp: alert.toLowerCase().includes('blood pressure') },
      { pulse: alert.toLowerCase().includes('blood pressure') },
      { weight: alert.toLowerCase().includes('rapid weight gain') },
      { gain: alert.toLowerCase().includes('rapid weight gain') },
      { bloodSugar: alert.toLowerCase().includes('blood sugar') },
      { phoneNumber: true },
      {
        professionalName: this.hasPatientApp
      },
      { assignment: true },
      { attributedCP: true },
      { predictedRiskLevel: environment.displayRiskLevel },
      {
        ccmEligible: this.agGridCustomFilteringFlag
      },
      {
        actions: this.hasPatientApp
      },
      {
        hccGapCount: this.agGridCustomFilteringFlag
      },
      {
        diseaseCohorts: this.agGridCustomFilteringFlag
      },
      {
        raF_YTD: this.agGridCustomFilteringFlag
      },
      { openRAFWt: true },
      { lastVisit: true },
      { attributedPractice: true },
      { pyRaf: true }
    ];
  }
}
