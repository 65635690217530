import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';


import { COLUMN_DEFS } from './care-prescription.columns';
import {
  IPrescription,
  IPrescriptionDetail,
} from '@shared/models/prescription';
import { IPrescriptionFilterPreset } from '@shared/models/prescription-filters';
import { HierarchyTierService } from '@shared/services/hierarchy/hierarchy-tier.service';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { takeUntil } from 'rxjs/operators';
import { KillSubscriptions } from '@shared/components/kill-subscriptions';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { Subscription } from 'rxjs';
import {
  ClaimStatus,
  mapOverallOptionsToStrings,
  formatDate,
  formatDateFourDigitYear,
  B340StringConstants,
} from './shared-lib';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { FilterOption } from '../care-all-prescriptions/care-all-prescriptions.component';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';

// THIS IS A TEST FOR GITHUB INTEGRATION AND WILL BE REMOVED IN THE FUTURE
@Component({
  selector: 'coach-care-prescriptions',
  templateUrl: './care-prescriptions.component.html',
  host: {
    class: 'page-content coach-care-prescriptions',
  },
})
export class CarePrescriptionsComponent
  extends KillSubscriptions
  implements OnInit, OnDestroy
{
  public static ACCESS_AUDIT_MESSAGE = 'Accessed 340B Work Queue';
  public static PRINTED_AUDIT_MESSAGE = 'Printed 340B patient list';

  public prescriptions: Array<IPrescription> = [];
  public dataLoaded: boolean = false;
  public loadingStatus = true;
  public cancelLoadingBool = false;
  public resetFilter = false;
  public selectedPreset: IPrescriptionFilterPreset;
  public prescriptionDetail: IPrescriptionDetail;
  
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  public selectedPatientInfo: any;
  readonly defaultColDef: ColDef = {
    resizable: true,
  };
  readonly columnDefs = COLUMN_DEFS;

  dateRangeLow: Date;
  dateRangeHigh: Date;

  public orgId: string = null;
  public tier: IHierarchyTier = null;
  public rowCount: number = 10;
  public pageNo: number = 1;

  public gridObject: any = null;
  public currentRow: any = null;
  public redrawRowInterval: any;

  b340StringConstants: B340StringConstants = new B340StringConstants();
  
  ceLocations;

  public claimPanelStatus: number = 1;

  authSubscription: Subscription;
  currentUser: any;

  filterOptions: FilterOption[];
  UIUpdates340BFeatureFlag: boolean;
  @Output() public gridApiEmitter: EventEmitter<GridApi> = new EventEmitter();
  @Output() public filterOptionsEmitter: EventEmitter<FilterOption[]> =
    new EventEmitter();

  exportOptions = ["Exports", "Patient List", "Export"];
  selectedExportOption = this.exportOptions[0];

  constructor(
    private _route: ActivatedRoute,
    private HierarchyTierService: HierarchyTierService,
    private PrescriptionService: PrescriptionService,
    private toastr: ToastrService,
    private router: Router,
    protected featureFlagService: FeatureFlagService,
    private authService: AuthService
  ) {
    super();
    HierarchyTierService.currentTier$
      .pipe(takeUntil(this.killTrigger))
      .subscribe(async (tier) => {
        if (this.tier?.selectedTierId !== tier?.selectedTierId) {
          this.tier = tier;
          this.prescriptions = await this.getPrescriptions();
          this.initializeFilter();
        }
      });
    this.UIUpdates340BFeatureFlag = this.featureFlagService.hasFeatureFlag(
      CareFeatureConstants.UIUpdates340B
    );
  }

  ngOnInit(): void {
    this.authSubscription = this.authService.userInfo$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    clearInterval(this.redrawRowInterval);
  }

  initializeFilter() {
    const { selectedItem } = this.tier;

    this.PrescriptionService.getPrescribers(
      selectedItem.tier1_ID,
      selectedItem.tier2_ID,
      selectedItem.tier3_ID,
      selectedItem.tier4_ID
    )
      .then((prescribers) => {
        const filterOptions = [
          {
            headerLabel: 'Status',
            filterInstanceLabel: 'status',
            optionLabels: ['Has Note', 'Pending', 'New', 'Rejected', 'Expired'],
            checkedByDefault: ['Has Note', 'Pending', 'New'],
            dataMapper: (data) => {
              switch (data) {
                case 'Has Note':
                  return `${ClaimStatus.HAS_NOTE}`;
                case 'Pending':
                  return `${ClaimStatus.PENDING}`;
                case 'New':
                  return `${ClaimStatus.NEW}`;
                case 'Rejected':
                  return `${ClaimStatus.REJECTED}`;
                case 'Expired':
                  return `${ClaimStatus.EXPIRED}`;
              }
            },
          },
        ];

        prescribers.shift();

        filterOptions.push({
          headerLabel: 'External Prescribers',
          filterInstanceLabel: 'prescriberName',
          checkedByDefault: ['All'],
          optionLabels: prescribers
            .filter(prescriber => prescriber.name && prescriber.name !== ' ')
            .map((prescriber) => {
            return prescriber.name;
          }),
          dataMapper: null,
        });

        filterOptions.push({
          headerLabel: 'Claim Source',
          filterInstanceLabel: 'tpaName',
          checkedByDefault: ['All'],
          optionLabels: this.b340StringConstants.FILTER_SHARED_OPTION_LABELS_TPANAME,
          dataMapper: null,
        });

        this.filterOptions = filterOptions;
        this.filterOptionsEmitter.emit(this.filterOptions);

        this.loadingStatus = false;
      });
  }

  exportSelectionChanged(e) {
    switch (e.target.value) {
      case this.b340StringConstants.EXPORTS_PATIENT_LIST:
        this.generatePatientOutputFile();
        break;
      case this.b340StringConstants.EXPORTS_EXPORT:
        this.exportExtendedClaimsData();
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.selectedExportOption = this.b340StringConstants.EXPORT_MENU_TITLE;
    }, 0);
  }

  generatePatientOutputFile() {
    const dateRange = this.getAuditMessageDateRange();
    const auditOptions = {
      entityid: parseInt(this.currentUser.dnn_id),
      moduleid: 2, // care
      componentid: 36,
      actionid: 9,
      tablename: 'Coach340BDetail',
      auditdesc: `User ID ${this.currentUser.dnn_id} Printed 340B patient list for date range ${dateRange}`,
      applicationID: 2,
    };
    this.PrescriptionService.writeAuditMessage(auditOptions);

    this.gridApi.exportDataAsExcel({
      processCellCallback: (params) => {
        switch (params.column.getColId()) {
          case 'status':
            return mapOverallOptionsToStrings(params.value);
          case 'dob':
          case 'firstFilled':
            return formatDate(params.value);
          case 'fillDate':
            return formatDate(params.value);
          case 'claimType':
            return params.value === "CMS" ? "CMS" : params.node.data.tpaName;
          default:
            return params.value;
        }
      },
    });
  }

  exportExtendedClaimsData() {
    let tbl = document.createElement('table');
    let data = this.buildTableData();
    let visibleNodes = this.buildVisibleClaimsNodeSet();

    let filteredData = data.filter(claimObj => {
      for (let node of visibleNodes) {
        if (node.claimPk === claimObj.claimPk && node.claimType === claimObj.claimType) {
          return true;
        }
      }
      return false;
    }).map(claimObj => {
      delete claimObj.claimPk;
      delete claimObj.claimType;
      claimObj.drugManufacturer = claimObj.drugManufacturer ?? '';
      claimObj.tpaName = claimObj.tpaName ? claimObj.tpaName : "CMS";
      claimObj["Claim Source"] = claimObj.tpaName;
      delete claimObj.tpaName;
      return claimObj;
    });

    this.generateTable(tbl, filteredData);
    this.generateTableHead(tbl, filteredData[0]);

    const csv = this.toCsv(tbl);
    this.download(csv, 'download.csv');
  }

  buildVisibleClaimsNodeSet() {
    let pageCount = this.gridApi.paginationGetTotalPages();
    let originalPage = this.gridApi.paginationGetCurrentPage();
    this.gridApi.paginationGoToFirstPage();
    let nodes = [];
    for (let i = 0; i < pageCount; i++) {
      let currentPageNodes = this.gridApi.getRenderedNodes();
      nodes.push(currentPageNodes);
      this.gridApi.paginationGoToNextPage();
    }
    this.gridApi.paginationGoToPage(originalPage);
    return new Set(nodes.flat(1).map(node => {return {claimPk: node.data.claimPk, claimType: node.data.claimType}}));
  }

  buildTableData(): any {
    return this.prescriptions.map(p => {
      return {
        "claimPk": p.claimPk,
        "Tier2_Name": p.tier2_Name,
        "Rx Number": p.rxNumber,
        "Bene_Full Name": `"${p.patientName}"`,
        "Bene DOB": formatDateFourDigitYear(p.dob),
        "NDC": p.ndc,
        "Medication_Description": `"${p.prescriptionName}"`,
        "Fill Number": p.clPrescriptionFillNumber,
        "Prescription_Filled_Date": formatDateFourDigitYear(p.fillDate),
        "Date_Initial_Fill": formatDateFourDigitYear(p.firstFilled),
        "Prescribing_Provider_NPI": p.npi,
        "Prescribing_Provider_Name": `"${p.prescriberName}"`,
        "Provider_Address": `"${p.location}"`,
        "Prescribing_Provider_Specialty": `"${p.prescriberSpecialty}"`,
        "Service_Pharmacy_NPI": p.servicePharmacyNpi,
        "Pharmacy_Org_Name": `"${p.pharmacyName}"`,
        "Pharmacy_Other_Org_Name": `"${p.pharmacyNameOther}"`,
        "Pharmacy_Address": `"${p.pharmacyAddress}"`,
        "Pharmacy_Address_Other": `"${p.pharmacyAddressOther || ''}"`,
        "Price": `$${p.claimType === 'CMS' ? p.awpPrice : p.tpaPrice}`,
        "claimType": p.claimType,
        "Claim Source": null,
        "drugManufacturer": p.drugManufacturer,
        "tpaName": p.tpaName,
      }
    })
  }
  
  toCsv = function (table) {
    // Query all rows
    const rows = table.querySelectorAll('tr');

    return [].slice
        .call(rows)
        .map(function (row) {
            // Query all cells
            const cells = row.querySelectorAll('th,td');
            return [].slice
                .call(cells)
                .map(function (cell) {
                    return cell.textContent;
                })
                .join(',');
        })
        .join('\n');
  };
  
  download = function (text, fileName) {
    const link = document.createElement('a');
    link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
    link.setAttribute('download', fileName);

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  generateTableHead(table, data) {
    let thead = table.createTHead();
    let row = thead.insertRow();
    for (let key in data) {
      let th = document.createElement("th");
      let text = document.createTextNode(key);
      th.appendChild(text);
      row.appendChild(th);
    }
  }

  generateTable(table, data) {
    for (let element of data) {
      let row = table.insertRow();
      for (let key in element) {
        let cell = row.insertCell();
        let text = document.createTextNode(element[key]);
        cell.appendChild(text);
      }
    }
  }

  onGridReady(param: any): void {
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
    this.rowCount = this.gridApi.getDisplayedRowCount();
    this.pageNo = 1;

    this.setDefaultSortOrder();
    this.gridApiEmitter.emit(this.gridApi);
  }

  onFirstDataRendered(params: any): void {
    this.gridObject = params;
    this.dataLoaded = true;
  }

  setDefaultSortOrder() {
    this.columnApi.applyColumnState({
      state: [
        {
          colId: 'status',
          sort: 'asc',
          sortIndex: 0,
        },
        {
          colId: 'tpaPrice',
          sort: 'desc',
          sortIndex: 1,
        },
        {
          colId: 'fillDate',
          sort: 'asc',
          sortIndex: 2,
        },
        {
          colId: 'clPrescriptionFillNumber',
          sort: 'asc',
          sortIndex: 3,
        },
      ],
      defaultState: { sort: null },
    });
  }

  async refreshGridAndUpdateStatus(status: ClaimStatus) {
    let currentRowId = this.currentRow.node.id;
    let rowNode = this.gridApi.getRowNode(currentRowId);
    rowNode.setDataValue('status', status);
    this.gridApi.refreshClientSideRowModel();
  }

  removeRow(subsequentFillLogicOptOut) {
    const selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length === 1) {
      const rxNumber = selectedData[0].rxNumber;
      const fillNumber = parseInt(selectedData[0].clPrescriptionFillNumber);
      this.gridApi.forEachNode((rowNode, index) => {
        
        if (rowNode.data.status === ClaimStatus.EXPIRED) {
          rowNode.setSelected(false);
          return;
        }

        if (rowNode.data.rxNumber === rxNumber) {
          if (parseInt(rowNode.data.clPrescriptionFillNumber) === fillNumber) {
            rowNode.setSelected(true);
          }
          if (parseInt(rowNode.data.clPrescriptionFillNumber) > fillNumber && !subsequentFillLogicOptOut) {
            rowNode.setSelected(true);
          }
        }
      });
    }
    this.gridApi.applyTransaction({ remove: this.gridApi.getSelectedRows() });
  }

  onRejectClaim(subsequentFillLogicOptOut: boolean = false) {
    this.changeClaimStatus(ClaimStatus.REJECTED, subsequentFillLogicOptOut);
  }

  changeClaimStatus(status: ClaimStatus, subsequentFillLogicOptOut: boolean = false) {
    if (status != ClaimStatus.REJECTED && status != ClaimStatus.APPROVED && status != ClaimStatus.EXPIRED) {
      let currentRowId = this.currentRow.node.id;
      let rowNode = this.gridApi.getRowNode(currentRowId);
      rowNode.setDataValue('status', status);
    } else {
      const selectedData = this.gridApi.getSelectedRows();
      const fillNumber = parseInt(selectedData[0].clPrescriptionFillNumber);

      if (selectedData.length === 1) {
        this.gridApi.forEachNode((rowNode, index) => {
          if (rowNode.data.rxNumber === selectedData[0].rxNumber) {
            if (rowNode.data.status !== ClaimStatus.EXPIRED) {
              if (parseInt(rowNode.data.clPrescriptionFillNumber) === fillNumber) {
                rowNode.setDataValue('status', status);
              }
              if (parseInt(rowNode.data.clPrescriptionFillNumber) > fillNumber && !subsequentFillLogicOptOut) {
                rowNode.setDataValue('status', status);
              }
            }
          } 
        });
      }
    }
    this.gridApi.refreshClientSideRowModel();
  }

  public async onRowClicked(event: any): Promise<void> {
    this.selectedPatientInfo = {
      claimPk: event.data.claimPk,
      claimId: event.data.claimId,
      acoId: event.data.acoId,
      tier2_id: event.data.tier2_id,
      tier3_id: event.data.tier3_id,
      tier4_grpId: event.data.tier4_grpId,
      patientName: event.data.patientName,
      patientDob: event.data.dob,
      patientSex: event.data.patientSex,
      mbi: event.data.mbi,
      patientPrimaryCare: '',
      prescriptionName: event.data.prescriptionName,
      prescriberName: event.data.prescriberName,
      prescriberCredentials: event.data.prescriberCredentials,
      prescriberSpecialty: event.data.prescriberSpecialty,
      prescriberPhone: event.data.prescriberPhone,
      location: event.data.location,
      firstFilled: event.data.firstFilled,
      claim_Status: event.data.status,
      primaryProviderName: event.data.primaryProviderName,
      primaryProviderCredentials: event.data.primaryProviderCredentials,
      pharmacyName: event.data.pharmacyName,
      pharmacyNameOther: event.data.pharmacyNameOther,
      chPatId: event.data.chPatId,
      ccn: event.data.ccn,
      userId: this.currentUser.dnn_id,
      ndc: event.data.ndc,
      clPrescriptionFillNumber: event.data.clPrescriptionFillNumber,
      rxNumber: event.data.rxNumber,
      npi: event.data.npi,
      servicePharmacyNpi: event.data.servicePharmacyNpi,
      gender: event.data.gender,
      prescriberFax: event.data.prescriberFax,
      pharmacyAddress: event.data.pharmacyAddress,
      prescriptionRefID: event.data.prescriptionRefID,
      enrollmentStatus: event.data.enrollmentStatus,
      claimType: event.data.claimType,
      drugManufacturer: event.data.drugManufacturer,
    };

    this.ceLocations = await this.PrescriptionService.getCELocations(
      this.selectedPatientInfo.tier2_id
    );

    this.PrescriptionService.setSelectedClaimInfo({
      selectedPatientInfo: this.selectedPatientInfo,
      ceLocations: this.ceLocations,
      claimId: event.data.claimId,
      claimType: event.data.claimType,
    });

    this.currentRow = event;
  }

  public cancelPrescriptionsLoadingProcess(): void {
    this.cancelLoadingBool = true;
    this.loadingStatus = false;
    this.resetFilter = true;
    let that = this;
    setTimeout(function () {
      that.resetFilter = false;
    }, 1000);
  }

  async getPrescriptions(): Promise<IPrescription[]> {
    const { selectedItem } = this.tier;
    let prescriptions;
    try {
      prescriptions = this.PrescriptionService.getPrescriptions(
        selectedItem.tier1_ID,
        selectedItem.tier2_ID,
        selectedItem.tier3_ID,
        selectedItem.tier4_ID
      );
    } catch (e) {
      this.toastr.error(`${e.status}: ${e.statusText}`, 'Error');
    }

    this.writeAccessAuditMessage(
      CarePrescriptionsComponent.ACCESS_AUDIT_MESSAGE
    );

    return prescriptions;
  }

  writeAccessAuditMessage(action: string) {
    const dateRange = this.getAuditMessageDateRange();
    setTimeout(async () => {
      this.PrescriptionService.writeAuditMessage({
        entityid: parseInt(this.currentUser.dnn_id),
        moduleid: 2, // care
        componentid: 36,
        actionid: 6, // phi access
        tablename: 'Coach340BClaims',
        auditdesc: `User ID ${this.currentUser.dnn_id} ${action} for date range ${dateRange}`,
        applicationID: 2,
      });
    }, 0);
  }

  getAuditMessageDateRange() {
    return `${this.dateRangeLow?.toLocaleDateString() ?? 'All'} - ${
      this.dateRangeHigh?.toLocaleDateString() ?? 'All'
    }`;
  }
}
