<ng-container *ngIf="patientClone">
  <div class="header" *ngIf="patientClone">
    <div class="titles">
      <div class="title">
        Edit Patient Form
      </div>
    </div>
  </div>
  <div class="dynamicBody padding">

    <!-- LAST, FIRST, and PREFERRED NAME -->
    <div class="list-item padding-leftRight--none">
      <div class="row">
        <div class="field padding--smallest">
          <label class="margin-top label" for="patientLastName" [ngClass]="{
                'u-color--brand--3': !(patientClone.lastName.length > 0) || !(patientClone.firstName.length > 0)
            }">Name
          </label>
        </div>
      </div>
      <div class="row">
        <div class="field padding--smallest">
          <input type="text" id="patientLastName" class="is-disabled" disabled="true" placeholder="Last"
            [(ngModel)]="patientClone.lastName">
        </div>
        <div class="field padding--smallest">
          <input type="text" id="patientFirstName" class="is-disabled" disabled="true" placeholder="First"
            [(ngModel)]="patientClone.firstName">
        </div>
        <div class="field padding--smallest">
          <input type="text" id="patientPreferredName" placeholder="Preferred" [(ngModel)]="patientClone.nickName">
        </div>
      </div>
    </div>

    <!-- EMAIL and PHONE NUMBER -->
    <div class="list-item padding-leftRight--none">
      <div class="row">
        <div class="field padding--smallest">
          <label class="margin-top label" for="patientEmail" [ngClass]="{
                    'u-color--brand--3': !isInputValid('email')
                }">Email Address</label>
          <input type="text" id="patientEmail" [(ngModel)]="patientClone.email" />
        </div>

        <div class="field padding--smallest">
          <label class="margin-top label" for="patientMobile" [ngClass]="{
          'u-color--brand--3': !isInputValid('phoneNumber')
      }">Mobile Phone Number</label>
          <input type="text" id="patientMobile" placeholder="xxx-xxx-xxxx" [(ngModel)]="patientClone.phoneNumber" />
        </div>

      </div>
      <div class="row">
        <div class="field padding--smallest">
          <small id="error-message-email" class="margin-top" *ngIf="!isInputValid('email')"> Invalid email </small>
        </div>
        <div class="field padding--smallest">
          <small id="error-message-phone" class="margin-top" *ngIf="!isInputValid('phoneNumber')"> Invalid phone format
          </small>
        </div>
      </div>
      <div class = "row">
        <div class="field padding--smallest">
        </div>
        <div class="field padding--smallest">
          <small id = "error-message-phone" class="margin-top" *ngIf="!isFirstDigitValid('phoneNumber')"> First digit has to be 2-9 </small>
        </div>
      </div>
    </div>
  </div>
  <div class="footer" [ngClass]="{
    'u-colors--success': patientEditStatus == 'success',
    'u-colors--warning': patientEditStatus == 'processing',
    'u-colors--danger': patientEditStatus == 'failed'
  }">


  <button class="button margin-right--small" (click)="close('')"
    [ngClass]="{'u-color--brand--2--contrast': patientEditStatus != 'ready'}">
    <span *ngIf="patientEditStatus != 'success'">Cancel</span>
    <span *ngIf="patientEditStatus == 'success'">Close</span>
  </button>
  <button class="button" [disabled]="!isInputValid('email') || !isInputValid('phoneNumber')" [ngClass]="{
  'is-disabled': !isInputValid('email') || !isInputValid('phoneNumber'),
  'is-primary': isInputValid('email') && isInputValid('phoneNumber'),
  'u-color--brand--2--contrast': patientEditStatus != 'ready'
}" (click)="updatePatient()">
    <span *ngIf="patientEditStatus == 'failed'">Failed</span>
    <span *ngIf="patientEditStatus == 'ready'">Update</span>
    <span *ngIf="patientEditStatus == 'success'">Updated</span>
    <span *ngIf="patientEditStatus == 'processing'">Processing</span>
  </button>
  </div>
</ng-container>
