<!-- BLOOD PRESSURE CHART -->
<div class="widget margin--large margin-bottom--none" [ngClass]="{'u-display--none': bpChartSettings.chart == null}">
  <div class="header">
    <div class="titles t5 u-display--flex u-flex-direction--row u-align-items--center">
      <div *ngIf="!isMobile || !bpChartSettings.listToChartToggle"  class="title u-width--fit-conten margin-right--large">Blood pressure</div>
      <div class="u-display--flex">
        <button class="button is-small u-border-right-radius--none"
          [ngClass]="{'is-primary': !bpChartSettings.listToChartToggle}"
          (click)="bpChartSettings.listToChartToggle = false">
          <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
        </button>
        <button class="button is-small u-border-left-radius--none"
          [ngClass]="{'is-primary': bpChartSettings.listToChartToggle}"
          (click)="bpChartSettings.listToChartToggle = true">
          <fa-icon class='fa-sm' [icon]="faList"></fa-icon>
        </button>
      </div>
    </div>
    <div class=" margin-left--large"
      [ngClass]="{'u-display--none': !bpChartSettings.listToChartToggle, 'u-display--flex': bpChartSettings.listToChartToggle}">
      <button class="button margin-right border--none is-small" (click)="export('csv', bpChartSettings)">Get CSV</button>
      <button class="button margin-right border--none is-small" (click)="export('copy', bpChartSettings)">Copy</button>
      <select class="is-small" id="bpChartSettingsListDateRange" [(ngModel)]="bpChartSettings.tableDisplayDays" name="selectdemo">
        <option value=30>30</option>
        <option value=60>60</option>
        <option value=90>90</option>
        <option value="all">All</option>
      </select>
    </div>
  </div>
  <div class="dynamicBody">
    <div class="reportChart bpChart" [ngClass]="{'u-display--none': bpChartSettings.listToChartToggle}"></div>
    <div class="tableWrapper" [ngClass]="{'u-display--none': !bpChartSettings.listToChartToggle}">
      <!-- bpChartSettings.reportsData -->
      <table class="table u-width--full">
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>BP</th>
            <th>PULSE</th>
            <th>Comment</th>
            <th>Alert</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of bpChartSettings.reportsData"
            [ngClass]="{'u-display--none': !isDateInRange(report.date, bpChartSettings.tableDisplayDays)}"
            [hidden]="!report._id">
            <td>{{report.date | date: 'medium'}}</td>
            <td>
              <strong>
                <span
                  [ngClass]="{'u-color--danger': (report.goal_systolic_bottom && report.systolic < report.goal_systolic_bottom) || (report.goal_systolic_top && report.systolic > report.goal_systolic_top) }">
                  {{report.systolic}}
                </span>
                /
                <span
                  [ngClass]="{'u-color--danger': (report.goal_diastolic_bottom && report.diastolic < report.goal_diastolic_bottom) || (report.goal_diastolic_top && report.diastolic > report.goal_diastolic_top) }">
                  {{report.diastolic}}
                </span>
              </strong>
            </td>
            <td>{{report.pulse}}</td>
            <td>{{report.comment}}</td>
            <td class="u-display--flex u-flex-direction--column">
              <div *ngFor="let alert of connectAlertWithCompletion(report._id)"
                class="has-tooltip tooltip--bottom u-display--flex u-align-items--center">
                <span class="tooltip is-bottom">{{alert.desc}}</span>
                <coach-icon iconName="alert" iconColor="red" class="transition-fix"></coach-icon>
                <div *ngIf="!alert.isActive" class="u-display--flex u-align-items--center u-width--fit-content">
                  <coach-icon iconName="check" iconColor="red" class="transition-fix margin-right--smaller">
                  </coach-icon>
                  <p class="margin--none">Closed by <span
                      *ngIf="alert.inactiveByFullName">{{alert.inactiveByFullName}}</span><span
                      *ngIf="!alert.inactiveByFullName">[name not found]</span><span *ngIf="alert.inactiveAt">,
                      {{alert.inactiveAt | date: 'medium'}}</span></p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- SUGAR CHART -->
<div class="widget margin--large margin-bottom--none" [ngClass]="{'u-display--none': sugarChartSettings.chart == null}">
  <div class="header">
    <div class="titles t5 u-display--flex u-flex-direction--row u-align-items--center">
      <div *ngIf="!isMobile || !bpChartSettings.listToChartToggle"  class="title u-width--fit-content">Blood sugar</div>
      <div class="u-display--flex margin-left--large">
        <button class="button is-small u-border-right-radius--none"
          [ngClass]="{'is-primary': !sugarChartSettings.listToChartToggle}"
          (click)="sugarChartSettings.listToChartToggle = false">
          <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
        </button>
        <button class="button is-small u-border-left-radius--none"
          [ngClass]="{'is-primary': sugarChartSettings.listToChartToggle}"
          (click)="sugarChartSettings.listToChartToggle = true">
          <fa-icon class='fa-sm' [icon]="faList"></fa-icon>
        </button>
      </div>
    </div>
    <div class=" margin-left--large"
      [ngClass]="{'u-display--none': !sugarChartSettings.listToChartToggle, 'u-display--flex': sugarChartSettings.listToChartToggle}">
      <button class="button margin-right border--none is-small" (click)="export('csv', sugarChartSettings)">Get CSV</button>
      <button class="button margin-right border--none is-small" (click)="export('copy', sugarChartSettings)">Copy</button>
      <select class="is-small" id="sugarChartSettingsListDateRange" [(ngModel)]="sugarChartSettings.tableDisplayDays"
        name="selectdemo">
        <option value=30>30</option>
        <option value=60>60</option>
        <option value=90>90</option>
        <option value="all">All</option>
      </select>
    </div>
  </div>
  <div class="dynamicBody">
    <div class="reportChart sugarChart" [ngClass]="{'u-display--none': sugarChartSettings.listToChartToggle}"></div>
    <div class="tableWrapper" [ngClass]="{'u-display--none': !sugarChartSettings.listToChartToggle}">
      <table class="table u-width--full">
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>SUGAR</th>
            <th>Last ate</th>
            <th>Comment</th>
            <th>Alert</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of sugarChartSettings.reportsData"
            [ngClass]="{'u-display--none': !isDateInRange(report.date, sugarChartSettings.tableDisplayDays)}"
            [hidden]="!report._id">
            <td>{{report.date | date: 'medium'}}</td>
            <td>
              <strong>
                <span
                  [ngClass]="{'u-color--danger': report.bloodSugar < report.goal_sugar_bottom || report.sugar > report.goal_sugar_top || (connectAlertWithCompletion(report._id) && connectAlertWithCompletion(report._id).length > 0) }">
                  {{report.bloodSugar}}
                </span>
              </strong>
            </td>
            <td>{{report.lastAte}} {{report.lastAteUnits}}</td>
            <td>{{report.comment}}</td>
            <td class="u-display--flex u-flex-direction--column">
              <div *ngFor="let alert of connectAlertWithCompletion(report._id)"
                class="has-tooltip tooltip--bottom u-display--flex u-align-items--center">
                <span class="tooltip is-bottom">{{alert.desc}}</span>
                <coach-icon iconName="alert" iconColor="red" class="transition-fix"></coach-icon>
                <div *ngIf="!alert.isActive" class="u-display--flex u-align-items--center u-width--fit-content">
                  <coach-icon iconName="check" iconColor="red" class="transition-fix margin-right--smaller">
                  </coach-icon>
                  <p class="margin--none">Closed by <span
                      *ngIf="alert.inactiveByFullName">{{alert.inactiveByFullName}}</span><span
                      *ngIf="!alert.inactiveByFullName">[name not found]</span><span *ngIf="alert.inactiveAt">,
                      {{alert.inactiveAt | date: 'medium'}}</span></p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- WEIGHT CHART -->
<div class="widget margin--large margin-bottom--none"
  [ngClass]="{'u-display--none': weightChartSettings.chart == null}">
  <div class="header">
    <div class="titles t5 u-display--flex u-flex-direction--row u-align-items--center">
      <div *ngIf="!isMobile || !bpChartSettings.listToChartToggle"  class="title u-width--fit-content">weight</div>
      <div class="u-display--flex margin-left--large">
        <button class="button is-small u-border-right-radius--none"
          [ngClass]="{'is-primary': !weightChartSettings.listToChartToggle}"
          (click)="weightChartSettings.listToChartToggle = false">
          <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
        </button>
        <button class="button is-small u-border-left-radius--none"
          [ngClass]="{'is-primary': weightChartSettings.listToChartToggle}"
          (click)="weightChartSettings.listToChartToggle = true">
          <fa-icon class='fa-sm' [icon]="faList"></fa-icon>
        </button>
      </div>
    </div>
    <div class=" margin-left--large"
      [ngClass]="{'u-display--none': !weightChartSettings.listToChartToggle, 'u-display--flex': weightChartSettings.listToChartToggle}">
      <button class="button margin-right border--none is-small" (click)="export('csv', weightChartSettings)">Get CSV</button>
      <button class="button margin-right border--none is-small" (click)="export('copy', weightChartSettings)">Copy</button>
      <select class="is-small" id="weightChartSettingsListDateRange" [(ngModel)]="weightChartSettings.tableDisplayDays"
        name="selectdemo">
        <option value=30>30</option>
        <option value=60>60</option>
        <option value=90>90</option>
        <option value="all">All</option>
      </select>
    </div>
  </div>
  <div class="dynamicBody">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ">
    <div class="reportChart weightChart" [ngClass]="{'u-display--none': weightChartSettings.listToChartToggle}"></div>
    <div class="tableWrapper" [ngClass]="{'u-display--none': !weightChartSettings.listToChartToggle}">
      <table class="table u-width--full">
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Weight</th>
            <th>Comment</th>
            <th>Alert</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of weightChartSettings.reportsData"
            [ngClass]="{'u-display--none': !isDateInRange(report.date, weightChartSettings.tableDisplayDays)}"
            [hidden]="!report._id">
            <td>{{report.date | date: 'medium'}}</td>
            <td><strong><span
                  [ngClass]="{'u-color--danger': (report.goal_weight_bottom && report.weight < report.goal_weight_bottom )|| (report.goal_weight_top && report.weight > report.goal_weight_top) || (connectAlertWithCompletion(report._id) && connectAlertWithCompletion(report._id).length > 0) }">
                  {{report.weight}}
                  {{report.weightUnits}} </span></strong></td>
            <td>{{report.comment}}</td>
            <td class="u-display--flex u-flex-direction--column">
              <div *ngFor="let alert of connectAlertWithCompletion(report._id)"
                class="has-tooltip tooltip--bottom u-display--flex u-align-items--center">
                <span class="tooltip is-bottom">{{alert.desc}}</span>
                <coach-icon iconName="alert" iconColor="red" class="transition-fix"></coach-icon>
                <div *ngIf="!alert.isActive" class="u-display--flex u-align-items--center u-width--fit-content">
                  <coach-icon iconName="check" iconColor="red" class="transition-fix margin-right--smaller">
                  </coach-icon>
                  <p class="margin--none">Closed by <span
                      *ngIf="alert.inactiveByFullName">{{alert.inactiveByFullName}}</span><span
                      *ngIf="!alert.inactiveByFullName">[name not found]</span><span *ngIf="alert.inactiveAt">,
                      {{alert.inactiveAt | date: 'medium'}}</span></p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--SMS Charts-->
<div *ngIf="!showStandardSchedule && !showCustomSchedule">
<!-- COPD SMS CHART -->
<div class="widget margin--large margin-bottom--none" *ngIf="enrolledPrograms.hasCOPD">
  <div class="header">
    <div class="titles t5 u-display--flex u-flex-direction--row u-align-items--center u-justify-content--spaceBetween">
      <div class="u-display--flex u-flex-direction--row u-align-items--center">
        <div class="title u-width--fit-content"> COPD SMS Responses</div>
        <div class="u-display--flex margin-left--large">
          <button class="button is-small u-border-right-radius--none"
            [ngClass]="{'is-primary': !smsChartSettingsCOPD.listToChartToggle}"
            (click)="smsChartSettingsCOPD.listToChartToggle = false">
            <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
          </button>
          <button class="button is-small u-border-left-radius--none"
            [ngClass]="{'is-primary': smsChartSettingsCOPD.listToChartToggle}"
            (click)="smsChartSettingsCOPD.listToChartToggle = true">
            <fa-icon class='fa-sm' [icon]="faList"></fa-icon>
          </button>
        </div>
      </div> 
    </div>
    <div class="margin-left--large u-display--flex u-flex-direction--row u-align-items--center">
      <button [ngClass]="{'u-display--none': !smsChartSettingsCOPD.listToChartToggle}"
        class="button margin-right border--none is-small" (click)="export('csv', smsChartSettingsCOPD)">Get CSV</button>
      <button [ngClass]="{'u-display--none': !smsChartSettingsCOPD.listToChartToggle}"
        class="button margin-right border--none is-small" (click)="export('copy', smsChartSettingsCOPD)">Copy to
        clipboard</button>
    
      <select class="is-small u-width--medium" [(ngModel)]="smsChartSettingsCOPD.chartRange">
        <option [ngValue]="'4w'">Last 4 weeks</option>
        <option [ngValue]="'6m'">Last 6 months</option>
      </select>
    </div>
    <div *ngIf="isScheduleAdaptive || isScheduleCustom">
    <a (click)="toggleStandardSchedule()">
    <svg class="icon">
      <use href="assets/icons/Magicons.svg#icon-settings"></use>
    </svg>
    </a>
    </div>
  </div>

  <div class="reportChart" [ngClass]="{'u-display--none': smsChartSettingsCOPD.listToChartToggle}" id="smsChart4weeksCOPD" [hidden]="smsChartSettingsCOPD.chartRange != '4w'"></div>
  <div class="reportChart" [ngClass]="{'u-display--none': smsChartSettingsCOPD.listToChartToggle}" id="smsChart6monthCOPD" [hidden]="smsChartSettingsCOPD.chartRange != '6m'"></div>
  <div class="tableWrapper" [ngClass]="{'u-display--none': !smsChartSettingsCOPD.listToChartToggle}">
    <table class="table u-width--full" [hidden]="smsChartSettingsCOPD.chartRange != '4w'">
      <thead>
        <tr>
          <th>Date/Time</th>
          <th>Message</th>
          <th>Category</th>
          <th>Alert</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of smsChartSettingsCOPD.fourWeeksReports">
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">{{report.date | date: 'medium'}}</td>
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">{{report.msg}}</td>
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">{{report.type}}</td>
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">
            {{report.type == '2' ? 'Yes' : 'No'}}
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table u-width--full" [hidden]="smsChartSettingsCOPD.chartRange != '6m'">
      <thead>
        <tr>
          <th>Month</th>
          <th>1</th>
          <th>2</th>
          <th>Other</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of smsChartSettingsCOPD.sixMonthReports">
          <td>{{report.month}}</td>
          <td>{{report[1]}}</td>
          <td>{{report[2]}}</td>
          <td>{{report.Other}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br>
  <div class="container u-width--fit-content custom-schedule-description" *ngIf="isScheduleCustom && !isScheduleStopped">
    <p class="margin-right" id = "schedule-info"> <b>  CUSTOM  SCHEDULE: </b> </p>
    <p lass="margin-leftRight--small"> {{customScheduleSettings.frequency}} </p>
    <p class="margin-leftRight--small"> Started: {{customScheduleSettings.startDate}} </p>
    <p class="margin-leftRight--small"> | </p>
    <p> <a  (click)="toggleStandardSchedule()"> Edit </a> </p>
   </div>

 <div class="container u-width--fit-content" *ngIf="smsChartSettingsCOPD.scheduleSettings && !isScheduleCustom">
  <p class="margin-right--none" id = "schedule-info"> <b> SCHEDULE </b> (Step {{smsChartSettingsCOPD.scheduleSettings.currentStep}} of {{smsChartSettingsCOPD.scheduleSettings.totalSteps}}) :  {{smsChartSettingsCOPD.scheduleSettings.frequency}} for {{smsChartSettingsCOPD.scheduleSettings.duration.period}} {{smsChartSettingsCOPD.scheduleSettings.duration.units}}.  Started : {{smsChartSettingsCOPD.scheduleSettings.startDate}} </p>
  <p class="margin-left--none" *ngIf="smsChartSettingsCOPD.scheduleSettings.endDate">- {{smsChartSettingsCOPD.scheduleSettings.endDate}}  </p>
  <p>  | </p>
  <p> <a  (click)="toggleStandardSchedule()"> Edit </a> </p>
 </div>

 <div class="container u-width--fit-content u-pa custom-schedule-description" *ngIf="customScheduleSettings?.stoppedBy && isScheduleStopped">
  <div class="redContainer">
    <p class="margin-right" id = "schedule-info">   Stopped by:  {{customScheduleSettings?.stoppedBy}} on {{customScheduleSettings?.stoppedAt}} </p>
    <p class="">  | </p>
    <p> <a  (click)="toggleStandardSchedule()"> Edit </a> </p>
  </div>
 </div>

<br>
</div>
<!-- HF SMS CHART-->
<div class="widget margin--large" *ngIf="enrolledPrograms.hasHF">
  <div class="header">
    <div class="titles t5 u-display--flex u-flex-direction--row u-align-items--center u-justify-content--spaceBetween">
      <div class="u-display--flex u-flex-direction--row u-align-items--center">
        <div class="title u-width--fit-content"> Heart Failure SMS Responses</div>
        <div class="u-display--flex margin-left--large">
          <button class="button is-small u-border-right-radius--none"
            [ngClass]="{'is-primary': !smsChartSettingsHF.listToChartToggle}"
            (click)="smsChartSettingsHF.listToChartToggle = false">
            <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
          </button>
          <button class="button is-small u-border-left-radius--none"
            [ngClass]="{'is-primary': smsChartSettingsHF.listToChartToggle}"
            (click)="smsChartSettingsHF.listToChartToggle = true">
            <fa-icon class='fa-sm' [icon]="faList"></fa-icon>
          </button>
        </div>
      </div> 
    </div>
    <div class="margin-left--large u-display--flex u-flex-direction--row u-align-items--center">
      <button [ngClass]="{'u-display--none': !smsChartSettingsHF.listToChartToggle}"
        class="button margin-right border--none is-small" (click)="export('csv', smsChartSettingsHF)">Get CSV</button>
      <button [ngClass]="{'u-display--none': !smsChartSettingsHF.listToChartToggle}"
        class="button margin-right border--none is-small" (click)="export('copy', smsChartSettingsHF)">Copy to
        clipboard</button>
    
      <select class="is-small u-width--medium" [(ngModel)]="smsChartSettingsHF.chartRange">
        <option [ngValue]="'4w'">Last 4 weeks</option>
        <option [ngValue]="'6m'">Last 6 months</option>
      </select>
    </div>
    <div *ngIf="isScheduleAdaptive || isScheduleCustom">
      <a (click)="toggleStandardSchedule()">
      <svg class="icon">
        <use href="assets/icons/Magicons.svg#icon-settings"></use>
      </svg>
      </a>
      </div>
  </div>

  <div class="reportChart" [ngClass]="{'u-display--none': smsChartSettingsHF.listToChartToggle}" id="smsChart4weeksHF" [hidden]="smsChartSettingsHF.chartRange != '4w'"></div>
  <div class="reportChart" [ngClass]="{'u-display--none': smsChartSettingsHF.listToChartToggle}" id="smsChart6monthHF" [hidden]="smsChartSettingsHF.chartRange != '6m'"></div>
  <div class="tableWrapper" [ngClass]="{'u-display--none': !smsChartSettingsHF.listToChartToggle}">
    <table class="table u-width--full" [hidden]="smsChartSettingsHF.chartRange != '4w'">
      <thead>
        <tr>
          <th>Date/Time</th>
          <th>Message</th>
          <th>Category</th>
          <th>Alert</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of smsChartSettingsHF.fourWeeksReports">
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">{{report.date | date: 'medium'}}</td>
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">{{report.msg}}</td>
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">{{report.type}}</td>
          <td [ngClass]="{'u-color--brand--3': report.type == '2'}">
            {{report.type == '2' ? 'Yes' : 'No'}}
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table u-width--full" [hidden]="smsChartSettingsHF.chartRange != '6m'">
      <thead>
        <tr>
          <th>Month</th>
          <th>1</th>
          <th>2</th>
          <th>Other</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of smsChartSettingsHF.sixMonthReports">
          <td>{{report.month}}</td>
          <td>{{report[1]}}</td>
          <td>{{report[2]}}</td>
          <td>{{report.Other}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br>
  <div class="container  u-width--fit-content custom-schedule-description" *ngIf="isScheduleCustom && !isScheduleStopped">
    <p class="margin-right" id = "schedule-info"> <b>  CUSTOM  SCHEDULE: </b> </p>
    <p lass="margin-leftRight--small"> {{customScheduleSettings.frequency}} </p>
    <p class="margin-leftRight--small"> Started: {{customScheduleSettings.startDate}} </p>
    <p class="margin-leftRight--small"> | </p>
    <p> <a  (click)="toggleStandardSchedule()"> Edit </a> </p>
   </div>
  <div class="container u-width--fit-content" *ngIf="smsChartSettingsHF.scheduleSettings && !smsChartSettingsHF.scheduleSettings.stoppedDate">
    <p class="margin-right--none" id = "schedule-info"> SCHEDULE (Step {{smsChartSettingsHF.scheduleSettings.currentStep}} of {{smsChartSettingsHF.scheduleSettings.totalSteps}}) :  {{smsChartSettingsHF.scheduleSettings.frequency}} for {{smsChartSettingsHF.scheduleSettings.duration.period}} {{smsChartSettingsHF.scheduleSettings.duration.units}}.  Started : {{smsChartSettingsHF.scheduleSettings.startDate}} </p>
    <p class="margin-left--none" *ngIf="smsChartSettingsHF.scheduleSettings.endDate">- {{smsChartSettingsHF.scheduleSettings.endDate}}</p>
    <p> | </p>
    <p> <a  (click)="toggleStandardSchedule()"> Edit </a> </p>
   </div>
   <div class="container u-width--fit-content custom-schedule-description" *ngIf="customScheduleSettings?.stoppedBy && isScheduleStopped">
    <p class="margin-right" id = "schedule-info">   Stopped by:  {{customScheduleSettings?.stoppedBy}} on {{customScheduleSettings?.stoppedAt}} | <a  (click)="toggleStandardSchedule()"> Edit </a></p>
   </div>
<br>
</div>
</div>

<!--Standard Schedule-->
<coach-sms-standard-schedule (onCancel)="hideStandardSchedule($event)" [program]="scheduleProgram" [phases]="schedulePhases" *ngIf="showStandardSchedule"> </coach-sms-standard-schedule>

<!--Custom Schedule-->
<coach-custom-schedule (cancelClicked)="handleCancel()" [program]="scheduleProgram" [patient]="patient" [phases]="schedulePhases" [adaptivephases]="Adaptivephases" [customSchedulesettings]="customScheduleSettings" [stoppedbyUser]="stoppedByUser" [isScheduleStopped]="isScheduleStopped" *ngIf="showCustomSchedule"> </coach-custom-schedule>


 