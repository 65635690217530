import { RecentLogData } from '@shared/services/router-history/router-history.service';
import { IRecentMatcher } from './recent-matcher';
export class PrescriptionsMatcher implements IRecentMatcher {
  match(url: string): boolean {
    if (url.includes('care/prescriptions')) {
      return true;
    } else {
      return false;
    }
  }

  createLog(url: string): RecentLogData {
    return {
      url,
      elements: [],
    };
  }

  title(url: string): string {
    if (url.includes('-processing')) {
      return 'Process 340B';
    } else if (url.includes('-invoicing')) {
      return 'Invoice 340B';
    } else if (url.includes('-exceptions')) {
      return 'Pre-validation Exceptions';
    } else {
      return 'Validate 340B';
    }
  }
}
