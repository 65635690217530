<div class="alertsAndMessages-header  u-preserve timeTracker">
  <!-- DESKTOP -->
  <div  *ngIf="desktopSize" class="header desktop-responsive u-min-width--large borderless padding-leftRight--large padding-topBottom--none ">
    <div class="titles">
      <div class="title">
        Time Tracker
      </div>
    </div>
  </div>
  <div *ngIf="desktopSize" class="list desktop-responsive borderless u-width--large">
    <div class="list-item list-item--complex">
      <div class="list-item timer-preview-wrapper" *ngIf="!differenceCounter">
        <div class="total-minutes-running-lg">
          <svg viewBox="0 0 42 42" class="donut" style="position:absolute;">
            <circle class="donut-hole" cx="21" cy="21" r="15.915494309" fill="#fff"></circle>
            <circle class="donut-ring" cx="21" cy="21" r="15.915494309" fill="transparent" stroke-width="5"></circle>
            <circle class="donut-segment total-minutes" cx="21" cy="21" r="15.915494309" fill="transparent"
              stroke-width="5" stroke-dashoffset="25"
              attr.stroke-dasharray="{{timeTrackerTotal*100/60}} {{100/60*(60-timeTrackerTotal)}}"
              [ngClass]="{'limit-reached': timeTrackerTotal > 20}"></circle>
          </svg>
          <div class="total-minutes-indicator">
            <div class="total-minutes-text">
              <div class="margin-bottom--small" *ngIf="!timeTrackerEvents_loadingStatus">{{ timeTrackerTotal  }}</div>
              <div class="loader margin-bottom--small" *ngIf="timeTrackerEvents_loadingStatus"></div>
            </div>
            <div class="total-minutes-unit">min</div>
          </div>
        </div>
      </div>
      <div class="list-item-main rows_justify_bottom margin-topBottom--none" style="margin-top: 0px"
        *ngIf="!differenceCounter">
        <h5 class="u-text-align--center margin-topBottom--none">
          {{startOfMonth | date: 'MMM, yyyy' }}
        </h5>
        <button class="row button border--none is-small" (click)="openModal('modalViewDetailsDisplayBool')">
          View Details
        </button>
      </div>
      <!-- Action buttons -->
      <div class="list-item-main" *ngIf="differenceCounter && newTimeTrackingEvent">
        <div class="button-group row borderless">
          <div class="button-group-element">
            <button class="button is-small button-group-element"
              [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'chart'}"
              (click)="newTimeTrackingEvent.activity = 'chart'">
              <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
            </button>
          </div>
          <div class="button-group-element">
            <button class="button is-small button-group-element"
              [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'email'}"
              (click)="newTimeTrackingEvent.activity = 'email'">
              <fa-icon class='fa-sm' [icon]="faEnvelope"></fa-icon>
            </button>
          </div>
          <div class="button-group-element">
            <button class="button is-small button-group-element"
              [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'phone'}"
              (click)="newTimeTrackingEvent.activity = 'phone'">
              <fa-icon class='fa-sm' [icon]="faPhone"></fa-icon>
            </button>
          </div>
          <div class="button-group-element">
            <button class="button is-small button-group-element"
              [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'sms'}"
              (click)="newTimeTrackingEvent.activity = 'sms'">
              <fa-icon class='fa-sm' [icon]="faSms"></fa-icon>
            </button>
          </div>
          <div class="button-group-element">
            <button class="button is-small button-group-element padding-leftRight--small"
              [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'other'}"
              (click)="newTimeTrackingEvent.activity = 'other'">...</button>
          </div>
        </div> 
        <textarea [maxlength]="commentMaxLength" [(ngModel)]="newTimeTrackingEvent.notes" placeholder="Comments (optional)"></textarea>
        <div class="text-right subtitle"><small>{{newTimeTrackingEvent.notes.length}}/{{commentMaxLength}}</small></div>
      </div>
      <div class="list-item u-display--flex u-justify-content--center">
        <!-- IF COUNTER IS OFF -->
        <div *ngIf="!differenceCounter" class="u-display--flex u-justify-content--center">
          <!-- start counter button -->
          <button class="button margin-right--small" (click)='startTimer()'>Start</button>
          <!-- add time manually -->
          <button class="button button-icon-minor padding-leftRight--small" (click)="openModal('modalAddTimeDisplayBool')">
            <span style="height: 90%;">
              <svg class="icon icon-minor">
                <use href="assets/icons/Magicons.svg#icon-plus"></use>
              </svg>
            </span>
            <svg
            class="icon">
            <use href="assets/icons/Magicons.svg#icon-clock"></use>
          </svg>
          </button>
        </div>
        <!-- IF COUNTER IS ON -->
        <div *ngIf="differenceCounter">
          <div class="total-minutes-running" >
            <svg viewBox="0 0 42 42" class="donut" style="position:absolute;">
              <circle class="donut-hole" cx="21" cy="21" r="15.915494309" fill="#fff"></circle>
              <circle class="donut-ring" cx="21" cy="21" r="15.915494309" fill="transparent" stroke-width="5"></circle>
              <circle class="donut-segment total-minutes" cx="21" cy="21" r="15.915494309" fill="transparent"
                stroke-width="5" stroke-dashoffset="25"
                attr.stroke-dasharray="{{timeTrackerTotal*100/60}} {{100/60*(60-timeTrackerTotal)}}"
                [ngClass]="{'limit-reached': timeTrackerTotal > 20}"></circle>
            </svg>
            <div class="total-minutes-indicator">
              <div class="total-minutes-text">{{ timeTrackerTotal  }}</div>
              <div class="total-minutes-unit">min</div>
            </div>
            
          </div>
          <button class="button trackingButton is-primary is-small" (click)='stopTimer(false)'>
            <div class="trackingButtonLabel">
              <span class="trackingButtonLabelText">TRACKING</span>
              <span class="trackingButtonLabelCounter">{{differenceCounter | date: 'mm:ss' }}</span>
            </div>
            <div>
              <fa-icon [icon]="faStop" size="sm"></fa-icon>
            </div>
          </button>
        </div>
        
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <div *ngIf="!desktopSize" class="mobile-responsive">
    <div class="timer-preview-wrapper-mobile padding--none" *ngIf="!differenceCounter" (click)="openScopeSelectDropdown()" #scopeSelectOrigin>
      <div class="total-minutes-running-lg u-cursor--pointer">
        <svg viewBox="0 0 42 42" class="donut roundBorder" style="position:absolute;">
          <circle class="donut-hole" cx="21" cy="21" r="15.915494309" fill="#fff"></circle>
          <circle class="donut-ring" cx="21" cy="21" r="15.915494309" fill="transparent" stroke-width="5"></circle>
          <circle class="donut-segment total-minutes" cx="21" cy="21" r="15.915494309" fill="transparent"
            stroke-width="5" stroke-dashoffset="25"
            attr.stroke-dasharray="{{timeTrackerTotal*100/60}} {{100/60*(60-timeTrackerTotal)}}"
            [ngClass]="{'limit-reached': timeTrackerTotal > 20}"></circle>
        </svg>
        <div class="total-minutes-indicator">
          <div class="total-minutes-text">
            <div class="margin-bottom--small" *ngIf="!timeTrackerEvents_loadingStatus">{{ timeTrackerTotal  }}</div>
            <div class="loader margin-bottom--small" *ngIf="timeTrackerEvents_loadingStatus"></div>
          </div>
          <div class="total-minutes-unit">min</div>
        </div>
      </div>
    </div>
    <div class="timer-preview-wrapper-mobile" *ngIf="differenceCounter">
      <button class="button trackingButton is-primary" (click)='stopTimer(true)'>
        <div class="trackingButtonLabel">
          <span class="trackingButtonLabelText">TRACKING</span>
          <span class="trackingButtonLabelCounter">{{differenceCounter | date: 'mm:ss' }}</span>
        </div>
        <div>
          <fa-icon [icon]="faStop" size="sm"></fa-icon>
        </div>
      </button>
    </div>
  </div>
  <ng-template
  cdkConnectedOverlay >
    <ng-template 
      [cdkPortalOutlet]="scopeSelectDropdownPortal"
    ></ng-template>
  </ng-template>


  <!-- MODALS -->

  <!-- add time modal -->
  <ng-container *ngIf="modalAddTimeDisplayBool">
    <div class="overlay is-open" role="modal-background">
      <div class="modal u-width--larger">
        <div class="header">
          <div class="titles">
            <div class="title">Add Time</div>
            <div class="subtitle">Time Tracker</div>
          </div>
        </div>
        <div class="dynamicBody padding">
          <div class="row modalFormRow margin-bottom--small">
            <label class="label" for="trackingAddTimeDate">Date</label>
            <input class="u-width--medium" id="trackingAddTimeDate" [(ngModel)]="newTimeTrackingEvent.startDatePicker"
              placeholder="date..." type="date" min="{{startOfMonth|date:'yyyy-MM-dd'}}" max="{{today|date:'yyyy-MM-dd'}}">
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Minutes</label>
            <div class="row">
              <input class="u-width--smallest margin-right--small" [(ngModel)]="newTimeTrackingEvent.durationMinsText"
              type="text" minlength="1" maxlength="3" pattern="^[1-9][0-9]{0,2}$"
              style="width: 8rem">
              <button class="button is-small margin-right--small"
                (click)="addTimeToNewEvent(1);">+1</button>
              <button class="button is-small margin-right--small"
                (click)="addTimeToNewEvent(5)">+5</button>
              <button class="button is-small margin-right--small"
                (click)="addTimeToNewEvent(10)">+10</button>
            </div>
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Activity</label>
            <div class="button-group row borderless" style="width: fit-content;">
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'chart'}"
                  (click)="newTimeTrackingEvent.activity = 'chart'">
                  <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'email'}"
                  (click)="newTimeTrackingEvent.activity = 'email'">
                  <fa-icon class='fa-sm' [icon]="faEnvelope"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'phone'}"
                  (click)="newTimeTrackingEvent.activity = 'phone'">
                  <fa-icon class='fa-sm' [icon]="faPhone"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'sms'}"
                  (click)="newTimeTrackingEvent.activity = 'sms'">
                  <fa-icon class='fa-sm' [icon]="faSms"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element padding-leftRight--small"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'other'}"
                  (click)="newTimeTrackingEvent.activity = 'other'">...</button>
              </div>
            </div>
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Comments</label>
            <textarea [maxlength]="commentMaxLength" [(ngModel)]="newTimeTrackingEvent.notes" placeholder="Comments (optional)"></textarea>
            <div class="text-right subtitle"><small>{{newTimeTrackingEvent.notes.length}}/{{commentMaxLength}}</small></div>
          </div>
        </div>
        <div class="footer">
          <button class="button margin-right border--none" (click)="modalAddTimeDisplayBool = false">Close</button>
          <button class="button margin-right is-info" [ngClass]="{'u-disabled': disableNewEventSave}"
            [disabled]="disableNewEventSave"
            (click)="!disableNewEventSave && updateEntry(newTimeTrackingEvent, 'direct')">Save & New</button>
          <button class="button is-primary" [ngClass]="{'u-disabled': disableNewEventSave}"
            [disabled]="disableNewEventSave"
            (click)="!disableNewEventSave && updateEntry(newTimeTrackingEvent, 'direct') && modalAddTimeDisplayBool = false">Save</button>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- edit time modal -->
  <ng-container *ngIf="modalEditTimeDisplayBool">
    <div class="overlay is-open" role="modal-background">
      <div class="modal u-width--larger">
        <div class="header">
          <div class="titles">
            <div class="title">Edit Time</div>
            <div class="subtitle">Time Tracker</div>
          </div>
        </div>
        <!-- <div class="dynamicBody padding">{{modalEditTimeTarget}}</div> -->
        <div class="dynamicBody padding">
          <div class="row modalFormRow margin-bottom--small">
            <label class="label" for="trackingAddTimeDate">Date</label>
            <input class="u-width--medium" id="trackingAddTimeDate"
              [(ngModel)]="currentTimeTrackingEvent.startDatePicker" placeholder="date..." type="date"
              min="{{startOfMonth|date:'yyyy-MM-dd'}}" max="{{today|date:'yyyy-MM-dd'}}">
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Minutes</label>
            <div class="row">
              <input class="u-width--smallest margin-right--small" [(ngModel)]="currentTimeTrackingEvent.durationMinsText"
                type="text" minlength="1" maxlength="3" pattern="^[1-9][0-9]{0,2}$" style="width: 8rem">
              <button class="button is-small margin-right--small"
                (click)="addTimeToCurrentEvent(1)">+1</button>
              <button class="button is-small margin-right--small"
                (click)="addTimeToCurrentEvent(5)">+5</button>
              <button class="button is-small margin-right--small"
                (click)="addTimeToCurrentEvent(10)">+10</button>
            </div>
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Activity</label>
            <div class="button-group row borderless" style="width: fit-content;">
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': currentTimeTrackingEvent.activity == 'chart'}"
                  (click)="currentTimeTrackingEvent.activity = 'chart'">
                  <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': currentTimeTrackingEvent.activity == 'email'}"
                  (click)="currentTimeTrackingEvent.activity = 'email'">
                  <fa-icon class='fa-sm' [icon]="faEnvelope"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': currentTimeTrackingEvent.activity == 'phone'}"
                  (click)="currentTimeTrackingEvent.activity = 'phone'">
                  <fa-icon class='fa-sm' [icon]="faPhone"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': currentTimeTrackingEvent.activity == 'sms'}"
                  (click)="currentTimeTrackingEvent.activity = 'sms'">
                  <fa-icon class='fa-sm' [icon]="faSms"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element padding-leftRight--small"
                  [ngClass]="{'is-primary': currentTimeTrackingEvent.activity == 'other'}"
                  (click)="currentTimeTrackingEvent.activity = 'other'">...</button>
              </div>
            </div>
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Comments</label>
            <textarea [maxlength]="commentMaxLength" [(ngModel)]="currentTimeTrackingEvent.notes" placeholder="Comments (optional)"></textarea>
            <div class="text-right subtitle"><small>{{currentTimeTrackingEvent.notes.length}}/{{commentMaxLength}}</small></div>
          </div>
        </div>
        <div class="footer">
          <button class="button margin-right border--none"
            (click)="modalEditTimeDisplayBool = false; openModal('modalViewDetailsDisplayBool')">Close</button>
            <button class="button margin-right border--none"
            (click)="OnDeleteEntryClick()">Delete</button>
          <button class="button is-primary"
            [ngClass]="{'u-disabled': disableCurrentEventSave}"
            [disabled]="disableCurrentEventSave"
            (click)="!disableCurrentEventSave && updateEntry(currentTimeTrackingEvent,'direct') && modalEditTimeDisplayBool=false && openModal('modalViewDetailsDisplayBool')">
            Save
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- view details modal -->
  <ng-container *ngIf="modalViewDetailsDisplayBool">
    <div class="overlay is-open" role="modal-background">
      <div class="modal u-width--largest">
        <div class="header">
          <div class="titles">
            <div class="list-item list-item--complex">
              <button class="button border--none list-item-action" [ngClass]="{'u-disabled': timeTrackerEvents_loadingStatus}" [disabled]="timeTrackerEvents_loadingStatus" (click)="selectMonth(1)">
                &#x027E8;
              </button>
              <div class="list-item-main">
                <div class="row">
                  <div class="title">{{startOfMonth | date: 'MMMM yyyy' }} <div class="loader margin-left--small" *ngIf="timeTrackerEvents_loadingStatus"></div></div>
                </div>
                <div class="row">
                  <div class="subtitle">Time Tracker</div>
                </div>
              </div>
              <button class="button border--none list-item-action" [ngClass]="{'u-disabled': timeTrackerEvents_loadingStatus}" [disabled]="timeTrackerEvents_loadingStatus" (click)="selectMonth(-1)">
                &#x027E9;
              </button>
            </div>
            <!-- <div class="title"></div>
            <div class="subtitle"></div> -->
          </div>
        </div>
        <div class="dynamicBody padding">
          <div class="d4 text-center" *ngIf="getObjectLength(timeTrackerEvents) < 1">No data found</div>
          <div *ngIf="getObjectLength(timeTrackerEvents) > 0">
            <div class="list" *ngFor="let day of timeTrackerEvents | keyvalue">
              <div class="subheader">{{day.value.date| date}}</div>
              <div class="list-item list-item--complex" *ngFor="let event of day.value.events">
                <div class="list-item-main">
                  <div class="row">
                    <div class="field">{{timeTrackerActionLabels[event.activity]}}</div>
                    <div class="field">{{roundUpMinutes(event.durationSeconds, 'sec')}} mins</div>
                  </div>
                  <div class="row">
                    <div id = "event_notes_1" class="field u-italic">{{event.notes}}</div>
                  </div>
                  <div class="row" *ngIf="getCreatorFullNameAsString(event.createdByName)">
                    <div class="field u-italic">by {{getCreatorFullNameAsString(event.createdByName)}}</div>
                  </div>
                </div>
                <div class="list-item-action" (click)="openEditModal(event)">
                  <coach-icon iconName="edit" iconColor="currentColor" class="u-flex--top"></coach-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
           <!-- DeletedTimeTrackerEvents-->
           <!--Accordion   -->
           <div class="dynamicBody">
           <div class="accordion " [class.is-open]="showDeleted" *ngIf="getObjectLength(deletedTimeTrackerEvents) > 0"  >
            <div class="header u-align-items--center">
              <label class="margin-left--largest"> Deleted TimeTracker Events ({{ totalNoOfDeletedEvents}} deleted)</label>
              <!-- accordion button is always last (see _accordion.scss) -->
              <button class="button is-small margin-right--small" (click)="showDeleted = !showDeleted"></button>
            </div>
            <div class="dynamicBody padding" *ngIf="showDeleted">
              <div *ngIf="getObjectLength(deletedTimeTrackerEvents) > 0">
                <div class="list" *ngFor="let day of deletedTimeTrackerEvents | keyvalue">
                  <div class="subheader">{{day.value.date| date}}</div>
                  <div class="list-item list-item--complex" *ngFor="let event of day.value.events">
                    <div class="list-item-main">
                      <div class="row">
                        <div class="field">{{timeTrackerActionLabels[event.activity]}}</div>
                        <div class="field">{{roundUpMinutes(event.durationSeconds, 'sec')}} mins</div>
                      </div>
                      <div class="row">
                        <div id = "event_notes_2" class="field u-italic">{{event.notes}}</div>
                      </div>
                      <div class="row" *ngIf="getCreatorFullNameAsString(event.createdByName)">
                        <div class="field u-italic">by {{getCreatorFullNameAsString(event.createdByName)}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
           <!--End Accordion-->
        <div class="footer">
          <button class="button margin-right border--none" (click)="export('csv')">Get CSV</button>
          <button class="button margin-right border--none" (click)="export('copy')">Copy to clipboard</button>
          <button class="button margin-right border--none" (click)="modalViewDetailsDisplayBool = false">Close</button>
          <button class="button margin-right is-primary"
            (click)="modalViewDetailsDisplayBool = false; openModal('modalAddTimeDisplayBool')">Add new entry</button>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- message modal -->
  <ng-container *ngIf="modalMessageDisplayBool">
    <div class="overlay is-open" role="modal-background">
      <div class="modal u-width--larger">
        <div class="header">
          <div class="titles">
            <div class="title" *ngIf="modalMessage.title">{{modalMessage.title}}</div>
          </div>
        </div>
        <div class="dynamicBody padding" *ngIf="modalMessage.body">
          <p *ngFor="let message of modalMessage.body | keyvalue">{{message.key}}: {{message.value}}</p>
        </div>
        <div class="footer">
          <button class="button is-primary" (click)="modalMessageDisplayBool = false">Close</button>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- mobile endTimer modal -->
  <ng-container *ngIf="modalMobileEndTimerBool">
    <div class="overlay is-open" role="modal-background">
      <div class="modal u-width--larger">
        <div class="header">
          <div class="titles">
            <div class="subtitle">Time Total {{roundUpMinutes(newTimeTrackingEvent.durationSeconds, 'sec')}}</div>
          </div>
        </div>
        <div class="dynamicBody padding">
          <div class="row modalFormRow margin-bottom--small">
            <label class="label" for="trackingAddTimeDate">Date</label>
            <input class="u-width--medium" id="trackingAddTimeDate" [(ngModel)]="newTimeTrackingEvent.startDatePicker"
              placeholder="date..." type="date" min="{{startOfMonth|date:'yyyy-MM-dd'}}" max="{{today|date:'yyyy-MM-dd'}}">
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Activity</label>
            <div class="button-group row borderless" style="width: fit-content;">
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'chart'}"
                  (click)="newTimeTrackingEvent.activity = 'chart'">
                  <fa-icon class='fa-sm' [icon]="faChartLine"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'email'}"
                  (click)="newTimeTrackingEvent.activity = 'email'">
                  <fa-icon class='fa-sm' [icon]="faEnvelope"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'phone'}"
                  (click)="newTimeTrackingEvent.activity = 'phone'">
                  <fa-icon class='fa-sm' [icon]="faPhone"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'sms'}"
                  (click)="newTimeTrackingEvent.activity = 'sms'">
                  <fa-icon class='fa-sm' [icon]="faSms"></fa-icon>
                </button>
              </div>
              <div class="button-group-element">
                <button class="button is-small button-group-element padding-leftRight--small"
                  [ngClass]="{'is-primary': newTimeTrackingEvent.activity == 'other'}"
                  (click)="newTimeTrackingEvent.activity = 'other'">...</button>
              </div>
            </div>
          </div>
          <div class="row modalFormRow margin-bottom--small">
            <label class="label">Comments</label> 
            <textarea [maxlength]="commentMaxLength" [(ngModel)]="newTimeTrackingEvent.notes" placeholder="Comments (optional)"></textarea>
            <div class="text-right subtitle"><small>{{newTimeTrackingEvent.notes.length}}/{{commentMaxLength}}</small></div>
          </div>
        </div>
        <div class="footer">
          <button class="button margin-right border--none" (click)="modalMobileEndTimerBool = false">Close</button>
          <button class="button is-primary" [ngClass]="{'u-disabled': !newTimeTrackingEvent.startDatePicker}"
            [disabled]="!newTimeTrackingEvent.startDatePicker"
            (click)="updateEntry(newTimeTrackingEvent, 'timer'); modalMobileEndTimerBool = false">Save</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>