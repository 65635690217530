<div class="dropdown margin-left--small margin-bottom--small">
  <div class="list">
      <div class="list-item">
        <div class="row">
          <div class="field">Account Details</div>
        </div>
      </div>
      <div class="list-item">
        <div class="row">
          <div class="field">Change Password</div>
        </div>
      </div>
      <div class="list-item">
        <div class="row">
          <div class="field">Change Picture</div>
        </div>
      </div>
      <div class="list-item">
        <div class="row">
          <div class="field">Sign Out</div>
        </div>
      </div>
  </div>
</div>
