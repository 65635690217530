<ng-container *doesNotHaveFeatureFlag="FacesheetUIUpdatesFeatureFlag">
  <div class="care-gaps-grid u-background-color--white margin--large u-border-radius border--2" *ngIf="isLoaded">
    <div class="border-right border-bottom padding--larger">
      <div class="titles t4 margin-bottom">
        <div class="title">Population Health Initiatives</div>
      </div>
      <div class="group">
        <div class="label">Health Maintenance</div>
        <ul>
          <li>
            <div class="row">
              <div class="u-fill">Annual Wellness Visit</div>
              <div class="u-preserve margin-left"
                [ngClass]="{'u-color--brand--2': !(maintenance.awvLastVisit && maintenance.awvLastVisit.length > 0)}">
                <span *ngIf="maintenance.awvLastVisit && maintenance.awvLastVisit.length > 0">
                  <span class="u-color--brand--2">({{getDateDifference(maintenance.awvLastVisit)}})</span>
                  {{maintenance.awvLastVisit | date:'MM/dd/yyyy'}}</span>
                <span *ngIf="!(maintenance.awvLastVisit && maintenance.awvLastVisit.length > 0)">--/--/----</span>
              </div>
            </div>
            <ul *ngIf="awvDetails != null && (awvDetails.lastDate || awvDetails.provider)">
              <li>
                <div class="row">
                  <div>{{awvDetails.procCodeList}} on {{awvDetails.lastDate | date:'MM/dd/yyyy'}} with
                    {{awvDetails.provider ? awvDetails.provider : 'unknown'}}</div>
                </div>
              </li>
            </ul>
          </li>
          <!-- Need dev to implement this section -->
          <li>
            <div class="row">
              <div class="u-fill">Advance Care Planning</div>
              <div class="u-preserve margin-left"
                [ngClass]="{'u-color--brand--2': !(maintenance.acpLastVisit && maintenance.acpLastVisit.length > 0)}">
                <span *ngIf="maintenance.acpLastVisit && maintenance.acpLastVisit.length > 0">
                  <span class="u-color--brand--2">({{getDateDifference(maintenance.acpLastVisit)}})</span>
                  {{maintenance.acpLastVisit| date:'MM/dd/yyyy'}}</span>
                <span *ngIf="!(maintenance.acpLastVisit && maintenance.acpLastVisit.length > 0)">--/--/----</span>
              </div>
            </div>
            <ul *ngIf="acpDetails != null && (acpDetails.lastDate || acpDetails.provider)">
              <li>
                <div class="row">
                  <div>{{acpDetails.procCodeList}} on {{acpDetails.lastDate | date:'MM/dd/yyyy'}} with
                    {{acpDetails.provider ? acpDetails.provider : 'unknown'}}</div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="group">
        <div class="label">Chronic Care Management</div>
        <ul>
          <li>
            <div class="row">
              <div class="u-fill">Last 6 Months</div>
              <div class="u-preserve margin-left"
                [ngClass]="{'u-color--brand--2': !(maintenance.ccmLastVisit && maintenance.ccmLastVisit.length > 0)}">
                <span *ngIf="maintenance.ccmLastVisit && maintenance.ccmLastVisit.length > 0">
                  <span class="u-color--brand--2">({{getDateDifference(maintenance.ccmLastVisit)}})</span>
                  {{maintenance.ccmLastVisit| date:'MM/dd/yyyy'}}</span>
                <span *ngIf="!(maintenance.ccmLastVisit && maintenance.ccmLastVisit.length > 0)">--/--/----</span>
              </div>
            </div>
            <ul *ngIf="ccmDetails !== null">
              <li *ngFor="let details of ccmDetails">
                <div class="row">
                  <div>{{ details.procCodeList }} on {{ details.lastDate | date:'MM/dd/yyyy' }} with {{ details.provider
                    ? details.provider : 'unknown' }}</div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="border-bottom padding--larger">
      <div class="titles t4 margin-bottom">
        <div class="title">Preventive Care + Utilization</div>
      </div>
      <div class="group">
        <div class="label">Preventive Care</div>
        <ul>
          <li *ngFor="let vaccine of currentVaccinations">
            <div class="row">
              <div class="u-fill">{{vaccine.name}}</div>
              <div class="u-preserve margin-left"
                [ngClass]="{'u-color--brand--2': !(vaccine.receivedDate && vaccine.receivedDate.length > 0)}">
                <span *ngIf="vaccine.receivedDate && vaccine.receivedDate.length > 0">
                  <span class="u-color--brand--2">({{getDateDifference(vaccine.receivedDate)}})</span>
                  {{vaccine.receivedDate| date:'MM/dd/yyyy'}}</span>
                <span *ngIf="!(vaccine.receivedDate && vaccine.receivedDate.length > 0)">--/--/----</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="group">
        <div class="label">Utilization</div>
        <ul>
          <li>
            <div class="row">
              <div class="u-fill">Health Costs <span class="u-opacity--lighter">(12 mo)</span></div>
              <div class="u-preserve margin-left">{{costUtilization.totalCost | currency}}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="u-fill">ED Visits <span class="u-opacity--lighter">(15 mo)</span></div>
              <div class="u-preserve margin-left">{{costUtilization.edVisits}}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="u-fill">Inpatient Visits <span class="u-opacity--lighter">(15 mo)</span></div>
              <div class="u-preserve margin-left">{{costUtilization.inpatientVisits}}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="u-fill">Post Acute <span class="u-opacity--lighter">(6 mo)</span></div>
              <div class="u-preserve margin-left">{{costUtilization.postAcute}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="border-right padding--larger">
      <div class="titles t4 margin-bottom">
        <div class="title">Chronic Conditions</div>
      </div>
      <div class="row">
        <div class="label cco-weight">Weight</div>
        <div class="label cco-codes u-fill">HCC/ICD-Codes</div>
      </div>

      <ng-container *ngFor="let opportunity of chronicOpportunities">
        <div class="chronic-opportunity">
          <div class="row padding-bottom"
            [ngClass]="{'chronic-opportunity-parent': opportunity.childOpportunities?.length > 0}">
            <div class="cco-weight u-preserve">{{opportunity.weight}}</div>
            <div class="cco-codes u-fill">
              <div class="u-bold">{{opportunity.hccId}} - {{opportunity.hccDescription}}</div>
              <div class="u-opacity--light u-font-size--small">
                <span class="u-bold">{{opportunity.dateOfService | date:'MM/dd/yyyy'}}</span>
                <span class="u-italic"> {{opportunity.providerName | titlecase}}</span>
              </div>
              <ul class="u-opacity--light u-font-size--small u-italic margin-bottom--none">
                <li *ngFor="let code of opportunity.hccCodes">{{code.code}} - {{code.name}}</li>
              </ul>
            </div>
          </div>
          <ng-container *ngIf="opportunity.childOpportunities?.length > 0">
            <div class="row padding-bottom padding-left--large chronic-opportunity-child"
              *ngFor="let opportunityChild of opportunity.childOpportunities">
              <div class="cco-weight u-preserve">{{opportunityChild.weight}}</div>
              <div class="cco-codes u-fill">
                <div class="u-bold">{{opportunityChild.hccId}} - {{opportunityChild.hccDescription}}</div>
                <div class="u-opacity--light u-font-size--small">
                  <span class="u-bold">{{opportunityChild.dateOfService | date:'MM/dd/yyyy'}}</span>
                  <span class="u-italic"> {{opportunityChild.providerName | titlecase}}</span>
                </div>
                <ul class="u-opacity--light u-font-size--small u-italic margin-bottom--none">
                  <li *ngFor="let code of opportunityChild.hccCodes">{{code.code}} - {{code.name}}</li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="row padding-bottom" *ngFor="let interaction of chronicInteractions">
        <div class="cco-weight u-preserve">{{interaction.weight}}</div>
        <div class="cco-codes u-fill">
          <div class="u-bold">{{interaction.hccDescription}}</div>
        </div>
      </div>

      <div class="row padding-bottom">
        <div class="cco-weight u-preserve">{{totalChronicWeight | number:'1.3-3'}}</div>
        <div class="cco-codes u-fill u-bold">Total Outstanding Chronic Closure Weight</div>
      </div>

    </div>
    <div class="padding--larger">
      <div class="titles t4 margin-bottom">
        <div class="title">Acute Episodes</div>
      </div>
      <div class="row">
        <div class="label cco-weight">Weight</div>
        <div class="label cco-codes u-fill">HCC/ICD-Codes</div>
      </div>

      <ng-container *ngFor="let opportunity of acuteOpportunities">
        <div class="chronic-opportunity">
          <div class="row padding-bottom"
            [ngClass]="{'chronic-opportunity-parent': opportunity.childOpportunities?.length > 0}">
            <div class="cco-weight u-preserve">{{opportunity.weight}}</div>
            <div class="cco-codes u-fill">
              <div class="u-bold">{{formatAcuteOpportunity(opportunity)}}</div>
              <div class="u-opacity--light u-font-size--small">
                <span class="u-bold">{{opportunity.dateOfService | date:'MM/dd/yyyy'}}</span>
                <span class="u-italic"> {{opportunity.providerName | titlecase}}</span>
              </div>
              <div *ngIf="!opportunity.isInteraction">
                <ul class="u-opacity--light u-font-size--small u-italic margin-bottom--none">
                  <li *ngFor="let code of opportunity.hccCodes">{{code.code}} - {{code.name}}</li>
                </ul>
              </div>
            </div>
          </div>
          <ng-container *ngIf="opportunity.childOpportunities?.length > 0">
            <div class="row padding-bottom padding-left--large chronic-opportunity-child"
              *ngFor="let opportunityChild of opportunity.childOpportunities">
              <div class="cco-weight u-preserve">{{opportunityChild.weight}}</div>
              <div class="cco-codes u-fill">
                <div class="u-bold">{{opportunityChild.hccId}} - {{opportunityChild.hccDescription}}</div>
                <div class="u-opacity--light u-font-size--small">
                  <span class="u-bold">{{opportunityChild.dateOfService | date:'MM/dd/yyyy'}}</span>
                  <span class="u-italic"> {{opportunityChild.providerName | titlecase}}</span>
                </div>
                <ul class="u-opacity--light u-font-size--small u-italic margin-bottom--none">
                  <li *ngFor="let code of opportunityChild.hccCodes">{{code.code}} - {{code.name}}</li>
                </ul>

              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<div class="u-background-color--white" *hasFeatureFlag="FacesheetUIUpdatesFeatureFlag">


  <!-- How to add a new component:
  1. add it into patient-facesheet folder (./components/patient-facesheet/patient-NEWCOMPONENT/patient-NEWCOMPONENT.component)
     by runninng command ng g c patient-NEWCOMPONENT
  2. go to  patient-facesheet.module.ts to register a new component
  3. add import { PatientNEWCOMPONENT } from './components/patient-facesheet/patient-NEWCOMPONENT/patient-NEWCOMPONENT.component';
  4. add component name into declarations list
  5. remove PatientNEWCOMPONENT from care.module.ts
  6. add test cases/settings into spec(test) file './components/patient-facesheet/patient-NEWCOMPONENT/patient-NEWCOMPONENT.component.spec.ts'

  -->


  <!-- Chronic coding Opportunities -->
  <coach-patient-chronic-coding-opportunities *ngIf="patient" [patient]="patient" [tier]="tier"
    [mobileScreenSize]="mobileScreenSize">
  </coach-patient-chronic-coding-opportunities>

  <!-- Acute coding Opportunities -->
  <ng-container *doesNotHaveFeatureFlag="FacesheetV28">
    <coach-patient-acute-coding-opportunities *ngIf="patient" [patient]="patient" [tier]="tier">
    </coach-patient-acute-coding-opportunities>
  </ng-container>





  <!-- Suggesting to keep the next HTML structure

  <div class="section" *ngIf="patient">

    // HEADER
    <div class="header border-top--none border-bottom padding-bottom--smaller">
      <div class="u-display--flex u-justify-content--spaceBetween u-width--full margin-leftRight--larger">
          <div class="titles t4">
              <b class="title">TITLE</b>
          </div>
      </div>
    </div>

    // BODY
    <div class="margin-left--larger margin-right--smaller margin-topBottom--smaller">
         <table class="care-gaps-table">
            <tbody>
                <tr>
                    <td class="icon-placeholder">
                        // ICON OR EMPTY SPACE
                    </td>
                    <td>
                         // COLUMN 1
                    </td>
                    <td>
                        // COLUMN 2
                        // TIP: USE "u-text-align--right" CLASS
                    </td>
                </tr>
              </tbody>
          </table>
    </div>
  </div>

  -->



  <!-- Health maintenance -->
  <coach-patient-health-maintenance *ngIf="patient" [patient]="patient"
    [tier]="tier"></coach-patient-health-maintenance>


  <!-- Quality opportunities -->
  <coach-patient-quality-opportunities *ngIf="patient" [patient]="patient"
    [tier]="tier"></coach-patient-quality-opportunities>

  <!-- Utilization -->
  <coach-patient-utilization *ngIf="patient" [patient]="patient" [tier]="tier"> </coach-patient-utilization>

  <footer class="footer" *doesNotHaveFeatureFlag="FacesheetV28">
    The data provided herein are solely for informational and consideration purposes only. The displayed patient
    information is based on historical claims data related to that patient. These data do not purport to require, nor
    should be construed as requiring, any specific clinical treatment, standard of care, or course of action for the
    displayed patient. All patient care decisions are at the independent clinical discretion and judgement of the
    applicable patient's treating clinician. Providers should independently assess whether a patient's clinical
    conditions are accurate and should be documented.
  </footer>

  <footer class="footer coach-disclaimer" *hasFeatureFlag="FacesheetV28">
    <div>
      <span class="u-bold">Coach Disclaimer: </span>
      <span>
        As noted in the legal disclaimer, this facesheet is "solely for information and consideration purposes only."
        The
        purpose of the interaction component of this facesheet is to allow for the provider and care team to correct
        diagnosis codes which have been presented based upon historic information. This will improve the accuracy of
        future facesheet data. All applicable diagnoses should be reviewed and entered within your own electronic
        medical
        record. Information entered into Coach does not constitute billing codes and does not go to CMS.
      </span>
    </div>
    <div class="margin-top">
      <span class="u-bold">Legal Disclaimer: </span>
      <span>
        The data provided herein are solely for informational and consideration purposes only. The displayed patient
        information is based on historical claims data related to that patient. These data do not purport to require,
        nor
        should be construed as requiring, any specific clinical treatment, standard of care, or course of action for the
        displayed patient. All patient care decisions are at the independent clinical discretion and judgement of the
        applicable patient's treating clinician. Providers should independently assess whether a patient's clinical
        conditions are accurate and should be documented.
      </span>
    </div>
  </footer>
</div>