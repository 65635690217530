import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SubmitComponent } from './submit.component';
import { SubmitSummaryComponent } from '@views/submit/submit-summary/submit-summary.component';
import { SubmitQualityComponent } from '@views/submit/submit-quality/submit-quality.component';
import { SubmitComplianceComponent } from '@views/submit/submit-compliance/submit-compliance.component';
import { SubmitPromotingInteroperabilityComponent } from '@views/submit/submit-promoting-interoperability/submit-promoting-interoperability.component';
import { SubmitMeetingComponent } from '@views/submit/submit-meeting/submit-meeting.component';
import { SubmitFeatureNames, SubmitFeatureConstants } from '@shared/models/module-constants';
import { SubmitQualityAdminReportComponent } from './admin-report/submit-quality-report/submit-quality-admin-report.component';
import { SubmitComplianceAdminReportComponent } from './admin-report/submit-compliance-report/submit-compliance-admin-report.component';
import { SubmitPromotingInteroperabilityAdminReportComponent } from './admin-report/submit-promoting-interoperability-report/submit-promoting-interoperability-admin-report.component';
import { AuthGuard } from '../../../app/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: SubmitComponent,
    data: {
      label: 'Submit',
      helpfile: 'LEARN_SUBMIT_General_2020'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'summary'
      },
      {
        path: 'summary',
        component: SubmitSummaryComponent,
        data: {
          label: SubmitFeatureNames.Summary.valueOf()
        }
      },
      {
        path: 'quality',
        component: SubmitQualityComponent,
        data: {
          label: SubmitFeatureNames.Quality.valueOf()
        },

      },
      {
        path: 'compliance',
        component: SubmitComplianceComponent,
        data: {
          label: SubmitFeatureNames.Compliance.valueOf()
        }
      },
      {
        path: 'promoting-interoperability',
        component: SubmitPromotingInteroperabilityComponent,
        data: {
          label: SubmitFeatureNames.PI.valueOf()
        }
      },
      {
        path: 'meeting',
        component: SubmitMeetingComponent,
        data: {
          label: SubmitFeatureNames.Meeting.valueOf()
        }
      },
      {
        path: 'quality/formreport',
        component: SubmitQualityAdminReportComponent,
        canActivate: [AuthGuard],
        data: {
          label: SubmitFeatureNames.FormAdministration.valueOf(),
          featureId: SubmitFeatureConstants.FormsAdminFormFeature.valueOf(),
          featureFlag: SubmitFeatureConstants.FormsAdminFormFeatureFlag.valueOf()
        }
      },
      {
        path: 'promoting-interoperability/formreport',
        component: SubmitPromotingInteroperabilityAdminReportComponent,
        canActivate: [AuthGuard],
        data: {
          label: SubmitFeatureNames.FormAdministration.valueOf(),
          featureId: SubmitFeatureConstants.FormsAdminFormFeature.valueOf(),
          featureFlag: SubmitFeatureConstants.FormsAdminFormFeatureFlag.valueOf()
        }
      },
      {
        path: 'compliance/formreport',
        component: SubmitComplianceAdminReportComponent,
        canActivate: [AuthGuard],
        data: {
          label: SubmitFeatureNames.FormAdministration.valueOf(),
          featureId: SubmitFeatureConstants.FormsAdminFormFeature.valueOf(),
          featureFlag: SubmitFeatureConstants.FormsAdminFormFeatureFlag.valueOf()
        }
      },

    ]

  },
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   redirectTo: ''
  // }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubmitRoutingModule { }
