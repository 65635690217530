<div class="widget" data-cy="care-summary-widget">
  <div class="header header--slanted u-hoverEffect">
    <div class="titles t5">
      <div class="title">{{ summaryTile.title }}</div>
    </div>
  </div>
  <div class="dynamicBody padding--large">
    <img class="widget-illustration" src="{{ summaryTile.imagePath }}" />
    <p class="u-italic u-opacity--light margin-top--smaller">
      {{ summaryTile.description }}
    </p>
    <ul class="margin-top--large margins-bottom--small" *ngFor="let item of summaryTile.tileItems">
      <li *hasFeatureFlagDefined="item.featureFlag; featureId: item.featureId; componentId: item.componentId">
        <a href="#" (click)="onItemClick($event, item)">{{ item.linkText }}</a>
        <ng-container *ngIf="item.subLinks && item.subLinks.length">
          <ul class="margin-top--large margins-bottom--small sublink-item" *ngFor="let subLink of item.subLinks">
            <li *hasFeatureFlagDefined="
                subLink.featureFlag;
                featureId: subLink.featureId;
                componentId: subLink.componentId">
              <a href="#" (click)="onItemClick($event, item, subLink)">{{ subLink.linkText }}</a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
