import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'coach-claim-note',
  templateUrl: './claim-note.component.html',
  styleUrls: ['./claim-note.component.scss'],
})
export class ClaimNoteComponent implements OnInit, OnChanges {
  @Input() claimNote: string;
  @Input() showModal: boolean = false;
  @Input() selectedPatientInfo: any;
  @Output() modalToggled = new EventEmitter<boolean>();
  @Output() saveClaimNote = new EventEmitter<string>();

  cancelClicked: boolean = false;
  originalNote: string = null;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPatientInfo) {
      setTimeout(() => {
        if (this.showModal) {
          this.cancel();
        }
      }, 0);
    }

    if (changes.showModal) {
      if (changes.showModal.currentValue === false && this.cancelClicked) {
        this.claimNote = this.originalNote;
        this.originalNote = null;
        setTimeout(() => {
          this.cancelClicked = false;
        }, 0);
      }
      if (changes.showModal.currentValue === true && !this.originalNote) {
        this.originalNote = this.claimNote;
      }
    }
  }

  toggleVisitNoteModal() {
    this.modalToggled.emit(this.showModal);
  }

  saveNote() {
    this.saveClaimNote.emit(this.claimNote);
    this.toggleVisitNoteModal();
  }

  cancel() {
    this.cancelClicked = true;
    this.toggleVisitNoteModal();
  }

  deleteNote() {
    this.claimNote = '';
    this.saveNote();
  }
}
