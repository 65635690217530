export class BackendError {
  errorStatus: number = null;
  errorStatusText = '';
  userFriendlyErrorMessage = '';
  constructor(errorStatus: number, errorStatusText: string, userFriendlyErrorMessage: string){
    this.errorStatus = errorStatus;
    this.userFriendlyErrorMessage = userFriendlyErrorMessage;
    this.errorStatusText = errorStatusText;
  }
}
