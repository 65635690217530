<ng-container *ngFor="let cat of teamMembers">
    <coach-team-member title="VP, Value Based Care" [name]="cat.EVP" [description]="cat.EVPDescription" [email]="cat.EVPEmail" [phone]="cat.EVPPhone"></coach-team-member>
    <coach-team-member title="Regional Vice President" [name]="cat.RegionalVicePresident" [description]="cat.RVPDescription" [email]="cat.RVPEmail" [phone]="cat.RVPPhone"></coach-team-member>
    <coach-team-member title="Regional Manager" [name]="cat.RM" [description]="cat.RMDescription" [email]="cat.RMEmail" [phone]="cat.RMPhone"></coach-team-member>
    <coach-team-member title="Population Health Leader" [name]="cat.SeniorPracticeImprovementManager" [description]="cat.SPIMDescription" [email]="cat.SPIMEmail" [phone]="cat.SPIMPhone"></coach-team-member>
    <coach-team-member title="Clinical Leader" [name]="cat.ClinicalPracticeImprovementManager" [description]="cat.CPIMDescription" [email]="cat.CPIMEmail" [phone]="cat.CPIMPhone"></coach-team-member>
    <coach-team-member title="Clinical Coding Specialist" [name]="cat.ClinicalCodingSpecialist" [description]="cat.CCSDescription" [email]="cat.CCSEmail" [phone]="cat.CCSPhone"></coach-team-member>
    <coach-team-member title="Interoperability Specialist" [name]="cat.InteroperabilitySpecialist" [description]="cat.IOSDescription" [email]="cat.IOSEmail" [phone]="cat.IOSPhone"></coach-team-member>
    <coach-team-member title="Performance or Quality Improvement Analyst" [name]="cat.PerformanceImprovementAnalyst" [description]="cat.PIDescription" [email]="cat.PIEmail" [phone]="cat.PIPhone"></coach-team-member>
    <coach-team-member title="Integrations Analyst" [name]="cat.IntegrationAnalyst" [description]="cat.IADescription" [email]="cat.IAEmail" [phone]="cat.IAPhone"></coach-team-member>
</ng-container>
