<div class="eocList" *ngIf="availableProcedures">
    <div class="widget margin--large u-height--fit-content" [ngClass]="{'u-align-self--stretch': availableProcedures.length == 1}" 
      *ngFor="let eoc of availableProcedures; index as eoc_idx">
      <div class="header">
        <div class="titles">
          <div class="title" [ngClass]="{'u-color--success': eoc.isActive}">{{eoc.name}}</div>
        </div>
        <div class="field row--baseline">
          <button class="button is-borderless is-smaller u-height--webkit-fill-available" (click)="expandCollapseAll(true, eoc_idx)">Expand All</button>
         | 
          <button class="button is-borderless is-smaller u-height--webkit-fill-available" (click)="expandCollapseAll(false, eoc_idx)">Collapse All</button>
        </div>
      </div>
      <div class="dynamicBody margin--small border" [ngClass]="{'u-color--highlight': !protocol.isActive }" *ngFor="let protocol of eoc.protocols; let p_idx = index">
        <div class="header border-bottom--none">
          <div class="row">
            <button class="button is-icon margin-right--small"
            [ngClass]="{
              'is-disabled': doesProtocolHaveTwilioFlowTask(protocol) && !isPhoneValid(phoneNumber)
            }" (click)="protocolCheckClicked(protocol)">
              <svg class="icon" *ngIf="protocol.isActive"><use href="assets/icons/Magicons.svg#icon-check"></use></svg>
            </button>
            <label class="u-display--flex u-align-items--center">
              <h3 class="margin--none" (click)="test(protocol)">{{ protocol.name }}</h3>
            </label>
          </div>
        </div>
        <div *ngIf="doesProtocolHaveTwilioFlowTask(protocol)" class="row margin-leftRight--small">
          <div class="field padding--smallest">
            <div class="u-display--flex u-justify-content--bottom">
              <div class="">
                <label class="label" for="patientMobile" (click)="test(patient)"
                  [ngClass]="{
                      'u-color--brand--3': !isPhoneValid(phoneNumber)
                  }">Mobile Phone Number *</label>
                  <div class="u-display--flex">
                    <input type="text" id="patientMobile" class="u-border-right-radius--none" placeholder="xxx-xxx-xxxx" [(ngModel)]="phoneNumber" />
                    <button class="button u-border-left-radius--none" 
                      (click)="updatePhoneNumber()"
                      [ngClass]="{
                        'is-disabled': !isPhoneValid(phoneNumber) || patient.phoneNumber == phoneNumber
                      }">
                      <fa-icon class='fa-sm' [icon]="faSave"></fa-icon>
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="margin-leftRight--large">
          <div class="accordion" [ngClass]="{'is-open':protocol.isOpen }" *ngIf="protocol.tasks && protocol.tasks.length > 0">
            <div class="header">
              <div class="row">
                <label>Base Tasks ({{ protocol.activeTaskCount }} of {{ protocol.tasks.length }} active)</label>
              </div>
              <!-- accordion button is always last (see _accordion.scss) -->
              <button class="button is-small margin-right--small" (click)="protocol.isOpen = !protocol.isOpen"></button>
            </div>
            <div class="content row cadence-wrapper">
              <div class="swimlane cadence-header header">Once</div>
              <div class="swimlane cadence-header header">Daily</div>
              <div class="swimlane cadence-header header">Weekly</div>
              <div class="swimlane cadence-header header">Monthly</div>
            </div>
            <div class="content content-planRow row swimlane-wrapper" style="height: auto;" *ngFor="let tasks of [protocol.tasks]" >
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t_o of tasksForSwimlane(tasks,'once')" (updated)="onTaskUpdated($event)" [assignedTask]="t_o" [isDisabled]="!protocol.isActive" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t_d of tasksForSwimlane(tasks,'daily')" (updated)="onTaskUpdated($event)" [assignedTask]="t_d" [isDisabled]="!protocol.isActive" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t_w of tasksForSwimlane(tasks,'weekly')" (updated)="onTaskUpdated($event)" [assignedTask]="t_w" [isDisabled]="!protocol.isActive" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
              <div class="swimlane">
                <coach-careplan-patient-task *ngFor="let t_m of tasksForSwimlane(tasks,'monthly')" (updated)="onTaskUpdated($event)" [assignedTask]="t_m" [isDisabled]="!protocol.isActive" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
              </div>
            </div>
          </div>
          <div *ngFor="let module of protocol.modules">
            <!-- component coach-patient-careplan-module -->
            <div class="module accordion" [ngClass]="{
                'u-color--highlight': module.state !== 'active',
                'is-open': module.isOpen
              }">
              <div class="header">
                <div class="row">
                  <button class="button is-icon is-small margin-right--small" (click)="moduleCheckClicked(module)">
                    <svg class="icon" *ngIf="module.state === 'active'"><use href="assets/icons/Magicons.svg#icon-check"></use></svg>
                  </button>
                  <label class="u-display--flex u-align-items--center" (click)="test(module)">{{ module.protocol?.name }}
                    <span class="margin-left--small" *ngIf="module.assignedTasks && module.assignedTasks.length > 0">
                      ({{ module.activeTaskCount }} of {{ module.assignedTasks.length }} tasks active)
                    </span>
                  </label>
                </div>
                <button class="button is-small margin-right--small" (click)="module.isOpen = !module.isOpen"></button>
              </div>
              <div class="content row" *ngIf="module.assignedTasks && module.assignedTasks.length > 0">
                <div class="task-header" >Assigned tasks</div>
              </div>
              <div class="content row cadence-wrapper" *ngIf="module.assignedTasks && module.assignedTasks.length > 0">
                <div class="swimlane header cadence-header">Once</div>
                <div class="swimlane header cadence-header">Daily</div>
                <div class="swimlane header cadence-header">Weekly</div>
                <div class="swimlane header cadence-header">Monthly</div>
              </div>
              <div class="content row swimlane-wrapper" *ngIf="module.assignedTasks && module.assignedTasks.length > 0">
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.assignedTasks, null)" [isDisabled]="!t" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.assignedTasks, 'once')" [isDisabled]="!t" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
                </div>
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.assignedTasks, 'daily')" [isDisabled]="!t" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
                </div>
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.assignedTasks, 'weekly')" [isDisabled]="!t" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
                </div>
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.assignedTasks, 'monthly')" [isDisabled]="!t" (updated)="onTaskUpdated($event)" [assignedTask]="t" [tier]="tier" [patient]="patient"></coach-careplan-patient-task>
                </div>
              </div>
    
              <div class="content row" *ngIf="!module.assignedTasks || module.assignedTasks.length === 0">
                <div class="task-header" >Template tasks (module not assigned)</div>
              </div>
              <div class="content row cadence-wrapper" *ngIf="!module.assignedTasks || module.assignedTasks.length === 0">
                <div class="swimlane header cadence-header">Once</div>
                <div class="swimlane header cadence-header">Daily</div>
                <div class="swimlane header cadence-header">Weekly</div>
                <div class="swimlane header cadence-header">Monthly</div>
              </div>
              <div class="content row swimlane-wrapper" *ngIf="!module.assignedTasks || module.assignedTasks.length === 0">
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.templateTasks, null)" [isDisabled]="module.state !== 'active'" [templateTask]="t"></coach-careplan-patient-task>
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.templateTasks, 'once')" [isDisabled]="module.state !== 'active'" [templateTask]="t"></coach-careplan-patient-task>
                </div>
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.templateTasks, 'daily')" [isDisabled]="module.state !== 'active'" [templateTask]="t"></coach-careplan-patient-task>
                </div>
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.templateTasks, 'weekly')" [isDisabled]="module.state !== 'active'" [templateTask]="t"></coach-careplan-patient-task>
                </div>
                <div class="swimlane">
                  <coach-careplan-patient-task *ngFor="let t of tasksForSwimlane(module.templateTasks, 'monthly')" [isDisabled]="module.state !== 'active'" [templateTask]="t"></coach-careplan-patient-task>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="selectedModuleActivateDeactivateInfo" class="module-activate-dialog">
    <div *ngFor="let x of [selectedModuleActivateDeactivateInfo]" class="overlay is-open">
      <div class="modal">
        <ng-container *ngIf="x.module">
          <div class="header">
            <div class="titles">
              <div class="title">{{ x.operation }} {{ x.module.protocol.name }}</div>
            </div>
          </div>
          <div class="dynamicBody ">
            <div class="list border u-width--full" *ngIf="x.tasksToAssign.length > 0">
              <div class="subheader">These tasks will be assigned to the patient</div>
              <div class="list-item" *ngFor="let t of x.tasksToAssign">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>        
            <div class="list border u-width--full" *ngIf="x.tasksToActivate.length > 0">
              <div class="subheader">These tasks will be activated</div>
              <div class="list-item" *ngFor="let t of x.tasksToActivate">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>        
            <div class="list border u-width--full" *ngIf="x.tasksNotToBeDeactivated.length > 0">
              <div class="subheader">These tasks are already active in another module and will be left unchanged</div>
              <div class="list-item" *ngFor="let t of x.tasksNotToBeDeactivated">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>        
            <div class="list border u-width--full" *ngIf="x.tasksToBeDeactivated.length > 0">
              <div class="subheader">{{x.tasksToBeDeactivated.length}} tasks will be deactivated</div>
              <div class="list-item" *ngFor="let t of x.tasksToBeDeactivated">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>
            <div class="alert is-warning padding--small margin--small u-background-color--accentColor" *ngIf="x.protocol && !x.module">
              This action also will {{x.operation == 'Deactivate' ? 'deactivate' : 'activate'}} {{x.protocol.name}} protocol
            </div>   
          </div>
        </ng-container>
        <ng-container *ngIf="x.protocol">
          <div class="alert is-warning padding--small margin--small margin-bottom--none u-background-color--accentColor" *ngIf="x.protocol && x.module">
            This action also will {{x.operation == 'Deactivate' ? 'deactivate' : 'activate'}} parent protocol
          </div>
          <div class="header" >
            <div class="titles">
              <div class="title">{{ x.operation }} {{ x.protocol.name }}</div>
            </div>
          </div>
          <div class="dynamicBody ">
            <div class="list border u-width--larger" *ngIf="x.protocolTasksToAssign.length > 0">
              <div class="subheader">These tasks will be assigned to the patient</div>
              <div class="list-item" *ngFor="let t of x.protocolTasksToAssign">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>        
            <div class="list border u-width--larger" *ngIf="x.protocolTasksToActivate.length > 0">
              <div class="subheader">These tasks will be activated</div>
              <div class="list-item" *ngFor="let t of x.protocolTasksToActivate">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>        
            <div class="list border u-width--larger" *ngIf="x.protocolTasksNotToBeDeactivated.length > 0">
              <div class="subheader">These tasks are already active in another module and will be left unchanged</div>
              <div class="list-item" *ngFor="let t of x.protocolTasksNotToBeDeactivated">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>        
            <div class="list border u-width--larger" *ngIf="x.protocolTasksToBeDeactivated.length > 0">
              <div class="subheader">{{x.protocolTasksToBeDeactivated.length}} tasks will be deactivated</div>
              <div class="list-item" *ngFor="let t of x.protocolTasksToBeDeactivated">
                <div class="row">
                  <div class="field">{{t.name}}</div>
                  <div class="field">{{getTaskTypeText(t.type)}}</div>
                </div>
              </div>
            </div>   
          </div>
        </ng-container> 
        <div class="footer">
          <button class="button margin-right border--none" (click)="selectedModuleActivateDeactivateInfo = null">Cancel</button>
          <button class="button is-primary" (click)="activateOrDeactivateModule(selectedModuleActivateDeactivateInfo)">{{ x.operation }}</button>
        </div>
      </div>
    </div>
  </ng-container>
  