import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToolTipMeasureDetails } from '@shared/models/forms/tool-tip-measure-details';
import { FormQuestionConstants } from '@shared/models/forms/form-question-constants';
import { SecurityRiskQuestion } from '@shared/models/forms/forms-frontend';

@Component({
  selector: 'coach-submit-interoperability-security-risk',
  templateUrl: './submit-interoperability-security-risk.component.html',
  styleUrls: ['./submit-interoperability-security-risk.component.scss'],
})
export class SubmitInteroperabilitySecurityRiskComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() questionInfo: SecurityRiskQuestion;
  @Output() showHelpText = new EventEmitter<ToolTipMeasureDetails>();

  public willComplete = 'WillComplete';
  public willCompleteIn = 'WillCompletein';
  public complete = 'Complete';
  public no = 'no';
  public unset = 'unset'
  //public q8;
  public isComplete: string;
  private readonly completeText = 'Complete';
  private readonly willCompleteInText = 'WillCompletein';
  private readonly willCompleteText = 'WillComplete';

  ngOnInit(): void {
    const response: string = this.parentForm
      .get(`${this.questionInfo.name}.FormQResponses.0.UserResponse`)
      ?.value?.toString();
    const completeControl = this.parentForm.get(
      `${this.questionInfo.name}.FormQResponses.0.complete`
    );

    if (response != null && response.indexOf(this.willCompleteText) >= 0 && this.questionInfo.promptQuarter == false) {
      completeControl?.setValue(this.willComplete);
    } else if (response != null && response.indexOf(this.willCompleteText) >= 0  && this.questionInfo.promptQuarter == true) {
      completeControl?.setValue(this.willComplete);
    } else if (response != null && response.indexOf(this.completeText) >= 0) {
      completeControl?.setValue(this.complete);
    } else if (response != null && response.indexOf(this.no) >= 0) {
      completeControl?.setValue(this.no);
    } else {
      completeControl?.setValue(this.unset);
    }

  }

  toggleInfoText(): void {
    this.showHelpText.emit({
      detailsText: this.questionInfo.helpText,
      modalLinks: this.questionInfo.modalLinks,
    });
  }
}
