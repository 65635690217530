<div [ngClass]="{ 'widget margin--large u-height--fit-content': !sidebarMode }">
  <div class="header" *ngIf="!sidebarMode">
    <div class="titles">
      <div class="title" (click)="isFormOpen = !isFormOpen">
        <span>PAC Navigator Program</span>
      </div>
    </div>
    <coach-icon
      class="u-cursor--pointer"
      iconSize="large"
      [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'"
      (click)="isTabOpen = !isTabOpen"
    >
    </coach-icon>
  </div>
  <div
    *ngIf="patient && isTabOpen"
    class="u-display--flex u-flex-direction--row margin-bottom--large pacnav-content-wrapper"
    [ngClass]="{ 'u-flex-wrap--wrap': mobileScreenSize }"
  >
    <div
      class="u-display--flex u-flex-direction--column margin-bottom--large padding-leftRightTopBottom--small"
      [ngClass]="{ 'u-width--webkit-fill-available': mobileScreenSize, 'span--6': !mobileScreenSize }"
    >
      <ng-container *ngIf="!isFormOpen">
        <div class="margin--large u-height--fit-content">
          <div class="info-text">
            The PAC Navigator Worklist allows you to track patients in a Skilled Nursing Facility and work through any
            issues related to their stay.
          </div>
          <button
            [ngClass]="{ 'add-patient-button-mobile': mobileScreenSize }"
            class="add-patient-button u-color--brand--1 button"
            (click)="activateForm()"
          >
            <coach-icon class="button-icon" iconName="plus" iconColor="currentColor"></coach-icon>Add this patient to
            the PAC Navigator Worklist
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="isFormOpen">
        <div class="margin">
          <ng-container *ngIf="hasSNFFeature">
            <label class="margin-top--small label">Waiver Selection</label>
            <div
              class="snf-info"
              *ngIf="isWaiverSubmitted; else showWavierCheckbox"
              (click)="expandSubmittedWaiver = !expandSubmittedWaiver"
            >
              <coach-icon
                class="icon--primary is-large u-font-size--small"
                iconColor="primary"
                iconSize="large"
                iconName="filled-check"
              >
              </coach-icon>
              <div>
                <span class="snfInfo-text waiver-header-text">3 Day Waiver</span>
                <span class="snfInfo-text u-color--brand--1 u-thin waiver-input-text"
                  ><span *ngIf="isWaiverSaved">Completed</span> {{ snfInfo.snfName }} {{ ACOProviderEvalDate }}</span
                >
              </div>
              <coach-icon [iconName]="expandSubmittedWaiver ? 'chevron-up' : 'chevron-down'"> </coach-icon>
            </div>
            <ng-template #showWavierCheckbox>
              <input id="checkbox_waiver_selection" type="checkbox" [formControl]="waiverSelectionControl" />
              <label for="checkbox_waiver_selection">
                <span class="has-tooltip">Patient is using SNF 3 day waiver</span>
              </label>
            </ng-template>

            <div
              *ngIf="expandSubmittedWaiver || (!isWaiverSubmitted && waiverSelectionControl.value)"
              class="snf-container"
            >
              <span class="margin-bottom--small text-center font-bold">All fields are required</span>

              <label class="label margin-top--small" for="snf-name-select">SNF Name</label>
              <select
                id="snf-name-select"
                name="snf-name-select"
                class="field u-color--interaction u-width--medium margin-bottom"
                [formControl]="snfNameControl"
              >
                <option value="">Please select SNF</option>
                <option [value]="item.pacNavSNFID" *ngFor="let item of snfList">{{ item.snfName }}</option>
              </select>
              <div class="snf-info margin-bottom">
                <coach-icon class="icon--primary u-font-size--small" iconSize="is-medium" iconName="info"> </coach-icon>
                <span class="waiver-input-text">Submission of waiver use must be completed within 24 hours of use</span>
              </div>
              <span class="waiver-input-text margin-bottom--smaller"
                >At the time of admission the client is responsible for ensuring the SNF has a 3 star rating</span
              >
              <div class="snf-wrapper">
                <div class="snf-info">
                  <input id="checkbox_snf_disclaimer" type="checkbox" [(ngModel)]="snfInfo.isVerified3StarRating" />
                  <label for="checkbox_snf_disclaimer">
                    <span class="waiver-input-text"
                      >I have verified through
                      <a href="{{ medicareUrl }}" target="_blank">Medicare's Care Compare site</a> that the SNF selected
                      has a CMS 3 Star Rating or higher</span
                    >
                  </label>
                </div>
                <div class="snf-info">
                  <input id="checkbox_snf_ACOCertificed" type="checkbox" [(ngModel)]="snfInfo.isACOCertified" />
                  <label for="checkbox_snf_ACOCertificed">
                    <span class="waiver-input-text"
                      >I have verified the provider completing the certification of skilled need is an ACO
                      provider</span
                    >
                  </label>
                </div>
                <label class="label" for="aco_name">ACO PROVIDER NAME</label>
                <input
                  class="u-width--medium"
                  id="aco_name"
                  [(ngModel)]="snfInfo.ACOProviderName"
                  type="text"
                  required
                />
                <div class="snf-info">
                  <input id="checkbox_snf_PatientEval" type="checkbox" [(ngModel)]="snfInfo.isPatientEvaluated" />
                  <label for="checkbox_snf_PatientEval">
                    <span class="waiver-input-text"
                      >I have verified the patient has been evaluated within 3 days prior to the admission</span
                    >
                  </label>
                </div>
                <label class="label" for="aco_eval_date">ACO PROVIDER EVALUATION DATE </label>
                <input
                  class="u-width--medium"
                  id="aco_eval_date"
                  [(ngModel)]="snfInfo.ACOProviderEvalDate"
                  type="date"
                  required
                />
                <div class="snf-info snf-patient-not-resident">
                  <input id="checkbox_snf_PatientResident" type="checkbox" [(ngModel)]="snfInfo.isPatientNotResident" />
                  <label for="checkbox_snf_PatientResident">
                    <span class="waiver-input-text"
                      >I have verified the patient was attributed at time of admission and is not a resident in a
                      SNF/LTC Facility</span
                    >
                  </label>
                  <span class="waiver-input-text snf-patient-not-resident-note">
                    Note: Independent or Assisted Living Facilities are not included in this category
                  </span>
                </div>
                <div class="snf-info">
                  <input id="checkbox_snf_PatientAdmission" type="checkbox" [(ngModel)]="snfInfo.isPatientAdmissible" />
                  <label for="checkbox_snf_PatientAdmission">
                    <span class="waiver-input-text"
                      >I have verified that the provider certifying the skilled need has stated that the patient meets
                      the criteria for skilled admission
                    </span>
                  </label>
                </div>
                <div *ngIf="isFormValid() && isFormUpdated() && !isSnfAdmittedAtValid">
                  <span class="snf-admitted-at-error">Please select an Admission date below and click Save</span>
                  {{ snfAdmittedAtModel.control.markAsTouched() }}
                </div>
                <div
                  class="u-display--flex"
                  [ngClass]="isWaiverSaved ? 'u-justify-content--spaceBetween' : 'u-justify-content--right'"
                >
                  <coach-icon
                    *ngIf="isWaiverSaved"
                    class="icon--primary u-font-size--small u-cursor--pointer"
                    iconName="trash"
                    (click)="showDialog()"
                  >
                  </coach-icon>

                  <button class="button is-small is-borderless margin-right--small" (click)="cancelWaiver()">
                    Cancel
                  </button>

                  <button
                    class="button is-primary is-small"
                    [disabled]="!isFormValid() || !isFormUpdated() || !isSnfAdmittedAtValid || loadingStatus"
                    (click)="onSave()">
                    Save
                  </button>
                </div>

                <coach-patient-awv-remove-modal-dialog
                  [dialogHeader]="'Remove Waiver'"
                  [dialogMessage]="'This will clear all fields and the waiver will be removed from the patient.'"
                  [showDialog]="showDeleteDialog"
                  (buttonClicked)="onDialogButtonClick($event)"
                ></coach-patient-awv-remove-modal-dialog>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!hasSNFFeature || !waiverSelectionControl.value">
            <label class="margin-top--small label" for="snf_name">SNF Name* </label>
            <input class="u-width--medium" id="snf_name" [(ngModel)]="snfInfo.snfName" type="text" required />
          </ng-container>

          <label class="margin-top--small label" for="snf_admission">Admission* </label>
          <input
            #snfAdmittedAtModel="ngModel"
            class="u-width--medium"
            id="snf_admission"
            max="{{ snfInfo.snfDischargedAt | date : 'yyyy-MM-dd' }}"
            [(ngModel)]="snfInfo.snfAdmittedAt"
            type="date"
            required
          />

          <label class="margin-top--small label" for="snf_discharge">Discharge</label>
          <input
            class="u-width--medium"
            id="snf_discharge"
            min="{{ snfInfo.snfAdmittedAt | date : 'yyyy-MM-dd' }}"
            [(ngModel)]="snfInfo.snfDischargedAt"
            type="date"
          />
        </div>
        <div class="margin">
          <label class="margin-top--small label">Issues</label>
          <div *ngFor="let flag of patientSnfStatusItemAllowedTypes">
            <input
              id="checkbox_{{ flag }}"
              type="checkbox"
              [(ngModel)]="selectedStatusFlags[flag]"
              (ngModelChange)="statusFlagUpdated(flag, $event)"
            />
            <label for="checkbox_{{ flag }}">
              <span class="has-tooltip"
                >{{ patientSnfStatusItemLabels[flag] ? patientSnfStatusItemLabels[flag] : splitStringIntoWords(flag) }}
                <span
                  *ngIf="patientSnfStatusItemDefinitions[flag]"
                  class="tooltip is-right"
                  [ngClass]="{ 'u-width--md': !mobileScreenSize, 'u-width--small': mobileScreenSize }"
                  >{{ patientSnfStatusItemDefinitions[flag] }}</span
                >
              </span>
            </label>
          </div>
        </div>
        <div class="margin padding-bottom--large">
          <label class="margin-top--small label" for="snf_comment">Comments</label>
          <textarea
            class="resize--both"
            id="snf_comment"
            [(ngModel)]="snfInfo.snfComment"
            rows="6"
            maxlength="5000"
          ></textarea>
        </div>
        <div class="margin u-display--flex u-justify-content--spaceBetween">
          <button
            class="button is-primary is-small"
            (click)="update()"
            [disabled]="!isFormValid() || !isFormUpdated() || loadingStatus"
          >
            Update
          </button>
          <button
            *ngIf="!isWaiverSaved && !waiverSelectionControl.value"
            class="button is-primary is-small margin-left--small is-secondary"
            (click)="remove()"
            [disabled]="!canInfoBeRemoved() || loadingStatus"
          >
            Remove
          </button>
        </div>
      </ng-container>
    </div>
    <div
      class="u-display--flex u-flex-direction--column history-wrapper span--6"
      [ngClass]="{ 'u-width--webkit-fill-available': mobileScreenSize }"
    >
      <div class="list u-width--full u-display--flex u-flex-direction--column-reverse">
        <ng-container *ngFor="let log of snfHistoryDetails; index as idx">
          <div class="list-item--complex list-item--singleHitArea" *ngIf="log.updates">
            <div class="list-item-avi">
              <div class="avi">
                <img *ngIf="proAvatar(log.createdBy)" [src]="proAvatar(log.createdBy)" class="img--circle" />
                <div class="nameAvatar" *ngIf="proName(log.createdBy) && !proAvatar(log.createdBy)">
                  {{ proName(log.createdBy) | initialsFromName }}
                </div>
              </div>
            </div>
            <div class="list-item-main u-justify-content--center">
              <div class="row u-display--flex u-justify-content--spaceBetween">
                <div class="field u-bold">
                  <span *ngIf="proName(log.createdBy)">{{ proName(log.createdBy) }}</span>
                  <span *ngIf="!proName(log.createdBy)">(name not found)</span>
                </div>
                <div
                  class="row--verticalCenter margin-right--smallest u-color--highlight u-font-size--smallest text-right"
                >
                  {{ log.createdAt | date : 'medium' }}
                  <span *ngIf="!log.createdAt">Patient was discharged</span>
                </div>
              </div>
              <div
                class="u-display--flex u-flex-direction--row u-color--brand--1 u-font-size--smallest"
                *ngFor="let update of log.updates"
              >
                <div class="faIcon-wrapper">
                  <fa-icon *ngIf="update.faIcon" class="fa-lg" [icon]="update.faIcon"></fa-icon>
                </div>
                {{ update.text }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="u-display--flex u-justify-content--right padding" *ngIf="isFormEditable()">
        <button *ngIf="editMode" class="button is-small" (click)="clearForm()">Cancel</button>
        <button
          *ngIf="!editMode && patient.snfInfo && patient.snfInfo.current"
          class="button is-primary is-small"
          (click)="clonePreviousEntry()"
        >
          Edit
        </button>
      </div>
    </div>
  </div>
</div>
