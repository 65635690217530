import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@shared/services/auth-service/auth.service';
import { UserInfo } from '@shared/services/auth-service/auth-interface';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { SidebarAccountDropdownComponent } from './sidebar-account-dropdown/sidebar-account-dropdown.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'coach-sidebar-account',
  templateUrl: './sidebar-account.component.html',
  providers: [Overlay]
})
export class SidebarAccountComponent implements OnInit {
  public isAccountDropdownOpen: boolean = false;
  private overlayRef: OverlayRef;
  @Input() isSidebarOpen: boolean;
  public isAuthenticated$: Observable<boolean>;
  public userInfo$: Observable<UserInfo>;
  private isLoggedIn = false;

  constructor(public overlay: Overlay, private vcRef: ViewContainerRef, private authService: AuthService) {}

  sidebarAccountDropdownPortal: ComponentPortal<SidebarAccountDropdownComponent>;

  ngOnInit(): void {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.userInfo$ = this.authService.userInfo$;
    this.isAuthenticated$.subscribe(
      isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
      },
      error => {}
    ); //()=>this.tryLogin());
  }

  signInOrOutClick(event) {
    if (event.altKey && !environment.production) {
      //var w = ('', '_blank', 'toolbar=0,width=300,height=200');
      if (this.isLoggedIn) {
        this.userInfo$.subscribe(userInfo => {
          let str = `UserInfo: ${JSON.stringify(userInfo)} \r\n idp_sub:${
            this.authService.idp_sub
          } \r\n TOKENPAYLOAD: ${JSON.stringify(this.authService.getPayloadFromIdToken())}`;
          window.alert(str);
          console.warn('login Info:', str);
          console.warn('login Info:', this.authService.IdToken);
        });
      }
    } else {
      console.warn('trying to SignInSignOutClick ');
      this.signInOrOut(this.isLoggedIn);
    }
  }
  signInOrOut(isloggedIn: boolean) {
    if (isloggedIn) this.authService.logout(); //logofflocal();//logoffAndRevokeTokens();//logout();
    else {
      this.authService.write('coachRedirect', window.location.pathname);
      this.authService.write('queryParam', null);
      this.authService.login();
    }
  }

  openAccountDropdown() {
    let sidebarAccountDropdownPortal = new ComponentPortal(SidebarAccountDropdownComponent, this.vcRef);
    let config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    config.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.vcRef.element.nativeElement.children[0].children[0])
      .withPositions([{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' }]);
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(sidebarAccountDropdownPortal);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
  }
}
