import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Patient } from '@shared/models/patient';
import moment from 'moment/moment';
import { OutcomeType } from '@api/care-orch/models/outcome-type';
import { PropertiesType } from '@api/care-orch/models/properties-type';
import { CompletedProperties } from '@api/care-orch/models/completed-properties';
import { CompletedOutcome } from '@api/care-orch/models/completed-outcome';
import { CareOrchestrationConstants } from '@shared/models/module-constants';
import { PracticeListResult } from '@api/track/models/practice-list-result';
import { ActivityType } from '@api/care-orch/models/activity-type';
import {
  WorkflowDateTimeUtil
} from '@shared/modules/patient-facesheet/tabs/patient-awv-utilities/workflow-date-time-util';
import { PracticeService } from '@shared/services/hierarchy/practice.service';
import { OnChanges } from '@angular/core';
import { NextAwvDateValidationService } from '@shared/services/form-validation/awv/next-awv-date-validation.service';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export class Comment {
  createdDateTime: string;
  createdUserDisplayName: string;
  commentText: string;
  constructor(createdDateTime: string, createdUserDisplayName: string, commentText: string) {
    this.createdDateTime = createdDateTime;
    this.createdUserDisplayName = createdUserDisplayName;
    this.commentText = commentText;
  }
}

@Component({
  selector: 'coach-patient-workflow-completed',
  templateUrl: './patient-workflow-completed.component.html',
  styleUrls: ['./patient-workflow-completed.component.scss']
})
export class PatientWorkflowCompletedComponent implements OnChanges{
  @Input() public set patient(patient: Patient){
    if (patient) {
      this.clearFields();
      this.loadPracticesDropdown(patient);
    }
  }
  @Input() public properties: CompletedProperties;
  @Input() public editModeActive = false;
  @Output() outcomeSaved: EventEmitter<CompletedOutcome> = new EventEmitter();
  @Output() deleteActivity: EventEmitter<string> = new EventEmitter();
  @Output() public activityDeleted: EventEmitter<ActivityType> = new EventEmitter();

  completedDate = '';
  today: string = WorkflowDateTimeUtil.todayDateOnlyString();
  minNextAwvDate: string = WorkflowDateTimeUtil.minNextAwvDate();
  maxNextAwvDate: string = WorkflowDateTimeUtil.maxNextAwvDate();
  completedPracticeId = '';
  providerName = '';
  alternativePractice = false;
  comment = '';
  nextAwvDate = '';
  practices: PracticeListResult[];
  allFieldsEmpty = true;
  requiredFieldsSatisfied = false;
  commentCharacterLimit = CareOrchestrationConstants.commentBoxCharacterLimit;
  minCompletedDate = moment().subtract(1, 'year').format('yyyy-MM-DD');
  nextAwvDateChecked = false;
  providerFieldCharacterLimit = CareOrchestrationConstants.providerFieldCharacterLimit;
  showRemoveDialog = false;
  control = new UntypedFormControl();
  filteredPractices: PracticeListResult[];
   otherPractice: PracticeListResult = {
    tier4Id: '',
    tier4Name: 'OTHER'
  };
  practiceOnLoad: string;

  constructor(private practiceService: PracticeService, private nextAwvDateValidationService: NextAwvDateValidationService ) { }

  private _filter(value: string): PracticeListResult[] {
    const filterValue = this._normalizeValue(value);
    return this.practices?.filter(practice => this._normalizeValue(practice.tier4Name).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  ngOnChanges(): void {
    if (this.properties){
      if (this.properties.providerName){
        this.providerName = this.properties.providerName;
      }
      if (this.properties.externalPractice){
        this.alternativePractice = this.properties.externalPractice;
      }
      this.completedDate = WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.properties.date);
      this.updateNextAwvDateRestrictions();
      if (this.properties.nextAwvDate) {
        this.nextAwvDate = WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.properties.nextAwvDate);
        this.nextAwvDateChecked = true;
      }
    }
  }

  filterPractices(value: string ): void{
    this.filteredPractices = value ? this._filter(value) : [...this.practices];
  }

  displayFn(practice?: string): string {
   return practice ;
  }

  loadPracticesDropdown(patient: Patient): void {
    this.practiceService.getPractices(patient).then(practices => {
      this.practices = practices.slice();
      if (!this.practices.some(o => o.tier4Name === 'OTHER')) {
        this.practices.unshift(this.otherPractice);
      }
      this.filteredPractices = this._filter('');

      if (this.properties && this.properties.externalPractice){
        this.control.setValue(this.otherPractice.tier4Name);
      }
      else if (this.properties && this.properties.practiceId) {
        this.completedPracticeId = this.properties.practiceId;
        this.practiceOnLoad = this.practices.find(x => x.tier4Id === this.completedPracticeId).tier4Name.toString();
        this.control.setValue(this.practiceOnLoad);
      } else {
        this.completedPracticeId = '';
      }
    });
  }

  getPracticeId(event: MatAutocompleteSelectedEvent): void {
    this.completedPracticeId = event.option.id;
    this.fieldChanged();
  }

  copyComment(commentBox: HTMLTextAreaElement): void{
    commentBox.select();
    document.execCommand('copy');

    commentBox.setSelectionRange(0, 0);
  }

  fieldChanged(): void {
    this.checkAnyFieldHasValue();
    this.checkRequiredFieldsSatisfied();
  }

  private checkAnyFieldHasValue(): void {
    if (!this.completedDate && !this.completedPracticeId && !this.comment
      && !this.providerName && !this.nextAwvDate) {
      this.allFieldsEmpty = true;
      return;
    }

    this.allFieldsEmpty = false;
  }

  private checkRequiredFieldsSatisfied(): void {
    if (this.completedDate && this.completedDate >= this.minCompletedDate && this.completedDate <= this.today){
      if (this.nextAwvDateChecked){
        if (this.nextAwvDate && this.nextAwvDateValidationService.isNextAwvDateChangedAndHasValidValueForCompleted(this.nextAwvDateChecked, this.nextAwvDate,
          this.minNextAwvDate, this.maxNextAwvDate, this.properties)){
          this.requiredFieldsSatisfied = true;
        }
        else {
          this.requiredFieldsSatisfied = false;
          return;
        }
      }

      this.requiredFieldsSatisfied = true;
      return;
    }

    this.requiredFieldsSatisfied = false;
  }

  clearFields(): void {
    if (!this.editModeActive) {
      this.completedDate = '';
      this.completedPracticeId = '';
      this.control.setValue('');
      this.providerName = '';
      this.comment = '';
      this.nextAwvDate = '';
      this.nextAwvDateChecked = false;
      this.alternativePractice = false;
      this.fieldChanged();
    }
    else{
      this.resetFields();
    }
  }

  private resetFields(): void{
    this.completedDate = this.properties.date.substring(0, 10);
    this.completedPracticeId = this.properties.practiceId;
    this.providerName = this.properties.providerName;
    this.control.setValue(this.practiceOnLoad);
    this.comment = '';
    this.requiredFieldsSatisfied = false;
    this.allFieldsEmpty = true;
    this.nextAwvDate = WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.properties.nextAwvDate);
    if (this.nextAwvDate) {
      this.nextAwvDateChecked = true;
    } else {
      this.nextAwvDateChecked = false;
    }
    if (this.properties.externalPractice){
      this.alternativePractice = this.properties.externalPractice;
    } else {
      this.alternativePractice = false;
    }
  }

  save(): void {
    if (this.completedPracticeId === ''){
      this.alternativePractice = true;
    }
    else {
      this.alternativePractice = false;
    }
    const completedOutcome = {
      type: OutcomeType.CompletedOutcome,
      properties: {
        type: PropertiesType.CompletedProperties,
        date: WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.completedDate),
        nextAwvDate: this.nextAwvDate ? WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.nextAwvDate) : null,
        practiceId: this.completedPracticeId ? this.completedPracticeId :  null,
        providerName: this.providerName ? this.providerName : null,
        comment: this.comment ? this.comment : null,
        externalPractice: this.alternativePractice,
      } as unknown as CompletedProperties
    } as CompletedOutcome;
    if (completedOutcome != null) {
      this.outcomeSaved.emit(completedOutcome);
    }
    this.clearFields();
  }

  updateNextAwvDateRestrictions(): void {
    if (this.completedDate) {
      this.minNextAwvDate = WorkflowDateTimeUtil.minNextAwvDate(this.completedDate);
      this.maxNextAwvDate = WorkflowDateTimeUtil.maxNextAwvDate(this.completedDate);
    }
  }

  onDialogButtonClick(buttonValue: string): void {
    switch (buttonValue){
      case 'cancel':
        this.showRemoveDialog = !this.showRemoveDialog;
        break;
      case 'delete':
        this.deleteActivity.emit();
    }
  }

  showDialog(): void {
    this.showRemoveDialog = true;
  }

  stringifyForm(): string {
    if (!this.completedDate) {
      return '';
    }

    let value = 'AWV completed on ' + WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat(this.completedDate);

    if (this.providerName) {
      value += ' with ' + this.providerName;
    }

    if (this.completedPracticeId) {
      value += ' at ' + this.practices.find(p => p.tier4Id === this.completedPracticeId).tier4Name;
    }

    if (this.nextAwvDate) {
      value += '. Next AWV date scheduled for ' + WorkflowDateTimeUtil.dateTimeStringToMonthDateFormat(this.nextAwvDate);
    }

    if (this.comment) {
      value += '. ' + this.comment;
    }

    return value;
  }

  toggleNextAwvDateInput(): void{
    this.nextAwvDateChecked = !this.nextAwvDateChecked;
    if (!this.nextAwvDateChecked) {
        this.nextAwvDate = '';
    } else {
      if (this.properties?.nextAwvDate) {
        this.nextAwvDate = WorkflowDateTimeUtil.dateTimeStringToDateOnlyString(this.properties.nextAwvDate);
      }
    }
    this.fieldChanged();
  }
}
