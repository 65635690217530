<div class="margin-top--smaller">
  <div *hasFeature="submitQualityFeature">
    <span>{{ submitQuailityName }}</span>
    <ul class="margin-topBottom--small" *ngFor="let subPeriod of formSubmissionOverview">
      <li *ngIf="subPeriod.formID == qualityFormId">

          <a
            href="{{ submitQuailityName }}"
            (click)="goToItems($event, '/submit/quality', {
              submitperiod: subPeriod.caravanFormSubmitPeriodID
            })"
            *ngIf="subPeriod.openDate <= currentDate"
          >
            <span class="u-thick">{{subPeriod.openingInfo}}</span>
          </a>
          <span class="u-thick" *ngIf="subPeriod.openDate > currentDate">{{subPeriod.openingInfo}}</span>
          <br />
        <span class="u-thin" >{{ subPeriod.openDueDate }}</span>
        <div *ngIf="subPeriod.caravanFormDataSourceID == 2">
          <ul>
            <li>
              <a
                  href="From Caravan WITH PHI"
                  (click)="
                    goToItems($event, '/improve/practice-improvement', {
                      folder: randomSampleManualAbstractionFolder.id
                    })">Access Random Patient Sample & How-to Resources</a>
              </li>
            </ul>
        </div>
      </li>
    </ul>
  </div>
  <div *hasFeature="submitComplianceFeature">
    <span>{{ submitComplianceName }}</span>
    <ul class="margin-topBottom--small" *ngFor="let subPeriod of formSubmissionOverview">
      <li *ngIf="subPeriod.formID == complianceFormId">
        <a href={{submitComplianceName}} (click)="goToItems($event, '/submit/compliance')"
          *ngIf="subPeriod.openDate <= currentDate">
        <span class="u-thick">{{ subPeriod.openingInfo }}</span>
        </a>
        <span class="u-thick" *ngIf="subPeriod.openDate > currentDate">{{subPeriod.openingInfo}}</span>
        <br />
        <span class="u-thin">{{ subPeriod.openDueDate }}</span>
      </li>
    </ul>
  </div>
  <div *hasFeature="submitPIFeature">
    <span>{{ submitPIName }}</span>
    <ul class="margin-topBottom--small" *ngFor="let subPeriod of formSubmissionOverview">
      <li *ngIf="subPeriod.formID == piFormId">

          <a href={{submitPIName}} (click)="goToItems($event, '/submit/promoting-interoperability')"
            *ngIf="subPeriod.openDate <= currentDate">
          <span class="u-thick">{{ subPeriod.openingInfo }}</span>
          </a>
          <span class="u-thick" *ngIf="subPeriod.openDate > currentDate">{{subPeriod.openingInfo}}</span>
          <br />
        <span class="u-thin">{{ subPeriod.openDueDate }}</span>
      </li>
    </ul>
  </div>
  <div *hasFeature="submitMeetingFeature">
    <span>{{ meetingWidgetName }}</span>
    <ul class="margin-topBottom--small">
      <li>
        <a href="#" (click)="goToItems($event, '/submit/meeting')">
          {{ meetingOpenInfo }}
        </a>
        <br />
        <span class="u-thin">{{ meetingOpenDueDate }}</span>
      </li>
    </ul>
  </div>
</div>
