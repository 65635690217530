import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { wpapi } from '@hcd-caravanhealth/pkg-wptypes';
import { CaravanPatient, PatientSnfInfo, PatientSnfStatusItem } from '@hcd-caravanhealth/pkg-wptypes/dist/src/models/user';
import { Patient } from '@shared/models/patient';
import { IHierarchyTier } from '@shared/models/hierarchy/hierarchy-tier';
import { CareComponentConstants, PatientSnfStatusItemAllowedTypes } from '@shared/models/module-constants';
import { PatientService } from '@shared/services/patient/patient.service';
import _ from 'lodash';
import moment from 'moment';
import {
  IconDefinition,
  faArrowRight,
  faEdit,
  faTimes,
  faMinus,
  faPlus,
  faCheckCircle,
  faCheck,
  faComment
} from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { Access } from '@shared/services/graphql/access.service';
import { UserAccessService } from '@shared/services/user-access/user-access.service';
import { SnfService } from '@api/track/services';
import { SnfResult } from '@api/track/models';

export interface snfInfoDetails extends PatientSnfInfo {
  updates?: Array<snfInfoDetailUpdate>;
}
export interface snfInfoDetailUpdate {
  faIcon?: IconDefinition;
  text: string;
  label?: string;
}
@Component({
  selector: 'coach-patient-pac-navigator',
  templateUrl: './patient-pac-navigator.component.html',
  styleUrls: ['./patient-pac-navigator.component.scss']
})
export class PatientPacNavigatorComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public tier: IHierarchyTier;
  @Input() public mobileScreenSize: boolean;
  @Input() sidebarMode?: boolean;
  @Input() public isTabOpen: boolean;
  @Output() patientUpdated: EventEmitter<null> = new EventEmitter();
  private get tierId(): string {
    return this?.tier?.selectedTierId;
  }
  // patientSnfStatusItem: Patient.PatientSnfStatusItem;
  isFormOpen = false;
  editMode = false;
  patientSnfStatusItemAllowedTypes: string[] = [];
  patientSnfStatusItemDefinitions: { [key: string]: string } = {};
  patientSnfStatusItemLabels: { [key: string]: string } = {};
  selectedStatusFlags: { [key: string]: boolean } = {};
  snfInfo: PatientSnfInfo = {
    snfName: '',
    snfId: null,
    snfAdmittedAt: '',
    snfDischargedAt: '',
    isUsingSnf3DayWaiver: false,
    isVerified3StarRating: false,
    snfStatus: [],
    isACOCertified: false,
    ACOProviderName: '',
    isPatientEvaluated: false,
    ACOProviderEvalDate: null,
    isPatientNotResident: false,
    createdAt: null,
    createdBy: null,
    snfComment: null
  };
  snfHistory: PatientSnfInfo[] = [];
  professionalsById: { [key: string]: wpapi.model.Professional } = {};
  snfHistoryDetails: snfInfoDetails[];
  today: moment.Moment = moment();
  thirtyDaysAgoDate: moment.Moment = this.today.subtract(30, 'days').startOf('day');
  loadingStatus = false;
  waiverSelectionControl = new UntypedFormControl(null);
  snfNameControl = new UntypedFormControl('');
  snfList: SnfResult[] = [];
  private userAccess: Access;
  hasSNFFeature = false;
  readonly medicareUrl = 'https://www.medicare.gov/care-compare/?providerType=NursingHome';
  isWaiverSubmitted = false;
  expandSubmittedWaiver = false;
  showDeleteDialog = false;

  constructor(
    private PatientService: PatientService,
    private userAccessService: UserAccessService,
    private snfService: SnfService
  ) {
    this.patientSnfStatusItemAllowedTypes = PatientSnfStatusItemAllowedTypes.Names;
    this.patientSnfStatusItemDefinitions = PatientSnfStatusItemAllowedTypes.Definitions;
    this.patientSnfStatusItemAllowedTypes.forEach(flag => {
      this.selectedStatusFlags[flag] = false;
      this.patientSnfStatusItemLabels[flag] = this.splitStringIntoWords(flag);
    });
    this.userAccessService.currentAccess$.subscribe(async access => {
      if (access == null) {
        return;
      }
      this.userAccess = access;
    });
  }

  ngOnChanges(): void {
    if (this.patient?.snfInfo) {
      this.clearForm();
      this.assignSnfInfoValues(_.cloneDeep(this.patient.snfInfo));
    }
  }

  ngOnInit(): void {
    this.waiverSelectionControl.valueChanges.subscribe(value => {
      this.snfNameControl.reset('', { emitEvent: false });
    });

    this.hasSNFFeature = this.userAccessService.hasComponent(this.userAccess, CareComponentConstants.PAC3DayWaiver);
    this.snfNameControl.valueChanges.subscribe(value => {
      const snfItem = this.snfList.find(r => r.pacNavSNFID === +value);
      this.snfInfo.snfName = snfItem.snfName;
      this.snfInfo.snfId = snfItem.pacNavSNFID;
    });

    this.loadSNFList();
  }

  loadSNFList(): void {
    this.snfService.snfListGet({ Tier2_ID: this.patient.tier2_ID }).subscribe(snfList => {
      this.snfList = snfList;
    });
  }

  assignSnfInfoValues(snfInfo: { current?: PatientSnfInfo; history?: Array<PatientSnfInfo> }): void {
    this.snfInfo.snfName = snfInfo?.current.snfName || '';
    this.snfInfo.snfId = snfInfo?.current.snfId || null;
    this.snfNameControl.setValue(snfInfo?.current.snfId, { emitEvent: false });
    this.snfInfo.isVerified3StarRating = snfInfo?.current.isVerified3StarRating || false;
    this.snfInfo.isUsingSnf3DayWaiver = snfInfo?.current.isUsingSnf3DayWaiver || false;
    this.waiverSelectionControl.setValue(snfInfo?.current.isUsingSnf3DayWaiver || false, { emitEvent: false });
    this.snfInfo.snfAdmittedAt = snfInfo?.current.snfAdmittedAt || '';
    this.snfInfo.snfDischargedAt = snfInfo?.current?.snfDischargedAt || '';
    this.snfInfo.snfComment = snfInfo?.current?.snfComment || null;
    this.snfInfo.isACOCertified = snfInfo?.current?.isACOCertified || false;
    this.snfInfo.ACOProviderName = snfInfo?.current?.ACOProviderName;
    this.snfInfo.isPatientEvaluated = snfInfo?.current?.isPatientEvaluated || false;
    this.snfInfo.ACOProviderEvalDate = snfInfo?.current?.ACOProviderEvalDate;
    this.snfInfo.isPatientNotResident = snfInfo?.current?.isPatientNotResident || false;
    this.snfInfo.isPatientAdmissible = snfInfo?.current?.isPatientAdmissible || false;
    this.snfInfo.snfStatus = snfInfo?.current?.snfStatus || [];
    this.snfInfo.snfStatus.forEach(flag => {
      this.selectedStatusFlags[flag] = true;
    });
    this.snfHistory = snfInfo.history || [];
    let proIds = [
      ...new Set(
        [snfInfo?.current?.createdBy?.toString()].concat(snfInfo?.history?.map(snf => snf.createdBy?.toString()) || [])
      )
    ]; // pulls ids from current and history list, concats into one list, and then removes duplicates
    this.getProfessionalsInfo(proIds);
    this.snfHistoryDetails = this.assignSnfHistoryDetails(snfInfo);
    this.isFormOpen = !!this.snfInfo.snfName?.length && !this.snfInfo.snfDischargedAt;
    if (this.snfInfo.isVerified3StarRating && !!this.snfInfo.snfName?.length) {
      this.isWaiverSubmitted = true;
      this.expandSubmittedWaiver = false;
    }
  }

  assignSnfHistoryDetails(snfInfo: { current?: PatientSnfInfo; history?: Array<PatientSnfInfo> }): snfInfoDetails[] {
    let result: snfInfoDetails[] = [];
    // start from the oldest element, check the previous element for differences and store them as snfInfoDetails.updates
    for (let i = 0; i < snfInfo.history?.length; i++) {
      result.push(
        this.getChangesBetweenTwoLogs(
          _.cloneDeep(snfInfo.history[i]),
          _.cloneDeep(snfInfo.history[i - 1]),
          i - 1,
          snfInfo.history
        )
      );
    }
    // add the current element as the latest one
    result.push(
      this.getChangesBetweenTwoLogs(
        _.cloneDeep(snfInfo.current),
        _.cloneDeep(snfInfo.history[snfInfo.history?.length - 1]),
        snfInfo.history?.length - 1,
        snfInfo.history
      )
    );
    let results2 = result.map(x => x.updates);
    return result;
  }
  getChangesBetweenTwoLogs(
    currentLog: PatientSnfInfo,
    previousLog: PatientSnfInfo,
    previousLogIdx: number,
    historyList: Array<PatientSnfInfo>
  ): snfInfoDetails {
    let snfInfoDetails = currentLog as snfInfoDetails;
    snfInfoDetails.updates = [];
    try {
      if (previousLog?.snfDischargedAt && !currentLog.isEdit) {
        previousLog = { createdAt: null, createdBy: null } as PatientSnfInfo;
      }

      if (!currentLog.snfName) {
        if (previousLog?.isUsingSnf3DayWaiver) {
          snfInfoDetails.updates.push({ faIcon: faMinus, text: '3 day waiver removed from patient' });
        } else {
          snfInfoDetails.updates.push({ faIcon: faMinus, text: 'Patient removed from Worklist' });
        }

        // need to check whether if was removed because of expired admittedAt date.
        // compare previousLog.snfAdmittedAt with currentLog.createdAt subtracted 30 days.
        // remove currentLog.createdAt to display "Automatically removed"
        if (previousLog?.snfDischargedAt) {
          currentLog.createdAt = null;
        }
        if (currentLog.snfComment != previousLog?.snfComment && currentLog?.snfComment?.length) {
          snfInfoDetails.updates.push({ faIcon: faComment, text: currentLog.snfComment });
        }
        return snfInfoDetails; // no need to compare values further
      } else {
        if (!currentLog.isEdit && (!previousLog || !previousLog?.snfName || previousLog?.snfDischargedAt)) {
          snfInfoDetails.updates.push({ faIcon: faPlus, text: 'Patient added to Worklist' });
        } else if (!previousLog?.snfName && currentLog.isEdit) {
          // in case if record was removed, we need to compare it the pre-deleleted record
          let idx = previousLogIdx - 1;
          while (!historyList[idx].snfName && idx > 0) {
            idx--;
          }
          previousLog = historyList[idx];
        }
      }

      // snf name
      if (!previousLog || (previousLog?.snfDischargedAt && !currentLog.isEdit)) {
        snfInfoDetails.updates.push({ faIcon: faEdit, text: `SNF Name: ${currentLog.snfName}` });
      } else if (currentLog.snfName != previousLog?.snfName) {
        snfInfoDetails.updates.push({
          faIcon: faEdit,
          text: `SNF Name${previousLog?.snfName?.length ? ' changed to' : ':'} ${currentLog.snfName}`
        });
      }

      if (currentLog.isUsingSnf3DayWaiver && currentLog.isUsingSnf3DayWaiver !== previousLog?.isUsingSnf3DayWaiver) {
        snfInfoDetails.updates.push({ faIcon: faArrowRight, text: `3-day waiver used` });
      }

      if (currentLog.isVerified3StarRating && currentLog.isVerified3StarRating !== previousLog?.isVerified3StarRating) {
        snfInfoDetails.updates.push({ faIcon: faArrowRight, text: `Rating verifications completed` });
      }

      // snfAdmittedAt
      if (currentLog.snfAdmittedAt != previousLog?.snfAdmittedAt) {
        snfInfoDetails.updates.push({
          faIcon: faArrowRight,
          text: `Patient admitted ${currentLog.isEdit ? 'date changed to' : 'on'} ${moment(
            currentLog.snfAdmittedAt
          ).format('l')}`
        });
      }

      // snfDischargedAt
      if (
        !!currentLog.snfDischargedAt != !!previousLog?.snfDischargedAt &&
        currentLog.snfDischargedAt != previousLog?.snfDischargedAt
      ) {
        let snfDischargedAtInfo: snfInfoDetailUpdate = {
          faIcon: faCheckCircle,
          text: `Patient discharge date was ${currentLog.isEdit ? 'changed' : 'set'} to ${moment(
            currentLog.snfDischargedAt
          ).format('l')}`
        };
        snfInfoDetails.updates.push(snfDischargedAtInfo);
      }

      if (currentLog.snfStatus != previousLog?.snfStatus) {
        let snfStatusAdded = currentLog.snfStatus.filter(x => !previousLog?.snfStatus?.includes(x));
        let snfStatusRemoved = previousLog?.snfStatus?.filter(x => !currentLog?.snfStatus.includes(x)) || [];
        if (snfStatusAdded.length) {
          snfInfoDetails.updates.push({
            faIcon: faCheck,
            text: snfStatusAdded.map(status => this.patientSnfStatusItemLabels[status] || status).join(', ')
          });
        }
        if (snfStatusRemoved.length) {
          snfInfoDetails.updates.push({
            faIcon: faTimes,
            text: snfStatusRemoved.map(status => this.patientSnfStatusItemLabels[status] || status).join(', ')
          });
        }
      }
      if (currentLog.snfComment != previousLog?.snfComment && currentLog.snfComment?.length > 0) {
        snfInfoDetails.updates.push({ faIcon: faComment, text: currentLog.snfComment });
      }
    } catch (error) {
      console.error(error);
    }
    if (!snfInfoDetails.updates.length) {
      snfInfoDetails.updates = null;
    }
    return snfInfoDetails;
  }

  assignSnfInfoDetailsElement(snfInfo: PatientSnfInfo): snfInfoDetails {
    return snfInfo as snfInfoDetails;
  }

  splitStringIntoWords(string: string): string {
    return (
      string[0] +
      string
        .slice(1, string.length)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }
  async update() {
    this.loadingStatus = true;
    this.snfInfo.snfStatus = [];
    for (let key in this.selectedStatusFlags) {
      if (this.selectedStatusFlags[key]) {
        this.snfInfo.snfStatus.push(key as PatientSnfStatusItem);
      }
    }
    if (this.waiverSelectionControl.value) {
      const snfItem = this.snfList.find(r => r.pacNavSNFID === +this.snfNameControl.value);
      this.snfInfo.snfName = snfItem.snfName;
      this.snfInfo.snfId = snfItem.pacNavSNFID;
      this.snfInfo.isUsingSnf3DayWaiver = this.waiverSelectionControl.value;
    }

    try {
      let updatedSnfInfo: CaravanPatient['snfInfo'] = await this.PatientService.updatePatientSnfInfo(
        this.tierId,
        this.patient.chPatId,
        _.clone(this.snfInfo)
      );
      if (updatedSnfInfo) {
        this.patient.snfInfo = updatedSnfInfo;
        this.patient.carePatient = { ...this.patient.carePatient, ...{ snfInfo: updatedSnfInfo } };
        this.patientUpdated.emit();
        this.assignSnfInfoValues(updatedSnfInfo);
        this.editMode = false;
      }
    } catch (error) {}
    this.loadingStatus = false;
  }

  remove(): void {
    let fieldsToIgnore =
      this.snfInfo.snfComment != this.patient.snfInfo?.current?.snfComment
        ? { snfComment: this.snfInfo.snfComment }
        : {}; // check if comment was changed
    this.clearForm(fieldsToIgnore);
    this.update();
  }

  onDialogButtonClick(buttonValue: string): void {
    switch (buttonValue) {
      case 'cancel':
        this.showDeleteDialog = !this.showDeleteDialog;
        break;
      case 'delete':
        this.showDeleteDialog = false;
        this.remove();
    }
  }

  showDialog(): void {
    this.showDeleteDialog = true;
  }

  clearForm(ignoreFields: { [key: string]: any } = {}): void {
    this.snfInfo = {
      ...{
        snfName: '',
        snfId: null,
        snfAdmittedAt: '',
        snfDischargedAt: '',
        snfStatus: [],
        createdAt: null,
        isUsingSnf3DayWaiver: false,
        isVerified3StarRating: false,
        createdBy: null,
        snfComment: null,
        isACOCertified: false,
        ACOProviderName: '',
        isPatientEvaluated: false,
        ACOProviderEvalDate: null,
        isPatientNotResident: false
      },
      ...ignoreFields
    };
    this.selectedStatusFlags = {};
    this.waiverSelectionControl.reset(null, { emitEvent: false });
    this.snfNameControl.reset('', { emitEvent: false });
    this.isWaiverSubmitted = false;
    this.expandSubmittedWaiver = false;
    this.editMode = false;
  }

  get isWaiverValid() {
    return (
      this.snfInfo.snfId &&
      this.waiverSelectionControl.value &&
      this.snfInfo.isVerified3StarRating &&
      this.snfInfo.isACOCertified &&
      this.snfInfo.ACOProviderName &&
      this.snfInfo.isPatientEvaluated &&
      this.snfInfo.ACOProviderEvalDate &&
      this.snfInfo.isPatientNotResident &&
      this.snfInfo.isPatientAdmissible
    );
  }

  isFormUpdated(): boolean {
    let oldSnfInfo: PatientSnfInfo = this.editMode
      ? this.getPreviousEligibleEntry()
      : _.clone(this.patient?.snfInfo?.current);
    this.snfInfo.snfStatus = [];
    for (let key in this.selectedStatusFlags) {
      if (this.selectedStatusFlags[key]) {
        this.snfInfo.snfStatus.push(key as PatientSnfStatusItem);
      }
    }
    let ignoreProperties: string[] = ['createdAt', 'createdBy', 'isEdit'];
    return !_.isEqual(_.omit(oldSnfInfo, ignoreProperties), _.omit(this.snfInfo, ignoreProperties));
  }

  activateForm(): void {
    this.isFormOpen = true;
    this.clearForm();
  }

  statusFlagUpdated(flagName: PatientSnfStatusItem, value: boolean) {
    if (value) {
      if (!this.snfInfo.snfStatus.includes(flagName)) {
        this.snfInfo.snfStatus.push(flagName);
      }
    } else {
      this.snfInfo.snfStatus = this.snfInfo.snfStatus.filter(flag => flag != flagName);
    }
  }

  isFormValid(): boolean {
    if (
      this.snfInfo.snfAdmittedAt &&
      this.snfInfo.snfDischargedAt &&
      moment(this.snfInfo.snfDischargedAt).isBefore(this.snfInfo.snfAdmittedAt)
    ) {
      return false;
    }
    if (this.snfInfo.snfDischargedAt && !moment(this.snfInfo.snfDischargedAt).isValid()) {
      return false;
    }
    if (
      this.waiverSelectionControl.value &&
      (!this.snfInfo.isVerified3StarRating ||
        !this.snfInfo.snfId ||
        !this.snfInfo.isACOCertified ||
        !this.snfInfo.ACOProviderName ||
        !this.snfInfo.isPatientEvaluated ||
        !this.snfInfo.ACOProviderEvalDate ||
        !this.snfInfo.isPatientNotResident ||
        !this.snfInfo.isPatientAdmissible)
    ) {
      return false;
    }

    let oldSnfInfo: PatientSnfInfo = _.clone(this.patient?.snfInfo?.current);
    if (!oldSnfInfo?.snfName && !this.snfInfo.snfName) {
      return false;
    }

    return this.snfInfo.snfName.length > 0;
  }

  getProfessionalsInfo(proIds: string[]) {
    proIds?.forEach(id => {
      if (!this.professionalsById[id]) {
        this.PatientService.getProfessional(id).then(professional => {
          this.professionalsById[id] = professional;
        });
      }
    });
  }

  clonePreviousEntry(): void {
    let previousSnfLog = this.getPreviousEligibleEntry();
    this.snfInfo = _.cloneDeep(previousSnfLog);
    previousSnfLog.snfStatus.forEach(status => {
      this.selectedStatusFlags[status] = true;
    });
    this.snfInfo.isEdit = true;
    this.isFormOpen = true;
    this.editMode = true;
  }

  getPreviousEligibleEntry(): PatientSnfInfo {
    let inputs = this.patient?.snfInfo.current;
    if (!inputs?.snfName) {
      // in case if snf was removed
      inputs = this.patient?.snfInfo.history[this.patient?.snfInfo.history.length - 1];
    }
    return inputs;
  }

  isFormEditable(): boolean {
    /**
     * Add an edit button to the form when a patient is:
        Discharged 
        Discharged after 30 days
        Removed
     */
    return !!(this.patient?.snfInfo?.current?.snfDischargedAt || !this.patient?.snfInfo?.current?.snfName);
  }

  canInfoBeRemoved(): boolean {
    return !!this.patient?.snfInfo?.current?.snfName;
  }

  proAvatar(proId: string): string {
    return this.professionalsById?.[proId]?.avatar?.url;
  }

  proName(proId: string): string {
    let fName = this.professionalsById?.[proId]?.firstName;
    if (fName) {
      fName += ' ';
    }
    return fName + this.professionalsById?.[proId]?.lastName;
  }

  cancelWaiver() {
    if (this.isWaiverSaved) {
      this.onSave();
      return;
    }

    this.clearForm();
    if (this.patient?.snfInfo) {
      this.assignSnfInfoValues(_.cloneDeep(this.patient.snfInfo));
    }
  }

  onSave() {
    this.isWaiverSubmitted = true;
    this.expandSubmittedWaiver = false;
    this.update();
  }

  toggleSubmittedWaiver() {
    this.expandSubmittedWaiver = true;
  }

  get ACOProviderEvalDate() {
    if (this.snfInfo.ACOProviderEvalDate) {
      return new Date(this.snfInfo?.ACOProviderEvalDate).toLocaleDateString();
    }
  }

  get isWaiverSaved() {
    return this.patient.snfInfo?.current?.snfId && !this.patient.snfInfo?.current?.snfDischargedAt;
  }

  get isVerified3StarRating() {
    return this.patient.snfInfo?.current?.isVerified3StarRating;
  }

  get isSnfAdmittedAtValid() {
    return (
      this.snfInfo.snfAdmittedAt &&
      this.snfInfo.snfAdmittedAt.length > 0 &&
      moment(this.snfInfo.snfAdmittedAt).isValid()
    );
  }
}
