import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {ClickableParentComponent} from './clickable.parent.component';

@Component({
  selector: 'app-detail-cell-renderer',
  template: `
    <ag-grid-angular
      #agGrid
      coachAgGridResize
      style='width: 100%; height: 2000px; padding-left: 50px;'
      id='detailGrid'
      class='full-width-grid ag-theme-alpine'
      [columnDefs]='colDefs'
      [defaultColDef]='defaultColDef'
      [rowData]='rowData'
      (gridReady)='onGridReady($event)'
      [frameworkComponents]='frameworkComponents'
      [masterDetail]='true'
      [detailCellRendererParams]='detailCellRendererParams'
      [context]="context"
      [detailRowAutoHeight]="true"
    >
    </ag-grid-angular>`,
})
export class DetailCellRenderer implements ICellRendererAngularComp {
  rowData;
  colDefs;
  rowId;
  masterGridApi;
  params;
  defaultColDef;
  public detailCellRendererParams;
  public detailCellRenderer;
  public detailCellRendererParamsThird;
  public context: any;

  public detailRowHeight;


  public frameworkComponents = {
    clickableParentComponent: ClickableParentComponent
};

constructor() {
  this.context = {componentParent: this};

}

  agInit(params: any): void {
    this.params = params;

    this.masterGridApi = params.api;
    this.rowId = params.node.id;


    this.colDefs = params.detailGridOptions.columnDefs;

    this.detailRowHeight = 500;
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: params.detailGridOptions.detailCellRendererParams.detailGridOptions.columnDefs,
        defaultColDef: { flex: 1, },
        detailRowHeight: 500,
        groupDefaultExpanded: 0,
        masterDetail: true,
        detailRowAutoHeight: true,

    },
    getDetailRowData: params => {
      setTimeout(function () {
        params.successCallback(params.data.practices);
      }, 0);

    },
    };

    this.rowData = params.data.communities;

  }

  refresh(params: any): boolean {
    return false;
  }

  onGridReady(params) {
    var gridInfo = {
      id: this.rowId,
      api: params.api,
      columnApi: params.columnApi,
    };

    this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
  }

  ngOnDestroy(): void {
    this.masterGridApi.removeDetailGridInfo(this.rowId);
  }

  reportParentClickResult(cell: any, data: any):void {
    this.params.context.componentParent.reportParentClickResult(cell, data);
}
}
