import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Patient, PatientAlert } from '../../models/patient';
import { PATIENT_ALERTS } from '../../mocks/mock-patient-alerts';
import { HttpClient } from '@angular/common/http';
import { AuthService} from '@shared/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';
import { wpapi } from "@hcd-caravanhealth/pkg-wptypes";
import { ToastrMessageService } from '@shared/services/toastr-message/toastr-message.service';

@Injectable({
  providedIn: 'root'
})
export class PatientAlertService {
  get wpBaseURL() { return environment.wpApiUrl; }
  get userToken() { return this.AuthService.IdToken; }
  constructor(private http: HttpClient, private AuthService: AuthService, private ToastrMessageService: ToastrMessageService) { 
  }

  async getWPAPIorgAlerts(orgId): Promise<Array<wpapi.model.Alert & { filterDesc?: string, userId?: string }>> {
    let url = `${this.wpBaseURL}/orgs/${orgId}/alerts?isActive=true`;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Authorization': 'Bearer ' + this.userToken
      }
    }
    try {
      return await this.http.get<Array<wpapi.model.Alert & { filterDesc?: string, userId?: string }>>(url, options).toPromise();
    } catch (err) {
      this.ToastrMessageService.error(err);
      throw err;
    }
  }
  async getPatientAlerts(orgId:string,patientId:string): Promise<Array<wpapi.model.Alert>> {
    let alerts;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Authorization': 'Bearer ' + this.userToken,
      }
    };
​
    let url = `${this.wpBaseURL}/users/${patientId}/alerts`;
​
    try {
      alerts = await this.http.get(url, options).toPromise() as Promise<Array<wpapi.model.Alert>>;
      return alerts;
    } catch(e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e)
      }
      console.error(e);
    }
  }
  async getPatientCompletion(orgId, patientId, completionId): Promise<wpapi.model.Completion>{
    let completion;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Authorization': 'Bearer ' + this.userToken,
      }
    };
​
    let url = `${this.wpBaseURL}/users/${patientId}/completions/${completionId}`;
   
    try {
      completion = await this.http.get(url, options).toPromise() as wpapi.model.Completion;
      return completion;
    } catch(e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e)
      }
      console.error(e);
    }
  }
  async updatePatientAlert(orgId, patientId, alert): Promise<any>{
    let updatedAlertResponse;
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Authorization': 'Bearer ' + this.userToken,
      }
    };
    let userInfo = await this.AuthService.getWellpepperUserInfo();
​    let updatedAlert = {
      inactiveAt: new Date(),
      inactiveBy: userInfo._id,
      inactiveReason: alert.inactiveReason,
      inactiveReasonText: alert.inactiveReasonText,
      isActive: false,
      _id: alert._id
    }
    let url = `${this.wpBaseURL}/users/${patientId}/alerts/${alert._id}`;
​
    try {
      updatedAlertResponse = await this.http.put(url, updatedAlert, options).toPromise() as Promise<any>;
      return updatedAlertResponse;
    } catch(e) {
      if (environment.production) {
        // TODO - need to decide what to display
        this.ToastrMessageService.error(e)
      }
      console.error(e);
      return false
    }
  }
}
