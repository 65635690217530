import { CareComponentConstants, CareFeatureConstants, CarePatientItem } from '@shared/models/module-constants';
import { SummaryTile } from './summary-title.model';
import { AwvWorkflowStatusForPatientListDisplay } from '@shared/models/patient';
import { PatientEngagement } from '@shared/enums';

export const SUMMARY_TILES: SummaryTile[] = [
  {
    title: 'Annual Wellness Visits',
    description:
      'Annual Wellness cohorts can be used to develop targeted approaches to scheduling and tracking annual wellness visits in the platform.  Facesheet schedule and Top 50 are great resources for pre-visit planning to get the most out of annual wellness visits.',

    imagePath:
      'assets/img/sh-platform-care-sum-illustrations/SH_PEP_Illustrations_Care_Sum_AnnualWellnessVisit_1700x1100_20230526.svg',
    tileItems: [
      {
        linkText: 'AWV Opportunities',
        filterPreset: CarePatientItem.AwvOpportunities,
        filterTitle: 'AWV Opportunities',
        awvPatientsStatus: AwvWorkflowStatusForPatientListDisplay.AllPatients,
        subLinks: [
          {
            linkText: 'Ready to Schedule',
            filterPreset: CarePatientItem.AwvOpportunities,
            filterTitle: 'AWV Opportunities',
            componentId:CareComponentConstants.AwvPatientsWorklist,
            awvPatientsStatus: AwvWorkflowStatusForPatientListDisplay.ReadyToSchedule
          },
          {
            linkText: 'Ready to Remind',
            filterPreset: CarePatientItem.AwvOpportunities,
            filterTitle: 'AWV Opportunities',
            componentId:CareComponentConstants.AwvPatientsWorklist,
            awvPatientsStatus: AwvWorkflowStatusForPatientListDisplay.ReadyToRemind
          },
          {
            linkText: 'Ready to Self-Report',
            filterPreset: CarePatientItem.AwvOpportunities,
            filterTitle: 'AWV Opportunities',
            componentId:CareComponentConstants.AwvPatientsWorklist,
            awvPatientsStatus: AwvWorkflowStatusForPatientListDisplay.ReadyToSelfReport
          }
        ]
      },
      {
        linkText: 'In Home AWV Schedule',
        filterPreset: CarePatientItem.AwvSchedule,
        componentId: CareComponentConstants.IHESchedule,
        featureFlag: CareFeatureConstants.InHomeAWVScheduleList,
        filterTitle: 'In Home AWV Schedule'
      },
      {
        linkText: 'In Home AWV Files',
        filterPreset: CarePatientItem.AwvFiles,
        componentId: CareComponentConstants.IHECohort,
        featureFlag: CareFeatureConstants.IHECohort,
        filterTitle: 'In Home AWV Files'
      },
      {
        linkText: 'Facesheet Schedule',
        filterPreset: CarePatientItem.FacesheetSchedule,
        componentId: CareComponentConstants.HccFacesheetBySchedule,
        filterTitle: 'Facesheet Schedule'
      },
      {
        linkText: 'Top 50',
        filterPreset: CarePatientItem.HccTop50,
        filterTitle: 'TOP 50'
      }
    ]
  },
  {
    title: 'Chronic Conditions',
    description:
      'Streamline your workflows for identifying your most vulnerable patients for care management services and disease management clinical programs that lead to more healthy days at home.',
    imagePath:
      'assets/img/sh-platform-care-sum-illustrations/SH_PEP_Illustrations_Care_Sum_ChronicConditions_1700x1100_20230526.svg',

    tileItems: [
      {
        linkText: 'Care Management',
        filterPreset: CarePatientItem.CareManagementAllProgram,
        filterTitle: 'Care Management',
        subLinks: [
          {
            linkText: 'Heart Failure',
            filterPreset: CarePatientItem.CareManagementHeartFailure,
            filterTitle: 'Care Management Heart Failure'
          },
          {
            linkText: 'COPD',
            filterPreset: CarePatientItem.CareManagementCopd,
            filterTitle: 'Care Management COPD'
          },
          {
            linkText: 'Hypertension',
            filterPreset: CarePatientItem.CareManagementHypertension,
            filterTitle: 'Care Management Hypertension'
          }
        ]
      },
      {
        linkText: 'SMS Outreach Program',
        filterPreset: CarePatientItem.SmsOutreachProgram,
        componentId: PatientEngagement.PatientTwilioSMS,
        featureFlag: CareFeatureConstants.SMSOutreachProgram,
        filterTitle: 'SMS Outreach Program'
      }
    ]
  },
  {
    title: 'Post-Acute Care',
    description:
      'The Post-Acute Care Navigator Program is the first step in helping health systems manage quality, cost and utilization of their patients during their post-acute journey.',
    imagePath:
      'assets/img/sh-platform-care-sum-illustrations/SH_PEP_Illustrations_Care_Sum_PostAcuteCare_1700x1100_20230526.svg',
    tileItems: [
      {
        linkText: 'PAC Navigator Program',
        filterPreset: CarePatientItem.PacNavigatorProgram,
        filterTitle: 'PAC Navigator Program'
      }
    ]
  },
  {
    title: 'General',
    description:
      'The All Patient List provides a view of your entire traditional Medicare population. By using the All Patients list, you can create and save your own custom list using the advanced filter tray and column settings.',
    imagePath:
      'assets/img/sh-platform-care-sum-illustrations/SH_PEP_Illustrations_Care_Sum_Administrative_1700x1100_20230526.svg',
    tileItems: [
      {
        linkText: 'All Patients',
        filterPreset: CarePatientItem.AllPatients,
        filterTitle: 'All Patients'
      }
    ]
  },
  {
    title: '340B Prescriptions',
    featureFlag: CareFeatureConstants.FeatureFlag340B,
    featureId: CareFeatureConstants.CarePrescriptionFeature,
    description:
      'Our referral prescription compliance program uncovers hidden opportunities to double the number of profitable contract pharmacies and eligible prescriptions.',
    imagePath:
      'assets/img/sh-platform-care-sum-illustrations/SH_PEP_Illustrations_Care_Sum_Prescriptions_1700x1100_20230526.svg',
    tileItems: [
      {
        linkText: '340B Prescriptions',
        path: '../care/allPrescriptions',
        filterPreset: null,
        filterTitle: null
      }
    ]
  }
];
