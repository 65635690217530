<div class="u-height--fit-content margin-auto row min-height">
  <div class="span--2 u-border-radius--rounded">
    <coach-stepper class="stepper" [icon]="'calendar-complete'" [isLastStep]="true" [activityCompleted]="isActivityComplete"></coach-stepper>
  </div>
  <div class="span--10">
    <div class="row" (click)="isTabOpen = !isTabOpen">
      <div class="span--10 padding-left--small pointer">
        <div [ngClass]="{'tabOpen': isTabOpen === true }" class="u-bold">AWV Completed</div>
        <div class="u-bold u-color--brand--6 u-font-weight--light" id='testAwvCompletedDateText'>
          <ng-container *ngIf="completedOutcome">
            <div *ngIf="completedOutcome.type == outcomeTypes.PatientAttendedOutcome" class="u-color--brand--6 u-font-weight--light">Attended on {{ scheduledDate | date:'MM/dd/yyyy' }}</div>
            <div *ngIf="completedOutcome.type == outcomeTypes.PatientDidNotAttendOutcome" class="u-color--brand--6 u-font-weight--light">Did Not Attend</div>
          </ng-container>
          <div *ngIf="scheduledDate > today" class="u-color--default u-font-weight--light">Available to complete {{ scheduledDate | date:'MM/dd/yyyy' }}</div>
        </div>
      </div>
      <div class="span--2 padding-left--small" id='testAWVCompletedArrow'>
        <coach-icon [iconName]="isTabOpen ? 'chevron-up' : 'chevron-down'" iconColor="currentColor"></coach-icon>
      </div>
    </div>
    <div class="padding-top--large">
      <coach-patient-step-completed-form *ngIf="isTabOpen"
                                         [patient]="patient"
                                         [scheduledOutcome] = "scheduledOutcome"
                                         [editModeActive]="true"
                                         [outcome]="completedOutcome"
                                         (outcomeSaved)="this.outcomeSaved($event)"
                                         (deleteActivity)="this.deleteActivity($event)"
      ></coach-patient-step-completed-form>
    </div>
  </div>
</div>
