import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SelectedPatientInfo } from '@shared/models/care/prescriptions';
import { CareFeatureConstants } from '@shared/models/module-constants';
import { PrescriptionService } from '@shared/services/prescription/prescription.service';

@Component({
  selector: 'coach-request-note',
  templateUrl: './request-note.component.html',
  styleUrls: ['./request-note.component.scss'],
})
export class RequestNoteComponent implements OnInit, OnChanges {
  @Input() selectedPatientInfo: SelectedPatientInfo;
  @Input() showModal = false;
  @Input() visitNoteRequestedDate: string;
  @ViewChild('noteContent') noteContent: ElementRef;
  @Output() modalToggled = new EventEmitter<boolean>();
  @Output() noteRequested = new EventEmitter<boolean>();
  faxNum = '';
  sendingFaxLoading = false;

  public Fax340bOutboundFeature = CareFeatureConstants.Fax340bOutboundFeature;

  faxRegex = /^\([0-9]{3}\)[ ]*[0-9]{3}-[0-9]{4}$/;

  constructor(private prescriptionService: PrescriptionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.faxNum = this.selectedPatientInfo.prescriberFax
      ? `(${this.selectedPatientInfo.prescriberFax.substring(
          0,
          3
        )}) ${this.selectedPatientInfo.prescriberFax.substring(
          3,
          6
        )}-${this.selectedPatientInfo.prescriberFax.substring(6, 10)}`
      : '';
  }

  ngOnInit(): void {}

  public formatDate(dateStr) {
    return new Date(dateStr).toLocaleDateString();
  }

  toggleVisitNoteModal() {
    this.modalToggled.emit(this.showModal);
  }

  formatPhoneNumber(value) {
    if (!value) {
      this.faxNum = value;
      return; 
    }

    if (!'1234567890'.includes(value.substring(value.length - 1))) {
      this.faxNum = this.faxNum.substring(0, this.faxNum.length - 1);
      return;
    }

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength > 10) {
      this.faxNum = this.faxNum.substring(0, this.faxNum.length - 1);
      return;
    }

    if (phoneNumberLength < 4) {
      this.faxNum = phoneNumber;
      return;
    }

    if (phoneNumberLength < 7) {
      this.faxNum = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      return;
    }

    this.faxNum = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  public faxNumberValid() {
    if (this.faxNum.length == 0 || !this.faxNum.match(this.faxRegex))
      return false;
    else return true;
  }

  getPatientSex() {
    return this.selectedPatientInfo.gender;
  }

  sendFax(faxnumber: any) {
    this.sendingFaxLoading = true;
    this.prescriptionService
      .sendFax(this.selectedPatientInfo.claimPk, faxnumber, this.selectedPatientInfo.claimType)
      .then(() => {
        this.sendingFaxLoading = false;
        const dateConfig: any = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        };
        this.toggleVisitNoteModal();
        this.noteRequested.emit(true);
      });
  }

  copyTextAndClose() {
    this.copyMessage(this.noteContent.nativeElement.innerText);
    this.toggleVisitNoteModal();
    this.noteRequested.emit(true);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
